// @flow
import { DeleteButtonWithIcon as DeleteButton } from "components/DeleteButton";
import { ListEntry, Buttons } from "components/ListEntry";
import { Grid, Cell, LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";
import { CurrencyField } from "components/CurrencyField";
import { TextField } from "components/TextField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import updateHouseholdCostMutation from "./updateMutation/updateHouseholdCost.graphql";

type Props = {
  children: Node,
  deleteEntry: {
    message: string,
    onSubmit: () => void,
    className: string,
  },
  id: string,
  data: {
    ...householdCostFields,
    nestDeal: { ...HouseholdCostsList_nestDeal },
  },
};

export const HouseholdCost = ({ children, deleteEntry, id, data }: Props) => (
  <ExtendedMutation mutation={updateHouseholdCostMutation}>
    {(mutate) => {
      const { householdCosts = [] } = data.nestDeal;
      const cost = householdCosts.find((c) => c.id === id);
      const updateHouseholdCost = (input) =>
        mutate({
          variables: {
            input,
            id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateHouseholdCost: {
              id,
              ...cost,
              ...input,
            },
          },
        });

      return (
        <ListEntry>
          <Cell width={4}>
            <Grid columns={4}>
              <LeafCell width={1}>
                <DatePicker
                  label="Date cost incurred"
                  value={data.dateIncurred}
                  property="dateIncurred"
                  mutation={updateHouseholdCost}
                  data-test="household-cost-date-incurred"
                />
              </LeafCell>
              <LeafCell width={1}>
                <CurrencyField
                  label="Payment amount"
                  value={data.amount}
                  property="amount"
                  mutation={updateHouseholdCost}
                  data-test="household-cost-amount"
                />
              </LeafCell>
              <LeafCell width={2}>
                <TextField
                  label="Description"
                  value={data.description}
                  property="description"
                  mutation={updateHouseholdCost}
                  data-test="household-cost-description"
                />
              </LeafCell>
              <LeafCell width={1}>
                <DatePicker
                  label="Date we re-imbursed-the-vendor"
                  value={data.dateReimbursed}
                  property="dateReimbursed"
                  mutation={updateHouseholdCost}
                  data-test="household-cost-date-reimbursed"
                />
              </LeafCell>
            </Grid>
          </Cell>
          <Buttons>
            <DeleteButton {...deleteEntry} />
          </Buttons>
          {children}
        </ListEntry>
      );
    }}
  </ExtendedMutation>
);
