// @flow
import { useLayoutEffect, useState } from "react";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { useOutsideOnClick } from "../../hooks/useOutsideOnClick";

const outerWrapperStyle = css`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 20px);
  max-width: 355px;
  background-color: white;
  border-radius: 10px;
  z-index: 10;
  padding: 20px;

  ${media.tablet`
    position: absolute;
    left: auto;
    bottom: auto;
    top: calc(100% + 13px);
    transform: unset;
    width: 200px;
    border: 2px solid ${({ theme }) => theme.palette.hague20};
    z-index: 1;
    border-radius: 5px;
    padding: 0px;
  `}
`;

const backgroundBox = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.palette.hague150};
  z-index: 9;

  ${media.tablet`
    display: none;
  `}
`;

const innerWrapperStyle = css`
  ${media.tablet`
    background-color: white;
    padding: 10px;
    z-index: 1;
    position: relative;
    border-radius: 5px;
  `}
`;

const triangleStyle = css`
  display: none;

  ${media.tablet`
    display: block;
    border: 2px solid ${({ theme }) => theme.palette.hague20};
    background-color: white;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    position: absolute;
    top: -7px;
    left: ${({ $left }) => $left};
    border-top-left-radius: 2px;
    z-index: 0;
  `}
`;

type Props = {
  onClose(): void,
  children: React$Node,
  className?: string,
  arrowOffset?: string,
  parentRef?: {| current: HTMLElement | null |},
};

export const CalendarPopupBox = ({
  onClose,
  children,
  className,
  parentRef,
}: Props) => {
  const ref = useOutsideOnClick(() => onClose());
  const [offset, setOffset] = useState("auto");

  useLayoutEffect(() => {
    const parentElement = parentRef?.current;
    const localElement = ref?.current;
    if (parentElement && localElement) {
      const localLeft = localElement.getBoundingClientRect().left;
      const parentLeft = parentElement.getBoundingClientRect().left;
      const parentRight = parentElement.getBoundingClientRect().right;
      const parentWidth = parentRight - parentLeft;
      const diff = parentLeft - localLeft;
      setOffset(`${diff + parentWidth / 2 - 8.5}px`);
    }
  }, [ref, parentRef]);

  return (
    <div css="position: absolute; top: 0; z-index: 1000;">
      <div css={backgroundBox} />
      <div className={className} ref={ref} css={outerWrapperStyle}>
        <div $left={offset} css={triangleStyle}></div>
        <div css={innerWrapperStyle}>{children}</div>
      </div>
    </div>
  );
};
