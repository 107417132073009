// @flow
import { gql } from "@apollo/client";

import { Checkbox } from "components/Checkbox/Checkbox";
import { CurrencyField } from "components/CurrencyField/CurrencyField";
import { Grid, LeafCell } from "components/Grid";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { DebtFundingCommitmentList } from "../DebtFundingCommitmentList/DebtFundingCommitmentList";

const DEBT_SIGNOFF_QUERY = gql`
  query DebtSignoffQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      debtSignedOff
      id
      requestedLoanAmount
      signedOffGuarantee
      signedOffValuation
    }
  }
`;

const DEBT_SIGNOFF_MUTATION = gql`
  mutation DebtSignoffMutation($input: NestDealInput!, $dealId: ID!) {
    updateNestDeal(nestDeal: $input, id: $dealId) {
      debtSignedOff
      id
      requestedLoanAmount
      signedOffGuarantee
      signedOffValuation
    }
  }
`;

type Props = {
  dealId: string,
};

export const DebtSignoff = ({ dealId }: Props) => (
  <ExtendedQuery query={DEBT_SIGNOFF_QUERY} variables={{ dealId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<DebtSignoffQuery>) => (
      <ExtendedMutation mutation={DEBT_SIGNOFF_MUTATION} variables={{ dealId }}>
        {(updateNestDeal) => {
          const mutate = (input) =>
            updateNestDeal({
              variables: {
                input,
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateNestDeal: {
                  __typename: "NestDeal",
                  ...nestDeal,
                  ...input,
                },
              },
            });

          return (
            <Grid columns={4}>
              <LeafCell width={1}>
                <Checkbox
                  data-test="debt-signoff:debt-signed-off"
                  label="Debt signoff"
                  mutation={mutate}
                  optionName="Sent"
                  property="debtSignedOff"
                  value={nestDeal.debtSignedOff}
                />
              </LeafCell>
              <LeafCell width={1} left={1}>
                <CurrencyField
                  data-test="debt-signoff:requested-loan-amount"
                  label="Maximum debt funding amount"
                  mutation={mutate}
                  property="requestedLoanAmount"
                  value={nestDeal.requestedLoanAmount}
                />
              </LeafCell>
              <LeafCell width={1}>
                <CurrencyField
                  data-test="debt-signoff:signed-off-valuation"
                  label="Signed off valuation"
                  mutation={mutate}
                  property="signedOffValuation"
                  value={nestDeal.signedOffValuation}
                />
              </LeafCell>
              <LeafCell width={1}>
                <CurrencyField
                  data-test="debt-signoff:signed-off-guarantee"
                  label="Signed off guarantee"
                  mutation={mutate}
                  property="signedOffGuarantee"
                  value={nestDeal.signedOffGuarantee}
                />
              </LeafCell>
              <LeafCell width={4}>
                <DebtFundingCommitmentList parentId={dealId} />
              </LeafCell>
            </Grid>
          );
        }}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
