// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { OverlayNav } from "../../../components/Overlay/Overlay";
import { BackButton } from "../../../components/BackButton";
import { ModalHeader } from "../../../components/Modal";

const headerTextStyles = css`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

const modalHeaderStyles = css`
  display: none;
  ${media.tablet`
    display: block;
    min-height: 53px;
    padding: 18px 20px 10px;
    text-align: center;
  `}
`;

type Props = {
  headerText: string,
  onBack: () => void,
  onClose: () => void,
  withBackButton: boolean,
};

export const TabHeader = ({
  headerText,
  onBack,
  onClose,
  withBackButton,
}: Props) => {
  return (
    <>
      <OverlayNav
        heading={headerText}
        onClose={onClose}
        onBack={onBack}
        withBackButton={withBackButton}
        css={mobileOnly}
      />
      <ModalHeader css={modalHeaderStyles}>
        {withBackButton && <BackButton onClick={onBack} />}
        <span css={headerTextStyles}>{headerText}</span>
      </ModalHeader>
    </>
  );
};
