// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitCheckbox } from "components/Checkbox";
import { Loader } from "components/Loader";
import { NoSubmitTextField } from "components/TextField";

const PROPERTY_SALE_REDEMPTION_QUERY = gql`
  query AdvancesForAllPropertySaleRedemption($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      dealTypeDetails {
        ... on DealTypeAdvancesForAllMarch2019 {
          id
          propertySaleRedemption {
            id
            paymentDateReceived
            totalAmountReceived
            advancePaymentReceivedAmount
            agencyFeeOnCompletionAmount
            incentiveFeeReceivedAmount
            advanceFeeCapRefundAmount
            paymentAdjustmentAmount
            adjustmentNotes
            lossRealised
            isPaymentMadeToSpv
            reconciledAndSignedOff
            projectedSalePrice
            projectedAdvanceRepaymentAmount
            projectedLosses
            amountOutstandingOwedToNested
            advanceAmountReconciliation
          }
        }
      }
    }
  }
`;

const UPDATE_PSR = gql`
  mutation UpsertPSR(
    $dealTypeId: ID!
    $input: UpsertAdvancesForAllPropertySaleRedemption!
  ) {
    upsertAdvancesForAllPropertySaleRedemption(
      dealTypeId: $dealTypeId
      input: $input
    ) {
      id
      paymentDateReceived
      totalAmountReceived
      advancePaymentReceivedAmount
      agencyFeeOnCompletionAmount
      incentiveFeeReceivedAmount
      advanceFeeCapRefundAmount
      paymentAdjustmentAmount
      adjustmentNotes
      lossRealised
      isPaymentMadeToSpv
      reconciledAndSignedOff
      projectedSalePrice
      projectedAdvanceRepaymentAmount
      projectedLosses
      amountOutstandingOwedToNested
      advanceAmountReconciliation
    }
  }
`;

const RECONCILE = gql`
  mutation markAsReconciled($id: ID!, $input: ReconcileInput!) {
    advancesForAllPropertySaleRedemptionReconciliation(id: $id, input: $input) {
      id
      reconciledAndSignedOff
    }
  }
`;

type Props = { dealId: string, dealTypeId: string };
export const AdvancesForAllMarch2019PropertySaleRedemption = ({
  dealId,
  dealTypeId,
}: Props) => (
  <ExtendedQuery query={PROPERTY_SALE_REDEMPTION_QUERY} variables={{ dealId }}>
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<AdvancesForAllPropertySaleRedemption>) => {
      if (
        !nestDeal &&
        !nestDeal.dealTypeDetails &&
        !nestDeal.dealTypeDetails.propertySaleRedemption
      )
        return <Loader />;
      const {
        // I mean this can't be null or undefined because of the above if check... so like
        // $FlowFixMe
        dealTypeDetails: { propertySaleRedemption },
      } = nestDeal;

      return (
        <ExtendedMutation
          awaitRefetchQueries
          mutation={UPDATE_PSR}
          refetchQueries={[
            { query: PROPERTY_SALE_REDEMPTION_QUERY, variables: { dealId } },
          ]}
        >
          {(updatePropertysaleRedemption: MutationFunction<*>) => {
            const update = async (input) => {
              await updatePropertysaleRedemption({
                variables: {
                  input,
                  dealTypeId,
                },
                optimisticResponse: {
                  upsertAdvancesForAllPropertySaleRedemption: {
                    __typename: "DealTypeAdvancesForAllMarch2019",
                    ...propertySaleRedemption,
                    ...input,
                  },
                },
              });
            };

            return (
              <Grid columns={4}>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Projected sale price"
                    data-test="projected-sale-price"
                    value={propertySaleRedemption?.projectedSalePrice}
                    readOnly
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Projected advance repayment amount"
                    data-test="projected-advance-repayment-amount"
                    value={
                      propertySaleRedemption?.projectedAdvanceRepaymentAmount
                    }
                    readOnly
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Projected loss"
                    data-test="projected-loss"
                    value={propertySaleRedemption?.projectedLosses}
                    readOnly
                  />
                </LeafCell>
                <LeafCell width={1} />
                <LeafCell width={1}>
                  <NoSubmitDatePicker
                    label="Date received"
                    data-test="payment-date-received"
                    value={propertySaleRedemption?.paymentDateReceived}
                    onSubmit={(val) => update({ paymentDateReceived: val })}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Total amount received (+ / -)"
                    data-test="total-amount-received"
                    allowNegative
                    value={propertySaleRedemption?.totalAmountReceived}
                    onSubmit={(val) => update({ totalAmountReceived: val })}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Advance payment received"
                    data-test="advance-payment-received"
                    value={propertySaleRedemption?.advancePaymentReceivedAmount}
                    onSubmit={(val) =>
                      update({ advancePaymentReceivedAmount: val })
                    }
                  />
                </LeafCell>
                <LeafCell width={1} />
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Agency fee on completion"
                    data-test="agency-fee-on-completion"
                    value={propertySaleRedemption?.agencyFeeOnCompletionAmount}
                    onSubmit={(val) =>
                      update({
                        agencyFeeOnCompletionAmount: val,
                      })
                    }
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Incentive fee received"
                    data-test="incentive-fee-received"
                    value={propertySaleRedemption?.incentiveFeeReceivedAmount}
                    onSubmit={(val) =>
                      update({
                        incentiveFeeReceivedAmount: val,
                      })
                    }
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Advance fee cap refund amount"
                    data-test="advance-fee-cap-refund-amount"
                    value={propertySaleRedemption?.advanceFeeCapRefundAmount}
                    onSubmit={(val) =>
                      update({ advanceFeeCapRefundAmount: val })
                    }
                  />
                </LeafCell>
                <LeafCell width={1} />
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Payment adjustment (-/+ £)"
                    data-test="payment-adjustment"
                    allowNegative
                    value={propertySaleRedemption?.paymentAdjustmentAmount}
                    onSubmit={(val) => update({ paymentAdjustmentAmount: val })}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <NoSubmitTextField
                    label="Payment adjustment notes"
                    data-test="payment-adjustment-notes"
                    value={propertySaleRedemption?.adjustmentNotes}
                    onSubmit={(val) => update({ adjustmentNotes: val })}
                  />
                </LeafCell>
                <LeafCell width={1} />

                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Actual loss realised"
                    data-test="actual-loss-realised"
                    value={propertySaleRedemption?.lossRealised}
                    onSubmit={(val) => update({ lossRealised: val })}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCheckbox
                    label="Payment made to SPV? (If applicable)"
                    data-test="payment-made-to-spv"
                    value={propertySaleRedemption?.isPaymentMadeToSpv}
                    onSubmit={(val) => update({ isPaymentMadeToSpv: val })}
                  />
                </LeafCell>
                <LeafCell width={2} />
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Amount outstanding owed to Nested"
                    data-test="amount-outstanding-owed-to-nested"
                    value={
                      propertySaleRedemption?.amountOutstandingOwedToNested
                    }
                    readOnly
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    label="Advance amount reconciliation"
                    data-test="advance-amount-reconciliation"
                    value={propertySaleRedemption?.advanceAmountReconciliation}
                    readOnly
                  />
                </LeafCell>
                <LeafCell width={2} />
                <ExtendedMutation mutation={RECONCILE}>
                  {(reconcile: MutationFunction<*>) => {
                    const reconcilePsr = async (input) => {
                      await reconcile({
                        variables: {
                          input,
                          id: propertySaleRedemption?.id,
                        },
                        optimisticResponse: {
                          advancesForAllPropertySaleRedemptionReconciliation: {
                            __typename: "DealTypeAdvancesForAllMarch2019",
                            ...propertySaleRedemption,
                            ...input,
                          },
                        },
                      });
                    };
                    return (
                      <LeafCell>
                        <NoSubmitCheckbox
                          label="Reconciled and signed off?"
                          data-test="reconciled-and-signed-off"
                          onSubmit={(val) =>
                            reconcilePsr({ reconciledAndSignedOff: val })
                          }
                          readOnly={!propertySaleRedemption?.id}
                          value={propertySaleRedemption?.reconciledAndSignedOff}
                        />
                      </LeafCell>
                    );
                  }}
                </ExtendedMutation>
              </Grid>
            );
          }}
        </ExtendedMutation>
      );
    }}
  </ExtendedQuery>
);
