// @flow
import { getImage } from "@nested/utils";

const NURTURE_HOTNESS_IMAGE_SOURCE = {
  HOT: getImage("nest/icons/hot_face_emoji_72.png"),
  WARM: getImage("nest/icons/smiling_face_with_smiling_eyes_emoji_72.png"),
  COLD: getImage("nest/icons/cold_face_emoji_72.png"),
  DO_NOT_NURTURE: getImage("nest/icons/no_entry_emoji_72.png"),
};

type Props = {
  className?: string,
  nurtureHotness: $ReadOnly<{
    label: string,
  }>,
};

export const NurtureHotnessImage = ({
  className,
  nurtureHotness: { label },
}: Props) => {
  const imageSource = NURTURE_HOTNESS_IMAGE_SOURCE[label];

  return (
    <img
      css="width: 20px;"
      alt={label}
      className={className}
      src={imageSource}
    />
  );
};

export const ThermometerImage = () => (
  <img
    alt="Thermometer"
    css="width: 20px;"
    src={getImage("nest/icons/thermometer_emoji_72.png")}
  />
);
