export const updateConfig = {
  props({ mutate, ownProps }) {
    return {
      updateAgencyValuation(input) {
        return mutate({
          optimisticResponse: {
            __typename: "Mutation",
            updateAgencyValuation: {
              __typename: "AgencyValuation",
              ...ownProps.data.agencyValuation,
              ...input,
            },
          },
          variables: {
            id: ownProps.match.params.agencyValuationId,
            input,
          },
        }).then((result) => result);
      },
    };
  },
};
