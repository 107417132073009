import { gql, useQuery, useMutation } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

import { Loader } from "components/Loader/Loader";
import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";
import { NoSubmitSelectField } from "components/SelectField";
import { FEE_TYPES_QUERY } from "tabs/Valuation/PropertyAnalysis/ProposedAgencyFee/ProposedAgencyFeeSection";

const DEAL_TYPE_AGENCY_FRAGMENT = gql`
  fragment DealTypeAgencyFragment on DealTypeAgency {
    id
    baseFee
    baseFeeExcludingVat
    referralDiscount
    feeType {
      id
      value
    }
  }
`;

export const DEAL_TYPE_AGENCY_QUERY = gql`
  query DealTypeAgency($id: ID!) {
    nestDeal(id: $id) {
      id
      dealTypeAgency {
        id
        ...DealTypeAgencyFragment
      }
    }
  }

  ${DEAL_TYPE_AGENCY_FRAGMENT}
`;

export const UPDATE_FEE_MUTATION = gql`
  mutation UpdateDealTypeAgencyFee(
    $input: DealTypeAgencyUpdateFeeInput!
    $id: ID!
  ) {
    updateDealTypeAgencyFee(input: $input, id: $id) {
      id
      ...DealTypeAgencyFragment
    }
  }

  ${DEAL_TYPE_AGENCY_FRAGMENT}
`;

export const UPDATE_DEAL_TYPE_AGENCY_FEE_TYPE_MUTATION = gql`
  mutation UpdateDealTypeAgencyFeeType($id: ID!, $feeType: FeeTypeValue!) {
    updateDealTypeAgencyFeeType(id: $id, feeType: $feeType) {
      id
      value
      label
    }
  }
`;

export const Agency = ({ dealId }) => {
  const { data, loading, error } = useQuery(DEAL_TYPE_AGENCY_QUERY, {
    variables: {
      id: dealId,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const { loading: loadingFeeTypes, data: feeTypesData } =
    useQuery(FEE_TYPES_QUERY);

  const [triggerUpdateDealTypeAgencyFeeTypeMutation] = useMutation(
    UPDATE_DEAL_TYPE_AGENCY_FEE_TYPE_MUTATION,
    {
      refetchQueries: [
        {
          query: DEAL_TYPE_AGENCY_QUERY,
          variables: {
            id: dealId,
          },
        },
      ],
    },
  );

  const dealTypeData = data?.nestDeal?.dealTypeAgency;

  const [updateFeeMutation] = useMutation(UPDATE_FEE_MUTATION);

  const updateFeeHandler = async (input) => {
    try {
      await updateFeeMutation({
        variables: {
          id: dealTypeData.id,
          input: {
            ...input,
            feeType: currentFeeType,
          },
        },
        // We can't accurately calculate with VAT here due to javascript being unable to do floating point arithmatic correctly.
        optimisticResponse: {
          __typename: "Mutation",
          updateDealTypeAgencyFee: {
            __typename: "DealTypeAgency",
            ...dealTypeData,
            ...input,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  if (loading || loadingFeeTypes) {
    return <Loader />;
  }

  if (error) {
    errorHandler(error);
    return null;
  }

  if (!dealTypeData) {
    return null;
  }

  const currentFeeType = dealTypeData.feeType?.value;

  const getFeeTypeFromValue = (value) => {
    return feeTypesData?.feeTypes?.find((feeType) => feeType.value === value);
  };

  const handleFeeTypeChange = async (value) => {
    try {
      await triggerUpdateDealTypeAgencyFeeTypeMutation({
        variables: { id: dealTypeData.id, feeType: value },
        optimisticResponse: {
          __typename: "Mutation",
          updateDealTypeAgencyFeeType: getFeeTypeFromValue(value),
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <Grid columns={4}>
      <>
        <LeafCell width={4}>
          <NoSubmitSelectField
            options={feeTypesData?.feeTypes?.map(({ value, label }) => ({
              label,
              value,
            }))}
            value={currentFeeType}
            onSubmit={(value) => {
              if (value) {
                handleFeeTypeChange(value);
              }
            }}
            placeholder="Select fee type..."
          />
        </LeafCell>
        {currentFeeType === "PERCENTAGE" && (
          <>
            <LeafCell width={2}>
              <PercentageField
                data-test="agency:base-fee-exc-vat-percentage"
                mutation={updateFeeHandler}
                property="baseFeeExcludingVat"
                label="Fee excluding VAT"
                value={dealTypeData.baseFeeExcludingVat}
              />
            </LeafCell>

            <LeafCell width={2} data-test="agency:base-fee-inc-vat-percentage">
              <PercentageField
                property="baseFee"
                label="Fee including VAT"
                value={dealTypeData.baseFee}
                disabled
              />
            </LeafCell>
          </>
        )}
        {currentFeeType === "FIXED" && (
          <>
            <LeafCell width={2}>
              <CurrencyField
                mutation={updateFeeHandler}
                property="baseFeeExcludingVat"
                label="Fee excluding VAT"
                value={dealTypeData.baseFeeExcludingVat}
              />
            </LeafCell>
            <LeafCell width={2}>
              <CurrencyField
                property="baseFee"
                label="Fee including VAT"
                value={dealTypeData.baseFee}
                disabled
              />
            </LeafCell>
          </>
        )}
      </>
    </Grid>
  );
};
