// @flow
import { gql } from "@apollo/client";

import { RadioButtons } from "components/RadioButtons";
import { Grid, LeafCell } from "components/Grid";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { yesNAOptions } from "@nest-ui/shared/options";
import { useMutation } from "@apollo/client/react/hooks";

const CLOSE_MISC_CHECKLIST_FRAGMENT = gql`
  fragment CloseMiscChecklistFields on NestDeal {
    id
    nestedForSaleBoardAllowed
  }
`;

const CLOSE_MISC_CHECKLIST_QUERY = gql`
  query CloseMiscChecklistQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      ...CloseMiscChecklistFields
    }
  }
  ${CLOSE_MISC_CHECKLIST_FRAGMENT}
`;

const CLOSE_MISC_CHECKLIST_MUTATION = gql`
  mutation UpdateCloseMiscChecklist($id: ID!, $input: NestDealInput!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      ...CloseMiscChecklistFields
    }
  }
  ${CLOSE_MISC_CHECKLIST_FRAGMENT}
`;

export const CloseMiscChecklist = ({ dealId }: {| dealId: string |}) => {
  const [mutate] = useMutation(CLOSE_MISC_CHECKLIST_MUTATION);

  return (
    <ExtendedQuery
      query={CLOSE_MISC_CHECKLIST_QUERY}
      variables={{ id: dealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<CloseMiscChecklistQuery>) => {
        return (
          <Grid columns={2}>
            <LeafCell width={2}>
              <RadioButtons
                data-test="close-misc-checklist:nested-for-sale-board-allowed"
                options={yesNAOptions}
                label="Put up Nested for sale board?"
                property="nestedForSaleBoardAllowed"
                value={nestDeal.nestedForSaleBoardAllowed}
                mutation={(input) => {
                  mutate({
                    variables: {
                      input,
                      id: nestDeal.id,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        __typename: "NestDeal",
                        ...nestDeal,
                        ...input,
                      },
                    },
                  });
                }}
              />
            </LeafCell>
          </Grid>
        );
      }}
    </ExtendedQuery>
  );
};
