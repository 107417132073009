// @flow
import { MutationFunction } from "@apollo/client/react/components";
import { Heading } from "components/Heading";
import { CurrencyField } from "components/CurrencyField";
import { PercentageField } from "components/PercentageField";
import { Checkbox } from "components/Checkbox";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { Grid, Cell, LeafCell } from "components/Grid";
import { TextField } from "@nest-ui/sellers-nest/components/TextField";

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
};

export const BuyerOfferFinanceInfo = ({ buyerOffer, mutation }: Props) => (
  <>
    <Cell width={4}>
      <Grid columns={4}>
        <LeafCell width={1}>
          <Heading level={2}> Finance </Heading>
        </LeafCell>
        <LeafCell width={1} left={1}>
          <Checkbox
            label="Proof of funds received?"
            value={buyerOffer.proofOfFundsReceived}
            property="proofOfFundsReceived"
            optionName="Yes"
            mutation={mutation}
            data-test="buyer-offer-details-proof-of-funds-received"
          />
        </LeafCell>
        <LeafCell width={3}>
          <TextField
            label="Buyer Finance Notes"
            value={buyerOffer.financeNotesIfCash}
            property="financeNotesIfCash"
            mutation={mutation}
            data-test="buyer-offer-details-finance-notes-if-cash"
          />
        </LeafCell>
        <LeafCell width={2} left={1}>
          <TextField
            label="Mortgage broking firm"
            value={buyerOffer.mortgageBrokerFirm}
            property="mortgageBrokerFirm"
            mutation={mutation}
            data-test="buyer-offer-details-mortgage-broker-firm"
            className="fs-exclude"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Lead broker"
            value={buyerOffer.mortgageBrokerLead}
            property="mortgageBrokerLead"
            mutation={mutation}
            data-test="buyer-offer-details-mortgage-broker-lead"
            className="fs-exclude"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Mortgage broker phone number"
            value={buyerOffer.mortgageBrokerPhone}
            property="mortgageBrokerPhone"
            mutation={mutation}
            className="fs-exclude"
            data-test="buyer-offer-details-mortgage-broker-phone"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="Mortgage broker email"
            value={buyerOffer.mortgageBrokerEmail}
            property="mortgageBrokerEmail"
            mutation={mutation}
            data-test="buyer-offer-details-mortgage-broker-email"
            className="fs-exclude"
          />
        </LeafCell>
        <LeafCell width={1}>
          <CurrencyField
            label="Deposit (£)"
            value={buyerOffer.depositAmount}
            property="depositAmount"
            mutation={mutation}
            data-test="buyer-offer-details-deposit-amount"
          />
        </LeafCell>
        <LeafCell width={1}>
          <PercentageField
            label="Deposit (%)"
            value={buyerOffer.depositPercentage}
            property="depositPercentage"
            mutation={mutation}
            data-test="buyer-offer-details-deposit-percentage"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Buyer's mortgage lender"
            value={buyerOffer.buyersMortgageProvider}
            property="buyersMortgageProvider"
            mutation={mutation}
            data-test="buyer-offer-details-buyers-mortgage-provider"
            className="fs-exclude"
          />
        </LeafCell>
      </Grid>
    </Cell>
  </>
);
