// @flow
import moment from "moment-timezone";

import { Warning } from "@nest-ui/sellers-nest/components/Warning";

const contactToEmailList =
  (contactType) =>
  ({ emailAddresses, id, name }) =>
    emailAddresses.map((email) => (
      <span
        key={`${id}-${email}`}
        style={{ display: "block", paddingTop: "4px" }}
        className="fs-exclude"
      >
        <strong>{`${name || "[Name is missing]"} - ${
          (contactType && `${contactType} `) || ""
        }(${email})`}</strong>
      </span>
    ));

type Props = {|
  buyerContacts: $ReadOnlyArray<buyerPropertyInterestFields_buyer_contacts>,
  confirmedWithVendor: ?string,
  dealId: ID,
  viewingStartTime: ?string,
  viewingDate: ?string,
  vendorContacts: $ReadOnlyArray<buyerPropertyInterestFields_deal_contacts>,
|};

export const ViewingsWarning = ({
  buyerContacts,
  confirmedWithVendor,
  dealId,
  viewingStartTime,
  viewingDate,
  vendorContacts,
}: Props) => {
  const viewingStartsInFuture = moment(viewingDate)
    .set({
      hour: moment(viewingStartTime).get("hour"),
      minute: moment(viewingStartTime).get("minute"),
    })
    .isAfter();

  if (viewingStartsInFuture) {
    return confirmedWithVendor === "Yes" ? (
      <Warning hasTopArrow>
        This will email these Seller contacts (
        <a
          href={`/deals/${dealId}/interest/edit-contact-preferences`}
          target="_blank"
          rel="noopener"
        >
          change preferences
        </a>
        ) and Buyer contacts to confirm the viewing:
        {buyerContacts.map(contactToEmailList("Buyer"))}
        {vendorContacts
          .filter(({ viewingCommsEnabled }) => viewingCommsEnabled)
          .map(contactToEmailList("Seller"))}
      </Warning>
    ) : (
      <Warning hasTopArrow>
        This will email all contacts associated with the Buyer to pencil in the
        viewing:
        {buyerContacts.map(contactToEmailList())}
      </Warning>
    );
  }

  return (
    <Warning hasTopArrow>
      We won&apos;t contact the buyer or seller because this viewing is in the
      past.
    </Warning>
  );
};
