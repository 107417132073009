// @flow
import type { ApolloError } from "@apollo/client/react/components";

type AlertErrors = {|
  errors?:
    | $ReadOnlyArray<GraphQLError>
    | $ReadOnlyArray<ApolloError>
    | $ReadOnlyArray<Error>,
  detail?: string,
|};

export const alertErrors = ({
  errors = [],
  detail = "",
}: AlertErrors): void => {
  const errorMessages = errors
    .slice()
    .reverse()
    .reduce((acc, { message }) => `${acc}${message}\n\n`, "");

  // eslint-disable-next-line no-alert
  window.alert(`${errorMessages}${detail}`);
};
