// @flow
import { theme } from "@nest-ui/styles";
import { handleErrors } from "@nest-ui/hocs";

import { Buttons, ListEntry } from "components/ListEntry";
import { ButtonWithIcon as Button } from "components/ButtonWithIcon";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { DuplicateIcon, InfoIcon } from "@nest-ui/icons";
import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { TextField } from "components/TextField";

type MutationConfig = {
  variables: {
    buyerOfferId: string,
  },
};

type Props = {
  deleteBuyerOffer?: (MutationConfig) => any,
  duplicateBuyerOffer?: (MutationConfig) => any,
  showDetailsModal(): any,
  id: string,
  data: any,
  deleteMessage: string,
};

export const BuyerOfferSummary = ({
  deleteBuyerOffer,
  deleteMessage,
  id,
  data,
  duplicateBuyerOffer,
  showDetailsModal,
}: Props) => {
  return (
    <ListEntry highlight={() => data.status.value === "accepted"}>
      <Grid columns={4}>
        <LeafCell width={1}>
          <CurrencyField
            label="Offer"
            value={data.amount}
            property="amount"
            data-test="buyer-offer-amount"
            readOnly
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Date"
            value={data.placedOn}
            property="placedOn"
            data-test="buyer-offer-placed-on"
            readOnly
          />
        </LeafCell>
        <LeafCell width={1}>
          <TextField
            label="Buyer"
            value={data.buyer?.name || data.contactOneName}
            property="buyer"
            data-test="buyer-offer-buyer-name"
            readOnly
            className="fs-exclude"
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <TextField
            label="Offer notes"
            value={data.notes}
            property="notes"
            data-test="buyer-offer-notes"
            readOnly
          />
        </LeafCell>

        <LeafCell width={2} left={3}>
          <TextField
            label="Buyer offer status"
            highlight={data.status.label === "Accepted"}
            value={data.status.label}
            data-test="buyer-offer-status"
            readOnly
          />
        </LeafCell>
      </Grid>
      <Buttons>
        {duplicateBuyerOffer && (
          <Button
            label="Duplicate"
            key="duplicate"
            icon={DuplicateIcon}
            data-test={`buyer-offer-duplicate-${id}`}
            onSubmit={async () => {
              try {
                await duplicateBuyerOffer({
                  variables: {
                    buyerOfferId: data.id,
                  },
                });
              } catch (e) {
                handleErrors(e);
              }
            }}
          />
        )}
        <Button
          label="View full details"
          key="all-details"
          icon={InfoIcon}
          onSubmit={showDetailsModal}
          color={theme.color.secondary.light}
          data-test={`buyer-offer-view-full-details-${id}`}
        />
        {deleteBuyerOffer && (
          <DeleteButton
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deleteBuyerOffer({
                  variables: { buyerOfferId: data.id },
                });
              }
            }}
            message={deleteMessage}
            data-test={`buyer-offer-delete-${id}`}
          />
        )}
      </Buttons>
    </ListEntry>
  );
};
