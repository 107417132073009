// @flow
import {
  graphql as apolloGraphql,
  type OperationOption,
} from "@apollo/client/react/hoc";
import { type DocumentNode } from "graphql";
import { compose, type HOC } from "recompose";

import { withGenericErrors, withLoader } from "@nested/utils/graphql";

export const graphql: (
  query: DocumentNode,
  config?: OperationOption<*, *, *, *>,
) => HOC<*, *> = (query, config) =>
  compose(
    apolloGraphql(query, config),
    withGenericErrors,
    // only show spinner if a tab has never been fetched before, otherwise show data in cache
    withLoader(({ data }) => data?.loading),
  );
