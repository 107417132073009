import T from "prop-types";

import { Grid, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { TextField } from "components/TextField";
import { AlignRight } from "components/AlignRight";
import { graphql } from "deal/utils/graphql";
import { DatePicker } from "components/DatePicker";
import { SelectField } from "components/SelectField";

import deleteDueDiligenceDateMutation from "./deleteMutation/deleteDueDiligenceDate.graphql";
import { deleteConfig } from "./deleteMutation/config";

const DueDiligenceDatesComponent = ({
  deleteDueDiligenceDate,
  endDate,
  reasonForChangeOfDate,
  reasonsForChangeOfDueDiligenceEndDate,
  insertedAt,
  explanation,
}) => (
  <ListEntryCard data-test="due-diligence-dates">
    <Grid columns={4}>
      <LeafCell width={1}>
        <DatePicker
          label="Revised due diligence end date"
          value={endDate}
          data-test="due-diligence-dates-end-date"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Date added"
          value={insertedAt}
          data-test="due-diligence-dates-date-added"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1} />

      <LeafCell width={2}>
        <SelectField
          label="Reason for change of date"
          value={reasonForChangeOfDate}
          options={reasonsForChangeOfDueDiligenceEndDate}
          data-test="due-diligence-dates-reason-for-change-of-date"
          readOnly
        />
      </LeafCell>
      <LeafCell width={2}>
        <TextField
          label="Explanation"
          value={explanation}
          data-test="due-diligence-dates-explanation"
          multiline
          readOnly
        />
      </LeafCell>

      <LeafCell left={4}>
        <AlignRight>
          <DeleteButton
            data-test="delete-due-diligence-date"
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deleteDueDiligenceDate();
              }
            }}
          />
        </AlignRight>
      </LeafCell>
    </Grid>
  </ListEntryCard>
);

export const DueDiligenceDates = graphql(
  deleteDueDiligenceDateMutation,
  deleteConfig,
)(DueDiligenceDatesComponent);

DueDiligenceDatesComponent.propTypes = {
  endDate: T.string,
  reasonForChangeOfDate: T.object,
  reasonsForChangeOfDueDiligenceEndDate: T.array.isRequired,
  explanation: T.string,
  deleteDueDiligenceDate: T.func.isRequired,
  insertedAt: T.string,
};
