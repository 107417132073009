// @flow
import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import { LeafCell } from "components/Grid";
import { TextareaWithLinks } from "components/TextareaWithLinks";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import SA_RESPONSIBLE_PEOPLE_NOTES from "./queries/SAResponsiblePeopleNotes.graphql";
import UPDATE_SA_RESPONSIBLE_PEOPLE_NOTES from "./mutations/UpdateSAResponsiblePeopleNotes.graphql";

type Props = {
  dealId: string,
};

const mutationHandler = (mutate, nestDeal) => (input: NestDealInput) =>
  mutate({
    variables: { dealId: nestDeal.id, input },
    optimisticResponse: {
      __typename: "Mutation",
      updateNestDeal: {
        __typename: "NestDeal",
        ...nestDeal,
        ...input,
      },
    },
  });

export const ResponsiblePeopleNotes = ({ dealId }: Props) => (
  <ExtendedQuery query={SA_RESPONSIBLE_PEOPLE_NOTES} variables={{ dealId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<SAResponsiblePeopleNotes>) => (
      <Mutation mutation={UPDATE_SA_RESPONSIBLE_PEOPLE_NOTES}>
        {(
          mutate: MutationFunction<
            UpdateSAResponsiblePeopleNotes,
            UpdateSAResponsiblePeopleNotesVariables,
          >,
        ) => (
          <>
            <LeafCell width={1}>
              <TextareaWithLinks
                data-test="sa_responsible_people:property_notes"
                label="SA property & marketability notes"
                property="snPropertyAndMarketabilityNotes"
                mutation={mutationHandler(mutate, nestDeal)}
                value={nestDeal.snPropertyAndMarketabilityNotes}
              />
            </LeafCell>
            <LeafCell width={1}>
              <TextareaWithLinks
                data-test="sa_responsible_people:customer_notes"
                label="SA customer notes"
                property="snCustomerNotes"
                mutation={mutationHandler(mutate, nestDeal)}
                value={nestDeal.snCustomerNotes}
              />
            </LeafCell>
          </>
        )}
      </Mutation>
    )}
  </ExtendedQuery>
);
