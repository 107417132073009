// @flow
import { css } from "styled-components";
import { maxBy } from "lodash";
import moment from "moment";
import { media } from "@nested/brand";
import {
  BUYER_INACTIVE,
  SNOOZED_ENQUIRY,
  SNOOZED_FEEDBACK,
  SNOOZED_NEGOTIATION,
  ENQUIRY_COMPLETE,
  ENQUIRIES_WORKFLOW,
  FEEDBACK_COMPLETE,
  FEEDBACK_WORKFLOW,
  NEGOTIATIONS_WORKFLOW,
  NEGOTIATIONS_COMPLETE,
  type SummaryBarState,
} from "./getSummaryBarState";

const statusDescriptionStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  margin: 0;
  padding: 0;
`;

const tabletAndDesktopOnly = css`
  ${statusDescriptionStyle}
  display: none;
  ${media.tablet`
     display: block;
  `}
`;
const mobileOnly = css`
  ${statusDescriptionStyle}
  ${media.tablet`
    display: none;
  `}
`;

const statusInWords = (num: number, workflow: ?string) => {
  if (!workflow) return null;

  switch (num) {
    case 1:
      return `Day one ${workflow}`;
    case 2:
      return `Day two ${workflow}`;
    case 3:
      return `Day three ${workflow}`;
    case 4:
      return `Ongoing ${workflow}`;
    default:
      return null;
  }
};

export const BuyerStatusDescription = ({
  selectedBpi,
  summaryBarState,
}: {
  summaryBarState: SummaryBarState,
  selectedBpi: BuyerSummary_buyer_buyerPropertyInterests,
}) => {
  const daysAfterViewing =
    selectedBpi?.postViewingContactSummary?.dayNumber || 0;
  const daysAfterEnquiry =
    selectedBpi?.postEnquiryContactSummary?.dayNumber || 0;
  const latestOfferStatus = maxBy(selectedBpi?.buyerOffers, (offer) => [
    offer.placedOn,
    offer.id,
  ])?.status.label;

  // returns null if ACTIVE_BPI_OUTSIDE_WORKFLOW or INACTIVE_BPI_WITHOUT_WORKFLOW
  switch (summaryBarState) {
    case BUYER_INACTIVE:
      return <p css={statusDescriptionStyle}>Inactive buyer</p>;
    case SNOOZED_ENQUIRY:
      return (
        <>
          <p css={mobileOnly}>
            Snoozed until{" "}
            {moment(selectedBpi.snooze?.snoozedUntil).format("DD/MM/YY")}
          </p>
          <p css={tabletAndDesktopOnly}>Enquiry snoozed</p>
        </>
      );
    case SNOOZED_FEEDBACK:
      return (
        <>
          <p css={mobileOnly}>
            Snoozed until{" "}
            {moment(selectedBpi.snooze?.snoozedUntil).format("DD/MM/YY")}
          </p>
          <p css={tabletAndDesktopOnly}>Feedback snoozed</p>
        </>
      );
    case SNOOZED_NEGOTIATION:
      return (
        <>
          <p css={mobileOnly}>
            Snoozed until{" "}
            {moment(selectedBpi.snooze?.snoozedUntil).format("DD/MM/YY")}
          </p>
          <p css={tabletAndDesktopOnly}>Negotiation snoozed</p>
        </>
      );
    case ENQUIRY_COMPLETE:
      return <p css={statusDescriptionStyle}>Enquiry complete</p>;
    case ENQUIRIES_WORKFLOW:
      return (
        <p css={statusDescriptionStyle}>
          {statusInWords(daysAfterEnquiry, summaryBarState)}
        </p>
      );
    case FEEDBACK_COMPLETE:
      return <p css={statusDescriptionStyle}>Feedback complete</p>;
    case FEEDBACK_WORKFLOW:
      return (
        <p css={statusDescriptionStyle}>
          {daysAfterViewing < 3
            ? statusInWords(daysAfterViewing, summaryBarState)
            : "Ongoing feedback"}
        </p>
      );
    case NEGOTIATIONS_COMPLETE:
    case NEGOTIATIONS_WORKFLOW:
      return (
        <p css={statusDescriptionStyle}>
          {latestOfferStatus || "No offers yet"}
        </p>
      );
    default:
      return null;
  }
};
