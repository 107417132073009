// @flow
import { gql } from "@apollo/client";

export const BUYERS_REPORT_FIELDS_FRAGMENT = gql`
  fragment buyersReportFields on BuyersAgentReport {
    id
    addedAt
    addressId
    addressText
    agentName
    customerStatus {
      id: value
      label
      value
    }
    latestOffer
    listingLink
    listingHistoryEvents {
      eventDate
      eventType {
        id: value
        label
        value
      }
      price
    }
    localBuyerCompetition
    localMarketSummary
    offerEvents {
      id
      eventDate
      offerEventType {
        id: value
        label
        value
      }
      price
    }
    primaryPhoto
    renovationPotential
    savings
    summaryStatement
    propertyCommentaryIntro
    positiveFeatures
    thingsToConsider
    reasonsForFirstOffer
    reportStatus {
      id: value
      label
      value
    }
    suggestedStartingOffer
    suggestedMaxOffer
  }
`;
