// @flow
import { last, sortBy } from "lodash";
import {
  formatDateDifference,
  formatDatetimeDifference,
} from "@nested/utils/src/formatDate/formatDate";

export const getBuyerSummary = (buyer: Search_search_buyers) => {
  if (!buyer.mostRecentlyCreatedBpi) {
    return null;
  }

  const {
    insertedAt,
    buyerOffers,
    status: { label },
    viewings,
    deal: { acceptedBuyerOffer },
  } = buyer.mostRecentlyCreatedBpi;

  const latestViewing = last(sortBy(viewings, "datetimeViewingStarts"));
  const latestOffer = last(sortBy(buyerOffers, "placedOn"));

  if (label.startsWith("B01:")) {
    return `Enquired ${formatDatetimeDifference(insertedAt)}`;
  }

  if (label.startsWith("B02:") && latestViewing) {
    return `Viewing ${formatDatetimeDifference(
      latestViewing?.datetimeViewingStarts,
    )}`;
  }

  if ((label.startsWith("B03:") || label.startsWith("B04:")) && latestViewing) {
    return `Visited ${formatDatetimeDifference(
      latestViewing.datetimeViewingStarts,
    )}`;
  }

  if (label.startsWith("B05:") && latestOffer?.placedOn) {
    return `Offered ${formatDateDifference(latestOffer.placedOn)}`;
  }

  if (label.startsWith("B06:") && acceptedBuyerOffer?.placedOn) {
    return `Offer accepted ${formatDateDifference(
      acceptedBuyerOffer?.placedOn,
    )}`;
  }

  if (label.startsWith("B07:") && acceptedBuyerOffer?.actualDateOfExchange) {
    return `Exchanged ${formatDateDifference(
      acceptedBuyerOffer?.actualDateOfExchange,
    )}`;
  }

  if (label.startsWith("B08:") && acceptedBuyerOffer?.actualDateOfCompletion) {
    return `Completed ${formatDateDifference(
      acceptedBuyerOffer?.actualDateOfCompletion,
    )}`;
  }

  return label;
};
