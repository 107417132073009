// @flow

import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import qs from "query-string";
import { gql, useQuery, useMutation } from "@apollo/client";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "styled-components";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Sentry } from "@nested/isomorphic-sentry";
import { GET_USER_QUERY } from "./AuthoriseNylasModal";
import { Modal } from "../Modal";

const loadingStyle = css`
  text-align: center;
  padding: 45px;
  color: ${({ theme }) => theme.palette.hague50};
`;

export const SAVE_AUTH_TOKEN = gql`
  mutation SaveNylasAuthToken($code: String!) {
    saveNylasAuthToken(code: $code) {
      successful
      nestedUser {
        id
        needsToAuthoriseNylas
      }
    }
  }
`;

export const AuthoriseNylasSuccess = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [saveToken] = useMutation(SAVE_AUTH_TOKEN);

  const { email } = useUser();
  const {
    loading,
    data: getUserQueryData,
    error,
  } = useQuery(GET_USER_QUERY, {
    variables: { email },
  });

  const { code } = qs.parse(search);

  useEffect(() => {
    const saveCode = async () => {
      try {
        const { data } = await saveToken({
          variables: { code },
        });
        if (data?.saveNylasAuthToken.successful) {
          history.push("/leads");
        }
      } catch (e) {
        errorHandler(e);
      }
    };

    if (loading) {
      return undefined;
    }
    if (!getUserQueryData?.nestedUserByEmail?.needsToAuthoriseNylas) {
      history.push("/leads");
    } else {
      saveCode();
    }
    return undefined;
  }, [getUserQueryData]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          code,
          email,
        },
      });
      errorHandler(error);
    }
  }, [error]);

  return (
    <Modal open onClose={() => {}} showXButton={false}>
      <div css={loadingStyle} data-test="spinner">
        <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
      </div>
    </Modal>
  );
};
