// @flow
import type { MutationFunction } from "@apollo/client/react/components";
import { Grid, Cell, LeafCell } from "components/Grid";
import { Heading } from "components/Heading";
import { RadioButtons } from "components/RadioButtons";
import { TextareaWithLinks } from "components/TextareaWithLinks";

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
};

export const BuyerOfferAMLInfo = ({ buyerOffer, mutation }: Props) => (
  <Cell width={4}>
    <Grid columns={4}>
      <LeafCell width={4}>
        <Heading level={2}>AML</Heading>
      </LeafCell>
      <LeafCell width={1}>
        <RadioButtons
          data-test="buyer-offer-modal-credas-completed"
          label="Credas completed?"
          mutation={mutation}
          nonNullable
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          property="credasCompleted"
          value={buyerOffer.credasCompleted}
        />
      </LeafCell>
      <LeafCell width={2}>
        <TextareaWithLinks
          data-test="buyer-offer-modal-link-to-aml-folder"
          label="Link to AML folder"
          mutation={mutation}
          property="linkToAmlFolder"
          value={buyerOffer.linkToAmlFolder}
        />
      </LeafCell>
    </Grid>
  </Cell>
);
