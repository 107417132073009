// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import { TextareaWithLinks as TextAreaWithLinks } from "components/TextareaWithLinks";
import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
type Props = {
  parentId: string,
};

const RECENT_MARKETING_QUERY = gql`
  query RecentMarketingQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      currentListingUrl
    }
  }
`;

const RECENT_MARKETING_MUTATION = gql`
  mutation UpdateDealWithRecentMarketing(
    $nestDeal: NestDealInput!
    $dealId: ID!
  ) {
    updateNestDeal(nestDeal: $nestDeal, id: $dealId) {
      id
      currentListingUrl
    }
  }
`;

export const RecentMarketing = ({ parentId }: Props) => (
  <ExtendedQuery
    query={RECENT_MARKETING_QUERY}
    variables={{ dealId: parentId }}
  >
    {({ nestDeal }: ExtendedQueryRenderProps<RecentMarketingQuery>) => (
      <Mutation mutation={RECENT_MARKETING_MUTATION}>
        {(
          updateNestDeal: MutationFunction<
            UpdateDealWithRecentMarketing,
            UpdateDealWithRecentMarketingVariables,
          >,
        ) => {
          const mutate = mutationConfig(updateNestDeal, nestDeal);
          return (
            <Grid columns={4}>
              <LeafCell left={1} width={4}>
                <TextAreaWithLinks
                  label="Previous listing URLs"
                  value={nestDeal.currentListingUrl}
                  property="currentListingUrl"
                  mutation={mutate}
                  data-test="current-listing-url"
                />
              </LeafCell>
            </Grid>
          );
        }}
      </Mutation>
    )}
  </ExtendedQuery>
);

const mutationConfig = (mutate, nestDeal) => (input) =>
  mutate({
    variables: {
      nestDeal: input,
      dealId: nestDeal.id,
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateNestDeal: {
        __typename: "NestDeal",
        id: nestDeal.id,
        ...nestDeal,
        ...input,
      },
    },
  });
