// @flow
import { useState, useMemo } from "react";
import { css } from "styled-components";
import { useParams } from "react-router-dom";
import { gql, useQuery, useSubscription } from "@apollo/client";
import { useInView } from "react-intersection-observer";
import { media } from "@nested/brand";
import { BuyerInformation } from "./BuyerInformation";
import { BuyerSummaryBar } from "./BuyerSummaryBar/BuyerSummaryBar";
import { RightSidePanel } from "../../components/RightSidePanel/RightSidePanel";
import { calculateTasksDue } from "../../components/RightSidePanel/Tasks/TasksButton";

const wrapperStyle = css`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: hidden;
    max-height: calc(100vh - 50px);
    position: relative;
  `}
`;

const buyerInformationDisplay = css`
  display: ${({ $mobileTaskPanelOpen }) =>
    $mobileTaskPanelOpen ? "none" : "flex"};

  ${media.tablet`
    display: flex;
  `}
`;

const customBuyerPanel = css`
  ${media.tablet`
    position: fixed;
    top: 127px;
    height: calc(100vh - 127px);
    padding-bottom: 0;
  `};
`;

export const BUYER_TASKS_COUNT_QUERY = gql`
  query BuyerTasksCount($relationType: String!, $id: ID!) {
    agentTaskCounts {
      forReference(relationType: $relationType, relationId: $id) {
        due
      }
    }
  }
`;

export const BUYER_TASKS_COUNT_SUBSCRIPTION = gql`
  subscription AgentTaskBuyerCountChanged(
    $relationType: String!
    $relationId: ID!
  ) {
    agentTasksForReferenceCountChanged(
      relationType: $relationType
      relationId: $relationId
    ) {
      due
    }
  }
`;

export const Buyer = () => {
  const { id } = useParams();
  const { data: initialTaskCountData } = useQuery(BUYER_TASKS_COUNT_QUERY, {
    variables: { id, relationType: "buyer" },
  });
  const { data: updatedTaskCountData } = useSubscription(
    BUYER_TASKS_COUNT_SUBSCRIPTION,
    { variables: { relationType: "buyer", relationId: id } },
  );

  const [showTaskPanel, setShowTaskPanel] = useState(true);
  const [showTasksOnMobile, setShowTasksOnMobile] = useState(false);

  const toggleShowTasks = (viewport = "tablet") => {
    viewport === "mobile"
      ? setShowTasksOnMobile(!showTasksOnMobile)
      : setShowTaskPanel(!showTaskPanel);
  };

  const tasksDue = useMemo(
    () => calculateTasksDue(updatedTaskCountData, initialTaskCountData),
    [updatedTaskCountData, initialTaskCountData],
  );

  // Needed for task panel scrolling
  const [navRef, pageTopInView] = useInView({
    initialInView: true,
  });

  if (!id) {
    return null;
  }

  return (
    <div data-test="buyer-page">
      <BuyerSummaryBar
        buyerId={id}
        taskButtonProps={{
          onClick: toggleShowTasks,
          showTaskPanel: (viewport) =>
            viewport === "mobile" ? showTasksOnMobile : showTaskPanel,
          taskCount: tasksDue,
        }}
      />
      <div css={wrapperStyle}>
        <BuyerInformation
          buyerId={id}
          buyerPageTopRef={navRef}
          css={buyerInformationDisplay}
          pageTopInView={pageTopInView}
          $mobileTaskPanelOpen={showTasksOnMobile}
        />
        <RightSidePanel
          relationId={id}
          relationType="buyer"
          pageTopInView={pageTopInView}
          mobileOnClose={() => setShowTasksOnMobile(false)}
          mobileOpen={showTasksOnMobile}
          onClose={() => setShowTaskPanel(false)}
          open={showTaskPanel}
          css={customBuyerPanel}
          taskCount={tasksDue}
        />
      </div>
    </div>
  );
};
