// @flow
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { TasksButton } from "../../../components/RightSidePanel/Tasks/TasksButton";
import { BuyerDetails } from "./BuyerDetails";
import { BuyerSummaryBarPlaceholder } from "./BuyerSummaryBarPlaceholder";
import { BUYER_SUMMARY_QUERY } from "./BuyerSummaryQuery";
import { WorkflowButtons } from "./workflows/WorkflowButtons";
import { getSummaryBarState } from "./getSummaryBarState";
import { useHandoff } from "../../../components/MobileActionSheet/useHandoff";

const hideOnMobile = css`
  padding-left: 20px;
  display: none;
  ${media.tablet`
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
  `}
`;

const hideOnDesktop = css`
  // to be changed to 10px before workflow launch
  padding-left: 20px;
  display: flex;
  align-self: center;
  ${media.tablet`
    display: none;
  `}
`;

export const buyerSummaryWrapper = css`
  opacity: ${({ $loading }) => ($loading ? "0.3" : "1")};
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  background-color: white;
  width: 100%;
  z-index: 4;
  ${media.tablet`
    position: fixed;
    top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
  ${media.desktop`
    width: calc(100% - 170px);
  `}
`;

const buyerSummaryContent = css`
  padding: 30px 15px 20px;

  ${media.tablet`
    padding: 20px 20px 15px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 0px;
    box-sizing: border-box;
    max-width: 1180px;
    width: 100%;
  `}
`;

type Props = {
  buyerId: string,
  taskButtonProps: {
    onClick: (viewport: string) => void,
    showTaskPanel: (viewport: string) => boolean,
    taskCount: ?number,
  },
};

const buttonsWrapper = css`
  margin-top: 15px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  ${media.tablet`
    margin-top: 0;
  `}
`;

export const BuyerSummaryBar = ({
  buyerId: id,
  taskButtonProps: { taskCount, showTaskPanel, onClick: onTaskButtonClick },
}: Props) => {
  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(BUYER_SUMMARY_QUERY, {
    variables: { id },
  });

  const { bpiId, workflow } = useParams();
  const { handoffOpen } = useHandoff();

  useEffect(() => {
    if (!handoffOpen) {
      // Handoff has just closed, meaning the call or SMS was probably completed on mobile,
      // so we refetch to update traffic lights/workflow progress.
      refetch();
    }
  }, [handoffOpen]);

  if (loading && !data) {
    return <BuyerSummaryBarPlaceholder bpiId={bpiId} workflow={workflow} />;
  }

  if (error || !data?.buyer) {
    errorHandler(error);
    return null;
  }

  const selectedBpi = data?.buyer?.buyerPropertyInterests.find(
    (bpi) => bpi.id === bpiId,
  );

  const summaryBarState = getSummaryBarState(
    selectedBpi,
    workflow,
    data.buyer.leadStatus,
  );

  return (
    <div $loading={loading} css={buyerSummaryWrapper}>
      <div css={buyerSummaryContent}>
        <BuyerDetails
          selectedBpi={selectedBpi}
          buyer={data.buyer}
          summaryBarState={summaryBarState}
        />
        <div css={buttonsWrapper}>
          <TasksButton
            css={hideOnDesktop}
            data-test="mobile-tasks-button"
            onClick={() => onTaskButtonClick("mobile")}
            selected={showTaskPanel("mobile")}
            taskCount={taskCount || 0}
          />
          <WorkflowButtons
            buyer={data.buyer}
            selectedBpi={selectedBpi}
            workflow={workflow}
            summaryBarState={summaryBarState}
          />
        </div>
      </div>
      <div css={hideOnMobile}>
        <TasksButton
          data-test="tablet-tasks-button"
          onClick={() => onTaskButtonClick("tablet")}
          selected={showTaskPanel("tablet")}
          taskCount={taskCount || 0}
        />
      </div>
    </div>
  );
};
