import { Subscription } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import styled from "styled-components";
import { branch, renderNothing } from "recompose";
import T from "prop-types";

const NEW_VERSION_SUBSCRIPTION = gql`
  subscription newVersionReleased {
    newVersionReleased {
      version
    }
  }
`;

const Div = styled.div`
  background-color: ${({ theme }) => theme.color.primary.light};
  padding-top: 20px;
  text-align: center;
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  z-index: 99;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
`;

const reloadPage = () => window.location.reload(true);

const enhance = branch(
  ({ data, loading }) => loading || !(data && data.newVersionReleased),
  renderNothing,
);

const Banner = enhance(
  ({
    data: {
      newVersionReleased: { version },
    },
  }) => (
    <Div id="deal-notifications-banner">
      A new version of the Nest is available:&nbsp;
      <span style={{ fontFamily: "Courier" }}>{version}</span>. Please save your
      current work and click <button onClick={reloadPage}>here</button> to
      refresh the page.
    </Div>
  ),
);

Banner.propTypes = {
  data: T.object.isRequired,
};

export const NotificationsBanner = () => (
  <ErrorBoundary>
    <Subscription subscription={NEW_VERSION_SUBSCRIPTION} variables={{}}>
      {Banner}
    </Subscription>
  </ErrorBoundary>
);
