import debtFundingCommitmentQuery from "../queries/debtFundingCommitments.graphql";

export const updateConfig = {
  props({ mutate, ownProps: { data } }) {
    return {
      updateDebtFundingCommitment: (id) => (input) =>
        mutate({
          variables: {
            input,
            id,
          },
          refetchQueries: [
            {
              query: debtFundingCommitmentQuery,
              variables: {
                id: data.nestDeal.id,
              },
            },
          ],
          optimisticResponse: {
            __typename: "Mutation",
            updateDebtFundingCommitment: {
              __typename: "DebtFundingCommitment",
              id,
              // eslint-disable-next-line
              ...data.nestDeal.debtFundingCommitments.find(
                ({ id: commitmentId }) => commitmentId === id,
              ),
              ...input,
            },
          },
        }),
    };
  },
};
