// @flow
import { useState, useMemo, useContext } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { ThemeContext, css } from "styled-components";
import { RadioGroup, RadioListItem } from "../RadioList";
import { AreYouSureRowOverlay } from "../AreYouSureOverlay";

export const emailListStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  span {
    font-size: 14px;
  }
`;

const mobileAccountLoginHeader = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 95px 0 8px;
  font-weight: 500;
`;

const areYouSureOverlayStyle = css`
  & div {
    opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  }

  &.fade-enter {
    opacity: 0;
    height: 55px;
  }
  &.fade-enter-active {
    opacity: 1;
    height: 97px;
    transition: height 0.1s ease, opacity 0.2s ease;
  }
  &.fade-exit {
    opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
    height: 97px;
  }
  &.fade-exit-active {
    opacity: 0;
    height: 55px;
    transition: height 0.2s ease, opacity 0.1s ease;
  }
  ${({ $index }) => $index === 0 && "border-top: unset;"}
`;

type Props = {
  customerAccountEmail: string,
  contacts: $ReadOnlyArray<contactsTabDealQuery_dealOrBuyer_contacts>,
  loading: boolean,
  onConfirm: (email: string) => Promise<void>,
};

export const MobileAccountLoginList = ({
  customerAccountEmail,
  contacts,
  loading,
  onConfirm,
}: Props) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const theme = useContext(ThemeContext);

  const mobileEmails = useMemo(
    () =>
      contacts.flatMap(({ firstName, lastName, emails }) => {
        return emails.map(({ emailAddress }) => ({
          label: `${firstName || "-"} ${lastName || "-"}`,
          radioName: `${firstName || "-"} ${lastName || "-"}`,
          subLabel: emailAddress,
          value: emailAddress,
        }));
      }),
    [contacts],
  );

  // This is required to ensure radio buttons are not rendered twice
  // and the correct desktop radio is shown as 'checked'. If it is
  // rendered, even with display: none, the mobile radio will be checked
  // instead of the desktop one because only one radio can be selected at once
  if (window.innerWidth >= theme.breakpoints.tablet) return null;

  return (
    <>
      <div css={mobileAccountLoginHeader}>Account login</div>
      <div css={emailListStyle}>
        <RadioGroup>
          {mobileEmails.map(({ value, label, subLabel, radioName }, index) => {
            const isSelected = Boolean(
              selectedEmail && selectedEmail === value,
            );
            return (
              <SwitchTransition>
                <CSSTransition
                  key={isSelected.toString()}
                  timeout={100}
                  classNames="fade"
                >
                  {selectedEmail && isSelected ? (
                    <AreYouSureRowOverlay
                      css={areYouSureOverlayStyle}
                      $index={index}
                      $loading={loading}
                      message={`Give access to ${selectedEmail}?`}
                      messageSubText="This will log out the previous account holder"
                      onCancel={() => setSelectedEmail(null)}
                      onConfirm={async () => {
                        await onConfirm(selectedEmail);
                        setSelectedEmail(null);
                      }}
                    />
                  ) : (
                    <RadioListItem
                      censorText
                      checked={customerAccountEmail === value}
                      key={`${index}-${value}`}
                      label={label}
                      onChange={setSelectedEmail}
                      radioName={radioName}
                      subLabel={subLabel}
                      value={value}
                    />
                  )}
                </CSSTransition>
              </SwitchTransition>
            );
          })}
        </RadioGroup>
      </div>
    </>
  );
};
