// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { LinkExistingContact } from "./LinkExistingContact";

const overlayWrapper = css`
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${media.tablet`
    align-items: center;
    top: 64px;
  `}
`;

const messageStyle = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 auto 20px;
  max-width: 760px;

  ${media.tablet`
    max-width: 400px;
    text-align: center;
  `}
`;

const contactCard = css`
  width: 100vw - 20px;
  ${media.tablet`
    width: 660px; 
    margin: 20px;
  `}
`;

type Props = {
  buyerId: ?string,
  contacts: FindContactByEmailUpdateContactModal_findContactByEmail[],
  dealId: ?string,
};

const getMessage = (contacts) =>
  contacts.length > 1
    ? "Matching contacts have been found"
    : "A matching contact has been found";

export const ExistingContactFound = ({ buyerId, contacts, dealId }: Props) => {
  return (
    <div css={overlayWrapper}>
      <p css={messageStyle}>{getMessage(contacts)}</p>
      {contacts.map((contact) => (
        <LinkExistingContact
          buyerId={buyerId}
          css={contactCard}
          contact={contact}
          dealId={dealId}
          key={contact.id}
        />
      ))}
    </div>
  );
};
