// @flow
import { useState } from "react";
import { pick } from "ramda";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import { H2 } from "components/Heading";
import { Grid, LeafCell } from "components/Grid";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitCheckbox } from "components/Checkbox/Checkbox";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { NoSubmitDatePicker } from "components/DatePicker";
import { FullWidthCancelButton } from "./Buttons/FullWidthCancelButton";
import { SaveButton } from "./Buttons/SaveButton";

const SET_ADVANCE_DRAWDOWN_PAYMENT_DATE_REQUEST = gql`
  mutation SetAdvanceDrawdownPaymentDate(
    $input: SetAdvanceDrawdownPaymentDateInput!
  ) {
    setAdvanceDrawdownPaymentDate(input: $input) {
      id
    }
  }
`;

export const AdvancePayment = ({
  advanceDrawdownRequest,
  advanceOfferId,
  refetchQueries,
  readOnly,
}: *) => {
  const { netAmount, datePaymentMade } = advanceDrawdownRequest;
  const paymentAlreadyMade = Boolean(datePaymentMade);
  const [readyToMakePayment, setReadyToMakePayment] = useState(
    paymentAlreadyMade || false,
  );
  const [paymentDate, setPaymentDate] = useState(datePaymentMade);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Grid columns={4} style={{ position: "relative" }}>
      <LeafCell width={4}>
        <H2>Payment</H2>
      </LeafCell>

      {!readOnly && !isEditing && (
        <EditButton
          data-test="advance-payment-edit-button"
          style={{ top: "-8px", right: "-8px" }}
          onClick={() => setIsEditing(!isEditing)}
        >
          Edit
        </EditButton>
      )}

      <LeafCell width={2}>
        <NoSubmitCheckbox
          optionName="Ready to make payment?"
          parentId={advanceDrawdownRequest.id}
          property="advance-payment"
          data-test="ready-to-make-payment"
          value={readyToMakePayment}
          readOnly={readOnly || (!isEditing && paymentAlreadyMade)}
          onSubmit={() => {
            setIsEditing(true);
            setReadyToMakePayment(!readyToMakePayment);
          }}
        />
      </LeafCell>
      <LeafCell width={2} />

      {readyToMakePayment && (
        <>
          <LeafCell width={2}>
            <NoSubmitCurrencyField
              data-test="actual-amount-net-of-fees"
              label="Actual amount net of fees"
              value={netAmount}
              readOnly
            />
          </LeafCell>
          <LeafCell width={1}>
            <NoSubmitDatePicker
              label="Date paid"
              data-test="date-paid"
              readOnly={!isEditing}
              value={paymentDate}
              onSubmit={setPaymentDate}
            />
          </LeafCell>
        </>
      )}

      {isEditing && (
        <>
          {readyToMakePayment && <LeafCell width={1} />}

          <LeafCell width={1}>
            <ExtendedMutation
              mutation={SET_ADVANCE_DRAWDOWN_PAYMENT_DATE_REQUEST}
              awaitRefetchQueries
              refetchQueries={refetchQueries}
            >
              {(SetAdvanceDrawdownPaymentDate: MutationFunction<*>) => {
                const reallySetPaymentDate = async (value) => {
                  const result = await SetAdvanceDrawdownPaymentDate({
                    variables: {
                      input: {
                        ...pick(
                          [
                            "requestedDrawdownDate",
                            "requestMadeDate",
                            "feeAdjustmentAmount",
                            "feeAdjustmentNotes",
                            "datePaymentMade",
                            "id",
                          ],
                          advanceDrawdownRequest,
                        ),
                        datePaymentMade: value,
                        advanceOfferId,
                      },
                    },
                  });

                  if (result) {
                    setIsEditing(false);
                  }
                };

                const update = () => {
                  if (readyToMakePayment) {
                    reallySetPaymentDate(paymentDate);
                  } else {
                    reallySetPaymentDate(null);
                    setPaymentDate(null);
                  }
                };

                return (
                  <SaveButton
                    onClick={update}
                    disabled={!paymentDate}
                    data-test="advance-payment-save-button"
                  >
                    Save
                  </SaveButton>
                );
              }}
            </ExtendedMutation>
          </LeafCell>
          <LeafCell width={1}>
            <FullWidthCancelButton
              data-test="advance-payment-cancel-button"
              onClick={() => {
                setPaymentDate(datePaymentMade);
                if (paymentAlreadyMade) {
                  setReadyToMakePayment(true);
                } else {
                  setReadyToMakePayment(false);
                }
                setIsEditing(false);
              }}
            >
              Cancel
            </FullWidthCancelButton>
          </LeafCell>
        </>
      )}
    </Grid>
  );
};

// We set the display name so that we can still switch on the component name in prod builds
AdvancePayment.displayName = "AdvancePayment";
