/* eslint-disable no-nested-ternary */
// @flow
import { is } from "ramda";
import React from "react";
import styled, { css } from "styled-components";

import ReactSelect, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

import { TickBox } from "components/Checkbox";
import { Arrow, DarkArrow, Search } from "@nest-ui/icons";
import { theme } from "@nest-ui/styles";

const SelectedValue = styled.span`
  color: ${theme.color.text.default};
  line-height: 28px;
`;

const DarkSelectedValue = styled(SelectedValue)`
  color: ${theme.color.text.dark};
`;

const StyledTickBox = styled(TickBox)`
  top: 4px;
  margin-right: 8px;
`;

const TickBoxOption = (props) => (
  <div>
    <StyledTickBox checked={props.isSelected} /> {props.label}
  </div>
);

const MultiOption = (props) => (
  <components.Option {...props}>
    <TickBoxOption {...props} />
  </components.Option>
);

const LightSearch = () => <Search fill={theme.color.text.light} />;

export const customStyles = (
  dark: boolean,
  highlightRed: boolean,
  t: any = theme,
) => ({
  singleValue: (provided: any) => ({
    ...provided,
    color: `${dark ? t.color.text.dark : t.color.text.default}} !important`,
    fontSize: `${t.font.size.default}`,
  }),
  control: (provided: any, { isFocused }: { isFocused: boolean }) => ({
    ...provided,
    backgroundColor: dark ? t.color.secondary.dark : t.color.background.button,
    borderColor: highlightRed
      ? t.color.background.danger
      : dark
      ? t.color.secondary.dark
      : t.color.primary.dark,
    borderStyle: "solid",
    borderWidth: t.input.border.width,
    cursor: "text",
    flexDirection: "row-reverse",
    minHeight: null,
    height: t.input.height,
    paddingLeft: "0px",
    boxShadow: isFocused ? "none !important" : null,
    fontSize: `${t.font.size.default}`,
    borderRadius: 0,
    ":focus": {
      outline: "none",
    },
    ":hover": {
      borderColor: highlightRed
        ? t.color.background.danger
        : dark
        ? t.color.secondary.dark
        : t.color.primary.dark,
    },
  }),
  option: (
    provided: any,
    {
      isFocused,
      isSelected,
      isDisabled,
    }: { isFocused: boolean, isSelected: boolean, isDisabled: boolean },
  ) => ({
    ...provided,
    backgroundColor: isDisabled
      ? t.color.background.disabled
      : isFocused
      ? `${dark ? t.color.text.dark : t.color.background.highlight}`
      : isSelected
      ? `${dark ? t.color.secondary.dark : "white"}`
      : `${dark ? t.color.secondary.dark : ""}`,
    color: isDisabled
      ? `${t.color.text.disabled} !important`
      : isFocused
      ? `${dark ? t.color.primary.dark : "default"} !important`
      : `${dark ? t.color.text.dark : t.color.text.default} !important`,
    cursor: isDisabled ? "not-allowed" : "pointer",
    fontWeight: isSelected
      ? `${dark ? t.font.weight.regular : t.font.weight.semibold}`
      : null,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: t.input.height,
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "8px",
    lineHeight: t.input.height,
    borderRadius: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    border: `2px solid ${dark ? t.color.secondary.dark : t.color.primary.dark}`,
    borderTop: 0,
    maxHeight: "180px",
    padding: "0px",
    borderBottomLeftRadius: t.input.border.radius,
    borderBottomRightRadius: t.input.border.radius,
  }),
  menu: (provided: any) => ({
    ...provided,
    border: "none",
    outline: "none",
    boxShadow: `${t.input.border.shadow} !important`,
    zIndex: 2, // so the menu is over the slider knob
    margin: 0,
    fontSize: `${t.font.size.default}`,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: "0px",
    color: dark ? t.color.text.dark : t.color.primary.dark,
    width: "15px",
  }),
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }),
  input: (provided: any) => ({
    ...provided,
    color: dark ? t.color.text.light : t.color.text.default,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    ":after": {
      content: '", "',
      alignSelf: "center",
    },
    ":last-of-type:after": {
      content: '" "',
      alignSelf: "center",
    },
  }),
  multiValueRemove: () => ({ display: "none" }),
});

const SelectContainer = styled.div`
  position: relative;

  .react-select-wrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
  }

  .react-select__indicators {
    padding-left: 8px;
  }

  .react-select__option:hover,
  .react-select__option--is-focused {
    color: ${({ theme: t }) => t.color.text.default};
  }
`;

/**
 * This is necessary to close the dropdown when clicking on the select.  Without
 * this, if you open the dropdown and click on the select again, select briefly
 * closes and then re-opens. This is because on mouse down blur even is
 * triggered, which closes the menu, but on mouse up onClick is triggered, which
 * re-opens the menu making it impossible to close the options menu by clicking
 * on the select box.
 */

const Blanket = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

const DropdownTarget = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding: 4px 8px;
  text-align: left;
  user-select: none;
  width: 100%;

  & > span {
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ dark, isOpen, highlightRed, theme: t }) => css`
    background-color: ${dark
      ? t.color.secondary.dark
      : t.color.background.button};
    border-style: solid;
    border-width: ${t.input.border.width};
    border-color: ${() => {
      if (highlightRed) {
        return t.color.background.danger;
      }
      if (dark && isOpen) {
        return t.color.secondary.dark;
      }
      if (dark && !isOpen) {
        return t.color.secondary.dark;
      }
      if (!dark && isOpen) {
        return t.color.primary.dark;
      }

      // !dark && !isOpen
      return t.color.secondary.highlight;
    }};
    border-bottom-left-radius: ${isOpen ? 0 : t.input.border.radius};
    border-bottom-right-radius: ${isOpen ? 0 : t.input.border.radius};
    border-top-left-radius: ${t.input.border.radius};
    border-top-right-radius: ${t.input.border.radius};
    color: ${dark ? t.color.text.dark : t.color.text.default};

    &:focus {
      border-color: ${dark ? t.color.secondary.dark : t.color.primary.dark};
      outline: none;
    }

    &:hover {
      border-color: ${isOpen
        ? dark
          ? `rgb(179, 179, 179)`
          : "initial"
        : dark
        ? `rgb(179, 179, 179)`
        : `rgb(179, 179, 179)`};
    }
  `}
`;

const DropdownTargetLabel = ({ children }) => {
  if (Array.isArray(children) && children.length > 0) {
    return (
      <span>
        {children.map((v, index) => {
          const label = is(Object, v) ? v.label : v;

          return children.length - 1 === index ? `${label}` : `${label}, `;
        })}
      </span>
    );
  }

  if (typeof children === "string") {
    return <span>{children}</span>;
  }

  if (children && typeof children.label === "string") {
    return <span>{children.label}</span>;
  }

  return <span>Select...</span>;
};

type Props = {|
  clearable?: boolean,
  dark?: boolean,
  highlightRed?: boolean,
  multi?: boolean,
  onChange: (option: { label: string, value: any }) => void,
  options: $ReadOnlyArray<Choice>,
  searchable?: boolean,
  value: ?{ label: string, value: any },
  onCreateOption?: (string) => void,
  placeholder?: string,
|};

type State = {
  isOpen: boolean,
};

export class Select extends React.Component<Props, State> {
  state = { isOpen: false };

  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  /**
   * $FlowFixMe is here because ReactSelect doesn't like "value" being an object. It
   * is right. We shouldn't allow objects to be used as values. Select component
   * needs an update to get away from this pattern. At some point this will
   * hopefully become a big enough of a pain to fix.
   */
  // $FlowFixMe
  onSelectChange = (value: { label: string, value: any }) => {
    if (!this.props.multi) {
      this.toggleOpen();
    }
    this.props.onChange(value);
  };

  render() {
    const {
      clearable,
      dark = false,
      highlightRed = false,
      multi,
      onChange,
      searchable,
      value,
      placeholder,
      ...rest
    } = this.props;
    const { isOpen } = this.state;
    const DropdownIcon = dark ? DarkArrow : Arrow;
    const Component = rest.onCreateOption ? CreatableSelect : ReactSelect;

    return (
      <SelectContainer>
        <DropdownTarget
          dark={dark}
          highlightRed={highlightRed}
          isOpen={isOpen}
          onClick={this.toggleOpen}
        >
          <DropdownTargetLabel>{value || placeholder}</DropdownTargetLabel>
          <DropdownIcon isOpen={isOpen} />
        </DropdownTarget>

        {isOpen && (
          <>
            <Component
              autoFocus
              blurInputOnSelect={false}
              className="react-select-wrapper"
              classNamePrefix="react-select"
              closeMenuOnSelect={!multi}
              components={{
                ClearIndicator: null,
                // $FlowFixMe this is because of a `fill` attribute on an svg
                DropdownIndicator: dark ? LightSearch : Search,
                MultiValueLabel: dark ? DarkSelectedValue : SelectedValue,
                Option: multi ? MultiOption : components.Option,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={clearable}
              isMulti={multi}
              isSearchable={searchable}
              menuIsOpen
              onBlur={this.toggleOpen}
              onChange={this.onSelectChange}
              placeholder="Search..."
              styles={customStyles(dark, false)}
              value={value}
              {...rest}
            />
            <Blanket />
          </>
        )}
      </SelectContainer>
    );
  }
}
