// @flow
import { gql } from "@apollo/client";
import moment from "moment-timezone";
import React from "react";
import { type MutationFunction } from "@apollo/client/react/components";
import styled from "styled-components";

import { Card } from "components/Card";
import { NoSubmitDatePicker } from "components/DatePicker";
import { EphemeralStateModal } from "components/Modal/EphemeralStateModal";
import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { SelectField, NoSubmitSelectField } from "components/SelectField";
import { Switch } from "components/Switch";
import { Warning } from "components/Warning";
import { InfoIcon } from "@nest-ui/icons";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { theme as themeObject } from "@nest-ui/styles";

const COVER_SA = gql`
  query CoverSAQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      vendorCoverSaExpiryDate
      coverSaUser {
        id
      }
    }

    activeNestedUsers {
      value: id
      label: fullName
      closeUserId
    }
  }
`;

const UPDATE_COVER_SA = gql`
  mutation UpdateCoverSa($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      vendorCoverSaExpiryDate
      coverSaUser {
        id
      }
    }
  }
`;

const SwitchCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
`;

const CoverIsOnCard = styled(Card)`
  align-items: center;
  background-color: ${({ theme }) => theme.color.text.highlight};
  color: ${({ theme }) => theme.color.text.light};
  display: flex;
  font-family: Euclid;
  margin: 16px 0 0 0;
  padding: 8px;

  svg {
    height: 16px;
    margin-right: 8px;
  }
`;

const LeafCellWithSmallerDropdown = styled(LeafCell)`
  .Select-menu-outer {
    .Select-menu {
      max-height: 120px;
    }
  }
`;

type State = {
  isModalOpen: boolean,
  vendorCoverSaExpiryDate: ?Date,
  assignedCoverSaId: ?string,
};

export class CoverSA extends React.Component<{ parentId: string }, State> {
  state = {
    isModalOpen: false,
    vendorCoverSaExpiryDate: null,
    assignedCoverSaId: null,
  };

  closeModal = () => this.setState({ isModalOpen: false });

  openModal = () =>
    this.setState({ isModalOpen: true, assignedCoverSaId: null });

  setVendorCoverSaId = (value: string) => {
    this.setState({
      assignedCoverSaId: value,
    });
  };

  setVendorCoverSaExpiryDate = (value: Date) => {
    this.setState({
      vendorCoverSaExpiryDate: value,
    });
  };

  render() {
    const { parentId: dealId } = this.props;
    const { isModalOpen, vendorCoverSaExpiryDate, assignedCoverSaId } =
      this.state;

    return (
      <ExtendedQuery query={COVER_SA} variables={{ dealId }}>
        {({
          activeNestedUsers,
          nestDeal,
        }: ExtendedQueryRenderProps<CoverSAQuery>) => (
          <ExtendedMutation mutation={UPDATE_COVER_SA}>
            {(
              updateCoverSaMutation: MutationFunction<
                UpdateCoverSa,
                UpdateCoverSaVariables,
              >,
              { loading },
            ) => {
              const updateCoverSa = (input, withOptimisticResponse = true) => {
                const optimisticResponse = {
                  __typename: "Mutation",
                  updateNestDeal: {
                    ...nestDeal,
                    ...input,
                    coverSaUser: {
                      __typename: "NestedUser",
                      id: input.assignedCoverSaId || nestDeal?.coverSaUser?.id,
                    },
                  },
                };

                let config = {
                  variables: {
                    id: nestDeal.id,
                    input,
                  },
                };

                // Optimistic response is not required when user is in a modal
                if (withOptimisticResponse) {
                  config = { ...config, optimisticResponse };
                }

                return updateCoverSaMutation(config);
              };

              const resetCoverSa = () =>
                updateCoverSa({
                  assignedCoverSaId: null,
                });

              const handleSubmit = async () => {
                await updateCoverSa(
                  { assignedCoverSaId, vendorCoverSaExpiryDate },
                  false,
                );
                this.closeModal();
              };

              const isCoverOn = nestDeal.coverSaUser !== null;

              return (
                <Card>
                  <H2>SA Cover</H2>
                  <Grid columns={2}>
                    <LeafCell width={1}>
                      <SelectField
                        data-test="cover-sa:vendor-cover-sa-id"
                        disabled
                        label="Cover SA"
                        mutation={updateCoverSa}
                        nullable={false}
                        options={activeNestedUsers}
                        property="assignedCoverSaId"
                        value={nestDeal.coverSaUser?.id}
                      />
                    </LeafCell>
                    <LeafCell width={1}>
                      <SwitchCell>
                        Is cover currently needed?
                        <Switch
                          data-test="cover-sa:is-cover-needed-switch"
                          onSubmit={isCoverOn ? resetCoverSa : this.openModal}
                          value={isCoverOn}
                        />
                      </SwitchCell>
                    </LeafCell>
                  </Grid>
                  {isCoverOn && (
                    <CoverIsOnCard data-test="cover-sa:cover-sa-is-active-card">
                      <InfoIcon fill={themeObject.color.text.light} />
                      SA Cover is currently active until{" "}
                      {moment(nestDeal.vendorCoverSaExpiryDate).format(
                        "ddd D MMM Y",
                      )}
                    </CoverIsOnCard>
                  )}
                  <EphemeralStateModal
                    isOpen={isModalOpen}
                    title="Select Cover SA"
                    closeModal={this.closeModal}
                    onButtonClick={handleSubmit}
                    buttonLabel="Add"
                    buttonEnabled={
                      Boolean(assignedCoverSaId) &&
                      Boolean(vendorCoverSaExpiryDate)
                    }
                    data-test="select-cover-sa-modal"
                    isSubmitting={loading}
                  >
                    <>
                      <Grid columns={1}>
                        <LeafCellWithSmallerDropdown width={1}>
                          <NoSubmitSelectField
                            data-test="cover-sa:vendor-cover-sa-select"
                            label="Cover SA"
                            nullable={false}
                            options={activeNestedUsers}
                            onSubmit={this.setVendorCoverSaId}
                            value={assignedCoverSaId}
                            searchable
                          />
                        </LeafCellWithSmallerDropdown>

                        <LeafCellWithSmallerDropdown width={1}>
                          <NoSubmitDatePicker
                            data-test="cover-sa:vendor-cover-sa-expiry-date"
                            label="Date to revert to Lead SA"
                            onSubmit={this.setVendorCoverSaExpiryDate}
                            property="vendorCoverSaExpiryDate"
                            value={vendorCoverSaExpiryDate}
                          />
                        </LeafCellWithSmallerDropdown>
                      </Grid>
                      <Warning>
                        <p>
                          New enquiries on this property + all existing B01
                          enquiries will be assigned to the Cover SA. The Lead
                          SA should arrange for someone to take care of B02+
                          enquiries, usually the RM in the area.
                        </p>
                        <p>
                          Once the cover period is over, any B01 enquiries will
                          be reassigned to the Lead SA, but any B02+ enquiries
                          will remain with the Cover SA for them to convert to
                          an offer. You can manually change the SA assigned to
                          the enquiry in the Buyers Nest if you like.
                        </p>
                      </Warning>
                    </>
                  </EphemeralStateModal>
                </Card>
              );
            }}
          </ExtendedMutation>
        )}
      </ExtendedQuery>
    );
  }
}
