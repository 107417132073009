// @flow
import { LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import { updateDealTypeUnderwritingConfig } from "./mutations/updateDealTypeUnderwritingConfig";
import UpdateDealTypeUnderwritingMutation from "./mutations/UpdateDealTypeUnderwritingMutation.graphql";

type Props = {|
  nestDeal: {
    dealTypeDetails: DealTypeUnderwritingGlobalFragment,
  },
  updateDealTypeUnderwriting: () => Promise<UpdateDealTypeUnderwriting>,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
|};

export const UnderwritingComponent = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeUnderwriting,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            mutation={updateDealTypeUnderwriting}
            readOnly={readOnly}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            mutation={updateDealTypeUnderwriting}
            readOnly={readOnly}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}
  </>
);

export const Underwriting = compose(
  graphql(UpdateDealTypeUnderwritingMutation, updateDealTypeUnderwritingConfig),
)(UnderwritingComponent);
