// @flow
import { useState } from "react";
import {
  EmailPreviewBasicLayout,
  Paragraph,
} from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/MailoutPreview";
import { EmailPreviewPropertyCard } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/EmailPreviewPropertyCard";

const EmailPreview = ({ firstName, body, senderName, properties }) => {
  return (
    <div>
      <Paragraph>Hi {firstName},</Paragraph>
      <Paragraph data-test="email-body-preview">{body}</Paragraph>
      <Paragraph>Thanks,</Paragraph>
      <Paragraph>{senderName} @ Nested</Paragraph>
      {properties &&
        properties.map((property) => (
          <EmailPreviewPropertyCard
            withKeyFeatures={false}
            propertyDetails={property}
            key={`email-preview-property-card-${property.externalId}`}
          />
        ))}
    </div>
  );
};

type Props = {
  body: string,
  noTabs?: boolean,
  firstName: string,
  properties?: Array<Object>,
  senderName: string,
  smsText?: string,
};

export const Preview = ({
  body,
  noTabs,
  firstName,
  properties,
  senderName,
  smsText,
}: Props) => {
  const [showEmailPreview, setShowEmailPreview] = useState(true);

  return (
    <EmailPreviewBasicLayout
      css="width:100%; min-height: calc(100vh - 144px);"
      showEmailPreview={showEmailPreview}
      setShowEmailPreview={setShowEmailPreview}
      noTabs={noTabs}
    >
      {showEmailPreview ? (
        <EmailPreview
          firstName={firstName}
          body={body}
          senderName={senderName}
          properties={properties}
        />
      ) : (
        <Paragraph css="white-space: pre-line">{smsText}</Paragraph>
      )}
    </EmailPreviewBasicLayout>
  );
};
