// @flow
import { createContext } from "react";
import { gql } from "@apollo/client";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { useQuery } from "@apollo/client/react/hooks";

export const COUNTERS_QUERY = gql`
  query listViewCountersQuery($email: String!) {
    bookVisitLeads(email: $email) {
      resultCount
    }
    closeDeals(email: $email) {
      resultCount
    }
    noTaskSetNurtureDeals(email: $email) {
      resultCount
    }
    allNurtureDeals(email: $email) {
      resultCount
    }
    toListDeals(email: $email) {
      resultCount
    }
    listedDeals(email: $email) {
      resultCount
    }
    underOfferDeals(email: $email) {
      resultCount
    }
    doPropertyAnalysisDeals(email: $email) {
      resultCount
    }
    completedDeals(email: $email) {
      resultCount
    }
    exchangedDeals(email: $email) {
      resultCount
    }
    enquiries(email: $email) {
      resultCount
    }
    upcomingEnquiries(email: $email) {
      resultCount
    }
    awaitingFeedback(email: $email) {
      resultCount
    }
    upcomingAwaitingFeedback(email: $email) {
      resultCount
    }
    negotiatingOffers(email: $email) {
      resultCount
    }
    upcomingNegotiatingOffers(email: $email) {
      resultCount
    }
    buyersWithOffers(email: $email) {
      resultCount
    }
  }
`;

type Props = {
  children: React$Node,
};

export type ListViewCounts = {
  bookVisit: number,
  valuation: number,
  close: number,
  allNurture: number,
  noTaskSetNurture: number,
  toList: number,
  listed: number,
  underOffer: number,
  exchanged: number,
  completed: number,
  enquiries: number,
  upcomingEnquiries: number,
  awaitingFeedback: number,
  upcomingAwaitingFeedback: number,
  negotiatingOffers: number,
  upcomingNegotiatingOffers: number,
  buyersWithOffers: number,
};

export const ListViewCountContext = createContext<ListViewCounts>({});

export const ListViewCountProvider = ({ children }: Props) => {
  const { selectedUser } = useUser();
  const { data } = useQuery(COUNTERS_QUERY, {
    variables: {
      email: selectedUser.email,
    },
    pollInterval: process.env.NODE_ENV === "production" ? 10_000 : 0,
  });

  return (
    <ListViewCountContext.Provider
      value={{
        bookVisit: data?.bookVisitLeads?.resultCount || 0,
        close: data?.closeDeals?.resultCount || 0,
        allNurture: data?.allNurtureDeals?.resultCount || 0,
        noTaskSetNurture: data?.noTaskSetNurtureDeals?.resultCount || 0,
        valuation: data?.doPropertyAnalysisDeals?.resultCount || 0,
        toList: data?.toListDeals?.resultCount || 0,
        listed: data?.listedDeals?.resultCount || 0,
        underOffer: data?.underOfferDeals?.resultCount || 0,
        completed: data?.completedDeals?.resultCount || 0,
        exchanged: data?.exchangedDeals?.resultCount || 0,
        enquiries: data?.enquiries?.resultCount || 0,
        upcomingEnquiries: data?.upcomingEnquiries?.resultCount || 0,
        awaitingFeedback: data?.awaitingFeedback?.resultCount || 0,
        upcomingAwaitingFeedback:
          data?.upcomingAwaitingFeedback?.resultCount || 0,
        negotiatingOffers: data?.negotiatingOffers?.resultCount || 0,
        upcomingNegotiatingOffers:
          data?.upcomingNegotiatingOffers?.resultCount || 0,
        buyersWithOffers: data?.buyersWithOffers?.resultCount || 0,
      }}
    >
      {children}
    </ListViewCountContext.Provider>
  );
};
