// @flow
import { createContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { gql, useQuery, useSubscription, useMutation } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { usePresence } from "../../hooks/usePresence";
import { useUser } from "../../hooks/useUser";
import { type ContactWithPhones } from "../Navigation/ContactDrawer/ContactDrawerShared";

export type SmsTemplate = {
  label: string,
  content: string,
};

type ContextProps = {
  isOpen: boolean,
  onClose(): void,
  openMobileActionSheet({
    id: string,
    relationType: string,
    commsType: string,
    smsBody?: string,
    initiatedByHandoff: boolean,
    selectedUser: string,
    smsTemplateOptions?: ?(SmsTemplate[]),
    contacts?: ?$ReadOnlyArray<ContactWithPhones>,
  }): void,
  onlyPhoneContact?: ?ContactWithPhones,
  relationType?: string,
  relationId?: string,
  contacts: $ReadOnlyArray<ContactWithPhones>,
  commsType?: string,
  loadingContacts: boolean,
  errorFetchingContacts: ?Error,
  smsBody?: ?string,
  selectedUser?: string,
  smsTemplateOptions?: ?(SmsTemplate[]),
};

export const MobileActionSheetContext = createContext<ContextProps>({});

// This subscription is triggered when the call button is pressed, and causes
// the action sheet to appear on any connected mobile devices for the user.
const MOBILE_SUBSCRIPTION = gql`
  subscription MobileActionSheetSubscription {
    contactsSentToPhone {
      relationType
      id
      commsType
      selectedUser
      smsBody
      smsTemplateOptions {
        label
        content
      }
    }
  }
`;

// This subscription is triggered when a call or SMS is attempted, and causes
// the handoff modal to be closed on any connected desktop devices for the user.
const DESKTOP_SUBSCRIPTION = gql`
  subscription ContactAttempted($email: String!) {
    contactAttempted(email: $email) {
      id
      nestedUserEmail
    }
  }
`;

export const CLOSE_MOBILE_ACTION_SHEET_SUBSCRIPTION = gql`
  subscription MobileActionSheetClosed {
    mobileActionSheetClosed {
      email
    }
  }
`;

export const DEAL_QUERY = gql`
  query MobileActionSheetDealQuery($id: ID!) {
    dealOrBuyer: nestDeal(id: $id) {
      id
      contacts {
        id
        avatarUrl
        name
        title
        phones {
          id
          telephoneNumber
        }
        emails {
          id
          emailAddress
        }
      }
    }
  }
`;

export const BUYER_QUERY = gql`
  query MobileActionSheetBuyerQuery($id: ID!) {
    dealOrBuyer: buyer(id: $id) {
      id
      contacts {
        id
        avatarUrl
        name
        title
        phones {
          id
          telephoneNumber
        }
        emails {
          id
          emailAddress
        }
      }
    }
  }
`;

export const SEND_COMMS_TO_PHONE = gql`
  mutation SendContactsToPhone(
    $id: ID!
    $relationType: String!
    $commsType: String!
    $smsBody: String
    $selectedUser: String
    $smsTemplateOptions: [SmsTemplateOptionsInput!]
  ) {
    sendContactsToPhone(
      id: $id
      relationType: $relationType
      commsType: $commsType
      smsBody: $smsBody
      selectedUser: $selectedUser
      smsTemplateOptions: $smsTemplateOptions
    ) {
      id
    }
  }
`;

export const CLOSE_MOBILE_ACTION_SHEET_MUTATION = gql`
  mutation CloseActionSheet {
    closeMobileActionSheet {
      email
    }
  }
`;

export const isMobile = (userAgent: string) =>
  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

type ProviderProps = {
  children: React$Node,
};

export const MobileActionSheetProvider = ({ children }: ProviderProps) => {
  const [sendToPhoneMutation] = useMutation(SEND_COMMS_TO_PHONE);
  const [closeMobileActionSheetMutation] = useMutation(
    CLOSE_MOBILE_ACTION_SHEET_MUTATION,
  );
  const { mobilePresent } = usePresence();
  const [relationType, setRelationType] = useState();
  const [relationId, setRelationId] = useState();
  const [commsType, setCommsType] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [handoffOpen, setHandoffOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [smsBody, setSmsBody] = useState(null);
  const [smsTemplateOptions, setSmsTemplateOptions] = useState(null);
  const [initiatedByHandoff, setInitiatedByHandoff] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [justClosedMobileActionSheet, setJustClosedMobileActionSheet] =
    useState(false);
  const { selectedUser: contextSelectedUser } = useUser();

  const { data: mobileSubscriptionData } = useSubscription(MOBILE_SUBSCRIPTION);
  const { data: desktopSubscriptionData } = useSubscription(
    DESKTOP_SUBSCRIPTION,
    {
      variables: { email: contextSelectedUser.email },
    },
  );
  const { data: closingMobileActionSheetData } = useSubscription(
    CLOSE_MOBILE_ACTION_SHEET_SUBSCRIPTION,
  );

  const { data, loading, error } = useQuery(
    relationType === "deal" ? DEAL_QUERY : BUYER_QUERY,
    {
      variables: {
        id: relationId,
      },
      skip:
        !relationId ||
        !relationType ||
        !mobilePresent ||
        !drawerOpen ||
        !initiatedByHandoff,
    },
  );

  useEffect(() => {
    setContacts(data?.dealOrBuyer?.contacts);
  }, [data]);

  const sendContactsToPhone = async () => {
    try {
      await sendToPhoneMutation({
        variables: {
          id: relationId,
          relationType,
          commsType,
          smsBody,
          selectedUser,
          smsTemplateOptions,
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const openMobileActionSheet = (input) => {
    setCommsType(input.commsType);
    setRelationId(input.id);
    setRelationType(input.relationType);
    setSmsBody(input.smsBody);
    setInitiatedByHandoff(input.initiatedByHandoff ?? true);
    setSelectedUser(input.selectedUser);
    setSmsTemplateOptions(input.smsTemplateOptions);
    if (!isMobile(navigator.userAgent)) {
      setHandoffOpen(true);
    } else {
      if (!data?.dealOrBuyer?.contacts) {
        setContacts(input.contacts || []);
      }
      setDrawerOpen(true);
    }
  };

  // We're sending a 'heartbeat' through from desktop to mobile while the desktop handoff modal is open.
  // If the user closes the mobile action sheet, this triggers a mutation which desktop is subscribed to.
  // When desktop receives an update to this subscription, the desktop handoff is closed. When that happens,
  // this useEffect block will clear out this interval. We're doing this because it seems on mobile, users
  // would sometimes not see the action sheet appearing at all, because the desktop->mobile contact data
  // was sent out by desktop before mobile was even subscribed, so that data was never received by mobile.
  // This approach seeks to avoid that problem.
  useEffect(() => {
    if (!handoffOpen) return () => {};

    const sendInterval = setInterval(() => {
      if (handoffOpen) {
        sendContactsToPhone();
      } else {
        clearInterval(sendInterval);
      }
    }, 1000);

    return () => {
      clearInterval(sendInterval);
    };
  }, [handoffOpen]);

  useEffect(() => {
    if (
      !mobileSubscriptionData?.contactsSentToPhone ||
      !isMobile(navigator.userAgent)
    ) {
      return;
    }

    const { contactsSentToPhone } = mobileSubscriptionData;
    if (!justClosedMobileActionSheet)
      openMobileActionSheet(contactsSentToPhone);
  }, [mobileSubscriptionData]);

  useEffect(() => {
    if (
      !desktopSubscriptionData?.contactAttempted ||
      isMobile(navigator.userAgent)
    ) {
      return;
    }
    setHandoffOpen(false);
  }, [desktopSubscriptionData]);

  // Usually a second or two between when user closes mobile action sheet, and when desktop actually picks up
  // on this change. If we don't have this timeout, then as soon as user closes action sheet, then the action
  // sheet would immediately reappear due to the 'heartbeat' explained above. By adding this grace period, we
  // wait to try to ensure desktop has stopped calling sendContactsToPhone, and then after that, we re-allow
  // mobile to display action sheets.
  useEffect(() => {
    let justClosedTimeout;
    if (justClosedMobileActionSheet) {
      justClosedTimeout = setTimeout(() => {
        setJustClosedMobileActionSheet(false);
      }, 3000);
    }
    return () => clearTimeout(justClosedTimeout);
  }, [justClosedMobileActionSheet]);

  useEffect(() => {
    if (
      !closingMobileActionSheetData?.mobileActionSheetClosed?.email ||
      isMobile(navigator.userAgent)
    ) {
      return;
    }

    setHandoffOpen(false);
  }, [closingMobileActionSheetData]);

  const availablePhones = isEmpty(contacts)
    ? []
    : contacts.flatMap(({ phones }) => phones);

  const onlyPhoneContact =
    initiatedByHandoff || availablePhones.length !== 1
      ? null
      : contacts.find(({ phones }) => phones.includes(availablePhones[0]));

  return (
    <MobileActionSheetContext.Provider
      value={{
        isOpen: drawerOpen,
        onClose: () => {
          setJustClosedMobileActionSheet(true);
          closeMobileActionSheetMutation();
          setDrawerOpen(false);
        },
        openMobileActionSheet,
        justClosedMobileActionSheet,
        relationType,
        relationId,
        contacts,
        commsType,
        loadingContacts: loading,
        errorFetchingContacts: error,
        onlyPhoneContact,
        smsBody,
        smsTemplateOptions,
        mobilePresent,
        handoffOpen,
        initiatedByHandoff,
        selectedUser,
        closeHandoff: () => setHandoffOpen(false),
      }}
    >
      {children}
    </MobileActionSheetContext.Provider>
  );
};
