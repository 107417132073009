// @flow
import { groupBy } from "lodash";
import moment from "moment";
import { fetchPropertyDetailsFromGCS } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/MailoutPreview";
import { Sentry } from "@nested/isomorphic-sentry";
import {
  formatPrice,
  PropertyTypeDescription,
  type PropertyTypeEnum,
} from "@nested/utils";

export const CUSTOM_SITUATION: "custom_situation" = "custom_situation";
export const DAY_ONE_ENQUIRY: "day_one_enquiry" = "day_one_enquiry";
export const DAY_TWO_ENQUIRY: "day_two_enquiry" = "day_two_enquiry";
export const DAY_THREE_ENQUIRY: "day_three_enquiry" = "day_three_enquiry";
export const DAY_ONE_FEEDBACK: "day_one_feedback" = "day_one_feedback";
export const DAY_TWO_FEEDBACK: "day_two_feedback" = "day_two_feedback";
export const SNOOZING: "snoozing" = "snoozing";

type SituationState =
  | typeof CUSTOM_SITUATION
  | typeof DAY_ONE_ENQUIRY
  | typeof DAY_TWO_ENQUIRY
  | typeof DAY_THREE_ENQUIRY
  | typeof DAY_ONE_FEEDBACK
  | typeof DAY_TWO_FEEDBACK
  | typeof SNOOZING;

const fetchPropertyDetails = async (properties) => {
  try {
    const propertyInfo = await Promise.all(
      properties.map((p) => fetchPropertyDetailsFromGCS(p.deal.externalId)),
    );

    if (propertyInfo) return propertyInfo;
  } catch (e) {
    Sentry.captureException(
      new Error("Nest failed to fetch property details from GCP"),
      properties,
    );
    // eslint-disable-next-line no-alert
    alert(
      "There was a problem fetching the property's details for this template. Please try again in a few minutes, or contact tech-support",
    );
    return null;
  }
  return null;
};

const formatPropertyType = (
  propertyType: PropertyTypeEnum,
  bedrooms: ?number,
) => {
  if (!bedrooms && propertyType === "FLAT") {
    return "studio flat";
  }
  if (!bedrooms) {
    return PropertyTypeDescription(propertyType);
  }
  if (bedrooms) {
    return `${bedrooms} bed ${PropertyTypeDescription(propertyType)}`;
  }
  return "property";
};

const getDayOneEnquiryPrefill = async (dayOneEnquiries) => {
  const properties = await fetchPropertyDetails(dayOneEnquiries);

  if (!properties) return getCustomSituationPrefill(); // if no properties found we raise an alert and default to custom message prefill

  if (dayOneEnquiries.length === 1) {
    const [
      {
        bedrooms,
        propertyType,
        price,
        address: { street, outcode },
      },
    ] = properties;

    const formattedPropertyType = formatPropertyType(propertyType, bedrooms);
    const subject = `Nested viewing request - ${street}, ${outcode}`;
    const body = `Thank you for your interest in the ${formattedPropertyType} we're selling in ${street}, ${outcode} at ${formatPrice(
      price,
    )}. \n\nTo book a viewing, could you let me know which days and times work for you? I've also just tried to call, so please feel free to email, call or SMS back - whichever is easier for you.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_ONE_ENQUIRY,
    };
  }
  if (dayOneEnquiries.length > 1) {
    const subject = `Viewing request for ${dayOneEnquiries.length} Nested properties`;
    const body = `Thank you for your interest in ${dayOneEnquiries.length} of the properties we're selling.\n\nTo book a viewing, could you let me know which days and times work for you? I've also just tried to call, so please feel free to email, call or SMS back - whichever is easier for you.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_ONE_ENQUIRY,
    };
  }

  return null;
};

const getDayTwoEnquiryPrefill = async (dayTwoEnquiries) => {
  const properties = await fetchPropertyDetails(dayTwoEnquiries);

  if (!properties) return getCustomSituationPrefill();

  if (dayTwoEnquiries.length === 1) {
    const [
      {
        bedrooms,
        propertyType,
        price,
        address: { street, outcode },
      },
    ] = properties;

    const formattedPropertyType = formatPropertyType(propertyType, bedrooms);
    const subject = `Re: Nested viewing request - ${street}, ${outcode}`;
    const body = `I'm following up on your interest in the ${formattedPropertyType} we're selling in ${street}, ${outcode} at ${formatPrice(
      price,
    )}.\n\nAre you still interested in arranging a viewing? If this week doesn't work for you, are there days next week that are better?\n\nI've also given you a call, so please feel free to call, email or SMS back - whichever is easier.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_TWO_ENQUIRY,
    };
  }

  if (dayTwoEnquiries.length > 1) {
    const subject = `Re: Viewing request for ${dayTwoEnquiries.length} Nested properties`;
    const body = `I'm following up on your interest in ${dayTwoEnquiries.length} of the properties we're selling.\n\nAre you still interested in arranging viewings? If this week doesn't work for you, are there days next week that are better?\n\nI've also given you a call, so please feel free to call, email or SMS back - whichever is easier.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_TWO_ENQUIRY,
    };
  }

  return null;
};

const getDayThreeEnquiryPrefill = async (dayThreeEnquiries) => {
  const properties = await fetchPropertyDetails(dayThreeEnquiries);

  if (!properties) return getCustomSituationPrefill();

  if (dayThreeEnquiries.length === 1) {
    const [
      {
        bedrooms,
        propertyType,
        price,
        address: { street, outcode },
      },
    ] = properties;

    const formattedPropertyType = formatPropertyType(propertyType, bedrooms);
    const subject = `Re: Nested viewing request - ${street}, ${outcode}`;
    const body = `I'm reaching out one last time to get in touch about your interest in the ${formattedPropertyType} we're selling in ${street}, ${outcode} at ${formatPrice(
      price,
    )}.\n\nAre you still interested in arranging a viewing? If this week doesn't work for you, are there days next week that are better?\n\nFeel free to call, email or SMS back. If I don't hear from you I'll assume you're not interested anymore, and remove you from my list for this property.\n\nGreat properties are coming to market all the time. Tell me about your dream home and I can keep you notified if I list anything matching your criteria.\n\n\u2022 What are you looking for in a property (for example: area, how many bedrooms, flat or house, outside space, close to station/schools)?\n\n\u2022 What's your maximum budget?\n\nIf it's easier to chat about any of this over the phone, please feel free to call.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_THREE_ENQUIRY,
    };
  }
  if (dayThreeEnquiries.length > 1) {
    return {
      subject: `Re: Viewing request for ${dayThreeEnquiries.length} Nested properties`,
      body: `I'm reaching out one last time to get in touch about your interest in ${dayThreeEnquiries.length} of the properties we're selling.\n\nAre you still interested in arranging a viewing? If this week doesn't work for you, are there days next week that are better?\n\nFeel free to call, email or SMS back. If I don't hear from you I'll assume you're not interested anymore, and remove you from my list for this property.\n\nGreat properties are coming to market all the time. Tell me about your dream home and I can keep you notified if I list anything matching your criteria.\n\n\u2022 What are you looking for in a property (for example: area, how many bedrooms, flat or house, outside space, close to station/schools)?\n\n\u2022 What's your maximum budget?\n\nIf it's easier to chat about any of this over the phone, please feel free to call.`,
      properties,
      situation: DAY_THREE_ENQUIRY,
    };
  }

  return null;
};

const getDayOneFeedbackPrefill = async (dayOneFeedback) => {
  const properties = await fetchPropertyDetails(dayOneFeedback);

  if (!properties) return getCustomSituationPrefill();

  if (dayOneFeedback.length === 1) {
    const [
      {
        address: { street, outcode },
      },
    ] = properties;
    const subject = `Nested viewing feedback - ${street}, ${outcode}`;
    const body = `It was good to meet you yesterday for your viewing in ${street}, ${outcode}. \n\nNow that you've had some time to reflect, I'd love to hear your thoughts on the property?\n\nI've also just tried to call, so please feel free to email, call or SMS back - whichever is easier for you.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_ONE_FEEDBACK,
    };
  }
  if (dayOneFeedback.length > 1) {
    const subject = `Viewing feedback for ${dayOneFeedback.length} Nested properties`;
    const body = `It was good to meet you for your ${dayOneFeedback.length} recent viewings of the properties we're selling.\n\nNow that you've had some time to reflect, I'd love to hear your thoughts on the properties?\n\nI've also just tried to call, so please feel free to email, call or SMS back - whichever is easier for you.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_ONE_FEEDBACK,
    };
  }

  return null;
};

const getDayTwoFeedbackPrefill = async (dayTwoFeedback) => {
  const properties = await fetchPropertyDetails(dayTwoFeedback);

  if (!properties) return getCustomSituationPrefill();

  if (dayTwoFeedback.length === 1) {
    const [
      {
        address: { street, outcode },
      },
    ] = properties;
    const subject = `Re: Nested viewing feedback - ${street}, ${outcode}`;
    const body = `I'm following up on your viewing in the ${street}, ${outcode} and would love to hear your thoughts on the property.\n\nEven if this isn't the one for you, your feedback will help us find something more suitable in future.\n\nI've also given you a call, so please feel free to call, email or SMS back - whichever is easier.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_TWO_FEEDBACK,
    };
  }
  if (dayTwoFeedback.length > 1) {
    const subject = `Re: Viewing feedback for ${dayTwoFeedback.length} Nested properties`;
    const body = `I'm following up on your ${dayTwoFeedback.length} recent viewings of the properties we're selling, and would love to hear your thoughts on them.\n\nEven if these aren't quite right for you, your feedback will help us find something more suitable in future.\n\nI've also given you a call, so please feel free to call, email or SMS back - whichever is easier.`;

    return {
      subject,
      body,
      properties,
      situation: DAY_TWO_FEEDBACK,
    };
  }

  return null;
};

const getCustomSituationPrefill = () => {
  return {
    subject: null,
    body: null,
    properties: [],
    situation: CUSTOM_SITUATION,
  };
};

const getSnoozedPrefill = (snoozedBpi) => {
  const formattedDate = moment(snoozedBpi.snooze?.snoozedUntil).format(
    "Do MMM",
  );
  return {
    subject: `I will reach out around ${formattedDate}`,
    body: `Just a note from Nested to let you know I've set myself a reminder to get back in touch on or around ${formattedDate} to check in.\n\nIf you'd like to chat before then about your property search,  just let me know - happy to help wherever I can! \n\nSpeak soon,`,
    properties: [],
    situation: SNOOZING,
  };
};

type GroupedEnquiriesProps = {
  dayOneEnquiries: Array<BuyerSummary_buyer_buyerPropertyInterests>,
  dayTwoEnquiries: Array<BuyerSummary_buyer_buyerPropertyInterests>,
  dayThreeEnquiries: Array<BuyerSummary_buyer_buyerPropertyInterests>,
};

type GroupedFeedbackProps = {
  dayOneFeedback: Array<BuyerSummary_buyer_buyerPropertyInterests>,
  dayTwoFeedback: Array<BuyerSummary_buyer_buyerPropertyInterests>,
};
type PrefillType = {
  body?: string,
  subject?: string,
  properties?: Array<any>,
  situation?: SituationState,
};

export const getPrefills = async (
  buyerEnquiries: GroupedEnquiriesProps,
  buyerFeedback: GroupedFeedbackProps,
  situation: SituationState,
): PrefillType => {
  const { dayOneEnquiries, dayTwoEnquiries, dayThreeEnquiries } =
    buyerEnquiries;
  const { dayOneFeedback, dayTwoFeedback } = buyerFeedback;

  switch (situation) {
    case DAY_ONE_ENQUIRY:
      return getDayOneEnquiryPrefill(dayOneEnquiries);
    case DAY_TWO_ENQUIRY:
      return getDayTwoEnquiryPrefill(dayTwoEnquiries);
    case DAY_THREE_ENQUIRY:
      return getDayThreeEnquiryPrefill(dayThreeEnquiries);
    case DAY_ONE_FEEDBACK:
      return getDayOneFeedbackPrefill(dayOneFeedback);
    case DAY_TWO_FEEDBACK:
      return getDayTwoFeedbackPrefill(dayTwoFeedback);
    default:
      return getCustomSituationPrefill();
  }
};

export const getSuggestedPrefills = async (
  buyerEnquiries: GroupedEnquiriesProps,
  buyerFeedback: GroupedFeedbackProps,
  workflow: ?string,
  snoozedBpi: ?BuyerSummary_buyer_buyerPropertyInterests,
): PrefillType => {
  if (snoozedBpi) {
    return getSnoozedPrefill(snoozedBpi);
  }

  if (workflow === "enquiries") {
    const { dayOneEnquiries, dayTwoEnquiries, dayThreeEnquiries } =
      buyerEnquiries;

    if (dayThreeEnquiries) {
      return getDayThreeEnquiryPrefill(dayThreeEnquiries);
    }

    if (dayTwoEnquiries) {
      return getDayTwoEnquiryPrefill(dayTwoEnquiries);
    }

    if (dayOneEnquiries) {
      return getDayOneEnquiryPrefill(dayOneEnquiries);
    }
  }

  if (workflow === "feedback") {
    const { dayOneFeedback, dayTwoFeedback } = buyerFeedback;

    if (dayTwoFeedback) {
      return getDayTwoFeedbackPrefill(dayTwoFeedback);
    }

    if (dayOneFeedback) {
      return getDayOneFeedbackPrefill(dayOneFeedback);
    }
  }

  return getCustomSituationPrefill();
};

export const getSituationData = (
  buyer: BuyerSummary_buyer,
  todaysEnquiries: Array<BuyerSummary_buyer_buyerPropertyInterests>,
  todaysFeedback: Array<BuyerSummary_buyer_buyerPropertyInterests>,
  workflow: ?string,
) => {
  if (workflow !== "feedback" && workflow !== "enquiries") {
    return {
      validOptions: [{ label: "Custom situation", value: CUSTOM_SITUATION }],
      buyerEnquiries: {},
      buyerFeedback: {},
      snoozedBpi: null,
    };
  }

  const enquiries = buyer.buyerPropertyInterests.filter((bpi) =>
    todaysEnquiries.find((e) => bpi.id === e.id),
  );
  const feedback = buyer.buyerPropertyInterests.filter((bpi) =>
    todaysFeedback.find((f) => bpi.id === f.id),
  );

  // bpis for a buyer get snoozed at the same time, so if one of them is snoozed, we know that buyer is snoozed
  // and we need to render Snooze template.
  const snoozedBpi = buyer.buyerPropertyInterests.find((bpi) => bpi.snooze);

  if (snoozedBpi) {
    return {
      validOptions: [{ label: "Snoozing", value: SNOOZING }],
      buyerEnquiries: {},
      buyerFeedback: {},
      snoozedBpi,
    };
  }

  const {
    "1": dayOneEnquiries,
    "2": dayTwoEnquiries,
    "3": dayThreeEnquiries,
  } = groupBy(enquiries, (bpi) => bpi.postEnquiryContactSummary.dayNumber);

  const { "1": dayOneFeedback, "2": dayTwoFeedback } = groupBy(
    feedback,
    (bpi) => bpi.postViewingContactSummary.dayNumber,
  );

  const options = [
    { label: "Custom situation", value: CUSTOM_SITUATION },
    dayOneEnquiries && {
      label: "Day one enquiry",
      value: DAY_ONE_ENQUIRY,
    },
    dayTwoEnquiries && {
      label: "Day two enquiry",
      value: DAY_TWO_ENQUIRY,
    },
    dayThreeEnquiries && {
      label: "Day three enquiry",
      value: DAY_THREE_ENQUIRY,
    },
    dayOneFeedback && {
      label: "Day one feedback",
      value: DAY_ONE_FEEDBACK,
    },
    dayTwoFeedback && {
      label: "Day two feedback",
      value: DAY_TWO_FEEDBACK,
    },
  ];

  return {
    validOptions: options.filter(Boolean),
    buyerEnquiries: {
      dayOneEnquiries,
      dayTwoEnquiries,
      dayThreeEnquiries,
    },
    buyerFeedback: { dayOneFeedback, dayTwoFeedback },
    snoozedBpi: null,
  };
};
