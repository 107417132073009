// @flow

import { Cell } from "components/Grid";
import { SelectField } from "components/SelectField";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { type MutationFunction } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import type { ExtendedQueryRenderProps } from "@nested/utils/graphql/ExtendedQuery";

const NEST_DEAL_QUERY = gql`
  query NestDealQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      guaranteeNotes
      guaranteeCurrentStatus
    }
  }
`;

const UPDATE_NEST_DEAL_PAYMENTS = gql`
  mutation NestedProgressorUpdateNestDeal($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      guaranteeCurrentStatus
      guaranteeNotes
    }
  }
`;

const options = [
  { value: "Outstanding", label: "Outstanding" },
  { value: "Paid early", label: "Paid early" },
  { value: "Post 90 days", label: "Post 90 days" },
  { value: "Pending - unlikely", label: "Pending - unlikely" },
  { value: "Pending - likely", label: "Pending - likely" },
  { value: "Not paid", label: "Not paid" },
  { value: "10% paid early", label: "10% paid early" },
];

type Props = { parentId: string };

const NestedProgressorGuarantee = ({ parentId }: Props) => (
  <ExtendedQuery query={NEST_DEAL_QUERY} variables={{ id: parentId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<NestDealQuery>) => (
      <ExtendedMutation mutation={UPDATE_NEST_DEAL_PAYMENTS}>
        {(
          updateNestDeal: MutationFunction<
            NestedProgressorUpdateNestDeal,
            NestedProgressorUpdateNestDealVariables,
          >,
        ) => (
          <>
            <Cell width={1} left={1}>
              <SelectField
                label="Guarantee current status"
                multiple
                data-test="nested-progressor-guarantee-status"
                options={options}
                value={nestDeal.guaranteeCurrentStatus}
                property="guaranteeCurrentStatus"
                mutation={(input) => {
                  updateNestDeal({
                    variables: {
                      id: nestDeal.id,
                      input,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        ...nestDeal,
                        ...input,
                      },
                    },
                  });
                }}
              />
            </Cell>
            <Cell width={1}>
              <TextareaWithLinks
                label="Guarantee notes"
                property="guaranteeNotes"
                data-test="nested-progressor-guarantee-notes"
                value={nestDeal.guaranteeNotes}
                mutation={(input) => {
                  updateNestDeal({
                    variables: {
                      id: nestDeal.id,
                      input,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        __typename: "NestDeal",
                        ...nestDeal,
                        ...input,
                      },
                    },
                  });
                }}
              />
            </Cell>
          </>
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);

export { NestedProgressorGuarantee };
