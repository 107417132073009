// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const buttonStyles = css`
  box-sizing: border-box;
  background-color: white;
  border: 2px solid ${({ theme }) => theme.palette.hague20};
  padding: 0;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.hague70};
  cursor: pointer;

  &:disabled {
    color: ${({ theme }) => theme.palette.hague40};
  }

  &:hover:not(:disabled) {
    border: 2px solid ${({ theme }) => theme.palette.hague40};
  }
`;

type Props = { faIcon: any, disabled?: boolean };

export const IconButton = ({ faIcon, disabled = false, ...rest }: Props) => {
  return (
    <button {...rest} css={buttonStyles} disabled={disabled}>
      <FontAwesomeIcon icon={faIcon} />
    </button>
  );
};
