// @flow
import { useLocation } from "react-router-dom";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { Button } from "../Button/Button";
import { ModalHeader, Modal } from "../Modal";

const buttonStyle = css`
  width: calc(100% - 40px);
  margin: 20px;
  ${media.tablet`
    margin: 20px auto;
    width: unset;
    width: 310px;
  `}
`;

export const GET_USER_QUERY = gql`
  query GetHasUserAuthorisedNylas($email: String!) {
    nestedUserByEmail(email: $email) {
      id
      needsToAuthoriseNylas
    }
  }
`;

export const GET_NYLAS_AUTHORISATION_URL = gql`
  query GetNylasAuthorisationUrl {
    nylasAuthorisationUrl
  }
`;

export const AuthoriseNylasModal = () => {
  const location = useLocation();

  const { email } = useUser();
  const { loading, data } = useQuery(GET_USER_QUERY, {
    variables: { email },
  });

  const [getNylasAuthorisationUrl, { data: authorisationUrlData }] =
    useLazyQuery(GET_NYLAS_AUTHORISATION_URL, {
      onCompleted: () => {
        window.location.assign(authorisationUrlData.nylasAuthorisationUrl);
      },
      fetchPolicy: "no-cache",
    });

  if (
    loading ||
    // this means that a user is not in our database.
    !data?.nestedUserByEmail ||
    !data?.nestedUserByEmail.needsToAuthoriseNylas ||
    location.pathname === "/nylas-authorise-success"
  ) {
    return null;
  }

  return (
    <Modal open onClose={() => {}} showXButton={false}>
      <ModalHeader>Please sync your emails with the Nest</ModalHeader>
      <Button
        buttonStyle="outline"
        css={buttonStyle}
        onClick={() => {
          getNylasAuthorisationUrl();
        }}
        large
      >
        Sync email
      </Button>
    </Modal>
  );
};
