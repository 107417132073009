// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";

import { NoSubmitDatePicker } from "components/DatePicker";

export const SIMPLE_DD_QUERY = gql`
  query SimpleDDQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      vendorSolicitorInstructedDate
      ddProtocolFormsSent
      simpleDdEndDate
    }
  }
`;

export const SIMPLE_DD_MUTATION = gql`
  mutation UpdateSimpleDD($nestDeal: NestDealInput!, $dealId: ID!) {
    updateNestDeal(nestDeal: $nestDeal, id: $dealId) {
      id
      vendorSolicitorInstructedDate
      ddProtocolFormsSent
      simpleDdEndDate
    }
  }
`;

type Props = { dealId: string };
export const SimpleDD = ({ dealId }: Props) => (
  <ExtendedQuery query={SIMPLE_DD_QUERY} variables={{ dealId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<SimpleDDQuery>) => (
      <ExtendedMutation mutation={SIMPLE_DD_MUTATION}>
        {(
          updateNestDeal: MutationFunction<
            UpdateSimpleDD,
            UpdateSimpleDDVariables,
          >,
        ) => {
          const updateSimpleDD = async (input) => {
            await updateNestDeal({
              variables: { nestDeal: input, dealId },
              optimisticResponse: {
                __typename: "Mutation",
                updateNestDeal: {
                  __typename: "NestDeal",
                  id: dealId,
                  ...nestDeal,
                  ...input,
                },
              },
            });
          };

          return (
            <Grid columns={4}>
              <LeafCell width={1}>
                <NoSubmitDatePicker
                  label="Protocol forms sent"
                  value={nestDeal.ddProtocolFormsSent}
                  onSubmit={(value) =>
                    updateSimpleDD({ ddProtocolFormsSent: value })
                  }
                  data-test="protocol-forms-sent"
                />
              </LeafCell>

              <LeafCell width={1}>
                <NoSubmitDatePicker
                  label="Protocol forms returned"
                  value={nestDeal.vendorSolicitorInstructedDate}
                  onSubmit={(value) =>
                    updateSimpleDD({ vendorSolicitorInstructedDate: value })
                  }
                  data-test="protocol-forms-returned"
                />
              </LeafCell>

              <LeafCell width={1}>
                <NoSubmitDatePicker
                  label="Simple DD end date"
                  value={nestDeal.simpleDdEndDate}
                  onSubmit={(value) =>
                    updateSimpleDD({ simpleDdEndDate: value })
                  }
                  data-test="simple-dd-end-date"
                />
              </LeafCell>
            </Grid>
          );
        }}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
