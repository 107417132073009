// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";

const container = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.hague80};
  ${media.tablet`
    justify-content: unset;
  `}
`;

const labelStyle = css`
  font-size: ${({ large }) => (large ? "16px" : "14px")};
  font-weight: 500;
  margin: 0;
  padding-right: 10px;
  color: ${({ theme }) => theme.palette.hague100};
  ${media.tablet`
    color: ${({ theme }) => theme.palette.hague80};
    font-weight: 400;
  `}
`;

const switchStyle = css`
  width: 64px;
  height: 28px;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 34px;
  position: relative;
  cursor: pointer;

  input:checked + .slider {
    transform: translateX(32px);
    background-color: ${({ theme }) => theme.palette.green100};
    border: 2px solid ${({ theme }) => theme.palette.green150};
  }
`;

const input = css`
  display: none;
`;

const text = css`
  font-size: 12px;
  padding: 5px 7px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const slider = css`
  position: absolute;
  left: 4px;
  top: 2px;
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.palette.terracotta100};
  border: 2px solid ${({ theme }) => theme.palette.terracotta150};
  border-radius: 50%;
  transition: 0.4s;
`;

const textStyle = css`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

type Props = {
  checked: boolean,
  className?: string,
  labelText?: string,
  largeLabel?: boolean,
  onChange: () => any,
  titleOnHover?: ?string,
  "data-test"?: string,
};

export const Toggle = ({
  checked,
  className,
  labelText,
  largeLabel = false,
  onChange,
  titleOnHover,
  "data-test": dataTest = "toggle",
}: Props) => (
  <div css={container} className={className}>
    {labelText && (
      <p css={labelStyle} large={largeLabel}>
        {labelText}
      </p>
    )}
    <label css={switchStyle} data-test={dataTest}>
      <input
        type="checkbox"
        checked={Boolean(checked)}
        onChange={onChange}
        css={input}
        data-test={`${dataTest}-checkbox`}
      />
      <span className="slider" css={slider} />
      <span css={text} title={!checked ? titleOnHover : undefined}>
        <span css={textStyle} visible={checked}>
          Yes
        </span>
        <span css={textStyle} visible={!checked}>
          No
        </span>
      </span>
    </label>
  </div>
);
