// @flow
import styled from "styled-components";
import { H2 } from "@nest-ui/sellers-nest/components/Heading";

const Heading = styled(H2)`
  margin: 0;
  overflow: visible;
`;

const Container = styled.section`
  margin: 16px;
`;

type Props = {|
  enquiries: ?$ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_buyerEnquiries>,
|};

const getFirstCommentOrDefault = (enquiries) => {
  if (enquiries && enquiries.length > 0 && enquiries[0].additionalComments) {
    return enquiries[0].additionalComments;
  }
  return "No comments";
};

export const EnquiryComments = ({ enquiries }: Props) => (
  <Container>
    <Heading>Enquiry comments</Heading>
    <p>{getFirstCommentOrDefault(enquiries)}</p>
  </Container>
);
