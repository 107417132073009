// @flow
import T from "prop-types";

import { CurrencyField } from "components/CurrencyField";
import { Grid, LeafCell } from "components/Grid";
import { Section } from "components/DealDetailsBar";
import { TextField } from "components/TextField";
import { TextareaWithLinks } from "components/TextareaWithLinks";

const ReadOnlyContent = ({ nestDeal }: Props) => (
  <Grid columns={2}>
    <LeafCell>
      <TextField
        label="Owner 1"
        className="fs-exclude"
        value={nestDeal.ownerName}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <TextField
        label="Owner 2"
        className="fs-exclude"
        value={nestDeal.secondOwnerName}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <TextField label="Referral source" value={nestDeal.source} readOnly />
    </LeafCell>
    <LeafCell>
      <TextField
        label="Referral name (if applicable)"
        value={nestDeal.partnerReferral}
        readOnly
      />
    </LeafCell>
    <LeafCell width={2}>
      <TextareaWithLinks
        label="Referral notes (if applicable)"
        value={nestDeal.partnerDetails}
        readOnly
      />
    </LeafCell>

    <LeafCell width={1}>
      <TextField
        value={nestDeal.onboardingFormReasonForContact}
        label="Reason for getting in touch"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        label="Customer valuation"
        value={nestDeal.sellerValuation}
        readOnly
      />
    </LeafCell>
    <LeafCell width={2}>
      <TextField value={nestDeal.customerNeed} label="Customer need" readOnly />
    </LeafCell>
  </Grid>
);

ReadOnlyContent.propTypes = {
  nestDeal: T.object.isRequired,
};

type Props = {|
  nestDeal: VATabDealDetails_nestDeal,
|};

const CustomerInformation = (props: Props) => (
  <Section title="Customer" readOnlyContent={ReadOnlyContent} {...props} />
);

export { CustomerInformation };
