// @flow
import type { BrowserHistory } from "history/createBrowserHistory";

import { compose, withHandlers } from "recompose";
import { withRouter } from "react-router";

import { graphql } from "deal/utils/graphql";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";

import { CreateButton } from "components/CreateButton";
import createAgencyValuationMutation from "./createMutation/createAgencyValuation.graphql";
import { createConfig } from "./createMutation/config";

import agencyValuationsQuery from "./queries/agencyValuations.graphql";
import { agencyValuationsQueryConfig } from "./queries/config";

import { AgencyValuation } from "./AgencyValuation";
import { AgencyValuationModal } from "./AgencyValuationModal";

type ID = number | string;

type Valuation = {
  id: ID,
  expectedPrice: string,
  worstCaseValuation: string,
  reasonForUpdate: string,
  updatedByUserId: string,
  insertedAt: string,
};

type QueryResult = {
  data: {
    nestDeal: {
      id: ID,
      agencyValuations: [Valuation],
    },
    activeNestedUsers: $ReadOnlyArray<AgencyValuationList_activeNestedUsers>,
  },
};

type MutationResult = {
  data: {
    createAgencyValuation: {
      id: ID,
    },
  },
};

type Props = QueryResult & {
  createNewAgencyValuation: () => Promise<empty>,
};

const AgencyValuationListComponent = ({
  data: { nestDeal, activeNestedUsers },
  createNewAgencyValuation,
}: Props) => {
  const agencyValuations = nestDeal ? nestDeal.agencyValuations : [];

  return (
    <ErrorBoundary>
      <div data-test="agency-valuation-list">
        {agencyValuations.map(
          ({
            id,
            expectedPrice,
            worstCaseValuation,
            reasonForUpdate,
            updatedByUserId,
            insertedAt,
          }) => (
            <AgencyValuation
              dealId={nestDeal.id}
              id={id}
              key={id}
              expectedPrice={expectedPrice}
              worstCaseValuation={worstCaseValuation}
              reasonForUpdate={reasonForUpdate}
              updatedByUserId={updatedByUserId}
              insertedAt={insertedAt}
              activeNestedUsers={activeNestedUsers}
            />
          ),
        )}
        <CreateButton
          label="Add updated valuation"
          data-test="create-agency-valuation-button"
          onSubmit={createNewAgencyValuation}
        />
        <AgencyValuationModal activeNestedUsers={activeNestedUsers} />
      </div>
    </ErrorBoundary>
  );
};

type HandlerProps = {|
  createAgencyValuation: () => Promise<MutationResult>,
  history: BrowserHistory,
  match: { url: string },
|};

export function createNewAgencyValuationFun({
  createAgencyValuation,
  history,
  match,
}: HandlerProps) {
  return async function createNewAgencyValuationThenNavigate() {
    const {
      data: {
        createAgencyValuation: { id: agencyValuationId },
      },
    } = await createAgencyValuation();

    return history.push(
      `${match.url}/new-agency-valuation/${agencyValuationId}`,
    );
  };
}

const enhance = compose(
  withRouter,
  graphql(agencyValuationsQuery, agencyValuationsQueryConfig),
  graphql(createAgencyValuationMutation, createConfig),
  // $FlowFixMe - issue is in recompose types
  withHandlers({ createNewAgencyValuation: createNewAgencyValuationFun }),
);

export const AgencyValuationList = enhance(AgencyValuationListComponent);
