// @flow
import styled from "styled-components";

import { device } from "@nest-ui/styles";

export const LIGHT_BLUE = "hsl(212,100%,92%)";

export const Background = styled.div`
  background-color: ${LIGHT_BLUE};
  width: 100vw;
  height: 100vh;
`;

export const HeaderWrapper = styled.header`
  background-color: ${LIGHT_BLUE};
  position: fixed;
  height: 200px;
  width: 100%;
  z-index: 1;

  @media ${device.tablet} {
    height: 170px;
  }
`;

export const Header = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;

  @media ${device.tablet} {
    padding: 20px 50px;
  }

  & > div:first-child {
    height: 100px;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;

    img {
      width: auto;
      height: 60px;
    }

    p {
      margin: 0;
      color: #fff;
      text-align: center;
      font-size: 1.2em;
    }
  }
`;

export const HeaderLinks = styled.div`
  position: relative;
  top: 45px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.primary.dark};

  a {
    cursor: pointer;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 1.4em;
    color: inherit;
  }

  @media ${device.tablet} {
    color: #fff;
    top: -75px;
  }
`;

export const ContentWrapper = styled.section`
  width: 100%;
  position: absolute;
  top: 210px;
  bottom: 0;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${LIGHT_BLUE};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.secondary.dark};
  }

  @media ${device.tablet} {
    top: 170px;
  }
`;

export const Content = styled.section`
  margin: 0 auto;
  max-width: 1024px;
  padding: 10px 20px;

  @media ${device.tablet} {
    padding: 10px 50px;
  }
`;
