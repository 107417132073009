// @flow
import { ApolloProvider } from "@apollo/client";
import { Router } from "react-router";
import { ThemeProvider } from "styled-components";
import { UserProvider } from "@nested/nest/src/components/UserContext";
import { ListViewCountProvider } from "@nested/nest/src/components/ListViewCountContext";
import { TaskCounterProvider } from "@nested/nest/src/components/RightSidePanel/Tasks/TaskCounterContext";
import { WebsocketProvider } from "@nest-ui/providers/WebsocketProvider";

import { theme } from "@nest-ui/styles";
import { theme as rebrandTheme } from "@nested/brand";

type Props = {
  apolloClient: Object,
  children: Object,
  history: Object,
  socket: any,
  user: Object,
};

export function Providers({
  apolloClient,
  children,
  history,
  socket,
  user,
}: Props) {
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={rebrandTheme}>
          <UserProvider user={user}>
            <WebsocketProvider socket={socket}>
              <ListViewCountProvider>
                <TaskCounterProvider>
                  <Router history={history}>{children}</Router>
                </TaskCounterProvider>
              </ListViewCountProvider>
            </WebsocketProvider>
          </UserProvider>
        </ThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
