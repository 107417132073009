import styled, { css } from "styled-components";

import { media } from "@nested/brand";

const SideBar = styled.div`
  display: none;
  background-color: #fafafa;
  height: 100vh;
  padding-top: 20px;
  width: 330px;
  transition: right 200ms ease-out;
  border-left: 1px solid ${({ theme }) => theme.palette.hague20};
  ${media.desktop`
    display: block;
    min-width: 300px;
    max-width: 350px;
    width: calc(100vw - 170px - 675px);
    height: calc(100% - 15px);
  `}
`;

/**
 * Bottom padding is necessary so that the Helpdesk blue icon
 * would not hide content when scrolled to the very bottom of
 * the sidebar.
 */
const DealDetails = styled.div`
  overflow: auto;
  height: calc(100vh - ${({ theme }) => theme.height.header});
  padding-bottom: 100px;
  padding-left: 16px;
  ${media.desktop`
    overflow: visible;
  `}
`;

const headingStyle = css`
  font-size: 18px;
  font-weight: 500;
  padding-left: 15px;
  margin-top: 20px;
  line-height: 24px;

  ${media.desktop`
    margin-top: 0;
  `}
`;

export const DealDetailsBar = ({ children }) => (
  <SideBar id="deal-details">
    <h1 css={headingStyle}>Deal details</h1>
    <DealDetails>{children}</DealDetails>
  </SideBar>
);
