import thirdPartyAppraisalsQuery from "../queries/thirdPartyAppraisals.graphql";

export const updateConfig = {
  props: ({ mutate, ownProps }) => ({
    updateThirdPartyAppraisal: (input) =>
      mutate({
        optimisticResponse: {
          __typename: "Mutation",
          updateThirdPartyAppraisal: {
            __typename: "ThirdPartyAppraisal",
            id: ownProps.id,
            amount: ownProps.amount,
            date: ownProps.date,
            dealId: ownProps.dealId,
            ...input,
          },
        },
        refetchQueries: [
          {
            query: thirdPartyAppraisalsQuery,
            variables: {
              dealId: ownProps.dealId,
            },
          },
        ],
        variables: {
          id: ownProps.id,
          input,
        },
      }),
  }),
};
