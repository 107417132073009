// @flow
import { ThemeProvider } from "styled-components";
import type { ElementType } from "react";

// Actual theme has semantically useful names
export const theme = {
  color: {
    text: {
      leftNav: "#93a0b8",
      dark: "#fafafa",
      default: "#242b38",
      highlight: "#10b186",
      muted: "#999",
      light: "white",
      disabled: "#b7b7b7",
      hague40: "rgba(2, 66, 85, 0.4)",
      hague70: "rgba(2, 66, 85, 0.7)",
      hague: "#0A4254",
      hague150: "rgba(5, 41, 56, 1)",
      blue150: "#2D89D9",
    },
    background: {
      button: "white",
      danger: "#f15050",
      dangerHover: "#c64d4d",
      tomato: "#ee5b45",
      default: "#fafafa",
      highlight: "#f5fffc",
      muted: "#eee",
      hoverMuted: "#D5D5D5",
      spinner: "#999",
      disabled: "#f2f2f2",
      hague: "#024255",
      hague90: "rgba(10, 66, 84, 0.9)",
      hague40: "rgba(2, 66, 85, 0.4)",
      hague20: "rgba(2, 66, 85, 0.2)",
    },
    primary: {
      accent: "#ebf4ff",
      dark: "#353f52",
      highlight: "#afd4ff",
      light: "#ccfaee",
      muted: "#fafafa",
      black: "#000",
      mutedAccent: "#d3e0ef",
    },
    secondary: {
      accent: "#f5fffc",
      danger: "#f14747",
      dark: "#5d6574",
      highlight: "#e6e6e6",
      light: "#10b186",
      hover: "#0D8666",
    },
  },
  height: {
    header: "50px",
  },
  input: {
    height: "32px",
    padding: "8px",
    border: {
      shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
      radius: "4px",
      width: "2px",
    },
  },
  font: {
    weight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    size: {
      default: "12px",
      small: "8px",
    },
    family: {
      default: "Euclid",
    },
  },
  breakpoints: {
    tablet: 480,
    desktop: 860,
    maxDesktop: 1180,
  },
};

export const withTheme = (Component: ElementType) => (props: any) =>
  (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
