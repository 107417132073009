// @flow

type Props = {
  data?: {},
  loading: boolean,
};

export const isQueryLoading =
  (query: any) =>
  ({ data, loading }: Props) => {
    if (loading) {
      return true;
    }

    // if the query is actually a mutation, we don't want to show a loader
    if (query.definitions.find(({ operation }) => operation === "mutation")) {
      return false;
    }

    // there is an issue with Apollo where the props show the query is finished, but the data isn't yet there
    // see: https://github.com/apollographql/react-apollo/issues/1314
    // here we figure out which top level fields the query is returning, and then check if they are all present
    // before saying the query is finished
    const listOfTopLevelFields = query.definitions
      .find(({ kind }) => kind === "OperationDefinition")
      .selectionSet.selections.map((queryField) => queryField.name.value);

    const topLevelFieldsReturned = listOfTopLevelFields.every((field) =>
      Object.keys(data || {}).includes(field),
    );

    if (!topLevelFieldsReturned) {
      return true;
    }
    return false;
  };
