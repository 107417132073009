// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  checkBoxTick,
  checkBoxOuter,
  Wrapper,
  inputStyle,
} from "./CheckboxShared";

// Circular checkbox behaviour:
// Unchecked: white circle with hague border
//            on hover: hague background with white tick
// Checked: green solid circle with white tick
//          on hover: no change
const circularCheckBoxTick = css`
  ${checkBoxTick}
  color: white;
  left: 2.3px;
  opacity: 1;
`;

const circularCheckBoxOuter = css`
  ${checkBoxOuter}
  border: 1px solid ${({ checked, theme }) =>
    checked ? theme.palette.green100 : theme.palette.hague40};
  border-radius: 50%;
  background-color: ${({ theme, checked }) =>
    checked ? theme.palette.green100 : "white"};
  transition: background-color 300ms ease;
  &:hover {
    background: ${({ checked, theme }) =>
      checked ? theme.palette.green100 : theme.palette.hague40};
  }
`;

type CircularCheckboxProps = {
  checked: boolean,
  className?: string,
  "data-test"?: string,
  id: string,
  onChange: () => any,
};

export const CircularCheckbox = ({
  checked,
  className,
  "data-test": dataTest,
  id,
  onChange,
}: CircularCheckboxProps) => {
  return (
    <Wrapper className={className}>
      <input
        data-test={dataTest ? `${dataTest}-${id}` : id}
        css={inputStyle}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
      />
      <label css={circularCheckBoxOuter} htmlFor={id} checked={checked}>
        <FontAwesomeIcon css={circularCheckBoxTick} icon={faCheck} />
      </label>
    </Wrapper>
  );
};
