// @flow
import { useState } from "react";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "../../../components/IconButton/IconButton";
import { EditDealOverlay } from "./EditDealOverlay";

type Props = {
  id: string,
};

export const EditDealButton = ({ id }: Props) => {
  const [openEditDeal, setOpenEditDeal] = useState(false);

  return (
    <>
      <IconButton
        faIcon={faPencilAlt}
        onClick={() => setOpenEditDeal(true)}
        data-test="edit-deal-button"
      />
      {openEditDeal && (
        <EditDealOverlay
          id={id}
          open={openEditDeal}
          onClose={() => setOpenEditDeal(false)}
        />
      )}
    </>
  );
};
