// @flow
import { useState, useEffect } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";
import { Placeholder } from "../../Placeholder";
import { getFullName, getStatusSummary } from "../../../pages/utils";
import { getBuyerSummary } from "./utils";

const DEFAULT_RESULT_NUMBER = 5;

const listItemStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;

  ${media.tablet`
    padding: 5px 20px;
  `}

  ${({ highlight }) =>
    highlight &&
    css`
      background: ${({ theme }) => theme.palette.blue15};
    `};
`;

const unorderedListStyle = css`
  padding: 0 0 20px;
  margin: 0px;

  ${media.tablet`
    max-height: calc(100vh - 75px);
    overflow-y: auto;
  `}
`;

const notEnoughCharsStyle = css`
  display: block;
  font-style: italic;
  margin-left: calc(14% - 47px);
  padding: 11px;
  padding-top: 15px;
  color: ${({ theme }) => theme.palette.hague70};
  ${media.tablet`
    margin-left: 10px;
    padding: 11px;
  `}
`;

const noResultsContainerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const noResultsHeadingStyle = css`
  color: ${({ theme }) => theme.palette.hague150};
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;
  line-height: 24px;
  padding: 0 15px;
  text-align: center;
`;

const noResultsMessageStyle = css`
  margin: 0;
  margin-bottom: 30px;
  padding: 0 15px;
  color: ${({ theme }) => theme.palette.hague70};
  line-height: 18px;
  text-align: center;
`;

const noResultsNestorStyle = css`
  width: 130px;
  height: 130px;
  margin-top: 20px;
`;

const searchSectionHeading = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 0 15px;
  margin: 20px 0 10px;
`;

const resultLink = css`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const statusCircle = css`
  border-radius: 100%;
  margin-top: 6px;
  margin-right: 10px;
  background-color: red;
  width: 6px;
  height: 6px;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.green150 : theme.palette.terracotta150};
`;

const resultHeadingStyle = css`
  font-weight: 500;
  line-height: 18px;
  ${media.tablet`
    display: ${({ tabletInline }) => (tabletInline ? "inline-block" : "block")};
  `}
`;

const resultTimestampStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  ${media.tablet`
    display: inline-block;
    margin-left: 8px;
  `}
`;

const resultDetailStyle = css`
  line-height: 18px;
  ${media.tablet`
    display: ${({ tabletInline }) => (tabletInline ? "inline-block" : "block")};
  `}
`;

const hrStyle = css`
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const recentSearchStyle = css`
  margin-right: 10px;
  color: ${({ theme }) => theme.palette.hague40};
`;

const moreResultsButtonStyle = css`
  background: transparent;
  border: none;
  margin: 0;
  outline: none;
  color: ${({ theme }) => theme.palette.blue150};
  padding: 3px 0 0 20px;
  cursor: pointer;
  line-height: 18px;
`;

const refineSearchMessageStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
  margin: 0;
  text-align: left;
  font-style: italic;
  padding: 11px 0 3px 20px;
  line-height: 18px;
`;

type MoreResultsButtonProps = {
  resultCount: number,
  onClick: () => void,
  allResultsDisplayed: boolean,
  children: React$Node,
};

const MoreResultsButton = ({
  resultCount,
  onClick,
  allResultsDisplayed,
  children,
}: MoreResultsButtonProps) => {
  const buttonVisible =
    resultCount > DEFAULT_RESULT_NUMBER && !allResultsDisplayed;
  return (
    buttonVisible && (
      <button onClick={() => onClick()} css={moreResultsButtonStyle}>
        {children}
      </button>
    )
  );
};

const RefineSearchMessage = () => {
  return (
    <p css={refineSearchMessageStyle}>
      No more results. Try refining your search criteria if you can’t find what
      you’re looking for.
    </p>
  );
};

const NotEnoughChars = ({ charCount }: { charCount: number }) => {
  switch (charCount) {
    case 0:
      return <div css={notEnoughCharsStyle}>3 more characters to go</div>;
    case 1:
      return <div css={notEnoughCharsStyle}>2 more characters to go</div>;
    case 2:
      return <div css={notEnoughCharsStyle}>1 more character to go</div>;
    default:
      return null;
  }
};

const NoResults = () => (
  <div css={noResultsContainerStyle}>
    <img
      css={noResultsNestorStyle}
      alt="Clueless Owl"
      src={getImage("illustrations/nestor/nestor_3.png")}
    />
    <p css={noResultsHeadingStyle}>No results found</p>
    <p css={noResultsMessageStyle}>I can’t find 'hoo' you’re looking for...</p>
  </div>
);

const SearchError = () => (
  <div css={noResultsContainerStyle}>
    <img
      css={noResultsNestorStyle}
      alt="Error robot"
      src={getImage("illustrations/error-bot-yellow.png")}
    />
    <p css={noResultsHeadingStyle}>
      Something went wrong while searching for your request!
    </p>
    <p css={noResultsMessageStyle}>
      Please check your connection and try again.
    </p>
  </div>
);

const DealResult = ({
  deal,
  recentSearch = false,
}: {
  deal: Search_search_deals,
  recentSearch?: boolean,
}) => (
  <div css={resultLink}>
    {recentSearch && (
      <div css={recentSearchStyle}>
        <FontAwesomeIcon icon={faHistory} />
      </div>
    )}
    <div css={statusCircle} active={deal?.active} />
    <div>
      <div css={resultHeadingStyle} className="fs-exclude" tabletInline>
        {deal.address}
      </div>
      <div css={resultTimestampStyle}>
        {getStatusSummary({
          opportunityStatus: deal.opportunityStatus,
          acceptedBuyerOffer: deal.acceptedBuyerOffer,
          createdAt: deal.createdAt,
          currentListingStartDate: deal.currentListingStartDate,
          nestedContractSignedDate:
            deal.dealTypeAgency?.nestedContractSignedDate,
          propertyAnalysisSharedAt: deal.propertyAnalysisSharedAt,
          rmMostRecentContactDatetime: deal.rmMostRecentContactDatetime,
        })}
      </div>
      <div css={resultDetailStyle} className="fs-exclude">
        {getFullName(deal)}
      </div>
    </div>
  </div>
);

const BuyerResult = ({
  buyer,
  recentSearch,
}: {
  buyer: Search_search_buyers,
  recentSearch?: boolean,
}) => (
  <div css={resultLink}>
    {recentSearch && (
      <div css={recentSearchStyle}>
        <FontAwesomeIcon icon={faHistory} />
      </div>
    )}
    <div css={statusCircle} active={buyer.leadStatus === "buyer_active"} />
    <div>
      <div css={resultHeadingStyle} className="fs-exclude">
        {buyer.name}
      </div>
      <div css={resultDetailStyle} className="fs-exclude" tabletInline>
        {buyer.mostRecentlyCreatedBpi
          ? buyer.mostRecentlyCreatedBpi.deal.address
          : "No enquiries"}
        {buyer.buyerPropertyInterests.length > 1 &&
          ` + ${buyer.buyerPropertyInterests.length - 1} more`}
      </div>
      <div css={resultTimestampStyle}>{getBuyerSummary(buyer)}</div>
    </div>
  </div>
);

const ResultsPlaceholder = () => (
  <ul css={unorderedListStyle}>
    {[1, 2].map((i) => (
      <li key={i} css={listItemStyle}>
        <Placeholder height={9} width={134} css="margin-top: 10px;" />
        <Placeholder
          height={9}
          css="display; block; width: 100%; margin-top: 10px;"
        />
        <Placeholder
          height={9}
          css="display; block; width: 100%; margin-top: 10px;"
        />
      </li>
    ))}
  </ul>
);

type RecentSearchesProps = {
  "data-test"?: string,
  getItemProps(options?: Object): void,
  getMenuProps(options?: Object): void,
  recentSearches: RecentSearches_recentSearches[],
  highlightedIndex: number,
};

const RecentSearches = ({
  "data-test": dataTest,
  getItemProps,
  getMenuProps,
  recentSearches,
  highlightedIndex,
}: RecentSearchesProps) => {
  return (
    <ul data-test={dataTest} css={unorderedListStyle} {...getMenuProps()}>
      <li css={searchSectionHeading}>Recent searches</li>
      {recentSearches.map((item, index) => (
        <li
          key={item.id}
          data-test={`search:recent-search:${item.id}`}
          highlight={highlightedIndex === index}
          css={listItemStyle}
          {...getItemProps({ index, item })}
        >
          {item.buyer && <BuyerResult recentSearch buyer={item.buyer} />}
          {item.deal && <DealResult recentSearch deal={item.deal} />}
        </li>
      ))}
    </ul>
  );
};

type Props = {
  "data-test"?: string,
  error?: any,
  loading: boolean,
  isOpen: boolean,
  searchValue: string,
  highlightedIndex: number,
  getMenuProps: (options?: Object) => void,
  getItemProps: (options?: Object) => void,
  buyers: Search_search_buyers[],
  sellers: Search_search_deals[],
  recentSearches: RecentSearches_recentSearches[],
};

export const SearchResults = ({
  "data-test": dataTest,
  error,
  loading,
  isOpen,
  searchValue,
  buyers,
  sellers,
  highlightedIndex,
  getItemProps,
  getMenuProps,
  recentSearches,
}: Props) => {
  const [numOfSellersToDisplay, setNumOfSellersToDisplay] = useState(
    DEFAULT_RESULT_NUMBER,
  );
  const [numOfBuyersToDisplay, setNumOfBuyersToDisplay] = useState(
    DEFAULT_RESULT_NUMBER,
  );
  useEffect(() => {
    setNumOfSellersToDisplay(DEFAULT_RESULT_NUMBER);
    setNumOfBuyersToDisplay(DEFAULT_RESULT_NUMBER);
  }, [searchValue]); // set number of displayed items to 5 as soon as results finish loading or search is cleared

  if (!isOpen) return null;

  if (searchValue.length === 0) {
    return (
      <RecentSearches
        data-test={dataTest}
        recentSearches={recentSearches}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        highlightedIndex={highlightedIndex}
      />
    );
  }

  const sellerCount = sellers.length;
  const buyerCount = buyers.length;

  if (searchValue.length < 3) {
    return <NotEnoughChars charCount={searchValue.length} />;
  }

  if (loading) {
    return <ResultsPlaceholder />;
  }

  if (error) {
    return <SearchError />;
  }

  if (buyerCount === 0 && sellerCount === 0) {
    return <NoResults />;
  }

  return (
    <ul data-test={dataTest} css={unorderedListStyle} {...getMenuProps()}>
      {sellerCount > 0 && (
        <>
          <li css={searchSectionHeading}>Sellers</li>
          {sellers.slice(0, numOfSellersToDisplay).map((deal, index) => (
            <li
              data-test={`search:deal-${deal.id}`}
              css={listItemStyle}
              highlight={highlightedIndex === index}
              key={`deal-${deal.id}`}
              {...getItemProps({ item: deal, index })}
            >
              <DealResult deal={deal} />
            </li>
          ))}
        </>
      )}
      <MoreResultsButton
        resultCount={sellerCount}
        onClick={() => setNumOfSellersToDisplay(sellerCount)}
        allResultsDisplayed={numOfSellersToDisplay === sellerCount}
      >
        Show more sellers
      </MoreResultsButton>
      {numOfSellersToDisplay === sellerCount && <RefineSearchMessage />}
      {buyerCount > 0 && (
        <>
          <li css={searchSectionHeading}>
            <hr css={hrStyle} />
            Buyers
          </li>
          {buyers.slice(0, numOfBuyersToDisplay).map((buyer, index) => (
            <li
              data-test={`search:buyer-${buyer.id}`}
              css={listItemStyle}
              highlight={highlightedIndex === index + sellerCount}
              key={`buyer-${buyer.id}`}
              {...getItemProps({ item: buyer, index: index + sellerCount })}
            >
              <BuyerResult buyer={buyer} />
            </li>
          ))}
        </>
      )}
      <MoreResultsButton
        resultCount={buyerCount}
        onClick={() => setNumOfBuyersToDisplay(buyerCount)}
        allResultsDisplayed={numOfBuyersToDisplay === buyerCount}
      >
        Show more buyers
      </MoreResultsButton>
      {numOfBuyersToDisplay === buyerCount && <RefineSearchMessage />}
    </ul>
  );
};
