// @flow
import { Helmet } from "react-helmet";

export const MetaTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />

    <link
      rel="apple-touch-icon"
      href="https://nested.imgix.net/nest/viewings-app.png"
    />
    <meta name="apple-mobile-web-app-title" content="Viewings" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta
      name="apple-mobile-web-app-status-bar-style"
      content="translucent-black"
    />
  </Helmet>
);
