// @flow
import { compose } from "recompose";

import { PercentageField } from "components/PercentageField";
import { Grid, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";
import { SelectField } from "components/SelectField";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { graphql } from "deal/utils/graphql";

import deleteDrawdownTimingFeeMutation from "./mutations/deleteDrawdownTimingFee.graphql";
import updateDrawdownTimingFeeMutation from "./mutations/updateDrawdownTimingFee.graphql";
import {
  deleteDrawdownTimingFeeMutationConfig,
  updateDrawdownTimingFeeMutationConfig,
} from "./mutations/config";

function DrawdownTimingFeesListComponent({
  dayChoices,
  dealId,
  deleteDrawdownTimingFee,
  drawdownTimingFees,
  updateDrawdownTimingFee,
}) {
  return drawdownTimingFees.map((drawdownTimingFee, index) => {
    const { id, days, fee, totalFee } = drawdownTimingFee;

    /**
     * We want to add the current option to the remaining day options
     * so that the value is properly displayed to the user
     */
    const dayChoicesWithCurrentValue = dayChoices
      .concat({ value: days, label: String(days) })
      .sort((a, b) => a.value > b.value);

    return (
      <ListEntryCard data-test="drawdown-timing-fees" key={id}>
        <Grid rowGap="0px" columns={4}>
          <LeafCell width={1}>
            <SelectField
              data-test={`drawdown-timing-fees-days-${index}`}
              label="Drawdown on or after day..."
              mutation={updateDrawdownTimingFee(drawdownTimingFee)}
              nullable={false}
              options={dayChoicesWithCurrentValue}
              property="days"
              value={days}
            />
          </LeafCell>
          <LeafCell width={1}>
            <PercentageField
              data-test={`drawdown-timing-fees-fee-${index}`}
              label="Drawdown timing fee %"
              mutation={updateDrawdownTimingFee(drawdownTimingFee)}
              property="fee"
              value={fee}
            />
          </LeafCell>
          <LeafCell width={1}>
            <PercentageField
              data-test={`drawdown-timing-fees-total-fee-${index}`}
              disabled
              label="Total fee at drawdown %"
              value={totalFee}
            />
          </LeafCell>
          <LeafCell width={1}>
            <DeleteButton
              data-test="drawdown-timing-fees-delete-button"
              onSubmit={() => {
                if (itemDeletionConfirmed()) {
                  deleteDrawdownTimingFee(id, dealId);
                }
              }}
            />
          </LeafCell>
        </Grid>
      </ListEntryCard>
    );
  });
}

const enhance = compose(
  graphql(
    deleteDrawdownTimingFeeMutation,
    deleteDrawdownTimingFeeMutationConfig,
  ),
  graphql(
    updateDrawdownTimingFeeMutation,
    updateDrawdownTimingFeeMutationConfig,
  ),
);

export const DrawdownTimingFeesList = enhance(DrawdownTimingFeesListComponent);
