// @flow
import { useState, useEffect, useRef } from "react";
import { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import {
  inputStyle,
  labelStyle,
  optionalLabelStyle,
} from "../TextInput/TextInput";

// This is a sort of arbitrary number. We don't expect more than 100 lines
// in a textarea (or textareas with full heights greater than 1800px) so setting lines
// to this should just reveal the whole text. Can increase this at any point if its
// insufficient.
const MAX_LINE_LIMIT = 200;

const ellipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: ${({ $lineLimit }) => $lineLimit};
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;

const lineLimitStyles = css`
  max-height: ${({ $lineLimit }) => $lineLimit * 18 + 1}px;
  overflow: hidden;
  ${({ $isExpanded, $editing }) => ($isExpanded || $editing ? "" : ellipsis)}
`;

const textAreaStyle = css`
  ${inputStyle};
  height: auto;
  min-height: 54px;
  resize: vertical;
  max-height: 150px;
  ${({ $lineLimit }) => ($lineLimit ? lineLimitStyles : "")}
`;

const seeMoreButton = css`
  border: none;
  color: ${({ theme }) => theme.palette.blue150};
  font-weight: 500;
  padding: 0;
  background-color: white;
  margin-top: 10px;
  align-self: right;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.blue100};
  }
`;

export type Props = {
  className?: string,
  dark?: boolean,
  "data-test"?: string,
  disableShowMore?: boolean,
  forwardedRef?: { current: React$ElementRef<"input"> | null },
  label?: string,
  lineLimit?: ?number,
  optional?: boolean,
  value: string,
  valid?: boolean,
  onBlur?: (e: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  onFocus?: (e: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  onChange: (e: SyntheticInputEvent<HTMLTextAreaElement>) => void,
  onKeyPress?: (e: SyntheticKeyboardEvent<HTMLTextAreaElement>) => void,
};

export const TextArea = ({
  className,
  dark = false,
  "data-test": dataTest = "textarea",
  disableShowMore = false,
  label,
  optional = false,
  valid = true,
  forwardedRef,
  lineLimit,
  value = "",
  onFocus,
  onBlur,
  onKeyPress,
  ...rest
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [currentLineLimit, setCurrentLineLimit] = useState(lineLimit);
  const [showSeeMore, setShowSeeMore] = useState(false);
  const ref = useRef(null);
  const isExpanded = currentLineLimit === MAX_LINE_LIMIT;
  const toggleNoteExpansion = () => {
    if (isExpanded) {
      setCurrentLineLimit(lineLimit);
    } else {
      setCurrentLineLimit(MAX_LINE_LIMIT);
    }
  };

  useEffect(() => {
    if (disableShowMore) {
      return;
    }
    if (lineLimit && ref) {
      const wrapperHeight = ref?.current?.clientHeight || 0;
      const maxHeight = lineLimit * 18;
      if (wrapperHeight > maxHeight) {
        setShowSeeMore(true);
      } else {
        setShowSeeMore(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (editing) {
      setCurrentLineLimit(MAX_LINE_LIMIT);
    }
  }, [editing]);

  return (
    <>
      <label
        className={className}
        css={labelStyle}
        dark={dark}
        valid={valid}
        data-test={dataTest}
        ref={ref}
      >
        {(label || optional) && (
          <div css="margin-bottom: 5px;">
            {label}
            {optional && (
              <span css={optionalLabelStyle} dark={dark}>
                Optional
              </span>
            )}
          </div>
        )}
        <TextareaAutosize
          {...rest}
          $lineLimit={lineLimit ? currentLineLimit : null}
          $isExpanded={isExpanded}
          $editing={editing}
          ref={forwardedRef}
          css={textAreaStyle}
          onFocus={(e) => {
            setEditing(true);
            if (onFocus) onFocus(e);
          }}
          onBlur={(e) => {
            setEditing(false);
            if (onBlur) onBlur(e);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              setEditing(false);
            }
            if (onKeyPress) onKeyPress(e);
          }}
          dark={dark}
          data-test={`${dataTest}:input`}
          valid={valid}
          value={value}
        />
      </label>
      {showSeeMore && !editing && (
        <button css={seeMoreButton} onClick={toggleNoteExpansion}>
          See {isExpanded ? "less" : "more"}
        </button>
      )}
    </>
  );
};
