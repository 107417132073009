// @flow
import styled, { css } from "styled-components";
import { media } from "@nested/brand";
import {
  ModalHeader as OriginalModalHeader,
  Modal,
} from "../../../components/Modal";
import { DeactivationForm } from "./DeactivationForm";
import { DisinstructionForm } from "./DisinstructionForm";

const modalStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    height: 400px;
    max-height: 600px;
    width: 700px;
    & > div {
      padding: 0 20px;
    }
  `}
`;

const ModalHeader = styled(OriginalModalHeader)`
  display: none;
  ${media.tablet`
    display: block;
    margin-bottom: 0;
  `}
`;

export const REACTIVATION = "REACTIVATION";
export const DEACTIVATION = "DEACTIVATION";
export const DISINSTRUCTION = "DISINSTRUCTION";

type DealStatusFormsProps = {
  closeForm: () => void,
  closeFormAndResetToggle?: () => void,
  closeOverlay?: () => void,
  formType: ?string,
  id: string,
  setIsToggleYes: (value: boolean) => void,
  useModals?: boolean,
};

export const DealStatusForms = ({
  useModals,
  id,
  formType,
  closeForm,
  closeFormAndResetToggle,
  closeOverlay,
  setIsToggleYes,
}: DealStatusFormsProps) => {
  if (useModals) {
    return (
      <>
        {formType === DEACTIVATION && (
          <Modal
            data-test="deal-status-modal"
            open={formType === DEACTIVATION}
            onClose={closeFormAndResetToggle || closeForm}
            topScreen
            css={modalStyle}
          >
            <ModalHeader>Deactivate Seller?</ModalHeader>
            <DeactivationForm
              id={id}
              closeForm={closeForm}
              data-test="modal:deactivation-form"
            />
          </Modal>
        )}
        {formType === DISINSTRUCTION && (
          <Modal
            data-test="deal-status-modal"
            open={formType === DISINSTRUCTION}
            onClose={closeFormAndResetToggle || closeForm}
            topScreen
            css={modalStyle}
          >
            <ModalHeader>Deactivate Seller?</ModalHeader>
            <DisinstructionForm
              id={id}
              closeForm={closeForm}
              data-test="modal:disinstruction-form"
            />
          </Modal>
        )}
      </>
    );
  }

  return (
    <>
      {formType === DEACTIVATION && (
        <DeactivationForm
          id={id}
          closeOverlay={closeOverlay}
          closeForm={closeForm}
          data-test="deactivation-form"
          // to keep the toggle temporarily inactive if there is a network failure
          keepToggleInactive={() => setIsToggleYes(false)}
        />
      )}
      {formType === DISINSTRUCTION && (
        <DisinstructionForm
          id={id}
          closeOverlay={closeOverlay}
          closeForm={closeForm}
          data-test="disinstruction-form"
          // to keep the toggle temporarily inactive if there is a network failure
          keepToggleInactive={() => setIsToggleYes(false)}
        />
      )}
    </>
  );
};
