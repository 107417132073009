import T from "prop-types";

const Input = ({
  id,
  type,
  onBlur,
  onChange,
  disabled,
  inputRef,
  value,
  onWheel,
  onFocus,
  name,
  "data-test": dataTest,
  placeholder,
  maxLength,
}) => (
  <input
    id={id}
    type={type}
    onBlur={onBlur}
    onChange={onChange}
    disabled={disabled}
    value={value}
    onWheel={onWheel}
    onFocus={onFocus}
    data-test={dataTest}
    name={name}
    placeholder={placeholder}
    ref={inputRef}
    maxLength={maxLength}
  />
);

Input.propTypes = {
  "data-test": T.string,
  disabled: T.bool,
  id: T.string,
  onBlur: T.func,
  onChange: T.func,
  onFocus: T.func,
  onWheel: T.func,
  type: T.string,
  value: T.oneOfType([T.string, T.number, T.bool]),
  placeholder: T.string,
  ref: T.elementType,
};

export { Input };
