// @flow
import styled from "styled-components";
import { SecondaryButton } from "components/Buttons";
import { Info } from "@nest-ui/icons";

const Div = styled.div`
  width: 261px;
  height: 345px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #242b38;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  font-family: Euclid;

  .info-icon svg {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto;
    padding-bottom: 12px;
  }
`;

export const NoAdvances = ({ enableCreating }: *) => (
  <Div data-test="advances:empty-list">
    <div className="info-icon">
      <Info fill="#B5B5B5" />
    </div>
    <p>We don&apos;t have any advance in principles yet.</p>
    <SecondaryButton
      data-test="advances:create-advance-proposal"
      onClick={enableCreating}
    >
      Add new advance in principle
    </SecondaryButton>
  </Div>
);
