// @flow
import { gql } from "@apollo/client";
import { type ContextRouter } from "react-router-dom";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { Card } from "components/Card";
import { H2, Heading } from "components/Heading";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { useUser } from "@nested/nest/src/hooks/useUser";
import { Experiments } from "./Experiments/Experiments";
import { ResponsiblePeople } from "./ResponsiblePeople/ResponsiblePeople";
import { CoverSA } from "./CoverSA/CoverSA";
import { PortalDetails } from "./PortalDetails/PortalDetails";
import { NestedListingsSection } from "./NestedListingsSection/NestedListingsSection";
import { PropertyInfo } from "./PropertyInfo/PropertyInfo";
import { ViewingAppPropertyInfo } from "./ViewingAppPropertyInfo/ViewingAppPropertyInfo";

const LISTING_TAB_QUERY = gql`
  query ListingTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
    }
  }
`;

export const Listing = ({
  match: {
    params: { id: idOrExternalDealId },
  },
}: ContextRouter) => {
  const { currentUser } = useUser();

  return (
    <ExtendedQuery query={LISTING_TAB_QUERY} variables={{ idOrExternalDealId }}>
      {({ nestDeal: { id } }: ExtendedQueryRenderProps<ListingTabQuery>) => (
        <TabWrapper>
          <TabContainer id="Listing" data-test="listing-tab" fullWidth>
            <ResponsiblePeople parentId={id} />
            <CoverSA parentId={id} />

            <Card>
              <H2>Portal Info</H2>
              <PortalDetails parentId={id} />
            </Card>

            <Card>
              <H2>Nested listings</H2>
              <NestedListingsSection parentId={id} />
            </Card>

            <Card>
              <H2>Property info</H2>
              <PropertyInfo parentId={id} />
            </Card>

            <Heading id="property---info--app-">
              Property Info (Viewing App)
            </Heading>
            <ViewingAppPropertyInfo dealId={id} editMode />
            {currentUser.isNestAdmin && (
              <ErrorBoundary>
                <Experiments dealId={id} />
              </ErrorBoundary>
            )}
          </TabContainer>
        </TabWrapper>
      )}
    </ExtendedQuery>
  );
};
