// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { getImage } from "@nested/utils";
import { Placeholder } from "./Placeholder";
import { HandoffCallButton } from "./Comms";
import { NurtureHotnessImage } from "./NurtureHotnessImage";
import { type NurtureListNurtureHotness } from "../pages/Nurture/NurtureShared";

const dealWrapperStyle = css`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague10};
  display: flex;
  justify-content: space-between;
  & button:disabled {
    align-self: center;
    height: 30px;
    border-radius: 10px;
    min-width: 30px;
    background-color: ${({ theme }) => theme.palette.hague10};
    color: ${({ theme }) => theme.palette.hague50};
    cursor: default;
  }
  & button:focus {
    background: transparent;
  }
`;

const loaderWrapper = css`
  ${dealWrapperStyle}
  flex-direction: column;
`;

const dealInfoWrapperStyle = css`
  display: flex;
  flex-direction: row;
  padding: 0 20px 0 0;
  min-width: 0;
`;

const ellipsisStyle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const addressStyle = css`
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  ${ellipsisStyle}
`;

const detailStyle = css`
  margin: 0px;
  margin-top: 5px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.hague70};
  ${ellipsisStyle}
`;

const imageStyle = css`
  width: 80px;
  margin-right: 15px;
  border-radius: 2px;
`;

const addressWithImageStyle = css`
  line-height: 20px;
  font-weight: 500;
  ${ellipsisStyle}
`;

const detailWithImageStyle = css`
  margin: 0;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.hague70};
  ${ellipsisStyle}
`;

const communicationButtonsStyle = css`
  align-self: center;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 10px;

  svg {
    min-height: 16px;
    min-width: 16px;
  }
`;

const nameAndIconStyle = css`
  display: flex;
  gap: 10px;
  height: 20px;
  line-height: 20px;
`;

const LoadingDeal = () => (
  <div css={loaderWrapper}>
    <div css={addressStyle}>
      <Placeholder width={200} />
    </div>
    <div css={detailStyle}>
      <Placeholder width={100} />
    </div>
  </div>
);

// TODO: Maybe remove this in favour of the `MobilePlaceholderList` in the
// `Placeholder` component; it's more generic
export const MobileLoader = ({ className }: { className?: string }) => (
  <div className={className}>
    <LoadingDeal />
    <LoadingDeal />
    <LoadingDeal />
  </div>
);
type Props = {|
  externalId: string,
  address: string,
  detailText: string,
  detailTextTitle: ?string,
  displayImage?: boolean,
  sellerName: string,
  nurtureHotness?: NurtureListNurtureHotness,
  contacts: $ReadOnlyArray<DealSummaryBar_nestDeal_contacts>,
|};

export const MobileListViewDealCard = ({
  address,
  contacts,
  detailText,
  detailTextTitle,
  displayImage,
  externalId,
  nurtureHotness,
  sellerName,
}: Props) => {
  return (
    <div css={dealWrapperStyle}>
      <Link
        key={externalId}
        to={`/deals/${externalId}`}
        css={dealInfoWrapperStyle}
      >
        {displayImage && (
          <div>
            <img
              alt=""
              css={imageStyle}
              src={getImage(`property-listings/${externalId}/1`)}
            />
          </div>
        )}
        <div css="min-width: 0;">
          <div
            css={displayImage ? addressWithImageStyle : addressStyle}
            className="fs-exclude"
          >
            {address}
          </div>
          <div css={nameAndIconStyle}>
            <span css={ellipsisStyle} className="fs-exclude">
              {sellerName}
            </span>
            {nurtureHotness && (
              <NurtureHotnessImage nurtureHotness={nurtureHotness} />
            )}
          </div>
          <div
            css={displayImage ? detailWithImageStyle : detailStyle}
            title={detailTextTitle}
          >
            {detailText}
          </div>
        </div>
      </Link>
      <HandoffCallButton
        css={communicationButtonsStyle}
        contacts={contacts}
        relationType="deal"
        relationId={externalId}
      />
    </div>
  );
};
