// @flow
import { gql } from "@apollo/client";

export const VALUATIONS_QUERY = gql`
  query PostVisitValuationsListNestDealQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      valuationList {
        __typename
        id
        notes
        dealId

        ... on PostVisitValuationDec18 {
          completedBy
          dateCompleted
          estimatedAdvanceLowerBound
          estimatedAdvanceOnTrack
          estimatedAdvanceUpperBound
          estimatedSalePrice
          estimatedSalePriceLowerBound
          estimatedSalePriceUpperBound
          estimatingAdvance
          listPrice
          maximumListPrice
          signedOffBy
          valuationGsheetUrl
        }

        ... on PostVisitValuationPreDec18 {
          # The fields below are aliased because they're nullable which upsets flow because
          # fields with the same name on PostVisitValuationDec18 are non nullable
          estimatedAdvanceLowerBound
          estimatedAdvanceUpperBound
          nullableDateCompleted: dateCompleted
          nullableEstimatedSalePrice: estimatedSalePrice
          nullableEstimatedSalePriceLowerBound: estimatedSalePriceLowerBound
          nullableEstimatedSalePriceUpperBound: estimatedSalePriceUpperBound
          nullableLiquidityAssessmentExact: liquidityAssessmentExact
          nullableListPrice: listPrice
          nullableValuationConfidenceComments: valuationConfidenceComments
          nullableValuationGsheetUrl: valuationGsheetUrl
        }
      }
    }

    activeNestedUsers {
      id
      closeUserId
      fullName
    }
  }
`;

export const CREATE_VALUATION_MUTATION = gql`
  mutation CreatePostVisitValuation(
    $dealId: ID!
    $input: CreatePostVisitValuationDec18Input!
  ) {
    createPostVisitValuationDec18(dealId: $dealId, input: $input) {
      completedBy
      dateCompleted
      estimatedAdvanceLowerBound
      estimatedAdvanceOnTrack
      estimatedAdvanceUpperBound
      estimatedSalePrice
      estimatedSalePriceLowerBound
      estimatedSalePriceUpperBound
      estimatingAdvance
      id
      listPrice
      maximumListPrice
      notes
      signedOffBy
      valuationGsheetUrl
    }
  }
`;

export const UPDATE_POST_VISIT_VALUATION_MUTATION = gql`
  mutation UpdatePostVisitValuationDec18(
    $postVisitValuationId: ID!
    $input: PostVisitValuationDec18UpdateInput!
  ) {
    updatePostVisitValuationDec18(
      postVisitValuationId: $postVisitValuationId
      input: $input
    ) {
      id
      notes
    }
  }
`;
