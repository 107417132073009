import T from "prop-types";
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";
import { Grid, LeafCell } from "components/Grid";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";

import { SelectField } from "components/SelectField";
import { ProposedCustomerEligibility } from "tabs/AdvanceValuationsAndLegal/LegalServices/sections/ProposedCustomerEligibility/ProposedCustomerEligibility";

import valuerSectionQuery from "./queries/valuerSectionQuery.graphql";
import { valuerSectionQueryConfig } from "./queries/valuerSectionQueryConfig";

import valuerSectionMutation from "./mutations/updateNestDeal.graphql";
import { valuerSectionMutationConfig } from "./mutations/updateNestDealConfig";

export const ValuerSectionComponent = ({ data, updateNestDeal }) => {
  const { nestDeal, valuationTypes, activeNestedUsers } = data;

  return (
    <ErrorBoundary>
      <Grid columns={2} rows="auto auto">
        <LeafCell width={1}>
          <SelectField
            options={activeNestedUsers}
            value={nestDeal?.assignedVa?.id}
            mutation={updateNestDeal}
            data-test="valuer-section-nested-valuer"
            property="assignedVaId"
            label="VA"
          />
        </LeafCell>
        <LeafCell width={1}>
          <ProposedCustomerEligibility dealId={nestDeal.id} />
        </LeafCell>
        <LeafCell width={1}>
          <SelectField
            options={valuationTypes}
            value={nestDeal.valuationType}
            mutation={updateNestDeal}
            data-test="valuer-section-valuation-type"
            property="valuationType"
            label="Valuation type"
          />
        </LeafCell>
      </Grid>
    </ErrorBoundary>
  );
};

ValuerSectionComponent.propTypes = {
  data: T.object.isRequired,
  updateNestDeal: T.func.isRequired,
};

const enhance = compose(
  graphql(valuerSectionQuery, valuerSectionQueryConfig),
  graphql(valuerSectionMutation, valuerSectionMutationConfig),
);

export const ValuerSection = enhance(ValuerSectionComponent);
