// @flow
import styled from "styled-components";
import { Route } from "react-router-dom";
import { type ContextRouter } from "react-router";
import { Comparables } from "components/Comparables/Comparables";
import { BuyersAgentNav } from "./NavBar";
import { Reports } from "./Reports/Reports";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const BuyersAgentPage = ({
  match: {
    params: { customerAccountId },
  },
}: ContextRouter) => (
  <>
    <BuyersAgentNav customerAccountId={customerAccountId} />
    <Wrapper>
      <Route
        path={"/buyers-agent/:customerAccountId"}
        render={() => <Reports customerAccountId={customerAccountId} />}
      />
      <Route
        path={"/buyers-agent/:customerAccountId/report/:reportId/comparables"}
        render={({
          match: {
            params: { reportId },
          },
        }) => (
          <Comparables
            closeUrl={`/buyers-agent/${customerAccountId}`}
            sourceType="BUYERS_AGENT_REPORT"
            sourceId={reportId}
          />
        )}
      />
    </Wrapper>
  </>
);
