// @flow
import { shuffle as lodashShuffle } from "lodash";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { css } from "styled-components";
import { Checkbox, TextInput, smallParagraph, media } from "@nested/brand";
import {
  Heading,
  QuestionWrapper,
  QuestionDescription,
  type RadioButtonsOptions,
} from "../components";
import { type QuestionProps } from "../components/MultiStageForm";
import { validatePresence } from "./validators";

const validate = (value = {}) =>
  validatePresence("sources", "Please select an option")(value?.sources);

const labelStyle = css`
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 10px;
  display: block;
`;

const checkboxWrapper = css`
  margin-top: 5px;
  margin-bottom: 10px;

  ${media.desktop`
    display: inline-block;
    width: 50%;
  `};
`;

const errorStyles = css`
  ${smallParagraph}
  color: ${({ theme }) => theme.palette.terracotta100};
  font-weight: 500;
  line-height: 17px;
  margin-top: 0;
  margin-bottom: -20px;
  ${media.tablet`
    margin-top: 14px;
  `}
`;

const options: RadioButtonsOptions<Source> = [
  { value: "A_FRIEND", label: "Friend" },
  { value: "BILLBOARD_POSTER_TUBE", label: "Billboard, poster or tube ad" },
  { value: "FOR_SALE_BOARD", label: "For sale board" },
  { value: "FACEBOOK", label: "Facebook" },
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "LOCAL_BUSINESS", label: "Local business" },
  { value: "POST", label: "Letter through the post" },
  { value: "PRESS", label: "Press" },
  { value: "RIGHTMOVE", label: "Rightmove" },
  { value: "TV", label: "TV ad" },
  { value: "ZOOPLA", label: "Zoopla" },
];

const internalOnlyOptions = [
  { value: "NESTED_EMPLOYEE", label: "A Nested employee" },
  { value: "PARTNER", label: "Nested partner" },
];

const getSourceAndReferrer = (sourcesSet, referrer, referralCode) => {
  const sources = Array.from(sourcesSet);
  if (referralCode) {
    return {
      sources,
      referrer: `REFERRAL_CODE:${referralCode}`,
    };
  }
  return { sources, referrer };
};

const checkForCookie = (cookies) => {
  if (cookies && cookies.referral_code) {
    const { referral_code } = cookies;
    return referral_code;
  }
  return null;
};

const getOptions = (internalUser, shuffle) => {
  const shuffled = [...shuffle(options), { value: "GOOGLE", label: "Google" }];
  if (internalUser) {
    return [...shuffled, ...internalOnlyOptions];
  }

  return shuffled;
};

type Props = {
  ...QuestionProps<"sources">,
  shuffle?: (any[]) => any[],
};

export const SourceComponent = ({
  ActionButtons,
  initialValue,
  next,
  valid,
  validationErrors,
  shuffle = lodashShuffle, // allows storybook to be deterministic
}: Props) => {
  const [cookies] = useCookies([]);
  const referralCode = checkForCookie(cookies);
  const internalUser = Boolean(cookies["com.nested.internaluser"]);
  const [referrer, setReferrer] = useState(initialValue?.referrer || "");
  const [selectedSources, setSelectedSources] = useState(
    new Set(initialValue?.sources || []),
  );
  const [shuffledOptions] = useState(getOptions(internalUser, shuffle));

  const createOnChange = (value) => (e) => {
    const newSources = new Set(selectedSources);
    if (e.target.checked) {
      newSources.add(value);
    } else {
      newSources.delete(value);
    }
    setSelectedSources(newSources);
  };

  return (
    <>
      <QuestionWrapper
        next={() =>
          // $FlowFixMe: Needs validation for non null
          next(
            getSourceAndReferrer(selectedSources, referrer, referralCode),
            validate,
          )
        }
        dataTest="source-question"
      >
        <Heading>Tell us all the places you've seen Nested.</Heading>
        <QuestionDescription css="font-weight: 500">
          Please select all that apply.
        </QuestionDescription>
        {shuffledOptions.map(({ label, value }) => (
          <div key={value} css={checkboxWrapper}>
            <Checkbox
              id={`onboarding-form:source-${value}`}
              onChange={createOnChange(value)}
              label={label}
              checked={selectedSources.has(value)}
            />
          </div>
        ))}
        {(selectedSources.has("A_FRIEND") ||
          selectedSources.has("NESTED_EMPLOYEE") ||
          selectedSources.has("PARTNER")) &&
          !referralCode && (
            <>
              <label css={labelStyle} htmlFor="referrer-input">
                Who referred you to Nested?
              </label>
              <TextInput
                data-test="referred-by-input"
                id="referrer-input"
                value={referrer}
                onChange={(e) => setReferrer(e.target.value)}
              />
            </>
          )}
        {!valid && <p css={errorStyles}>{validationErrors.sources}</p>}
        <ActionButtons />
      </QuestionWrapper>
    </>
  );
};
