// @flow
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Form, Field } from "react-final-form";
import moment from "moment-timezone";
import { Checkbox } from "@nested/nest/src/components/Checkbox/Checkbox";
import { formatShortDate } from "@nested/utils/src/formatDate/formatDate";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { DatePicker } from "@nested/nest/src/components/DatePicker/DatePicker";
import { Button } from "@nested/nest/src/components/Button/Button";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import reloadAgreedOrAcceptedOffer from "../../queries/reloadAgreedOrAcceptedOffer.graphql";
import reloadDealStatus from "../../queries/reloadDealStatus.graphql";
import {
  MILESTONE_LABELS,
  milestoneStyle,
  checkboxStyle,
  dateStyle,
  type MilestoneProps,
} from "./MilestoneShared";
import {
  modalStyle,
  required,
  textStyle,
  sectionStyle,
  fieldStyle,
  buttonStyle,
} from "./Exchanged";

export const REACH_MEMO_SENT_MILESTONE = gql`
  mutation ReachMemoMilestone(
    $buyerOfferId: ID!
    $input: ReachMemoMilestoneInput!
  ) {
    reachMemoMilestone(buyerOfferId: $buyerOfferId, input: $input) {
      id
      reachedAt
    }
  }
`;

export const MemoSentMilestone = ({
  buyerOfferId,
  dealId,
  disabled,
  milestone,
}: MilestoneProps) => {
  const [open, setOpen] = useState(false);
  const refetchQueries = [
    { query: reloadDealStatus, variables: { dealId } },
    { query: reloadAgreedOrAcceptedOffer, variables: { dealId } },
  ];
  const [reach] = useMutation(REACH_MEMO_SENT_MILESTONE, { refetchQueries });
  const { createNotification } = useNotifications();

  const { id, reachedAt } = milestone || {};

  const onSubmit = async (input) => {
    if (!buyerOfferId || reachedAt) {
      return;
    }

    try {
      const result = await reach({
        variables: {
          buyerOfferId,
          input,
        },
        optimisticResponse: {
          reachMemoMilestone: {
            __typename: "MilestoneStatus",
            id,
            reachedAt: moment(),
          },
        },
      });
      if (result?.data?.reachMemoMilestone?.reachedAt) {
        setOpen(false);
        createNotification("New milestone reached!", {
          subText: MILESTONE_LABELS[id],
        });
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <MobileFullScreenModal
        css={modalStyle}
        open={open}
        onClose={() => setOpen(false)}
        headerText="Confirm accepted offer date"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            acceptedDate: moment().format("YYYY-MM-DD"),
          }}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} css="padding: 0 20px;">
              <p css={textStyle}>
                Please confirm the date the offer was accepted
              </p>
              <div css={sectionStyle}>
                <Field name="acceptedDate" validate={required}>
                  {({ input, meta }) => {
                    return (
                      <div css={fieldStyle}>
                        <DatePicker
                          dataTest="accepted-offer-date"
                          label="Accepted Offer Date"
                          onSubmit={(date) => input.onChange(date)}
                          valid={meta.valid}
                          value={input.value}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>
              <div css={buttonStyle}>
                <Button
                  data-test={"submit-button"}
                  disabled={hasValidationErrors || submitting}
                  buttonStyle="pink"
                  type="submit"
                  css="width: 100%;"
                >
                  {submitting ? "Saving..." : "Save"}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </MobileFullScreenModal>
      <div css={milestoneStyle}>
        <Checkbox
          checked={Boolean(milestone?.reachedAt)}
          css={checkboxStyle}
          disabled={disabled || !buyerOfferId}
          id={id}
          labelText={MILESTONE_LABELS[id]}
          onChange={() => setOpen(true)}
        />
        {reachedAt && <div css={dateStyle}>{formatShortDate(reachedAt)}</div>}
      </div>
    </>
  );
};
