// @flow
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "styled-components";
import { useUser } from "@nested/nest/src/hooks/useUser";
import { useSorting } from "@nested/nest/src/hooks/useSorting";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { Select } from "../../components/Select/Select";
import { NoResults } from "../../components/NoResults";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import { prettyFormat } from "../Portfolio/utils";
import { ListViewError } from "../../components/ListViewError";
import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import { getFullName } from "../utils";

const VISIT_OR_CALL_DATE = "VISIT_OR_CALL_DATE";
const SELLER_NAME = "SELLER_NAME";

const MOBILE_SORT_OPTIONS = [
  { label: "Valuation visit/call date", value: VISIT_OR_CALL_DATE },
  { label: "Seller name", value: SELLER_NAME },
];

export const VALUATION_LIST_VIEW_QUERY = gql`
  query valuationListViewDeals(
    $sortBy: DoPropertyAnalysisListViewSortBy
    $sortDirection: ListViewSortDirection
    $email: String!
  ) {
    doPropertyAnalysisDeals(
      sortBy: $sortBy
      sortDirection: $sortDirection
      email: $email
    ) {
      results {
        id
        externalId
        address
        ownerName
        firstName
        lastName
        rmMostRecentContactDatetime
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
          }
          avatarUrl
        }
      }
    }
  }
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0px;
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          key={deal.externalId}
          externalId={deal.externalId}
          address={deal.address}
          sellerName={getFullName(deal)}
          contacts={deal.contacts}
          detailText={`Valuation ${formatDatetimeDifference(
            deal.rmMostRecentContactDatetime,
          )}`}
          detailTextTitle={prettyFormat(deal.rmMostRecentContactDatetime)}
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field={SELLER_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <SortableTH
              css="padding-right: 10px;"
              field={VISIT_OR_CALL_DATE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Valuation visit/call date
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => (
            <tr key={deal.externalId} data-test={`deal-row-${deal.externalId}`}>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {deal.address}
              </DealTD>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {getFullName(deal)}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={prettyFormat(deal.rmMostRecentContactDatetime)}
              >
                {formatDatetimeDifference(deal.rmMostRecentContactDatetime)}
              </DealTD>
              <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                <ListViewCallButton
                  contacts={deal.contacts}
                  externalId={deal.externalId}
                  relationType={"deal"}
                />
              </DealTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const ValuationListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    VISIT_OR_CALL_DATE,
    "ASCENDING",
  );
  const { selectedUser } = useUser();
  const { valuation } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(VALUATION_LIST_VIEW_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [valuation]);

  const results = data?.doPropertyAnalysisDeals?.results || [];

  if (!loading && results.length === 0) {
    return <NoResults message="You have no leads to do a valuation for." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
