import { theme } from "@nest-ui/styles";

export const DeleteIcon = ({ fill = theme.color.text.muted }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={fill} viewBox="0 0 16 16">
    <g fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M13 4.5a.5.5 0 0 1 .497.555l-.901 8.11a1.5 1.5 0 0 1-1.491 1.335h-6.21a1.5 1.5 0 0 1-1.49-1.334l-.902-8.11A.5.5 0 0 1 3 4.5h10zm-.559 1H3.56l.84 7.555a.5.5 0 0 0 .496.445h6.21a.5.5 0 0 0 .497-.445l.84-7.555z"
      />
      <path
        fillRule="nonzero"
        d="M6.748 11.95a.5.5 0 0 1-.996.1l-.5-5a.5.5 0 0 1 .996-.1l.5 5zm3.004-5a.5.5 0 0 1 .996.1l-.5 5a.5.5 0 1 1-.996-.1l.5-5zM6.5 4.5h3V4a1.5 1.5 0 1 0-3 0v.5zm-1 .5V4a2.5 2.5 0 1 1 5 0v1a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5z"
      />
      <path
        fillRule="nonzero"
        d="M14 4.5a.5.5 0 1 1 0 1H2a.5.5 0 0 1 0-1h12z"
      />
    </g>
  </svg>
);

export const Delete = DeleteIcon;
export const BinIcon = DeleteIcon;
