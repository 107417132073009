import { createContext } from "react";

export const WebsocketContext = createContext();

export const WebsocketProvider = ({ children, socket }) => {
  return (
    <WebsocketContext.Provider value={socket}>
      {children}
    </WebsocketContext.Provider>
  );
};
