// @flow

import { gql } from "@apollo/client";

import { LeafCell as Cell } from "components/Grid";
import { NumberField } from "components/NumberField";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { type MutationFunction } from "@apollo/client/react/components";

const PROPERTY_INFORMATION_QUERY = gql`
  query PropertyInformationQuery($id: ID!) {
    property(id: $id) {
      id
      bathrooms
      bedrooms
      floorAreaTotal
    }
  }
`;

const UPDATE_PROPERTY_INFORMATION_MUTATION = gql`
  mutation UpdatePropertyInformation($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      bathrooms
      bedrooms
      floorAreaTotal
    }
  }
`;

const mutationHandler = (mutate, property) => (input: PropertyInput) =>
  mutate({
    variables: { id: property.id, input },
    optimisticResponse: {
      __typename: "Mutation",
      updateProperty: {
        __typename: "Property",
        ...property,
        ...input,
      },
    },
    // Simplest interim solution to keep in sync while we still have sections reading property information off the deal
    refetchQueries: ["NestDeal"],
  });

type Props = {
  propertyId: string,
};

export const PropertyFields = ({ propertyId }: Props) => (
  <ExtendedQuery
    query={PROPERTY_INFORMATION_QUERY}
    variables={{ id: propertyId }}
  >
    {({ property }: ExtendedQueryRenderProps<PropertyInformationQuery>) => (
      <ExtendedMutation mutation={UPDATE_PROPERTY_INFORMATION_MUTATION}>
        {(
          mutate: MutationFunction<
            UpdatePropertyInformation,
            UpdatePropertyInformationVariables,
          >,
        ) => (
          <>
            <Cell width={1}>
              <NumberField
                label="Total floor area (our assessment) (ft2)"
                data-test="property_information:floor_area_total"
                property="floorAreaTotal"
                mutation={mutationHandler(mutate, property)}
                value={property.floorAreaTotal}
              />
            </Cell>
            <Cell with={1}>
              <NumberField
                label="Bedrooms"
                data-test="property_information:bedrooms"
                property="bedrooms"
                mutation={mutationHandler(mutate, property)}
                value={property.bedrooms}
              />
            </Cell>
            <Cell width={1}>
              <NumberField
                label="Bathrooms"
                data-test="property_information:bathrooms"
                property="bathrooms"
                mutation={mutationHandler(mutate, property)}
                value={property.bathrooms}
              />
            </Cell>
          </>
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
