// @flow
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { isEmpty } from "lodash";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { ListViewError } from "@nested/nest/src/components/ListViewError";
import {
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "@nested/nest/src/components/ListViewSharedStyles";
import { MobileListViewBuyerCard } from "@nested/nest/src/components/MobileListViewBuyer";
import {
  PlaceholderTable,
  PlaceholderList,
} from "@nested/nest/src/components/Placeholder";
import { Table, TH, BuyerTD } from "@nested/nest/src/components/Table";
import {
  mobileWrapperStyle,
  buyerTableStyle,
  stickyHeaderStyle,
  callButtonTDStyle,
} from "./StillInterestedList";

export const POTENTIAL_QUERY = gql`
  query potentialNoExistingBpiBuyers($id: ID!) {
    nestDeal(id: $id) {
      id
      unlinkedPotentialBuyers {
        id
        name
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
            subscribedToPropertyMatch
          }
          emails {
            id
            subscribedToPropertyMatch
          }
          avatarUrl
        }
        propertyTypes {
          id
        }
        latestMailoutDatetimeForDeal(dealId: $id)
        lastOutboundCall
        searchAreas {
          id
          name
        }
        maxPrice
        minBedrooms
      }
    }
    buyerPropertyTypes {
      value: id
      label
    }
  }
`;

const getPropertyTypeText = (propertyTypes, buyerPropertyTypes) => {
  if (isEmpty(propertyTypes)) {
    return "flats and houses";
  }
  const labels = propertyTypes.reduce((acc, { id: selectedId }) => {
    const type = buyerPropertyTypes.find(({ value }) => value === selectedId);
    return acc.concat(type.label.toLowerCase());
  }, []);

  return labels.join(" and ");
};

const BuyerTable = ({ buyers, buyerPropertyTypes, loading }) => {
  if (loading && buyers.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div css={buyerTableStyle}>
      <Table css="margin-top: 0;">
        <thead css={stickyHeaderStyle}>
          <tr>
            <TH>Name</TH>
            <TH css="min-width: 130px;">Criteria</TH>
            <TH>Areas</TH>
            <TH>Last mailout</TH>
            <TH>Last outbound call</TH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody css="max-height: 450px;">
          {buyers.map((buyer) => {
            return (
              <tr key={buyer.id} data-test={`buyer-row-${buyer.id}`}>
                <BuyerTD buyerId={buyer.id} className="fs-exclude" narrow>
                  <div>{buyer.name}</div>
                </BuyerTD>
                <BuyerTD buyerId={buyer.id} narrow>
                  <div>
                    {formatPrice(parseFloat(buyer.maxPrice))} -{" "}
                    {buyer.minBedrooms} bed
                  </div>
                  <div>
                    {getPropertyTypeText(
                      buyer.propertyTypes,
                      buyerPropertyTypes,
                    )}
                  </div>
                </BuyerTD>
                <BuyerTD buyerId={buyer.id} narrow>
                  {buyer.searchAreas.map(({ name }) => name).join(", ")}
                </BuyerTD>
                <BuyerTD
                  buyerId={buyer.id}
                  title={
                    buyer.latestMailoutDatetimeForDeal &&
                    moment(buyer.latestMailoutDatetimeForDeal).format("LLLL")
                  }
                  narrow
                >
                  {buyer.latestMailoutDatetimeForDeal
                    ? formatDatetimeDifference(
                        buyer.latestMailoutDatetimeForDeal,
                      )
                    : "-"}
                </BuyerTD>
                <BuyerTD
                  buyerId={buyer.id}
                  title={
                    buyer.lastOutboundCall &&
                    moment(buyer.lastOutboundCall).format("LLLL")
                  }
                  narrow
                >
                  {buyer.lastOutboundCall
                    ? formatDatetimeDifference(buyer.lastOutboundCall)
                    : "-"}
                </BuyerTD>
                <BuyerTD buyerId={buyer.id} css={callButtonTDStyle} narrow>
                  <ListViewCallButton
                    contacts={buyer.contacts}
                    externalId={buyer.id}
                    relationType={"buyer"}
                  />
                </BuyerTD>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const BuyerList = ({ buyers, buyerPropertyTypes, loading }) => {
  if (loading && buyers.length === 0) {
    return <PlaceholderList />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {buyers.map((buyer) => (
        <MobileListViewBuyerCard
          key={buyer.id}
          buyer={buyer}
          detail={
            <>
              <div>
                {formatPrice(parseFloat(buyer.maxPrice))} - {buyer.minBedrooms}{" "}
                bed{" "}
                {getPropertyTypeText(buyer.propertyTypes, buyerPropertyTypes)}
              </div>
              <div>{buyer.searchAreas.map(({ name }) => name).join(", ")}</div>
            </>
          }
        />
      ))}
    </div>
  );
};

export const PotentialList = ({ id }: { id: string }) => {
  const { data, error, loading } = useQuery(POTENTIAL_QUERY, {
    variables: { id },
  });

  const potentialBuyers = data?.nestDeal?.unlinkedPotentialBuyers || [];

  if (error) {
    return <ListViewError />;
  }

  if (!loading && isEmpty(potentialBuyers)) {
    return <div css="margin-top: 20px;">No potential buyers</div>;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <BuyerList
          buyers={potentialBuyers}
          buyerPropertyTypes={data?.buyerPropertyTypes}
          loading={loading}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <BuyerTable
          buyers={potentialBuyers}
          buyerPropertyTypes={data?.buyerPropertyTypes}
          loading={loading}
        />
      </div>
    </>
  );
};
