// @flow
import * as R from "ramda";
import { withHandlers, mapProps, compose, type HOC } from "recompose";

import { errorHandler } from "@nested/utils/graphql/errorHandler";

const dealGraphQLErrors = {
  onSubmit({ onSubmit }) {
    return function onSubmitWithErrorHandling(...args) {
      return onSubmit(...args).catch(errorHandler);
    };
  },
};

const omitProps = compose(mapProps, R.omit)(["submit"]);

export const withGraphqlErrors = (compose(
  withHandlers(dealGraphQLErrors),
  omitProps,
): HOC<*, *>);
