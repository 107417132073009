import T from "prop-types";
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { RadioButtons } from "components/RadioButtons";
import { CurrencyField } from "components/CurrencyField";

const FeesLegacyGuarantee = ({ nestDeal: { dealTypeLegacyGuarantee } }) => (
  <>
    <LeafCell>
      <PercentageField
        property="agencyFeeIncVat"
        label="Agency fee % (inc VAT)"
        value={dealTypeLegacyGuarantee.agencyFeeIncVat}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="upsideShareIncVat"
        label="Upside share % (inc VAT)"
        value={dealTypeLegacyGuarantee.upsideShareIncVat}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="feeCapIncVat"
        label="Fee cap % (inc VAT)"
        value={dealTypeLegacyGuarantee.feeCapIncVat}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="oldFlatEarlyPaymentFee"
        label="Early payment fee %"
        value={dealTypeLegacyGuarantee.oldFlatEarlyPaymentFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        property="referralDiscount"
        label="Referral discount"
        value={dealTypeLegacyGuarantee.referralDiscount}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <RadioButtons
        property="rebate"
        options={["No", "Yes"]}
        label="Rebate"
        value={dealTypeLegacyGuarantee.rebate}
        readOnly
      />
    </LeafCell>
  </>
);

FeesLegacyGuarantee.propTypes = {
  nestDeal: T.object,
};

export { FeesLegacyGuarantee };
