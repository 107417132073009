// @flow
import { gql } from "@apollo/client";
import { kebabCase } from "lodash";
import { type MutationFunction } from "@apollo/client/react/components";
import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { UploadHomeReport } from "tabs/Listing/PortalDetails/UploadHomeReport";
import { Label } from "components/Label";

const PORTAL_DETAILS_QUERY = gql`
  query PortalDetailsQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      virtualViewingUrl
      agentReference
    }
  }
`;

const PORTAL_DETAILS_MUTATION = gql`
  mutation UpdatePortalDetails($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      virtualViewingUrl
    }
  }
`;

export const PortalDetails = ({
  parentId: externalDealId,
}: {|
  parentId: string,
|}) => {
  return (
    <ExtendedQuery
      query={PORTAL_DETAILS_QUERY}
      variables={{ id: externalDealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<PortalDetailsQuery>) => (
        <ExtendedMutation mutation={PORTAL_DETAILS_MUTATION}>
          {(
            updatePortalDetails: MutationFunction<
              UpdatePortalDetails,
              UpdatePortalDetailsVariables,
            >,
          ) => {
            const fieldProps = (key) => ({
              "data-test": `portal-details:${kebabCase(key)}`,
              mutation: (input) =>
                updatePortalDetails({
                  variables: {
                    id: externalDealId,
                    input,
                  },
                  optimisticResponse: {
                    updateNestDeal: {
                      ...nestDeal,
                      ...input,
                    },
                  },
                }),
              property: key,
              value: nestDeal[key],
            });

            return (
              <Grid columns={4}>
                <LeafCell width={3}>
                  <TextField
                    data-test="portal-details:agent-reference"
                    label="Agent Reference (copy/paste this into Rightmove/Zoopla listing form)"
                    property="agentReference"
                    disabled
                    value={nestDeal.agentReference}
                  />
                </LeafCell>
                <LeafCell width={3}>
                  <TextField
                    {...fieldProps("virtualViewingUrl")}
                    label="Virtual Viewing URL"
                  />
                </LeafCell>

                <LeafCell width={3}>
                  <Label>
                    {"Home Report (Scotland only - visible to buyer)"}
                  </Label>
                </LeafCell>

                <LeafCell width={3}>
                  <UploadHomeReport dealId={nestDeal.id} />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
};
