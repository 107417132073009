// @flow
import { css } from "styled-components";
import { useForm, Field } from "react-final-form";
import { media } from "@nested/brand";
import {
  Checkbox,
  SelectAllCheckbox,
} from "@nested/nest/src/components/Checkbox/Checkbox";
import { modalHeader } from "tabs/Interest/PotentialBuyers/MailoutModal/MailoutModal";
import { BackButton } from "@nested/nest/src/components/BackButton";
import { AddContactButton } from "@nested/nest/src/components/ContactManagement/ContactsTab";

const contactSelectWrapper = css`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  ${media.tablet`
    padding: 0;
    flex-direction: row;
  `}
`;

const contactList = css`
  width: 100%;

  ${media.tablet`
    width: 50%;
    padding: 20px;
  `}
`;

const selectAllWrapper = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 17px;

  ${media.tablet`
    margin-top: 0;
  `}
`;

const selectAllHeading = css`
  font-weight: 500;
  padding-left: 5px;
`;

const contactWrapper = css`
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  }
`;

const contactName = css`
  font-weight: 500;
  margin-bottom: ${({ name }) => (name === "" ? "0px" : "5px")};
`;

type Props = {
  onBack: () => void,
  data: MemoOfSaleModalQuery,
  onAddSellerContactButtonClick: () => void,
  onAddBuyerContactButtonClick: () => void,
};

export const MemoOfSaleSelectContacts = ({
  onBack,
  data,
  onAddSellerContactButtonClick,
  onAddBuyerContactButtonClick,
}: Props) => {
  const form = useForm();
  const selectedContacts = form.getState().values?.selectedContacts || {};

  const sellerSolicitor = data?.buyerOffer?.deal?.leadSolicitor;
  const sellerContacts = data?.buyerOffer?.deal?.contacts || [];

  const addedSellerCcEmails = form.getState().values?.addedSellerCcEmails || [];
  const addedBuyerCcEmails = form.getState().values?.addedBuyerCcEmails || [];

  const sellerCcContacts = addedSellerCcEmails.map((email) => {
    return { name: "", id: email, emailAddresses: [email] };
  });

  const buyerCcContacts = addedBuyerCcEmails.map((email) => {
    return { name: "", id: email, emailAddresses: [email] };
  });

  const buyerSolicitorName = data?.buyerOffer?.solicitorLead;
  const buyerSolicitorEmail = data?.buyerOffer?.solicitorEmail;
  const buyerContacts = data?.buyerOffer?.buyer?.contacts || [];

  const solicitorSelected = (buyerOrSeller) =>
    selectedContacts[`solicitor:${buyerOrSeller}`];

  const allSelected = (contacts, ccContactEmails, buyerOrSeller) => {
    return (
      solicitorSelected(buyerOrSeller) &&
      contacts.every(
        ({ id }) => selectedContacts[`${buyerOrSeller}-contact:${id}`],
      ) &&
      ccContactEmails.every(
        ({ id }) => selectedContacts[`${buyerOrSeller}-cc-email:${id}`],
      )
    );
  };

  const noneSelected = (contacts, ccContactEmails, buyerOrSeller) =>
    !solicitorSelected(buyerOrSeller) &&
    contacts.every(
      ({ id }) => !selectedContacts[`${buyerOrSeller}-contact:${id}`],
    ) &&
    ccContactEmails.every(
      ({ id }) => !selectedContacts[`${buyerOrSeller}-cc-email:${id}`],
    );

  const selectAll = (contacts, ccContactEmails, buyerOrSeller) => {
    const value = !allSelected(contacts, ccContactEmails, buyerOrSeller);

    form.change(`selectedContacts[solicitor:${buyerOrSeller}]`, value);

    contacts.forEach(({ id }) => {
      form.change(`selectedContacts[${buyerOrSeller}-contact:${id}]`, value);
    });

    ccContactEmails.forEach(({ id }) => {
      form.change(`selectedContacts[${buyerOrSeller}-cc-email:${id}]`, value);
    });
  };

  return (
    <div css="flex-grow: 1;">
      <div css={modalHeader}>
        <BackButton onClick={onBack} />
        Choose contacts
      </div>
      <div css={contactSelectWrapper}>
        <div css={contactList}>
          <div css={selectAllWrapper}>
            <div>
              <SelectAllCheckbox
                id="select-all-sellers"
                noneSelected={noneSelected(
                  sellerContacts,
                  sellerCcContacts,
                  "seller",
                )}
                allSelected={allSelected(
                  sellerContacts,
                  sellerCcContacts,
                  "seller",
                )}
                onChange={() =>
                  selectAll(sellerContacts, sellerCcContacts, "seller")
                }
              />
            </div>
            <div css={selectAllHeading}>Seller contacts</div>
          </div>
          {sellerSolicitor && (
            <SelectableContact
              id="seller"
              type="solicitor"
              name={sellerSolicitor.name}
              emailAddresses={[sellerSolicitor.email]}
            />
          )}
          {sellerContacts.map((contact) => (
            <SelectableContact
              id={contact.id}
              type="seller-contact"
              name={contact.name}
              emailAddresses={contact.emailAddresses}
            />
          ))}
          {sellerCcContacts.map((ccContact) => (
            <SelectableContact
              id={ccContact.id}
              type="seller-cc-email"
              name={ccContact.name}
              emailAddresses={ccContact.emailAddresses}
            />
          ))}
          <AddContactButton
            data-test={"add-seller-contact-button"}
            onClick={() => onAddSellerContactButtonClick()}
          />
        </div>
        <div css={contactList}>
          <div css={selectAllWrapper}>
            <div>
              <SelectAllCheckbox
                id="select-all-buyers"
                noneSelected={noneSelected(
                  buyerContacts,
                  buyerCcContacts,
                  "buyer",
                )}
                allSelected={allSelected(
                  buyerContacts,
                  buyerCcContacts,
                  "buyer",
                )}
                onChange={() =>
                  selectAll(buyerContacts, buyerCcContacts, "buyer")
                }
              />
            </div>
            <div css={selectAllHeading}>Buyer contacts</div>
          </div>
          {buyerSolicitorEmail && (
            <SelectableContact
              id="buyer"
              type="solicitor"
              name={buyerSolicitorName}
              emailAddresses={[buyerSolicitorEmail]}
            />
          )}
          {buyerContacts.map((contact) => (
            <SelectableContact
              id={contact.id}
              type="buyer-contact"
              name={contact.name}
              emailAddresses={contact.emailAddresses}
            />
          ))}
          {buyerCcContacts.map((ccContact) => (
            <SelectableContact
              id={ccContact.id}
              type="buyer-cc-email"
              name={ccContact.name}
              emailAddresses={ccContact.emailAddresses}
            />
          ))}
          <AddContactButton
            data-test={"add-buyer-contact-button"}
            onClick={() => onAddBuyerContactButtonClick()}
          />
        </div>
      </div>
    </div>
  );
};

const SelectableContact = ({ id, type, name, emailAddresses }) => (
  <Field type="checkbox" name={`selectedContacts[${type}:${id}]`}>
    {({ input }) => (
      <div css={contactWrapper}>
        <div>
          <Checkbox id={`select-${type}-${id}`} {...input} />
        </div>
        <div>
          <div css={contactName} name={name}>
            <label htmlFor={`select-${type}-${id}`}>{name}</label>
          </div>
          {emailAddresses.map((email) => (
            <div>{email}</div>
          ))}
        </div>
      </div>
    )}
  </Field>
);
