// @flow
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import {
  Gravatar as OriginalGravatar,
  mediumParagraph,
  smallParagraph,
} from "@nested/brand";
import { Heading, QuestionWrapper, ImageCardOption } from "../components";
import { type QuestionProps } from "../components/MultiStageForm";

const columnFlex = css`
  display: flex;
  flex-direction: column;
`;

const teamWrapper = css`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const employeeName = css`
  ${mediumParagraph}
  margin: 5px 0 0 0;
`;

const employeeJobTitle = css`
  ${smallParagraph}
  font-style: italic;
  margin: 0;
`;

const employeeDescription = css`
  ${smallParagraph}
  margin-bottom: 0;
`;

const Gravatar = styled(OriginalGravatar)`
  width: 70px;
  height: 70px;
`;

export const RM_QUERY = gql`
  query ReasonForContactRM($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      normalisedPostcode
      relationshipManager {
        id
        bio
        email
        firstName
        homeVisitBookingUrl
        introCallBookingUrl
        jobTitle
        lastName
        virtualHomeVisitBookingUrl
      }
    }
  }
`;

const NotLocalAreaSignPost = ({
  BackButton,
  next,
  homeVisitBookingUrl,
  introCallBookingUrl,
}: *) => (
  <QuestionWrapper dataTest="sign-post-question">
    <BackButton />
    <Heading>How can we help?</Heading>
    {introCallBookingUrl && (
      <ImageCardOption
        onClick={() => next("INTRO_CALL")}
        dataTest="intro-call"
        heading="Book a call"
      />
    )}
    {homeVisitBookingUrl && (
      <ImageCardOption
        onClick={() => next("HOME_VISIT")}
        dataTest="home-visit"
        heading="Book an in-person visit"
      />
    )}
    <ImageCardOption
      onClick={() => next(null)}
      dataTest="skip-button"
      heading="View my account"
    />
  </QuestionWrapper>
);

const LocalAreaSignPost = ({ next, rm, BackButton }: *) => (
  <QuestionWrapper dataTest="sign-post-question">
    <BackButton />
    <Heading>How can we help?</Heading>
    <div css={teamWrapper}>
      <Gravatar className="chromatic-ignore" email={rm.email} />
      <div css={columnFlex}>
        <h4 css={employeeName}>
          <strong>
            {rm.firstName} {rm.lastName}
          </strong>
        </h4>
        <p css={employeeJobTitle}>{rm.jobTitle}</p>
        <p css={employeeDescription}>{rm.bio}</p>
      </div>
    </div>
    <ImageCardOption
      onClick={() => next("INTRO_CALL")}
      dataTest="intro-call"
      heading="Book a call"
    />
    <ImageCardOption
      onClick={() => next("HOME_VISIT")}
      dataTest="home-visit"
      heading="Book an in-person visit"
    />
    <ImageCardOption
      onClick={() => next(null)}
      dataTest="skip-button"
      heading="View my account"
    />
  </QuestionWrapper>
);

export const SignPost = (props: QuestionProps<"reasonForContact">) => {
  const {
    address: { postcode },
    abTest,
  } = useSelector((state) => state.onboardingForm);

  const { data, loading } = useQuery(RM_QUERY, { variables: { postcode } });
  if (loading) {
    return null;
  }

  const rm = data?.postcodeDetails?.relationshipManager;

  if (
    rm?.bio &&
    rm?.homeVisitBookingUrl &&
    rm?.introCallBookingUrl &&
    rm?.jobTitle
  ) {
    return <LocalAreaSignPost {...props} abTest={abTest} rm={rm} />;
  }

  return (
    <NotLocalAreaSignPost
      {...props}
      homeVisitBookingUrl={Boolean(rm?.homeVisitBookingUrl)}
      introCallBookingUrl={Boolean(rm?.introCallBookingUrl)}
    />
  );
};
