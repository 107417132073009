// @flow
export const Tick = ({ fill = "#353F52" }: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M11.01 4.91c.27-.28.71-.28.98 0 .28.27.28.71 0 .98l-4.9 5.25c-.27.28-.71.28-.98 0l-2.1-2.1a.677.677 0 0 1 0-.98c.27-.28.71-.28.98 0l1.61 1.6 4.41-4.75z"
    />
  </svg>
);
