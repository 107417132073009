// @flow

import { LeafCell } from "components/Grid/Grid";
import { CurrencyField } from "components/CurrencyField";
import styled from "styled-components";
import { ListForm } from "./ListForm";

export const List = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    button {
      cursor: pointer;
      outline: none;
      border: none;
      padding: 0;
      background-color: transparent;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
  li:hover {
    background-color: ${({ theme }) => theme.color.primary.accent};
  }
`;

const reasonsForFirstOfferDetails = {
  listToSubmit: (report, { reasonForFirstOffer }) => {
    if (report.reasonsForFirstOffer) {
      return {
        reasonsForFirstOffer:
          report.reasonsForFirstOffer.concat(reasonForFirstOffer),
      };
    }
    return { reasonsForFirstOffer: [reasonForFirstOffer] };
  },
  list: (report) => report.reasonsForFirstOffer,
  objectList: (newList) => ({
    reasonsForFirstOffer: newList,
  }),
  formElementName: "reasonForFirstOffer",
  inputLabel: "Reason for first offer",
  inputDataTest: "reason-for-first-offer-input",
  inputPlaceholder: "Add a reason for first offer here",
  addButtonDataTest: "add-reason-for-first-offer-button",
  addButtonLabel: "Add reason for first offer",
  listItemDataTest: "reasons-for-first-offer-item",
  removeButtonDataTest: "remove-reason-for-first-offer-button",
  noElementsMessage: "No reasons for first offer added.",
};

const thingsToConsiderDetails = {
  listToSubmit: (report, { thingToConsider }) => {
    if (report.thingsToConsider) {
      return {
        thingsToConsider: report.thingsToConsider.concat(thingToConsider),
      };
    }
    return { thingsToConsider: [thingToConsider] };
  },
  list: (report) => report.thingsToConsider,
  objectList: (newList) => ({
    thingsToConsider: newList,
  }),
  formElementName: "thingToConsider",
  inputLabel: "Thing to consider",
  inputDataTest: "thing-to-consider-input",
  inputPlaceholder: "Add a thing to consider here",
  addButtonDataTest: "add-thing-to-consider-button",
  addButtonLabel: "Add thing to consider",
  listItemDataTest: "things-to-consider-item",
  removeButtonDataTest: "remove-thing-to-consider-button",
  noElementsMessage: "No things to consider added.",
};

type Props = {
  submitHandler: (any) => Promise<any>,
  report: getReports_buyersAgentReports,
};

export const PropertyOverviewSection = ({ submitHandler, report }: Props) => (
  <>
    <LeafCell width={2}>
      <CurrencyField
        data-test="suggested-starting-offer"
        label="Suggested Starting Offer"
        mutation={submitHandler}
        property="suggestedStartingOffer"
        value={report.suggestedStartingOffer}
      />
    </LeafCell>
    <LeafCell width={2}>
      <CurrencyField
        data-test="suggested-max-offer"
        label="Suggested Max Offer"
        mutation={submitHandler}
        property="suggestedMaxOffer"
        value={report.suggestedMaxOffer}
      />
    </LeafCell>
    <ListForm
      report={report}
      submitHandler={submitHandler}
      details={reasonsForFirstOfferDetails}
    />
    <ListForm
      report={report}
      submitHandler={submitHandler}
      details={thingsToConsiderDetails}
    />
  </>
);
