import T from "prop-types";
import styled from "styled-components";
import Linkify from "react-linkify";

import {
  sharedDefaultStyles,
  sharedTextareaStyles,
} from "components/TextField";
import { Label } from "components/Label";
import { isPresent } from "@nested/utils/isPresent";

// Is wrapped in a Linkify and displays the text.
// Linkify traverses its children to find links.
const P = styled.div`
  ${sharedDefaultStyles} ${sharedTextareaStyles}
  background-color: white;
  font-family: ${({ theme }) => theme.font.family.default};
  color: ${({ theme, value }) =>
    value ? theme.color.text.default : theme.color.text.muted};
  white-space: pre-wrap;
`;

P.propTypes = {
  highlight: T.bool,
};

// Linkify will accept a custom component to display links.
// We use the NoPropagateLink in order to open links
// in a new window and prevent clicks bubbling up to
// the textarea, focusing it.
const A = styled.a`
  outline: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  cursor: pointer;
  text-decoration: underline;
`;

const NoPropagateLink = ({ href, ...rest }) => (
  <A
    {...rest}
    onFocus={(e) => e.stopPropagation()}
    onClick={() => window.open(href, "_blank")}
    tabIndex={-1}
  />
);

NoPropagateLink.propTypes = {
  href: T.string,
};

const LinkPut = styled(Linkify)`
  max-width: 100%;
  word-wrap: break-word;
  cursor: text;
`;

// A LinkifiedTextArea is a div that masquerades as a textarea
// and converts URLs to hyperlinks.
export const LinkifiedTextArea = ({
  label,
  placeholder,
  value,
  className,
  onFocus,
  highlight,
  highlightRed,
}) => (
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  <div tabIndex="0" onFocus={onFocus}>
    <Label>{label}</Label>
    <LinkPut component={NoPropagateLink} properties={{ target: "_blank" }}>
      <P
        className={className}
        value={value}
        highlight={highlight}
        highlightRed={highlightRed}
      >
        {isPresent(value) ? value : placeholder}
      </P>
    </LinkPut>
  </div>
);

LinkifiedTextArea.propTypes = {
  label: T.string,
  placeholder: T.string,
  value: T.oneOfType([T.string, T.number]),
  className: T.string,
  onFocus: T.func,
  highlight: T.bool,
  highlightRed: T.bool,
};

export { NoPropagateLink };
