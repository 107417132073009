// @flow
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { ProgressionSummary } from "./ProgressionSummary/ProgressionSummary";
import { TabWrapper, TabContainer } from "../TabWrapper";
import { MilestonesList } from "./MilestonesList";

const PROGRESSION_TAB_QUERY = gql`
  query ProgressionTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      agreedBuyerOffer {
        id
      }
      acceptedBuyerOffer {
        id
      }
      id
    }
  }
`;

export const Progression = () => {
  const { id: idOrExternalDealId } = useParams();
  const { data } = useQuery(PROGRESSION_TAB_QUERY, {
    variables: { idOrExternalDealId },
  });

  if (!data) return null;

  const { nestDeal } = data;
  const { acceptedBuyerOffer, agreedBuyerOffer, id } = nestDeal;
  const buyerOffer = agreedBuyerOffer || acceptedBuyerOffer;

  return (
    <TabWrapper>
      <TabContainer id="Progression-tab">
        <ProgressionSummary dealId={id} buyerOfferId={buyerOffer?.id} />
        <MilestonesList buyerOfferId={buyerOffer?.id} dealId={id} />
      </TabContainer>
    </TabWrapper>
  );
};
