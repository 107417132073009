// @flow
import type { MutationFunction } from "@apollo/client/react/components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

import { Grid, Cell, LeafCell } from "components/Grid";
import { Heading } from "components/Heading";
import { SelectField } from "components/SelectField";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { RadioButtons } from "components/RadioButtons";
import { yesNoBooleanOptions } from "@nest-ui/shared/options";
import { TextField } from "components/TextField";

const submittedToVendorMandatoryFields = [
  "amount",
  "placedOn",
  "offerConditions",
  "timeFrameConditions",
  "lengthOfDownwardChain",
  "buyerFinanceMethod",
  "saWhoGotTheOffer",
  "buyerIsNestedCustomer",
];

const agreedPendingMemoMandatoryOfferFields = [
  "solicitorFirm",
  "solicitorLead",
  "solicitorPhone",
  "solicitorEmail",
  "solicitorAddress",
  "depositAmount",
  "depositPercentage",
  "buyerHasDownwardChain",
];

const agreedPendingMemoMortgageFields = [
  "mortgageBrokerFirm",
  "mortgageBrokerLead",
  "mortgageBrokerPhone",
  "mortgageBrokerEmail",
];

const agreedPendingMemoMandatoryChainLinkFields = [
  "estateAgent",
  "leadAgentName",
  "agentPhoneNumber",
  "agentEmail",
];

const formatMissingFieldName = (missingFieldName) => {
  switch (missingFieldName) {
    // fields on the buyer offer
    case "amount":
      return "Offer amount";
    case "buyerFinanceMethod":
      return "Buyer finance";
    case "buyerIsNestedCustomer":
      return "Is the buyer selling with Nested too?";
    case "buyerHasDownwardChain":
      return "Is the buyer in a downward chain?";
    case "depositAmount":
      return "Deposit (£)";
    case "depositPercentage":
      return "Deposit (%)";
    case "lengthOfDownwardChain":
      return "Length of downward chain";
    case "mortgageBrokerEmail":
      return "Mortgage broker email";
    case "mortgageBrokerFirm":
      return "Mortgage broking firm";
    case "mortgageBrokerLead":
      return "Lead broker";
    case "mortgageBrokerPhone":
      return "Mortgage broker phone number";
    case "offerConditions":
      return "Offer conditions";
    case "placedOn":
      return "Date offer made";
    case "saWhoGotTheOffer":
      return "SA who got the offer";
    case "solicitorAddress":
      return "Buyer's solicitor address";
    case "solicitorEmail":
      return "Buyer's solicitor email";
    case "solicitorFirm":
      return "Buyer's solicitor firm";
    case "solicitorLead":
      return "Buyer's lead solicitor";
    case "solicitorPhone":
      return "Buyer's solicitor phone number";
    case "timeFrameConditions":
      return "Time frame conditions";

    // fields on links in the chain
    case "agentEmail":
      return "Agent email";
    case "agentPhoneNumber":
      return "Agent phone number";
    case "estateAgent":
      return "Estate agent";
    case "leadAgentName":
      return "Lead agent name";

    default:
      return "Unknown field";
  }
};

const getMissingFieldsForBuyerOffer = (mandatoryFields, buyerOffer) => {
  const missingFields = mandatoryFields
    .filter((key) => buyerOffer[key] === null)
    .map((fieldName) => formatMissingFieldName(fieldName));
  return missingFields;
};

const getMissingFieldsForChainLinkAgreedPendingMemo = (chainLink) => {
  const nameOrAddress =
    chainLink.address || chainLink.contactName
      ? []
      : ["Address of property to be sold or Vendor name"];

  const missingFields = agreedPendingMemoMandatoryChainLinkFields
    .filter((key) => chainLink[key] === null)
    .map((fieldName) => formatMissingFieldName(fieldName));

  return nameOrAddress.concat(missingFields);
};

const getMissingFieldsForChainAgreedPendingMemo = (propertyChainLinks) => {
  const chainMembersWithMissingFields = propertyChainLinks
    .map((chainLink) =>
      getMissingFieldsForChainLinkAgreedPendingMemo(chainLink),
    )
    .filter((missingFields) => missingFields.length > 0);

  return chainMembersWithMissingFields.map(
    (missingFields) =>
      `(on one of the downward chain) ${missingFields.join(", ")}`,
  );
};

export const getMissingOrIncorrectChainLength = ({
  lengthOfDownwardChain,
  numBuyerChainLinks,
}: BuyerOffer_buyerOffer) => {
  // lengthOfDownwardChain not filled in
  if (lengthOfDownwardChain === null) {
    return ["Length of downward chain (must match number of chain links)"];
  }

  if (lengthOfDownwardChain === "To be confirmed") {
    return [
      "Length of downward chain (must be confirmed before this offer can be moved to agreed pending memo)",
    ];
  }

  if (lengthOfDownwardChain === "N/A") {
    return [];
  }

  // no chain
  if (!numBuyerChainLinks && lengthOfDownwardChain === "0") {
    return [];
  }

  // 1-9 chain links
  if (
    numBuyerChainLinks &&
    numBuyerChainLinks < 10 &&
    String(numBuyerChainLinks) === lengthOfDownwardChain
  ) {
    return [];
  }

  // 10+ chain links
  if (
    numBuyerChainLinks &&
    numBuyerChainLinks >= 10 &&
    lengthOfDownwardChain === "10+"
  ) {
    return [];
  }

  // numbers do not match up
  return ["Length of downward chain (must match number of chain links)"];
};

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
  refetchMutation: ({
    status: string,
  }) => Promise<BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer>,
  inputOptions: BuyerOffer_inputOptions,
  propertyChainLinks: $ReadOnlyArray<BuyerOffer_propertyChainLinks>,
};

export const BuyerOfferGeneralInfo = ({
  buyerOffer,
  inputOptions,
  mutation,
  propertyChainLinks,
  refetchMutation,
}: Props) => {
  const currentStatus = buyerOffer?.status?.value;
  const changeOfferStatus = async (newStatus) => {
    if (newStatus === "agreed_pending_memo") {
      const missingOfferFields = getMissingFieldsForBuyerOffer(
        agreedPendingMemoMandatoryOfferFields,
        buyerOffer,
      );

      const missingMortgageFields =
        buyerOffer.buyerFinanceMethod?.value === "mortgage"
          ? getMissingFieldsForBuyerOffer(
              agreedPendingMemoMortgageFields,
              buyerOffer,
            )
          : [];
      const missingChainFields = buyerOffer.buyerHasDownwardChain
        ? getMissingFieldsForChainAgreedPendingMemo(propertyChainLinks)
        : [];

      const missingOrIncorrectChainLength =
        getMissingOrIncorrectChainLength(buyerOffer);

      const missingFields = [
        ...missingOrIncorrectChainLength,
        ...missingOfferFields,
        ...missingMortgageFields,
        ...missingChainFields,
      ];

      if (missingFields.length > 0) {
        const alertMessage =
          "The following fields are required to progress the offer to Agreed pending memo:" +
          `\n- ${missingFields.join("\n- ")}` +
          "\nPlease complete these fields and try again.";
        // eslint-disable-next-line no-alert
        window.alert(alertMessage);
        return;
      }
    }

    // This is required for customers that move from collecting details to any other
    // status (e.g. straight to rejected) so that we have the minimum required information
    // for the offer email & offer in the account.
    if (
      buyerOffer?.status?.value === "collecting_details" &&
      newStatus !== "collecting_details"
    ) {
      const missingFields = getMissingFieldsForBuyerOffer(
        submittedToVendorMandatoryFields,
        buyerOffer,
      );

      if (missingFields.length > 0) {
        const alertMessage =
          "The following fields are required to progress the offer beyond Collecting details:" +
          `\n- ${missingFields.join("\n- ")}` +
          "\nPlease complete these fields and try again.";
        // eslint-disable-next-line no-alert
        window.alert(alertMessage);
        return;
      }
      const confirmationMessage =
        "Please confirm you are aware that the following fields will be immediately exposed to the seller in their account: Offer amount, Date offer made, Offer conditions, Time frame conditions, Buyer in a downward chain?, Buyer finance." +
        "\n\n" +
        "We will also send an email to the seller and all buyer contacts confirming that the offer has been received and submitted.\n";
      const confirmation = window.confirm(confirmationMessage); // eslint-disable-line no-alert
      if (!confirmation) {
        return;
      }
    }

    if (currentStatus === "accepted") {
      const confirmationMessage =
        "Warning:\n" +
        "Any Progression info for this offer will be hidden from the Progression tab but still accessible within this offer.\n\n" +
        "If marking offer as fallen through, please add a reason\n\n" +
        "Are you sure you want to change the status?";
      const confirmation = window.confirm(confirmationMessage); // eslint-disable-line no-alert
      if (!confirmation) {
        return;
      }
    }

    if (currentStatus === "no_longer_accepted") {
      const confirmationMessage =
        "Warning:\n" +
        " If the buyer's offer has now been accepted, you should duplicate this fallen-through offer and mark the duplicated one as accepted so we retain the record that this happened.\n\n" +
        "Changing the offer status of this offer will delete the reason the offer fell through.\n\n\n" +
        "Are you sure you want to change the status?";
      const confirmation = window.confirm(confirmationMessage); // eslint-disable-line no-alert
      if (!confirmation) {
        return;
      }
    }

    try {
      await refetchMutation({ status: newStatus });
    } catch (e) {
      if (e.message === "unauthorised" && currentStatus === "accepted") {
        // eslint-disable-next-line no-alert
        window.alert(
          "Only Progressors can document a fallen-through offer. Please contact the progressor involved.",
        );
      } else {
        errorHandler(e);
      }
    }
  };

  const buyerOfferStatusOptions = inputOptions.buyerOfferStatus.reduce(
    (acc, status) => {
      const disableOption =
        (currentStatus === "accepted" &&
          status.value !== "no_longer_accepted") ||
        currentStatus === "no_longer_accepted";

      const option = disableOption
        ? {
            ...status,
            isDisabled: true,
          }
        : status;

      return acc.concat(option);
    },
    [],
  );

  return (
    <Cell width={4}>
      <Grid columns={4}>
        <LeafCell width={4}>
          <Heading level={2}>Offer details (shown to seller)</Heading>
        </LeafCell>
        <LeafCell width={1}>
          <CurrencyField
            label="Offer amount"
            value={buyerOffer.amount}
            mutation={mutation}
            property="amount"
            data-test="buyer-offer-modal-amount"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Date offer made"
            value={buyerOffer.placedOn}
            mutation={mutation}
            property="placedOn"
            data-test="buyer-offer-modal-date"
          />
        </LeafCell>
        <LeafCell width={2}>
          <SelectField
            label="Offer status"
            options={buyerOfferStatusOptions}
            value={buyerOffer?.status?.value}
            mutation={({ status }) => changeOfferStatus(status)}
            property="status"
            propertyChainLinks={propertyChainLinks}
            data-test="buyer-offer-modal-offer-status"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Offer conditions - if none exist, enter 'none' below"
            value={buyerOffer.offerConditions}
            mutation={mutation}
            property="offerConditions"
            data-test="buyer-offer-modal-offer-conditions"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Time frame conditions - if none exist, enter 'none' below"
            value={buyerOffer.timeFrameConditions}
            mutation={mutation}
            property="timeFrameConditions"
            data-test="buyer-offer-modal-offer-frame-conditions"
          />
        </LeafCell>
        <LeafCell width={2} left={1}>
          <SelectField
            label="Length of downward chain"
            value={buyerOffer.lengthOfDownwardChain}
            property="lengthOfDownwardChain"
            options={[
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10+",
              "N/A",
              "To be confirmed",
            ]}
            mutation={mutation}
            data-test="buyer-offer-details-length-of-downward-chain"
          />
        </LeafCell>
        <LeafCell width={2}>
          <SelectField
            label="Buyer finance"
            value={buyerOffer.buyerFinanceMethod}
            property="buyerFinanceMethod"
            optionsName="buyerFinanceMethod"
            options={inputOptions.buyerFinanceMethod}
            mutation={mutation}
            data-test="buyer-offer-details-buyer-finance-method"
          />
        </LeafCell>
        <LeafCell width={1}>
          <RadioButtons
            label="Is the buyer selling with Nested too?"
            value={buyerOffer.buyerIsNestedCustomer}
            options={yesNoBooleanOptions}
            property="buyerIsNestedCustomer"
            mutation={mutation}
            data-test="buyer-offer-details-buyer-is-nested-customer"
          />
        </LeafCell>
      </Grid>
    </Cell>
  );
};
