// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Toggle } from "../../../components/Toggle/Toggle";

const toggleSection = css`
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  ${media.tablet`
    padding: 0;
    border-bottom: none;
  `}
`;

type DealStatusToggleProps = {
  isToggleYes: boolean,
  largeLabel?: boolean,
  onChange: () => void,
  titleOnHover?: ?string,
};

export const DealStatusToggle = ({
  isToggleYes,
  largeLabel,
  onChange,
  titleOnHover,
}: DealStatusToggleProps) => (
  <div css={toggleSection}>
    <Toggle
      checked={isToggleYes}
      labelText="Active"
      largeLabel={largeLabel}
      onChange={onChange}
      titleOnHover={titleOnHover}
      data-test="deal-status-toggle"
    />
  </div>
);
