import { css, keyframes } from "styled-components";
import { getImage } from "@nested/utils";

const wrapperStyle = css`
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  min-height: 250px;
  aspect-ratio: 1;
  position: relative;
`;

const firstLayerAnimation = keyframes`
  from {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-6px);
  }

  to {
    transform: translateY(0px);
  }
`;

const secondLayerAnimation = keyframes`
  from {
    transform: translateX(-50%) translateY(0px);
  }

  50% {
    transform: translateX(-50%) translateY(-4px);
  }

  to {
    transform: translateX(-50%) translateY(0px);
  }
`;

const agentAnimation = keyframes`
  from {
    transform: translateX(-50%) translateY(0px);
  }

  50% {
    transform: translateX(-50%) translateY(-12px);
  }

  to {
    transform: translateX(-50%) translateY(0px);
  }
`;

const shadowAnimation = keyframes`
  from {
    transform: translateX(-50%) scale(1);
    opacity: 0.7;
  }

  50% {
    transform: translateX(-50%) scale(0.75);
    opacity: 0.5;
  }

  to {
    transform: translateX(-50%) scale(1);
    opacity: 0.7;
  }
`;

const firstLayerStyle = css`
  position: absolute;
  width: 100%;
  left: 0;
  top: 25%;
  animation: ${firstLayerAnimation} 4s ease infinite;
`;

const secondLayerStyle = css`
  width: 80%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: ${secondLayerAnimation} 4.2s ease infinite;
`;

const agentStyle = css`
  width: 80%;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  animation: ${agentAnimation} 6s ease infinite;
`;

const shadowStyle = css`
  width: 55%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: ${shadowAnimation} 6s ease infinite;
`;

export const FloatingAgent = () => (
  <div css={wrapperStyle}>
    <img
      css={secondLayerStyle}
      alt=""
      src={getImage("illustrations/floating-agent/second-layer.png?w=600")}
    />
    <img
      css={firstLayerStyle}
      alt=""
      src={getImage("illustrations/floating-agent/first-layer.png?w=600")}
    />
    <img
      css={agentStyle}
      alt=""
      src={getImage("illustrations/floating-agent/agent.png?w=600")}
    />
    <img
      css={shadowStyle}
      alt=""
      src={getImage("illustrations/floating-agent/shadow.png?w=600")}
    />
  </div>
);
