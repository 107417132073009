// @flow
import styled from "styled-components";
import { H2 } from "components/Heading";

export const Card = styled.section.attrs({ className: "partyable" })`
  position: relative;
  margin-bottom: 16px;
  margin-top: 16px;
  border-radius: 4px;
  vertical-align: top;
  width: 100%;
  padding: 16px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.hague20};

  & > div:first-child {
    margin-top: 0;
  }

  ${H2} {
    &:after {
      display: none;
    }
    margin: 0px;
    margin-bottom: 16px;
  }
`;
