// @flow
import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { useQuery } from "@apollo/client/react/hooks";
import { Spinner } from "@nested/ui";
import {
  itemDeletionConfirmed,
  DeleteButtonWithIcon as DeleteButton,
} from "components/DeleteButton";
import { SingleFileUploadButton } from "components/UploadSingleFile/SingleFileUploadButton";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { css } from "styled-components";

const alignItemsStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const uploadedHomeReportLinkStyle = css`
  font-weight: 500;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.hague70};
  padding: 0px 10px 0 0;
`;

export const DELETE_HOME_REPORT_MUTATION = gql`
  mutation DeleteHomeReportMutation($dealId: ID!) {
    deleteHomeReport(dealId: $dealId) {
      id
    }
  }
`;

export const UPLOAD_HOME_REPORT_MUTATION = gql`
  mutation UploadHomeReportMutation($input: UploadHomeReportInput!) {
    uploadHomeReport(input: $input) {
      id
      fileName
      downloadUrl
    }
  }
`;

export const HOME_REPORT_FILENAME_QUERY = gql`
  query homeReportQuery($dealId: ID!) {
    homeReport(dealId: $dealId) {
      id
      fileName
      downloadUrl
    }
  }
`;

type Props = {
  dealId: string,
};

export const UploadHomeReport = ({ dealId }: Props) => {
  const [deleteHomeReport, { loading: deletingHomeReport }] = useMutation(
    DELETE_HOME_REPORT_MUTATION,
    {
      refetchQueries: [
        {
          query: HOME_REPORT_FILENAME_QUERY,
          variables: { dealId },
        },
      ],
    },
  );

  const { data, loading: loadingFileName } = useQuery(
    HOME_REPORT_FILENAME_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: { dealId },
    },
  );

  const [uploadFile, { loading: fileUploading }] = useMutation(
    UPLOAD_HOME_REPORT_MUTATION,
  );

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState(
    uploadedFile?.uploadHomeReport?.fileName || data?.homeReport?.fileName,
  );
  const [downloadUrl, setDownloadUrl] = useState(
    uploadedFile?.uploadHomeReport?.downloadUrl ||
      data?.homeReport?.downloadUrl,
  );
  const [fileHasBeenUploaded, setFileHasBeenUploaded] = useState(
    uploadedFile?.uploadHomeReport || data?.homeReport,
  );

  useEffect(() => {
    setFileName(
      uploadedFile?.uploadHomeReport?.fileName || data?.homeReport?.fileName,
    );
    setDownloadUrl(
      uploadedFile?.uploadHomeReport?.downloadUrl ||
        data?.homeReport?.downloadUrl,
    );

    setFileHasBeenUploaded(
      !!(uploadedFile?.uploadHomeReport || data?.homeReport),
    );
  }, [data, uploadedFile]);

  if (loadingFileName || deletingHomeReport || fileUploading) {
    return (
      <div css={alignItemsStyle}>
        <Spinner />
      </div>
    );
  }

  const triggerDeleteHomeReport = async () => {
    try {
      const result = await deleteHomeReport({
        variables: {
          dealId,
        },
      });

      if (result?.data) {
        setUploadedFile(null);
        setFileName(null);
        setFileHasBeenUploaded(false);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const triggerFileUpload = async (file) => {
    try {
      const result = await uploadFile({
        variables: {
          input: {
            dealId,
            file,
          },
        },
      });

      if (result?.data) {
        setUploadedFile(result?.data);
        setFileHasBeenUploaded(true);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <div css={alignItemsStyle}>
        {!fileHasBeenUploaded && (
          <SingleFileUploadButton
            allowedFileFormats={["application/pdf"]}
            maxAllowedSizeInBytes={8_000_000}
            additionalAlertMessage={
              "Please upload a PDF file no larger than 8MB."
            }
            onChange={(file) => {
              triggerFileUpload(file);
            }}
          />
        )}
        {fileHasBeenUploaded && (
          <div>
            <div css={uploadedHomeReportLinkStyle}>
              <a href={downloadUrl} target="_blank">
                {fileName}
              </a>
            </div>
            <DeleteButton
              onSubmit={() => {
                if (
                  itemDeletionConfirmed(
                    "Are you sure you want to delete the home report?",
                  )
                ) {
                  triggerDeleteHomeReport();
                }
              }}
              data-test="upload-home-report-delete-button"
            />
          </div>
        )}
      </div>
    </>
  );
};
