// @flow
import { Route, Switch } from "react-router-dom";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../../components/Navigation/Breadcrumbs";
import { UnderOfferBuyerListView } from "./UnderOfferBuyerListView";
import { ListViewPageWrapper } from "../../../components/ListViewSharedStyles";

export const UnderOffer = () => {
  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Buyers</Breadcrumb>
        <Breadcrumb to="#">Under offer</Breadcrumb>
      </Breadcrumbs>
      <Switch>
        <Route exact path="/buyers/under-offer">
          <UnderOfferBuyerListView />
        </Route>
      </Switch>
    </ListViewPageWrapper>
  );
};
