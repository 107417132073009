// @flow

import { NoSubmitCreateButton } from "components/CreateButton";

type ScheduleRmContactButtonProps = {
  "data-test": string,
  scheduleValuationMutation: () => {},
  disabled: boolean,
  sendCalendarInvite: null | boolean,
  isValuationScheduledInThePast: boolean,
};

const rmTaskConfirmation = (
  sendCalendarInvite,
  isValuationScheduledInThePast,
) => {
  if (sendCalendarInvite && !isValuationScheduledInThePast) {
    // eslint-disable-next-line no-alert
    return window.confirm(
      "Note: This will email the customer, create a calendar event for the AP and customer, and create a task in the Nest. Please confirm.\n",
    );
  }
  return true;
};

const getLabel = (sendCalendarInvite, isValuationScheduledInThePast) => {
  if (isValuationScheduledInThePast) {
    return "Create";
  }

  if (sendCalendarInvite) {
    return "Create task, event & email";
  }

  return "Create";
};

export const ScheduleRmContactButton = ({
  "data-test": dataTest,
  scheduleValuationMutation,
  sendCalendarInvite,
  isValuationScheduledInThePast,
  disabled = false,
}: ScheduleRmContactButtonProps) => {
  return (
    <NoSubmitCreateButton
      onSubmit={() => {
        if (
          rmTaskConfirmation(sendCalendarInvite, isValuationScheduledInThePast)
        ) {
          return scheduleValuationMutation();
        }
        return null;
      }}
      data-test={dataTest}
      label={getLabel(sendCalendarInvite, isValuationScheduledInThePast)}
      sendCalendarInvite={sendCalendarInvite}
      disabled={disabled}
    />
  );
};
