import T from "prop-types";
import styled, { css } from "styled-components";

import { Label } from "components/Label";

const disabledStyles = css`
  min-height: ${({ theme }) => theme.input.height};
  padding: ${({ theme }) => theme.input.padding};
  background-color: ${({ theme }) => theme.color.background.muted};
  border-radius: ${({ theme }) => theme.input.border.radius};
  border: ${({ theme }) =>
    `${theme.input.border.width} solid ${theme.color.background.muted}`};
`;

const highlightedStyles = css`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: ${({ theme, isSecondary }) =>
    isSecondary ? "lightGrey" : theme.color.text.highlight};
`;

const ReadOnlyText = styled.div`
  color: ${({ theme }) => theme.color.text.default};
  font-size: ${({ theme }) => theme.font.size.default};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  ${({ disabled }) => disabled && disabledStyles};
  ${({ highlight }) => highlight && highlightedStyles};
`;

const ReadOnlyField = ({
  dataTest,
  label,
  value,
  disabled,
  highlight,
  isSecondary,
  className,
}) => (
  <div className={className} data-test={dataTest}>
    {label && <Label>{label}</Label>}
    <ReadOnlyText
      highlight={highlight}
      disabled={disabled}
      isSecondary={isSecondary}
    >
      {value || "--"}
    </ReadOnlyText>
  </div>
);

ReadOnlyField.propTypes = {
  label: T.string,
  disabled: T.bool,
  value: T.oneOfType([T.string, T.node]),
  highlight: T.bool,
  isSecondary: T.bool,
};

export { ReadOnlyField };
