// @flow
import type { MutationFunction } from "@apollo/client/react/components";
import styled from "styled-components";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { H3 } from "components/Heading";
import { userNameFromCloseId } from "@nested/utils/graphql/users/users";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { DeleteValuation } from "./DeleteValuation";
import { UPDATE_POST_VISIT_VALUATION_MUTATION } from "./valuationsQuery";

const SubHeading = styled(H3)`
  margin-bottom: 0;
`;

type Props = {
  valuation: *,
  isReadOnly: boolean,
  users: $ReadOnlyArray<PostVisitValuationsListNestDealQuery_activeNestedUsers>,
};

const EditableNote = ({ id, notes }: { id: string, notes: string }) => (
  <ExtendedMutation mutation={UPDATE_POST_VISIT_VALUATION_MUTATION}>
    {(
      mutation: MutationFunction<
        UpdatePostVisitValuationDec18,
        UpdatePostVisitValuationDec18Variables,
      >,
    ) => {
      const updateValuation = (input) =>
        mutation({
          variables: {
            postVisitValuationId: id,
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updatePostVisitValuationDec18: {
              __typename: "PostVisitValuationDec18",
              id,
              ...input,
            },
          },
        });
      return (
        <NoSubmitTextareaWithLinks
          data-test={`post-visit-valuations:notes:${id}`}
          label="Notes"
          value={notes}
          property="notes"
          onSubmit={(val) => updateValuation({ notes: val })}
        />
      );
    }}
  </ExtendedMutation>
);

export const PostVisitValuationDec18 = ({
  valuation,
  users,
  isReadOnly,
}: Props) => (
  <Grid columns={6}>
    <LeafCell width={2}>
      <NoSubmitDatePicker
        data-test="post-visit-valuations:date-completed"
        label="Valuation Date"
        value={valuation.dateCompleted}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={4} />

    <LeafCell width={3}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations:completed-by"
        label="Completed By"
        value={userNameFromCloseId(valuation.completedBy, users)}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={3}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations:signed-off-by"
        label="Signed Off By"
        value={userNameFromCloseId(valuation.signedOffBy, users)}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={6}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations:valuation-gsheet-url"
        label="Valuation GSheet URL"
        value={valuation.valuationGsheetUrl}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations:list-price"
        label="Updated List Price"
        value={valuation.listPrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations:maximum-list-price"
        label="Maximum List Price"
        value={valuation.maximumListPrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2} />

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations:estimated-sale-price"
        label="Estimated Sale Price Best Estimate"
        value={valuation.estimatedSalePrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations:estimated-sale-price-lower-bound"
        label="Estimated Sale Price (Lower Bound)"
        value={valuation.estimatedSalePriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations:estimated-sale-price-upper-bound"
        label="Estimated Sale Price (Upper Bound)"
        value={valuation.estimatedSalePriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    {valuation.estimatingAdvance ? (
      <>
        <LeafCell width={6}>
          <SubHeading>Advance Estimates</SubHeading>
        </LeafCell>
        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="post-visit-valuations:estimated-advance-lower-bound"
            label="Off-track advance"
            value={valuation.estimatedAdvanceLowerBound}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="post-visit-valuations:estimated-advance-on-track"
            label="On-track advance"
            value={valuation.estimatedAdvanceOnTrack}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="post-visit-valuations:estimated-advance-upper-bound"
            label="Under Offer advance"
            value={valuation.estimatedAdvanceUpperBound}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>
      </>
    ) : (
      <LeafCell width={6}>
        <NoSubmitTextareaWithLinks
          label="Advance"
          value="None Estimated"
          readOnly={isReadOnly}
          disabled={!isReadOnly}
        />
      </LeafCell>
    )}

    <LeafCell width={6}>
      {isReadOnly ? (
        <NoSubmitTextareaWithLinks
          data-test={`post-visit-valuations:notes:${valuation.id}`}
          label="Notes"
          value={valuation.notes}
          readOnly={isReadOnly}
          disabled={!isReadOnly}
        />
      ) : (
        <EditableNote id={valuation.id} notes={valuation.notes} />
      )}
    </LeafCell>

    {!isReadOnly && (
      <LeafCell width={6}>
        <DeleteValuation id={valuation.id} dealId={valuation.dealId} />
      </LeafCell>
    )}
  </Grid>
);
