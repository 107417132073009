// @flow
/* eslint-disable no-alert */

import styled from "styled-components";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { DeleteIcon } from "@nest-ui/icons";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { RatingIcon } from "./Rating";

const ComparisonPointWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textField.text};
  display: flex;
  font-size: 12px;
  margin: 5px;
  padding: 5px;
`;

const Description = styled.span`
  flex-grow: 1;
`;

export const DELETE_COMPARISON_POINT = gql`
  mutation DeleteComparisonPoint(
    $comparableId: ID!
    $input: DeleteComparisonPointInput!
  ) {
    deleteComparisonPoint(comparableId: $comparableId, input: $input) {
      id
      comparisonPoints {
        description
        rating
      }
    }
  }
`;

type Prop = {
  description: string,
  comparable: SelectedComparablesQuery_selectedComparables,
  rating: ComparisonRatingType,
};

export const ComparisonPoint = ({ comparable, description, rating }: Prop) => (
  <ComparisonPointWrapper>
    <RatingIcon rating={rating} />
    <Description>{description}</Description>

    <ExtendedMutation mutation={DELETE_COMPARISON_POINT}>
      {(
        deleteComparisonPoint: MutationFunction<
          DeleteComparisonPoint,
          DeleteComparisonPointVariables,
        >,
      ) => (
        <ButtonWithIcon
          data-test="comparison-point/delete-btn"
          icon={DeleteIcon}
          onSubmit={() => {
            // eslint-disable-next-line no-alert
            const doIt = window.confirm(
              `Are you sure you want to remove '${description}'?`,
            );
            if (!doIt) return;

            deleteComparisonPoint({
              variables: {
                comparableId: comparable.id,
                input: { description, rating },
              },
              optimisticResponse: {
                deleteComparisonPoint: {
                  __typename: "Comparable",
                  id: comparable.id,
                  comparisonPoints: comparable.comparisonPoints.filter(
                    (point) =>
                      point.description !== description ||
                      point.rating !== rating,
                  ),
                },
              },
            });
          }}
        />
      )}
    </ExtendedMutation>
  </ComparisonPointWrapper>
);
