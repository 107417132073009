// @flow
import { NoSubmitSelectField } from "@nest-ui/sellers-nest/components/SelectField/SelectField";
import {
  viewingConductors,
  type AssociatedSas,
  type ViewingAgentOptions,
} from "@nest-ui/sellers-nest/tabs/Interest/Viewings/utility-functions";

// This generates the input for the viewing mutation
const generateViewingAgentInputs = (viewingAgent) => {
  const { conductor, id, nestedUserId } = viewingAgent;

  switch (conductor) {
    case viewingConductors.VIEWBER:
    case viewingConductors.VENDOR:
      return {
        conductor,
      };
    case viewingConductors.SUB_AGENT:
      return {
        conductor,
        viewingSubAgentId: id,
      };
    case viewingConductors.SALES_ASSOCIATE:
      return {
        conductor,
        nestedUserId,
        viewingSaId: id,
      };
    default:
      throw new Error(`Invalid viewing agent: ${JSON.stringify(viewingAgent)}`);
  }
};

// This converts the viewing data to the option format so that the select
// can show the currently selected user. The outputs for this function, once
// stringified, must exactly match the stringified options (see option functions below),
// i.e. ordering of keys is important
const findViewingAgentValue = (viewingAgent) => {
  const { conductor, viewingSaId, viewingSubAgentId, nestedUserId } =
    viewingAgent;

  switch (conductor) {
    case viewingConductors.VIEWBER:
    case viewingConductors.VENDOR:
      return { conductor };
    case viewingConductors.SUB_AGENT:
      return { conductor, id: viewingSubAgentId };
    case viewingConductors.SALES_ASSOCIATE:
      return {
        conductor,
        id: viewingSaId,
        nestedUserId,
      };
    default:
      return null;
  }
};

const stringify = ({ value }) => JSON.stringify(value);

type primarySaType =
  | ?PropertyInterestsByBuyer_buyer_leadSaUser
  | ?buyerPropertyInterestFields_deal_vendorLeadSa
  | ?buyerPropertyInterestFields_deal_currentlyResponsibleSaUser;

const remainingSaOption = (
  sa: ?PropertyInterestsByBuyer_activeNestedUsers,
) => ({
  label: sa?.fullName || "[Missing user name]",
  value: {
    conductor: viewingConductors.SALES_ASSOCIATE,
    id: sa?.closeUserId,
    nestedUserId: sa?.id,
  },
});

const subAgentOption = ({ branchName, id }) => ({
  label: branchName || "Sub agent branch name is not specified",
  value: { conductor: viewingConductors.SUB_AGENT, id },
});

const viewingAssistantOption = ({ fullName, id }) => ({
  label: fullName,
  value: {
    conductor: viewingConductors.SALES_ASSOCIATE,
    id: null,
    nestedUserId: id,
  },
});

const viewberOption = {
  label: "Viewber",
  value: { conductor: viewingConductors.VIEWBER },
};

const vendorOption = {
  label: "Vendor",
  value: { conductor: viewingConductors.VENDOR },
};

const dividers = [
  { value: "sub_agents_and_3rd_parties", label: "---" },
  { value: "nested_users", label: "---" },
];

type Props = {|
  onSubmit: (input: ViewingAgentOptions) => Promise<any> | void,
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  associatedSas: AssociatedSas,
  subAgents: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_deal_subAgents>,
  viewingAgentOptions: ViewingAgentOptions,
  "data-test"?: string,
  disabled?: boolean,
  label?: string,
  property?: string,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
|};

/*
We use JSON stringify/parse here so we we pass objects as the 'value' prop to
React Select. We need to do this because the list of options is built up from
different sources, hence we need metadata about where they come from.

This is a workaround to a limitation of react select, but it's isolated and
self-contained within this component so 🤷🏽‍♂️

The only thing to really note is that the ORDER of keys is important
*/
export const ViewingAgentSelect = (props: Props) => {
  const {
    onSubmit,
    activeNestedUsers,
    associatedSas: {
      buyerLeadSa: leadSa,
      vendorLeadSa,
      currentlyResponsibleSaUser,
    },
    subAgents,
    viewingAgentOptions,
    viewingAssistants,
    ...rest
  } = props;

  // These are the close users assigned to the Buyer or Seller, we want
  // to show them at the top of the select list for convenience
  const primaryUsers = [
    vendorLeadSa,
    currentlyResponsibleSaUser,
    leadSa,
  ].filter(
    (sa, index, arr) => arr.findIndex((val) => val?.id === sa?.id) === index, // deduplicate
  );

  // Remaining close users, not in list above
  const remainingUsers = activeNestedUsers
    ? activeNestedUsers.filter(
        ({ closeUserId }) =>
          !primaryUsers.map((user) => user?.id).includes(closeUserId),
      )
    : [];

  const primaryOptions = primaryUsers.map((sa: primarySaType) => {
    const nestedUser = sa?.id
      ? activeNestedUsers.find(({ closeUserId }) => closeUserId === sa?.id)
      : null;

    return {
      label: sa?.fullName || "[Missing close user name]",
      value: {
        conductor: viewingConductors.SALES_ASSOCIATE,
        id: sa?.id,
        nestedUserId: nestedUser?.id,
      },
    };
  });
  const subAgentOptions = subAgents.map(subAgentOption);
  const otherOptions = [viewberOption, vendorOption];
  const viewingAssistantOptions = viewingAssistants.map(viewingAssistantOption);
  const remainingOptions = remainingUsers.map(remainingSaOption);

  const options = [
    ...primaryOptions,
    ...subAgentOptions,
    ...otherOptions,
    ...viewingAssistantOptions,
    ...remainingOptions,
  ].map(({ value, label }) => ({ label, value: JSON.stringify(value) }));

  const preferredOrder = primaryOptions
    .map(stringify)
    .concat(["sub_agents_and_3rd_parties"])
    .concat(subAgentOptions.map(stringify))
    .concat(otherOptions.map(stringify))
    .concat(["nested_users"])
    .concat(remainingOptions.map(stringify))
    .concat(["viewing_assistants"])
    .concat(viewingAssistantOptions.map(stringify));

  const submit = (value) => {
    const parsedValue = JSON.parse(String(value));
    const inputs = generateViewingAgentInputs(parsedValue);
    onSubmit(inputs);
  };

  return (
    <NoSubmitSelectField
      data-test="viewings:agent-select"
      dividers={dividers}
      options={options}
      preferredOrder={preferredOrder}
      onSubmit={submit}
      value={JSON.stringify(findViewingAgentValue(viewingAgentOptions))}
      {...rest}
    />
  );
};
