// @flow
import React from "react";

type StorageEngine = {
  get: (cacheKey: string) => any,
  set: (cacheKey: string, data: any) => void,
  delete: (cacheKey: string) => void,
};

/**
 * A thin wrapper around local storage to make it easy to get / set / delete things from local storage
 */
export const LocalStorageEngine: StorageEngine = {
  /**
   * Retrieves the state stored in local storage under the cache key
   * deserializes it from JSON for you as well.
   */
  get(cacheKey) {
    // We have to do this because getItem will return undefined
    // if the key doesn't exist, which would make JSON.parse explode.
    const item = localStorage.getItem(cacheKey);
    if (typeof item === "string") return JSON.parse(item);
    return null;
  },

  /**
   * Converts data to JSON then saves it to local storage
   */
  set(cacheKey, data) {
    localStorage.setItem(cacheKey, JSON.stringify(data));
  },

  /**
   * Deletes the data under the cache key
   */
  delete(cacheKey) {
    localStorage.removeItem(cacheKey);
  },
};

/**
 * LocalCache is designed to make it easy save work in progress before we send it to the back end.
 * (local here means client side).
 *
 * When filling in a form, this will save the form data to a given storage engine (e.g. local storage)
 * as we update it. This would allow us to refresh the page or re-render but have the form data save.
 *
 * When we are happy we can submit the data to the backend, which will remove the data from the storage
 * engine, or if we change our mind, we can delete the data in the cache. Use it like this:
 *
 *    <LocalCache
 *      storageEngine={LocalStorageEngine}
 *      cacheKey="key-name"
 *      render={ ({cachedState, updateCache, clearCache}) => (
 *        <MyForm
 *          data={cachedState}
 *          onCreate={clearCache}
 *          onCancel={clearCache}
 *          updateCache={updateCache}
 *        />
 *      )}
 *    />
 */

type Props = {
  cacheKey: string,
  defaultState: {},
  storageEngine: StorageEngine,
  render: (renderProps: *) => React$Node,
};

type State = {
  hasState: boolean,
  managedState: any,
};

export class LocalCache extends React.Component<Props, State> {
  static defaultProps = {
    defaultState: {},
    storageEngine: LocalStorageEngine,
  };

  constructor(props: Props) {
    super(props);

    const storedState = this.readCache();

    if (storedState) {
      this.state = {
        hasState: true,
        managedState: storedState,
      };
    } else {
      this.state = {
        hasState: false,
        managedState: this.props.defaultState,
      };
    }
  }

  readCache = () => this.props.storageEngine.get(this.props.cacheKey);

  changeEvent = `local-cache/update:${this.props.cacheKey}`;

  onCacheChange = () => {
    this.setState({
      hasState: true,
      managedState: this.readCache(),
    });
  };

  clearEvent = `local-cache/clear:${this.props.cacheKey}`;

  onCacheClear = () => {
    this.setState({ managedState: this.props.defaultState, hasState: false });
  };

  componentDidMount = () => {
    window.addEventListener(this.changeEvent, this.onCacheChange);
    window.addEventListener(this.clearEvent, this.onCacheClear);
  };

  componentDidUpdate = (oldProps: any) => {
    if (this.props !== oldProps) {
      const storedState = this.readCache();

      if (!storedState) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          hasState: false,
          managedState: this.props.defaultState,
        });
      }
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener(this.changeEvent, this.onCacheChange);
    window.removeEventListener(this.clearEvent, this.onCacheClear);
  };

  updateCache = (updates: any) => {
    this.props.storageEngine.set(this.props.cacheKey, {
      ...this.state.managedState,
      ...updates,
    });

    window.dispatchEvent(new Event(this.changeEvent));
  };

  clearCache = () => {
    this.props.storageEngine.delete(this.props.cacheKey);
    window.dispatchEvent(new Event(this.clearEvent));
  };

  render() {
    return this.props.render({
      cachedState: this.state.managedState,
      hasState: this.state.hasState,
      updateCache: this.updateCache,
      clearCache: this.clearCache,
    });
  }
}
