// @flow
import { Heading, H2 } from "components/Heading";
import { Card } from "components/Card";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { NestedProgressor } from "../NestedProgressor/NestedProgressor";
import { HouseholdCostsList } from "../HouseholdCostsList/HouseholdCostsList";
import { DealDetails } from "../DealDetails";
import { DebtFundingSection } from "../DebtFundingSection/DebtFundingSection";
import { GuaranteePaymentsSection } from "../GuaranteePaymentsSection";
import { PropertySaleRedemption } from "../PropertySaleRedemption";
import { SDLTSurchargeSection } from "../SDLTSurcharge";

type Props = { dealId: string, dealTypeValue: string };
export const LegacyPayments = ({ dealId, dealTypeValue }: Props) => (
  <TabWrapper>
    <TabContainer id="Payments" data-test="payments-tab" className="fs-exclude">
      <NestedProgressor parentId={dealId} />

      <Heading id="advance-payments">Advance payments</Heading>
      <Card>
        <GuaranteePaymentsSection parentId={dealId} />
      </Card>

      <Heading id="SDLT-Surcharge">SDLT Surcharge</Heading>
      <Card>
        <SDLTSurchargeSection parentId={dealId} />
      </Card>

      <Heading id="Property-sale-redemption">Property sale redemption</Heading>
      <Card>
        <PropertySaleRedemption dealId={dealId} dealTypeValue={dealTypeValue} />
      </Card>

      <DebtFundingSection parentId={dealId} />

      <Heading id="Re-imburse-household-costs---fee-adjustments--s10-">
        Re-imburse household costs & fee adjustments (S10)
      </Heading>

      <Card>
        <H2>Household costs & fee adjustmentd</H2>
        <HouseholdCostsList parentId={dealId} />
      </Card>
    </TabContainer>
    <DealDetails dealId={dealId} className="fs-exclude" />
  </TabWrapper>
);
