// @flow

import type { MutationOperation } from "@apollo/client";

import { AlignRight } from "components/AlignRight";
import { Grid, Cell, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { TextField } from "components/TextField";
import { Checkbox } from "components/Checkbox";
import { AllowNegativeCurrencyField } from "components/AllowNegativeCurrencyField";
import { NoSubmitSelectField } from "components/SelectField";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";

type GuaranteePayment =
  | guaranteePaymentFields_DealTypeAgencyGuaranteePayment
  | guaranteePaymentFields_DealTypeGuaranteeGuaranteePayment
  | guaranteePaymentFields_DealTypeLegacyGuaranteeGuaranteePayment
  | guaranteePaymentFields_DealTypeUnderwritingGuaranteePayment;

type Props = {
  guaranteePayment: GuaranteePayment,
  deleteGuaranteePayment: () => MutationOperation<GuaranteePayment>,
  updateGuaranteePayment: (
    input: GuaranteePaymentInput,
  ) => MutationOperation<GuaranteePayment>,
};

export const LegacyGuaranteePayment = ({
  guaranteePayment: {
    id,
    baseFee,
    earlyDrawInterestCharge,
    feeAdjustment,
    feeAdjustmentNotes,
    isPaid,
    isProjectedDateConfirmed,
    paymentDate,
    projectedPaymentDate,
    projectedTotalNetOfFees,
    securityType,
    totalFees,
    totalGross,
    totalNetOfFees,
  },
  deleteGuaranteePayment,
  updateGuaranteePayment,
}: Props) => (
  <ListEntryCard data-test="guarantee-payment">
    <Grid columns={4}>
      <LeafCell width={1}>
        <DatePicker
          label="Projected Date"
          value={projectedPaymentDate}
          property="projectedPaymentDate"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1}>
        <Checkbox
          optionName="Yes"
          label="Projected date confirmed?"
          value={isProjectedDateConfirmed}
          property="isProjectedDateConfirmed"
          mutation={updateGuaranteePayment}
          parentId={id}
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Projected amount net of fees"
          value={projectedTotalNetOfFees}
          property="projectedTotalNetOfFees"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1}>
        <NoSubmitSelectField
          label="Security type"
          options={["Secured", "Unsecured"]}
          value={securityType}
          property="securityType"
          onSubmit={(val) => updateGuaranteePayment({ securityType: val })}
        />
      </LeafCell>

      <LeafCell width={1}>
        <CurrencyField
          label="Actual amount gross"
          value={totalGross}
          property="totalGross"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Base fee (£)"
          value={baseFee}
          property="baseFee"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Early draw interest charge (£)"
          value={earlyDrawInterestCharge}
          property="earlyDrawInterestCharge"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1} />

      <LeafCell width={1}>
        <AllowNegativeCurrencyField
          label="Fee adjustment (-/+ £)"
          value={feeAdjustment}
          property="feeAdjustment"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={2}>
        <TextField
          label="Fee adjustment notes"
          value={feeAdjustmentNotes}
          property="feeAdjustmentNotes"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1} />

      <LeafCell width={1}>
        <CurrencyField
          label="Total fees for this payment (£)"
          value={totalFees}
          property="totalFees"
          mutation={updateGuaranteePayment}
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Actual amount net of fees"
          value={totalNetOfFees}
          property="totalNetOfFees"
          mutation={updateGuaranteePayment}
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Actual Date"
          value={paymentDate}
          property="paymentDate"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={1}>
        <Checkbox
          label="Paid?"
          value={isPaid}
          property="isPaid"
          mutation={updateGuaranteePayment}
          parentId={id}
        />
      </LeafCell>

      <Cell width={4}>
        <AlignRight>
          <DeleteButton
            className="delete-button"
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deleteGuaranteePayment();
              }
            }}
          />
        </AlignRight>
      </Cell>
    </Grid>
  </ListEntryCard>
);
