// @flow
import { graphql } from "deal/utils/graphql";

import { DrawdownTimingFeesComponent } from "./DrawdownTimingFeesComponent";
import query from "./queries/drawdownTimingFees.graphql";
import { drawdownTimingFeesQueryConfig } from "./queries/config";

type SectionProps = {
  data: DrawdownTimingFeesQuery,
};

function DrawdownTimingFeesSection({ data }: SectionProps) {
  const dealTypeDetails = data?.nestDeal?.dealTypeDetails;
  const dealId = data?.nestDeal?.id;

  if (!dealTypeDetails) {
    return <span>Please set a deal type.</span>;
  }

  if (dealTypeDetails.__typename !== "DealTypeAgencyPlusSep2018") {
    return <span>Drawdown timing fees do not apply on this deal type.</span>;
  }

  const drawdownTimingFees = Array.from(
    dealTypeDetails.drawdownTimingFees ?? [],
  ).sort((left, right) => Number(left.days > right.days));

  const dayChoices = data.drawdownTimingFeeValidDays.map((periodInDays) => ({
    label: String(periodInDays),
    value: periodInDays,
  }));

  return (
    dealId && (
      <DrawdownTimingFeesComponent
        drawdownTimingFees={drawdownTimingFees}
        dayChoices={dayChoices}
        dealId={dealId}
      />
    )
  );
}

export const DrawdownTimingFees = graphql(
  query,
  drawdownTimingFeesQueryConfig,
)(DrawdownTimingFeesSection);
