export const RippleSpinner = () => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="lds-ripple"
    data-test="lds-ripple"
  >
    <circle
      cx="50"
      cy="50"
      r="11.6551"
      fill="none"
      ng-attr-stroke="{{config.c1}}"
      ng-attr-stroke-width="{{config.width}}"
      stroke="#353f52"
      strokeWidth="2"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="-0.5s"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      cx="50"
      cy="50"
      r="31.9061"
      fill="none"
      ng-attr-stroke="{{config.c2}}"
      ng-attr-stroke-width="{{config.width}}"
      stroke="#afd4ff"
      strokeWidth="2"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;40"
        keyTimes="0;1"
        dur="1"
        keySplines="0 0.2 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1"
        keySplines="0.2 0 0.8 1"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);
