import { path } from "ramda";

export const updateDealTypeAdvanceMay2018Config = {
  props: ({ ownProps: { data }, mutate }) => {
    const dealTypeData = path(["nestDeal", "dealTypeAdvanceMay2018"], data);
    return {
      updateDealTypeAdvanceMay2018: (input) =>
        mutate({
          variables: { input, id: dealTypeData.id },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeAdvanceMay2018: {
              __typename: "DealTypeAdvanceMay2018",
              ...dealTypeData,
              ...input,
            },
          },
        }),
    };
  },
};
