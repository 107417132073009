// @flow
import { useCallback, useState, useEffect, useContext } from "react";
import { css, ThemeContext } from "styled-components";
import { getImage } from "@nested/utils";

const wrapperStyle = css`
  position: relative;
  width: 100%;
  visibility: ${({ height }) => (height ? "visible" : "hidden")};
  height: ${({ height }) => height};
  min-height: 400px;
  box-sizing: border-box;
  margin-top: 20px;
  overflow: hidden;
`;

const innerBorder = css`
  border: 2px solid ${({ theme }) => theme.palette.hague20};
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const imageStyle = css`
  width: 120px;
`;

const messageStyle = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  margin-top: 20px;
`;

const subtextStyle = css`
  margin: 0;
  margin-top: 10px;
  line-height: 24px;
`;

const circle = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.hague20};
  position: absolute;
  background-color: white;
  z-index: 5;
`;

const topLeft = css`
  ${circle};
  top: -20px;
  left: -20px;
`;

const topRight = css`
  ${circle};
  top: -20px;
  right: -20px;
`;

const bottomLeft = css`
  ${circle};
  bottom: -20px;
  left: -20px;
`;

const bottomRight = css`
  ${circle};
  bottom: -20px;
  right: -20px;
`;

const defaultImage = {
  path: "illustrations/house-on-street.png",
  altText: "Houses on a street",
};

const NoResultsWrapper = ({ children }: { children: React$Node }) => {
  const [height, setHeight] = useState();
  const [device, setDevice] = useState();
  const theme = useContext(ThemeContext);

  const getDevice = () => {
    if (window.innerWidth < theme.breakpoints.tablet) {
      return setDevice("mobile");
    }
    if (window.innerWidth < theme.breakpoints.desktop) {
      return setDevice("tablet");
    }
    return setDevice("desktop");
  };

  useEffect(() => {
    window.onresize = getDevice;
  }, []);

  const ref = useCallback(
    (node) => {
      if (node) {
        // Fills the rest of the viewport based on the top of the bounding box
        const { top } = node.getBoundingClientRect();
        setHeight(`calc(100vh - ${top}px - 20px)`);
      }
    },
    [device],
  );

  return (
    <div height={height} ref={ref} css={wrapperStyle}>
      <div css={topLeft} />
      <div css={topRight} />
      <div css={bottomLeft} />
      <div css={bottomRight} />
      <div css={innerBorder}>
        <div>{children}</div>
      </div>
    </div>
  );
};

type Props = {
  message: string,
  subtext?: string | React.Element,
  largeImage?: boolean,
  image?: ?{
    path: string,
    altText: string,
  },
  children?: React$Node,
};

export const NoResults = ({
  message,
  image = defaultImage,
  subtext = false,
  children = null,
}: Props) => {
  return (
    <NoResultsWrapper>
      {children}
      {image && (
        <img css={imageStyle} src={getImage(image.path)} alt={image.altText} />
      )}
      <p css={messageStyle}>{message}</p>
      {subtext && <div css={subtextStyle}>{subtext}</div>}
    </NoResultsWrapper>
  );
};
