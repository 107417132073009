// @flow
import { useMutation } from "@apollo/client";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Checkbox } from "@nested/nest/src/components/Checkbox/Checkbox";
import { formatShortDate } from "@nested/utils/src/formatDate/formatDate";
import moment from "moment-timezone";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import reachMilestoneMutation from "../reachMilestone.graphql";
import unreachMilestoneMutation from "../unreachMilestone.graphql";
import reloadAgreedOrAcceptedOffer from "../../queries/reloadAgreedOrAcceptedOffer.graphql";
import reloadDealStatus from "../../queries/reloadDealStatus.graphql";

export const MILESTONE_LABELS = {
  all_enquiries_satisfied: "All enquiries satisfied",
  all_searches_returned: "All searches returned",
  applied_for_searches: "Applied for searches",
  buyer_draft_contract_pack: "Draft Contract Pack received",
  buyer_reported_to: "Buyer reported to",
  completed: "Completed",
  contracts_signed: "Contracts signed and returned",
  exchanged: "Exchanged",
  funds_deposited: "Deposit funds into solicitor’s account",
  initial_enquiries_raised: "Initial enquiries raised",
  instruction_form_id_checks: "Instruction forms and ID checks completed",
  management_pack_lpe_one_form_ordered: "Management Pack or LPE1 form ordered",
  management_pack_lpe_one_form_shared: "Management Pack or LPE1 form shared",
  memo_sent: "Memorandum of Sale issued",
  mortgage_application_submitted: "Mortgage application submitted",
  mortgage_offer_received: "Mortgage offer received",
  mortgage_valuation_booked: "Mortgage valuation booked",
  mortgage_valuation_report_received: "Mortgage valuation report received",
  protocol_forms: "Completed and returned protocol forms",
  search_monies: "Search monies paid to solicitor",
  seller_draft_contract_pack: "Draft Contract Pack issued",
};

const CANNOT_UNDO_MILESTONES = ["memo_sent", "exchanged", "completed"];

export const milestoneStyle = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 6px 15px 6px 9px;
  position: relative;

  ${media.tablet`
    padding: 6px 20px 6px 24px;
  `}
`;

export const dateStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  white-space: nowrap;
`;

export const checkboxStyle = css`
  font-weight: 500;
  margin-right: 14px;
  ${media.tablet`
    margin-right: 24px;
  `}

  #checkbox-border {
    z-index: 1;
    background-color: ${({ theme, checked }) =>
      checked ? theme.palette.blue150 : "white"};
    border: 2px solid ${({ theme }) => theme.palette.blue170};
    border-radius: 5px;
    margin-right: 10px;
    ${({ disabled, checked }) =>
      disabled &&
      !checked &&
      css`
        background-color: ${({ theme }) => theme.palette.hague20};
      `}
    ${media.tablet`
      margin-right: 24px;
    `}
  }
  #checkbox-tick {
    color: white;
  }
`;

const sectionHeadingStyle = css`
  display: flex;
  height: 36px;
  line-height: 36px;
  padding: 0 15px 0 9px;
  ${media.tablet`
    padding: 0 20px 0 24px;
  `}
`;

const dot = css`
  z-index: 1;
  display: inline-block;
  height: 12px;
  line-height: 36px;
  width: 12px;
  background-color: ${({ theme, $allReached }) =>
    $allReached ? theme.palette.blue170 : "white"};
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.palette.blue170};
  margin: auto 14px auto 4px;
  ${media.tablet`
    margin-right: 28px;
  `}
`;

export type Milestone = {
  id: string,
  reachedAt: ?Date,
  reachedAtTime: ?DateTime,
};

export const milestoneSectionStyle = css`
  background-color: ${({ $allReached, theme }) =>
    $allReached ? theme.palette.blue10 : "white"};
  border: 1px solid
    ${({ $allReached, theme }) =>
      $allReached ? "transparent" : theme.palette.hague20};
  border-radius: 5px;
  margin-top: 18px;
  padding: 9px 0;
`;

type MilestoneSectionProps = {|
  heading?: string,
  allReached: boolean,
  children: React$Node,
|};

export const MilestoneSection = ({
  heading,
  allReached,
  children,
}: MilestoneSectionProps) => (
  <div css={milestoneSectionStyle} $allReached={allReached}>
    {heading && <SectionHeading text={heading} allReached={allReached} />}
    {children}
  </div>
);

export const SectionHeading = ({
  text,
  allReached,
}: {
  text: string,
  allReached: boolean,
}) => (
  <div css={sectionHeadingStyle}>
    <span css={dot} $allReached={allReached}></span>
    <span>{text}</span>
  </div>
);

export type MilestoneProps = {
  buyerOfferId: ?string,
  dealId: string,
  disabled?: boolean,
  milestone: ?Milestone,
};

export const MilestoneRow = ({
  buyerOfferId,
  dealId,
  disabled,
  milestone,
}: MilestoneProps) => {
  const refetchQueries = [
    { query: reloadDealStatus, variables: { dealId } },
    { query: reloadAgreedOrAcceptedOffer, variables: { dealId } },
  ];
  const [reach, { loading: reachLoading }] = useMutation(
    reachMilestoneMutation,
    {
      refetchQueries,
    },
  );
  const [unreach, { loading: unreachLoading }] = useMutation(
    unreachMilestoneMutation,
    { refetchQueries },
  );
  const { createNotification } = useNotifications();

  // Some milestones can be irrelevant, so we don't want to display them.
  // E.g. the `Management Pack or LPE1 form ordered` milestone is
  // only relevant if the deal is leasehold or 'share of freehold'.
  if (!milestone) return null;

  const { id, reachedAt, reachedAtTime } = milestone || {};

  const reachMilestone = async (milestoneId) => {
    if (!buyerOfferId) {
      return null;
    }

    try {
      const result = await reach({
        variables: {
          buyerOfferId,
          milestoneId,
        },
        optimisticResponse: {
          reachMilestone: {
            __typename: "MilestoneStatus",
            id: milestoneId,
            reachedAt: moment(),
            reachedAtTime: moment(),
          },
        },
      });
      if (result?.data?.reachMilestone?.reachedAt) {
        const callback = CANNOT_UNDO_MILESTONES.includes(milestoneId)
          ? null
          : { text: "Undo", onClick: () => unreachMilestone(milestoneId) };
        createNotification("New milestone reached!", {
          callback,
          subText: MILESTONE_LABELS[id],
        });
      }
    } catch (e) {
      errorHandler(e);
    }
    return null;
  };

  const unreachMilestone = async (milestoneId) => {
    if (!buyerOfferId) {
      return null;
    }

    const now = moment.utc();
    // I have to resort to this because ESLint apparently doesn't mix very well with tertiary operators and disabling-rules.
    let confirmation;
    // The Nested user has one minute to unreach the milestone that has just been reached. After that the seller
    // will get notified about this, hence the warning below.
    if (now.diff(moment.utc(reachedAtTime)) > 60000) {
      // eslint-disable-next-line no-alert
      confirmation = window.confirm(
        `Are you sure you want to unreach the '${MILESTONE_LABELS[milestoneId]}' milestone?` +
          ` The seller has already been notified that this milestones has been reached.`,
      );
    } else {
      confirmation = true;
    }

    if (!confirmation) {
      return null;
    }

    try {
      await unreach({
        variables: {
          buyerOfferId,
          milestoneId,
        },
        optimisticResponse: {
          unreachMilestone: {
            __typename: "MilestoneStatus",
            id: milestoneId,
            reachedAt: null,
            reachedAtTime: null,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
    return null;
  };

  const onChange = () => {
    if (reachedAt) {
      unreachMilestone(id);
    } else {
      reachMilestone(id);
    }
  };

  const disableCheckbox =
    disabled || !buyerOfferId || reachLoading || unreachLoading;

  return (
    <div css={milestoneStyle}>
      <Checkbox
        checked={Boolean(reachedAt)}
        css={checkboxStyle}
        disabled={disableCheckbox}
        id={id}
        labelText={MILESTONE_LABELS[id]}
        onChange={onChange}
      />
      {reachedAt && <div css={dateStyle}>{formatShortDate(reachedAt)}</div>}
    </div>
  );
};
