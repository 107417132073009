import T from "prop-types";
import { compose } from "recompose";
import { path } from "ramda";
import { graphql } from "deal/utils/graphql";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";

import { Grid, LeafCell } from "components/Grid";
import { ErrorBoundary } from "components/ErrorBoundary";
import { SelectField } from "components/SelectField";

import dealTypeQuery from "./queries/dealTypeQuery.graphql";
import { dealTypeQueryConfig } from "./queries/dealTypeQueryConfig";

import changeDealTypeMutation from "./mutations/changeDealTypeMutation.graphql";
import { changeDealTypeConfig } from "./mutations/changeDealTypeConfig";

import { AdvanceJul2018 } from "./AdvanceJul2018";
import { AdvanceMay2018 } from "./AdvanceMay2018";
import { Agency } from "./Agency";
import { AgencyPlusSep2018 } from "./AgencyPlusSep2018";
import { Guarantee } from "./Guarantee";
import { LegacyGuarantee } from "./LegacyGuarantee";
import { Underwriting } from "./Underwriting";

const EmptyComponent = () => null;

const dealTypeMapping = {
  agency_plus_sep2018: AgencyPlusSep2018,
  advance_jul2018: AdvanceJul2018,
  advance_may2018: AdvanceMay2018,
  free_no_guarantee: Agency,
  regular_new: Guarantee,
  regular_original: LegacyGuarantee,
  underwrite: Underwriting,
  advances_for_all_march2019: EmptyComponent,
};

const preferredOrder = [
  "advances_for_all_march2019",
  "agency_plus_sep2018",
  "free_no_guarantee",
  "advance_jul2018",
  "advance_may2018",
  "regular_new",
  "regular_original",
  "underwrite",
];

const DealTypesComponent = ({ data: queryResult, changeDealType }) => {
  const { currentUser } = useUser();
  const dealType = path(["nestDeal", "dealType"], queryResult);
  const dealTypes = path(["dealTypes"], queryResult);

  const previouslyFetched = dealType && dealTypes;

  if (!previouslyFetched && queryResult.loading) {
    return null;
  }

  const Component = dealType ? dealTypeMapping[dealType.value] : null;
  return (
    <Grid columns={4}>
      {currentUser.isNestAdmin && (
        <LeafCell width={4}>
          <SelectField
            data-test="deal-type-select-field"
            fromValue
            mutation={changeDealType}
            options={dealTypes}
            preferredOrder={preferredOrder}
            property="dealType"
            value={dealType && dealType.value}
          />
        </LeafCell>
      )}
      <LeafCell width={4}>
        {Component && !queryResult.loading ? (
          <ErrorBoundary>
            <Component dealId={queryResult.nestDeal.id} />
          </ErrorBoundary>
        ) : null}
      </LeafCell>
    </Grid>
  );
};

DealTypesComponent.propTypes = {
  data: T.object,
  changeDealType: T.func.isRequired,
};

const enhance = compose(
  graphql(dealTypeQuery, dealTypeQueryConfig),
  graphql(changeDealTypeMutation, changeDealTypeConfig),
);

export const DealTypes = enhance(DealTypesComponent);
