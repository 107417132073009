const colours = [
  "darkgoldenrod",
  "cornflowerblue",
  "mediumvioletred",
  "peru",
  "darkspringgreen",
  "mediumorchid",
  "lilac",
  "indianred",
  "rebeccapurple",
];
const fontFamilies = ["serif", "sans-serif", "monospace", "Comic Sans MS"];

const randomStyle = (array) => () =>
  array[Math.floor(Math.random() * array.length)];

export const randomColour = randomStyle(colours); // We all know this won't be your sort of thing.
/* eslint-disable */ const addRandomColourToElements = (elements) =>
  Array.prototype.forEach.call(
    elements,
    (el) => (el.style.backgroundColor = randomColour()),
  );
const removeColorFromElements = (elements) =>
  Array.prototype.forEach.call(
    elements,
    (el) => (el.style.backgroundColor = ""),
  );
/* eslint-enable */
const randomFontFamily = randomStyle(fontFamilies);

let partyInterval;
const partyableElements = document.getElementsByClassName("partyable");

export const togglePartyMode = () => {
  if (partyInterval) {
    disablePartyMode();
  } else {
    partyInterval = setInterval(() => {
      document.body.style.color = randomColour();
      addRandomColourToElements(partyableElements);
      document.body.style.fontFamily = randomFontFamily();
    }, 250);
  }
};

export const disablePartyMode = () => {
  partyInterval = clearInterval(partyInterval);
  document.body.style.color = "";
  document.body.style.fontFamily = "Comic Sans MS";
  removeColorFromElements(partyableElements);
};

let threeDMode = false;
export const toggle3dMode = () => {
  if (threeDMode) {
    disable3dMode();
  } else {
    threeDMode = true;
    document.body.style.textShadow = `2px 2px 5px ${randomColour()}`;
  }
};

export const disable3dMode = () => {
  threeDMode = false;
  document.body.style.textShadow = "";
};
