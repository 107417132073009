export const updateDealDetailsConfig = {
  props: ({
    ownProps: {
      data: { nestDeal },
    },
    mutate,
  }) => ({
    updateNestDeal: (input) =>
      mutate({
        variables: { input, id: nestDeal.id },
        optimisticResponse: {
          __typename: "Mutation",
          updateNestDeal: {
            __typename: "NestDeal",
            ...nestDeal,
            ...input,
          },
        },
      }),
  }),
};
