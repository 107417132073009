// @flow
import { kebabCase } from "lodash";
import { ListEntry } from "components/ListEntry";
import { H2 } from "components/Heading";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextField } from "components/TextField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitRadioButtons } from "components/RadioButtons/RadioButtons";
import { FullWidthCancelButton } from "./Buttons/FullWidthCancelButton";
import { SaveButton } from "./Buttons/SaveButton";

type Props = {
  onSubmit: () => any,
  onCancel: () => any,
  advanceValuationDropdownOptions: $ReadOnlyArray<{
    id: string,
    value: string,
  }>,
  updateState: (newAdvance: $Shape<CreateAdvanceInPrincipleInput>) => void,
  advanceInPrinciple: $Shape<AdvancesSectionQuery_nestDeal_advanceInPrinciples>,
  valuations: [
    {
      advanceAmount: number,
      dateCompleted: string,
      expectedSalePrice: number,
      expiryDate: string,
      reasonForAdvanceOfferValuation: {
        id: string,
        label: string,
      },
      __typename: string,
      id: string,
    },
  ],
};

export const CreateAdvanceInPrinciple = (props: Props) => {
  const relevantValuation = props.valuations.find(
    ({ id }) => id === props.advanceInPrinciple.advanceOfferValuationOct18Id,
  );
  const reasonForAdvanceOfferValuation =
    relevantValuation?.reasonForAdvanceOfferValuation.label;

  const fieldProps = (key: string) => ({
    "data-test": `aip-section-create-${kebabCase(key)}`,
    value: props.advanceInPrinciple[key],
    onSubmit: (val) => props.updateState({ [key]: val }),
  });

  const disableSubmit = !(
    props.advanceInPrinciple.advanceOfferValuationOct18Id &&
    props.advanceInPrinciple.doesCustomerIntendToConvert &&
    props.advanceInPrinciple.dateCommunicatedToSeller &&
    props.advanceInPrinciple.expiryDate
  );

  return (
    <ListEntry>
      <H2>Advance in principle communicated to seller</H2>
      <Grid columns={4}>
        <LeafCell width={2}>
          <NoSubmitSelectField
            {...fieldProps("advanceOfferValuationOct18Id")}
            label="Advance valuation"
            searchable
            options={props.advanceValuationDropdownOptions}
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitDatePicker
            label="Expiry date"
            {...fieldProps("expiryDate")}
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitTextField
            label="Reason"
            disabled
            value={reasonForAdvanceOfferValuation}
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitDatePicker
            {...fieldProps("dateCommunicatedToSeller")}
            label="Date AIP communicated to seller"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitRadioButtons
            data-test="aip-section-create-does-customer-intend-to-convert"
            value={props.advanceInPrinciple.doesCustomerIntendToConvert}
            onSubmit={(val) => {
              /*
               * `doesCustomerIntendToConvert` is a required field, so once it is
               * selected we don't allow to de-select it.
               */
              if (val !== undefined && val !== null) {
                props.updateState({ doesCustomerIntendToConvert: val });
              }
            }}
            id="aip-section-create-does-customer-intend-to-convert"
            label="Does customer intend to convert to advance offer?"
            options={[
              { label: "No", value: "NO" },
              { label: "Maybe", value: "MAYBE" },
              { label: "Yes", value: "YES" },
            ]}
          />
        </LeafCell>
        <LeafCell width={1} />

        <LeafCell width={1}>
          <SaveButton
            data-test="advance-section:new-entry:save-button"
            onClick={props.onSubmit}
            disabled={disableSubmit}
          >
            Save
          </SaveButton>
        </LeafCell>
        <LeafCell width={1}>
          <FullWidthCancelButton
            data-test="advance-section:new-entry:cancel-button"
            onClick={props.onCancel}
          >
            Cancel
          </FullWidthCancelButton>
        </LeafCell>
      </Grid>
    </ListEntry>
  );
};
