import { useState } from "react";
import { faPen, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "react-final-form";
import { Button } from "@nested/nest/src/components/Button/Button";
import {
  EmailPreviewBasicLayout,
  mobileButtonsWrapper,
  previewButtonStyle,
  Paragraph,
  TemplateVariable,
} from "tabs/Interest/PotentialBuyers/MailoutModal/MailoutPreview";

export const MemoOfSalePreview = ({ refs, onNext, pdfUrl }) => {
  const { formRef, previewRef } = refs;
  const [showEmailPreview, setShowEmailPreview] = useState(true);
  const customMessageField = useField("customMessage");
  return (
    <EmailPreviewBasicLayout
      showEmailPreview={showEmailPreview}
      setShowEmailPreview={setShowEmailPreview}
      ref={previewRef}
      hasSMSPreview={false}
    >
      <div>
        <Paragraph>Congratulations on your accepted offer!</Paragraph>
        <Paragraph>
          I have attached a copy of the approved Memorandum of Sale, which has
          been sent to all parties and will allow your solicitor to begin
          proceedings.
        </Paragraph>
        <Paragraph>{customMessageField.input.value}</Paragraph>
        <Paragraph>
          We'll be on hand to progress the sale through until exchange and
          completion and will offer you updates as soon as there are any
          developments.
        </Paragraph>
        <Paragraph>
          Should you have any questions, please don't hesitate to reach out
          directly.
        </Paragraph>
        <Paragraph>
          Best, <br />
          <TemplateVariable>user</TemplateVariable> @ Nested
        </Paragraph>

        <a
          href={pdfUrl}
          target="_blank"
          css="display: inline-block;border-radius: 5px;overflow: hidden;"
        >
          <div css="padding: 5px;">
            <FontAwesomeIcon icon={faPaperclip} /> Memo of Sale.pdf
          </div>
        </a>
      </div>
      <div css={mobileButtonsWrapper}>
        <Button
          icon={faPen}
          buttonStyle="outline"
          large
          css={previewButtonStyle}
          type="button"
          onClick={() =>
            formRef.current?.scrollIntoView({
              behaviour: "smooth",
              block: "end",
            })
          }
        >
          Continue editing
        </Button>
        <Button
          buttonStyle="pink"
          data-test="choose-contacts-mobile-button"
          onClick={onNext}
          large
        >
          Next: Choose contacts
        </Button>
      </div>
    </EmailPreviewBasicLayout>
  );
};
