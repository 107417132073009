// @flow

import styled from "styled-components";
import { Grid, Cell } from "components/Grid";

import { Info } from "@nest-ui/icons";

import { theme as comparablesTheme } from "../theme";

const BlankText = styled.span`
  color: ${({ theme }) => theme.text.grey};
  font-size: 14px;
  line-height: 1.5em;
`;

const InfoWrapper = styled.div`
  margin: 0 auto;
  height: 44px;
  width: 44px;
`;

const StyledGrid = styled(Grid)`
  margin: 128px auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

export const Empty = () => (
  <StyledGrid columns={8}>
    <Cell left={4} width={2}>
      <InfoWrapper>
        <Info fill={comparablesTheme.text.grey} />
      </InfoWrapper>
    </Cell>
    <Cell center left={3} width={4}>
      <BlankText>
        To transfer all your selected comparables for this property, paste the
        contents from the comparison browser plugin above and hit 'Add Comps'
      </BlankText>
    </Cell>
  </StyledGrid>
);
