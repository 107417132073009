// @flow

import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { Add } from "@nest-ui/icons";
import { ButtonWithIcon } from "components/ButtonWithIcon/ButtonWithIcon";
import { NoSubmitSelectField } from "components/SelectField";
import { LIST_COMPARISON_LABEL_QUERY } from "../Comparables";
import { AddComparisonPoint } from "./AddComparisonPoint";
import { ComparisonPointList } from "./ComparisonPointList";

const ComparisonPointsSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Title = styled.label`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-bottom: 4px;
  width: 100%;
  display: block;
`;

const ComparisonPointsPanel = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.textField.background};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.textField.border};
  color: ${({ theme }) => theme.text.grey};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 1.5em;
  width: 100%;
`;

const SelectExistingPoint = styled(NoSubmitSelectField)`
  button {
    border-radius: 0;

    &:hover {
      border-radius: 2px 2px 0 0;
    }
  }
`;

const AddButton = styled(ButtonWithIcon)`
  border-radius: 0 0 2px 2px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: #353f52;
  overflow: hidden;

  &:hover {
    background-color: ${({ theme }) => theme.button.greyHover};
    cursor: pointer;
  }
`;

type Props = {
  comparable: SelectedComparablesQuery_selectedComparables,
};

export const ComparisonPoints = ({ comparable }: Props) => {
  const [view, setView] = useState("comparisonPointsList");
  const [comparisonLabel, setComparisonLabel] = useState();

  useEffect(() => {
    if (comparisonLabel) {
      setView("addComparisonPoints");
    }
  });

  return (
    <div onClick={(e) => e.stopPropagation()} role="presentation">
      <Title>Comparison points</Title>
      <ComparisonPointsPanel>
        {view === "comparisonPointsList" ? (
          <ComparisonPointsSection data-test="comparison-points-section">
            <ExtendedQuery
              // This is `cache-only` to avoid running many identical queries.
              // This is global state populated in the main `Comparables`
              // component instead.
              fetchPolicy="cache-only"
              query={LIST_COMPARISON_LABEL_QUERY}
            >
              {({
                listAllComparisonLabels: comparisonLabels,
              }: ExtendedQueryRenderProps<ListAllComparisonLabels>) => (
                <SelectExistingPoint
                  data-test="select-comparison-labels"
                  dark
                  options={comparisonLabels.map((label) => ({
                    label: label.description,
                    value: label,
                  }))}
                  onSubmit={(choice) => setComparisonLabel(choice)}
                />
              )}
            </ExtendedQuery>
            <ComparisonPointList
              comparable={comparable}
              comparisonPoints={comparable.comparisonPoints}
            />
            <AddButton
              data-test="add-new-comparison-point-btn"
              color="white"
              label="Add a new comparison point"
              icon={Add}
              onSubmit={() => setView("addComparisonPoints")}
            />
          </ComparisonPointsSection>
        ) : (
          <AddComparisonPoint
            data-test="add-existing-comparison-point-form"
            comparable={comparable}
            selectedComparisonLabel={comparisonLabel}
            returnToList={() => {
              setComparisonLabel(null);
              setView("comparisonPointsList");
            }}
          />
        )}
      </ComparisonPointsPanel>
    </div>
  );
};
