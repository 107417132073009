// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";

const breadcrumbWrapperStyle = css`
  ${media.tablet`
    display: none;
  `}
`;

const breadcrumbStyle = css`
  text-decoration: none;
  margin-right: 5px;
  opacity: 0.5;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  &:last-child svg {
    display: none;
  }
`;

const chevronStyle = css`
  opacity: 0.4;
  margin-left: 5px;
  height: 10px;
`;

type BreadcrumbProps = {
  children: React$Node,
  to: string,
  className?: string,
};

export const Breadcrumb = ({ children, to, className }: BreadcrumbProps) => (
  <Link to={to} css={breadcrumbStyle} disabled className={className}>
    {children}
    <FontAwesomeIcon css={chevronStyle} icon={faChevronRight} />
  </Link>
);

export const Breadcrumbs = ({ children }: { children: React$Node }) => (
  <div css={breadcrumbWrapperStyle}>{children}</div>
);
