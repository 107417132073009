// @flow
import { CollapsibleList } from "components/CollapsibleList/CollapsibleList";
import { ListEntry } from "components/ListEntry";
import { Valuation } from "./Valuation";

type Props = {
  dealId: string,
  reasonsForAdvanceOfferValuation?: $ReadOnlyArray<AdvanceOfferValuationsListNestDealQuery_reasonsForAdvanceOfferValuation>,
  users: $ReadOnlyArray<
    | PostVisitValuationsListNestDealQuery_activeNestedUsers
    | PreVisitValuationsListQuery_activeNestedUsers
    | AdvanceOfferValuationsListNestDealQuery_activeNestedUsers,
  >,
  valuations: Array<
    | PostVisitValuationsListNestDealQuery_nestDeal_valuationList
    | PreVisitValuationsListQuery_nestDeal_valuationList
    | AdvanceOfferValuationsListNestDealQuery_nestDeal_valuationList,
  >,
};

const createLabel = (count: number) => {
  switch (count) {
    case 1:
      return "1 Older Valuation -";
    default:
      return `${count} Older Valuations -`;
  }
};

export const ValuationsList = ({
  dealId,
  reasonsForAdvanceOfferValuation,
  users,
  valuations,
}: Props) => {
  const valuationComponents = valuations.map((valuation) => (
    <ListEntry key={`${valuation.__typename}:${valuation.id}`}>
      <Valuation
        valuation={valuation}
        users={users}
        dealId={dealId}
        isReadOnly={false}
        key={`${valuation.__typename}:${valuation.id}`}
        reasonsForAdvanceOfferValuation={reasonsForAdvanceOfferValuation}
      />
    </ListEntry>
  ));

  return (
    <section>
      <CollapsibleList createLabel={createLabel} items={valuationComponents} />
    </section>
  );
};
