// @flow
import { formatNumberAsCurrency } from "@nest-ui/hocs";
import moment from "moment-timezone";

const advancePercentage = ({
  advanceAmount,
  expectedSalePrice,
}: {
  advanceAmount: ?Money,
  expectedSalePrice: ?Money,
}) =>
  advanceAmount && expectedSalePrice
    ? ((Number(advanceAmount) / Number(expectedSalePrice)) * 100).toFixed(2)
    : 0;

export const valuationToSelectOption = ({
  advanceAmount,
  dateCompleted,
  expectedSalePrice,
  id,
}: *) => ({
  value: id,
  label: `${formatNumberAsCurrency(advanceAmount)} (${advancePercentage({
    advanceAmount,
    expectedSalePrice,
  })}%) - ${moment(dateCompleted).format("DD/MM/YYYY")}`,
});
