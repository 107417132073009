// @flow
import styled from "styled-components";

import { formatNumberAsCurrency } from "@nest-ui/hocs";
import moment from "moment-timezone";

import { DottedList, DottedListItemSpaced } from "./ComparableCardComponents";
import { ComparableStatusEdit } from "./ComparableStatusEdit";

const Highlighted = styled.span`
  font-weight: 600;
`;

const StatusLabel = styled.span`
  background-color: ${({ color }) => color};
  border-radius: 12px;
  color: #353f52;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  padding: 5px 12px 3px;
  height: 24px;
`;

const statusColor = (status) => {
  switch (status) {
    case "OTM":
      return "#FFC466";
    case "Sold":
      return "#AEDCD0";
    case "UO":
      return "#C1D4E8";
    case "Withdrawn":
      return "#B2B2B2";
    default:
      return "#FFFFFF";
  }
};

type SalesProps = {
  comparable: ListComparablesQuery_comparables,
  lightTheme?: boolean,
  editMode?: boolean,
  mutation?: any,
};

export const SalesInformation = ({
  comparable,
  lightTheme,
  editMode,
}: SalesProps) =>
  editMode ? (
    <ComparableStatusEdit comparable={comparable} />
  ) : (
    <DottedList>
      <DottedListItemSpaced lightTheme={lightTheme}>
        <StatusLabel
          color={statusColor(comparable.status)}
          data-test="comparable-card:status-label"
        >
          {comparable.status}
        </StatusLabel>
      </DottedListItemSpaced>

      {comparable.soldPrice && (
        <DottedListItemSpaced lightTheme={lightTheme}>
          Sold at{" "}
          <Highlighted data-test="comparable-card:status-price">
            {formatNumberAsCurrency(comparable.soldPrice)}
          </Highlighted>
          {comparable.soldDate && (
            <>
              {" "}
              on{" "}
              <Highlighted data-test="comparable-card:status-date">
                {moment(comparable.soldDate).format("DD/MM/YYYY")}
              </Highlighted>
            </>
          )}
        </DottedListItemSpaced>
      )}
      {comparable.status === "UO" && comparable.underOfferPrice && (
        <DottedListItemSpaced lightTheme={lightTheme}>
          Under offer at{" "}
          <Highlighted>
            {formatNumberAsCurrency(comparable.underOfferPrice)}
          </Highlighted>
          {comparable.underOfferDate && (
            <>
              {" "}
              on{" "}
              <Highlighted>
                {moment(comparable.underOfferDate).format("DD/MM/YYYY")}
              </Highlighted>
            </>
          )}
        </DottedListItemSpaced>
      )}
      {comparable.listingPrice && (
        <DottedListItemSpaced lightTheme={lightTheme}>
          Listed at{" "}
          <Highlighted>
            {formatNumberAsCurrency(comparable.listingPrice)}
          </Highlighted>
        </DottedListItemSpaced>
      )}
    </DottedList>
  );
