import T from "prop-types";
import styled from "styled-components";

const Circle = styled.div`
  border-radius: 50%;
  background-color: white;
  width: 40px;
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -28px;
  margin-bottom: -28px;
  z-index: 1;
  position: relative;
`;

const transform = (direction) =>
  direction === "up" ? "scale(1, -1) translate(0, -16)" : "";

const ChainArrow = ({ direction }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g transform={transform(direction)} fill="none" fillRule="evenodd">
      <path
        fill="#353F52"
        fillRule="nonzero"
        d="M8 13.28L3.686 8.77a.473.473 0 0 0-.684.654l4.656 4.868a.473.473 0 0 0 .684 0l4.656-4.868a.473.473 0 1 0-.684-.654L8 13.28z"
      />
      <path stroke="#353F52" strokeLinecap="round" d="M8 1.888v12" />
    </g>
  </svg>
);

ChainArrow.propTypes = {
  direction: T.oneOf(["up", "down"]),
};

export function ChainJoin({ direction }) {
  return (
    <Circle>
      <ChainArrow direction={direction} />
    </Circle>
  );
}

ChainJoin.propTypes = {
  direction: T.oneOf(["up", "down"]),
};
