// @flow
import { useSelect } from "downshift";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { isEqual } from "lodash";
import { optionalLabelStyle } from "../TextInput/TextInput";

const wrapperStyle = css`
  position: relative;
  margin: ${({ $forListView }) => ($forListView ? "20px 0 4px" : "0")};
`;

const buttonStyle = css`
  background-color: white;
  border: 1px solid
    ${({ valid, theme }) =>
      valid ? theme.palette.hague40 : theme.palette.terracotta150};
  border-radius: 5px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  line-height: 14px;
  font-size: 14px;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  ${({ open }) =>
    open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const labelStyle = css`
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.palette.hague40};
  color: ${({ theme }) => theme.palette.hague80};
  flex-grow: 0;
`;

const labelAboveStyle = css`
  margin: 0 0 5px 0;
  font-weight: 500;
  color: ${({ theme, valid }) =>
    valid ? theme.palette.hague100 : theme.palette.terracotta150};
`;

const valueStyle = css`
  padding: 10px;
  color: ${({ theme }) => theme.palette.hague100};
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const caretStyle = css`
  padding: 10px;
  padding-left: 0;
  color: ${({ theme }) => theme.palette.hague80};
  flex-grow: 0;
`;

export const listStyle = css`
  z-index: 10;
  list-style-type: none;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  margin: 0;
  display: ${({ open }) => (open ? "block" : "none")};
  padding: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-top: none;
  outline: none;
  max-height: 200px;
  overflow-y: auto;
`;

export const listItemStyle = css`
  padding: 10px 10px;
  font-size: 14px;
  line-height: 17px;
  font-weight: ${({ selected }) => (selected ? "500" : "400")};
  border: 1px solid transparent;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: space-between;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.palette.hague40 : theme.palette.hague100};

  ${({ highlight }) =>
    highlight &&
    css`
      background: ${({ theme }) => theme.palette.blue15};
    `};

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 500;
      color: ${({ theme, $disabled }) =>
        $disabled ? theme.palette.hague40 : theme.palette.hague100};
    `};
`;

const listItemLabelStyle = css`
  padding-right: 5px;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const tickStyles = css`
  color: ${({ theme }) => theme.palette.green500};
  float: right;
`;

const InLineLabel = ({ "data-test": dataTest, label }) => (
  <span css={labelStyle} data-test={`${dataTest}:label`}>
    {label}
  </span>
);

const AboveLabel = ({ "data-test": dataTest, label, optional, valid }) => (
  <p css={labelAboveStyle} data-test={`${dataTest}:label`} valid={valid}>
    {label}
    {optional && <span css={optionalLabelStyle}>Optional</span>}
  </p>
);

const OptionalLabel = () => (
  <p css={labelAboveStyle}>
    <span css={optionalLabelStyle}>Optional</span>
  </p>
);

export type Props<T> = {
  options: {
    disabled?: boolean,
    label: string,
    value: T,
  }[],
  value: T,
  onChange(value: T): any,
  className?: string,
  "data-test"?: string,
  forListView?: boolean,
  label?: string,
  labelAbove?: boolean,
  optional?: boolean,
  valid?: boolean,
  placeholder?: string,
  disableSelect?: boolean,
};

export const Select = <T>({
  options,
  value,
  onChange,
  className,
  forListView,
  labelAbove,
  label,
  optional = false,
  valid = true,
  disableSelect = false,
  placeholder = null,
  "data-test": dataTest = "select-field",
}: Props<T>) => {
  const selectedItem =
    options.find((option) => isEqual(option.value, value)) || null;

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    selectedItem,
    items: options,
    itemToString: ({ label: itemLabel }) => itemLabel,
    onSelectedItemChange: (changes) =>
      onChange(changes.selectedItem?.value || null),
  });

  return (
    <div
      data-test={dataTest}
      css={wrapperStyle}
      className={className}
      $forListView={forListView}
    >
      {labelAbove && label && (
        <AboveLabel
          data-test={dataTest}
          label={label}
          optional={optional}
          valid={valid}
        />
      )}
      {!labelAbove && optional && <OptionalLabel />}
      <button
        open={isOpen}
        {...getToggleButtonProps()}
        css={buttonStyle}
        data-test={`${dataTest}:button`}
        disabled={disableSelect}
        type="button"
        valid={valid}
      >
        {!labelAbove && label && (
          <InLineLabel data-test={dataTest} label={label} />
        )}
        <span css={valueStyle} data-test={`${dataTest}:value`}>
          {selectedItem?.label || placeholder}
        </span>
        <span css={caretStyle}>
          <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
        </span>
      </button>
      <ul open={isOpen} css={listStyle} {...getMenuProps()}>
        {options.map((item, index) => {
          const selected = selectedItem?.value === item.value;
          return (
            <li
              css={listItemStyle}
              $disabled={item.disabled}
              highlight={highlightedIndex === index}
              selected={selected}
              key={item.value}
              data-test={`${dataTest}:option:${String(item.value)}`}
              {...getItemProps({ item, index, disabled: item.disabled })}
            >
              <span css={listItemLabelStyle}>{item.label}</span>
              {selected && <FontAwesomeIcon icon={faCheck} css={tickStyles} />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
