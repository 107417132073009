// @flow

import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { media, TeamMember } from "@nested/brand";

import { useUser } from "../../../hooks/useUser";
import { type toggleCompleteTaskType } from "../../../hooks/useTaskCompletion";
import { type AgentTaskNoReference } from "../../../pages/Tasks/Tasks";
import { CircularCheckbox } from "../../Checkbox/CircularCheckbox";
import { TaskDatePicker } from "./TaskDatePicker";
import { TextAndIconButton } from "../../TextAndIconButton";
import { ChangeOnBlurTextArea } from "../../TextArea/ChangeOnBlurTextArea";
import { teamMemberStyle, AssignedTo } from "./AssignedTo";

export const UPDATE_DESCRIPTION = gql`
  mutation UpdateAgentTaskDescription($taskId: ID!, $description: String!) {
    updateAgentTaskDescription(taskId: $taskId, description: $description) {
      agentTask {
        id
        description
      }
    }
  }
`;

export const RESCHEDULE_TASK = gql`
  mutation RescheduleAgentTask($taskId: ID!, $dueAt: DateTime!) {
    rescheduleAgentTask(taskId: $taskId, dueAt: $dueAt) {
      agentTask {
        id
        dueAt
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($taskId: ID!) {
    deleteAgentTask(taskId: $taskId) {
      successful
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateAgentTask($input: CreateAgentTaskInput!) {
    createAgentTask(input: $input) {
      agentTask {
        id
      }
    }
  }
`;

const checkboxStyle = css`
  padding-right: 20px;
  ${media.tablet`
    padding-right: 10px;
  `}
`;

const dateStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  padding-left: 2px;
`;

const dueAndAssignedToStyle = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 23px;
  padding-top: 3px;
  ${media.tablet`
    padding-top: 0;
  `}
`;

const descriptionStyles = css`
  textarea {
    background-color: transparent;
    border-radius: 0px;
    border: none;
    margin-top: 1px;
    overflow: hidden;
    padding: 2px;
    z-index: 0;
    resize: none;
    min-height: 0;
    max-height: unset;
    font-weight: 400;

    &:focus {
      border: none;
    }

    ${({ $isCompletedTask }) =>
      $isCompletedTask &&
      css`
        &:disabled {
          background-color: transparent;
          opacity: 1;
        }
      `}

    ${media.tablet`
      max-width: calc(100% - 25px);
    `}
  }
`;

const taskContainerStyle = css`
  display: flex;
  padding-bottom: 19px;

  ${media.tablet`
    padding-bottom: 9px;
  `}
`;

const newTaskContainerStyle = css`
  ${taskContainerStyle}
  opacity: ${({ $loading }) => ($loading ? 0.3 : 1)};
  transition: all 200ms ease-in;
  overflow: hidden;
`;

type TaskProps = {
  item: AgentTaskNoReference,
  isLastTask: boolean,
  toggleCompleteTask: toggleCompleteTaskType,
  refetchTasks: () => Promise<*>,
};

export const isTodayOrEarlier = (date: string) =>
  moment(date).isSameOrBefore(moment(), "day");

export const Task = ({
  item: task,
  isLastTask,
  toggleCompleteTask,
  refetchTasks,
}: TaskProps) => {
  const [updateDescription] = useMutation(UPDATE_DESCRIPTION);
  const [deleteTask] = useMutation(DELETE_TASK);
  const [rescheduleTask] = useMutation(RESCHEDULE_TASK);
  const { createNotification } = useNotifications();
  const [disableEditing, setDisableEditing] = useState(false);

  const isCompletedTask = Boolean(task.completedAt);

  const onDeleteTask = async () => {
    try {
      setDisableEditing(true);
      const result = await deleteTask({
        variables: { taskId: task.id },
        optimisticResponse: {
          deleteAgentTask: {
            successful: true,
          },
        },
      });

      if (result?.data?.deleteAgentTask?.successful) {
        await refetchTasks();

        setTimeout(() => {
          createNotification("Task deleted");
        }, 500);
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      setDisableEditing(false);
    }
  };

  const onUpdateTaskDescription = async (newDescription) => {
    try {
      setDisableEditing(true);
      await updateDescription({
        variables: { taskId: task.id, description: newDescription },
        optimisticResponse: {
          updateAgentTaskDescription: {
            agentTask: {
              __typename: "AgentTask",
              id: task.id,
              description: newDescription,
            },
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    } finally {
      setDisableEditing(false);
    }
  };

  const onRescheduleTask = async (dueAt) => {
    const newDueAt = moment(dueAt).format();
    try {
      setDisableEditing(true);
      await rescheduleTask({
        variables: { taskId: task.id, dueAt: newDueAt },
        optimisticResponse: {
          rescheduleAgentTask: {
            agentTask: {
              __typename: "AgentTask",
              id: task.id,
              dueAt: newDueAt,
            },
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    } finally {
      setDisableEditing(false);
    }
  };

  const onDescriptionChange = async (newDescription) => {
    if (newDescription.length === 0) {
      await onDeleteTask();
      return;
    }

    if (task.description === newDescription) return;

    await onUpdateTaskDescription(newDescription);
  };

  const taskDateValue = task.completedAt ? task.completedAt : task.dueAt;

  return (
    <div css={taskContainerStyle} data-test={`task-container-${task.id}`}>
      <CircularCheckbox
        checked={Boolean(task.completedAt)}
        css={checkboxStyle}
        data-test="task-panel-checkbox"
        id={task.id}
        onChange={() => toggleCompleteTask(task, isLastTask)}
      />
      <div css="flex-grow: 1;">
        <ChangeOnBlurTextArea
          css={descriptionStyles}
          data-test={"task-description"}
          disabled={disableEditing || isCompletedTask}
          id={task.id}
          $isCompletedTask={isCompletedTask}
          value={task.description}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              // $FlowFixMe
              onDescriptionChange(e.target.value);
            }
          }}
          onChange={(e) => {
            onDescriptionChange(e.target.value);
          }}
          onFocus={(e) => e.target.select()}
        />
        <div css={dueAndAssignedToStyle}>
          <TaskDatePicker
            value={taskDateValue}
            onSubmit={onRescheduleTask}
            isCompletedTask={isCompletedTask}
            isDueTask={isTodayOrEarlier(task.dueAt)}
            data-test={`reschedule-task-${task.id}`}
          />
          <AssignedTo
            assignedTo={task.assignedTo}
            assignedToUser={task.assignedToUser}
            isCompletedTask={isCompletedTask}
            refetchTasks={refetchTasks}
            taskId={task.id}
          />
        </div>
      </div>
    </div>
  );
};

const NewTask = ({ createNewTask, disableEditing, setCreatingTask }) => {
  const descriptionInput = useRef(null);
  const { selectedUser } = useUser();
  const [loading, setLoading] = useState(true);

  const onClose = () => {
    setLoading(true);
    setTimeout(() => setCreatingTask(false), 200);
  };

  useEffect(() => {
    setLoading(false);
    descriptionInput.current?.focus();
  }, []);

  return (
    <div
      css={newTaskContainerStyle}
      data-test="new-task-container"
      $loading={loading}
    >
      <CircularCheckbox
        checked={false}
        css={checkboxStyle}
        id={"creating"}
        onChange={() => null}
      />
      <div css="flex-grow: 1;">
        <ChangeOnBlurTextArea
          css={descriptionStyles}
          data-test={"new-task-description"}
          disabled={disableEditing}
          forwardedRef={descriptionInput}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              // $FlowFixMe
              createNewTask(e.target.value, onClose);
            }
          }}
          onChange={(e) => {
            createNewTask(e.target.value, onClose);
          }}
          value=""
        />
        <div css={dueAndAssignedToStyle}>
          <div css={dateStyle} editable={false}>
            <FontAwesomeIcon icon={faCalendarAlt} css="margin-right: 5px;" />
            Today
          </div>
          <div>
            <TeamMember
              css={teamMemberStyle}
              email={selectedUser.email}
              fullName={selectedUser.fullName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddTask = ({
  refetchTasks,
  relationType,
}: {
  refetchTasks: () => Promise<*>,
  relationType: string,
}) => {
  const { id: referenceId } = useParams();
  const [creatingTask, setCreatingTask] = useState(false);
  const [createTask] = useMutation(CREATE_TASK);
  const { selectedUser } = useUser();
  const [disableEditing, setDisableEditing] = useState(false);

  const createNewTask = async (description, onSuccess) => {
    if (description === "") {
      onSuccess();
      return;
    }

    try {
      setDisableEditing(true);
      const result = await createTask({
        variables: {
          input: {
            assignedTo: selectedUser.email,
            description,
            dueAt: moment().startOf("day"),
            referenceType: relationType.toUpperCase(),
            referenceId,
          },
        },
      });

      if (result?.data?.createAgentTask?.agentTask?.id) {
        await refetchTasks();
        onSuccess();
      }
    } catch (e) {
      errorHandler(e);
    }
    setDisableEditing(false);
  };

  return (
    <div css="margin: 0 0 23px;">
      {creatingTask && (
        <NewTask
          createNewTask={createNewTask}
          disableEditing={disableEditing}
          setCreatingTask={setCreatingTask}
        />
      )}
      <TextAndIconButton
        onClick={() => setCreatingTask(true)}
        data-test="add-task"
        text="Add a task"
      />
    </div>
  );
};
