// @flow
import { getDateDiff } from "@nested/utils/src/formatDate/formatDate";
import { TimelineItem } from "@nested/brand/src/components/Timeline";
import { EditableNote } from "./Notes/EditableNote";

type Props = {
  event: Timeline_timeline,
};

export const TimelineSnooze = ({ event }: Props) => {
  if (event.__typename !== "TimelineEvent") {
    return null;
  }

  const { timestamp, userEmail, dateSnoozedUntil } = event;
  const dateDiff = dateSnoozedUntil && getDateDiff(timestamp, dateSnoozedUntil);

  return (
    <TimelineItem
      primaryHeading={dateDiff ? `Snoozed for ${dateDiff}` : ""}
      icon="snooze"
      userEmail={userEmail}
      timestamp={timestamp}
    >
      <EditableNote event={event} isShareable={false} />
    </TimelineItem>
  );
};
