// @flow
import { modalHeader } from "tabs/Interest/PotentialBuyers/MailoutModal/MailoutModal";
import { BackButton } from "@nested/nest/src/components/BackButton";
import { EmailInputWithValidation } from "@nested/nest/src/components/TextInput/EmailInputWithValidation";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Button } from "@nested/nest/src/components/Button/Button";
import { Form, useForm } from "react-final-form";

const enterEmailStyle = css`
  display: flex;
  gap: 10px;
  // styles spinning loader
  & svg {
    top: 0;
    right: 6px;
  }

  // styles error message
  span {
    bottom: -40px;
  }
`;

const emailInputStyle = css`
  width: 100%;

  & * {
    height: 42px;
    ${media.tablet`
      height: 36px;
    `}
  }
`;

const emailInputContainerStyle = css`
  height: 200px;
  margin: 50px 5px 0 5px;
`;

const nextButtonStyle = css`
  height: 42px;
  width: 63px;

  ${media.tablet`
    height: 36px;
    width: 76px;
  `}
`;

type Props = {
  onBack: () => void,
  sellerOrBuyerColumn: "seller" | "buyer",
};

export const AddCcEmailModal = ({ onBack, sellerOrBuyerColumn }: Props) => {
  const form = useForm();

  const onSubmit = async ({ email }) => {
    const selectedContacts = {
      ...form.getState().values?.selectedContacts,
      [`${sellerOrBuyerColumn}-cc-email:${email}`]: true,
    };

    const addedSellerOrBuyerEmails =
      sellerOrBuyerColumn === "seller"
        ? "addedSellerCcEmails"
        : "addedBuyerCcEmails";

    const addedCcEmails =
      form.getState().values?.[addedSellerOrBuyerEmails] || [];

    addedCcEmails.push(email);

    form.change(addedSellerOrBuyerEmails, addedCcEmails);
    form.change("selectedContacts", selectedContacts);

    onBack();
  };

  return (
    <div css="flex-grow: 1;">
      <div css={modalHeader}>
        <BackButton onClick={onBack} />
        Add a contact
      </div>
      <Form initialValues={{ email: "" }} onSubmit={onSubmit}>
        {({
          submitting,
          handleSubmit,
          modifiedSinceLastSubmit,
          submitSucceeded,
          valid,
          validating,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div css={{ emailInputContainerStyle }}>
                <div css={enterEmailStyle}>
                  <EmailInputWithValidation
                    placeholder="Enter email"
                    fieldName="email"
                    isOpen
                    css={emailInputStyle}
                    className="fs-exclude"
                    data-test="search-contact-email"
                  />
                  <Button
                    css={nextButtonStyle}
                    disabled={
                      validating ||
                      !valid ||
                      submitting ||
                      (submitSucceeded && !modifiedSinceLastSubmit)
                    }
                    type="submit"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </form>
          </>
        )}
      </Form>
    </div>
  );
};
