import { withHandlers, withProps, compose } from "recompose";
import { withOnSubmit, withReadOnly } from "@nest-ui/hocs";
import { isPresent } from "@nested/utils/isPresent";

import { NoSubmitTextField } from "components/TextField";

const cast = (x) => (x === "" ? null : Number(x));
const format = (x) => (x === null ? "" : Number(x));
// Deals with EGGL serializing decimals as strings and floats / ints as numbers.
const nonNullToNumber = (x) => (isPresent(x) ? Number(x) : null);

const enhance = compose(
  withHandlers({
    cast: () => cast,
    format: () => format,
  }),
  withProps(({ value }) => ({
    type: "number",
    value: nonNullToNumber(value),
  })),
  withReadOnly(({ value }) => (isPresent(value) ? String(value) : null)),
);

export const NoSubmitNumberField = enhance(NoSubmitTextField);

export const NumberField = withOnSubmit(NoSubmitNumberField);
