// @flow
import styled from "styled-components";
import { device } from "@nest-ui/styles";

import { LIGHT_BLUE } from "./StyledComponents";

const SwooshWrapper = styled.div`
  background-color: ${({ theme: t }) => t.color.primary.dark};
  background-image: url("https://nested.imgix.net/icons/map_blob.svg?auto=format,
    compress");
  background-position: bottom -300px left -150px;
  background-repeat: no-repeat;
  background-size: 449px 521px;
  height: 150px;
  width: 100vw;
  position: absolute;
  z-index: -1;
`;

const InvertedSwoosh = styled.div`
  background-color: transparent;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 25px;

  @media ${device.tablet} {
    height: 50px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Swoosh = () => (
  <SwooshWrapper>
    <InvertedSwoosh>
      <svg preserveAspectRatio="none" viewBox="0 0 1440 50">
        <path
          fill={LIGHT_BLUE}
          d="M0,0 C240,33.3333333 480,50 720,50 C960,50 1200,33.3333333 1440,0 L1440,50 L0,50 L0,0 Z"
        />
      </svg>
    </InvertedSwoosh>
  </SwooshWrapper>
);
