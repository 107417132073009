// @flow
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { useSorting } from "@nested/nest/src/hooks/useSorting";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import { Select } from "../../components/Select/Select";
import { NoResults } from "../../components/NoResults";
import { ListViewError } from "../../components/ListViewError";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import { getFullName } from "../utils";

const SELLER_NAME = "SELLER_NAME";
const PROPERTY_ANALYSIS_SHARED_AT = "PROPERTY_ANALYSIS_SHARED_AT";
const PROPERTY_ANALYSIS_VIEWED_AT = "PROPERTY_ANALYSIS_VIEWED_AT";
const RECOMMENDED_LIST_PRICE = "RECOMMENDED_LIST_PRICE";
const POTENTIAL_FEE = "POTENTIAL_FEE";

const MOBILE_SORT_OPTIONS = [
  { label: "Seller name", value: SELLER_NAME },
  {
    label: "Valuation shared",
    value: PROPERTY_ANALYSIS_SHARED_AT,
  },
];

export const CLOSE_LIST_VIEW_QUERY = gql`
  query CloseListViewQuery(
    $sortBy: CloseListViewSortBy
    $sortDirection: ListViewSortDirection
    $email: String!
  ) {
    closeDeals(sortBy: $sortBy, sortDirection: $sortDirection, email: $email) {
      results {
        id
        externalId
        firstName
        lastName
        ownerName
        propertyAnalysisSharedAt
        propertyAnalysisLastViewedAt
        rmRecommendedListPrice
        potentialFee
        address
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
          }
          avatarUrl
        }
      }
    }
  }
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0px;
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          key={deal.externalId}
          externalId={deal.externalId}
          address={deal.address}
          sellerName={getFullName(deal)}
          contacts={deal.contacts}
          detailText={
            deal.propertyAnalysisSharedAt
              ? `Val shared ${formatDatetimeDifference(
                  deal.propertyAnalysisSharedAt,
                )}`
              : ""
          }
          detailTextTitle={moment(deal.propertyAnalysisSharedAt).format("LLLL")}
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field={SELLER_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <SortableTH
              field={PROPERTY_ANALYSIS_SHARED_AT}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Valuation shared
            </SortableTH>
            <SortableTH
              field={PROPERTY_ANALYSIS_VIEWED_AT}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Valuation viewed
            </SortableTH>
            <SortableTH
              field={RECOMMENDED_LIST_PRICE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Recommended list price
            </SortableTH>
            <SortableTH
              css="padding-right: 10px;"
              field={POTENTIAL_FEE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Potential fee
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => {
            return (
              <tr
                key={deal.externalId}
                data-test={`deal-row-${deal.externalId}`}
              >
                <DealTD dealId={deal.externalId} className="fs-exclude">
                  {deal.address}
                </DealTD>
                <DealTD dealId={deal.externalId} className="fs-exclude">
                  {getFullName(deal)}
                </DealTD>
                <DealTD
                  dealId={deal.externalId}
                  title={
                    deal.propertyAnalysisSharedAt &&
                    moment(deal.propertyAnalysisSharedAt).format("LLLL")
                  }
                >
                  {deal.propertyAnalysisSharedAt
                    ? formatDatetimeDifference(deal.propertyAnalysisSharedAt)
                    : "-"}
                </DealTD>
                <DealTD
                  dealId={deal.externalId}
                  title={
                    deal.propertyAnalysisLastViewedAt &&
                    moment(deal.propertyAnalysisLastViewedAt).format("LLLL")
                  }
                >
                  {deal.propertyAnalysisLastViewedAt
                    ? formatDatetimeDifference(
                        deal.propertyAnalysisLastViewedAt,
                      )
                    : "-"}
                </DealTD>
                <DealTD dealId={deal.externalId}>
                  {deal.rmRecommendedListPrice
                    ? formatPrice(parseFloat(deal.rmRecommendedListPrice))
                    : "-"}
                </DealTD>
                <DealTD dealId={deal.externalId}>
                  {deal?.potentialFee
                    ? formatPrice(parseFloat(deal.potentialFee))
                    : "-"}
                </DealTD>
                <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                  <ListViewCallButton
                    contacts={deal.contacts}
                    externalId={deal.externalId}
                    relationType={"deal"}
                  />
                </DealTD>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export const CloseListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    PROPERTY_ANALYSIS_SHARED_AT,
    "ASCENDING",
  );
  const { selectedUser } = useUser();
  const { close } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(CLOSE_LIST_VIEW_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [close]);

  const results = data?.closeDeals?.results || [];

  if (!loading && results.length === 0) {
    return <NoResults message="You have no leads to close." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
