// @flow

import { useState } from "react";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { css } from "styled-components";

import { Card } from "components/Card";
import { TabContainer } from "components/TabContainer";
import { Grid, LeafCell } from "components/Grid/Grid";
import { H2 } from "components/Heading/index";
import { NoSubmitCheckbox } from "components/Checkbox/Checkbox";
import { NoSubmitCreateButton } from "components/CreateButton/CreateButton";
import { AlignRight } from "components/AlignRight";

import { Card as ReportCard } from "./Card";
import { ReportList } from "./ReportList";
import { CreateBuyersReport } from "./forms/CreateBuyersReport";

const reportsWrapper = css`
  max-width: 720px;
`;

const GET_INTRODUCTION_CALL_COMPLETE = gql`
  query GetIntroductionCallStatus($customerAccountId: ID!) {
    buyersAgentAccountReference(customerAccountId: $customerAccountId) {
      id
      introductionCallComplete
      customerAccountId
    }
  }
`;

const UPDATE_INTRODUCTION_CALL_COMPLETE = gql`
  mutation updateIntroductionCallComplete(
    $customerAccountId: ID!
    $input: UpsertBuyersAgentAccountReferenceInput!
  ) {
    upsertBuyersAgentAccountReference(
      customerAccountId: $customerAccountId
      input: $input
    ) {
      id
      introductionCallComplete
      customerAccountId
    }
  }
`;

type Props = {
  customerAccountId: string,
};

export const Reports = ({ customerAccountId }: Props) => {
  const [isCreating, setCreating] = useState(false);

  const [upsertCustomerAccountSetting] = useMutation(
    UPDATE_INTRODUCTION_CALL_COMPLETE,
  );

  const { data, loading, error } = useQuery(GET_INTRODUCTION_CALL_COMPLETE, {
    variables: {
      customerAccountId,
    },
  });

  const submitHandler = async (value: boolean) => {
    try {
      await upsertCustomerAccountSetting({
        variables: {
          customerAccountId,
          input: {
            introductionCallComplete: value,
          },
        },
        refetchQueries: [
          {
            query: GET_INTRODUCTION_CALL_COMPLETE,
            variables: {
              customerAccountId,
            },
          },
        ],
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(
        `Failed to update the introduction call setting. Please try again later or contact helpdesk if the problem persists:\n\n${e}`,
      );
    }
  };

  return (
    <TabContainer
      data-test="buyers-agent-services-heading"
      css={reportsWrapper}
    >
      <Grid columns={4} rowGap="0px">
        <LeafCell width={4}>
          <H2>Customer information</H2>
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCheckbox
            data-test="introduction-call-complete"
            onSubmit={submitHandler}
            optionName="Introduction call complete?"
            value={
              loading || error || !data?.buyersAgentAccountReference
                ? false
                : data?.buyersAgentAccountReference?.introductionCallComplete
            }
          />
        </LeafCell>
      </Grid>
      <Card>
        <Grid columns={4} rowGap="0px">
          <LeafCell width={2}>
            <H2>Buyers Reports</H2>
          </LeafCell>

          <LeafCell width={2}>
            <AlignRight>
              <NoSubmitCreateButton
                data-test="add-new-buyers-report"
                disabled={isCreating}
                onSubmit={() => setCreating(true)}
                label="Create new report"
              />
            </AlignRight>
          </LeafCell>
        </Grid>

        {isCreating && (
          <ReportCard>
            <CreateBuyersReport
              customerAccountId={customerAccountId}
              onClose={() => setCreating(false)}
            />
          </ReportCard>
        )}

        <ReportList customerAccountId={customerAccountId} />
      </Card>
    </TabContainer>
  );
};
