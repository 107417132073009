// @flow
import { gql } from "@apollo/client";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { Grid, LeafCell } from "components/Grid";
import { TextArea } from "components/TextArea";
import { H2 } from "components/Heading";

import { type MutationFunction } from "@apollo/client/react/components";
import { InfoTile, InfoSection } from "../InfoTile";

const VIEWING_ACCESS_MUTATION = gql`
  mutation UpdateViewingAccessPropertyMutation(
    $id: ID!
    $input: PropertyInput!
  ) {
    updateProperty(id: $id, input: $input) {
      id
      viewingAccessParticulars
      outsideSpaceAccess
    }
  }
`;

type Props = {
  editMode: boolean,
  nestDeal: ViewingAppNestDealQuery_nestDeal,
};

export const ViewingAccess = ({ editMode, nestDeal }: Props) => {
  const {
    property: { viewingAccessParticulars, outsideSpaceAccess, id },
  } = nestDeal;

  if (editMode) {
    return (
      <ExtendedMutation mutation={VIEWING_ACCESS_MUTATION}>
        {(
          UpdateViewingAccessPropertyMutation: MutationFunction<
            UpdateViewingAccessPropertyMutation,
            UpdateViewingAccessPropertyMutationVariables,
          >,
        ) => (
          <>
            <H2>Viewing Access</H2>
            <Grid columns={2} rows="auto auto">
              <LeafCell width={1}>
                <TextArea
                  data-test="getting-in:edit"
                  label="How to get into the property  (any tricks i.e. stubborn door, turn the wrong way etc)"
                  value={viewingAccessParticulars}
                  property="viewingAccessParticulars"
                  mutation={(input) =>
                    UpdateViewingAccessPropertyMutation({
                      variables: { id, input },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateProperty: {
                          __typename: "Property",
                          ...nestDeal.property,
                          ...input,
                        },
                      },
                    })
                  }
                />
              </LeafCell>
              <LeafCell width={1}>
                <TextArea
                  data-test="outside-access:edit"
                  label="Outside space access (Where is the key? How do I get to the garden?)"
                  value={outsideSpaceAccess}
                  property="outsideSpaceAccess"
                  mutation={(input) =>
                    UpdateViewingAccessPropertyMutation({
                      variables: { id, input },
                      optimisticResponse: {
                        __typename: "Mutation",
                        updateProperty: {
                          __typename: "Property",
                          ...nestDeal.property,
                          ...input,
                        },
                      },
                    })
                  }
                />
              </LeafCell>
            </Grid>
          </>
        )}
      </ExtendedMutation>
    );
  }

  return (
    <InfoSection title="Viewing Access">
      <InfoTile
        data-test="getting-in"
        title="How to get in to the property (any tricks i.e. stubborn door, turn the wrong way etc)"
        text={viewingAccessParticulars}
      />
      <InfoTile
        data-test="outside-access"
        title="Outside space access (Where is the key? How do I get into the garden?)"
        text={outsideSpaceAccess}
      />
    </InfoSection>
  );
};
