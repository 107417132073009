// @flow
import { useMemo } from "react";
import moment from "moment-timezone";
import { css } from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { groupBy, max } from "lodash";

import { viewingAgentLabel } from "@nest-ui/sellers-nest/tabs/Interest/Viewings/utility-functions";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

const tableContainer = css`
  margin: 16px 0;
`;

const tableStyles = css`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const tableHeadStyles = css`
  text-transform: uppercase;
  background-color: #0a4254;
  color: #ffffff;

  th {
    padding: 10px;
    text-align: left;
  }

  td {
    width: 25%;
  }
`;

const tableBodyStyles = css`
  background-color: ${({ theme }) => theme.color.background.default};
  background-color: #fdfdfc;
  color: #0a4254;
  vertical-align: top;

  tr:nth-child(even) {
    background-color: #e6eced;
  }

  td {
    padding: 10px;
    font-weight: 500;
  }

  p {
    margin: 0;
    padding: 2px;
  }
`;

const dateCellStyles = css`
  text-transform: uppercase;
`;

const virtualTextStyles = css`
  color: ${({ theme }) => theme.color.text.highlight};
`;

const unconfirmedTextStyles = css`
  color: #e8a233;
`;

const EXISTING_VIEWINGS_QUERY = gql`
  query ExistingViewings($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      viewings(filters: [UPCOMING, ACTIVE]) {
        id
        datetimeViewingStarts
        datetimeViewingEnds
        datetimeConfirmedWithVendor
        nestedUserId
        viewingSubAgentId
        virtual
        conductor
      }
    }
  }
`;

const formatAvailabilityTime = (time) =>
  moment(time, ["h:m a", "H:m"]).format("ha");

export const formatTimesAvailable = (
  ranges: $ReadOnlyArray<ViewingAvailability_nestDeal_viewingAvailability_availabilitySummaries_ranges>,
) => {
  if (ranges.length === 0) {
    return "Unavailable";
  }
  return ranges
    .map(
      ({ rangeStart, rangeEnd }) =>
        `${formatAvailabilityTime(rangeStart)}-${formatAvailabilityTime(
          rangeEnd,
        )}`,
    )
    .join(", ");
};

export const structureViewings = (
  viewings: $ReadOnlyArray<ExistingViewings_nestDeal_viewings>,
) => {
  const transformed = viewings
    .map((viewing) => {
      return {
        viewing,
        date: moment(viewing.datetimeViewingStarts).format("ddd D MMM"), // Fri 30 Oct
        time: moment(viewing.datetimeViewingStarts).format("h:mma"), // 3:00pm
      };
    })
    .reverse();
  const groupedByDay = groupBy(transformed, (v) => v.date);

  return groupedByDay;
};

type structuredAvailabilityType = Array<{
  dayOfWeek: string,
  ranges: $ReadOnlyArray<ViewingAvailability_nestDeal_viewingAvailability_availabilitySummaries_ranges>,
}>;

export const structureAvailability = (
  availabilities: $ReadOnlyArray<ViewingAvailability_nestDeal_viewingAvailability_availabilitySummaries>,
): structuredAvailabilityType => {
  const transformed = availabilities.map((availability) => {
    return {
      dayOfWeek: moment().isoWeekday(availability.dayOfWeek).format("ddd"), // Mon
      ranges: availability.ranges,
    };
  });

  return transformed;
};

const calculateTableSize = (viewings, currentDate) => {
  const defaultSizeInDays = 7;

  const viewingDates = viewings.map(
    ({ datetimeViewingStarts }) => datetimeViewingStarts,
  );
  const maxViewingDate = max(viewingDates);

  const daysToLastViewing =
    moment(maxViewingDate).diff(currentDate, "days") + 1; // include today

  return Math.max(daysToLastViewing, defaultSizeInDays);
};

type structuredDataType = Array<{
  date: string,
  viewings?: Array<any>,
  availability?: $ReadOnlyArray<ViewingAvailability_nestDeal_viewingAvailability_availabilitySummaries_ranges>,
}>;

export const structureData = (
  availability: $ReadOnlyArray<ViewingAvailability_nestDeal_viewingAvailability_availabilitySummaries>,
  viewings: $ReadOnlyArray<ExistingViewings_nestDeal_viewings>,
): structuredDataType => {
  const currentDate = moment().startOf("day"); // today 00:00
  const tableSize = calculateTableSize(viewings, currentDate);
  const structuredAvailability = structureAvailability(availability);
  const structuredViewings = structureViewings(viewings);

  return Array(Number.isNaN(tableSize) ? 0 : tableSize)
    .fill()
    .map(() => {
      const dayString = currentDate.format("ddd");
      const dateString = currentDate.format("ddd D MMM");

      const matchingViewings = structuredViewings[dateString];

      const matchingAvailabilities = structuredAvailability.find(
        (dayAvailability) => dayAvailability.dayOfWeek === dayString,
      );

      currentDate.add(1, "days"); // moments are mutable

      return {
        date: dateString,
        viewings: matchingViewings,
        availability: matchingAvailabilities?.ranges,
      };
    });
};

const viewingConductorFunction =
  (activeNestedUsers, subAgents, viewingAssistants) => (viewing) =>
    viewingAgentLabel({
      viewing,
      activeNestedUsers,
      subAgents,
      viewingAssistants,
    });

const TableRow = ({ date, viewings, availability, viewingConductor }) => (
  <tr>
    <td css={dateCellStyles}>{date}</td>
    <td>
      {viewings ? (
        viewings.map(({ viewing, time }) => {
          const agent = viewingConductor(viewing);
          return (
            <p key={viewing.id}>
              {time} - {agent}
              <span css={virtualTextStyles}>
                {viewing.virtual ? " Virtual" : ""}
              </span>
              <span css={unconfirmedTextStyles}>
                {viewing.datetimeConfirmedWithVendor || viewing.virtual
                  ? ""
                  : " Unconfirmed"}
              </span>
            </p>
          );
        })
      ) : (
        <p>-</p>
      )}
    </td>
    <td>{availability && <p>{formatTimesAvailable(availability)}</p>}</td>
  </tr>
);

type Props = {
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  dealId: ID,
  subAgents: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_deal_subAgents>,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
  viewingAvailability: ViewingAvailability_nestDeal_viewingAvailability,
};

export const ViewingPlanner = (props: Props) => {
  const {
    activeNestedUsers,
    dealId,
    subAgents,
    viewingAssistants,
    viewingAvailability,
  } = props;

  const { data, loading, error } = useQuery(EXISTING_VIEWINGS_QUERY, {
    variables: { dealId },
  });

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  const { nestDeal } = data;
  const viewingConductor = viewingConductorFunction(
    activeNestedUsers,
    subAgents,
    viewingAssistants,
  );

  return (
    <div css={tableContainer}>
      <table css={tableStyles}>
        <thead css={tableHeadStyles}>
          <tr>
            <td></td>
            <th>viewings</th>
            <th>property availability</th>
          </tr>
        </thead>
        <tbody css={tableBodyStyles}>
          <TabledData
            userAvailability={viewingAvailability.availabilitySummaries}
            viewingConductor={viewingConductor}
            allViewings={nestDeal.viewings}
          />
        </tbody>
      </table>
    </div>
  );
};

const TabledData = ({ userAvailability, viewingConductor, allViewings }) => {
  const structuredData = useMemo(
    () => structureData(userAvailability, allViewings),
    [userAvailability, allViewings],
  );

  return (
    <>
      {structuredData.map(({ date, viewings, availability }) => (
        <TableRow
          key={date}
          availability={availability}
          date={date}
          viewings={viewings}
          viewingConductor={viewingConductor}
        />
      ))}
    </>
  );
};
