// @flow
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
`;

export const inputStyle = css`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0px;
  padding: 0px;
  &:focus-visible {
    & ~ label {
      outline: 1px solid ${({ theme }) => theme.palette.hague100};
    }
  }
  /* We want to hide it, but keep it "visible" so you can actually tab
  to it for styling purposes */
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

export const checkBoxOuter = css`
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  box-sizing: border-box;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  // margin needed to allow room for outline on focus
  margin: 1px;
  margin-right: ${({ $labelText }) => ($labelText ? "10px" : "1px")};
  position: relative;
  display: inline-block;
  text-align: center;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.hague20 : "white"};
  cursor: pointer;
`;

export const checkBoxTick = css`
  position: absolute;
  top: 3px;
  left: 2.5px;
  font-size: 13px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.hague40 : theme.palette.green100};
  opacity: ${({ checked }) => (checked ? 1 : 0)};
`;
