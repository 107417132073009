// @flow
import { Switch, Route } from "react-router-dom";
import { ContactsTab } from "./ContactsTab";
import { EditContact } from "./EditContact";
import { FindContact } from "./FindContact";
import { CreateContact } from "./CreateContact";

type ContactManagementProps = {
  dealId?: string,
  buyerId?: string,
  editing: boolean,
  onClose(): void,
  setEditing(boolean): void,
  Wrapper: React$ComponentType<{ onClose(): void, children: React$Node }>,
};

export const ContactManagement = ({
  dealId,
  editing,
  buyerId,
  onClose,
  setEditing,
  Wrapper,
}: ContactManagementProps) => (
  <Switch>
    <Route path="/contacts" exact>
      <Wrapper onClose={onClose}>
        <ContactsTab dealId={dealId} buyerId={buyerId} onClose={onClose} />
      </Wrapper>
    </Route>
    <Route path="/contacts/search">
      <FindContact dealId={dealId} buyerId={buyerId} onClose={onClose} />
    </Route>
    <Route path="/contacts/new">
      <CreateContact
        dealId={dealId}
        buyerId={buyerId}
        editing={editing}
        onClose={onClose}
        setEditing={setEditing}
      />
    </Route>
    <Route path="/contacts/:contactId">
      <EditContact
        buyerId={buyerId}
        dealId={dealId}
        editing={editing}
        onClose={onClose}
        setEditing={setEditing}
      />
    </Route>
  </Switch>
);
