// @flow
import { gql } from "@apollo/client";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ViewingCard } from "./ViewingCard";

const VIEWINGS_QUERY = gql`
  query viewings($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      viewings {
        id
        buyerPropertyInterest {
          id
          buyer {
            id
            name
          }
        }
        conductor
        datetimeFeedbackAdded
        datetimeViewingEnds
        datetimeViewingStarts
        nestedUserId
        viewingCancellationReasonId
        viewingFeedbackFull
        viewingSaId
        viewingSubAgentId
        virtual
      }
    }

    viewingCancellationReasons {
      id
      reason
    }

    subAgents(dealId: $dealId) {
      id
      branchName
    }

    activeNestedUsers {
      id
      email
      fullName
      closeUserId
    }

    viewingAssistants {
      id
      email
      fullName
    }
  }
`;

export function ViewingsList({ dealId }: {| dealId: string |}) {
  return (
    <ExtendedQuery query={VIEWINGS_QUERY} variables={{ dealId }}>
      {({
        activeNestedUsers,
        nestDeal,
        viewingCancellationReasons,
        subAgents,
        viewingAssistants,
      }: ExtendedQueryRenderProps<viewings>) => (
        <section data-test="viewings:list">
          {nestDeal.viewings.map((viewing) => (
            <ViewingCard
              activeNestedUsers={activeNestedUsers}
              key={viewing.id}
              subAgents={subAgents}
              viewing={viewing}
              viewingCancellationReasons={viewingCancellationReasons}
              viewingAssistants={viewingAssistants}
            />
          ))}
        </section>
      )}
    </ExtendedQuery>
  );
}
