export const updateSDLTSurchargeConfig = {
  props({ mutate, ownProps: { data } }) {
    const sdltSurcharge = data?.nestDeal?.sdltSurcharge ?? {};
    return {
      updateSDLTSurcharge: (input) =>
        mutate({
          variables: {
            input,
            id: sdltSurcharge.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateSdltSurcharge: {
              __typename: "SdltSurcharge",
              ...sdltSurcharge,
              ...input,
            },
          },
        }),
    };
  },
};
