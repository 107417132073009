// @flow
import { useState, useEffect } from "react";
import { TextArea, type Props } from "./TextArea";

export const ChangeOnBlurTextArea = ({
  onBlur,
  onChange,
  value = "",
  ...rest
}: Props) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <TextArea
      {...rest}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
      onBlur={(e) => {
        onChange(e);
        onBlur && onBlur(e);
      }}
    />
  );
};
