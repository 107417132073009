// @flow
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";
import { pick } from "lodash";

import { InfoIcon, Tick } from "@nest-ui/icons";
import { A } from "@nest-ui/sellers-nest/components/A/A";
import {
  CancelButton,
  PrimaryButton,
} from "@nest-ui/sellers-nest/components/Buttons";
import { NoSubmitDatePicker } from "@nest-ui/sellers-nest/components/DatePicker";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid/Grid";
import { ListEntryCard } from "@nest-ui/sellers-nest/components/Card/ListEntryCard";
import { NoSubmitRadioButtons } from "@nest-ui/sellers-nest/components/RadioButtons/RadioButtons";
import { NoSubmitTimePicker } from "@nest-ui/sellers-nest/components/TimePicker/TimePicker";
import {
  type ViewingAgentOptions,
  type AssociatedSas,
} from "@nest-ui/sellers-nest/tabs/Interest/Viewings/utility-functions";
import { yesNoOptions } from "@nest-ui/shared/options";
import { theme as t } from "@nest-ui/styles";
import { ViewingsWarning } from "./Warning";
import { ViewingAgentSelect } from "./ViewingAgentSelect";

const AccessArrangementsBox = styled.div`
  background-color: ${({ theme }) => theme.color.background.muted};
  border: 1px solid ${({ theme }) => theme.color.secondary.highlight};
  margin-top: 4px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 16px;
`;

const ViewingTimeSeparator = styled.span`
  align-self: center;
  flex-shrink: 2;
  padding: 17px 8px 0px 8px;
  text-align: center;
`;

export const noticeBeforeViewing = (
  viewingDate: ?string,
  viewingStartTime: ?string,
) => {
  return moment(viewingDate)
    .set({
      hour: moment(viewingStartTime).get("hour"),
      minute: moment(viewingStartTime).get("minute"),
    })
    .diff(moment(), "hours");
};

const isLessThan24HoursNotice = (viewingDate, viewingStartTime) => {
  const notice = noticeBeforeViewing(viewingDate, viewingStartTime);
  return notice < 24 && notice > 0;
};

export const isOutsideAvailability = (
  viewingAvailability: ViewingAvailability_nestDeal_viewingAvailability,
  viewingDate: ?string,
  viewingStartTime: ?string,
  viewingEndTime: ?string,
) => {
  // finds availability summaries for the day of the viewing
  const matchingDay = viewingAvailability.availabilitySummaries.find(
    (day) => day.dayOfWeek === parseInt(moment(viewingDate).format("E"), 10), // e.g. Wednesday => 3
  );

  const isAvailable =
    matchingDay &&
    matchingDay.ranges.some((r) => {
      const startOfRange = moment(viewingDate).set({
        hour: moment(r.rangeStart, "HH:mm:ss").get("hour"),
      });
      const endOfRange = moment(viewingDate).set({
        hour: moment(r.rangeEnd, "HH:mm:ss").get("hour"),
      });

      const startTime = moment(viewingDate).set({
        hour: moment(viewingStartTime).get("hour"),
        minute: moment(viewingStartTime).get("minute"),
      });

      const endTime = moment(viewingDate).set({
        hour: moment(viewingEndTime).get("hour"),
        minute: moment(viewingEndTime).get("minute"),
      });

      return (
        moment(startTime).isSameOrAfter(startOfRange) &&
        moment(endTime).isSameOrBefore(endOfRange)
      );
    });

  return !isAvailable;
};

type Props = {|
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  associatedSas: AssociatedSas,
  buyerContacts: $ReadOnlyArray<buyerPropertyInterestFields_buyer_contacts>,
  cancelScheduling: () => void,
  dealId: ID,
  loading: boolean,
  propertyAccessArrangements: ?string,
  rearrangedViewing?: PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings,
  successCallback: (values: {|
    confirmedWithVendor: string,
    viewingDate: string,
    viewingEndTime: string,
    viewingStartTime: string,
    viewingAgentOptions: ViewingAgentOptions,
    virtual: boolean,
  |}) => Promise<void>,
  subAgents: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_deal_subAgents>,
  setSaModalOpen: () => void,
  vendorContacts: $ReadOnlyArray<buyerPropertyInterestFields_deal_contacts>,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
  viewingAvailability: ViewingAvailability_nestDeal_viewingAvailability,
|};

export const SchedulingCard = (props: Props) => {
  const {
    activeNestedUsers,
    associatedSas,
    buyerContacts,
    cancelScheduling,
    dealId,
    loading,
    propertyAccessArrangements,
    rearrangedViewing,
    successCallback,
    subAgents,
    setSaModalOpen,
    vendorContacts,
    viewingAssistants,
    viewingAvailability,
  } = props;

  const defaults = {
    start: rearrangedViewing?.datetimeViewingStarts || null,
    end: rearrangedViewing?.datetimeViewingEnds || null,
    agent: pick(rearrangedViewing, [
      "conductor",
      "viewingSaId",
      "nestedUserId",
      "viewingSubAgentId",
    ]),
  };

  const [confirmedWithVendor, setConfirmedWithVendor] = useState(null);
  const [viewingDate, setViewingDate] = useState(defaults.start);
  const [viewingEndTime, setViewingEndTime] = useState(defaults.end);
  const [viewingStartTime, setViewingStartTime] = useState(defaults.start);
  const [viewingAgentOptions, setViewingAgentOptions] = useState(
    defaults.agent,
  );

  const virtual = false;

  const setStartEndTimes = (value: ?string) => {
    setViewingStartTime(value);

    setViewingEndTime(moment(value).add(20, "minutes").toISOString());
  };

  const isMissingRequiredSchedulingFields = (): boolean =>
    [
      confirmedWithVendor,
      viewingDate,
      viewingEndTime,
      viewingStartTime,
      viewingAgentOptions.conductor,
    ].includes(null);

  const handleSubmit = async () => {
    /* eslint-disable no-alert */
    if (moment(viewingEndTime).isSameOrBefore(moment(viewingStartTime))) {
      window.alert(
        "Please make sure the viewing end time is after the viewing start time.",
      );
      return;
    }

    /* eslint-disable no-alert */
    const noticeConfirmation = () => {
      if (
        virtual === false &&
        confirmedWithVendor === "Yes" &&
        isLessThan24HoursNotice(viewingDate, viewingStartTime)
      ) {
        return window.confirm(
          "STOP: This is less than 24h notice, which means you need to get the seller's confirmation before scheduling it. Please only proceed if you have confirmation already.",
        );
      }
      return true;
    };

    /* eslint-disable no-alert */
    const availabilityConfirmation = () => {
      if (
        virtual === false &&
        confirmedWithVendor === "Yes" &&
        isOutsideAvailability(
          viewingAvailability,
          viewingDate,
          viewingStartTime,
          viewingEndTime,
        )
      ) {
        return window.confirm(
          "STOP: This viewing is outside the hours the seller has told us they're available. Do you still want to schedule it for this time?",
        );
      }
      return true;
    };

    if (
      confirmedWithVendor === "Yes" &&
      viewingAgentOptions.viewingSaId !==
        associatedSas.buyerPropertyInterestSa?.closeUserId
    ) {
      setSaModalOpen();
    }

    if (
      confirmedWithVendor &&
      viewingAgentOptions.conductor &&
      viewingDate &&
      viewingEndTime &&
      viewingStartTime &&
      noticeConfirmation() &&
      availabilityConfirmation()
    ) {
      await successCallback({
        confirmedWithVendor,
        viewingAgentOptions,
        viewingDate,
        viewingEndTime,
        viewingStartTime,
        virtual,
      });
    }
  };

  const hasRearrangedViewing = Boolean(rearrangedViewing);

  const currentUser =
    activeNestedUsers &&
    activeNestedUsers.find(
      (user) => user.id === viewingAgentOptions?.nestedUserId,
    );

  return (
    <>
      <ListEntryCard
        data-test="viewings:viewing-scheduling-card"
        style={{ marginTop: "16px" }}
      >
        <Grid columns={4}>
          <LeafCell width={2}>
            <ViewingAgentSelect
              activeNestedUsers={activeNestedUsers}
              viewingAssistants={viewingAssistants}
              subAgents={subAgents}
              associatedSas={associatedSas}
              data-test="viewings:select-agent"
              label="Agent"
              onSubmit={setViewingAgentOptions}
              viewingAgentOptions={viewingAgentOptions}
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitRadioButtons
              data-test="viewings:select-confirmed-with-vendor"
              id="viewings:select-confirmed-with-vendor"
              label="Confirmed with vendor?"
              onSubmit={setConfirmedWithVendor}
              options={yesNoOptions}
              value={confirmedWithVendor}
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitDatePicker
              data-test="viewings:select-viewing-date"
              label="Viewing date"
              onSubmit={setViewingDate}
              value={viewingDate}
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitTimePicker
              data-test="viewings:select-viewing-start-time"
              label="Viewing time"
              onSubmit={setStartEndTimes}
              value={viewingStartTime}
            />
            <ViewingTimeSeparator>to</ViewingTimeSeparator>
            <NoSubmitTimePicker
              data-test="viewings:select-viewing-end-time"
              onSubmit={setViewingEndTime}
              value={viewingEndTime}
            />
          </LeafCell>
          {currentUser && currentUser.email && (
            <LeafCell
              width={1}
              style={{
                alignItems: "center",
                paddingTop: "16px",
              }}
            >
              <A
                href={`https://calendar.google.com/calendar/embed?src=${currentUser.email}&ctz=Europe/London&mode=WEEK`}
              >
                <strong>Find a time</strong>
              </A>
            </LeafCell>
          )}
          <LeafCell width={4}>
            <AccessArrangementsBox>
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  flexShrink: 0,
                  marginRight: "18px",
                }}
              >
                <InfoIcon fill={t.color.primary.dark} />
              </div>
              {propertyAccessArrangements ||
                "No known seller access arrangements - add them in the Sellers Nest"}
            </AccessArrangementsBox>
          </LeafCell>
        </Grid>
        <div style={{ display: "flex" }}>
          <PrimaryButton
            data-test="viewings:schedule-viewing"
            disabled={loading || isMissingRequiredSchedulingFields()}
            icon={Tick}
            onClick={handleSubmit}
          >
            {hasRearrangedViewing ? "Reschedule viewing" : "Schedule viewing"}
          </PrimaryButton>

          <CancelButton
            data-test="viewings:cancel-scheduling"
            onClick={cancelScheduling}
          >
            Cancel
          </CancelButton>
        </div>
        {!isMissingRequiredSchedulingFields() && (
          <ViewingsWarning
            buyerContacts={buyerContacts}
            confirmedWithVendor={confirmedWithVendor}
            dealId={dealId}
            viewingStartTime={viewingStartTime}
            viewingDate={viewingDate}
            vendorContacts={vendorContacts}
          />
        )}
      </ListEntryCard>
    </>
  );
};
