// @flow
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem } from "@nested/brand/src/components/Timeline";
import { useUser } from "../../../../../hooks/useUser";
import { NewNoteInput } from "./NewNoteInput";

type Props = {
  event: {
    onClose: () => void,
    relationType: "buyer" | "deal",
    relationId: string,
  },
  isShareable: boolean,
};

export const NewNote = ({
  event: { onClose, relationType, relationId },
  isShareable,
}: Props) => {
  const {
    currentUser: { email: userEmail },
  } = useUser();

  return (
    <TimelineItem
      icon={faFileAlt}
      color="yellow"
      userEmail={userEmail}
      primaryHeading="Note"
    >
      <NewNoteInput
        autoFocus
        isShareable={isShareable}
        noteRelationType={relationType}
        onCancel={onClose}
        onSuccess={onClose}
        relationId={relationId}
      />
    </TimelineItem>
  );
};
