// @flow
import { LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";

import { graphql } from "deal/utils/graphql";

import { updateDealTypeAdvanceMay2018Config } from "./mutations/updateDealTypeAdvanceMay2018Config";
import UpdateDealTypeAdvanceMay2018Mutation from "./mutations/UpdateDealTypeAdvanceMay2018Mutation.graphql";

type Props = {|
  nestDeal: {
    dealTypeDetails: DealTypeAdvanceMay2018GlobalFragment,
  },
  updateDealTypeAdvanceMay2018: () => Promise<UpdateDealTypeAdvanceMay2018>,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
|};

export const AdvanceMay2018Component = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeAdvanceMay2018,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            readOnly={readOnly}
            mutation={updateDealTypeAdvanceMay2018}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            readOnly={readOnly}
            mutation={updateDealTypeAdvanceMay2018}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}
  </>
);

export const AdvanceMay2018 = graphql(
  UpdateDealTypeAdvanceMay2018Mutation,
  updateDealTypeAdvanceMay2018Config,
)(AdvanceMay2018Component);
