// @flow
import type { MutationFunction } from "@apollo/client/react/components";

import { Grid, Cell, LeafCell } from "components/Grid";
import { Heading } from "components/Heading";
import { SelectField } from "components/SelectField";
import { TextField } from "components/TextField";
import { ConditionalBuyerOfferFields } from "./ConditionalBuyerOfferFields";

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
  inputOptions: BuyerOffer_inputOptions,
  activeNestedUsers: $ReadOnlyArray<BuyerOffer_activeNestedUsers>,
};

export const BuyerOfferOtherInfo = ({
  buyerOffer,
  inputOptions,
  mutation,
  activeNestedUsers,
}: Props) => (
  <>
    <Cell width={4}>
      <Grid columns={4}>
        <LeafCell width={4}>
          <Heading level={2}>Other offer info (not shown to seller)</Heading>
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Offer notes"
            value={buyerOffer.notes}
            mutation={mutation}
            property="notes"
            data-test="buyer-offer-modal-offer-notes"
          />
        </LeafCell>
        <LeafCell width={1}>
          <SelectField
            options={activeNestedUsers}
            label="AP who got the offer"
            value={buyerOffer.saWhoGotTheOffer?.id}
            mutation={mutation}
            property="saWhoGotTheOfferId"
            nullable={false}
            data-test="buyer-offer-modal-sa-who-got-the-offer"
          />
        </LeafCell>
        <LeafCell width={4}>
          <ConditionalBuyerOfferFields
            inputOptions={inputOptions}
            updateBuyerOffer={mutation}
            buyerOffer={buyerOffer}
          />
        </LeafCell>
        <Cell width={2}>
          <TextField
            label="Buyer 1 legal name"
            value={buyerOffer.contactOneName}
            mutation={mutation}
            property="contactOneName"
            data-test="buyer-offer-modal-contact-one-name"
            className="fs-exclude"
          />
        </Cell>
        <Cell width={2}>
          <TextField
            label="Buyer 2 legal name"
            value={buyerOffer.contactTwoName}
            mutation={mutation}
            property="contactTwoName"
            data-test="buyer-offer-modal-contact-two-name"
            className="fs-exclude"
          />
        </Cell>
        <Cell width={4}>
          <TextField
            label="Buyer's address"
            value={buyerOffer.buyersAddress}
            mutation={mutation}
            property="buyersAddress"
            data-test="buyer-offer-modal-buyers-address"
            className="fs-exclude"
          />
        </Cell>
      </Grid>
    </Cell>
  </>
);
