import guaranteePaymentHistoryAggregateFields from "../queries/guaranteePaymentHistoryAggregateFields.graphql";

export const updateConfig = {
  props: ({ mutate, ownProps: { data } }) => ({
    updateGuaranteePayment: (guaranteePaymentId) => (input) =>
      mutate({
        variables: {
          dealId: data.nestDeal.id,
          input,
          guaranteePaymentId,
        },
        refetchQueries: [
          {
            query: guaranteePaymentHistoryAggregateFields,
            variables: {
              id: data.nestDeal.id,
            },
          },
        ],
        optimisticResponse: {
          __typename: "Mutation",
          updateGuaranteePayment: {
            __typename: "GuaranteePayment",
            guaranteePaymentId,
            ...data.nestDeal.guaranteePaymentHistory.guaranteePayments.find(
              ({ id: paymentId }) => paymentId === guaranteePaymentId,
            ),
            ...input,
          },
        },
      }),
  }),
};
