// @flow
import type { Node } from "react";
import styled from "styled-components";
import { Grid } from "components/Grid";
import { H2 } from "components/Heading";

export const SectionWrapper = styled.div`
  padding: ${({ noHeader }) => (noHeader ? "0 16px 16px" : "16px")};
`;

const SectionTitleBar = styled.div`
  background-color: ${({ theme }) => theme.color.secondary.highlight};
  h2 {
    padding: 10px 16px;
    margin-bottom: 0 !important;
  }
`;

type FormSectionProps = {
  sectionTitle?: string,
  noHeader?: boolean,
  children: Node,
};

export const FormSection = ({
  sectionTitle,
  children,
  noHeader,
}: FormSectionProps) => (
  <>
    {!noHeader && (
      <SectionTitleBar>
        <H2>{sectionTitle} </H2>
      </SectionTitleBar>
    )}

    <SectionWrapper noHeader={noHeader}>
      <Grid columns={4}>{children}</Grid>
    </SectionWrapper>
  </>
);
