// @flow
import type { Node, ChildrenArray, Element } from "react";
import styled from "styled-components";

const SectionTitle = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: ${({ theme }) => theme.color.primary.dark};
  text-align: left;
  margin: 50px 0 0;
`;

const InfoTileGroup = styled.div`
  display: grid;
  grid-gap: 20px 25px;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  margin: 20px 0 0;
`;

const InfoTileWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(53, 63, 82, 0.1);
  overflow: hidden;
`;

const InfoTileTitle = styled.div`
  background-color: ${({ theme }) => theme.color.primary.dark};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: #fff;
  font-size: 1.3em;
  padding: 15px;
`;

const InfoTileText = styled.div`
  color: ${({ theme }) => theme.color.primary.dark};
  font-size: 1.5em;
  padding: 15px;
  white-space: pre-wrap;

  & > p {
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }
`;

type InfoTileProps = {
  title: string,
  text: ?string | ?Array<Node> | ?number,
};

export const InfoTile = ({ title, text }: InfoTileProps) => (
  <InfoTileWrapper>
    <InfoTileTitle>{title}</InfoTileTitle>
    <InfoTileText>{text || "--"}</InfoTileText>
  </InfoTileWrapper>
);

type InfoSectionProps = {
  title: string,
  children?: ChildrenArray<Element<typeof InfoTile>>,
};

export const InfoSection = ({ title, children }: InfoSectionProps) => (
  <>
    <SectionTitle>{title}</SectionTitle>
    <InfoTileGroup>{children}</InfoTileGroup>
  </>
);
