// @flow
import { useEffect, useMemo, useState, useRef, useContext } from "react";
import { css, ThemeContext } from "styled-components";
import { useCombobox } from "downshift";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import { Gravatar, media } from "@nested/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../hooks/useUser";
import { Placeholder } from "./Placeholder";
import { PopupBox } from "./PopupBox";
import { type User } from "./UserContext";
import { Drawer } from "./Drawer/Drawer";

export const USERS_QUERY = gql`
  query UserSelectActiveUsers {
    activeNestedUsers {
      id
      email
      firstName
      lastName
      fullName
    }
  }
`;

const wrapperStyle = css`
  display: flex;
  flex-grow: 0.5;
  justify-content: flex-end;
  ${media.tablet`
    flex-grow: 0;
  `}
`;

const buttonStyle = css`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
`;

const gravatarStyle = css`
  border: 2px solid white;
  border-radius: 100%;
  height: 30px;
  margin: 0;
  width: 30px;
`;

const scrollWrapper = css`
  max-height: ${({ $useDrawerOnMobile }) =>
    $useDrawerOnMobile ? "calc(70vh - 105px)" : "300px"};
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
  list-style-type: none;
  padding: 0px 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.tablet`
    max-height: 300px;
  `}
}
`;

const listItemStyle = css`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
  margin-left: -13px;
  padding: 5px;
  padding-left: 18px;
  padding-right: 18px;
  width: calc(100% + 24px);
  &:first-child {
    margin-top: 0;
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      background: ${({ theme }) => theme.palette.blue20};
    `};
`;

const inputStyle = css`
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 3px;
  color: ${({ theme }) => theme.palette.hague150};
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  padding-right: 30px;
  width: 100%;

  :focus {
    border: 1px solid ${({ theme }) => theme.palette.hague40};
    outline: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.hague100};
    opacity: 0.7;
  }
`;

const itemGravatarStyle = css`
  ${gravatarStyle};
  border: none;
  margin-right: 10px;
`;

const itemNameStyle = css`
  flex-grow: 1;
  font-weight: 500;
`;

const selectedItemStyle = css`
  color: ${({ theme }) => theme.palette.green500};
`;

const currentUserStyle = css`
  margin-left: 7px;
  opacity: 0.4;
`;

const searchIconStyle = css`
  opacity: 0.5;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const labelStyle = css`
  display: block;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 14px;
  margin-bottom: 5px;
  opacity: 0.7;
  text-transform: uppercase;
`;

const searchWrapper = css`
  background-color: white;
  border-bottom: solid 1px transparent;
  // order is important here
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 15px 15px 0;
  padding-bottom: 9px;
  position: relative;
  width: 100%;
  z-index: 10;
`;

const reassignAgentMobileStyle = css`
  display: ${({ drawerOpen }) => (drawerOpen ? "block" : "none")};
  height: 70vh;

  ${media.tablet` 
    display: none;
  `}
`;

const PlaceholderUser = () => (
  <li css={listItemStyle}>
    <Placeholder css={itemGravatarStyle} />
    <div css={itemNameStyle}>
      <Placeholder css="display: block;" width={150} />
    </div>
  </li>
);

const UserListContainer = ({
  children,
  closeMenu,
  isOpen,
  popUpCss,
  triangleRightOffset,
  useDrawerOnMobile,
}) => {
  const theme = useContext(ThemeContext);
  if (useDrawerOnMobile && window.innerWidth < theme.breakpoints.tablet) {
    return (
      <Drawer
        drawerType="email"
        closeDrawer={closeMenu}
        css={reassignAgentMobileStyle}
        drawerOpen={isOpen}
      >
        {children}
      </Drawer>
    );
  }

  return (
    <PopupBox
      css={popUpCss}
      data-test="user-list-popup"
      isOpen={isOpen}
      triangleRightOffset={triangleRightOffset}
    >
      {children}
    </PopupBox>
  );
};

type UserListProps = {
  disabled?: boolean,
  onSelect: (user: User) => any,
  openListButton: any,
  popUpCss?: string,
  selectedEmail: string,
  title?: string,
  triangleRightOffset?: string,
  useDrawerOnMobile?: boolean,
};

export const UserList = ({
  disabled,
  onSelect,
  openListButton: OpenListButton,
  popUpCss,
  selectedEmail,
  title = "Change agent",
  triangleRightOffset,
  useDrawerOnMobile,
}: UserListProps) => {
  const { currentUser } = useUser();
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const theme = useContext(ThemeContext);
  const searchInput = useRef();
  const observingElement = useRef();
  const observationTarget = useRef();
  const selectedItems = useMemo(() => {
    return [
      currentUser,
      ...items.filter(({ fullName }) =>
        fullName.toLowerCase().startsWith(searchValue.toLowerCase()),
      ),
    ];
  }, [items, searchValue]);

  const inputContainer = observingElement.current;

  const handleScroll = (scrollEvents) => {
    const [scrollEvent] = scrollEvents;
    if (inputContainer) {
      inputContainer.style.borderBottomColor = scrollEvent.isIntersecting
        ? "transparent"
        : theme.palette.hague20;
    }
  };

  const inputObserver = new IntersectionObserver(handleScroll, {
    root: null,
    threshold: 0.9,
  });

  const {
    closeMenu,
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useCombobox({
    items: selectedItems,
    itemToString: (item) => item?.fullName,
    onSelectedItemChange: async (changes) => {
      if (changes.selectedItem) {
        await onSelect(changes.selectedItem);
      }
    },
  });

  const { data, loading } = useQuery(USERS_QUERY, {
    skip: !isOpen,
  });

  useEffect(() => {
    if (data?.activeNestedUsers) {
      setItems(
        data.activeNestedUsers.filter(
          ({ email }) => email !== currentUser.email,
        ),
      );
    }
  }, [data]);

  useEffect(() => {
    const firstUser = observationTarget.current;
    if (isOpen && firstUser) {
      inputObserver.observe(firstUser);
      return () => inputObserver.unobserve(firstUser);
    }
    return () => searchInput.current?.focus();
  }, [isOpen]);

  return (
    <div css={wrapperStyle}>
      <button
        data-test="user-select:toggle-button"
        {...getToggleButtonProps()}
        css={buttonStyle}
        disabled={disabled}
        tabIndex={0}
      >
        <OpenListButton isOpen={isOpen} />
      </button>
      <UserListContainer
        closeMenu={closeMenu}
        isOpen={isOpen}
        popUpCss={popUpCss}
        triangleRightOffset={triangleRightOffset}
        useDrawerOnMobile={useDrawerOnMobile}
      >
        <div css={searchWrapper} ref={observingElement}>
          <label {...getLabelProps()} css={labelStyle}>
            {title}
          </label>
          <div css="position: relative;" {...getComboboxProps()}>
            <input
              data-test="user-select:search"
              css={inputStyle}
              {...getInputProps({ ref: searchInput })}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Search for an agent"
            />
            <FontAwesomeIcon css={searchIconStyle} icon={faSearch} />
          </div>
        </div>
        <ul
          css={scrollWrapper}
          $useDrawerOnMobile={useDrawerOnMobile}
          data-test="user-select-list"
          {...getMenuProps()}
        >
          {isOpen &&
            selectedItems.map((item, index) => (
              <li
                data-test={`user-select:option:${item.email}`}
                key={item.email}
                css={listItemStyle}
                $highlight={highlightedIndex === index}
                {...getItemProps({
                  item,
                  index,
                  ref: index === 0 ? observationTarget : null,
                })}
              >
                <Gravatar css={itemGravatarStyle} email={item.email} />
                <div css={itemNameStyle}>
                  {item.fullName}
                  {item.email === currentUser.email && (
                    <span css={currentUserStyle}>Me</span>
                  )}
                </div>
                {item.email === selectedEmail && (
                  <div css={selectedItemStyle}>
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </li>
            ))}
          {loading && (
            <>
              <PlaceholderUser />
              <PlaceholderUser />
              <PlaceholderUser />
            </>
          )}
        </ul>
      </UserListContainer>
    </div>
  );
};
