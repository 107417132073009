// @flow
import styled from "styled-components";
import { TimelineItem } from "@nested/brand/src/components/Timeline";

const ViewAll = styled.button`
  border: none;
  background: none;
  color: ${({ theme }) => theme.palette.blue150};
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
`;

export const ViewAllButton = ({ onClick }: { onClick: () => void }) => (
  <TimelineItem icon="clock" color="blue">
    <ViewAll onClick={onClick}>View all activity</ViewAll>
  </TimelineItem>
);
