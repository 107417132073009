import styled, { css } from "styled-components";
import moment from "moment-timezone";
import { SelectField } from "components";
import { Grid, LeafCell } from "components/Grid";

export const viewingStatusStyle = css`
  padding-top: 5px;

  strong {
    font-weight: 500;
  }
`;

const ValuationStatusIndicator = styled.span`
  background-color: ${({ theme, scheduledValuationStatus }) => {
    switch (scheduledValuationStatus) {
      case "scheduled":
        return theme.palette.yellow100;
      case "completed":
        return theme.palette.green100;
      default:
        return null;
    }
  }};
  border-radius: 100%;
  height: 8px;
  margin-right: 7px;
  width: 8px;
  display: inline-block;
`;

export const ScheduledValuationStatusCard = ({
  valuationDatetime,
  scheduledValuationStatus,
  activeNestedUsers,
  areaPartner,
  changeAreaPartnerMutation,
}) => {
  const capitalize = (s) =>
    s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

  return (
    <Grid columns={4} rows="auto auto">
      <LeafCell width={4}>
        <SelectField
          options={activeNestedUsers}
          data-test="post-call-checklist-nested-relationship-manager"
          value={areaPartner}
          mutation={changeAreaPartnerMutation}
          property="assignedRmId"
          label="AP"
        />
      </LeafCell>
      <LeafCell width={4}>
        <div css={viewingStatusStyle}>
          <ValuationStatusIndicator
            scheduledValuationStatus={scheduledValuationStatus}
          />
          <strong>Valuation {capitalize(scheduledValuationStatus)}</strong>
          {` – ${moment(valuationDatetime).format("DD/MM/YYYY")} at ${moment(
            valuationDatetime,
          ).format("h:mma")}`}
        </div>
      </LeafCell>
    </Grid>
  );
};
