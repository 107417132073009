// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import { Progression } from "@nested/nest/src/pages/Deal/Progression/Progression";
import { Payments } from "tabs/Payments/Payments";
import { Valuation } from "tabs/Valuation/Valuation";
import { Interest } from "tabs/Interest/Interest";
import { Listing } from "tabs/Listing/Listing";
import { Close } from "tabs/Close/Close";
import { AdvanceValuationsAndLegal } from "tabs/AdvanceValuationsAndLegal/AdvanceValuationsAndLegal";
import { DealBuyersAgentPage } from "pages/BuyersAgent/DealBuyersAgent";
import { RedirectToAppropriateTab } from "./RedirectToAppropriateTab";

export const DealPage = () => (
  <Switch>
    <Route path={"/deals/:id/progression-info"} component={Progression} />
    <Route path={"/deals/:id/payments-info"} component={Payments} />

    <Route path="/deals/:id/valuation" component={Valuation} />
    <Route path="/deals/:id/interest" component={Interest} />
    <Route path={"/deals/:id/listing"} component={Listing} />
    <Route path={"/deals/:id/close"} component={Close} />
    <Route
      path={"/deals/:id/advance-valuations-and-legal"}
      component={AdvanceValuationsAndLegal}
    />
    <Route
      path="/deals/:id/buyers-agent/:customerAccountId"
      component={DealBuyersAgentPage}
    />

    <Redirect from="/deals/:id/qual-info" to="/deals/:id/valuation" />
    <Redirect from="/deals/:id/rm-info" to="/deals/:id/valuation" />
    <Redirect
      from="/deals/:id/va-info"
      to="/deals/:id/advance-valuations-and-legal"
    />
    <Redirect from="/deals/:id/agency-info" to="/deals/:id/listing" />
    <Redirect
      from="/deals/:id/legal-ops-info"
      to="/deals/:id/advance-valuations-and-legal"
    />
    <Redirect from="/deals/:id/viewings-and-offers" to="/deals/:id/interest" />

    <Route path="/deals/:id" component={RedirectToAppropriateTab} exact />
  </Switch>
);
