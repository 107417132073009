// @flow
// This component is intended for use with react-final-form and FieldArray
import { css } from "styled-components";
import { Checkbox } from "./Checkbox/Checkbox";

const labelStyle = css`
  font-weight: 500;
  padding-bottom: 12px;
  color: ${({ theme, $invalid }) =>
    $invalid ? theme.palette.terracotta150 : theme.palette.hague100};
`;

const checkboxStyle = css`
  padding-right: 10px;
  label {
    border-color: ${({ theme, $invalid }) =>
      $invalid ? theme.palette.terracotta150 : theme.palette.hague40};
  }
`;

type Props = {
  className?: string,
  hasErrors: boolean,
  label: string,
  options: { value: string, label: string }[],
  selectedValues: Object,
};

export const CheckboxList = ({
  className,
  hasErrors,
  label,
  options,
  selectedValues = { value: [] },
}: Props) => {
  const toggleChecked = (value) => {
    const index = selectedValues.value?.indexOf(value);
    index < 0 ? selectedValues.push(value) : selectedValues.remove(index);
  };

  return (
    <div className={className}>
      <div css={labelStyle} $invalid={hasErrors}>
        {label}
      </div>
      {options.map(({ value, label: optionLabel }) => (
        <div css="margin-bottom: 10px;" key={`checkbox-${value}`}>
          <Checkbox
            checked={selectedValues.value?.includes(value)}
            css={checkboxStyle}
            $invalid={hasErrors}
            onChange={() => toggleChecked(value)}
            id={value}
            labelText={optionLabel}
          />
        </div>
      ))}
    </div>
  );
};
