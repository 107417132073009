// @flow
import type { ApolloError } from "@apollo/client/react/components";

import { alertErrors } from "../alertErrors";
import { logException } from "../sentry";

function formatError(error): $ReadOnlyArray<ApolloError> | $ReadOnlyArray<any> {
  if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
    return error.graphQLErrors;
  }

  /**
   * Flow is not smart enough to infer this properly
   * https://github.com/facebook/flow/issues/2507
   */
  return ([error]: Array<Error>);
}

export function errorHandler(error: ApolloError | Error) {
  if (error.graphQLErrors === undefined) {
    logException(error);
    throw error;
  }

  if (error.networkError) {
    logException(error);
  }

  alertErrors({
    errors: formatError(error),
    detail: `
    If this is an error, please refresh the page and try again.

    If the issue persists, please contact #tech-support on slack. Please include a link to this page and a brief description of what you were doing when this alert triggered.`,
  });
}
