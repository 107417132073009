// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import { useTaskCounters } from "../../hooks/useTaskCounters";
import { Tabs, Tab } from "../../components/Navigation/Tabs";
import { Today } from "./Today";
import { Upcoming } from "./Upcoming";
import {
  Breadcrumbs,
  Breadcrumb,
} from "../../components/Navigation/Breadcrumbs";
import { ListViewPageWrapper } from "../../components/ListViewSharedStyles";

export type AgentTaskNoReference = TasksForReference_agentTasksForReference;

export type AgentTaskWithReference =
  | TaskToday_agentTasksToday
  | UpcomingTasks_agentTasksUpcoming;

export type AgentTask = AgentTaskNoReference | AgentTaskWithReference;

export const Tasks = ({ className }: { className?: string }) => {
  const { dueToday, upcoming } = useTaskCounters();

  return (
    <ListViewPageWrapper className={className}>
      <Breadcrumbs>
        <Breadcrumb to="#">Tasks</Breadcrumb>
        <Breadcrumb to="#">To-do</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/tasks/to-do/today" count={dueToday}>
          Today
        </Tab>
        <Tab to="/tasks/to-do/upcoming" count={upcoming}>
          Upcoming
        </Tab>
      </Tabs>
      <Switch>
        <Redirect from="/tasks" to="/tasks/to-do/today" exact />
        <Route path="/tasks/to-do/today" component={Today} />
        <Route path="/tasks/to-do/upcoming" component={Upcoming} />
      </Switch>
    </ListViewPageWrapper>
  );
};
