// @flow
import { useEffect, useState } from "react";

import { gql } from "@apollo/client";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { NoSubmitCreateButton } from "components/CreateButton";
import { Cell } from "components/Grid";
import { Modal } from "components/Modal";
import { useMutation } from "@apollo/client/react/hooks";

import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { BuyerOfferDetails } from "./BuyerOfferDetails/BuyerOfferDetails";
import { BuyerOfferSummary } from "./BuyerOfferSummary";
import { OfferCreationForm } from "./BuyerOfferCreationForm";
import { MemoOfSaleModal } from "./MemoOfSaleModal";

import { BUYER_OFFER_LIST_FRAGMENT } from "./BuyerOfferListFragment";
import { MemoValidationModal } from "./MemoValidationModal";

export const BUYER_OFFER_LIST_QUERY = gql`
  query BuyerOfferListQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      buyerOffers {
        id
        buyer {
          id
          name
        }
        deal {
          id
          assignedProgressor {
            id
          }
        }
        ...BuyerOfferListFragment
      }
    }
  }
  ${BUYER_OFFER_LIST_FRAGMENT}
`;

const canSendMemo = (offerId, offers) => {
  const offer = offers.find(({ id }) => id === offerId);
  if (!offer) {
    return false;
  }
  return (
    offer?.deal?.assignedProgressor?.id &&
    offer?.expectedExchangeDate &&
    offer?.expectedCompletionDate
  );
};

export const CREATE_MOS_PDF = gql`
  mutation CreateBuyerOfferMemoOfSalePDF(
    $input: CreateBuyerOfferMemoOfSalePdfInput!
  ) {
    createBuyerOfferMemoOfSalePdf(input: $input) {
      buyerOfferMemoOfSaleId
      downloadUrl
    }
  }
`;

type Props = {
  parentId: string,
};

export const BuyerOfferListContainer = ({ parentId }: Props) => (
  <ExtendedQuery
    query={BUYER_OFFER_LIST_QUERY}
    variables={{ dealId: parentId }}
  >
    {(buyerOfferData: ExtendedQueryRenderProps<BuyerOfferListQuery>) => (
      <BuyerOfferList buyerOffers={buyerOfferData.nestDeal.buyerOffers} />
    )}
  </ExtendedQuery>
);
type InnerProps = {
  deleteOffer?: () => any,
  duplicateOffer?: () => any,
  buyerOffers: $ReadOnlyArray<any>,
  buyerId?: ?string,
  buyerPropertyInterestId?: ?string,
};

export const BuyerOfferList = ({
  buyerId,
  buyerPropertyInterestId,
  buyerOffers,
  deleteOffer,
  duplicateOffer,
}: InnerProps) => {
  const [showDetailsModal, setShowDetailsModal] = useState();
  const [showMemoOfSaleModal, setShowMemoOfSaleModal] = useState();
  const [showOfferCreationModal, setShowOfferCreationModal] = useState();
  const [showMemoValidationModal, setShowMemoValidationModal] = useState();

  const [createMosPdf, { loading: downloadingMosPdf }] =
    useMutation(CREATE_MOS_PDF);

  // The mutation automatically decides whether to fetch or create a new PDF.
  const createOrFetchExistingMosPdf = async (buyerOfferId) => {
    try {
      const result = await createMosPdf({
        variables: {
          input: {
            buyerOfferId,
          },
        },
      });

      if (result?.data?.createBuyerOfferMemoOfSalePdf?.downloadUrl) {
        window.open(result.data.createBuyerOfferMemoOfSalePdf.downloadUrl);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const closeOfferCreationModal = () => {
    // eslint-disable-next-line no-alert
    const confirmation = window.confirm(
      "All your changes will be lost. Are you sure you want to cancel?",
    );
    if (confirmation) {
      setShowOfferCreationModal(false);
    }
  };

  useEffect(() => {
    if (!showDetailsModal) {
      return;
    }
    const [buyerOfferDetailsModalBuyerOffer] = buyerOffers.filter(
      (buyerOffer) => buyerOffer?.id === showDetailsModal?.id,
    );
    setShowDetailsModal(buyerOfferDetailsModalBuyerOffer);
  }, [buyerOffers]);

  return (
    <>
      <Cell width={4}>
        {buyerOffers.map((buyerOffer) => (
          <BuyerOfferSummary
            data={buyerOffer}
            duplicateBuyerOffer={duplicateOffer}
            deleteBuyerOffer={deleteOffer}
            deleteMessage="Warning: You should only delete an offer if it was added by mistake. If an offer falls through or is rejected etc please change the offer status instead. Are you sure you want to delete this offer?"
            id={buyerOffer.id}
            key={buyerOffer.id}
            showDetailsModal={() => {
              setShowDetailsModal(buyerOffer);
            }}
          />
        ))}
        {buyerPropertyInterestId && (
          <NoSubmitCreateButton
            className="create-button"
            label="Add an offer"
            data-test="new-offer-form:add-offer-button"
            onSubmit={() => {
              setShowOfferCreationModal(true);
            }}
          />
        )}
      </Cell>
      {showDetailsModal && (
        <Modal
          title="Offer Details"
          closeModal={() => setShowDetailsModal()}
          isOpen
          secondaryButtonLabel="Send Memorandum of Sale"
          onSecondaryButtonClick={() => {
            setShowDetailsModal();
            if (canSendMemo(showDetailsModal.id, buyerOffers)) {
              setShowMemoOfSaleModal(showDetailsModal);
            } else {
              setShowMemoValidationModal(showDetailsModal);
            }
          }}
          disableSecondaryButton={
            !["agreed_pending_memo", "accepted"].includes(
              showDetailsModal?.status.value,
            )
          }
          tertiaryButtonLabel={
            downloadingMosPdf
              ? "Generating Memorandum of Sale..."
              : "Download Memorandum of Sale"
          }
          onTertiaryButtonClick={() => {
            createOrFetchExistingMosPdf(showDetailsModal.id);
          }}
          disableTertiaryButton={
            showDetailsModal?.status.value !== "accepted" || downloadingMosPdf
          }
        >
          <BuyerOfferDetails buyerOfferId={showDetailsModal.id} />
        </Modal>
      )}
      {showMemoOfSaleModal && (
        <MemoOfSaleModal
          buyerOfferId={showMemoOfSaleModal.id}
          closeModal={() => {
            setShowMemoOfSaleModal();
            setShowDetailsModal(showMemoOfSaleModal);
          }}
        />
      )}
      {showOfferCreationModal && buyerId && buyerPropertyInterestId && (
        <Modal
          title="Add an offer"
          closeModal={closeOfferCreationModal}
          isOpen
          buttonLabel="Cancel"
          data-test="offer-creation-modal"
        >
          <OfferCreationForm
            buyerId={buyerId}
            buyerPropertyInterestId={buyerPropertyInterestId}
            onSuccess={() => setShowOfferCreationModal(false)}
          />
        </Modal>
      )}
      {showMemoValidationModal && (
        <MemoValidationModal
          open
          buyerOfferId={showMemoValidationModal.id}
          dealId={showMemoValidationModal.deal.id}
          onClose={() => {
            if (!canSendMemo(showMemoValidationModal.id, buyerOffers)) {
              // eslint-disable-next-line
              const confirm = window.confirm(
                "Expected exchange date, expected completion date and a progressor are required to send the memo. Are you sure you want to continue?",
              );
              if (confirm) {
                setShowMemoValidationModal();
                setShowDetailsModal(showMemoValidationModal);
                return;
              }
              return;
            }
            setShowMemoValidationModal();
            setShowMemoOfSaleModal(showMemoValidationModal);
          }}
        />
      )}
    </>
  );
};
