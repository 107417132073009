// @flow
import { MutationFunction } from "@apollo/client/react/components";
import styled, { css } from "styled-components";
import { gql } from "@apollo/client";

import { Switch } from "components/Switch";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";

const propertyAnalysisReady = ({
  rmRecommendedListPrice,
  rmRecommendedMaxListPrice,
  rmRecommendedMinListPrice,
  customerAccount,
}) => {
  const hasAtLeastOneRecommendedListingPrice = [
    rmRecommendedListPrice,
    rmRecommendedMaxListPrice,
    rmRecommendedMinListPrice,
  ].some((price) => price !== null);

  return customerAccount && hasAtLeastOneRecommendedListingPrice;
};

const QUERY_SHOW_CUSTOMER_PROPERTY_ANALYSIS = gql`
  query showCustomerPropertyAnalysis(
    $dealId: ID!
    $externalDealId: ID!
    $sortBy: SortBy!
    $sortOrder: SortOrder!
  ) {
    nestDeal(id: $dealId) {
      id
      rmRecommendedListPrice
      rmRecommendedMaxListPrice
      rmRecommendedMinListPrice
      opportunityStatus {
        label
        value
        valueText
      }
      customerAccount {
        id
        email
      }
      dealSettingsForCustomerAccount {
        id
        showPropertyAnalysis
      }
    }
    comparables(
      externalDealId: $externalDealId
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      id
      selected
    }
  }
`;

const UPDATE_SHOW_CUSTOMER_PROPERTY_ANALYSIS = gql`
  mutation UpdateNestDealShowPropertyAnalysisToCustomer(
    $id: ID!
    $showPropertyAnalysisToCustomer: Boolean!
  ) {
    updateShowPropertyAnalysisToCustomer(
      id: $id
      showPropertyAnalysisToCustomer: $showPropertyAnalysisToCustomer
    ) {
      id
      opportunityStatus {
        label
        value
        valueText
      }
      dealSettingsForCustomerAccount {
        id
        showPropertyAnalysis
      }
    }
  }
`;

const Div = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: flex-end;

  label {
    margin-left: 10px;
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`;

const ErrorMessage = styled.div`
  color: red;
`;

const onMessage = (
  email: string,
) => `This will send a email to the customer (email: ${email}) directing them to their Property Analysis in the account.

It will also update the opportunity status to "Valuation Complete".

PLEASE ONLY TURN THIS ON ONCE.

Are you sure you want to proceed?`;

const offMessage = `This toggle has previously been turned on, which means the customer has been sent an email with a link to their Property Analysis in the account.

Turning this off will HIDE the property analysis in the account, and turning the toggle on again in future will trigger another email.

You can edit the list prices and comparables without turning this toggle off.

Are you sure you want to proceed?`;

type Props = {
  parentId: string,
  externalId: string,
};

export const ShowPropertyAnalysisToCustomerToggle = ({
  parentId: dealId,
  externalId,
}: Props) => (
  <ExtendedQuery
    query={QUERY_SHOW_CUSTOMER_PROPERTY_ANALYSIS}
    variables={{
      dealId,
      externalDealId: externalId,
      sortBy: "PRICE",
      sortOrder: "ASC",
    }}
  >
    {({ nestDeal }) => (
      <ExtendedMutation mutation={UPDATE_SHOW_CUSTOMER_PROPERTY_ANALYSIS}>
        {(
          UpdateNestDealShowPropertyAnalysisToCustomer: MutationFunction<
            UpdateNestDealShowPropertyAnalysisToCustomer,
            UpdateNestDealShowPropertyAnalysisToCustomerVariables,
          >,
        ) => {
          const { id: settingsId, showPropertyAnalysis } =
            nestDeal.dealSettingsForCustomerAccount;
          const customerEmail = nestDeal.customerAccount?.email;

          return (
            <>
              {nestDeal.customerAccount === null && (
                <ErrorMessage data-test="no-customer-account-error-message">
                  This customer has no account. Please create one using the
                  button in the sidebar before activating the Property Analysis.
                </ErrorMessage>
              )}
              <Div isActive={propertyAnalysisReady(nestDeal)}>
                Show property analysis to customer?
                <Switch
                  id="show-property-analysis-toggle"
                  textColour="white"
                  value={showPropertyAnalysis}
                  onSubmit={(value: boolean) => {
                    // eslint-disable-next-line no-alert
                    const confirmation = window.confirm(
                      value ? onMessage(customerEmail) : offMessage,
                    );
                    if (confirmation) {
                      const isBeforeS06 =
                        nestDeal.opportunityStatus.valueText <=
                        "s06_provided_final_offer";
                      const optimisticOpportunityStatus =
                        isBeforeS06 === true
                          ? {
                              label: "Valuation Complete",
                              value: "17",
                              valueText: "s06_provided_final_offer",
                            }
                          : nestDeal.opportunityStatus;

                      UpdateNestDealShowPropertyAnalysisToCustomer({
                        variables: {
                          id: dealId,
                          showPropertyAnalysisToCustomer: value,
                        },
                        optimisticResponse: {
                          __typename: "Mutation",
                          updateShowPropertyAnalysisToCustomer: {
                            __typename: "NestDeal",
                            id: dealId,
                            opportunityStatus: optimisticOpportunityStatus,
                            dealSettingsForCustomerAccount: {
                              __typename: "DealSettingsForCustomerAccount",
                              id: settingsId,
                              showPropertyAnalysis: value,
                            },
                          },
                        },
                      });
                    }
                  }}
                />
              </Div>
            </>
          );
        }}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
