// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type ContactWithEmails } from "../../../components/Navigation/ContactDrawer/ContactDrawerShared";
import { buttonStyles } from "../../../components/IconButton/IconButton";
import { MailtoEmailButton } from "../../../components/Comms";

const emailButton = css`
  display: none;
  ${media.tablet`
    ${buttonStyles}
    padding-top: 4px;
    display: block;
  `}
`;

type Props = {
  dealId: string,
  contacts: $ReadOnlyArray<ContactWithEmails>,
};

export const EmailSellerButton = ({ dealId, contacts }: Props) => {
  return (
    <MailtoEmailButton
      heading="Email seller"
      contacts={contacts}
      relationId={dealId}
    >
      <div css={emailButton}>
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
    </MailtoEmailButton>
  );
};
