import T from "prop-types";
import { withTheme } from "styled-components";

import { Grid, LeafCell } from "components/Grid";
import { NumberField } from "components/NumberField";
import { CurrencyField } from "components/CurrencyField";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { ListEntryCard } from "components/Card";
import { InfoIcon } from "@nest-ui/icons";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { AlignRight } from "components/AlignRight";

export const NestedListings = withTheme(
  ({
    daysSinceOriginalListing,
    listingUrl,
    currentListPrice,
    openModalHandler,
    theme,
  }) => (
    <ListEntryCard data-test="nested-listing">
      <Grid columns={4}>
        <LeafCell width={4}>
          <TextareaWithLinks
            label="Nested Listing Rightmove URL"
            value={listingUrl}
            data-test="nested-listing-url"
            readOnly
          />
        </LeafCell>
        <LeafCell width={1}>
          <CurrencyField
            label="Current list price"
            value={currentListPrice}
            data-test="nested-listing-current-list-price"
            readOnly
          />
        </LeafCell>
        <LeafCell width={1}>
          <NumberField
            label="Days since listing"
            value={daysSinceOriginalListing}
            data-test="nested-listing-days-since-listing"
            readOnly
          />
        </LeafCell>
        <LeafCell width={2}>
          <AlignRight>
            <ButtonWithIcon
              icon={InfoIcon}
              onSubmit={openModalHandler}
              color={theme.color.text.highlight}
              data-test="nested-listing-view-full-details-button"
              label="View full details"
            />
          </AlignRight>
        </LeafCell>
      </Grid>
    </ListEntryCard>
  ),
);

NestedListings.propTypes = {
  listingUrl: T.string,
  daysSinceOriginalListing: T.number,
  currentListPrice: T.string,
};
