// @flow
import { css } from "styled-components";
import { ModalHeader, Modal } from "../Modal";

const contactDrawerStyle = css`
  padding-bottom: 20px;
`;

type DrawerProps = {
  drawerType?: string,
  children: React$Node,
  className?: string,
  closeDrawer: () => void,
  drawerOpen: boolean,
  heading?: string,
};

export const Drawer = ({
  drawerType,
  children,
  className,
  closeDrawer,
  drawerOpen,
  heading,
}: DrawerProps) => (
  <Modal
    data-test={
      drawerType
        ? `${drawerType}-drawer${drawerOpen ? "-open" : ""}`
        : undefined
    }
    open={drawerOpen}
    className={className}
    onClose={() => {
      closeDrawer();
    }}
  >
    {heading && <ModalHeader className="fs-exclude">{heading}</ModalHeader>}
    <div css={contactDrawerStyle}>{children}</div>
  </Modal>
);
