// @flow
import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";

import { Cell } from "components/Grid";
import { Label } from "components/Label";
import { AddButton as Button } from "components/Buttons";
import { Modal } from "components/Modal";
import { NoSubmitTextField } from "components/TextField";

import { BinIcon as Bin } from "@nest-ui/icons";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

const AddButton = styled(Button)`
  margin-top: 0px;
`;

const Section = styled.div`
  margin-top: 16px;
`;

const Features = styled.div`
  ul {
    padding-left: 16px;
    li {
      margin-bottom: 8px;
    }
  }
`;

const ModalFeatures = styled(Features)`
  ul {
    padding-left: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        cursor: pointer;
        outline: none;
        border: none;
        padding: none;
        background-color: transparent;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
    li:hover {
      background-color: ${({ theme }) => theme.color.primary.accent};
    }
  }
`;

const GET_KEY_FEATURES_QUERY = gql`
  query GetKeyFeatures($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      property {
        id
        summaryForPropertyAnalysis
        keyFeatures {
          id
          propertyId
          description
        }
      }
    }
  }
`;

const ADD_KEY_FEATURE = gql`
  mutation CreateKeyFeature($input: PropertyKeyFeatureInput!) {
    createPropertyKeyFeature(input: $input) {
      id
      description
    }
  }
`;
const DELETE_KEY_FEATURE = gql`
  mutation DeleteKeyFeature($keyFeatureId: ID!) {
    deletePropertyKeyFeature(keyFeatureId: $keyFeatureId) {
      id
    }
  }
`;

type Props = {
  dealId: string,
};

export const KeyFeatures = ({ dealId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newKeyFeature, setKeyFeature] = useState(null);
  return (
    <Section>
      <ExtendedQuery query={GET_KEY_FEATURES_QUERY} variables={{ dealId }}>
        {({
          nestDeal: {
            property: { keyFeatures, id: propertyId },
          },
        }: ExtendedQueryRenderProps<GetKeyFeatures>) => (
          <>
            <Label>Key features (customer facing)</Label>
            <Cell width={4}>
              <Features>
                <ul>
                  {keyFeatures.map(({ description, id }) => (
                    <li key={id} id={id} data-test="key-feature">
                      {description}
                    </li>
                  ))}
                </ul>
              </Features>
            </Cell>
            <AddButton
              onClick={() => setModalOpen(true)}
              data-test="open-key-features-modal"
            >
              Add key feature
            </AddButton>
            <Modal
              data-test="key-feature-modal"
              title="Add key feature"
              closeModal={() => {
                setModalOpen(false);
              }}
              isOpen={modalOpen}
            >
              <ModalFeatures>
                <ExtendedMutation
                  mutation={DELETE_KEY_FEATURE}
                  awaitRefetchQueries
                  refetchQueries={[
                    {
                      query: GET_KEY_FEATURES_QUERY,
                      variables: { dealId },
                    },
                  ]}
                >
                  {(mutation) => (
                    <ul>
                      {keyFeatures.map(({ description, id }) => (
                        <li
                          key={id}
                          id={id}
                          data-test="modal-key-features-item"
                        >
                          <span>{description}</span>
                          <button
                            data-test="remove-feature-button"
                            onClick={() =>
                              mutation({
                                variables: { keyFeatureId: id },
                                optimisticResponse: {
                                  deletePropertyKeyFeature: {
                                    __typename: "PropertyKeyFeature",
                                    id,
                                  },
                                },
                              })
                            }
                          >
                            <Bin />
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </ExtendedMutation>
              </ModalFeatures>
              <ExtendedMutation
                mutation={ADD_KEY_FEATURE}
                awaitRefetchQueries
                refetchQueries={[
                  {
                    query: GET_KEY_FEATURES_QUERY,
                    variables: { dealId },
                  },
                ]}
              >
                {(mutation) => (
                  <>
                    <NoSubmitTextField
                      placeholder="e.g. High Ceilings"
                      data-test="key-feature-text-field"
                      property="description"
                      value={newKeyFeature}
                      onSubmit={(value) => setKeyFeature(value)}
                    />
                    <Button
                      data-test="add-key-feature-button"
                      onClick={() => {
                        mutation({
                          variables: {
                            input: { propertyId, description: newKeyFeature },
                          },
                        });
                        setKeyFeature(null);
                      }}
                    >
                      Add
                    </Button>
                  </>
                )}
              </ExtendedMutation>
            </Modal>
          </>
        )}
      </ExtendedQuery>
    </Section>
  );
};
