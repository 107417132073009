// @flow
import { gql, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { TeamMember } from "@nested/brand";
import { UserList } from "../../UserList";

export const REASSIGN_TASK = gql`
  mutation ReassignAgentTask($taskId: ID!, $email: String!) {
    reassignAgentTask(taskId: $taskId, email: $email) {
      successful
      agentTask {
        id
        assignedTo
        assignedToUser {
          id
          fullName
        }
      }
    }
  }
`;

export const teamMemberStyle = css`
  display: block;
  height: 25px;
  width: 25px;
  & > * {
    height: 25px;
    width: 25px;
  }
`;

type AssignedToProps = {
  assignedTo: string,
  assignedToUser: ?TasksForReference_agentTasksForReference_assignedToUser,
  isCompletedTask: boolean,
  refetchTasks: () => Promise<*>,
  taskId: string,
};

export const AssignedTo = ({
  assignedTo,
  assignedToUser,
  isCompletedTask,
  refetchTasks,
  taskId,
}: AssignedToProps) => {
  const [reassignTask] = useMutation(REASSIGN_TASK);

  const onReassignTask = async (newAssignedTo) => {
    if (newAssignedTo.email !== assignedTo) {
      try {
        const result = await reassignTask({
          variables: { taskId, email: newAssignedTo.email },
          optimisticResponse: {
            reassignAgentTask: {
              successful: true,
              agentTask: {
                __typename: "AgentTask",
                id: taskId,
                assignedTo: newAssignedTo.email,
                assignedToUser: {
                  id: newAssignedTo.id,
                  fullName: newAssignedTo.fullName,
                },
              },
            },
          },
        });

        if (result?.data?.reassignAgentTask?.successful) {
          await refetchTasks();
        }
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const AssignedTeamMember = () => (
    <TeamMember
      css={teamMemberStyle}
      email={assignedTo}
      fullName={assignedToUser?.fullName || assignedTo}
    />
  );

  return (
    <UserList
      disabled={isCompletedTask}
      onSelect={onReassignTask}
      openListButton={AssignedTeamMember}
      popUpCss="top: 40px; width: 287px;"
      selectedEmail={assignedTo}
      title="Assign agent"
      triangleRightOffset="8px"
      useDrawerOnMobile
    />
  );
};
