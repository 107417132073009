// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const buttonStyle = css`
  position: absolute;
  left: 20px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.blue150};
  background-color: transparent;

  &:focus {
    text-decoration: underline;
  }
`;

type Props = {
  children?: string,
  className?: string,
  "data-test"?: string,
  onClick: () => void,
};

export const BackButton = ({
  children = "Back",
  className,
  "data-test": dataTest = "back-button",
  onClick,
}: Props) => {
  return (
    <button
      aria-label="Back button"
      className={className}
      css={buttonStyle}
      data-test={dataTest}
      onClick={onClick}
      type="button"
    >
      <FontAwesomeIcon icon={faCaretLeft} />
      &nbsp;&nbsp;
      {children}
    </button>
  );
};
