// @flow
import { media } from "@nested/brand";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon } from "@fortawesome/free-solid-svg-icons";

const wrapperStyle = css`
  padding: 10px;
  width: 100%;
  background: ${({ theme }) => theme.palette.pink20};
  border: 1px solid ${({ theme }) => theme.palette.pink100};
  border-radius: 5px;
  margin-top: 15px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.hague100};
  ${media.tablet`
    margin-bottom: 17px;
    margin-top: 0;
  `}
`;
const falloutReasonStyle = css`
  margin: 5px 0 0 24px;
  display: inline-block;
  ${media.tablet`
    margin: 0 auto 0 0;
  `}
`;

const iconAndStatusStyle = css`
  max-width: 90px;
  display: inline-block;
  & span {
    font-weight: 500;
    margin: 0 10px;
  }
  & svg {
    font-size: 14px;
  }
`;

const getFalloutText = (
  preCloseFalloutReason: ?string,
  reasonForDisinstruction: ?string,
) => {
  if (reasonForDisinstruction) return reasonForDisinstruction;
  if (preCloseFalloutReason) return `Fallout reason - ${preCloseFalloutReason}`;
  return null;
};
type Props = {
  preCloseFalloutReason: ?string,
  reasonForDisinstruction: ?string,
};

export const FalloutReasonBanner = ({
  preCloseFalloutReason,
  reasonForDisinstruction,
}: Props) => {
  const falloutText = getFalloutText(
    preCloseFalloutReason,
    reasonForDisinstruction,
  ); // Only most recent reason is displayed
  return (
    <div css={wrapperStyle}>
      <div css={iconAndStatusStyle}>
        <FontAwesomeIcon icon={faMoon} />
        <span>Inactive</span>
      </div>
      <p css={falloutReasonStyle}>{falloutText}</p>
    </div>
  );
};
