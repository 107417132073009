// @flow

import type { Element } from "react";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import { H1, H2, H3 } from "components/Heading";
import { Header } from "components/Header";
import { device } from "@nest-ui/styles";
import { Button } from "@nested/nest/src/components/Button/Button";

export const TranslucentBox = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: auto;
`;

export const WhiteBox = styled.div`
  background-color: #ffffff;
  max-width: 672px;
  width: 90%;
  box-shadow: 2px 2px 5px 0 rgba(85, 85, 85, 0.5);
  border-radius: 13px;
  margin: auto;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 20px;
  height: calc(100% - 40px);
  @media ${device.tablet} {
    height: calc(100% - 96px);
    margin-top: 48px;
    margin-bottom: 48px;
  }
`;

const CloseModal = styled.button`
  height: 32px;
  float: right;
  border-radius: 4px;
  border: solid 2px ${({ theme }) => theme.color.primary.dark};
  background-color: ${({ theme }) => theme.color.background.button};
  padding: 8px;
  min-width: 84px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
  outline: none;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`;

const secondaryButtonStyle = css`
  height: 32px;
  max-width: 250px;
  padding: 8px;
  margin: 16px 16px 0 0;
`;

const tertiaryButtonStyle = css`
  height: 32px;
  max-width: 250px;
  padding: 8px;
  margin: 16px 16px 0 0;
`;

type TitleProps = {
  title: string,
  children?: Element<any>,
};

export const Title = ({ title, children }: TitleProps) => (
  <Header>
    {children}
    <H1>{title}</H1>
  </Header>
);

export const Footer = styled.div`
  height: 50px;
  border-top: 1px solid #eee;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
    flex-direction: row;
    justify-content: right;
  }
`;

export const Content = styled.div`
  padding: 16px;
  height: calc(100% - 128px);
  overflow-y: auto;
  overflow-x: hidden;

  ${H2}:first-child, ${H3}:first-child {
    margin-top: 0;
  }
`;

type ModalProps = {
  closeModal: () => void,
  title: string,
  children: Element<any>,
  isOpen: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  "data-test"?: string,
  buttonLabel?: string,
  onButtonClick?: () => void,
  secondaryButtonLabel?: String,
  onSecondaryButtonClick?: () => void,
  disableSecondaryButton?: boolean,
  tertiaryButtonLabel?: String,
  onTertiaryButtonClick?: () => void,
  disableTertiaryButton?: boolean,
};

export const Modal = ({
  title,
  closeModal,
  children,
  isOpen,
  onButtonClick = closeModal,
  buttonLabel = "Done",
  "data-test": dataTest,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  disableSecondaryButton = false,
  tertiaryButtonLabel,
  onTertiaryButtonClick,
  disableTertiaryButton = false,
}: ModalProps) => {
  return (
    <CSSTransition
      in={isOpen}
      timeout={200}
      classNames="modal"
      appear
      unmountOnExit
    >
      <TranslucentBox onClick={closeModal}>
        <WhiteBox
          data-test={dataTest}
          className="white-box"
          id="modal"
          onClick={(e) => e.stopPropagation()}
        >
          <Title title={title} />
          <Content>{children}</Content>
          <Footer>
            {secondaryButtonLabel && onSecondaryButtonClick && (
              <Button
                disabled={disableSecondaryButton}
                onClick={onSecondaryButtonClick}
                buttonStyle={disableSecondaryButton ? "grey" : "pink"}
                css={secondaryButtonStyle}
              >
                {secondaryButtonLabel}
              </Button>
            )}
            {tertiaryButtonLabel && onTertiaryButtonClick && (
              <Button
                disabled={disableTertiaryButton}
                onClick={onTertiaryButtonClick}
                css={tertiaryButtonStyle}
                buttonStyle={disableTertiaryButton ? "grey" : "pink"}
              >
                {tertiaryButtonLabel}
              </Button>
            )}
            <CloseModal data-test="close-modal" onClick={onButtonClick}>
              {buttonLabel}
            </CloseModal>
          </Footer>
        </WhiteBox>
      </TranslucentBox>
    </CSSTransition>
  );
};
