// @flow
import { gql } from "@apollo/client";

export const MAIL_POTENTIAL_BUYERS_QUERY = gql`
  query MailoutPotentialBuyersCount($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      opportunityStatus {
        valueText
      }
      potentialBuyers {
        id
      }
      mailoutHistory {
        createdBy {
          id
          fullName
        }
        createdAt
        buyerCount
      }
    }
  }
`;
