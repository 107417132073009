// @flow
import { useState, useMemo } from "react";
import { CSSTransition } from "react-transition-group";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { useQuery } from "@apollo/client/react/hooks";
import { useHistory, Link } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button/Button";
import { PlaceholderList } from "../Placeholder";
import { ContactDetails } from "./ContactDetails";
import { CommsCheckbox } from "./CommsCheckbox";
import { IconButton } from "../IconButton/IconButton";
import { AreYouSureRowOverlay } from "../AreYouSureOverlay";
import { RadioList } from "../RadioList";
import { getFullName } from "../../pages/utils";
import { MobileAccountLoginList } from "./MobileAccountLoginList";
import {
  ConfirmContactRemovalPrompt,
  RemoveContactButton,
} from "./RemoveContact";

const headerStyle = css`
  color: ${({ theme }) => theme.palette.hague150};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;

export const buttonHeader = css`
  width: 30px;
  ${media.tablet`
    width: 80px;
  `}
`;

const buttonWrapper = css`
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 20%;
  justify-content: flex-end;
  ${media.tablet`
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 14%;
  `}
`;

export const contactTable = css`
  padding: 20px;
  ${media.tablet`
    padding: 0;
  `}
`;

export const contactTableHeaders = css`
  padding: 0 0 8px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};

  div {
    color: ${({ theme }) => theme.palette.hague150};
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }
`;

export const contactRow = css`
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  position: relative;
  min-height: 100px;
  div: first-child {
    border: none;
  }
  ${({ $isBuyersModal }) =>
    $isBuyersModal &&
    css`
      justify-content: space-between;
    `}
  ${media.tablet`
    padding: 15px 0 20px;
  `}
`;

const addContactButtonStyle = css`
  align-items: right;
  float: right;
  margin: 15px 0;
  width: 151px;

  ${media.tablet`
    float: unset;
    margin: 20px 0 10px auto;
  `}
`;

const editButtonDesktopStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    width: 80px;
    svg {
      color: ${({ theme }) => theme.palette.hague70};
    }
    span {
      margin-left: 5px;
      color: ${({ theme }) => theme.palette.hague100};
    }
  `}
`;

const editButtonMobileStyle = css`
  margin: auto 0;
  ${media.tablet`
    display: none;
  `}
`;

const contactsStyle = css`
  width: 50%;
  ${media.tablet`
    width: 30%;
  `}
`;

const accountLoginHeaderStyle = css`
  ${headerStyle}
  display: none;
  ${media.tablet`
    display: block; 
    width: 40%;
  `}
`;

const contactHeaderStyle = css`
  ${headerStyle}
  width: 50%;
  ${media.tablet`
    width: 30%;
  `}
`;

const viewingCommsHeaderStyle = css`
  ${headerStyle}
  width: 50%;
  ${media.tablet`
    width: 30%;
  `}
`;
const desktopAccountLoginStyle = css`
  ${headerStyle}
  display: none;

  ${media.tablet`
    display: block;
    width: 40%;
  `}
`;

const desktopAccountConfirmationStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: white;
  & div {
    opacity: ${({ $loading }) => ($loading ? 0.5 : 1)};
  }

  &.fade-enter {
    & div {
      opacity: 0;
    }
  }
  &.fade-enter-active {
    & div {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }
`;

export const CONTACTS_FRAGMENT = gql`
  fragment ContactsFragment on Contact {
    id
    firstName
    lastName
    emails {
      id
      emailAddress
    }
    title
    viewingCommsEnabled
    phones {
      id
      telephoneNumber
    }
  }
`;

export const BUYER_CONTACTS_QUERY = gql`
  query contactsTabBuyerQuery($id: ID!) {
    dealOrBuyer: buyer(id: $id) {
      id
      contacts {
        id
        ...ContactsFragment
      }
    }
  }

  ${CONTACTS_FRAGMENT}
`;

export const DEAL_CONTACTS_QUERY = gql`
  query contactsTabDealQuery($id: ID!) {
    dealOrBuyer: nestDeal(id: $id) {
      id
      externalId
      contacts {
        id
        ...ContactsFragment
      }
      customerAccount {
        id
        email
      }
    }
  }

  ${CONTACTS_FRAGMENT}
`;

export const UPDATE_ACCOUNT_HOLDER = gql`
  mutation UpdateAccountHolder($dealId: ID!, $emailId: ID!) {
    updateAccountHolder(dealId: $dealId, emailId: $emailId) {
      id
      customerAccount {
        id
        email
      }
    }
  }
`;

export const EditButton = ({ id }: { id: string }) => (
  <>
    <Link css={editButtonMobileStyle} to={`/contacts/${id}`}>
      <IconButton faIcon={faPencilAlt} />
    </Link>
    <Link css={editButtonDesktopStyle} to={`/contacts/${id}`}>
      <FontAwesomeIcon icon={faPencilAlt} />
      <span>Edit</span>
    </Link>
  </>
);

type AddContactButtonProps = {
  className?: string,
  "data-test"?: string,
  onClick: () => void | Promise<*>,
  text?: string,
};

export const AddContactButton = ({
  className,
  "data-test": dataTest,
  onClick,
  text = "Add a contact",
}: AddContactButtonProps) => (
  <Button
    buttonStyle="white"
    className={className}
    data-test={dataTest}
    icon={faPlusCircle}
    onClick={onClick}
  >
    {text}
  </Button>
);

const ContactRow = ({
  dealId,
  buyerId,
  contact,
  contactCount,
  accountHolderContactId,
  customerAccountEmail,
  onConfirm,
  loading,
}) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [contactToDeleteId, setContactToDeleteId] = useState(null);
  const deletionDisabled =
    accountHolderContactId === contact.id || contactCount <= 1;
  const emails = useMemo(
    () =>
      contact.emails.map(({ emailAddress }) => ({
        label: emailAddress,
        radioName: `${contact.firstName} ${contact.lastName}`,
        value: emailAddress,
      })),
    [contact],
  );

  return (
    <div $isBuyersModal={Boolean(buyerId)} css={contactRow}>
      <CSSTransition
        in={Boolean(contactToDeleteId)}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <ConfirmContactRemovalPrompt
          contactId={contact.id}
          dealId={dealId}
          buyerId={buyerId}
          name={getFullName(contact)}
          contactToDeleteId={contactToDeleteId}
          hidePrompt={() => setContactToDeleteId(null)}
        />
      </CSSTransition>
      <ContactDetails css={contactsStyle} contact={contact} />
      {dealId && (
        <>
          <div css={desktopAccountLoginStyle}>
            <RadioList
              censorText
              onChange={setSelectedEmail}
              options={emails}
              selectedValue={customerAccountEmail}
            />
          </div>
          <CommsCheckbox
            id={contact.id}
            viewingCommsEnabled={contact.viewingCommsEnabled}
          />
        </>
      )}
      <div css={buttonWrapper}>
        <EditButton id={contact.id} />
        <RemoveContactButton
          contactId={contact.id}
          disabled={deletionDisabled}
          onClick={() => setContactToDeleteId(contact.id)}
        />
      </div>
      <CSSTransition
        in={Boolean(selectedEmail)}
        timeout={selectedEmail ? 200 : 0}
        classNames="fade"
        unmountOnExit
      >
        <AreYouSureRowOverlay
          css={desktopAccountConfirmationStyle}
          $loading={loading}
          message={selectedEmail ? `Give access to ${selectedEmail}?` : ""}
          messageSubText="This will log out the previous account holder"
          onCancel={() => setSelectedEmail(null)}
          onConfirm={async () => {
            await onConfirm(selectedEmail);
            setSelectedEmail(null);
          }}
        />
      </CSSTransition>
    </div>
  );
};

type Props = {
  dealId?: string,
  buyerId?: string,
  onClose: () => void,
};

export const getContactsQuery = (dealId: ?string) =>
  dealId ? DEAL_CONTACTS_QUERY : BUYER_CONTACTS_QUERY;

const getAccountEmailContactId = (contacts, customerAccountEmail) => {
  if (!customerAccountEmail) return null;

  return contacts.reduce((acc, contact) => {
    const accountEmail = contact.emails.find(
      (e) => e.emailAddress === customerAccountEmail,
    );
    if (accountEmail) return contact.id;
    return acc;
  }, null);
};

export const ContactsTab = ({ dealId, buyerId }: Props) => {
  const history = useHistory();
  const query = getContactsQuery(dealId);
  const { data, loading } = useQuery(query, {
    fetchPolicy: "network-only",
    variables: {
      id: dealId || buyerId,
    },
  });

  const [updateAccountHolder] = useMutation(UPDATE_ACCOUNT_HOLDER);
  const [updating, setUpdating] = useState(false);

  const dealOrBuyer = data?.dealOrBuyer;

  const contacts = dealOrBuyer?.contacts;
  const contactCount = contacts?.length || 0;
  const customerAccountEmail = dealOrBuyer?.customerAccount?.email;
  const accountHolderContactId = useMemo(
    () => getAccountEmailContactId(contacts, customerAccountEmail),
    [customerAccountEmail],
  );

  const onConfirm = async (selectedEmail) => {
    setUpdating(true);
    const { id: emailId } = contacts
      .flatMap(({ emails }) => emails)
      .find(({ emailAddress }) => emailAddress === selectedEmail);

    try {
      const { error: mutationError } = await updateAccountHolder({
        // TODO: Why does this use external ID? Bad idea, should use database Id
        variables: { dealId: dealOrBuyer?.externalId, emailId },
        refetchQueries: [
          {
            query: DEAL_CONTACTS_QUERY,
            variables: { id: dealId },
          },
        ],
        awaitRefetchQueries: true,
      });
      if (mutationError) {
        errorHandler(mutationError);
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return <PlaceholderList css={contactTable} />;
  }

  return (
    <div css={contactTable}>
      <div css={contactTableHeaders}>
        <div css={contactHeaderStyle}>Contact</div>
        {dealId && (
          <>
            <div css={accountLoginHeaderStyle}>Account login</div>
            <div css={viewingCommsHeaderStyle}>Viewing comms</div>
          </>
        )}
      </div>
      {contacts.map((contact) => {
        return (
          <ContactRow
            dealId={dealId}
            buyerId={buyerId}
            contact={contact}
            customerAccountEmail={customerAccountEmail}
            key={contact.id}
            onConfirm={onConfirm}
            loading={updating}
            contactCount={contactCount}
            accountHolderContactId={accountHolderContactId}
          />
        );
      })}
      <AddContactButton
        css={addContactButtonStyle}
        data-test="contacts-tab:add-contact-button"
        onClick={() => history.push("/contacts/search")}
      />
      {dealId && (
        <MobileAccountLoginList
          contacts={contacts}
          externalId={data?.nestDeal?.externalId}
          customerAccountEmail={customerAccountEmail}
          onConfirm={onConfirm}
          loading={updating}
        />
      )}
    </div>
  );
};
