// @flow
import { Fragment } from "react";

import { ListEntryCard } from "components/Card";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { Grid, LeafCell } from "components/Grid";
import { NumberField } from "components/NumberField";
import { PercentageField } from "components/PercentageField";

type ReadOnlyProps = {
  dayChoices: Choice[],
  drawdownTimingFees: $ReadOnlyArray<DrawdownTimingFee>,
  toggleEditing: () => void,
};

export function DrawdownTimingFeesComponentReadOnly({
  drawdownTimingFees,
  toggleEditing,
}: ReadOnlyProps) {
  return (
    <ListEntryCard data-test="drawdown-timing-fees">
      <Grid columns={4} rowGap="0px">
        <EditButton
          data-test="drawdown-timing-fees-edit-button"
          onClick={toggleEditing}
        >
          Edit
        </EditButton>
        {drawdownTimingFees.map(({ id, days, totalFee }, index) => (
          <Fragment key={id}>
            <LeafCell width={1}>
              <NumberField
                value={days}
                label={index === 0 ? "Drawdown on or after day..." : null}
                data-test={`drawdown-timing-fees-days-${index}`}
                readOnly
              />
            </LeafCell>
            <LeafCell width={1}>
              <PercentageField
                value={totalFee}
                label={index === 0 ? "Total fee at drawdown %" : null}
                data-test={`drawdown-timing-fees-total-fee-${index}`}
                readOnly
              />
            </LeafCell>
            <LeafCell width={2} />
          </Fragment>
        ))}
      </Grid>
    </ListEntryCard>
  );
}
