// @flow
import { useState } from "react";
import { type FetchResult } from "@apollo/client/react/components";
import styled, { css } from "styled-components";
import { Tick } from "@nest-ui/icons";

import { NoSubmitCreateButton as CreateButton } from "components/CreateButton";
import { ListEntryCard as OriginalListEntryCard } from "components/Card";
import { DeleteButtonWithIcon as OriginalDeleteButton } from "components/DeleteButton";
import { PrimaryButton } from "components/Buttons";

const ListEntryCard = styled(OriginalListEntryCard)`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DeleteButton = styled(OriginalDeleteButton)`
  padding-right: 0;
  margin-top: 16px;
  width: 16px;
`;

const newEntryCardStyle = css`
  display: flex;
  /* flex-direction: column; */
  flex-flow: ${({ $addButtonAtTop }) =>
    $addButtonAtTop ? "wrap-reverse" : "wrap"};
`;

type Values = {| [key: string]: any |};

type ChildrenProps = {
  update: (property: string) => *,
  values: Values,
};

type Props = {
  addButtonAtTop?: boolean,
  createMutation: (values: Values) => Promise<void | FetchResult<any>>,
  children: (ChildrenProps) => *,
  className?: string,
  useSaveButton?: boolean,
  itemName: string,
  requiredFields: Array<string>,
};

export const NewEntry = (props: Props) => {
  const { children, createMutation, itemName, requiredFields } = props;
  const { useSaveButton = false } = props;
  const { className = "" } = props;
  const { addButtonAtTop = false } = props;
  const initialState = Object.freeze({});

  const [values, setValues] = useState<Values>(initialState);
  const [addingNewEntry, setAddingNewEntry] = useState(false);

  const deleteNewEntryCard = () => {
    setValues(initialState);
    setAddingNewEntry(false);
  };

  const createNewEntry = async (newValues) => {
    await createMutation(newValues);
    deleteNewEntryCard();
  };

  const handleUpdate =
    (property: string) => async (value: ?(string | number | boolean)) => {
      const newValues = {
        ...values,
        [property]: value,
      };

      setValues(newValues);

      if (readyToCreate(newValues) && !useSaveButton) {
        await createNewEntry(newValues);
      }
    };

  const readyToCreate = (newValues: Values) =>
    requiredFields.every((fieldName) => newValues[fieldName] !== undefined);

  return (
    <div css={newEntryCardStyle} $addButtonAtTop={addButtonAtTop}>
      {addingNewEntry && (
        <ListEntryCard
          data-test={`${itemName}-new-entry-card`}
          className={className}
        >
          {children({ values, update: handleUpdate })}
          {useSaveButton && (
            <PrimaryButton
              css={css`
                position: absolute;
                left: 16px;
                bottom: 16px;
              `}
              data-test={`save-new-${itemName}-button`}
              onClick={() => createNewEntry(values)}
              disabled={!readyToCreate(values)}
              icon={Tick}
              label={`Save ${itemName.replace(/-/g, " ")}`}
            />
          )}
          <DeleteButton
            css={css`
              margin-top: ${({ hasSaveButton }) =>
                hasSaveButton ? "32px" : "16px"};
            `}
            hasSaveButton={useSaveButton}
            data-test={`delete-new-${itemName}-card-button`}
            onSubmit={deleteNewEntryCard}
          />
        </ListEntryCard>
      )}

      <CreateButton
        data-test={`add-new-${itemName}-button`}
        label={`Add ${itemName.replace(/-/g, " ")}`}
        onSubmit={() => setAddingNewEntry(true)}
        disabled={addingNewEntry}
      />
    </div>
  );
};
