// @flow
import type { MutationOperation } from "@apollo/client";

import { Grid, Cell, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";

import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { TextField } from "components/TextField";
import { Checkbox } from "components/Checkbox";
import { AllowNegativeCurrencyField } from "components/AllowNegativeCurrencyField";
import { SelectField } from "components/SelectField";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";

type GuaranteePayment =
  | guaranteePaymentFields_DealTypeAdvanceMay2018GuaranteePayment
  | (guaranteePaymentFields_DealTypeAdvanceJul2018GuaranteePayment & {
      // This is here to work around a previous design choice.
      // Ideally we would have separate components for the two payments.
      earlyDrawInterestCharge: ?Money,
    });

type Props = {
  guaranteePayment: GuaranteePayment,
  hideEarlyDrawInterestCharge: boolean,
  deleteGuaranteePayment: () => MutationOperation<GuaranteePayment>,
  updateGuaranteePayment: (
    input: GuaranteePaymentInput,
  ) => MutationOperation<GuaranteePayment>,
};

export const AdvanceGuaranteePayment = ({
  guaranteePayment: {
    id,
    advanceFee,
    agencyFee,
    drawdownType,
    earlyDrawInterestCharge,
    feeAdjustment,
    feeAdjustmentNotes,
    isPaid,
    isProjectedDateConfirmed,
    paymentDate,
    projectedPaymentDate,
    projectedTotalNetOfFees,
    securityType,
    totalFees,
    totalGross,
    totalNetOfFees,
  },
  deleteGuaranteePayment,
  updateGuaranteePayment,
  hideEarlyDrawInterestCharge,
}: Props) => (
  <ListEntryCard data-test="guarantee-payment">
    <Grid columns={4}>
      <LeafCell width={2}>
        <SelectField
          label="Drawdown Type"
          options={[
            "10% of advance",
            "Remainder of advance",
            "Custom drawdown amount",
          ]}
          value={drawdownType}
          property="drawdownType"
          mutation={updateGuaranteePayment}
        />
      </LeafCell>
      <LeafCell width={2} />

      {drawdownType && (
        <>
          <LeafCell width={1}>
            <DatePicker
              label="Projected Date"
              value={projectedPaymentDate}
              property="projectedPaymentDate"
              mutation={updateGuaranteePayment}
            />
          </LeafCell>
          <LeafCell width={1}>
            <Checkbox
              optionName="Yes"
              label="Projected date confirmed?"
              value={isProjectedDateConfirmed}
              property="isProjectedDateConfirmed"
              mutation={updateGuaranteePayment}
              parentId={id}
            />
          </LeafCell>
          <LeafCell width={1}>
            <CurrencyField
              label="Projected amount net of fees"
              value={projectedTotalNetOfFees}
              property="projectedTotalNetOfFees"
              mutation={updateGuaranteePayment}
              data-test="projected-amount-net-of-fees-field"
              disabled={
                drawdownType === "10% of advance" ||
                drawdownType === "Remainder of advance"
              }
            />
          </LeafCell>
          <LeafCell width={1}>
            <SelectField
              label="Security type"
              options={["Secured", "Unsecured"]}
              value={securityType}
              data-test="security-type-select-field"
              property="securityType"
              onSubmit={(val) => updateGuaranteePayment({ securityType: val })}
              disabled={
                drawdownType === "10% of advance" ||
                drawdownType === "Remainder of advance"
              }
            />
          </LeafCell>

          <LeafCell width={1}>
            <CurrencyField
              label="Actual amount gross"
              value={totalGross}
              property="totalGross"
              disabled
            />
          </LeafCell>
          <LeafCell width={1}>
            <CurrencyField
              label="Advance fee on drawdown (£)"
              value={advanceFee}
              property="advanceFee"
              disabled
            />
          </LeafCell>
          <LeafCell width={1}>
            <CurrencyField
              label="Agency fee on drawdown (£)"
              value={agencyFee}
              property="agencyFee"
              disabled
            />
          </LeafCell>
          <LeafCell width={1}>
            {!hideEarlyDrawInterestCharge && (
              <CurrencyField
                label="Early draw interest charge (£)"
                value={earlyDrawInterestCharge}
                data-test="early-draw-interest-charge"
                property="earlyDrawInterestCharge"
                mutation={updateGuaranteePayment}
              />
            )}
          </LeafCell>

          <LeafCell width={1}>
            <AllowNegativeCurrencyField
              label="Fee adjustment (-/+ £)"
              value={feeAdjustment}
              property="feeAdjustment"
              mutation={updateGuaranteePayment}
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextField
              label="Fee adjustment notes"
              value={feeAdjustmentNotes}
              property="feeAdjustmentNotes"
              mutation={updateGuaranteePayment}
            />
          </LeafCell>
          <LeafCell width={1} />

          <LeafCell width={1}>
            <CurrencyField
              label="Total fees for this payment (£)"
              value={totalFees}
              property="totalFees"
              disabled
            />
          </LeafCell>
          <LeafCell width={1}>
            <CurrencyField
              label="Actual amount net of fees"
              value={totalNetOfFees}
              property="totalNetOfFees"
              disabled
            />
          </LeafCell>
          <LeafCell width={1}>
            <DatePicker
              label="Actual Date"
              value={paymentDate}
              property="paymentDate"
              mutation={updateGuaranteePayment}
            />
          </LeafCell>
          <LeafCell width={1}>
            <Checkbox
              label="Paid?"
              value={isPaid}
              property="isPaid"
              mutation={updateGuaranteePayment}
              parentId={id}
            />
          </LeafCell>
        </>
      )}

      <Cell
        width={4}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <DeleteButton
          className="delete-button"
          onSubmit={() => {
            if (itemDeletionConfirmed()) {
              deleteGuaranteePayment();
            }
          }}
        />
      </Cell>
    </Grid>
  </ListEntryCard>
);

export const AdvanceMay2018GuaranteePayment = (props: Props) => (
  <AdvanceGuaranteePayment {...props} />
);

export const AdvanceJul2018GuaranteePayment = (props: Props) => (
  <AdvanceGuaranteePayment {...props} hideEarlyDrawInterestCharge />
);
