// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import moment from "moment";

import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { TextField } from "components/TextField";
import { NumberField } from "components/NumberField/";
import { InfoTile, InfoSection } from "../InfoTile";

const PROPERTY_INFO_UPDATE = gql`
  mutation UpdateProperty($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      approxYearBuilt
    }
  }
`;

const sortDesc = (fieldName: string) => (a, b) =>
  moment(b[fieldName]).diff(a[fieldName]);

const formatCurrency = (value) => {
  if (!value) {
    return "[Amount not added]";
  }
  const numberFormat = new Intl.NumberFormat("en-GB", {
    style: "currency",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currency: "GBP",
  });

  return numberFormat.format(parseFloat(value));
};

const formatBuyerOffer = ({ amount, status, placedOn }, i) => {
  const offer = [formatCurrency(amount), status?.label, placedOn].filter(
    (value) => value,
  );
  return <p key={`offer=${i}`}>{`${i + 1}. ${offer.join(" - ")}`}</p>;
};

const formatFallthrough = ({ amount, status, placedOn, notes }, i) => (
  <p key={`fallthrough=${i}`}>
    {`${formatCurrency(amount)} - ${placedOn || "[Date not added]"}`}
    <br />
    {status?.label}
    <br />
    {notes}
  </p>
);

const formatListPrice = ({ amount, startDate }, i) => (
  <p key={`list-price=${i}`}>
    {`${formatCurrency(amount)} - ${startDate || "[Date not added]"}`}
  </p>
);

const getFormattedListPrices = (listPrices) =>
  listPrices.length > 0
    ? listPrices.slice().sort(sortDesc("startDate")).map(formatListPrice)
    : "-";

const getFormattedBuyerOffers = (buyerOffers) =>
  buyerOffers.length > 0
    ? buyerOffers.slice().sort(sortDesc("placedOn")).map(formatBuyerOffer)
    : "No";

const getFormattedFallthroughs = (buyerOffers) => {
  const fallthroughs = buyerOffers.filter(
    ({ status }) => status?.value === "no_longer_accepted",
  );

  return fallthroughs.length > 0
    ? fallthroughs.slice().sort(sortDesc("placedOn")).map(formatFallthrough)
    : "No";
};

type Props = {
  editMode: boolean,
  nestDeal: ViewingAppNestDealQuery_nestDeal,
};

export const SaleDetails = ({ editMode, nestDeal }: Props) => {
  const [updateProperty] = useMutation(PROPERTY_INFO_UPDATE);

  const {
    property,
    propertyListingPrices,
    daysSinceOriginalListing,
    buyerOffers,
  } = nestDeal;
  const { id, approxYearBuilt } = property;

  const firstListed = moment()
    .subtract(daysSinceOriginalListing, "days")
    .format("DD/MM/YYYY");

  if (editMode) {
    return (
      <>
        <H2>Sale Details</H2>
        <Grid columns={4}>
          <LeafCell width={2}>
            <TextField
              disabled
              label="Listing History"
              data-test="listing-history"
              value={getFormattedListPrices(propertyListingPrices)}
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextField
              disabled
              label="First listed"
              data-test="first-listed"
              value={firstListed}
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextField
              disabled
              label="Offers"
              data-test="offers"
              value={getFormattedBuyerOffers(buyerOffers)}
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextField
              disabled
              label="Fallthroughs"
              data-test="fallthroughs"
              value={getFormattedFallthroughs(buyerOffers)}
            />
          </LeafCell>
          <LeafCell width={2}>
            <NumberField
              label="Property built"
              data-test="approx-year-built:edit"
              property="approxYearBuilt"
              value={approxYearBuilt}
              mutation={(input) =>
                updateProperty({
                  variables: {
                    id,
                    input,
                  },
                  optimisticResponse: {
                    updateProperty: {
                      ...property,
                      ...input,
                    },
                  },
                })
              }
            />
          </LeafCell>
        </Grid>
      </>
    );
  }

  return (
    <InfoSection title="Sale details">
      <InfoTile
        data-test="listing-history"
        title="Listing history"
        text={getFormattedListPrices(propertyListingPrices)}
      />
      <InfoTile
        data-test="first-listed"
        title="First listed"
        text={firstListed}
      />
      <InfoTile
        data-test="offers"
        title="Offers"
        text={getFormattedBuyerOffers(buyerOffers)}
      />
      <InfoTile
        data-test="fallthroughs"
        title="Fallthroughs"
        text={getFormattedFallthroughs(buyerOffers)}
      />
      <InfoTile
        data-test="approx-year-built"
        title="Property built"
        text={approxYearBuilt}
      />
    </InfoSection>
  );
};
