import styled from "styled-components";

export const ViewingStatusContainer = styled.p`
  ${({ inline }) => inline && "display: inline;"}
  margin-bottom: 23px;
  margin-top: 12px;

  strong {
    font-weight: 500;
  }
`;
