// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const ctaStyle = css`
  color: ${({ theme }) => theme.palette.blue150};
  cursor: pointer;
  font-weight: 500;
`;

type Props = {
  className?: string,
  "data-test"?: string,
  onClick: () => void,
  text: string,
};

export const TextAndIconButton = ({
  className,
  "data-test": dataTest,
  onClick,
  text,
}: Props) => (
  <div
    css={ctaStyle}
    data-test={dataTest}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex={0}
    className={className}
  >
    <span>{text}</span>
    <FontAwesomeIcon icon={faPlusCircle} css="margin-left: 5px" />
  </div>
);
