// @flow
import { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { device } from "@nest-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import OriginalGravatar from "react-gravatar";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import FuzzySearch from "fuzzy-search";
import {
  Checkbox,
  SelectAllCheckbox,
} from "@nested/nest/src/components/Checkbox/Checkbox";

const cutPostcode = (address) =>
  address && address.split(", ").slice(0, -1).join(", ");

const SmallGravatar = styled(OriginalGravatar)`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  margin-right: 7px;
`;

const mainBodyWrapper = css`
  padding: 20px 20px 30px;
  height: calc(100vh - 118px);
  overflow-y: auto;
  overflow-x: hidden;
  @media ${device.tablet} {
    height: calc(100vh - 228px);
  }
`;

const searchBarContainer = css`
  position: relative;
  margin-bottom: 10px;
`;

const searchBar = css`
  border: solid 1px ${({ theme }) => theme.color.background.hague40};
  border-radius: 5px;
  color: ${({ theme }) => theme.color.text.hague70};
  padding: 8px 35px;
  font-size: 14px;
  width: 100%;
  display: block;
`;

const searchIcon = css`
  color: ${({ theme }) => theme.color.background.hague40};
  position: absolute;
  font-size: 18px;
  margin: 10px;
  left: 0;
  top: 0;
`;

const clearTextIcon = css`
  color: ${({ theme }) => theme.color.background.hague40};
  position: absolute;
  display: block;
  font-size: 18px;
  padding: 9px;
  right: 0;
  top: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const mobileCardWrapper = css`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    display: none;
  }
`;

const buyerDetailsContainer = css`
  border-bottom: solid 1px ${({ theme }) => theme.color.background.hague20};
  flex-grow: 1;
  padding: 10px 0;
  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.text.hague70};
  }
  p:first-of-type {
    font-weight: 500;
    color: ${({ theme }) => theme.color.text.hague};
  }
`;

const MobileBuyerTableRow = ({
  buyerId,
  buyerName,
  lastMailout,
  latestBpiAddress,
  latestBpiAgent,
  onCheck,
  selected,
}) => (
  <div css={mobileCardWrapper}>
    <Checkbox
      checked={selected}
      onChange={onCheck}
      css="margin-right: 15px;"
      id={`select-buyer-checkbox-${buyerId}`}
    />
    <div css={buyerDetailsContainer}>
      <p className="fs-exclude">{buyerName}</p>
      <p>
        Last mailout:{" "}
        {lastMailout ? formatDatetimeDifference(lastMailout) : "-"}
      </p>
      <p>
        Last enquiry:{" "}
        {latestBpiAgent ? (
          <>
            ${latestBpiAgent.fullName} on ${cutPostcode(latestBpiAddress)}
          </>
        ) : (
          "-"
        )}
      </p>
    </div>
  </div>
);

const desktopBuyerTable = css`
  display: none;
  @media ${device.tablet} {
    display: table;
    min-width: 100%;
    border-collapse: collapse;
    td,
    th {
      padding: 10px 15px 10px 0;
      font-size: 14px;
    }
    th {
      font-weight: 500;
      color: ${({ theme }) => theme.palette.hague};
      text-align: left;
      white-space: nowrap;
    }
    td {
      font-weight: 400;
      color: ${({ theme }) => theme.palette.hague70};
    }
    tr {
      border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
    }
  }
`;

const DesktopBuyerTableRow = ({
  buyerId,
  selected,
  buyerName,
  onCheck,
  lastMailout,
  latestBpiAddress,
  latestBpiAgent,
}) => (
  <tr key={buyerId}>
    <td>
      <Checkbox
        checked={selected}
        onChange={onCheck}
        id={`select-buyer-checkbox-${buyerId}`}
      />
    </td>
    <td className="fs-exclude" css="min-width: 175px;">
      {buyerName}
    </td>
    <td data-test={`last-mailout-${buyerId}`} css="min-width: 171px;">
      {lastMailout ? formatDatetimeDifference(lastMailout) : "-"}
    </td>
    <td
      css="display: flex; align-items: center;"
      data-test={`latest-enquiry-${buyerId}`}
    >
      {latestBpiAgent ? (
        <>
          <SmallGravatar email={latestBpiAgent?.email} />
          {latestBpiAgent?.fullName} on {cutPostcode(latestBpiAddress)}
        </>
      ) : (
        "-"
      )}
    </td>
  </tr>
);

type Props = {
  allBuyers: $ReadOnlyArray<MailoutModalQuery_nestDeal_potentialBuyers>,
  selectedBuyerState: { [id: string]: boolean },
  toggleBuyerSelect: (id: string) => void,
  setAll: (value: boolean) => void,
  selectedBuyerIds: string[],
};

export const SelectBuyers = ({
  allBuyers,
  selectedBuyerState,
  toggleBuyerSelect,
  setAll,
  selectedBuyerIds,
}: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const [buyerSearchResults, setBuyerSearchResults] = useState(allBuyers || []);
  const searcher = new FuzzySearch(allBuyers, ["name"]);

  const allSelected = selectedBuyerIds.length === allBuyers.length;
  const noneSelected = selectedBuyerIds.length === 0;

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      const buyers = searcher.search(searchValue);
      setBuyerSearchResults(buyers);
    }
    if (searchValue === "") {
      setBuyerSearchResults(allBuyers);
    }
  }, [searchValue, allBuyers]);

  return (
    <div css={mainBodyWrapper}>
      <div css={searchBarContainer}>
        <input
          data-test="choose-buyers-search-bar"
          css={searchBar}
          value={searchValue}
          placeholder="Search for buyer name"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <FontAwesomeIcon css={searchIcon} icon={faSearch} />
        <button css={clearTextIcon} onClick={() => setSearchValue("")}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {buyerSearchResults &&
        buyerSearchResults.map(
          ({
            id,
            name,
            latestMailoutDatetimeForDeal,
            mostRecentlyCreatedBpi,
          }) => {
            return (
              <MobileBuyerTableRow
                key={id}
                buyerId={id}
                selected={selectedBuyerState[id]}
                onCheck={() => toggleBuyerSelect(id)}
                buyerName={name}
                lastMailout={latestMailoutDatetimeForDeal}
                latestBpiAgent={mostRecentlyCreatedBpi?.assignedSa}
                latestBpiAddress={mostRecentlyCreatedBpi?.deal?.address}
              />
            );
          },
        )}
      {buyerSearchResults && (
        <table css={desktopBuyerTable}>
          <thead>
            <tr>
              <th>
                <SelectAllCheckbox
                  id="select-all-checkbox"
                  noneSelected={noneSelected}
                  allSelected={allSelected}
                  onChange={() => setAll(!allSelected)}
                />
              </th>
              <th>Buyer name</th>
              <th>Last mailout</th>
              <th>Agent on last enquiry</th>
            </tr>
          </thead>
          <tbody>
            {buyerSearchResults.map(
              ({
                id,
                name,
                latestMailoutDatetimeForDeal,
                mostRecentlyCreatedBpi,
              }) => {
                return (
                  <DesktopBuyerTableRow
                    key={id}
                    buyerId={id}
                    buyerName={name}
                    selected={selectedBuyerState[id]}
                    lastMailout={latestMailoutDatetimeForDeal}
                    latestBpiAgent={mostRecentlyCreatedBpi?.assignedSa}
                    latestBpiAddress={mostRecentlyCreatedBpi?.deal?.address}
                    onCheck={() => toggleBuyerSelect(id)}
                  />
                );
              },
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
