import T from "prop-types";
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";
import { NumberField } from "components/NumberField";

const FeesAdvanceJul2018 = ({ nestDeal: { dealTypeAdvanceJul2018 } }) => (
  <>
    <LeafCell>
      <PercentageField
        property="advanceFee"
        label="Advance fee %"
        value={dealTypeAdvanceJul2018.advanceFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="agencyFee"
        label="Agency fee %"
        value={dealTypeAdvanceJul2018.agencyFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="noAdvanceDiscountPercentage"
        label="Discount for no advance %"
        value={dealTypeAdvanceJul2018.noAdvanceDiscountPercentage}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="depositDrawdownAllowancePercentage"
        label="Deposit drawdown discount %"
        value={dealTypeAdvanceJul2018.depositDrawdownAllowancePercentage}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <NumberField
        property="coolingOffPeriodDays"
        label="DD period (days)"
        value={dealTypeAdvanceJul2018.coolingOffPeriodDays}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        property="referralDiscount"
        label="Referral discount"
        value={dealTypeAdvanceJul2018.referralDiscount}
        readOnly
      />
    </LeafCell>
  </>
);

FeesAdvanceJul2018.propTypes = {
  nestDeal: T.object,
};

export { FeesAdvanceJul2018 };
