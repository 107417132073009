// @flow
import moment from "moment-timezone";

import { TextField, NoSubmitTextField } from "components/TextField";

const cast = (x, dependsOn) => {
  const matched = x.match(/(\d*):(\d*)/);
  if (matched === null) return dependsOn;

  return moment(dependsOn)
    .set({
      hours: matched[1],
      minutes: matched[2],
    })
    .toISOString();
};

type Props = {
  label?: string,
  value: ?string,
  dependsOn: ?string,
  enabled?: boolean,
};

export const TimePicker = (props: Props) => (
  <TextField
    {...props}
    type="time"
    format={(x) => (x === null ? "" : moment(x).format("HH:mm"))}
    cast={(x) => cast(x, props.dependsOn)}
    disabled={!props.dependsOn && !props.enabled}
  />
);

type NoSubmitProps = {
  label?: string,
  onSubmit: (input: ?string) => void,
  value: ?string,
};

export const NoSubmitTimePicker = (props: NoSubmitProps) => (
  <NoSubmitTextField
    {...props}
    cast={cast}
    format={(x) => (x === null ? "" : moment(x).format("HH:mm"))}
    type="time"
  />
);
