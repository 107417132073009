// @flow
import { useEffect, useState } from "react";
import { NestTextInput, type Props } from "./NestTextInput";

export const ChangeOnBlurNestTextInput = ({
  value,
  onBlur,
  onChange,
  ...rest
}: Props) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <NestTextInput
      {...rest}
      value={state}
      onChange={(e) => {
        setState(e.target.value);
      }}
      onBlur={(e) => {
        onChange(e);
        onBlur && onBlur(e);
      }}
    />
  );
};
