import T from "prop-types";
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";

import { TextareaWithLinks } from "components/TextareaWithLinks";
import { Cell, Grid } from "components/Grid";
import { Card } from "components/Card";

import { updateConfig } from "@nest-ui/shared/apollo-configs/nestDealConfig";
import query from "./query/query.graphql";
import { guaranteeNotesQueryConfig } from "./query/config";
import updateMutation from "./updateMutation/updateNestDeal.graphql";

const GuaranteeNotesComponent = ({
  updateNestDeal,
  data: {
    nestDeal: { guaranteeNotes },
  },
}) => (
  <Card>
    <Grid columns={4}>
      <Cell width={2}>
        <TextareaWithLinks
          label="Guarantee notes"
          mutation={updateNestDeal}
          property="guaranteeNotes"
          value={guaranteeNotes}
        />
      </Cell>
    </Grid>
  </Card>
);

GuaranteeNotesComponent.propTypes = {
  updateNestDeal: T.func.isRequired,
  data: T.object,
};

const enhance = compose(
  graphql(query, guaranteeNotesQueryConfig),
  graphql(updateMutation, updateConfig),
);

export const GuaranteeNotes = enhance(GuaranteeNotesComponent);
