import { withProps } from "recompose";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { DeleteIcon } from "@nest-ui/icons";

const defaultMessage =
  "This will permanently delete this item. Are you sure you want to do this?";

export const DeleteButtonWithIcon = withProps(() => ({ icon: DeleteIcon }))(
  ButtonWithIcon,
);

export const itemDeletionConfirmed = (message) => {
  const confirmationMessage = message || defaultMessage;
  const confirmation = window.confirm(confirmationMessage); // eslint-disable-line no-alert
  if (!confirmation) {
    return false;
  }
  return true;
};
