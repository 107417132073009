// @flow
import styled from "styled-components";

import { Card } from "@nest-ui/sellers-nest/components/Card";
import { SadHouse } from "@nest-ui/icons";
import { H1 } from "@nest-ui/sellers-nest/components/Heading/H1";

const BlankCard = styled.section`
  max-width: 912px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  svg {
    width: 80px;
    height: 80px;
    object-fit: contain;
    opacity: 0.2;
    padding-bottom: 8px;
  }

  section {
    background-color: ${({ theme }) => theme.color.background.default};
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 912px;
    width: 100%;
    height: 368px;
  }

  h1 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.9px;
    opacity: 0.2;
    color: ${({ theme }) => theme.color.primary.black};
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
  }
`;

const BlankCurrentPropertyInterests = () => (
  <BlankCard>
    <Card>
      <SadHouse />
      <H1>NO PROPERTIES YET</H1>
    </Card>
  </BlankCard>
);

export { BlankCurrentPropertyInterests };
