// @flow

import { useEffect, useState } from "react";
import { getConfig } from "@nested/config";
import {
  EmailPreviewBasicLayout,
  Paragraph,
  fakeLink,
  EmailPreviewFooter,
  Unsubscribe,
  TemplateVariable,
} from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/MailoutPreview";
import { EmailPreviewPropertyCard } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/EmailPreviewPropertyCard";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";

const { GOOGLE_CLOUD_STORAGE_API_URL } = getConfig();

type Props = {
  emailBody: string,
  selectedProperties: PropertyMatchSummary_buyer_potentialProperties[],
  currentUser: UsersForPropertyMatchEmail_activeNestedUsers,
  previewRef?: { current: React$ElementRef<"div"> | null },
};

const EmailPreview = ({
  emailBody,
  currentUser,
  propertyConfigs,
}: {
  emailBody: string,
  propertyConfigs: Object,
  currentUser: UsersForPropertyMatchEmail_activeNestedUsers,
}) => (
  <div>
    <Paragraph>
      Hi <TemplateVariable>contact name</TemplateVariable>,
    </Paragraph>
    <Paragraph data-test="email-body-preview">{emailBody}</Paragraph>
    <Paragraph>
      If you’d like to organise a viewing you can press the{" "}
      <span css="font-weight: 500; font-style: italic;">Request a viewing</span>{" "}
      button below, respond to this email or call me on{" "}
      {currentUser?.mobileNumber}
    </Paragraph>
    <Paragraph>Thanks,</Paragraph>
    <Paragraph>{currentUser?.firstName} @ Nested</Paragraph>
    {propertyConfigs?.map((property) => (
      <EmailPreviewPropertyCard
        propertyDetails={property}
        key={`email-preview-property-card-${property.externalId}`}
      />
    ))}
    <EmailPreviewFooter>
      <Unsubscribe>
        <span css="text-decoration: underline">Unsubscribe</span> from property
        updates
      </Unsubscribe>
    </EmailPreviewFooter>
  </div>
);

const SMSPreview = ({ preview, propertyConfigs, currentUser }) => (
  <div>
    <Paragraph>
      Hi <TemplateVariable>contact name</TemplateVariable>,
    </Paragraph>
    <Paragraph>{preview}</Paragraph>
    {propertyConfigs?.map(
      ({ address: { street, borough, outcode }, price }) => (
        <div>
          <Paragraph>
            {street}, {borough}, {outcode} - {formatPrice(price)}:{" "}
            <span css={fakeLink}>https://v.nested.com/example</span>
            <br />
            Request a viewing:{" "}
            <span css={fakeLink}>https://v.nested.com/example</span>
          </Paragraph>
        </div>
      ),
    )}
    <Paragraph>
      Thanks,
      <br />
      {currentUser?.firstName} @ Nested
    </Paragraph>
    <Paragraph>
      P.S. if you'd rather not receive these messages, click here:{" "}
      <span css={fakeLink}>https://v.nested.com/example</span>
    </Paragraph>
  </div>
);

export const EmailPreviewBody = ({
  emailBody,
  selectedProperties,
  currentUser,
  previewRef,
}: Props) => {
  const [propertyConfigs, setPropertyConfigs] = useState(null);
  const [showEmailPreview, setShowEmailPreview] = useState(true);

  const getPropertyConfigs = async (properties) => {
    const propertiesConfig = await Promise.all(
      properties.map(async (property) => {
        const fileName = `${property.externalId}/config.json`;
        const response = await fetch(
          `${GOOGLE_CLOUD_STORAGE_API_URL}/b/nested-property-listings/o/${encodeURIComponent(
            fileName,
          )}?alt=media`,
          {
            headers: {
              "Cache-Control": "no-store",
            },
          },
        );
        return response.json();
      }),
    );
    setPropertyConfigs(propertiesConfig);
  };

  useEffect(() => {
    getPropertyConfigs(selectedProperties);
  }, [selectedProperties]);

  return (
    <EmailPreviewBasicLayout
      showEmailPreview={showEmailPreview}
      setShowEmailPreview={setShowEmailPreview}
      ref={previewRef}
    >
      {showEmailPreview ? (
        <EmailPreview
          emailBody={emailBody}
          currentUser={currentUser}
          propertyConfigs={propertyConfigs}
        />
      ) : (
        <SMSPreview
          preview={emailBody}
          currentUser={currentUser}
          propertyConfigs={propertyConfigs}
        />
      )}
    </EmailPreviewBasicLayout>
  );
};
