// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Button } from "./Button/Button";

const overlayWrapper = css`
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  ${media.tablet`
    top: 64px;
  `}
`;

const messageStyle = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 20px;
  max-width: 325px;
`;

const buttonsWrapper = css`
  display: flex;
  justify-content: center;
  button {
    width: 150px;
  }
`;

type Props = {
  message: string,
  leftText: string,
  rightText: string,
  leftOnClick: () => void,
  rightOnClick: () => void,
};

export const AreYouSureOverlay = ({
  message,
  leftText,
  rightText,
  leftOnClick,
  rightOnClick,
}: Props) => (
  <div css={overlayWrapper}>
    <div>
      <p css={messageStyle} className="fs-exclude">
        {message}
      </p>
      <div css={buttonsWrapper}>
        <Button
          onClick={leftOnClick}
          buttonStyle="white"
          large
          css="margin-right: 15px;"
        >
          {leftText}
        </Button>
        <Button onClick={rightOnClick} large buttonStyle="pink">
          {rightText}
        </Button>
      </div>
    </div>
  </div>
);

const rowContainer = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  border-left: unset;
  border-right: unset;
  padding: 10px 0;
  ${media.tablet`
    border: unset;
    margin: unset;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const rowButtonWrapper = css`
  display: flex;
  button {
    width: 90px;
    height: 32px;
  }
  button:first-of-type {
    margin-right: 10px;
  }
`;

const confirmationMessage = css`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague100};
  p {
    margin: 0;
  }
  margin-bottom: 10px;
  ${media.tablet`
    margin-bottom: 0;
  `}
`;

type RowProps = {
  className?: string,
  message: string,
  messageSubText?: string,
  onCancel: () => void,
  onConfirm: () => Promise<*>,
};

export const AreYouSureRowOverlay = ({
  className,
  message,
  messageSubText,
  onCancel,
  onConfirm,
}: RowProps) => {
  return (
    <div css={rowContainer} className={className}>
      <div css={confirmationMessage}>
        <p css="font-weight: 500;">{message}</p>
        <p>{messageSubText}</p>
      </div>
      <div css={rowButtonWrapper}>
        <Button buttonStyle="white" onClick={onCancel}>
          Cancel
        </Button>
        <Button buttonStyle="pink" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
