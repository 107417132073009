// @flow

import { MutationFunction } from "@apollo/client/react/components";
import { Cell } from "components/Grid";
import { SelectField } from "components/SelectField";
import { gql } from "@apollo/client";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import type { ExtendedQueryRenderProps } from "@nested/utils/graphql/ExtendedQuery";
import { UPDATE_PROGRESSOR } from "@nested/nest/src/pages/Deal/Progression/ProgressionSummary/ProgressorSelect/ProgressorSelect";

const NESTED_PROGRESSOR_QUERY = gql`
  query NestedProgressorQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      assignedProgressor {
        id
      }
    }
    activeNestedUsers {
      closeUserId
      label: fullName
      value: id
    }
  }
`;

type Props = { parentId: string };

export const NestedProgressorCP = ({ parentId }: Props) => (
  <ExtendedQuery query={NESTED_PROGRESSOR_QUERY} variables={{ id: parentId }}>
    {({
      activeNestedUsers,
      nestDeal,
    }: ExtendedQueryRenderProps<NestedProgressorQuery>) => {
      return (
        <ExtendedMutation mutation={UPDATE_PROGRESSOR}>
          {(
            updateNestDealMutation: MutationFunction<
              UpdateProgressor,
              UpdateProgressorVariables,
            >,
          ) => (
            <Cell width={1}>
              <SelectField
                label="CP"
                data-test="nested-progressor-cp"
                options={activeNestedUsers}
                value={
                  nestDeal?.assignedProgressor &&
                  nestDeal?.assignedProgressor?.id
                }
                property="assignedProgressorId"
                mutation={(input) => {
                  return updateNestDealMutation({
                    variables: {
                      dealId: nestDeal?.id,
                      nestDeal: {
                        assignedProgressorId:
                          input.assignedProgressorId &&
                          Number(input.assignedProgressorId),
                      },
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        __typename: "NestDeal",
                        id: nestDeal.id,
                        assignedProgressor: {
                          __typename: "NestedUser",
                          id: input.assignedProgressorId,
                        },
                      },
                    },
                  });
                }}
              />
            </Cell>
          )}
        </ExtendedMutation>
      );
    }}
  </ExtendedQuery>
);

export const NestedProgressor = NestedProgressorCP;
