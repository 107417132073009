import T from "prop-types";

import { Grid, Cell, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";

import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { NoSubmitSelectField } from "components/SelectField";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";

export const DebtFundingCommitment = ({
  repaymentAmount,
  repaymentDate,
  drawdownAmount,
  drawdownDate,
  debtFacilityCommittedTo,
  facilityOnly,
  updateDebtFundingCommitment,
  deleteDebtFundingCommitment,
  debtFacilityOptions,
}) => (
  <ListEntryCard data-test="debt-funding-commitment">
    <Grid columns={4}>
      <LeafCell width={2}>
        <NoSubmitSelectField
          label="Debt Facility"
          options={debtFacilityOptions}
          value={debtFacilityCommittedTo ? debtFacilityCommittedTo.value : null}
          onSubmit={(facility) =>
            updateDebtFundingCommitment({
              debt_facility_committed_to: facility,
            })
          }
        />
      </LeafCell>
      <LeafCell width={2} />

      <LeafCell width={1}>
        <CurrencyField
          label="Debt facility drawdown amount"
          value={drawdownAmount}
          property="drawdownAmount"
          mutation={updateDebtFundingCommitment}
          disabled={facilityOnly}
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Debt facility drawdown date"
          value={drawdownDate}
          property="drawdownDate"
          mutation={updateDebtFundingCommitment}
          disabled={facilityOnly}
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Debt facility repayment amount"
          value={repaymentAmount}
          property="repaymentAmount"
          mutation={updateDebtFundingCommitment}
          disabled={facilityOnly}
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Debt facility repayment date"
          value={repaymentDate}
          property="repaymentDate"
          mutation={updateDebtFundingCommitment}
          disabled={facilityOnly}
        />
      </LeafCell>

      <Cell
        width={4}
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <DeleteButton
          data-test="debt-funding-commitment-delete-button"
          className="delete-button"
          onSubmit={() => {
            if (itemDeletionConfirmed()) {
              deleteDebtFundingCommitment();
            }
          }}
        />
      </Cell>
    </Grid>
  </ListEntryCard>
);

DebtFundingCommitment.propTypes = {
  facilityOnly: T.bool,
  repaymentAmount: T.string,
  repaymentDate: T.string,
  drawdownAmount: T.string,
  drawdownDate: T.string,
  debtFacilityCommittedTo: T.object,
  deleteDebtFundingCommitment: T.func.isRequired,
  updateDebtFundingCommitment: T.func.isRequired,
  debtFacilityOptions: T.array.isRequired,
};
