// @flow
import styled from "styled-components";

import { RippleSpinner as Ripple } from "@nest-ui/icons";

const LoaderWrapper = styled.div`
  width: 80px;
  height: 80px;
  margin: auto;
  margin-top: 16px;
  &.small {
    margin-top: 8px;
    height: 50px;
  }
`;

const Loader = ({
  loader = true,
  smallLoaderStyle,
}: {
  loader?: boolean,
  smallLoaderStyle?: boolean,
}) =>
  loader && (
    <LoaderWrapper className={smallLoaderStyle && "small"}>
      <Ripple />
    </LoaderWrapper>
  );

export { Loader };
