// @flow
import styled, { css } from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
`;
const ButtonGroupLabel = styled.label`
  font-weight: 500;
  line-height: 18px;
  display: block;
  width: 100%;
  color: ${({ theme, valid }) =>
    valid ? theme.palette.hague100 : theme.palette.terracotta150};
  margin-bottom: 5px;
`;

const Button = styled.button`
  flex-grow: 1;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  border: solid 1px;
  border-right: none;
  height: 36px;
  width: 40px;
  color: ${({ theme }) => theme.palette.hague150};
  background-color: white;
  ${({ isLast, isFirst }) => {
    if (isLast)
      return css`
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: solid 1px;
      `;
    if (isFirst)
      return css`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      `;
    return "";
  }}
  ${({ isSelected }) => {
    return (
      isSelected &&
      css`
        background-color: ${({ theme }) => theme.palette.blue150};
        color: white;
      `
    );
  }}
    border-color: ${({ theme, valid }) =>
    valid ? theme.palette.hague40 : theme.palette.terracotta150};
`;

// label typed to React node, so that we can pass React elements as labels, e.g <FontAwesomeIcon />
type Item<T> = {
  label: React$Node,
  value: T,
};

type Props<T> = {
  label?: string,
  options: Item<T>[],
  valid?: boolean,
  value: T,
  onChange: (item: T) => void,
  className?: string,
  "data-test"?: string,
};

export const ButtonGroup = <T>({
  label: groupLabel,
  options,
  valid,
  value: currentValue,
  onChange,
  className,
  "data-test": dataTest,
}: Props<T>) => (
  <div data-test={dataTest} css={className}>
    <ButtonGroupLabel valid={valid}>{groupLabel}</ButtonGroupLabel>
    <ButtonContainer>
      {options.map((option, currentPosition) => {
        const positionOfLastOption = options.length - 1;
        const { label, value } = option;

        return (
          <Button
            key={currentPosition}
            isFirst={currentPosition === 0}
            isLast={currentPosition === positionOfLastOption}
            isSelected={value === currentValue}
            onClick={() => {
              onChange(value);
            }}
            value={value}
            valid={valid}
          >
            {label}
          </Button>
        );
      })}
    </ButtonContainer>
  </div>
);
