import T from "prop-types";

import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { UnorderedList } from "components/UnorderedList";
import { CurrencyField } from "components/CurrencyField";
import { RadioButtons } from "components/RadioButtons";

export const ReadOnlyContent = ({ nestDeal }) => (
  <Grid columns={2}>
    <LeafCell width={2}>
      <TextareaWithLinks
        value={nestDeal.currentListingUrl}
        label="Current listing URL"
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <TextField
        value={nestDeal.address}
        label="Address"
        readOnly
        className="fs-exclude"
      />
    </LeafCell>

    <LeafCell>
      <TextField
        value={nestDeal.propertySubType}
        label="Property type (our assessment"
        readOnly
      />
    </LeafCell>

    <LeafCell>
      <TextField label="Tenure" value={nestDeal.tenure} readOnly />
    </LeafCell>

    <LeafCell>
      <TextField
        label="Years remaining on lease"
        value={nestDeal.leaseRemaining}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <TextField
        label="Lease extendable"
        value={nestDeal.isLeaseExtendable}
        readOnly
      />
    </LeafCell>

    <LeafCell>
      <CurrencyField
        label="Ground rent (pa)"
        value={nestDeal.groundRent}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        label="Service charge (pa)"
        value={nestDeal.serviceCharge}
        readOnly
      />
    </LeafCell>

    <LeafCell>
      <TextField label="Bedrooms" value={nestDeal.property.bedrooms} readOnly />
    </LeafCell>

    <LeafCell>
      <TextField
        label="Bathrooms"
        value={nestDeal.property.bathrooms}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <TextField
        label="Floor area (customer submitted) ft2"
        value={nestDeal.floorAreaUserSubmitted}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <UnorderedList
        label="Outdoor space type"
        value={nestDeal.outsideSpace}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <RadioButtons
        label="Major property defects identified by customer"
        value={nestDeal.propertyIssues}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <TextField
        label="Major property defect details (customer)"
        value={nestDeal.propertyIssuesDetails}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <RadioButtons
        label="Property above retail"
        value={nestDeal.aboveRetail}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <TextField
        label="Additional info"
        value={nestDeal.additionalDetails}
        readOnly
      />
    </LeafCell>

    <LeafCell width={2}>
      <TextareaWithLinks
        label="SDA property & marketability notes"
        value={nestDeal.propertyNotes}
        readOnly
      />
    </LeafCell>
  </Grid>
);

ReadOnlyContent.propTypes = {
  nestDeal: T.object.isRequired,
};
