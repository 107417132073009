// @flow
import { gql } from "@apollo/client";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { Report } from "./Report";
import { BUYERS_REPORT_FIELDS_FRAGMENT } from "./ReportFieldsFragment";

export const LIST_BUYERS_REPORT = gql`
  query getReports($customerAccountId: ID!) {
    buyersAgentReports(customerAccountId: $customerAccountId) {
      id
      ...buyersReportFields
    }
  }
  ${BUYERS_REPORT_FIELDS_FRAGMENT}
`;

type Props = {
  customerAccountId: string,
};

export const ReportList = ({ customerAccountId }: Props) => (
  <ExtendedQuery query={LIST_BUYERS_REPORT} variables={{ customerAccountId }}>
    {({ buyersAgentReports }) => {
      if (buyersAgentReports.length === 0) {
        return <h3>No reports found</h3>;
      }
      return buyersAgentReports.map((report) => (
        <Report
          report={report}
          customerAccountId={customerAccountId}
          key={report.id}
        />
      ));
    }}
  </ExtendedQuery>
);
