import { Socket } from "phoenix";
import { getConfig } from "@nested/config";
import { v4 as uuidv4 } from "uuid";

const { GRAPHQL_SOCKET_URI } = getConfig();

export const CLIENT_ID = uuidv4();

/**
 * This wraps Phoenix's websocket class to allow us to asynchronously fetch the current auth token
 * before connecting to the backend. This means if we get disconnected for any reason, we will
 * reconnect with an up to date token instead of repeatedly using a potentially expired one.
 */
export class AuthenticatedSocket extends Socket {
  state = {
    client_id: CLIENT_ID,
  };

  constructor(authClient) {
    super(GRAPHQL_SOCKET_URI, {
      params: () => this.state,
    });
    this.authClient = authClient;
  }

  async connect() {
    const token = await this.authClient.getToken();
    this.state.token = token;
    return super.connect();
  }
}
