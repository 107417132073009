// @flow
import { useEffect, useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled, { css } from "styled-components";
import moment from "moment";
import { gql, useQuery, useSubscription } from "@apollo/client";
import { useInView } from "react-intersection-observer";
import { media } from "@nested/brand";
import { useTaskCompletion } from "../../../hooks/useTaskCompletion";
import { useTaskCounters } from "../../../hooks/useTaskCounters";
import { AnimatedList } from "../../AnimatedList";
import { ListViewError } from "../../ListViewError";
import { PlaceholderList } from "../../Placeholder";
import { StickyHeader } from "../../StickyHeader";
import { Task, AddTask } from "./Task";

export const TASKS_QUERY = gql`
  query TasksForReference($relationId: String!, $relationType: String!) {
    agentTasksForReference(
      relationId: $relationId
      relationType: $relationType
    ) {
      id
      assignedTo
      assignedToUser {
        id
        fullName
      }
      description
      dueAt
      completedAt
    }
  }
`;

export const TASKS_CHANGED_SUBSCRIPTION = gql`
  subscription AgentTaskForDealChanged(
    $relationType: String!
    $relationId: ID!
  ) {
    agentTaskForReferenceChanged(
      relationType: $relationType
      relationId: $relationId
    ) {
      changedAt
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overscroll-behavior: contain;
  height: fit-content;
  ${media.tablet`
    max-height: calc(50% - 50px);
  `}
`;

const closeButtonStyle = css`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.hague100};
  cursor: pointer;
  padding: 0;
`;

const uncompletedTaskContainerStyle = css`
  padding: 20px 15px 0;
  ${media.tablet`
    padding-top: 10px;
  `}
`;

const loadingAndErrorStyle = css`
  margin: 15px;
  ${media.tablet`
    margin: 0 15px;
  `}
`;

type Props = {
  relationId: ID,
  relationType: "deal" | "buyer",
  onClose: () => void,
};

export const TaskPanelContent = ({
  relationId,
  relationType,
  onClose,
}: Props) => {
  const { toggleCompleteTask } = useTaskCompletion();
  // needed to identify whether to show loading state or not
  const [firstLoadOfRelationPage, setFirstLoadOfRelationPage] = useState(true);
  const {
    previousData: previousTasksData,
    data: tasksData = previousTasksData,
    loading,
    error,
    refetch: refetchTasks,
  } = useQuery(TASKS_QUERY, {
    variables: { relationId, relationType },
  });
  const { data: taskForReferenceChanged } = useSubscription(
    TASKS_CHANGED_SUBSCRIPTION,
    { variables: { relationType, relationId } },
  );
  const { dueToday, upcoming } = useTaskCounters();
  const [ref, inView] = useInView({
    initialInView: true,
  });

  // as soon as we have data for the deal/buyer, set this to false so loading state is not displayed
  useEffect(() => {
    setFirstLoadOfRelationPage(false);
  }, [tasksData]);

  // as soon as the relationId changes, set this to true so loading state is shown (instead of previous tasks for previous deal/buyer)
  useEffect(() => {
    setFirstLoadOfRelationPage(true);
  }, [relationId]);

  useEffect(() => {
    // prevent unnecessary refetching
    if (!error && !loading) {
      // We want a timeout in order to allow useTaskCompletion to handle the refetching (it does so in a smooth manner).
      // we still a refetch call here, so that if someone on a Task page (un)completes a task, then it'll update in the task sidebar too.
      setTimeout(() => {
        refetchTasks();
      }, 1000);
    }
  }, [dueToday, upcoming]);

  useEffect(() => {
    if (!error && !loading) {
      refetchTasks();
    }
  }, [taskForReferenceChanged]);

  if (loading || error) {
    return (
      <>
        <StickyHeader css="justify-content: space-between;">
          <p css="margin: 0; font-weight: 500;">Tasks</p>
          {onClose && (
            <button css={closeButtonStyle} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </StickyHeader>
        <Content>
          {loading && firstLoadOfRelationPage ? (
            <PlaceholderList css={loadingAndErrorStyle} />
          ) : (
            <ListViewError
              css={loadingAndErrorStyle}
              sidebar
              message="Something went wrong. Please try refreshing!"
            />
          )}
        </Content>
      </>
    );
  }

  const tasks = tasksData?.agentTasksForReference || [];

  const isLastTask = tasks.length === 1;

  const uncompletedTasks = tasks.filter((task) => task.completedAt === null);
  const completedTasks = tasks.filter((task) => task.completedAt !== null);
  uncompletedTasks.sort((a, b) => moment(a.dueAt).diff(moment(b.dueAt)));
  completedTasks.sort((a, b) =>
    moment(b.completedAt).diff(moment(a.completedAt)),
  );

  return (
    <>
      <StickyHeader
        css="justify-content: space-between"
        onClose={onClose}
        scrolling={!inView}
      >
        <p css="margin: 0; font-weight: 500;">Tasks</p>
        <button css={closeButtonStyle} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </StickyHeader>
      <Content>
        <div data-test="task-panel-content" css={uncompletedTaskContainerStyle}>
          <div ref={ref} css="height: 0px;" />
          <AnimatedList
            list={uncompletedTasks}
            rowComponent={Task}
            additionalComponentProps={{
              isLastTask,
              refetchTasks,
              toggleCompleteTask,
            }}
          />
          <AddTask refetchTasks={refetchTasks} relationType={relationType} />
        </div>
      </Content>
    </>
  );
};

// Without memoisation animation on activity panel is janky as it re-renders tasks.
export const MemoizedTaskPanelContent = memo<Props>(TaskPanelContent);
