import styled from "styled-components";
import T from "prop-types";

const Button = styled.button`
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-align: right;
  padding-left: 0px;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.default};
  color: ${({ theme, color }) => color || theme.color.text.muted};
  border: none;
  text-align: right;
  svg {
    height: 16px;
    margin-bottom: -4px;
  }
`;

const Label = styled.span`
  margin-left: 4px;
`;

export const ButtonWithIcon = ({
  icon: Icon,
  onSubmit,
  label,
  className,
  color,
  "data-test": dataTest,
}) => (
  <Button
    onClick={onSubmit}
    className={className}
    color={color}
    data-test={dataTest}
  >
    <Icon fill={color} />
    {label && <Label>{label}</Label>}
  </Button>
);

ButtonWithIcon.propTypes = {
  "data-test": T.string,
  className: T.string,
  color: T.string,
  icon: T.func,
  label: T.string,
  onSubmit: T.func.isRequired,
};
