// @flow
import styled, { css } from "styled-components";
import { range } from "lodash";
import { media } from "@nested/brand";
import { Table, TH, TD } from "./Table";

/* There are three components here:
 *
 * 1. Placeholder - simple block of grey to be substituted in for some text
 * 2. PlaceholderList - List of rows with grey blocks, indicating some
 *    structure in each list item
 * 3. PlaceholderTable - blocks of grey in a table layout. This doesn't work on
 *    mobile because it's too wide, so will appear as the PlaceholderList
 *    on mobile
 *
 * TODO: component library
 */

export const Placeholder = styled.div`
  background-color: ${({ theme }) => theme.palette.hague100};
  opacity: 0.1;
  width: ${({ width = 100 }) => width}px;
  height: ${({ height = 14 }) => height}px;
  display: inline-block;
`;

const listItemStyles = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague10};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
`;

const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

const hideOnMobile = css`
  display: none;

  ${media.tablet`
    display: block;
  `}
`;

const tdStyle = css`
  padding: 20px 20px 20px 0;
`;

const ListItem = () => (
  <div css={listItemStyles}>
    <div css="line-height: 20px; margin-bottom: 10px;">
      <Placeholder width={200} />
    </div>
    <div css="line-height: 20px; margin: 0px; margin-top: 5px;">
      <Placeholder width={100} />
    </div>
  </div>
);

export const PlaceholderList = ({
  className,
  rows = 3,
}: {
  className?: string,
  rows?: number,
}) => (
  <div className={className}>
    {range(0, rows).map((i) => (
      <ListItem key={i} />
    ))}
  </div>
);

// Note, this table is too wide for our mobile view, so we fallback to the
// PlaceholderList on mobile
export const PlaceholderTable = ({ rows = 3 }: { rows?: number }) => (
  <>
    <PlaceholderList css={mobileOnly} />
    <Table css={hideOnMobile}>
      <thead>
        <tr>
          <TH>
            <Placeholder width={100} />
          </TH>
          <TH>
            <Placeholder width={100} />
          </TH>
          <TH>
            <Placeholder width={100} />
          </TH>
        </tr>
      </thead>
      <tbody>
        {range(0, rows).map((i) => (
          <tr key={i}>
            <TD css={tdStyle}>
              <Placeholder width={250} />
            </TD>
            <TD css={tdStyle}>
              <Placeholder width={150} />
            </TD>
            <TD css={tdStyle}>
              <Placeholder width={200} />
            </TD>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);
