// @flow
/**
 * This component is intended to simplify common section building. It's goal is
 * to handle the loading and error states ensuring that a child will always get
 * the data back.
 *
 * It intentionally passes only the data back to the child component to limit
 * it's functionality. If you want to do something custom that this component
 * was not designed for please use `Query` component directly instead of
 * modifying this one.
 */
import type { Node } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { type FetchPolicy } from "@apollo/client/react/components";

import { Loader } from "@nest-ui/sellers-nest/components/Loader";
import { isQueryLoading } from "./isQueryLoading";
import { logException } from "../sentry";

type DocumentNode = any;

type NonMaybeIdentity = <T>(value: T) => $NonMaybeType<T>;

export type ExtendedQueryRenderProps<TData: {}> = $ObjMap<
  TData,
  NonMaybeIdentity,
>;

type ExtendedQueryRenderPropFunction<TData: {}> = (
  $NonMaybeType<TData>,
) => Node;

function ErrorComponent() {
  return (
    <div data-test="extended-query-error-message">Something went wrong.</div>
  );
}

export function ExtendedQuery<TData: {}, TVariables>({
  children,
  query,
  smallLoaderStyle,
  ...queryOptions
}: {|
  query: DocumentNode,
  children: ExtendedQueryRenderPropFunction<TData>,
  variables?: TVariables,
  fetchPolicy?: FetchPolicy,
  nextFetchPolicy?: FetchPolicy,
  onCompleted?: Function,
  smallLoaderStyle?: boolean,
|}): Node {
  const { data, error, loading, networkStatus } = useQuery(query, queryOptions);

  if (networkStatus === 8 || error) {
    if (error) {
      error.graphQLErrors.forEach((err) => {
        const graphQLError = new Error(err.message);
        logException(graphQLError, {
          networkStatus,
          queryOptions,
          path: err.path,
        });
      });
    }

    return <ErrorComponent />;
  }

  if (isQueryLoading(query)({ data, loading })) {
    return (
      <Loader
        smallLoaderStyle={smallLoaderStyle}
        data-test="extended-query-loader"
      />
    );
  }

  /**
   * I could have wrapped children with <ErrorBoundary>, but that creates
   * a deeply nested tree unnecessarily. I used componentDidCatch
   * initially, but that doesn't catch the actual error (shown by the
   * tests) and since children is a function, it's easy to just wrap it in
   * a try / catch block and be done with it.
   */
  try {
    /**
     * This is necessary as flow is not smart enough to refine empty
     * object types, which flow sometimes return. We eliminate them in
     * `isQueryLoading` function.
     */
    return children(((data: any): TData));
  } catch (e) {
    logException(e, { networkStatus, queryOptions });

    return <ErrorComponent />;
  }
}
