import styled from "styled-components";
import T from "prop-types";

const ListEntryCard = styled.div`
  background-color: ${({ theme }) => theme.color.background.default};
  border: ${({ highlight, theme, ...rest }) =>
    highlight && highlight(rest)
      ? `2px solid ${theme.color.secondary.light};`
      : `2px solid ${theme.color.secondary.highlight};`}
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  font-size: ${({ theme }) => theme.font.size.default};
`;

const Buttons = styled.div`
  margin-top: 16px;
  height: 16px;
  display: flex;
  justify-content: flex-end;
`;

export const HighlightText = styled.div`
  position: absolute;
  bottom: 12px;
  left: 16px;
  color: ${({ theme }) => theme.color.text.highlight};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`;

const ListEntry = ({ highlightText, highlight, children, ...rest }) => (
  <ListEntryCard highlight={highlight} {...rest}>
    {children}
    {highlight && highlight(rest) && highlightText ? (
      <HighlightText>{highlightText}</HighlightText>
    ) : null}
  </ListEntryCard>
);

ListEntry.propTypes = {
  highlight: T.func,
  highlightText: T.string,
  children: T.any,
};

const deleteEntryShape = T.shape({
  onSubmit: T.func.isRequired,
  message: T.string,
  className: T.string,
});

export { ListEntry };
export { ListEntryCard, Buttons, deleteEntryShape };
