// @flow
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Link as LinkIcon } from "@nest-ui/icons";
import {
  ViewingCardContainer,
  ViewingStatusIndicator,
  ViewingStatusContainer,
} from "../Viewings";

import {
  isViewingActionRequired,
  isViewingCancelled,
  viewingAgentLabel,
} from "../Viewings/utility-functions";

const ViewingDetails = styled.p`
  display: inline;
`;

const ViewingBuyerLink = styled(Link)`
  color: #10b186;
  text-decoration: none;
  font: 600 12px Euclid;
  text-align: right;
  justify-self: end;

  svg {
    position: relative;
    top: 3px;
    right: 4px;
  }
`;

const ViewingNotes = styled.p`
  margin-top: 7px;
  margin-bottom: 0;
`;

const ViewingSummary = styled(ViewingCardContainer)`
  display: grid;
  grid-template-columns: 70fr 30fr;
  align-items: center;
`;

const viewingStatusText = ({
  viewingCancellationReasonId,
  datetimeFeedbackAdded,
}) => {
  if (viewingCancellationReasonId) return "Cancelled";
  if (datetimeFeedbackAdded) return "Viewed";
  return "Scheduled";
};

export function ViewingCard({
  viewing,
  activeNestedUsers,
  subAgents,
  viewingCancellationReasons,
  viewingAssistants,
}: {|
  viewing: viewings_nestDeal_viewings,
  activeNestedUsers: $ReadOnlyArray<viewings_activeNestedUsers>,
  subAgents: $ReadOnlyArray<viewings_subAgents>,
  viewingCancellationReasons: $ReadOnlyArray<viewings_viewingCancellationReasons>,
  viewingAssistants: $ReadOnlyArray<viewings_viewingAssistants>,
|}) {
  const {
    id,
    datetimeViewingEnds,
    datetimeViewingStarts,
    datetimeFeedbackAdded,
    viewingCancellationReasonId,
    viewingFeedbackFull,
    buyerPropertyInterest: { buyer },
    virtual,
  } = viewing;
  return (
    <ViewingSummary
      data-test={`viewing-card:container-${id}`}
      hasAlert={isViewingActionRequired(viewing)}
    >
      <div>
        <ViewingStatusContainer inline data-test={`viewing-card:status-${id}`}>
          <ViewingStatusIndicator
            alert={
              isViewingCancelled(viewing) || isViewingActionRequired(viewing)
            }
            hasBuyerFeedback={Boolean(datetimeFeedbackAdded)}
          />
          <strong>{viewingStatusText(viewing)}</strong>
        </ViewingStatusContainer>

        <ViewingDetails data-test={`viewing-card:details-${id}`}>
          {` - ${moment(datetimeViewingStarts).format("DD/MM/YYYY")}`}
          {` from ${moment(datetimeViewingStarts).format("HH:mm")}`}
          {` to ${moment(datetimeViewingEnds).format("HH:mm")}`}
          {` with ${viewingAgentLabel({
            viewing,
            activeNestedUsers,
            subAgents,
            viewingAssistants,
          })}`}
          {`${virtual ? " - VIRTUAL" : ""}`}
        </ViewingDetails>

        {viewingCancellationReasonId && (
          <ViewingNotes data-test={`viewing-card:cancelled-notes-${id}`}>
            {`Cancelled: ${
              viewingCancellationReasons.find(
                (r) => r.id === viewingCancellationReasonId,
              )?.reason || "--"
            }`}
          </ViewingNotes>
        )}

        {datetimeFeedbackAdded && !viewingCancellationReasonId && (
          <div
            data-test={`viewing-card:feedback-${id}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: viewingFeedbackFull }}
          ></div>
        )}
      </div>
      <ViewingBuyerLink
        data-test={`viewing-card:buyer-${id}`}
        to={`/buyers/${buyer.id}`}
        className="fs-exclude"
      >
        <LinkIcon />
        {buyer.name}
      </ViewingBuyerLink>
    </ViewingSummary>
  );
}
