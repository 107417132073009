// @flow
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  type ExtendedQueryRenderProps,
  ExtendedQuery,
} from "@nested/utils/graphql/ExtendedQuery";
import { AlignRight } from "components/AlignRight";
import { DetailsIcon } from "@nest-ui/icons";
import { NoSubmitCreateButton } from "components/CreateButton/CreateButton";
import { Grid, LeafCell, Cell as OriginalCell } from "components/Grid/Grid";
import { device } from "@nest-ui/styles";
import { H2 } from "components/Heading/index";
import { LIST_COMPARABLES_QUERY } from "./ComparableList/ComparableList";
import { PropertyPhotoContainer } from "./ComparableCard/ComparableCard";
import {
  DottedList,
  DottedListItem,
  DottedListItemSpaced,
} from "./ComparableCard/ComparableCardComponents";
import { SalesInformation } from "./ComparableCard/SalesInformation";
import { RatingsChip } from "./ComparableCard/ComparabilityRatings";

const Cell = styled(OriginalCell)`
  min-height: 32px;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 16px 0 32px;
`;

const CenteredCell = styled(Cell)`
  margin: 0;
  display: flex;
  align-items: center;
`;

const Infotext = styled.p`
  font-size: 14px;
  line-height: 21px;
`;

const AddButton = styled(NoSubmitCreateButton)`
  width: 145px;
`;

const Info = styled.div`
  width: 48px;
  height: 48px;
  opacity: 0.75;
`;

const TinyIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

const PlainLink = styled(Link)`
  text-decoration: none;
`;

const CardText = styled.p`
  font-weight: 500;
  line-height: 16px;
  margin: 4px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.default};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.text.highlight};
  }
`;

const Greentext = styled(CardText)`
  color: ${({ theme }) => theme.color.text.highlight};
  font-size: 12px;
`;

const ComparableCardLight = styled(Grid)`
  background-color: ${({ theme }) => theme.color.background.default};
  border: 2px solid ${({ theme }) => theme.color.secondary.highlight};
  border-radius: 4px;
  padding: 8px;
  grid-row-gap: 4px;
  grid-column-gap: 8px;

  @media ${device.desktop} {
    grid-template-columns: 121px 1fr 50px;
  }
`;

const ComparableContainer = styled(Grid)`
  margin-top: 16px;
`;

const areas = [
  "photo      address        address",
  "photo      salesInfo      viewFullComp",
  "photo      propertyInfo   propertyInfo",
];

const mobileAreas = [
  "photo",
  "address",
  "salesInfo",
  "viewFullComp",
  "propertyInfo",
];

export const linkForComp = (comp: ListComparablesQuery_comparables) => {
  if (comp.comparableLinks.length === 0) return "";

  const rightmovePlusLink = comp.comparableLinks.find(
    (link) => link.type === "RIGHTMOVE_PLUS",
  );
  if (rightmovePlusLink) {
    return rightmovePlusLink.url;
  }

  const rightmoveLink = comp.comparableLinks.find(
    (link) => link.type === "RIGHTMOVE",
  );
  if (rightmoveLink) {
    return rightmoveLink.url;
  }

  return comp.comparableLinks[0].url;
};

type Props = {
  comparablesUrl: string,
  showAccountLink?: boolean,
  sourceId: string,
  sourceType: string,
};

export const ComparablePropertiesSection = ({
  comparablesUrl,
  sourceId,
  sourceType,
}: Props) => (
  <ExtendedQuery
    query={LIST_COMPARABLES_QUERY}
    variables={{
      reference: { sourceId, sourceType },
      sortBy: "PRICE",
      sortOrder: "ASC",
    }}
  >
    {({ comparables }: ExtendedQueryRenderProps<ListComparablesQuery>) => {
      const selectedComps = comparables.reduce(
        (acc, comp) => (comp.selected ? acc.concat(comp) : acc),
        [],
      );

      if (selectedComps.length === 0) {
        return (
          <Grid columns={4} rowGap="0px">
            <LeafCell width={2}>
              <H2>Comparable Properties</H2>
            </LeafCell>
            <LeafCell width={2}>
              <AlignRight>
                <PlainLink to={comparablesUrl}>
                  <AddButton label="Add Comparables" />
                </PlainLink>
              </AlignRight>
            </LeafCell>
            <LeafCell width={2} left={2}>
              <Centered>
                <Info>
                  <DetailsIcon />
                </Info>
                <Infotext>
                  We don’t have any Comparables for this property yet, add one!
                </Infotext>
                <PlainLink to={comparablesUrl}>
                  <AddButton href="/comparables" label="Add Comparables" />
                </PlainLink>
              </Centered>
            </LeafCell>
          </Grid>
        );
      }

      return (
        <>
          <Grid columns={2} rowGap="0px">
            <LeafCell width={1}>
              <H2>
                {`${selectedComps.length} Selected Comparables (${comparables.length} total)`}
              </H2>
            </LeafCell>
            <LeafCell width={1}>
              <AlignRight>
                <PlainLink to={comparablesUrl}>
                  <NoSubmitCreateButton label="Add or edit comparables" />
                </PlainLink>
              </AlignRight>
            </LeafCell>
          </Grid>
          <ComparableContainer columns={1} gridGap="4px">
            <>
              {selectedComps.map((comp) => (
                <ComparableCardLight
                  key={comp.id}
                  areas={areas}
                  mobileAreas={mobileAreas}
                  columns={1}
                >
                  <PropertyPhotoContainer
                    area="photo"
                    imageUrl={comp.comparableImages[0]?.url}
                  />
                  <CenteredCell>
                    <CardText
                      area="address"
                      as="a"
                      target="_blank"
                      href={linkForComp(comp)}
                      className="fs-exclude"
                    >
                      {comp.address}
                    </CardText>
                  </CenteredCell>
                  <CenteredCell area="salesInfo">
                    <SalesInformation comparable={comp} lightTheme />
                  </CenteredCell>
                  <CenteredCell area="propertyInfo">
                    <DottedList>
                      <DottedListItemSpaced lightTheme>
                        {comp.numberOfBeds} Bed
                      </DottedListItemSpaced>
                      <DottedListItemSpaced lightTheme>
                        {comp.propertyType}
                      </DottedListItemSpaced>
                      <DottedListItem lightTheme>
                        <RatingsChip
                          lightTheme
                          comparabilityRating={comp.comparabilityRating}
                          relevanceRating={comp.relevanceRating}
                        />
                      </DottedListItem>
                    </DottedList>
                  </CenteredCell>
                  <CenteredCell area="viewFullComp">
                    <TinyIcon>
                      <DetailsIcon fill="#10B186" />
                    </TinyIcon>
                    <PlainLink to={`${comparablesUrl}/selected`}>
                      <Greentext>View</Greentext>
                    </PlainLink>
                  </CenteredCell>
                </ComparableCardLight>
              ))}
            </>
          </ComparableContainer>
        </>
      );
    }}
  </ExtendedQuery>
);
