// @flow
import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { type MutationFunction } from "@apollo/client/react/components";
import styled, { css } from "styled-components";

import { ListEntryCard as OriginalListEntryCard } from "components/Card";
import { NoSubmitCurrencyField, CurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker, DatePicker } from "components/DatePicker";
import {
  DeleteButtonWithIcon,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitSelectField, SelectField } from "components/SelectField";
import { NoSubmitTextField, TextField } from "components/TextField";
import { NewEntry } from "components/NewEntry";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import latestOfferAmountQuery from "@nested/nest/src/pages/Deal/Progression/ProgressionSummary/CurrentOfferPrice/latestOfferAmount.graphql";

const ListEntryCard = styled(OriginalListEntryCard)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DeleteButtonWithConfirmation = styled(DeleteButtonWithIcon)`
  align-self: end;
  margin-top: 5px;
  width: 16px;
`;

const newEntryStyle = css`
  align-items: normal;
  margin-bottom: 0;
  > :last-child {
    align-self: end;
    margin-top: 5px;
  }
`;

const renegotiatedOfferFields = gql`
  fragment renegotiatedOfferFragment on RenegotiatedOffer {
    additionalInfoAboutRenegotiatedOffer
    dateAccepted
    id
    newAcceptedOfferAmount
    reasonForRenegotiatedOfferId
    signedOffBy
  }
`;

const RENEGOTIATED_OFFERS_QUERY = gql`
  query RenegotiatedOffersQuery($buyerOfferId: ID!) {
    buyerOffer(id: $buyerOfferId) {
      id
      renegotiatedOffers {
        id
        ...renegotiatedOfferFragment
      }
    }

    activeNestedUsers {
      id
      label: fullName
      value: closeUserId
    }

    reasonsForRenegotiatedOffer {
      label
      id
    }
  }
  ${renegotiatedOfferFields}
`;

const RENEGOTIATED_OFFERS_CREATE_MUTATION = gql`
  mutation CreateRenegotiatedOffer(
    $buyerOfferId: ID!
    $input: CreateRenegotiatedOfferInput!
  ) {
    createRenegotiatedOffer(buyerOfferId: $buyerOfferId, input: $input) {
      id
      ...renegotiatedOfferFragment
    }
  }
  ${renegotiatedOfferFields}
`;

const RENEGOTIATED_OFFERS_UPDATE_MUTATION = gql`
  mutation UpdateRenegotiatedOffer(
    $id: ID!
    $input: UpdateRenegotiatedOfferInput!
  ) {
    updateRenegotiatedOffer(id: $id, input: $input) {
      id
      ...renegotiatedOfferFragment
    }
  }
  ${renegotiatedOfferFields}
`;

const RENEGOTIATED_OFFERS_DELETE_MUTATION = gql`
  mutation DeleteRenegotiatedOffer($id: ID!) {
    deleteRenegotiatedOffer(id: $id) {
      id
    }
  }
`;

export function RenegotiatedOffers({ buyerOfferId }: { buyerOfferId: string }) {
  return (
    <ExtendedQuery
      query={RENEGOTIATED_OFFERS_QUERY}
      variables={{ buyerOfferId }}
    >
      {({
        buyerOffer: { renegotiatedOffers },
        activeNestedUsers,
        reasonsForRenegotiatedOffer,
      }: ExtendedQueryRenderProps<RenegotiatedOffersQuery>) => {
        const sortedRenegotiatedOffers = renegotiatedOffers
          .slice()
          .sort((a, b) => {
            switch (true) {
              case moment(a.dateAccepted).isAfter(b.dateAccepted):
                return -1;
              case moment(a.dateAccepted).isBefore(b.dateAccepted):
                return 1;
              default:
                return 0;
            }
          });

        const reasonsForRenegotiatedOfferOptions =
          reasonsForRenegotiatedOffer.map(({ label, id }) => ({
            label,
            value: id,
          }));

        return (
          <RenegotiatedOffersComponent
            {...{
              buyerOfferId,
              activeNestedUsers,
              reasonsForRenegotiatedOfferOptions,
              sortedRenegotiatedOffers,
            }}
          />
        );
      }}
    </ExtendedQuery>
  );
}

function RenegotiatedOffersComponent({
  activeNestedUsers,
  buyerOfferId,
  reasonsForRenegotiatedOfferOptions,
  sortedRenegotiatedOffers,
}) {
  return (
    <div data-test="renegotiated-offers">
      <NewRenegotiatedOffer
        {...{
          buyerOfferId,
          activeNestedUsers,
          reasonsForRenegotiatedOfferOptions,
        }}
      />
      <RenegotiatedOffersList
        {...{
          buyerOfferId,
          activeNestedUsers,
          reasonsForRenegotiatedOfferOptions,
          sortedRenegotiatedOffers,
        }}
      />
    </div>
  );
}

function RenegotiatedOffersList({
  buyerOfferId,
  activeNestedUsers,
  reasonsForRenegotiatedOfferOptions,
  sortedRenegotiatedOffers,
}) {
  return (
    <div data-test="renegotiated-offers:list">
      {sortedRenegotiatedOffers.map((renegotiatedOffer) => (
        <ListEntryCard key={renegotiatedOffer.id}>
          <ExtendedMutation
            mutation={RENEGOTIATED_OFFERS_DELETE_MUTATION}
            variables={{ id: renegotiatedOffer.id }}
          >
            {(
              deleteRenegotiatedOfferMutation: MutationFunction<DeleteRenegotiatedOffer>,
            ) => (
              <ExtendedMutation mutation={RENEGOTIATED_OFFERS_UPDATE_MUTATION}>
                {(
                  updateRenegotiatedOfferMutation: MutationFunction<UpdateRenegotiatedOffer>,
                ) => {
                  const deleteRenegotiatedOffer = () =>
                    deleteRenegotiatedOfferMutation({
                      awaitRefetchQueries: true,
                      refetchQueries: [
                        {
                          query: RENEGOTIATED_OFFERS_QUERY,
                          variables: {
                            buyerOfferId,
                          },
                        },
                        {
                          query: latestOfferAmountQuery,
                          variables: { buyerOfferId },
                        },
                      ],
                    });

                  const updateRenegotiatedOffer = (input) =>
                    updateRenegotiatedOfferMutation({
                      variables: {
                        id: renegotiatedOffer.id,
                        input,
                      },
                      optimisticResponse: {
                        updateRenegotiatedOffer: {
                          ...renegotiatedOffer,
                          ...input,
                        },
                      },
                      refetchQueries: [
                        {
                          query: latestOfferAmountQuery,
                          variables: { buyerOfferId },
                        },
                      ],
                    });

                  return (
                    <>
                      <Grid columns={4}>
                        <LeafCell>
                          <CurrencyField
                            data-test="renegotiated-offer:new-accepted-offer-amount:existing-entry"
                            label="New accepted offer"
                            mutation={updateRenegotiatedOffer}
                            property="newAcceptedOfferAmount"
                            value={renegotiatedOffer.newAcceptedOfferAmount}
                          />
                        </LeafCell>
                        <LeafCell>
                          <DatePicker
                            data-test="renegotiated-offer:date-accepted:existing-entry"
                            label="Date accepted"
                            mutation={updateRenegotiatedOffer}
                            property="dateAccepted"
                            value={renegotiatedOffer.dateAccepted}
                          />
                        </LeafCell>
                        <LeafCell width={2}>
                          <SelectField
                            data-test="renegotiated-offer:signed-off-by:existing-entry"
                            label="Signed off by"
                            mutation={updateRenegotiatedOffer}
                            property="signedOffBy"
                            options={activeNestedUsers}
                            searchable
                            value={renegotiatedOffer.signedOffBy}
                          />
                        </LeafCell>
                        <LeafCell width={2}>
                          <SelectField
                            data-test="renegotiated-offer:reason-for-renegotiated-offer-id:existing-entry"
                            label="Reason for renegotiated offer"
                            mutation={updateRenegotiatedOffer}
                            property="reasonForRenegotiatedOfferId"
                            options={reasonsForRenegotiatedOfferOptions}
                            searchable
                            value={
                              renegotiatedOffer.reasonForRenegotiatedOfferId
                            }
                          />
                        </LeafCell>
                        <LeafCell width={2}>
                          <TextField
                            data-test="renegotiated-offer:additional-info-about-renegotiated-offer:existing-entry"
                            label="Additional info about renegotiated offer"
                            multiline
                            mutation={updateRenegotiatedOffer}
                            property="additionalInfoAboutRenegotiatedOffer"
                            value={
                              renegotiatedOffer.additionalInfoAboutRenegotiatedOffer
                            }
                          />
                        </LeafCell>
                      </Grid>
                      <DeleteButtonWithConfirmation
                        data-test={`renegotiated-offer:delete-existing-entry:${renegotiatedOffer.id}`}
                        onSubmit={() => {
                          if (itemDeletionConfirmed()) {
                            deleteRenegotiatedOffer();
                          }
                        }}
                      />
                    </>
                  );
                }}
              </ExtendedMutation>
            )}
          </ExtendedMutation>
        </ListEntryCard>
      ))}
    </div>
  );
}

function NewRenegotiatedOffer({
  activeNestedUsers,
  buyerOfferId,
  reasonsForRenegotiatedOfferOptions,
}) {
  return (
    <ExtendedMutation mutation={RENEGOTIATED_OFFERS_CREATE_MUTATION}>
      {(
        createRenegotiatedOfferMutation: MutationFunction<CreateRenegotiatedOffer>,
      ) => {
        const createRenegotiatedOffer = (input) =>
          createRenegotiatedOfferMutation({
            variables: { buyerOfferId, input },
            refetchQueries: [
              {
                query: RENEGOTIATED_OFFERS_QUERY,
                variables: {
                  buyerOfferId,
                },
              },
              {
                query: latestOfferAmountQuery,
                variables: { buyerOfferId },
              },
            ],
            awaitRefetchQueries: true,
          });

        return (
          <NewEntry
            addButtonAtTop
            css={newEntryStyle}
            createMutation={createRenegotiatedOffer}
            itemName="renegotiated-offer"
            requiredFields={[
              "dateAccepted",
              "newAcceptedOfferAmount",
              "reasonForRenegotiatedOfferId",
              "signedOffBy",
            ]}
          >
            {({ update, values }) => (
              <Grid columns={4}>
                <LeafCell>
                  <NoSubmitCurrencyField
                    data-test="renegotiated-offer:new-accepted-offer-amount:new-entry"
                    label="New accepted offer"
                    onSubmit={update("newAcceptedOfferAmount")}
                    value={values.newAcceptedOfferAmount}
                  />
                </LeafCell>
                <LeafCell>
                  <NoSubmitDatePicker
                    data-test="renegotiated-offer:date-accepted:new-entry"
                    label="Date accepted"
                    onSubmit={update("dateAccepted")}
                    value={values.dateAccepted}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <NoSubmitSelectField
                    data-test="renegotiated-offer:signed-off-by:new-entry"
                    label="Signed off by"
                    onSubmit={update("signedOffBy")}
                    options={activeNestedUsers}
                    searchable
                    value={values.signedOffBy}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <NoSubmitSelectField
                    data-test="renegotiated-offer:reason-for-renegotiated-offer-id:new-entry"
                    label="Reason for renegotiated offer"
                    onSubmit={update("reasonForRenegotiatedOfferId")}
                    options={reasonsForRenegotiatedOfferOptions}
                    searchable
                    value={values.reasonForRenegotiatedOfferId}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <NoSubmitTextField
                    data-test="renegotiated-offer:additional-info-about-renegotiated-offer:new-entry"
                    label="Additional info about renegotiated offer"
                    multiline
                    onSubmit={update("additionalInfoAboutRenegotiatedOffer")}
                    value={values.additionalInfoAboutRenegotiatedOffer}
                  />
                </LeafCell>
              </Grid>
            )}
          </NewEntry>
        );
      }}
    </ExtendedMutation>
  );
}
