// @flow
import { type MutationFunction } from "@apollo/client/react/components";
import { Heading } from "components/Heading";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { Grid, Cell, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
  readOnly?: boolean,
};

export const BuyerOfferBuyerSolicitorInfo = ({
  buyerOffer,
  mutation,
  readOnly = false,
}: Props) => (
  <>
    <Cell width={4}>
      <Grid columns={4}>
        <LeafCell width={4}>
          <Heading level={2}>Buyer's solicitor</Heading>
        </LeafCell>
        <LeafCell width={2} left={1}>
          <TextField
            label="Buyer's solicitor firm"
            value={buyerOffer.solicitorFirm}
            property="solicitorFirm"
            mutation={mutation}
            data-test="buyer-offer-details-solicitor-firm"
            className="fs-exclude"
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Buyer's lead solicitor"
            value={buyerOffer.solicitorLead}
            property="solicitorLead"
            mutation={mutation}
            data-test="buyer-offer-details-solicitor-lead"
            className="fs-exclude"
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Buyer's solicitor phone number"
            value={buyerOffer.solicitorPhone}
            property="solicitorPhone"
            mutation={mutation}
            data-test="buyer-offer-details-solicitor-phone"
            className="fs-exclude"
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="Buyer's solictor email"
            value={buyerOffer.solicitorEmail}
            property="solicitorEmail"
            mutation={mutation}
            data-test="buyer-offer-details-solicitor-email"
            className="fs-exclude"
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={4}>
          <TextField
            label="Buyer's solictor address"
            value={buyerOffer.solicitorAddress}
            property="solicitorAddress"
            mutation={mutation}
            data-test="buyer-offer-details-solicitor-address"
            className="fs-exclude"
            readOnly={readOnly}
          />
        </LeafCell>
      </Grid>
    </Cell>
  </>
);
