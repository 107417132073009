// @flow
import { Route, Switch, type ContextRouter } from "react-router-dom";

import { HomePage } from "./HomePage";
import { PropertyInfoPage } from "./PropertyInfoPage";

export const SAiPadApp = (props: ContextRouter) => {
  const { match } = props;

  return (
    <Switch>
      <Route exact path={match.path} component={HomePage} />

      <Route
        exact
        path={`${match.path}/propertyinfo/:dealId`}
        component={PropertyInfoPage}
      />
    </Switch>
  );
};
