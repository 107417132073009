// @flow
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem } from "@nested/brand/src/components/Timeline";

type Props = {
  event: Timeline_timeline,
};

export const TimelineAnalysisViewedEvent = ({ event }: Props) => {
  if (event.__typename !== "TimelineEvent") {
    return null;
  }

  const { timestamp } = event;

  return (
    <TimelineItem
      icon={faEye}
      color="grey"
      primaryHeading="Property analysis viewed by customer"
      timestamp={timestamp}
    />
  );
};
