// @flow

import { gql } from "@apollo/client";
import styled from "styled-components";

import { Grid, LeafCell } from "components/Grid";
import { RadioButtons } from "components/RadioButtons";
import { TextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { SelectField } from "components/SelectField";
import { H3 } from "components/Heading";
import { lowMediumHighOptions } from "@nest-ui/shared/options";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

const VALUATION_SECTION_QUERY = gql`
  query ValuationSectionQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      valuationPastSaleComparablesConfidence
      pastSaleComparablesComments
      valuationSaleAgreedComparablesConfidence
      saleAgreedComparablesComments
      valuationExpectedBuyerSet
      expectedBuyerSetComments
      liquidityRatiosComments
    }
  }
`;

const VALUATION_SECTION_MUTATION = gql`
  mutation UpdateValuationSectionMutation(
    $dealId: ID!
    $input: NestDealInput!
  ) {
    updateNestDeal(id: $dealId, nestDeal: $input) {
      id
      valuationPastSaleComparablesConfidence
      pastSaleComparablesComments
      valuationSaleAgreedComparablesConfidence
      saleAgreedComparablesComments
      valuationExpectedBuyerSet
      expectedBuyerSetComments
      liquidityRatiosComments
    }
  }
`;

type Props = {
  parentId: string,
};

const SubHeading = styled(H3)`
  margin-bottom: 0;
`;

const CellAlignedTop = styled(LeafCell)`
  align-items: start;
`;

export const ValuationSection = ({ parentId: dealId }: Props) => (
  <section>
    <ExtendedQuery query={VALUATION_SECTION_QUERY} variables={{ dealId }}>
      {(data: ExtendedQueryRenderProps<ValuationSectionQuery>) => (
        <ExtendedMutation mutation={VALUATION_SECTION_MUTATION}>
          {(mutateFn) => {
            const mutation = (input) =>
              mutateFn({
                variables: { dealId: data.nestDeal.id, input },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateNestDeal: {
                    __typename: "NestDeal",
                    id: data.nestDeal.id,
                    ...data.nestDeal,
                    ...input,
                  },
                },
              });

            return (
              <Grid columns={2}>
                <LeafCell width={2}>
                  <SubHeading>Comparables</SubHeading>
                </LeafCell>
                <CellAlignedTop width={1}>
                  <RadioButtons
                    data-test="valuation_section:past_sale_comparables_confidence"
                    label="Past sale comparables (confidence)"
                    options={lowMediumHighOptions}
                    property="valuationPastSaleComparablesConfidence"
                    mutation={mutation}
                    value={data.nestDeal.valuationPastSaleComparablesConfidence}
                  />
                </CellAlignedTop>
                <LeafCell width={1}>
                  <TextareaWithLinks
                    data-test="valuation_section:past_sale_comparables_comments"
                    label="Comments"
                    property="pastSaleComparablesComments"
                    mutation={mutation}
                    value={data.nestDeal.pastSaleComparablesComments}
                  />
                </LeafCell>
                <CellAlignedTop width={1}>
                  <RadioButtons
                    data-test="valuation_section:sale_agreed_comparables_confidence"
                    label="Sale agreed comparables (confidence)"
                    options={lowMediumHighOptions}
                    property="valuationSaleAgreedComparablesConfidence"
                    mutation={mutation}
                    value={
                      data.nestDeal.valuationSaleAgreedComparablesConfidence
                    }
                  />
                </CellAlignedTop>
                <LeafCell width={1}>
                  <TextareaWithLinks
                    data-test="valuation_section:sale_agreed_comparables_comments"
                    label="Comments"
                    property="saleAgreedComparablesComments"
                    mutation={mutation}
                    value={data.nestDeal.saleAgreedComparablesComments}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <SubHeading>Marketability</SubHeading>
                </LeafCell>
                <CellAlignedTop width={1}>
                  <SelectField
                    data-test="valuation_section:expected_buyer_set"
                    multiple
                    label="Expected buyer set"
                    options={[
                      "FTB: Single/couple",
                      "FTB: Young family/family",
                      "STB+: Single",
                      "STB+: Couple/young family",
                      "STB+: Family",
                      "Downsizers",
                      "BTL investor",
                      "Property developer",
                    ]}
                    property="valuationExpectedBuyerSet"
                    mutation={mutation}
                    value={data.nestDeal.valuationExpectedBuyerSet}
                  />
                </CellAlignedTop>
                <LeafCell width={1}>
                  <TextareaWithLinks
                    data-test="valuation_section:expected_buyer_set_comments"
                    label="Comments"
                    property="expectedBuyerSetComments"
                    mutation={mutation}
                    value={data.nestDeal.expectedBuyerSetComments}
                  />
                </LeafCell>
                <LeafCell width={2}>
                  <SubHeading>Area & Liquidity</SubHeading>
                </LeafCell>
                <LeafCell width={2}>
                  <TextareaWithLinks
                    data-test="valuation_section:estimated_days_to_offer_comments"
                    label="Comments"
                    property="liquidityRatiosComments"
                    mutation={mutation}
                    value={data.nestDeal.liquidityRatiosComments}
                  />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  </section>
);
