import T from "prop-types";
import { path } from "ramda";
import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";
import { NumberField } from "components/NumberField";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import advanceJul2018Query from "./queries/advanceJul2018.graphql";
import { advanceJul2018QueryConfig } from "./queries/advanceJul2018QueryConfig";

import { updateDealTypeAdvanceJul2018Config } from "./mutations/updateDealTypeAdvanceJul2018Config";
import UpdateDealTypeAdvanceJul2018Mutation from "./mutations/UpdateDealTypeAdvanceJul2018Mutation.graphql";

const AdvanceJul2018Component = ({
  data: queryResult,
  updateDealTypeAdvanceJul2018,
}) => {
  const dealTypeData =
    path(["nestDeal", "dealTypeAdvanceJul2018"], queryResult) || null;
  return (
    dealTypeData && (
      <Grid columns={4}>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceJul2018}
            property="advanceFee"
            data-test="deal-type-advance-jul2018-advance-fee"
            label="Advance fee %"
            value={dealTypeData.advanceFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceJul2018}
            property="agencyFee"
            data-test="deal-type-advance-jul2018-agency-fee"
            label="Agency fee %"
            value={dealTypeData.agencyFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceJul2018}
            property="noAdvanceDiscountPercentage"
            data-test="deal-type-advance-jul2018-no-advance-discount-percentage"
            label="Discount for no advance %"
            value={dealTypeData.noAdvanceDiscountPercentage}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceJul2018}
            property="depositDrawdownAllowancePercentage"
            data-test="deal-type-advance-jul2018-deposit-drawdown-allowance"
            label="Deposit drawdown allowance %"
            value={dealTypeData.depositDrawdownAllowancePercentage}
          />
        </LeafCell>
        <LeafCell>
          <NumberField
            mutation={updateDealTypeAdvanceJul2018}
            property="coolingOffPeriodDays"
            data-test="deal-type-advance-jul2018-cooling-off-period-days"
            label="DD period (days)"
            value={dealTypeData.coolingOffPeriodDays}
          />
        </LeafCell>
        <LeafCell>
          <CurrencyField
            mutation={updateDealTypeAdvanceJul2018}
            property="referralDiscount"
            data-test="deal-type-advance-jul2018-referral-discount"
            label="Referral discount"
            value={dealTypeData.referralDiscount}
          />
        </LeafCell>
      </Grid>
    )
  );
};
AdvanceJul2018Component.propTypes = {
  data: T.object,
  mutations: T.object,
};

export const AdvanceJul2018 = compose(
  graphql(advanceJul2018Query, advanceJul2018QueryConfig),
  graphql(
    UpdateDealTypeAdvanceJul2018Mutation,
    updateDealTypeAdvanceJul2018Config,
  ),
)(AdvanceJul2018Component);
