// @flow
import { SingleFileUploadButton } from "components/UploadSingleFile/SingleFileUploadButton";
import { css } from "styled-components";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { useEffect, useState } from "react";
import { faTrashRestoreAlt } from "@fortawesome/free-solid-svg-icons";

import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_DEAL_OPPORTUNITY_STATUS } from "@nested/nest/src/components/RightSidePanel/Timeline/TimelinePanel";
import { Spinner } from "@nested/ui";

import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "../../../hooks/useNotifications";

const uploadedHomeReportLinkStyle = css`
  font-weight: 500;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.hague70};
  padding: 0 10px 0 0;
`;

const alignItemsStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const CONTRACT_QUERY = gql`
  query contractQuery($dealId: ID!) {
    contract(dealId: $dealId) {
      id
      fileName
      downloadUrl
    }
  }
`;

export const UPLOAD_CONTRACT_MUTATION = gql`
  mutation UploadContractMutation($input: UploadContractInput!) {
    uploadContract(input: $input) {
      id
      fileName
      downloadUrl
    }
  }
`;

export const DELETE_CONTRACT_MUTATION = gql`
  mutation DeleteContractMutation($dealId: ID!) {
    deleteContract(dealId: $dealId) {
      id
    }
  }
`;

export const REUPLOAD_CONTRACT_MUTATION = gql`
  mutation ReuploadContractMutation($input: ReuploadContractInput!) {
    reuploadContract(input: $input) {
      id
      fileName
      downloadUrl
    }
  }
`;

type Props = {
  dealId: string,
};

export const UploadContract = ({ dealId }: Props) => {
  const { data: opportunityStatusData, loading: loadingOpportunityStatus } =
    useQuery(GET_DEAL_OPPORTUNITY_STATUS, {
      variables: { id: dealId },
    });

  const { data: contractData, loading: loadingContract } = useQuery(
    CONTRACT_QUERY,
    {
      fetchPolicy: "cache-and-network",
      variables: { dealId },
    },
  );

  const [uploadContract, { loading: uploadingContract }] = useMutation(
    UPLOAD_CONTRACT_MUTATION,
  );

  const [deleteContract, { loading: deletingHomeReport }] = useMutation(
    DELETE_CONTRACT_MUTATION,
    {
      refetchQueries: [
        {
          query: CONTRACT_QUERY,
          variables: { dealId },
        },
      ],
    },
  );

  const [reuploadContract, { loading: reuploadingContract }] = useMutation(
    REUPLOAD_CONTRACT_MUTATION,
  );

  const [currentContract, setCurrentContract] = useState(
    contractData?.contract,
  );

  const { createNotification } = useNotifications();

  useEffect(() => {
    setCurrentContract(contractData?.contract);
  }, [contractData]);

  if (
    loadingOpportunityStatus ||
    loadingContract ||
    uploadingContract ||
    deletingHomeReport ||
    reuploadingContract
  ) {
    return (
      <div css={alignItemsStyle}>
        <Spinner />
      </div>
    );
  }

  const currentOpportunityStatus =
    opportunityStatusData?.nestDeal?.opportunityStatus?.valueText;

  const isDealClosed = currentOpportunityStatus >= "s07_closed";
  const triggerContractUpload = async (file) => {
    try {
      const result = await uploadContract({
        variables: {
          input: {
            dealId,
            file,
          },
        },
      });

      if (result?.data) {
        setCurrentContract(result?.data?.uploadContract);
      }
    } catch (e) {
      errorHandler(e);
    }
  };
  const triggerDeleteHomeReport = async () => {
    try {
      const result = await deleteContract({
        variables: {
          dealId,
        },
      });

      if (result?.data) {
        setCurrentContract(false);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const triggerContractReupload = async (file) => {
    try {
      const result = await reuploadContract({
        variables: {
          input: {
            dealId,
            file,
          },
        },
      });

      if (result?.data) {
        setCurrentContract(result?.data?.reuploadContract);
        createNotification("Contract re-uploaded");
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      {
        // Prior to this feature, contracts were manually uploaded into Google Drive.
        // Since we've decided not to migrate such contracts from Google Drive to the Nest,
        // it's OK to upload a new contract into the Nest even if it's not physically stored there.
        !currentContract && (
          <div>
            <SingleFileUploadButton
              allowedFileFormats={["application/pdf"]}
              maxAllowedSizeInBytes={8_000_000}
              additionalAlertMessage={
                "Please upload a PDF file no larger than 8MB."
              }
              onChange={(file) => {
                triggerContractUpload(file);
              }}
              data-test={"single-file-upload-button"}
            />
          </div>
        )
      }
      {!isDealClosed && currentContract && (
        <div>
          <div css={uploadedHomeReportLinkStyle}>
            <a href={currentContract.downloadUrl} target="_blank">
              {currentContract.fileName}
            </a>
          </div>
          <DeleteButton
            onSubmit={() => {
              if (
                itemDeletionConfirmed(
                  "Are you sure you want to delete the contract?",
                )
              ) {
                triggerDeleteHomeReport();
              }
            }}
            data-test="upload-home-report-delete-button"
          />
        </div>
      )}
      {isDealClosed && currentContract && (
        <div>
          <div css={uploadedHomeReportLinkStyle}>
            <a href={currentContract.downloadUrl} target="_blank">
              {currentContract.fileName}
            </a>
          </div>
          <SingleFileUploadButton
            allowedFileFormats={["application/pdf"]}
            maxAllowedSizeInBytes={8_000_000}
            additionalAlertMessage={
              "Please upload a PDF file no larger than 8MB."
            }
            onChange={(file) => {
              triggerContractReupload(file);
            }}
            icon={faTrashRestoreAlt}
            data-test={"single-file-reupload-button"}
          />
        </div>
      )}
    </>
  );
};
