// @flow
import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { css, ThemeContext } from "styled-components";
import { useCombobox } from "downshift";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
  faSearch,
  faAngleLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQuery, gql } from "@apollo/client";
import { media } from "@nested/brand";
import { Sentry } from "@nested/isomorphic-sentry";
import { useUser } from "../../../hooks/useUser";
import { SearchResults } from "./SearchResults";

export const SEARCH_DEAL_FRAGMENT = gql`
  fragment SearchDealFragment on NestDeal {
    id
    active
    acceptedBuyerOffer {
      id
      actualDateOfCompletion
      actualDateOfExchange
      placedOn
    }
    address
    externalId
    createdAt
    currentListingStartDate
    dealTypeAgency {
      id
      nestedContractSignedDate
    }
    opportunityStatus {
      valueText
    }
    propertyAnalysisSharedAt
    rmMostRecentContactDatetime
    firstName
    lastName
    ownerName
  }
`;

export const SEARCH_BUYER_FRAGMENT = gql`
  fragment SearchBuyerFragment on Buyer {
    id
    leadStatus
    name
    buyerPropertyInterests {
      id
    }
    mostRecentlyCreatedBpi {
      id
      insertedAt
      buyerOffers {
        id
        placedOn
      }
      deal {
        id
        address
        acceptedBuyerOffer {
          id
          actualDateOfCompletion
          actualDateOfExchange
          placedOn
        }
      }
      status {
        label
        value
      }
      viewings {
        id
        datetimeViewingStarts
      }
    }
  }
`;

export const RECENT_SEARCHES_QUERY = gql`
  query RecentSearches {
    recentSearches {
      id
      deal {
        id
        ...SearchDealFragment
      }
      buyer {
        id
        ...SearchBuyerFragment
      }
    }
  }
  ${SEARCH_BUYER_FRAGMENT}
  ${SEARCH_DEAL_FRAGMENT}
`;

export const SEARCH_QUERY = gql`
  query Search($input: String!) {
    search(input: $input) {
      deals {
        id
        ...SearchDealFragment
      }
      buyers {
        id
        ...SearchBuyerFragment
      }
    }
  }
  ${SEARCH_BUYER_FRAGMENT}
  ${SEARCH_DEAL_FRAGMENT}
`;

export const CREATE_SEARCH_HISTORY_ITEM = gql`
  mutation CreateSearchHistoryItem($input: CreateSearchHistoryItemInput!) {
    createSearchHistoryItem(input: $input) {
      id
    }
  }
`;

const searchButtonStyle = css`
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.palette.hague70};
  background-color: ${({ theme }) => theme.palette.hague150};
  height: 30px;
  width: 30px;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.hague20};
  padding: 0;

  ${media.tablet`
    display: block;
    width: 100%;
    max-width: 440px;
    margin: auto;
    text-align: left;
    border-radius: 5px;
    padding-left: 10px;
  `}
`;

const searchIconStyle = css`
  color: ${({ theme }) => theme.palette.hague20};
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  ${media.tablet`
    left: 20px;
    color: ${({ theme }) => theme.palette.hague100};
  `}
`;

const backButtonStyle = css`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.hague20};
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 0;
  cursor: pointer;

  ${media.tablet`
    display: none;
  `}
`;

const inputWrapperStyle = css`
  display: flex;
  align-items: center;
  height: 50px;
  background: ${({ theme }) => theme.palette.hague100};
  width: 100%;

  ${media.tablet`
    height: auto;
    background: transparent;
  `}
`;

const inputStyle = css`
  width: 100%;
  height: 30px;
  margin-right: 15px;
  padding-left: 30px;
  padding-right: 20px;
  background: ${({ theme }) => theme.palette.hague150};
  font-size: 14px;
  line-height: 17px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague70};
  color: white;
  overflow: hidden;

  :focus {
    outline-style: none;
    box-shadow: none;
  }

  &::placeholder {
    color: #9db3bb;
    line-height: 18px;
    opacity: 1 !important;
  }

  ${media.tablet`
    margin: 0;
    color: ${({ theme }) => theme.palette.hague150};
    height: 54px;
    padding-left: 50px;
    background: white;
    outline-style: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const searchContainerStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  z-index: 7;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  pointer-events: none;
  padding-bottom: 50px;

  ${({ open }) =>
    open &&
    css`
      display: block;
      background-color: white;
      pointer-events: auto;
    `}

  ${media.tablet`
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto;
    min-height: unset;
    width: calc(100% - 40px);
    max-width: 640px;
    border-radius: 5px;
    padding-bottom: 0;
  `}
`;

const backgroundOverlayStyle = css`
  display: none;

  ${media.tablet`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 20px 10px;
    z-index: 6;
    overflow-y: auto;
    overflow-x: hidden;
    ${({ open }) =>
      open
        ? css`
            transition: background-color 300ms linear 0ms,
              visibility 300ms linear 0ms;
            visibility: visible;
            background-color: rgb(10, 66, 84, 0.8);
            pointer-events: auto;
          `
        : css`
            transition: background-color 100ms ease 100ms,
              visibility 100ms ease 100ms;
            visibility: hidden;
            background-color: rgb(10, 66, 84, 0);
            pointer-events: none;
          `};
  `}
`;

const wrapperStyle = css`
  width: 100%;
  text-align: right;
`;

const toggleButtonLabel = css`
  display: none;
  ${media.tablet`
    padding-left: 10px;
    display: inline;
    color: #9db3bb;
    opacity: 1;
  `}
`;

const inputAndIconWrapper = css`
  position: relative;
  flex-grow: 1;
  padding-right: 15px;

  ${media.tablet`
    padding-right: 0;
  `}
`;

const clearButtonStyle = css`
  position: absolute;
  top: 7px;
  right: 20px;
  border: none;
  background-color: transparent;
  color: #9ab3bb;
  cursor: pointer;

  ${media.tablet`
    top: 17px;
  `}
`;

const ultimateWrapper = css`
  margin-right: 15px;
  ${media.tablet`
   flex-grow: 1;
  `}
`;

export const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [queryInput, setQueryInput] = useState(searchValue);
  const { email } = useUser();

  const theme = useContext(ThemeContext);
  const history = useHistory();

  const debouncedSetQueryInput = useCallback(debounce(setQueryInput, 500), []);

  const placeholder =
    window.innerWidth < theme.breakpoints.tablet
      ? "Search the Nest"
      : "Try searching for names, addresses, emails and phone numbers.";

  useEffect(() => {
    debouncedSetQueryInput(searchValue);
  }, [searchValue]);

  const inputRef = useRef();

  const [createSearchHistoryItem, { error: mutationError }] = useMutation(
    CREATE_SEARCH_HISTORY_ITEM,
  );

  const {
    previousData,
    data = previousData,
    loading,
    error,
  } = useQuery(SEARCH_QUERY, {
    variables: {
      input: queryInput,
    },
    skip: queryInput.length < 3,
  });

  const {
    data: recentSearchesData,
    loading: recentSearchesLoading,
    error: recentSearchesError,
  } = useQuery(RECENT_SEARCHES_QUERY);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error, {
        extra: {
          searchValue,
          queryInput,
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (mutationError) {
      Sentry.captureException(mutationError, {
        extra: {
          searchValue,
          queryInput,
        },
      });
    }
  }, [mutationError]);

  useEffect(() => {
    if (recentSearchesError) {
      Sentry.captureException(recentSearchesError, {
        extra: {
          searchValue,
          queryInput,
        },
      });
    }
  }, [recentSearchesError]);

  const buyers = data?.search?.buyers || [];
  const sellers = data?.search?.deals || [];
  const recentSearches = recentSearchesData?.recentSearches || [];

  const items =
    searchValue.length === 0 ? recentSearches : [...sellers, ...buyers];

  const {
    isOpen,
    getMenuProps,
    getComboboxProps,
    getItemProps,
    getInputProps,
    highlightedIndex,
    getToggleButtonProps,
    reset,
  } = useCombobox({
    items,
    onSelectedItemChange: (changes) => {
      const item = changes.selectedItem;

      if (!item) {
        return;
      }

      if (item.__typename === "SearchHistoryItem" && item.deal) {
        history.push(`/deals/${item.deal.externalId}`);
        return;
      }

      if (item.__typename === "SearchHistoryItem" && item.buyer) {
        history.push(`/buyers/${item.buyer.id}`);
        return;
      }

      if (item.__typename === "NestDeal") {
        history.push(`/deals/${item.externalId}`);
      } else {
        history.push(`/buyers/${item.id}`);
      }

      // Don't try to record history if there is no email for the current user (eg when running cypress tests)
      if (!email) {
        return;
      }

      createSearchHistoryItem({
        variables: {
          input: {
            searchInput: searchValue,
            resultNumber: highlightedIndex + 1,
            dealId: item.__typename === "NestDeal" ? item.id : null,
            buyerId: item.__typename === "Buyer" ? item.id : null,
          },
        },
        refetchQueries: [
          {
            query: RECENT_SEARCHES_QUERY,
          },
        ],
      });
    },
  });

  return (
    <div {...getComboboxProps()} css={ultimateWrapper}>
      <div css={wrapperStyle}>
        <button
          data-test="search:toggle"
          css={searchButtonStyle}
          {...getToggleButtonProps({
            onClick: () => {
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              });
            },
          })}
        >
          <FontAwesomeIcon icon={faSearch} />
          <span css={toggleButtonLabel}>
            {searchValue.length === 0 ? "Search" : searchValue}
          </span>
        </button>
      </div>
      <div
        css={backgroundOverlayStyle}
        role="presentation"
        open={isOpen}
        onClick={() => reset()}
      />
      <div css={searchContainerStyle} open={isOpen}>
        <div css={inputWrapperStyle}>
          <button css={backButtonStyle} onClick={reset}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <div css={inputAndIconWrapper}>
            <FontAwesomeIcon css={searchIconStyle} icon={faSearch} />
            <input
              data-test="search:input"
              placeholder={placeholder}
              css={inputStyle}
              {...getInputProps({
                ref: inputRef,
              })}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchValue.length > 0 && (
              <button
                css={clearButtonStyle}
                // Ensures clearing the input doesn't lose focus
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  setSearchValue("");
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </div>
        <SearchResults
          data-test="search:results"
          error={error || recentSearchesError}
          loading={
            loading || recentSearchesLoading || (!data?.search && !error)
          }
          isOpen={isOpen}
          searchValue={searchValue}
          buyers={buyers}
          sellers={sellers}
          highlightedIndex={highlightedIndex}
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          recentSearches={recentSearches}
        />
      </div>
    </div>
  );
};
