import { graphql } from "deal/utils/graphql";
import { withRouter } from "react-router";
import { compose } from "recompose";

const config = {
  options: ({
    match: {
      params: { id },
    },
  }) => ({
    variables: {
      id,
    },
    // return data in cache immediately then update cache with result from network request
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  }),
};

export const withGraphQLQuery = (query) =>
  compose(withRouter, graphql(query, config));
