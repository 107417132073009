import { compose, renameProps } from "recompose";
import styled from "styled-components";

import { withOnSubmit } from "@nest-ui/hocs";

const Button = styled.button`
  min-width: 112px;
  height: ${({ theme }) => theme.input.height};
  border-radius: ${({ theme }) => theme.input.border.radius};
  background-color: ${({ theme }) => theme.color.secondary.light};
  color: white;
  font-size: ${({ theme }) => theme.font.size.default};
  cursor: pointer;
  outline: none;
`;

const enhance = compose(
  renameProps({ label: "children", onSubmit: "onClick" }),
  withOnSubmit,
);

export const ActionButton = enhance(Button);
