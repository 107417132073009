// @flow
import { useState } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { RHSidePanel } from "./RHSidePanel";
import { MemoizedTaskPanelContent } from "./Tasks/TaskPanelContent";
import { TimelinePanel } from "./Timeline/TimelinePanel";
import { ButtonTab, Tabs } from "../Navigation/Tabs";
import { useOutsideOnClick } from "../../hooks/useOutsideOnClick";

const mobileOnly = css`
  position: relative;
  display: ${({ $open }) => ($open ? "block" : "none")};

  ${media.tablet`
    display: none;
  `}
`;

const tabContainer = css`
  margin: 0 15px 0;
  padding-top: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
`;

const closeButtonStyle = css`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.hague100};
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 0px;
  top: 12px;
`;

const breakStyle = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  height: 20px;
  width: 100vw;
`;

const arrowStyle = css`
  background-color: white;
  border-left: 2px solid ${({ theme }) => theme.palette.hague20};
  border-top: 2px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 5px 0 0 0;
  height: 18px;
  position: absolute;
  right: ${({ $buyer }) => ($buyer ? "30px" : "10.5%")};
  transform: scalex(0.6) rotate(45deg) translate(55%, 25%);
  width: 18px;
  z-index: 5;
  top: ${({ $buyer }) => ($buyer ? "-20px" : "0")};
`;

export type Props = {
  className?: string,
  relationId: string,
  relationType: "deal" | "buyer",
  mobileOnClose: () => void,
  mobileOpen: boolean,
  onClose: () => void,
  open: boolean,
  pageTopInView: boolean,
  taskCount: ?number,
};

const TASKS = "TASKS";
const ACTIVITY = "ACTIVITY";

export const RightSidePanel = ({
  className,
  relationId,
  relationType,
  mobileOnClose,
  mobileOpen,
  onClose,
  open,
  pageTopInView,
  taskCount,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(TASKS);
  const [activitiesExpanded, setActivitiesExpanded] = useState(false);
  const expandActivityButtonRef = useOutsideOnClick(() =>
    setActivitiesExpanded(false),
  );

  return (
    <>
      <RHSidePanel
        className={className}
        data-test="tasks-activity-side-panel"
        open={open}
        pageTopInView={pageTopInView}
        activitiesExpanded={activitiesExpanded}
      >
        <MemoizedTaskPanelContent
          onClose={onClose}
          relationId={relationId}
          relationType={relationType}
        />
        <TimelinePanel
          activitiesExpanded={activitiesExpanded}
          setActivitiesExpanded={setActivitiesExpanded}
          relationType={relationType}
          relationId={relationId}
          ref={expandActivityButtonRef}
        />
      </RHSidePanel>
      <div
        css={mobileOnly}
        $open={mobileOpen}
        data-test="tasks-activity-mobile-panel"
      >
        {relationType === "buyer" ? (
          <div css={arrowStyle} $buyer />
        ) : (
          <div css={breakStyle}>
            <div css={arrowStyle} />
          </div>
        )}
        <div css={tabContainer}>
          <Tabs noBorder>
            <ButtonTab
              onClick={() => setSelectedTab(TASKS)}
              selected={selectedTab === TASKS}
              count={taskCount}
            >
              Tasks
            </ButtonTab>
            <ButtonTab
              onClick={() => setSelectedTab(ACTIVITY)}
              selected={selectedTab === ACTIVITY}
            >
              Activity
            </ButtonTab>
          </Tabs>
          <button css={closeButtonStyle} onClick={mobileOnClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div css="width: 100vw;">
          {selectedTab === TASKS && (
            <MemoizedTaskPanelContent
              onClose={onClose}
              relationId={relationId}
              relationType={relationType}
            />
          )}
          {selectedTab === ACTIVITY && (
            <TimelinePanel
              relationId={relationId}
              relationType={relationType}
              activitiesExpanded={activitiesExpanded}
              setActivitiesExpanded={setActivitiesExpanded}
              ref={expandActivityButtonRef}
              noLimit
            />
          )}
        </div>
      </div>
    </>
  );
};
