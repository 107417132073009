import { withProps } from "recompose";
import { withGraphqlErrors } from "@nest-ui/hocs";

import { Add } from "@nest-ui/icons";
import { SecondaryButton } from "components/SecondaryButton";

export const NoSubmitCreateButton = withProps({
  icon: Add,
})(SecondaryButton);

export const CreateButton = withGraphqlErrors(NoSubmitCreateButton);
