// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import { Checkbox } from "components/Checkbox";
import { DatePicker } from "components/DatePicker";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const FRAGMENT_COOLING_OFF_PERIOD_SIGNOFF = gql`
  fragment CoolingOffPeriodSignoffFragment on NestDeal {
    id
    coolingOffPeriodReadyForSignoffDate
    isLeaseApproved
    isReportOnTitleReceivedByLegalOps
    isTitleApproved
  }
`;

const QUERY_COOLLING_OFF_PERIOD_SIGNOFF = gql`
  query CoolingOffPeriodSignoff($id: ID!) {
    nestDeal(id: $id) {
      id
      dealType {
        value
      }
      ...CoolingOffPeriodSignoffFragment
    }
  }
  ${FRAGMENT_COOLING_OFF_PERIOD_SIGNOFF}
`;

const UPDATE_COOLING_OFF_PERIOD_SIGNOFF = gql`
  mutation UpdateNestDealCoolingOffPeriodSignoff(
    $input: NestDealInput!
    $id: ID!
  ) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      ...CoolingOffPeriodSignoffFragment
    }
  }
  ${FRAGMENT_COOLING_OFF_PERIOD_SIGNOFF}
`;

const MutationConfig = (nestDeal, mutate) => (input) =>
  mutate({
    variables: {
      input,
      id: nestDeal.id,
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateNestDeal: {
        __typename: "NestDeal",
        id: nestDeal.id,
        ...nestDeal,
        ...input,
      },
    },
  });

type Props = { dealId: string, fieldLayout?: Array<string> };

export const CoolingOffPeriodSignoffContainer = ({ dealId }: Props) => (
  <ExtendedQuery
    query={QUERY_COOLLING_OFF_PERIOD_SIGNOFF}
    variables={{ id: dealId }}
  >
    {({ nestDeal }: ExtendedQueryRenderProps<CoolingOffPeriodSignoff>) => (
      <ExtendedMutation mutation={UPDATE_COOLING_OFF_PERIOD_SIGNOFF}>
        {(
          mutation: MutationFunction<
            UpdateNestDealCoolingOffPeriodSignoff,
            UpdateNestDealCoolingOffPeriodSignoffVariables,
          >,
        ) => (
          <CoolingOffPeriodSignoffComponent
            nestDeal={nestDeal}
            updateNestDeal={MutationConfig(nestDeal, mutation)}
          />
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);

const CoolingOffPeriodSignoffComponent = ({ nestDeal, updateNestDeal }) => (
  <Grid columns={2}>
    <LeafCell width={1}>
      <Checkbox
        label="DD scorecard prepared?"
        value={nestDeal.isReportOnTitleReceivedByLegalOps}
        mutation={updateNestDeal}
        data-test="cooling-off-period-signoff-report-title-received"
        property="isReportOnTitleReceivedByLegalOps"
        optionName="Yes"
      />
    </LeafCell>
    <LeafCell width={1}>
      <Checkbox
        label="Title approved?"
        value={nestDeal.isTitleApproved}
        mutation={updateNestDeal}
        data-test="cooling-off-period-signoff-is-title-approved"
        property="isTitleApproved"
        optionName="Yes"
      />
    </LeafCell>
    <LeafCell width={1} left={1}>
      <Checkbox
        label="Lease, ground rent & service charge approved (or N/A)?"
        value={nestDeal.isLeaseApproved}
        mutation={updateNestDeal}
        data-test="cooling-off-period-signoff-is-lease-approved"
        property="isLeaseApproved"
        optionName="Yes"
      />
    </LeafCell>
    <LeafCell width={1}>
      <DatePicker
        label="Actual legal DD period end date"
        value={nestDeal.coolingOffPeriodReadyForSignoffDate}
        mutation={updateNestDeal}
        data-test="cooling-off-period-ready-for-signoff-date"
        property="coolingOffPeriodReadyForSignoffDate"
      />
    </LeafCell>
  </Grid>
);
