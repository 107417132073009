// @flow

import { gql, useQuery, useMutation } from "@apollo/client";
import { useEffect, useState, useRef } from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { NakedTextField } from "components/TextField";
import { Form, Field } from "react-final-form";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { PropertyMatchModal } from "tabs/Interest/PotentialBuyers/PotentialBuyers";
import { Loader } from "components/Loader/Loader";
import {
  ModalWrapper,
  modalFooter,
  sendMessageButton,
} from "tabs/Interest/PotentialBuyers/MailoutModal/MailoutModal";
import { Button } from "@nested/nest/src/components/Button/Button";
import {
  EmailFormWrapper,
  subjectFieldStyles,
  previewButtonStyles,
  chooseBuyersButtonStyle,
  CustomMessageField,
} from "tabs/Interest/PotentialBuyers/MailoutModal/ComposeMessage";
import { css } from "styled-components";
import { AddCcEmailModal } from "tabs/Interest/BuyerOfferList/AddCcEmailModal";
import reloadDealStatus from "@nested/nest/src/pages/Deal/queries/reloadDealStatus.graphql";
import { MemoOfSalePreview } from "./MemoOfSalePreview";
import { MemoOfSaleSelectContacts } from "./MemoOfSaleSelectContacts";
import { useNotifications } from "../../../hooks/useNotifications";

const COMPOSE_MESSAGE = "compose-message";
const SELECT_CONTACTS = "select-contacts";
const ADD_SELLER_CONTACT = "add-seller-contact";
const ADD_BUYER_CONTACT = "add--buyer-contact";

const memoOfSaleSelectContactsStyle = css`
  width: 100%;
`;

export const BUYER_OFFER_QUERY = gql`
  query MemoOfSaleModalQuery($buyerOfferId: ID!) {
    buyerOffer(id: $buyerOfferId) {
      id
      solicitorLead
      solicitorEmail

      buyer {
        id
        contacts {
          id
          name
          emailAddresses
        }
      }

      deal {
        id
        leadSolicitor {
          name
          email
        }
        contacts {
          id
          name
          emailAddresses
        }
        address
      }
    }
  }
`;

export const GENERATE_MOS_MUTATION = gql`
  mutation MemoOfSaleModalCreatePdfMutation(
    $input: CreateBuyerOfferMemoOfSalePdfInput!
  ) {
    createBuyerOfferMemoOfSalePdf(input: $input) {
      downloadUrl
    }
  }
`;

export const SEND_MEMO_OF_SALE_EMAIL_MUTATION = gql`
  mutation SendMemoOfSaleEmail($input: SendMemoOfSaleEmailInput!) {
    sendMemoOfSaleEmail(input: $input) {
      success
    }
  }
`;

const initialValues = (address) => {
  return {
    selectedContacts: {
      "solicitor:buyer": true,
      "solicitor:seller": true,
    },
    subject: `${address} – Memorandum of Sale`,
    customMessage: "",
  };
};

type Props = {
  closeModal: () => void,
  buyerOfferId: string,
};

export const MemoOfSaleModal = ({ buyerOfferId, closeModal }: Props) => {
  return (
    <PropertyMatchModal isOpen closeModal={closeModal}>
      <ModalContent buyerOfferId={buyerOfferId} closeModal={closeModal} />
    </PropertyMatchModal>
  );
};

const ModalContent = ({ buyerOfferId, closeModal }) => {
  const [modalView, setModalView] = useState(COMPOSE_MESSAGE);

  const [memoFileURL, setMemoFileURL] = useState();

  const [formState, setFormState] = useState();

  const { createNotification } = useNotifications();
  const navigateToScreen = (state, screen) => {
    setFormState(state);
    setModalView(screen);
  };

  const { data, loading, error } = useQuery(BUYER_OFFER_QUERY, {
    variables: {
      buyerOfferId,
    },
  });

  const [generateMoS] = useMutation(GENERATE_MOS_MUTATION);

  const [sendEmail] = useMutation(SEND_MEMO_OF_SALE_EMAIL_MUTATION, {
    refetchQueries: [
      {
        query: reloadDealStatus,
        variables: { dealId: data?.buyerOffer?.deal?.id },
      },
    ],
  });

  useEffect(() => {
    const run = async () => {
      try {
        const result = await generateMoS({
          variables: {
            input: {
              buyerOfferId,
            },
          },
        });
        setMemoFileURL(result.data.createBuyerOfferMemoOfSalePdf.downloadUrl);
      } catch (e) {
        errorHandler(e);
        closeModal();
      }
    };

    run();
  }, [buyerOfferId]);

  if (!memoFileURL || loading) {
    return <Loader />;
  }

  if (error) {
    errorHandler(error);
    return null;
  }

  const getSelectedContacts = ({ selectedContacts }) =>
    Object.keys(selectedContacts).filter((k) => selectedContacts[k]);

  const onFormSubmit = async (state) => {
    const sendToBuyerSolicitor = state.selectedContacts["solicitor:buyer"];
    const sendToSellerSolicitor = state.selectedContacts["solicitor:seller"];

    const addedBuyerCcEmails = state.addedBuyerCcEmails || [];
    const addedSellerCcEmails = state.addedSellerCcEmails || [];

    const selectedSellerContacts = [...data.buyerOffer.deal.contacts]
      .filter((c) => state.selectedContacts[`seller-contact:${c.id}`])
      .map(({ id }) => id);
    const selectedSellerCcEmails = addedSellerCcEmails.filter(
      (c) => state.selectedContacts[`seller-cc-email:${c}`],
    );
    const selectedBuyerContacts = [...data.buyerOffer.buyer.contacts]
      .filter((c) => state.selectedContacts[`buyer-contact:${c.id}`])
      .map(({ id }) => id);
    const selectedBuyerCcEmails = addedBuyerCcEmails.filter(
      (c) => state.selectedContacts[`buyer-cc-email:${c}`],
    );

    const onlySellerCcEmailsSelected =
      selectedSellerCcEmails.length > 0 &&
      !sendToSellerSolicitor &&
      selectedSellerContacts.length === 0;

    const onlyBuyerCcEmailsSelected =
      selectedBuyerCcEmails.length > 0 &&
      !sendToBuyerSolicitor &&
      selectedBuyerContacts.length === 0;

    if (onlySellerCcEmailsSelected || onlyBuyerCcEmailsSelected) {
      /* eslint-disable no-alert */
      window.alert(
        "WARNING: Your memorandum of sale can't currently be sent. Please select a main contact on the seller OR buyer's side to send your memorandum.",
      );
      return;
    }

    const input = {
      sendToBuyerSolicitor,
      sendToSellerSolicitor,
      selectedSellerContacts,
      selectedBuyerContacts,
      selectedSellerCcEmails,
      selectedBuyerCcEmails,
      subject: state.subject,
      customMessage:
        state.customMessage === undefined ? "" : state.customMessage,
      buyerOfferId,
    };

    try {
      const result = await sendEmail({
        variables: {
          input,
        },
      });

      if (result?.data?.sendMemoOfSaleEmail?.success) {
        createNotification("Memorandum of Sale sent");
        closeModal();
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <ModalWrapper>
      <Form
        destroyOnUnregister={false}
        initialValues={
          formState || initialValues(data?.buyerOffer.deal.address)
        }
        onSubmit={onFormSubmit}
      >
        {({ handleSubmit, submitting, values = {} }) => {
          switch (modalView) {
            case COMPOSE_MESSAGE:
              return (
                <>
                  <ComposeMessage
                    closeModal={closeModal}
                    pdfUrl={memoFileURL}
                    onNext={() => navigateToScreen(values, SELECT_CONTACTS)}
                  />
                </>
              );

            case ADD_SELLER_CONTACT:
              return (
                <AddCcEmailModal
                  sellerOrBuyerColumn={"seller"}
                  onBack={() => navigateToScreen(values, SELECT_CONTACTS)}
                />
              );

            case ADD_BUYER_CONTACT:
              return (
                <AddCcEmailModal
                  sellerOrBuyerColumn={"buyer"}
                  onBack={() => navigateToScreen(values, SELECT_CONTACTS)}
                />
              );

            case SELECT_CONTACTS:
              return (
                <>
                  <div css={memoOfSaleSelectContactsStyle}>
                    <MemoOfSaleSelectContacts
                      onBack={() => navigateToScreen(values, COMPOSE_MESSAGE)}
                      data={data}
                      onAddSellerContactButtonClick={() =>
                        navigateToScreen(values, ADD_SELLER_CONTACT)
                      }
                      onAddBuyerContactButtonClick={() =>
                        navigateToScreen(values, ADD_BUYER_CONTACT)
                      }
                    />
                  </div>
                  <div css={modalFooter}>
                    <Button
                      buttonStyle="pink"
                      css={sendMessageButton}
                      onClick={handleSubmit}
                      data-test="email-send-button"
                      large
                      disabled={
                        submitting || getSelectedContacts(values).length === 0
                      }
                    >
                      {submitting
                        ? "Sending..."
                        : `Send email to ${
                            getSelectedContacts(values).length
                          } contacts`}
                    </Button>
                  </div>
                </>
              );
            // ESLint: Expected a default case
            default:
              return null;
          }
        }}
      </Form>
    </ModalWrapper>
  );
};

const ComposeMessage = ({ closeModal, onNext, pdfUrl }) => {
  const formRef = useRef(null);
  const previewRef = useRef(null);
  return (
    <>
      <EmailFormWrapper
        title="Memorandum of Sale"
        onBack={closeModal}
        onCancel={closeModal}
        ref={formRef}
      >
        <div>
          <div>
            <Field name="subject">
              {({ input, meta }) => (
                <NakedTextField
                  {...input}
                  {...meta}
                  css={subjectFieldStyles}
                  hasErrors={false}
                  maxLength={80}
                  label="Email subject"
                  errors={meta.error}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="customMessage">
              {({ input, meta }) => (
                <CustomMessageField
                  {...input}
                  {...meta}
                  multiline
                  label="Custom message"
                  minRows={5}
                />
              )}
            </Field>
          </div>
        </div>
        <div>
          <Button
            icon={faEye}
            buttonStyle="outline"
            large
            css={previewButtonStyles}
            onClick={() =>
              previewRef.current?.scrollIntoView({
                behaviour: "smooth",
                block: "end",
              })
            }
          >
            Preview message
          </Button>
          <Button
            buttonStyle="pink"
            data-test="choose-contacts-desktop-button"
            onClick={onNext}
            css={chooseBuyersButtonStyle}
            large
          >
            Next: Choose contacts
          </Button>
        </div>
      </EmailFormWrapper>
      <MemoOfSalePreview
        pdfUrl={pdfUrl}
        refs={{ formRef, previewRef }}
        onNext={onNext}
      />
    </>
  );
};
