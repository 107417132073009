// @flow
import moment from "moment-timezone";
import { useState } from "react";
import { type MutationFunction } from "@apollo/client/react/components";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  CancelIcon,
  ExternalLink,
  Tick,
  CirclingArrows,
  Pencil,
} from "@nest-ui/icons";
import {
  CancelButton,
  DangerButton,
  PrimaryButton,
} from "@nest-ui/sellers-nest/components/Buttons";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid/Grid";
import { NoSubmitTextField } from "@nest-ui/sellers-nest/components/TextField";
import { NoSubmitSelectField } from "@nest-ui/sellers-nest/components/SelectField/SelectField";
import { ViewingCardContainer } from "@nest-ui/sellers-nest/tabs/Interest/Viewings";
import { A } from "@nest-ui/sellers-nest/components/A/A";

import {
  isViewingActionRequired,
  type AssociatedSas,
  type ViewingAgentOptions,
} from "@nest-ui/sellers-nest/tabs/Interest/Viewings/utility-functions";

import { getConfig } from "@nested/config";

import { ViewingStatus } from "./ViewingStatus";
import { ViewingFeedback } from "./ViewingFeedback";
import { ViewingAgentSelect } from "./ViewingAgentSelect";
import { FollowUpFeedback } from "./FollowUpFeedback";

export const BaseButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-family: Euclid;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 20px;
  outline: none;
  padding: 0 16px 0 0;
  text-align: left;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const ConstructiveActionButton = styled(BaseButton)`
  color: #10b186;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const ConfirmViewingButton = styled(BaseButton)`
  color: #10b186;
`;

const CancelViewingButton = styled(BaseButton)`
  color: ${({ theme }) => theme.color.background.danger};

  svg {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
`;

const GridWithTransparencyOption = styled(Grid)`
  ${({ isRescheduling }) => isRescheduling && `opacity: 0.5;`}
`;

const ViewingDetails = styled(LeafCell)`
  flex-direction: column;
`;

const ViewingAppLink = styled(Link)`
  color: #10b186;
  margin-top: 18px;
  text-decoration: none;
  font: 600 12px Euclid;

  svg {
    position: relative;
    top: 2px;
    right: 4px;
  }
`;

const ViewingTime = styled.p`
  margin-top: 13px;
  margin-bottom: 0;
`;

const SexyDangerButton = styled(DangerButton)`
  margin: 16px 16px 0px 0px;
`;

const CustomerMode = styled.div`
  color: #10b186;
  cursor: pointer;
  display: flex;
  font: 600 12px Euclid;
  margin: 20px 0 0 5px;
  text-decoration: none;

  svg {
    position: relative;
    right: 4px;
  }
`;

const viewingCardContainer = css`
  ${({ displayOnTheMarketBanner }) =>
    displayOnTheMarketBanner &&
    css`
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin: 0;
    `}
`;

const buyerOnMarketBanner = css`
  display: flex;
  margin-top: 16px;
`;

const moneyFace = css`
  background-color: ${({ theme }) => theme.color.background.hague};
  border-top-left-radius: 4px;
  font-size: 24px;
  padding: 6px 8px;
`;

const buyerOnMarketText = css`
  background-color: ${({ theme }) => theme.color.background.hague90};
  border-top-right-radius: 4px;
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 8px;
  width: 100%;
`;

const viewingInPast = (startTime) => {
  const start = moment(startTime);
  return moment().diff(start) > 0;
};

const BuyerOnMarketBanner = () => {
  return (
    <div css={buyerOnMarketBanner}>
      <span role="img" aria-label="Money mouth" css={moneyFace}>
        🤑
      </span>
      <div css={buyerOnMarketText}>This buyer is on the market</div>
    </div>
  );
};

const { ACCOUNT_URI } = getConfig();

type RearrangedViewingInput = {|
  notes: ?string,
  viewingCancellationReasonId: string,
|};

type UpdateViewingInput =
  | {| feedbackSharedWithSeller: boolean |}
  | {| firstImpressionsSharedWithSeller: boolean |}
  | {| notes: ?string |}
  | {| virtual: boolean |}
  | {| viewingFeedbackFirstImpressions: ?string |}
  | {| viewingFeedbackFull: ?string |}
  | ViewingAgentOptions;

type Props = {|
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  addRearrangedViewingInput: (
    rearrangedViewingInput: RearrangedViewingInput,
  ) => void,
  associatedSas: AssociatedSas,
  buyerContacts: $ReadOnlyArray<buyerPropertyInterestFields_buyer_contacts>,
  cancelViewingMutation: MutationFunction<CancelViewing>,
  externalDealId: string,
  buyerIsOnTheMarket: boolean,
  isRearrangingViewing: boolean,
  rearrangedViewingInput: ?RearrangedViewingInput,
  startRearranging: (viewingId: string) => void,
  stopRearranging: () => void,
  updateViewingMutation: MutationFunction<UpdateViewing>,
  vendorContacts: $ReadOnlyArray<buyerPropertyInterestFields_deal_contacts>,
  viewing: PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings,
  viewingCancellationReasons: $ReadOnlyArray<PropertyInterestsByBuyer_viewingCancellationReasons>,
  setSaModalOpen: () => void,
  subAgents: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_deal_subAgents>,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
|};

export const ViewingCard = ({
  activeNestedUsers,
  addRearrangedViewingInput,
  associatedSas,
  buyerContacts,
  cancelViewingMutation,
  externalDealId,
  buyerIsOnTheMarket,
  isRearrangingViewing,
  rearrangedViewingInput,
  setSaModalOpen,
  startRearranging,
  stopRearranging,
  subAgents,
  updateViewingMutation,
  vendorContacts,
  viewing,
  viewingAssistants,
  viewingCancellationReasons,
}: Props) => {
  const {
    datetimeConfirmedWithVendor,
    datetimeFeedbackSharedWithSeller,
    datetimeFirstImpressionsSharedWithSeller,
    datetimeViewingEnds,
    datetimeViewingStarts,
    id: viewingId,
    notes,
    viewingCancellationReasonId,
    viewingFeedbackFull,
    viewingSaId,
    virtual,
  } = viewing;

  const [isCancellingViewing, setIsCancellingViewing] = useState(false);
  const [buyerFeedback, setBuyerFeedback] = useState(
    rearrangedViewingInput?.notes,
  );
  const [cancellationReasonId, setCancellationReasonId] = useState(
    rearrangedViewingInput?.viewingCancellationReasonId,
  );
  const [showViewingFeedback, setShowViewingFeedback] = useState(
    viewingInPast(datetimeViewingStarts) && !viewingCancellationReasonId,
  );

  const cancelViewing = () => {
    const input = {
      notes: buyerFeedback,
      viewingCancellationReasonId: cancellationReasonId,
    };

    cancelViewingMutation({
      variables: {
        id: viewingId,
        input,
      },
      optimisticResponse: {
        __typename: "Mutation",
        cancelViewing: {
          __typename: "Viewing",
          id: viewingId,
          ...input,
        },
      },
    });

    setIsCancellingViewing(false);
  };

  const confirmViewing = () => {
    const input = { confirmedWithVendor: true };

    const pastViewingText = () =>
      "We won't contact the buyer or seller because this viewing is in the past.";

    const futureViewingText = () => {
      const getEmailsForContact =
        (contactType) =>
        ({ name, emailAddresses }) =>
          emailAddresses.map(
            (email) =>
              `${name || "[Name is missing]"} - ${contactType} (${email})`,
          );

      const vendorEmailsPerContact = vendorContacts
        .filter(({ viewingCommsEnabled }) => viewingCommsEnabled)
        .map(getEmailsForContact("Seller"));

      const buyerEmailsPerContact = buyerContacts.map(
        getEmailsForContact("Buyer"),
      );

      return `This will email these contacts to confirm the viewing:\n
        ${vendorEmailsPerContact
          .map((contactEmails) => contactEmails.join("\n        "))
          .join("\n        ")}
        ${buyerEmailsPerContact
          .map((contactEmails) => contactEmails.join("\n        "))
          .join("\n        ")}
      `;
    };

    const warningMessage = moment(datetimeViewingStarts).isAfter()
      ? futureViewingText()
      : pastViewingText();

    // eslint-disable-next-line no-alert
    if (window.confirm(warningMessage)) {
      if (viewingSaId !== associatedSas.buyerPropertyInterestSa?.closeUserId) {
        setSaModalOpen();
      }

      return updateViewingMutation({
        variables: {
          id: viewingId,
          input,
        },
      });
    }

    return undefined;
  };

  const startCancelling = () => {
    setBuyerFeedback(null);
    setIsCancellingViewing(true);
    setCancellationReasonId(null);
  };

  const startArrangingNewViewing = () => {
    if (!cancellationReasonId) {
      return;
    }

    addRearrangedViewingInput({
      notes: buyerFeedback,
      viewingCancellationReasonId: String(cancellationReasonId),
    });
  };

  const updateViewing = (input: UpdateViewingInput) => {
    if (input?.feedbackSharedWithSeller && !viewing.viewingFeedbackFull) {
      // eslint-disable-next-line no-alert
      window.alert("Please add full feedback before sharing with the vendor.");
      return null;
    }

    return updateViewingMutation({
      variables: {
        id: viewing.id,
        input,
      },
      optimisticResponse: {
        updateViewing: {
          __typename: "Viewing",
          ...viewing,
          ...input,
          datetimeFeedbackSharedWithSeller: input.feedbackSharedWithSeller
            ? moment().toString()
            : viewing.datetimeFeedbackSharedWithSeller,
          datetimeFirstImpressionsSharedWithSeller:
            input.firstImpressionsSharedWithSeller
              ? moment().toString()
              : viewing.datetimeFirstImpressionsSharedWithSeller,
        },
      },
    });
  };

  const isConfirmedWithVendor = datetimeConfirmedWithVendor !== null;

  const hasViewingFeedback = Boolean(viewingFeedbackFull);

  const availableCancellationReasons = isConfirmedWithVendor
    ? viewingCancellationReasons.filter((r) => r.showForConfirmedViewings)
    : viewingCancellationReasons;

  const cancellationReasons = availableCancellationReasons
    .filter((r) => !r.showWhenRearranging)
    .map((r) => ({
      label: r.reason,
      value: r.id,
    }));

  const rearrangementReasons = viewingCancellationReasons
    .filter((r) => r.showWhenRearranging)
    .map((r) => ({
      label: r.reason,
      value: r.id,
    }));

  const hasNotes = Boolean(notes);
  const hasViewingTimePassed = moment().isAfter(datetimeViewingEnds);

  const viewingCancellationReasonLabel =
    viewingCancellationReasons.find((r) => r.id === viewingCancellationReasonId)
      ?.reason || null;

  const updateViewingAgent = (input: ViewingAgentOptions) => {
    if (
      isConfirmedWithVendor &&
      input.viewingSaId !== associatedSas.buyerPropertyInterestSa?.closeUserId
    ) {
      setSaModalOpen();
    }
    updateViewing(input);
  };

  const displayOnTheMarketBanner =
    Boolean(!datetimeFeedbackSharedWithSeller) && buyerIsOnTheMarket;

  return (
    <>
      {displayOnTheMarketBanner && (
        <BuyerOnMarketBanner data-test="viewings:on-the-market-banner" />
      )}
      <ViewingCardContainer
        data-test={`viewings:viewing-card-${viewingId}`}
        hasAlert={isViewingActionRequired(viewing)}
        displayOnTheMarketBanner={displayOnTheMarketBanner}
        css={viewingCardContainer}
      >
        <GridWithTransparencyOption
          columns={4}
          isRescheduling={isRearrangingViewing}
        >
          <ViewingDetails width={2}>
            <ViewingStatus
              data-test={`viewings:viewing-description-${viewingId}`}
              hasBuyerFeedback={hasViewingFeedback}
              hasViewingTimePassed={hasViewingTimePassed}
              isConfirmedWithVendor={isConfirmedWithVendor}
              viewingCancellationReasonLabel={viewingCancellationReasonLabel}
              virtual={virtual}
            />
            <ViewingTime data-test="viewings:viewing-time">
              {`${moment(datetimeViewingStarts).format(
                "DD/MM/YYYY",
              )} at ${moment(datetimeViewingStarts).format(
                "h:mma",
              )} to ${moment(datetimeViewingEnds).format("h:mma")}`}
            </ViewingTime>
          </ViewingDetails>
          <LeafCell width={2} style={{ alignItems: "flex-start" }}>
            <ViewingAgentSelect
              activeNestedUsers={activeNestedUsers}
              associatedSas={associatedSas}
              data-test="viewings:agent"
              disabled={
                hasNotes ||
                Boolean(viewingCancellationReasonId) ||
                isRearrangingViewing
              }
              label="Agent"
              onSubmit={updateViewingAgent}
              property="viewingAgentInput"
              subAgents={subAgents}
              viewingAgentOptions={{
                conductor: viewing.conductor,
                viewingSubAgentId: viewing.viewingSubAgentId,
                viewingSaId: viewing.viewingSaId,
                nestedUserId: viewing.nestedUserId,
              }}
              viewingAssistants={viewingAssistants}
            />
          </LeafCell>
        </GridWithTransparencyOption>
        {viewingCancellationReasonId && (
          <NoSubmitTextField
            data-test={`viewings:buyer-feedback-${viewingId}`}
            disabled
            label="Notes"
            value={notes || "-"}
          />
        )}

        {showViewingFeedback && (
          <div data-test={`viewings:buyer-feedback-section-${viewingId}`}>
            <ViewingFeedback
              externalDealId={externalDealId}
              feedbackSharedWithSeller={Boolean(
                datetimeFeedbackSharedWithSeller,
              )}
              firstImpressionsSharedWithSeller={Boolean(
                datetimeFirstImpressionsSharedWithSeller,
              )}
              updateViewing={updateViewing}
              viewing={viewing}
              viewingId={viewingId}
            />
            <FollowUpFeedback viewing={viewing} />
          </div>
        )}

        {(datetimeFeedbackSharedWithSeller ||
          datetimeFirstImpressionsSharedWithSeller) && (
          <CustomerMode>
            <ExternalLink fill="#10b186" />
            <A
              href={`${ACCOUNT_URI}/listing/viewings/viewing-feedback?deal=${externalDealId}`}
            >
              Customer account
            </A>
          </CustomerMode>
        )}

        {isCancellingViewing && (
          <>
            <Grid columns={2}>
              <NoSubmitSelectField
                data-test={`viewings:select-cancellation-reason-${viewingId}`}
                label="Cancellation reason"
                onSubmit={setCancellationReasonId}
                options={cancellationReasons}
                value={cancellationReasonId}
              />
              <NoSubmitTextField
                data-test={`viewings:notes-input-${viewingId}`}
                label="Notes"
                multiline
                onSubmit={setBuyerFeedback}
                value={buyerFeedback}
              />
            </Grid>
            <ButtonGroup>
              <SexyDangerButton
                disabled={!cancellationReasonId}
                onClick={cancelViewing}
                data-test={`viewings:cancel-viewing-${viewingId}`}
              >
                <Tick fill="#fff" />
                Cancel viewing
              </SexyDangerButton>

              <CancelButton onClick={() => setIsCancellingViewing(false)}>
                Cancel
              </CancelButton>
            </ButtonGroup>
          </>
        )}
        {isRearrangingViewing && (
          <>
            <Grid columns={2}>
              <NoSubmitSelectField
                data-test={`viewings:select-cancellation-reason-${viewingId}`}
                label="Reason for rescheduling"
                onSubmit={setCancellationReasonId}
                options={rearrangementReasons}
                value={cancellationReasonId}
              />
              <NoSubmitTextField
                data-test={`viewings:notes-input-${viewingId}`}
                label="Notes"
                multiline
                onSubmit={setBuyerFeedback}
                value={buyerFeedback}
              />
            </Grid>
            <ButtonGroup>
              <PrimaryButton
                disabled={!cancellationReasonId}
                icon={Tick}
                onClick={startArrangingNewViewing}
                data-test={`viewings:continue-rearranging-${viewingId}`}
              >
                Save
              </PrimaryButton>

              <CancelButton onClick={stopRearranging}>Cancel</CancelButton>
            </ButtonGroup>
          </>
        )}
        {!isCancellingViewing && !isRearrangingViewing && (
          <ButtonGroup data-test="button-group">
            {!isConfirmedWithVendor && !notes && !viewingCancellationReasonId && (
              <ConfirmViewingButton
                onClick={confirmViewing}
                data-test={`viewings:confirm-viewing-${viewingId}`}
              >
                <Tick fill="#10b186" />
                Confirm viewing
              </ConfirmViewingButton>
            )}

            {!datetimeFeedbackSharedWithSeller && !viewingCancellationReasonId && (
              <>
                <ConstructiveActionButton
                  onClick={() => startRearranging(viewingId)}
                  data-test={`viewings:start-viewing-rearrangment-${viewingId}`}
                >
                  <CirclingArrows />
                  Reschedule viewing
                </ConstructiveActionButton>
                <CancelViewingButton
                  data-test={`viewings:start-viewing-cancellation-${viewingId}`}
                  onClick={startCancelling}
                >
                  <CancelIcon />
                  Cancel / no-show
                </CancelViewingButton>
                <ViewingAppLink
                  to={`/buyers/${viewing.buyerPropertyInterest.buyer.id}/bpi/${viewing.buyerPropertyInterest.id}`}
                >
                  <ExternalLink fill="#10b186" />
                  Open viewing app
                </ViewingAppLink>
              </>
            )}

            {!showViewingFeedback && (
              <ConstructiveActionButton
                onClick={() => setShowViewingFeedback(true)}
                data-test={`viewings:add-viewing-feedback-${viewingId}`}
              >
                <Pencil fill="#10b186" />
                Add feedback
              </ConstructiveActionButton>
            )}
          </ButtonGroup>
        )}
      </ViewingCardContainer>
    </>
  );
};
