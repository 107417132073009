// @flow
import { gql } from "@apollo/client";

import { type MutationFunction } from "@apollo/client/react/components";

import { Section } from "components/DealDetailsBar";
import { Grid, LeafCell } from "components/Grid";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { SelectField } from "components/SelectField";
import { TextField } from "components/TextField";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ProposedCustomerEligibility } from "tabs/AdvanceValuationsAndLegal/LegalServices/sections/ProposedCustomerEligibility/ProposedCustomerEligibility";

const TEAM_NOTES_FRAGMENT = gql`
  fragment TeamNotesFragment on NestDeal {
    id
    guaranteeNotes
    snPropertyAndMarketabilityNotes
    snCustomerNotes
    rmPropertyNotes
    rmCustomerNotes
    vaPropertyNotes
    propertyNotes
    onwardPurchaseStatus {
      label
      value
    }
  }
`;

const TEAM_NOTES_QUERY = gql`
  query TeamNotesQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      ...TeamNotesFragment
    }
    onwardPurchaseStatuses {
      label
      value
    }
  }
  ${TEAM_NOTES_FRAGMENT}
`;

const TEAM_NOTES_SIMPLE_QUERY = gql`
  query TeamNotesSimpleQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      ...TeamNotesFragment
    }
  }
  ${TEAM_NOTES_FRAGMENT}
`;

const TEAM_NOTES_MUTATION = gql`
  mutation UpdateNestDealTeamNotes($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      ...TeamNotesFragment
    }
  }
  ${TEAM_NOTES_FRAGMENT}
`;

const EditableContent = ({ dealId }) => (
  <ExtendedQuery query={TEAM_NOTES_QUERY} variables={{ id: dealId }}>
    {({
      nestDeal,
      onwardPurchaseStatuses,
    }: ExtendedQueryRenderProps<TeamNotesQuery>) => (
      <ExtendedMutation mutation={TEAM_NOTES_MUTATION}>
        {(
          updateNestDeal: MutationFunction<
            UpdateNestDealTeamNotes,
            UpdateNestDealTeamNotesVariables,
          >,
        ) => (
          <Grid columns={4}>
            <LeafCell width={2}>
              <SelectField
                label="Onward purchase status"
                value={nestDeal.onwardPurchaseStatus}
                property="onwardPurchaseStatus"
                mutation={(input) => {
                  const onwardPurchaseStatus = onwardPurchaseStatuses.find(
                    ({ value }) => value === input.onwardPurchaseStatus,
                  );
                  return updateNestDeal({
                    variables: {
                      input,
                      id: nestDeal.id,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        __typename: "NestDeal",
                        ...nestDeal,
                        onwardPurchaseStatus,
                      },
                    },
                  });
                }}
                data-test="team-notes-onward-purchase-status"
                options={onwardPurchaseStatuses}
              />
            </LeafCell>
          </Grid>
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);

const ReadOnlyContent = ({ dealId }) => (
  <ExtendedQuery query={TEAM_NOTES_SIMPLE_QUERY} variables={{ id: dealId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<TeamNotesSimpleQuery>) => (
      <Grid columns={2}>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="SDA property & marketability notes"
            value={nestDeal.propertyNotes}
            readOnly
            data-test="team-notes-readonly-sda-property-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="VA property & marketability notes"
            value={nestDeal.vaPropertyNotes}
            readOnly
            data-test="team-notes-readonly-va-property-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="RM property & marketability notes"
            value={nestDeal.rmPropertyNotes}
            readOnly
            data-test="team-notes-readonly-rm-property-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="RM customer notes"
            value={nestDeal.rmCustomerNotes}
            readOnly
            data-test="team-notes-readonly-rm-customer-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="SA property & marketability notes"
            value={nestDeal.snPropertyAndMarketabilityNotes}
            readOnly
            data-test="team-notes-readonly-sa-property-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="SA customer notes"
            value={nestDeal.snCustomerNotes}
            readOnly
            data-test="team-notes-readonly-sa-customer-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            label="Guarantee notes"
            value={nestDeal.guaranteeNotes}
            readOnly
            data-test="team-notes-readonly-guarantee-notes"
          />
        </LeafCell>
        <LeafCell width={2}>
          <ProposedCustomerEligibility dealId={dealId} readOnly />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Onward purchase status"
            value={nestDeal.onwardPurchaseStatus?.label}
            readOnly
            data-test="team-notes-readonly-onward-purchase-status"
          />
        </LeafCell>
      </Grid>
    )}
  </ExtendedQuery>
);

export const TeamNotes = (props: {| dealId: string |}) => (
  <Section
    title="Team notes"
    readOnlyContent={() => ReadOnlyContent(props)}
    editableContent={() => EditableContent(props)}
    {...props}
  />
);
