import householdCostsQuery from "../queries/householdCosts.graphql";

export const deleteConfig = {
  props({ mutate, ownProps }) {
    return {
      deleteHouseholdCost: (householdCostId) =>
        mutate({
          variables: {
            id: householdCostId,
          },
          refetchQueries: [
            {
              query: householdCostsQuery,
              variables: {
                id: ownProps.parentId,
              },
            },
          ],
        }),
      ...ownProps,
    };
  },
};
