// @flow
import type { MutationFunction } from "@apollo/client/react/components";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitPercentageField } from "components/PercentageField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { userNameFromCloseId } from "@nested/utils/graphql/users/users";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { DeleteValuation } from "./DeleteValuation";
import { UPDATE_ADVANCE_VALUATION_MUTATION } from "./advanceOfferValuationMutations";

const EditableNote = ({ id, notes }: { id: string, notes: string }) => (
  <ExtendedMutation mutation={UPDATE_ADVANCE_VALUATION_MUTATION}>
    {(
      mutation: MutationFunction<
        UpdateAdvanceValuation,
        UpdateAdvanceValuationVariables,
      >,
    ) => {
      const updateValuation = (input) =>
        mutation({
          variables: {
            advanceValuationId: id,
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateAdvanceOfferValuationOct18: {
              __typename: "AdvanceOfferValuationOct18",
              id,
              ...input,
            },
          },
        });
      return (
        <NoSubmitTextareaWithLinks
          data-test={`advance-valuation-list-notes:${id}`}
          label="Notes"
          value={notes}
          property="notes"
          onSubmit={(val) => updateValuation({ notes: val })}
        />
      );
    }}
  </ExtendedMutation>
);

export const AdvanceOfferValuationOct18 = ({
  reasonsForAdvanceOfferValuation = [],
  valuation: {
    advanceAmount,
    advancePercentage,
    dateCompleted,
    completedBy,
    dealId,
    expectedSalePrice,
    expiryDate,
    id,
    issuingAdvance,
    notes,
    reasonForAdvanceOfferValuationId,
    recommendedListingPrice,
    signedOffBy,
    advanceValuationTrigger,
  },
  users,
  isReadOnly,
}: *) => (
  <Grid columns={4}>
    <LeafCell width={2}>
      <NoSubmitDatePicker
        data-test="valuation-list-valuation-date"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Valuation Date"
        value={dateCompleted}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitDatePicker
        data-test="valuation-list-expiry-date"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Expiry Date"
        value={expiryDate}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-reason-for-advance-offer-valuation"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Reason for advance offer valuation"
        value={
          reasonsForAdvanceOfferValuation.find(
            (reason) => reason.id === reasonForAdvanceOfferValuationId,
          )?.label || "--"
        }
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-advance-valuation-trigger"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Advance valuation trigger"
        value={advanceValuationTrigger?.label || "--"}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-completed-by"
        label="Completed By"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        value={userNameFromCloseId(completedBy, users)}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-signed-off-by"
        label="Signed Off By"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        value={userNameFromCloseId(signedOffBy, users)}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-recommended-listing-price"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Updated List Price"
        value={recommendedListingPrice}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-expected-sale-price"
        readOnly={isReadOnly}
        disabled={!isReadOnly}
        label="Expected Sale Price"
        value={expectedSalePrice}
      />
    </LeafCell>

    {issuingAdvance ? (
      <>
        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="valuation-list-advance-amount"
            readOnly={isReadOnly}
            disabled={!isReadOnly}
            label="Advance Amount"
            value={advanceAmount}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitPercentageField
            data-test="valuation-list-advance-percent"
            label="Advance %"
            value={advancePercentage}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>
      </>
    ) : (
      <LeafCell width={4}>
        <NoSubmitTextareaWithLinks
          value="No Advance Offered!"
          readOnly={isReadOnly}
          disabled={!isReadOnly}
        />
      </LeafCell>
    )}

    <LeafCell width={4}>
      {isReadOnly ? (
        <NoSubmitTextareaWithLinks
          data-test={`advance-valuation-list-notes:${id}`}
          label="Notes"
          readOnly={isReadOnly}
          disabled={!isReadOnly}
          value={notes}
        />
      ) : (
        <EditableNote id={id} notes={notes} />
      )}
    </LeafCell>

    {!isReadOnly && (
      <LeafCell width={4}>
        <DeleteValuation
          valuationType="ADVANCE_OFFER_VALUATION_OCT_18"
          id={id}
          dealId={dealId}
        />
      </LeafCell>
    )}
  </Grid>
);
