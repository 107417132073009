// @flow
import React from "react";

import { NoSubmitCreateButton } from "components/CreateButton";
import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { filterValuationsByType } from "@nested/utils/graphql/valuations/valuationHelpers";
import { AlignRight } from "components/AlignRight";
import { ErrorBoundary } from "components/ErrorBoundary";
import {
  LocalStorageEngine,
  LocalCache,
} from "components/LocalCache/LocalCache";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ValuationsList } from "../Valuations/ValuationsList";
import { NoValuations } from "../Valuations/NoValuations";
import { CreatePostVisitValuation } from "./CreatePostVisitValuation";
import { VALUATIONS_QUERY, CREATE_VALUATION_MUTATION } from "./valuationsQuery";

type State = {
  showCreateEntry: boolean,
};
type Props = {
  parentId: string,
};

export class PostVisitValuationsList extends React.Component<Props, State> {
  state = {
    showCreateEntry: false,
  };

  enableCreating = () => {
    this.setState({
      showCreateEntry: true,
    });
  };

  disableCreating = () => {
    this.setState({
      showCreateEntry: false,
    });
  };

  render() {
    const { parentId } = this.props;

    return (
      <ErrorBoundary>
        <ExtendedQuery
          query={VALUATIONS_QUERY}
          variables={{ dealId: parentId }}
        >
          {({
            nestDeal: { id: dealId, valuationList },
            activeNestedUsers,
          }: ExtendedQueryRenderProps<PostVisitValuationsListNestDealQuery>) => {
            const postVisitValuationList = filterValuationsByType(
              valuationList,
              ["PostVisitValuationPreDec18", "PostVisitValuationDec18"],
            );

            const showValuationsList = postVisitValuationList.length > 0;
            const noValuations = !showValuationsList;

            return (
              <LocalCache
                storageEngine={LocalStorageEngine}
                cacheKey={`createPostVisitVal:${dealId}`}
                render={({
                  cachedState,
                  hasState,
                  updateCache,
                  clearCache,
                }) => {
                  const isEditing = this.state.showCreateEntry || hasState;

                  return (
                    <ExtendedMutation
                      awaitRefetchQueries
                      mutation={CREATE_VALUATION_MUTATION}
                      refetchQueries={[
                        {
                          query: VALUATIONS_QUERY,
                          variables: { dealId },
                        },
                      ]}
                    >
                      {(mutate) => {
                        const createValuation = async () => {
                          const result = await mutate({
                            variables: {
                              input: cachedState,
                              dealId,
                            },
                          });

                          if (result) {
                            this.disableCreating();
                            clearCache();
                          }
                        };

                        const deleteValuation = () => {
                          this.disableCreating();
                          clearCache();
                        };

                        return (
                          <div style={{ minHeight: "377px" }}>
                            <Grid columns={4} rowGap="0px">
                              <LeafCell width={2}>
                                <H2>Post Visit Valuations</H2>
                              </LeafCell>

                              {showValuationsList && (
                                <LeafCell width={2}>
                                  <AlignRight>
                                    <NoSubmitCreateButton
                                      data-test="post-visit-valuations:create-valuation"
                                      disabled={isEditing}
                                      onSubmit={this.enableCreating}
                                      label="Add New Post Visit Valuation"
                                    />
                                  </AlignRight>
                                </LeafCell>
                              )}

                              {isEditing && (
                                <LeafCell width={4}>
                                  <CreatePostVisitValuation
                                    valuation={cachedState}
                                    updateState={updateCache}
                                    users={activeNestedUsers.map(
                                      ({ closeUserId, fullName }) => ({
                                        value: closeUserId,
                                        label:
                                          fullName || "Undefined user name",
                                      }),
                                    )}
                                    dealId={dealId}
                                    onSubmit={createValuation}
                                    onCancel={deleteValuation}
                                  />
                                </LeafCell>
                              )}

                              {showValuationsList && (
                                <LeafCell width={4}>
                                  <ValuationsList
                                    dealId={dealId}
                                    users={activeNestedUsers}
                                    valuations={postVisitValuationList}
                                  />
                                </LeafCell>
                              )}

                              {noValuations && !isEditing && (
                                <LeafCell width={4}>
                                  <NoValuations
                                    valuationType="postVisit"
                                    enableCreating={this.enableCreating}
                                  />
                                </LeafCell>
                              )}
                            </Grid>
                          </div>
                        );
                      }}
                    </ExtendedMutation>
                  );
                }}
              />
            );
          }}
        </ExtendedQuery>
      </ErrorBoundary>
    );
  }
}
