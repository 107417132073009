// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";
import { useApolloClient } from "@apollo/client/react/hooks";
import { AsyncSelectField } from "components/SelectField/AsyncSelectField";

const POSTCODES_QUERY = gql`
  query Postcodes($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      normalisedPostcode
      addresses {
        id
        label
        postcode
      }
    }
  }
`;

type Props = {
  onBlur?: (Object) => Promise<Object>,
  name: string,
  value?: Object,
  onChange: (Object) => Promise<Object>,
  label: string,
};

const SelectField = styled(AsyncSelectField)`
  .react-select__control {
    border-radius: 0px;
    border: 2px solid black;
  }
`;

export const AddressPicker = ({
  label: inputLabel,
  onBlur,
  value,
  onChange,
}: Props) => {
  const client = useApolloClient();

  return (
    <SelectField
      label={inputLabel}
      data-test="address-picker-component"
      noOptionsMessage={() => "Please enter a valid postcode"}
      onChange={(event) => {
        const updatedState = {
          addressId: event.value,
          addressText: event.label,
        };
        onChange(updatedState);
      }}
      onBlur={onBlur}
      value={
        value?.addressId
          ? {
              value: value?.addressId,
              label: value?.addressText,
            }
          : {
              value: "",
              label: "Enter postcode",
            }
      }
      loadOptions={async (inputValue) => {
        /**
         * $FlowFixMe is here because ApolloClient.query does not like
         * variables being an object. We need to work out how to call
         * this function properly, but we needed to get this feature out
         * and this was a but of a rabbit hole.
         *
         * Other people have experienced the same issue, lets come back
         * to this when we have time
         */
        const {
          data: {
            postcodeDetails: { addresses },
          },
          // $FlowFixMe
        } = await client.query({
          query: POSTCODES_QUERY,
          variables: {
            postcode: inputValue,
          },
        });
        return addresses.map(({ id, label, postcode }) => ({
          label: `${label}, ${postcode}`,
          value: id,
        }));
      }}
    />
  );
};
