// @flow
import { gql } from "@apollo/client";
import React from "react";

import { Card } from "components/Card";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { H2 } from "components/Heading";
import { Modal } from "components/Modal";
import { TextArea } from "components/TextArea";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const EXPERIMENTAL_HASHTAGS_QUERY = gql`
  query ExperimentalHashtagsQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      hashtags
    }
  }
`;

const UPDATE_HASHTAGS = gql`
  mutation UpdateHashtags($dealId: ID!, $input: NestDealInput!) {
    updateNestDeal(nestDeal: $input, id: $dealId) {
      id
      hashtags
    }
  }
`;

type Props = {
  dealId: string,
};

type State = {
  editing: boolean,
};

export class Experiments extends React.Component<Props, State> {
  state = {
    editing: false,
  };

  closeModal = () => this.setState({ editing: false });

  openModal = () => this.setState({ editing: true });

  render() {
    const { dealId } = this.props;
    const { editing } = this.state;

    return (
      <Card data-test="experiments-section">
        <H2>Experiments</H2>
        <EditButton
          onClick={this.openModal}
          data-test="deal-details-edit-experimental-hashtags"
        >
          Edit
        </EditButton>
        <ExtendedQuery
          query={EXPERIMENTAL_HASHTAGS_QUERY}
          variables={{ dealId }}
        >
          {({
            nestDeal: { hashtags },
          }: ExtendedQueryRenderProps<ExperimentalHashtagsQuery>) => (
            <>
              <TextArea
                data-test="deal-details-experiment-hashtags-read-only"
                label="Experiment #hashtags"
                value={hashtags}
                readOnly
              />
              <Modal
                title="Experiments"
                closeModal={this.closeModal}
                isOpen={editing}
              >
                <ExtendedMutation mutation={UPDATE_HASHTAGS}>
                  {(
                    mutation: MutationFunction<
                      UpdatePropertyAccessArrangements,
                      UpdatePropertyAccessArrangementsVariables,
                    >,
                  ) => (
                    <TextArea
                      data-test="deal-details-experiment-hashtags-updatable"
                      label="Experiment #hashtags"
                      mutation={(input) =>
                        mutation({
                          variables: { dealId, input },

                          optimisticResponse: {
                            __typename: "Mutation",
                            updateNestDeal: {
                              __typename: "NestDeal",
                              id: dealId,
                              ...input,
                            },
                          },
                        })
                      }
                      property="hashtags"
                      value={hashtags}
                    />
                  )}
                </ExtendedMutation>
              </Modal>
            </>
          )}
        </ExtendedQuery>
      </Card>
    );
  }
}
