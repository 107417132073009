// @flow
import { css } from "styled-components";
import { getImage } from "@nested/utils";
import { ModalHeader, Modal } from "../Modal";
import { useHandoff } from "../MobileActionSheet/useHandoff";

const instructionText = css`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague150};
  margin: 20px auto;
`;

const smsText = css`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague70};
  margin: 10px 20px 20px;
`;

const modalStyle = css`
  border-radius: 3px;
  display: block;
  text-align: center;
  width: 335px;
  & button {
    padding: 16px 20px;
  }
`;

const modalHeaderStyle = css`
  text-align: center;
  padding: 16px 20px;
  font-size: 14px;
  min-height: 53px;
`;

const imageFadeStyle = css`
  position: relative;
  width: 140px;
  margin: auto;
`;

const arrowStyle = css`
  width: 45px;
  position: absolute;
  top: 9px;
  right: 23px;
`;

const NestToPhoneGif = () => (
  <>
    <div css={imageFadeStyle} data-test="phone-not-detected">
      <img
        alt=""
        src={getImage(
          "illustrations/nest-to-phone-handover/phone-asset.png?w=120",
        )}
      />
      <img
        alt=""
        css={arrowStyle}
        src="https://nested.imgix.net/illustrations/nest-to-phone-handover/arrow-exchange.gif"
      />
    </div>
    <div css={instructionText}>Open the Nest on your phone</div>
  </>
);

const NestToPhoneSuccess = ({ isSMS }) => (
  <>
    <div css={imageFadeStyle} data-test="phone-detected">
      <img
        alt=""
        src={getImage(
          "illustrations/nest-to-phone-handover/connected.png?w=120",
        )}
      />
    </div>
    <div css={instructionText}>
      Phone found, your {isSMS ? "SMS" : "call"} is ready
    </div>
  </>
);

export const HandoffModal = () => {
  const { commsType, handoffOpen, closeHandoff, mobilePresent, smsBody } =
    useHandoff();

  const isSMS = commsType === "sms";

  return (
    <Modal css={modalStyle} open={handoffOpen} onClose={closeHandoff}>
      <ModalHeader className="fs-exclude" css={modalHeaderStyle}>
        {isSMS ? "SMS" : "Call"}
      </ModalHeader>
      {mobilePresent ? (
        <NestToPhoneSuccess isSMS={isSMS} />
      ) : (
        <NestToPhoneGif />
      )}
      {isSMS && smsBody && (
        <p css={smsText}>
          Log in to the Nest on your phone, we’ll have your SMS ready composed.
        </p>
      )}
    </Modal>
  );
};
