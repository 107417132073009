// @flow
import type { ComponentType } from "react";
import { ReadOnlyField } from "@nest-ui/sellers-nest/components/ReadOnlyField";

type Props = {
  readOnly?: boolean,
  disabled?: boolean,
  [key: string]: any,
};

export function withReadOnly<T: Props>(format: (props: T) => string) {
  return (BaseComponent: ComponentType<T>) => (props: T) => {
    const { readOnly, disabled } = props;

    if (readOnly || disabled) {
      return <ReadOnlyField {...props} value={format(props)} />;
    }

    return <BaseComponent {...props} />;
  };
}
