// @flow
import styled, { css } from "styled-components";
import { media } from "@nested/brand";

import { useTaskCompletion } from "../../hooks/useTaskCompletion";
import { AnimatedList } from "../../components/AnimatedList";
import { ListViewError } from "../../components/ListViewError";
import { PlaceholderTable } from "../../components/Placeholder";
import { ThermometerImage } from "../../components/NurtureHotnessImage";
import { TaskListRow } from "./TaskListRow";
import { type AgentTask } from "./Tasks";

const headingStyle = css`
  display: none;

  ${media.tablet`
    border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
    display: flex;
    font-weight: 500;
    margin-left: 30px;
    padding: 30px 0px 10px 0px;
    text-align: left;
    white-space: nowrap;
  `}

  & div {
    line-height: 18px;
    align-self: center;
  }
`;

const HeaderWhitespace = styled.div`
  width: 35px;

  ${media.tablet`
    width: 30px;
  `}
`;

const HeaderBorder = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
`;

type HeaderProps = {
  showHeader: boolean,
  showTopBorder: boolean,
};

const Header = ({ showHeader, showTopBorder }: HeaderProps) => {
  return showHeader ? (
    <div css={headingStyle}>
      <div css="width: 25%;">Name</div>
      <div css="width: 50%;">Task</div>
      <div css="width: 15%;">Due date</div>
      <div css="text-align: center; width: 5%;">
        <ThermometerImage />
      </div>
      <div css="width: 5%;"></div>
    </div>
  ) : (
    showTopBorder && (
      <div css="display: flex;">
        <HeaderWhitespace />
        <HeaderBorder />
      </div>
    )
  );
};

const Wrapper = styled.div`
  ${media.tablet`
    width: 100%;
    overflow-y: auto;
    margin-bottom: auto;
    padding-bottom: auto;
  `}
`;

type TaskListProps = {
  completedSection?: boolean,
  error: boolean,
  loading: boolean,
  refetchQueries: {
    refetchCompleted?: () => Promise<void>,
    refetchUncompleted?: () => Promise<void>,
  },
  tasks: AgentTask[],
};

export const TaskList = ({
  completedSection = false,
  error,
  loading,
  refetchQueries,
  tasks,
}: TaskListProps) => {
  const { toggleCompleteTask } = useTaskCompletion(refetchQueries);

  const customError = `Could not load your ${
    completedSection ? "completed" : ""
  } tasks - please try refreshing the page!`;

  if (error) {
    return <ListViewError message={customError} />;
  }

  if (loading && tasks.length === 0) {
    return (
      <Wrapper>
        <PlaceholderTable />
      </Wrapper>
    );
  }

  const isLastTask = tasks.length === 1;

  return (
    <Wrapper>
      <Header showHeader={!completedSection} showTopBorder={tasks.length > 0} />
      <AnimatedList
        list={tasks}
        rowComponent={TaskListRow}
        additionalComponentProps={{
          toggleCompleteTask,
          isLastTask,
          completedSection,
        }}
      />
    </Wrapper>
  );
};
