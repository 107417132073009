// @flow

import {
  MutationFunction,
  RefetchQueriesProviderFn,
} from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { Grid, Cell } from "components/Grid";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { TextareaWithLinks } from "components/TextareaWithLinks";

import { ComparableCard } from "./ComparableCard";
import { ComparisonPoints } from "../ComparisonPoints/ComparisonPoints";

const CompWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ComparisonPointsCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  min-height: 194px;
`;

const NotesWrapper = styled.div`
  textarea,
  span > div {
    background-color: ${({ theme }) => theme.textField.background};
    border: 2px solid ${({ theme }) => theme.textField.border};
    border-radius: 4px;
    color: ${({ theme }) => theme.textField.text};
    min-height: 175px;

    ::placeholder {
      opacity: 0.4;
      color: ${({ theme }) => theme.text.white};
    }

    &:focus {
      border-color: ${({ theme }) => theme.textField.borderFocus};
    }
  }
  label {
    color: ${({ theme }) => theme.text.white};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
  }
`;

const SAVE_SELECTED_COMPARABLE = gql`
  mutation SaveSelectedComparable(
    $id: ID!
    $input: SaveSelectedComparableInput!
  ) {
    saveSelectedComparable(id: $id, input: $input) {
      id
      internalNotes
    }
  }
`;

type Props = {
  comparable:
    | SelectedComparablesQuery_selectedComparables
    | ListComparablesQuery_comparables,
  refetchOnDelete: RefetchQueriesProviderFn,
  selectAction: () => void,
  selected?: boolean,
  disableSelect: (boolean) => void,
};

export const ComparableWithNotes = ({
  comparable,
  disableSelect,
  selected,
  selectAction,
  refetchOnDelete,
}: Props) => (
  <CompWrapper>
    <ComparableCard
      comparable={comparable}
      disableSelect={disableSelect}
      refetchOnDelete={refetchOnDelete}
      selected={selected}
      selectAction={selectAction}
    >
      <Grid columns={2}>
        <ComparisonPointsCell width={1}>
          <ComparisonPoints comparable={comparable} />
        </ComparisonPointsCell>

        <ExtendedMutation mutation={SAVE_SELECTED_COMPARABLE}>
          {(
            mutation: MutationFunction<
              SaveSelectedComparable,
              SaveSelectedComparableVariables,
            >,
          ) => {
            const saveComparable = (input) =>
              mutation({
                variables: {
                  id: comparable.id,
                  input,
                },
                optimisticResponse: {
                  saveSelectedComparable: {
                    __typename: "Comparable",
                    id: comparable.id,
                    ...input,
                  },
                },
              });
            return (
              <Cell width={1}>
                <NotesWrapper>
                  <TextareaWithLinks
                    label="Internal Notes"
                    multiline
                    mutation={saveComparable}
                    property="internalNotes"
                    value={comparable.internalNotes}
                  />
                </NotesWrapper>
              </Cell>
            );
          }}
        </ExtendedMutation>
      </Grid>
    </ComparableCard>
  </CompWrapper>
);
