// @flow
import { css, createGlobalStyle } from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { media } from "@nested/brand";
import { getImage } from "@nested/utils";

import { Navigation } from "./Navigation/Navigation";
import { Sidebar } from "./Navigation/Sidebar";
import { AuthoriseNylasSuccess } from "./AuthoriseNylas/AuthoriseNylasSuccess";
import { Deal } from "../pages/Deal/Deal";
import { Buyer } from "../pages/Buyer/Buyer";
import { Portfolio } from "../pages/Portfolio/Portfolio";
import { Leads } from "../pages/Leads/Leads";
import { Nurture } from "../pages/Nurture/Nurture";
import { Sold } from "../pages/Sold/Sold";
import { Enquiries } from "../pages/Buyers/Enquiries/Enquiries";
import { Feedback } from "../pages/Buyers/Feedback/Feedback";
import { Negotiations } from "../pages/Buyers/Negotiations/Negotiations";
import { UnderOffer } from "../pages/Buyers/UnderOffer/UnderOffer";
import { Tasks } from "../pages/Tasks/Tasks";

const wrapperStyle = css`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  ${media.tablet`
    -webkit-overflow-scrolling: auto;
  `}
`;

const pageWrapperStyle = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100% - 50px);
`;

const desktopSidebarStyle = css`
  display: none;
  ${media.desktop`
    display: block;
    height: 100%;
  `}
`;

export const contentWrapper = css`
  position: relative;
  flex-grow: 1;
  max-width: 100%;
  ${media.desktop`
    overflow-y: auto;
  `}
`;

export const GlobalStyles = createGlobalStyle`
  #root {
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme: t }) => t.palette.hague150};
    box-sizing: border-box;
  }

  #root a {
    color: inherit;
    text-decoration: inherit;
  }

  * {
    box-sizing: border-box;
  }
`;

// The layout of the Nest (so header, left hand nav, theme, etc) is separated
// out from the functional `Main` component so that it can be reused separately
// to the functional purpose of `Main` which is to manage routing. This is
// useful for things like storybook where we want to fine-tune what's within
// the content area of the page without having to contend with routing.
export const MainLayout = ({ children }: { children: React$Node }) => (
  <>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={getImage("nest/icons/the-nest-app-icon_1024.png?w=120")}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={getImage("nest/icons/the-nest-app-icon_1024.png?w=152")}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={getImage("nest/icons/the-nest-app-icon_1024.png?w=167")}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={getImage("nest/icons/the-nest-app-icon_1024.png?w=180")}
      />
      <meta name="apple-mobile-web-app-title" content="The Nest" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="translucent-black"
      />
    </Helmet>
    <GlobalStyles />
    <div css={wrapperStyle}>
      <Navigation />
      <div css={pageWrapperStyle}>
        <Sidebar onClose={() => undefined} css={desktopSidebarStyle} />
        <div css={contentWrapper}>{children}</div>
      </div>
    </div>
  </>
);

// IMPORTANT: Any layout features must go into the `MainLayout` component. This
// component is responsible just for the routing.
export const Main = () => (
  <MainLayout>
    <Switch>
      <Route path="/leads" component={Leads} />
      <Route path="/nurture" component={Nurture} />
      <Route path="/portfolio" component={Portfolio} />
      <Route path="/buyers/enquiries" component={Enquiries} />
      <Route path="/buyers/feedback" component={Feedback} />
      <Route path="/buyers/negotiations" component={Negotiations} />
      <Route path="/buyers/under-offer" component={UnderOffer} />
      <Route
        path="/buyers/:id/(interests)?/:bpiId?/(workflows)?/:workflow?"
        component={Buyer}
      />
      <Route path="/sold" component={Sold} />
      <Route path="/deals/:id" component={Deal} />
      <Route path="/tasks" component={Tasks} />
      <Route
        path="/nylas-authorise-success"
        component={AuthoriseNylasSuccess}
      />
      <Redirect from="/create-buyer-from-close" to="/buyers/enquiries" />
      <Redirect to="/leads" />
    </Switch>
  </MainLayout>
);
