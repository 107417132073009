// @flow
import { useState } from "react";
import styled from "styled-components";
import { getConfig } from "@nested/config";
import { Grid, LeafCell } from "components/Grid/Grid";
import { NoSubmitTextField } from "components/TextField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { UpdateBuyersReport } from "./forms/UpdateBuyersReport";
import { DeleteBuyersReport } from "./forms/DeleteBuyersReport";
import { Card } from "./Card";

const Wrapper = styled.div`
  padding: 16px;
`;

const { ACCOUNT_URI } = getConfig();

type Props = {
  customerAccountId: string,
  report: getReports_buyersAgentReports,
};

export const Report = ({ report, customerAccountId }: Props) => {
  const [editReport, setEditReport] = useState(false);

  if (editReport) {
    return (
      <UpdateBuyersReport
        report={report}
        onClose={() => setEditReport(false)}
        customerAccountId={customerAccountId}
      />
    );
  }

  return (
    <Card address={report.addressText} reportStatus={report?.reportStatus}>
      <EditButton
        onClick={() => setEditReport(true)}
        data-test="edit-report-button"
      >
        Edit details
      </EditButton>
      <Wrapper>
        <Grid columns={4}>
          <LeafCell left={1} width={2}>
            <NoSubmitTextField
              label="Property address"
              disabled
              value={report.addressText}
              data-test="report-card-address-text"
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitDatePicker
              disabled
              label="Report created on"
              value={report.addedAt}
              dataTest="report-card-added-at"
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitTextareaWithLinks
              label="Live report"
              value={`${ACCOUNT_URI}/buyers-report/${report.id}`}
              disabled
              data-test="live-report-link"
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitTextField
              label="Customer status with this property"
              value={report.customerStatus.label}
              disabled
              data-test="report-card-customer-status"
            />
          </LeafCell>
          <LeafCell width={2}>
            <NoSubmitTextField
              label="Agent name"
              value={report.agentName}
              disabled
              data-test="report-card-agent-name"
            />
          </LeafCell>
          <LeafCell left={4}>
            <DeleteBuyersReport
              report={report}
              customerAccountId={customerAccountId}
            />
          </LeafCell>
        </Grid>
      </Wrapper>
    </Card>
  );
};
