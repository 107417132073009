import T from "prop-types";
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";

import { CreateButton } from "components/CreateButton";
import thirdPartyAppraisalsQuery from "./queries/thirdPartyAppraisals.graphql";
import { thirdPartyAppraisalsQueryConfig } from "./queries/config";
import createThirdPartyAppraisalMutation from "./createMutation/createThirdPartyAppraisal.graphql";
import { createConfig } from "./createMutation/config";

import { ThirdPartyAppraisal } from "./ThirdPartyAppraisal";

const ThirdPartyAppraisalListComponent = ({
  data: { thirdPartyAppraisalsForDeal: thirdPartyAppraisals },
  createThirdPartyAppraisal,
}) => (
  <div>
    {thirdPartyAppraisals.map(({ id, amount, date, dealId }) => (
      <ThirdPartyAppraisal
        id={id}
        key={id}
        amount={amount}
        date={date}
        dealId={dealId}
      />
    ))}
    <CreateButton
      label="Add third party appraisal"
      onSubmit={createThirdPartyAppraisal}
      data-test="third-party-appraisal-create-button"
    />
  </div>
);

ThirdPartyAppraisalListComponent.propTypes = {
  data: T.object.isRequired,
  createThirdPartyAppraisal: T.func.isRequired,
};

const enhance = compose(
  graphql(thirdPartyAppraisalsQuery, thirdPartyAppraisalsQueryConfig),
  graphql(createThirdPartyAppraisalMutation, createConfig),
);

export const ThirdPartyAppraisalList = enhance(
  ThirdPartyAppraisalListComponent,
);
