// @flow

import { gql } from "@apollo/client";
import styled from "styled-components";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { Tick } from "@nest-ui/icons";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { PROPERTY_INTERESTS_QUERY } from "@nest-ui/buyers-nest/buyer/pages/BuyerPage/PropertyInterests/PropertyInterests";
import { PrimaryButton } from "components/Buttons";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitRadioButtons } from "components/RadioButtons/RadioButtons";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";

const OFFER_CREATION_PRE_REQUISITES_QUERY = gql`
  query OfferCreationPreRequisitesQuery {
    activeNestedUsers {
      id
      label: fullName
      value: id
    }
    inputOptions {
      buyerOfferStatus: inputOptions(field: "buyer_offer_status") {
        label
        value
      }
      buyerFinanceMethod: inputOptions(field: "buyer_finance_method") {
        label
        value
      }
    }
  }
`;

const CREATE_BUYER_OFFER = gql`
  mutation CreateBuyerOffer($input: CreateBuyerOfferInput!) {
    createBuyerOffer(input: $input) {
      id
      amount
      placedOn
      status {
        label
        value
      }
      offerConditions
      timeFrameConditions
      lengthOfDownwardChain
      buyerFinanceMethod {
        label
        value
      }
      buyerIsNestedCustomer
      saWhoGotTheOffer {
        id
      }
    }
  }
`;

const FormError = styled.p`
  color: red;
  font-weight: 500;
`;

const required = (value) =>
  value !== undefined && value !== null ? undefined : "Required";

const createSubmitHandler =
  (
    createBuyerOffer,
    buyerPropertyInterestId,
    { buyerOfferStatus, buyerFinanceMethod },
    onSuccess,
  ) =>
  async (state) => {
    const confirmationMessage =
      "Please confirm you are aware that the following fields will be exposed to the seller in their account: Offer amount, Date offer made, Offer conditions, Time frame conditions, Buyer in a downward chain?, Buyer finance";
    const confirmation = window.confirm(confirmationMessage); // eslint-disable-line no-alert
    if (!confirmation) {
      return undefined;
    }

    const input = { buyerPropertyInterestId, ...state };

    const {
      status: offerStatusValue,
      buyerFinanceMethod: buyerFinanceMethodValue,
      ...rest
    } = state;

    try {
      await createBuyerOffer({
        variables: {
          input,
        },
        optimisticResponse: {
          createBuyerOffer: {
            __typename: "buyerOffer",
            id: "3",
            status: {
              __typename: "BuyerOfferStatus",
              label: buyerOfferStatus.find(
                (status) => status.value === offerStatusValue,
              ).label,
              value: offerStatusValue,
            },
            buyerFinanceMethod: {
              __typename: "BuyerFinanceMethod",
              label: buyerFinanceMethod.find(
                (method) => method.value === buyerFinanceMethodValue,
              ).label,
              value: buyerFinanceMethodValue,
            },
            ...rest,
          },
        },
      });

      onSuccess();

      return undefined;
    } catch (e) {
      return {
        [FORM_ERROR]: `Failed to create the offer: ${e}`,
      };
    }
  };

type Props = {
  buyerId: string,
  buyerPropertyInterestId: string,
  onSuccess: () => any,
};

export const OfferCreationForm = ({
  buyerId,
  buyerPropertyInterestId,
  onSuccess,
}: Props) => {
  const [createBuyerOffer] = useMutation(CREATE_BUYER_OFFER, {
    refetchQueries: [
      { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
    ],
  });

  const { data, loading, error } = useQuery(
    OFFER_CREATION_PRE_REQUISITES_QUERY,
  );

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  const { activeNestedUsers, inputOptions } = data;

  return (
    <Form
      onSubmit={createSubmitHandler(
        createBuyerOffer,
        buyerPropertyInterestId,
        inputOptions,
        onSuccess,
      )}
      initialValues={{ status: "collecting_details" }}
    >
      {({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        submitting,
        pristine,
      }) => {
        return (
          <Grid columns={4}>
            {hasSubmitErrors && (
              <LeafCell width={4}>
                <FormError data-test="create-offer-error">
                  {submitError}
                </FormError>
              </LeafCell>
            )}
            <LeafCell width={1}>
              <Field name="amount" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitCurrencyField
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    highlightRed={meta.error && meta.touched}
                    label="Offer amount"
                    data-test="create-offer-amount"
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={1}>
              <Field name="placedOn" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitDatePicker
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Date offer made"
                    data-test="create-offer-date-offer-made"
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2}>
              <Field name="status" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitSelectField
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Offer status"
                    data-test="create-offer-status"
                    options={inputOptions.buyerOfferStatus}
                    disabled
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2}>
              <Field name="offerConditions" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitTextareaWithLinks
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Offer conditions - if none exist enter 'None' below"
                    data-test="create-offer-conditions"
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2} left={1}>
              <Field name="timeFrameConditions" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitTextareaWithLinks
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Time frame conditions - if none exist enter 'None' below"
                    data-test="create-offer-time-frame-conditions"
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2} left={1}>
              <Field name="lengthOfDownwardChain" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitSelectField
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Length of downward chain"
                    data-test="create-offer-length-downward-chain"
                    options={[
                      "0",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10+",
                      "N/A",
                      "To be confirmed",
                    ]}
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2}>
              <Field name="buyerFinanceMethod" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitSelectField
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Buyer finance"
                    data-test="create-offer-buyer-finance"
                    options={inputOptions.buyerFinanceMethod}
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={1}>
              <Field name="buyerIsNestedCustomer" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitRadioButtons
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="Is the buyer selling with Nested too?"
                    data-test="create-offer-buyer-selling-with-nested"
                    options={[
                      { label: "No", value: false },
                      { label: "Yes", value: true },
                    ]}
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2} left={1}>
              <Field name="saWhoGotTheOfferId" validate={required}>
                {({ input: { onChange, ...input }, meta }) => (
                  <NoSubmitSelectField
                    highlightRed={meta.error && meta.touched}
                    onSubmit={onChange}
                    {...input}
                    {...meta}
                    label="SA who got the offer"
                    data-test="create-offer-sa-who-got-the-offer"
                    options={activeNestedUsers}
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={1} left={1}>
              <PrimaryButton
                data-test="create-offer-submit-button"
                onClick={handleSubmit}
                icon={Tick}
                disabled={pristine || submitting}
                label="Create offer"
              />
            </LeafCell>
          </Grid>
        );
      }}
    </Form>
  );
};
