// @flow

import { getConfig } from "@nested/config";
import { A } from "components/A/A";

type Props = {
  externalId: string,
};

const { ACCOUNT_URI } = getConfig();

export const LinkToPropertyAnalysisAdmin = ({ externalId }: Props) => (
  <A
    href={`${ACCOUNT_URI}/listing/strategy/property-analysis?deal=${externalId}`}
  >
    View the property analysis
  </A>
);
