// @flow
import { useState } from "react";
import { css } from "styled-components";
import moment from "moment";
import { gql } from "@apollo/client";
import { last } from "lodash";
import { type ContextRouter } from "react-router";
import { useQuery, useMutation } from "@apollo/client/react/hooks";

import { recentlySoldSstcLink } from "@nested/utils/rightmove/rightMove";
import { Loader } from "components/Loader";

import {
  Background,
  HeaderWrapper,
  Header,
  HeaderLinks,
  ContentWrapper,
  Content,
} from "./StyledComponents";

import { Swoosh } from "./Swoosh";
import { TileButton, TileLink, TileGroup, TileGroupTitle } from "./Tile";

const SEND_BROKER_REFERRAL = gql`
  mutation SendBrokerReferral($closeIoLeadId: ID!, $vendorCloseIoLeadId: ID!) {
    sendBrokerReferral(
      closeIoLeadId: $closeIoLeadId
      vendorCloseIoLeadId: $vendorCloseIoLeadId
    )
  }
`;

const BUYER_QUERY = gql`
  query Buyer($buyerId: ID!) {
    buyer(id: $buyerId) {
      id
      buyerPropertyInterests {
        id
        buyer {
          id
          closeIoLeadId
          name
        }
        deal {
          closeIoLeadLink
          closeIoLeadId
          id
          address
          externalId
          rightmoveListingUrl: nestedListing
          sellerValuation
          currentListPrice
          property {
            id
            propertyType
            postcode
            bedrooms
            outcode
            street
            lat
            lng
          }
        }
        viewings {
          id
          datetimeViewingStarts
        }
      }
    }
  }
`;

const nestedListingUrl = ({ property: { outcode, street }, externalId }) =>
  new URL(
    `for-sale/${outcode}/${formattedStreet(street)}/${externalId}`,
    "https://nested.com",
  ).toString();

const similarPropertiesUrl = ({ externalId }) =>
  new URL(
    `listing/strategy/property-analysis?deal=${externalId}`,
    "https://account.nested.com",
  ).toString();

const rightmoveSearchUrl = ({
  property: { postcode, bedrooms, propertyType },
  sellerValuation,
  currentListPrice,
}) =>
  recentlySoldSstcLink(
    postcode,
    bedrooms,
    propertyType,
    sellerValuation || currentListPrice || 0,
  );

const googleMapsUrl = ({ lat, lng }) =>
  new URL(
    `maps/search/?api=1&query=${lat},${lng}`,
    "https://www.google.com",
  ).toString();

const cityMapperUrl = ({ lat, lng }) =>
  new URL(
    `directions?endcoord=${lat},${lng}`,
    "https://citymapper.com",
  ).toString();

const formattedStreet = (street) =>
  encodeURI(street.replace(/,/g, "").replace(/\W+/g, "-"));

const sortByDateDesc = (x, y) =>
  moment(x.datetimeViewingStarts).diff(y.datetimeViewingStarts);

export const HomePage = (props: ContextRouter) => {
  const {
    match: { params, url },
  } = props;

  const { buyerId, buyerPropertyInterestId } = params;
  const { data, loading, error } = useQuery(BUYER_QUERY, {
    variables: { buyerId },
  });

  const [buyerReferralSent, setBuyerReferralSent] = useState(false);
  const [sendBrokerReferral] = useMutation(SEND_BROKER_REFERRAL);

  if (loading || error) {
    return (
      // probably want to add a loading component in here..
      <Background>
        <HeaderWrapper>
          <Swoosh />
        </HeaderWrapper>
        <ContentWrapper>
          <Content>
            {error && (
              <h2
                css={css`
                  font-weight: ${({ theme }) => theme.font.weight.semibold};
                  text-transform: uppercase;
                  margin-top: 100px;
                  letter-spacing: 0.25em;
                  color: ${({ theme }) => theme.color.primary.dark};
                  text-align: center;
                `}
              >
                Oops! Something went wrong!
              </h2>
            )}
            {loading && <Loader />}
          </Content>
        </ContentWrapper>
      </Background>
    );
  }

  const { buyer, deal, viewings } = data.buyer.buyerPropertyInterests.find(
    (bpi) => bpi.id === buyerPropertyInterestId,
  );

  const { datetimeViewingStarts } = last(viewings.slice().sort(sortByDateDesc));
  const viewingDatetime = moment(datetimeViewingStarts).format("h:mma Do MMM");
  const {
    property,
    address,
    closeIoLeadLink,
    rightmoveListingUrl,
    closeIoLeadId: sellerCloseLeadId,
  } = deal;

  return (
    <>
      <Background>
        <HeaderWrapper>
          <Swoosh />
          <Header>
            <div>
              <img
                src="https://nested.imgix.net/nestedLogoWhite.svg?auto=format,compress"
                alt=""
              />
              <p style={{ marginTop: "15px" }}>{address}</p>
              <p>{`${buyer.name} viewing at ${viewingDatetime}`}</p>
            </div>

            <HeaderLinks>
              <a href={closeIoLeadLink} target="_blank">
                Seller
              </a>
              <a
                href={`https://app.close.io/lead/${buyer.closeIoLeadId}`}
                target="_blank"
              >
                Buyer
              </a>
            </HeaderLinks>
          </Header>
        </HeaderWrapper>

        <ContentWrapper>
          <Content>
            <TileGroupTitle>viewing</TileGroupTitle>
            <TileGroup>
              <TileLink
                id="viewing_app-property_info"
                to={`${url}/propertyinfo/${deal.id}`}
              >
                Property info
              </TileLink>
              <TileLink
                id="viewing_app-buyer_info"
                prep
                to={`/buyers/${buyerId}`}
              >
                Buyer info
              </TileLink>
              <TileLink id="viewing_app-photos" href={nestedListingUrl(deal)}>
                Photos
              </TileLink>
              <TileButton prep disabled>
                Checklist
              </TileButton>
            </TileGroup>

            <TileGroupTitle>local area</TileGroupTitle>
            <TileGroup>
              <TileLink id="viewing_app-schools" href={rightmoveListingUrl}>
                Schools
              </TileLink>
              <TileLink
                id="viewing_app-transport"
                href={cityMapperUrl(property)}
              >
                Transport
              </TileLink>
              <TileLink id="viewing_app-map" href={googleMapsUrl(property)}>
                Map
              </TileLink>
              <TileLink
                id="viewing_app-nested_properties"
                href="https://nested.com/for-sale/"
              >
                Nested properties
              </TileLink>
              <TileLink
                id="viewing_app-similar_properties"
                prep
                href={similarPropertiesUrl(deal)}
              >
                Similar properties
              </TileLink>
              <TileLink
                id="viewing_app-rightmove_search"
                href={rightmoveSearchUrl(deal)}
              >
                Rightmove search
              </TileLink>
              <TileLink
                id="viewing_app-area_guides"
                prep
                href="googledrive://https://docs.google.com/spreadsheets/d/1WIuJJMdwoYKdi8W7tpQdaxSVd5UKj4OhFFjnRp01wPw"
              >
                Area guides
              </TileLink>
              <TileLink
                id="viewing_app-upgrade_potential"
                prep
                href="googledrive://https://docs.google.com/spreadsheets/d/1S9g8MfRJJzBacvmCI33kDqM5J6JvT--i5e9H4UNfdfE"
              >
                Upgrade potential
              </TileLink>
            </TileGroup>

            <TileGroupTitle>Nested</TileGroupTitle>
            <TileGroup>
              <TileLink
                id="viewing_app-how_it_works"
                href="https://docs.google.com/a/nested.com/presentation/d/1lCrewpEVnYmb0hIA6r8AuLTVAcZzDVQBHmDwjQzpIME/edit?usp=slack&ts=5d53e9ed"
              >
                How it works
              </TileLink>
              <TileLink
                id="viewing_app-sales_enquiry"
                href="https://nested.com/get-started"
              >
                Sales enquiry
              </TileLink>
              <TileLink
                id="viewing_app-offer_form"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeIv2wZNWqptqcK2y4qUSkACmKugrwyHBDWwLYbfkcRWiu2zw/viewform"
              >
                Offer form
              </TileLink>
              <TileLink
                id="viewing_app-trinity_financial"
                href="googledrive://https://drive.google.com/file/d/1KFTRKJI4wFabuqbYKSAgIBId_xK8UITy/view"
              >
                Trinity Financial
              </TileLink>
              <TileButton
                disabled={buyerReferralSent}
                onClick={() => {
                  if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                      "This will send an email to the buyer with the broker details. Broker will also receive a copy of the email. Are you sure you want to do this?",
                    )
                  ) {
                    setBuyerReferralSent(true);
                    sendBrokerReferral({
                      variables: {
                        closeIoLeadId: buyer.closeIoLeadId,
                        vendorCloseIoLeadId: sellerCloseLeadId,
                      },
                    });
                  }
                }}
                href="googledrive://https://drive.google.com/file/d/1KFTRKJI4wFabuqbYKSAgIBId_xK8UITy/view"
              >
                Send broker referral
              </TileButton>
            </TileGroup>
          </Content>
        </ContentWrapper>
      </Background>
    </>
  );
};
