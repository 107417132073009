// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const Table = styled.table`
  min-width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
`;

export const TH = styled.th`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  color: ${({ selected, theme }) =>
    selected ? theme.palette.blue500 : theme.palette.hague150};
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
  font-weight: 500;
  padding: 10px 20px 10px 0px;
  text-align: left;
  white-space: nowrap;
`;

export const TD = styled.td`
  font-weight: 400;
  border-bottom: 1px solid
    ${({ theme, noBorder }) => (noBorder ? "none" : theme.palette.hague20)};
`;

const dealOrBuyerLinkStyle = css`
  display: block;
  width: 100%;
  height: 100%;
  padding: ${({ $narrow }) =>
    $narrow ? "10px 10px 10px 0" : "20px 20px 20px 0"};
`;

type DealTDProps = {
  children: React$Node,
  dealId: string,
  title?: ?string,
  className?: string,
};

export const DealTD = ({ children, dealId, title, className }: DealTDProps) => {
  return (
    <TD title={title} className={className}>
      <Link css={dealOrBuyerLinkStyle} to={`/deals/${dealId}`}>
        {children}
      </Link>
    </TD>
  );
};

type BuyerTDProps = {
  children: React$Node,
  buyerId: string,
  title?: ?string,
  className?: string,
  narrow?: boolean,
};

export const BuyerTD = ({
  children,
  buyerId,
  title,
  className,
  narrow,
}: BuyerTDProps) => {
  return (
    <TD title={title} className={className}>
      <Link
        css={dealOrBuyerLinkStyle}
        $narrow={narrow}
        to={`/buyers/${buyerId}`}
      >
        {children}
      </Link>
    </TD>
  );
};

type SortableTHProps = {
  field: string,
  sortDirection: string,
  sortBy: string,
  children: React$Node,
  toggleSortDirection(direction: string): void,
  className?: string,
};

const sortIconStyle = css`
  opacity: ${({ selected }) => (selected ? "1" : "0.5")};
  margin-left: 5px;
`;

export const SortableTH = ({
  field,
  sortDirection,
  sortBy,
  children,
  toggleSortDirection,
  className,
}: SortableTHProps) => {
  const selected = sortBy === field;
  return (
    <TH
      className={className}
      data-test={`sortable-heading:${field}:${sortDirection}:${
        selected ? "selected" : "deselected"
      }`}
      clickable
      selected={selected}
      onClick={() => {
        toggleSortDirection(field);
      }}
    >
      {children}&nbsp;
      <FontAwesomeIcon
        selected={selected}
        css={sortIconStyle}
        icon={
          sortDirection === "ASCENDING" && selected ? faCaretUp : faCaretDown
        }
      />
    </TH>
  );
};
