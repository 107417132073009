// @flow
import type { Element } from "react";
import styled from "styled-components";
import { withProps, type HOC } from "recompose";

export const Button = styled.button`
  height: ${({ theme }) => theme.input.height};
  min-width: 90px;
  border-radius: ${({ theme }) => theme.input.border.radius};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.background.disabled : theme.color.primary.accent};
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.text.disabled : theme.color.text.default};
  font-size: ${({ theme }) => theme.font.size.default};
  line-height: 1.33;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  padding: ${({ theme }) => theme.input.padding};
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  position: relative;
  padding-left: 28px;
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.color.background.disabled
        : theme.color.primary.mutedAccent};
  }

  svg {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 16px;
    height: 16px;
  }
`;

const Label = styled.span`
  margin-left: 4px;
`;

type SecondaryButtonProps = {
  children?: string,
  icon: ({ fill?: string }) => Element<"svg">,
  label?: string,
  onSubmit: () => void,
  color?: string,
};

const SecondaryButtonComponent = ({
  children,
  icon: Icon,
  label,
  color,
  ...props
}: SecondaryButtonProps) => (
  <Button {...props}>
    <Icon fill={color} />
    {label && <Label>{label}</Label>}
    {children && <Label>{children}</Label>}
  </Button>
);

SecondaryButtonComponent.displayName = "SecondaryButton";

const enhance: HOC<*, SecondaryButtonProps> = withProps(({ onSubmit }) => ({
  onClick: onSubmit,
}));

export const SecondaryButton = enhance(SecondaryButtonComponent);
