// @flow
import { gql, useQuery, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { ADDRESS_NOT_FOUND_ID, media } from "@nested/brand";
import { AddressPicker } from "@nested/nest/src/components/AddressPicker/AddressPicker";
import { TextInput } from "@nested/nest/src/components/TextInput/TextInput";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Field, Form } from "react-final-form";
import { Button } from "../../../../components/Button/Button";

export const ADDRESS_QUERY = gql`
  query AddressTabQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      property {
        id
        addressId
      }
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateDealAddress(
    $dealId: ID!
    $addressId: ID!
    $manualAddress: ManualAddressInput
  ) {
    updateAddress(
      dealId: $dealId
      addressId: $addressId
      manualAddress: $manualAddress
    ) {
      id
      externalId
      address
      property {
        id
        addressId
      }
    }
  }
`;

const fieldsStyle = css`
  padding: 5px 0;

  & input {
    ::placeholder {
      color: ${({ theme }) => theme.palette.hague40};
    }
  }

  & ul {
    top: unset;
  }
`;

export const formStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 20px 0 20px;

  width: 100%;
  overflow-y: auto;
  ${media.tablet`
    padding: 0 20px 0 20px;
  `}
`;

const saveButtonWrapperStyle = css`
  width: 100%;
  padding-top: 15px;
  justify-content: center;

  ${media.tablet`
    padding-top: 5px;
    position: static;
    button {
      width: 310px;
      margin: 0 auto;
    }
`}
`;

const addressPickerStyle = css`
  padding: 20px 20px 0 20px;

  ${media.tablet`
  padding: 0 20px 0 20px;
`}
`;

export const AddressTab = ({
  onClose,
  id,
}: {
  onClose: () => void,
  id: string,
}) => {
  const { data, loading } = useQuery(ADDRESS_QUERY, {
    variables: { id },
  });

  const [updateAddress, { loading: updatingAddress }] = useMutation(
    UPDATE_ADDRESS_MUTATION,
  );

  const updateAddressHandler = async (newAddress) => {
    try {
      await updateAddress({
        variables: {
          dealId: data?.nestDeal?.externalId,
          addressId: newAddress?.id,
          manualAddress: null,
        },
        optimisticResponse: {
          updateAddress: {
            __typename: "NestDeal",
            id,
            externalId: data?.nestDeal?.externalId,
            address: `${newAddress.label}, ${newAddress.postcode}`,
            property: {
              __typename: "Property",
              id: data?.nestDeal?.property?.id,
              addressId: newAddress?.id,
            },
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleOnSubmit = async (args) => {
    const {
      property: {
        address: { postcode: newPostcode },
      },
      manualAddress,
    } = args;

    const manualAddressInput = {
      ...manualAddress,
      postcode: newPostcode,
    };

    try {
      await updateAddress({
        variables: {
          dealId: data?.nestDeal?.externalId,
          addressId: ADDRESS_NOT_FOUND_ID,
          manualAddress: { ...manualAddressInput },
        },
        optimisticResponse: {
          updateAddress: {
            __typename: "NestDeal",
            id,
            externalId: data?.nestDeal?.externalId,
            address: `${manualAddressInput.lineOne.trim()},${
              manualAddressInput.lineTwo
                ? ` ${manualAddressInput.lineTwo.trim()},`
                : ""
            } ${manualAddressInput.postcode}`,
            property: {
              __typename: "Property",
              id: data?.nestDeal?.property?.id,
              addressId: "fake-address-id",
            },
          },
        },
      });

      onClose();
    } catch (e) {
      errorHandler(e);
    }
  };

  const validate = (value) => (value ? undefined : "Required");

  return (
    <>
      <div>
        <Form
          onSubmit={(args) => {
            handleOnSubmit(args);
          }}
          initialValues={{
            property: {
              address: {
                id: data?.nestDeal?.property?.addressId,
              },
            },
          }}
        >
          {({
            handleSubmit,
            hasValidationErrors,
            validating,
            pristine,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name="property.address" validate={validate}>
                {({ input, meta }) => {
                  return (
                    <div css={addressPickerStyle}>
                      <AddressPicker
                        {...input}
                        aria-label="Deal address picker"
                        allowAddressNotFound
                        className="fs-exclude"
                        data-test="deal-address-picker"
                        valid={meta.valid}
                        value={input.value.id}
                        withMapIcon
                        onChange={(newAddress) => {
                          if (newAddress.id !== ADDRESS_NOT_FOUND_ID) {
                            updateAddressHandler(newAddress);
                          }
                          input.onChange(newAddress);
                        }}
                        updating={loading || updatingAddress}
                      />
                    </div>
                  );
                }}
              </Field>
              {values?.property?.address?.id === ADDRESS_NOT_FOUND_ID && (
                <div css={formStyles}>
                  <Field name="manualAddress.lineOne" validate={validate}>
                    {({ input, meta }) => (
                      <TextInput
                        {...input}
                        className="fs-exclude"
                        css={fieldsStyle}
                        label="Address line one"
                        valid={!meta.error}
                      />
                    )}
                  </Field>
                  <Field name="manualAddress.lineTwo">
                    {({ input }) => (
                      <TextInput
                        {...input}
                        className="fs-exclude"
                        css={fieldsStyle}
                        label="Address line two"
                        showOptionalLabel
                      />
                    )}
                  </Field>
                  <Field name="manualAddress.city" validate={validate}>
                    {({ input, meta }) => (
                      <TextInput
                        {...input}
                        className="fs-exclude"
                        css={fieldsStyle}
                        label="City"
                        valid={!meta.error}
                      />
                    )}
                  </Field>
                  <TextInput
                    onChange={() => undefined}
                    className="fs-exclude"
                    disabled
                    css={fieldsStyle}
                    label="Postcode"
                    value={values?.property?.address?.postcode}
                  />
                  <div css={saveButtonWrapperStyle}>
                    <Button
                      buttonStyle="pink"
                      css="&:disabled { opacity: 0.4 }"
                      disabled={
                        updatingAddress ||
                        hasValidationErrors ||
                        validating ||
                        pristine
                      }
                      large
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </form>
          )}
        </Form>
      </div>
    </>
  );
};
