// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  checkBoxOuter,
  checkBoxTick,
  inputStyle,
  Wrapper,
} from "./CheckboxShared";

const deselectIcon = css`
  position: absolute;
  width: 10px;
  height: 3px;
  left: 4px;
  top: 8px;
  background-color: ${({ theme }) => theme.palette.hague40};
`;

const checkboxWrapperStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const checkboxBorderStyle = css`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 4px;
  margin: ${({ $hasLabel, $labelTextPosition }) => {
    if ($labelTextPosition === "left") {
      return $hasLabel ? "2px 0 2px 10px" : "0 0 0 10px";
    }
    return $hasLabel ? "2px 10px 2px 0" : "0 10px 0 0";
  }};
  transition: border 200ms ease-out;
  overflow: hidden;
  flex-shrink: 0;
  background-color: ${({ $disabled, theme }) =>
    $disabled ? theme.palette.hague20 : "white"};
`;

const checkboxBackgroundStyle = css`
  width: 100%;
  height: 100%;
  opacity: ${({ checked }) => (checked ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const checkboxTickStyle = css`
  width: 100%;
  height: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.hague40 : theme.palette.green100};
`;

const labelStyle = css`
  display: block;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
`;

type Props = {
  checked: boolean,
  className?: string,
  "data-test"?: string,
  disabled?: boolean,
  labelText?: string | React$Node,
  onChange(e: SyntheticInputEvent<HTMLInputElement>): void | Promise<void>,
  id: string,
  labelTextPosition?: "left" | "right",
};

const Checkbox = ({
  checked,
  className,
  "data-test": dataTest,
  disabled,
  labelText,
  onChange,
  id,
  labelTextPosition = "right",
}: Props) => (
  <label
    className={className}
    css={labelStyle}
    data-test={dataTest ? `${dataTest}:label` : `${id}:label`}
    $disabled={disabled}
  >
    <div css={checkboxWrapperStyle}>
      {labelTextPosition === "left" && <div>{labelText}</div>}

      <input
        css={inputStyle}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id={id}
        data-test={dataTest || id}
      />
      <div
        $disabled={disabled}
        checked={checked}
        css={checkboxBorderStyle}
        id="checkbox-border"
        $hasLabel={labelText}
        $labelTextPosition={labelTextPosition}
      >
        <div
          css={checkboxBackgroundStyle}
          checked={checked}
          id="checkbox-background"
        >
          <FontAwesomeIcon
            icon={faCheck}
            id="checkbox-tick"
            css={checkboxTickStyle}
            disabled={disabled}
          />
        </div>
      </div>
      {labelTextPosition === "right" && <div>{labelText}</div>}
    </div>
  </label>
);

// The SelectAllCheckbox has 3 states:
//   Everything selected = tick
//   Some selected = grey line
//   None selected = blank
type SelectAllCheckboxProps = {
  noneSelected: boolean,
  allSelected: boolean,
  className?: string,
  "data-test"?: string,
  disabled?: boolean,
  id?: string,
  onChange: () => void,
};

const SelectAllCheckbox = ({
  noneSelected,
  allSelected,
  className,
  "data-test": dataTest = "select-all",
  disabled,
  id = "all",
  onChange,
}: SelectAllCheckboxProps) => {
  return (
    <Wrapper className={className}>
      <input
        checked={allSelected}
        css={inputStyle}
        data-test={dataTest}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
      <label css={checkBoxOuter} htmlFor={id} disabled={disabled}>
        {allSelected || noneSelected ? (
          <FontAwesomeIcon
            checked={allSelected}
            css={checkBoxTick}
            disabled={disabled}
            icon={faCheck}
          />
        ) : (
          <div css={deselectIcon}></div>
        )}
      </label>
    </Wrapper>
  );
};

export { Checkbox, SelectAllCheckbox };
