// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import { Checkbox } from "components/Checkbox";
import { DatePicker } from "components/DatePicker";
import { RadioButtons } from "components/RadioButtons";
import { TextField } from "components/TextField";
import { TextArea } from "components/TextArea";
import { yesNAOptions } from "@nest-ui/shared/options";

import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

type AMLSectionQuery = {
  ...AMLQuery,
  nestDeal: AMLQuery_nestDeal,
};

type Props = {
  parentId: string,
};

const AML_QUERY = gql`
  query AMLQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      amlChecklistSubmitted
      checkedTitle
      legalAddress
      ownerName
      ownerDob
      ownerTwoDob
      secondOwnerName
      signedOffByAmlOfficerDate
      signedOffIdentity
      signedOffIdentityOwnerTwo
      signedOffProofOfAddress
      signedOffProofOfOwnership
    }
  }
`;

const AML_MUTATION = gql`
  mutation UpdateDealAMLChecks($nestDeal: NestDealInput!, $dealId: ID!) {
    updateNestDeal(nestDeal: $nestDeal, id: $dealId) {
      id
      amlChecklistSubmitted
      checkedTitle
      legalAddress
      ownerName
      ownerDob
      ownerTwoDob
      secondOwnerName
      signedOffByAmlOfficerDate
      signedOffIdentity
      signedOffIdentityOwnerTwo
      signedOffProofOfAddress
      signedOffProofOfOwnership
    }
  }
`;

export const AMLCheckList = ({ parentId }: Props) => (
  <ExtendedQuery query={AML_QUERY} variables={{ dealId: parentId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<AMLSectionQuery>) => (
      <Mutation mutation={AML_MUTATION}>
        {(
          updateNestDeal: MutationFunction<
            UpdateDealAMLChecks,
            UpdateDealAMLChecksVariables,
          >,
        ) => (
          <Grid columns={2}>
            <LeafCell width={1} left={1}>
              <TextField
                label="Owner 1 - name (checked on ID)"
                value={nestDeal.ownerName}
                property="ownerName"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="owner-name"
                className="fs-exclude"
              />
            </LeafCell>
            <LeafCell width={1}>
              <TextField
                label="Owner 2 - name (checked on ID)"
                value={nestDeal.secondOwnerName}
                property="secondOwnerName"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="second-owner-name"
                className="fs-exclude"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <Checkbox
                label="Owner 1 - Certified ID (passport or driving license)"
                value={nestDeal.signedOffIdentity}
                property="signedOffIdentity"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="signed-off-identity"
              />
            </LeafCell>
            <LeafCell width={1}>
              <RadioButtons
                label="Owner 2 - Certified ID (passport or driving license)"
                value={nestDeal.signedOffIdentityOwnerTwo}
                property="signedOffIdentityOwnerTwo"
                options={yesNAOptions}
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="signed-off-identity-owner-two"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <DatePicker
                label="Owner 1 - Date of birth"
                value={nestDeal.ownerDob}
                property="ownerDob"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="owner-dob"
                className="fs-exclude"
              />
            </LeafCell>
            <LeafCell width={1}>
              <DatePicker
                label="Owner 2 - Date of birth"
                value={nestDeal.ownerTwoDob}
                property="ownerTwoDob"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="owner-two-dob"
                className="fs-exclude"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <Checkbox
                label="Certified proof of address"
                value={nestDeal.signedOffProofOfAddress}
                property="signedOffProofOfAddress"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="signed-off-proof-of-address"
              />
            </LeafCell>
            <LeafCell width={1}>
              <RadioButtons
                label="Certified proof of ownership (if not owner occupied)"
                value={nestDeal.signedOffProofOfOwnership}
                property="signedOffProofOfOwnership"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                options={yesNAOptions}
                data-test="signed-off-proof-of-ownership"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <Checkbox
                label="Check title (ownership, charges and lease length)"
                value={nestDeal.checkedTitle}
                property="checkedTitle"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="checked-title"
              />
            </LeafCell>
            <LeafCell width={1}>
              <TextArea
                label="Legal Address"
                value={nestDeal.legalAddress}
                property="legalAddress"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="legal-address"
                className="fs-exclude"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <Checkbox
                label="AML Checklist submitted"
                value={nestDeal.amlChecklistSubmitted}
                property="amlChecklistSubmitted"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="aml-checklist-submitted"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <DatePicker
                label="Date signed off by AML officer (including AML checklist)"
                value={nestDeal.signedOffByAmlOfficerDate}
                property="signedOffByAmlOfficerDate"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="signed-off-by-aml-officer-date"
              />
            </LeafCell>
          </Grid>
        )}
      </Mutation>
    )}
  </ExtendedQuery>
);

const mutationConfig =
  (
    mutate: MutationFunction<UpdateDealAMLChecks, UpdateDealAMLChecksVariables>,
    nestDeal: AMLQuery_nestDeal,
    id: string,
  ) =>
  (input) =>
    mutate({
      variables: {
        nestDeal: input,
        dealId: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          __typename: "NestDeal",
          id,
          ...nestDeal,
          ...input,
        },
      },
    });
