// @flow
import { gql, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { Button } from "../../../../components/Button/Button";

export const WAKE_BPI_MUTATION = gql`
  mutation WakeBPI($id: ID!) {
    wakeBuyerPropertyInterest(id: $id) {
      id
      snooze {
        id
        snoozedUntil
      }
    }
  }
`;

const buttonStyle = css`
  height: 32px;
  width: auto;
`;

type Props = {
  bpiId: string,
};

export const WakeButton = ({ bpiId }: Props) => {
  const [wakeMutation, { loading: wakeLoading }] =
    useMutation(WAKE_BPI_MUTATION);

  const wake = () =>
    wakeMutation({
      variables: {
        id: bpiId,
      },
      optimisticResponse: {
        wakeBuyerPropertyInterest: {
          __typename: "BuyerPropertyInterest",
          id: bpiId,
          snooze: null,
        },
      },
    });

  return (
    <Button
      data-test="wake-button"
      disabled={wakeLoading}
      buttonStyle="white"
      onClick={wake}
      css={buttonStyle}
    >
      Wake
    </Button>
  );
};
