// @flow
import { gql } from "@apollo/client";
import { kebabCase } from "lodash";
import { type MutationFunction } from "@apollo/client/react/components";

import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { SelectField } from "components/SelectField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const SA_PROPERTY_INFO_QUERY = gql`
  query SaPropertyInfo($id: ID!) {
    nestDeal(id: $id) {
      id
      borough
      councilTaxBand
      councilTaxPerAnnum
      stampDutyAtListPrice
    }
  }
`;

const SA_PROPERTY_INFO_MUTATION = gql`
  mutation UpdateSaPropertyInfo($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      borough
      councilTaxBand
      councilTaxPerAnnum
      stampDutyAtListPrice
    }
  }
`;

export function PropertyInfo({
  parentId: externalDealId,
}: {|
  parentId: string,
|}) {
  return (
    <ExtendedQuery
      query={SA_PROPERTY_INFO_QUERY}
      variables={{ id: externalDealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<SaPropertyInfo>) => (
        <ExtendedMutation mutation={SA_PROPERTY_INFO_MUTATION}>
          {(
            updateSaPropertyInfo: MutationFunction<
              UpdateSaPropertyInfo,
              UpdateSaPropertyInfoVariables,
            >,
          ) => {
            const fieldProps = (key) => ({
              "data-test": `property-info:${kebabCase(key)}`,
              property: key,
              value: nestDeal[key],
              mutation: (input) =>
                updateSaPropertyInfo({
                  variables: {
                    id: nestDeal.id,
                    input,
                  },
                  optimisticResponse: {
                    updateNestDeal: {
                      ...nestDeal,
                      ...input,
                    },
                  },
                }),
            });

            const councilTaxBandOptions = [
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
            ];
            const boroughOptions = [
              "Barking and Dagenham",
              "Barnet",
              "Bexley",
              "Brent",
              "Bromley",
              "Camden",
              "City of London",
              "Croydon",
              "Ealing",
              "Enfield",
              "Greenwich",
              "Hackney",
              "Hammersmith and Fulham",
              "Haringey",
              "Harrow",
              "Havering",
              "Hillingdon",
              "Hounslow",
              "Islington",
              "Kensington and Chelsea",
              "Kingston upon Thames",
              "Lambeth",
              "Lewisham",
              "Merton",
              "Newham",
              "Redbridge",
              "Richmond upon Thames",
              "Southwark",
              "Sutton",
              "Tower Hamlets",
              "Waltham Forest",
              "Wandsworth",
              "Westminster",
            ];

            return (
              <Grid columns={4}>
                <LeafCell width={2}>
                  <SelectField
                    {...fieldProps("borough")}
                    label="Borough"
                    options={boroughOptions}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <SelectField
                    {...fieldProps("councilTaxBand")}
                    label="Council tax band"
                    options={councilTaxBandOptions}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <CurrencyField
                    {...fieldProps("councilTaxPerAnnum")}
                    label="Council tax per annum"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <CurrencyField
                    {...fieldProps("stampDutyAtListPrice")}
                    label="Stamp duty (calculated from the latest list price)"
                    disabled
                  />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
}
