// @flow
import { useState } from "react";
import styled from "styled-components";
import { Button } from "components/Buttons";
import { NoSubmitSelectField } from "components/SelectField";
import moment from "moment-timezone";
import { type MutationFunction } from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { Grid, Cell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { EditableField } from "./ComparableCardComponents";

type Props = {
  comparable: ListComparablesQuery_comparables,
  mutation?: (EditComparableInput) => void,
};

const Wrapper = styled(Grid)`
  width: 100%;
  padding: 3px;
  border: ${({ pristine }) => (pristine ? "none" : "solid 1px red")};
`;

const EditableDateField = styled(NoSubmitDatePicker)`
  max-width: 200px;
  align-self: unset;
  input {
    width: 150px;
  }
`;

const SaveButton = styled(Button)`
  margin: 0 0 0 auto;
  background-color: ${({ theme }) => theme.button.green};
`;

export const EDIT_COMPARABLE_STATUS = gql`
  mutation editComparableStatus($id: ID!, $input: EditComparableInput!) {
    editComparable(id: $id, input: $input) {
      id
      status
      underOfferDate
      underOfferPrice
      soldDate
      soldPrice
    }
  }
`;

export const ComparableStatusEdit = ({ comparable }: Props) => {
  const initialState = {
    status: comparable.status,
    underOfferPrice: comparable.underOfferPrice,
    underOfferDate:
      comparable.underOfferDate &&
      moment(comparable.underOfferDate).format("YYYY-MM-DD"),
    soldPrice: comparable.soldPrice,
    soldDate:
      comparable.soldDate && moment(comparable.soldDate).format("YYYY-MM-DD"),
  };

  const [formStatus, setFormStatus] = useState(initialState);
  const [pristine, setPristine] = useState(true);

  const updateFormState = (value) => {
    setPristine(false);
    setFormStatus({ ...formStatus, ...value });
  };

  const submitHandler = (mutation) => {
    const input = { ...formStatus };

    if (formStatus.status !== "UO") {
      input.underOfferPrice = null;
      input.underOfferDate = null;
    }

    if (formStatus.status !== "Sold") {
      input.soldPrice = null;
      input.soldDate = null;
    }

    return mutation && mutation(input);
  };
  return (
    <Wrapper columns={4} pristine={pristine}>
      <ExtendedMutation
        mutation={EDIT_COMPARABLE_STATUS}
        onCompleted={() => setPristine(true)}
      >
        {(
          editComparable: MutationFunction<
            editComparableStatus,
            editComparableStatusVariables,
          >,
        ) => {
          const mutation = (input) =>
            editComparable({
              variables: {
                id: comparable.id,
                input,
              },
              optimisticResponse: {
                editComparable: {
                  __typename: "Comparable",
                  id: comparable.id,
                  status: comparable.status,
                  underOfferDate: comparable.underOfferDate,
                  underOfferPrice: comparable.underOfferPrice,
                  soldPrice: comparable.soldPrice,
                  soldDate: comparable.soldDate,
                },
              },
            });
          return (
            <>
              <EditableField
                data-test="select-status-field"
                as={NoSubmitSelectField}
                dark
                nullable={false}
                value={formStatus.status}
                onSubmit={(value) =>
                  value !== formStatus.status
                    ? updateFormState({ status: value })
                    : undefined
                }
                options={["OTM", "Sold", "UO", "Withdrawn"]}
                searchable={false}
              />

              {formStatus.status === "UO" && (
                <>
                  <EditableField
                    data-test="under-offer-price-field"
                    as={NoSubmitCurrencyField}
                    placeholder="£ price"
                    dark
                    value={formStatus.underOfferPrice}
                    onSubmit={(value) => {
                      updateFormState({ underOfferPrice: value });
                    }}
                    isSecondary
                    highlight
                  />
                  <EditableDateField
                    data-test="under-offer-date-field"
                    as={NoSubmitDatePicker}
                    dark
                    property="underOfferDate"
                    highlight
                    isSecondary
                    value={formStatus.underOfferDate}
                    onSubmit={(value) => {
                      updateFormState({ underOfferDate: value });
                    }}
                    placeholder="Under offer date"
                  />
                </>
              )}
              {formStatus.status === "Sold" && (
                <>
                  <EditableField
                    data-test="sold-price-field"
                    as={NoSubmitCurrencyField}
                    placeholder="£ price"
                    dark
                    property="soldPrice"
                    value={formStatus.soldPrice}
                    onSubmit={(value) => {
                      updateFormState({ soldPrice: value });
                    }}
                    isSecondary
                    highlight
                  />
                  <EditableDateField
                    data-test="sold-date-field"
                    as={NoSubmitDatePicker}
                    dark
                    property="soldDate"
                    highlight
                    isSecondary
                    value={formStatus.soldDate}
                    onSubmit={(value) => {
                      updateFormState({ soldDate: value });
                    }}
                    placeholder="Sold date"
                  />
                </>
              )}
              <Cell width={1}>
                <SaveButton
                  onClick={() => {
                    submitHandler(mutation);
                  }}
                  data-test="save-status-button"
                >
                  Save
                </SaveButton>
              </Cell>
            </>
          );
        }}
      </ExtendedMutation>
    </Wrapper>
  );
};
