// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Breadcrumbs,
  Breadcrumb,
} from "../../components/Navigation/Breadcrumbs";
import { Tabs, Tab } from "../../components/Navigation/Tabs";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { CompletedListView } from "./CompletedListView";
import { ExchangedListView } from "./ExchangedListView";
import { ListViewPageWrapper } from "../../components/ListViewSharedStyles";

export const Sold = () => {
  const { exchanged, completed } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Sellers</Breadcrumb>
        <Breadcrumb to="#">Sold</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/sold/exchanged" count={exchanged}>
          Exchanged
        </Tab>
        <Tab to="/sold/completed" count={completed}>
          Completed
        </Tab>
      </Tabs>
      <Switch>
        <Redirect from="/sold" to="/sold/exchanged" exact />
        <Route path="/sold/completed" component={CompletedListView} />
        <Route path="/sold/exchanged" component={ExchangedListView} />
      </Switch>
    </ListViewPageWrapper>
  );
};
