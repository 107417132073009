// @flow
import { gql } from "@apollo/client";

import { Card } from "components/Card";
import { Grid, Cell } from "components/Grid";
import { Heading } from "components/Heading";
import { DebtFundingCommitmentList } from "tabs/AdvanceValuationsAndLegal/DebtFundingCommitmentList/DebtFundingCommitmentList";
import { formatNumberAsCurrency } from "@nest-ui/hocs";

import { ReadOnlyField } from "components/ReadOnlyField";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const DEBT_FUNDING_QUERY = gql`
  query DebtFundingQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      debtSignedOff
      requestedLoanAmount
      signedOffValuation
      latestGuaranteeAgreement {
        id
        amount
      }
      amountToRequestFromDebtProviders
      outstandingDebt
    }
  }
`;

type Props = {
  parentId: string,
};

export const DebtFundingSection = ({ parentId: dealId }: Props) => (
  <section id="debt_funding">
    <Heading>Debt Funding</Heading>
    <Card>
      <ExtendedQuery query={DEBT_FUNDING_QUERY} variables={{ dealId }}>
        {({ nestDeal }: ExtendedQueryRenderProps<DebtFundingQuery>) => (
          <Grid columns={4}>
            <Cell width={1}>
              <ReadOnlyField
                data-test="debt_funding:debt_signed_off"
                label="Debt signoff"
                value={nestDeal.debtSignedOff ? "Sent" : "Not sent"}
              />
            </Cell>
            <Cell width={1} left={1}>
              <ReadOnlyField
                data-test="debt_funding:requested_loan_amount"
                label="Maximum Debt Funding Amount"
                value={formatNumberAsCurrency(nestDeal.requestedLoanAmount)}
              />
            </Cell>
            <Cell width={1}>
              <ReadOnlyField
                data-test="debt_funding:signed_off_valuation"
                label="Signed off valuation"
                value={formatNumberAsCurrency(nestDeal.signedOffValuation)}
              />
            </Cell>
            <Cell width={1}>
              <ReadOnlyField
                data-test="debt_funding:guarantee_amount"
                label="Guarantee amount"
                value={formatNumberAsCurrency(
                  nestDeal.latestGuaranteeAgreement?.amount,
                )}
              />
            </Cell>
            <Cell width={1}>
              <ReadOnlyField
                data-test="debt_funding:amount_to_request_from_debt_providers"
                label="80% of signed off valuation"
                value={formatNumberAsCurrency(
                  nestDeal.amountToRequestFromDebtProviders,
                )}
              />
            </Cell>
            <Cell width={4}>
              <DebtFundingCommitmentList parentId={dealId} />
            </Cell>
            <Cell width={1}>
              <ReadOnlyField
                data-test="debt_funding:outstanding_debt"
                label="Debt outstanding to repay"
                value={formatNumberAsCurrency(nestDeal.outstandingDebt)}
              />
            </Cell>
          </Grid>
        )}
      </ExtendedQuery>
    </Card>
  </section>
);
