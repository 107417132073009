// @flow
import { css } from "styled-components";
import { media, Gravatar } from "@nested/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faMask,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../hooks/useUser";
import { UserList } from "../UserList";

const nameStyle = css`
  color: white;
  font-weight: 500;
  margin-right: 15px;
  display: none;
  white-space: nowrap;
  ${media.tablet`
    display: inline; 
  `}
`;

const gravatarStyle = css`
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 100%;
  margin: 0;
`;

const caretStyle = css`
  margin-left: 15px;
  color: white;
  opacity: 0.7;
`;

const maskWrapper = css`
  position: absolute;
  top: -3px;
  left: -7px;
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 100%;
`;

export const UserSelect = () => {
  const { selectedUser, setSelectedUser, currentUser } = useUser();

  const viewAsButton = ({ isOpen }) => (
    <>
      <div data-test="user-select:selected-user" css={nameStyle}>
        {selectedUser.firstName}
      </div>
      <div css="position: relative;">
        {selectedUser.email !== currentUser.email && (
          <div css={maskWrapper}>
            <FontAwesomeIcon size="sm" icon={faMask} />
          </div>
        )}
        <Gravatar css={gravatarStyle} email={selectedUser.email} />
      </div>
      {currentUser.isNestAdmin && (
        <div css={caretStyle}>
          <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
        </div>
      )}
    </>
  );

  return (
    <UserList
      onSelect={setSelectedUser}
      openListButton={viewAsButton}
      selectedEmail={selectedUser.email}
      disabled={!currentUser.isNestAdmin}
    />
  );
};
