// @flow
import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { REACTIVATION, DEACTIVATION, DISINSTRUCTION } from "./DealStatusForms";

export const DEAL_STATUS_QUERY = gql`
  query DealStatus($id: ID!) {
    nestDeal(id: $id) {
      id
      active
      preCloseFalloutReason {
        label
      }
      opportunityStatus {
        valueText
      }
    }
  }
`;

export const REACTIVATION_MUTATION = gql`
  mutation ReactivateDeal($input: ReactivateDealInput!) {
    reactivateDeal(input: $input) {
      id
      active
    }
  }
`;

type Props = {
  id: string,
  active: boolean,
};

type UseDealStatusReturnValue = {
  closeForm: () => void,
  closeFormAndResetToggle: () => void,
  formType: ?string,
  isToggleYes: boolean,
  setIsToggleYes: (v: boolean) => void,
  switchToggleAndOpenFormOrReactivateDeal: () => void,
  titleOnHover: ?string,
};

export const useDealStatus = ({
  id,
  active,
}: Props): UseDealStatusReturnValue => {
  const [isToggleYes, setIsToggleYes] = useState(active);
  const [formType, setFormType] = useState(null);
  const { data, loading } = useQuery(DEAL_STATUS_QUERY, { variables: { id } });
  const [reactivateDeal, { error: mutationError }] = useMutation(
    REACTIVATION_MUTATION,
  );

  const { createNotification } = useNotifications();

  useEffect(() => {
    setIsToggleYes(data?.nestDeal?.active);
  }, [data]);

  if (loading) {
    return {};
  }

  const titleOnHover = data?.nestDeal?.preCloseFalloutReason?.label;
  const opportunityStatusValue = data?.nestDeal?.opportunityStatus?.valueText;

  const closeForm = () => {
    setFormType(null);
  };

  const closeFormAndResetToggle = () => {
    setIsToggleYes(data?.nestDeal?.active);
    setFormType(null);
  };

  const openForm = () => {
    if (!data?.nestDeal?.active) {
      setFormType(REACTIVATION);
    } else if (opportunityStatusValue >= "s07_closed") {
      setFormType(DISINSTRUCTION);
    } else {
      setFormType(DEACTIVATION);
    }
  };

  const reactivate = async () => {
    try {
      const result = await reactivateDeal({
        variables: { input: { dealId: id } },
        optimisticResponse: {
          reactivateDeal: {
            __typename: "NestDeal",
            id,
            active: true,
          },
        },
      });
      if (result?.data?.reactivateDeal.active) {
        createNotification("Reactivated");
      } else {
        errorHandler(mutationError);
        return null;
      }
    } catch (e) {
      errorHandler(e);
    }
    return null;
  };

  const switchToggleAndOpenFormOrReactivateDeal = () => {
    if (!data?.nestDeal?.active) {
      reactivate();
    } else if (opportunityStatusValue === "s09_under_offer") {
      window.alert(
        'Before marking the seller as inactive, please change the offer status to "No Longer Accepted".',
      );
      return;
    } else if (opportunityStatusValue >= "s07_closed") {
      setFormType(DISINSTRUCTION);
      openForm();
    } else {
      setFormType(DEACTIVATION);
      openForm();
    }
    setIsToggleYes(!isToggleYes);
  };

  return {
    closeForm,
    closeFormAndResetToggle,
    formType,
    isToggleYes,
    setIsToggleYes,
    switchToggleAndOpenFormOrReactivateDeal,
    titleOnHover,
  };
};
