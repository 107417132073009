import T from "prop-types";
import styled, { css } from "styled-components";
import {
  compose,
  defaultProps,
  renameProp,
  withHandlers,
  withProps,
  withStateHandlers,
} from "recompose";
import { withReadOnly, withOnSubmit } from "@nest-ui/hocs";
import { Label } from "components/Label";
import { TickBox } from "./TickBox";

const Wrapper = styled.div`
  font-size: 12px;
  ${TickBox} {
    margin: 8px;
  }
`;

const checkedStyles = css`
  border: 2px solid
    ${({ focused, theme }) =>
      focused ? theme.color.primary.dark : theme.color.primary.highlight};
  background-color: ${({ theme }) => theme.color.primary.accent};
`;

const uncheckedStyles = css`
  border: 2px solid
    ${({ focused, theme }) =>
      focused ? theme.color.primary.dark : theme.color.secondary.highlight};
  background-color: white;
`;

const darkModeStyles = css`
  border: none;
  background-color: none;
`;

const greenModeStyles = css`
  border: 2px solid ${({ theme }) => theme.color.secondary.light};
  background-color: ${({ theme, checked }) =>
    checked ? "none" : theme.color.secondary.light};
  color: ${({ theme, checked }) =>
    checked ? theme.color.secondary.light : "white"};
  font-weight: 600;
`;

const ToggleBox = styled.label`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 100%;

  ${({ darkMode, greenMode, checked }) => {
    if (darkMode) return darkModeStyles;
    if (greenMode) return greenModeStyles;
    return checked ? checkedStyles : uncheckedStyles;
  }}
`;

const inputStyle = css`
  opacity: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0px;
  padding: 0px;
`;

export const enhance = compose(
  defaultProps({ optionName: "Done?" }),
  renameProp("value", "checked"),
  withStateHandlers(
    { focused: false },
    {
      onFocus: () => () => ({ focused: true }),
      onBlur: () => () => ({ focused: false }),
    },
  ),
  withHandlers({
    onChange:
      ({ onSubmit }) =>
      (e) =>
        onSubmit(e.target.checked),
  }),
  withProps(({ parentId, property, label }) => ({
    id: `${property}-${parentId || label}`,
  })),
  withReadOnly(({ checked, optionName }) =>
    checked ? optionName.replace("?", "") : "No",
  ),
);

export const NakedCheckbox = ({
  darkMode,
  disabled,
  id,
  label,
  focused,
  greenMode,
  onFocus,
  onBlur,
  onChange,
  checked,
  optionName,
  "data-test": dataTest,
}) => (
  <Wrapper>
    {Boolean(label) && <Label htmlFor={id}>{label}</Label>}

    <ToggleBox
      checked={checked}
      data-test={dataTest}
      focused={focused}
      htmlFor={id}
      darkMode={darkMode}
      greenMode={greenMode}
    >
      <TickBox checked={checked} focused={focused} greenMode={greenMode} />
      <input
        disabled={disabled}
        css={inputStyle}
        type="checkbox"
        checked={Boolean(checked)}
        onChange={onChange}
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {optionName}
    </ToggleBox>
  </Wrapper>
);

export const NoSubmitCheckbox = enhance(NakedCheckbox);

NoSubmitCheckbox.propTypes = {
  label: T.string,
  value: T.bool,
  onSubmit: T.func,
  property: T.string,
  optionName: T.string,
  focused: T.bool,
  setFocused: T.func,
};

export const Checkbox = withOnSubmit(NoSubmitCheckbox);
