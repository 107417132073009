// @flow
import type { MutationOperation } from "@apollo/client";
import { compose } from "recompose";

import { EditButton } from "components/DealDetailsBar/EditButton";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitPercentageField } from "components/PercentageField";
import { NoSubmitSelectField } from "components/SelectField";
import { NewEntry } from "components/NewEntry";
import { graphql } from "deal/utils/graphql";
import { DrawdownTimingFeesList } from "./DrawdownTimingFeesList";
import createDrawdownTimingFeeMutation from "./mutations/createDrawdownTimingFee.graphql";
import { createDrawdownTimingFeeMutationConfig } from "./mutations/config";

type EditableProps = {
  dayChoices: Choice[],
  drawdownTimingFees: $ReadOnlyArray<DrawdownTimingFee>,
  dealId: ID,
  createDrawdownTimingFee: (dealId: ID) => ({
    days: number,
    fee: string,
  }) => MutationOperation<CreateDrawdownTimingFee_createDrawdownTimingFee>,
  deleteDrawdownTimingFee: (id: ID) => () => DeleteDrawdownTimingFee,
  updateDrawdownTimingFee: (
    id: ID,
  ) => (input: UpdateDrawdownTimingFeeInput) => UpdateDrawdownTimingFee,
  toggleEditing: () => void,
};

function DrawdownTimingFeesEditableState({
  dayChoices,
  dealId,
  drawdownTimingFees,
  toggleEditing,
  createDrawdownTimingFee,
}: EditableProps) {
  /**
   * Remove all day choice options which are already present in the existing
   * entries. This is so that we can only show the available day choice options
   * in the drop down.
   */
  const remainingDayChoices = dayChoices.filter(
    (dayChoice) =>
      !drawdownTimingFees.find(
        (drawdownTimingFee) => dayChoice.value === drawdownTimingFee.days,
      ),
  );

  /**
   * If there are no remaining day choices it means that a new edition would be a
   * duplicate. Therefore we don't want to show an "Add ..." button.
   */
  const hasAvailableDayOptions = remainingDayChoices.length > 0;

  return (
    <div>
      <EditButton
        data-test="drawdown-timing-fees-edit-button"
        onClick={toggleEditing}
      >
        Finished
      </EditButton>

      <DrawdownTimingFeesList
        dayChoices={remainingDayChoices}
        dealId={dealId}
        drawdownTimingFees={drawdownTimingFees}
      />

      {hasAvailableDayOptions && (
        <NewEntry
          createMutation={createDrawdownTimingFee(dealId)}
          itemName="drawdown-timing-fee"
          requiredFields={["days", "fee"]}
        >
          {({ update, values }) => (
            <Grid columns={4}>
              <LeafCell>
                <NoSubmitSelectField
                  data-test="drawdown-timing-fees-days-new-entry"
                  label="Expected drawdown on or after day..."
                  onSubmit={update("days")}
                  options={remainingDayChoices}
                  searchable
                  value={values.days}
                />
              </LeafCell>
              <LeafCell>
                <NoSubmitPercentageField
                  data-test="drawdown-timing-fees-fee-new-entry"
                  label="Drawdown timing fee %"
                  onSubmit={update("fee")}
                  value={values.fee}
                />
              </LeafCell>
            </Grid>
          )}
        </NewEntry>
      )}
    </div>
  );
}

const enhance = compose(
  graphql(
    createDrawdownTimingFeeMutation,
    createDrawdownTimingFeeMutationConfig,
  ),
);

export const DrawdownTimingFeesComponentEditable = enhance(
  DrawdownTimingFeesEditableState,
);
