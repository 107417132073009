// @flow
import styled from "styled-components";
import { largeHeading, regularHeading, media } from "@nested/brand";

export const Heading = styled.h1`
  ${regularHeading}
  ${media.tablet`
    ${largeHeading}
  `}
`;
