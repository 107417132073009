// @flow
import { AdvancesForAllMarch2019PropertySaleRedemption } from "tabs/Payments/PropertySaleRedemption/DealType/AdvancesForAllMarch2019PropertySaleRedemption";
import { AdvanceMay2018 } from "./DealType/AdvanceMay2018";
import { DeleteMe } from "./DealType/DeleteMe";

type Props = { dealId: string, dealTypeValue: string, dealTypeId?: string };
export const PropertySaleRedemption = ({
  dealId,
  dealTypeValue,
  dealTypeId,
}: Props) => {
  switch (dealTypeValue) {
    case "advance_may2018":
      return <AdvanceMay2018 dealId={dealId} />;
    case "advances_for_all_march2019":
      return (
        <AdvancesForAllMarch2019PropertySaleRedemption
          dealId={dealId}
          // This dealTypeId is only ever used when the deal type is advances_for_all_march2019
          // and if the deal type is that there will be an id for it, meaning we'll only ever use it
          // if it is non null. But I don't know how to tell flow that.
          // $FlowFixMe
          dealTypeId={dealTypeId}
        />
      );
    default:
      // This default component should get removed once we've added all other
      // deal types and replaced with a sensible "Sorry..." message and a
      // sentry.
      return <DeleteMe dealId={dealId} />;
  }
};
