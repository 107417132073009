import { useState } from "react";
import { css } from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";
import { Grid, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card/ListEntryCard";
import { NoSubmitCreateButton } from "components/CreateButton/CreateButton";
import { CancelButton } from "components/Buttons";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { Delete } from "@nest-ui/icons";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitTextField } from "components/TextField";
import { UPDATE_BUYERS_REPORT } from "../UpdateBuyersReport";
import {
  CreateListingHistoryEvent,
  sanitizeListingHistoryEvent,
} from "../CreateListingHistoryEvent";

const noMargin = css`
  margin: 0;
`;

export const ListingHistoryEvents = ({
  listingHistoryEvents,
  reportId,
  eventTypeOptions,
}) => {
  const [creating, setCreating] = useState(false);
  const [updateBuyersReport] = useMutation(UPDATE_BUYERS_REPORT);

  const deleteEvent = async (indexToDelete) => {
    try {
      const events = listingHistoryEvents
        .filter((e, index) => index !== indexToDelete)
        .map(sanitizeListingHistoryEvent);
      await updateBuyersReport({
        variables: {
          id: reportId,
          input: {
            listingHistoryEvents: events,
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(e);
    }
  };

  return (
    <Grid columns={4} data-test="listing-event-history-section">
      {listingHistoryEvents.map(
        ({ eventType: { label }, eventDate, price }, index) => (
          // eslint-disable-next-line
          <LeafCell key={index} width={4}>
            <ListEntryCard data-test="listing-history-item" css={noMargin}>
              <Grid columns={4}>
                <LeafCell width={1}>
                  <NoSubmitTextField
                    disabled
                    value={label}
                    label="Event type"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitDatePicker
                    disabled
                    value={eventDate}
                    label="Event date"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitCurrencyField
                    data-test="event-item-price-field"
                    disabled
                    value={price}
                    label="Price following event"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <ButtonWithIcon
                    icon={Delete}
                    data-test="delete-listing-history-event"
                    onSubmit={() => {
                      // eslint-disable-next-line no-alert
                      const confirmation = window.confirm(
                        "Are you sure you want to delete this event?",
                      );
                      if (confirmation) {
                        deleteEvent(index);
                      }
                    }}
                  />
                </LeafCell>
              </Grid>
            </ListEntryCard>
          </LeafCell>
        ),
      )}

      {creating && (
        <LeafCell width={4}>
          <CreateListingHistoryEvent
            listingHistoryEvents={listingHistoryEvents}
            reportId={reportId}
            onClose={() => setCreating(false)}
            eventTypeOptions={eventTypeOptions}
          />
        </LeafCell>
      )}

      {!creating && (
        <LeafCell width={1} left={4}>
          <NoSubmitCreateButton
            onSubmit={() => setCreating(true)}
            label="Add new"
            data-test="add-listing-history-button"
          />
        </LeafCell>
      )}
      {creating && (
        <LeafCell width={1} left={4}>
          <CancelButton
            onClick={() => setCreating(false)}
            data-test="add-listing-history-button"
            css={noMargin}
          >
            Cancel
          </CancelButton>
        </LeafCell>
      )}
    </Grid>
  );
};
