// @flow
import { theme } from "@nest-ui/styles/theme";

export const DetailsIcon = ({
  fill = theme.color.text.muted,
}: {
  fill?: string,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g fill={fill} fillRule="nonzero">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0-1A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
      <circle cx="1" cy=".75" r="1" transform="translate(7 5)" />
      <path d="M7.5 7h1v4h-1z" />
    </g>
  </svg>
);

export const Info = DetailsIcon;
export const InfoIcon = DetailsIcon;
