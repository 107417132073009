// @flow
import { gql } from "@apollo/client";
import React from "react";

import { Card } from "components/Card";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { H2 } from "components/Heading";
import { Modal } from "components/Modal";
import { TextArea } from "components/TextArea";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const PROPERTY_ACCESS_ARRANGEMENTS_QUERY = gql`
  query PropertyAccessArrangementsQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      propertyAccessArrangements
    }
  }
`;

const UPDATE_PROPERTY_ACCESS_ARRANGEMENTS_MUTATION = gql`
  mutation UpdatePropertyAccessArrangements(
    $dealId: ID!
    $input: NestDealInput!
  ) {
    updateNestDeal(nestDeal: $input, id: $dealId) {
      id
      propertyAccessArrangements
    }
  }
`;

type Props = {
  dealId: string,
};

type State = {
  editing: boolean,
};

export class PropertyAccessArrangements extends React.Component<Props, State> {
  state = {
    editing: false,
  };

  closeModal = () => this.setState({ editing: false });

  openModal = () => this.setState({ editing: true });

  render() {
    const { dealId } = this.props;
    const { editing } = this.state;

    return (
      <Card>
        <H2>Access arrangements</H2>
        <EditButton
          onClick={this.openModal}
          data-test="sa-deal-details:property-access-arrangements-edit-button"
        >
          Edit
        </EditButton>
        <ExtendedQuery
          query={PROPERTY_ACCESS_ARRANGEMENTS_QUERY}
          variables={{ dealId }}
        >
          {({
            nestDeal: { propertyAccessArrangements },
          }: ExtendedQueryRenderProps<PropertyAccessArrangementsQuery>) => (
            <>
              <TextArea
                data-test="sa-deal-details:property-access-arrangements-read-only"
                value={propertyAccessArrangements}
                readOnly
              />
              <Modal
                title="Access arrangements"
                closeModal={this.closeModal}
                isOpen={editing}
              >
                <ExtendedMutation
                  mutation={UPDATE_PROPERTY_ACCESS_ARRANGEMENTS_MUTATION}
                >
                  {(
                    mutation: MutationFunction<
                      UpdatePropertyAccessArrangements,
                      UpdatePropertyAccessArrangementsVariables,
                    >,
                  ) => (
                    <TextArea
                      data-test="sa-deal-details:property-access-arrangements-editable"
                      label="Access arrangements"
                      mutation={(input) =>
                        mutation({
                          variables: { dealId, input },

                          optimisticResponse: {
                            __typename: "Mutation",
                            updateNestDeal: {
                              __typename: "NestDeal",
                              id: dealId,
                              ...input,
                            },
                          },
                        })
                      }
                      property="propertyAccessArrangements"
                      value={propertyAccessArrangements}
                    />
                  )}
                </ExtendedMutation>
              </Modal>
            </>
          )}
        </ExtendedQuery>
      </Card>
    );
  }
}
