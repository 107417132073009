// @flow

import { Link } from "react-router-dom";
import styled from "styled-components";
import { Grid, Cell } from "components/Grid";
import { withRouter, type Match } from "react-router";

import { Header as RawHeader } from "components/Header";
import { Button as StandardButton } from "components/Buttons";
import { H1 } from "components/Heading";

import { Arrow, Add } from "@nest-ui/icons";

import { theme as comparablesTheme } from "../theme";

const Button = styled(StandardButton)`
  min-width: 0;
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  grid-column-gap: 16px;
  grid-row-gap: 0;
  height: 100%;
`;

const StyledHeader = styled(RawHeader)`
  background-color: ${({ theme }) => theme.background.header};
  border-bottom: 0;
  color: ${({ theme }) => theme.text.white};
  min-height: 64px;
  padding-right: 16px;
`;

const StyledH1 = styled(H1)`
  color: ${({ theme }) => theme.text.white};
`;

const PlainLink = styled(Link)`
  text-decoration: none;
`;

const CloseButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.grey};

  &:hover {
    background-color: ${({ theme }) => theme.button.greyHover};
  }

  svg {
    transform: rotate(45deg);
  }
`;

const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.grey};

  &:hover {
    background-color: ${({ theme }) => theme.button.grey};
  }

  svg {
    transform: rotate(90deg);
  }
`;

const NextButton = styled(Button)`
  svg {
    transform: rotate(270deg);
  }
`;

const ButtonLabel = styled.span`
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
`;

const DisabledBackButton = () => (
  <BackButton disabled>
    <Arrow fill={comparablesTheme.text.white} />
    <ButtonLabel>Back</ButtonLabel>
  </BackButton>
);

const EnabledBackButton = ({ location }: { location: string }) => (
  <PlainLink to={location}>
    <BackButton>
      <Arrow fill={comparablesTheme.text.white} />
      <ButtonLabel>Back</ButtonLabel>
    </BackButton>
  </PlainLink>
);

const DisabledNextButton = () => (
  <NextButton disabled>
    <ButtonLabel>Next</ButtonLabel>
    <Arrow fill={comparablesTheme.text.white} />
  </NextButton>
);

const EnabledNextButton = ({ location }: { location: string }) => (
  <PlainLink to={location}>
    <NextButton>
      <ButtonLabel>Next</ButtonLabel>
      <Arrow fill={comparablesTheme.text.white} />
    </NextButton>
  </PlainLink>
);

type Props = {
  backDisabled?: boolean,
  closeUrl: string,
  match: Match<*>,
  nextDisabled?: boolean,
};

export const Header = withRouter(
  ({ backDisabled, closeUrl, nextDisabled, match: { url } }: Props) => {
    const baseUrl = url.replace(/\/comparables.*$/, "");
    const listUrl = `${baseUrl}/comparables`;
    const selectedUrl = `${baseUrl}/comparables/selected`;

    return (
      <StyledHeader>
        <StyledGrid columns={8}>
          <Cell width={5}>
            <StyledH1>Comparable Picker</StyledH1>
          </Cell>

          <Cell width={1}>
            <PlainLink to={closeUrl}>
              <CloseButton>
                <Add fill={comparablesTheme.text.white} />
                <ButtonLabel>Close</ButtonLabel>
              </CloseButton>
            </PlainLink>
          </Cell>

          <Cell width={1}>
            {backDisabled ? (
              <DisabledBackButton />
            ) : (
              <EnabledBackButton location={listUrl} />
            )}
          </Cell>

          <Cell width={1}>
            {nextDisabled ? (
              <DisabledNextButton />
            ) : (
              <EnabledNextButton location={selectedUrl} />
            )}
          </Cell>
        </StyledGrid>
      </StyledHeader>
    );
  },
);
