// @flow
import styled from "styled-components";

export const Label = styled.label`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: 4px;
  display: block;
`;

export const OriginalLabel = Label;
