// @flow
import React from "react";
import { H2 } from "components/Heading";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCheckbox } from "components/Checkbox/Checkbox";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { Button } from "components/Buttons";
import { gql } from "@apollo/client";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";
import { FullWidthCancelButton } from "../Buttons/FullWidthCancelButton";

const UPDATE_ADVANCE_DRAWDOWN_REQUEST_PROJECTED_DATE = gql`
  mutation UpdateAdvanceDrawdownRequestProjectedDate(
    $input: UpdateAdvanceDrawdownRequestProjectedDateInput!
  ) {
    updateAdvanceDrawdownRequestProjectedDate(input: $input) {
      id
      drawdownProjectedDate
      drawdownProjectedDateConfirmed
    }
  }
`;

type State = {
  isEditing: boolean,
  projectedDate: ?string,
  projectedDateConfirmed: boolean,
  formIsDirty: boolean,
};

export class LegalServicesSection extends React.Component<*, State> {
  state = {
    isEditing: false,
    projectedDate: this.props.advanceDrawdownRequest.drawdownProjectedDate,
    projectedDateConfirmed:
      this.props.advanceDrawdownRequest.drawdownProjectedDateConfirmed || false,
    formIsDirty: false,
  };

  setIsEditing = (value: boolean) => {
    this.setState({ isEditing: value });
  };

  setProjectedDate = (value: string) => {
    this.setState({ projectedDate: value, formIsDirty: true });
  };

  setProjectedDateConfirmed = (value: boolean) => {
    this.setState({ projectedDateConfirmed: value, formIsDirty: true });
  };

  cancelEdit = () => {
    this.setState({
      projectedDate: this.props.advanceDrawdownRequest.drawdownProjectedDate,
      projectedDateConfirmed:
        this.props.advanceDrawdownRequest.drawdownProjectedDateConfirmed,
      formIsDirty: false,
      isEditing: false,
    });
  };

  render() {
    const {
      advanceDrawdownRequest: { id, drawdownProjectedDate, datePaymentMade },
      readOnly,
      refetchQueries,
    } = this.props;

    const inEditMode = this.state.isEditing || drawdownProjectedDate === null;

    const showButtons = this.state.formIsDirty || this.state.isEditing;

    const canSave = this.state.projectedDate !== null;

    return (
      // We position the grid because the EditButton below is absolutely positioned
      // and we want that to be absolutely positioned relative to the grid.
      <Grid columns={4} style={{ position: "relative" }}>
        <LeafCell width={4}>
          <H2>Legal Services</H2>
        </LeafCell>

        {!readOnly && !inEditMode && !datePaymentMade && (
          <EditButton
            style={{ top: "-8px", right: "-8px" }}
            data-test={`legal-services-section:${id}:edit-button`}
            onClick={() => this.setIsEditing(true)}
          >
            Edit
          </EditButton>
        )}

        <LeafCell width={2}>
          <NoSubmitDatePicker
            data-test={`legal-services-section:${id}:projected-date`}
            label="Projected drawdown date"
            disabled={readOnly || !inEditMode}
            value={this.state.projectedDate}
            onSubmit={this.setProjectedDate}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCheckbox
            data-test={`legal-services-section:${id}:projected-date-confirmed`}
            label="Projected drawdown date confirmed?"
            parentId={id}
            property="legal-services-section"
            optionName="Yes"
            disabled={readOnly || !inEditMode || !canSave}
            value={this.state.projectedDateConfirmed}
            onSubmit={this.setProjectedDateConfirmed}
          />
        </LeafCell>

        {!readOnly && showButtons && (
          <>
            <LeafCell width={2} left={1}>
              <ExtendedMutation
                awaitRefetchQueries
                mutation={UPDATE_ADVANCE_DRAWDOWN_REQUEST_PROJECTED_DATE}
                refetchQueries={refetchQueries}
              >
                {(
                  updateApprovalOfAdvanceDrawdownRequest: MutationFunction<UpdateAdvanceDrawdownRequestProjectedDate>,
                ) => (
                  <Button
                    data-test={`legal-services-section:${id}:save-button`}
                    disabled={!canSave}
                    onClick={async () => {
                      const result =
                        await updateApprovalOfAdvanceDrawdownRequest({
                          variables: {
                            input: {
                              id,
                              drawdownProjectedDate: this.state.projectedDate,
                              drawdownProjectedDateConfirmed:
                                this.state.projectedDateConfirmed,
                            },
                          },
                        });

                      if (result) {
                        this.setState({ formIsDirty: false, isEditing: false });
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </ExtendedMutation>
            </LeafCell>
            <LeafCell width={2}>
              <FullWidthCancelButton
                data-test={`legal-services-section:${id}:cancel-button`}
                onClick={this.cancelEdit}
              >
                Cancel
              </FullWidthCancelButton>
            </LeafCell>
          </>
        )}
      </Grid>
    );
  }
}

// We set the display name so that we can still switch on the component name in prod builds
LegalServicesSection.displayName = "LegalServicesSection";
