export const legacyGuaranteeQueryConfig = {
  options(props) {
    return {
      variables: {
        id: props.dealId,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    };
  },
};
