export const valuerSectionMutationConfig = {
  props({
    mutate,
    ownProps: {
      parentId,
      data: { valuationTypes, nestDeal },
    },
  }) {
    return {
      updateNestDeal: (input) => {
        const formattedInput = {
          valuationType: valuationTypes
            .map((vt) => ({
              value: vt.value,
              __typename: "ValuationType",
            }))
            .find(
              ({ value: optionValue }) => optionValue === input.valuationType,
            ),
          assignedVa: {
            __typename: "NestedUser",
            id: input.assignedVaId || nestDeal.assignedVa.id,
          },
        };
        return mutate({
          variables: {
            input,
            id: parentId,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateNestDeal: {
              __typename: "NestDeal",
              ...nestDeal,
              ...input,
              ...formattedInput,
            },
          },
        });
      },
    };
  },
};
