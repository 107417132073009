// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { css } from "styled-components";

import { Grid, LeafCell } from "components/Grid";
import { NewEntry } from "components/NewEntry";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";

import { PropertyListingPriceCard } from "./PropertyListingPriceCard";

const CONFIRMATION_MESSAGE = `Please ensure you’ve already made this change on Rightmove and that the listing price and date exactly match the Rightmove listing.

This will create a new ‘Action plan’ in the customer account, and task the area SAs to call and email the buyer database in Close.

Are you sure you want to proceed?`;

const LISTING_PRICE_FRAGMENT = gql`
  fragment propertyListingPriceFragment on PropertyListingPrice {
    amount
    dealId
    id
    startDate
  }
`;

const CREATE_LISTING_PRICE_MUTATION = gql`
  mutation CreateListingPrice($dealId: ID!, $input: ListingPriceInput!) {
    createListingPrice(dealId: $dealId, input: $input) {
      id
    }
  }
`;

const LISTING_PRICES_QUERY = gql`
  query ListingPrices($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      propertyListingPrices {
        id
        ...propertyListingPriceFragment
      }
    }
  }
  ${LISTING_PRICE_FRAGMENT}
`;

type Props = { dealId: string };

export const PropertyListingPriceList = (props: Props) => {
  const { dealId } = props;
  const [createListingPrice] = useMutation(CREATE_LISTING_PRICE_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LISTING_PRICES_QUERY,
        variables: {
          dealId,
        },
      },
    ],
  });

  return (
    <div
      css={css`
        display: block;
      `}
    >
      <ExtendedQuery query={LISTING_PRICES_QUERY} variables={{ dealId }}>
        {({
          nestDeal: { propertyListingPrices },
        }: ExtendedQueryRenderProps<ListingPrices>) => (
          <div data-test="property-listing-price-list">
            {propertyListingPrices.map((propertyListingPrice) => (
              <PropertyListingPriceCard
                key={propertyListingPrice.id}
                propertyListingPrice={propertyListingPrice}
              />
            ))}
          </div>
        )}
      </ExtendedQuery>

      <NewEntry
        createMutation={async (input) => {
          // eslint-disable-next-line
          if (window.confirm(CONFIRMATION_MESSAGE)) {
            return createListingPrice({
              variables: {
                input,
                dealId,
              },
            });
          }
          return undefined;
        }}
        itemName="listing-price"
        requiredFields={["amount", "startDate"]}
        useSaveButton
      >
        {({ update, values }) => (
          <Grid css="width: 100%;" columns={4}>
            <LeafCell css="height: auto;">
              <NoSubmitCurrencyField
                data-test="listing-prices-amount-new-entry"
                label="Price"
                onSubmit={update("amount")}
                value={values.amount}
              />
            </LeafCell>
            <LeafCell css="height: auto;">
              <NoSubmitDatePicker
                data-test="listing-prices-start-date-new-entry"
                label="Date"
                onSubmit={update("startDate")}
                value={values.startDate || null}
              />
            </LeafCell>
          </Grid>
        )}
      </NewEntry>
    </div>
  );
};
