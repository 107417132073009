import T from "prop-types";
import { withStateHandlers } from "recompose";

import { Modal } from "components/Modal";
import { H2 } from "components/Heading";
import { Card } from "components/Card";
import { EditButton } from "./EditButton";

const enhance = withStateHandlers(
  { editing: false },
  {
    closeModal: () => () => ({ editing: false }),
    openModal: () => () => ({ editing: true }),
  },
);

export const DealDetailsSection = enhance(
  ({
    editing,
    openModal,
    closeModal,
    title,
    readOnlyContent: ReadOnlyContent,
    editableContent: EditableContent,
    ...rest
  }) => (
    <>
      {EditableContent && (
        <Modal title={title} closeModal={closeModal} isOpen={editing}>
          <EditableContent {...rest} />
        </Modal>
      )}
      <Card>
        {title && <H2>{title}</H2>}
        {EditableContent && (
          <EditButton onClick={openModal} data-test={`${title} button`}>
            Edit
          </EditButton>
        )}
        <ReadOnlyContent openModal={openModal} {...rest} />
      </Card>
    </>
  ),
);

DealDetailsSection.propTypes = {
  title: T.string,
  readOnlyContent: T.oneOfType([T.func, T.object]).isRequired,
  editableContent: T.func,
};

export const Section = DealDetailsSection;
