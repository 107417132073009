// @flow
import moment from "moment-timezone";
import { css } from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";

import { device } from "@nest-ui/styles";
import { SelectField } from "@nest-ui/sellers-nest/components/SelectField";
import { formatNumberAsCurrency } from "@nest-ui/hocs";
import { getConfig } from "@nested/config";

import { CurrentlyInterestedToggle } from "./CurrentlyInterestedToggle";
import {
  COMPLETED_BPI_STATUS_VALUE,
  PROPERTY_INTERESTS_QUERY,
} from "../PropertyInterests";
import { UPDATE_BUYER_PROPERTY_INTEREST } from "./BuyerPropertyInterestCard";

const propertyInfoStyles = css`
  padding: 16px;
  white-space: pre-wrap;
`;

const divider = css`
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 0;
  height: 8px;
  margin: 0 5px;
`;

const linkGroupStyles = css`
  a::after {
    content: " ";
    ${divider}
  }

  a:last-child::after {
    content: unset;
  }
`;

const propertyPhotoContainerStyles = css`
  height: 136px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ url }) => url},
    url(https://nested.imgix.net/missingProperty.svg) center center no-repeat,
    #e1f4ef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media ${device.desktop} {
    border-top-right-radius: 0;
  }
`;

const textStyles = css`
  border: none;
  padding: 0px;
  font-weight: 500;
  margin: 0;
`;

const buyerPropertyInterestStatusStyles = css`
  margin-top: 16px;

  ${({ active }) => !active && `font-weight: 500`};
`;

const selectFieldStyles = css`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;
type LinkProps = {
  href: ?string,
  children: React$Node,
};
export const Link = ({ children, href }: LinkProps) => (
  <a href={href} target="_blank" rel="noopener">
    {children}
  </a>
);

const customMoment = (date) => {
  moment.relativeTimeRounding(Math.floor);
  return moment(date);
};

const formatDate = (date: string) => {
  const weeks = moment().diff(date, "weeks");
  return weeks > 1 && weeks <= 9
    ? `${weeks} weeks ago`
    : customMoment(date).fromNow();
};

const getListingStatus = (listingStatus) => {
  if (!listingStatus) {
    return "listing status unknown";
  }

  const { date, status } = listingStatus;
  const relativeTime = formatDate(date);

  switch (status) {
    case "PRICE_DROP":
      return `reduced ${relativeTime}`;
    case "FALL_THROUGH":
      return `fell through ${relativeTime}`;
    case "NEW_LISTING":
    default:
      return `listed ${relativeTime}`;
  }
};

type PropertySummaryProps = {
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  buyerPropertyInterest: PropertyInterestsByBuyer_buyer_buyerPropertyInterests,
  buyerPropertyInterestStatuses: $ReadOnlyArray<PropertyInterestsByBuyer_buyerPropertyInterestStatuses>,
  changeBuyerPropertyInterestSa: (id: ID) => void,
  hasAssignedSa: boolean,
};

export const PropertySummary = (props: PropertySummaryProps) => {
  const {
    activeNestedUsers,
    buyerPropertyInterest,
    buyerPropertyInterestStatuses,
    changeBuyerPropertyInterestSa,
    hasAssignedSa,
  } = props;

  const {
    deal,
    buyer: { id: buyerId },
    status,
    currentlyInterested,
    assignedSa,
  } = buyerPropertyInterest;

  const {
    externalId,
    address,
    saExtendedSummary,
    nestedListing,
    opportunityStatus,
    currentListPrice,
    viewings,
    buyerOffers,
    listingStatus,
  } = deal;

  const [mutation, { loading }] = useMutation(UPDATE_BUYER_PROPERTY_INTEREST, {
    refetchQueries: [
      { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
    ],
  });

  const opportunityStatusLabel = opportunityStatus?.label || "-";
  const isCompleted = status.value === COMPLETED_BPI_STATUS_VALUE;
  const showStatusToggle = !isCompleted;
  const selectFieldEditable = currentlyInterested || isCompleted;

  const { ACCOUNT_URI } = getConfig();
  const nestDealURL = `/deals/${externalId}/listing`;
  const interestUrl = `${ACCOUNT_URI}/listing/interest?deal=${externalId}`;
  const propertyInfoUrl = `/deals/${externalId}/property-info`;

  return (
    <>
      <Link href={nestDealURL}>
        <div
          css={propertyPhotoContainerStyles}
          url={`url(https://nested.imgix.net/property-listings/${externalId}/1?auto=compress,format&w=240&height=136&fit=crop)`}
        />
      </Link>

      <div css={propertyInfoStyles}>
        <p style={{ fontWeight: "600" }}>{address}</p>
        <p css={textStyles}>
          {`${opportunityStatusLabel} (${getListingStatus(listingStatus)})`}
        </p>
        <p css={textStyles}>{formatNumberAsCurrency(currentListPrice)}</p>

        <p css={textStyles}>
          {`${viewings.length} viewings`}
          <span css={divider} />
          {`${buyerOffers.length} offers`}
        </p>

        <div
          css={css`
            margin-top: 16px;
            ${linkGroupStyles}
          `}
        >
          <Link href={nestedListing}>Rightmove</Link>
          <Link href={propertyInfoUrl}>Prop info</Link>
          <Link href={nestDealURL}>Nest</Link>
        </div>

        <div css={linkGroupStyles}>
          <Link href={interestUrl}>Interest</Link>
        </div>

        <div style={{ marginTop: "16px" }}>{saExtendedSummary}</div>

        <div
          active={currentlyInterested}
          css={buyerPropertyInterestStatusStyles}
        >
          <SelectField
            data-test={`buyer-property-interest-assigned-sa-field-${buyerPropertyInterest.id}`}
            options={activeNestedUsers.map((user) => ({
              label: user.fullName,
              value: user.id,
            }))}
            property="assignedSaId"
            value={assignedSa?.id}
            label="SA"
            mutation={({ assignedSaId }) => {
              changeBuyerPropertyInterestSa(assignedSaId);
            }}
            highlightRed={!hasAssignedSa}
          />
          <SelectField
            css={selectFieldStyles}
            data-test={`buyer-property-interest-select-field-${buyerPropertyInterest.id}`}
            disabled={!hasAssignedSa}
            nullable={false}
            options={buyerPropertyInterestStatuses}
            property="buyerPropertyInterestStatusId"
            readOnly={!selectFieldEditable}
            value={status.value}
            fromValue
            label="Status"
            mutation={(input) =>
              mutation({
                variables: { id: buyerPropertyInterest.id, input },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateBuyerPropertyInterest: {
                    __typename: "BuyerPropertyInterest",
                    ...buyerPropertyInterest,
                    status: {
                      __typename: "BuyerPropertyInterestStatus",
                      value: input.buyerPropertyInterestStatusId,
                    },
                  },
                },
              })
            }
          />
        </div>

        {showStatusToggle && (
          <CurrentlyInterestedToggle
            buyerPropertyInterest={buyerPropertyInterest}
            disabled={!hasAssignedSa}
            mutation={mutation}
            mutationInProgress={loading}
          />
        )}
      </div>
    </>
  );
};
