// @flow
import { useState, useMemo } from "react";
import { useQuery, useSubscription } from "@apollo/client/react/hooks";
import { Route, Switch, useParams, Redirect } from "react-router-dom";
import { gql } from "@apollo/client";
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { DealPage } from "@nest-ui/sellers-nest/pages/DealPage";
import { CreateCustomerAccount } from "@nest-ui/sellers-nest/deal/components/LeftNav/DealSummary/CreateCustomerAccount/CreateCustomerAccount";
import { SellerGdpr } from "@nest-ui/sellers-nest/components/Gdpr/Gdpr";
import { media } from "@nested/brand";
import { DealNav } from "./DealNav";
import { DealSummary } from "./DealSummary";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../components/Navigation/Breadcrumbs";
import { RightSidePanel } from "../../components/RightSidePanel/RightSidePanel";
import {
  calculateTasksDue,
  navRefStyle,
} from "../../components/RightSidePanel/Tasks/TasksButton";
import { getFullName } from "../utils";
import { mobileActiveDot } from "./PropertySummary";

export const DEAL_HEADER_QUERY = gql`
  query DealHeaderQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      active
      address
      customerAccountId
      firstName
      lastName
      ownerName
      opportunityStatus {
        valueText
      }
    }
  }
`;

// Task queries only work with the externalId in the params
export const DEAL_TASKS_COUNT_QUERY = gql`
  query DealTasksCount($id: ID!) {
    agentTaskCounts {
      forReference(relationType: "deal", relationId: $id) {
        due
        upcoming
      }
    }
  }
`;

export const DEAL_TASKS_COUNT_SUBSCRIPTION = gql`
  subscription AgentTaskDealCountChanged(
    $relationType: String!
    $relationId: ID!
  ) {
    agentTasksForReferenceCountChanged(
      relationType: $relationType
      relationId: $relationId
    ) {
      due
    }
  }
`;

const getParentBreadcrumb = (status) => {
  if (!status) {
    return "";
  }

  if (status < "s07_closed") {
    return "Leads";
  }

  if (status <= "s09_under_offer") {
    return "Portfolio";
  }

  return "Sold";
};

const wrapperStyle = css`
  display: flex;
  flex-direction: column;
  ${media.tablet`
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: hidden;
    padding: 0;
    max-height: calc(100vh - 50px);
  `}
`;

const breadCrumbWrapper = css`
  margin-left: 15px;
  margin-top: 20px;
  ${media.tablet`
    margin: 0;
  `}
`;

const intersectionTrigger = css`
  position: relative;
  bottom: 0;
  height: 0px;
  ${media.tablet`
    bottom: 40px;
  `}
`;

const navWrapper = css`
  margin-top: 15px;
  height: 40px;

  ${media.tablet`
    margin-top: 0;
    width: 100%;
  `}
`;

const overlayStyle = css`
  height: 40px;
  justify-content: flex-end;
  flex-direction: column;
  background-color: white;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;

  ${media.tablet`
    height: 80px;
  `}

  ${media.desktop`
    left: 170px;
    top: 50px;
  `}
`;

const summaryStyle = css`
  display: none;
  flex-grow: 1;
  align-items: center;
  padding: 10px 20px 0px;
  ${media.tablet`
    display: ${({ inView }) => (inView ? "flex" : "none")};
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    background-color: white;
  `}
`;

const addressStyle = css`
  margin-right: 20px;
  font-weight: 500;
`;

const statusStyle = css`
  ${mobileActiveDot};
  margin-left: 0;
  margin-right: 5px;
  ${media.tablet`
    display: inline-block;
  `}
`;

const stickyNavStyle = css`
  margin-top: 0;
  ${media.desktop`
    width: calc(100vw - 170px);
  `}
`;

const CreateCustomerAccountWrapper = styled.div`
  padding: 0 15px;

  ${media.tablet`
    padding 0 20px;
  `}
`;

const MainDealSection = styled.div`
  display: ${({ $mobileTaskPanelOpen }) =>
    $mobileTaskPanelOpen ? "none" : "block"};

  ${media.tablet`
    display: block;
  `}
`;

export const Deal = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(DEAL_HEADER_QUERY, {
    variables: { id },
  });

  const { data: initialTaskCountData } = useQuery(DEAL_TASKS_COUNT_QUERY, {
    variables: { id: data?.nestDeal?.externalId },
    skip: !data?.nestDeal,
  });

  const { data: updatedTaskCountData } = useSubscription(
    DEAL_TASKS_COUNT_SUBSCRIPTION,
    {
      variables: {
        relationType: "deal",
        relationId: data?.nestDeal?.externalId,
      },
      skip: !data?.nestDeal,
    },
  );

  const [showTaskPanel, setShowTaskPanel] = useState(true);
  const [showTasksOnMobile, setShowTasksOnMobile] = useState(false);

  const customerAccountId = data?.nestDeal?.customerAccountId;

  const tasksDue = useMemo(
    () => calculateTasksDue(updatedTaskCountData, initialTaskCountData),
    [updatedTaskCountData, initialTaskCountData],
  );

  // Needed for sticky deal nav
  const [ref, inView] = useInView({
    initialInView: true,
  });

  // Needed for task panel scrolling
  const [navRef, pageTopInView] = useInView({
    initialInView: true,
  });

  const toggleShowTasks = (viewport = "tablet") => {
    viewport === "mobile"
      ? setShowTasksOnMobile(!showTasksOnMobile)
      : setShowTaskPanel(!showTaskPanel);
  };

  if (id === "9") return <Redirect to="/deals/uj60m0ii" />;

  // To keep flow quiet
  if (!id) {
    return null;
  }

  return (
    <div css={wrapperStyle}>
      <div ref={navRef} inView={pageTopInView} css={navRefStyle} />
      <div css="position: relative; flex-grow: 1;">
        <div css={breadCrumbWrapper}>
          <Breadcrumbs>
            <Breadcrumb to="#">Sellers</Breadcrumb>
            {loading ? null : (
              <>
                <Breadcrumb to="#">
                  {getParentBreadcrumb(
                    data?.nestDeal?.opportunityStatus?.valueText,
                  )}
                </Breadcrumb>
                <Breadcrumb to="#" className="fs-exclude">
                  {getFullName(data?.nestDeal)}
                </Breadcrumb>
              </>
            )}
          </Breadcrumbs>
        </div>
        <ErrorBoundary>
          <DealSummary
            idOrExternalId={id}
            taskButtonProps={{
              onClick: toggleShowTasks,
              showTaskPanel: (viewport) =>
                viewport === "mobile" ? showTasksOnMobile : showTaskPanel,
              taskCount: tasksDue,
            }}
          />
        </ErrorBoundary>
        <div ref={ref} css={intersectionTrigger} />
        <MainDealSection $mobileTaskPanelOpen={showTasksOnMobile}>
          <div css={navWrapper} inView={inView}>
            <div css={inView ? undefined : overlayStyle}>
              {!loading && (
                <div inView={!inView} css={summaryStyle}>
                  <div className="fs-exclude" css={addressStyle}>
                    {data?.nestDeal?.address}
                  </div>
                  <div css={statusStyle} active={data?.nestDeal?.active} />
                  <div className="fs-exclude">
                    {getFullName(data?.nestDeal)}
                  </div>
                </div>
              )}
              {customerAccountId ? (
                <DealNav
                  css={inView ? undefined : stickyNavStyle}
                  idOrExternalId={id}
                />
              ) : (
                !loading && (
                  <CreateCustomerAccountWrapper>
                    <CreateCustomerAccount dealId={data?.nestDeal?.id} />
                  </CreateCustomerAccountWrapper>
                )
              )}
            </div>
          </div>
          <Switch>
            <Route path="/deals/:id/gdpr" component={SellerGdpr} />
            <Route>
              {customerAccountId && (
                <ErrorBoundary>
                  <DealPage />
                </ErrorBoundary>
              )}
            </Route>
          </Switch>
        </MainDealSection>
      </div>
      {data?.nestDeal && (
        <RightSidePanel
          relationId={data.nestDeal.externalId}
          relationType="deal"
          pageTopInView={pageTopInView}
          mobileOnClose={() => setShowTasksOnMobile(false)}
          mobileOpen={showTasksOnMobile}
          onClose={() => setShowTaskPanel(false)}
          open={showTaskPanel}
          taskCount={tasksDue}
        />
      )}
    </div>
  );
};
