// @flow
import { css } from "styled-components";

import {
  ViewingStatusContainer,
  ViewingStatusIndicator,
} from "@nest-ui/sellers-nest/tabs/Interest/Viewings";

const statusContainerStyles = css`
  margin-bottom: 0;
  margin-top: 0;
`;

function getViewingStatusText({
  hasViewingTimePassed,
  isConfirmedWithVendor,
  virtual,
}) {
  if (hasViewingTimePassed) {
    return virtual
      ? "Virtual viewing time has passed!"
      : "Viewing time has passed!";
  }

  if (isConfirmedWithVendor) {
    return "Confirmed with vendor";
  }

  return "Awaiting confirmation";
}

export function ViewingStatus({
  hasBuyerFeedback,
  hasViewingTimePassed,
  isConfirmedWithVendor,
  viewingCancellationReasonLabel,
  virtual,
}: {
  hasBuyerFeedback: boolean,
  hasViewingTimePassed: boolean,
  isConfirmedWithVendor: boolean,
  viewingCancellationReasonLabel: ?string,
  virtual: ?boolean,
}) {
  if (viewingCancellationReasonLabel) {
    return (
      <ViewingStatusContainer css={statusContainerStyles}>
        <ViewingStatusIndicator alert />
        <strong>Cancelled</strong> - {viewingCancellationReasonLabel}
      </ViewingStatusContainer>
    );
  }

  if (hasBuyerFeedback) {
    return (
      <ViewingStatusContainer css={statusContainerStyles}>
        <ViewingStatusIndicator hasBuyerFeedback={hasBuyerFeedback} />
        <strong>{virtual ? "Viewed virtually" : "Viewed"}</strong>
      </ViewingStatusContainer>
    );
  }

  return (
    <ViewingStatusContainer css={statusContainerStyles}>
      <ViewingStatusIndicator alert={hasViewingTimePassed} />

      <strong>Scheduled</strong>
      {` - ${getViewingStatusText({
        hasViewingTimePassed,
        isConfirmedWithVendor,
        virtual,
      })}`}
    </ViewingStatusContainer>
  );
}
