// @flow
const cross = String.fromCharCode(0x2717);

export const lowMediumHighOptions = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];
export const noneMajorMinorOptions = [
  { label: `${cross}${cross}`, value: `${cross}${cross}` },
  { label: cross, value: cross },
  { label: "~", value: "~" },
];
export const yesNAOptions = [
  { label: "N/A", value: "N/A" },
  { label: "Yes", value: "Yes" },
];
export const yesNoOptions = [
  { label: "No", value: "No" },
  { label: "Yes", value: "Yes" },
];
export const yesNoBooleanOptions = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];
export const highLowOptions = [
  { label: "High", value: "High" },
  { label: "Low", value: "Low" },
];
export const yesNoNAOptions = [
  { label: "N/A", value: "N/A" },
  { label: "No", value: "No" },
  { label: "Yes", value: "Yes" },
];
export const yesNoWontAsk = [
  { label: "No, won't ask", value: "No, won't ask" },
  { label: "Yes", value: "Yes" },
];
export const leadTemperatures = [
  { label: "Hot", value: "HOT" },
  { label: "Warm", value: "WARM" },
  { label: "Cold", value: "COLD" },
];
