// @flow
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import styled, { css, ThemeContext } from "styled-components";
import { useInView } from "react-intersection-observer";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { getImage } from "@nested/utils";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { media } from "@nested/brand";
import { CollapseArrows, ExpandArrows } from "@nested/ui/icons";
import {
  Call,
  EmailThread,
  Milestone,
  SMS,
} from "@nested/brand/src/components/Timeline";
import { buttonStyles } from "../../IconButton/IconButton";
import { PlaceholderList } from "../../Placeholder";
import { ListViewError } from "../../ListViewError";
import { useWebsocket } from "../../../hooks/useWebsocket";
import { MILESTONE_LABELS } from "../../../pages/Deal/Progression/Milestones/MilestoneShared";

import { TextAndIconButton } from "../../TextAndIconButton";
import { StickyHeader } from "../../StickyHeader";
import { AnimatedList } from "../../AnimatedList";
import { MultiSelectDropdown } from "../../MultiSelectDropdown/MultiSelectDropdown";
import {
  TIMELINE_AGENT_TASK_FRAGMENT,
  TIMELINE_EMAIL_FRAGMENT,
  TIMELINE_EVENT_FRAGMENT,
} from "./TimelineFragments";

// TODO: We are gradually migrating the visual components of this
// Timeline component into the `@nested/brand` package so that they can
// be shared with the Account. There's a fair amount of refactoring to
// do with each event type because of graphql queries, editability, and
// general Note nonsense.
//
// Consequently, we're approaching this an event-type at a time, and
// have a whole epic dedicated to Notes. Ultimately, this component will
// simply handle fetching of data with the relevant graphql queries, and
// the Account will have its own version. They will then share the
// visual components which will all live in `@nested/brand`
import {
  EditableNote,
  NewNote,
  PinnedNote,
  TimelineAnalysisViewedEvent,
  TimelineBuyerOppStatus,
  TimelineDealOppStatus,
  TimelineNote,
  TimelineSnooze,
  TimelineTaskEvent,
  ViewAllButton,
} from "./Events";

// We query for 1 more event than displayed so that we know whether to render "View all activity" button
export const DISPLAYED_EVENT_LIMIT = 8;

export const TIMELINE = gql`
  query Timeline(
    $relationType: String!
    $relationId: ID!
    $limit: Int
    $filterBy: [TimelineFilters]
  ) {
    timeline(
      relationType: $relationType
      relationId: $relationId
      limit: $limit
      filterBy: $filterBy
    ) {
      __typename
      ... on AgentTaskEvent {
        id
        ...TimelineAgentTaskFragment
      }
      ... on TimelineEvent {
        id
        ...TimelineEventFragment
      }
      ... on EmailThread {
        id
        ...TimelineEmailFragment
      }
    }
  }

  ${TIMELINE_AGENT_TASK_FRAGMENT}
  ${TIMELINE_EVENT_FRAGMENT}
  ${TIMELINE_EMAIL_FRAGMENT}
`;

const TIMELINE_SUBSCRIPTION = gql`
  subscription TimelineChanges(
    $relationId: ID!
    $relationType: String!
    $limit: Int
    $filterBy: [TimelineFilters]
  ) {
    timelineForDealOrBuyerChanged(
      relationId: $relationId
      relationType: $relationType
      limit: $limit
      filterBy: $filterBy
    ) {
      __typename
      ... on AgentTaskEvent {
        id
        ...TimelineAgentTaskFragment
      }
      ... on TimelineEvent {
        id
        ...TimelineEventFragment
      }
      ... on EmailThread {
        id
        ...TimelineEmailFragment
      }
    }
  }

  ${TIMELINE_AGENT_TASK_FRAGMENT}
  ${TIMELINE_EVENT_FRAGMENT}
  ${TIMELINE_EMAIL_FRAGMENT}
`;

export const GET_EMAIL_BY_ID = gql`
  query GetEmailById($id: ID!) {
    email(id: $id) {
      id
      body
      recipients {
        id
        name
        email
        type
      }
    }
  }
`;

export const GET_DEAL_OPPORTUNITY_STATUS = gql`
  query DealOpportunityStatus($id: ID!) {
    nestDeal(id: $id) {
      id
      opportunityStatus {
        valueText
      }
    }
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  transition: top 300ms ease-out;
  z-index: 100;
  min-height: 50%;
  height: 50%;
  flex-grow: 1;
  ${media.tablet`
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    overflow-y: auto;
    margin: 0;
    overflow-x: hidden;
    overscroll-behavior: contain;
    min-width: 100%;
    background: white;
  `}
  ${({ $expanded }) =>
    $expanded &&
    css`
      position: absolute;
      height: 100%;
      top: 0;
      width: 80vw;
    `}
`;

const timelineLine = css`
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 2px;
    border-left: 2px solid ${({ theme }) => theme.palette.hague20};
    top: 36px;
    bottom: 45px;
    left: 12px;
  }
`;

const Content = styled.div`
  ${media.tablet`
    padding: 10px 14px 0;
  `}
`;

const expandButtonStyle = css`
  ${buttonStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 14px;
  background: transparent;
`;

const headerWrapperStyle = css`
  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    ${({ $expanded }) =>
      $expanded &&
      css`
        border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
        min-height: 50px;
        text-align: center;
        padding: 10px 0;

        p {
          margin: auto;
        }
      `}
  `}
`;

const noActivityYetWrapper = css`
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  ${media.tablet`
    margin: 0;
  `}
`;

const noActivityImage = css`
  width: 120px;
  margin-top: 10px;
`;

const noActivityYetText = css`
  font-weight: 500;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
`;

const subtext = css`
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  margin: 5px auto 0;
`;

const ActionBar = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 20px 0;
  ${media.tablet`
    margin: 10px 14px;
    ${({ $expanded }) =>
      $expanded &&
      css`
        margin-top: 30px;
      `}
  `}
`;

const NoActivityYet = () => (
  <div css={noActivityYetWrapper}>
    <img
      css={noActivityImage}
      alt="cat in plants"
      src={getImage("icons/cat-in-plants.png")}
    />
    <p css={noActivityYetText}>No activity yet</p>
    <p css={subtext}>
      I'm not standing still,
      <br />I am lying in wait
    </p>
  </div>
);

const TimelineEvent = ({
  item: event,
  expanded,
  relationType,
  fetchEmail,
  allowNoteSharing,
}) => {
  if (event.__typename === "AgentTaskEvent") {
    return <TimelineTaskEvent event={event} expanded={expanded} />;
  }
  if (event.__typename === "EmailThread") {
    return (
      <EmailThread
        id={event.id}
        emails={event.emails}
        expanded={expanded}
        fetchEmail={fetchEmail}
        subject={event.subject}
      />
    );
  }
  if (event.eventType === "call") {
    return (
      <Call
        expanded={expanded}
        id={event.id}
        markNoteAsSharedWithSeller={event.isNoteSharedWithCustomer}
        nestedUser={{ email: event.userEmail }}
        recipient={event.recipient}
        timestamp={event.timestamp}
      >
        <EditableNote event={event} isShareable={allowNoteSharing} />
      </Call>
    );
  }
  if (event.eventType === "sms") {
    return (
      <SMS
        expanded={expanded}
        id={event.id}
        nestedUser={{ email: event.userEmail }}
        recipient={event.recipient}
        smsBody={event.content}
        timestamp={event.timestamp}
      />
    );
  }
  if (event.eventType === "OppStatus" && relationType === "deal") {
    return <TimelineDealOppStatus event={event} expanded={expanded} />;
  }
  if (event.eventType === "OppStatus" && relationType === "buyer") {
    return <TimelineBuyerOppStatus event={event} expanded={expanded} />;
  }
  if (event.eventType === "Snooze") {
    return <TimelineSnooze event={event} expanded={expanded} />;
  }
  if (event.eventType === "AnalysisViewed") {
    return <TimelineAnalysisViewedEvent event={event} expanded={expanded} />;
  }
  if (event.eventType === "Note") {
    return <TimelineNote event={event} isShareable={allowNoteSharing} />;
  }
  if (event.eventType === "MilestoneReached") {
    return (
      <Milestone
        id={event.id}
        markNoteAsSharedWithSeller={event.isNoteSharedWithCustomer}
        timestamp={event.timestamp}
        title={MILESTONE_LABELS[event.id] || event.id}
      >
        <EditableNote event={event} isShareable={allowNoteSharing} />
      </Milestone>
    );
  }
  if (event.new === "Note") {
    return (
      <NewNote
        event={event}
        expanded={expanded}
        isShareable={allowNoteSharing}
      />
    );
  }
  return null;
};

type Props = {
  relationId: string,
  relationType: string,
  activitiesExpanded?: boolean,
  setActivitiesExpanded: (state: boolean) => void,
  noLimit?: boolean,
};

export const TimelinePanel = forwardRef<Props, null | HTMLButtonElement>(
  (
    {
      relationId,
      relationType,
      activitiesExpanded,
      setActivitiesExpanded,
      noLimit,
    },
    expandButtonRef,
  ) => {
    const unsubscribe = useRef();
    const [creatingNote, setCreatingNote] = useState(false);
    const [filters, setFilters] = useState([]);
    const theme = useContext(ThemeContext);
    const apolloClient = useApolloClient();
    const isMobile = window.innerWidth < theme.breakpoints.tablet;

    const {
      data: dealOpportunityStatusData,
      loading: dealOpportunityStatusLoading,
    } = useQuery(GET_DEAL_OPPORTUNITY_STATUS, {
      variables: { id: relationId },
      skip: relationType === "buyer",
    });

    const limit =
      noLimit || activitiesExpanded ? null : DISPLAYED_EVENT_LIMIT + 1;

    const variables = {
      relationType,
      relationId,
      limit,
      filterBy: filters,
    };

    const socket = useWebsocket();

    // Below handles the case when user's laptop goes to sleep for prolonged period of time
    // When this occurs, WebSocket does not reconnect automatically for some reason
    // which causes newly created notes not to appear in the UI.
    // We listen to the disconnect and force page reload before user gets a chance to create new note
    useEffect(() => {
      if (["closing", "closed"].includes(socket.connectionState())) {
        window.location.reload();
      }
    }, [socket.connectionState()]);

    const {
      data,
      loading: loadingTimelineQuery,
      error,
      subscribeToMore,
      refetch,
    } = useQuery(TIMELINE, {
      variables,
      fetchPolicy:
        activitiesExpanded || isMobile ? "cache-and-network" : "cache-first",
    });

    const loadingTimeline =
      loadingTimelineQuery || dealOpportunityStatusLoading;

    // This is another way of subscribing, similar to useSubscription! It executes the query in "document" with the
    // relevant variables, and updateQuery tells Apollo how to update the cache.
    // Note: The return value of updateQuery completely replaces the cached result. This is suitable here because
    // updates in the timeline include additions, edits and removals, so we cannot just push to the cached array.
    // Docs here: https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    const subscribeToUpdates = () => {
      const newUnsubscribe = subscribeToMore({
        document: TIMELINE_SUBSCRIPTION,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return {
            timeline: subscriptionData.data.timelineForDealOrBuyerChanged,
          };
        },
      });

      // We need to explicitly unsubscribe to previous subscriptions when the
      // filters change because otherwise this useEffect will just end up
      // creating loads of different subscriptions which all try and override
      // the state when a change happens. This leads to weird behaviour like
      // adding a note while filtered clearing the filters in the timeline.
      if (unsubscribe.current) unsubscribe.current();
      unsubscribe.current = newUnsubscribe;

      refetch(variables);
    };

    useEffect(subscribeToUpdates, [filters]);

    const headerTitle = activitiesExpanded ? "Activity" : "Recent activity";
    const [ref, inView] = useInView({
      initialInView: true,
    });

    if (!data && (loadingTimeline || error)) {
      return (
        <Panel $expanded={activitiesExpanded}>
          <StickyHeader>{headerTitle}</StickyHeader>
          <Content>
            {loadingTimeline ? (
              <PlaceholderList />
            ) : (
              <ListViewError
                css="margin: 0;"
                sidebar
                message="Something went wrong. Please try refreshing!"
              />
            )}
          </Content>
        </Panel>
      );
    }

    const timelineData = data?.timeline;

    const existingEventsToDisplay =
      activitiesExpanded || noLimit
        ? timelineData
        : timelineData.slice(0, DISPLAYED_EVENT_LIMIT);

    const pinnedNote = existingEventsToDisplay.find((event) => event.pinned);

    const allowNoteSharing =
      relationType === "deal" &&
      // We don't share any activity with the seller in the Seller app before the deal goes
      //  under offer or after it has been exchanged, hence this constraint.
      ["s09_under_offer", "s10_exchanged"].includes(
        dealOpportunityStatusData?.nestDeal?.opportunityStatus?.valueText,
      );

    const newNote = {
      new: "Note",
      id: "new-note",
      relationType,
      relationId,
      onClose: () => setCreatingNote(false),
    };

    const eventsToDisplay = creatingNote
      ? [newNote, ...existingEventsToDisplay]
      : existingEventsToDisplay;

    const noActivity = eventsToDisplay.length === 0;

    const fetchEmail = async (id) => {
      try {
        const result = await apolloClient.query({
          query: GET_EMAIL_BY_ID,
          variables: { id },
        });
        if (result?.error) {
          errorHandler(result?.error);
        }
        if (result?.data?.email) {
          return result?.data?.email;
        }
      } catch (e) {
        errorHandler(e);
      }

      return null;
    };

    return (
      <Panel ref={expandButtonRef} $expanded={activitiesExpanded}>
        <StickyHeader
          scrolling={!inView}
          $expanded={activitiesExpanded}
          css={headerWrapperStyle}
        >
          <p css="margin: 0; font-weight: 500;">{headerTitle}</p>
          <button
            css={expandButtonStyle}
            onClick={() => setActivitiesExpanded(!activitiesExpanded)}
          >
            {activitiesExpanded ? <CollapseArrows /> : <ExpandArrows />}
          </button>
        </StickyHeader>
        <div ref={ref} css="height: 0;" />
        {pinnedNote && (
          <PinnedNote isShareable={allowNoteSharing} event={pinnedNote} />
        )}
        <ActionBar $expanded={activitiesExpanded}>
          <TextAndIconButton
            css="width: 60px;"
            onClick={() => setCreatingNote(true)}
            text="Note"
          />
          <MultiSelectDropdown
            onSelectionChange={setFilters}
            options={[
              { value: "CALLS", label: "Calls" },
              { value: "EMAILS", label: "Emails" },
              { value: "MILESTONES", label: "Milestones" },
              { value: "NOTES", label: "Notes" },
              { value: "STATUS_EVENTS", label: "Status changes" },
              { value: "TASKS", label: "Tasks" },
            ]}
          />
        </ActionBar>
        <Content>
          {noActivity ? (
            <NoActivityYet />
          ) : (
            <>
              <div css={eventsToDisplay.length > 1 && timelineLine}>
                <AnimatedList
                  list={eventsToDisplay}
                  rowComponent={TimelineEvent}
                  additionalComponentProps={{
                    relationType,
                    expanded: activitiesExpanded,
                    fetchEmail,
                    allowNoteSharing,
                  }}
                />
                {existingEventsToDisplay.length < timelineData.length && (
                  <ViewAllButton onClick={() => setActivitiesExpanded(true)} />
                )}
              </div>

              {/* These manage the loading and error states in the expanded panel */}
              {activitiesExpanded && loadingTimeline && (
                <PlaceholderList css="padding-left: 36px;" />
              )}
              {activitiesExpanded && error && (
                <ListViewError
                  message="Something went wrong loading the remaining events. Please try
                    refreshing!"
                />
              )}
            </>
          )}
        </Content>
      </Panel>
    );
  },
);
