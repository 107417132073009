// @flow

import { withRouter, Route } from "react-router";
import styled, { ThemeProvider } from "styled-components";
import { gql } from "@apollo/client";

import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { ErrorBoundary } from "components/ErrorBoundary";

import { theme } from "./theme";
import { ComparableList } from "./ComparableList/ComparableList";
import { SelectedComparables } from "./SelectedComparables/SelectedComparables";

// NOTE: This query is for populating global cache. This is used to manage a
// dropdown that appears for `ComparisonPoints`, and avoid having to run many
// queries. Each `ComparisonPoints` component runs this query as with a
// `cache-only` query (so will never hit the network).
export const LIST_COMPARISON_LABEL_QUERY = gql`
  query ListAllComparisonLabels {
    listAllComparisonLabels {
      id
      description
    }
  }
`;

const ComparablesPanel = styled.section`
  position: fixed;
  top: 50px;
  left: 170px;
  width: calc(100vw - 170px);
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  background-color: ${theme.background.mainSection};
  color: ${theme.text.grey};
  z-index: 2;
`;

export const Comparables = withRouter(
  ({ closeUrl, match, sourceType, sourceId }) => (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <ComparablesPanel>
          <ExtendedQuery query={LIST_COMPARISON_LABEL_QUERY}>
            {() => {
              const reference = { sourceId, sourceType };

              return (
                <>
                  <Route
                    path={`${match.path}/selected`}
                    render={() => (
                      <SelectedComparables
                        closeUrl={closeUrl}
                        reference={reference}
                      />
                    )}
                  />
                  <Route
                    path={`${match.path}`}
                    exact
                    render={() => (
                      <ComparableList
                        closeUrl={closeUrl}
                        reference={reference}
                      />
                    )}
                  />
                </>
              );
            }}
          </ExtendedQuery>
        </ComparablesPanel>
      </ThemeProvider>
    </ErrorBoundary>
  ),
);
