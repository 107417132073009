import T from "prop-types";
import { gql } from "@apollo/client";

import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { CurrencyField } from "components/CurrencyField";
import { SelectField } from "components/SelectField";
import { NumberField } from "components/NumberField";
import { RadioButtons } from "components/RadioButtons";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { yesNoNAOptions } from "@nest-ui/shared/options";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation updateVAPropertyInDealDetails($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      bathrooms
    }
  }
`;

export const EditableContent = ({ mutations, nestDeal }) => (
  <Grid columns={4}>
    <LeafCell width={2}>
      <TextareaWithLinks
        value={nestDeal.currentListingUrl}
        label="Current listing URL"
        property="currentListingUrl"
        mutation={mutations.updateNestDeal}
      />
    </LeafCell>

    <LeafCell left={1} width={2}>
      <TextField
        label="Address"
        value={nestDeal.address}
        disabled
        className="fs-exclude"
      />
    </LeafCell>

    <LeafCell left={1} width={2}>
      <SelectField
        options={[
          "Studio flat",
          "Flat",
          "LG floor flat",
          "Gr floor flat",
          "Mid floor flat",
          "Top floor flat",
          "Gr floor maisonette",
          "Mid floor maisonette",
          "Top floor maisonette",
          "Terraced house",
          "End-of-terrace house",
          "Mid-terrace house",
          "Semi-detached house",
          "Detached house",
          "Bungalow",
        ]}
        label="Property type (our assessment)"
        value={nestDeal.propertySubType}
        mutation={mutations.updateNestDeal}
        property="propertySubType"
      />
    </LeafCell>

    <LeafCell width={2}>
      <SelectField
        property="tenure"
        label="Tenure"
        value={nestDeal.tenure}
        mutation={mutations.updateNestDeal}
        options={[
          "Freehold",
          "Share of freehold",
          "Leasehold",
          "Commonhold",
          "Shared ownership",
          "I'm not sure",
        ]}
      />
    </LeafCell>

    <LeafCell left={1}>
      <NumberField
        mutation={mutations.updateNestDeal}
        property="leaseRemaining"
        label="Years remaining on lease"
        value={nestDeal.leaseRemaining}
      />
    </LeafCell>
    <LeafCell width={2}>
      <RadioButtons
        mutation={mutations.updateNestDeal}
        label="Lease extendable"
        property="isLeaseExtendable"
        value={nestDeal.isLeaseExtendable}
        options={yesNoNAOptions}
      />
    </LeafCell>

    <LeafCell left={1}>
      <CurrencyField
        label="Ground rent (pa)"
        value={nestDeal.groundRent}
        mutation={mutations.updateNestDeal}
        property="groundRent"
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        label="Service charge (pa)"
        value={nestDeal.serviceCharge}
        mutation={mutations.updateNestDeal}
        property="serviceCharge"
      />
    </LeafCell>
    <ExtendedMutation mutation={UPDATE_PROPERTY_MUTATION}>
      {(mutate) => {
        const updateProperty = async (data) => {
          await mutate({
            variables: {
              input: data,
              id: nestDeal.property.id,
            },
            optimisticResponse: {
              updateProperty: {
                __typename: "Property",
                ...nestDeal.property,
                ...data,
              },
            },
          });
        };
        return (
          <>
            <LeafCell>
              <NumberField
                property="bathrooms"
                label="Bathrooms"
                value={nestDeal.property.bathrooms}
                mutation={updateProperty}
              />
            </LeafCell>
          </>
        );
      }}
    </ExtendedMutation>
    <LeafCell width={2} left={1}>
      <NumberField
        label="Floor area (customer submitted) ft2"
        value={nestDeal.floorAreaUserSubmitted}
        disabled
      />
    </LeafCell>

    <LeafCell width={4}>
      <SelectField
        multiple
        label="Outdoor space type"
        value={nestDeal.outsideSpace}
        property="outsideSpace"
        mutation={mutations.updateNestDeal}
        options={[
          "None",
          "Patio",
          "Shared patio",
          "Balcony",
          "Balcony (undemised)",
          "Roof terrace",
          "Roof terrace (undemised)",
          "Shared roof terrace",
          "Private garden",
          "Private garden (section)",
          "Shared garden",
          "Other",
        ]}
      />
    </LeafCell>

    <LeafCell width={1}>
      <RadioButtons
        label="Major property defects identified by customer"
        value={nestDeal.propertyIssues}
        property="propertyIssues"
        mutation={mutations.updateNestDeal}
      />
    </LeafCell>

    <LeafCell width={3} left={1}>
      <TextareaWithLinks
        label="Major property defect details (customer)"
        value={nestDeal.propertyIssuesDetails}
        property="propertyIssuesDetails"
        mutation={mutations.updateNestDeal}
      />
    </LeafCell>

    <LeafCell width={1} left={1}>
      <RadioButtons
        label="Property above retail"
        value={nestDeal.aboveRetail}
        property="aboveRetail"
        mutation={mutations.updateNestDeal}
      />
    </LeafCell>

    <LeafCell width={3} left={1}>
      <TextareaWithLinks
        label="Additional info"
        value={nestDeal.additionalDetails}
        property="additionalDetails"
        mutation={mutations.updateNestDeal}
      />
    </LeafCell>
  </Grid>
);

EditableContent.propTypes = {
  nestDeal: T.object.isRequired,
  mutations: T.object.isRequired,
};
