// @flow
import { useState } from "react";
import styled, { css } from "styled-components";

import { Label } from "components/Label";
import { Spinner } from "@nest-ui/hocs/withOnSubmit/withSpinner";
import { ReadOnlyField } from "@nest-ui/sellers-nest/components/ReadOnlyField";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.default};
`;

const FlexParent = styled.div`
  border-radius: ${({ theme }) => theme.input.border.radius};
  background-color: ${({ theme, isSecondary }) =>
    isSecondary ? theme.color.secondary.dark : theme.color.background.button};
  display: flex;
  height: ${({ theme }) => theme.input.height};
  width: 100%;
  input {
    appearance: none;
    margin: 0px;
    padding: 0px;
  }
  label:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.input.border.radius};
    border-bottom-left-radius: ${({ theme }) => theme.input.border.radius};
    ${({ isSecondary }) => isSecondary && "border-right: 1px solid #999"};
  }
  label:last-of-type {
    border-top-right-radius: ${({ theme }) => theme.input.border.radius};
    border-bottom-right-radius: ${({ theme }) => theme.input.border.radius};
    ${({ isSecondary }) => isSecondary && "border-left: 1px solid #999"};
  }
`;

const checkedStyles = css`
  background: ${({ theme, isSecondary }) =>
    isSecondary ? "#6F7C96" : theme.color.primary.accent};
  border: ${({ focused, theme }) =>
    `${theme.input.border.width} solid ${
      focused ? theme.color.primary.dark : theme.color.primary.highlight
    }`};
  z-index: 0;

  /* We need to override the more specific border rules above */
  &&& {
    ${({ isSecondary, theme }) =>
      isSecondary && `border: ${theme.input.border.width} solid #C6D1E6;`};
  }
`;

const uncheckedStyles = css`
  background: ${({ theme, isSecondary }) =>
    isSecondary ? theme.color.secondary.dark : theme.color.background.button};
  border: ${({ focused, theme }) =>
    `${theme.input.border.width} solid ${
      focused ? theme.color.primary.dark : theme.color.secondary.highlight
    }`};
  ${({ theme, isSecondary }) =>
    isSecondary && `border-color: ${theme.color.secondary.dark};`};
`;

export const RadioButton = styled.label`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  margin-right: -2px;
  outline: none;
  ${({ checked }) => (checked ? checkedStyles : uncheckedStyles)};
  ${({ highlightRed, theme }) =>
    highlightRed && `border: 2px solid ${theme.color.background.danger}`}
`;

const boolToString = (value) => {
  switch (value) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return value;
  }
};

export function NoSubmitRadioButtons<Value>(props: {|
  className?: string,
  "data-test"?: string,
  disabled?: boolean,
  id: string,
  isSecondary?: boolean,
  label?: string,
  onSubmit: (value: ?Value) => Promise<void> | void,
  options: Array<{|
    label: string,
    icon?: string,
    value: Value,
  |}>,
  readOnly?: boolean,
  value: ?Value,
  highlightRed?: boolean,
|}) {
  const {
    "data-test": dataTest,
    className,
    disabled,
    id,
    isSecondary,
    label,
    onSubmit,
    options,
    readOnly,
    value,
    highlightRed,
  } = props;
  const [focused, setFocused] = useState(false);
  const fieldId = id;
  /**
   * Options are required by flow, but not every component has options, because
   * we used to rely on implicit defaults. I wanted to make options an explicit
   * requirement as we move on, but because of failing and hard to find issues I
   * could not not have a default. So setting it here. Ideally, once the entire
   * codebase is "flowed" we should be able to remove this default.
   */
  const radioOptions = options || [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ];

  if (readOnly || disabled) {
    return <ReadOnlyField {...props} value={boolToString(props.value)} />;
  }

  return (
    <Wrapper className={className}>
      {label ? <Label>{label}</Label> : null}
      <FlexParent
        focused={focused}
        data-test={dataTest}
        isSecondary={isSecondary}
      >
        {radioOptions.map((option) => {
          const inputId = `${fieldId}-${option.label}`;
          const checked = value === option.value;
          return (
            <RadioButton
              checked={checked}
              data-test={inputId}
              focused={focused}
              htmlFor={inputId}
              isSecondary={isSecondary}
              key={inputId}
              highlightRed={highlightRed}
              onClick={async (e) => {
                e.preventDefault();

                if (checked) {
                  return onSubmit(null);
                }

                return onSubmit(option.value);
              }}
            >
              <input
                checked={checked}
                id={inputId}
                name={fieldId}
                onBlur={() => setFocused(false)}
                onChange={() => null}
                onFocus={() => setFocused(true)}
                tabIndex={0}
                type="radio"
                value={option.value}
              />
              {option?.icon}
              {option.label}
            </RadioButton>
          );
        })}
      </FlexParent>
    </Wrapper>
  );
}

export function RadioButtons<Value>(props: {|
  "data-test"?: string,
  className?: string,
  disabled?: boolean,
  id?: string,
  isSecondary?: boolean,
  label?: string,
  mutation: (propertyValueObject: any) => Promise<any> | any,
  nonNullable?: boolean,
  options: Array<{|
    label: string,
    icon?: string,
    value: Value,
  |}>,
  property: string,
  readOnly?: boolean,
  showSpinner?: boolean,
  value: ?Value,
  highlightRed?: boolean,
|}) {
  const [isLoading, setLoading] = useState(false);
  const {
    id,
    mutation,
    property,
    nonNullable,
    showSpinner = true,
    ...rest
  } = props;

  async function onSubmit(newValue) {
    // This short-circuits making requests to the backend if no change has been made
    if (props.value === newValue) return Promise.resolve();

    // Prevents nullifying field
    if (nonNullable && (newValue === null || newValue === undefined))
      return Promise.resolve();

    setLoading(true);

    try {
      return await mutation({ [property]: newValue });
    } catch (e) {
      errorHandler(e);
      return undefined;
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Spinner {...{ isLoading, showSpinner }}>
        <NoSubmitRadioButtons {...rest} {...{ id: id || property, onSubmit }} />
      </Spinner>
    );
  }

  return (
    <NoSubmitRadioButtons {...rest} {...{ id: id || property, onSubmit }} />
  );
}

RadioButtons.displayName = "RadioButtons";
