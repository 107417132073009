export const updateDealTypeAdvanceMay2018Config = {
  props: ({ ownProps: { nestDeal }, mutate }) => ({
    updateDealTypeAdvanceMay2018: (input) =>
      mutate({
        variables: { input, id: nestDeal.dealTypeDetails.id },
        optimisticResponse: {
          __typename: "Mutation",
          updateDealTypeAdvanceMay2018: {
            __typename: "DealTypeAdvanceMay2018",
            ...nestDeal.dealTypeDetails,
            ...input,
          },
        },
      }),
  }),
};
