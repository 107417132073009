import { path } from "ramda";
import propertyListingPricesQuery from "../queries/propertyListingPricesQuery.graphql";

export const updateConfig = {
  props: ({ mutate, ownProps }) => ({
    updatePropertyListingPrice(input) {
      const { propertyListingPrice } = ownProps;
      const propertyListingPriceId = path(["id"], propertyListingPrice);
      return mutate({
        variables: {
          id: propertyListingPriceId,
          input,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updatePropertyListingPrice: {
            __typename: "PropertyListingPrice",
            id: propertyListingPriceId,
            ...propertyListingPrice,
            ...input,
          },
        },
        refetchQueries: [
          {
            query: propertyListingPricesQuery,
            variables: {
              dealId: propertyListingPrice.dealId,
            },
          },
        ],
      });
    },
  }),
};
