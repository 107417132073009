// @flow
import type { Node } from "react";
import styled, { css } from "styled-components";
import { Tick } from "@nest-ui/icons";
import { H2 } from "components/Heading";

const Wrapper = styled.div`
  position: relative;
  background-color: rgb(250, 250, 250);
  border: 2px solid rgb(230, 230, 230);
  border-top: 0;
  border-radius: 0 0 4px 4px;
  margin-bottom: 16px;
`;

const completedReportStyles = css`
  background-color: ${({ theme }) => theme.color.primary.dark};
  h2 {
    color: ${({ theme }) => theme.color.text.light};
  }
`;

const CardHeader = styled.div`
  border-radius: 4px 4px 0 0;
  margin-top: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #aedcd0;
  h2 {
    margin-bottom: 0 !important;
    line-height: 21px;
  }
  ${({ reportStatus }) =>
    reportStatus?.value === "COMPLETED" ? completedReportStyles : ""}
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  div {
    background-color: ${({ theme }) => theme.color.secondary.light};
    border-radius: 50%;
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
  svg {
    height: 22px;
    width: 22px;
  }
`;

const ReportStatusBadge = ({ reportStatus }) => (
  <Badge>
    {reportStatus?.value === "COMPLETED" && (
      <div>
        <Tick fill="#fff" />
      </div>
    )}
    <H2>{reportStatus?.label}</H2>
  </Badge>
);

type Props = {
  address?: string,
  reportStatus?: getReports_buyersAgentReports_reportStatus,
  children?: Node,
};

export const Card = ({ address, reportStatus, children }: Props) => (
  <div>
    <CardHeader reportStatus={reportStatus}>
      <H2>{address}</H2>
      <ReportStatusBadge reportStatus={reportStatus} />
    </CardHeader>
    <Wrapper>{children}</Wrapper>
  </div>
);
