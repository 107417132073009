// @flow
import { useEffect, useState } from "react";
import { css } from "styled-components";
import { device } from "@nest-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotifications } from "../../hooks/useNotifications";

const NOTIFICATION_TIMEOUT = 5000;

const CALLBACK_BUTTON_WIDTH = 66;

const notificationsWrapper = css`
  z-index: 99;
  @media ${device.tablet} {
    position: fixed;
    display: flex;
    flex-direction: column-reverse;
    bottom: 30px;
    right: 30px;
    pointer-events: none;
  }
`;

const notificationWrapperStyle = css`
  background-color: ${({ theme }) =>
    theme.palette?.hague150 || theme.color.background.hague};
  box-shadow: 0px 0px 0px 3px rgba(2, 66, 85, 0.2);
  bottom: ${({ rendered }) => (rendered ? "0" : "-10px")};
  /* Mobile only has space to show the latest notification */
  display: ${({ isLatest }) => (isLatest ? "flex" : " none")};
  left: 0;
  opacity: ${({ rendered }) => (rendered ? "0.95" : "0")};
  padding: 0;
  position: fixed;
  transition: bottom 200ms linear, opacity 200ms linear;
  width: 100%;

  @media ${device.tablet} {
    border-radius: 10px;
    display: flex;
    margin-top: 10px;
    position: relative;
    width: 400px;
  }
`;

const notificationStyle = css`
  align-items: center;
  background-color: ${({ theme }) =>
    theme.palette?.hague150 || theme.color.background.hague};
  border: none;
  color: ${({ theme }) => theme.palette.hague20 || "white"};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  flex-direction: row;
  line-height: 21px;
  padding: 10px 15px;
  pointer-events: ${({ rendered }) => (rendered ? "auto" : "none")};
  text-align: left;
  width: ${({ includesCallback }) =>
    includesCallback ? `calc(100% - ${CALLBACK_BUTTON_WIDTH}px)` : "100%"};

  strong {
    font-weight: 500;
  }

  @media ${device.tablet} {
    border-radius: ${({ includesCallback }) =>
      includesCallback ? "10px 0 0 10px" : "10px"};
  }
`;

const callbackStyle = css`
  background-color: ${({ theme }) =>
    theme.palette?.hague150 || theme.color.background.hague};
  border: none;
  color: white;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  pointer-events: ${({ rendered }) => (rendered ? "auto" : "none")};
  text-decoration: underline;
  width: ${CALLBACK_BUTTON_WIDTH}px;

  @media ${device.tablet} {
    border-radius: 0 10px 10px 0;
  }
`;

const subTextStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// exported for storybook
type NotificationProps = {
  callback: ?{ text: string, onClick: () => any },
  dismiss: () => void,
  icon: ({ fill?: string }) => Element<"svg">,
  isLatest: boolean,
  message: string,
  subText?: string,
  visible: boolean,
};

export const Notification = ({
  callback,
  dismiss,
  icon,
  isLatest,
  message,
  subText,
  visible,
}: NotificationProps) => {
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    // By requesting an animation frame we ensure the component is added to the DOM whilst still
    // invisible, then on the next frame we set it to be visible allowing it to smoothly transition in.
    requestAnimationFrame(() => {
      setRendered(true);
    });

    // Automatically dismiss the notification after a few seconds
    const timeoutId = setTimeout(dismiss, NOTIFICATION_TIMEOUT);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // We unbind the hover and click event handlers when the notification is out of view to prevent
  // unnecessary re-renders
  return (
    <div
      css={notificationWrapperStyle}
      isLatest={isLatest}
      rendered={rendered && visible}
    >
      <button
        rendered={rendered && visible}
        css={notificationStyle}
        onClick={visible ? dismiss : undefined}
        includesCallback={Boolean(callback)}
      >
        <div css="padding-right: 10px;">
          <FontAwesomeIcon css="color: #ffb3be;" size="lg" icon={icon} />
        </div>
        <div css="width: calc(100% - 15px);">
          <strong>{message}</strong>
          {subText && <div css={subTextStyle}>{subText}</div>}
        </div>
      </button>
      {callback && (
        <button
          css={callbackStyle}
          rendered={rendered && visible}
          onClick={visible ? () => callback.onClick() : undefined}
        >
          {callback.text}
        </button>
      )}
    </div>
  );
};

export const Notifications = () => {
  const { notifications } = useNotifications();

  return (
    <div css={notificationsWrapper}>
      {notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          {...notification}
          isLatest={index + 1 === notifications.length}
        />
      ))}
    </div>
  );
};
