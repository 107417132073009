import T from "prop-types";
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

const FeesAdvanceMay2018 = ({ nestDeal: { dealTypeAdvanceMay2018 } }) => (
  <>
    <LeafCell>
      <PercentageField
        property="advanceFee"
        label="Advance fee %"
        value={dealTypeAdvanceMay2018.advanceFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="agencyFee"
        label="Agency fee %"
        value={dealTypeAdvanceMay2018.agencyFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="noAdvanceDiscountPercentage"
        label="Discount for no advance %"
        value={dealTypeAdvanceMay2018.noAdvanceDiscountPercentage}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeePerDay"
        label="Early payment fee % (per day)"
        value={dealTypeAdvanceMay2018.earlyPaymentFeePerDay}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeeFreePercent"
        label="Early payment fee free %"
        value={dealTypeAdvanceMay2018.earlyPaymentFeeFreePercent}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        property="referralDiscount"
        label="Referral discount"
        value={dealTypeAdvanceMay2018.referralDiscount}
        readOnly
      />
    </LeafCell>
  </>
);

FeesAdvanceMay2018.propTypes = {
  nestDeal: T.object,
};

export { FeesAdvanceMay2018 };
