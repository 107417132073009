import T from "prop-types";
import { withStateHandlers, withHandlers, compose } from "recompose";
import Linkify from "react-linkify";

import { withOnSubmit, withReadOnly } from "@nest-ui/hocs";
import { NoSubmitTextField } from "components/TextField";

import { LinkifiedTextArea } from "./LinkifiedTextArea";

const enhance = compose(
  withStateHandlers(() => ({ editing: false }), {
    onBlur: () => () => ({ editing: false }),
    onFocus: () => () => ({ editing: true }),
  }),
  withHandlers({
    // A TextareaWithLinks gains focus when the 'fake' textarea
    // is focused and immediately replaced with a 'real' textarea'.
    // Consequently, the 'real' textarea does not have focus when
    // it's rendered, which the below remedies.
    inputRef: () => (ref) => ref && ref.focus(),
  }),
  withReadOnly(({ value }) =>
    value === null ? null : (
      <Linkify properties={{ target: "_blank" }}>{value}</Linkify>
    ),
  ),
);

// The TextareaWithLinks is a noble lie.
// It is, in fact, either an actual textarea (when focused)
// or an identically formatted div (when blurred) that converts
// all URLs to hyperlinks, allowing users to
// add useful links to their work easily.
export const NakedTextareaWithLinks = ({
  onBlur,
  editing,
  onFocus,
  placeholder,
  label,
  value,
  inputRef,
  className,
  highlightRed,
  ...rest
}) =>
  editing ? (
    // An actual text area
    <NoSubmitTextField
      multiline
      className={className}
      {...rest}
      label={label}
      value={value}
      onBlur={onBlur}
      inputRef={inputRef}
    />
  ) : (
    // A cunning div that automatically converts URLs to hyperlinks
    <LinkifiedTextArea
      label={label}
      value={value}
      onFocus={onFocus}
      placeholder={placeholder}
      className={className}
      highlightRed={highlightRed}
    />
  );

NakedTextareaWithLinks.propTypes = {
  label: T.string.isRequired,
  placeholder: T.string,
  value: T.oneOfType([T.string, T.number, T.bool]),
  inputRef: T.func.isRequired,
  onBlur: T.func.isRequired,
  onFocus: T.func.isRequired,
  editing: T.bool.isRequired,
  className: T.string,
};

export const NoSubmitTextareaWithLinks = enhance(NakedTextareaWithLinks);
export const TextareaWithLinks = compose(
  enhance,
  withOnSubmit,
)(NakedTextareaWithLinks);
