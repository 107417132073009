// @flow
import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import moment from "moment";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Form, Field } from "react-final-form";
import { NoSubmitDatePicker } from "@nest-ui/sellers-nest/components/DatePicker";
import { Select } from "../../../components/Select/Select";
import { Button } from "../../../components/Button/Button";
import {
  formStyle,
  fieldStyle,
  submitButtonStyle,
  required,
} from "./StatusFormShared";

export const DISINSTRUCTION_OPTIONS_QUERY = gql`
  query ReasonsForDisinstruction {
    reasonsForDisinstruction {
      label
      value
    }
  }
`;

export const DISINSTRUCTION_MUTATION = gql`
  mutation DisinstructDeal($input: DisinstructDealInput!) {
    disinstructDeal(input: $input) {
      id
      active
    }
  }
`;

type Props = {
  closeForm: () => void,
  closeOverlay?: () => void,
  "data-test"?: string,
  id: string,
  keepToggleInactive?: () => void,
};

export const DisinstructionForm = ({
  id,
  closeForm,
  closeOverlay,
  "data-test": dataTest = "disinstruction-form",
  keepToggleInactive,
}: Props) => {
  const [disinstructionDate, setDisinstructionDate] = useState(
    moment().format("L"),
  );
  const { createNotification } = useNotifications();
  const { data, loading, error } = useQuery(DISINSTRUCTION_OPTIONS_QUERY);
  const [disinstructDeal, { error: mutationError }] = useMutation(
    DISINSTRUCTION_MUTATION,
  );

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return null;
  }

  const { reasonsForDisinstruction } = data;

  const onSubmit = async (values) => {
    const input = Object.assign(values, { dealId: id });
    try {
      const result = await disinstructDeal({
        variables: { input },
        optimisticResponse: {
          disinstructDeal: {
            __typename: "NestDeal",
            id,
            active: false,
          },
        },
      });
      if (!result?.data?.disinstructDeal.active) {
        createNotification("Deactivated");
        closeOverlay ? closeOverlay() : closeForm();
      } else {
        errorHandler(mutationError);
        return null;
      }
    } catch (e) {
      if (keepToggleInactive) keepToggleInactive();
      errorHandler(e);
    }
    return null;
  };

  return (
    <div css={formStyle}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, values, hasValidationErrors, submitting }) => (
          <form data-test={dataTest} onSubmit={handleSubmit}>
            <div css={fieldStyle}>
              <Field name="dateDisinstructed">
                {({ input }) => (
                  <NoSubmitDatePicker
                    data-test={`${dataTest}:date-disinstructed`}
                    {...input}
                    onSubmit={(date) => {
                      setDisinstructionDate(date);
                      return input.onChange(date);
                    }}
                    value={disinstructionDate}
                    label="Date disinstructed"
                  />
                )}
              </Field>
            </div>
            <div css={fieldStyle}>
              <Field name="reasonForDisinstruction" validate={required}>
                {({ input }) => {
                  return (
                    <Select
                      data-test={`${dataTest}:disinstruct-reason-dropdown`}
                      options={reasonsForDisinstruction}
                      label="Reason for disinstruct"
                      labelAbove
                      valid={Boolean(values?.reasonForDisinstruction)}
                      {...input}
                    />
                  );
                }}
              </Field>
            </div>

            <div css={submitButtonStyle}>
              <Button
                data-test={`${dataTest}:submit-button`}
                disabled={hasValidationErrors || submitting}
                buttonStyle="pink"
                type="submit"
                css="width: 100%;"
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
