// @flow
import styled from "styled-components";

import { A } from "components/A/A";
import { Label } from "components/Label";

type Props = {
  buildURL?: (string) => string,
  label?: string,
  text: string,
  value: string,
};

const LinkContainer = styled.div`
  height: 100%;
  label {
    margin-bottom: 15px;
  }
`;

export const LinkField = ({
  buildURL = (x) => x,
  label,
  text,
  value,
}: Props) => {
  if (value) {
    return (
      <div>
        {label && <Label>{label}</Label>}
        <A href={buildURL(value)} text={text} />
      </div>
    );
  }
  return null;
};

type LinkFieldForGridProps = {
  label?: string,
  text: string,
  value: string,
};

export const LinkFieldForGrid = (props: LinkFieldForGridProps) => (
  <LinkContainer {...props}>
    <LinkField {...props} />
  </LinkContainer>
);
