// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const closeIconStyle = css`
  padding: 20px;
  color: ${({ theme }) => theme.palette.hague70};
  background: none;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 22px;
  font-size: 20px;
  z-index: 2;
  cursor: pointer;
`;

export const XButton = ({
  className,
  "data-test": dataTest = "close-modal",
  onClick,
}: {
  className?: string,
  "data-test"?: string,
  onClick: () => void,
}) => (
  <button
    data-test={dataTest}
    className={className}
    css={closeIconStyle}
    onClick={onClick}
    aria-label="Close"
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
);
