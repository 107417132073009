// @flow
import { Heading } from "components/Heading";
import { Card } from "components/Card";
import { ProposedCustomerEligibility } from "../sections/ProposedCustomerEligibility/ProposedCustomerEligibility";
import { CoolingOffPeriodSignoffContainer } from "../sections/CoolingOffPeriodSignoff/CoolingOffPeriodSignoff";
import { ContractDetails } from "../sections/ContractDetails/ContractDetails";
import { GuaranteeNotes } from "../sections/GuaranteeNotes";
import { DueDiligenceDatesSection } from "../sections/DueDiligenceDatesSection";

type Props = { dealId: string };

export const LegacyLegalServices = ({ dealId }: Props) => (
  <>
    <GuaranteeNotes dealId={dealId} />
    <Card>
      <ProposedCustomerEligibility dealId={dealId} />
    </Card>
    <DueDiligenceDatesSection dealId={dealId} />
    <section>
      <Heading id="cooling-off-period-sign-off">
        DD period sign-off (S07)
      </Heading>
      <Card>
        <CoolingOffPeriodSignoffContainer dealId={dealId} />
      </Card>
      <Heading id="contract-details">Contract Details</Heading>
      <Card>
        <ContractDetails dealId={dealId} />
      </Card>
    </section>
  </>
);
