// @flow
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, gql, useApolloClient } from "@apollo/client";
import { css } from "styled-components";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { isEmpty } from "lodash";
import { isValidEmail } from "@nested/utils";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { media } from "@nested/brand";
import { Button } from "../../Button/Button";
import { TextInput } from "../../TextInput/TextInput";
import { TextArea } from "../../TextArea/TextArea";
import { MobileFullScreenModal } from "../../Modal";
import { AreYouSureOverlay } from "../../AreYouSureOverlay";
import { Section, submitButtonWrapper } from "./CreateDeal";

const errorStyle = css`
  color: ${({ theme }) => theme.palette.terracotta150};
  position: absolute;
  bottom: -10px;
`;

const formStyle = css`
  padding: 10px 20px;
  ${media.tablet`
    padding: 0px 30px 20px 30px;
  `}
`;

const fieldStyle = css`
  position: relative;
  margin: 0;
  padding: 10px 0;
  width: 100%;
`;

const desktopRow = css`
  ${media.tablet`
    display: flex;
    gap: 20px;
  `}
`;

const existingBuyersContainer = css`
  ${formStyle}
  p {
    font-size: 14px;
    margin: 0 0 5px;
  }
`;

const backButton = css`
  color: ${({ theme }) => theme.palette.hague70};
  background-color: white;
  border: none;
  margin-bottom: 20px;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
`;

const existingBuyerButton = css`
  padding: 0;
  color: ${({ theme }) => theme.palette.blue150};
  margin: 10px 0;
  display: block;
  background-color: white;
  text-align: left;
  :hover {
    text-decoration: underline;
  }
`;

export const FIND_EXISTING_BUYER_BY_EMAIL = gql`
  query FindExistingBuyerByEmail($emailAddress: String!) {
    findBuyersByEmailAddress(emailAddress: $emailAddress) {
      id
      name
    }
  }
`;

export const CREATE_BUYER = gql`
  mutation CreateBuyer($input: CreateBuyerInput!) {
    createBuyer(input: $input) {
      id
    }
  }
`;

const validate = ({ firstName, lastName, email, phoneNumber }) => {
  let errors = {};
  if (!firstName || firstName === "") {
    errors = { ...errors, firstName: "First name is required" };
  }
  if (!lastName || lastName === "") {
    errors = { ...errors, lastName: "Last name is required" };
  }

  if (!isValidEmail(email || "")) {
    errors = { ...errors, email: "Please enter a valid email address" };
  }

  if (!phoneNumber || phoneNumber === "") {
    errors = { ...errors, phoneNumber: "Contact number is required" };
  }
  return isEmpty(errors) ? undefined : errors;
};

type Props = {
  onClose(): void,
  open: boolean,
  existingBuyers: FindExistingBuyerByEmail_findBuyersByEmailAddress[],
  setExistingBuyers: (
    FindExistingBuyerByEmail_findBuyersByEmailAddress[],
  ) => void,
  setEditing: (boolean) => void,
};

const CreateBuyerForm = ({
  onClose,
  open,
  existingBuyers,
  setExistingBuyers,
  setEditing,
}: Props) => {
  const firstNameInput = useRef();
  const [checkingEmail, setCheckingEmail] = useState(false);
  const apolloClient = useApolloClient();

  useEffect(() => {
    setTimeout(() => {
      if (firstNameInput.current) {
        firstNameInput.current?.focus();
      }
    }, 500);
  }, [open]);

  const history = useHistory();
  const [mutation] = useMutation(CREATE_BUYER);
  const onSubmit = async (state) => {
    try {
      const result = await mutation({
        variables: {
          input: state,
        },
      });

      if (result?.data?.createBuyer) {
        setExistingBuyers([]);
        onClose();
        history.push(`/buyers/${result.data.createBuyer.id}`);
        return undefined;
      }

      throw new Error("Failed to create buyer");
    } catch (e) {
      errorHandler(e);
      return {
        [FORM_ERROR]: "Failed to create buyer, please try again later",
      };
    }
  };

  const checkForExistingBuyersByEmail = async (input) => {
    const emailAddress = input.target.value;
    setCheckingEmail(true);
    try {
      const {
        data: { findBuyersByEmailAddress: buyers },
      } = await apolloClient.query({
        query: FIND_EXISTING_BUYER_BY_EMAIL,
        variables: { emailAddress },
        fetchPolicy: "network-only",
      });

      setExistingBuyers(buyers);
    } catch (e) {
      errorHandler(e);
    } finally {
      setCheckingEmail(false);
    }
  };

  return (
    <Form
      initialValues={
        open
          ? {}
          : {
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
            }
      }
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, submitting, hasValidationErrors, form, pristine }) => (
        <>
          {existingBuyers.length > 0 ? (
            <div css={existingBuyersContainer}>
              <button
                onClick={() => {
                  form.change("email", "");
                  setExistingBuyers([]);
                }}
                css={backButton}
              >
                &#60; Back to form
              </button>
              <p css="font-weight: 500;">
                There is already a buyer with that email address
              </p>
              <p css="font-style: italic;">Click below to be redirected</p>
              {existingBuyers.map(({ id, name }, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    history.push(`/buyers/${id}`);
                    onClose();
                  }}
                  css={existingBuyerButton}
                >
                  {name} - nest.nested.com/buyers/{id}
                </Button>
              ))}
            </div>
          ) : (
            <FormFields
              pristine={pristine}
              setEditing={setEditing}
              handleSubmit={handleSubmit}
              firstNameInput={firstNameInput}
              checkForExistingBuyersByEmail={checkForExistingBuyersByEmail}
              checkingEmail={checkingEmail}
              submitting={submitting}
              hasValidationErrors={hasValidationErrors}
            />
          )}
        </>
      )}
    </Form>
  );
};

const FormFields = ({
  pristine,
  setEditing,
  handleSubmit,
  firstNameInput,
  checkForExistingBuyersByEmail,
  checkingEmail,
  submitting,
  hasValidationErrors,
}) => {
  useEffect(() => {
    setEditing(!pristine);
  }, [pristine]);

  return (
    <form onSubmit={handleSubmit}>
      <div css={formStyle}>
        <Section title="Buyer details">
          <div css={desktopRow}>
            <div css={fieldStyle}>
              <Field name="firstName">
                {({ input, meta }) => (
                  <TextInput
                    forwardedRef={firstNameInput}
                    {...input}
                    label="First name"
                    valid={meta.valid}
                    className="fs-exclude"
                  />
                )}
              </Field>
            </div>
            <div css={fieldStyle}>
              <Field name="lastName">
                {({ input, meta }) => (
                  <TextInput
                    valid={meta.valid}
                    {...input}
                    className="fs-exclude"
                    label="Last name"
                  />
                )}
              </Field>
            </div>
          </div>
          <div css={desktopRow}>
            <div css={fieldStyle}>
              <Field name="email">
                {({ input, meta }) => (
                  <>
                    <TextInput
                      valid={meta.valid}
                      {...input}
                      onBlur={checkForExistingBuyersByEmail}
                      label="Email"
                      loading={checkingEmail}
                      className="fs-exclude"
                    />
                    {meta.dirty && meta.error && (
                      <span css={errorStyle}>{meta.error}</span>
                    )}
                  </>
                )}
              </Field>
            </div>
            <div css={fieldStyle}>
              <Field name="phoneNumber">
                {({ input, meta }) => (
                  <TextInput
                    valid={meta.valid}
                    {...input}
                    label="Contact number"
                    className="fs-exclude"
                  />
                )}
              </Field>
            </div>
          </div>
          <div css={fieldStyle}>
            <Field name="notes">
              {({ input }) => (
                <TextArea {...input} label="General notes" optional />
              )}
            </Field>
          </div>
        </Section>
      </div>
      <div css={submitButtonWrapper}>
        <Button
          buttonStyle="pink"
          css="&:disabled { opacity: 0.4 };"
          data-test="create-buyer-button"
          disabled={submitting || hasValidationErrors || checkingEmail}
          large
          type="submit"
        >
          {submitting ? "Saving..." : "Add new buyer"}
        </Button>
      </div>
    </form>
  );
};

export const CreateBuyer = ({
  open,
  onClose,
}: {
  onClose(): void,
  open: boolean,
}) => {
  const [existingBuyers, setExistingBuyers] = useState([]);
  const [areYouSure, setAreYouSure] = useState(false);
  const [editing, setEditing] = useState(false);

  const onCloseResetState = () => {
    setExistingBuyers([]);
    setAreYouSure(false);
    setEditing(false);
    onClose();
  };

  const onCloseUnlessEditing = () => {
    if (editing) {
      setAreYouSure(true);
    } else {
      onCloseResetState();
    }
  };

  return (
    <MobileFullScreenModal
      onClose={onCloseUnlessEditing}
      open={open}
      css="width: 780px;"
      headerText="Add new buyer"
    >
      {open && (
        <>
          <CreateBuyerForm
            open={open}
            onClose={onCloseResetState}
            existingBuyers={existingBuyers}
            setExistingBuyers={setExistingBuyers}
            setEditing={setEditing}
          />
          {areYouSure && (
            <AreYouSureOverlay
              message="You have unsaved changes. Are you sure you want to stop creating this buyer?"
              leftText="Continue editing"
              leftOnClick={() => setAreYouSure(false)}
              rightText="Discard changes"
              rightOnClick={onCloseResetState}
            />
          )}
        </>
      )}
    </MobileFullScreenModal>
  );
};
