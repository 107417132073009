// @flow
import { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";
import { gql, useQuery } from "@apollo/client";

import { FloatingAgent } from "../../components/FloatingAgent";
import { NoResults } from "../../components/NoResults";
import { useTaskCounters } from "../../hooks/useTaskCounters";
import { useUser } from "../../hooks/useUser";

import { TaskList } from "./TaskList";

export const TASKS_TODAY = gql`
  query TaskToday($email: String!) {
    agentTasksToday(email: $email) {
      completedAt
      description
      dueAt
      id
      reference {
        ... on NestDeal {
          id
          active
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          externalId
          firstName
          lastName
          nurtureHotness {
            label
          }
          ownerName
        }
        ... on Buyer {
          id
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          name
        }
      }
    }
  }
`;

export const COMPLETED_TASKS = gql`
  query TasksCompletedToday($email: String!) {
    tasksCompletedToday(email: $email) {
      completedAt
      description
      dueAt
      id
      reference {
        ... on NestDeal {
          id
          active
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          externalId
          firstName
          lastName
          nurtureHotness {
            label
          }
          ownerName
        }
        ... on Buyer {
          id
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          name
        }
      }
    }
  }
`;

const CompletedTasksWrapper = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  padding: 15px 0;
  &.completed-enter {
    opacity: 0;
  }
  &.completed-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }
  &.completed-exit {
    opacity: 1;
  }
  &.completed-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`;

const CompletedTasksToggle = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const noResultsViewCompletedTasksStyle = css`
  color: ${({ theme }) => theme.palette.blue150};
  font-weight: 500;
  cursor: pointer;
`;

const ViewCompletedTasksButton = ({ setShowCompleted }) => (
  <div
    css={noResultsViewCompletedTasksStyle}
    onClick={() => setShowCompleted(true)}
    role="button"
    tabIndex={0}
    onKeyPress={() => setShowCompleted(true)}
  >
    View completed tasks
  </div>
);

export const Fade = styled.div`
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease;
  }
`;

export const Today = () => {
  const [showCompleted, setShowCompleted] = useState(false);
  const { selectedUser } = useUser();
  const {
    previousTodayData,
    data: todayData = previousTodayData,
    loading: todayLoading,
    error: todayError,
    refetch: refetchToday,
  } = useQuery(TASKS_TODAY, {
    variables: { email: selectedUser.email },
    fetchPolicy: "network-only",
  });

  const todayTasks = todayData?.agentTasksToday || [];

  const {
    previousData: previousCompletedData,
    data: completedData = previousCompletedData,
    loading: completedLoading,
    error: completedError,
    refetch: refetchCompleted,
  } = useQuery(COMPLETED_TASKS, {
    variables: { email: selectedUser.email },
    fetchPolicy: "network-only",
  });

  const completedTasks = completedData?.tasksCompletedToday || [];

  const { dueToday, upcoming, taskChanged } = useTaskCounters();

  useEffect(() => {
    // prevent unnecessary refetching
    if (!todayLoading && !todayError) {
      // We want a timeout in order to allow useTaskCompletion to handle the refetching (it does so in a smooth manner).
      // we still have refetch calls here, so that if someone on a Deal/Buyer page changes the tasks, then it'll update on the Tasks page too.
      setTimeout(() => {
        refetchToday();
        refetchCompleted();
      }, 1000);
    }
  }, [dueToday, upcoming]);

  // delay not needed for these changes
  useEffect(() => {
    if (!todayLoading && !todayError) {
      refetchToday();
      refetchCompleted();
    }
  }, [taskChanged]);

  const completedText = showCompleted
    ? `Hide completed (${completedTasks.length})`
    : `Show completed (${completedTasks.length})`;

  const refetchQueries = {
    refetchUncompleted: refetchToday,
    refetchCompleted,
  };
  const showTaskList =
    todayLoading ||
    todayError ||
    completedLoading ||
    todayTasks.length > 0 ||
    showCompleted;

  return (
    <SwitchTransition>
      <CSSTransition
        key={showTaskList.toString()}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="fade"
      >
        {showTaskList ? (
          <Fade>
            <TaskList
              loading={
                todayLoading || (completedLoading && todayTasks.length === 0)
              }
              error={todayError}
              tasks={todayTasks}
              refetchQueries={refetchQueries}
            />

            {!todayLoading && !completedLoading && (
              <CSSTransition
                in={completedTasks.length > 0}
                classNames="completed"
                timeout={300}
                unmountOnExit
              >
                <CompletedTasksWrapper>
                  <CompletedTasksToggle
                    data-test="show-hide-completed"
                    onClick={() => setShowCompleted(!showCompleted)}
                    onKeyPress={() => setShowCompleted(!showCompleted)}
                    tabIndex={0}
                  >
                    {completedText}
                  </CompletedTasksToggle>
                </CompletedTasksWrapper>
              </CSSTransition>
            )}

            {(showCompleted || completedError) && (
              <TaskList
                completedSection
                error={completedError}
                loading={completedLoading}
                tasks={completedTasks}
                refetchQueries={refetchQueries}
              />
            )}
          </Fade>
        ) : (
          <Fade>
            <NoResults
              image={null}
              message="You're up to date!"
              subtext={
                completedTasks.length > 0 ? (
                  <ViewCompletedTasksButton
                    setShowCompleted={setShowCompleted}
                  />
                ) : undefined
              }
            >
              <FloatingAgent />
            </NoResults>
          </Fade>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};
