import styled, { css } from "styled-components";

const sharedDefaultStyles = css`
  ${({ theme, isSecondary }) =>
    isSecondary &&
    `background-color: ${theme.color.secondary.dark};`} width: 100%;
  border-radius: 4px;
  border: ${({ theme, isSecondary, highlightRed }) => {
    if (highlightRed) {
      return `2px solid ${theme.color.background.danger}`;
    }
    if (isSecondary) {
      return "0";
    }
    return `solid 2px ${theme.color.secondary.highlight}`;
  }};
  color: ${({ theme, mutedText, isSecondary }) => {
    if (isSecondary) {
      return theme.color.text.light;
    }
    if (mutedText) {
      return theme.color.text.muted;
    }
    return theme.color.text.default;
  }};
  padding: 8px;
  padding-right: ${({ showCharacterCounter }) =>
    showCharacterCounter ? "60px !important" : "inherit"};
  font-size: ${({ theme }) => theme.font.size.default};
`;

const sharedFocusStyles = css`
  border: ${({ theme, highlightRed, isSecondary }) => {
    if (highlightRed) {
      return `2px solid ${theme.color.background.danger}`;
    }
    if (isSecondary) {
      return "0";
    }
    return `solid 2px ${theme.color.primary.dark}`;
  }};
  outline: none;
`;

const sharedTextareaStyles = css`
  min-height: 32px;
  max-height: 800px;
  overflow: auto;
  resize: none;
  line-height: 16px;
  /* Account for the line-height adding 4px */
  padding-top: 7px;
  padding-bottom: 5px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const characterCount = css`
  position: absolute;
  top: calc(50% + 2px);
  transform: translateY(-50%);
  right: 8px;
  color: ${({ theme }) => theme.color.text.hague40};
  background-color: white;
  font-size: 14px;
  padding-left: 5px;
`;

const Div = styled.div`
  input, textarea {
    height: 32px;
    ${sharedDefaultStyles}
    &:focus {
      ${sharedFocusStyles}
    }

    /* hide 'arrows' on number and date fields - https://app.clubhouse.io/nested/story/3731/remove-arrows-on-number-fields */
    &[type="number"], &[type="date"], &[type="month"] {
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }

    &:hover, :focus {
      &::-webkit-calendar-picker-indicator {
        display: initial;
      }
    }

    &::-webkit-calendar-picker-indicator {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    ${sharedTextareaStyles}
    /* I don't know, and I don't want to talk about it */
    margin-bottom: -2px;
`;

export { Div, sharedDefaultStyles, sharedFocusStyles, sharedTextareaStyles };
