import { theme } from "@nest-ui/styles";

const DuplicateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g fill={theme.color.text.muted} fillRule="nonzero">
      <path d="M10.5 13a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h7zm0 1h-7A1.5 1.5 0 0 1 2 12.5v-7A1.5 1.5 0 0 1 3.5 4h7A1.5 1.5 0 0 1 12 5.5v7a1.5 1.5 0 0 1-1.5 1.5z" />
      <path d="M5.5 3a.5.5 0 0 1-1 0A1.5 1.5 0 0 1 6 1.5h7A1.5 1.5 0 0 1 14.5 3v7a1.5 1.5 0 0 1-1.5 1.5.5.5 0 1 1 0-1 .5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0-.5.5zM6.5 7.5a.5.5 0 0 1 1 0v3a.5.5 0 0 1-1 0v-3z" />
      <path d="M8.5 8.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1h3z" />
    </g>
  </svg>
);

export { DuplicateIcon };
