// @flow
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Select } from "@nested/nest/src/components/Select/Select";
import { Button } from "@nested/nest/src/components/Button/Button";
import GetActiveUsersQuery from "./queries/GetActiveUsersQuery.graphql";

export const DEACTIVATE_USER_MUTATION = gql`
  mutation deactivateUser($id: ID!, $transferTo: ID!) {
    deactivateNestedUser(id: $id, transferTo: $transferTo) {
      id
      active
    }
  }
`;

const textStyle = css`
  color: ${({ theme }) => theme.palette.hague};
  font-size: 14px;
  font-weight: 500;
`;

const formStyle = css`
  ${media.tablet`
    display: flex; 
    justify-content: space-between;
  `}
`;

const selectStyle = css`
  width: 100%;
  ${media.tablet`
    width: 70%;
  `}
`;

const buttonStyle = css`
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  ${media.tablet`
    margin: auto 0;
    position: unset;
    width: 200px;
  `}
`;

export const DeactivateUser = ({
  onClose,
  userId,
}: {
  onClose: () => void,
  userId: string,
}) => {
  const [transferTo, setTransferTo] = useState(null);
  const { data: activeUsersData } = useQuery(GetActiveUsersQuery);
  const [deactivateUser] = useMutation(DEACTIVATE_USER_MUTATION, {
    refetchQueries: [{ query: GetActiveUsersQuery }],
  });

  const deactivateAndTransfer = async () => {
    if (!transferTo) {
      window.alert("Please choose an agent to transfer to");
      return;
    }

    try {
      const { data: deactivatedUserData } = await deactivateUser({
        variables: {
          id: userId,
          transferTo,
        },
      });
      if (!deactivatedUserData.deactivateNestedUser.active) {
        onClose();
      }
    } catch (e) {
      window.alert(e);
    }
  };

  if (!activeUsersData) return null;

  const options = activeUsersData.activeNestedUsers.reduce((acc, user) => {
    if (user.id === userId) {
      return acc;
    }

    const userOption = {
      label: user.fullName,
      value: user.id,
    };
    return [...acc, userOption];
  }, []);

  return (
    <>
      <div css="padding: 0 20px 20px;">
        <p css={textStyle}>
          Reassign postcodes, active pre-closed deals, CP role and incomplete
          tasks to:
        </p>
        <div css={formStyle}>
          <Select
            css={selectStyle}
            data-test="select-reassign-agent"
            onChange={(e) => setTransferTo(e)}
            options={options}
            value={transferTo}
          />
          <Button
            css={buttonStyle}
            disabled={!transferTo}
            onClick={() => deactivateAndTransfer()}
          >
            Deactivate and Reassign
          </Button>
        </div>
      </div>
    </>
  );
};
