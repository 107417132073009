// @flow
import React from "react";
import styled from "styled-components";

const CollapseControl = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const ShowHide = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  color: ${({ theme }) => theme.color.text.highlight};
  font-weight: inherit;
  text-decoration: underline;
  padding: 0 0 0 4px;
`;

type Props = {
  "data-test"?: string,
  items: Array<React$Node>,
  createLabel: (count: number) => string,
};

type State = {
  expanded: boolean,
};

export class CollapsibleList extends React.Component<Props, State> {
  static defaultProps = {
    createLabel: (count: number) => {
      switch (count) {
        case 0:
          return "No hidden items";
        case 1:
          return "1 item hidden -";
        default:
          return `${count} items hidden -`;
      }
    },
  };

  state = {
    expanded: false,
  };

  toggleShowHide = () => {
    this.setState(({ expanded }) => ({
      expanded: !expanded,
    }));
  };

  renderControl = () => {
    if (this.props.items.length > 1) {
      return (
        <CollapseControl data-test="collapsible-list:control">
          <span data-test="collapsible-list:label">
            {this.props.createLabel(this.props.items.length - 1)}
          </span>
          <ShowHide
            data-test="collapsible-list:toggle-button"
            onClick={this.toggleShowHide}
          >
            {this.state.expanded ? "Collapse" : "Show all"}
          </ShowHide>
        </CollapseControl>
      );
    }

    return null;
  };

  render() {
    if (this.props.items.length === 0) {
      return null;
    }

    const [firstItem, ...rest] = this.props.items;

    return (
      <div data-test={this.props["data-test"] || "collapsible-list"}>
        {firstItem}
        {this.renderControl()}
        {this.state.expanded && rest}
      </div>
    );
  }
}
