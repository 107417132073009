// @flow
import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { max, maxBy } from "lodash";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { formatPrice, getImage } from "@nested/utils";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";
import { useListViewCounts } from "../../../hooks/useListViewCounts";
import { FloatingAgent } from "../../../components/FloatingAgent";
import { ListViewError } from "../../../components/ListViewError";
import { NoResults } from "../../../components/NoResults";
import { PlaceholderTable } from "../../../components/Placeholder";
import { Table, TH, TD } from "../../../components/Table";
import { MobileLoader } from "../../../components/MobileListViewDeal";
import {
  ListViewPageWrapper,
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
} from "../../../components/ListViewSharedStyles";
import { groupEnquiriesByDeal, noBorderBpi } from "../listViewHelpers";

export const OFFERS_QUERY = gql`
  query OffersListViewQuery($email: String!) {
    negotiatingOffers(email: $email) {
      results {
        id
        deal {
          id
          externalId
          address
          currentListPrice
        }
        buyer {
          id
          name
        }
        buyerOffers {
          id
          placedOn
          latestRenegotiatedOfferAmount
          status {
            label
          }
        }
        viewings {
          id
          datetimeViewingStarts
        }
      }
    }
  }
`;

// mobile styles

const dealWrapper = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding-bottom: 20px;
`;

const dealHeading = css`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 18px;
  margin-bottom: 20px;
`;

const addressStyle = css`
  font-weight: 500;
`;

const bpiWrapper = css`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const buyerDetailsWrapper = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
`;

const buyerNameStyle = css`
  font-weight: 500;
  flex-grow: 1;
`;

const contactSummaryAndChevronWrapper = css`
  display: flex;
`;

const chevronStyle = css`
  flex-grow: 0;
  color: ${({ theme }) => theme.palette.hague40};
`;

// tablet/desktop styles

const buyerNameCellStyle = css`
  padding-left: 20px;
  border-left: 1px solid ${({ theme }) => theme.palette.hague20};
  vertical-align: top;
`;

const buyerLinkStyle = css`
  font-weight: 500;
  padding: 15px 20px 15px 0;
  display: block;
  padding-top: ${({ $first }) => ($first ? "15px" : "7.5px")};
  padding-bottom: ${({ $last }) => ($last ? "15px" : "7.5px")};
`;

const photoAndAddressWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
const addressLinkStyle = css`
  font-weight: 400;
  padding: 0px 20px 0px 0px;
  display: block;
`;

const tableCellStyle = css`
  padding: 15px 15px 15px 0;
  vertical-align: top;
`;

// shared styles

const imageStyle = css`
  width: 60px;
  margin-right: 15px;
  border-radius: 2px;
`;

const offerStatusStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  padding-right: 10px;
`;

const workflowLink = (bpi) =>
  `/buyers/${bpi.buyer.id}/interests/${bpi.id}/workflows/negotiations`;

const getLatestOffer = (bpi) =>
  maxBy(bpi.buyerOffers, (offer) => [offer.placedOn, offer.id]);

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map(({ deal, bpis }) => (
        <div
          css={dealWrapper}
          data-test={`deal-row-${deal.externalId}`}
          key={deal.externalId}
        >
          <div css={dealHeading}>
            <img
              alt=""
              css={imageStyle}
              src={getImage(`property-listings/${deal.externalId}/1`)}
            />
            <div>
              <div css={addressStyle}>{deal.address}</div>
              <div>{formatPrice(Number(deal.currentListPrice))}</div>
            </div>
          </div>
          {bpis.map((bpi) => {
            const latestOffer = getLatestOffer(bpi);
            return (
              <Link
                data-test={`bpi-row-${bpi.id}`}
                key={bpi.id}
                to={workflowLink(bpi)}
                css={bpiWrapper}
              >
                <div css={buyerDetailsWrapper}>
                  <div>
                    <div css={buyerNameStyle}>{bpi.buyer.name}</div>
                    {latestOffer?.latestRenegotiatedOfferAmount &&
                    latestOffer?.placedOn ? (
                      <>
                        <div css={offerStatusStyle} data-test="offer-summary">
                          {formatPrice(
                            Number(latestOffer.latestRenegotiatedOfferAmount),
                          )}{" "}
                          - {latestOffer.status.label}
                        </div>
                        <div css={offerStatusStyle} data-test="offer-date">
                          Placed {formatDateDifference(latestOffer.placedOn)}
                        </div>
                      </>
                    ) : (
                      <div css={offerStatusStyle}>No offers yet</div>
                    )}
                  </div>
                  <div css={contactSummaryAndChevronWrapper}>
                    <div css={chevronStyle}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const DealTable = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH css="width: 40%; min-width: 270px;">Property</TH>
            <TH css="width: 15%;">List price</TH>
            <TH css="width: 15%; padding-left: 20px;">Buyer</TH>
            <TH css="width: 15%;">Offer</TH>
            <TH css="width: 15%;">Offer status</TH>
            <TH css="width: 15%;">Offer received</TH>
          </tr>
        </thead>
        <tbody>
          {results.map(({ deal, bpis }, resultIndex) =>
            bpis.map((bpi, index) => {
              const latestOffer = getLatestOffer(bpi);
              return (
                <tr key={bpi.id}>
                  {index === 0 && (
                    <>
                      <TD
                        css={tableCellStyle}
                        rowSpan={bpis.length}
                        noBorder={resultIndex === results.length - 1}
                      >
                        <Link to={workflowLink(bpi)} css={addressLinkStyle}>
                          <div css={photoAndAddressWrapper}>
                            <img
                              alt=""
                              css={imageStyle}
                              src={getImage(
                                `property-listings/${deal.externalId}/1`,
                              )}
                            />
                            {deal.address}
                          </div>
                        </Link>
                      </TD>
                      <TD
                        css={tableCellStyle}
                        rowSpan={bpis.length}
                        noBorder={resultIndex === results.length - 1}
                      >
                        <Link to={workflowLink(bpi)} css={addressLinkStyle}>
                          {formatPrice(Number(deal.currentListPrice))}
                        </Link>
                      </TD>
                    </>
                  )}
                  <TD
                    css={buyerNameCellStyle}
                    className="fs-exclude"
                    noBorder={noBorderBpi({
                      index,
                      bpis,
                      resultIndex,
                      results,
                    })}
                  >
                    <Link
                      css={buyerLinkStyle}
                      to={workflowLink(bpi)}
                      $first={index === 0}
                      $last={index === bpis.length - 1}
                    >
                      {bpi.buyer.name}
                    </Link>
                  </TD>
                  <TD
                    noBorder={noBorderBpi({
                      index,
                      bpis,
                      resultIndex,
                      results,
                    })}
                    css="vertical-align: top;"
                  >
                    <Link
                      css={buyerLinkStyle}
                      to={workflowLink(bpi)}
                      $first={index === 0}
                      $last={index === bpis.length - 1}
                    >
                      {latestOffer?.latestRenegotiatedOfferAmount
                        ? formatPrice(
                            Number(latestOffer.latestRenegotiatedOfferAmount),
                          )
                        : "-"}
                    </Link>
                  </TD>
                  <TD
                    noBorder={noBorderBpi({
                      index,
                      bpis,
                      resultIndex,
                      results,
                    })}
                    css="vertical-align: top;"
                  >
                    <Link
                      css={buyerLinkStyle}
                      to={workflowLink(bpi)}
                      $first={index === 0}
                      $last={index === bpis.length - 1}
                    >
                      {latestOffer?.status?.label || "-"}
                    </Link>
                  </TD>
                  <TD
                    noBorder={noBorderBpi({
                      index,
                      bpis,
                      resultIndex,
                      results,
                    })}
                    css="vertical-align: top;"
                  >
                    <Link
                      css={buyerLinkStyle}
                      to={workflowLink(bpi)}
                      $first={index === 0}
                      $last={index === bpis.length - 1}
                    >
                      {latestOffer?.placedOn
                        ? formatDateDifference(latestOffer.placedOn)
                        : "-"}
                    </Link>
                  </TD>
                </tr>
              );
            }),
          )}
        </tbody>
      </Table>
    </div>
  );
};

export const TodaysNegotiationsListView = () => {
  const { selectedUser } = useUser();
  const { negotiatingOffers } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(OFFERS_QUERY, {
    variables: {
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [negotiatingOffers]);

  const results = data?.negotiatingOffers?.results || [];

  const enquiriesByDeal = useMemo(
    () =>
      groupEnquiriesByDeal(results, (bpi) =>
        max(bpi.viewings.map((v) => v.datetimeViewingStarts)),
      ),
    [results],
  );

  if (!loading && results.length === 0) {
    return (
      <ListViewPageWrapper>
        <NoResults
          image={null}
          message="Negotiations dealt with"
          subtext={
            <p>
              Have you got any{" "}
              <Link
                css="text-decoration: underline !important;"
                to="/leads/book-visit"
              >
                seller leads to speak to
              </Link>
              ?
            </p>
          }
        >
          <FloatingAgent />
        </NoResults>
      </ListViewPageWrapper>
    );
  }

  if (error) {
    return (
      <ListViewPageWrapper>
        <ListViewError />
      </ListViewPageWrapper>
    );
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <DealList loading={loading} results={enquiriesByDeal} />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable loading={loading} results={enquiriesByDeal} />
      </div>
    </>
  );
};
