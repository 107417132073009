import styled from "styled-components";
import { device } from "@nest-ui/styles";

const frGetter = (value) => `repeat(${value}, 1fr)`;
// We should never really have > 2 columns on mobile, so cap the number of columns at 2
// Obviously not ideal, but there are hundreds of components using this and cba
// to define a mobile layout for all of them - it works quite well for the vast majority
const mobileColumnCap = (value) => (value > 2 ? 2 : value);
const formatAreas = (areas) => areas.map((area) => `"${area}"`).join(" ");

export const Grid = styled.div`
  grid-column-gap: ${({ columnGap = "16px" }) => `${columnGap}`};
  grid-row-gap: ${({ rowGap = "12px" }) => `${rowGap}`};
  display: grid;
  height: auto;
  grid-auto-flow: row;
  ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};
  grid-template-columns: ${({ columns = 2 }) =>
    frGetter(mobileColumnCap(columns))};
  grid-gap: 8px;
  ${({ mobileAreas }) =>
    mobileAreas && `grid-template-areas: ${formatAreas(mobileAreas)}`};

  @media ${device.desktop} {
    ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};
    grid-template-columns: ${({ columns = 12 }) => frGetter(columns)};
  }
`;

export const Cell = styled.div`
  min-width: 0;
  height: 100%;
  grid-column-end: ${({ width = 1 }) => `span ${mobileColumnCap(width)}`};
  grid-row-end: ${({ height = 1 }) => `span ${height}`};
  ${({ left }) => left && `grid-column-start: ${mobileColumnCap(left)}`};
  ${({ center }) => center && `text-align: center`};
  ${({ area }) => area && `grid-area: ${area}`};
  ${({ middle }) =>
    middle &&
    `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `};
  overflow: visible;

  @media ${device.desktop} {
    grid-column-end: ${({ width = 1 }) => `span ${width}`};
    ${({ left }) => left && `grid-column-start: ${left}`};
    ${({ area }) => area && `grid-area: ${area}`};
  }
`;

export const LeafCell = styled(Cell)`
  display: flex;
  align-items: flex-end;

  > * {
    width: 100%;
  }
`;

export const FullScreenGrid = styled(Grid)`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.color.background.default};
  overflow-x: auto;
  overflow-y: hidden;
`;
