// @flow

import { H2 } from "components/Heading";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { gql } from "@apollo/client";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { Grid, LeafCell } from "components/Grid";
import { type MutationFunction } from "@apollo/client/react/components";
import { CurrencyField } from "components/CurrencyField";
import styled from "styled-components";
import { ListEntryCard } from "components/Card/ListEntryCard";

const RM_LISTING_PRICE_RECOMMENDATION_QUERY = gql`
  query RmRecommendedListPrice($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      rmRecommendedMinListPrice
      rmRecommendedListPrice
      rmRecommendedMaxListPrice
    }
  }
`;

const RM_LISTING_PRICE_RECOMMENDATION_MUTATION = gql`
  mutation updateRmRecommendedListPrice($dealId: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $dealId, nestDeal: $input) {
      id
      rmRecommendedMinListPrice
      rmRecommendedListPrice
      rmRecommendedMaxListPrice
    }
  }
`;

const ListingPriceRecommendationSectionCard = styled(ListEntryCard)`
  margin-top: 16px;
`;

const updateRecommendedPrice = (input, field: string, nestDeal, mutation) => {
  const newInput = input[field] === "0" ? { ...input, [field]: null } : input;
  mutation({
    variables: {
      dealId: nestDeal.id,
      input: newInput,
    },
    optimisticResponse: {
      updateNestDeal: {
        ...nestDeal,
        ...newInput,
      },
    },
  });
};

export const ListingPriceRecommendationSection = ({
  parentId: dealId,
}: {|
  parentId: string,
|}) => {
  return (
    <ExtendedQuery
      query={RM_LISTING_PRICE_RECOMMENDATION_QUERY}
      variables={{ dealId }}
    >
      {({ nestDeal }) => (
        <ExtendedMutation mutation={RM_LISTING_PRICE_RECOMMENDATION_MUTATION}>
          {(
            updateRmRecommendedListPrice: MutationFunction<
              updateRmRecommendedListPrice,
              updateRmRecommendedListPriceVariables,
            >,
          ) => (
            <>
              <ListingPriceRecommendationSectionCard>
                <H2>Listing Price Recommendation</H2>
                <Grid columns={3}>
                  <LeafCell width={1}>
                    <CurrencyField
                      data-test="recommended-min-list-price"
                      property="rmRecommendedMinListPrice"
                      value={nestDeal.rmRecommendedMinListPrice}
                      mutation={(input) => {
                        updateRecommendedPrice(
                          input,
                          "rmRecommendedMinListPrice",
                          nestDeal,
                          updateRmRecommendedListPrice,
                        );
                      }}
                      label="Recommended Min Listing Price"
                    />
                  </LeafCell>
                  <LeafCell width={1}>
                    <CurrencyField
                      data-test="recommended-list-price"
                      property="rmRecommendedListPrice"
                      value={nestDeal.rmRecommendedListPrice}
                      mutation={(input) => {
                        updateRecommendedPrice(
                          input,
                          "rmRecommendedListPrice",
                          nestDeal,
                          updateRmRecommendedListPrice,
                        );
                      }}
                      label="Recommended Listing Price"
                    />
                  </LeafCell>
                  <LeafCell width={1}>
                    <CurrencyField
                      data-test="recommended-max-list-price"
                      property="rmRecommendedMaxListPrice"
                      value={nestDeal.rmRecommendedMaxListPrice}
                      mutation={(input) => {
                        updateRecommendedPrice(
                          input,
                          "rmRecommendedMaxListPrice",
                          nestDeal,
                          updateRmRecommendedListPrice,
                        );
                      }}
                      label="Recommended Max Listing Price"
                    />
                  </LeafCell>
                </Grid>
              </ListingPriceRecommendationSectionCard>
            </>
          )}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
};
