// @flow
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import moment from "moment";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { formatPrice, getImage } from "@nested/utils";
import { media } from "@nested/brand";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { ListViewError } from "../../components/ListViewError";
import { NoResults } from "../../components/NoResults";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, TD } from "../../components/Table";
import { MobileLoader } from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
} from "../../components/ListViewSharedStyles";

const buyerLinkStyle = css`
  padding: 20px 20px 20px 0;
  display: block;
`;

const bpiWrapper = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const bpiDetailsStyle = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 15px 0;
  line-height: 18px;
  flex-grow: 1;
`;

const imageStyle = css`
  width: 80px;
  margin: 15px 15px 15px 0;
  border-radius: 2px;

  ${media.tablet`
    margin: 0;
    margin-right: 15px;
    width: 60px;
  `}
`;

const dueStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
`;

const bpiLink = (bpi) => `/buyers/${bpi.buyer.id}/interests/${bpi.id}`;

const getDueDateMobile = (due) => {
  const dueDatetime = moment(due);
  const daysUntilDue = moment(due)
    .startOf("day")
    .diff(moment().startOf("day"), "days");

  // If in the past
  if (daysUntilDue < 0) {
    return "tomorrow";
  }

  // If later today - '10:40am today'
  if (daysUntilDue === 0) {
    return `${dueDatetime.format("h:mma")} today`;
  }

  // If tomorrow
  if (daysUntilDue === 1) {
    return "tomorrow";
  }

  // If not tomorrow but in next 7 days
  if (daysUntilDue < 7) {
    return `in ${daysUntilDue} days`;
  }

  // If later than in next 7 days e.g. 'on 27/01/2021'
  return dueDatetime.format("on L");
};

const getDueDateTablet = (due) => {
  const dueDatetime = moment(due);
  const daysUntilDue = moment(due)
    .startOf("day")
    .diff(moment().startOf("day"), "days");

  // If in the past
  if (daysUntilDue < 0) {
    return "Tomorrow";
  }

  // If later today - '10:40am today'
  if (daysUntilDue === 0) {
    return `${dueDatetime.format("h:mma")} today`;
  }

  // If tomorrow
  if (daysUntilDue === 1) {
    return "Tomorrow";
  }

  // If not tomorrow but in next 7 days
  if (daysUntilDue < 7) {
    return `${daysUntilDue} days`;
  }

  // If later than in next 7 days e.g. '27/01/2021'
  return dueDatetime.format("DD/MM/YY");
};

const DealList = ({ getDueTimestamp, loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((bpi) => (
        <Link
          key={bpi.id}
          to={bpiLink(bpi)}
          css={bpiWrapper}
          data-test={`bpi-row-${bpi.id}`}
        >
          <img
            alt=""
            css={imageStyle}
            src={getImage(`property-listings/${bpi.deal.externalId}/1`)}
          />
          <div css={bpiDetailsStyle}>
            <div>{bpi.deal.address}</div>
            <div>{bpi.buyer.name}</div>
            <div css={dueStyle}>
              Due {getDueDateMobile(getDueTimestamp(bpi))}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

const DealTable = ({ getDueTimestamp, loading, results }) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <TH css="width: 95px;">List price</TH>
            <TH css="width: 120px;">Buyer</TH>
            <TH css="width: 130px;">Due</TH>
          </tr>
        </thead>
        <tbody>
          {results.map((bpi) => (
            <tr key={bpi.id}>
              <TD>
                <Link to={bpiLink(bpi)} css={buyerLinkStyle}>
                  <div css="display: flex; flex-direction: row; align-items: center;">
                    <img
                      alt=""
                      css={imageStyle}
                      src={getImage(
                        `property-listings/${bpi.deal.externalId}/1`,
                      )}
                    />
                    {bpi.deal.address}
                  </div>
                </Link>
              </TD>
              <TD>
                <Link to={bpiLink(bpi)} css={buyerLinkStyle}>
                  {formatPrice(Number(bpi.deal.currentListPrice))}
                </Link>
              </TD>
              <TD className="fs-exclude">
                <Link to={bpiLink(bpi)} css={buyerLinkStyle}>
                  {bpi.buyer.name}
                </Link>
              </TD>
              <TD>
                <Link to={bpiLink(bpi)} css={buyerLinkStyle}>
                  {getDueDateTablet(getDueTimestamp(bpi))}
                </Link>
              </TD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

type Props = {
  queryField: string,
  query: any,
  emptyMessage: string,
  getDueTimestamp: (bpi: Object) => string,
};

export const UpcomingBuyersListView = ({
  emptyMessage,
  getDueTimestamp,
  queryField,
  query,
}: Props) => {
  const { selectedUser } = useUser();
  const counters = useListViewCounts();
  const count = counters[queryField];

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(query, {
    variables: {
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [count]);

  const results = data?.[queryField]?.results || [];

  if (!loading && results.length === 0) {
    return (
      <>
        <NoResults message={emptyMessage} image={null} />
      </>
    );
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <DealList
          getDueTimestamp={getDueTimestamp}
          loading={loading}
          results={results}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          getDueTimestamp={getDueTimestamp}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
