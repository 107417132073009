import { createContext, useEffect, useState } from "react";
import { Presence } from "phoenix";
import { useUser } from "@nested/nest/src/hooks/useUser";
import { useWebsocket } from "@nested/nest/src/hooks/useWebsocket";
import { isMobile } from "@nested/nest/src/components/MobileActionSheet/MobileActionSheetContext";

export const PresenceContext = createContext();

export const PresenceProvider = ({ children }) => {
  const socket = useWebsocket();
  const { email } = useUser();
  const [activeSessions, setActiveSessions] = useState([]);
  const [mobilePresent, setMobilePresent] = useState(false);

  useEffect(() => {
    const run = async () => {
      const presenceChannel = socket.channel(`presence:${email}`);
      const presence = new Presence(presenceChannel);
      presence.onSync(() => {
        setActiveSessions(presence.list());
      });
      presenceChannel.join();
    };

    run();
  }, []);

  useEffect(() => {
    setMobilePresent(
      activeSessions
        .map(({ metas }) => metas.map(({ user_agent }) => user_agent))
        .flat()
        .some((userAgent) => isMobile(userAgent)),
    );
  }, [activeSessions]);

  return (
    <PresenceContext.Provider value={{ mobilePresent }}>
      {children}
    </PresenceContext.Provider>
  );
};
