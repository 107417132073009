import guaranteePaymentHistoryAggregateFields from "../queries/guaranteePaymentHistoryAggregateFields.graphql";

export const deleteConfig = {
  props: ({
    mutate,
    ownProps: {
      data: { nestDeal },
    },
  }) => ({
    deleteGuaranteePayment: (guaranteePaymentId) => () =>
      mutate({
        variables: {
          guaranteePaymentId,
          dealId: nestDeal.id,
        },
        refetchQueries: [
          {
            query: guaranteePaymentHistoryAggregateFields,
            variables: {
              id: nestDeal.id,
            },
          },
        ],
      }),
  }),
};
