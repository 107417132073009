// @flow
import moment from "moment-timezone";
import { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { ApolloConsumer, ApolloClient, gql } from "@apollo/client";

import { NoSubmitCreateButton } from "@nest-ui/sellers-nest/components/CreateButton/CreateButton";
import { H2 } from "@nest-ui/sellers-nest/components/Heading";
import { Modal } from "@nest-ui/sellers-nest/components/Modal";
import { SelectField } from "@nest-ui/sellers-nest/components/SelectField";
import {
  excludeRearrangedViewings,
  getViewingsIncludingAndFollowingRearrangedViewing,
  getViewingsPrecedingRearrangedViewing,
} from "@nest-ui/sellers-nest/tabs/Interest/Viewings/utility-functions";
import { Warning } from "@nest-ui/sellers-nest/components/Warning";

import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { SchedulingCard } from "./SchedulingCard";
import { ViewingPlanner } from "./ViewingPlanner";
import { ViewingCard } from "./ViewingCard";
import { BUYER_PROPERTY_INTEREST_FRAGMENT } from "../../BuyerPropertyInterestFragment";
import { PROPERTY_INTERESTS_QUERY } from "../../PropertyInterests";

const INPUT_ALERT = "INPUT";
const TEXT_ALERT = "TEXT";
const CONFIRMATION_ALERT = "CONFIRMATION";

const getAlertInfo = (missingFields) => {
  const reasonForFirstErrorIs = (reason) =>
    missingFields.length === 1 && missingFields[0] === reason;
  if (reasonForFirstErrorIs("Buyer Address")) {
    return {
      type: CONFIRMATION_ALERT,
      text: "It's important we gather the buyer's address before a viewing, for security and future marketing potential.\n\nAre you sure you want to continue without the buyer's address?\n",
    };
  }
  if (reasonForFirstErrorIs("Location")) {
    return {
      type: TEXT_ALERT,
      text: "⛔️ It's important we gather information about areas the buyer is interested in (Location field), for better cross-selling recommendations in the future.",
    };
  }
  return {
    type: TEXT_ALERT,
    text: "⛔️ You must fill in the highlighted red fields before proceeding.\n\nWe expose some of this info to sellers in their account and it looks bad if we haven't gathered it about their buyers.",
  };
};

const REQUIRED_BUYER_FIELDS = gql`
  query RequiredBuyerFields($buyerId: ID!) {
    requiredBuyerFields(id: $buyerId) {
      undefinedRequiredFields
    }
  }
`;

const BUYER_SAVE_INCOMPLETE_REQUIREMENTS_REASON = gql`
  mutation BuyerSaveIncompleteRequirementsReason(
    $buyerId: ID!
    $reason: String!
  ) {
    buyerSaveIncompleteRequirementsReason(buyerId: $buyerId, reason: $reason) {
      id
    }
  }
`;

const CANCEL_VIEWING = gql`
  mutation CancelViewing($input: CancelViewingInput!, $id: ID!) {
    cancelViewing(input: $input, id: $id) {
      id
      notes
      viewingCancellationReasonId
    }
  }
`;

const CREATE_VIEWING_MUTATION = gql`
  mutation CreateViewing(
    $input: CreateViewingInput!
    $buyerPropertyInterestId: ID!
  ) {
    createViewing(
      input: $input
      buyerPropertyInterestId: $buyerPropertyInterestId
    ) {
      id
      ...buyerPropertyInterestFields
    }
  }
  ${BUYER_PROPERTY_INTEREST_FRAGMENT}
`;

const REARRANGE_VIEWING_MUTATION = gql`
  mutation RearrangeViewing(
    $buyerPropertyInterestId: ID!
    $newViewingInput: CreateViewingInput!
    $rearrangedViewingId: ID!
    $rearrangedViewingInput: CancelViewingInput!
  ) {
    rearrangeViewing(
      buyerPropertyInterestId: $buyerPropertyInterestId
      newViewingInput: $newViewingInput
      rearrangedViewingId: $rearrangedViewingId
      rearrangedViewingInput: $rearrangedViewingInput
    ) {
      id
      ...buyerPropertyInterestFields
    }
  }
  ${BUYER_PROPERTY_INTEREST_FRAGMENT}
`;

export const UPDATE_VIEWING = gql`
  mutation UpdateViewing($input: UpdateViewingInput!, $id: ID!) {
    updateViewing(input: $input, id: $id) {
      conductor
      datetimeConfirmedWithVendor
      datetimeFeedbackSharedWithSeller
      id
      notes
      viewingCancellationReasonId
      viewingFeedbackFull
      nestedUserId
      viewingSubAgentId
      virtual
    }
  }
`;

const VIEWING_AVAILABILITY_QUERY = gql`
  query ViewingAvailability($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      externalId
      viewingAvailability {
        availabilitySummaries {
          dayOfWeek
          ranges {
            rangeStart
            rangeEnd
          }
        }
      }
    }
  }
`;

const Heading = styled(H2)`
  margin: 0;
  overflow: visible;
`;

const ViewingsContainer = styled.section`
  margin: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {|
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  associatedSas: {
    buyerLeadSa: ?PropertyInterestsByBuyer_buyer_leadSaUser,
    buyerPropertyInterestSa: ?PropertyInterestsByBuyer_activeNestedUsers,
    vendorLeadSa: ?buyerPropertyInterestFields_deal_vendorLeadSa,
    currentlyResponsibleSaUser: ?buyerPropertyInterestFields_deal_currentlyResponsibleSaUser,
  },
  buyerId: ID,
  buyerContacts: $ReadOnlyArray<buyerPropertyInterestFields_buyer_contacts>,
  buyerPropertyInterestId: string,
  changeBuyerPropertyInterestSa: (id: ID) => void,
  dealId: ID,
  hasAssignedSa: boolean,
  buyerIsOnTheMarket: boolean,
  isSchedulingDisabled?: boolean,
  propertyAccessArrangements: ?string,
  subAgents: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_deal_subAgents>,
  vendorContacts: $ReadOnlyArray<buyerPropertyInterestFields_deal_contacts>,
  viewings: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings>,
  viewingCancellationReasons: $ReadOnlyArray<PropertyInterestsByBuyer_viewingCancellationReasons>,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
|};

export const Viewings = (props: Props) => {
  const {
    activeNestedUsers,
    associatedSas,
    buyerPropertyInterestId,
    buyerContacts,
    changeBuyerPropertyInterestSa,
    buyerId,
    dealId,
    hasAssignedSa,
    buyerIsOnTheMarket,
    isSchedulingDisabled = false,
    propertyAccessArrangements,
    subAgents,
    vendorContacts,
    viewingCancellationReasons,
    viewings,
    viewingAssistants,
  } = props;

  const [isSchedulingNewViewing, setIsSchedulingNewViewing] = useState(false);
  const [rearrangedViewingId, setRearrangedViewingId] = useState(null);
  const [rearrangedViewingInput, setRearrangedViewingInput] = useState(null);
  const [
    isSchedulingForRearrangedViewing,
    setIsSchedulingForRearrangedViewing,
  ] = useState(false);
  const [saModalOpen, setSaModalOpen] = useState(false);

  const commonOptions = {
    refetchQueries: [
      {
        query: PROPERTY_INTERESTS_QUERY,
        variables: { buyerId },
      },
    ],
  };

  const [cancelViewingMutation] = useMutation(CANCEL_VIEWING, commonOptions);
  const [updateViewingMutation] = useMutation(UPDATE_VIEWING, commonOptions);
  const [createViewingMutation, { loading: createViewingMutationloading }] =
    useMutation(CREATE_VIEWING_MUTATION, commonOptions);

  const [
    rearrangeViewingMutation,
    { loading: rearrangeViewingMutationLoading },
  ] = useMutation(REARRANGE_VIEWING_MUTATION, commonOptions);

  const [buyerSaveIncompleteRequirementsReasonMutation] = useMutation(
    BUYER_SAVE_INCOMPLETE_REQUIREMENTS_REASON,
    commonOptions,
  );

  const addRearrangedViewingInput = (input) => {
    setIsSchedulingForRearrangedViewing(true);
    setRearrangedViewingInput(input);
  };

  const cancelScheduling = () => {
    setIsSchedulingNewViewing(false);
    setRearrangedViewingId(null);
    setRearrangedViewingInput(null);
  };

  const formatViewingDateTime = (date: string, time: string) =>
    moment(date)
      .set({
        hour: moment(time).get("hour"),
        minute: moment(time).get("minute"),
      })
      .toISOString();

  const createViewing = async (values) => {
    const {
      confirmedWithVendor,
      viewingAgentOptions,
      viewingDate,
      viewingEndTime,
      viewingStartTime,
      virtual,
    } = values;

    const input = {
      confirmedWithVendor: confirmedWithVendor === "Yes",
      datetimeViewingEnds: formatViewingDateTime(viewingDate, viewingEndTime),
      datetimeViewingStarts: formatViewingDateTime(
        viewingDate,
        viewingStartTime,
      ),
      ...viewingAgentOptions,
      virtual,
    };

    await createViewingMutation({
      variables: {
        buyerPropertyInterestId,
        input,
      },
    });

    setIsSchedulingNewViewing(false);
  };

  const stopRearranging = () => {
    setRearrangedViewingId(null);
    setRearrangedViewingInput(null);
  };

  const rearrangeViewing = async (values) => {
    const {
      confirmedWithVendor,
      viewingAgentOptions,
      viewingDate,
      viewingEndTime,
      viewingStartTime,
      virtual,
    } = values;

    const newViewingInput = {
      confirmedWithVendor: confirmedWithVendor === "Yes",
      datetimeViewingEnds: formatViewingDateTime(viewingDate, viewingEndTime),
      datetimeViewingStarts: formatViewingDateTime(
        viewingDate,
        viewingStartTime,
      ),
      ...viewingAgentOptions,
      virtual,
    };

    await rearrangeViewingMutation({
      variables: {
        buyerPropertyInterestId,
        newViewingInput,
        rearrangedViewingId,
        rearrangedViewingInput,
      },
    });

    setIsSchedulingForRearrangedViewing(false);
    stopRearranging();
  };

  const sortedViewings = viewings
    .slice()
    .sort((a, b) =>
      moment(a.datetimeViewingStarts).diff(b.datetimeViewingStarts),
    );

  const viewingsExcludingRearranged = excludeRearrangedViewings(sortedViewings);

  const rearrangedViewing = rearrangedViewingId
    ? viewings.find((viewing) => viewing.id === rearrangedViewingId)
    : null;

  const viewingsPrecedingRearrangedViewing =
    getViewingsPrecedingRearrangedViewing({
      viewings: viewingsExcludingRearranged,
      rearrangedViewingId,
    });

  const viewingsIncludingAndFollowingRearrangedViewing =
    getViewingsIncludingAndFollowingRearrangedViewing({
      viewings: viewingsExcludingRearranged,
      rearrangedViewingId,
    });

  const viewingsFollowingRearrangedViewing =
    viewingsIncludingAndFollowingRearrangedViewing.slice(1);

  const { data, loading, error } = useQuery(VIEWING_AVAILABILITY_QUERY, {
    variables: { dealId },
  });

  const nestDeal = data?.nestDeal;

  const triggerInputAlert = (text) => {
    const reason = window.prompt(text); // eslint-disable-line no-alert
    if (reason) {
      buyerSaveIncompleteRequirementsReasonMutation({
        variables: {
          buyerId,
          reason,
        },
      });
      setIsSchedulingNewViewing(true);
    }
  };

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ViewingsContainer>
      <Modal
        isOpen={saModalOpen}
        closeModal={() => setSaModalOpen(false)}
        title="Change the SA on this buyer enquiry?"
        data-test="viewings:buyer-property-interest-sa-modal"
      >
        <Warning>
          The person doing this viewing isn't the SA assigned to the buyer
          enquiry.
          <br />
          You can change the SA on the buyer enquiry here, or on the buyer
          property interest summary card.
          <br />
          This won't affect the viewing.
        </Warning>
        <br />
        <p>SA assigned to the buyer enquiry:</p>
        <SelectField
          data-test={`viewings:modal-assigned-sa-field`}
          options={activeNestedUsers.map((user) => ({
            label: user.fullName,
            value: user.id,
          }))}
          property="buyerPropertyInterestSaId"
          value={associatedSas.buyerPropertyInterestSa?.id}
          mutation={({ buyerPropertyInterestSaId }) => {
            changeBuyerPropertyInterestSa(buyerPropertyInterestSaId);
          }}
        />
      </Modal>

      <Heading>Viewings</Heading>

      <ViewingPlanner
        activeNestedUsers={activeNestedUsers}
        dealId={dealId}
        subAgents={subAgents}
        viewingAssistants={viewingAssistants}
        viewingAvailability={nestDeal.viewingAvailability}
      />

      <ButtonContainer>
        {!isSchedulingDisabled && (
          <ApolloConsumer>
            {(client: ApolloClient<RequiredBuyerFields>) => {
              const onSubmit = async () => {
                const {
                  data: {
                    requiredBuyerFields: { undefinedRequiredFields },
                  },
                } = await client.query({
                  query: REQUIRED_BUYER_FIELDS,
                  // $FlowFixMe
                  variables: { buyerId },
                  fetchPolicy: "network-only",
                  nextFetchPolicy: "cache-first",
                });
                if (undefinedRequiredFields.length === 0) {
                  setIsSchedulingNewViewing(true);
                  return;
                }
                const { text, type } = getAlertInfo(undefinedRequiredFields);

                switch (type) {
                  case INPUT_ALERT: {
                    triggerInputAlert(text);
                    break;
                  }
                  case CONFIRMATION_ALERT: {
                    // eslint-disable-next-line no-alert
                    const hasConfirmedAlert = window.confirm(text);
                    if (hasConfirmedAlert) {
                      setIsSchedulingNewViewing(true);
                    }
                    break;
                  }
                  default:
                    // eslint-disable-next-line no-alert
                    window.alert(text);
                    break;
                }
              };

              return (
                !isSchedulingDisabled && (
                  <NoSubmitCreateButton
                    data-test="viewings:start-scheduling"
                    disabled={isSchedulingNewViewing || !hasAssignedSa}
                    onSubmit={onSubmit}
                  >
                    Schedule viewing
                  </NoSubmitCreateButton>
                )
              );
            }}
          </ApolloConsumer>
        )}
      </ButtonContainer>

      {viewingsPrecedingRearrangedViewing.map((viewing) => (
        <ViewingCard
          activeNestedUsers={activeNestedUsers}
          addRearrangedViewingInput={addRearrangedViewingInput}
          associatedSas={associatedSas}
          buyerContacts={buyerContacts}
          cancelViewingMutation={cancelViewingMutation}
          externalDealId={nestDeal.externalId}
          key={viewing.id}
          buyerIsOnTheMarket={buyerIsOnTheMarket}
          isRearrangingViewing={rearrangedViewingId === viewing.id}
          rearrangedViewingInput={rearrangedViewingInput}
          setSaModalOpen={() => setSaModalOpen(true)}
          startRearranging={setRearrangedViewingId}
          stopRearranging={stopRearranging}
          subAgents={subAgents}
          updateViewingMutation={updateViewingMutation}
          vendorContacts={vendorContacts}
          viewing={viewing}
          viewingCancellationReasons={viewingCancellationReasons}
          viewingAssistants={viewingAssistants}
        />
      ))}

      {isSchedulingNewViewing && (
        <SchedulingCard
          activeNestedUsers={activeNestedUsers}
          associatedSas={associatedSas}
          buyerContacts={buyerContacts}
          cancelScheduling={cancelScheduling}
          dealId={dealId}
          propertyAccessArrangements={propertyAccessArrangements}
          loading={createViewingMutationloading}
          setSaModalOpen={() => setSaModalOpen(true)}
          subAgents={subAgents}
          successCallback={createViewing}
          vendorContacts={vendorContacts}
          viewingAssistants={viewingAssistants}
          viewingAvailability={nestDeal.viewingAvailability}
        />
      )}

      {isSchedulingForRearrangedViewing && rearrangedViewing && (
        <SchedulingCard
          activeNestedUsers={activeNestedUsers}
          associatedSas={associatedSas}
          buyerContacts={buyerContacts}
          cancelScheduling={() => setIsSchedulingForRearrangedViewing(false)}
          dealId={dealId}
          loading={rearrangeViewingMutationLoading}
          propertyAccessArrangements={propertyAccessArrangements}
          rearrangedViewing={rearrangedViewing}
          setSaModalOpen={() => setSaModalOpen(true)}
          subAgents={subAgents}
          successCallback={rearrangeViewing}
          vendorContacts={vendorContacts}
          viewingAssistants={viewingAssistants}
          viewingAvailability={nestDeal.viewingAvailability}
        />
      )}
      {
        /**
         * We want to exclude the viewing that is currently being
         * rearranged when we are in a "scheduling" state
         */
        (isSchedulingForRearrangedViewing
          ? viewingsFollowingRearrangedViewing
          : viewingsIncludingAndFollowingRearrangedViewing
        ).map((viewing) => (
          <ViewingCard
            activeNestedUsers={activeNestedUsers}
            addRearrangedViewingInput={addRearrangedViewingInput}
            associatedSas={associatedSas}
            buyerContacts={buyerContacts}
            cancelViewingMutation={cancelViewingMutation}
            externalDealId={nestDeal.externalId}
            key={viewing.id}
            buyerIsOnTheMarket={buyerIsOnTheMarket}
            isRearrangingViewing={rearrangedViewingId === viewing.id}
            rearrangedViewingInput={rearrangedViewingInput}
            setSaModalOpen={() => setSaModalOpen(true)}
            startRearranging={setRearrangedViewingId}
            stopRearranging={stopRearranging}
            subAgents={subAgents}
            updateViewingMutation={updateViewingMutation}
            viewing={viewing}
            viewingCancellationReasons={viewingCancellationReasons}
            vendorContacts={vendorContacts}
            viewingAssistants={viewingAssistants}
          />
        ))
      }
    </ViewingsContainer>
  );
};
