// @flow
export const PRICE_REDUCTION = "price-reduction";
export const PROPERTY_JUST_LISTED = "property-just-listed";
export const FALL_THROUGH = "fall-through";
export const SLOW_MOVING_PROPERTY = "slow-moving-property";
export const COMING_SOON = "coming-soon";

export const createAnonymisedAddress = (
  property: MailoutModalQuery_nestDeal_property,
): string => {
  return [property?.street, property?.outcode]
    .filter((value) => value !== null)
    .join(", ");
};

const createPropertyDescriptor = (houseOrFlat, bedrooms) => {
  let beds;
  if (bedrooms === 0) {
    beds = "studio";
  } else if (bedrooms) {
    beds = `${bedrooms} bed`;
  } else {
    beds = "";
  }
  return `${beds} ${houseOrFlat}`;
};

const formatCircumstanceText = (circumstance) => {
  switch (circumstance) {
    case PRICE_REDUCTION:
      return { verb: "reduced", preposition: "to" };
    case FALL_THROUGH:
      return { verb: "brought back to market", preposition: "at" };
    default:
      return { verb: "listed", preposition: "at" };
  }
};

export const suggestEmailSubject = (
  circumstance: string,
  houseOrFlat: string,
  bedrooms: ?number,
  anonymisedAddress: string,
) => {
  const propertyDescriptor = createPropertyDescriptor(houseOrFlat, bedrooms);
  switch (circumstance) {
    case PROPERTY_JUST_LISTED:
      return `🏡💥 Just listed: ${propertyDescriptor} - ${anonymisedAddress}`;
    case PRICE_REDUCTION:
      return `🏡⬇️ Price reduction: ${propertyDescriptor} - ${anonymisedAddress}`;
    case FALL_THROUGH:
      return `🏡😲 Back on the market: ${propertyDescriptor} - ${anonymisedAddress}`;
    case SLOW_MOVING_PROPERTY:
      return `🏡🧐 Have you considered this ${propertyDescriptor} in ${anonymisedAddress}?`;
    case COMING_SOON:
      return `🏡🔮 Coming soon: ${propertyDescriptor} - ${anonymisedAddress}`;
    default:
      return "";
  }
};

const buildBedroomBullet = (bedrooms) => {
  if (!bedrooms) {
    return "";
  }
  if (bedrooms === 1) {
    return `* 1 bedroom\n`;
  }
  return `* ${bedrooms} bedrooms\n`;
};

const buildBathroomBullet = (bathrooms) => {
  if (!bathrooms) {
    return "";
  }
  if (bathrooms === 1) {
    return `* 1 bathroom`;
  }
  return `* ${bathrooms} bathrooms`;
};

export const suggestEmailBody = (
  circumstance: string,
  houseOrFlat: string,
  bedrooms: ?number,
  bathrooms: ?number,
  anonymisedAddress: string,
  price: string,
) => {
  const propertyDescriptor = createPropertyDescriptor(houseOrFlat, bedrooms);
  if (circumstance === SLOW_MOVING_PROPERTY) {
    return `Have you considered this ${propertyDescriptor} in ${anonymisedAddress} at ${price}? Please let me know your thoughts.`;
  }

  if (circumstance === COMING_SOON) {
    const bedroomBulletPoint = buildBedroomBullet(bedrooms);
    const bathroomBulletPoint = buildBathroomBullet(bathrooms);
    return `I've just been instructed on a ${propertyDescriptor} in ${anonymisedAddress} and I thought it might be of interest.\n\nI don't yet have photos to share but I'm expecting a lot of interest on this property. The details are:\n\n* ${price}\n${bedroomBulletPoint}${bathroomBulletPoint}`;
  }

  const { verb, preposition } = formatCircumstanceText(circumstance);

  return `I've just ${verb} this ${propertyDescriptor} in ${anonymisedAddress} ${preposition} ${price} and I thought it might be of interest.`;
};
