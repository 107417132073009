// @flow
import styled, { css } from "styled-components";
import { ErrorMessage, media } from "@nested/brand";

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const labelStyle = css`
  font-weight: 500;
  color: ${({ theme, $invalid }) =>
    $invalid ? theme.palette.terracotta150 : theme.palette.hague100};
  padding-bottom: 12px;
`;

const RadioListItemStyled = styled.label`
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.palette.hague150};
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  ${media.tablet`
    margin: 0 0 10px;
  `}
`;

const RadioInput = styled.input`
  /*
  Styles to visually hide whilst not impairing use by screenreaders
  https://a11yproject.com/posts/how-to-hide-content/
  */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
`;

const RadioCircle = styled.span`
  background-color: white;
  border-radius: 15px;
  border: 1px solid
    ${({ theme, $invalid }) =>
      $invalid ? theme.palette.terracotta150 : theme.palette.hague40};
  display: inline-block;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  top: 2px;
  margin-right: 15px;
  position: relative;
  visibility: visible;

  input + &::after {
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 11px;
    position: absolute;
    top: 3px;
    right: 3px;
    background-color: transparent;
  }

  input:active + &::after,
  input:focus:checked + &::after,
  input:checked + &::after {
    background-color: ${({ theme }) => theme.palette.blue150};
  }
  input:focus-visible + & {
    outline: 1px solid ${({ theme }) => theme.palette.hague100};
  }
`;

const dividerCss = css`
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const labelWrapper = css`
  ${({ $divider }) => $divider && dividerCss}
`;

const LabelText = styled.span`
  display: inline-block;
  word-break: break-all;
  width: 100%;
  vertical-align: text-top;
  color: ${({ theme }) => theme.palette.hague70};
  font-weight: 500;

  ${media.tablet`
    color: ${({ theme }) => theme.palette.hague70};
    font-weight: auto;
  `}
`;

const LabelSubtext = styled.span`
  display: inline-block;
  word-break: break-all;
  width: 100%;
  vertical-align: text-top;
  color: ${({ theme }) => theme.palette.hague70};
`;

type RadioListItemProps = {
  censorText?: boolean,
  checked: boolean,
  divider?: boolean,
  hasErrors?: boolean,
  label: string,
  onChange: (value: any) => any,
  radioName: string,
  subLabel?: string,
  value: string,
};

export const RadioListItem = ({
  checked,
  censorText = false,
  divider = false,
  hasErrors,
  label,
  onChange,
  radioName,
  subLabel,
  value,
}: RadioListItemProps) => (
  <RadioListItemStyled
    hasError={hasErrors}
    data-test={`label-${radioName}-${String(value)}`}
  >
    <RadioInput
      type="radio"
      name={radioName}
      value={value}
      onChange={() => onChange(value)}
      checked={checked}
      data-test={`input-${value}`}
    />
    <RadioCircle $invalid={hasErrors} />
    <span css={labelWrapper} $divider={divider}>
      <LabelText className={censorText ? "fs-exclude" : ""}>{label}</LabelText>
      {subLabel && (
        <LabelSubtext className={censorText ? "fs-exclude" : ""}>
          {subLabel}
        </LabelSubtext>
      )}
    </span>
  </RadioListItemStyled>
);

type RadioButtonsOptions = {
  label: string,
  radioName: string,
  subLabel: string,
  value: string,
};

type Props = {|
  censorText?: boolean,
  "data-test"?: string,
  dividers?: boolean,
  hasErrors?: boolean,
  label?: string,
  errorMessage?: string,
  options: RadioButtonsOptions[],
  selectedValue: string,
  onChange: (value: any) => any,
|};

export const RadioList = ({
  "data-test": dataTest,
  dividers = false,
  censorText = false,
  errorMessage,
  hasErrors,
  label: listLabel,
  onChange,
  options,
  selectedValue,
}: Props) => {
  const optionNumber = options.length;
  return (
    <>
      <RadioGroup data-test={dataTest}>
        {listLabel && (
          <label css={labelStyle} $invalid={hasErrors}>
            {listLabel}
          </label>
        )}
        {options.map(({ value, label, subLabel, radioName }, index) => {
          return (
            <RadioListItem
              value={value}
              label={label}
              subLabel={subLabel}
              radioName={radioName}
              key={`${index}-${value}`}
              divider={dividers && index !== optionNumber - 1}
              censorText={censorText}
              hasErrors={hasErrors}
              onChange={onChange}
              checked={value === selectedValue}
            />
          );
        })}
      </RadioGroup>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};
