import T from "prop-types";

import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { ListEntryCard } from "components/Card";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { AlignRight } from "components/AlignRight";
import { graphql } from "deal/utils/graphql";
import { NoSubmitTextField } from "components/TextField";
import { DatePicker } from "components/DatePicker";
import { SelectField } from "components/SelectField";

import deleteAgencyValuationMutation from "./deleteMutation/deleteAgencyValuation.graphql";
import { deleteConfig } from "./deleteMutation/config";

const AgencyValuationComponent = ({
  deleteAgencyValuation,
  expectedPrice,
  worstCaseValuation,
  reasonForUpdate,
  updatedByUserId,
  insertedAt,
  activeNestedUsers,
}) => (
  <ListEntryCard data-test="agency-valuation">
    <Grid columns={4}>
      <LeafCell width={1}>
        <CurrencyField
          label="Expected price"
          value={expectedPrice}
          data-test="agency-valuation-expected-price"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Worst case valuation"
          value={worstCaseValuation}
          data-test="agency-valuation-worst-case-valuation"
          readOnly
        />
      </LeafCell>
      <LeafCell width={2}>
        <NoSubmitTextField
          label="Reason for update"
          value={reasonForUpdate}
          data-test="agency-valuation-reason-for-update"
          readOnly
        />
      </LeafCell>
      <LeafCell width={2}>
        <SelectField
          label="Updated by"
          value={updatedByUserId}
          options={activeNestedUsers}
          data-test="agency-valuation-updated-by"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Date added"
          value={insertedAt}
          data-test="agency-valuation-date-added"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <AlignRight>
          <DeleteButton
            data-test="delete-agency-valuation"
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deleteAgencyValuation();
              }
            }}
          />
        </AlignRight>
      </LeafCell>
    </Grid>
  </ListEntryCard>
);

export const AgencyValuation = graphql(
  deleteAgencyValuationMutation,
  deleteConfig,
)(AgencyValuationComponent);

AgencyValuationComponent.propTypes = {
  expectedPrice: T.string,
  worstCaseValuation: T.string,
  deleteAgencyValuation: T.func.isRequired,
  reasonForUpdate: T.string,
  updatedByUserId: T.string,
  insertedAt: T.string,
  activeNestedUsers: T.array.isRequired,
};
