// @flow
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { MilestoneSection, MilestoneRow } from "./Milestones/MilestoneShared";
import { ExchangedMilestone } from "./Milestones/Exchanged";
import { CompletedMilestone } from "./Milestones/Completed";
import { MemoSentMilestone } from "./Milestones/MemoSent";
import getDealStatus from "../queries/reloadDealStatus.graphql";

const milestonesLine = css`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 2px;
    border-left: 2px solid ${({ theme }) => theme.palette.blue170};
    pointer-events: none;
    z-index: 0;
    top: 35px;
    bottom: 35px;
    left: 19px;
    ${media.tablet`
      left: 34px;
    `}
  }
`;

export const MILESTONES = gql`
  query MilestonesForDeal($buyerOfferId: ID) {
    progressionMilestones(buyerOfferId: $buyerOfferId) {
      allEnquiriesSatisfied {
        id
        reachedAt
        reachedAtTime
      }
      allSearchesReturned {
        id
        reachedAt
        reachedAtTime
      }
      appliedForSearches {
        id
        reachedAt
        reachedAtTime
      }
      buyerDraftContractPack {
        id
        reachedAt
        reachedAtTime
      }
      buyerReportedTo {
        id
        reachedAt
        reachedAtTime
      }
      completed {
        id
        reachedAt
        reachedAtTime
      }
      contractsSigned {
        id
        reachedAt
        reachedAtTime
      }
      exchanged {
        id
        reachedAt
        reachedAtTime
      }
      fundsDeposited {
        id
        reachedAt
        reachedAtTime
      }
      initialEnquiriesRaised {
        id
        reachedAt
        reachedAtTime
      }
      instructionFormIdChecks {
        id
        reachedAt
        reachedAtTime
      }
      managementPackLpeOneFormOrdered {
        id
        reachedAt
        reachedAtTime
      }
      managementPackLpeOneFormShared {
        id
        reachedAt
        reachedAtTime
      }
      memoSent {
        id
        reachedAt
        reachedAtTime
      }
      mortgageApplicationSubmitted {
        id
        reachedAt
        reachedAtTime
      }
      mortgageOfferReceived {
        id
        reachedAt
        reachedAtTime
      }
      mortgageValuationBooked {
        id
        reachedAt
        reachedAtTime
      }
      mortgageValuationReportReceived {
        id
        reachedAt
        reachedAtTime
      }
      protocolForms {
        id
        reachedAt
        reachedAtTime
      }
      searchMonies {
        id
        reachedAt
        reachedAtTime
      }
      sellerDraftContractPack {
        id
        reachedAt
        reachedAtTime
      }
    }
  }
`;

// These milestones aren't always relevant, depending on the tenure field on
// the deal and the finance method on the buyer offer. This subscription listens
// out for any changes to these relevant fields and receives the updated milestones
const RELEVANT_FIELDS_SUBSCRIPTION = gql`
  subscription RelevantMilestoneFieldsChanged($buyerOfferId: ID!) {
    relevantMilestoneFieldsChanged(buyerOfferId: $buyerOfferId) {
      managementPackLpeOneFormOrdered {
        id
        reachedAt
        reachedAtTime
      }
      managementPackLpeOneFormShared {
        id
        reachedAt
        reachedAtTime
      }
      mortgageApplicationSubmitted {
        id
        reachedAt
        reachedAtTime
      }
      mortgageOfferReceived {
        id
        reachedAt
        reachedAtTime
      }
      mortgageValuationBooked {
        id
        reachedAt
        reachedAtTime
      }
      mortgageValuationReportReceived {
        id
        reachedAt
        reachedAtTime
      }
    }
  }
`;

export const MilestonesList = ({
  buyerOfferId,
  dealId,
}: {
  buyerOfferId: ?string,
  dealId: string,
}) => {
  const { data: dealData } = useQuery(getDealStatus, { variables: { dealId } });
  const { data, loading, subscribeToMore } = useQuery(MILESTONES, {
    variables: { buyerOfferId },
  });
  const { currentUser } = useUser();

  // This receives any changed milestones (ie, newly relevant or no longer relevant
  // depending on some fields on the deal and buyer offer) and updates Apollo's cache
  // with these updated milestones.
  useEffect(() => {
    if (buyerOfferId) {
      subscribeToMore({
        document: RELEVANT_FIELDS_SUBSCRIPTION,
        variables: { buyerOfferId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedMilestones =
            subscriptionData.data.relevantMilestoneFieldsChanged;

          return {
            progressionMilestones: {
              ...prev.progressionMilestones,
              ...updatedMilestones,
            },
          };
        },
      });
    }
  }, []);

  if (loading) return null;

  const {
    allEnquiriesSatisfied,
    allSearchesReturned,
    appliedForSearches,
    buyerDraftContractPack,
    buyerReportedTo,
    completed,
    contractsSigned,
    exchanged,
    fundsDeposited,
    initialEnquiriesRaised,
    instructionFormIdChecks,
    managementPackLpeOneFormOrdered,
    managementPackLpeOneFormShared,
    memoSent,
    mortgageApplicationSubmitted,
    mortgageOfferReceived,
    mortgageValuationBooked,
    mortgageValuationReportReceived,
    protocolForms,
    searchMonies,
    sellerDraftContractPack,
  } = data.progressionMilestones;

  const allBuyerInstructionMilestonesReached =
    searchMonies?.reachedAt &&
    appliedForSearches?.reachedAt &&
    instructionFormIdChecks?.reachedAt &&
    buyerDraftContractPack?.reachedAt;

  const allSellersPropertyInfoReached =
    protocolForms?.reachedAt &&
    (!managementPackLpeOneFormShared ||
      managementPackLpeOneFormShared?.reachedAt) &&
    (!managementPackLpeOneFormOrdered ||
      managementPackLpeOneFormOrdered.reachedAt) &&
    sellerDraftContractPack?.reachedAt;

  const allEnquiriesReached =
    initialEnquiriesRaised?.reachedAt &&
    allSearchesReturned?.reachedAt &&
    allEnquiriesSatisfied?.reachedAt;

  const allBuyersFinancesReached =
    mortgageApplicationSubmitted?.reachedAt &&
    mortgageOfferReceived?.reachedAt &&
    mortgageValuationBooked?.reachedAt &&
    mortgageValuationReportReceived?.reachedAt;

  const allReportsAndContractsReached =
    buyerReportedTo?.reachedAt &&
    contractsSigned?.reachedAt &&
    fundsDeposited?.reachedAt;

  const showBuyersFinances = Boolean(mortgageApplicationSubmitted?.id);

  const basicMilestoneDisabled = !memoSent?.reachedAt || exchanged?.reachedAt;

  const hasCompleted =
    dealData?.nestDeal?.opportunityStatus?.valueText === "s11_completed";
  const canReachMemo =
    Boolean(buyerOfferId) && !hasCompleted && !memoSent?.reachedAt;

  const canReachExchanged =
    allBuyerInstructionMilestonesReached &&
    allSellersPropertyInfoReached &&
    allEnquiriesReached &&
    allReportsAndContractsReached &&
    (!mortgageApplicationSubmitted || allBuyersFinancesReached) &&
    !exchanged?.reachedAt;

  // Super admins can disable an exchanged milestone, but only if we haven't
  // completed yet
  const exchangeEnabled =
    canReachExchanged ||
    (exchanged?.reachedAt && currentUser.isNestAdmin && !completed?.reachedAt);

  const canReachCompleted = exchanged?.reachedAt && !completed?.reachedAt;

  const completedEnabled =
    canReachCompleted || (completed?.reachedAt && currentUser.isNestAdmin);

  return (
    <div css={milestonesLine}>
      <MilestoneSection allReached={Boolean(memoSent?.reachedAt)}>
        <MemoSentMilestone
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={!canReachMemo}
          milestone={memoSent}
        />
      </MilestoneSection>
      <MilestoneSection
        heading="Buyer instruction"
        allReached={allBuyerInstructionMilestonesReached}
      >
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={instructionFormIdChecks}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={searchMonies}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={buyerDraftContractPack}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={appliedForSearches}
        />
      </MilestoneSection>
      <MilestoneSection
        heading="Seller's property information"
        allReached={allSellersPropertyInfoReached}
      >
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={protocolForms}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={managementPackLpeOneFormOrdered}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={managementPackLpeOneFormShared}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={sellerDraftContractPack}
        />
      </MilestoneSection>
      <MilestoneSection heading="Enquiries" allReached={allEnquiriesReached}>
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={initialEnquiriesRaised}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={allSearchesReturned}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={allEnquiriesSatisfied}
        />
      </MilestoneSection>
      {showBuyersFinances && (
        <MilestoneSection
          heading="Buyer's finances"
          allReached={allBuyersFinancesReached}
        >
          <MilestoneRow
            buyerOfferId={buyerOfferId}
            dealId={dealId}
            disabled={basicMilestoneDisabled}
            milestone={mortgageApplicationSubmitted}
          />
          <MilestoneRow
            buyerOfferId={buyerOfferId}
            dealId={dealId}
            disabled={basicMilestoneDisabled}
            milestone={mortgageValuationBooked}
          />
          <MilestoneRow
            buyerOfferId={buyerOfferId}
            dealId={dealId}
            disabled={basicMilestoneDisabled}
            milestone={mortgageValuationReportReceived}
          />
          <MilestoneRow
            buyerOfferId={buyerOfferId}
            dealId={dealId}
            disabled={basicMilestoneDisabled}
            milestone={mortgageOfferReceived}
          />
        </MilestoneSection>
      )}
      <MilestoneSection
        heading="Reports and contracts"
        allReached={allReportsAndContractsReached}
      >
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={buyerReportedTo}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={contractsSigned}
        />
        <MilestoneRow
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={basicMilestoneDisabled}
          milestone={fundsDeposited}
        />
      </MilestoneSection>
      <MilestoneSection allReached={Boolean(exchanged?.reachedAt)}>
        <ExchangedMilestone
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={!exchangeEnabled}
          milestone={exchanged}
        />
      </MilestoneSection>
      <MilestoneSection allReached={Boolean(completed?.reachedAt)}>
        <CompletedMilestone
          buyerOfferId={buyerOfferId}
          dealId={dealId}
          disabled={!completedEnabled}
          milestone={completed}
        />
      </MilestoneSection>
    </div>
  );
};
