import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import moment from "moment-timezone";
import * as cookies from "js-cookie";
import { getConfig } from "@nested/config";
import { Sentry } from "@nested/isomorphic-sentry";
import "normalize.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "@nest-ui/styles/global-styles";

import { App } from "components/App";
import {
  createApolloClient,
  Providers,
  NotificationProvider,
  AuthenticatedSocket,
  PresenceProvider,
} from "@nest-ui/providers";
import { authClient } from "./authClient";

const { COOKIE_DOMAIN } = getConfig();

const init = async () => {
  // Before rendering the app, we need to authenticate with auth0
  await authClient.init();

  const authenticated = await authClient.authenticated();
  if (!authenticated) {
    authClient.redirectToLogin();
    return;
  }

  const socket = new AuthenticatedSocket(authClient);
  await socket.connect();

  const user = await authClient.getUser();
  Sentry.configureScope((scope) => {
    scope.setUser({ email: user.email });
  });

  const internalUserCookieExpiry = moment.utc().add(1, "years").toDate();

  cookies.set("com.nested.internaluser", user.email, {
    expires: internalUserCookieExpiry,
    domain: COOKIE_DOMAIN,
    path: "/",
  });

  // Fullstory user tracking
  // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
  if (window.FS && typeof window.FS.setUserVars === "function") {
    window.FS.setUserVars({
      email: user.email,
      displayName: user.name,
    });
  }

  const apolloClient = await createApolloClient(authClient, socket);
  const history = createBrowserHistory();

  // Force the nest to use London timezone to prevent people setting it wrong while on holiday etc.
  // Obviously this will need to change when expand to e.g. Switzerland and we'll have to switch to
  // the property's local time (I guess), but let's cross that bridge when we come to it, eh folks?
  moment.tz.setDefault("Europe/London");
  moment.updateLocale("en", {
    relativeTime: {
      future: "In %s",
      past: "%s ago",
      s: "A few seconds",
      ss: "%d seconds",
      m: "1 minute",
      mm: "%d minutes",
      h: "1 hour",
      hh: "%d hours",
      d: "1 day",
      dd: "%d days",
      w: "1 week",
      ww: "%d weeks",
      M: "1 month",
      MM: "%d months",
      y: "1 year",
      yy: "%d years",
    },
  });

  ReactDOM.render(
    <Providers
      apolloClient={apolloClient}
      history={history}
      socket={socket}
      user={user}
    >
      <PresenceProvider>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </PresenceProvider>
    </Providers>,
    document.getElementById("root"),
  );
};

export { init };
