import styled from "styled-components";
import { withProps } from "recompose";

import { ReadOnlyField } from "components/ReadOnlyField";

const List = styled.ul`
  padding-left: 0px;
  margin: 0;

  li {
    list-style: none;

    &:not(:first-child) {
      padding-top: 4px;
    }
  }
`;

const UnorderedList = withProps(({ value }) => ({
  value:
    value === null ? null : (
      <List>
        {value.map((v, index) => (
          <li key={`${v + index}`}>{v}</li>
        ))}
      </List>
    ),
}))(ReadOnlyField);

export { UnorderedList };
