// @flow
import { MemoryRouter, Redirect, Route, Switch } from "react-router-dom";
import { useState } from "react";
import { css } from "styled-components";
import { ContactPreferencesTab } from "./ContactPreferencesTab";
import { XButton } from "../../../components/XButton";
import { Tabs, Tab } from "../../../components/Navigation/Tabs";
import { OverlayNav } from "../../../components/Overlay/Overlay";
import {
  desktopOnly,
  mobileOnly,
  maxHeightWrapper,
  overlayPadding,
  modalStyle,
  ContactsModal,
} from "../../Deal/EditDeal/EditDealOverlay";
import { ContactManagement } from "../../../components/ContactManagement/ContactManagement";

const tabsStyle = css`
  color: ${({ theme }) => theme.palette.hague150};
`;

const TabWrapper = ({ children, onClose }) => (
  <>
    <XButton onClick={onClose} css={desktopOnly} />
    <OverlayNav heading="Edit buyer" onClose={onClose} css={mobileOnly} />
    <div css={maxHeightWrapper}>
      <div css={overlayPadding}>
        <Tabs noBorderWhenNotSelected css={tabsStyle}>
          <Tab to={`/contacts`}>Contacts</Tab>
          <Tab to={`/contact-preferences`}>Contact preferences</Tab>
        </Tabs>
      </div>
      <div css={modalStyle}>{children}</div>
    </div>
  </>
);

type Props = {
  buyerId: string,
  onClose: () => void,
  open: boolean,
  initialPath?: string,
};

export const ContactModal = ({
  buyerId,
  onClose,
  open,
  initialPath = "/deal-status",
}: Props) => {
  const [editing, setEditing] = useState(false);

  const closeModal = () => {
    onClose();
    setEditing(false);
  };

  return (
    <ContactsModal onClose={closeModal} open={open} editing={editing}>
      <MemoryRouter initialEntries={[initialPath]}>
        <Switch>
          <Route path="/contact-preferences">
            <TabWrapper onClose={onClose}>
              <ContactPreferencesTab buyerId={buyerId} onClose={onClose} />
            </TabWrapper>
          </Route>
          <Route path="/contacts">
            <ContactManagement
              buyerId={buyerId}
              editing={editing}
              onClose={onClose}
              setEditing={setEditing}
              Wrapper={TabWrapper}
            />
          </Route>
          <Redirect to="/contacts" />
        </Switch>
      </MemoryRouter>
    </ContactsModal>
  );
};
