// @flow
import { gql } from "@apollo/client";

export const BUYER_OFFER_LIST_FRAGMENT = gql`
  fragment BuyerOfferListFragment on BuyerOffer {
    id
    amount
    contactOneName
    dealId
    expectedExchangeDate
    expectedCompletionDate
    notes
    placedOn
    status {
      value
      label
    }
  }
`;
