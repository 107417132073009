// @flow
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Button } from "@nested/nest/src/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "components/Modal/SimpleModal";
import { media } from "@nested/brand";
import { Tabs, NestedTab } from "@nested/nest/src/components/Navigation/Tabs";
import { MailoutModal } from "./MailoutModal/MailoutModal";
import { fetchPropertyDetailsFromGCS } from "./MailoutModal/MailoutPreview";
import { StillInterestedList } from "./StillInterestedList";
import { PotentialList } from "./PotentialList";
import { NotInterestedList } from "./NotInterestedList";

export const POTENTIAL_BUYERS_QUERY = gql`
  query PotentialBuyersCount($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      opportunityStatus {
        valueText
      }
      potentialBuyersCounts {
        stillInterestedBuyersCount
        notInterestedBuyersCount
        unlinkedPotentialBuyersCount
      }
      rmRecommendedListPrice
      property {
        id
        bedrooms
      }
      propertySubType
    }
  }
`;

const potentialBuyers = css`
  font-weight: 500;
`;

export const PropertyMatchModal = styled(Modal)`
  border: none;
  border-radius: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  ${media.tablet`
    border: 3px solid ${({ theme }) => theme.color.text.hague70};
    border-radius: 13px;
    margin: auto;
    overflow: hidden;
    height: calc(100% - 96px);
    margin-top: 48px;
    margin-bottom: 48px;
  `}
`;

const mailoutHeader = css`
  button {
    margin-top: 20px;
    padding: 12px 17px;
    text-align: left;
    max-width: 360px;
  }

  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin: 0;
      padding: 7px 17px;
    }
  `}
`;

const mailoutHeading = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;

  span {
    color: ${({ theme }) => theme.palette.hague70};
    margin-left: 10px;
  }
`;

const missingDataStyle = css`
  text-align: center;
`;

const infoCircle = css`
  color: ${({ theme }) => theme.palette.hague70};
  margin: 20px auto;
`;

const infoText = css`
  ${infoCircle};
  max-width: 430px;
  margin-top: 0;
`;

const tabsStyle = css`
  overflow-y: hidden;
`;

const STILL_INTERESTED = "still-interested";
const NOT_INTERESTED = "not-interested";
const POTENTIAL = "potential";

const MissingFields = () => (
  <div css={missingDataStyle}>
    <FontAwesomeIcon icon={faInfoCircle} size="2x" css={infoCircle} />
    <p css={infoText}>
      To generate potential buyers pre-listing, add property type, bedrooms and
      a recommended listing price in the Valuation tab
    </p>
  </div>
);

type Props = {
  parentId: string,
};

export const PotentialBuyers = ({ parentId: dealId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingJSON, setLoadingJSON] = useState(true);
  const [errorLoadingJSON, setErrorLoadingJSON] = useState(true);
  const [showBuyers, setShowBuyers] = useState(STILL_INTERESTED);

  const { data, error, loading } = useQuery(POTENTIAL_BUYERS_QUERY, {
    variables: { id: dealId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!data?.nestDeal?.externalId) {
      return;
    }

    if (data?.nestDeal?.opportunityStatus?.valueText < "s08_listed") {
      // no need to fetch JSON config for prelist deals
      setErrorLoadingJSON(false);
      setLoadingJSON(false);
      return;
    }
    // This makes sure there is a JSON config file for the listing before attempting to render
    // a preview of the mailout
    const run = async () => {
      try {
        await fetchPropertyDetailsFromGCS(data.nestDeal.externalId);
        setErrorLoadingJSON(false);
      } catch (e) {
        setErrorLoadingJSON(true);
      } finally {
        setLoadingJSON(false);
      }
    };

    run();
  }, [data?.nestDeal?.externalId]);

  if (error) {
    errorHandler(error);
    return null;
  }

  const {
    opportunityStatus,
    rmRecommendedListPrice,
    property,
    propertySubType,
  } = data?.nestDeal || {};

  const isPreList = opportunityStatus?.valueText < "s08_listed";

  const missingFields =
    !rmRecommendedListPrice || !property?.bedrooms || !propertySubType;

  const {
    stillInterestedBuyersCount,
    notInterestedBuyersCount,
    unlinkedPotentialBuyersCount,
  } = data?.nestDeal.potentialBuyersCounts || {};

  const totalBuyers =
    stillInterestedBuyersCount +
    notInterestedBuyersCount +
    unlinkedPotentialBuyersCount;

  return (
    <div css={potentialBuyers}>
      <div css={mailoutHeader}>
        <h2 css={mailoutHeading}>Buyers</h2>
        <Button
          disabled={
            loading ||
            loadingJSON ||
            errorLoadingJSON ||
            (isPreList && missingFields) ||
            totalBuyers === 0
          }
          icon={faPaperPlane}
          onClick={() => setModalOpen(true)}
          data-test="open-mailout-modal"
        >
          Create mailout
        </Button>
      </div>
      {isPreList && missingFields ? (
        <MissingFields />
      ) : (
        <>
          {!isPreList && errorLoadingJSON && !loadingJSON && (
            <p data-test="mailout-json-load-error">
              We cannot create a mailout because we've not yet found the
              rightmove data for this deal. If you've just listed this property,
              try again in 10 mins.
            </p>
          )}
          <Tabs css={tabsStyle}>
            <NestedTab
              active={showBuyers === STILL_INTERESTED}
              count={stillInterestedBuyersCount}
              onClick={() => setShowBuyers(STILL_INTERESTED)}
              data-test="still-interested-tab"
            >
              Still Interested
            </NestedTab>
            <NestedTab
              active={showBuyers === NOT_INTERESTED}
              count={notInterestedBuyersCount}
              onClick={() => setShowBuyers(NOT_INTERESTED)}
              data-test="not-interested-tab"
            >
              Not Interested
            </NestedTab>
            <NestedTab
              active={showBuyers === POTENTIAL}
              count={unlinkedPotentialBuyersCount}
              onClick={() => setShowBuyers(POTENTIAL)}
            >
              Potential
            </NestedTab>
          </Tabs>
          {showBuyers === STILL_INTERESTED && (
            <StillInterestedList id={dealId} />
          )}
          {showBuyers === NOT_INTERESTED && <NotInterestedList id={dealId} />}
          {showBuyers === POTENTIAL && <PotentialList id={dealId} />}
        </>
      )}
      <PropertyMatchModal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      >
        {modalOpen && (
          <MailoutModal
            closeModal={() => setModalOpen(false)}
            dealId={dealId}
          />
        )}
      </PropertyMatchModal>
    </div>
  );
};
