// @flow
import { css } from "styled-components";
import { Link } from "react-router-dom";
import { HandoffCallButton } from "./Comms";

const buyerWrapperStyle = css`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague10};
  display: flex;
  justify-content: space-between;
  & button:disabled {
    align-self: center;
    height: 30px;
    border-radius: 10px;
    min-width: 30px;
    background-color: ${({ theme }) => theme.palette.hague10};
    color: ${({ theme }) => theme.palette.hague50};
    cursor: default;
  }
  & button:focus {
    background: transparent;
  }
`;

const communicationButtonsStyle = css`
  align-self: center;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 10px;

  svg {
    min-height: 16px;
    min-width: 16px;
  }
`;

const detailStyle = css`
  margin: 0px;
  margin-top: 5px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.hague70};
`;

type Props = {
  buyer: StillInterestedBuyers_nestDeal_stillInterestedBpis_buyer,
  detail: React.Element,
  detailTitle?: string,
};

export const MobileListViewBuyerCard = ({
  buyer: { id, name, contacts },
  detail,
  detailTitle,
}: Props) => {
  return (
    <div css={buyerWrapperStyle}>
      <Link key={id} to={`/buyers/${id}`}>
        <div className="fs-exclude">{name}</div>
        <div css={detailStyle} title={detailTitle}>
          {detail}
        </div>
      </Link>
      <HandoffCallButton
        css={communicationButtonsStyle}
        contacts={contacts}
        relationType="buyer"
        relationId={id}
      />
    </div>
  );
};
