import { createGlobalStyle } from "styled-components";
import { fonts } from "@nested/brand/src/fonts";
import { media } from "@nested/brand";

export const DatePickerStyles = createGlobalStyle`
  ${fonts}

  .react-datepicker {
    font-size: 12px;
    transform: none;
    border: none;
    ${media.tablet`
      border: 2px solid ${({ theme }) => theme.palette.hague20};
      transform: inherit;
    `}
  }
  .react-datepicker-popper {
    /* transform: none allows us to set the position of the pop up as 'fixed' on mobile.
    I need to use important! to overwrite the inline styles the library
    applies but I only want to do so on mobile. I can't use media.tablet because
    that uses min-width as that would change it on tablet as well which couldn't be
    'unset' to use the original inline styles */
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet / 16}em) {
      transform: none !important;
    }
    z-index: 2;
  }
  .react-datepicker__month {
    margin: 0 3px 5px;
  }
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__current-month {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.hague150};
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 15px;
    ${media.tablet`
      margin: 0;
    `}
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    width: 6px;
    height: 6px;
    border-width: 2px 2px 0 0;
  }
  .react-datepicker__header {
    font-family: ${({ theme }) => theme.fonts.euclid};
    background-color: unset;
    border: none;
  }
  .react-datepicker__triangle {
    border-bottom-width: 2px;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: white;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.palette.hague20};
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    top: 2px;
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before {
    bottom: -2px;
    border-top-color: ${({ theme }) => theme.palette.hague20};
  }
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after {
    bottom: 1px;
  }
  .react-datepicker__day-names {
    font-family: ${({ theme }) => theme.fonts.euclid};
    font-weight: 500;
    margin-top: 5px; 
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.palette.hague150};
    width: 24px;
    line-height: 24px;
    margin: 5px calc((100% - (24px * 7)) / 14);
    ${media.tablet`
      width: 18px;
      line-height: 18px;
      margin: 4px;
    `}
  }
  .react-datepicker__day--today {
    font-weight: 500;
  }
  .react-datepicker__day {
    font-family: ${({ theme }) => theme.fonts.euclid};
    color: ${({ theme }) => theme.palette.hague150};
    width: 24px;
    line-height: 24px;
    margin: 5px calc((100% - (24px * 7)) / 14);
    ${media.tablet`
      width: 18px;
      line-height: 18px;
      margin: 4px;
    `}

  }
  .react-datepicker__day:hover {
    background-color: ${({ theme }) => theme.palette.blue10};
    border-radius: 50%;
  }
  .react-datepicker__day:active {
    background-color: ${({ theme }) => theme.palette.blue10};
    border-radius: 50%;
  }
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.palette.blue150};
    border-radius: 50%;
    color: white;
  }
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.palette.hague40};
  }
  .react-datepicker__day--keyboard-selected {
    background-color: unset;
  }
  .react-datepicker__day--selected:hover {
    background-color: ${({ theme }) => theme.palette.blue100};
    border-radius: 50%;
    color: white;
  }
`;
