import T from "prop-types";
import styled from "styled-components";
import { EditIcon } from "@nest-ui/icons";

const EditButtonStyle = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  height: 32px;
  border-radius: 2px;
  background-color: transparent;
  border: none;
  padding: 8px;
  padding-left: 28px;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  cursor: pointer;
  outline: none;
  color: #8c8c8c;
  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 8px;
    top: 8px;
  }
`;

const EditButton = ({ children, Icon = EditIcon, ...rest }) => (
  <EditButtonStyle {...rest}>
    <Icon />
    {children}
  </EditButtonStyle>
);

EditButton.propTypes = {
  children: T.node,
};

export { EditButton };
