import { gql } from "@apollo/client";

export const VALUATIONS_LIST_QUERY = gql`
  query AdvanceOfferValuationsListNestDealQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      valuationList {
        id
        dealId
        notes

        ... on AdvanceOfferValuationOct18 {
          advanceAmount
          advancePercentage
          dateCompleted
          expectedSalePrice
          expiryDate
          issuingAdvance
          reasonForAdvanceOfferValuationId
          recommendedListingPrice
          signedOffBy
          completedBy

          advanceValuationTrigger {
            value: id
            label
          }
        }

        ... on FinalValuationPreOct18 {
          advanceAmount
          advancePercentage
          # The fields below are aliased as they're nullable (unlike on AdvanceOfferValuationOct18)
          # which upsets flow (as the name is shared with conflicting types).
          nullableDateCompleted: dateCompleted
          finalValExpectedSalePrice: expectedSalePrice
          finalValRecommendedListingPrice: recommendedListingPrice
        }
      }
    }

    activeNestedUsers {
      id
      closeUserId
      fullName
    }

    reasonsForAdvanceOfferValuation {
      id
      label
    }

    advanceValuationTriggers {
      value: id
      label
    }
  }
`;
