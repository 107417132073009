// @flow
import { graphql } from "deal/utils/graphql";
import { Heading } from "components/Heading";
import { Card } from "components/Card";
import query from "./query/query.graphql";
import { dueDiligenceDatesSectionQueryConfig } from "./query/config";
import { DueDiligenceDatesList } from "./DueDiligenceDatesList";

const dealTypesWithDueDiligenceDates = [
  "advance_jul2018",
  "agency_plus_sep2018",
  "advances_for_all_march2019",
];

type Props = {
  dealId: string,
  data: DueDiligenceDatesSection,
};

const DueDiligenceDatesSectionComponent = ({
  dealId,
  data: { nestDeal },
}: Props) => (
  <section>
    <Heading id="due-diligence-dates-list">Revised full DD dates</Heading>
    {nestDeal && (
      <Card>
        {!nestDeal.dealType && (
          <span>
            Sorry, you cannot add due diligence dates until the deal type is
            set.
          </span>
        )}
        {nestDeal.dealType &&
          !dealTypesWithDueDiligenceDates.includes(nestDeal.dealType.value) && (
            <span>
              Sorry, you cannot add due diligence dates on this deal type.
            </span>
          )}
        {nestDeal.dealType &&
          dealTypesWithDueDiligenceDates.includes(nestDeal.dealType.value) && (
            <DueDiligenceDatesList
              dealId={dealId}
              data-test="due-diligence-dates-list"
            />
          )}
      </Card>
    )}
  </section>
);

export const DueDiligenceDatesSection = graphql(
  query,
  dueDiligenceDatesSectionQueryConfig,
)(DueDiligenceDatesSectionComponent);
