// @flow
import { useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { gql, useQuery } from "@apollo/client";

import { useTaskCounters } from "../../hooks/useTaskCounters";
import { useUser } from "../../hooks/useUser";
import { FloatingAgent } from "../../components/FloatingAgent";
import { NoResults } from "../../components/NoResults";
import { TaskList } from "./TaskList";
import { Fade } from "./Today";

export const UPCOMING_TASKS = gql`
  query UpcomingTasks($email: String!) {
    agentTasksUpcoming(email: $email) {
      completedAt
      description
      dueAt
      id
      reference {
        ... on NestDeal {
          id
          active
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          externalId
          firstName
          lastName
          nurtureHotness {
            label
          }
          ownerName
        }
        ... on Buyer {
          id
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
          name
        }
      }
    }
  }
`;

export const Upcoming = () => {
  const { selectedUser } = useUser();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch: refetchUncompleted,
  } = useQuery(UPCOMING_TASKS, {
    variables: { email: selectedUser.email },
    fetchPolicy: "network-only",
  });

  const { upcoming, taskChanged } = useTaskCounters();

  useEffect(() => {
    // prevent unnecessary refetch on first render
    if (!loading && !error) {
      setTimeout(() => {
        refetchUncompleted();
      }, 1000);
    }
  }, [upcoming]);

  // delay not needed for these changes
  useEffect(() => {
    if (!loading && !error) {
      refetchUncompleted();
    }
  }, [taskChanged]);

  const tasks = data?.agentTasksUpcoming || [];

  const showTaskList = loading || error || tasks.length > 0;

  return (
    <SwitchTransition>
      <CSSTransition
        key={showTaskList.toString()}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="fade"
      >
        {showTaskList ? (
          <Fade>
            <TaskList
              loading={loading}
              error={error}
              tasks={tasks}
              refetchQueries={{ refetchUncompleted }}
            />
          </Fade>
        ) : (
          <Fade>
            <NoResults image={null} message="You're up to date!">
              <FloatingAgent />
            </NoResults>
          </Fade>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};
