import T from "prop-types";

import { ListEntry } from "components/ListEntry";
import { CreateButton } from "components/CreateButton";
import { itemDeletionConfirmed } from "components/DeleteButton";

const ListContainer = ({
  value,
  component: Entry = ListEntry,
  template: { layout, content } = {},
  itemTitle,
  highlight,
  area,
  direction,
  submit,
  parentId,
  createButtonText,
  createListEntry,
  deleteListEntry,
  data,
  deleteMessage = "This will permanently delete this item. Are you sure you want to do this?",
  ...rest
}) => (
  <div>
    {value.map(({ id, ...item }, index) => (
      <Entry
        index={index}
        highlight={highlight}
        {...rest}
        key={area + id}
        id={id}
        direction={direction}
        data={{ ...data, ...item, id }}
        layout={layout}
        content={content}
        parentId={parentId}
        deleteEntry={{
          className: "delete-button",
          onSubmit: () => {
            if (itemDeletionConfirmed()) {
              deleteListEntry(id);
            }
          },
          message: deleteMessage,
        }}
      />
    ))}
    {createListEntry && (
      <CreateButton
        className="create-button"
        label={createButtonText}
        data-test={`${createButtonText} button`}
        onSubmit={createListEntry}
      />
    )}
  </div>
);

ListContainer.propTypes = {
  value: T.arrayOf(T.object),
  highlight: T.func,
  direction: T.string,
  itemTitle: T.string,
  component: T.func,
  deleteButton: T.func,
  template: T.shape({
    title: T.string,
    content: T.arrayOf(T.object),
    layout: T.arrayOf(T.string),
  }),
  match: T.object,
  submit: T.func,
  area: T.string,
  rest: T.object,
  parentId: T.string,
  createButtonText: T.string,
  createListEntry: T.func,
  deleteListEntry: T.func,
  deleteMessage: T.string,
  data: T.shape({
    inputOptions: T.object,
  }),
};

export { ListContainer };
