// @flow
import { useState } from "react";
import { css } from "styled-components";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { Label } from "components/Label";
import { Div } from "components/TextField/styles";
import { ReadOnlyField } from "components/ReadOnlyField";
import { Spinner, useOnSubmit } from "../../hooks/useOnSubmit";

const calendarStyles = css`
  /* Cancel button */

  & + button.react-datepicker__close-icon::after {
    background-color: #8f8f8f;
    height: 10px;
    width: 10px;
    font-size: 13px;
    line-height: 9px;
  }
`;

export const formatDate = (dateInput: Date) => {
  if (dateInput) {
    const { years } = moment(dateInput).toObject();
    if (years > 9999) {
      const truncYears = years.toString().slice(0, 4);
      return moment(dateInput)
        .year(parseInt(truncYears, 10))
        .format("YYYY-MM-DD");
    }
    return moment(dateInput).format("YYYY-MM-DD");
  }
  return dateInput;
};

const formatForDisplay = (date: ?Date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return null;
};

type Props = {|
  className?: string,
  "data-test"?: string,
  dataTest?: string,
  dark?: boolean,
  isSecondary?: boolean,
  disabled?: boolean,
  highlight?: boolean,
  highlightRed?: boolean,
  label?: string,
  onSubmit: (date: ?string) => void,
  placeholder?: string,
  readOnly?: boolean,
  value: ?string,
|};

export const NoSubmitDatePicker = ({
  className,
  dark,
  dataTest,
  disabled,
  highlight,
  highlightRed,
  isSecondary,
  label,
  onSubmit,
  placeholder = "dd/mm/yyyy",
  readOnly,
  value,
}: Props) => {
  const dateValue =
    value === undefined || !moment(value).isValid()
      ? undefined
      : moment.utc(value).toDate();

  if (readOnly || disabled) {
    return (
      <ReadOnlyField
        dataTest={dataTest}
        className={className}
        disabled={disabled}
        label={label}
        value={formatForDisplay(dateValue)}
        highlight={highlight}
        isSecondary={isSecondary}
      />
    );
  }

  return (
    <Div
      dark={dark}
      highlight={highlight}
      highlightRed={highlightRed}
      isSecondary={isSecondary}
    >
      <Label>{label}</Label>
      <ReactDatePicker
        className={className}
        css={calendarStyles}
        // TODO: Get rid of `data-test` or find a way to pass it into
        // `ReactDatePicker` because currently it's not possible
        // https://github.com/Hacker0x01/react-datepicker/issues/3591
        data-test={dataTest}
        dateFormat="dd/MM/yyyy"
        isClearable
        selected={dateValue}
        onChange={(dateInput) => {
          const formattedDate = dateInput && formatDate(dateInput);
          return onSubmit(formattedDate);
        }}
        placeholderText={placeholder}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        calendarStartDay={1}
      />
    </Div>
  );
};

export const DatePicker = ({
  value,
  property,
  mutation,
  ...rest
}: Props & { mutation: (date: string) => void, property: string }) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = useOnSubmit({ value, property, mutation, setLoading });

  return (
    <Spinner isLoading={loading}>
      <NoSubmitDatePicker onSubmit={onSubmit} value={value} {...rest} />
    </Spinner>
  );
};
