// @flow
import { Grid, LeafCell } from "components/Grid/Grid";
import { NoSubmitCurrencyField } from "components/CurrencyField/CurrencyField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { InfoBlob } from "components/InfoBlob/InfoBlob";

export const MiniValuation = ({
  valuation: {
    listPriceLowerBound,
    listPriceUpperBound,
    nullableEstimatedSalePriceLowerBound,
    nullableEstimatedSalePriceUpperBound,
    estimatedSalePriceBestEstimate,
    advanceLowerBound,
    advanceUpperBound,
    notes,
  },
  isReadOnly,
}: *) => (
  <Grid columns={4}>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-listing-price-lower"
        label="List price lower bound"
        value={listPriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-listing-price-upper"
        label="List price upper bound"
        value={listPriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-sale-price-lower"
        label="Estimated sale price lower bound"
        value={nullableEstimatedSalePriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-sale-price-upper"
        label="Estimated sale price upper bound"
        value={nullableEstimatedSalePriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={4}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-estimated-sale-price"
        label="Estimated sale price (best estimate)"
        value={estimatedSalePriceBestEstimate}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-advance-amount-lower"
        label="Advance lower bound"
        value={advanceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="mini-valuation-list-advance-amount-upper"
        label="Advance upper bound"
        value={advanceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={4}>
      <NoSubmitTextareaWithLinks
        data-test="mini-valuation-list-notes"
        label="Notes"
        value={notes}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={4}>
      <InfoBlob>PRE-DEC 2018</InfoBlob>
    </LeafCell>
  </Grid>
);
