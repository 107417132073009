// @flow
import type { Element } from "react";
import styled from "styled-components";
import { togglePartyMode } from "@nested/utils/easterEggs";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { RippleSpinner as Ripple } from "@nest-ui/icons";

// This is a hooks based implementation of the 'withOnSubmit' HOC used by many
// input components. It encorporates the 'withGraphQLError' HOC and an optional spinner.
// See DatePicker for example usage.

const partyModeFeatures = (newValue) => {
  if (document.body) {
    if (newValue === "DO A BARREL ROLL") {
      document.body.classList.add("barrel-roll");
    } else {
      document.body.classList.remove("barrel-roll");
    }

    // Provides a vibrant and dynamic user experience
    if (newValue === "ACTIVATE PARTY MODE") {
      togglePartyMode();
    }
  }
};

const RippleWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
`;

export const Spinner = ({
  children,
  isLoading,
  showSpinner = true,
}: {|
  children: Element<any>,
  isLoading: boolean,
  showSpinner?: boolean,
|}) => (
  <div style={{ position: "relative" }}>
    {isLoading && showSpinner && (
      <RippleWrapper>
        <Ripple />
      </RippleWrapper>
    )}
    {children}
  </div>
);

type Props = {
  value: ?any,
  property: string,
  mutation: (input: any) => void,
  setLoading?: (loading: boolean) => void,
};

export const useOnSubmit =
  ({ value: oldValue, property, mutation, setLoading = () => {} }: Props) =>
  async (newValue: any) => {
    partyModeFeatures(newValue);

    // This short-circuits making requests to the backend if no change has been made
    if (oldValue === newValue) return Promise.resolve();

    setLoading(true);
    try {
      return await mutation({ [property]: newValue });
    } catch (e) {
      return errorHandler(e);
    } finally {
      setLoading(false);
    }
  };
