// @flow
import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Loader } from "components/Loader";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitSelectField } from "components/SelectField";
import { ListEntryCard } from "components/Card";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitPercentageField } from "components/PercentageField";

export const FEE_TYPES_QUERY = gql`
  query feeTypes {
    feeTypes {
      id
      value
      label
    }
  }
`;

export const PROVISIONAL_FEES_QUERY = gql`
  query provisionalFeeType($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      provisionalFixedFee {
        id
        fee
      }
      provisionalPercentageFee {
        id
        fee
      }
      provisionalFeeType {
        id
        label
        value
      }
    }
  }
`;

export const UPDATE_PROVISIONAL_FEE_TYPE_MUTATION = gql`
  mutation updateProvisionalFeeType($input: ProvisionalFeeTypeInput!) {
    updateProvisionalFeeType(input: $input) {
      id
      value
      label
    }
  }
`;

export const UPDATE_PROVISIONAL_FIXED_FEE_MUTATION = gql`
  mutation updateProvisionalFixedFee($input: UpdateProvisionalFixedFeeInput!) {
    updateProvisionalFixedFee(input: $input) {
      id
      fee
    }
  }
`;

export const UPDATE_PROVISIONAL_PERCENTAGE_FEE_MUTATION = gql`
  mutation updateProvisionalPercentageFee(
    $input: UpdateProvisionalPercentageFeeInput!
  ) {
    updateProvisionalPercentageFee(input: $input) {
      id
      fee
    }
  }
`;

type Props = {
  dealId: string,
};

export const ProposedAgencyFeeSection = ({ dealId }: Props) => {
  const { loading: loadingFeeTypes, data: feeTypesData } =
    useQuery(FEE_TYPES_QUERY);

  const { loading: loadingProvisionalFees, data: provisionalData } = useQuery(
    PROVISIONAL_FEES_QUERY,
    {
      variables: { dealId },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    },
  );

  const [triggerUpdateProvisionalFeeTypeMutation] = useMutation(
    UPDATE_PROVISIONAL_FEE_TYPE_MUTATION,
  );

  const [triggerUpdateProvisionalFixedFeeMutation] = useMutation(
    UPDATE_PROVISIONAL_FIXED_FEE_MUTATION,
  );

  const [triggerUpdateProvisionalPercentageFee] = useMutation(
    UPDATE_PROVISIONAL_PERCENTAGE_FEE_MUTATION,
  );

  if (loadingFeeTypes || loadingProvisionalFees) {
    return <Loader />;
  }

  const currentFeeType = provisionalData?.nestDeal?.provisionalFeeType?.value;
  const currentProvisionalFixedFee =
    provisionalData?.nestDeal?.provisionalFixedFee?.fee;
  const currentProvisionalPercentageFee =
    provisionalData?.nestDeal?.provisionalPercentageFee?.fee;

  const getFeeTypeFromValue = (value) => {
    return feeTypesData?.feeTypes?.find((feeType) => feeType.value === value);
  };

  const handleFeeTypeChange = async (value) => {
    try {
      await triggerUpdateProvisionalFeeTypeMutation({
        variables: { input: { dealId, type: value } },
        optimisticResponse: {
          __typename: "Mutation",
          updateProvisionalFeeType: getFeeTypeFromValue(value),
        },
        update: (cache, { data: { updateProvisionalFeeType } }) => {
          const { nestDeal } = cache.readQuery({
            query: PROVISIONAL_FEES_QUERY,
            variables: { dealId },
          });

          cache.writeQuery({
            query: PROVISIONAL_FEES_QUERY,
            data: {
              nestDeal: {
                ...nestDeal,
                provisionalFeeType: updateProvisionalFeeType,
              },
            },
            refetchQueries: [PROVISIONAL_FEES_QUERY],
          });
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const handleFixedFeeChange = async (fee) => {
    try {
      await triggerUpdateProvisionalFixedFeeMutation({
        variables: { input: { dealId, fee } },
        optimisticResponse: {
          __typename: "Mutation",
          updateProvisionalFixedFee: {
            id: provisionalData?.nestDeal?.provisionalFixedFee?.id || "temp-id",
            fee,
            __typename: "ProvisionalFixedFee",
          },
        },
        update: (cache, { data: { updateProvisionalFixedFee } }) => {
          const { nestDeal } = cache.readQuery({
            query: PROVISIONAL_FEES_QUERY,
            variables: { dealId },
          });

          cache.writeQuery({
            query: PROVISIONAL_FEES_QUERY,
            data: {
              nestDeal: {
                ...nestDeal,
                provisionalFixedFee: updateProvisionalFixedFee,
              },
            },
            refetchQueries: [PROVISIONAL_FEES_QUERY],
          });
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const handlePercentageFeeChange = async (fee) => {
    try {
      await triggerUpdateProvisionalPercentageFee({
        variables: { input: { dealId, fee } },
        optimisticResponse: {
          __typename: "Mutation",
          updateProvisionalPercentageFee: {
            id:
              provisionalData?.nestDeal?.provisionalPercentageFee?.id ||
              "temp-id",
            fee,
            __typename: "ProvisionalPercentageFee",
          },
        },
        update: (cache, { data: { updateProvisionalPercentageFee } }) => {
          const { nestDeal } = cache.readQuery({
            query: PROVISIONAL_FEES_QUERY,
            variables: { dealId },
          });

          cache.writeQuery({
            query: PROVISIONAL_FEES_QUERY,
            data: {
              nestDeal: {
                ...nestDeal,
                provisionalPercentageFee: updateProvisionalPercentageFee,
              },
            },
            refetchQueries: [PROVISIONAL_FEES_QUERY],
          });
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <ListEntryCard>
      <Grid columns={2}>
        <LeafCell width={1}>
          <NoSubmitSelectField
            options={feeTypesData?.feeTypes?.map(({ value, label }) => ({
              label,
              value,
            }))}
            value={currentFeeType}
            onSubmit={(value) => {
              if (value) {
                handleFeeTypeChange(value);
              }
            }}
            label="Fees"
            placeholder="Select fee type..."
          />
        </LeafCell>

        <LeafCell width={1}>
          {currentFeeType === "PERCENTAGE" && (
            <NoSubmitPercentageField
              property="provisionalFee"
              value={currentProvisionalPercentageFee}
              onSubmit={(fee) => {
                if (fee !== currentProvisionalPercentageFee) {
                  handlePercentageFeeChange(fee);
                }
              }}
              label="Proposed agency fee"
              placeholder="e.g. 1.5%"
            />
          )}
          {currentFeeType === "FIXED" && (
            <NoSubmitCurrencyField
              value={currentProvisionalFixedFee}
              label="Proposed agency fee"
              onSubmit={(fee) => {
                if (fee !== currentProvisionalFixedFee) {
                  handleFixedFeeChange(fee);
                }
              }}
              placeholder="e.g. £1500"
            />
          )}
        </LeafCell>
      </Grid>
    </ListEntryCard>
  );
};
