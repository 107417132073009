// @flow
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  type ContactWithPhones,
} from "../Navigation/ContactDrawer/ContactDrawerShared";
import { iconButtonStyle, labelButtonStyle } from "./HandoffCallButton";
import { useHandoff } from "../MobileActionSheet/useHandoff";
import { useUser } from "../../hooks/useUser";
import {
  type SmsTemplate,
  isMobile,
} from "../MobileActionSheet/MobileActionSheetContext";

type Props = {
  className?: string,
  contacts: $ReadOnlyArray<ContactWithPhones>,
  relationType: "deal" | "buyer",
  relationId: string,
  smsTemplates?: SmsTemplate[],
  withLabel?: boolean,
};

export const HandoffSMSButton = ({
  className,
  contacts,
  relationType,
  relationId,
  smsTemplates,
  withLabel,
}: Props) => {
  const { openMobileActionSheet } = useHandoff();
  const { selectedUser } = useUser();

  const availablePhones = isEmpty(contacts)
    ? []
    : contacts.flatMap(({ phones }) => phones);

  return (
    <div className={className}>
      <Button
        data-test={`sms-${relationId}`}
        onClick={() =>
          openMobileActionSheet({
            commsType: "sms",
            relationType,
            id: relationId,
            initiatedByHandoff: !isMobile(navigator.userAgent),
            selectedUser: selectedUser.email,
            smsTemplateOptions: smsTemplates,
            contacts,
          })
        }
        disabled={availablePhones.length === 0}
        css={withLabel ? labelButtonStyle : iconButtonStyle}
      >
        {withLabel ? (
          "SMS"
        ) : (
          <div>
            <FontAwesomeIcon icon={faCommentDots} />
          </div>
        )}
      </Button>
    </div>
  );
};
