// @flow
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import moment from "moment";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { type AgentTask } from "../pages/Tasks/Tasks";

export const COMPLETE_AGENT_TASK = gql`
  mutation CompleteAgentTask($taskId: ID!) {
    completeAgentTask(taskId: $taskId) {
      agentTask {
        id
        completedAt
      }
      successful
    }
  }
`;

export const UNCOMPLETE_AGENT_TASK = gql`
  mutation UncompleteAgentTask($taskId: ID!) {
    uncompleteAgentTask(taskId: $taskId) {
      agentTask {
        id
        completedAt
      }
      successful
    }
  }
`;

type useTaskCompletionProps = {
  refetchCompleted?: () => Promise<void>,
  refetchUncompleted?: () => Promise<void>,
};

export type toggleCompleteTaskType = (
  task: AgentTask,
  isUndo: boolean,
) => Promise<void>;

export const useTaskCompletion = ({
  refetchCompleted,
  refetchUncompleted,
}: useTaskCompletionProps = {}): ({
  toggleCompleteTask: toggleCompleteTaskType,
  lastTaskAnimationComplete: () => boolean,
}) => {
  const [lastTask, setLastTask] = useState(false);
  const [completeAgentTask] = useMutation(COMPLETE_AGENT_TASK);
  const [uncompleteAgentTask] = useMutation(UNCOMPLETE_AGENT_TASK);

  const { createNotification } = useNotifications();

  const completeTask = async (
    id: string,
    description: string,
    isUndo: boolean = false,
  ) => {
    try {
      const result = await completeAgentTask({
        variables: {
          taskId: id,
        },
        optimisticResponse: {
          completeAgentTask: {
            successful: true,
            agentTask: {
              __typename: "AgentTask",
              id,
              completedAt: moment(),
            },
          },
        },
      });

      if (result?.data?.completeAgentTask?.successful) {
        if (refetchUncompleted) {
          // We've just completed a task, and refetching the uncompleted list
          // will allow us to remove the task. This transition is animated, and
          // takes 300ms. We wait for that to complete before refetching any
          // completed task lists, otherwise this leads to a janky "bouncing"
          // behaviour when these lists are stacked.
          await refetchUncompleted();
          if (refetchCompleted)
            setTimeout(async () => {
              await refetchCompleted();
            }, 300);
        } else if (refetchCompleted) {
          // No uncompleted lists to fetch, let's just refetch the completed
          refetchCompleted();
        }

        setTimeout(() => {
          if (isUndo) {
            createNotification("Action undone");
          } else {
            createNotification("Task completed", {
              callback: {
                text: "Undo",
                onClick: () => uncompleteTask(id, description, true),
              },
              subText: description,
            });
          }
        }, 500);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const uncompleteTask = async (
    id: string,
    description: string,
    isUndo: boolean = false,
  ) => {
    try {
      const result = await uncompleteAgentTask({
        variables: {
          taskId: id,
        },
        optimisticResponse: {
          uncompleteAgentTask: {
            successful: true,
            agentTask: {
              __typename: "AgentTask",
              id,
              completedAt: null,
            },
          },
        },
      });

      if (result?.data?.uncompleteAgentTask?.successful) {
        if (refetchCompleted) {
          // We've just uncompleted a task, and refetching the completed list
          // will allow us to remove the task. This transition is animated, and
          // takes 300ms. We wait for that to almost complete before refetching any
          // uncompleted task lists, otherwise this leads to a janky "bouncing"
          // behaviour when these lists are stacked. If we wait for it to fully complete
          // this can lead to the empty state incorrectly appearing briefly.
          await refetchCompleted();
          if (refetchUncompleted)
            setTimeout(async () => {
              await refetchUncompleted();
            }, 200);
        } else if (refetchUncompleted) {
          // No completed lists to fetch, let's just refetch the uncompleted
          refetchUncompleted();
        }

        if (isUndo) {
          createNotification("Action undone");
        } else {
          createNotification("Task reinstated", {
            callback: {
              text: "Undo",
              onClick: () => completeTask(id, description, true),
            },
            subText: description,
          });
        }
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const toggleCompleteTask = async (
    { id, completedAt, description }: AgentTask,
    isLastTask: boolean,
  ) => {
    if (isLastTask) setLastTask(true);
    if (completedAt) {
      await uncompleteTask(id, description);
    } else {
      await completeTask(id, description);
    }
  };

  const lastTaskAnimationComplete = () => {
    if (lastTask) {
      setTimeout(() => {
        setLastTask(false);
      }, 300);
    } else {
      return true;
    }
    return false;
  };

  return {
    toggleCompleteTask,
    lastTaskAnimationComplete,
  };
};
