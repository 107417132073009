// @flow
import { useState, useEffect, useMemo } from "react";
import { css } from "styled-components";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { useParams, useHistory } from "react-router-dom";
import { isEmpty, uniqBy, isEqual, differenceBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation, gql, useApolloClient } from "@apollo/client";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { isValidEmail } from "@nested/utils";
import { PlaceholderList } from "../Placeholder";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { AreYouSureOverlay } from "../AreYouSureOverlay";
import { ExistingContactFound } from "./ExistingContactFound";
import { TabHeader } from "../../pages/Deal/EditDeal/TabHeader";

export const pageWrapper = css`
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  ${media.tablet`
    max-height: calc(100vh - 64px);
  `}
`;

export const formStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 20px 62px;
  width: 100%;
  overflow-y: auto;
  ${media.tablet`
    padding: 0 40px 0 20px;
  `}
`;

export const fieldStyles = css`
  margin: ${({ $upperSpacing }) => ($upperSpacing ? "15px 0" : "0 0 15px 0")};
  width: 100%;
  ${media.tablet`
    max-width: 295px;
    margin: 0 0 15px 0;
    width: calc(50% - 10px);
  `};
`;

export const submitButtonWrapper = css`
  padding: 15px 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  button {
    width: 100%;
  }
  ${media.tablet`
    position: static;
    padding: 20px;
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    button {
      width: 310px;
      margin: 0 auto;
    }
  `}
`;

const linkedDealsBannerStyle = css`
  background: ${({ theme }) => theme.palette.pink20};
  border: 1px solid ${({ theme }) => theme.palette.pink100};
  border-radius: 5px;
  display: flex;
  gap: 10px;
  min-height: 38px;
  margin: 20px 20px 0;
  padding: 10px;
  & svg {
    height: 18px;
    width: 16px;
  }
  ${media.tablet`
    margin: 15px 20px;
  `}
`;

const addContactInfoStyle = css`
  border: none;
  background: none;
  color: ${({ theme }) => theme.palette.blue150};
  cursor: pointer;
  font-weight: 500;
  margin-top: 5px;
  padding: 0 1px;
`;

const deleteEntryButtonStyle = css`
  ${addContactInfoStyle}
  color: ${({ theme }) => theme.palette.terracotta150};
  margin-left: 8px;
  margin-top: ${({ $isFirstButton }) => ($isFirstButton ? "22px;" : "10px;")};
`;

const inputStyle = css`
  margin-top: ${({ isFirstEntry }) => (isFirstEntry ? "0px;" : "10px;")}
    ${media.tablet`
     min-width: 295px;
  `};
`;

export const GET_CONTACT = gql`
  query GetContact($id: ID!) {
    contact(id: $id) {
      id
      firstName
      lastName
      title
      phones {
        id
        telephoneNumber
      }
      emails {
        id
        emailAddress
      }
      deals {
        id
      }
      buyers {
        id
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query EditContactGetDealAccountEmail($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      customerAccount {
        id
        email
      }
    }
  }
`;

export const FIND_EXISTING_EMAIL = gql`
  query FindContactByEmailUpdateContactModal($email: String!) {
    findContactByEmail(email: $email) {
      id
      firstName
      lastName
      title
      phones {
        id
        telephoneNumber
      }
      emails {
        id
        emailAddress
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContactModal($id: ID!, $input: UpdateContactInput!) {
    updateContact(id: $id, input: $input) {
      id
    }
  }
`;

type Props = {
  buyerId: ?string,
  dealId: ?string,
  editing: boolean,
  onClose: () => void,
  setEditing: (boolean) => void,
};

export const EditContact = ({
  buyerId,
  dealId,
  editing,
  onClose,
  setEditing,
}: Props) => {
  const [areYouSure, setAreYouSure] = useState(false);
  const [contactsFound, setContactsFound] = useState(null);
  const { contactId } = useParams();
  const history = useHistory();

  const goBack = () => {
    setEditing(false);
    history.push(`/contacts`);
  };

  const goBackUnlessEditing = () => {
    if (contactsFound) {
      setContactsFound(null);
    } else if (editing) {
      setAreYouSure(true);
    } else {
      goBack();
    }
  };

  return (
    <>
      <TabHeader
        headerText="Edit contact"
        onClose={onClose}
        onBack={goBackUnlessEditing}
        withBackButton={!areYouSure}
      />
      {areYouSure && (
        <AreYouSureOverlay
          message="You have unsaved changes. Are you sure you want to stop editing this contact?"
          leftText="Continue editing"
          leftOnClick={() => setAreYouSure(false)}
          rightText="Discard changes"
          rightOnClick={goBack}
        />
      )}
      <EditContactForm
        contactId={contactId}
        dealId={dealId}
        goBack={goBack}
        setContactsFound={setContactsFound}
        setEditing={setEditing}
      />
      {contactsFound && (
        <ExistingContactFound
          buyerId={buyerId}
          contacts={contactsFound}
          dealId={dealId}
        />
      )}
    </>
  );
};

export const getLinkedDealsBuyersBannerText = (
  dealCount: number,
  buyerCount: number,
) => {
  let numberOfDeals = "";
  let numberOfBuyers = "";
  let conjunction = "";

  if (dealCount > 0) {
    numberOfDeals = dealCount > 1 ? `${dealCount} deals` : `${dealCount} deal`;
  }
  if (buyerCount > 0) {
    numberOfBuyers =
      buyerCount > 1 ? `${buyerCount} buyers` : `${buyerCount} buyer`;
  }
  if (buyerCount > 0 && dealCount > 0) {
    conjunction = " and ";
  }

  return `This contact is linked to ${numberOfDeals}${conjunction}${numberOfBuyers}`;
};

const validate = ({ firstName, lastName, emails }) => {
  let errors = {};

  const invalidEmails = emails.map((emailObject) => {
    const { emailAddress } = emailObject;
    if (!emailAddress) return {}; // allow submission of empty email (equivalent to deletion of the email)

    if (!isValidEmail(emailAddress)) {
      return { emailAddress: "Please provide valid email address" };
    }

    return {};
  });

  if (!firstName || firstName === "") {
    errors = { ...errors, firstName: "First name is required" };
  }
  if (!lastName || lastName === "") {
    errors = { ...errors, lastName: "Last name is required" };
  }

  if (invalidEmails !== []) {
    errors = {
      ...errors,
      emails: invalidEmails,
    };
  }
  return isEmpty(errors) ? undefined : errors;
};

const EditContactForm = ({
  contactId,
  dealId,
  goBack,
  setContactsFound,
  setEditing,
}) => {
  const apolloClient = useApolloClient();
  const [editedFields, setEditedFields] = useState({});
  const { data, loading, error } = useQuery(GET_CONTACT, {
    variables: { id: contactId },
  });
  const { data: accountData, loading: accountLoading } = useQuery(GET_ACCOUNT, {
    variables: { dealId },
  });
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const { createNotification } = useNotifications();

  const initialPhones = useMemo(() => {
    const phones = data?.contact?.phones;
    if (!phones || phones.length === 0) {
      return [{ uuid: null, telephoneNumber: "" }];
    }
    return phones.map(({ id, telephoneNumber }) => ({
      uuid: id,
      telephoneNumber,
    }));
  }, [data?.contact?.phones]);

  const initialEmails = useMemo(() => {
    const emails = data?.contact?.emails;
    if (!emails || emails.length === 0) {
      return [{ id: null, emailAddress: "" }];
    }
    return emails.map(({ id, emailAddress }) => ({
      id,
      emailAddress,
    }));
  }, [data?.contact?.emails]);

  if (loading || accountLoading) {
    return (
      <div css="padding: 0 20px; width: 100%;">
        <PlaceholderList />
      </div>
    );
  }

  if (error || !data?.contact) {
    return <div css={formStyles}>Oops, there was a problem</div>;
  }

  const updateContactSubmit = async (input) => {
    const filteredInput = {
      ...input,
      phones: input.phones.filter(
        ({ telephoneNumber }) => telephoneNumber && telephoneNumber.length > 0,
      ),
      emails: input.emails.filter(
        ({ emailAddress }) => emailAddress && emailAddress.length > 0,
      ),
    };

    try {
      const { data: result, error: updateError } = await updateContact({
        variables: { id: contactId, input: filteredInput },
      });
      if (result?.updateContact) {
        createNotification("Changes saved");
        goBack();
      }
      if (updateError) {
        errorHandler(updateError);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const findContactsByEmail = async ({ emails: input }) => {
    // exclude deleted emails
    const deletedEmails = differenceBy(initialEmails, input, "id");
    const existingEmails = differenceBy(initialEmails, deletedEmails, "id");

    // find which emails have changed, excluding deleted ones
    const emailsChanged = differenceBy(input, existingEmails, "emailAddress");

    const findContactsResponses = await Promise.all(
      emailsChanged.map(async (emailField) => {
        if (!emailField.emailAddress) {
          return null;
        }
        try {
          const result = await apolloClient.query({
            fetchPolicy: "network-only",
            query: FIND_EXISTING_EMAIL,
            variables: { email: emailField.emailAddress },
          });

          if (result?.error) {
            errorHandler(result?.error);
          }
          if (result?.data?.findContactByEmail) {
            return result?.data?.findContactByEmail;
          }
        } catch (e) {
          errorHandler(e);
        }
        return null;
      }),
    );

    const uniqueContact = uniqBy(
      findContactsResponses.filter(Boolean),
      (contact) => (contact ? contact.id : undefined),
    );

    return uniqueContact;
  };

  const onSubmit = async (input) => {
    // if email has been changed, attempt find email first.
    // if a contact is found, suggest adding the contact,
    // otherwise proceed with editing contact mutation.
    if ("emails" in editedFields) {
      const existingContactsFound = await findContactsByEmail(input);

      return existingContactsFound.length > 0
        ? setContactsFound(existingContactsFound)
        : updateContactSubmit(input);
    }
    return updateContactSubmit(input);
  };

  const { firstName, lastName, title, deals, buyers } = data?.contact;

  return (
    <>
      {[...deals, ...buyers].length > 1 && (
        <div css={linkedDealsBannerStyle}>
          <FontAwesomeIcon icon={faLink} />
          {getLinkedDealsBuyersBannerText(deals.length, buyers.length)}
        </div>
      )}

      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        validate={validate}
        initialValues={{
          firstName,
          lastName,
          title,
          phones: initialPhones,
          emails: initialEmails,
        }}
        render={({
          dirtyFields,
          handleSubmit,
          pristine,
          submitting,
          hasValidationErrors,
          form: {
            mutators: { push },
          },
        }) => (
          <FormFields
            accountEmail={accountData?.nestDeal?.customerAccount?.email}
            dirtyFields={dirtyFields}
            initialEmails={initialEmails}
            initialPhones={initialPhones}
            handleSubmit={handleSubmit}
            pristine={pristine}
            submitting={submitting}
            hasValidationErrors={hasValidationErrors}
            setEditing={setEditing}
            setEditedFields={setEditedFields}
            push={push}
          />
        )}
      />
    </>
  );
};

type DeleteEntryButtonProps = {
  disabled?: boolean,
  isHidden?: boolean,
  onClick: () => void,
  index: number,
  "data-test"?: string,
};

const DeleteEntryButton = ({
  onClick,
  isHidden,
  index,
  "data-test": dataTest,
}: DeleteEntryButtonProps) => {
  if (isHidden) return null;

  return (
    <button
      type="button"
      data-test={dataTest}
      css={deleteEntryButtonStyle}
      $isFirstButton={index === 0}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </button>
  );
};

const FormFields = ({
  accountEmail,
  dirtyFields,
  initialEmails,
  initialPhones,
  handleSubmit,
  pristine,
  submitting,
  hasValidationErrors,
  setEditing,
  setEditedFields,
  push,
}) => {
  useEffect(() => {
    setEditing(!pristine);
  }, [pristine]);

  useEffect(() => {
    setEditedFields({ ...dirtyFields });
  }, [dirtyFields]);

  const buttonText = () => {
    if (pristine) {
      return "No changes to save";
    }
    return submitting ? "Saving..." : "Save";
  };

  return (
    <form onSubmit={handleSubmit} css={pageWrapper}>
      <div css={formStyles}>
        <Field name="firstName">
          {({ input, meta }) => (
            <div css={fieldStyles}>
              <TextInput
                {...input}
                className="fs-exclude"
                label="First name"
                valid={!meta.error}
              />
            </div>
          )}
        </Field>
        <Field name="lastName">
          {({ input, meta }) => (
            <div css={fieldStyles}>
              <TextInput
                {...input}
                className="fs-exclude"
                label="Last name"
                valid={!meta.error}
              />
            </div>
          )}
        </Field>
        <div css={fieldStyles} $upperSpacing>
          {/* $FlowFixMe - known issue with typing in library */}
          <FieldArray
            name="emails"
            // This is needed to help final form array work correctly with pristine
            isEqual={(fields) => isEqual(fields, initialEmails)}
          >
            {({ fields }) =>
              fields.map((name, index) => (
                <Field name={`${name}.emailAddress`} key={name}>
                  {({ input, meta }) => {
                    return (
                      <div css="display: flex">
                        <TextInput
                          css={inputStyle}
                          isFirstEntry={index === 0}
                          data-test={`email-field-${index}`}
                          key={`email-${index}`}
                          valid={meta.valid}
                          label={index === 0 ? "Email" : undefined}
                          {...input}
                          className="fs-exclude"
                          disabled={input.value === accountEmail}
                          title={
                            input.value === accountEmail
                              ? "Cannot edit account holder email"
                              : undefined
                          }
                        />
                        <DeleteEntryButton
                          data-test={`delete-email-button-${index}`}
                          index={index}
                          isHidden={
                            (fields.value.length === 1 && !input.value) ||
                            input.value === accountEmail ||
                            fields.value.length <= 1
                          }
                          onClick={() => fields.remove(index)}
                        />
                      </div>
                    );
                  }}
                </Field>
              ))
            }
          </FieldArray>
          <div>
            <button
              type="button"
              css={addContactInfoStyle}
              data-test="add-email-button"
              onClick={() => push("emails", { id: null, emailAddress: "" })}
            >
              Add another email
              <FontAwesomeIcon icon={faPlusCircle} css="margin-left: 5px" />
            </button>
          </div>
        </div>
        <div css={fieldStyles} $upperSpacing>
          {/* $FlowFixMe - known issue with typing in library */}
          <FieldArray
            name="phones"
            isEqual={(fields) => isEqual(fields, initialPhones)}
          >
            {({ fields }) =>
              fields.map((name, index) => (
                <Field name={`${name}.telephoneNumber`} key={name}>
                  {({ input, meta }) => (
                    <div css="display: flex">
                      <TextInput
                        data-test={`phone-number-${index}`}
                        css={inputStyle}
                        isFirstEntry={index === 0}
                        label={index === 0 ? "Contact number" : undefined}
                        valid={!meta.error}
                        {...input}
                        optional
                        showOptionalLabel={index === 0}
                        className="fs-exclude"
                      />
                      <DeleteEntryButton
                        data-test={`delete-phone-button-${index}`}
                        index={index}
                        isHidden={fields.value.length === 1 && !input.value}
                        onClick={() => {
                          // If only one field is present, we dont remove the field, but rather clear the input
                          if (fields.value.length === 1 && input.value) {
                            /* $FlowFixMe - known issue with typing in library */
                            fields.update(index, {
                              uuid: null,
                              telephoneNumber: "",
                            });
                            return;
                          }
                          fields.remove(index);
                        }}
                      />
                    </div>
                  )}
                </Field>
              ))
            }
          </FieldArray>
          <div>
            <button
              type="button"
              css={addContactInfoStyle}
              data-test="add-phone-button"
              onClick={() =>
                push("phones", { uuid: null, telephoneNumber: "" })
              }
            >
              Add another number
              <FontAwesomeIcon icon={faPlusCircle} css="margin-left: 5px" />
            </button>
          </div>
        </div>
        <Field name="title">
          {({ input, meta }) => (
            <div css={fieldStyles} $upperSpacing>
              <TextInput
                {...input}
                className="fs-exclude"
                label="Description"
                showOptionalLabel
                valid={!meta.error}
              />
            </div>
          )}
        </Field>
      </div>
      <div css={submitButtonWrapper}>
        <Button
          large
          buttonStyle="pink"
          type="submit"
          disabled={submitting || pristine || hasValidationErrors}
          css="&:disabled { opacity: 0.3 }"
        >
          {buttonText()}
        </Button>
      </div>
    </form>
  );
};
