// @flow
import { css } from "styled-components";
import { Card } from "@nest-ui/sellers-nest/components/Card";
import { getConfig } from "@nested/config";
import {
  HandoffSMSButton,
  HandoffCallButton,
} from "@nested/nest/src/components/Comms";
import { type ContactWithPhones } from "@nested/nest/src/components/Navigation/ContactDrawer/ContactDrawerShared";
import { contactIconStyle } from "./ViewingCard/ViewingCard";

const contactSellerStyle = css`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
  justify-content: space-between;
`;

const { ACCOUNT_URI } = getConfig();

type Props = {
  agentFirstName: string,
  contacts: $ReadOnlyArray<ContactWithPhones>,
  externalId: string,
};

export const ContactSellerCard = ({
  agentFirstName,
  contacts,
  externalId,
}: Props) => {
  const checkAccount = `Hi, it’s ${agentFirstName} from Nested, I’ve just left your property. I’ve shared some first impressions in the Nested app: ${ACCOUNT_URI}/listing/viewings/viewing-feedback`;
  const noFirstImpressions = `Hi, it’s ${agentFirstName} from Nested, I’ve just left your property. We’ll let you know once we have viewing feedback to share.`;
  const sellerSMSTemplates = [
    { label: "Check account", content: checkAccount },
    { label: "No first impressions", content: noFirstImpressions },
  ];

  return (
    <Card css={contactSellerStyle}>
      <span>Contact the seller</span>
      <div css="display: flex;">
        <HandoffSMSButton
          css={contactIconStyle}
          contacts={contacts}
          relationType="deal"
          relationId={externalId}
          smsTemplates={sellerSMSTemplates}
        />
        <HandoffCallButton
          css={contactIconStyle}
          contacts={contacts}
          relationId={externalId}
          relationType="deal"
        />
      </div>
    </Card>
  );
};
