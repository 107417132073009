// @flow
import { Heading } from "components/Heading";
import { Card } from "components/Card";
import { ProposedCustomerEligibility } from "../sections/ProposedCustomerEligibility/ProposedCustomerEligibility";
import { CoolingOffPeriodSignoffContainer } from "../sections/CoolingOffPeriodSignoff/CoolingOffPeriodSignoff";
import { AdvancesForAllMar2019CoolingOff } from "../sections/CoolingOffPeriodSignoff/AdvancesForAllMar2019CoolingOff/AdvancesForAllMar2019CoolingOff";
import { ContractDetails } from "../sections/ContractDetails/ContractDetails";
import { GuaranteeNotes } from "../sections/GuaranteeNotes";
import { DueDiligenceDatesSection } from "../sections/DueDiligenceDatesSection";
import { SimpleDD } from "../sections/SimpleDD/SimpleDD";
import { Advances } from "../../Advances/Advances";
import { AdvancesSectionList } from "../../Advances/AdvancesSectionList";
import { AdvanceInPrinciple } from "../../Advances/AdvanceInPrinciple";
import { AdvanceDrawdownRequest } from "../../Advances/AdvanceDrawdownRequest";
import { AdvanceOffer } from "../../Advances/AdvanceOffer";
import { LegalServicesSection } from "../../Advances/LegalServicesSection/LegalServicesSection";
import { AdvancePayment } from "../../Advances/AdvancePayment";

type Props = { dealId: string };

export const AdvancesForAllMarch2019LegalServices = ({ dealId }: Props) => (
  <>
    <GuaranteeNotes dealId={dealId} />
    <Card>
      <ProposedCustomerEligibility dealId={dealId} />
    </Card>
    <Heading>Simple DD start</Heading>
    <Card>
      <SimpleDD dealId={dealId} />
    </Card>
    <Heading>Full DD start</Heading>
    <Card>
      <AdvancesForAllMar2019CoolingOff dealId={dealId} />
    </Card>
    <DueDiligenceDatesSection dealId={dealId} />
    <Heading id="cooling-off-period-sign-off">Full DD signoff</Heading>
    <Card>
      <CoolingOffPeriodSignoffContainer
        dealId={dealId}
        fieldLayout={[
          "isReportOnTitleReceivedByLegalOps isTitleApproved isLeaseApproved coolingOffPeriodReadyForSignoffDate",
        ]}
      />
    </Card>
    <Heading id="contract-details">Contract Details</Heading>
    <Card>
      <ContractDetails dealId={dealId} />
    </Card>
    <Heading>Advances</Heading>
    <Card>
      <Advances dealId={dealId} data-test="legal-ops-tab-advances">
        <AdvancesSectionList>
          <AdvanceInPrinciple />
          <AdvanceOffer />
          <AdvanceDrawdownRequest />
          <LegalServicesSection />
          <AdvancePayment readOnly />
        </AdvancesSectionList>
      </Advances>
    </Card>
  </>
);
