// @flow
import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { css } from "styled-components";
import moment from "moment";
import { media } from "@nested/brand";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { useSorting } from "@nested/nest/src/hooks/useSorting";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { Select } from "../../components/Select/Select";
import { ListViewError } from "../../components/ListViewError";
import { NoResults } from "../../components/NoResults";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import { Button } from "../../components/Button/Button";
import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import {
  NurtureHotnessImage,
  ThermometerImage,
} from "../../components/NurtureHotnessImage";
import { getFullName } from "../utils";
import { NURTURE_VIEW_LIMIT, MOBILE_SORT_OPTIONS } from "./NurtureShared";

const loadMoreButtonStyle = css`
  margin: 20px auto;
  width: 250px;
  ${media.tablet`
    margin-bottom: 0;
  `}
`;

const mobileStyle = css`
  ${mobileWrapperStyle};
  ${({ $showButton }) =>
    $showButton &&
    css`
      padding-bottom: 0;
      margin-bottom: 0;
    `}
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0px;
  }
`;

export const NO_TASK_SET_NURTURE_QUERY = gql`
  query NoTaskSetNurtureListViewQuery(
    $sortBy: NurtureListViewSortBy
    $sortDirection: ListViewSortDirection
    $email: String!
    $limit: Int
    $after: String
  ) {
    noTaskSetNurtureDeals(
      sortBy: $sortBy
      sortDirection: $sortDirection
      email: $email
      limit: $limit
      after: $after
    ) {
      paginatedResults {
        results {
          id
          address
          createdAt
          externalId
          firstName
          lastName
          lastOutboundCall
          nurtureHotness {
            label
          }
          ownerName
          opportunityStatus {
            valueText
            label
          }
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
            }
            avatarUrl
          }
        }
        endCursor
      }
    }
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          key={deal.externalId}
          address={deal.address}
          contacts={deal.contacts}
          externalId={deal.externalId}
          detailText={`Enquired ${formatDatetimeDifference(deal.createdAt)}`}
          detailTextTitle={moment(deal.createdAt).format("LLLL")}
          nurtureHotness={deal.nurtureHotness}
          sellerName={getFullName(deal)}
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field="SELLER_NAME"
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <TH>Status</TH>
            <SortableTH
              field="CREATED_AT"
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              {" "}
              Enquired
            </SortableTH>
            <SortableTH
              field="LAST_OUTBOUND_CALL"
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              {" "}
              Last outbound call
            </SortableTH>
            <SortableTH
              css="svg { vertical-align: unset; }"
              field="NURTURE_HOTNESS"
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              <ThermometerImage />
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => (
            <tr key={deal.externalId} data-test={`deal-row-${deal.externalId}`}>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {deal.address}
              </DealTD>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {getFullName(deal)}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {deal.opportunityStatus.label}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={moment(deal.createdAt).format("LLLL")}
              >
                {formatDatetimeDifference(deal.createdAt)}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={
                  deal.lastOutboundCall &&
                  moment(deal.lastOutboundCall).format("LLLL")
                }
              >
                {deal.lastOutboundCall
                  ? formatDatetimeDifference(deal.lastOutboundCall)
                  : "-"}
              </DealTD>
              <DealTD
                css="font-size: large;"
                dealId={deal.externalId}
                title={deal.nurtureHotness?.label}
              >
                <NurtureHotnessImage nurtureHotness={deal.nurtureHotness} />
              </DealTD>
              <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                <ListViewCallButton
                  contacts={deal.contacts}
                  externalId={deal.externalId}
                  relationType={"deal"}
                />
              </DealTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const NoTaskSetNurtureListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    "NURTURE_HOTNESS",
    "DESCENDING",
  );

  const { selectedUser } = useUser();
  const { noTaskSetNurture } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(NO_TASK_SET_NURTURE_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
      limit: NURTURE_VIEW_LIMIT,
    },
    notifyOnNetworkStatusChange: true,
  });

  const results = data?.noTaskSetNurtureDeals?.paginatedResults?.results || [];
  const endCursor = data?.noTaskSetNurtureDeals?.paginatedResults?.endCursor;
  const showLoadMoreButton = !!endCursor;

  useEffect(() => {
    refetch({ limit: Math.max(results.length || NURTURE_VIEW_LIMIT) });
  }, [noTaskSetNurture]);

  if (!loading && results.length === 0) {
    return <NoResults message="You have no leads to nurture." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileStyle} $showButton={showLoadMoreButton}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
      {showLoadMoreButton && (
        <Button
          css={loadMoreButtonStyle}
          disabled={loading}
          large
          onClick={() => {
            fetchMore({
              variables: { after: endCursor },
              updateQuery: (prev, { fetchMoreResult }) => {
                const { results: newResults, endCursor: newEndCursor } =
                  fetchMoreResult.noTaskSetNurtureDeals.paginatedResults;

                const updatedResults = [
                  ...prev.noTaskSetNurtureDeals.paginatedResults.results,
                  ...newResults,
                ];

                const updatedQuery = {
                  noTaskSetNurtureDeals: {
                    paginatedResults: {
                      results: updatedResults,
                      endCursor: newEndCursor,
                    },
                  },
                };
                return updatedQuery;
              },
            });
          }}
        >
          Load more
        </Button>
      )}
    </>
  );
};
