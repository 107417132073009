// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { useLocation } from "react-router-dom";

// This appears on the right as an absolute div in desktop and
// a fixed div in tablet. It contains scrollable content and a sticky header.
const PANEL_WIDTH = 380;

const sidePanelStyle = css`
  display: none;
  ${media.tablet`
    height: 100%;
    background-color: white;
    border-left: 1px solid ${({ theme }) => theme.palette.hague20};
    display: flex;
    flex-direction: column;
    min-width: ${PANEL_WIDTH}px;
    max-width: ${PANEL_WIDTH}px;
    width: ${PANEL_WIDTH}px;
    overflow-x: hidden;
    overflow-y: hidden;
    overscroll-behavior: contain;
    transition: max-width 200ms ease-out, width 200ms ease-out, right 200ms ease-out;
    ${({ $expanded }) =>
      $expanded &&
      css`
        max-width: 82vw;
        width: 82vw;
      `}
    position: ${({ $pageTopInView }) =>
      $pageTopInView ? "absolute" : "fixed"};
    right: calc(${PANEL_WIDTH} * ${({ $open }) => ($open ? "0" : "-1")}px);
    top: 0;
    z-index: 2;
  `}

  ${media.desktop`
      height: auto;
      bottom: 0;
      transition: max-width 200ms ease-out, width 200ms ease-out, right 200ms ease-out, margin-right 200ms ease-out;
      width: ${PANEL_WIDTH}px;

      ${({ $expanded, $isBuyer }) => {
        if ($expanded) {
          return css`
            position: absolute;
            width: 80vw;
          `;
        }
        // If not expanded and is buyer, make it sticky but adjust the height
        // && increases specificity of applied styles and is equivalent to .class .class and helps avoid use of important
        if ($isBuyer) {
          return css`
            && {
              position: sticky;
              top: 77px;
              height: calc(100vh - 127px);
            }
          `;
        }
        // If not expanded make it sticky anyway. Will not work if moved outside ${} block 🙄
        return css`
          position: sticky;
        `;
      }}
      margin-left: 0;
      margin-right: calc(${PANEL_WIDTH} * ${({ $open }) =>
    $open ? "0" : "-1"}px);
      padding-bottom: 0;
      right: calc(${PANEL_WIDTH} * ${({ $open }) => ($open ? "0" : "-1")}px);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
       display: none;
      }
    `}
`;

type RHSidePanelProps = {
  children: any,
  className?: string,
  "data-test": string,
  activitiesExpanded: boolean,
  pageTopInView: boolean,
  open: boolean,
};

export const RHSidePanel = ({
  children,
  className,
  "data-test": dataTest = "RH-side-panel",
  pageTopInView,
  open,
  activitiesExpanded,
}: RHSidePanelProps) => {
  const { pathname } = useLocation();
  const isBuyer = pathname.startsWith("/buyer");
  return (
    <div
      className={className}
      css={sidePanelStyle}
      data-test={dataTest}
      $pageTopInView={pageTopInView}
      $open={open}
      $expanded={activitiesExpanded}
      $isBuyer={isBuyer}
    >
      {children}
    </div>
  );
};
