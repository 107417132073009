// @flow
import styled from "styled-components";

export const PopupBoxDiv = styled.div`
  position: absolute;
  top: 52px;
  right: -5px;
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  z-index: 105;
  background-color: white;
  width: 302px;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 5px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
    right: ${({ $triangleRightOffset }) => $triangleRightOffset || "35px;"};
    border-left: 2px solid ${({ theme }) => theme.palette.hague20};
    border-top: 2px solid ${({ theme }) => theme.palette.hague20};
    background: linear-gradient(
      to top left,
      transparent 50%,
      white calc(50% - 1px)
    );
    pointer-events: none;
  }
`;

type Props = {
  className?: string,
  children: any,
  "data-test"?: string,
  triangleRightOffset?: ?string,
  isOpen: boolean,
};

export const PopupBox = ({
  className,
  children,
  "data-test": dataTest,
  triangleRightOffset,
  isOpen,
}: Props) => (
  <div css="position: absolute">
    <PopupBoxDiv
      className={className}
      data-test={dataTest}
      $triangleRightOffset={triangleRightOffset}
      $isOpen={isOpen}
    >
      {children}
    </PopupBoxDiv>
  </div>
);
