// @flow
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { Select } from "../../components/Select/Select";
import { NURTURE_TEMPERATURE_OPTIONS } from "./DealStatus/DeactivationForm";

export const NURTURE_HOTNESS_SELECT_QUERY = gql`
  query NurtureHotnessSelect($id: ID!) {
    nestDeal(id: $id) {
      id
      nurtureHotness {
        label
      }
    }
  }
`;

export const UPDATE_NURTURE_HOTNESS_MUTATION = gql`
  mutation UpdateNurtureHotness($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      nurtureHotness {
        label
      }
    }
  }
`;

type Props = {
  id: string,
};

export const NurtureHotnessSelect = ({ id }: Props) => {
  const { data } = useQuery(NURTURE_HOTNESS_SELECT_QUERY, {
    variables: {
      id,
    },
  });
  const [updateNurtureHotness] = useMutation(UPDATE_NURTURE_HOTNESS_MUTATION);

  const { nurtureHotness } = data?.nestDeal || {};

  const onChange = async (newNurtureHotness) => {
    try {
      await updateNurtureHotness({
        variables: {
          id,
          input: { nurtureHotness: newNurtureHotness },
        },
        optimisticResponse: {
          updateNestDeal: {
            __typename: "NestDeal",
            id,
            nurtureHotness: {
              __typename: "NurtureHotness",
              label: newNurtureHotness,
            },
          },
        },
      });
    } catch (e) {
      // eslint-disable-next-line
      window.alert(e.message);
    }
  };

  return (
    <Select
      data-test="nurture-hotness-select"
      value={nurtureHotness?.label}
      options={NURTURE_TEMPERATURE_OPTIONS}
      label="Nurture"
      onChange={onChange}
    />
  );
};
