// @flow
export const Link = ({ fill = "#10B186" }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M7.333 11.696a2.144 2.144 0 0 1-1.302 1.016 2.144 2.144 0 0 1-1.638-.202 2.141 2.141 0 0 1-1.016-1.302 2.142 2.142 0 0 1 .202-1.638l1.159-2.045a2.142 2.142 0 0 1 1.301-1.016A2.16 2.16 0 0 1 8.77 8.608a1.357 1.357 0 0 0 1.246-.005 3.405 3.405 0 0 0-1.724-2.975 3.38 3.38 0 0 0-2.585-.319 3.38 3.38 0 0 0-2.053 1.602L2.495 8.956a3.38 3.38 0 0 0-.319 2.585 3.38 3.38 0 0 0 1.603 2.053 3.385 3.385 0 0 0 2.584.319 3.38 3.38 0 0 0 2.053-1.602l.341-.602a.6.6 0 0 0 .053-.126 4.209 4.209 0 0 1-1.21-.358l-.267.471zm4.89-9.29a3.38 3.38 0 0 0-2.584-.319A3.38 3.38 0 0 0 7.586 3.69l-.341.602a.61.61 0 0 0-.054.128c.42.058.828.176 1.211.356l.268-.472A2.142 2.142 0 0 1 9.97 3.288a2.141 2.141 0 0 1 1.639.202 2.16 2.16 0 0 1 .813 2.94l-1.159 2.045A2.142 2.142 0 0 1 9.963 9.49a2.144 2.144 0 0 1-1.639-.202 2.16 2.16 0 0 1-1.093-1.896 1.359 1.359 0 0 0-1.245.005 3.406 3.406 0 0 0 3.527 3.416c.262-.01.524-.05.782-.122a3.38 3.38 0 0 0 2.053-1.602l1.159-2.045a3.408 3.408 0 0 0-1.283-4.638z"
    />
  </svg>
);
