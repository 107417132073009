import styled from "styled-components";

const H2 = styled.h2`
  font-size: 14px;
  overflow: hidden;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: ${({ theme }) => theme.color.text.default};
  position: relative;
  white-space: nowrap;
  margin-bottom: 24px;
  margin-top: 24px;
  line-height: 16px;
`;

export { H2 };
