export const ExpandArrows = () => (
  <svg
    css="position: absolute; z-index: -1;"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.78125 12.7188L3.65625 11.5938L11.5938 3.65625L12.7188 4.78125C12.9479 5.01042 13.2188 5.0625 13.5312 4.9375C13.8438 4.8125 14 4.58333 14 4.25V0.75C14 0.541667 13.9271 0.364583 13.7812 0.21875C13.6354 0.0729167 13.4583 0 13.25 0H9.75C9.41667 0 9.1875 0.15625 9.0625 0.46875C8.9375 0.78125 8.98958 1.05208 9.21875 1.28125L10.3438 2.40625L2.40625 10.3438L1.28125 9.21875C1.05208 8.98958 0.78125 8.9375 0.46875 9.0625C0.15625 9.1875 0 9.41667 0 9.75V13.25C0 13.4583 0.0729167 13.6354 0.21875 13.7812C0.364583 13.9271 0.541667 14 0.75 14H4.25C4.58333 14 4.8125 13.8438 4.9375 13.5312C5.0625 13.2188 5.01042 12.9479 4.78125 12.7188Z"
      fill="#4E7A88"
    />
  </svg>
);
