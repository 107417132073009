// @flow
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem } from "@nested/brand/src/components/Timeline";

type Props = {
  event: {
    title: string,
    timestamp: DateTime,
  },
};

export const TimelineDealOppStatus = ({
  event: { title, timestamp },
}: Props) => (
  <TimelineItem
    color="green"
    headingColor="sand"
    icon={faFlag}
    primaryHeading={title}
    timestamp={timestamp}
  />
);
