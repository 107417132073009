// @flow
import React from "react";

import { ReadOnlyField } from "components/ReadOnlyField";
import { NakedTextField, type NakedTextFieldProps } from "./NakedTextField";

const nullOrUndefinedToEmptyString = (
  value: string | number | boolean | null,
) => (value === null || value === undefined ? "" : value);
const emptyStringToNull = (value: string | number | boolean | null) =>
  value === "" ? null : value;
const identity = (x: string | number | boolean) => x;
const noop = () => {};

type NoSubmitTextFieldProps = NakedTextFieldProps & {
  cast: (value: string | number | boolean) => string | number | boolean,
  format: (
    value?: string | number | boolean | null,
  ) => string | number | boolean,
  highlight?: boolean,
  highlightRed?: boolean,
  onSubmit: (value: string | number | boolean | null) => void,
  readOnly?: boolean,
};

type NoSubmitTextFieldState = {
  inputRef: {| current: null | HTMLInputElement |},
  text: string | number | boolean | null,
  editing: boolean,
};

export class NoSubmitTextField extends React.Component<
  NoSubmitTextFieldProps,
  NoSubmitTextFieldState,
> {
  static defaultProps = {
    cast: identity,
    format: nullOrUndefinedToEmptyString,
    onBlur: noop,
    onFocus: noop,
    onSubmit: noop,
    type: "text",
  };

  constructor(props: NoSubmitTextFieldProps) {
    super(props);

    this.state = {
      inputRef: React.createRef(),
      text: props.value ?? null,
      editing: false,
    };
  }

  iosClearDefault(target: HTMLInputElement) {
    // https://github.com/facebook/react/issues/8938
    // eslint-disable-next-line no-param-reassign
    target.defaultValue = "";
  }

  componentDidUpdate(prevProps: NoSubmitTextFieldProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ text: value });
    }
    if (this.state.inputRef && this.state.inputRef.current) {
      this.iosClearDefault(this.state.inputRef.current);
    }
  }

  onChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { cast } = this.props;

    this.setState({
      text: cast(event.currentTarget.value),
    });
    const target = event.currentTarget;
    window.setTimeout(() => this.iosClearDefault(target), 0);
  };

  onBlur = (event: SyntheticEvent<HTMLInputElement>) => {
    const { onBlur: onBlurFromProps, onSubmit } = this.props;
    const { text } = this.state;

    const submitValue = emptyStringToNull(text);

    onSubmit(submitValue);
    onBlurFromProps(event);

    this.setState({
      editing: false,
      text: undefined,
    });
  };

  onFocus = (event: SyntheticEvent<HTMLInputElement>) => {
    const { onFocus: onFocusFromProps, value } = this.props;

    onFocusFromProps(event);

    this.setState({
      text: value,
      editing: true,
    });
  };

  render() {
    const {
      className,
      disabled,
      format,
      highlight,
      highlightRed,
      isSecondary,
      label,
      readOnly,
      value,
    } = this.props;
    const { editing, text } = this.state;
    const inputRef = this.props.inputRef || this.state.inputRef;

    let inputValue;

    if (editing) {
      inputValue = text === undefined ? format(value) : format(text);
    } else {
      /**
       * We don't show "text" from state here, because we expect the props to
       * be updated and the value would reflect that. This also means that the value
       * when testing would be an empty string ('').
       */
      inputValue = value === null ? "" : format(value);
    }

    if (readOnly || disabled) {
      return (
        <ReadOnlyField
          className={className}
          disabled={disabled}
          label={label}
          value={inputValue}
          highlight={highlight}
          isSecondary={isSecondary}
        />
      );
    }

    return (
      <NakedTextField
        {...this.props}
        className={className}
        inputRef={inputRef}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        mutedText={!editing && value === null}
        value={inputValue}
        highlightRed={highlightRed}
      />
    );
  }
}
