// @flow

import styled from "styled-components";

const Wrapper = styled.div`
  color: ${({ theme }) => theme.text.white};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const CountStyle = styled.div`
  text-align: left;
  padding-bottom: 8px;
  &:last-child {
    padding-bottom: 20px;
  }
`;

type Props = {
  labels: Array<string>,
};

export const Counter = ({ labels }: Props) => (
  <Wrapper>
    {labels.map((label, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <CountStyle key={index}>{label}</CountStyle>
    ))}
  </Wrapper>
);
