import { createContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  // This effect sweeps up all notifications when there are none currently visible.
  useEffect(() => {
    if (notifications.length === 0) {
      return;
    }
    if (notifications.every(({ visible }) => !visible)) {
      // Wait 200ms to allow the last notification to finish animating out
      setTimeout(() => {
        setNotifications([]);
      }, 200);
    }
  }, [notifications]);

  // When dismissing a notification, we just set the `visible` prop to false to prevent any
  // notifications on top of it from moving around. Once all notifications have been hidden, we
  // can safely remove them all from the DOM.
  const dismissNotification = (idToRemove) => {
    setNotifications((current) => {
      return current.map((notification) => {
        if (notification.id === idToRemove) {
          return {
            ...notification,
            visible: false,
          };
        }
        return notification;
      });
    });
  };

  const createNotification = (
    message,
    { icon = faCheck, callback = null, subText = null } = {},
  ) => {
    const id = uuidv4();
    setNotifications((current) => [
      ...current,
      {
        id,
        icon,
        message,
        subText,
        callback,
        dismiss: () => dismissNotification(id),
        visible: true,
      },
    ]);

    return id;
  };

  return (
    <NotificationContext.Provider
      value={{
        createNotification,
        dismissNotification,
        notifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
