// @flow
import styled from "styled-components";

import { Exit } from "@nest-ui/icons";
import { authClient } from "../../authClient";

const LogoutSection = styled.a`
  cursor: pointer;
  display: block;
  height: 32px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.leftNav};
  margin-bottom: 14px;
  width: 100%;

  &:hover {
    color: white;
    svg {
      stroke: white;
    }
  }

  svg {
    stroke: ${({ theme }) => theme.color.text.leftNav};
    margin-right: 8px;
    position: relative;
    top: 4px;
    left: 0;
  }
`;

function LogoutLink() {
  return (
    <LogoutSection
      data-test="logout"
      onClick={() => {
        authClient.logout();
      }}
    >
      <Exit />
      Log Out
    </LogoutSection>
  );
}

export { LogoutLink };
