// @flow
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";
import { Grid, LeafCell } from "components/Grid";

import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { RadioButtons } from "components/RadioButtons";

import SDLTSurchargeSectionSectionQuery from "./queries/SDLTSurchargeSectionQuery.graphql";
import { SDLTSurchargeSectionQueryConfig } from "./queries/SDLTSurchargeSectionQueryConfig";

import updateNestDealSDLTSurchargeSection from "./mutations/updateNestDealSDLTSurchargeSection.graphql";
import { updateNestDealSDLTSurchargeSectionConfig } from "./mutations/updateNestDealSDLTSurchargeSectionConfig";
import updateSDLTSurchargeMutation from "./mutations/updateSDLTSurcharge.graphql";
import { updateSDLTSurchargeConfig } from "./mutations/updateSDLTSurchargeConfig";

const SDLTSurchargeComponent = ({
  data,
  updateNestDeal,
  updateSDLTSurcharge,
}) => {
  const { nestDeal } = data;
  const sdltSurcharge = nestDeal.sdltSurcharge ?? {};

  return (
    <Grid columns={4}>
      <LeafCell width={1}>
        <RadioButtons
          data-test="sdlt-surcharge-applicable"
          label="Will there be a SDLT surcharge?"
          mutation={updateNestDeal}
          options={[
            { label: "No", value: false },
            { label: "Yes", value: true },
          ]}
          property="sdltSurchargeApplicable"
          value={nestDeal.sdltSurchargeApplicable}
        />
      </LeafCell>
      {nestDeal.sdltSurchargeApplicable && (
        <LeafCell width={4}>
          <Grid columns={4}>
            <LeafCell width={1}>
              <DatePicker
                label="Payment date"
                value={sdltSurcharge.paymentDate}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-payment-date"
                property="paymentDate"
              />
            </LeafCell>
            <LeafCell width={1}>
              <CurrencyField
                label="Payment amount"
                value={sdltSurcharge.paymentAmount}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-payment-amount"
                property="paymentAmount"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <DatePicker
                label="Stamp Duty redemption expiry date"
                value={sdltSurcharge.stampDutyRedemptionExpiryDate}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-stamp-duty-redemption-expiry-date"
                property="stampDutyRedemptionExpiryDate"
              />
            </LeafCell>
            <LeafCell width={1}>
              <DatePicker
                label="Option expiry date"
                value={sdltSurcharge.optionExpiryDate}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-option-expiry-date"
                property="optionExpiryDate"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <DatePicker
                label="Redemption application submission date"
                value={sdltSurcharge.redemptionApplicationSubmissionDate}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-redemption-application-submission-date"
                property="redemptionApplicationSubmissionDate"
              />
            </LeafCell>
            <LeafCell width={1}>
              <DatePicker
                label="Redemption date"
                value={sdltSurcharge.redemptionDate}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-redemption-date"
                property="redemptionDate"
              />
            </LeafCell>
            <LeafCell width={1}>
              <CurrencyField
                label="Redemption amount"
                value={sdltSurcharge.redemptionAmount}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-redemption-amount"
                property="redemptionAmount"
              />
            </LeafCell>
            <LeafCell width={1}>
              <CurrencyField
                label="SDLT outstanding to be repaid"
                value={sdltSurcharge.outstandingRedemptionAmount}
                mutation={updateSDLTSurcharge}
                data-test="sdlt-surcharge-outstanding-redemption-amount"
                property="outstandingRedemptionAmount"
                readOnly
              />
            </LeafCell>
          </Grid>
        </LeafCell>
      )}
    </Grid>
  );
};

const enhance = compose(
  graphql(SDLTSurchargeSectionSectionQuery, SDLTSurchargeSectionQueryConfig),
  graphql(
    updateNestDealSDLTSurchargeSection,
    updateNestDealSDLTSurchargeSectionConfig,
  ),
  graphql(updateSDLTSurchargeMutation, updateSDLTSurchargeConfig),
);

export const SDLTSurchargeSection = enhance(SDLTSurchargeComponent);
