// @flow

import { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { media } from "@nested/brand";
import { BackButton } from "./BackButton";
import { XButton } from "./XButton";

export const backgroundStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px 10px;
  z-index: 98;
  overflow-y: auto;
  ${({ $open }) =>
    $open
      ? css`
          transition: background-color 300ms linear 0ms,
            visibility 300ms linear 0ms;
          visibility: visible;
          background-color: rgb(10, 66, 84, 0.8);
          pointer-events: auto;
        `
      : css`
          transition: background-color 300ms ease 300ms,
            visibility 300ms ease 300ms;
          visibility: hidden;
          background-color: rgb(10, 66, 84, 0);
          pointer-events: none;
        `};
`;

const mobileFullScreenBackground = css`
  z-index: 98;

  ${media.tablet`
    ${backgroundStyle}
  `}
`;

const topScreenStyles = css`
  top: 20px;
  left: 10px;
  right: 10px;
`;

const bottomScreenStyles = css`
  bottom: 20px;
  left: 10px;
  right: 10px;
`;

const modalStyle = css`
  position: fixed;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  ${({ topScreen }) => (topScreen ? topScreenStyles : bottomScreenStyles)}

  ${({ $open }) =>
    $open
      ? css`
          transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
          transform: translateY(0px);
          opacity: 1;
        `
      : css`
          transition: opacity 300ms ease 0ms, visibility 300ms ease 0ms,
            transform 300ms ease 0ms;
          transform: translateY(3em);
          opacity: 0;
        `}

    ${media.tablet`
    width: 540px;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
  `}
`;

const mobileFullScreenModal = css`
  position: fixed;
  background-color: white;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  z-index: 98;
  ${({ $open }) =>
    $open
      ? css`
          display: block;
          pointer-events: auto;
        `
      : css`
          display: none;
        `}

  ${({ $open }) =>
    $open
      ? css`
          transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
          transform: translateY(0px);
          opacity: 1;
        `
      : css`
          transition: opacity 300ms ease 0ms, visibility 300ms ease 0ms,
            transform 300ms ease 0ms;
          transform: translateY(3em);
          opacity: 0;
        `}

    ${media.tablet`
    border-radius: 10px;
    max-height: 80%;
    ${({ topScreen }) => (topScreen ? topScreenStyles : bottomScreenStyles)}
    width: 540px;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
  `}
`;

const headerStyles = css`
  min-height: 62px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 20px;
  padding-right: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px;
`;

export const ModalHeader = styled.header`
  ${headerStyles}
`;

const Header = styled.div`
  min-height: 53px;
  background-color: ${({ theme }) => theme.palette.hague};
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 20px 0;
  ${media.tablet`
    ${headerStyles}
    color: ${({ theme }) => theme.palette.hague};
    background-color: white;
  `}
`;

const backButtonStyle = css`
  left: 0;
  font-size: 14px;
  padding: 20px;
`;

type Props = {|
  "data-test"?: string,
  open: boolean,
  onBack?: ?() => void,
  onClose: () => void,
  children: React$Node,
  className?: string,
  css?: any,
  topScreen?: boolean,
  showXButton?: boolean,
|};

export const Modal = ({
  open,
  onClose,
  children,
  className,
  "data-test": dataTest,
  topScreen = false,
  showXButton = true,
}: Props) => {
  const modalRef = useRef();

  useEffect(() => {
    if (open) {
      disableBodyScroll(modalRef.current);
    } else {
      enableBodyScroll(modalRef.current);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <div
      role="presentation"
      css={backgroundStyle}
      $open={open}
      onClick={onClose}
      ref={modalRef}
    >
      <div
        role="presentation"
        $open={open}
        onClick={(e) => e.stopPropagation()}
        css={modalStyle}
        data-test={dataTest}
        className={className}
        topScreen={topScreen}
      >
        {showXButton && <XButton onClick={onClose} />}
        {children}
      </div>
    </div>
  );
};

type MobileFullScreenModalProps = {
  ...Props,
  headerText: string,
};

export const MobileFullScreenModal = ({
  open,
  onBack,
  onClose,
  children,
  className,
  "data-test": dataTest,
  headerText,
  topScreen = false,
}: MobileFullScreenModalProps) => {
  const modalRef = useRef();
  useEffect(() => {
    if (open) {
      disableBodyScroll(modalRef.current, { allowTouchMove: () => true });
    } else {
      enableBodyScroll(modalRef.current);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);
  return (
    <div
      role="presentation"
      css={mobileFullScreenBackground}
      $open={open}
      onClick={onClose}
      ref={modalRef}
    >
      <div
        role="presentation"
        $open={open}
        onClick={(e) => e.stopPropagation()}
        css={mobileFullScreenModal}
        data-test={dataTest}
        className={className}
        topScreen={topScreen}
      >
        {onBack && <BackButton onClick={onBack} css={backButtonStyle} />}
        <XButton onClick={onClose} />
        <Header>{headerText}</Header>
        {children}
      </div>
    </div>
  );
};
