// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs, Tab } from "../../components/Navigation/Tabs";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../components/Navigation/Breadcrumbs";
import { NoTaskSetNurtureListView } from "./NoTaskSetNurtureListView";
import { AllNurtureListView } from "./AllNurtureListView";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { ListViewPageWrapper } from "../../components/ListViewSharedStyles";

export const Nurture = () => {
  const { noTaskSetNurture, allNurture } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Sellers</Breadcrumb>
        <Breadcrumb to="#">Leads</Breadcrumb>
        <Breadcrumb to="#">Nurture</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/nurture/no-task-set" count={noTaskSetNurture}>
          No task set
        </Tab>
        <Tab to="/nurture/all" count={allNurture}>
          All
        </Tab>
      </Tabs>
      <Switch>
        <Redirect from="/nurture" to="/nurture/no-task-set" exact />
        <Route
          path="/nurture/no-task-set"
          component={NoTaskSetNurtureListView}
        />
        <Route path="/nurture/all" component={AllNurtureListView} />
      </Switch>
    </ListViewPageWrapper>
  );
};
