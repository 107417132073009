// @flow
import { graphql } from "deal/utils/graphql";

import drawdownPeriodDatesQuery from "./queries/drawdownPeriodDatesQuery.graphql";
import { drawdownPeriodDatesQueryConfig } from "./queries/drawdownPeriodDatesQueryConfig";

import { AgencyPlusSep2018 } from "./AgencyPlusSep2018";
import { AdvanceJul2018 } from "./AdvanceJul2018";
import { AdvanceMay2018 } from "./AdvanceMay2018";
import { Agency } from "./Agency";
import { Guarantee } from "./Guarantee";
import { LegacyGuarantee } from "./LegacyGuarantee";
import { Underwriting } from "./Underwriting";

const dealTypeMapping = {
  agency_plus_sep2018: AgencyPlusSep2018,
  advance_jul2018: AdvanceJul2018,
  advance_may2018: AdvanceMay2018,
  free_no_guarantee: Agency,
  regular_new: Guarantee,
  regular_original: LegacyGuarantee,
  underwrite: Underwriting,
};

type Props = {
  data: DrawdownPeriodDates,
  dealId: string,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
};

const DrawdownPeriodDatesComponent = ({
  data,
  dealId,
  readOnly,
  hideContractSignedDates,
}: Props) => {
  const dealTypeValue = data?.nestDeal?.dealType?.value;
  const Component = dealTypeValue ? dealTypeMapping[dealTypeValue] : null;

  return Component ? (
    <Component
      data-test="drawdown-period-dates"
      dealId={dealId}
      nestDeal={data.nestDeal}
      readOnly={readOnly}
      hideContractSignedDates={hideContractSignedDates}
    />
  ) : null;
};

export const DrawdownPeriodDatesSection = graphql(
  drawdownPeriodDatesQuery,
  drawdownPeriodDatesQueryConfig,
)(DrawdownPeriodDatesComponent);
