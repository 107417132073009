import propertyListingPricesQuery from "../queries/propertyListingPricesQuery.graphql";

export const deleteConfig = {
  props: ({ mutate, ownProps }) => ({
    deletePropertyListingPrice() {
      return mutate({
        variables: {
          id: ownProps.propertyListingPrice.id,
        },
        refetchQueries: [
          {
            query: propertyListingPricesQuery,
            variables: {
              dealId: ownProps.propertyListingPrice.dealId,
            },
          },
        ],
      });
    },
  }),
};
