import moment from "moment";
import * as cookies from "js-cookie";

export const setCookie = (name, value, currentTime = moment.utc()) => {
  cookies.set(name, value, {
    expires: currentTime.add(2, "years").toDate(),
  });
};

export const clearCookie = (name) => cookies.remove(name);

export const getCookie = cookies.get;
