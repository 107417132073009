// @flow
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

const FeesAgencyPlusSep2018 = ({ nestDeal }: PaymentsTabDealDetails) => {
  const dealTypeAgencyPlusSep2018 = nestDeal?.dealTypeAgencyPlusSep2018;

  return (
    <>
      <LeafCell>
        <PercentageField
          property="reservationFee"
          label="Reservation fee %"
          data-test="reservation-fee"
          value={dealTypeAgencyPlusSep2018?.reservationFee}
          readOnly
        />
      </LeafCell>
      <LeafCell>
        <PercentageField
          property="agencyFee"
          label="Agency fee %"
          data-test="agency-fee"
          value={dealTypeAgencyPlusSep2018?.agencyFee}
          readOnly
        />
      </LeafCell>
      <LeafCell>
        <PercentageField
          property="agencyOnlyFee"
          label="List-first fee %"
          data-test="agency-only-fee"
          value={dealTypeAgencyPlusSep2018?.agencyOnlyFee}
          readOnly
        />
      </LeafCell>
      <LeafCell>
        <PercentageField
          property="depositDrawdownFee"
          label="Deposit drawdown fee %"
          data-test="deposit-drawdown-fee"
          value={dealTypeAgencyPlusSep2018?.depositDrawdownFee}
          readOnly
        />
      </LeafCell>
      <LeafCell>
        <PercentageField
          property="depositDrawdownAllowancePercentage"
          label="Deposit drawdown allowance %"
          data-test="deposit-drawdown-allowance-percentage"
          value={dealTypeAgencyPlusSep2018?.depositDrawdownAllowancePercentage}
          readOnly
        />
      </LeafCell>
      <LeafCell>
        <CurrencyField
          property="referralDiscount"
          label="Referral discount"
          data-test="referral-discount"
          value={dealTypeAgencyPlusSep2018?.referralDiscount}
          readOnly
        />
      </LeafCell>
    </>
  );
};

export { FeesAgencyPlusSep2018 };
