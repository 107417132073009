// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const darkInputColours = css`
  background-color: ${({ theme }) => theme.palette.hague150};
  border: 1px solid
    ${({ theme, valid }) =>
      valid ? theme.palette.hague40 : theme.palette.terracotta150};
  color: white;

  &:focus {
    border: 1px solid
      ${({ theme, valid }) =>
        valid ? theme.palette.blue150 : theme.palette.terracotta500};
  }

  &:disabled {
    color: ${({ theme }) => theme.palette.hague70};
    opacity: 0.5;
  }
`;

const lightInputColours = css`
  background-color: white;
  border: 1px solid
    ${({ theme, valid }) =>
      valid ? theme.palette.hague40 : theme.palette.terracotta150};
  color: ${({ theme }) => theme.palette.hague100};

  &:focus {
    border: 1px solid
      ${({ theme, valid }) =>
        valid ? theme.palette.blue150 : theme.palette.terracotta500};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.hague20};
    opacity: 0.5;
  }
`;

export const inputStyle = css`
  height: 36px;
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 10px;
  padding-left: ${({ icon }) => (icon ? "30px" : "10px")};
  outline: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  ${({ dark }) => (dark ? darkInputColours : lightInputColours)};
`;

export const labelStyle = css`
  display: block;
  position: relative;
  width: 100%;
  color: ${({ dark, theme }) => (dark ? "white" : theme.palette.hague100)};

  ${({ valid }) =>
    !valid &&
    css`
      color: ${({ theme }) => theme.palette.terracotta150};
    `}

  font-size: 14px;
  font-weight: 500;
`;

const iconStyle = css`
  height: 36px;
  position: absolute;
  bottom: 0;
  left: 10px;
  color: ${({ theme }) => theme.palette.hague70};
`;

const rightIconStyle = css`
  ${iconStyle}
  left: unset;
  right: 10px;
`;

export const optionalLabelStyle = css`
  float: right;
  color: ${({ dark, theme }) => (dark ? "white" : theme.palette.hague40)};
`;

export type Props = {
  className?: string,
  dark?: boolean,
  disabled?: boolean,
  label?: string,
  loading?: boolean,
  onChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onFocus?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  showOptionalLabel?: boolean,
  type?: string,
  valid?: boolean,
  value: ?string,
  "data-test"?: string,
  forwardedRef?: { current: React$ElementRef<"input"> | null },
  icon?: any,
};

export const NestTextInput = ({
  className,
  dark = false,
  label = null,
  showOptionalLabel = false,
  valid = true,
  "data-test": dataTest = "input-field",
  forwardedRef,
  loading,
  icon,
  ...rest
}: Props) => {
  return (
    <label
      dark={dark}
      className={className}
      css={labelStyle}
      valid={valid}
      data-test={dataTest}
    >
      {(label || showOptionalLabel) && (
        <div css="margin-bottom: 5px;">
          {label}
          {showOptionalLabel && <span css={optionalLabelStyle}>Optional</span>}
        </div>
      )}
      <input
        {...rest}
        ref={forwardedRef}
        dark={dark}
        css={inputStyle}
        data-test={`${dataTest}:input`}
        valid={valid}
        icon={Boolean(icon)}
      />
      {icon && <FontAwesomeIcon css={iconStyle} icon={icon} />}
      {loading && (
        <FontAwesomeIcon css={rightIconStyle} icon={faSpinner} spin />
      )}
    </label>
  );
};
