// @flow
import { useState } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { getAnalyticsMetadata, sendAnalyticsEvent } from "@nested/analytics";
import {
  ONBOARDING_FORM_PERSONAL_SUBMITTED,
  AVM_VISIT_BOOKED,
} from "@nested/analytics/events";
import { Sentry } from "@nested/isomorphic-sentry";
import { ADDRESS_NOT_FOUND_ID } from "@nested/brand";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTemporaryToken } from "@nested/auth";
import {
  type State,
  formStateUpdateAction,
} from "../components/MultiStageForm/ducks";
import { cleanupAndRedirectToAccount } from "./FinishingUpForm";

export const CREATE_VENDOR_MUTATION = gql`
  mutation onboardingFormCustomerOnboardingMutation(
    $input: CustomerOnboardingInput!
    $meta: VendorOnboardingMetaInput
  ) {
    customerOnboarding(input: $input, meta: $meta) {
      successful
      failureReason
      dealId
      temporaryToken
    }
  }
`;

export const calculateLeadScore = (state: State) => {
  if (state.customerIntent === "ALREADY_ON_THE_MARKET") {
    return "THREE";
  }
  if (state.urgency === "SIX_MONTHS_PLUS") {
    return "ONE";
  }
  if (!state.urgency && state.customerIntent === "JUST_CURIOUS") {
    return "ONE";
  }
  return "TWO";
};

const getFirstAndLastName = (name: string) => {
  const [firstName, ...rest] = name.split(" ");
  const lastName = rest.join(" ");
  return { firstName, lastName };
};

export const extractName = ({ contactDetails, name }: State) => {
  const fullName = contactDetails
    ? getFirstAndLastName(contactDetails?.name)
    : name;
  return {
    firstName: fullName?.firstName,
    lastName: fullName?.lastName,
  };
};

type Options = {|
  leadScore?: (State) => string,
  redirectToAccount?: boolean,
|};

export const usePersonalInformationOnSubmit = ({
  leadScore = calculateLeadScore,
  redirectToAccount = false,
}: Options = {}) => {
  const [errorMessage, setErrorMessage] = useState<?React$Node>();
  const [createVendor] = useMutation(CREATE_VENDOR_MUTATION);
  const history = useHistory();
  const dispatch = useDispatch();
  const { saveTemporaryToken } = useTemporaryToken();

  const updateFormState = (payload: $Shape<State>) =>
    dispatch(formStateUpdateAction(payload));

  const onSubmit = async (
    state: State,
    nextPage: string = "/get-started/finishing-up",
  ) => {
    const score = leadScore(state);
    const { firstName, lastName } = extractName(state);

    sendAnalyticsEvent({
      event: ONBOARDING_FORM_PERSONAL_SUBMITTED,
    });
    if (state.avmValuationId) {
      sendAnalyticsEvent({
        event: AVM_VISIT_BOOKED,
      });
    }

    let manualAddress;
    if (state.address?.id === ADDRESS_NOT_FOUND_ID) {
      manualAddress = {
        lineOne: state.manualAddress?.lineOne,
        lineTwo: state.manualAddress?.lineTwo,
        city: state.manualAddress?.city,
        postcode: state.manualAddress?.postcode,
      };
    }

    try {
      const response = await createVendor({
        variables: {
          input: {
            allowOnboardingUpdate: true,
            customer: {
              avmValuationId: state.avmValuationId,
              customerIntent: state.customerIntent,
              email: state.contactDetails?.email || state.email?.email,
              firstName,
              lastName,
              leadScore: score,
              originUrl: "https://nested.com",
              privacyPolicyConsent: state.contactDetails
                ? state.contactDetails?.privacyPolicyConsent
                : state.email?.privacyPolicyConsent,
              reasonForContact: state.reasonForContact,
              telephone: state.contactDetails?.phone || state.phone,
              urgency: state.urgency,
            },
            property: {
              manualAddress,
              addressId: state.address?.id,
              postcode: state.address?.postcode,
              recentlyMarketed:
                state.customerIntent === "ALREADY_ON_THE_MARKET",
              propertyType: state.propertyDetails?.propertyType,
              bedrooms: state.propertyDetails?.bedrooms,
            },
          },
          meta: getAnalyticsMetadata(),
        },
      });
      const data = response?.data?.customerOnboarding;
      if (!data) {
        throw new Error("No data returned by graphql");
      }

      const { successful, failureReason, dealId, temporaryToken } = data;

      if (successful) {
        if (temporaryToken) saveTemporaryToken(temporaryToken);

        // If a deal wasn't created we skip the rest of the form and go straight into the account
        if (!dealId || redirectToAccount) {
          await cleanupAndRedirectToAccount(state, leadScore);
          return;
        }

        updateFormState({ dealId });
        history.push(nextPage);
        return;
      }

      if (failureReason === "EMAIL_INVALID") {
        setErrorMessage(
          <>
            The email address you have submitted is not valid. Please{" "}
            <Link
              data-test="onboarding-form:back-to-email-button"
              onClick={(e) => {
                e.preventDefault();
                setErrorMessage();
                history.push(`/get-started/email`);
              }}
              to="/"
            >
              click here
            </Link>{" "}
            to set a new one
          </>,
        );
        return;
      }

      throw new Error(failureReason);
    } catch (e) {
      Sentry.captureException(e);
      setErrorMessage(
        "It looks like there was an error, please try again. If the problem persists please give us a call on 020 3858 0695.",
      );
    }
  };

  return {
    onSubmit,
    errorMessage,
    setErrorMessage,
  };
};
