// @flow
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { addCurrentStatusIfNotInList } from "@nest-ui/sellers-nest/deal/components/LeftNav/DealSummary/OpportunityStatusSelect";
import { Select } from "../../components/Select/Select";

export const UPDATE_OPPORTUNITY_STATUS = gql`
  mutation UpdateOpportunityStatus($id: ID!, $value: String!) {
    updateDealOpportunityStatus(id: $id, value: $value) {
      id
      opportunityStatus {
        label
        value
        valueText
      }
    }
  }
`;

export const OPPORUTUNITY_STATUS_SELECT_QUERY = gql`
  query OpportunityStatusSelect($id: ID!) {
    nestDeal(id: $id) {
      id
      opportunityStatus {
        label
        value
        valueText
      }
    }

    opportunityStatuses {
      label
      value
      valueText
    }
  }
`;

type Props = {
  idOrExternalId: string,
  labelAbove?: boolean,
};

export const OpportunityStatusSelect = ({
  idOrExternalId,
  labelAbove = false,
}: Props) => {
  const { data } = useQuery(OPPORUTUNITY_STATUS_SELECT_QUERY, {
    variables: {
      id: idOrExternalId,
    },
  });

  const [updateOpportunityStatus] = useMutation(UPDATE_OPPORTUNITY_STATUS);

  const { id, opportunityStatus } = data?.nestDeal || {};

  const statusesIncludingLegacy = addCurrentStatusIfNotInList(
    opportunityStatus,
    data?.opportunityStatuses || [],
  );

  const opportunityStatuses = statusesIncludingLegacy.reduce((acc, status) => {
    // Disable the following statuses because these are now achieved through the
    // milestones in the progression tab.
    const option = ["Under Offer", "Exchanged", "Completed"].includes(
      status.label,
    )
      ? { ...status, disabled: true }
      : status;

    return acc.concat(option);
  }, []);

  return (
    <Select
      css="margin-top: 0px; min-width: 240px;"
      data-test="opportunity-status-select"
      options={opportunityStatuses}
      value={opportunityStatus?.valueText}
      onChange={async (input) => {
        try {
          const newStatus = opportunityStatuses.find(
            ({ value }) => value === input,
          );

          if (!newStatus) {
            return;
          }

          await updateOpportunityStatus({
            variables: {
              id,
              value: input,
            },
            optimisticResponse: {
              updateDealOpportunityStatus: {
                __typename: "NestDeal",
                id,
                opportunityStatus: {
                  __typename: "OpportunityStatus",
                  label: newStatus.label,
                  value: newStatus.value,
                  valueText: newStatus.value,
                },
              },
            },
          });
        } catch (e) {
          // eslint-disable-next-line
          window.alert(e.message);
        }
      }}
      label="Status"
      labelAbove={labelAbove}
    />
  );
};
