// @flow
import styled, { css } from "styled-components";
import moment from "moment";
import { Citymapper, GoogleMaps, Waze } from "@nest-ui/icons";
import { formatNumberAsCurrency } from "@nest-ui/hocs";
import { getConfig } from "@nested/config";

const wrapperStyle = css`
  padding: 0 15px;
`;

const viewingTimeAndAddress = css`
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
`;

const listingPrice = css`
  font-weight: 600;
`;

const divider = css`
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 0;
  height: 8px;
  margin: 0 5px;
`;

const linkGroupStyles = css`
  margin-top: 16px;

  a:not(:last-child)::after {
    content: " ";
    ${divider}
  }
`;

const textGroupStyles = css`
  margin-top: 8px;

  span:not(:last-child)::after {
    content: " ";
    ${divider}
  }
`;

const Icon = styled.div`
  svg {
    width: 24px;
    height: 24px;
    position: relative;
    bottom: 2px;
    margin-left: clamp(4px, 2vw, 12px);
  }
`;

const Link = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener">
    {children}
  </a>
);

const cityMapperUrl = (lat, lng) =>
  new URL(
    `directions?endcoord=${lat},${lng}`,
    "https://citymapper.com",
  ).toString();

const googleMapsUrl = (lat, lng) =>
  new URL(
    `maps/dir/?api=1&destination=${lat},${lng}`,
    "https://google.com",
  ).toString();

const wazeUrl = (lat, lng) =>
  new URL(`ul?ll=${lat},${lng}&navigate=yes`, "https://waze.com").toString();

const formatOffers = (numberOfOffers) => {
  switch (numberOfOffers) {
    case 0:
      return "No offers yet";
    case 1:
      return "1 offer";
    default:
      return `${numberOfOffers} offers`;
  }
};

const formatStartTime = ({ datetimeViewingStarts }) => {
  return moment(datetimeViewingStarts).format("h:mma");
};

const TransportLinks = ({ lat, lng }) => {
  return (
    <div id="linksdiv" css="display: flex;">
      <Link href={cityMapperUrl(lat, lng)}>
        <Icon>
          <Citymapper />
        </Icon>
      </Link>
      <Link href={googleMapsUrl(lat, lng)}>
        <Icon>
          <GoogleMaps />
        </Icon>
      </Link>
      <Link href={wazeUrl(lat, lng)}>
        <Icon>
          <Waze />
        </Icon>
      </Link>
    </div>
  );
};
type props = {
  deal: DealsWithViewingsToday_dealsWithViewingsToday,
};

export const DealSummary = ({ deal }: props) => {
  const {
    id,
    address,
    buyerOffers,
    currentListPrice,
    externalId,
    nestedListing,
    property,
    propertySummary,
    tenureSummary,
    timeOnMarket,
    viewings,
    todaysViewings,
  } = deal;

  const { lat, lng } = property;

  const propertyAddress = address || "No address for this property";

  const { ACCOUNT_URI } = getConfig();
  const rightmoveUrl = nestedListing || null;
  const infoUrl = `/deals/${id}/property-info`;
  const nestUrl = `/deals/${id}/listing`;
  const interestUrl = `${ACCOUNT_URI}/listing/interest/performance?deal=${externalId}`;

  return (
    <div css={wrapperStyle}>
      <div css="display: flex; justify-content: space-between;">
        <div
          css={viewingTimeAndAddress}
          data-test="my-viewings:summary-heading"
          className="fs-exclude"
        >
          {todaysViewings &&
            `${formatStartTime(todaysViewings[0])} - ${propertyAddress}`}
        </div>
        {lat && lng && <TransportLinks lat={lat} lng={lng} />}
      </div>

      <div css={textGroupStyles} data-test={`my-viewings:summary-part-1-${id}`}>
        <span css={listingPrice}>
          {formatNumberAsCurrency(currentListPrice)}
        </span>{" "}
        <span>{propertySummary}</span>
        <span>{tenureSummary || "No tenure info"}</span>
      </div>
      <div css={textGroupStyles} data-test={`my-viewings:summary-part-2-${id}`}>
        <span>{timeOnMarket}</span>
        <span>{`${viewings.length} viewings`}</span>
        <span>{formatOffers(buyerOffers.length)}</span>
      </div>
      <div css={linkGroupStyles}>
        {rightmoveUrl && <Link href={rightmoveUrl}>Rightmove</Link>}
        <Link href={infoUrl}>Info</Link>
        <Link href={nestUrl}>Nest</Link>
        <Link href={interestUrl}>Interest</Link>
      </div>
    </div>
  );
};
