import T from "prop-types";
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

const FeesGuarantee = ({ nestDeal: { dealTypeGuarantee } }) => (
  <>
    <LeafCell>
      <PercentageField
        property="baseFee"
        label="Base fee %"
        value={dealTypeGuarantee.baseFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="incentiveFee"
        label="Incentive fee %"
        value={dealTypeGuarantee.incentiveFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="noDrawDiscount"
        label="No draw discount %"
        value={dealTypeGuarantee.noDrawDiscount}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeePerDay"
        label="Early payment fee % (per day)"
        value={dealTypeGuarantee.earlyPaymentFeePerDay}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeeFreePercent"
        label="Early payment fee free %"
        value={dealTypeGuarantee.earlyPaymentFeeFreePercent}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        property="referralDiscount"
        label="Referral discount"
        value={dealTypeGuarantee.referralDiscount}
        readOnly
      />
    </LeafCell>
  </>
);

FeesGuarantee.propTypes = {
  nestDeal: T.object,
};

export { FeesGuarantee };
