// @flow
import styled, { css } from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";
import { device } from "@nest-ui/styles";
import { getConfig } from "@nested/config";
import { NoSubmitCheckbox } from "@nest-ui/sellers-nest/components/Checkbox/Checkbox";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@nested/nest/src/components/Button/Button";
import { Card } from "@nest-ui/sellers-nest/components/Card";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { CREATE_BUYER_PROPERTY_INTEREST } from "../AddInterest/AddInterest";
import { PROPERTY_INTERESTS_QUERY } from "../PropertyInterests";
import { Link } from "../BuyerPropertyInterestCard/PropertySummary";

const { ACCOUNT_URI } = getConfig();

const wrapper = css`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const propertyPhotoContainerStyles = css`
  height: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ url }) => url},
    url("https://nested.imgix.net/missingProperty.svg") center center no-repeat,
    #e1f4ef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  width: 238px;
  margin-bottom: 16px;
  @media ${device.desktop} {
    border-top-right-radius: 0;
  }
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

const propertyInfoStyles = css`
  color: black;
  margin-bottom: 16px;
  padding: 0;
  white-space: pre-wrap;
  width: 100%;
  @media ${device.tablet} {
    padding: 0 24px 24px 15px;
    margin-bottom: 0;
  }
`;

const divider = css`
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  width: 0;
  height: 14px;
  margin: 2px 5px;
`;

const linkGroupStyles = css`
  a:not(:last-child)::after {
    content: " ";
    ${divider};
  }
`;
const Paragraph = styled.p`
  margin: 0;
  font-weight: 600;
  width: 170px;
  @media ${device.tablet} {
    width: 100%;
  }
`;

type Props = {
  checkboxChangeCallback: () => void,
  checkboxValue: boolean,
  buyerId: string | number,
  potentialProperty: PropertyMatchSummary_buyer_potentialProperties,
  closeModal: () => void,
  isLastProperty: boolean,
};

export const SimilarPropertyCard = ({
  potentialProperty: {
    id: dealId,
    externalId,
    address,
    nestedListing,
    saExtendedSummary,
  },
  checkboxValue,
  checkboxChangeCallback,
  buyerId,
  closeModal,
  isLastProperty,
}: Props) => {
  const nestDealURL = `/deals/${dealId}/listing`;
  const interestUrl = `${ACCOUNT_URI}/listing/interest?deal=${externalId}`;
  const propertyInfoUrl = `/deals/${dealId}/property-info`;

  const [addBPI, { loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_BUYER_PROPERTY_INTEREST, {
      refetchQueries: [
        { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
      ],
    });

  return (
    <Card>
      <div css={wrapper}>
        <Link href={nestDealURL}>
          <div
            css={propertyPhotoContainerStyles}
            url={`url(https://nested.imgix.net/property-listings/${externalId}/1?auto=compress,format&w=240&height=136&fit=crop)`}
          />
        </Link>
        <div css={propertyInfoStyles}>
          <Paragraph>{address}</Paragraph>
          <div
            css={css`
              margin-top: 16px;
              ${linkGroupStyles}
            `}
          >
            <Link href={nestedListing}>Rightmove</Link>
            <Link href={propertyInfoUrl}>Prop info</Link>
            <Link href={nestDealURL}>Nest</Link>
          </div>

          <div css={linkGroupStyles}>
            <Link href={interestUrl}>Interest</Link>
          </div>
          <div css="margin-top: 16px">{saExtendedSummary}</div>
        </div>
        <div>
          <Button
            css="margin:0 0 12px 0; width: 140px;"
            onClick={async () => {
              try {
                const result = await addBPI({
                  variables: { input: { buyerId, dealId } },
                });
                if (result?.data?.createBuyerPropertyInterest?.id) {
                  if (isLastProperty) closeModal();
                } else {
                  errorHandler(mutationError);
                  return null;
                }
              } catch (e) {
                errorHandler(e);
              }
              return null;
            }}
            disabled={mutationLoading}
            data-test={`add-interest-${dealId}`}
            icon={faPlus}
            iconPosition="left"
          >
            Add interest
          </Button>
          <div css="width: 140px" data-test="select-property-checkbox">
            <NoSubmitCheckbox
              optionName="Send to buyer"
              value={checkboxValue}
              parentId={dealId}
              onSubmit={() => checkboxChangeCallback()}
              data-test={`select-property-checkbox-${dealId}`}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
