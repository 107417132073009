// @flow
import { Children, cloneElement } from "react";
import { pick } from "ramda";
import styled from "styled-components";
import { CollapsibleList } from "components/CollapsibleList/CollapsibleList";
import { ListEntry } from "components/ListEntry";
import { LeafCell } from "components/Grid";
import {
  LocalStorageEngine,
  LocalCache,
} from "components/LocalCache/LocalCache";

const FlexListEntry = styled(ListEntry)`
  display: flex;
  flex-direction: column;
`;

const Divider = styled.section`
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: ${({ theme }) => `2px solid ${theme.color.secondary.highlight};`};
`;

const createLabel = (count: number) => {
  switch (count) {
    case 1:
      return "1 older advance -";
    default:
      return `${count} older advances -`;
  }
};

export const AdvancesSectionList = ({
  advanceValuationDropdownOptions,
  advanceInPrinciples,
  dealId,
  advancesSectionQuery,
  dealTypeDetails,
  contractVersions,
  children,
}: *) => (
  <CollapsibleList
    createLabel={createLabel}
    data-test="advances:list"
    items={advanceInPrinciples.map((advanceInPrinciple) => {
      const { advanceOffer } = advanceInPrinciple;
      const advanceDrawdownRequests = advanceInPrinciple.advanceOffer
        ? advanceInPrinciple.advanceOffer.advanceDrawdownRequests
        : null;
      const advanceDrawdownRequest = advanceDrawdownRequests
        ? advanceDrawdownRequests[0]
        : null;
      const feeBreakdown = () => {
        if (advanceDrawdownRequest) {
          return pick(
            [
              "maximumNetAmount",
              "advanceFeeCapAmount",
              "agencyFeeAmount",
              "facilityFeeAmount",
              "feeAdjustmentAmount",
              "totalFees",
              "netAmount",
            ],
            advanceDrawdownRequest,
          );
        }
        return null;
      };

      return (
        <FlexListEntry key={`${advanceInPrinciple.id}`}>
          <LeafCell width={4}>
            <LocalCache
              defaultState={{ advanceInPrinciple }}
              storageEngine={LocalStorageEngine}
              cacheKey={`editAdvanceInPrinciple:${dealId}:${advanceInPrinciple.id}`}
              render={({ cachedState, updateCache, clearCache, hasState }) =>
                Children.map(children, (child) => {
                  switch (child.type.displayName) {
                    case "AdvanceInPrinciple":
                      return cloneElement(child, {
                        advancesSectionQuery,
                        advanceInPrinciple: cachedState.advanceInPrinciple,
                        updateState: updateCache,
                        hasState,
                        advanceValuationDropdownOptions,
                        onCancel: clearCache,
                        onSubmit: clearCache,
                        dealId,
                      });
                    default:
                      return null;
                  }
                })
              }
            />
          </LeafCell>
          <LeafCell width={4}>
            <Divider />
          </LeafCell>
          <LeafCell width={4}>
            <LocalCache
              defaultState={{
                advanceOffer: {
                  advanceInPrincipleId: advanceInPrinciple.id,
                  ...advanceInPrinciple?.advanceOffer,
                },
              }}
              storageEngine={LocalStorageEngine}
              cacheKey={`editAdvanceOffer:${dealId}:${advanceInPrinciple.id}`}
              render={({ cachedState, updateCache, clearCache, hasState }) =>
                Children.map(children, (child) => {
                  switch (child.type.displayName) {
                    case "AdvanceOffer":
                      return cloneElement(child, {
                        advancesSectionQuery,
                        advanceOffer: cachedState.advanceOffer,
                        updateState: updateCache,
                        advanceInPrincipleId: advanceInPrinciple.id,
                        hasState,
                        contractVersions,
                        onCancel: clearCache,
                        onSubmit: clearCache,
                        dealId,
                      });
                    default:
                      return null;
                  }
                })
              }
            />
          </LeafCell>

          {advanceOffer && (
            <>
              <LeafCell width={4}>
                <Divider />
              </LeafCell>
              <LeafCell width={4}>
                <LocalCache
                  defaultState={{
                    advanceOffer,
                    feeBreakdown: feeBreakdown(),
                  }}
                  storageEngine={LocalStorageEngine}
                  cacheKey={`AdvanceDrawdownRequest:${dealId}:${advanceInPrinciple.id}`}
                  render={({
                    cachedState,
                    updateCache,
                    clearCache,
                    hasState,
                  }) =>
                    Children.map(children, (child) => {
                      switch (child.type.displayName) {
                        case "AdvanceDrawdownRequest":
                          return cloneElement(child, {
                            advanceOffer: cachedState.advanceOffer,
                            feeBreakdown: cachedState.feeBreakdown,
                            updateState: updateCache,
                            hasState,
                            onCancel: clearCache,
                            onSubmit: clearCache,
                            dealId,
                            dealTypeDetails,
                            advancesSectionQuery,
                          });
                        default:
                          return null;
                      }
                    })
                  }
                />
              </LeafCell>
            </>
          )}

          {advanceDrawdownRequest && (
            <>
              <LeafCell width={4}>
                <Divider />
              </LeafCell>
              <LeafCell width={4}>
                {Children.map(children, (child) => {
                  switch (child.type.displayName) {
                    case "LegalServicesSection":
                      return cloneElement(child, {
                        advanceDrawdownRequest,
                        refetchQueries: [
                          {
                            query: advancesSectionQuery,
                            variables: { dealId },
                          },
                        ],
                      });
                    default:
                      return null;
                  }
                })}
              </LeafCell>
            </>
          )}
          {advanceDrawdownRequest?.drawdownProjectedDateConfirmed && (
            <>
              <LeafCell width={4}>
                <Divider />
              </LeafCell>
              <LeafCell width={4}>
                {Children.map(children, (child) => {
                  switch (child.type.displayName) {
                    case "AdvancePayment":
                      return cloneElement(child, {
                        advanceDrawdownRequest,
                        advanceOfferId: advanceOffer.id,
                        refetchQueries: [
                          {
                            query: advancesSectionQuery,
                            variables: { dealId },
                          },
                        ],
                      });
                    default:
                      return null;
                  }
                })}
              </LeafCell>
            </>
          )}
        </FlexListEntry>
      );
    })}
  />
);
// We set the display name so that we can still switch on the component name in prod builds
AdvancesSectionList.displayName = "AdvancesSectionList";
