import debtFundingCommitmentQuery from "../queries/debtFundingCommitments.graphql";

export const deleteConfig = {
  props({ mutate, ownProps: { dealId } }) {
    return {
      deleteDebtFundingCommitment: (debtFundingCommitmentId) => () =>
        mutate({
          variables: {
            id: debtFundingCommitmentId,
          },
          refetchQueries: [
            {
              query: debtFundingCommitmentQuery,
              variables: {
                id: dealId,
              },
            },
          ],
        }),
    };
  },
};
