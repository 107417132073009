// @flow
import { css } from "styled-components";
import { Redirect, Switch } from "react-router-dom";
import { useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { media } from "@nested/brand";
import { Tabs, Tab } from "../../components/Navigation/Tabs";

const DEAL_NAV_QUERY = gql`
  query DealNavQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      customerAccountId
    }
  }
`;

const tabsStyle = css`
  overflow-x: auto;
  overflow-y: hidden;
  background-color: white;
  width: 100vw;
  padding: 0 15px;
  ${media.tablet`
    padding: 0 20px;
    display: block;
    width: 100%;
    overflow: unset;
  `}
`;

type Props = {
  idOrExternalId: string,
  className?: string,
  id?: string,
};

export const DealNav = ({
  className,
  idOrExternalId,
  id = "nav-bar",
}: Props) => {
  const { data } = useQuery(DEAL_NAV_QUERY, {
    variables: { id: idOrExternalId },
  });

  const customerAccountId = data?.nestDeal?.customerAccountId;

  const createPath = (tab) => `/deals/${idOrExternalId}/${tab}`;

  return (
    <>
      <Tabs id={id} className={className} css={tabsStyle}>
        <Tab to={createPath("valuation")}>Valuation</Tab>
        <Tab to={createPath("close")}>Close</Tab>
        <Tab to={createPath("listing")}>Listing</Tab>
        <Tab to={createPath("interest")}>Interest</Tab>
        <Tab to={createPath("progression-info")}>Progression</Tab>
      </Tabs>
      <Switch>
        {customerAccountId && (
          <Redirect
            from="/deals/:externalDealId/buyers-agent"
            to={`/deals/:externalDealId/buyers-agent/${customerAccountId}`}
            exact
          />
        )}
      </Switch>
    </>
  );
};
