// @flow
import { Grid, LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";
import { SelectField } from "components/SelectField";

import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import disinstructionQuery from "./queries/disinstructionQuery.graphql";
import updateDisinstructionMutation from "./mutations/updateDisinstructionMutation.graphql";

type Props = {
  parentId: string,
};

export function Disinstruction({ parentId }: Props) {
  return (
    <ExtendedQuery query={disinstructionQuery} variables={{ id: parentId }}>
      {({
        nestDeal,
        reasonsForDisinstruction,
      }: ExtendedQueryRenderProps<DisinstructionIntroductoryCheckQuery>) => (
        <Mutation mutation={updateDisinstructionMutation}>
          {(
            updateNestDeal: MutationFunction<
              DisinstructionUpdateIntroductoryChecksDealMutation,
              DisinstructionUpdateIntroductoryChecksDealMutationVariables,
            >,
          ) => (
            <Grid columns={4} rows="auto auto">
              <>
                <LeafCell width={1}>
                  <DatePicker
                    label="Date disinstructed"
                    property="dateDisinstructed"
                    value={nestDeal.dateDisinstructed}
                    mutation={mutationConfig(
                      updateNestDeal,
                      nestDeal,
                      parentId,
                      reasonsForDisinstruction,
                    )}
                    data-test="disinstruction-section-date-disinstructed"
                  />
                </LeafCell>
                <LeafCell width={4}>
                  <SelectField
                    options={reasonsForDisinstruction}
                    label="Reason for disinstruction"
                    property="reasonForDisinstruction"
                    mutation={mutationConfig(
                      updateNestDeal,
                      nestDeal,
                      parentId,
                      reasonsForDisinstruction,
                    )}
                    value={nestDeal.reasonForDisinstruction}
                    data-test="disinstruction-section-reason-for-disinstruction"
                  />
                </LeafCell>
              </>
            </Grid>
          )}
        </Mutation>
      )}
    </ExtendedQuery>
  );
}

const mutationConfig =
  (
    mutate: MutationFunction<
      DisinstructionUpdateIntroductoryChecksDealMutation,
      DisinstructionUpdateIntroductoryChecksDealMutationVariables,
    >,
    nestDeal: DisinstructionIntroductoryCheckQuery_nestDeal,
    dealId: string,
    reasonsForDisinstruction: $ReadOnlyArray<DisinstructionIntroductoryCheckQuery_reasonsForDisinstruction>,
  ) =>
  (input) => {
    const reasonForDisinstruction = reasonsForDisinstruction.filter(
      (item) => item.value === input.reasonForDisinstruction,
    )[0];
    return mutate({
      variables: {
        input,
        dealId,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          id: dealId,
          ...nestDeal,
          ...input,
          reasonForDisinstruction,
        },
      },
    });
  };

Disinstruction.displayName = "Disinstruction";
