// @flow
import { gql } from "@apollo/client";
import { kebabCase } from "lodash";
import { type MutationFunction } from "@apollo/client/react/components";

import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const RM_LEASEHOLD_PROPERTIES_QUERY = gql`
  query RmLeaseholdProperties($id: ID!) {
    nestDeal(id: $id) {
      id
      groundRent
      serviceCharge
    }
  }
`;

const RM_LEASEHOLD_PROPERTIES_MUTATION = gql`
  mutation UpdateRmLeaseholdProperties($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      groundRent
      serviceCharge
    }
  }
`;

export function LeaseholdProperties({
  parentId: externalDealId,
}: {|
  parentId: string,
|}) {
  return (
    <ExtendedQuery
      query={RM_LEASEHOLD_PROPERTIES_QUERY}
      variables={{ id: externalDealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<RmLeaseholdProperties>) => (
        <ExtendedMutation mutation={RM_LEASEHOLD_PROPERTIES_MUTATION}>
          {(
            updateRmLeaseholdProperties: MutationFunction<
              UpdateRmLeaseholdProperties,
              UpdateRmLeaseholdPropertiesVariables,
            >,
          ) => {
            const fieldProps = (key) => ({
              "data-test": `leasehold-properties:${kebabCase(key)}`,
              property: key,
              value: nestDeal[key],
              mutation: (input) =>
                updateRmLeaseholdProperties({
                  variables: {
                    id: nestDeal.id,
                    input,
                  },
                  optimisticResponse: {
                    updateNestDeal: {
                      ...nestDeal,
                      ...input,
                    },
                  },
                }),
            });

            return (
              <Grid columns={2}>
                <LeafCell width={1}>
                  <CurrencyField
                    {...fieldProps("groundRent")}
                    label="Ground rent (pa)"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <CurrencyField
                    {...fieldProps("serviceCharge")}
                    label="Service charge (pa)"
                  />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
}
