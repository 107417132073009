// @flow
import {
  type Address,
  type AddressNotFound,
  ADDRESS_NOT_FOUND_ID,
} from "@nested/brand";
import type { BedroomsValue } from "../../questions/types";

export const UPDATE_FORM_STATE = "src/components/MultiStageForm/UPDATE";
export const RESET_FORM_STATE = "src/components/MultiStageForm/RESET";

type FullName = {|
  firstName: ?string,
  lastName: ?string,
|};

type EmailAndConsent = {|
  email: string,
  privacyPolicyConsent: boolean,
|};

type ContactDetailsAndConsent = {|
  ...EmailAndConsent,
  name: string,
  phone: ?string,
|};

type SourcesAndReferrer = {|
  sources: Source[],
  referrer: string,
|};

type PropertyDetails = {|
  propertyType: string,
  bedrooms: number,
|};

export type ManualAddress = {|
  id: typeof ADDRESS_NOT_FOUND_ID,
  lineOne: string,
  lineTwo: string,
  city: string,
  postcode: string,
|};

export type HurdleChoice = "EXPLORE_MARKET_TRENDS" | "SPEAK_TO_AN_AGENT";

export type State = {
  abTest: ?"A" | "B",
  address: Address | AddressNotFound | null, // eslint-disable-line
  agentFullName: ?string,
  avmValuationId: ?string,
  bedrooms: ?BedroomsValue,
  bookingDate: ?string,
  customerIntent: ?CustomerIntentValue,
  calendly: ?boolean,
  calendlyBookingLink: ?string,
  calendlyOnboarding: boolean,
  calendlyCallback: boolean,
  dealId: ?string,
  email: ?EmailAndConsent,
  expectedSalePrice: ?number,
  hurdleChoice: ?HurdleChoice,
  listings: ?string,
  manualAddress: ?ManualAddress,
  name: ?FullName,
  contactDetails: ?ContactDetailsAndConsent,
  notInServicedAreaNextStep?: string,
  phone: ?string,
  propertyDetails: ?PropertyDetails,
  propertyType: ?PropertyType,
  reasonForContact: ?ReasonForContact,
  sources: ?SourcesAndReferrer,
  tenure: ?Tenure,
  urgency: ?CustomerUrgencyValue,
  valuationIntent: ?CustomerIntentValue,
};

export type FormStateUpdateAction = {|
  type: "src/components/MultiStageForm/UPDATE",
  payload: $Shape<State>,
|};

export type ResetStateAction = {|
  type: "src/components/MultiStageForm/RESET",
|};

type Action = FormStateUpdateAction | ResetStateAction;

export function reducer(state: State = {}, action: Action): State {
  switch (action.type) {
    case RESET_FORM_STATE:
      return {};
    case UPDATE_FORM_STATE:
      // Setting a new address resets the form
      if (
        action.payload.address &&
        action.payload.address.id !== state.address?.id
      ) {
        return {
          ...action.payload,
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const formStateUpdateAction = (
  payload: $Shape<State>,
): FormStateUpdateAction => ({
  type: UPDATE_FORM_STATE,
  payload,
});

export const resetStateAction = (): ResetStateAction => ({
  type: RESET_FORM_STATE,
});
