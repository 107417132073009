import { gql } from "@apollo/client";

const subAgentFields = gql`
  fragment subAgentFragment on SubAgent {
    id
    dealId
    branchName
    contactEmail
    contactName
    contactNumber
    dateInstructed
    disinstructedDate
    feePercentIncVat
    isAgreementSigned
    negotiatorBonus
    notes
    onlineListingDate
    onlineListingUrl
  }
`;

export const SA_SUB_AGENTS_QUERY = gql`
  query SASubAgents($dealId: ID!) {
    subAgents(dealId: $dealId) {
      id
      ...subAgentFragment
    }
  }
  ${subAgentFields}
`;

export const SA_SUB_AGENT_CREATE_MUTATION = gql`
  mutation CreateSASubAgent($input: DealIdInput!) {
    createSubAgent(input: $input) {
      id
    }
  }
`;

export const SA_SUB_AGENT_UPDATE_MUTATION = gql`
  mutation UpdateSASubAgent($id: ID!, $input: SubAgentInput) {
    updateSubAgent(id: $id, input: $input) {
      id
      ...subAgentFragment
    }
  }
  ${subAgentFields}
`;

export const SA_SUB_AGENT_DELETE_MUTATION = gql`
  mutation DeleteSASubAgent($id: ID!) {
    deleteSubAgent(id: $id) {
      id
    }
  }
`;
