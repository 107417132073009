// @flow
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { Form } from "react-final-form";
import { gql, useApolloClient } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { maxHeightWrapper } from "../../pages/Deal/EditDeal/EditDealOverlay";
import { Button } from "../Button/Button";
import { LinkExistingContact } from "./LinkExistingContact";
import { TabHeader } from "../../pages/Deal/EditDeal/TabHeader";
import { CONTACTS_FRAGMENT } from "./ContactsTab";
import { EmailInputWithValidation } from "../TextInput/EmailInputWithValidation";

export const FIND_CONTACT = gql`
  query FindContactByEmail($email: String!) {
    findContactByEmail(email: $email) {
      id
      ...ContactsFragment
    }
  }

  ${CONTACTS_FRAGMENT}
`;

export const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

export const tabletOverlayStyle = css`
  display: none;
  ${media.tablet`
    ${maxHeightWrapper}
    background: white;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 700px;
  `}
`;

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  color: ${({ theme }) => theme.palette.hague100};
  font-weight: 500;
  height: 53px;
  padding: 22px 20px 18px;
  text-align: center;
  width: 100%;
`;

export const Body = styled.div`
  min-height: 400px;
  height: calc(100% - 53px);
  padding: 20px;
`;

const enterEmailStyle = css`
  display: flex;
  gap: 10px;
  // styles spinning loader
  & svg {
    top: 0;
    right: 6px;
  }
  // styles error message
  span {
    bottom: -40px;
  }
`;

const emailInputStyle = css`
  width: 100%;
  & * {
    height: 42px;
    ${media.tablet`
      height: 36px;
    `}
  }
`;

const nextButtonStyle = css`
  height: 42px;
  width: 63px;

  ${media.tablet`
    height: 36px;
    width: 76px;
  `}
`;

const resultsStyle = css`
  height: calc(100% - 36px);
  padding-top: 14px;

  ${({ $loading }) =>
    $loading &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}
  }
`;

const noEmailStyle = css`
  text-align: center;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 30px;
`;

const noEmailButtonStyle = css`
  color: ${({ theme }) => theme.palette.blue150};
  cursor: pointer;
  border: none;
  background: transparent;
`;

type Props = {
  className?: string,
  dealId?: string,
  buyerId?: string,
  onClose: () => void,
};

export const FindContact = ({ dealId, buyerId, onClose }: Props) => {
  const [existingContact, setExistingContact] = useState();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const goBack = () => history.push("/contacts");
  const emailInputRef = useRef(null);

  useEffect(() => {
    if (emailInputRef) {
      emailInputRef.current?.focus();
    }
  });

  const onSubmit = async ({ email }) => {
    try {
      const result = await apolloClient.query({
        fetchPolicy: "network-only",
        query: FIND_CONTACT,
        variables: {
          email,
        },
      });

      if (result?.data?.findContactByEmail) {
        setExistingContact(result.data.findContactByEmail);
      } else {
        history.push(`/contacts/new?email=${email}`);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <TabHeader
        headerText="Add a contact"
        onClose={onClose}
        onBack={goBack}
        withBackButton
      />
      <Body>
        <Form initialValues={{ email: "" }} onSubmit={onSubmit}>
          {({
            submitting,
            handleSubmit,
            modifiedSinceLastSubmit,
            submitSucceeded,
            valid,
            validating,
          }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div css={enterEmailStyle}>
                  <EmailInputWithValidation
                    placeholder="Enter email"
                    fieldName="email"
                    isOpen
                    css={emailInputStyle}
                    className="fs-exclude"
                    data-test="search-contact-email"
                    ref={emailInputRef}
                  />
                  <Button
                    css={nextButtonStyle}
                    disabled={
                      validating ||
                      !valid ||
                      submitting ||
                      (submitSucceeded && !modifiedSinceLastSubmit)
                    }
                    type="submit"
                  >
                    Next
                  </Button>
                </div>
              </form>

              <div css={resultsStyle} $loading={submitting}>
                {submitting && <FontAwesomeIcon icon={faCircleNotch} spin />}
                {existingContact && (
                  <LinkExistingContact
                    contact={existingContact}
                    dealId={dealId}
                    buyerId={buyerId}
                  />
                )}
              </div>
            </>
          )}
        </Form>
        <div css={noEmailStyle}>
          <button
            aria-label="Add contact without an email"
            css={noEmailButtonStyle}
            data-test="skip-find-email-button"
            onClick={() => history.push("/contacts/new")}
            type="button"
          >
            Add contact without email
          </button>
        </div>
      </Body>
    </>
  );
};
