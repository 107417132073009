// @flow
import { gql } from "@apollo/client";

import type { Node } from "react";
import type { MutationFunction } from "@apollo/client/react/components";

import { DatePicker } from "components/DatePicker";
import { NumberField } from "components/NumberField";
import { SelectField } from "components/SelectField";

import { Grid, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card";
import { H3 } from "components/Heading";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

type ContractCardProps = {|
  children: Array<Node>,
  heading: string,
  readOnly?: boolean,
|};

type ContractVersion = {
  +value: string,
  +label: string,
  +type: string,
};

type Props = {|
  hideContractSignedDates?: boolean,
  nestDeal: {
    dealTypeDetails: DealTypeAgencyPlusSep2018GlobalFragment,
  },
  readOnly?: boolean,
|};

const ContractCard = ({ children, heading, readOnly }: ContractCardProps) =>
  readOnly ? (
    children
  ) : (
    <LeafCell width={4}>
      <ListEntryCard style={{ margin: "0" }}>
        <H3 style={{ marginTop: "0" }}>{heading}</H3>
        <Grid columns={4}>{children}</Grid>
      </ListEntryCard>
    </LeafCell>
  );

const filterContractVersionsByType = (
  contractVersions: $ReadOnlyArray<?ContractVersion>,
  type: string,
): any => {
  if (contractVersions !== null) {
    return contractVersions.filter((c) => c?.type === type);
  }
  return [];
};

export const reverseAlphabetSort = (
  contractVersions: ContractVersion[],
): ContractVersion[] =>
  contractVersions.sort(({ label: a }, { label: b }) => {
    if (a === "Legacy" || b > a) return 1;
    if (b === "Legacy" || a > b) return -1;
    return 0;
  });

const UPDATE_AGENCY_PLUS = gql`
  mutation updateDealTypeAgencyPlusSep2018DrawDown(
    $input: DealTypeAgencyPlusSep2018Input
    $id: ID!
  ) {
    updateDealTypeAgencyPlusSep2018(input: $input, id: $id) {
      id
      advanceContractCustomerSignedDate
      advanceContractNestedSignedDate
      advanceFeePeriodStartDate
      agencyContractCustomerSignedDate
      agencyContractNestedSignedDate
      agencyOnlyContractCustomerSignedDate
      agencyOnlyContractNestedSignedDate
      advanceContractVersionId
      agencyPlusContractVersionId
      coolingOffPeriodDays
      coolingOffPeriodEndDate
      ddPeriodStartDate
      listFirstContractVersionId
      projectedFirstDrawdownDate
    }
  }
`;

const CONTRACT_VERSIONS = gql`
  query ContractVersions {
    contractVersions {
      value: id
      label
      type
    }
  }
`;

export const AgencyPlusSep2018 = ({
  hideContractSignedDates,
  nestDeal: { dealTypeDetails },
  readOnly,
}: Props) => (
  <ExtendedMutation mutation={UPDATE_AGENCY_PLUS}>
    {(
      updateAgencyPlusMutation: MutationFunction<
        updateDealTypeAgencyPlusSep2018DrawDown,
        updateDealTypeAgencyPlusSep2018DrawDownVariables,
      >,
    ) => {
      const updateDealTypeAgencyPlusSep2018 = (input) =>
        updateAgencyPlusMutation({
          variables: { input, id: dealTypeDetails.id },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeAgencyPlusSep2018: {
              __typename: "DealTypeAgencyPlusSep2018",
              ...dealTypeDetails,
              ...input,
            },
          },
        });

      return (
        <>
          {!hideContractSignedDates && (
            <ContractCard heading="LIST-FIRST CONTRACT" readOnly={readOnly}>
              <LeafCell width={1}>
                <DatePicker
                  mutation={updateDealTypeAgencyPlusSep2018}
                  label="List-first Customer Contract Signed Date"
                  value={dealTypeDetails.agencyOnlyContractCustomerSignedDate}
                  property="agencyOnlyContractCustomerSignedDate"
                  data-test="agency-only-contract-customer-signed-date"
                  readOnly={readOnly}
                />
              </LeafCell>
              <LeafCell>
                <DatePicker
                  mutation={updateDealTypeAgencyPlusSep2018}
                  label="List-first Nested Contract Signed Date"
                  value={dealTypeDetails.agencyOnlyContractNestedSignedDate}
                  property="agencyOnlyContractNestedSignedDate"
                  data-test="agency-only-contract-nested-signed-date"
                  readOnly={readOnly}
                />
              </LeafCell>
              <LeafCell width={readOnly ? 1 : 2}>
                <ExtendedQuery query={CONTRACT_VERSIONS}>
                  {({
                    contractVersions,
                  }: ExtendedQueryRenderProps<ContractVersions>) => (
                    <SelectField
                      data-test="agency_plus_sep2018:list_first_contract_version"
                      label={
                        readOnly
                          ? "Contract version"
                          : "Contract version (this should match the contract signed in Juro)"
                      }
                      mutation={updateDealTypeAgencyPlusSep2018}
                      options={filterContractVersionsByType(
                        contractVersions,
                        "list_first",
                      )}
                      property="listFirstContractVersionId"
                      value={dealTypeDetails.listFirstContractVersionId}
                      readOnly={readOnly}
                    />
                  )}
                </ExtendedQuery>
              </LeafCell>
            </ContractCard>
          )}

          {!hideContractSignedDates && (
            <ContractCard heading="AGENCY+ CONTRACT" readOnly={readOnly}>
              <LeafCell width={1}>
                <DatePicker
                  mutation={updateDealTypeAgencyPlusSep2018}
                  label="Agency+ Customer Contract Signed Date"
                  value={dealTypeDetails.agencyContractCustomerSignedDate}
                  property="agencyContractCustomerSignedDate"
                  data-test="agency-contract-customer-signed-date"
                  readOnly={readOnly}
                />
              </LeafCell>
              <LeafCell>
                <DatePicker
                  mutation={updateDealTypeAgencyPlusSep2018}
                  label="Agency+ Nested Contract Signed Date"
                  value={dealTypeDetails.agencyContractNestedSignedDate}
                  property="agencyContractNestedSignedDate"
                  data-test="agency-contract-nested-signed-date"
                  readOnly={readOnly}
                />
              </LeafCell>
              <LeafCell width={readOnly ? 1 : 2}>
                <ExtendedQuery query={CONTRACT_VERSIONS}>
                  {({
                    contractVersions,
                  }: ExtendedQueryRenderProps<ContractVersionsList>) => (
                    <SelectField
                      data-test="agency_plus_sep2018:agency_plus_contract_version"
                      label={
                        readOnly
                          ? "Contract version"
                          : "Contract version (this should match the contract signed in Juro)"
                      }
                      mutation={updateDealTypeAgencyPlusSep2018}
                      options={reverseAlphabetSort(
                        filterContractVersionsByType(
                          contractVersions,
                          "agency_plus",
                        ),
                      )}
                      property="agencyPlusContractVersionId"
                      value={dealTypeDetails.agencyPlusContractVersionId}
                      readOnly={readOnly}
                    />
                  )}
                </ExtendedQuery>
              </LeafCell>
            </ContractCard>
          )}

          <ContractCard heading="ADVANCE CONTRACT" readOnly={readOnly}>
            {!hideContractSignedDates && (
              <LeafCell width={1}>
                <DatePicker
                  mutation={updateDealTypeAgencyPlusSep2018}
                  label="Advance Customer Contract Signed Date"
                  value={dealTypeDetails.advanceContractCustomerSignedDate}
                  property="advanceContractCustomerSignedDate"
                  data-test="advance-contract-customer-signed-date"
                  readOnly={readOnly}
                />
              </LeafCell>
            )}
            <LeafCell>
              <DatePicker
                mutation={updateDealTypeAgencyPlusSep2018}
                label="Advance Nested Contract Signed Date"
                value={dealTypeDetails.advanceContractNestedSignedDate}
                property="advanceContractNestedSignedDate"
                data-test="advance-contract-nested-signed-date"
                readOnly={readOnly}
              />
            </LeafCell>
            <LeafCell width={readOnly ? 1 : 2}>
              <ExtendedQuery query={CONTRACT_VERSIONS}>
                {({
                  contractVersions,
                }: ExtendedQueryRenderProps<ContractVersions>) => (
                  <SelectField
                    data-test="agency_plus_sep2018:advance_contract_version"
                    label={
                      readOnly
                        ? "Contract version"
                        : "Contract version (this should match the contract signed in Juro)"
                    }
                    mutation={updateDealTypeAgencyPlusSep2018}
                    options={reverseAlphabetSort(
                      filterContractVersionsByType(contractVersions, "advance"),
                    )}
                    property="advanceContractVersionId"
                    value={dealTypeDetails.advanceContractVersionId}
                    readOnly={readOnly}
                  />
                )}
              </ExtendedQuery>
            </LeafCell>
          </ContractCard>

          <LeafCell width={1}>
            <DatePicker
              mutation={updateDealTypeAgencyPlusSep2018}
              label="Advance fee period start date"
              value={dealTypeDetails.advanceFeePeriodStartDate}
              property="advanceFeePeriodStartDate"
              data-test="advance-fee-period-start-date"
              readOnly={readOnly}
            />
          </LeafCell>
          <LeafCell width={1}>
            <DatePicker
              mutation={updateDealTypeAgencyPlusSep2018}
              label="DD period start date"
              value={dealTypeDetails.ddPeriodStartDate}
              property="ddPeriodStartDate"
              data-test="dd-period-start-date"
              readOnly={readOnly}
            />
          </LeafCell>
          <LeafCell width={1}>
            <NumberField
              mutation={updateDealTypeAgencyPlusSep2018}
              property="coolingOffPeriodDays"
              label="DD period (days)"
              value={dealTypeDetails.coolingOffPeriodDays}
              data-test="cooling-off-period-days"
              readOnly={readOnly}
            />
          </LeafCell>
          <LeafCell width={1}>
            <DatePicker
              label="Projected DD period end date"
              value={dealTypeDetails.coolingOffPeriodEndDate}
              property="coolingOffPeriodEndDate"
              data-test="cooling-off-period-end-date"
              readOnly
            />
          </LeafCell>

          <LeafCell width={1}>
            <DatePicker
              mutation={updateDealTypeAgencyPlusSep2018}
              label="Projected first drawdown date"
              value={dealTypeDetails.projectedFirstDrawdownDate}
              property="projectedFirstDrawdownDate"
              data-test="projected-first-drawdown-date"
              readOnly={readOnly}
            />
          </LeafCell>
        </>
      );
    }}
  </ExtendedMutation>
);
