import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client/react/hooks";
import { PrimaryButton } from "components/Buttons";

import { ListEntryCard } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { H3 } from "components/Heading";
import { LinkFieldForGrid } from "components/LinkField";
import { SelectField, NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTimePicker } from "components/TimePicker";
import { NoSubmitRadioButtons } from "components/RadioButtons/RadioButtons";
import { NoSubmitDatePicker } from "@nest-ui/sellers-nest/components/DatePicker";
import { media } from "@nested/brand";

import { gql, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { Loader } from "components/Loader";
import { ScheduledValuationStatusCard } from "tabs/Valuation/PostCallChecklist/ScheduledValuationStatusCard";
import { CancelIcon, CirclingArrows, Tick } from "@nest-ui/icons";
import { BaseButton } from "@nest-ui/buyers-nest/buyer/pages/BuyerPage/PropertyInterests/BuyerPropertyInterestCard/Viewings/ViewingCard";
import styled, { css } from "styled-components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Warning } from "components/Warning";
import { TASKS_QUERY } from "@nested/nest/src/components/RightSidePanel/Tasks/TaskPanelContent";
import { ScheduleRmContactButton } from "./ScheduleRmContactButton";

const RescheduleValuationButton = styled(BaseButton)`
  color: #10b186;
  width: 200px;
  padding: 15px 0 0 0;
  margin: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const CancelValuationButton = styled(BaseButton)`
  color: ${({ theme }) => theme.color.background.danger};
  padding: 15px 0 0 0;
  margin: 0;

  svg {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

export const CancelReschedulingButton = styled.button`
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.background.muted};
  color: ${({ theme }) => theme.color.primary.dark};
  padding: 8px;
  min-width: 84px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};

  &:hover {
    background-color: ${({ theme }) => theme.color.background.hoverMuted};
  }
`;

const alignButtonsStyle = css`
  flex-direction: column;

  display: flex;

  justify-content: left;
  ${media.tablet`
    flex-direction: row;

  `}
`;
const formatViewingDateTime = (date, time) => {
  return moment(date)
    .set({
      hour: moment(time).get("hour"),
      minute: moment(time).get("minute"),
    })
    .toISOString();
};

const extractDate = (dateTime) => {
  return moment(dateTime).utc().format("YYYY-MM-DD");
};

const extractTime = (dateTime) => {
  return moment(dateTime).utc().format("HH-mm");
};

export const CREATE_SCHEDULED_VALUATION_MUTATION = gql`
  mutation ScheduleValuation($input: ScheduleValuationInput!) {
    scheduleValuation(input: $input) {
      id
      dealId
      datetime
      type
      googleCalendarEventId
      isCancelled
    }
  }
`;

export const RESCHEDULE_VALUATION_MUTATION = gql`
  mutation rescheduleValuation($input: RescheduleValuationInput!) {
    rescheduleValuation(input: $input) {
      id
      dealId
      datetime
      type
      googleCalendarEventId
      sendCalendarInvite
      isCancelled
      isLegacy
    }
  }
`;

export const CANCEL_VALUATION_MUTATION = gql`
  mutation cancelValuation($dealId: ID!) {
    cancelValuation(dealId: $dealId) {
      id
    }
  }
`;

export const SCHEDULED_VALUATION_QUERY = gql`
  query ScheduledValuation($dealId: ID!) {
    scheduledValuation(dealId: $dealId) {
      id
      dealId
      datetime
      type
      googleCalendarEventId
      sendCalendarInvite
      isCancelled
      isLegacy
    }
  }
`;

const NEST_DEAL_UPDATE_MUTATION = gql`
  mutation UpdateAP($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      assignedRm {
        id
        fullName
      }
    }
  }
`;

export const POST_CALL_CHECK_LIST_QUERY = gql`
  query PostCallChecklist($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      active
      assignedRm {
        id
        fullName
      }
      opportunityStatus {
        label
        value
        valueText
      }
      rmCalendarLink
    }
    activeNestedUsers {
      value: id
      label: fullName
    }
  }
`;

const PROPERTY_VISIT_OPTION = "PROPERTY_VISIT";
const CALL_OPTION = "CALL";

export const PostCallChecklist = ({ dealId }) => {
  const {
    data: postCallCheckListData,
    loading: isPostCallCheckListDataLoading,
  } = useQuery(POST_CALL_CHECK_LIST_QUERY, {
    variables: { id: dealId },
  });

  const {
    data: scheduledValuationData,
    loading: queryingScheduledValuation,
    refetch: refetchScheduledValuation,
  } = useQuery(SCHEDULED_VALUATION_QUERY, {
    variables: { dealId },
  });

  const [scheduleValuation, { loading: schedulingValuation }] = useMutation(
    CREATE_SCHEDULED_VALUATION_MUTATION,
    {
      refetchQueries: [
        {
          query: SCHEDULED_VALUATION_QUERY,
          variables: { dealId },
        },
        {
          query: TASKS_QUERY,
          variables: {
            relationId: postCallCheckListData?.nestDeal?.externalId,
            relationType: "deal",
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const [rescheduleValuation, { loading: reschedulingValuation }] = useMutation(
    RESCHEDULE_VALUATION_MUTATION,
    {
      refetchQueries: [
        { query: SCHEDULED_VALUATION_QUERY, variables: { dealId } },
        {
          query: TASKS_QUERY,
          variables: {
            relationId: postCallCheckListData?.nestDeal?.externalId,
            relationType: "deal",
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const [cancelValuation, { loading: cancellingValuation }] = useMutation(
    CANCEL_VALUATION_MUTATION,
    {
      refetchQueries: [
        { query: SCHEDULED_VALUATION_QUERY, variables: { dealId } },
        {
          query: TASKS_QUERY,
          variables: {
            relationId: postCallCheckListData?.nestDeal?.externalId,
            relationType: "deal",
          },
        },
      ],
      awaitRefetchQueries: true,
    },
  );

  const [updateDeal] = useMutation(NEST_DEAL_UPDATE_MUTATION);

  const { nestDeal, activeNestedUsers } = postCallCheckListData || {};
  const { scheduledValuation } = scheduledValuationData || {};

  const [areaPartnerFirstContactType, setAreaPartnerFirstContactType] =
    useState(scheduledValuation?.type);
  const [valuationDate, setValuationDate] = useState(
    scheduledValuation?.datetime,
  );
  const [valuationTime, setValuationTime] = useState(
    // For some reason, undefined is converted to local time, so this needs
    // to be explicitly set to null
    scheduledValuation?.datetime === undefined
      ? null
      : scheduledValuation?.datetime,
  );
  const [sendCalendarInvite, setSendCalendarInvite] = useState(
    scheduledValuation?.sendCalendarInvite,
  );
  const [showValuationStatusCard, setShowValuationStatusCard] = useState(
    !!scheduledValuation?.id,
  );
  const [isRescheduling, setIsRescheduling] = useState(false);
  const [disableAllButtons, setDisableAllButtons] = useState(false);

  const [isScheduledInThePast, setIsScheduledInThePast] = useState(
    scheduledValuation?.datetime === undefined
      ? false
      : !moment.utc(scheduledValuation?.datetime).isAfter(moment.utc()),
  );

  const areaPartner = nestDeal?.assignedRm && nestDeal?.assignedRm.id;

  const changedFieldValues =
    scheduledValuation?.type !== areaPartnerFirstContactType ||
    extractDate(scheduledValuation?.datetime) !== extractDate(valuationDate) ||
    extractTime(scheduledValuation?.datetime) !== extractTime(valuationTime) ||
    scheduledValuation?.sendCalendarInvite !== sendCalendarInvite;

  useEffect(() => {
    // When `refetchScheduledValuation` is resolved, this component is re-rendered.
    // We want to make sure that `showValuationStatusCard` is set to false
    // when this happens. Otherwise, the rescheduling card disappears and the
    // valuation status card is shown instead, which we don't want!
    if (isRescheduling) {
      setShowValuationStatusCard(false);
    } else {
      setShowValuationStatusCard(!!scheduledValuation?.id);
    }

    setIsScheduledInThePast(
      !!valuationDate && !!valuationTime
        ? !moment
            .utc(formatViewingDateTime(valuationDate, valuationTime))
            .isAfter(moment.utc())
        : false,
    );
  }, [scheduledValuation, valuationTime, valuationDate]);

  if (
    queryingScheduledValuation ||
    isPostCallCheckListDataLoading ||
    schedulingValuation ||
    cancellingValuation ||
    reschedulingValuation
  ) {
    return <Loader />;
  }

  // `googleCalendarEventId` is updated separately by a Google Cloud task.
  // The idea here is that by the time someone clicks on `Reschedule valuation`,
  // the task will have already run successfully.
  if (isRescheduling && scheduledValuation?.googleCalendarEventId === null) {
    refetchScheduledValuation({ dealId });
  }

  const scheduledValuationStatus =
    nestDeal?.opportunityStatus?.valueText === "s01_received_offer_request"
      ? "scheduled"
      : "completed";

  const isCancellationAllowed =
    scheduledValuationStatus !== "completed" && nestDeal?.active;

  const isReschedulingAllowed =
    !scheduledValuation?.isLegacy &&
    scheduledValuationStatus !== "completed" &&
    nestDeal?.active;

  const allMandatoryFieldsHaveBeenFilledIn =
    !!(areaPartnerFirstContactType && valuationDate && valuationTime) &&
    // sendCalendarInvite can be either FALSE or TRUE,
    // but we want to make sure it's neither
    !(sendCalendarInvite === null || sendCalendarInvite === undefined);

  const updateAssignedRm = ({ assignedRmId: newRmId }) => {
    const { label: fullName } =
      activeNestedUsers.find((user) => user.value === newRmId) || {};
    updateDeal({
      variables: {
        id: dealId,
        input: {
          assignedRmId: newRmId && Number(newRmId),
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          __typename: "NestDeal",
          id: dealId,
          assignedRm: {
            __typename: "NestedUser",
            id: newRmId && Number(newRmId),
            fullName,
          },
        },
      },
    });
  };

  const triggerCreateValuation = async () => {
    try {
      const { data: result } = await scheduleValuation({
        variables: {
          input: {
            dealId: nestDeal.id,
            datetime: formatViewingDateTime(valuationDate, valuationTime),
            type: areaPartnerFirstContactType,
            sendCalendarInvite,
          },
        },
      });

      if (result?.scheduleValuation?.id) {
        setShowValuationStatusCard(true);
        setDisableAllButtons(false);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const triggerCancelValuation = async () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      "Are you sure you want to cancel this valuation?",
    );

    if (confirm) {
      setDisableAllButtons(true);
      try {
        const { data: result } = await cancelValuation({
          variables: {
            dealId,
          },
        });

        if (result?.cancelValuation?.id) {
          setAreaPartnerFirstContactType(null);
          setValuationDate(null);
          setValuationTime(null);

          setSendCalendarInvite(null);
          setShowValuationStatusCard(false);
          setDisableAllButtons(false);
        }
      } catch (e) {
        errorHandler(e);
      }
    }
  };
  const triggerRescheduleValuation = async () => {
    try {
      const { data: result } = await rescheduleValuation({
        variables: {
          input: {
            dealId: nestDeal.id,
            datetime: formatViewingDateTime(valuationDate, valuationTime),
            type: areaPartnerFirstContactType,
            sendCalendarInvite,
          },
        },
      });

      if (result?.rescheduleValuation?.id) {
        setDisableAllButtons(false);
        setIsRescheduling(false);
        setShowValuationStatusCard(true);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const valuationOrCallFromFirstContactTypeOption = (
    firstContactTypeOption,
  ) => {
    switch (firstContactTypeOption) {
      case PROPERTY_VISIT_OPTION:
        return "valuation";
      case CALL_OPTION:
        return "call";
      default:
        return "valuation";
    }
  };

  const warningMessage = `We won't contact the seller because this ${valuationOrCallFromFirstContactTypeOption(
    areaPartnerFirstContactType,
  )} is in the past.`;

  return (
    <div>
      <ListEntryCard>
        {!showValuationStatusCard && (
          <H3 style={{ marginTop: "0" }}>Schedule AP Contact</H3>
        )}
        {!showValuationStatusCard && (
          <Grid columns={4} rows="auto auto">
            <LeafCell width={2}>
              <SelectField
                options={activeNestedUsers}
                value={areaPartner}
                mutation={updateAssignedRm}
                data-test="post-call-checklist-nested-relationship-manager"
                property="assignedRmId"
                label="AP"
              />
            </LeafCell>
            <LeafCell width={2}>
              <NoSubmitSelectField
                options={[
                  { label: "Property visit", value: PROPERTY_VISIT_OPTION },
                  { label: "Call", value: CALL_OPTION },
                ]}
                value={areaPartnerFirstContactType}
                onSubmit={setAreaPartnerFirstContactType}
                data-test="post-call-checklist-relationship-manager-first-contact-type"
                label="AP first contact type"
              />
            </LeafCell>
            {areaPartnerFirstContactType && (
              <>
                <LeafCell width={1}>
                  <LinkFieldForGrid
                    label="Find a time"
                    text="Check AP calendar"
                    value={nestDeal.rmCalendarLink}
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitDatePicker
                    value={valuationDate}
                    onSubmit={setValuationDate}
                    label={
                      areaPartnerFirstContactType === PROPERTY_VISIT_OPTION
                        ? "Property visit date"
                        : "AP call date"
                    }
                    data-test="post-call-checklist-no-submit-date-picker"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NoSubmitTimePicker
                    value={valuationTime}
                    onSubmit={setValuationTime}
                    data-test="post-call-checklist-no-submit-time-picker"
                    label={
                      areaPartnerFirstContactType === PROPERTY_VISIT_OPTION
                        ? "Property visit time"
                        : "AP call time"
                    }
                  />
                </LeafCell>
                <LeafCell></LeafCell>
                <LeafCell width={1}>
                  <NoSubmitRadioButtons
                    options={[
                      { label: "No", value: false },
                      { label: "Yes", value: true },
                    ]}
                    data-test="post-call-checklist-send-calendar-invite"
                    label="Send calendar invite to customer?"
                    value={sendCalendarInvite}
                    onSubmit={setSendCalendarInvite}
                    id={"post-call-checklist-send-calendar-invite"}
                  />
                </LeafCell>
                <LeafCell width={4}>
                  <div>
                    {isRescheduling && (
                      <div css={alignButtonsStyle}>
                        <PrimaryButton
                          icon={Tick}
                          onClick={() => {
                            triggerRescheduleValuation(true);
                            setDisableAllButtons(true);
                          }}
                          disabled={
                            !allMandatoryFieldsHaveBeenFilledIn ||
                            disableAllButtons ||
                            !changedFieldValues ||
                            scheduledValuation?.googleCalendarEventId === null
                          }
                        >
                          Reschedule valuation
                        </PrimaryButton>
                        <CancelReschedulingButton
                          onClick={() => {
                            setShowValuationStatusCard(true);
                            setIsRescheduling(false);
                          }}
                        >
                          Cancel
                        </CancelReschedulingButton>
                      </div>
                    )}

                    {!isRescheduling && (
                      <ScheduleRmContactButton
                        data-test="post-call-checklist-create-rm-task-button"
                        scheduleValuationMutation={() => {
                          setDisableAllButtons(true);
                          triggerCreateValuation();
                        }}
                        isValuationScheduledInThePast={isScheduledInThePast}
                        sendCalendarInvite={sendCalendarInvite}
                        disabled={
                          !allMandatoryFieldsHaveBeenFilledIn ||
                          disableAllButtons
                        }
                      />
                    )}
                  </div>
                </LeafCell>
              </>
            )}
          </Grid>
        )}

        {!showValuationStatusCard &&
          isScheduledInThePast &&
          changedFieldValues && <Warning hasTopArrow>{warningMessage}</Warning>}

        {showValuationStatusCard && (
          <div>
            <ScheduledValuationStatusCard
              valuationDatetime={scheduledValuation?.datetime}
              scheduledValuationStatus={scheduledValuationStatus}
              activeNestedUsers={activeNestedUsers}
              areaPartner={areaPartner}
              changeAreaPartnerMutation={updateAssignedRm}
            />
            <div css={alignButtonsStyle}>
              <RescheduleValuationButton
                onClick={() => {
                  setShowValuationStatusCard(false);
                  setIsRescheduling(true);
                  setAreaPartnerFirstContactType(scheduledValuation?.type);
                  setValuationDate(scheduledValuation?.datetime);
                  setValuationTime(scheduledValuation?.datetime);

                  setSendCalendarInvite(scheduledValuation?.sendCalendarInvite);
                }}
                disabled={!isReschedulingAllowed || disableAllButtons}
              >
                <CirclingArrows />
                Reschedule valuation
              </RescheduleValuationButton>
              <CancelValuationButton
                onClick={() => {
                  triggerCancelValuation();
                }}
                disabled={!isCancellationAllowed || disableAllButtons}
              >
                <CancelIcon />
                Cancel valuation
              </CancelValuationButton>
            </div>
          </div>
        )}
      </ListEntryCard>
    </div>
  );
};
