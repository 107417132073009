// @flow
import { useEffect, useState } from "react";
import { css } from "styled-components";
import { gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { Sentry } from "@nested/isomorphic-sentry";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import {
  ContactsWrapper,
  ContactTypeLink,
  noContactStyle,
} from "../Navigation/ContactDrawer/ContactDrawerShared";
import { Drawer } from "../Drawer/Drawer";
import { PlaceholderList } from "../Placeholder";
import { useHandoff } from "./useHandoff";

type DrawerTypeProps = "sms" | "call";

export const REGISTER_ATTEMPTED_CONTACT = gql`
  mutation RegisterAttemptedContact(
    $commsType: CommsType!
    $contactId: ID
    $email: String!
    $relationId: ID!
    $relationType: RelationType!
    $smsBody: String
  ) {
    registerAttemptedContact(
      commsType: $commsType
      contactId: $contactId
      email: $email
      relationId: $relationId
      relationType: $relationType
      smsBody: $smsBody
    ) {
      id
      nestedUserEmail
    }
  }
`;

const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

const mobileIconWrapper = css`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.blue150};
  display: flex;
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  svg {
    color: ${({ theme }) => theme.palette.hague70};
  }
`;

const iconStyle = css`
  font-size: 20px;
  line-height: 30px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  width: 30px;
`;

const getDrawerHeading = (
  drawerType: DrawerTypeProps,
  initiatedByHandoff: boolean,
) => {
  if (drawerType === "call") {
    return initiatedByHandoff ? "Complete call on this phone" : "Call";
  }
  return initiatedByHandoff ? "Complete SMS on this phone" : "SMS";
};

type SmsOrCallLinkProps = {
  phone: string,
  smsBody: ?string,
  drawerType: DrawerTypeProps,
};

export const removeSpaces = (phoneNumber: string) =>
  phoneNumber.replace(/\s+/gim, "");

const getLocation = ({ phone, drawerType, smsBody }: SmsOrCallLinkProps) => {
  const formattedPhone = removeSpaces(phone);
  if (drawerType === "call") return `tel:${formattedPhone}`;

  const smsLink = smsBody
    ? `sms:${formattedPhone};?&body=${encodeURIComponent(smsBody)}`
    : `sms:${formattedPhone}`;

  return smsLink;
};

export const MobileActionSheet = () => {
  const [registerContact] = useMutation(REGISTER_ATTEMPTED_CONTACT);
  const [smsTemplateDrawerOpen, setSmsTemplateDrawerOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState({});

  const {
    relationType,
    relationId,
    commsType,
    isOpen,
    onClose,
    onlyPhoneContact,
    contacts = [],
    loadingContacts,
    errorFetchingContacts,
    smsBody,
    smsTemplateOptions,
    initiatedByHandoff,
    selectedUser,
  } = useHandoff();

  useEffect(() => {
    if (contactInfo.contactId) {
      smsTemplateOptions ? setSmsTemplateDrawerOpen(true) : openOnDevice();
    }
  }, [contactInfo]);

  // Immediately set the chosen contact if only one contact phone available
  useEffect(() => {
    if (isOpen && onlyPhoneContact) {
      const { id, phones } = onlyPhoneContact;
      setContactInfo({ contactId: id, phone: phones[0].telephoneNumber });
    }

    return undefined;
  }, [isOpen]);

  if (!relationType || !relationId) {
    return null;
  }

  const openOnDevice = async (smsTemplate = smsBody) => {
    const { contactId, phone } = contactInfo;
    const link = getLocation({
      phone,
      drawerType: commsType,
      smsBody: smsTemplate || smsBody,
    });
    window.location.assign(link);
    closeDrawers();
    try {
      await registerContact({
        variables: {
          commsType: commsType.toUpperCase(),
          contactId,
          email: selectedUser,
          relationId,
          relationType: relationType.toUpperCase(),
          smsBody: smsTemplate || smsBody || null,
        },
      });
    } catch (e) {
      errorHandler(e);
      Sentry.captureMessage("Failed to register attempted contact", {
        extra: {
          error: JSON.stringify(e),
          user: selectedUser,
          relationId,
          relationType,
          contactId,
        },
      });
    }
  };

  const closeDrawers = () => {
    setSmsTemplateDrawerOpen(false);
    setContactInfo({});
    onClose();
  };

  if (errorFetchingContacts) {
    return (
      <Drawer
        heading={getDrawerHeading(commsType, initiatedByHandoff)}
        closeDrawer={onClose}
        drawerOpen={isOpen && !smsTemplateDrawerOpen && !onlyPhoneContact}
        drawerType={commsType}
        css={mobileOnly}
      >
        <span css="padding: 20px;">
          Something went wrong. Please try refreshing!
        </span>
      </Drawer>
    );
  }

  return (
    <>
      <Drawer
        heading={getDrawerHeading(commsType, initiatedByHandoff)}
        closeDrawer={onClose}
        drawerOpen={isOpen && !smsTemplateDrawerOpen && !onlyPhoneContact}
        drawerType={commsType}
        css={mobileOnly}
      >
        {loadingContacts ? (
          <PlaceholderList
            rows={1}
            css="* { border: none; } padding: 0 20px;"
          />
        ) : (
          contacts.map((contact) => {
            const { id, phones } = contact;
            const contactHasNumbers = phones.length > 0;

            return (
              <ContactsWrapper key={`contact-${id}`} contact={contact}>
                {contactHasNumbers ? (
                  phones.map(({ telephoneNumber }, index) => (
                    <ContactTypeLink
                      key={`contact-${id}-phone-${index}`}
                      className="fs-exclude"
                      onClick={() =>
                        setContactInfo({
                          contactId: id,
                          phone: telephoneNumber,
                        })
                      }
                    >
                      {telephoneNumber}
                    </ContactTypeLink>
                  ))
                ) : (
                  <p css={noContactStyle}>No phone number</p>
                )}
              </ContactsWrapper>
            );
          })
        )}
      </Drawer>
      <Drawer
        heading="Select a template"
        closeDrawer={closeDrawers}
        drawerOpen={smsTemplateDrawerOpen}
        drawerType="sms"
        css={mobileOnly}
      >
        {smsTemplateOptions &&
          smsTemplateOptions.map(({ label, content }, index) => (
            <button
              key={index}
              data-test={`sms-template-${index}`}
              css={mobileIconWrapper}
              onClick={() => openOnDevice(content)}
            >
              <span css={iconStyle}>
                <FontAwesomeIcon icon={faCommentDots} />
              </span>
              {label}
            </button>
          ))}
        <button
          key={"no-template"}
          data-test={`sms-template-blank}`}
          css={mobileIconWrapper}
          onClick={() => openOnDevice()}
        >
          <div css={iconStyle}>
            <FontAwesomeIcon icon={faCommentDots} />
          </div>
          Blank
        </button>
      </Drawer>
    </>
  );
};
