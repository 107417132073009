import T from "prop-types";
import { compose } from "recompose";
import { graphql } from "deal/utils/graphql";

import { Grid, LeafCell } from "components/Grid";
import { AlignRight } from "components/AlignRight";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { ListEntryCard } from "components/Card";
import deleteThirdPartyAppraisalMutation from "./deleteMutation/deleteThirdPartyAppraisal.graphql";
import { deleteConfig } from "./deleteMutation/config";
import updateThirdPartyAppraisalMutation from "./updateMutation/updateThirdPartyAppraisal.graphql";
import { updateConfig } from "./updateMutation/config";

export const ThirdPartyAppraisalComponent = ({
  amount,
  date,
  updateThirdPartyAppraisal,
  deleteThirdPartyAppraisal,
}) => (
  <ListEntryCard data-test="third-party-appraisal">
    <Grid columns={4}>
      <LeafCell width={1}>
        <CurrencyField
          label="Valuation"
          value={amount}
          mutation={updateThirdPartyAppraisal}
          property="amount"
          data-test="third-party-appraisal-amount"
        />
      </LeafCell>
      <LeafCell width={1}>
        <DatePicker
          label="Valuation date"
          value={date}
          mutation={updateThirdPartyAppraisal}
          property="date"
          data-test="third-party-appraisal-date"
        />
      </LeafCell>
      <LeafCell left={4}>
        <AlignRight>
          <DeleteButton
            className="delete-button"
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deleteThirdPartyAppraisal();
              }
            }}
            data-test="third-party-appraisal-delete-button"
          />
        </AlignRight>
      </LeafCell>
    </Grid>
  </ListEntryCard>
);

ThirdPartyAppraisalComponent.propTypes = {
  amount: T.string,
  date: T.string,
  deleteThirdPartyAppraisal: T.func.isRequired,
  updateThirdPartyAppraisal: T.func.isRequired,
};

const enhance = compose(
  graphql(deleteThirdPartyAppraisalMutation, deleteConfig),
  graphql(updateThirdPartyAppraisalMutation, updateConfig),
);

export const ThirdPartyAppraisal = enhance(ThirdPartyAppraisalComponent);
