// @flow
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import styled, { css } from "styled-components";
import { media } from "@nested/brand";
import { Form } from "react-final-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { gql } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Loader } from "components/Loader/Loader";
import { Button } from "@nested/nest/src/components/Button/Button";
import { handleErrors } from "@nest-ui/hocs";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { useNotifications } from "../../../../hooks/useNotifications";
import { SelectBuyers } from "./SelectBuyers";
import { ComposeMessage } from "./ComposeMessage";
import { MAIL_POTENTIAL_BUYERS_QUERY } from "../potentialBuyersQuery";
import {
  COMING_SOON,
  suggestEmailBody,
  suggestEmailSubject,
  createAnonymisedAddress,
} from "./utils";

export const MAILOUT_MODAL_QUERY = gql`
  query MailoutModalQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      externalId
      opportunityStatus {
        valueText
      }
      currentListPrice
      property {
        id
        bedrooms
        bathrooms
        street
        outcode
      }
      houseOrFlat
      rmRecommendedListPrice
      potentialBuyers {
        id
        name
        latestMailoutDatetimeForDeal(dealId: $id)
        mostRecentlyCreatedBpi {
          id
          assignedSa {
            id
            fullName
            email
          }
          deal {
            id
            address
          }
        }
      }
    }
  }
`;

export const SEND_MAILOUT_EMAIL = gql`
  mutation MailoutToSelectedBuyers($input: MailoutToSelectedBuyersInput!) {
    mailoutToSelectedBuyers(input: $input) {
      success
    }
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh);
  ${media.tablet`
    overflow: hidden;
    height: calc(100vh - 96px);
  `}
`;

const chooseBuyersWrapper = css`
  flex-grow: 1;
`;

export const modalHeader = css`
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.color.background.hague20};
  color: white;
  background-color: ${({ theme }) => theme.color.text.hague};
  display: flex;
  height: 50px;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  ${media.tablet`
    color: ${({ theme }) => theme.color.text.hague70};
    background-color: white;
  `}
`;

export const backButton = css`
  color: white;
  position: absolute;
  top: 15px;
  left: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${media.tablet`
    color: ${({ theme }) => theme.color.text.blue150};
  `}
`;

const backIcon = css`
  margin: 2px 5px 0 0;
`;

export const modalFooter = css`
  border-top: solid 1px ${({ theme }) => theme.color.background.hague20};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: white;
  padding: 0 20px;
`;

export const sendMessageButton = css`
  margin: 20px 0;
  ${media.tablet`
    max-width: 310px;
  `}
`;

const COMPOSE_MESSAGE = "compose-message";
const SELECT_BUYERS = "select-buyers";

const createInitialState = (allBuyers, startingValue: boolean) => {
  const state = {};
  allBuyers.forEach(({ id }) => {
    state[id] = startingValue;
  });
  return state;
};

type Props = {
  closeModal: () => void,
  dealId: string,
};

const initialValues = ({
  opportunityStatus,
  houseOrFlat,
  property,
  rmRecommendedListPrice,
}) => {
  if (opportunityStatus && opportunityStatus?.valueText >= "s08_listed") {
    return {
      subject: "",
      circumstance: "",
      customMessage: "",
      selectedBuyerIds: [],
    };
  }

  const anonymisedAddress = createAnonymisedAddress(property);

  return {
    subject: suggestEmailSubject(
      COMING_SOON,
      houseOrFlat,
      property?.bedrooms,
      anonymisedAddress,
    ),
    circumstance: COMING_SOON,
    customMessage: suggestEmailBody(
      COMING_SOON,
      houseOrFlat,
      property?.bedrooms,
      property?.bathrooms,
      anonymisedAddress,
      formatPrice(parseFloat(rmRecommendedListPrice)),
    ),
    selectedBuyerIds: [],
  };
};

const ModalContent = ({
  closeModal,
  deal,
  dealId,
}: Props & { deal: MailoutModalQuery_nestDeal }) => {
  const { potentialBuyers: allBuyers, externalId } = deal;

  const [selectedBuyerState, setSelectedBuyerState] = useState(
    createInitialState(allBuyers, true),
  );

  const [modalView, setModalView] = useState(COMPOSE_MESSAGE);

  // needed to maintain the form values whilst the modalView state is changed
  const [formValues, setFormValues] = useState(null);

  const [sendMailout, { loading: sending }] = useMutation(SEND_MAILOUT_EMAIL, {
    refetchQueries: [
      { query: MAIL_POTENTIAL_BUYERS_QUERY, variables: { id: dealId } },
    ],
  });
  const { createNotification } = useNotifications();

  const toggleBuyerSelect = (id: string) => {
    const selected = selectedBuyerState[id];
    const newState = {
      ...selectedBuyerState,
      [id]: !selected,
    };
    setSelectedBuyerState(newState);
  };

  const setAll = (value: boolean) => {
    const newState = createInitialState(allBuyers, value);
    setSelectedBuyerState(newState);
  };

  const selectedBuyerIds = Object.keys(selectedBuyerState).filter(
    (id) => selectedBuyerState[id],
  );

  const sendMailoutEmail = async ({ subject, customMessage }) => {
    const input = {
      dealId: externalId,
      mailoutBody: customMessage,
      mailoutSubject: subject,
      buyerIds: selectedBuyerIds,
    };

    try {
      const result = await sendMailout({
        variables: {
          input,
        },
      });

      if (result?.data?.mailoutToSelectedBuyers?.success) {
        createNotification("Messages delivered");
        closeModal();
        return;
      }

      if (result?.errors) {
        throw result?.errors;
      }
    } catch (e) {
      handleErrors(e);
    }
  };

  const toggleView = (values, view) => {
    setFormValues(values);
    setModalView(view);
  };

  return (
    <ModalWrapper>
      <Form
        onSubmit={sendMailoutEmail}
        initialValues={formValues || initialValues(deal)}
      >
        {({ handleSubmit, hasValidationErrors, submitting, values }) => {
          return (
            <>
              {modalView === COMPOSE_MESSAGE ? (
                <ComposeMessage
                  onNext={() => toggleView(values, SELECT_BUYERS)}
                  closeModal={closeModal}
                  hasValidationErrors={hasValidationErrors}
                  deal={deal}
                />
              ) : (
                <div css={chooseBuyersWrapper}>
                  <div css={modalHeader}>
                    <button
                      css={backButton}
                      onClick={() => toggleView(values, COMPOSE_MESSAGE)}
                    >
                      <FontAwesomeIcon css={backIcon} icon={faCaretLeft} />
                      Back
                    </button>
                    <div>Choose buyers</div>
                  </div>
                  <SelectBuyers
                    allBuyers={allBuyers}
                    selectedBuyerState={selectedBuyerState}
                    toggleBuyerSelect={toggleBuyerSelect}
                    setAll={setAll}
                    selectedBuyerIds={selectedBuyerIds}
                  />
                  <div css={modalFooter}>
                    <Button
                      buttonStyle="pink"
                      css={sendMessageButton}
                      onClick={handleSubmit}
                      data-test="email-send-button"
                      large
                      disabled={
                        submitting ||
                        sending ||
                        hasValidationErrors ||
                        selectedBuyerIds.length === 0
                      }
                    >
                      {sending
                        ? "Sending..."
                        : `Send SMS & email to ${selectedBuyerIds.length} buyers`}
                    </Button>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Form>
    </ModalWrapper>
  );
};

export const MailoutModal = ({ closeModal, dealId }: Props) => {
  const { data, error, loading } = useQuery(MAILOUT_MODAL_QUERY, {
    variables: { id: dealId },
  });

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ModalContent
      deal={data?.nestDeal}
      closeModal={closeModal}
      dealId={dealId}
    />
  );
};
