// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";
import moment from "moment";
import { useMutation } from "@apollo/client/react/hooks";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid/Grid";
import { AddButton } from "@nest-ui/sellers-nest/components/Buttons";
import { RichTextField } from "@nest-ui/sellers-nest/components/TextField";
import { Checkbox } from "@nest-ui/sellers-nest/components/Checkbox/Checkbox";
import { Label as OriginalLabel } from "@nest-ui/sellers-nest/components/Label/Label";
import { PROPERTY_INTERESTS_QUERY } from "../../PropertyInterests";

const CREATE_FOLLOW_UP_FEEDBACK = gql`
  mutation CreateFollowUpFeedback($input: CreateViewingFollowUpFeedbackInput!) {
    createViewingFollowUpFeedback(input: $input) {
      id
    }
  }
`;

const UPDATE_FOLLOW_UP_FEEDBACK = gql`
  mutation UpdateFollowUpFeedback(
    $id: ID!
    $input: UpdateViewingFollowUpFeedbackInput!
  ) {
    updateViewingFollowUpFeedback(id: $id, input: $input) {
      id
      feedback
    }
  }
`;

const SHARE_FOLLOW_UP_FEEDBACK = gql`
  mutation ShareFollowUpFeedback(
    $id: ID!
    $input: ShareViewingFollowUpFeedbackWithSellerInput!
  ) {
    shareViewingFollowUpFeedbackWithSeller(id: $id, input: $input) {
      id
      datetimeSharedWithSeller
    }
  }
`;

const Label = styled(OriginalLabel)`
  margin-top: 20px;
`;

const prefillLinks = [
  {
    label: "Still considering",
    content:
      "The buyer is not ready to commit to an offer at this stage. In our experience, if they’re not ready to make an offer or view the property again, they are less likely to proceed. We’ll keep talking to them until they have made a firm decision.",
  },
  {
    label: "Not proceeding",
    content:
      "<h3>The buyer has decided not to proceed because:</h3><p></p><h3>Thoughts on price:</h3><p>&#8205;</p>",
  },
  {
    label: "No contact",
    content:
      "<p>We did our best to contact the buyer for feedback but didn’t hear back. We’ll let you know if they get back to us but we’re assuming they’re not interested.</p>",
  },
];

const Feedback = ({ feedback }) => {
  const [update] = useMutation(UPDATE_FOLLOW_UP_FEEDBACK);
  const [share] = useMutation(SHARE_FOLLOW_UP_FEEDBACK);

  const displayDate = feedback.datetimeSharedWithSeller
    ? moment(feedback.datetimeSharedWithSeller).format("D MMM")
    : moment().format("D MMM");

  return (
    <>
      <Label data-test={`viewings:follow-up-feedback:label-${feedback.id}`}>
        Update: {displayDate}
      </Label>
      <Grid columns={6}>
        <LeafCell width={5}>
          <RichTextField
            data-test={`viewings:follow-up-feedback:edit-${feedback.id}`}
            onSubmit={(value) => {
              update({
                variables: {
                  id: feedback.id,
                  input: {
                    feedback: value,
                  },
                },
                optimisticResponse: {
                  updateViewingFollowUpFeedback: {
                    __typename: "ViewingFollowUpFeedback",
                    ...feedback,
                    feedback: value,
                  },
                },
              });
            }}
            prefillLinks={prefillLinks}
            disableLinks={Boolean(feedback.datetimeSharedWithSeller)}
            value={feedback.feedback || ""}
          />
        </LeafCell>
        <LeafCell width={1} left={6}>
          <Checkbox
            data-test={`viewings:follow-up-feedback:share-${feedback.id}`}
            mutation={(input) => {
              share({
                variables: {
                  id: feedback.id,
                  input,
                },
                optimisticResponse: {
                  shareViewingFollowUpFeedbackWithSeller: {
                    __typename: "ViewingFollowUpFeedback",
                    ...feedback,
                    datetimeSharedWithSeller: moment().toISOString(),
                  },
                },
              });
            }}
            greenMode
            optionName={feedback.datetimeSharedWithSeller ? "Shared!" : "Share"}
            property="shareWithSeller"
            parentId={feedback.id}
            value={Boolean(feedback.datetimeSharedWithSeller)}
          />
        </LeafCell>
      </Grid>
    </>
  );
};

type Props = {
  viewing: PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings,
};

export const FollowUpFeedback = ({ viewing }: Props) => {
  const [createMutation, { loading }] = useMutation(CREATE_FOLLOW_UP_FEEDBACK, {
    variables: {
      input: { viewingId: viewing.id },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROPERTY_INTERESTS_QUERY,
        variables: { buyerId: viewing.buyerPropertyInterest.buyer.id },
      },
    ],
  });

  return (
    <div>
      {viewing.followUpFeedback.map((feedback) => (
        <Feedback key={feedback.id} feedback={feedback} />
      ))}
      <AddButton
        data-test="viewings:follow-up-feedback:add"
        disabled={loading}
        onClick={() => {
          createMutation();
        }}
      >
        Add followup feedback
      </AddButton>
    </div>
  );
};
