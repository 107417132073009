import T from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Grid, LeafCell } from "components/Grid";
import { TextareaWithLinks } from "components/TextareaWithLinks";

import { graphql } from "deal/utils/graphql";
import { Modal } from "components/Modal";
import { PropertyListingPriceList } from "./PropertyListingPriceList/PropertyListingPriceList";
import nestedListingsQuery from "./queries/nestedListings.graphql";
import { nestedListingsQueryConfig } from "./queries/config";
import updateNestedListingMutation from "./updateMutation/updateNestedListings.graphql";
import { updateConfig } from "./updateMutation/config";

const enhance = compose(
  graphql(nestedListingsQuery, nestedListingsQueryConfig),
  graphql(updateNestedListingMutation, updateConfig),
);

function NestedListingComponent({
  updateNestedListings,
  history,
  data: {
    nestDeal: { id: dealId, nestedListing, externalId },
  },
}) {
  return (
    <Modal
      title="Nested listing"
      closeModal={() => history.push(`/deals/${externalId}/listing`)}
      isOpen
      data-test="nested-listing-modal"
    >
      <Grid columns={2} rows="auto auto">
        <LeafCell width={1}>
          <TextareaWithLinks
            label="Nested Listing Rightmove Url "
            mutation={updateNestedListings}
            property="nestedListing"
            data-test="nested-listing-modal-listing-url"
            value={nestedListing}
            multiline={false}
          />
        </LeafCell>
        <LeafCell width={2}>
          <PropertyListingPriceList
            dealId={dealId}
            data-test="nested-listing-modal-prices-list"
          />
        </LeafCell>
      </Grid>
    </Modal>
  );
}

NestedListingComponent.propTypes = {
  updateNestedListings: T.func.isRequired,
  history: T.object.isRequired,
  data: T.object.isRequired,
};

const NestedListing = enhance(NestedListingComponent);

function NestedListingsModalComponent({ match, ...rest }) {
  return (
    <Route
      path={`${match.path}/nested-listings`}
      render={() => <NestedListing {...rest} />}
    />
  );
}

NestedListingsModalComponent.propTypes = {
  match: T.object.isRequired,
};

export const NestedListingsModal = withRouter(NestedListingsModalComponent);
