// @flow

import { compose } from "recompose";

import type { MutationOperation } from "@apollo/client";

import { graphql } from "deal/utils/graphql";
import { CreateButton } from "components/CreateButton";

import createGuaranteePaymentMutation from "./createMutation/createGuaranteePayment.graphql";
import { createConfig } from "./createMutation/config";
import deleteGuaranteePaymentMutation from "./deleteMutation/deleteGuaranteePayment.graphql";
import { deleteConfig } from "./deleteMutation/config";
import updateGuaranteePaymentMutation from "./updateMutation/updateGuaranteePayment.graphql";
import { updateConfig } from "./updateMutation/config";

import guaranteePaymentsQuery from "./queries/guaranteePayments.graphql";
import { guaranteePaymentsQueryConfig } from "./queries/config";

import { LegacyGuaranteePayment } from "./LegacyGuaranteePayment";
import { AgencyPlusSep2018GuaranteePayment } from "./AgencyPlusSep2018GuaranteePayment";
import {
  AdvanceMay2018GuaranteePayment,
  AdvanceJul2018GuaranteePayment,
} from "./AdvanceGuaranteePayment";

type GuaranteePayment =
  GuaranteePaymentList_nestDeal_guaranteePaymentHistory_guaranteePayments;

type Props = {
  data: typeof GuaranteePaymentList,
  createGuaranteePayment: (id: ID) => () => MutationOperation<GuaranteePayment>,
  deleteGuaranteePayment: (id: ID) => () => MutationOperation<GuaranteePayment>,
  updateGuaranteePayment: (
    id: ID,
  ) => (input: GuaranteePaymentInput) => MutationOperation<GuaranteePayment>,
};

const paymentComponentMapping = {
  agency_plus_sep2018: AgencyPlusSep2018GuaranteePayment,
  advance_may2018: AdvanceMay2018GuaranteePayment,
  advance_jul2018: AdvanceJul2018GuaranteePayment,
};

const GuaranteePaymentListComponent = ({
  data: {
    nestDeal: {
      dealType,
      guaranteePaymentHistory: { guaranteePayments },
    },
  },
  deleteGuaranteePayment,
  createGuaranteePayment,
  updateGuaranteePayment,
}: Props) => {
  const PaymentComponent =
    dealType && dealType.value ? paymentComponentMapping[dealType.value] : null;
  return (
    <div data-test="guarantee-payment-list">
      {PaymentComponent
        ? guaranteePayments.map((guaranteePayment) => (
            <PaymentComponent
              id={guaranteePayment.id}
              key={guaranteePayment.id}
              guaranteePayment={guaranteePayment}
              updateGuaranteePayment={updateGuaranteePayment(
                guaranteePayment.id,
              )}
              deleteGuaranteePayment={deleteGuaranteePayment(
                guaranteePayment.id,
              )}
            />
          ))
        : guaranteePayments.map((guaranteePayment) => (
            <LegacyGuaranteePayment
              key={guaranteePayment.id}
              guaranteePayment={guaranteePayment}
              updateGuaranteePayment={updateGuaranteePayment(
                guaranteePayment.id,
              )}
              deleteGuaranteePayment={deleteGuaranteePayment(
                guaranteePayment.id,
              )}
            />
          ))}

      <CreateButton
        data-test="create-guarantee-payment-button"
        label="Add a new guarantee payment"
        onSubmit={createGuaranteePayment}
      />
    </div>
  );
};

export const GuaranteePaymentList = compose(
  graphql(guaranteePaymentsQuery, guaranteePaymentsQueryConfig),
  graphql(createGuaranteePaymentMutation, createConfig),
  graphql(deleteGuaranteePaymentMutation, deleteConfig),
  graphql(updateGuaranteePaymentMutation, updateConfig),
)(GuaranteePaymentListComponent);
