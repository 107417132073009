import T from "prop-types";
import { path } from "ramda";

import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import underwritingQuery from "./queries/underwritingQuery.graphql";
import { underwritingQueryConfig } from "./queries/underwritingQueryConfig";

import { updateDealTypeUnderwritingConfig } from "./mutations/updateDealTypeUnderwritingConfig";
import UpdateDealTypeUnderwritingMutation from "./mutations/UpdateDealTypeUnderwritingMutation.graphql";

const UnderwritingComponent = ({
  data: queryResult,
  updateDealTypeUnderwriting,
}) => {
  const dealTypeData =
    path(["nestDeal", "dealTypeUnderwriting"], queryResult) || null;
  return (
    dealTypeData && (
      <Grid columns={4}>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeUnderwriting}
            property="baseFee"
            data-test="deal-type-underwriting-base-fee"
            label="Base fee %"
            value={dealTypeData.baseFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeUnderwriting}
            property="noDrawDiscount"
            data-test="deal-type-underwriting-no-draw-discount"
            label="No draw discount %"
            value={dealTypeData.noDrawDiscount}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeUnderwriting}
            property="earlyPaymentFeePerDay"
            data-test="deal-type-underwriting-early-payment-fee-per-day"
            label="Early payment fee % (per day)"
            value={dealTypeData.earlyPaymentFeePerDay}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeUnderwriting}
            property="earlyPaymentFeeFreePercent"
            data-test="deal-type-underwriting-early-payment-fee-free-percent"
            label="Early payment fee free %"
            value={dealTypeData.earlyPaymentFeeFreePercent}
          />
        </LeafCell>
        <LeafCell>
          <CurrencyField
            mutation={updateDealTypeUnderwriting}
            property="referralDiscount"
            data-test="deal-type-underwriting-referral-discount"
            label="Referral discount"
            value={dealTypeData.referralDiscount}
          />
        </LeafCell>
      </Grid>
    )
  );
};

UnderwritingComponent.propTypes = {
  data: T.object.isRequired,
  updateDealTypeUnderwriting: T.func.isRequired,
};

export const Underwriting = compose(
  graphql(underwritingQuery, underwritingQueryConfig),
  graphql(UpdateDealTypeUnderwritingMutation, updateDealTypeUnderwritingConfig),
)(UnderwritingComponent);
