// @flow
import styled, { css } from "styled-components";

import { DetailsIcon } from "@nest-ui/icons";
import { theme as t } from "@nest-ui/styles";
import { Card } from "./Card/Card";

const WarningCard = styled(Card)`
  align-items: center;
  background-color: #fff8e0;
  border: solid 1px #ffda9c;
  box-shadow: none;
  display: flex;
  margin: 0;
  position: relative;

  ${({ hasTopArrow }) =>
    hasTopArrow &&
    css`
      &:after,
      &:before {
        bottom: 100%;
        left: 10%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(255, 248, 224, 0);
        border-bottom-color: #fff8e0;
        border-width: 8px;
        margin-left: -8px;
      }

      &:before {
        border-color: rgba(255, 218, 156, 0);
        border-bottom-color: #ffda9c;
        border-width: 9px;
        margin-left: -9px;
      }
    `}
`;

const WarningCardContainer = styled.div`
  position: relative;
  padding-top: 16px;
`;

const WarningText = styled.p`
  color: ${({ theme }) => theme.color.text.default};
  font-family: Euclid;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 0;
`;

export function Warning({
  children,
  hasTopArrow,
}: {
  children: string | Array<any>,
  hasTopArrow?: boolean,
}) {
  return (
    <WarningCardContainer>
      <WarningCard hasTopArrow={hasTopArrow}>
        <div
          style={{
            width: "28px",
            height: "28px",
            flexShrink: 0,
            marginRight: "16px",
          }}
        >
          <DetailsIcon fill={t.color.primary.dark} />
        </div>
        <WarningText>{children}</WarningText>
      </WarningCard>
    </WarningCardContainer>
  );
}
