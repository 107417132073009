// @flow
import { faCheck, faTasks } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem } from "@nested/brand/src/components/Timeline";

type TaskEventProps = {
  event: Timeline_timeline,
};

export const TimelineTaskEvent = ({ event }: TaskEventProps) => {
  if (event.__typename !== "AgentTaskEvent") {
    return null;
  }

  const { description, agent, completedAt, reinstatedAt } = event;

  const eventDate = completedAt || reinstatedAt;
  const primaryHeading = `Task ${completedAt ? "completed" : "reinstated"}`;

  return (
    <TimelineItem
      icon={completedAt ? faCheck : faTasks}
      primaryHeading={primaryHeading}
      userEmail={agent}
      timestamp={eventDate}
      secondaryHeading={description}
    />
  );
};
