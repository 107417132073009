// @flow
import type { Node } from "react";
import { withState, compose, nest, type HOC } from "recompose";
import styled from "styled-components";

import { RippleSpinner as Ripple } from "@nest-ui/icons";

const RippleWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
`;

export const Spinner = ({
  children,
  isLoading,
  showSpinner = true,
}: {
  children: Node,
  isLoading: boolean,
  showSpinner?: boolean,
}) => (
  <div style={{ position: "relative" }}>
    {isLoading && showSpinner && (
      <RippleWrapper>
        <Ripple />
      </RippleWrapper>
    )}
    {children}
  </div>
);

const withLoadingSpinner = (component) => nest(Spinner, component);

export const withSpinner = (compose(
  withState("isLoading", "setLoading", false),
  withLoadingSpinner,
): HOC<*, *>);
