// @flow
import moment from "moment-timezone";

export const viewingConductors = {
  SALES_ASSOCIATE: "SALES_ASSOCIATE",
  SUB_AGENT: "SUB_AGENT",
  VENDOR: "VENDOR",
  VIEWBER: "VIEWBER",
};

export type ViewingAgentOptions = {|
  +conductor: ?Conductor,
  +viewingSubAgentId?: ?string,
  +viewingSaId?: ?string,
  +nestedUserId?: ?string,
|};

export type AssociatedSas = {
  buyerLeadSa: ?PropertyInterestsByBuyer_buyer_leadSaUser,
  buyerPropertyInterestSa: ?PropertyInterestsByBuyer_activeNestedUsers,
  vendorLeadSa: ?buyerPropertyInterestFields_deal_vendorLeadSa,
  currentlyResponsibleSaUser: ?buyerPropertyInterestFields_deal_currentlyResponsibleSaUser,
};

export function excludeRearrangedViewings(
  viewings: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings>,
): $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings> {
  const rearrangedViewingsIds = viewings.reduce(
    (acc, viewing) =>
      viewing.rearrangedViewingId
        ? acc.concat(viewing.rearrangedViewingId)
        : acc,
    [],
  );

  return viewings.filter(
    (viewing) => !rearrangedViewingsIds.includes(viewing.id),
  );
}

/**
 * The below 2 functions are necessary to keep the order of viewings the same
 * so that users would have a good experience.
 */
export function getViewingsIncludingAndFollowingRearrangedViewing({
  viewings,
  rearrangedViewingId,
}: {|
  viewings: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings>,
  rearrangedViewingId: ?string,
|}): $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings> {
  return viewings.filter(
    (viewing) => Number(rearrangedViewingId) <= Number(viewing.id),
  );
}

// See the comment for the function above
export function getViewingsPrecedingRearrangedViewing({
  viewings,
  rearrangedViewingId,
}: {|
  viewings: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings>,
  rearrangedViewingId: ?string,
|}): $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings> {
  return viewings.filter(
    (viewing) => Number(viewing.id) < Number(rearrangedViewingId),
  );
}

export function isViewingActionRequired({
  datetimeViewingEnds,
  viewingCancellationReasonId,
  datetimeFeedbackAdded,
}: {
  +viewingCancellationReasonId: ?string,
  +datetimeFeedbackAdded: ?string,
  +datetimeViewingEnds: string,
}) {
  return (
    !datetimeFeedbackAdded &&
    !viewingCancellationReasonId &&
    moment().isAfter(datetimeViewingEnds)
  );
}

export const isViewingCancelled = ({
  viewingCancellationReasonId,
}: viewings_nestDeal_viewings) => Boolean(viewingCancellationReasonId);

type ViewingAgentLabelProps = {
  viewing: {
    +conductor: ?Conductor,
    +nestedUserId: ?ID,
    +viewingSubAgentId: ?ID,
  },
  activeNestedUsers: $ReadOnlyArray<{
    +id: ID,
    +fullName: ?string,
  }>,
  subAgents: $ReadOnlyArray<{ +id: ID, +branchName: ?string }>,
  viewingAssistants: $ReadOnlyArray<{
    +id: ID,
    +fullName: ?string,
  }>,
};

export const viewingAgentLabel = ({
  viewing,
  activeNestedUsers,
  subAgents,
  viewingAssistants,
}: ViewingAgentLabelProps) => {
  const { conductor, viewingSubAgentId, nestedUserId } = viewing;

  switch (conductor) {
    case viewingConductors.VIEWBER:
      return "Viewber";
    case viewingConductors.VENDOR:
      return "Vendor";
    case viewingConductors.SUB_AGENT:
      return (
        subAgents.find((s) => s.id === viewingSubAgentId)?.branchName ||
        "[Sub agent branch name not specified]"
      );
    case viewingConductors.SALES_ASSOCIATE:
      return (
        activeNestedUsers
          .concat(viewingAssistants)
          .find((user) => user.id === nestedUserId)?.fullName ||
        "[Nested user name not specified]"
      );
    default:
      throw new Error(
        `Invalid viewing conductor: ${conductor || "[No Conductor]"}`,
      );
  }
};
