// @flow
import { branch, renderComponent, compose, type HOC } from "recompose";
import styled from "styled-components";

import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";

import { logException } from "../sentry";

export const ErrorP = styled.p`
  margin-top: 119px;
  text-align: center;
  color: red;
`;

const GenericError = ({ data }) => (
  console.error(data.error), // eslint-disable-line no-console, no-sequences
  (
    <ErrorP>
      Please try refreshing the page. If the problem persists, please tell the
      #tech-support on slack so we can fix it for you. Sorry!
    </ErrorP>
  )
);

export const withLoader = (
  isLoading: () => boolean,
): HOC<*, { loader: boolean }> => branch(isLoading, renderComponent(Loader));

export const logError = (maybeError: any) => {
  if (maybeError && maybeError.message) {
    logException(maybeError.message, { error: maybeError });
  } else if (maybeError) {
    logException("Got GraphQL error in response", { error: maybeError });
  }

  return maybeError;
};

const isErrorWithLogging = compose(logError, ({ data }) => data && data.error);

export const withGenericErrors: HOC<*, *> = branch(
  isErrorWithLogging,
  renderComponent(GenericError),
);
