import householdCostsQuery from "../queries/householdCosts.graphql";

export const createConfig = {
  props: ({ mutate, ownProps }) => ({
    createHouseholdCost: () =>
      mutate({
        variables: {
          id: Number(ownProps.parentId),
        },
        refetchQueries: [
          {
            query: householdCostsQuery,
            variables: {
              id: ownProps.parentId,
            },
          },
        ],
      }),
    ...ownProps,
  }),
};
