import moment from "moment";
import { withHandlers } from "recompose";

export const formatStrippingInvalid = (formatFrom, formatTo) => (value) => {
  const date = moment(value, formatFrom);
  return date.isValid() ? date.format(formatTo) : "";
};

export const withFormattedDate = (inputFormat, outputFormat = inputFormat) =>
  withHandlers({
    format: () => formatStrippingInvalid(inputFormat, outputFormat),
    cast: () => formatStrippingInvalid(outputFormat, inputFormat),
  });
