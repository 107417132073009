import { createGlobalStyle } from "styled-components";

import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Euclid;
    src: url("https://cdn.nested.com/fonts/EuclidCircularB-Regular.otf") format("opentype");
    font-weight: ${theme.font.weight.regular};
    font-style: normal;
  }

  @font-face {
    font-family: Euclid;
    src: url("https://cdn.nested.com/fonts/EuclidCircularB-Medium.otf") format("opentype");
    font-weight: ${theme.font.weight.medium};
    font-style: normal;
  }

  @font-face {
    font-family: Euclid;
    src: url("https://cdn.nested.com/fonts/EuclidCircularB-Semibold.otf") format("opentype");
    font-weight: ${theme.font.weight.semibold};
    font-style: normal;
  }

  @font-face {
    font-family: Euclid;
    src: url("https://cdn.nested.com/fonts/EuclidCircularB-Bold.otf") format("opentype");
    font-weight: ${theme.font.weight.bold};
    font-style: normal;
  }

  body {
    font-family: Euclid;
    overflow: ${(props) => (props.scrollable ? "auto" : "hidden")};
  }

  * {
    box-sizing: border-box;

    // Necessary to make Euclid fonts look beautiful
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, input, textarea {
    font-size: ${theme.font.size.default};
  }

  a {
    color: ${theme.color.text.highlight};
  }

  .formToggle-enter {
    max-height: 1px;
    opacity: 0.01;

    .Linkify > div, input:not([type="radio"]), input[type="radio"] + label, .Select > .Select-control {
        border: ${`${theme.input.border.width} solid ${theme.color.primary.dark}`} !important;
        background-color: ${theme.color.primary.accent} !important;
      }
    }
  }

  .formToggle-enter.formToggle-enter-active {
    opacity: 1;
    max-height: 1000px;
    transition: max-height 300ms ease-in, opacity 150ms linear 300ms;
    .Linkify > div, input:not([type="radio"]), input[type="radio"] + label, .Select > .Select-control {
        border: ${`${theme.input.border.width} solid ${theme.color.secondary.highlight}`} !important;
        background-color: white !important;
        transition: border 300ms linear 2000ms, background-color 300ms linear 2000ms;
      }
    }
  }

  .formToggle-exit {
    opacity: 1;
    max-height: 1000px;
  }

  .formToggle-exit.formToggle-exit-active {
    opacity: 0.01;
    max-height: 1px;
    transition: opacity 150ms linear, max-height 300ms ease-out 150ms;
  }

  @keyframes roll {
    from { transform: rotate(0deg) }
    to   { transform: rotate(360deg) }
  }

  .barrel-roll {
    animation-name: roll;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
  }

  .modal-enter, .modal-appear {
    opacity: 0.01;
    .white-box {
      transform: translateY(-100%);
    }
  }

  .modal-enter-active, .modal-appear-active {
    opacity: 1;
    transition: opacity 200ms ease-out;
    .white-box {
      transition: all 200ms ease-out;
      transform: translateY(0%);
    }
  }

  .modal-exit {
    opacity: 1;
    .white-box {
      transition: all 200ms ease-out;
      transform: translateY(0%);
    }
  }

  .modal-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-out;
    .white-box {
      transition: all 200ms ease-out;
      transform: translateY(-100%);
    }
  }

  b, strong {
    font-weight: 500;
  }
`;
