import drawdownTimingFeesQuery from "../queries/drawdownTimingFees.graphql";

export const createDrawdownTimingFeeMutationConfig = {
  props({ mutate }) {
    return {
      createDrawdownTimingFee: (dealId) => (values) =>
        mutate({
          variables: {
            input: {
              dealId,
              ...values,
            },
          },
          refetchQueries: [
            {
              query: drawdownTimingFeesQuery,
              variables: {
                id: dealId,
              },
            },
          ],
        }),
    };
  },
};

export const deleteDrawdownTimingFeeMutationConfig = {
  props: ({ mutate }) => ({
    deleteDrawdownTimingFee: (drawdownTimingFeeId, dealId) => () =>
      mutate({
        variables: {
          id: drawdownTimingFeeId,
        },
        refetchQueries: [
          {
            query: drawdownTimingFeesQuery,
            variables: {
              id: dealId,
            },
          },
        ],
      }),
  }),
};

export const updateDrawdownTimingFeeMutationConfig = {
  props({ mutate }) {
    return {
      updateDrawdownTimingFee: (drawdownTimingFee) => (input) =>
        mutate({
          optimisticResponse: {
            __typename: "Mutation",
            updateDrawdownTimingFee: {
              __typename: "DrawdownTimingFee",
              ...drawdownTimingFee,
              ...input,
            },
          },
          variables: {
            id: drawdownTimingFee.id,
            input,
          },
        }),
    };
  },
};
