// @flow

import { gql } from "@apollo/client";
import { css } from "styled-components";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import qs from "query-string";
import { Form, Field, FormSpy } from "react-final-form";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { Button } from "@nested/nest/src/components/Button/Button";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { useRef, useState } from "react";
import {
  CustomMessageField,
  SubjectField,
  validateSubjectField,
  EmailFormWrapper,
} from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/ComposeMessage";
import { ModalWrapper } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/MailoutModal";
import { media } from "@nested/brand";
import { EmailPreviewBody } from "./EmailPreviewBody";

// There is a limit of 1000 chars per SMS. Because we have limited the number of
// cross-sell properties to 5, there should be enough space for a 150 char body.
const CROSS_SELL_BODY_MAX_LENGTH = 150;

const fieldsWrapper = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const previewButtonStyles = css`
  margin: 20px 0;
  ${media.tablet`
    display: none;
  `}
`;

const mobileButtonsWrapper = css`
  width: 100%;
  padding: 20px 20px 110px 20px;
  background: ${({ theme }) => theme.palette.hague100};
  ${media.tablet`
    display: none;
  `}
`;

const desktopButtonStyle = css`
  display: none;
  margin-bottom: 20px;
  ${media.tablet`
    display: block;
  `}
`;

const ALL_ACTIVE_USERS = gql`
  query UsersForPropertyMatchEmail {
    activeNestedUsers {
      id
      email
      firstName
      fullName
      mobileNumber
    }
  }
`;

const SEND_EMAIL_MUTATION = gql`
  mutation SendPropertyMatchEmailMutation(
    $input: SendPropertyMatchEmailInput!
  ) {
    sendPropertyMatchEmail(input: $input) {
      success
    }
  }
`;

const required = (input) => (input ? undefined : "Required");
type Props = {
  onBack: () => void,
  onCancel: () => void,
  emailSubject: string,
  emailBody: string,
  buyer: PropertyInterestsByBuyer_buyer,
  selectedProperties: PropertyMatchSummary_buyer_potentialProperties[],
};

const MobileButtons = ({ formRef, sending, formApi }) => {
  return (
    <div css={mobileButtonsWrapper}>
      <Button
        icon={faPen}
        buttonStyle="outline"
        large
        css={previewButtonStyles}
        type="button"
        onClick={() =>
          formRef.current?.scrollIntoView({
            behaviour: "smooth",
            block: "end",
          })
        }
      >
        Continue editing
      </Button>
      <Button
        disabled={sending || formApi?.hasValidationErrors}
        buttonStyle="pink"
        large
        type="submit"
        onClick={() => formApi?.submit()}
      >
        {sending ? "Sending..." : "Send email & SMS"}
      </Button>
    </div>
  );
};

export const EmailBuilder = ({
  onBack,
  onCancel,
  emailSubject,
  emailBody,
  buyer,
  selectedProperties,
}: Props) => {
  const [sendEmail, { loading: sending }] = useMutation(SEND_EMAIL_MUTATION);
  const { email } = useUser();
  const { search } = useLocation();
  const { email: passedInEmail } = qs.parse(search);
  const { loading, error, data } = useQuery(ALL_ACTIVE_USERS);
  const { createNotification } = useNotifications();
  const [formApi, setFormApi] = useState(null);
  const formRef = useRef(null);
  const previewRef = useRef(null);
  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) return <Loader />;

  const { activeNestedUsers: users } = data;

  const currentUser = users.find(
    (user) => user.email === (passedInEmail || email).replace(/\s/g, "+"),
  );

  const onSubmit = async (values) => {
    try {
      const result = await sendEmail({
        variables: {
          input: {
            dealIds: selectedProperties.map((p) => p.id),
            buyerId: buyer.id,
            subject: values.emailSubject,
            body: values.emailBody,
            saEmailAddress: currentUser.email,
          },
        },
      });
      if (result?.data?.sendPropertyMatchEmail?.success) {
        createNotification("Messages delivered");
        onCancel();
        return null;
      }
      if (result.error) {
        throw result.error;
      }
    } catch (e) {
      errorHandler(e);
    }
    return null;
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        emailSubject,
        emailBody,
      }}
    >
      {({ handleSubmit, values, hasValidationErrors, form: { submit } }) => (
        <>
          <ModalWrapper>
            <EmailFormWrapper onCancel={onBack} title="Compose cross sell">
              <form
                data-test="send-property-match-email-form"
                onSubmit={handleSubmit}
                css={fieldsWrapper}
                ref={formRef}
              >
                <div>
                  <div>
                    <Field
                      name="emailSubject"
                      type="text"
                      validate={validateSubjectField}
                    >
                      {({ input, meta }) => (
                        <SubjectField
                          id="potential-property-email-subject-input"
                          label={"Email subject"}
                          {...input}
                          {...meta}
                          errors={meta.error}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="emailBody" type="text" validate={required}>
                      {({ input }) => (
                        <CustomMessageField
                          id="potential-property-email-body-input"
                          multiline
                          label={"Additional custom message"}
                          {...input}
                          minRows={9}
                          maxLength={CROSS_SELL_BODY_MAX_LENGTH}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <FormSpy
                  subscription={{
                    hasValidationErrors: true,
                  }}
                  onChange={(changes) => {
                    setFormApi({ ...changes, submit });
                  }}
                />
                <Button
                  icon={faEye}
                  buttonStyle="outline"
                  large
                  css={previewButtonStyles}
                  type="button"
                  onClick={() => {
                    previewRef.current?.scrollIntoView({
                      behaviour: "smooth",
                    });
                  }}
                >
                  Preview message
                </Button>
                <Button
                  disabled={sending || hasValidationErrors}
                  buttonStyle="pink"
                  large
                  css={desktopButtonStyle}
                  type="submit"
                >
                  {sending ? "Sending..." : "Send email & SMS"}
                </Button>
              </form>
            </EmailFormWrapper>
            <EmailPreviewBody
              emailBody={values?.emailBody || ""}
              selectedProperties={selectedProperties}
              currentUser={currentUser}
              previewRef={previewRef}
            />
            <MobileButtons
              sending={sending}
              formApi={formApi}
              formRef={formRef}
            />
          </ModalWrapper>
        </>
      )}
    </Form>
  );
};
