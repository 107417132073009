import { Component } from "react";
import T from "prop-types";
import { withRouter } from "react-router-dom";

class ScrollToTopComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return <div style={{ height: "100%" }}>{this.props.children}</div>;
  }
}

ScrollToTopComponent.propTypes = {
  children: T.oneOfType([T.arrayOf(T.element), T.element]),
};

export const ScrollToTop = withRouter(ScrollToTopComponent);
