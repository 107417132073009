import { gql } from "@apollo/client";
import { H2 } from "@nest-ui/sellers-nest/components/Heading";
import { useMutation } from "@apollo/client/react/hooks";
import { BuyerOfferList } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferList";
import { BUYER_OFFER_LIST_FRAGMENT } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferListFragment";
import { PROPERTY_INTERESTS_QUERY } from "../PropertyInterests";

const BUYER_OFFER_DELETE_MUTATION = gql`
  mutation DeleteBuyerOffer($buyerOfferId: ID!) {
    deleteBuyerOffer(id: $buyerOfferId) {
      id
    }
  }
`;

const BUYER_OFFER_DUPLICATE_MUTATION = gql`
  mutation DuplicateBuyerOffer($buyerOfferId: ID!) {
    duplicateBuyerOffer(id: $buyerOfferId) {
      id
      ...BuyerOfferListFragment
    }
  }
  ${BUYER_OFFER_LIST_FRAGMENT}
`;

export const Offers = ({ buyerId, buyerPropertyInterestId, buyerOffers }) => {
  const [duplicateBuyerOffer] = useMutation(BUYER_OFFER_DUPLICATE_MUTATION, {
    refetchQueries: [
      { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
    ],
  });
  const [deleteBuyerOffer] = useMutation(BUYER_OFFER_DELETE_MUTATION, {
    refetchQueries: [
      { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
    ],
  });

  return (
    <section css="margin: 16px;">
      <H2>Offers</H2>
      <BuyerOfferList
        buyerId={buyerId}
        buyerPropertyInterestId={buyerPropertyInterestId}
        deleteOffer={deleteBuyerOffer}
        duplicateOffer={duplicateBuyerOffer}
        buyerOffers={buyerOffers}
      />
    </section>
  );
};
