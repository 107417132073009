import T from "prop-types";

import { H3 } from "components/Heading";
import { Section } from "components/DealDetailsBar";
import { Grid, LeafCell } from "components/Grid";
import { TextareaWithLinks } from "components/TextareaWithLinks";

const ReadOnlyContent = ({ nestDeal }) => (
  <>
    <H3>Current listing</H3>
    <Grid columns={2}>
      <LeafCell width={2}>
        <TextareaWithLinks
          label="URL"
          value={nestDeal.currentListingUrl}
          readOnly
        />
      </LeafCell>
    </Grid>
  </>
);

ReadOnlyContent.propTypes = {
  nestDeal: T.object.isRequired,
};

const EditableContent = ({ mutations, nestDeal }) => (
  <>
    <H3>Current listing</H3>
    <Grid columns={4}>
      <LeafCell width={2}>
        <TextareaWithLinks
          label="URL"
          value={nestDeal.currentListingUrl}
          property="currentListingUrl"
          mutation={mutations.updateNestDeal}
        />
      </LeafCell>
    </Grid>
  </>
);

EditableContent.propTypes = {
  nestDeal: T.object.isRequired,
  mutations: T.object.isRequired,
};

export const Marketing = (props) => (
  <Section
    title="Marketing"
    readOnlyContent={ReadOnlyContent}
    editableContent={EditableContent}
    {...props}
  />
);
