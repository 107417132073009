import { Section } from "components/DealDetailsBar";
import { ReadOnlyContent } from "./ReadOnlyContent";
import { EditableContent } from "./EditableContent";

export const PropertyInformation = (props) => (
  <Section
    title="Property"
    readOnlyContent={ReadOnlyContent}
    editableContent={EditableContent}
    {...props}
  />
);
