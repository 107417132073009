import T from "prop-types";
import { Route, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { TextField } from "components/TextField";
import { SelectField } from "components/SelectField";

import { graphql } from "deal/utils/graphql";
import { Modal } from "components/Modal";
import updateAgencyValuationMutation from "./updateMutation/updateAgencyValuation.graphql";
import { updateConfig } from "./updateMutation/config";
import findAgencyValuationQuery from "./queries/findAgencyValuation.graphql";
import { findQueryConfig } from "./queries/findQueryConfig";

const enhance = compose(
  graphql(findAgencyValuationQuery, findQueryConfig),
  graphql(updateAgencyValuationMutation, updateConfig),
);

function AgencyValuationComponent({
  data,
  updateAgencyValuation,
  history,
  activeNestedUsers,
  closeUrl,
}) {
  return (
    <Modal
      title="Update valuation"
      closeModal={() => history.push(closeUrl)}
      isOpen
      data-test="agency-valuation-modal"
    >
      <Grid columns={4}>
        <LeafCell width={1}>
          <CurrencyField
            label="Expected price"
            mutation={updateAgencyValuation}
            property="expectedPrice"
            data-test="agency-valuation-modal-expected-price"
            value={data.agencyValuation.expectedPrice}
          />
        </LeafCell>
        <LeafCell width={1}>
          <CurrencyField
            label="Worst case valuation"
            property="worstCaseValuation"
            mutation={updateAgencyValuation}
            data-test="agency-valuation-modal-worst-case-valuation"
            value={data.agencyValuation.worstCaseValuation}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Reason for update"
            property="reasonForUpdate"
            mutation={updateAgencyValuation}
            data-test="agency-valuation-modal-reason-for-update"
            value={data.agencyValuation.reasonForUpdate}
          />
        </LeafCell>
        <LeafCell width={2}>
          <SelectField
            label="Updated by"
            property="updatedByUserId"
            mutation={updateAgencyValuation}
            data-test="agency-valuation-modal-updated-by"
            value={data.agencyValuation.updatedByUserId}
            options={activeNestedUsers}
            searchable
          />
        </LeafCell>
      </Grid>
    </Modal>
  );
}

AgencyValuationComponent.propTypes = {
  updateAgencyValuation: T.func,
  closeUrl: T.string.isRequired,
  history: T.object.isRequired,
  data: T.object,
  activeNestedUsers: T.array.isRequired,
};

const AgencyValuation = enhance(AgencyValuationComponent);

function AgencyValuationModalComponent({ activeNestedUsers, match }) {
  return (
    <Route
      path={`${match.path}/new-agency-valuation/:agencyValuationId`}
      render={(props) => (
        <AgencyValuation
          activeNestedUsers={activeNestedUsers}
          closeUrl={match.url}
          {...props}
        />
      )}
    />
  );
}

AgencyValuationModalComponent.propTypes = {
  activeNestedUsers: T.array.isRequired,
  match: T.object.isRequired,
};

export const AgencyValuationModal = withRouter(AgencyValuationModalComponent);
