// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";

const submitButtonStyle = css`
  width: 100%;
  padding: 30px 0 0 0;
  margin-top: auto;

  ${media.tablet`
  padding: 30px 0;
  position: fixed;
  bottom: 0;
  left: 0;
    button {
      height: 42px;
      font-size: 14px;
      line-height: 18px;
      max-width: 310px;
      margin: 0 auto;
      color: ${({ theme }) => theme.palette.hague150};
    }
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  `};
`;

const formStyle = css`
  margin: 0 5px;
  padding: 0 15px;
  height: calc(100vh - 300px);
  overflow-y: scroll;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  ${media.tablet`
    overflow-y: visible;
    padding: 0;
    margin: 0;
    height: unset;
    max-height: calc(100vh - 310px);
    form {
      height: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 123px;
    }
  `}
`;

const fieldStyle = css`
  margin-top: 20px;
  ${media.tablet`
    width: 49%;
  `}
`;

const required = (value: any) => (value ? undefined : "Required");

export { formStyle, submitButtonStyle, fieldStyle, required };
