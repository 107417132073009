// @flow
import { gql } from "@apollo/client";
import { useParams } from "react-router-dom";

import { Card } from "components/Card";
import { Heading, H2 } from "components/Heading";
import { TabContainer, TabWrapper } from "components/TabContainer";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { PropertyAccessArrangements } from "./PropertyAccessArrangements/PropertyAccessArrangements";
import { ViewingContactPreferences } from "./ViewingContactPreferences/ViewingContactPreferences";

import { PotentialBuyers } from "./PotentialBuyers/PotentialBuyers";
import { ViewingsList } from "./ViewingsList/ViewingsList";
import { BuyerOfferListContainer } from "./BuyerOfferList/BuyerOfferList";
import { Disinstruction } from "./Disinstruction/Disinstruction";

const INTEREST_QUERY = gql`
  query InterestQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
    }
  }
`;

export const Interest = () => {
  const { id: idOrExternalDealId } = useParams();
  return (
    <ExtendedQuery query={INTEREST_QUERY} variables={{ idOrExternalDealId }}>
      {({ nestDeal: { id } }: ExtendedQueryRenderProps<InterestQuery>) => (
        <TabWrapper>
          <TabContainer id="interest" data-test="interest-tab" fullWidth>
            <Card>
              <PotentialBuyers parentId={id} />
            </Card>
            <ViewingContactPreferences dealId={id} />
            <PropertyAccessArrangements dealId={id} />
            <Card>
              <H2 id="viewings-card">Viewings</H2>
              <ViewingsList dealId={id} />
            </Card>
            <Card>
              <H2 id="offers-card">Nested Offers</H2>
              <BuyerOfferListContainer parentId={id} />
            </Card>

            <Heading id="Disinstruction">Disinstruction</Heading>
            <Card>
              <Disinstruction parentId={id} />
            </Card>
          </TabContainer>
        </TabWrapper>
      )}
    </ExtendedQuery>
  );
};
