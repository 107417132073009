// @flow
import styled, { css } from "styled-components";
import { media } from "@nested/brand";

const stickyHeaderWrapperStyle = css`
  display: none;
  position: sticky;
  ${media.tablet`
    display: flex;
    background-color: white;
    border-bottom: 1px solid
      ${({ theme, $scrolling }) =>
        $scrolling ? theme.palette.hague20 : "none"};
    height: 50px;
    padding: 20px 15px 10px;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3;
  `}
`;

const StickyWrapper = styled.div`
  ${stickyHeaderWrapperStyle}
`;

export const StickyHeader = ({
  scrolling,
  children,
  className,
}: {
  children: React$Node,
  className?: string,
  scrolling?: boolean,
}) => {
  return (
    <StickyWrapper className={className} $scrolling={scrolling}>
      {children}
    </StickyWrapper>
  );
};
