import T from "prop-types";
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";
import { Grid, LeafCell } from "components/Grid";

import { CurrencyField } from "components/CurrencyField";
import { DrawdownPeriodDatesSection } from "../DrawdownPeriodDates/DrawdownPeriodDates";
import { GuaranteePaymentList } from "./GuaranteePaymentList";

import guaranteePaymentsSectionQuery from "./guaranteePaymentsSection.graphql";
import { guaranteePaymentsSectionQueryConfig } from "./GuaranteePaymentsSectionConfig";

const GuaranteePaymentsSectionComponent = ({ data: queryResult }) => {
  const { nestDeal } = queryResult;

  return !queryResult.loading ? (
    <Grid columns={4} data-test="guarantee-payment-section">
      <LeafCell width={4}>
        <GuaranteePaymentList dealId={nestDeal.id} />
      </LeafCell>
      <DrawdownPeriodDatesSection
        dealId={nestDeal.id}
        hideContractSignedDates
        readOnly
      />
      <LeafCell width={1}>
        <CurrencyField
          label="Amount outstanding to be paid out"
          data-test="guarantee-payments-section-amount-outstanding"
          value={nestDeal.guaranteePaymentHistory.amountOutstandingToBePaidOut}
          property="amountOutstandingToBePaidOut"
          readOnly
        />
      </LeafCell>
      <LeafCell width={1}>
        <CurrencyField
          label="Total paid gross"
          data-test="guarantee-payments-section-total-gross"
          value={nestDeal.guaranteePaymentHistory.totalPaidGross}
          property="totalPaidGross"
          readOnly
        />
      </LeafCell>
    </Grid>
  ) : null;
};

GuaranteePaymentsSectionComponent.propTypes = {
  data: T.object.isRequired,
};

const enhance = compose(
  graphql(guaranteePaymentsSectionQuery, guaranteePaymentsSectionQueryConfig),
);

export const GuaranteePaymentsSection = enhance(
  GuaranteePaymentsSectionComponent,
);
