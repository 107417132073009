// @flow
import { type MutationFunction } from "@apollo/client/react/components";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { Heading } from "components/Heading";
import { TextField } from "components/TextField";
import { ReadOnlyField } from "components/ReadOnlyField";
import { Cell, Grid, LeafCell } from "components/Grid";
import { css } from "styled-components";
import propertyTenureQuery from "../queries/propertyTenureQuery.graphql";

const maxLengthLabelStyle = css`
  span {
    position: absolute;
    bottom: 0;
    top: unset;
  }
`;

type Props = {
  buyerOffer: BuyerOffer_buyerOffer,
  mutation: MutationFunction<
    BuyerOfferDetailsUpdateBuyerOffer_updateBuyerOffer,
    BuyerOfferDetailsUpdateBuyerOfferVariables,
  >,
};

export const BuyerOfferSalesMemorandum = ({ buyerOffer, mutation }: Props) => {
  return (
    <ExtendedQuery
      query={propertyTenureQuery}
      variables={{ dealId: buyerOffer.dealId }}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-first"
    >
      {(data: ExtendedQueryRenderProps<PropertyTenureQuery>) => {
        const { nestDeal } = data;

        return (
          <>
            <Cell width={4}>
              <Grid columns={4}>
                <LeafCell width={4}>
                  <Heading level={2}>Sales memorandum</Heading>
                </LeafCell>
                <LeafCell width={2}>
                  <ReadOnlyField
                    data-test="buyer-offer-modal-tenure"
                    label="Tenure"
                    value={nestDeal.tenure}
                  />
                </LeafCell>
                <LeafCell width={4}>
                  <TextField
                    css={maxLengthLabelStyle}
                    label="Special comments (to be included in the Memorandum of Sale)"
                    value={buyerOffer?.specialComments}
                    mutation={mutation}
                    property="specialComments"
                    maxLength={1450}
                    multiline
                    maxRows={20}
                  />
                </LeafCell>
              </Grid>
            </Cell>
          </>
        );
      }}
    </ExtendedQuery>
  );
};
