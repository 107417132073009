// @flow
import React from "react";
import { pick } from "ramda";
import { kebabCase } from "lodash";
import {
  ApolloConsumer,
  type ApolloClient,
  type MutationFunction,
  gql,
} from "@apollo/client";
import { H2 } from "components/Heading";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitCheckbox } from "components/Checkbox/Checkbox";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitTextField } from "components/TextField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { NoSubmitPercentageField } from "components/PercentageField/PercentageField";
import { FullWidthCancelButton } from "./Buttons/FullWidthCancelButton";
import { SaveButton } from "./Buttons/SaveButton";

const ADVANCE_DRAWDOWN_REQUEST_FEE_BREAKDOWN = gql`
  query AdvanceDrawdownRequestFeeBreakdown(
    $input: AdvanceDrawdownFeeBreakdownInput!
  ) {
    advanceDrawdownFeeBreakdown(input: $input) {
      advanceFeeCapAmount
      agencyFeeAmount
      facilityFeeAmount
      feeAdjustmentAmount
      maximumNetAmount
      netAmount
      totalFees
    }
  }
`;

const UPDATE_DRAWDOWN_REQUEST = gql`
  mutation UpdateAdvanceDrawdownRequest(
    $input: UpdateAdvanceDrawdownRequestInput!
  ) {
    updateAdvanceDrawdownRequest(input: $input) {
      id
    }
  }
`;

const CREATE_ADVANCE_DRAWDOWN_REQUEST = gql`
  mutation CreateAdvanceDrawdownRequest(
    $input: CreateAdvanceDrawdownRequestInput!
  ) {
    createAdvanceDrawdownRequest(input: $input) {
      id
    }
  }
`;

const DELETE_ADVANCE_DRAWDOWN_REQUEST = gql`
  mutation DeleteAdvanceDrawdownRequest($id: ID!) {
    deleteAdvanceDrawdownRequest(id: $id) {
      id
    }
  }
`;

type State = {
  drawdownRequested: boolean,
  isEditing: boolean,
};

export class AdvanceDrawdownRequest extends React.Component<*, State> {
  state = {
    drawdownRequested: this.props.hasState || false,
    isEditing: false,
  };

  toggleRequestDrawdown = () => {
    const isDrawdownRequested = this.state.drawdownRequested;
    return this.setState({ drawdownRequested: !isDrawdownRequested });
  };

  setDrawdownRequested = (value: boolean) => {
    this.setState({ drawdownRequested: value });
  };

  setIsEditing = (value: boolean) => {
    this.setState({ isEditing: value });
  };

  render() {
    const { advanceOffer, feeBreakdown, advancesSectionQuery, dealId } =
      this.props;
    const { advanceDrawdownRequests } = advanceOffer;
    const advanceDrawdownRequest = advanceDrawdownRequests
      ? advanceDrawdownRequests[0]
      : null;

    const nextStepStarted = advanceDrawdownRequest
      ? advanceDrawdownRequest.drawdownProjectedDate
      : false;
    const drawdownRequestExists = Boolean(
      advanceDrawdownRequest && advanceDrawdownRequest.id,
    );

    const sharedProps = (key: string) => ({
      "data-test": `advance-drawdown-request-${kebabCase(key)}`,
      value: advanceDrawdownRequest ? advanceDrawdownRequest[key] : null,
      readOnly: !this.state.isEditing,
      onSubmit: (value) =>
        this.props.updateState({
          feeBreakdown: null,
          advanceOffer: {
            ...advanceOffer,
            advanceDrawdownRequests: [
              {
                ...advanceDrawdownRequest,
                [key]: value,
              },
            ],
          },
        }),
    });

    return (
      // We position the grid because the EditButton below is absolutely positioned
      // and we want that to be absolutely positioned relative to the grid.
      <Grid columns={4} style={{ position: "relative" }}>
        <LeafCell width={4}>
          <H2>Advance drawdown request</H2>
        </LeafCell>

        {drawdownRequestExists && !this.state.isEditing && !nextStepStarted && (
          <EditButton
            style={{ top: "-8px", right: "-8px" }}
            data-test="advance-drawdown-request-edit-button"
            onClick={() => this.setIsEditing(true)}
          >
            Edit
          </EditButton>
        )}

        <LeafCell width={2}>
          {!(drawdownRequestExists && !this.state.isEditing) && (
            <NoSubmitCheckbox
              data-test="advance-drawdown-request-request-drawdown"
              optionName="Drawdown requested?"
              parentId={advanceOffer.id}
              property="advance-drawdown-request"
              disabled={drawdownRequestExists && !this.state.isEditing}
              value={this.state.drawdownRequested}
              onSubmit={(value) => {
                switch (true) {
                  case drawdownRequestExists && !value:
                    this.setDrawdownRequested(false);
                    break;
                  case !drawdownRequestExists && !value:
                    this.setDrawdownRequested(false);
                    break;
                  case drawdownRequestExists && value:
                    this.setDrawdownRequested(true);
                    break;
                  case !drawdownRequestExists && value:
                    this.setDrawdownRequested(true);
                    this.setIsEditing(true);
                    break;
                  default:
                    throw new Error("w a t");
                }
              }}
            />
          )}
        </LeafCell>
        <LeafCell width={2} />

        {(this.state.drawdownRequested || drawdownRequestExists) && (
          <>
            <LeafCell width={1}>
              <NoSubmitDatePicker
                label="Requested date of drawdown"
                {...sharedProps("requestedDrawdownDate")}
              />
            </LeafCell>

            <LeafCell width={1}>
              <NoSubmitDatePicker
                label="Customer made the request on"
                {...sharedProps("requestMadeDate")}
              />
            </LeafCell>

            <LeafCell width={2}>
              <NoSubmitCurrencyField
                allowNegative
                label="Fee adjustment"
                {...sharedProps("feeAdjustmentAmount")}
              />
            </LeafCell>

            <LeafCell width={4}>
              <NoSubmitTextField
                label="Fee adjustment notes"
                readOnly={!this.state.isEditing}
                data-test="advance-drawdown-request-fee-adjustment-notes"
                onSubmit={(value) =>
                  this.props.updateState({
                    advanceOffer: {
                      ...advanceOffer,
                      advanceDrawdownRequests: [
                        {
                          ...advanceDrawdownRequest,
                          feeAdjustmentNotes: value,
                        },
                      ],
                    },
                  })
                }
                value={advanceDrawdownRequest?.feeAdjustmentNotes}
              />
            </LeafCell>
          </>
        )}

        {this.state.isEditing && !feeBreakdown && (
          <>
            <LeafCell width={2}>
              <ApolloConsumer>
                {(client: ApolloClient<AdvanceDrawdownRequestFeeBreakdown>) => {
                  const disableSubmit =
                    feeBreakdown ||
                    !(
                      advanceDrawdownRequest?.requestedDrawdownDate &&
                      advanceDrawdownRequest?.requestMadeDate &&
                      advanceDrawdownRequest?.feeAdjustmentAmount
                    );

                  return (
                    <SaveButton
                      data-test="advance-drawdown-section-calculate-button"
                      onClick={async () => {
                        const result = await client.query({
                          query: ADVANCE_DRAWDOWN_REQUEST_FEE_BREAKDOWN,
                          // This is also horrible because we need to know about what fields
                          // are required.
                          // $FlowFixMe
                          variables: {
                            input: {
                              advanceOfferId: advanceOffer.id,
                              feeAdjustmentAmount:
                                advanceDrawdownRequest?.feeAdjustmentAmount,
                            },
                          },
                        });
                        if (result) {
                          this.props.updateState({
                            advanceOffer,
                            feeBreakdown:
                              result.data.advanceDrawdownFeeBreakdown,
                          });
                        }
                      }}
                      disabled={disableSubmit}
                    >
                      Calculate available drawdown
                    </SaveButton>
                  );
                }}
              </ApolloConsumer>
            </LeafCell>
            <LeafCell width={2} />
          </>
        )}
        <LeafCell width={4} />
        {(this.state.drawdownRequested || drawdownRequestExists) &&
          feeBreakdown && (
            <>
              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>Actual amount gross</H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.maximumNetAmount}
                  readOnly
                  data-test="advance-drawdown-section-maximum-net-amount"
                />
              </LeafCell>
              <LeafCell width={1} />
              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>
                  Agency fee on advance (£)
                </H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.agencyFeeAmount}
                  readOnly
                  data-test="advance-drawdown-section-agency-fee-amount"
                />
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitPercentageField
                  readOnly
                  value={
                    this.props.dealTypeDetails.__typename ===
                    "DealTypeAdvancesForAllMarch2019"
                      ? this.props.dealTypeDetails.agencyFeePercent
                      : null
                  }
                />
              </LeafCell>

              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>
                  Facility fee on advance (£)
                </H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.facilityFeeAmount}
                  readOnly
                  data-test="advance-drawdown-section-facility-fee-amount"
                />
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitPercentageField
                  readOnly
                  value={
                    this.props.dealTypeDetails.__typename ===
                    "DealTypeAdvancesForAllMarch2019"
                      ? this.props.dealTypeDetails.facilityFeePercent
                      : null
                  }
                />
              </LeafCell>

              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>Advance fee cap (£)</H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.advanceFeeCapAmount}
                  readOnly
                  data-test="advance-drawdown-section-advance-fee-cap"
                />
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitPercentageField
                  readOnly
                  value={advanceOffer.advanceFeeCapPercent}
                />
              </LeafCell>

              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>Fee adjustment (-/+ £)</H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  readOnly
                  allowNegative
                  value={feeBreakdown.feeAdjustmentAmount}
                  data-test="advance-drawdown-section-fee-adjustment"
                />
              </LeafCell>

              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>
                  Total fees for this payment (£)
                </H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.totalFees}
                  readOnly
                  data-test="advance-drawdown-section-total-fees"
                />
              </LeafCell>
              <LeafCell width={1} />

              <LeafCell width={2}>
                <H2 style={{ marginBottom: "0px" }}>
                  Actual amount net of fees
                </H2>
              </LeafCell>
              <LeafCell width={1}>
                <NoSubmitCurrencyField
                  value={feeBreakdown.netAmount}
                  readOnly
                  data-test="advance-drawdown-section-net-amount"
                />
              </LeafCell>
            </>
          )}

        {(this.state.drawdownRequested || drawdownRequestExists) &&
          feeBreakdown &&
          this.state.isEditing && (
            <>
              <LeafCell width={1} />
              <LeafCell width={2}>
                <ExtendedMutation
                  awaitRefetchQueries
                  mutation={CREATE_ADVANCE_DRAWDOWN_REQUEST}
                  refetchQueries={[
                    { query: advancesSectionQuery, variables: { dealId } },
                  ]}
                >
                  {(
                    createDrawdownRequest: MutationFunction<CreateAdvanceDrawdownRequest>,
                  ) => (
                    <ExtendedMutation
                      awaitRefetchQueries
                      mutation={DELETE_ADVANCE_DRAWDOWN_REQUEST}
                      refetchQueries={[
                        { query: advancesSectionQuery, variables: { dealId } },
                      ]}
                    >
                      {(
                        deleteDrawdownRequest: MutationFunction<DeleteAdvanceDrawdownRequest>,
                      ) => (
                        <ExtendedMutation
                          awaitRefetchQueries
                          mutation={UPDATE_DRAWDOWN_REQUEST}
                          refetchQueries={[
                            {
                              query: advancesSectionQuery,
                              variables: { dealId },
                            },
                          ]}
                        >
                          {(
                            updateDrawdownRequest: MutationFunction<UpdateAdvanceDrawdownRequest>,
                          ) => {
                            const inputObjectFromProps = () =>
                              // This is horrible because we need to know about what fields
                              // are required by the BE. Wouldn't it be nicer to just send everything in
                              //  local cache to the BE.
                              pick(
                                [
                                  // $FlowFixMe missing properties are ignored but Ramda is out of date
                                  "requestedDrawdownDate",
                                  // $FlowFixMe missing properties are ignored but Ramda is out of date
                                  "requestMadeDate",
                                  // $FlowFixMe missing properties are ignored but Ramda is out of date
                                  "feeAdjustmentAmount",
                                  // $FlowFixMe missing properties are ignored but Ramda is out of date
                                  "feeAdjustmentNotes",
                                  "advanceOfferId",
                                ],
                                {
                                  ...advanceDrawdownRequest,
                                  advanceOfferId: advanceOffer.id,
                                },
                              );
                            const doDelete = async () => {
                              const result = await deleteDrawdownRequest({
                                variables: {
                                  id: advanceDrawdownRequest?.id,
                                },
                              });

                              if (result) {
                                this.props.onSubmit();
                              }
                            };
                            const create = async () => {
                              const result = await createDrawdownRequest({
                                variables: {
                                  input: {
                                    ...inputObjectFromProps(),
                                  },
                                },
                              });

                              if (result) {
                                this.props.onSubmit();
                              }
                            };

                            const update = async () => {
                              const result = await updateDrawdownRequest({
                                variables: {
                                  input: {
                                    ...inputObjectFromProps(),
                                    id: advanceDrawdownRequest?.id,
                                  },
                                },
                              });

                              if (result) {
                                this.props.onSubmit();
                              }
                            };

                            const updateAdvancesSection = () => {
                              switch (true) {
                                case drawdownRequestExists &&
                                  this.state.drawdownRequested:
                                  update();
                                  this.setIsEditing(false);
                                  break;
                                case !drawdownRequestExists &&
                                  this.state.drawdownRequested:
                                  create();
                                  this.setIsEditing(false);
                                  break;
                                case drawdownRequestExists &&
                                  !this.state.drawdownRequested:
                                  doDelete();
                                  this.setIsEditing(false);
                                  break;
                                case !drawdownRequestExists &&
                                  !this.state.drawdownRequested:
                                  this.setIsEditing(false);
                                  this.props.onCancel();
                                  break;
                                default:
                                  throw new Error("w a t");
                              }
                            };

                            return (
                              <SaveButton
                                data-test={`advance-drawdown-request-${advanceOffer.id}-save-button`}
                                onClick={() => {
                                  updateAdvancesSection();
                                  this.setIsEditing(false);
                                }}
                              >
                                Submit to Legal Services
                              </SaveButton>
                            );
                          }}
                        </ExtendedMutation>
                      )}
                    </ExtendedMutation>
                  )}
                </ExtendedMutation>
              </LeafCell>
              <LeafCell width={1}>
                <FullWidthCancelButton
                  data-test={`advance-drawdown-request-${advanceOffer.id}-cancel-button`}
                  onClick={() => {
                    if (drawdownRequestExists) {
                      this.setDrawdownRequested(true);
                      this.setIsEditing(false);
                      this.props.onCancel();
                    } else {
                      this.setIsEditing(false);
                      this.setDrawdownRequested(false);
                      this.props.onCancel();
                    }
                  }}
                >
                  Cancel
                </FullWidthCancelButton>
              </LeafCell>
            </>
          )}
      </Grid>
    );
  }
}

// We set the display name so that we can still switch on the component name in prod builds
AdvanceDrawdownRequest.displayName = "AdvanceDrawdownRequest";
