// @flow
import { DealStatusToggle } from "./DealStatusToggle";
import { DealStatusForms } from "./DealStatusForms";
import { useDealStatus } from "./useDealStatus";

export const DealStatus = ({ id, active }: { id: string, active: boolean }) => {
  const {
    closeForm,
    closeFormAndResetToggle,
    formType,
    isToggleYes,
    setIsToggleYes,
    switchToggleAndOpenFormOrReactivateDeal,
    titleOnHover,
  } = useDealStatus({ id, active });

  return (
    <div>
      <DealStatusToggle
        isToggleYes={isToggleYes}
        largeLabel
        onChange={switchToggleAndOpenFormOrReactivateDeal}
        titleOnHover={titleOnHover}
      />
      <DealStatusForms
        id={id}
        useModals
        formType={formType}
        closeForm={closeForm}
        closeFormAndResetToggle={closeFormAndResetToggle}
        setIsToggleYes={setIsToggleYes}
      />
    </div>
  );
};
