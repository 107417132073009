// @flow
type Props = {
  fill: string,
};

export const ExternalLink = ({ fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10.1249438,2.8125 C10.1249438,2.5018425 10.3768313,2.25 10.6874438,2.25 L15.1874438,2.25 C15.4981125,2.25 15.7499438,2.5018425 15.7499438,2.8125 L15.7499438,7.3125 C15.7499438,7.62316875 15.4981125,7.875 15.1874438,7.875 C14.8768313,7.875 14.6249438,7.62316875 14.6249438,7.3125 L14.6249438,4.17049313 L8.27274375,10.5227438 C8.05303125,10.7424 7.6969125,10.7424 7.4772,10.5227438 C7.25754375,10.3030875 7.25754375,9.9469125 7.4772,9.72725625 L13.8294563,3.375 L10.6874438,3.375 C10.3768313,3.375 10.1249438,3.1231575 10.1249438,2.8125 Z M2.25,6.1875 C2.25,5.25552187 3.00552187,4.5 3.9375,4.5 L7.3125,4.5 C7.62316875,4.5 7.875,4.7518425 7.875,5.0625 C7.875,5.3731575 7.62316875,5.625 7.3125,5.625 L3.9375,5.625 C3.6268425,5.625 3.375,5.87683125 3.375,6.1875 L3.375,14.0625 C3.375,14.3731687 3.6268425,14.625 3.9375,14.625 L11.8125,14.625 C12.1231687,14.625 12.375,14.3731687 12.375,14.0625 L12.375,10.6875 C12.375,10.3768313 12.6268313,10.125 12.9375,10.125 C13.2481687,10.125 13.5,10.3768313 13.5,10.6875 L13.5,14.0625 C13.5,14.9945063 12.7445063,15.75 11.8125,15.75 L3.9375,15.75 C3.00552187,15.75 2.25,14.9945063 2.25,14.0625 L2.25,6.1875 Z"
      transform="translate(-2 -2)"
    />
  </svg>
);

ExternalLink.defaultProps = {
  fill: "#FFFFFF",
};
