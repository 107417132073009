// @flow
import { PostVisitValuationPreDec18 } from "../PostVisitValuationsList/PostVisitValuationPreDec18";
import { PostVisitValuationDec18 } from "../PostVisitValuationsList/PostVisitValuationDec18";
import { FinalValuationPreOct18 } from "../AdvanceOfferValuationsList/FinalValuationPreOct18";
import { AdvanceOfferValuationOct18 } from "../AdvanceOfferValuationsList/AdvanceOfferValuationOct18";
import { PreVisitValuation } from "../PreVisitValuationsList/PreVisitValuation";
import { MiniValuation } from "../PreVisitValuationsList/MiniValuation";

type ValuationProps = {
  dealId: string,
  reasonsForAdvanceOfferValuation?: $ReadOnlyArray<AdvanceOfferValuationsListNestDealQuery_reasonsForAdvanceOfferValuation>,
  users: $ReadOnlyArray<
    | PostVisitValuationsListNestDealQuery_activeNestedUsers
    | PreVisitValuationsListQuery_activeNestedUsers
    | AdvanceOfferValuationsListNestDealQuery_activeNestedUsers,
  >,
  isReadOnly: boolean,
  valuation:
    | PostVisitValuationsListNestDealQuery_nestDeal_valuationList
    | PreVisitValuationsListQuery_nestDeal_valuationList
    | AdvanceOfferValuationsListNestDealQuery_nestDeal_valuationList,
};

export const Valuation = ({
  dealId,
  isReadOnly,
  reasonsForAdvanceOfferValuation,
  users,
  valuation,
}: ValuationProps) => {
  switch (valuation.__typename) {
    case "PostVisitValuationDec18":
      return (
        <PostVisitValuationDec18
          dealId={dealId}
          data-test="post-visit-valuation-dec-18"
          users={users}
          key={`${valuation.__typename}:${valuation.id}`}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    case "PostVisitValuationPreDec18":
      return (
        <PostVisitValuationPreDec18
          data-test="post-visit-valuation-pre-dec-18"
          key={`${valuation.__typename}:${valuation.id}`}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    case "FinalValuationPreOct18":
      return (
        <FinalValuationPreOct18
          dealId={dealId}
          data-test="final-valuation-pre-oct-18"
          key={`${valuation.__typename}:${valuation.id}`}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    case "AdvanceOfferValuationOct18":
      return (
        <AdvanceOfferValuationOct18
          dealId={dealId}
          data-test="advance-offer-valuation-oct-18"
          reasonsForAdvanceOfferValuation={reasonsForAdvanceOfferValuation}
          users={users}
          key={`${valuation.__typename}:${valuation.id}`}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    case "PreVisitValuation":
      return (
        <PreVisitValuation
          users={users}
          data-test="pre-visit-valuation"
          key={`${valuation.__typename}:${valuation.id}`}
          dealId={dealId}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    case "MiniValuation":
      return (
        <MiniValuation
          data-test="mini-valuation"
          key={`${valuation.__typename}:${valuation.id}`}
          valuation={valuation}
          isReadOnly={isReadOnly}
        />
      );
    default:
      throw new Error(
        `Unrecognised valuation typename: ${valuation.__typename}`,
      );
  }
};
