// @flow
import { MemoryRouter, Redirect, Route, Switch } from "react-router-dom";
import { useState } from "react";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { OverlayNav } from "../../../components/Overlay/Overlay";
import { backgroundStyle } from "../../../components/Modal";
import { XButton } from "../../../components/XButton";
import { Tabs, Tab } from "../../../components/Navigation/Tabs";
import { AreYouSureOverlay } from "../../../components/AreYouSureOverlay";
import { ContactManagement } from "../../../components/ContactManagement/ContactManagement";
import { DealStatusTab } from "./DealStatusTab";
import { AddressTab } from "./Address/AddressTab";

const overlayBackground = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 98;
  background: white;
  ${({ $open }) =>
    $open
      ? css`
          display: block;
          pointer-events: auto;
        `
      : css`
          display: none;
        `}
  ${media.tablet`
    ${backgroundStyle}
  `}
`;

const modalWindow = css`
  height: 100%;
  ${media.tablet`
    position: fixed;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    height: unset;
    max-height: 90%;
    width: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    ${({ $open }) =>
      $open
        ? css`
            transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
            transform: translateX(-50%) translateY(0px);
            opacity: 1;
          `
        : css`
            transition: opacity 300ms ease 0ms, visibility 300ms ease 0ms,
              transform 300ms ease 0ms;
            transform: translateX(-50%) translateY(3em);
            opacity: 0;
          `}

    `}
`;

export const maxHeightWrapper = css`
  height: calc(100% - 50px);
  overflow-y: auto;
  ${media.tablet`
    max-height: 453px;
  `}
`;

export const overlayPadding = css`
  padding: 0 20px;
  ${media.tablet`
    border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
    display: flex;
    justify-content: center;
    background-color: white;
    z-index: 1;
    padding: 12px 0 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  `}
`;

export const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

export const desktopOnly = css`
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

export const modalStyle = css`
  ${media.tablet`
    padding: 20px;
    margin-top: 53px;
    min-height: 400px;
  `}
`;

const TabWrapper = ({ children, onClose }) => (
  <>
    <XButton onClick={onClose} css={desktopOnly} />
    <OverlayNav heading="Edit deal" onClose={onClose} css={mobileOnly} />
    <div css={maxHeightWrapper}>
      <div css={overlayPadding}>
        <Tabs noBorderWhenNotSelected>
          <Tab to={`/contacts`}>Contacts</Tab>
          <Tab to={`/deal-status`}>Deal status</Tab>
          <Tab to={`/address`}>Address</Tab>
        </Tabs>
      </div>
      <div css={modalStyle}>{children}</div>
    </div>
  </>
);

type Props = {
  id: string,
  onClose: () => void,
  open: boolean,
  initialPath?: string,
};

export const ContactsModal = ({
  children,
  open,
  onClose,
  editing,
}: {
  children: any,
  open: boolean,
  onClose: () => void,
  editing: boolean,
}) => {
  const [areYouSure, setAreYouSure] = useState(false);

  const onCloseUnlessEditing = () => {
    if (editing) {
      setAreYouSure(true);
    } else {
      onClose();
    }
  };

  return (
    <div
      css={overlayBackground}
      $open={open}
      onClick={onCloseUnlessEditing}
      role="presentation"
    >
      <div
        onClose={onClose}
        $open={open}
        css={modalWindow}
        role="presentation"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
        {areYouSure && (
          <AreYouSureOverlay
            message="You have unsaved changes. Are you sure you want to stop editing this contact?"
            leftText="Continue editing"
            leftOnClick={() => setAreYouSure(false)}
            rightText="Discard changes"
            rightOnClick={onClose}
          />
        )}
      </div>
    </div>
  );
};

export const EditDealOverlay = ({
  id,
  open,
  onClose,
  initialPath = "/contacts",
}: Props) => {
  const [editing, setEditing] = useState(false);

  const closeModal = () => {
    onClose();
    setEditing(false);
  };

  return (
    <ContactsModal open={open} onClose={closeModal} editing={editing}>
      <MemoryRouter initialEntries={[initialPath]}>
        <Switch>
          <Route path="/deal-status">
            <TabWrapper onClose={onClose}>
              <DealStatusTab onClose={onClose} id={id} />
            </TabWrapper>
          </Route>
          <Route path="/contacts">
            <ContactManagement
              Wrapper={TabWrapper}
              dealId={id}
              onClose={onClose}
              editing={editing}
              setEditing={setEditing}
            />
          </Route>
          <Route path="/address">
            <TabWrapper onClose={onClose}>
              <AddressTab onClose={onClose} id={id} />
            </TabWrapper>
          </Route>
          <Redirect to="/contacts" />
        </Switch>
      </MemoryRouter>
    </ContactsModal>
  );
};
