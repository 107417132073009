import T from "prop-types";
import styled, { css } from "styled-components";
import { withHandlers } from "recompose";

const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  height: 24px;
  width: 48px;

  // Hide the actual checkbox thing
  input {
    display: none;
    font-size: 0px;
  }

  .slider {
    display: flex;
    justify-content: space-between;
    height: 24px;
    width: 48px;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.secondary.danger};
    transition: 0.4s;
    border-radius: 34px;
    line-height: 2;
    padding-top: 1px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: ${({ theme }) => theme.font.size.small};
    color: ${({ theme }) => theme.color.background.button};
    font-family: ${({ theme }) => theme.font.family.default};
    font-weight: 600;
  }

  .slider:before {
    position: absolute;
    content: "";
    bottom: 0px;
    height: 24px;
    width: 24px;
    left: -1px;
    background-color: ${({ theme }) => theme.color.background.muted};
    transition: 0.4s;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    z-index: 1;
  }

  input:checked + .slider {
    background-color: ${({ theme }) => theme.color.text.highlight};
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }
`;

const enhance = withHandlers({
  onChange:
    ({ value, onSubmit }) =>
    () =>
      onSubmit(!value),
});

const SwitchOption = styled.span`
  color: ${({ textColour }) => textColour};
  line-height: 1;
  position: absolute;
  top: 8px;
  user-select: none;
  ${({ secondOption }) =>
    secondOption &&
    css`
      right: 7px;
    `} ${({ secondOption }) =>
    !secondOption &&
    css`
      left: 8px;
    `};
`;

const SwitchComponent = ({
  "data-test": dataTest = "switch-button",
  value,
  sliderOnText = "YES",
  sliderOffText = "NO",
  textColour = "unset",
  onChange,
}) => (
  <StyledSwitch className="switch" data-test={dataTest}>
    <input type="checkbox" checked={value} onChange={onChange} />
    <span className="slider">
      <SwitchOption textColour={textColour}>{sliderOnText}</SwitchOption>
      <SwitchOption textColour={textColour} secondOption>
        {sliderOffText}
      </SwitchOption>
    </span>
  </StyledSwitch>
);

SwitchComponent.propTypes = {
  "data-test": T.string,
  value: T.bool,
  sliderOnText: T.string,
  sliderOffText: T.string,
  onChange: T.func.isRequired,
};

export const Switch = enhance(SwitchComponent);
