import { path } from "ramda";

export const updateDealTypeAdvanceJul2018Config = {
  props: ({ ownProps: { data }, mutate }) => {
    const dealTypeData = path(["nestDeal", "dealTypeAdvanceJul2018"], data);
    return {
      updateDealTypeAdvanceJul2018: (input) =>
        mutate({
          variables: { input, id: dealTypeData.id },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeAdvanceJul2018: {
              __typename: "DealTypeAdvanceJul2018",
              ...dealTypeData,
              ...input,
            },
          },
        }),
    };
  },
};
