import T from "prop-types";
import { LeafCell } from "components/Grid";

import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

const FeesUnderwriting = ({ nestDeal: { dealTypeUnderwriting } }) => (
  <>
    <LeafCell>
      <PercentageField
        property="baseFee"
        label="Base fee %"
        value={dealTypeUnderwriting.baseFee}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="noDrawDiscount"
        label="No draw discount %"
        value={dealTypeUnderwriting.noDrawDiscount}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeePerDay"
        label="Early payment fee % (per day)"
        value={dealTypeUnderwriting.earlyPaymentFeePerDay}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        property="earlyPaymentFeeFreePercent"
        label="Early payment fee free %"
        value={dealTypeUnderwriting.earlyPaymentFeeFreePercent}
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        property="referralDiscount"
        label="Referral discount"
        value={dealTypeUnderwriting.referralDiscount}
        readOnly
      />
    </LeafCell>
  </>
);

FeesUnderwriting.propTypes = {
  nestDeal: T.object,
};

export { FeesUnderwriting };
