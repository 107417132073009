export const changeDealTypeConfig = {
  props({ mutate, ownProps: { parentId, data } }) {
    const { dealTypes } = data;
    return {
      changeDealType: (input) =>
        mutate({
          variables: {
            id: parentId,
            input,
          },
          updateQueries: {
            DealType(_, { queryVariables, mutationResult }) {
              return {
                nestDeal: {
                  id: queryVariables.id,
                  ...mutationResult.data.changeDealType,
                },
                dealTypes,
              };
            },
          },
        }),
    };
  },
};
