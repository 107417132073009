// @flow
import { useState, useRef } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { css } from "styled-components";
import { useCombobox } from "downshift";
import { faSearch, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextInput } from "../../components/TextInput/TextInput";
import { Button } from "../../components/Button/Button";
import { listStyle, listItemStyle } from "../../components/Select/Select";

const wrapperStyle = css`
  flex-grow: 1;
  display: inline-block;
  padding-top: 5px;
`;

const inputStyle = css`
  input:focus {
    border-color: ${({ theme }) => theme.palette.hague40};
  }

  input::placeholder {
    color: ${({ theme }) => theme.palette.hague40};
    font-weight: 400;
    line-height: 18px;
  }

  input {
    cursor: pointer;
    ${({ open }) =>
      open &&
      css`
        cursor: initial;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `};
  }
`;

const locationPillStyle = css`
  display: inline-flex;
  align-items: center;
  margin: 5px 10px 0 0;
  border-radius: 3px;
  padding: 4px 6px;
  width: auto;
  height: 32px;
`;

const cancelIconStyle = css`
  color: ${({ theme }) => theme.palette.pink300};
`;

const tickIconStyle = css`
  color: ${({ theme }) => theme.palette.green100};
  margin-right: 10px;
`;

const localAuthorityDistrictStyle = css`
  font-size: 12px;
  opacity: 0.5;
`;

export const GET_ALL_LOCATIONS_QUERY = gql`
  query GetAllBuyerLocationsQueryListViews($input: String!) {
    searchAreas(input: $input) {
      id
      name
      localAuthorityDistrict
    }
  }
`;

export const ADD_LOCATION_MUTATION = gql`
  mutation AddSearchAreaToBuyerListViews($buyerId: ID!, $searchAreaId: ID!) {
    addSearchAreaToBuyer(buyerId: $buyerId, searchAreaId: $searchAreaId) {
      id
      searchAreas {
        id
        name
      }
    }
  }
`;

export const GET_BUYER_SAVED_SEARCHES_QUERY = gql`
  query GetBuyerListViews($id: ID!) {
    buyer(id: $id) {
      id
      searchAreas {
        id
        name
      }
    }
  }
`;

export const DELETE_SAVED_SEARCH_AREA_MUTATION = gql`
  mutation DeleteSearchAreaFromBuyerListViews(
    $buyerId: ID!
    $searchAreaId: ID!
  ) {
    deleteSearchAreaFromBuyer(buyerId: $buyerId, searchAreaId: $searchAreaId) {
      id
      searchAreas {
        id
        name
      }
    }
  }
`;

const MenuItems = ({
  loading,
  items,
  inputValue,
  error,
  highlightedIndex,
  getItemProps,
  selectedAreaIds,
}) => {
  if (inputValue === "" && items.length === 0) {
    return <li css={listItemStyle}>Start typing to find a location</li>;
  }

  if (loading) {
    return <li css={listItemStyle}>Loading...</li>;
  }

  if (error) {
    return (
      <li css={listItemStyle}>Failed to load areas. Please try again later</li>
    );
  }
  if (inputValue.length > 0 && !loading && items.length === 0) {
    return <li css={listItemStyle}>No locations found</li>;
  }

  return items.map((item, index) => {
    const selected = selectedAreaIds.includes(item.id);
    return (
      <li
        data-test={`buyer-search-area-select-item-${item.id}`}
        css={listItemStyle}
        highlight={index === highlightedIndex}
        key={item.id}
        {...getItemProps({ index, item })}
        selected={selected}
      >
        <div>
          <div>{item.name}</div>
          {item.localAuthorityDistrict && (
            <div css={localAuthorityDistrictStyle}>
              {item.localAuthorityDistrict}
            </div>
          )}
        </div>
        {selected && <FontAwesomeIcon css={tickIconStyle} icon={faCheck} />}
      </li>
    );
  });
};

export const BuyerSearchAreaSelect = ({ buyerId }: { buyerId: string }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(); // need to pass this to Input via getInputProps, otherwise Up Arrow/Down Arrow/Esc/Enter keys dont work after item is clicked

  const [addSearchAreaToBuyer] = useMutation(ADD_LOCATION_MUTATION);
  const [deleteSearchAreaFromBuyer] = useMutation(
    DELETE_SAVED_SEARCH_AREA_MUTATION,
  );
  const { loading, error, data } = useQuery(GET_BUYER_SAVED_SEARCHES_QUERY, {
    variables: {
      id: buyerId,
    },
  });

  const {
    previousData,
    loading: itemsLoading,
    error: itemsError,
    data: itemsData = previousData,
  } = useQuery(GET_ALL_LOCATIONS_QUERY, {
    variables: {
      input: inputValue,
    },
    skip: inputValue === "",
  });

  const items = itemsData?.searchAreas || [];
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items,
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        // Keeps the menu open when an item is selected
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedAreaIds.includes(changes.selectedItem.id)) {
            deleteSearchAreaFromBuyer({
              variables: {
                buyerId,
                searchAreaId: changes.selectedItem.id,
              },
            });
          }
          return {
            ...changes,
            isOpen: true,
            highlightedIndex: state.highlightedIndex,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onInputValueChange: (changes) => {
      setInputValue(changes.inputValue);
    },
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) {
        return;
      }

      if (selectedAreaIds.includes(changes.selectedItem.id)) {
        return;
      }

      addSearchAreaToBuyer({
        variables: {
          buyerId,
          searchAreaId: changes.selectedItem.id,
        },
      });
    },
  });

  if (loading || error) {
    return null;
  }

  const selectedAreaIds = data?.buyer?.searchAreas.map(({ id }) => id) || [];
  return (
    <>
      <div css="position: relative;" {...getComboboxProps()}>
        <TextInput
          data-test="buyer-search-area-select-field"
          icon={faSearch}
          open={isOpen}
          {...getToggleButtonProps()}
          {...getInputProps({
            ref: inputRef,
            refKey: "forwardedRef",
          })}
          placeholder="Outcodes, boroughs, or districts"
          label="Search locations"
          css={inputStyle}
          valid={selectedAreaIds.length > 0}
        />
        <ul open={isOpen} {...getMenuProps()} css={listStyle}>
          <MenuItems
            loading={itemsLoading}
            error={itemsError}
            inputValue={inputValue}
            getItemProps={getItemProps}
            items={items}
            highlightedIndex={highlightedIndex}
            selectedAreaIds={selectedAreaIds}
          />
        </ul>
      </div>
      <div css={wrapperStyle}>
        {data.buyer.searchAreas.map(({ id, name }) => (
          <Button
            data-test={`saved-search-area/delete-btn-${id}`}
            css={locationPillStyle}
            iconCSS={cancelIconStyle}
            onClick={() => {
              // eslint-disable-next-line no-alert
              const doIt = window.confirm(
                `Are you sure you want to remove ${name}?`,
              );
              if (!doIt) return;

              deleteSearchAreaFromBuyer({
                variables: {
                  buyerId,
                  searchAreaId: id,
                },
              });
            }}
            key={id}
            buttonStyle="pink"
            icon={faTimes}
          >
            {name}
          </Button>
        ))}
      </div>
    </>
  );
};
