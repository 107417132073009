// @flow
import { useEffect } from "react";
import { css } from "styled-components";
import { gql, useQuery } from "@apollo/client";
import { useUser } from "@nested/nest/src/hooks/useUser";
import { useSorting } from "@nested/nest/src/hooks/useSorting";
import { useListViewCounts } from "@nested/nest/src/hooks/useListViewCounts";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";
import { Select } from "../../components/Select/Select";
import { NoResults } from "../../components/NoResults";
import { ListViewError } from "../../components/ListViewError";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import { getSmiley, getTitle } from "./ListedListView";
import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ImageAndAddress,
  rowWithImage,
  Progressor,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import { prettyFormat } from "./utils";
import { getFullName } from "../utils";

const UNDER_OFFER_DATE = "UNDER_OFFER_DATE";
const SELLER_NAME = "SELLER_NAME";

const MOBILE_SORT_OPTIONS = [
  { label: "Under offer date", value: UNDER_OFFER_DATE },
  { label: "Seller name", value: SELLER_NAME },
];

export const UNDER_OFFER_LIST_VIEW_QUERY = gql`
  query UnderOfferListViewQuery(
    $sortBy: UnderOfferListViewSortBy!
    $sortDirection: ListViewSortDirection!
    $email: String!
  ) {
    underOfferDeals(
      sortBy: $sortBy
      sortDirection: $sortDirection
      email: $email
    ) {
      results {
        id
        externalId
        firstName
        lastName
        ownerName
        address
        expectedFeeAmount
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
          }
          avatarUrl
        }
        acceptedBuyerOffer {
          id
          placedOn
          latestRenegotiatedOfferAmount
        }
        dealTypeAgency {
          id
          baseFee
          referralDiscount
        }
        assignedProgressor {
          id
          firstName
          email
        }
        latestCustomerSurveyResponse {
          id
          score
          comment
          insertedAt
        }
      }
    }
  }
`;

const csatStyles = css`
  text-align: center;
  white-space: nowrap;
  width: 24px;
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0px;
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          displayImage
          key={deal.externalId}
          externalId={deal.externalId}
          address={deal.address}
          sellerName={getFullName(deal)}
          contacts={deal.contacts}
          detailText={
            deal.acceptedBuyerOffer
              ? `Under offer ${formatDateDifference(
                  deal.acceptedBuyerOffer.placedOn,
                )}`
              : "-"
          }
          detailTextTitle={
            deal.acceptedBuyerOffer
              ? prettyFormat(deal.acceptedBuyerOffer.placedOn)
              : null
          }
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field={SELLER_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <SortableTH
              field={UNDER_OFFER_DATE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Under offer
            </SortableTH>
            <SortableTH
              field={"SALE_PRICE"}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Sale price
            </SortableTH>
            <SortableTH
              field={"FEE"}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Fee
            </SortableTH>
            <SortableTH
              field={"PROGRESSOR"}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Progressor
            </SortableTH>
            <SortableTH
              field="LATEST_CSAT"
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              CSAT
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => (
            <tr
              key={deal.externalId}
              data-test={`deal-row-${deal.externalId}`}
              css={rowWithImage}
            >
              <DealTD dealId={deal.externalId}>
                <ImageAndAddress
                  externalId={deal.externalId}
                  address={deal.address}
                />
              </DealTD>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {getFullName(deal)}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={
                  deal.acceptedBuyerOffer
                    ? prettyFormat(deal.acceptedBuyerOffer.placedOn)
                    : null
                }
              >
                {deal.acceptedBuyerOffer
                  ? formatDateDifference(deal.acceptedBuyerOffer.placedOn)
                  : "Under offer"}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {deal.acceptedBuyerOffer
                  ? formatPrice(
                      parseFloat(
                        deal.acceptedBuyerOffer.latestRenegotiatedOfferAmount,
                      ),
                    )
                  : "-"}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {deal.expectedFeeAmount
                  ? formatPrice(parseFloat(deal.expectedFeeAmount))
                  : "-"}
              </DealTD>

              <DealTD dealId={deal.externalId}>
                <Progressor progressor={deal.assignedProgressor} />
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={getTitle(deal.latestCustomerSurveyResponse)}
                css={csatStyles}
              >
                {deal.latestCustomerSurveyResponse
                  ? getSmiley(deal.latestCustomerSurveyResponse.score)
                  : "-"}
              </DealTD>
              <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                <ListViewCallButton
                  contacts={deal.contacts}
                  externalId={deal.externalId}
                  relationType={"deal"}
                />
              </DealTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const UnderOfferListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    UNDER_OFFER_DATE,
    "ASCENDING",
  );
  const { selectedUser } = useUser();
  const { underOffer } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(UNDER_OFFER_LIST_VIEW_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [underOffer]);

  const results = data?.underOfferDeals?.results || [];

  if (!loading && results.length === 0) {
    return <NoResults message="You have no properties under offer." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
