// @flow
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import qs from "query-string";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import { isEmpty } from "lodash";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { TextInput } from "../TextInput/TextInput";
import { Button } from "../Button/Button";
import { formStyles, fieldStyles, submitButtonWrapper } from "./EditContact";
import { TabHeader } from "../../pages/Deal/EditDeal/TabHeader";
import { AreYouSureOverlay } from "../AreYouSureOverlay";

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $buyerId: ID
    $dealId: ID
    $input: CreateContactInput!
  ) {
    createContact(buyerId: $buyerId, dealId: $dealId, input: $input) {
      id
    }
  }
`;

const validate = ({ firstName, lastName }) => {
  let errors = {};
  if (!firstName || firstName === "") {
    errors = { ...errors, firstName: "First name is required" };
  }
  if (!lastName || lastName === "") {
    errors = { ...errors, lastName: "Last name is required" };
  }
  return isEmpty(errors) ? undefined : errors;
};

type Props = {
  buyerId?: string,
  dealId?: string,
  editing: boolean,
  onClose(): void,
  setEditing: (boolean) => void,
};

export const CreateContact = ({
  buyerId,
  dealId,
  editing,
  onClose,
  setEditing,
}: Props) => {
  const { search } = useLocation();
  const history = useHistory();
  const { email = "" } = qs.parse(search);
  const [createContact] = useMutation(CREATE_CONTACT);
  const { createNotification } = useNotifications();
  const [areYouSure, setAreYouSure] = useState(false);
  useEffect(() => {
    setEditing(true);
  });

  const goBack = () => {
    setEditing(false);
    history.push("/contacts/search");
  };

  const goBackUnlessEditing = () => {
    if (editing) {
      setAreYouSure(true);
    } else {
      goBack();
    }
  };

  const onSubmit = async (state) => {
    try {
      const { data } = await createContact({
        variables: {
          input: state,
          dealId,
          buyerId,
        },
      });
      if (data?.createContact) {
        createNotification("Changes saved");
        history.push("/contacts");
      }
      return undefined;
    } catch (e) {
      errorHandler(e);
      return {
        [FORM_ERROR]: "Failed to create contact",
      };
    }
  };

  return (
    <>
      <TabHeader
        headerText="Add a contact"
        onBack={goBackUnlessEditing}
        onClose={onClose}
        withBackButton={!areYouSure}
      />
      {areYouSure && (
        <AreYouSureOverlay
          message="You have unsaved changes. Are you sure you want to stop editing this contact?"
          leftText="Continue editing"
          leftOnClick={() => setAreYouSure(false)}
          rightText="Discard changes"
          rightOnClick={goBack}
        />
      )}
      <Form
        validate={validate}
        initialValues={{
          firstName: "",
          lastName: "",
          email,
          phoneNumber: "",
          title: "",
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, submitting, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <div css={formStyles}>
              <Field name="firstName">
                {({ input }) => (
                  <div css={fieldStyles}>
                    <TextInput
                      {...input}
                      label="First name"
                      className="fs-exclude"
                    />
                  </div>
                )}
              </Field>
              <Field name="lastName">
                {({ input }) => (
                  <div css={fieldStyles}>
                    <TextInput
                      {...input}
                      label="Last name"
                      className="fs-exclude"
                    />
                  </div>
                )}
              </Field>
              <Field name="email">
                {({ input }) => (
                  <div css={fieldStyles}>
                    <TextInput
                      {...input}
                      disabled
                      label="Email"
                      className="fs-exclude"
                    />
                  </div>
                )}
              </Field>
              <Field name="phoneNumber">
                {({ input }) => (
                  <div css={fieldStyles}>
                    <TextInput
                      {...input}
                      label="Contact number"
                      data-test="create-contact-phone"
                      className="fs-exclude"
                      showOptionalLabel
                    />
                  </div>
                )}
              </Field>
              <Field name="title">
                {({ input }) => (
                  <div css={fieldStyles}>
                    <TextInput
                      {...input}
                      showOptionalLabel
                      label="Description"
                      className="fs-exclude"
                      data-test="create-contact-description"
                    />
                  </div>
                )}
              </Field>
            </div>
            <div css={submitButtonWrapper}>
              <Button
                data-test="create-contact-submit"
                large
                buttonStyle="pink"
                type="submit"
                disabled={submitting || hasValidationErrors}
                css="&:disabled { opacity: 0.3 }"
              >
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};
