// @flow
import moment from "moment";
import styled, { css } from "styled-components";

const snoozeUntil = css`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague100};
  margin-bottom: 10px;
`;

const datePickerButtonsWrapper = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DatePickerButton = styled.button`
  min-width: 32%;
  background-color: white;
  height: 32px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague100};
  margin-bottom: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  cursor: pointer;
  &:active,
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.hague100};
  }
`;

const createDate = (
  n: number,
  increment: "days" | "weeks" | "months",
): string => {
  return moment().startOf("day").add(n, increment).toDate();
};

export const SnoozeButtons = ({
  onClick,
}: {
  onClick: (date: Date) => void | Promise<void>,
}) => {
  return (
    <>
      <div css={snoozeUntil}>Snooze until...</div>
      <div css={datePickerButtonsWrapper}>
        {["days", "weeks", "months"].map((time) =>
          [1, 2, 3].map((count) => {
            return (
              <DatePickerButton
                key={`snooze-button-${count}${time[0]}`}
                onClick={() => {
                  onClick(createDate(count, time));
                }}
              >
                {`${count} ${time[0]}`}
              </DatePickerButton>
            );
          }),
        )}
      </div>
    </>
  );
};
