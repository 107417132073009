// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import styled from "styled-components";

import { Grid, LeafCell } from "components/Grid";
import { RadioButtons } from "components/RadioButtons";
import { TextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { noneMajorMinorOptions } from "@nest-ui/shared/options";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const PROPERTY_QUESTIONNAIRE_QUERY = gql`
  query PropertyQuestionnaireQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      propertyQuestionnaireIssues
      propertyQuestionnaireIssuesComments
    }
  }
`;
const PROPERTY_QUESTIONNAIRE_MUTATION = gql`
  mutation UpdatePropertyQuestionnaire($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      propertyQuestionnaireIssues
      propertyQuestionnaireIssuesComments
    }
  }
`;

const CellAlignedTop = styled(LeafCell)`
  align-items: start;
`;

export function PropertyQuestionnaire({
  parentId: externalDealId,
}: {|
  parentId: string,
|}) {
  return (
    <ExtendedQuery
      query={PROPERTY_QUESTIONNAIRE_QUERY}
      variables={{ id: externalDealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<PropertyQuestionnaireQuery>) => (
        <ExtendedMutation mutation={PROPERTY_QUESTIONNAIRE_MUTATION}>
          {(
            mutation: MutationFunction<
              UpdatePropertyQuestionnaire,
              UpdatePropertyQuestionnaireVariables,
            >,
          ) => {
            const updatePropertyQuestionnaire = (input) =>
              mutation({
                variables: {
                  id: nestDeal.id,
                  input,
                },
                optimisticResponse: {
                  updateNestDeal: {
                    ...nestDeal,
                    ...input,
                  },
                },
              });
            return (
              <Grid columns={2}>
                <CellAlignedTop width={1}>
                  <RadioButtons
                    data-test="property-questionnaire:property-questionnaire-issues"
                    label="Issues identified in property questionnaire?"
                    mutation={updatePropertyQuestionnaire}
                    options={noneMajorMinorOptions}
                    property="propertyQuestionnaireIssues"
                    value={nestDeal.propertyQuestionnaireIssues}
                  />
                </CellAlignedTop>

                <LeafCell width={1}>
                  <TextareaWithLinks
                    data-test="property-questionnaire:property-questionnaire-issues-comments"
                    label="Comments"
                    mutation={updatePropertyQuestionnaire}
                    property="propertyQuestionnaireIssuesComments"
                    value={nestDeal.propertyQuestionnaireIssuesComments}
                  />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
}
