// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Checkbox } from "../Checkbox/Checkbox";

const checkboxStyle = css`
  align-self: auto;
  width: 30%;

  ${media.tablet`
    align-self: auto;
    width: 16%;
  `}
`;

export const UPDATE_VIEWING_COMMS_MUTATION = gql`
  mutation UpdateViewingComms($id: ID!, $input: UpdateContactInput!) {
    updateContact(input: $input, id: $id) {
      id
      viewingCommsEnabled
    }
  }
`;

type Props = {
  id: string,
  viewingCommsEnabled: boolean,
};

export const CommsCheckbox = ({ id, viewingCommsEnabled }: Props) => {
  const [updateContact, { error: mutationError }] = useMutation(
    UPDATE_VIEWING_COMMS_MUTATION,
  );

  return (
    <div css={checkboxStyle}>
      <Checkbox
        id={`contact-${id}`}
        checked={viewingCommsEnabled}
        onChange={async () => {
          try {
            const result = await updateContact({
              variables: {
                id,
                input: { viewingCommsEnabled: !viewingCommsEnabled },
              },
              optimisticResponse: {
                updateContact: {
                  __typename: "Contact",
                  id,
                  viewingCommsEnabled: !viewingCommsEnabled,
                },
              },
            });

            if (!result?.data?.updateContact?.id) {
              errorHandler(mutationError);
            }
          } catch (error) {
            errorHandler(error);
          }
        }}
      />
    </div>
  );
};
