// @flow

import { gql } from "@apollo/client";

export const reconciledMutation = gql`
  mutation ReconciledAndSignedOff(
    $input: ReconciledAndSignedOffInput!
    $id: ID!
  ) {
    reconciledAndSignedOff(id: $id, input: $input) {
      id
      propertySaleReconciledAndSignedOff
    }
  }
`;
