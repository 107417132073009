// @flow
import { css } from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useRef, useState, useEffect, useMemo } from "react";
import { Form, Field, useForm, FormSpy } from "react-final-form";
import {
  CustomMessageField,
  SubjectField as Subject,
  validateSubjectField,
  EmailFormWrapper as FormWrapper,
} from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/ComposeMessage";
import { PropertyMatchModal } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/PotentialBuyers";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { useHandoff } from "../../../components/MobileActionSheet/useHandoff";
import { Select } from "../../../components/Select/Select";
import { Button } from "../../../components/Button/Button";
import { BUYER_SUMMARY_QUERY } from "../BuyerSummaryBar/BuyerSummaryQuery";
import { Preview } from "./Preview";
import { getPrefills, getSuggestedPrefills, getSituationData } from "./utils";
import { useUser } from "../../../hooks/useUser";

export const fieldsWrapper = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const previewButtonStyle = css`
  text-align: left;
  margin-bottom: 20px;
  ${media.tablet`
    display: none;
  `}
`;

// first-child fuckery in the css below is because we use existing EmailFormWrapper.
// The only way I could find to override conflicting styling is by committing the fuckery.
export const modalWrapper = css`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${media.tablet`
  flex-direction: row;
  `}
  > *:first-child {
    ${media.tablet`
      width: 100%;
      min-height: 100%;
      overflow: auto;
    `}
  }
}
`;

const mobileButtonsWrapper = css`
  width: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.palette.hague100};
  ${media.tablet`
    display: none;
  `}
`;

const desktopButtonStyle = css`
  margin: 20px 0;
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

export const TODAYS_ENQUIRIES_AND_FEEDBACK = gql`
  query TodaysEnquiryAndFeedback($email: String!) {
    awaitingFeedback(email: $email) {
      results {
        id
        deal {
          id
          externalId
        }
      }
    }
    enquiries(email: $email) {
      results {
        id
        deal {
          id
          externalId
        }
      }
    }
  }
`;

export const EMAIL_BUYER_MUTATION = gql`
  mutation SendEmailToBuyer($input: SendEmailToBuyerInput!) {
    sendEmailToBuyer(input: $input) {
      success
    }
  }
`;

const validateBody = (input) => (input ? undefined : ["Required"]);

type PropertyType = {|
  externalId: string,
  image: string,
  bedrooms: number,
  address: {|
    borough: string,
    lat: number,
    lng: number,
    outcode: string,
    street: string,
  |},
  price: number,
  status: string,
  rightmoveLink: string,
|};
export type BuildSmsTypes = {
  body: string,
  buyerFirstName: string,
  senderName: string,
  properties: PropertyType[],
};

export const buildSms = ({
  body,
  buyerFirstName,
  senderName,
  properties,
}: BuildSmsTypes) => {
  const propertyDetails = properties.map(
    ({ address, price, rightmoveLink }) =>
      `${address.street} ${address.borough}, ${address.outcode} - ${formatPrice(
        price,
      )}:\n${rightmoveLink}`,
  );

  const paragraphs = [
    `Hi ${buyerFirstName},`,
    body,
    "Thanks,",
    `${senderName} @ Nested`,
    ...propertyDetails,
  ];

  return paragraphs.join("\n\n");
};

const SituationField = ({
  todaysEnquiries,
  todaysFeedback,
  buyer,
  workflow,
}) => {
  const {
    mutators: { setFormState },
  } = useForm();
  // dont want to be calling this every time user types something in the body field
  const { validOptions, buyerEnquiries, buyerFeedback, snoozedBpi } = useMemo(
    () => getSituationData(buyer, todaysEnquiries, todaysFeedback, workflow),
    [buyer, todaysEnquiries, todaysFeedback, workflow],
  );

  useEffect(() => {
    const getRecommendedPrefill = async () => {
      const { body, properties, situation, subject } =
        await getSuggestedPrefills(
          buyerEnquiries,
          buyerFeedback,
          workflow,
          snoozedBpi,
        );

      setFormState({ body, properties, situation, subject });
    };

    getRecommendedPrefill();
  }, []);

  if (snoozedBpi) return null;

  return (
    <div css="margin-top: 20px;">
      <Field name="situation" type="select">
        {({ input, meta }) => {
          return (
            <Select
              options={validOptions}
              {...input}
              {...meta}
              onChange={async (selectedSituation) => {
                const { body, properties, situation, subject } =
                  await getPrefills(
                    buyerEnquiries,
                    buyerFeedback,
                    selectedSituation,
                  );
                setFormState({
                  body,
                  properties,
                  situation,
                  subject,
                });
              }}
              labelAbove
              label="Situation"
              css="p {color: white}"
            />
          );
        }}
      </Field>
      <p css="margin-top: 8px; font-size: 12px;">
        Only relevant templates are listed
      </p>
    </div>
  );
};

export const SubjectField = () => (
  <div>
    <Field name="subject" type="text" validate={validateSubjectField}>
      {({ input, meta }) => (
        <Subject
          id="message-buyer-subject-input"
          label={"Email subject"}
          {...input}
          {...meta}
          errors={meta.error}
        />
      )}
    </Field>
  </div>
);

export const BodyField = ({
  previewState,
  setPreviewState,
}: {
  previewState: Object,
  setPreviewState: (arg: Object) => void,
}) => (
  <div>
    <Field
      data-test="body-field"
      name="body"
      type="text"
      validate={validateBody}
    >
      {({ input, meta }) => (
        <CustomMessageField
          id="message-buyer-body-input"
          multiline
          minRows={5}
          label={"Customise message"}
          {...input}
          {...meta}
          onChange={(e) => {
            input.onChange(e);
            setPreviewState({
              ...previewState,
              body: e.currentTarget.value,
            });
          }}
        />
      )}
    </Field>
  </div>
);

export const PreviewButton = ({ previewRef }: { previewRef: any }) => (
  <Button
    icon={faEye}
    buttonStyle="outline"
    large
    css={previewButtonStyle}
    type="button"
    onClick={() =>
      previewRef.current?.scrollIntoView({
        behaviour: "smooth",
      })
    }
  >
    Preview message
  </Button>
);

export const SendButton = ({
  disabled,
  children,
  onClick,
  className,
}: {
  disabled: boolean,
  children: any,
  onClick?: () => void,
  className?: string,
}) => (
  <Button
    className={className}
    disabled={disabled}
    buttonStyle="pink"
    large
    type="submit"
    data-test="send-email-sms-button"
    onClick={() => onClick && onClick()}
  >
    {children}
  </Button>
);

const MobileButtons = ({ formRef, sending, formApi }) => {
  return (
    <div css={mobileButtonsWrapper}>
      <Button
        icon={faPen}
        buttonStyle="outline"
        large
        css={previewButtonStyle}
        type="button"
        onClick={() =>
          formRef.current?.scrollIntoView({
            behaviour: "smooth",
            block: "end",
          })
        }
      >
        Continue editing
      </Button>
      <SendButton
        disabled={
          sending || formApi?.submitting || formApi?.hasValidationErrors
        }
        onClick={() => {
          formApi?.submit();
        }}
      >
        {sending ? "Sending..." : "Send email and continue to SMS"}
      </SendButton>
    </div>
  );
};

type Props = {
  open: boolean,
  onClose: () => void,
  buyer: BuyerSummary_buyer,
  selectedUserEmail: string,
  selectedUserName: string,
  workflow?: ?string,
};

export const MessageBuyerModal = ({
  open,
  onClose,
  buyer,
  selectedUserEmail,
  selectedUserName,
  workflow,
}: Props) => {
  const { openMobileActionSheet } = useHandoff();
  const { selectedUser } = useUser();
  const [formApi, setFormApi] = useState(null);
  const formRef = useRef(null);

  const { data, loading, error } = useQuery(TODAYS_ENQUIRIES_AND_FEEDBACK, {
    variables: { email: selectedUserEmail },
  });
  const [sendEmail, { loading: sending }] = useMutation(EMAIL_BUYER_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: BUYER_SUMMARY_QUERY, variables: { id: buyer.id } },
    ],
  });

  const { createNotification } = useNotifications();
  const previewRef = useRef();
  const [previewState, setPreviewState] = useState({
    body: "",
    properties: [],
  });

  const smsMessage = useMemo(
    () =>
      buildSms({
        body: previewState.body,
        buyerFirstName: buyer.firstName,
        senderName: selectedUserName,
        properties: previewState.properties,
      }),
    [previewState.body, buyer, selectedUserName, previewState.properties],
  );

  const sendMessage = async ({ subject, body }) => {
    const variables = {
      input: {
        body,
        buyerId: buyer.id,
        dealIds: previewState.properties.map((p) => p.externalId),
        senderEmail: selectedUserEmail,
        subject,
      },
    };

    try {
      const result = await sendEmail({ variables });

      if (result?.data?.sendEmailToBuyer?.success) {
        createNotification("Email sent");
        onClose();
        openMobileActionSheet({
          commsType: "sms",
          relationType: "buyer",
          id: buyer.id,
          initiatedByHandoff: true,
          smsBody: smsMessage,
          selectedUser: selectedUser.email,
          contacts: buyer.contacts,
        });
        return;
      }

      if (result?.data?.sendEmailSmsToBuyer?.success) {
        createNotification("Message delivered");
        onClose();
        return;
      }

      if (result?.errors) {
        throw result?.errors;
      }
    } catch (e) {
      errorHandler(e);
    }
  };
  // This function is passed as a mutators prop to Form component and allows manual update of the form fields.
  // Function can be retrieved using useForm hook, field to be updated can be retrieved by using field names.
  const setFormState = (
    [{ body, properties, situation, subject }],
    state,
    { changeValue },
  ) => {
    changeValue(state, "body", () => body);
    changeValue(state, "subject", () => subject);
    changeValue(state, "situation", () => situation);
    setPreviewState({
      ...previewState,
      body,
      properties,
    });
  };

  if (loading || error) return null;

  const {
    enquiries: { results: todaysEnquiries },
    awaitingFeedback: { results: todaysFeedback },
  } = data;

  return (
    <>
      <PropertyMatchModal isOpen={open} closeModal={onClose}>
        {open && (
          <div css={modalWrapper} id="message-buyer-modal">
            <Form onSubmit={sendMessage} mutators={{ setFormState }}>
              {({
                handleSubmit,
                submitting,
                hasValidationErrors,
                form: { submit },
              }) => {
                // form reference to access for outside
                return (
                  <FormWrapper
                    ref={formRef}
                    onCancel={onClose}
                    title="Message Buyer"
                  >
                    <form
                      data-test="email-sms-form"
                      onSubmit={handleSubmit}
                      css={fieldsWrapper}
                    >
                      <div>
                        <SituationField
                          todaysEnquiries={todaysEnquiries}
                          todaysFeedback={todaysFeedback}
                          workflow={workflow}
                          buyer={buyer}
                        />
                        <SubjectField />
                        <BodyField
                          previewState={previewState}
                          setPreviewState={setPreviewState}
                        />
                      </div>
                      {/* This thing uses most up-to-date form values (equivalent to using event.target.value). Also allows to store form in react state without spiralling into re-render loop */}
                      <FormSpy
                        subscription={{
                          hasValidationErrors: true,
                          submitting: true,
                        }}
                        onChange={(changes) => {
                          setFormApi({ ...changes, submit });
                        }}
                      />
                      <PreviewButton previewRef={previewRef} />
                      <SendButton
                        css={desktopButtonStyle}
                        disabled={sending || submitting || hasValidationErrors}
                      >
                        {sending
                          ? "Sending..."
                          : "Send email and continue to SMS"}
                      </SendButton>
                    </form>
                  </FormWrapper>
                );
              }}
            </Form>
            <div css="width: 100%" ref={previewRef}>
              <Preview
                smsText={smsMessage}
                body={previewState.body}
                firstName={buyer.firstName}
                senderName={selectedUserName}
                properties={previewState.properties}
              />
              <MobileButtons
                sending={sending}
                formApi={formApi}
                formRef={formRef}
              />
            </div>
          </div>
        )}
      </PropertyMatchModal>
    </>
  );
};
