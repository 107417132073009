// @flow
import { css } from "styled-components";
import { faTasks, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media } from "@nested/brand";

export const navRefStyle = css`
  height: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 0;
`;

export const calculateTasksDue = (
  updatedTaskCountData: AgentTaskDealCountChanged | AgentTaskBuyerCountChanged,
  initialTaskCountData: DealTasksCount | BuyerTasksCount,
) => {
  if (
    Number.isInteger(
      updatedTaskCountData?.agentTasksForReferenceCountChanged?.due,
    )
  ) {
    return updatedTaskCountData?.agentTasksForReferenceCountChanged?.due;
  }
  return initialTaskCountData?.agentTaskCounts?.forReference?.due || 0;
};

const wrapperStyle = css`
  position: relative;
`;

const iconWrapperStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40px;
`;

const taskButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 0;
  border-radius: 5px;
  height: 30px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  width: 100%;

  background-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.blue150 : "white"};
  border: 1px solid
    ${({ theme, $selected }) =>
      $selected ? theme.palette.blue150 : theme.palette.hague40};
  color: ${({ theme, $selected }) =>
    $selected ? "white" : theme.palette.hague70};

  ${media.tablet`
    display: flex;
    border: 2px solid ${({ theme, $selected }) =>
      $selected ? theme.palette.blue150 : theme.palette.hague20};
    border-radius: 10px;
    width: 40px;

    &:hover {
      // Using a non-theme colour because there's no good theme colour for the
      // blue we want when hovering on a selected button. Signed off by Q!
      background-color: ${({ $selected }) => ($selected ? "#1773C5" : "white")};
      border: 2px solid ${({ theme, $selected }) =>
        $selected ? "#1773C5" : theme.palette.hague40};
      color: ${({ theme, $selected }) =>
        $selected ? "white" : theme.palette.hague100};
    }
  `}
`;

const taskCountStyle = css`
  background: ${({ theme }) => theme.palette.terracotta150};
  border-radius: 9px; // fixed at half the height to make pill shape
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 18px;
  line-height: 18px;
  position: absolute;
  right: -6px;
  top: -6px;
  text-align: center;
  transition: width 200ms ease;
  width: ${({ $count }) => ($count < 10 ? "18px" : "27px")};

  ${media.tablet`
    right: -7px;
    top: -7px;
  `}
`;

const arrowStyle = css`
  display: flex;
  ${({ $selected }) => ($selected ? "transform: rotate(-90deg);" : "")}

  ${media.tablet`
    ${({ $selected }) => ($selected ? "transform: rotate(180deg);" : "")}
  `}
`;

const TaskCount = ({ count, onClick }) => (
  // disabling accessibility for the task count button because the task icon itself has accessibility
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    css={taskCountStyle}
    data-test="task-count"
    onClick={onClick}
    $count={count}
  >
    {count}
  </div>
);

type TasksButtonProps = {
  className?: string,
  "data-test"?: string,
  onClick: () => void,
  selected: boolean,
  taskCount: number,
};

export const TasksButton = ({
  className,
  "data-test": dataTest = "tasks-button",
  onClick,
  selected,
  taskCount,
}: TasksButtonProps) => {
  return (
    <div css={wrapperStyle} className={className}>
      <button
        data-test={dataTest}
        css={taskButtonStyle}
        onClick={onClick}
        $selected={selected}
      >
        <div css={iconWrapperStyle}>
          <FontAwesomeIcon icon={faTasks} />
          <FontAwesomeIcon
            icon={faCaretLeft}
            css={arrowStyle}
            $selected={selected}
          />
        </div>
      </button>

      {taskCount > 0 && <TaskCount count={taskCount} onClick={onClick} />}
    </div>
  );
};
