// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { SelectField } from "components/SelectField";

const QUERY_PROPOSED_CUSTOMER_TYPE = gql`
  query ProposedCustomerTypeQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      proposedCustomerType {
        value: id
        label
      }
    }
    proposedCustomerTypes {
      value: id
      label
    }
  }
`;

const UPDATE_PROPOSED_CUSTOMER_TYPE = gql`
  mutation UpdateNestDealProposedCustomerType(
    $input: NestDealInput!
    $id: ID!
  ) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      proposedCustomerType {
        value: id
        label
      }
    }
  }
`;

type Props = {
  dealId: string,
  readOnly?: boolean,
};

export const ProposedCustomerEligibility = ({ dealId, readOnly }: Props) => (
  <ExtendedQuery
    query={QUERY_PROPOSED_CUSTOMER_TYPE}
    variables={{ id: dealId }}
  >
    {({
      nestDeal,
      proposedCustomerTypes,
    }: ExtendedQueryRenderProps<ProposedCustomerTypeQuery>) => (
      <ExtendedMutation mutation={UPDATE_PROPOSED_CUSTOMER_TYPE}>
        {(
          updateNestDeal: MutationFunction<
            UpdateNestDealProposedCustomerType,
            UpdateNestDealProposedCustomerTypeVariables,
          >,
        ) => (
          <SelectField
            data-test="proposed-customer-eligibility-input"
            label="Proposed Customer Eligibility"
            value={nestDeal.proposedCustomerType}
            property="proposedCustomerTypeId"
            options={proposedCustomerTypes}
            readOnly={readOnly}
            mutation={(input) => {
              const fieldResponse = proposedCustomerTypes.find(
                ({ value }) => value === input.proposedCustomerTypeId,
              );
              return updateNestDeal({
                variables: {
                  input,
                  id: dealId,
                },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateNestDeal: {
                    __typename: "NestDeal",
                    id: dealId,
                    ...nestDeal,
                    proposedCustomerType: fieldResponse,
                  },
                },
              });
            }}
          />
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
