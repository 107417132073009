// @flow
import { useEffect } from "react";
import { css } from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { formatPrice } from "@nested/utils";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";
import { useUser } from "../../hooks/useUser";
import { useSorting } from "../../hooks/useSorting";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { Select } from "../../components/Select/Select";
import { NoResults } from "../../components/NoResults";
import { ListViewError } from "../../components/ListViewError";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import { prettyFormat } from "./utils";

import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import { getFullName } from "../utils";

const CLOSE_DATE = "CLOSE_DATE";
const SELLER_NAME = "SELLER_NAME";
const RECOMMENDED_LIST_PRICE = "RECOMMENDED_LIST_PRICE";

const MOBILE_SORT_OPTIONS = [
  { label: "Close date", value: CLOSE_DATE },
  { label: "Seller name", value: SELLER_NAME },
];

export const TO_LIST_QUERY = gql`
  query ToListListViewQuery(
    $sortBy: ToListListViewSortBy!
    $sortDirection: ListViewSortDirection!
    $email: String!
  ) {
    toListDeals(sortBy: $sortBy, sortDirection: $sortDirection, email: $email) {
      results {
        id
        externalId
        firstName
        lastName
        ownerName
        address
        rmRecommendedListPrice
        dealTypeAgency {
          id
          nestedContractSignedDate
        }
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
          }
          avatarUrl
        }
      }
    }
  }
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0;
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          externalId={deal.externalId}
          address={deal.address}
          sellerName={getFullName(deal)}
          contacts={deal.contacts}
          detailText={`Closed ${formatDateDifference(
            deal.dealTypeAgency.nestedContractSignedDate,
          )}`}
          detailTextTitle={prettyFormat(
            deal.dealTypeAgency.nestedContractSignedDate,
          )}
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field={SELLER_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <SortableTH
              field={CLOSE_DATE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Close date
            </SortableTH>
            <SortableTH
              css="padding-right: 10px;"
              field={RECOMMENDED_LIST_PRICE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Recommended list price
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => (
            <tr key={deal.externalId} data-test={`deal-row-${deal.externalId}`}>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {deal.address}
              </DealTD>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {getFullName(deal)}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={prettyFormat(
                  deal.dealTypeAgency.nestedContractSignedDate,
                )}
              >
                {formatDateDifference(
                  deal.dealTypeAgency.nestedContractSignedDate,
                )}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {formatPrice(Number(deal.rmRecommendedListPrice))}
              </DealTD>
              <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                <ListViewCallButton
                  contacts={deal.contacts}
                  externalId={deal.externalId}
                  relationType={"deal"}
                />
              </DealTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const ToListListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    CLOSE_DATE,
    "ASCENDING",
  );
  const { selectedUser } = useUser();
  const { toList } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(TO_LIST_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [toList]);

  const results = data?.toListDeals?.results || [];

  if (!loading && results.length === 0) {
    return <NoResults message="You have no properties to list." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
