// @flow
import { Component } from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ScrollableSection } from "components/TabContainer";

import { Counter } from "../Counter/Counter";
import { Header } from "../Header/Header";

import { CSVInput } from "./CSVInput";
import { Empty } from "./Empty";
import { ManualInput } from "./ManualInput";
import { SelectableComparable } from "./SelectableComparable";
import { Sorter, type StateSortItem } from "./Sorter";

const InputWrapper = styled.div`
  background-color: ${({ theme }) => theme.background.csvInputSection};
  padding: 16px;
  transition: height 0.1s ease-in-out;

  &.csv {
    & > div {
      transform-origin: top center;
    }
  }

  &.manual {
    height: 330px;

    & > div {
      transform-origin: top center;
    }
  }

  & > .hide {
    height: 0;
    transition: all 0.1s ease-in;
    transform: scaleY(0);
  }

  & > .show {
    transform: scaleY(1);
    transition: all 0.1s ease-out 0.1s;
  }
`;

const Wrapper = styled(ScrollableSection)`
  overflow-y: auto;
  padding: 16px;
`;

const CompWrapper = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LIST_COMPARABLES_QUERY = gql`
  query ListComparablesQuery(
    $reference: ComparableReferenceInput!
    $sortBy: SortBy!
    $sortOrder: SortOrder!
  ) {
    comparables(reference: $reference, sortBy: $sortBy, sortOrder: $sortOrder) {
      addedAt
      address
      agentName
      comparabilityRating
      comparableImages {
        url
      }
      comparableLinks {
        type
        url
      }
      comparisonPoints {
        description
        rating
      }
      floorArea
      id
      internalNotes
      listingPrice
      numberOfBeds
      propertyType
      relevanceRating
      selected
      soldDate
      soldPrice
      status
      underOfferDate
      underOfferPrice
    }
  }
`;

type InputMode = "manual" | "csv";

type Props = {
  closeUrl: string,
  reference: ComparableReferenceInput,
};

type State = {
  sortBy: StateSortItem,
  mode: InputMode,
};

export class ComparableList extends Component<Props, State> {
  state = {
    mode: "csv",
    sortBy: {
      sort: "PRICE",
      order: "ASC",
    },
  };

  setSortBy = (updatedSortBy: StateSortItem) => {
    this.setState({
      sortBy: updatedSortBy,
    });
  };

  setMode = (mode: InputMode) => {
    this.setState((oldState) => ({ ...oldState, mode }));
  };

  render() {
    const { mode, sortBy } = this.state;
    const { closeUrl, reference } = this.props;

    return (
      <ExtendedQuery
        query={LIST_COMPARABLES_QUERY}
        variables={{
          reference,
          sortBy: sortBy.sort,
          sortOrder: sortBy.order,
        }}
        fetchPolicy="network-only"
        nextFetchPolicy="cache-first"
      >
        {({ comparables }: ExtendedQueryRenderProps<ListComparablesQuery>) => {
          const selectedCount = comparables.filter(
            ({ selected }) => selected,
          ).length;
          return (
            <>
              <Header
                backDisabled
                closeUrl={closeUrl}
                nextDisabled={selectedCount === 0}
              />

              <InputWrapper className={mode}>
                <CSVInput
                  className={mode === "csv" ? "show" : "hide"}
                  reference={reference}
                  sortBy={sortBy.sort}
                  sortOrder={sortBy.order}
                  switchToManual={() => this.setMode("manual")}
                />

                <ManualInput
                  className={mode === "manual" ? "show" : "hide"}
                  reference={reference}
                  sortBy={sortBy.sort}
                  sortOrder={sortBy.order}
                  switchToCSV={() => this.setMode("csv")}
                />
              </InputWrapper>

              <Wrapper>
                {comparables.length === 0 ? (
                  <Empty />
                ) : (
                  <>
                    <Sorter sortBy={sortBy} setSortBy={this.setSortBy} />
                    <Counter
                      labels={[
                        `${comparables.length} ${
                          comparables.length > 1 ? "Comparables" : "Comparable"
                        } found`,
                        `${selectedCount} Selected`,
                      ]}
                    />
                    {comparables.map((comparable) => (
                      <CompWrapper key={comparable.id}>
                        <SelectableComparable
                          comparable={comparable}
                          refetchOnDelete={() => [
                            {
                              query: LIST_COMPARABLES_QUERY,
                              variables: {
                                reference,
                                sortBy: sortBy.sort,
                                sortOrder: sortBy.order,
                              },
                            },
                          ]}
                        />
                      </CompWrapper>
                    ))}
                  </>
                )}
              </Wrapper>
            </>
          );
        }}
      </ExtendedQuery>
    );
  }
}
