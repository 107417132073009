// @flow
import { has } from "ramda";
import React from "react";
import {
  type MutationFunction,
  Query,
  type QueryRenderProps,
} from "@apollo/client/react/components";
import styled, { css } from "styled-components";

import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid";
import { Label as FullWidthLabel } from "@nest-ui/sellers-nest/components/Label";
import { Loader } from "@nest-ui/sellers-nest/components/Loader";
import { EphemeralStateModal } from "@nest-ui/sellers-nest/components/Modal/EphemeralStateModal";
import { NoSubmitTextField } from "@nest-ui/sellers-nest/components/TextField";
import { NoSubmitSelectField } from "@nest-ui/sellers-nest/components/SelectField";
import { Switch } from "@nest-ui/sellers-nest/components/Switch";
import { Tick } from "@nest-ui/icons";
import { logException } from "@nested/utils/sentry";
import { alertErrors } from "@nested/utils/alertErrors";

import BUYER_PROPERTY_INTEREST_FALLOUT_REASONS from "./queries/BuyerPropertyInterestFalloutReasons.graphql";

const B01_STATUS_ID = "1";

const Label = styled(FullWidthLabel)`
  width: auto;
  padding-right: 20px;
`;

const InterestToggle = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  justify-content: space-between;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const LeafCellWithSmallerDropdown = styled(LeafCell)`
  .Select-menu-outer {
    .Select-menu {
      max-height: 120px;
    }
  }
`;

const FalloutReason = styled.p`
  margins: 0;
`;

const falloutDetailsPrefills = [
  {
    status: "1. Property no longer available",
    content:
      "The buyer enquired after you accepted an offer. We will let them know if things change.",
  },
  {
    status: "2a. Fake or duplicate lead",
    content:
      "The buyer has already enquired or provided incorrect contact details.",
  },
  {
    status: "2b. Buyer stopped communications or unmotivated",
    content:
      "We called, texted and emailed the buyer every day, for 3 days. Since we couldn’t get in contact we have marked them as no longer interested.",
  },
  {
    status: "2c. Buyer changed or stopped their search",
    content:
      "This buyer changed their buying criteria or decided to stop searching for a home.",
  },
  {
    status: "2d. Buyer proceeding with another property",
    content:
      "This buyer decided to proceed with another property. We will keep in touch in case a fall through occurs.",
  },
  {
    status: "3a. Buyer does not have budget for this property",
    content:
      "We contacted the buyer and determined that they couldn’t afford your property. Their budget is ENTER BUDGET HERE",
  },
  {
    status: "3b. Buyer otherwise not proceed-able at this time",
    content:
      "We contacted the buyer and determined that they are not financially proceedable at this time. We will keep in touch and let you know if their situation changes.",
  },
  {
    status: "4a. Property location (vs. their requirements)",
    content:
      "We contacted the buyer, but they realised they didn’t like the location because … INSERT REASON HERE",
  },
  {
    status: "4b. Property fundamentals (vs. their requirements)",
    content: null,
  },
  {
    status: "4c. Property price (vs. comparables)",
    content:
      "The buyer believes that the asking price of the property is too high in comparison to other properties they have seen.",
  },
];

function SwitchWrapper({ value, mutation }) {
  return (
    <Switch
      value={value}
      onSubmit={(currentlyInterested) => mutation({ currentlyInterested })}
    />
  );
}

export function validateInputs({
  buyerPropertyInterest: {
    status: { value: bpiStatus },
  },
  buyerPropertyInterestChanges: {
    buyerPropertyInterestFalloutReasonId: falloutReason,
    falloutDetails,
  },
}: {
  buyerPropertyInterest: PropertyInterestsByBuyer_buyer_buyerPropertyInterests,
  buyerPropertyInterestChanges: BuyerPropertyInterestChanges,
}) {
  if (!falloutReason) {
    return false;
  }

  if (bpiStatus === B01_STATUS_ID) {
    // validate falloutDetails
    const needsEditing = /ENTER BUDGET HERE|INSERT REASON HERE/;

    if (!falloutDetails || falloutDetails.match(needsEditing)) {
      return false;
    }
  }

  return true;
}

type CurrentlyInterestedToggleProps = {
  buyerPropertyInterest: PropertyInterestsByBuyer_buyer_buyerPropertyInterests,
  disabled: boolean,
  mutation: MutationFunction<
    UpdateBuyerPropertyInterest,
    UpdateBuyerPropertyInterestVariables,
  >,
  mutationInProgress: boolean,
};

type BuyerPropertyInterestChanges = {|
  currentlyInterested: boolean,
  buyerPropertyInterestFalloutReasonId: ?string,
  falloutDetails: ?string,
|};

type CurrentlyInterestedToggleState = {
  isModalOpen: boolean,
  buyerPropertyInterestChanges: BuyerPropertyInterestChanges,
};

export class CurrentlyInterestedToggle extends React.Component<
  CurrentlyInterestedToggleProps,
  CurrentlyInterestedToggleState,
> {
  state = {
    isModalOpen: false,
    buyerPropertyInterestChanges: {
      currentlyInterested: false,
      buyerPropertyInterestFalloutReasonId:
        this.props.buyerPropertyInterest.falloutReason?.id,
      falloutDetails: this.props.buyerPropertyInterest.falloutDetails,
    },
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
    this.resetChanges();
  };

  resetChanges = () => {
    this.setState({
      buyerPropertyInterestChanges: {
        currentlyInterested: false,
        buyerPropertyInterestFalloutReasonId:
          this.props.buyerPropertyInterest.falloutReason?.id,
        falloutDetails: this.props.buyerPropertyInterest.falloutDetails,
      },
    });
  };

  updateFalloutDetails = (input: string) => {
    const { buyerPropertyInterestChanges } = this.state;

    this.setState({
      buyerPropertyInterestChanges: {
        ...buyerPropertyInterestChanges,
        falloutDetails: input,
      },
    });
  };

  selectFalloutReason = (selection: {
    label: string,
    value: string,
    prefillContent: ?string,
  }) => {
    const { buyerPropertyInterestChanges } = this.state;

    const changes =
      this.props.buyerPropertyInterest.status.value === B01_STATUS_ID
        ? {
            buyerPropertyInterestFalloutReasonId: selection.value,
            falloutDetails: selection.prefillContent,
          }
        : {
            buyerPropertyInterestFalloutReasonId: selection.value,
          };

    this.setState({
      buyerPropertyInterestChanges: {
        ...buyerPropertyInterestChanges,
        ...changes,
      },
    });
  };

  submitChanges = async () => {
    const { buyerPropertyInterest, mutation } = this.props;
    const { buyerPropertyInterestChanges } = this.state;

    try {
      await mutation({
        variables: {
          id: buyerPropertyInterest.id,
          input: buyerPropertyInterestChanges,
        },
        optimisticResponse: {
          updateBuyerPropertyInterest: {
            ...buyerPropertyInterest,
            ...buyerPropertyInterestChanges,
          },
        },
      });
    } catch (e) {
      logException(e);
      alertErrors({ errors: e.graphQLErrors });
    }

    this.closeModal();
  };

  render() {
    const { buyerPropertyInterest, mutation, mutationInProgress } = this.props;
    const { isModalOpen, buyerPropertyInterestChanges } = this.state;

    return (
      <InterestToggle
        data-test="buyer-property-interest-current-interest-toggle"
        disabled={this.props.disabled}
      >
        <div>
          <Label>Currently interested?</Label>
          {buyerPropertyInterest.falloutReason && (
            <FalloutReason data-test="buyer-property-interest-fallout-reason-label">
              {buyerPropertyInterest.falloutReason.label}
            </FalloutReason>
          )}
          {buyerPropertyInterest.falloutDetails && (
            <FalloutReason data-test="buyer-property-interest-fallout-details">
              {buyerPropertyInterest.falloutDetails}
            </FalloutReason>
          )}
        </div>
        {buyerPropertyInterest.currentlyInterested ? (
          <Switch
            data-test={`buyer-property-interest-active-switch-${buyerPropertyInterest.id}`}
            value={buyerPropertyInterest.currentlyInterested}
            onSubmit={this.openModal}
          />
        ) : (
          <SwitchWrapper
            data-test={`buyer-property-interest-active-switch-${buyerPropertyInterest.id}`}
            mutation={(input) =>
              mutation({
                variables: { id: buyerPropertyInterest.id, input },
                optimisticResponse: {
                  updateBuyerPropertyInterest: {
                    __typename: "BuyerPropertyInterest",
                    ...buyerPropertyInterest,
                    currentlyInterested: true,
                    falloutReason: null,
                  },
                },
              })
            }
            property="currentlyInterested"
            value={buyerPropertyInterest.currentlyInterested}
          />
        )}

        {isModalOpen && (
          <EphemeralStateModal
            buttonLabel="Save"
            buttonEnabled={validateInputs({
              buyerPropertyInterest,
              buyerPropertyInterestChanges,
            })}
            closeModal={this.closeModal}
            data-test="bpi-fallout-reason-modal"
            icon={Tick}
            isOpen={isModalOpen}
            isSubmitting={mutationInProgress}
            onButtonClick={this.submitChanges}
            title="Select a fallout reason"
          >
            <Grid columns={1}>
              <ErrorBoundary>
                <Query query={BUYER_PROPERTY_INTEREST_FALLOUT_REASONS}>
                  {({
                    data,
                    loading,
                  }: QueryRenderProps<
                    BuyerPropertyInterestFalloutReasons,
                    void,
                  >) => {
                    if (
                      loading ||
                      !data ||
                      !has("buyerPropertyInterestFalloutReasons", data)
                    ) {
                      return <Loader />;
                    }
                    const falloutReasonOptions =
                      data.buyerPropertyInterestFalloutReasons.map(
                        ({ id, label }) => ({
                          label,
                          value: id,
                          prefillContent: falloutDetailsPrefills.find(
                            ({ status }) => status === label,
                          )?.content,
                        }),
                      );

                    return (
                      <LeafCellWithSmallerDropdown width={1}>
                        <NoSubmitSelectField
                          data-test="bpi-fallout-reason-modal-fallout-reason"
                          label="Reason for buyer fallout"
                          nullable={false}
                          onSubmit={(inputValue) => {
                            const selection = falloutReasonOptions.find(
                              ({ value }) => value === inputValue,
                            );

                            this.selectFalloutReason(selection);
                          }}
                          options={falloutReasonOptions}
                          searchable
                          value={
                            buyerPropertyInterestChanges?.buyerPropertyInterestFalloutReasonId
                          }
                        />
                      </LeafCellWithSmallerDropdown>
                    );
                  }}
                </Query>
              </ErrorBoundary>
              {buyerPropertyInterest.status.value === B01_STATUS_ID &&
                buyerPropertyInterestChanges.buyerPropertyInterestFalloutReasonId && (
                  <LeafCell>
                    <NoSubmitTextField
                      data-test="bpi-fallout-reason-modal-fallout-details"
                      label="Buyer no longer interested reason (CUSTOMER FACING):"
                      multiline
                      onSubmit={this.updateFalloutDetails}
                      value={buyerPropertyInterestChanges?.falloutDetails}
                    />
                  </LeafCell>
                )}
            </Grid>
          </EphemeralStateModal>
        )}
      </InterestToggle>
    );
  }
}
