// @flow
import { useEffect } from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { gql } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";
import { Card } from "@nest-ui/sellers-nest/components/Card";
import { RichTextField } from "@nest-ui/sellers-nest/components/TextField";
import { NakedCheckbox } from "@nest-ui/sellers-nest/components/Checkbox/Checkbox";
import {
  HandoffSMSButton,
  HandoffCallButton,
} from "@nested/nest/src/components/Comms";

const UPDATE_FIRST_IMPRESSIONS = gql`
  mutation UpdateFirstImpressions($id: ID!, $input: UpdateViewingInput!) {
    updateViewing(id: $id, input: $input) {
      id
      viewingFeedbackFirstImpressions
      datetimeFirstImpressionsSharedWithSeller
    }
  }
`;

const timeAndBuyerStyles = css`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
`;

const virtualViewingDot = css`
  background-color: ${({ theme }) => theme.color.primary.dark};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 3px;
  position: relative;
  vertical-align: middle;
  &::after {
    content: "v";
    color: white;
    font-weight: 500;
    font-size: 14px;
    left: 4px;
    position: absolute;
    top: -1px;
  }
`;

const textAreaStyle = css`
  .editor-textarea {
    font-size: 14px;
  }
`;

const buyerPageLinkStyles = css`
  color: ${({ theme }) => theme.color.secondary.light};
`;

const checkboxWrapperStyles = css`
  margin-top: 10px;
  width: 100px;
`;

const buyerOnMarketStyles = css`
  border-radius: 5px;
  color: white;
  display: flex;
  margin: 12px 0 8px 0;
`;

const moneyFace = css`
  background-color: ${({ theme }) => theme.color.background.hague};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 24px;
  padding: 6px 8px;
`;

const buyerOnMarket = css`
  background-color: ${({ theme }) => theme.color.background.hague90};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding: 11px;
  width: 100%;
`;

export const contactIconStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  button {
    border: none;
  }
`;

const Link = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener">
    {children}
  </a>
);

const getCheckBoxText = (saving, feedbackShared) => {
  if (saving) {
    return "Saving...";
  }
  if (feedbackShared) {
    return "Shared!";
  }
  return "Share";
};

const positive = "The viewing went well and the buyer seemed interested.";
const neutral =
  "The viewing went well but the buyer didn’t give a firm indication of whether they are interested.";
const negative =
  "The buyer didn’t seem interested. We’ll follow up with them as it’s important we understand what they did and didn't like about your home. I’m not anticipating a second viewing or offer from them.";
const clear = "";

const firstImpressionsPrefillLinks = [
  { label: "Positive", content: positive },
  { label: "Neutral", content: neutral },
  { label: "Negative", content: negative },
  { label: "Clear", content: clear },
];

// The address should always be defined, the
// fallback here is to shut up flow.
const createSmsBuyerTemplates = ({
  buyerFirstName,
  agentFirstName,
  viewingStartTime,
  address,
}) => {
  const late = `Hi ${buyerFirstName}, it’s ${agentFirstName} from Nested.

I’m really sorry but I’m running a little late for our ${viewingStartTime} viewing at ${
    address || "the property"
  }.

I should be with you shortly.

If you have any problems finding it feel free to call me on this number.

Thanks, ${agentFirstName}`;

  const here = `Hi ${buyerFirstName}, it’s ${agentFirstName} from Nested.

Just to let you know I’m waiting outside for our ${viewingStartTime} viewing at ${
    address || "the property"
  }.

If you have any problems finding it feel free to call me on this number.

Thanks, ${agentFirstName}`;

  const onMyWay = `Hi ${buyerFirstName}, it’s ${agentFirstName} from Nested.

I’m on my way to our ${viewingStartTime} viewing at ${
    address || "the property"
  }.

If you have any problems finding it feel free to call me on this number.

Thanks, ${agentFirstName}`;

  return [
    { label: "I'm late", content: late },
    { label: "I'm here", content: here },
    { label: "On my way", content: onMyWay },
  ];
};

const OnTheMarketBanner = () => {
  return (
    <div css={buyerOnMarketStyles}>
      <span role="img" aria-label="Money mouth" css={moneyFace}>
        🤑
      </span>
      <div css={buyerOnMarket}>This buyer is on the market</div>
    </div>
  );
};

type props = {
  deal: DealsWithViewingsToday_dealsWithViewingsToday,
  viewing: DealsWithViewingsToday_dealsWithViewingsToday_todaysViewings,
  onTheMarketBuyerPositionsIds: Array<string>,
  currentUser: AllNestedUsers_activeNestedUsers,
};

export const ViewingCard = ({
  deal,
  viewing,
  onTheMarketBuyerPositionsIds,
  currentUser,
}: props) => {
  const {
    id,
    buyerPropertyInterest,
    datetimeViewingStarts,
    datetimeFirstImpressionsSharedWithSeller,
    viewingFeedbackFirstImpressions,
    virtual,
  } = viewing;

  const { buyer } = buyerPropertyInterest;

  const viewingStartTime = moment(datetimeViewingStarts).format("h:mma");
  const smsBuyerTemplates = createSmsBuyerTemplates({
    buyerFirstName: buyer.firstName,
    agentFirstName: currentUser.firstName,
    viewingStartTime,
    address: deal.address,
  });
  const firstImpressionsSharedWithSeller = Boolean(
    datetimeFirstImpressionsSharedWithSeller,
  );

  const [updateFirstImpressions, { error, loading: saving }] = useMutation(
    UPDATE_FIRST_IMPRESSIONS,
  );

  useEffect(() => {
    if (error) {
      Sentry.captureMessage("[MyViewings] Error updating first impressions", {
        extra: {
          error: JSON.stringify(error),
        },
      });
      // eslint-disable-next-line
      window.alert(
        `Error sharing initial feedback:\n\n${JSON.stringify(error)}`,
      );
    }
  }, [error]);

  return (
    <Card>
      <div css={timeAndBuyerStyles}>
        <span className="fs-exclude">
          {virtual && <div css={virtualViewingDot}></div>}
          <span
            data-test={`my-viewings:viewing-start-time-for-deal-${deal.id}`}
          >
            {viewingStartTime}
          </span>{" "}
          -{" "}
          <Link href={`/buyers/${buyer.id}`} css={buyerPageLinkStyles}>
            {buyer.name}
          </Link>
        </span>
        <div css="display: flex;">
          <HandoffSMSButton
            css={contactIconStyle}
            contacts={buyer.contacts}
            relationType="buyer"
            relationId={buyer.id}
            smsTemplates={smsBuyerTemplates}
          />
          <HandoffCallButton
            css={contactIconStyle}
            contacts={buyer.contacts}
            relationId={buyer.id}
            relationType="buyer"
          />
        </div>
      </div>
      {onTheMarketBuyerPositionsIds.includes(buyer.buyerPosition?.id) && (
        <OnTheMarketBanner
          data-test={`my-viewings:on-the-market-banner-${buyer.id}`}
        />
      )}
      <RichTextField
        css={textAreaStyle}
        data-test={`my-viewings:first-impressions-feedback:edit-deal-${deal.id}`}
        onSubmit={(value) => {
          updateFirstImpressions({
            variables: {
              id,
              input: {
                viewingFeedbackFirstImpressions: value,
              },
            },
            optimisticResponse: {
              updateViewing: {
                __typename: "Viewing",
                ...viewing,
                viewingFeedbackFirstImpressions: value,
              },
            },
          });
        }}
        value={viewingFeedbackFirstImpressions}
        prefillLinks={firstImpressionsPrefillLinks}
        disableLinks={firstImpressionsSharedWithSeller}
      />
      <div css={checkboxWrapperStyles}>
        <NakedCheckbox
          data-test={`my-viewings:first-impressions-feedback:share-deal-${deal.id}`}
          greenMode
          optionName={getCheckBoxText(
            saving,
            Boolean(firstImpressionsSharedWithSeller),
          )}
          checked={firstImpressionsSharedWithSeller}
          disabled={saving}
          id={`my-viewings:first-impressions-feedback:share-deal-${deal.id}-${id}`}
          onChange={(e) => {
            updateFirstImpressions({
              variables: {
                id,
                input: {
                  firstImpressionsSharedWithSeller: e.target.checked,
                },
              },
              optimisticResponse: {
                updateViewing: {
                  __typename: "Viewing",
                  ...viewing,
                  datetimeFirstImpressionsSharedWithSeller: e.target.checked
                    ? moment().toString()
                    : viewing.datetimeFirstImpressionsSharedWithSeller,
                },
              },
            });
          }}
        />
      </div>
    </Card>
  );
};
