// @flow
import { useState } from "react";
import styled from "styled-components";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { RichTextStyles } from "./RichTextStyles";

const TemplateLinks = styled.div`
  button:not(:last-of-type) {
    :after {
      content: "|";
      display: inline-block;
      margin-left: 6px;
      color: ${({ theme }) => theme.color.text.default};
      font-weight: ${({ theme }) => theme.font.weight.regular};
    }
  }
  button:not(:first-of-type) {
    padding-left: 8px;
  }
`;

const Button = styled.button`
  height: 24px;
  background-color: unset;
  font-size: ${({ theme }) => theme.font.size.default};
  cursor: pointer;
  outline: none;
  border: unset;
  text-decoration: underline;
  margin: 0;
  padding: 0;
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.text.muted : theme.color.text.highlight};
  font-weight: ${({ selected, theme }) =>
    selected ? theme.font.weight.bold : theme.font.weight.regular};
`;

type PrefillLink = {
  label: string,
  content: string,
};
type Props = {
  className?: string,
  value: string,
  onSubmit: (content: string) => void,
  prefillLinks?: PrefillLink[],
  disableLinks?: boolean,
};

export const RichTextField = ({
  className,
  value,
  onSubmit,
  prefillLinks = [],
  disableLinks = false,
}: Props) => {
  const [selectedLink, setSelectedLink] = useState(null);
  const [state, setState] = useState(() => {
    if (!value) {
      return EditorState.createEmpty();
    }
    const { contentBlocks, entityMap } = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    return EditorState.createWithContent(contentState);
  });

  return (
    <div className={className}>
      <TemplateLinks>
        {prefillLinks.map(({ label, content }) => (
          <Button
            key={label}
            disabled={disableLinks}
            selected={label === selectedLink}
            onClick={() => {
              const blocksFromHTML = htmlToDraft(content);
              const newState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
              );
              setState(EditorState.createWithContent(newState));
              setSelectedLink(label);
              onSubmit(content);
            }}
          >
            {label}
          </Button>
        ))}
      </TemplateLinks>
      <RichTextStyles />
      <Editor
        onBlur={() => {
          const content = convertToRaw(state.getCurrentContent());
          const html = draftToHtml(content);
          onSubmit(html);
        }}
        stripPastedStyles
        onEditorStateChange={setState}
        editorState={state}
        wrapperClassName="editor-wrapper"
        editorClassName="editor-textarea"
        toolbar={{
          options: ["blockType"],
          blockType: {
            inDropdown: false,
            options: ["H3"],
          },
        }}
      />
    </div>
  );
};
