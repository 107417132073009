// @flow
import styled, { css } from "styled-components";
import {
  TranslucentBox,
  WhiteBox,
} from "@nest-ui/sellers-nest/components/Modal/Modal";

const ModalBackground = styled(TranslucentBox)`
  background-color: white;
  ${({ isOpen }) =>
    isOpen
      ? css`
          transition: background-color 300ms linear 0ms,
            visibility 300ms linear 0ms;
          visibility: visible;
          background-color: rgba(10, 66, 84, 0.8);
          pointer-events: auto;
        `
      : css`
          transition: background-color 200ms ease 200ms,
            visibility 200ms ease 200ms;
          visibility: hidden;
          background-color: rgba(10, 66, 84, 0);
          pointer-events: none;
        `};
`;

const ModalBody = styled(WhiteBox)`
  max-width: 795px;
  border: 3px solid ${({ theme }) => theme.color.text.hague70};
  border-radius: 13px;
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen
      ? css`
          transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
          transform: translateY(0px);
          opacity: 1;
        `
      : css`
          transition: opacity 300ms ease 0ms, visibility 300ms ease 0ms,
            transform 300ms ease 0ms;
          transform: translateY(3em);
          opacity: 0;
        `}
`;

type Props = {
  isOpen: boolean,
  closeModal: () => void,
  children: React$Node,
  className?: string,
};

export const Modal = ({ isOpen, closeModal, children, className }: Props) => {
  return (
    <ModalBackground onClick={closeModal} isOpen={isOpen}>
      <ModalBody
        className={className}
        data-test="modal-body"
        onClick={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
      >
        {children}
      </ModalBody>
    </ModalBackground>
  );
};
