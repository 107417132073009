import styled from "styled-components";
import { H1 } from "components/Heading";
import { device } from "@nest-ui/styles";

const Header = styled.header`
  height: ${({ theme }) => theme.height.header};
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-left: 16px;
  padding-right: 16px;

  ${H1} {
    line-height: ${({ theme }) => theme.height.header};
    margin: 0px;
    padding: 0px;
    padding-bottom: 24px;
    text-align: center;

    @media ${device.desktop} {
      text-align: left;
    }
  }
`;

export { Header };
