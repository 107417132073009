// @flow
import { Route, withRouter, Switch } from "react-router-dom";
import { gql } from "@apollo/client";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { Card } from "components/Card";
import { Heading, H2 } from "components/Heading";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { ComparablePropertiesSection } from "components/Comparables/ComparablePropertiesSection";
import { Comparables } from "components/Comparables/Comparables";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";

import { PropertyInformationSection } from "tabs/Valuation/PropertyInformationSection/PropertyInformationSection";

import { DealDetails } from "./DealDetails";

import { Survey } from "./Survey/Survey";
import { ExpectedDrawdownTiming } from "./ExpectedDrawdownTiming/ExpectedDrawdownTiming";
import { AdvanceOfferValuationsList } from "./AdvanceOfferValuationsList/AdvanceOfferValuationsList";
import { DebtSignoff } from "./DebtSignoff/DebtSignoff";
import { PostSurveyChecklist } from "./PostSurveyChecklist/PostSurveyChecklist";
import { PostVisitValuationsList } from "./PostVisitValuationsList/PostVisitValuationsList";
import { PreVisitValuationsList } from "./PreVisitValuationsList/PreVisitValuationsList";
import { ProceedingAfterCompletedIndicValuation } from "./ProceedingAfterCompletedIndicValuation/ProceedingAfterCompletedIndicValuation";
import { PropertyQuestionnaire } from "./PropertyQuestionnaire/PropertyQuestionnaire";
import { ValuationSection } from "./IndicativeValuation/ValuationSection";
import { DrawdownTimingFees } from "./DrawdownTimingFees/DrawdownTimingFees";
import { Advances } from "./Advances/Advances";
import { AdvancesSectionList } from "./Advances/AdvancesSectionList";
import { AdvanceInPrinciple } from "./Advances/AdvanceInPrinciple";
import { AdvanceOffer } from "./Advances/AdvanceOffer";
import { AdvanceDrawdownRequest } from "./Advances/AdvanceDrawdownRequest";
import { LegalServicesSection } from "./Advances/LegalServicesSection/LegalServicesSection";
import { AdvancePayment } from "./Advances/AdvancePayment";
import { AgencyValuationList } from "./AgencyValuationList/AgencyValuationList";
import { SubAgentsList } from "./SubAgentsList/SubAgentsList";
import { ThirdPartyAppraisalList } from "./ThirdPartyAppraisalList/ThirdPartyAppraisalList";
import { ValuerSection } from "./ValuerSection/ValuerSection";
import { LegalServices } from "./LegalServices/LegalServices";

export const TAB_QUERY = gql`
  query AdvanceValuationsAndLegalTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
    }
  }
`;

const AdvanceValuationAndLegalServicesInfo = ({ idOrExternalDealId, url }) => (
  <ExtendedQuery query={TAB_QUERY} variables={{ idOrExternalDealId }}>
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<AdvanceValuationsAndLegalTabQuery>) => {
      const { id } = nestDeal;

      return (
        <TabContainer
          id="Advance-Valuations-and-Legal-Services"
          data-test="Advance-Valuations-and-Legal-Services-tab"
          className="fs-exclude"
        >
          <Card>
            <ValuerSection parentId={id} />
          </Card>
          <PropertyInformationSection parentId={id} />
          <Heading id="Comparables">Comparables</Heading>
          <Card>
            <ComparablePropertiesSection
              comparablesUrl={`${url}/comparables`}
              showAccountLink
              sourceId={idOrExternalDealId}
              sourceType="DEAL"
            />
          </Card>
          <Heading id="Pre-Visit-Valuations">Pre Visit Valuations</Heading>
          <Card>
            <H2>Valuation</H2>
            <ValuationSection parentId={id} />
          </Card>
          <Card>
            <PreVisitValuationsList parentId={id} />
          </Card>
          <Heading id="Post-Visit-Valuations">Post Visit Valuations</Heading>
          <Card>
            <PostVisitValuationsList parentId={id} />
          </Card>
          <Card>
            <H2>Proceeding?</H2>
            <ProceedingAfterCompletedIndicValuation dealId={id} />
          </Card>
          <Heading id="Advance-Offer-Valuations">
            Advance Offer Valuations
          </Heading>
          <Card>
            <AdvanceOfferValuationsList parentId={id} />
          </Card>
          <Heading id="Property-Questionnaire">Property Questionnaire</Heading>
          <Card>
            <PropertyQuestionnaire parentId={id} />
          </Card>
          <Heading id="Debt-Signoff">Debt Signoff</Heading>
          <Card>
            <DebtSignoff dealId={id} />
          </Card>
          <Heading id="Survey-Review">Survey Review</Heading>
          <Card>
            <PostSurveyChecklist parentId={id} />
          </Card>
          <Heading id="Valuation-Review">Valuation Review</Heading>
          <Card>
            <H2>Updated Valuations</H2>
            <AgencyValuationList parentId={id} />
          </Card>
          <Card>
            <H2>Third party appraisals</H2>
            <ThirdPartyAppraisalList parentId={id} />
          </Card>
          <Card>
            <H2>Sub agents</H2>
            <SubAgentsList parentId={id} />
          </Card>
          <Heading id="advance">Advance</Heading>
          <Card>
            <Advances dealId={id}>
              <AdvancesSectionList>
                <AdvanceInPrinciple />
                <AdvanceOffer />
                <AdvanceDrawdownRequest />
                <LegalServicesSection readOnly />
                <AdvancePayment />
              </AdvancesSectionList>
            </Advances>
          </Card>
          <Card>
            <H2>Drawdown timing fees</H2>
            <DrawdownTimingFees parentId={id} />
          </Card>
          <Card>
            <H2>Expected drawdown timing</H2>
            <ErrorBoundary>
              <ExpectedDrawdownTiming parentId={id} />
            </ErrorBoundary>
          </Card>
          <Card>
            <H2>Survey</H2>
            <Survey parentId={id} />
          </Card>
          <Heading id="Legal-Services">Legal Services</Heading>
          <Card>
            <LegalServices dealId={id} />
          </Card>
        </TabContainer>
      );
    }}
  </ExtendedQuery>
);

export const AdvanceValuationsAndLegal = withRouter(({ match }) => {
  const {
    params: { id: idOrExternalDealId },
    path,
    url,
    history,
  } = match;

  return (
    <TabWrapper>
      <Switch>
        <Route
          path={`${match.path}/comparables`}
          render={() => (
            <Comparables
              sourceType="DEAL"
              sourceId={idOrExternalDealId}
              closeUrl={match.url}
            />
          )}
        />
        <Route
          path={`${match.path}`}
          render={() => (
            <AdvanceValuationAndLegalServicesInfo
              {...{ idOrExternalDealId, url, path, history }}
            />
          )}
        />
      </Switch>
      <DealDetails dealId={idOrExternalDealId} className="fs-exclude" />
    </TabWrapper>
  );
});
