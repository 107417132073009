// @flow
import styled from "styled-components";
import { kebabCase } from "lodash";

import { ButtonWithIcon } from "components/ButtonWithIcon";
import { Tick, Delete } from "@nest-ui/icons";
import { ListEntry } from "components/ListEntry";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { PrimaryButton } from "components/Buttons";
import { H3 } from "components/Heading";
import { NoSubmitRadioButtons } from "components/RadioButtons";

const Card = styled(ListEntry)`
  margin-bottom: 0px;
`;

const SubHeading = styled(H3)`
  margin-bottom: 0;
`;

type Props = {
  dealId: string,
  users: $ReadOnlyArray<{ value: string, label: ?string }>,
  updateState: (
    newValuation: $Shape<CreatePostVisitValuationDec18Input>,
  ) => void,
  valuation: $Shape<CreatePostVisitValuationDec18Input>,
  onSubmit: () => any,
  onCancel: () => any,
};

export const CreatePostVisitValuation = (props: Props) => {
  const fieldProps = (key: string) => ({
    "data-test": `create-post-visit-valuation-${kebabCase(key)}`,
    value: props.valuation[key],
    onSubmit: (val) => props.updateState({ [key]: val }),
  });

  const disableSubmit = !(
    props.valuation.completedBy &&
    props.valuation.dateCompleted &&
    props.valuation.signedOffBy &&
    props.valuation.listPrice &&
    props.valuation.maximumListPrice &&
    props.valuation.estimatedSalePrice &&
    props.valuation.estimatingAdvance !== null
  );

  return (
    <Card>
      <Grid columns={6}>
        <LeafCell width={3}>
          <NoSubmitDatePicker
            {...fieldProps("dateCompleted")}
            label="Date Completed"
          />
        </LeafCell>

        <LeafCell width={3} left={1}>
          <NoSubmitSelectField
            {...fieldProps("completedBy")}
            options={props.users}
            searchable
            label="Completed By"
          />
        </LeafCell>

        <LeafCell width={3}>
          <NoSubmitSelectField
            {...fieldProps("signedOffBy")}
            options={props.users}
            searchable
            label="Signed Off By"
          />
        </LeafCell>

        <LeafCell width={6}>
          <NoSubmitTextareaWithLinks
            {...fieldProps("valuationGsheetUrl")}
            label="Valuation GSheet URL"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("listPrice")}
            label="List Price"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("maximumListPrice")}
            label="Maximum List Price"
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePrice")}
            label="Estimated Sale Price Best Estimate"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePriceLowerBound")}
            label="Estimated Sale Price (Lower Bound)"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePriceUpperBound")}
            label="Estimated Sale Price (Upper Bound)"
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <SubHeading>Advance Estimates</SubHeading>
        </LeafCell>

        <LeafCell width={2} left={1}>
          <NoSubmitRadioButtons
            data-test="create-post-visit-valuation-estimating-advance"
            id="create-post-visit-valuation-estimating-advance"
            label="Estimating Advance"
            onSubmit={(val) => {
              /*
               * `estimatingAdvance` is a required field, so once it is
               * selected we don't allow to de-select it.
               */
              if (val !== undefined && val !== null) {
                props.updateState({ estimatingAdvance: val });
              }
            }}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={props.valuation.estimatingAdvance}
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceLowerBound")}
            disabled={!props.valuation.estimatingAdvance}
            label="Off-track advance"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceOnTrack")}
            disabled={!props.valuation.estimatingAdvance}
            label="On-track advance"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceUpperBound")}
            disabled={!props.valuation.estimatingAdvance}
            label="Under Offer advance"
          />
        </LeafCell>

        <LeafCell width={6}>
          <NoSubmitTextareaWithLinks {...fieldProps("notes")} label="Notes" />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <div>
            <PrimaryButton
              data-test="create-post-visit-valuation-save-button"
              onClick={props.onSubmit}
              disabled={disableSubmit}
              icon={Tick}
              label="Save Valuation"
            />
          </div>
        </LeafCell>

        <LeafCell width={2} left={6}>
          <ButtonWithIcon
            data-test="create-post-visit-valuation-cancel"
            icon={Delete}
            onSubmit={props.onCancel}
          />
        </LeafCell>
      </Grid>
    </Card>
  );
};
