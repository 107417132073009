// @flow
/* eslint-disable no-alert */

import { useState } from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { Tick } from "@nest-ui/icons";
import { NoSubmitTextField } from "components/TextField";
import { NoSubmitCheckbox as Checkbox } from "components/Checkbox";
import { Button } from "components/Buttons";
import { ButtonWithIcon } from "components/ButtonWithIcon/ButtonWithIcon";

import { Rating } from "./Rating";
import { LIST_COMPARISON_LABEL_QUERY } from "../Comparables";

export const ADD_EXISTING_COMPARISON_POINT = gql`
  mutation AddExistingComparisonPoint(
    $id: ID!
    $input: AddExistingComparisonPointInput!
  ) {
    addExistingComparisonPoint(id: $id, input: $input) {
      id
      comparisonPoints {
        description
        rating
      }
    }
  }
`;

export const ADD_NEW_COMPARISON_POINT = gql`
  mutation AddNewComparisonPoint(
    $id: ID!
    $input: AddNewComparisonPointInput!
  ) {
    addNewComparisonPoint(id: $id, input: $input) {
      id
      comparisonPoints {
        description
        rating
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
`;

const DescriptionTextFieldStyled = styled(NoSubmitTextField)`
  width: 100%;
  margin-bottom: 16px;

  div {
    color: ${({ theme }) => theme.text.white};
  }

  input {
    background-color: ${({ theme }) => theme.background.radioButton};
    border: 2px solid ${({ theme }) => theme.textField.border};
    border-radius: 4px;
    color: ${({ theme }) => theme.textField.text};

    ::placeholder {
      opacity: 0.4;
      color: ${({ theme }) => theme.text.white};
    }

    &:focus {
      border-color: ${({ theme }) => theme.textField.borderFocus};
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
`;

const AddButton = styled(ButtonWithIcon)`
  background-color: ${({ theme }) => theme.button.green};
  border-radius: 4px;
  padding: 0 8px;
`;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.grey};
  margin: 0 0 0 16px;

  &:hover {
    background-color: ${({ theme }) => theme.button.greyHover};
  }
`;

const AddExisting = ({
  comparable,
  rating,
  returnToList,
  selectedComparisonLabel,
}) => (
  <ExtendedMutation mutation={ADD_EXISTING_COMPARISON_POINT}>
    {(
      addExistingComparisonPoint: MutationFunction<
        AddExistingComparisonPoint,
        AddExistingComparisonPointVariables,
      >,
    ) => (
      <AddButton
        icon={Tick}
        color="white"
        data-test="add-existing-comparison-point-btn"
        label="Add point"
        onSubmit={async () => {
          if (!rating) return alert("Add a rating please");

          await addExistingComparisonPoint({
            variables: {
              id: comparable.id,
              input: {
                comparisonLabelId: selectedComparisonLabel.id,
                rating,
              },
            },
          });

          return returnToList();
        }}
      />
    )}
  </ExtendedMutation>
);

const AddNew = ({
  comparable,
  description,
  rating,
  returnToList,
  saveComparisonLabel,
}) => (
  <ExtendedMutation
    mutation={ADD_NEW_COMPARISON_POINT}
    refetchQueries={[{ query: LIST_COMPARISON_LABEL_QUERY }]}
  >
    {(
      addNewComparisonPoint: MutationFunction<
        AddNewComparisonPoint,
        AddNewComparisonPointVariables,
      >,
    ) => (
      <AddButton
        icon={Tick}
        color="white"
        data-test="trigger-add-new-comparison-point-mutation"
        label="Add new point"
        onSubmit={async () => {
          if (!description) return alert("Please enter a description");
          if (!rating) return alert("Please add a rating");

          await addNewComparisonPoint({
            variables: {
              id: comparable.id,
              input: {
                description,
                rating,
                saveComparisonLabel,
              },
            },
          });

          return returnToList();
        }}
      />
    )}
  </ExtendedMutation>
);

type Props = {
  selectedComparisonLabel: ?ListAllComparisonLabels_listAllComparisonLabels,
  comparable: SelectedComparablesQuery_selectedComparables,
  returnToList: () => void,
};

export const AddComparisonPoint = ({
  selectedComparisonLabel,
  comparable,
  returnToList,
}: Props) => {
  const [rating, setRating] = useState(null);
  const [savePoint, setSavePoint] = useState(false);
  const [newComparisonLabel, setNewComparisonLabel] = useState("");

  return (
    <Wrapper>
      <DescriptionTextFieldStyled
        data-test="description-field"
        label="Description"
        value={
          selectedComparisonLabel
            ? selectedComparisonLabel.description
            : newComparisonLabel
        }
        placeholder="e.g. Off street parking"
        readOnly={selectedComparisonLabel}
        onSubmit={(value) => setNewComparisonLabel(value)}
      />
      <Rating
        comparableId={comparable.id}
        dataTest="rating-btn"
        onSelect={setRating}
        ratingValue={rating}
      />
      {!selectedComparisonLabel && (
        <Checkbox
          data-test="save-comparison-point-checkbox"
          optionName="Save comparison point?"
          value={savePoint}
          onSubmit={() => setSavePoint(!savePoint)}
          darkMode
        />
      )}
      <ButtonWrapper>
        <>
          {selectedComparisonLabel ? (
            <AddExisting
              comparable={comparable}
              rating={rating}
              returnToList={returnToList}
              selectedComparisonLabel={selectedComparisonLabel}
            />
          ) : (
            <AddNew
              comparable={comparable}
              description={newComparisonLabel}
              rating={rating}
              returnToList={returnToList}
              saveComparisonLabel={savePoint}
            />
          )}
          <CancelButton
            data-test="add-existing-comparison-point:cancel"
            onClick={returnToList}
          >
            Cancel
          </CancelButton>
        </>
      </ButtonWrapper>
    </Wrapper>
  );
};
