import { useState } from "react";
import { css } from "styled-components";
import { Form, Field } from "react-final-form";
import { Grid, LeafCell } from "components/Grid";
import { ListEntryCard } from "components/Card/ListEntryCard";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCreateButton } from "components/CreateButton/CreateButton";
import { PrimaryButton, CancelButton } from "components/Buttons";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { Delete } from "@nest-ui/icons";
import { ButtonWithIcon } from "components/ButtonWithIcon";

import { H2 } from "components/Heading";
import { required } from "../CreateBuyersReport";

import { FormSection } from "./FormSection";

const noMargin = css`
  margin: 0;
`;

export const sanitizeEvent = ({
  eventDate,
  offerEventType: { label, value },
  price,
}) => ({
  eventDate,
  offerEventType: { label, value },
  price,
});

export const OfferEvents = ({ eventTypeOptions, report, submitHandler }) => {
  const [creatingEvent, setCreating] = useState(false);

  const createEvent = async (state) => {
    const offerEventType = eventTypeOptions.find(
      (type) => type.value === state.eventType,
    );
    const newEvent = {
      ...state,
      offerEventType,
    };
    const newEvents = [...report.offerEvents, newEvent].map(sanitizeEvent);

    submitHandler({ offerEvents: newEvents });
    setCreating(false);
  };

  const deleteEvent = (id) => {
    const newEvents = report.offerEvents
      .filter((event) => event.id !== id)
      .map(sanitizeEvent);
    submitHandler({ offerEvents: newEvents });
  };

  return (
    <FormSection sectionTitle="Offer history">
      <LeafCell width={4}>
        <H2>Offer events</H2>
      </LeafCell>
      {report.offerEvents.length === 0 ? (
        <LeafCell width={4}>No events</LeafCell>
      ) : (
        <>
          {report.offerEvents.map((event, index) => {
            return (
              <LeafCell width={4} key={index}>
                <ListEntryCard css={noMargin}>
                  <Grid columns={4}>
                    <LeafCell>
                      <NoSubmitSelectField
                        value={event.offerEventType.value}
                        options={eventTypeOptions || []}
                        disabled
                        label="Event type"
                        data-test="offer-event-date"
                      />
                    </LeafCell>
                    <LeafCell>
                      <NoSubmitDatePicker
                        value={event.eventDate}
                        label="Event date"
                        disabled
                        data-test="offer-event-date"
                      />
                    </LeafCell>
                    <LeafCell>
                      <NoSubmitCurrencyField
                        value={event.price}
                        label="Price"
                        disabled
                        data-test="offer-event-price"
                      />
                    </LeafCell>
                    <LeafCell>
                      <LeafCell width={1}>
                        <ButtonWithIcon
                          data-test="delete-offer-event"
                          icon={Delete}
                          onSubmit={() => deleteEvent(event.id)}
                        />
                      </LeafCell>
                    </LeafCell>
                  </Grid>
                </ListEntryCard>
              </LeafCell>
            );
          })}
        </>
      )}
      {creatingEvent && (
        <>
          <LeafCell width={4}>
            <Form
              onSubmit={createEvent}
              initialState={{
                price: "",
                eventType: "",
                eventDate: "",
              }}
            >
              {({ handleSubmit, hasValidationErrors, submitting }) => (
                <ListEntryCard css={noMargin}>
                  <Grid columns={4}>
                    <LeafCell width={1}>
                      <Field name="eventType" validate={required}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <NoSubmitSelectField
                            onSubmit={onChange}
                            {...input}
                            {...meta}
                            label="Event type"
                            data-test="create-offer-event-type"
                            options={eventTypeOptions || []}
                          />
                        )}
                      </Field>
                    </LeafCell>
                    <LeafCell width={1}>
                      <Field name="eventDate" validate={required}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <NoSubmitDatePicker
                            onSubmit={onChange}
                            {...input}
                            {...meta}
                            label="Event date"
                            data-test="create-offer-event-date"
                          />
                        )}
                      </Field>
                    </LeafCell>
                    <LeafCell width={1}>
                      <Field name="price" validate={required}>
                        {({ input: { onChange, ...input }, meta }) => (
                          <NoSubmitCurrencyField
                            onSubmit={onChange}
                            {...input}
                            {...meta}
                            label="Price following event"
                            data-test="create-offer-event-price"
                          />
                        )}
                      </Field>
                    </LeafCell>
                    <LeafCell width={1}>
                      <PrimaryButton
                        css={noMargin}
                        data-test="create-offer-event-submit"
                        disabled={hasValidationErrors || submitting}
                        label="Save"
                        onClick={handleSubmit}
                      />
                    </LeafCell>
                  </Grid>
                </ListEntryCard>
              )}
            </Form>
          </LeafCell>
        </>
      )}
      {creatingEvent && (
        <LeafCell width={1} left={4}>
          <CancelButton onClick={() => setCreating(false)} css={noMargin}>
            Cancel
          </CancelButton>
        </LeafCell>
      )}
      {!creatingEvent && (
        <LeafCell width={1} left={4}>
          <NoSubmitCreateButton
            onSubmit={() => setCreating(true)}
            data-test="add-offer-button"
          >
            Add new
          </NoSubmitCreateButton>
        </LeafCell>
      )}
    </FormSection>
  );
};
