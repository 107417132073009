// @flow
// These thresholds relate to the values in the price dropdowns on
// https://www.rightmove.co.uk/property-for-sale/search.html. Hence this is
// potentially brittle; if they change it then we change need to change these.
// Note, we set it to "No min" if it's less than 50k (should never happen) or
// above 2m (it gets varied and irritating beyond this point and I CBA to type
// it all out; we don't take on properties this expensive anyway)
const roundPrice = (price: number, upOrDown: (number, number) => number) => {
  if (price < 50000) return "";
  if (price <= 300000) return upOrDown(price, 10000);
  if (price <= 500000) return upOrDown(price, 25000);
  if (price <= 700000) return upOrDown(price, 50000);
  if (price <= 1000000) return upOrDown(price, 100000);
  if (price <= 2000000) return upOrDown(price, 250000);

  return "";
};

const floor = (numToRound: number, roundToNearest: number) =>
  Math.floor(numToRound / roundToNearest) * roundToNearest;

const roundPriceDown = (price: number) => roundPrice(price, floor);

const ceil = (numToRound: number, roundToNearest: number) =>
  Math.ceil(numToRound / roundToNearest) * roundToNearest;

const roundPriceUp = (price: number) => roundPrice(price, ceil);

export const minPrice = (price: number) => {
  if (price <= 600000) {
    return roundPriceDown(price - price / 10);
  }

  return roundPriceDown(price - price / 5);
};

export const maxPrice = (price: number) => roundPriceUp(price + price / 20);

const recentlySoldTypeMapping = (type: string) => {
  switch (type) {
    case "FLAT":
      return "flats";
    default:
      return "houses";
  }
};

// This builds up a link to the search page with various useful filters
// appropriate to this property. This is used for finding similar properties
// that are under offer in the surrounding area, in order to find useful comps
export const recentlySoldSstcLink = (
  postcode: string,
  beds: number,
  propertyType: string,
  price: Money,
) =>
  "https://www.rightmove.co.uk/property-for-sale/search.html" +
  `?searchLocation=${postcode}` +
  "&buy.x=SALE" +
  "&search=Start+Search" +
  "&radius=0.25" +
  "&includeSSTC=true" +
  `&minPrice=${minPrice(parseInt(price, 10))}` +
  `&maxPrice=${maxPrice(parseInt(price, 10))}` +
  `&maxBedrooms=${beds}` +
  `&minBedrooms=${beds}` +
  `&displayPropertyType=${recentlySoldTypeMapping(propertyType)}`;

const soldTypeMapping = (type: string) => {
  switch (type) {
    case "DETACHED":
      return 1;
    case "FLAT":
      return 2;
    case "SEMI_DETACHED":
      return 3;
    case "TERRACED":
      return 4;
    default:
      return 2;
  }
};

// This is a link to the rightmove search page for finding properties that have
// sold in an area in the last year, in order to find appropriate comps.
export const soldLastYearLink = (postcode: string, propertyType: string) =>
  "https://www.rightmove.co.uk/house-prices/search.html" +
  `?searchLocation=${postcode}` +
  `&propertyType=${soldTypeMapping(propertyType)}` +
  "&housePrices=List+View" +
  "&radius=0.25" +
  "&year=1";
