// @flow
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css, ThemeContext } from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { ContactDetails } from "./ContactDetails";
import { AddContactButton, getContactsQuery } from "./ContactsTab";

export const ADD_EXISTING_CONTACT = gql`
  mutation AddExistingContactToDealOrBuyer(
    $dealId: ID
    $contactId: ID!
    $buyerId: ID
  ) {
    addExistingContact(
      dealId: $dealId
      contactId: $contactId
      buyerId: $buyerId
    ) {
      id
    }
  }
`;

const foundContactStyle = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
`;

const contactDetailsStyle = css`
  flex-grow: 1;
  ${media.tablet`
    width: 160px;
  `}
`;

const emailListStyle = css`
  display: flex;
  flex-direction: column;
`;

const emailStyle = css`
  display: none;

  ${media.tablet`
    color: ${({ theme }) => theme.palette.hague150};
    display: block;
    font-weight: 500;
    margin: 0;
    width: 330px;
  `}
`;

const addContactButtonStyle = css`
  width: 83px;

  ${media.tablet`
    width: 140px;
  `}
`;

const alreadyLinkedStyle = css`
  font-style: italic;
  font-weight: 500;
  margin: 0;
  text-align: right;
`;

const getButtonText = (theme) =>
  window.innerWidth < theme.breakpoints.tablet ? "Add" : "Add contact";

type Props = {
  className?: string,
  contact:
    | FindContactByEmail_findContactByEmail
    | FindContactByEmailUpdateContactModal_findContactByEmail,
  dealId?: ?string,
  buyerId?: ?string,
};

export const LinkExistingContact = ({
  className,
  contact,
  dealId,
  buyerId,
}: Props) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const query = getContactsQuery(dealId);
  const { data, loading, error } = useQuery(query, {
    variables: { id: dealId || buyerId },
  });

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  const [addContact, { error: mutationError }] =
    useMutation(ADD_EXISTING_CONTACT);

  const onAddContact = async () => {
    try {
      const result = await addContact({
        variables: {
          dealId,
          buyerId,
          contactId: contact.id,
        },
      });
      if (result?.data?.addExistingContact?.id) {
        history.push("/contacts");
      } else {
        errorHandler(mutationError);
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  if (loading || !data?.dealOrBuyer) {
    return null;
  }

  const linkedContacts = data.dealOrBuyer.contacts;

  const isContactAlreadyLinked = () =>
    linkedContacts.some((linkedContact) => linkedContact.id === contact.id);

  return (
    <div
      css={foundContactStyle}
      className={className}
      data-test="found-contact-card"
    >
      <ContactDetails css={contactDetailsStyle} contact={contact} showEmail />
      <div css={emailListStyle}>
        {contact.emails.map(({ id, emailAddress }) => (
          <p css={emailStyle} key={`contact-${id}`} className="fs-exclude">
            {emailAddress}
          </p>
        ))}
      </div>
      {isContactAlreadyLinked() ? (
        <p css={alreadyLinkedStyle}>Contact linked</p>
      ) : (
        <AddContactButton
          css={addContactButtonStyle}
          data-test={`add-contact-${contact.id}`}
          onClick={onAddContact}
          text={getButtonText(theme)}
        />
      )}
    </div>
  );
};
