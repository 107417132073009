// @flow
import { useState } from "react";
import { css } from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { media } from "@nested/brand";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { Select } from "@nested/nest/src/components/Select/Select";
import { Button } from "@nested/nest/src/components/Button/Button";
import GetActiveUsersQuery from "./queries/GetActiveUsersQuery.graphql";
import GetInactiveUsersQuery from "./queries/GetInactiveUsersQuery.graphql";

export const REACTIVATE_USER = gql`
  mutation ReactivateUser($id: ID!) {
    reactivateNestedUser(id: $id) {
      id
      active
    }
  }
`;

const buttonStyle = css`
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  margin: auto;
  width: calc(100% - 40px);
  ${media.tablet`
    width: 150px;
  `}
`;

export const ReactivateUserModal = ({ onClose }: { onClose: () => void }) => {
  const { data, loading } = useQuery(GetInactiveUsersQuery);
  const [userId, setUserId] = useState(null);
  const [reactivateUser] = useMutation(REACTIVATE_USER, {
    refetchQueries: [{ query: GetActiveUsersQuery }],
  });

  const options = data?.inactiveNestedUsers.reduce((acc, user) => {
    const userOption = {
      label: user.fullName,
      value: user.id,
    };

    return [...acc, userOption];
  }, []);

  const reactivate = async () => {
    if (!userId) {
      return;
    }

    try {
      const { data: reactivateUserData } = await reactivateUser({
        variables: { id: userId },
      });
      if (reactivateUserData.reactivateNestedUser.active) {
        onClose();
      }
    } catch (e) {
      window.alert(e);
    }
  };

  return (
    <MobileFullScreenModal
      onClose={onClose}
      open
      css="width: 780px; min-height: 500px; overflow: hidden;"
      headerText="Reactivate User"
      data-test="reactivate-user-modal"
    >
      <div css="padding: 20px;">
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <Select
            css="width: 100%;"
            data-test="user-reactivation-select"
            onChange={(id) => setUserId(id)}
            value={userId}
            options={options}
          />
        )}
        <Button
          css={buttonStyle}
          disabled={!userId}
          onClick={() => reactivate()}
        >
          Reactivate
        </Button>
      </div>
    </MobileFullScreenModal>
  );
};
