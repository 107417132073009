import T from "prop-types";
import { compose, withHandlers } from "recompose";
import { withRouter } from "react-router";

import { graphql } from "deal/utils/graphql";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";

import nestedListingsQuery from "./queries/nestedListings.graphql";
import { nestedListingsQueryConfig } from "./queries/config";

import { NestedListings } from "./NestedListings";
import { NestedListingsModal } from "./NestedListingsModal";

const NestedListingsSectionComponent = ({
  data: { nestDeal = {} },
  openModalHandler,
}) => (
  <ErrorBoundary>
    <div data-test="nested-listings-section">
      <NestedListings
        daysSinceOriginalListing={nestDeal.daysSinceOriginalListing}
        listingUrl={nestDeal.nestedListing}
        currentListPrice={nestDeal.currentListPrice}
        openModalHandler={openModalHandler}
      />
      <NestedListingsModal dealId={nestDeal.id} />
    </div>
  </ErrorBoundary>
);

NestedListingsSectionComponent.propTypes = {
  data: T.object.isRequired,
  openModalHandler: T.func.isRequired,
};

export function openModal({ history, match }) {
  return () => history.push(`${match.url}/nested-listings`);
}

const enhance = compose(
  withRouter,
  graphql(nestedListingsQuery, nestedListingsQueryConfig),
  withHandlers({ openModalHandler: openModal }),
);

export const NestedListingsSection = enhance(NestedListingsSectionComponent);
