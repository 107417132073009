// @flow
import { useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks";
import { css } from "styled-components";
import { gql } from "@apollo/client";
import { useSorting } from "@nested/nest/src/hooks/useSorting";
import { useUser } from "@nested/nest/src/hooks/useUser";
import { useListViewCounts } from "@nested/nest/src/hooks/useListViewCounts";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";
import { Select } from "../../components/Select/Select";
import { NoResults } from "../../components/NoResults";
import { ListViewError } from "../../components/ListViewError";
import { PlaceholderTable } from "../../components/Placeholder";
import { Table, TH, SortableTH, DealTD } from "../../components/Table";
import {
  MobileLoader,
  MobileListViewDealCard,
} from "../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
  ImageAndAddress,
  rowWithImage,
  Progressor,
  ListViewCallButton,
} from "../../components/ListViewSharedStyles";
import { prettyFormat } from "../Portfolio/utils";
import { getFullName } from "../utils";

const SELLER_NAME = "SELLER_NAME";
const COMPLETION_DATE = "COMPLETION_DATE";
const SALE_PRICE = "SALE_PRICE";
const FEE = "FEE";
const PROGRESSOR = "PROGRESSOR";

const MOBILE_SORT_OPTIONS = [
  { label: "Seller name", value: SELLER_NAME },
  { label: "Completed date ", value: COMPLETION_DATE },
];

export const COMPLETED_LIST_QUERY = gql`
  query CompletedListViewQuery(
    $sortBy: CompletedListViewSortBy!
    $sortDirection: ListViewSortDirection!
    $email: String!
  ) {
    completedDeals(
      sortBy: $sortBy
      sortDirection: $sortDirection
      email: $email
    ) {
      results {
        id
        externalId
        ownerName
        firstName
        lastName
        address
        salePrice
        finalFeeAmount
        contacts {
          id
          name
          title
          phones {
            id
            telephoneNumber
          }
          avatarUrl
        }
        acceptedBuyerOffer {
          id
          actualDateOfCompletion
        }
        dealTypeAgency {
          id
          baseFee
          referralDiscount
        }
        assignedProgressor {
          id
          firstName
          email
        }
      }
    }
  }
`;

const callButtonTDStyle = css`
  a {
    padding: 20px 0px;
  }
`;

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map((deal) => (
        <MobileListViewDealCard
          displayImage
          key={deal.externalId}
          externalId={deal.externalId}
          address={deal.address}
          contacts={deal.contacts}
          sellerName={getFullName(deal)}
          detailText={`Completed ${formatDateDifference(
            deal.acceptedBuyerOffer.actualDateOfCompletion,
          )}`}
          detailTextTitle={prettyFormat(
            deal.acceptedBuyerOffer.actualDateOfCompletion,
          )}
        />
      ))}
    </div>
  );
};

const DealTable = ({
  loading,
  sortBy,
  sortDirection,
  toggleSortDirection,
  results,
}) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH>Property</TH>
            <SortableTH
              field={SELLER_NAME}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Seller name
            </SortableTH>
            <SortableTH
              field={COMPLETION_DATE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Completed
            </SortableTH>
            <SortableTH
              field={SALE_PRICE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Sale price
            </SortableTH>
            <SortableTH
              field={FEE}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Fee
            </SortableTH>
            <SortableTH
              css="padding-right: 10px;"
              field={PROGRESSOR}
              sortBy={sortBy}
              sortDirection={sortDirection}
              toggleSortDirection={toggleSortDirection}
            >
              Progressor
            </SortableTH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody>
          {results.map((deal) => (
            <tr
              key={deal.externalId}
              data-test={`deal-row-${deal.externalId}`}
              css={rowWithImage}
            >
              <DealTD dealId={deal.externalId}>
                <ImageAndAddress
                  externalId={deal.externalId}
                  address={deal.address}
                />
              </DealTD>
              <DealTD dealId={deal.externalId} className="fs-exclude">
                {getFullName(deal)}
              </DealTD>
              <DealTD
                dealId={deal.externalId}
                title={prettyFormat(
                  deal.acceptedBuyerOffer.actualDateOfCompletion,
                )}
              >
                {formatDateDifference(
                  deal.acceptedBuyerOffer.actualDateOfCompletion,
                )}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {formatPrice(parseFloat(deal.salePrice))}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                {deal.finalFeeAmount
                  ? formatPrice(parseFloat(deal.finalFeeAmount))
                  : "-"}
              </DealTD>
              <DealTD dealId={deal.externalId}>
                <Progressor progressor={deal.assignedProgressor} />
              </DealTD>
              <DealTD dealId={deal.externalId} css={callButtonTDStyle}>
                <ListViewCallButton
                  contacts={deal.contacts}
                  externalId={deal.externalId}
                  relationType={"deal"}
                />
              </DealTD>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export const CompletedListView = () => {
  const { sortBy, setSortBy, sortDirection, toggleSortDirection } = useSorting(
    COMPLETION_DATE,
    "DESCENDING",
  );
  const { selectedUser } = useUser();
  const { completed } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(COMPLETED_LIST_QUERY, {
    variables: {
      sortBy,
      sortDirection,
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [completed]);

  const results = data?.completedDeals?.results || [];

  if (!loading && results.length === 0) {
    return <NoResults message="You have no completed properties." />;
  }

  if (error) {
    return <ListViewError />;
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <Select
          forListView
          label="Sort by"
          value={sortBy}
          onChange={setSortBy}
          options={MOBILE_SORT_OPTIONS}
        />
        <DealList
          loading={loading}
          results={results}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable
          sortBy={sortBy}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          loading={loading}
          results={results}
        />
      </div>
    </>
  );
};
