import { css } from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";
import { Form, Field } from "react-final-form";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitSelectField } from "components/SelectField";
import { PrimaryButton } from "components/Buttons";
import { ListEntryCard } from "components/Card/ListEntryCard";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import {
  UPDATE_BUYERS_REPORT,
  generateValidationErrorString,
} from "./UpdateBuyersReport";
import { required } from "./CreateBuyersReport";

const noMargin = css`
  margin: 0;
`;

// We need to do this to remove shit like __typename
export const sanitizeListingHistoryEvent = ({
  eventDate,
  eventType: { label, value },
  price,
}) => ({
  eventDate,
  eventType: { label, value },
  price,
});

export const CreateListingHistoryEvent = ({
  onClose,
  listingHistoryEvents,
  reportId,
  eventTypeOptions,
}) => {
  const [updateBuyersReport] = useMutation(UPDATE_BUYERS_REPORT);

  const createEvent = async (state) => {
    const eventType = eventTypeOptions.find(
      (type) => type.value === state.eventType,
    );
    const newEvent = {
      ...state,
      eventType,
    };
    const newEvents = [...listingHistoryEvents, newEvent].map(
      sanitizeListingHistoryEvent,
    );

    try {
      const result = await updateBuyersReport({
        variables: {
          id: reportId,
          input: {
            listingHistoryEvents: newEvents,
          },
        },
      });
      if (result?.data?.updateBuyersAgentReport?.success) {
        onClose();
        return;
      }

      const errorMessage = generateValidationErrorString(
        result?.data?.updateBuyersAgentReport?.validationErrors,
      );
      // eslint-disable-next-line no-alert
      window.alert(
        `There was a problem creating the listing history event due to the following fields being invalid:\n\n${errorMessage}`,
      );
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(
        `Failed to update the create the listing history event. Please try again later or contact helpdesk if the problem persists:\n\n${e}`,
      );
    }
  };

  return (
    <Grid columns={4}>
      <LeafCell width={4}>
        <Form
          onSubmit={createEvent}
          initialState={{
            price: "",
            eventType: "",
            eventDate: "",
          }}
        >
          {({ handleSubmit, hasValidationErrors, submitting }) => (
            <ListEntryCard css={noMargin}>
              <Grid columns={4}>
                <LeafCell width={1}>
                  <Field name="eventType" validate={required}>
                    {({ input: { onChange, ...input }, meta }) => (
                      <NoSubmitSelectField
                        onSubmit={onChange}
                        {...input}
                        {...meta}
                        label="Event type"
                        data-test="create-listing-history-type"
                        options={eventTypeOptions || []}
                      />
                    )}
                  </Field>
                </LeafCell>
                <LeafCell width={1}>
                  <Field name="eventDate" validate={required}>
                    {({ input: { onChange, ...input }, meta }) => (
                      <NoSubmitDatePicker
                        onSubmit={onChange}
                        {...input}
                        {...meta}
                        label="Event date"
                        data-test="create-listing-history-date"
                      />
                    )}
                  </Field>
                </LeafCell>
                <LeafCell width={1}>
                  <Field name="price" validate={required}>
                    {({ input: { onChange, ...input }, meta }) => (
                      <NoSubmitCurrencyField
                        onSubmit={onChange}
                        {...input}
                        {...meta}
                        label="Price following event"
                        data-test="create-listing-history-price"
                      />
                    )}
                  </Field>
                </LeafCell>
                <LeafCell width={1}>
                  <PrimaryButton
                    css={noMargin}
                    data-test="create-listing-history-submit"
                    disabled={hasValidationErrors || submitting}
                    label="Save"
                    onClick={handleSubmit}
                  />
                </LeafCell>
              </Grid>
            </ListEntryCard>
          )}
        </Form>
      </LeafCell>
    </Grid>
  );
};
