import jwtDecode from "jwt-decode";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { getConfig } from "@nested/config";
import { getCookie } from "@nested/utils/cookie";
import { logException } from "@nested/utils/sentry";

export const CYPRESS_AUTH_COOKIE_NAME = "cypress_auth0_jwt";

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN, NEST_URI } = getConfig();

const AUTH0_CONFIG = {
  domain: AUTH0_DOMAIN,
  client_id: AUTH0_CLIENT_ID,
  scope: "openid profile email",
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  redirect_uri: `${NEST_URI}/login/callback`,
  connection: "nested-com",
  sessionCheckExpiryDays: 30, // defaults to 1, needs to be the number of days our refresh token is valid for
};

/**
 * Rather than using React Context, the Nest uses old skool global state for its authentication logic.
 * We export a single instance of this class which is initialised before rendering the application,
 * and can then call any auth based logic via it.
 *
 * We have this magic Cypress cookie as a workaround for nested.com gmail accounts requiring 2 factor
 * auth, which is impossible to configure for use with Cypress. It makes the logic a bit more complex
 * and fragile, but is a necessary evil.
 */
class AuthClient {
  // We use this instead of a constructor to allow auth0's async setup which tries to renew expired tokens
  async init() {
    const cypressTestCookie = getCookie(CYPRESS_AUTH_COOKIE_NAME);
    if (cypressTestCookie) {
      const claims = jwtDecode(cypressTestCookie);
      if (claims.exp * 1000 > Date.now()) {
        this.cypressTestCookie = cypressTestCookie;
        return;
      }
    }

    try {
      /*
       * createAuth0Client attempts to renew expired sessions silently, so it can fail
       * due to network or configuration errors. If this fails due to a configuration
       * issue, it is possible to get stuck in an endless loop trying to renew invalid
       * credentials, so it's important that if the client fails to initialise we
       * log the user out. In order to log them out, we need to create a client, which is
       * why we have to use the class constructor instead of this factory function in
       * the catch block.
       */
      await this.initializeAuth0();
    } catch (e) {
      this.auth0 = new Auth0Client(AUTH0_CONFIG);
      // This means session has expired so no need to log it
      if (e.message !== "Unknown or invalid refresh token.") {
        logException(e);
      }
      this.logout();
    }
  }

  async initializeAuth0() {
    const auth0 = await createAuth0Client(AUTH0_CONFIG);
    // Handle the login callback without having to render the app first
    if (
      window.location.pathname.includes("/login/callback") &&
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      const { appState } = await auth0.handleRedirectCallback();
      window.history.replaceState({}, "", appState.previousPath);
    }

    this.auth0 = auth0;
  }

  async authenticated() {
    // Cypress runs only take a few seconds, so there is no point checking if this has expired
    if (this.cypressTestCookie) {
      return true;
    }
    return this.auth0.isAuthenticated();
  }

  async getUser() {
    if (this.cypressTestCookie) {
      return jwtDecode(this.cypressTestCookie);
    }

    return this.auth0.getUser();
  }

  async logout() {
    // `federated: true` logs the user out of their Google account as well as the Nest.
    // This is required because Google will automatically log you back in from the Google
    // auth page if you are currently authenticated with Google and only have 1 possible
    // email for the Nest (which is basically everyone).
    // This is probably fine because people don't often log out and when they do its likely
    // because they're on someone else's computer so clearing everything is a good plan.
    this.auth0.logout({ returnTo: NEST_URI, federated: true });
  }

  async getToken() {
    if (this.cypressTestCookie) {
      return this.cypressTestCookie;
    }

    await this.auth0.getTokenSilently();
    const { __raw } = await this.auth0.getIdTokenClaims();
    return __raw;
  }

  async redirectToLogin() {
    this.auth0.loginWithRedirect({
      appState: {
        previousPath: window.location.pathname,
      },
    });
  }
}

export const authClient = new AuthClient();
