// @flow
import styled, { css } from "styled-components";
import { media, smallParagraph } from "@nested/brand";
import { Button } from "@nested/component-library";
import { type NextFunction, type ActionButtonsProps } from "./Question";

const container = css`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  ${media.tablet`
    margin-top: 40px;
  `}
`;

const errorStyles = css`
  ${smallParagraph}
  color: ${({ theme }) => theme.palette.terracotta100};
  font-weight: 500;
  line-height: 17px;
  margin-top: 0;
  ${media.tablet`
    margin-top: 14px;
  `}
`;

const ButtonGroup = styled.div`
  width: 100%;
  ${media.tablet`
    width: auto;
    button:not(:last-child) {
      margin-right: 20px;
    }
  `}
`;

const sharedButtonStyles = css`
  white-space: nowrap;
  margin-bottom: 10px;
  width: 100%;
  ${media.tablet`
    width: auto;
  `}
`;

type CreateButtonsConfig = {|
  next: NextFunction<any>,
  label: string,
  submitError?: React$Node,
  disabled?: boolean,
|};

export const createActionButtons =
  ({ next, label, submitError, disabled }: CreateButtonsConfig) =>
  ({ skippable, skip = () => next(null) }: ActionButtonsProps) =>
    (
      <div css={container}>
        {submitError && (
          <p css={errorStyles} data-test="submit-error">
            {submitError}
          </p>
        )}
        <ButtonGroup>
          <NextButton disabled={disabled} label={label} />
          {skippable && <SkipButton disabled={disabled} next={skip} />}
        </ButtonGroup>
      </div>
    );

const NextButton = ({
  label,
  disabled,
}: {
  label: string,
  disabled?: boolean,
}) => (
  <Button
    data-test="onboarding-form:next-button"
    disabled={disabled}
    size="large"
    type="submit"
    css={sharedButtonStyles}
  >
    {label}
  </Button>
);

const SkipButton = <T>({
  next,
  disabled,
}: {|
  next: NextFunction<T>,
  disabled?: boolean,
|}) => (
  <Button
    data-test="onboarding-form:skip-button"
    disabled={disabled}
    onClick={next}
    size="large"
    css={sharedButtonStyles}
  >
    Skip
  </Button>
);
