// @flow
import { gql } from "@apollo/client";
import { Fragment } from "react";
import { type MutationFunction } from "@apollo/client/react/components";

import { CreateButton } from "components/CreateButton";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { ChainLink } from "./ChainLink";
import { ChainJoin } from "./ChainJoin";

const PROPERTY_CHAIN_LINK_FRAGMENT = gql`
  fragment PropertyChainLinkFragment on ChainLink {
    address
    agentEmail
    agentPhoneNumber
    contactName
    estateAgent
    id
    leadAgentName
    leadSolicitorName
    notes
    solicitorName
    solicitorPhoneNumber
  }
`;

const CREATE_PROPERTY_CHAINLINK_MUTATION = gql`
  mutation CreatePropertyChainLink($buyerOfferId: ID, $dealId: ID) {
    createPropertyChainLink(buyerOfferId: $buyerOfferId, dealId: $dealId) {
      id
      ...PropertyChainLinkFragment
    }
  }
  ${PROPERTY_CHAIN_LINK_FRAGMENT}
`;

const UPDATE_PROPERTY_CHAINLINK_MUTATION = gql`
  mutation UpdatePropertyChainLink($id: ID!, $input: ChainLinkInput!) {
    updatePropertyChainLink(id: $id, input: $input) {
      id
      ...PropertyChainLinkFragment
    }
  }
  ${PROPERTY_CHAIN_LINK_FRAGMENT}
`;

const DELETE_PROPERTY_CHAINLINK_MUTATION = gql`
  mutation DeletePropertyChainLink($id: ID!) {
    deletePropertyChainLink(id: $id) {
      id
    }
  }
`;

const PROPERTY_CHAINLINKS_QUERY = gql`
  query PropertyChainLinks($buyerOfferId: ID, $dealId: ID) {
    propertyChainLinks(buyerOfferId: $buyerOfferId, dealId: $dealId) {
      id
      ...PropertyChainLinkFragment
    }
  }
  ${PROPERTY_CHAIN_LINK_FRAGMENT}
`;

/*
    This is a list of chain links
*/
export function ChainLinks(variables: {|
  dealId?: string,
  buyerOfferId?: string,
|}) {
  return (
    <ExtendedQuery query={PROPERTY_CHAINLINKS_QUERY} variables={variables}>
      {({
        propertyChainLinks,
      }: ExtendedQueryRenderProps<PropertyChainLinks>) => (
        <ExtendedMutation mutation={CREATE_PROPERTY_CHAINLINK_MUTATION}>
          {(
            createPropertyChainLinkMutation: MutationFunction<
              CreatePropertyChainLink,
              CreatePropertyChainLinkVariables,
            >,
          ) => (
            <ExtendedMutation mutation={DELETE_PROPERTY_CHAINLINK_MUTATION}>
              {(
                deletePropertyChainLinkMutation: MutationFunction<
                  DeletePropertyChainLink,
                  DeletePropertyChainLinkVariables,
                >,
              ) => (
                <ExtendedMutation mutation={UPDATE_PROPERTY_CHAINLINK_MUTATION}>
                  {(
                    updatePropertyChainLinkMutation: MutationFunction<
                      UpdatePropertyChainLink,
                      UpdatePropertyChainLinkVariables,
                    >,
                  ) => {
                    const createPropertyChainLink = () =>
                      createPropertyChainLinkMutation({
                        variables,
                        refetchQueries: [
                          {
                            query: PROPERTY_CHAINLINKS_QUERY,
                            variables,
                          },
                        ],
                      });

                    const updatePropertyChainLink =
                      (propertyChainLink) => (input) => {
                        const updatedPropertyChainLink = {
                          ...propertyChainLink,
                          ...input,
                        };

                        return updatePropertyChainLinkMutation({
                          optimisticResponse: {
                            __typename: "Mutation",
                            updatePropertyChainLink: updatedPropertyChainLink,
                          },
                          variables: {
                            id: propertyChainLink.id,
                            input,
                          },
                        });
                      };

                    const deletePropertyChainLink = (id) => () =>
                      deletePropertyChainLinkMutation({
                        variables: {
                          id,
                        },
                        refetchQueries: [
                          {
                            query: PROPERTY_CHAINLINKS_QUERY,
                            variables,
                          },
                        ],
                      });

                    return (
                      <>
                        {variables.dealId && (
                          <CreateButton
                            data-test="add-seller-property-chain-link"
                            label="Add upward chain member"
                            onSubmit={createPropertyChainLink}
                          />
                        )}

                        {propertyChainLinks.map((link) => (
                          <Fragment key={link.id}>
                            {variables.buyerOfferId && (
                              <ChainJoin direction="down" />
                            )}
                            <ChainLink
                              chainLink={link}
                              updatePropertyChainLink={updatePropertyChainLink(
                                link,
                              )}
                              deletePropertyChainLink={deletePropertyChainLink(
                                link.id,
                              )}
                            />
                            {variables.dealId && <ChainJoin direction="up" />}
                          </Fragment>
                        ))}

                        {variables.buyerOfferId && (
                          <CreateButton
                            data-test="add-buyer-property-chain-link"
                            label="Add downward chain member"
                            onSubmit={createPropertyChainLink}
                          />
                        )}
                      </>
                    );
                  }}
                </ExtendedMutation>
              )}
            </ExtendedMutation>
          )}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
}

ChainLinks.displayName = "ChainLinks";
