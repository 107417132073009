// @flow
import styled from "styled-components";
import { media } from "@nested/brand";

export const TabWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.sand20};
  padding: 20px 16px;
  width: calc(100%);
  ${media.desktop`
    padding: 20px;
  `}
`;

export const TabContainer = styled.div`
  margin: 0 auto;
  max-width: 940px; // The 40 represents the space for the 20px padding
`;
