// @flow
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { useLocation } from "react-router-dom";
import { ComparablePropertiesSection } from "components/Comparables/ComparablePropertiesSection";
import { PrimaryButton } from "components/Buttons";
import { CurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { LeafCell } from "components/Grid";
import { NoSubmitSelectField } from "components/SelectField";
import { TextField } from "components/TextField";

import { AddressPicker } from "./AddressPicker";
import { BUYERS_REPORT_FIELDS_FRAGMENT } from "../ReportFieldsFragment";
import { Card } from "../Card";
import { PropertyOverviewSection } from "./Sections/PropertyOverviewSection";
import { FormSection, SectionWrapper } from "./Sections/FormSection";
import { ListingHistoryEvents } from "./Sections/ListingHistoryEvents";
import { OfferEvents } from "./Sections/OfferEvents";
import { agentDetailsArray } from "./CreateBuyersReport";

const GET_SELECT_OPTIONS = gql`
  query GetSelectOptions {
    buyersAgentReportCustomerStatuses {
      id: value
      label
      value
    }
    buyersAgentReportListingHistoryEventTypes {
      label
      value
    }
    buyersAgentReportOfferEventTypes {
      label
      value
    }
    buyersAgentReportReportStatuses {
      id: value
      label
      value
    }
  }
`;

export const UPDATE_BUYERS_REPORT = gql`
  mutation UpdateBuyersAgentReport(
    $id: ID!
    $input: UpdateBuyersAgentReportInput!
  ) {
    updateBuyersAgentReport(id: $id, input: $input) {
      success
      validationErrors {
        field
        error
      }
      buyersAgentReport {
        id
        ...buyersReportFields
      }
    }
  }
  ${BUYERS_REPORT_FIELDS_FRAGMENT}
`;

type Props = {
  report: getReports_buyersAgentReports,
  onClose(): void,
  customerAccountId: string,
};

type FieldError = {
  error: string,
  field: string,
};

export const generateValidationErrorString = (fields: FieldError[]) =>
  fields.reduce(
    (accString, { field, error }) => accString.concat(`${field} - ${error}\n`),
    "",
  );

export const UpdateBuyersReport = ({
  report,
  onClose,
  customerAccountId,
}: Props) => {
  const [updateBuyersReport] = useMutation(UPDATE_BUYERS_REPORT);
  const { pathname } = useLocation();

  const {
    loading: selectOptionsLoading,
    error: selectOptionsError,
    data: selectOptionsData,
  } = useQuery(GET_SELECT_OPTIONS);

  const lookupStatusLabel = (value) => {
    if (!selectOptionsData) return "";

    return selectOptionsData.buyersAgentReportCustomerStatuses.find(
      (status) => status.value === value,
    );
  };

  const lookupReportStatusLabel = (value) => {
    if (!selectOptionsData) return "";

    return selectOptionsData.buyersAgentReportReportStatuses.find(
      (status) => status.value === value,
    );
  };

  const submitHandler = async (input: any) => {
    try {
      const result = await updateBuyersReport({
        variables: {
          id: report.id,
          input,
        },
        optimisticResponse: {
          updateBuyersAgentReport: {
            __typename: "BuyersAgentReportResult",
            success: true,
            validationErrors: [],
            buyersAgentReport: {
              ...report,
              ...input,
              customerStatus: lookupStatusLabel(
                input.customerStatus?.value || report.customerStatus.value,
              ),
              reportStatus: lookupReportStatusLabel(
                input.reportStatus?.value || report.reportStatus.value,
              ),
            },
          },
        },
      });
      if (result?.data?.updateBuyersAgentReport?.validationErrors.length > 0) {
        const errorMessage = generateValidationErrorString(
          result?.data?.updateBuyersAgentReport?.validationErrors,
        );
        // eslint-disable-next-line no-alert
        window.alert(
          `There was a problem updating the report due to the following fields being invalid:\n\n${errorMessage}`,
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(
        `Failed to update the report. Please try again later or contact helpdesk if the problem persists:\n\n${e}`,
      );
    }
  };

  return (
    <Card
      address={report.addressText}
      reportStatus={report?.reportStatus}
      data-test="edit-report-card"
    >
      <FormSection sectionTitle="Report status">
        <LeafCell width={2}>
          <NoSubmitSelectField
            data-test="report-status-update"
            disabled={Boolean(selectOptionsLoading || selectOptionsError)}
            label="Status of the report"
            options={selectOptionsData?.buyersAgentReportReportStatuses || []}
            onSubmit={(value) => submitHandler({ reportStatus: { value } })}
            value={
              selectOptionsLoading || selectOptionsError
                ? ""
                : report.reportStatus.value
            }
          />
        </LeafCell>
      </FormSection>
      <FormSection sectionTitle="Report Details">
        <LeafCell width={2}>
          <AddressPicker
            name="address"
            onChange={submitHandler}
            label="Property address"
            value={{
              addressText: report.addressText,
              addressId: report.addressId,
            }}
          />
        </LeafCell>
        <LeafCell width={2}>
          <NoSubmitSelectField
            data-test="assigned-agent-update"
            label="Agent name"
            options={agentDetailsArray}
            onSubmit={(email) => {
              const option = agentDetailsArray.find(
                (item) => item.value === email,
              );
              submitHandler({ agentEmail: email, agentName: option?.label });
            }}
            value={agentDetailsArray.find(
              (item) => item.label === report.agentName,
            )}
          />
        </LeafCell>
        <LeafCell width={2}>
          <NoSubmitDatePicker
            data-test="report-card-added-at"
            disabled
            label="Report created on"
            value={report.addedAt}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            data-test="listing-link-update"
            label="Listing link"
            mutation={submitHandler}
            placeholder="Paste listing link"
            property="listingLink"
            value={report.listingLink}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            data-test="primary-photo-update"
            label="Primary photo"
            mutation={submitHandler}
            placeholder="Paste photo link"
            property="primaryPhoto"
            value={report.primaryPhoto}
          />
        </LeafCell>
        <LeafCell width={2}>
          <NoSubmitSelectField
            data-test="customer-status-update"
            disabled={Boolean(selectOptionsLoading || selectOptionsError)}
            label="Customer status with this property"
            options={selectOptionsData?.buyersAgentReportCustomerStatuses || []}
            onSubmit={(value) => submitHandler({ customerStatus: { value } })}
            value={
              selectOptionsLoading || selectOptionsError
                ? ""
                : report.customerStatus.value
            }
          />
        </LeafCell>
        {report.customerStatus.value !== "NO_OFFER_MADE" && (
          <>
            <LeafCell width={2}>
              <CurrencyField
                data-test="latest-offer-update"
                label="Latest Offer"
                mutation={submitHandler}
                property="latestOffer"
                value={report.latestOffer}
              />
            </LeafCell>
            <LeafCell width={2}>
              <CurrencyField
                data-test="savings-update"
                label="Savings (list price - accepted offer)"
                mutation={submitHandler}
                property="savings"
                value={report.savings}
              />
            </LeafCell>
          </>
        )}
        <LeafCell width={2} />
      </FormSection>

      <OfferEvents
        report={report}
        submitHandler={submitHandler}
        eventTypeOptions={selectOptionsData?.buyersAgentReportOfferEventTypes}
      />

      <FormSection sectionTitle="Listing History">
        <LeafCell width={4}>
          <ListingHistoryEvents
            reportId={report.id}
            customerAccountId={customerAccountId}
            eventTypeOptions={
              selectOptionsData?.buyersAgentReportListingHistoryEventTypes
            }
            listingHistoryEvents={report.listingHistoryEvents}
          />
        </LeafCell>
      </FormSection>

      <FormSection sectionTitle="Similar Properties">
        <LeafCell width={4}>
          <SectionWrapper>
            <ComparablePropertiesSection
              comparablesUrl={`${pathname}/report/${report.id}/comparables`}
              sourceType="BUYERS_AGENT_REPORT"
              sourceId={report.id}
            />
          </SectionWrapper>
        </LeafCell>
      </FormSection>

      <FormSection sectionTitle="Offer Guidance">
        <PropertyOverviewSection
          report={report}
          submitHandler={submitHandler}
        />
      </FormSection>

      <FormSection>
        <LeafCell left={4} width={1}>
          <PrimaryButton
            data-test="cancel-editing-button"
            onClick={onClose}
            label="Done editing"
          />
        </LeafCell>
      </FormSection>
    </Card>
  );
};
