// @flow
import { InfoBlob } from "components/InfoBlob/InfoBlob";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { NoSubmitPercentageField } from "components/PercentageField";
import { DeleteValuation } from "./DeleteValuation";

type Props = {
  valuation: *,
  isReadOnly: boolean,
};

export const FinalValuationPreOct18 = ({ valuation, isReadOnly }: Props) => (
  <Grid columns={4}>
    <LeafCell width={2}>
      <NoSubmitDatePicker
        data-test="valuation-list-valuation-date"
        label="Valuation Date"
        value={valuation.nullableDateCompleted}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-recommended-listing-price"
        label="Recommended Listing Price"
        value={valuation.finalValRecommendedListingPrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-expected-sale-price"
        label="Expected Sale Price"
        value={valuation.finalValExpectedSalePrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-advance-amount"
        label="Advance Amount"
        value={valuation.advanceAmount}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitPercentageField
        data-test="valuation-list-advance-percent"
        label="Advance %"
        value={valuation.advancePercentage}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={4}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-notes"
        label="Notes"
        value={valuation.notes}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    {!isReadOnly && (
      <LeafCell width={1} left={4}>
        <DeleteValuation
          valuationType="FINAL_VALUATION_PRE_OCT_18"
          id={valuation.id}
          dealId={valuation.dealId}
        />
      </LeafCell>
    )}
    <InfoBlob>PRE-NOV 2018</InfoBlob>
  </Grid>
);
