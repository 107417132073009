// @flow
import { useRef, useState } from "react";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { SnoozeIcon } from "@nested/brand/src/icons/SnoozeIcon";
import { Button } from "../../../../components/Button/Button";
import { Calendar } from "../../../../components/Calendar/Calendar";
import { CalendarPopupBox } from "../../../../components/Calendar/CalendarPopupBox";
import { SnoozeButtons } from "../../../../components/Calendar/SnoozeButtons";

export const SNOOZE_BPI_MUTATION = gql`
  mutation SnoozeBPI($id: ID!, $input: CreateSnoozeInput!) {
    snoozeBuyerPropertyInterest(id: $id, input: $input) {
      id
      buyerPropertyInterests {
        id
        snooze {
          id
          snoozedUntil
        }
      }
    }
  }
`;

const snoozeButtonStyle = css`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: ${({ theme }) => theme.palette.hague70};
  width: 30px;
  height: 32px;
  padding: 0;
`;

const calendarPopupStyle = css`
  ${media.tablet`
    right: -40px;
    top: 40px;
    left: auto;
  `}
`;

type Props = {
  bpiId: string,
  openMessageModal: () => void,
};

export const SnoozeButton = ({ bpiId, openMessageModal }: Props) => {
  const snoozeRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [snoozeMutation, { loading: snoozeLoading }] =
    useMutation(SNOOZE_BPI_MUTATION);

  const snooze = async (date) => {
    const snoozedUntil = moment(date).startOf("day").toISOString();

    try {
      const result = await snoozeMutation({
        variables: {
          id: bpiId,
          input: {
            snoozedUntil,
          },
        },
      });

      if (result?.data?.snoozeBuyerPropertyInterest?.id) {
        openMessageModal();
        return;
      }

      if (result?.errors) {
        throw result?.errors;
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      setShowCalendar(false);
    }
  };

  return (
    <div ref={snoozeRef} css="position: relative;">
      <Button
        data-test="snooze-button"
        css={snoozeButtonStyle}
        onClick={() => setShowCalendar(true)}
        buttonStyle="white"
        disabled={snoozeLoading}
      >
        <SnoozeIcon css="width: 17px; height: 18px;" />
      </Button>
      {showCalendar && (
        <CalendarPopupBox
          css={calendarPopupStyle}
          onClose={() => setShowCalendar(false)}
          parentRef={snoozeRef}
        >
          <SnoozeButtons onClick={snooze} />
          <Calendar
            value={null}
            minDate={moment().startOf("day").add(1, "day").toDate()}
            onChange={snooze}
          />
        </CalendarPopupBox>
      )}
    </div>
  );
};
