import { gql } from "@apollo/client";

export const completeDealConfig = {
  props: ({ mutate, ownProps: { dealId } }) => ({
    completeDeal: () =>
      mutate({
        variables: {
          input: {
            dealId,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          completeDeal: {
            __typename: "NestDeal",
            id: dealId,
            active: false,
          },
        },
        refetchQueries: [
          {
            query: gql`
              query CompleteDealRefetch($dealId: ID!) {
                nestDeal(id: $dealId) {
                  id
                  active
                }
              }
            `,
            variables: {
              dealId,
            },
          },
        ],
      }),
  }),
};
