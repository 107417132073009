// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../components/Navigation/Breadcrumbs";
import { BookVisitListView } from "./BookVisitListView";
import { CloseListView } from "./CloseListView";
import { ValuationListView } from "./ValuationListView";
import { ListViewPageWrapper } from "../../components/ListViewSharedStyles";

export const Leads = () => {
  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Sellers</Breadcrumb>
        <Breadcrumb to="#">Leads</Breadcrumb>
      </Breadcrumbs>
      <Switch>
        <Redirect from="/leads" to="/leads/book-visit" exact />
        <Route path="/leads/book-visit" component={BookVisitListView} />
        <Route path="/leads/valuation" component={ValuationListView} />
        <Route path="/leads/close" component={CloseListView} />
      </Switch>
    </ListViewPageWrapper>
  );
};
