// @flow
import styled from "styled-components";
import { theme } from "@nest-ui/styles";

export const ViewingStatusIndicator = styled.span`
  background-color: ${({ hasBuyerFeedback, alert }) => {
    if (alert) {
      return theme.color.background.danger;
    }
    if (hasBuyerFeedback) {
      return theme.color.secondary.light;
    }
    return "#ffc466";
  }};
  border-radius: 100%;
  height: 8px;
  margin-right: 7px;
  width: 8px;
  display: inline-block;
`;
