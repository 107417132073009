// @flow

import { useState } from "react";

const sortAscendingFirstValues = ["SELLER_NAME", "PROGRESSOR"];

export const useSorting = (
  defaultSortBy: string,
  defaultSortDirection: string,
) => {
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortDirection, setSortDirection] =
    useState<string>(defaultSortDirection);
  const toggleSortDirection = (newSortBy: string) => {
    if (sortBy === newSortBy) {
      if (sortDirection === "ASCENDING") {
        setSortDirection("DESCENDING");
        return;
      }
      setSortDirection("ASCENDING");
      return;
    }
    setSortBy(newSortBy);
    if (sortAscendingFirstValues.includes(newSortBy)) {
      setSortDirection("ASCENDING");
    } else {
      setSortDirection("DESCENDING");
    }
  };

  return {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    toggleSortDirection,
  };
};
