// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { TextField } from "components/TextField";
import { useMutation } from "@apollo/client/react/hooks";
import { NoSubmitSelectField } from "components/SelectField";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

export const MARKETING_OPTIONS = [
  { value: "AUCTION_REFERRAL", label: "Auction referral" },
  { value: "BILLBOARD_POSTER_TUBE", label: "Billboard, poster or tube ad" },
  { value: "PARTNER", label: "Business referral" },
  { value: "DOORKNOCK", label: "Doorknock" },
  { value: "FACEBOOK", label: "Facebook" },
  { value: "FOR_SALE_BOARD", label: "For sale board" },
  { value: "A_FRIEND", label: "Friend" },
  { value: "GOOGLE", label: "Google" },
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "POST", label: "Letter through the post" },
  { value: "NESTED_EMPLOYEE", label: "Nested employee / Area Partner" },
  { value: "PRESS", label: "Press" },
  { value: "RIGHTMOVE", label: "Rightmove" },
  { value: "TV", label: "TV ad" },
  { value: "VALPAL", label: "Valpal" },
  { value: "ZOOPLA", label: "Zoopla" },
];

const options = MARKETING_OPTIONS.concat(
  { value: "GET_AGENT", label: "GetAgent" },
  { value: "RIGHTMOVE_PAID", label: "Rightmove Paid" },
  { value: "RIGHTMOVE_DISCOVER", label: "Rightmove Discover" },
  { value: "LOCAL_BUSINESS", label: "Local business" },
  { value: "OTHER", label: "Other" },
  {
    value: "RADIO",
    label: "Radio",
  },
  {
    value: "TUBE_BUS",
    label: "Tube/Bus",
  },
  {
    value: "RIGHTMOVE_ZOOPLA",
    label: "Rightmove/Zoopla (deprecated)",
    isDisabled: true,
  },
  {
    value: "TV_RADIO",
    label: "TV/Radio (deprecated)",
    isDisabled: true,
  },
);

export const SOURCES_QUERY = gql`
  query ValuationEditSourcesSection($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      partnerReferral
      onboardingFormMarketingSources
    }
  }
`;

export const UPDATE_SOURCES = gql`
  mutation ValuationEditSourcesNestDealUpdate(
    $id: ID!
    $input: NestDealInput!
  ) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      partnerReferral
      onboardingFormMarketingSources
    }
  }
`;

const EditableFields = ({ nestDeal, dealId: id }) => {
  const [updateNestDeal] = useMutation(UPDATE_SOURCES);

  const update = (input) => {
    updateNestDeal({
      variables: {
        id,
        input,
      },
      optimisticResponse: {
        updateNestDeal: {
          ...nestDeal,
          ...input,
        },
      },
    });
  };

  return (
    <Grid columns={4}>
      <LeafCell width={2}>
        <TextField
          data-test="edit-sources:referrer"
          label="Referrer name/code"
          property="partnerReferral"
          value={nestDeal.partnerReferral}
          mutation={update}
        />
      </LeafCell>
      <LeafCell width={2}>
        <NoSubmitSelectField
          data-test="edit-sources:source-select"
          options={options}
          label="Sources"
          multiple
          onSubmit={(input) =>
            update({ onboardingFormMarketingSources: input })
          }
          value={nestDeal.onboardingFormMarketingSources}
          searchable
        />
      </LeafCell>
    </Grid>
  );
};

export const EditSources = ({ dealId }: { dealId: string }) => {
  return (
    <section id="edit_sources">
      <H2>Edit sources (Admin only)</H2>
      <p>
        For referral codes please use the format:{" "}
        <b>REFERRAL_CODE:Uaihdu_XxXx</b>
      </p>
      <ExtendedQuery query={SOURCES_QUERY} variables={{ dealId }}>
        {({
          nestDeal,
        }: ExtendedQueryRenderProps<ValuationEditSourcesSection>) => (
          <EditableFields nestDeal={nestDeal} dealId={dealId} />
        )}
      </ExtendedQuery>
    </section>
  );
};
