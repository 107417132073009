// @flow
import type { Node } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { device } from "@nest-ui/styles";

export const TileGroup = styled.div`
  margin-top: 20px;
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  @media ${device.tablet} {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }
`;

export const TileGroupTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.primary.dark};
  text-align: center;
  letter-spacing: 0.25em;
  margin: 40px 0 0;

  &:first-child {
    margin: 0;
  }
`;

export const Button = styled.button`
  position: relative;
  background-color: #fff;
  cursor: pointer;
  height: 80px;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  font-size: 2em;
  color: ${({ theme }) => theme.color.primary.dark};
  box-shadow: 0 2px 8px 0 rgba(53, 63, 82, 0.1);
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`;

const PrepBadge = styled.div`
  padding: 20px 5px 5px;
  position: absolute;
  top: -8px;
  right: -40px;
  background-color: ${({ theme }) => theme.color.primary.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  transform: rotate(45deg);

  &:before {
    content: "prep";
    font-size: 0.5em;
    text-transform: uppercase;
    color: #fff;
  }
`;

type LinkProps =
  | {|
      children: Node,
      disabled?: boolean,
      id: string, // ID is necessary for analytics so we can track link clicks
      prep?: boolean,
      href: string,
      onClick?: () => void,
    |}
  | {|
      children: Node,
      disabled?: boolean,
      id: string, // ID is necessary for analytics so we can track link clicks
      prep?: boolean,
      to: string,
      onClick?: () => void,
    |};

function isIpad() {
  return !!navigator.userAgent && /iPad/.test(navigator.userAgent);
}

export const TileLink = (props: LinkProps) => {
  const { children, disabled, prep, ...rest } = props;

  if (rest.to) {
    return (
      <Link id={rest.id} to={rest.to}>
        <Button disabled={disabled}>
          {prep && <PrepBadge />}
          {children}
        </Button>
      </Link>
    );
  }

  // $FlowFixMe because flow can't narrow down types
  if (!rest.href.includes("googledrive://") || isIpad()) {
    return (
      // $FlowFixMe because flow can't narrow down types
      <a href={rest.href} id={rest.id} target="_blank">
        <Button disabled={disabled}>
          {prep && <PrepBadge />}
          {children}
        </Button>
      </a>
    );
  }

  return (
    <a
      // $FlowFixMe because flow can't narrow down types
      href={rest.href.replace("googledrive://", "")}
      id={rest.id}
      target="_blank"
    >
      <Button disabled={disabled}>
        {prep && <PrepBadge />}
        {children}
      </Button>
    </a>
  );
};

type ButtonProps = {
  children: Node,
  disabled?: boolean,
  prep?: boolean,
  onClick?: () => void,
};

export const TileButton = (props: ButtonProps) => {
  const { children, prep, ...rest } = props;

  return (
    <Button {...rest}>
      {prep && <PrepBadge />}
      {children}
    </Button>
  );
};
