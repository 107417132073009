// @flow
import { gql } from "@apollo/client";
import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";

import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

type ContractDetailsQuery = {
  ...ContractDetailsQuery,
  nestDeal: ContractDetailsQuery_nestDeal,
};

type Props = {
  dealId: string,
};

const CONTRACT_DETAILS_QUERY = gql`
  query ContractDetailsQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      legalAddress
    }
  }
`;

const CONTRACT_DETAILS_MUTATION = gql`
  mutation UpdateDealContractDetails($nestDeal: NestDealInput!, $dealId: ID!) {
    updateNestDeal(nestDeal: $nestDeal, id: $dealId) {
      id
      legalAddress
    }
  }
`;

export const ContractDetails = ({ dealId }: Props) => (
  <ExtendedQuery query={CONTRACT_DETAILS_QUERY} variables={{ dealId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<ContractDetailsQuery>) => (
      <Mutation mutation={CONTRACT_DETAILS_MUTATION}>
        {(
          updateNestDeal: MutationFunction<
            UpdateDealContractDetails,
            UpdateDealContractDetailsVariables,
          >,
        ) => (
          <Grid columns={1}>
            <LeafCell width={1}>
              <TextField
                label="Legal Address"
                value={nestDeal.legalAddress}
                property="legalAddress"
                mutation={mutationConfig(updateNestDeal, nestDeal, dealId)}
                data-test="legal-address"
                className="fs-exclude"
              />
            </LeafCell>
          </Grid>
        )}
      </Mutation>
    )}
  </ExtendedQuery>
);

const mutationConfig =
  (
    mutate: MutationFunction<
      UpdateDealContractDetails,
      UpdateDealContractDetailsVariables,
    >,
    nestDeal: ContractDetailsQuery_nestDeal,
    id: string,
  ) =>
  (input) =>
    mutate({
      variables: {
        nestDeal: input,
        dealId: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          __typename: "NestDeal",
          id,
          ...nestDeal,
          ...input,
        },
      },
    });
