// @flow
import { createContext, useState } from "react";

type Props = {
  user: {
    given_name: string,
    family_name: string,
    email: string,
    /* 
    The only way to get a non standard key into a JWT is if 
    it is prefixed by a domain, so this is our key for 
    accessing the array of groups that the user belongs to. 
    */
    "https://nested.com/group"?: string[],
  },
  children: React$Node,
};

export type User = {|
  id: ?string,
  firstName: string,
  lastName: string,
  fullName: string,
  email: string,
  isNestAdmin: boolean,
|};

export const UserContext = createContext<{
  email: string,
  currentUser: User,
  selectedUser: User,
  setSelectedUser(user: User): void,
}>({});

const isNestAdmin = (groups) => groups.includes("Super");

export const UserProvider = ({ user, children }: Props) => {
  const currentUser = {
    id: null,
    firstName: user.given_name,
    lastName: user.family_name,
    fullName: `${user.given_name} ${user.family_name}`,
    email: user.email,
    isNestAdmin: isNestAdmin(user["https://nested.com/group"] || []),
  };
  const [selectedUser, setSelectedUser] = useState<User>(currentUser);

  return (
    <UserContext.Provider
      value={{
        email: currentUser.email,
        currentUser,
        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
