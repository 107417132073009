// @flow
import { gql } from "@apollo/client";
import type { Node } from "react";
import type { MutationFunction } from "@apollo/client/react/components";
import { DatePicker } from "components/DatePicker";
import { NumberField } from "components/NumberField";
import { Grid, LeafCell } from "components/Grid";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

const UPDATE_ADVANCES_FOR_ALL_DEAL_TYPE = gql`
  mutation updateAdvancesForAllMarch2019DealType(
    $id: ID!
    $input: AdvancesForAllDealTypeInput!
  ) {
    updateAdvancesForAllMarch2019DealType(id: $id, input: $input) {
      id
      ddPeriodStartDate
      coolingOffPeriodDays
      coolingOffPeriodEndDate
    }
  }
`;

const QUERY_COOLING_OFF_PERIOD_DATES = gql`
  query CoolingOffPeriodDatesQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      dealTypeDetails {
        ... on DealTypeAdvancesForAllMarch2019 {
          id
          ddPeriodStartDate
          coolingOffPeriodDays
          coolingOffPeriodEndDate
        }
      }
    }
  }
`;

type Props = { dealId: string };

export const AdvancesForAllMar2019CoolingOff = ({ dealId }: Props): Node => (
  <ExtendedQuery
    query={QUERY_COOLING_OFF_PERIOD_DATES}
    variables={{ id: dealId }}
  >
    {({ nestDeal }: ExtendedQueryRenderProps<CoolingOffPeriodDatesQuery>) => {
      const { dealTypeDetails } = nestDeal;
      if (!dealTypeDetails) {
        return null;
      }

      if (dealTypeDetails.__typename !== "DealTypeAdvancesForAllMarch2019") {
        return null;
      }

      return (
        <ExtendedMutation mutation={UPDATE_ADVANCES_FOR_ALL_DEAL_TYPE}>
          {(
            updateAdvancesForAllMarch2019Mutation: MutationFunction<
              updateAdvancesForAllMarch2019DealType,
              updateAdvancesForAllMarch2019DealTypeVariables,
            >,
          ) => {
            const mutate = (input) =>
              updateAdvancesForAllMarch2019Mutation({
                variables: { input, id: dealTypeDetails.id },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateAdvancesForAllMarch2019DealType: {
                    __typename: "DealTypeAdvancesForAllMarch2019",
                    ...dealTypeDetails,
                    ...input,
                  },
                },
              });
            return (
              <Grid columns={4}>
                <LeafCell width={1}>
                  <DatePicker
                    mutation={mutate}
                    label="DD period start date"
                    value={dealTypeDetails.ddPeriodStartDate}
                    property="ddPeriodStartDate"
                    data-test="dd-period-start-date"
                  />
                </LeafCell>
                <LeafCell width={1}>
                  <NumberField
                    mutation={mutate}
                    property="coolingOffPeriodDays"
                    label="DD period (days)"
                    value={dealTypeDetails.coolingOffPeriodDays}
                    data-test="cooling-off-period-days"
                  />
                </LeafCell>
                <LeafCell width={1} />
              </Grid>
            );
          }}
        </ExtendedMutation>
      );
    }}
  </ExtendedQuery>
);
