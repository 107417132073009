// @flow
import styled from "styled-components";
import { NoSubmitSelectField } from "components/SelectField";
import { Grid, LeafCell } from "components/Grid";
import { Label } from "components/Label";

const Wrapper = styled.span`
  float: right;
  color: ${({ theme }) => theme.text.white};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.regular};
  padding-bottom: 16px;
`;

const SortLabel = styled(Label)`
  padding-bottom: 5px;
`;

type SortOptionItem = {
  label: string,
  value: SortBy,
};

export type StateSortItem = {
  sort: SortBy,
  order: SortOrder,
};

const sortMap = {
  PRICE: {
    orders: [
      { label: "Low - High", value: "ASC" },
      { label: "High - Low", value: "DESC" },
    ],
    label: "Price",
    defaultOrder: "ASC",
  },
  STATUS: {
    orders: [
      { label: "Sold - Withdrawn", value: "SOLD_TO_WITHDRAWN" },
      { label: "Withdrawn - Sold", value: "WITHDRAWN_TO_SOLD" },
    ],
    label: "Status",
    defaultOrder: "SOLD_TO_WITHDRAWN",
  },
};

const sortOptions: Array<SortOptionItem> = Object.keys(sortMap).map((item) => ({
  label: sortMap[item].label,
  value: item,
}));

type Props = {
  sortBy: StateSortItem,
  setSortBy: (sortItem: StateSortItem) => void,
};

export const Sorter = ({ sortBy, setSortBy }: Props) => (
  <Wrapper>
    <Grid columns={7}>
      <LeafCell width={1}>
        <SortLabel>Sort by:</SortLabel>
      </LeafCell>
      <LeafCell width={2}>
        <NoSubmitSelectField
          value={sortBy.sort}
          options={sortOptions}
          onSubmit={(sortSelection) => {
            setSortBy({
              sort: sortSelection,
              order: sortMap[sortSelection].defaultOrder,
            });
          }}
          data-test="comparables:sort-by"
          dark
        />
      </LeafCell>
      <LeafCell width={1}>
        <SortLabel>Order:</SortLabel>
      </LeafCell>
      <LeafCell width={3}>
        <NoSubmitSelectField
          value={sortBy.order}
          options={sortMap[sortBy.sort].orders}
          onSubmit={(orderSelection) =>
            setSortBy({ ...sortBy, order: orderSelection })
          }
          data-test="comparables:sort-order"
          dark
        />
      </LeafCell>
    </Grid>
  </Wrapper>
);
