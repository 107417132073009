// @flow
import { css } from "styled-components";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { media, ChangeOnBlurNestTextInput } from "@nested/brand";
import { getImage } from "@nested/utils";
import { getFullName, getStatusSummary } from "../utils";
import { Placeholder } from "../../components/Placeholder";

const propertySummary = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const overviewText = css`
  margin: 0;
  line-height: 20px;
`;

const sellerNameStyle = css`
  ${overviewText}
  input {
    border: none;
    color: ${({ theme }) => theme.palette.hague150};
    font-weight: 400;
    height: unset;
    padding: 0;
  }
`;

const addressStyle = css`
  ${overviewText}
  font-weight: 500;
`;

const propertyThumbnail = css`
  width: 80px;
  margin-right: 15px;
  border-radius: 2px;
`;

const listedSince = css`
  align-items: baseline;
  display: ${({ hideActivity }) => (hideActivity ? "none" : "flex")};
`;

const listedSinceText = css`
  ${overviewText}
  color: ${({ theme }) => theme.palette.hague70};
  display: inline;
  white-space: nowrap;
`;

export const mobileActiveDot = css`
  display: inline-block;
  height: 7px;
  width: 7px;
  margin-left: 5px;
  border-radius: 100%;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.green100 : theme.palette.terracotta100};
  ${media.tablet`
    display: none;
  `}
`;

export const UPDATE_NAME_MUTATION = gql`
  mutation UpdateSellerName($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      firstName
      lastName
    }
  }
`;

export const PROPERTY_SUMMARY_QUERY = gql`
  query PropertySummary($id: ID!) {
    nestDeal(id: $id) {
      id
      active
      externalId
      firstName
      lastName
      ownerName
      acceptedBuyerOffer {
        id
        actualDateOfCompletion
        actualDateOfExchange
        placedOn
      }
      address
      createdAt
      currentListingStartDate
      dealTypeAgency {
        id
        nestedContractSignedDate
      }
      opportunityStatus {
        valueText
      }
      propertyAnalysisSharedAt
      rmMostRecentContactDatetime
    }
  }
`;

const PlaceholderSummary = () => (
  <div css={propertySummary}>
    <Placeholder css={propertyThumbnail} width={80} height={60} />
    <div>
      <div css={addressStyle}>
        <Placeholder width={140} /> <Placeholder width={40} />
      </div>
      <div css={overviewText}>
        <Placeholder width={80} /> <Placeholder width={90} />
      </div>
      <div css={listedSince}>
        <div css={listedSinceText}>
          <Placeholder />
        </div>
      </div>
    </div>
  </div>
);

type Props = {
  idOrExternalId: string,
  hideActivity?: boolean,
};

export const PropertySummary = ({
  idOrExternalId,
  hideActivity = false,
}: Props) => {
  const { data, loading } = useQuery(PROPERTY_SUMMARY_QUERY, {
    variables: { id: idOrExternalId },
  });
  const [updateName] = useMutation(UPDATE_NAME_MUTATION);

  if (loading) {
    return <PlaceholderSummary />;
  }

  const {
    externalId,
    acceptedBuyerOffer,
    active,
    address,
    createdAt,
    currentListingStartDate,
    dealTypeAgency,
    opportunityStatus,
    propertyAnalysisSharedAt,
    rmMostRecentContactDatetime,
  } = data?.nestDeal;

  const sellerName = getFullName(data?.nestDeal);
  const isListed = opportunityStatus?.valueText >= "s08_listed";

  return (
    <div css={propertySummary}>
      {isListed && (
        <img
          alt=""
          css={propertyThumbnail}
          src={getImage(`property-listings/${externalId}/1`)}
        />
      )}
      <div>
        <p css={addressStyle} className="fs-exclude">
          {address}
        </p>
        <ChangeOnBlurNestTextInput
          className="fs-exclude"
          css={sellerNameStyle}
          value={sellerName}
          onChange={(e) => {
            updateName({
              variables: {
                id: idOrExternalId,
                input: {
                  displayName: e.target.value,
                },
              },
              optimisticResponse: {
                updateNestDeal: {
                  id: data?.nestDeal.id,
                  firstName: e.target.value,
                  lastName: "",
                },
              },
            });
          }}
        />
        <div css={listedSince} hideActivity={hideActivity}>
          <div css={listedSinceText}>
            {getStatusSummary({
              opportunityStatus,
              acceptedBuyerOffer,
              createdAt,
              currentListingStartDate,
              nestedContractSignedDate:
                dealTypeAgency?.nestedContractSignedDate,
              propertyAnalysisSharedAt,
              rmMostRecentContactDatetime,
            })}
            <div css={mobileActiveDot} active={active} />
          </div>
        </div>
      </div>
    </div>
  );
};
