// @flow
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Form, Field } from "react-final-form";
import moment from "moment-timezone";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { Checkbox } from "@nested/nest/src/components/Checkbox/Checkbox";
import { formatShortDate } from "@nested/utils/src/formatDate/formatDate";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { DatePicker } from "@nested/nest/src/components/DatePicker/DatePicker";
import { Button } from "@nested/nest/src/components/Button/Button";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { Confetti } from "@nested/your-account/app/components/Confetti";
import reloadAgreedOrAcceptedOffer from "../../queries/reloadAgreedOrAcceptedOffer.graphql";
import reloadDealStatus from "../../queries/reloadDealStatus.graphql";
import { GET_DATES } from "../ProgressionSummary/ExchangeAndCompleteDates/ExchangeAndCompleteDates";
import unreachMilestoneMutation from "../unreachMilestone.graphql";
import {
  MILESTONE_LABELS,
  milestoneStyle,
  checkboxStyle,
  dateStyle,
  type MilestoneProps,
} from "./MilestoneShared";
import {
  modalStyle,
  required,
  textStyle,
  sectionStyle,
  fieldStyle,
  buttonStyle,
} from "./Exchanged";

export const REACH_COMPLETED_MILESTONE = gql`
  mutation ReachCompletedMilestone(
    $buyerOfferId: ID!
    $input: ReachCompletedMilestoneInput!
  ) {
    reachCompletedMilestone(buyerOfferId: $buyerOfferId, input: $input) {
      id
      reachedAt
    }
  }
`;

export const CompletedMilestone = ({
  buyerOfferId,
  dealId,
  disabled,
  milestone,
}: MilestoneProps) => {
  const [open, setOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const refetchQueries = [
    { query: reloadDealStatus, variables: { dealId } },
    { query: reloadAgreedOrAcceptedOffer, variables: { dealId } },
    { query: GET_DATES, variables: { buyerOfferId } },
  ];
  const [reach, { loading: reachLoading }] = useMutation(
    REACH_COMPLETED_MILESTONE,
    { refetchQueries },
  );
  const [unreach, { loading: unreachLoading }] = useMutation(
    unreachMilestoneMutation,
    {
      refetchQueries,
    },
  );
  const { createNotification } = useNotifications();
  const { currentUser } = useUser();

  const { id, reachedAt } = milestone || {};
  const checkboxDisabled =
    disabled || !buyerOfferId || reachLoading || unreachLoading;

  const onCheckboxToggle = async () => {
    if (!reachedAt) {
      setOpen(true);
      return;
    }

    if (reachedAt && currentUser.isNestAdmin && buyerOfferId) {
      // eslint-disable-next-line no-alert
      const unreachConfirmed = window.confirm(
        "WARNING: You are about to revert this to the Exchanged state, are you sure you want to do this?",
      );
      if (!unreachConfirmed) return;

      try {
        await unreach({
          variables: {
            buyerOfferId,
            milestoneId: id,
          },
          optimisticResponse: {
            unreachMilestone: {
              __typename: "MilestoneStatus",
              id,
              reachedAt: null,
            },
          },
        });
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const onSubmit = async (input) => {
    if (!buyerOfferId || reachedAt) {
      return;
    }

    try {
      const result = await reach({
        variables: {
          buyerOfferId,
          input,
        },
        optimisticResponse: {
          reachCompletedMilestone: {
            __typename: "MilestoneStatus",
            id,
            reachedAt: moment(),
          },
        },
      });
      if (result?.data?.reachCompletedMilestone?.reachedAt) {
        setOpen(false);
        setShowConfetti(true);
        createNotification("New milestone reached!", {
          subText: MILESTONE_LABELS[id],
        });
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <MobileFullScreenModal
        css={modalStyle}
        open={open}
        onClose={() => setOpen(false)}
        headerText="Update completion date"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            actualCompletionDate: moment().format("YYYY-MM-DD"),
          }}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} css="padding: 0 20px;">
              <p css={textStyle}>Please confirm the date of completion</p>
              <div css={sectionStyle}>
                <Field name="actualCompletionDate" validate={required}>
                  {({ input, meta }) => {
                    return (
                      <div css={fieldStyle}>
                        <DatePicker
                          dataTest="completed-date"
                          label="Completed Date"
                          onSubmit={(date) => input.onChange(date)}
                          valid={meta.valid}
                          value={input.value}
                        />
                      </div>
                    );
                  }}
                </Field>
              </div>
              <div css={buttonStyle}>
                <Button
                  data-test={"submit-button"}
                  disabled={hasValidationErrors || submitting}
                  buttonStyle="pink"
                  type="submit"
                  css="width: 100%;"
                >
                  {submitting ? "Saving..." : "Save"}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </MobileFullScreenModal>
      <div css={milestoneStyle}>
        <Checkbox
          checked={Boolean(milestone?.reachedAt)}
          css={checkboxStyle}
          disabled={checkboxDisabled}
          id={id}
          labelText={MILESTONE_LABELS[id]}
          onChange={onCheckboxToggle}
        />
        {reachedAt && <div css={dateStyle}>{formatShortDate(reachedAt)}</div>}
      </div>
      <Confetti showConfetti={showConfetti} />
    </>
  );
};
