// @flow

import styled, { css } from "styled-components";
import { Button } from "components/Buttons";
import { LeafCell, Grid } from "components/Grid/Grid";
import { NakedTextField } from "components/TextField";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { ListEntryCard as Original } from "components/Card";
import { Delete, Tick } from "@nest-ui/icons";
import { Field, Form } from "react-final-form";
import { List } from "./PropertyOverviewSection";

const ListEntryCard = styled(Original)`
  margin: 0;
`;

type Props = {
  report: getReports_buyersAgentReports,
  submitHandler: (any) => Promise<any>,
  details: {
    listToSubmit: (
      report: getReports_buyersAgentReports,
      input: {
        [string]: string,
      },
    ) => { [string]: ?$ReadOnlyArray<string> },
    list: (report: getReports_buyersAgentReports) => ?$ReadOnlyArray<string>,
    objectList: ($ReadOnlyArray<string>) => {
      [string]: ?$ReadOnlyArray<string>,
    },
    formElementName: string,
    inputLabel: string,
    inputDataTest: string,
    inputPlaceholder: string,
    addButtonDataTest: string,
    addButtonLabel: string,
    listItemDataTest: string,
    removeButtonDataTest: string,
    noElementsMessage: string,
  },
};

export const ListForm = ({ report, submitHandler, details }: Props) => {
  const submitListHandler = (input) => {
    submitHandler(details.listToSubmit(report, input));
  };

  const list = details.list(report);

  const removeListElementHandler = (index: number) => {
    const newList = list && [...list];

    if (newList) {
      newList.splice(index, 1);

      submitHandler(details.objectList(newList));
    }
  };

  return (
    <Form onSubmit={(input) => submitListHandler(input)}>
      {({ handleSubmit, hasValidationErrors, submitting, pristine, form }) => (
        <LeafCell width={4}>
          <ListEntryCard>
            <Grid columns={4}>
              <LeafCell width={3}>
                <Field name={details.formElementName}>
                  {({ input, meta }) => (
                    <NakedTextField
                      id={details.listItemDataTest}
                      label={details.inputLabel}
                      data-test={details.inputDataTest}
                      placeholder={details.inputPlaceholder}
                      {...input}
                      {...meta}
                    />
                  )}
                </Field>
              </LeafCell>
              <LeafCell width={1}>
                <Button
                  css={css`
                    margin: 0;
                  `}
                  data-test={details.addButtonDataTest}
                  label={details.addButtonLabel}
                  onClick={async () => {
                    await handleSubmit();
                    form.reset();
                  }}
                  icon={Tick}
                  disabled={pristine || hasValidationErrors || submitting}
                >
                  Add
                </Button>
              </LeafCell>
              <LeafCell width={4}>
                <List>
                  {list && list.length > 0 ? (
                    list.map((listElement, index) => (
                      <li
                        key={listElement.concat(index.toString())}
                        data-test={details.listItemDataTest}
                      >
                        <span>{listElement}</span>
                        <ButtonWithIcon
                          data-test={details.removeButtonDataTest}
                          icon={Delete}
                          onSubmit={() => {
                            removeListElementHandler(index);
                          }}
                        />
                      </li>
                    ))
                  ) : (
                    <span>{details.noElementsMessage}</span>
                  )}
                </List>
              </LeafCell>
            </Grid>
          </ListEntryCard>
        </LeafCell>
      )}
    </Form>
  );
};
