import T from "prop-types";

import { TextField } from "components/TextField";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { ListEntryCard } from "components/Card";
import { Grid, Cell, LeafCell } from "components/Grid";

export function ChainLink({
  chainLink,
  deletePropertyChainLink,
  updatePropertyChainLink,
}) {
  return (
    <ListEntryCard data-test="property-chain-link">
      <Grid columns={2}>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-address"
            label="Address of property to be sold"
            mutation={updatePropertyChainLink}
            property="address"
            value={chainLink.address}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-contact-name"
            label="Seller name"
            mutation={updatePropertyChainLink}
            property="contactName"
            value={chainLink.contactName}
          />
        </LeafCell>

        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-estate-agent"
            label="Estate agent"
            mutation={updatePropertyChainLink}
            property="estateAgent"
            value={chainLink.estateAgent}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-lead-agent-name"
            label="Lead agent name"
            mutation={updatePropertyChainLink}
            property="leadAgentName"
            value={chainLink.leadAgentName}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-agent-phone-number"
            label="Agent phone number"
            mutation={updatePropertyChainLink}
            property="agentPhoneNumber"
            value={chainLink.agentPhoneNumber}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-agent-email"
            label="Agent email"
            mutation={updatePropertyChainLink}
            property="agentEmail"
            value={chainLink.agentEmail}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-solicitor-firm"
            label="Solicitor firm"
            mutation={updatePropertyChainLink}
            property="solicitorName"
            value={chainLink.solicitorName}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-solicitor-name"
            label="Lead solicitor name"
            mutation={updatePropertyChainLink}
            property="leadSolicitorName"
            value={chainLink.leadSolicitorName}
          />
        </LeafCell>
        <LeafCell>
          <TextField
            className="fs-exclude"
            data-test="property-chain-link-solicitor-phone-number"
            label="Solicitor contact details"
            mutation={updatePropertyChainLink}
            property="solicitorPhoneNumber"
            value={chainLink.solicitorPhoneNumber}
          />
        </LeafCell>
        <LeafCell>
          <TextareaWithLinks
            className="fs-exclude"
            data-test="property-chain-link-notes"
            label="Notes"
            mutation={updatePropertyChainLink}
            property="notes"
            value={chainLink.notes}
          />
        </LeafCell>
        <Cell width={2} style={{ textAlign: "right" }}>
          <DeleteButton
            data-test="delete-property-chain-link"
            onSubmit={() => {
              if (itemDeletionConfirmed()) {
                deletePropertyChainLink();
              }
            }}
          />
        </Cell>
      </Grid>
    </ListEntryCard>
  );
}

ChainLink.propTypes = {
  chainLink: T.object.isRequired,
  deletePropertyChainLink: T.func.isRequired,
  updatePropertyChainLink: T.func.isRequired,
};
