import agencyValuationsQuery from "../queries/agencyValuations.graphql";

export const createConfig = {
  props: ({ mutate, ownProps }) => ({
    createAgencyValuation: () =>
      mutate({
        variables: {
          id: ownProps.parentId,
        },
        refetchQueries: [
          {
            query: agencyValuationsQuery,
            variables: {
              id: ownProps.parentId,
            },
          },
        ],
      }),
  }),
};
