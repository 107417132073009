import { compose, withHandlers } from "recompose";
import { withReadOnly, withOnSubmit } from "@nest-ui/hocs";

import { NoSubmitTextField } from "components/TextField";
import { isPresent } from "@nested/utils/isPresent";
import { find } from "ramda";

const findFirstMatchedGroup = find((x) => x !== undefined);

const percentage = String.fromCharCode("0x0025");
const percentageFormat = (value) => {
  if (typeof value === "undefined") {
    return `${percentage}`;
  }
  if (typeof value === "number") {
    return `${value}${percentage}`;
  }

  /* Feel I have to explain myself 😅
    We have to allow 4 decimal places whilst trimming any trailing zeros on the decimal part
    (To note, this function runs on every keystroke so we need to allow the decimal part to reach 4 places)
    There are 4 diferent possible outcomes under these rules:
    1 - 0.0000
    2 - 0.1000
    3 - 0.0100
    4 - 0.0010

    Regex will match against one of them while the other will be undefined, so we need to go through all the
    regex group matches to find the correct one and use it

    Example:
    "0.0100" regex test will result in: [".0100", undefined, undefined, ".01", undefined]
    We want to keep the ".01"
  */
  const groupedTrailingZeros =
    /(\.)[0]{4,}|(\.[^0])[0]{3,}|(\.\d{2})[0]{2,}|(\.\d{3,})[0]+/;
  const matches = value.match(groupedTrailingZeros);

  // No regex match means we don't have a decimal point
  if (matches === null) return `${value}${percentage}`;

  const [fullMatch, ...groups] = matches;
  const matchedGroup = findFirstMatchedGroup(groups);
  const decimalPart = matchedGroup === "." ? "" : matchedGroup;
  return `${value.replace(fullMatch, decimalPart)}${percentage}`;
};

const cast = (value) => value.replace(/[^0-9.]+/g, "");

const enhance = compose(
  withHandlers({
    cast: () => cast,
    format: () => (value) =>
      value === null ? percentage : percentageFormat(value),
  }),
  withReadOnly(({ value }) =>
    isPresent(value) ? percentageFormat(value) : null,
  ),
);

export const NoSubmitPercentageField = enhance(NoSubmitTextField);
export const PercentageField = withOnSubmit(NoSubmitPercentageField);
