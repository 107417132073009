import * as AbsintheSocket from "@absinthe/socket";
import { getMainDefinition } from "@apollo/client/utilities";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import {
  InMemoryCache,
  defaultDataIdFromObject,
  ApolloClient,
  createHttpLink,
  ApolloLink,
  from,
  split,
} from "@apollo/client";
import { createLink as createUploadLink } from "apollo-absinthe-upload-link";
import { setContext } from "@apollo/client/link/context";
import { generatePossibleTypes } from "@nested/utils/generatePossibleTypes";

import { getConfig } from "@nested/config";
import { advanceMay2018Id } from "@nest-ui/sellers-nest/tabs/Payments/PropertySaleRedemption/DealType/AdvanceMay2018";
import { CLIENT_ID } from "./AuthenticatedSocket";
import schema from "../../schema.private.json";

const { GRAPHQL_URI } = getConfig();

const possibleTypes = generatePossibleTypes(schema);

// It's useful to have access to this for debugging websockets
if (!window.NESTED_DEBUG) window.NESTED_DEBUG = {};
window.NESTED_DEBUG.websocketClientId = CLIENT_ID;

export const createCache = () =>
  new InMemoryCache({
    possibleTypes,
    dataIdFromObject: getDataIdByTypename,
    typePolicies: {
      Query: {
        fields: {
          subAgents: {
            merge: false,
          },
          awaitingFeedback: {
            merge: true,
          },
          enquiries: {
            merge: true,
          },
          negotiatingOffers: {
            merge: true,
          },
          timeline: {
            // Do not include the limit as a key arg. A difference in key arg means the results are cached separately,
            // resulting in discrepancies between the slim timeline view (limited) and the expanded timeline view (unlimited))
            keyArgs: ["relationId", "relationType"],
            merge: false,
          },
          allNurtureDeals: {
            merge: false,
          },
          noTaskSetNurtureDeals: {
            merge: false,
          },
        },
      },
      Buyer: {
        fields: {
          propertyTypes: {
            merge: false,
          },
        },
      },
      Comparable: {
        fields: {
          comparisonPoints: {
            merge: false,
          },
        },
      },
      BuyersAgentReport: {
        fields: {
          listingHistoryEvents: {
            merge: false,
          },
          offerEvents: {
            merge: false,
          },
        },
      },
      NestDeal: {
        fields: {
          potentialBuyers: {
            merge: false,
          },
        },
      },
      GuaranteePaymentHistory: {
        keyFields: [],
      },
      BuyerPropertySurveyType: {
        merge: true,
      },
      StringInputOption: {
        merge: true,
      },
      DealTypeOption: {
        merge: true,
      },
      AgentTaskCountResult: {
        merge: true,
      },
      Subscription: {
        fields: {
          timelineForDealOrBuyerChanged: {
            merge: false,
          },
        },
      },
    },
  });

// Copied from here https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
export const hasSubscription = (query) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};

export const createApolloClient = async (authClient, socket) => {
  // eslint-disable-next-line new-cap
  const httpLink = new createHttpLink({ uri: GRAPHQL_URI });

  const uploadLink = createUploadLink({
    uri: GRAPHQL_URI,
  });

  const absintheSocketLink = createAbsintheSocketLink(
    AbsintheSocket.create(socket),
  );

  const authMiddleware = setContext(async () => {
    const token = await authClient.getToken();
    return {
      headers: {
        "x-client-id": CLIENT_ID,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const absintheAfterware = new ApolloLink((operation, forward) =>
    // Apollo expects data and errors as properties of the response object
    forward(operation).map((response) => ({
      ...response,
      ...response.payload,
    })),
  );

  const httpLinkWithMods = from([
    absintheAfterware,
    authMiddleware,
    uploadLink,
    httpLink,
  ]);

  // While you can send ALL queries, mutations and subscriptions over websockets,
  // for now we will continue to use HTTP for queries and mutations (wich is recommended
  // by Apollo here https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended).
  const link = split(
    (operation) => hasSubscription(operation.query),
    absintheSocketLink,
    httpLinkWithMods,
  );

  return new ApolloClient({
    link,
    cache: createCache(),
  });
};

const dataIdTypenameMap = {
  // We need to have a unique id to identify some objects in the
  // normalized cache.
  //
  // See docs for more info:
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-identifier-generation-globally
  Vendor: (object) => `Vendor:${object.dealId}`,
  AdvanceMay2018: advanceMay2018Id,
  OpportunityStatus: (object) => `OpportunityStatus:${object.valueText}`,
  NurtureHotness: (object) => `NurtureHotness:${object.label}`,
};

export const getDataIdByTypename = (object) => {
  if (object.__typename in dataIdTypenameMap)
    return dataIdTypenameMap[object.__typename](object);

  return defaultDataIdFromObject(object);
};
