// @flow
import { useEffect, useState } from "react";
import { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { Button } from "../../../../components/Button/Button";

export const NEXT_BPI_ENQUIRIES = gql`
  query NextBPIEnquiries($email: String!, $currentBpiId: ID!) {
    nextBpi: enquiriesWorkflowNextBpi(
      email: $email
      currentBpiId: $currentBpiId
    ) {
      id
      buyer {
        id
      }
    }

    listView: enquiries(email: $email) {
      results {
        id
      }
    }
  }
`;

export const NEXT_BPI_FEEDBACK = gql`
  query NextBPIFeedback($email: String!, $currentBpiId: ID!) {
    nextBpi: feedbackWorkflowNextBpi(
      email: $email
      currentBpiId: $currentBpiId
    ) {
      id
      buyer {
        id
      }
    }

    listView: awaitingFeedback(email: $email) {
      results {
        id
      }
    }
  }
`;

export const NEXT_BPI_NEGOTIATIONS = gql`
  query NextBPINegotiations($email: String!, $currentBpiId: ID!) {
    nextBpi: negotiationsWorkflowNextBpi(
      email: $email
      currentBpiId: $currentBpiId
    ) {
      id
      buyer {
        id
      }
    }

    listView: negotiatingOffers(email: $email) {
      results {
        id
      }
    }
  }
`;

const separatorStyle = css`
  width: 0px;
  border-right: 1px solid ${({ theme }) => theme.palette.hague20};
  margin-right: 10px;
  padding-right: 10px;
`;

const workflowButtonStyle = css`
  height: 32px;
  width: auto;
  width: 90px;
  ${({ $hasSnooze }) =>
    $hasSnooze &&
    css`
      width: 60px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0px;
    `}
`;

const nextQuery = (workflow) => {
  if (workflow === "feedback") {
    return NEXT_BPI_FEEDBACK;
  }

  if (workflow === "negotiations") {
    return NEXT_BPI_NEGOTIATIONS;
  }

  return NEXT_BPI_ENQUIRIES;
};

const getButtonLabel = (selectedBpi, data) => {
  if (!data || !selectedBpi) {
    return "Skip";
  }

  const {
    nextBpi,
    listView: { results },
  } = data;

  if (!nextBpi) {
    return "Done";
  }

  if (!results.find((bpi) => bpi.id === selectedBpi.id)) {
    return "Next";
  }

  return "Skip";
};

type Props = {
  selectedBpi: ?BuyerSummary_buyer_buyerPropertyInterests,
  selectedUserEmail: string,
  workflow: string,
  recommended?: boolean,
};

export const NextButton = ({
  recommended,
  selectedBpi,
  selectedUserEmail,
  workflow,
}: Props) => {
  const history = useHistory();
  const nextBpiQuery = nextQuery(workflow);
  const {
    previousData,
    data = previousData,
    refetch,
  } = useQuery(nextBpiQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      email: selectedUserEmail,
      currentBpiId: selectedBpi?.id,
    },
    skip: !selectedBpi,
  });

  useEffect(() => {
    // If anything on the BPI changes, we need to check if the workflow changed too.
    refetch();
  }, [selectedBpi]);

  const apollo = useApolloClient();
  const [gettingNext, setGettingNext] = useState(false);

  const next = async () => {
    if (!selectedBpi) {
      // Button should be disabled so we shouldn't hit this branch
      return;
    }

    try {
      setGettingNext(true);
      const result = await apollo.query({
        query: nextBpiQuery,
        fetchPolicy: "network-only",
        variables: {
          email: selectedUserEmail,
          currentBpiId: selectedBpi.id,
        },
      });

      if (result?.data?.nextBpi) {
        const {
          data: {
            nextBpi: {
              id: nextBpiId,
              buyer: { id: nextBuyerId },
            },
          },
        } = result;

        history.push(
          `/buyers/${nextBuyerId}/interests/${nextBpiId}/workflows/${String(
            workflow,
          )}`,
        );
      } else {
        // Relies on list view name matching workflow name
        history.push(`/buyers/${String(workflow)}`);
      }
    } finally {
      setGettingNext(false);
    }
  };

  const label = getButtonLabel(selectedBpi, data);
  const snoozed = selectedBpi?.snooze;

  return (
    <>
      <div css={separatorStyle} />
      <Button
        onClick={next}
        buttonStyle={recommended ? "pink" : "white"}
        css={workflowButtonStyle}
        data-test="next-button"
        $hasSnooze={!snoozed}
        disabled={gettingNext || !selectedBpi}
      >
        {label}
      </Button>
    </>
  );
};
