// @flow
import { Route, Switch } from "react-router-dom";
import { Tabs, Tab } from "../../../components/Navigation/Tabs";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../../components/Navigation/Breadcrumbs";
import { TodaysEnquiriesListView } from "./TodaysEnquiriesListView";
import { UpcomingEnquiriesListView } from "./UpcomingEnquiriesListView";
import { useListViewCounts } from "../../../hooks/useListViewCounts";
import { ListViewPageWrapper } from "../../../components/ListViewSharedStyles";

export const Enquiries = () => {
  const { enquiries, upcomingEnquiries } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Buyers</Breadcrumb>
        <Breadcrumb to="#">Enquiries</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/buyers/enquiries" exact count={enquiries}>
          Today
        </Tab>
        <Tab to="/buyers/enquiries/upcoming" count={upcomingEnquiries}>
          Upcoming
        </Tab>
      </Tabs>
      <Switch>
        <Route exact path="/buyers/enquiries">
          <TodaysEnquiriesListView />
        </Route>
        <Route path="/buyers/enquiries/upcoming">
          <UpcomingEnquiriesListView />
        </Route>
      </Switch>
    </ListViewPageWrapper>
  );
};
