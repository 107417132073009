// @flow
import { useState } from "react";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { PropertySummary } from "../PropertySummary";
import { PlaceholderList } from "../../../components/Placeholder";
import { OpportunityStatusSelect } from "../OpportunityStatusSelect";
import { DealStatusToggle } from "../DealStatus/DealStatusToggle";
import { DealStatusForms } from "../DealStatus/DealStatusForms";
import { useDealStatus } from "../DealStatus/useDealStatus";
import { NurtureHotnessSelect } from "../NurtureHotnessSelect";

const propertySummaryStyle = css`
  padding: 16px 20px 20px;
  ${media.tablet`
    display: none;
  `}
`;

const dealStatusToggle = css`
  ${media.tablet`
    margin: 0 20px 0 0; 
`}
`;

const dealStatus = css`
  padding: 0px 20px;
  ${media.tablet`
    height: 40px;
    padding: 0 0 0 150px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `}
`;

const selectOption = css`
  padding: 20px 0;
  display: ${({ $hide }) => ($hide ? "none" : "block")};
  ${media.tablet`
    padding: 0;
  `}
`;

export const EDIT_DEAL_QUERY = gql`
  query EditingDealDetails($id: ID!) {
    nestDeal(id: $id) {
      id
      active
    }
  }
`;

export const DealStatusTab = ({
  onClose,
  id,
}: {
  onClose: () => void,
  id: string,
}) => {
  // We query for active here so when we render the toggle it has the
  // correct initial value and doesn't 'slide' before their eyes
  const { data, loading } = useQuery(EDIT_DEAL_QUERY, {
    variables: { id },
  });

  if (loading) {
    return <PlaceholderList css="padding: 0 20px;" />;
  }

  return (
    <DealStatusTabContent
      onClose={onClose}
      id={id}
      active={data?.nestDeal?.active}
    />
  );
};

const DealStatusTabContent = ({
  onClose,
  id,
  active,
}: {
  onClose: () => void,
  id: string,
  active: boolean,
}) => {
  const [hide, setHide] = useState(false);
  const {
    closeForm,
    closeFormAndResetToggle,
    formType,
    isToggleYes,
    setIsToggleYes,
    switchToggleAndOpenFormOrReactivateDeal,
    titleOnHover,
  } = useDealStatus({ active, id });

  // Ensures you can't use the toggle until the useDealStatus hook is
  // fully resolved.
  if (!switchToggleAndOpenFormOrReactivateDeal) {
    return <PlaceholderList css="padding: 0 20px;" />;
  }

  return (
    <>
      <div css={propertySummaryStyle}>
        <PropertySummary idOrExternalId={id} hideActivity />
      </div>
      <div css={dealStatus}>
        <div css={dealStatusToggle}>
          <DealStatusToggle
            isToggleYes={isToggleYes}
            largeLabel
            onChange={() => {
              if (!hide) {
                active && setHide(true);
                switchToggleAndOpenFormOrReactivateDeal();
              } else {
                setHide(false);
                closeFormAndResetToggle();
              }
            }}
            titleOnHover={titleOnHover}
          />
        </div>
        {active ? (
          <div css={selectOption} $hide={hide}>
            <OpportunityStatusSelect idOrExternalId={id} />
          </div>
        ) : (
          <div css={selectOption} $hide={hide}>
            <NurtureHotnessSelect id={id} />
          </div>
        )}
      </div>
      <div>
        <DealStatusForms
          id={id}
          formType={formType}
          closeForm={closeForm}
          closeOverlay={onClose}
          setIsToggleYes={setIsToggleYes}
        />
      </div>
    </>
  );
};
