import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { IndividualUserSettings } from "./IndividualUserSettings";
import { RMAreaAssignment } from "./RMAreaAssignment";
import { useUser } from "../../hooks/useUser";

const Wrapper = styled.div`
  padding: 0px 40px 100px;
  max-width: 640px;
  width: 100%;
`;

export const UserManagement = () => {
  const { currentUser } = useUser();

  if (!currentUser) return null;

  if (currentUser.isNestAdmin) {
    return (
      <Wrapper>
        <RMAreaAssignment />
        <IndividualUserSettings />
      </Wrapper>
    );
  }

  return <Redirect to="/leads" />;
};
