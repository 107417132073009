// @flow
import { css } from "styled-components";
import { useState } from "react";
import { NextButton } from "./NextButton";
import { EmailSmsButton } from "./EmailSmsButton";
import { SnoozeButton } from "./SnoozeButton";
import { WakeButton } from "./WakeButton";
import { useUser } from "../../../../hooks/useUser";
import { type SummaryBarState } from "../getSummaryBarState";
import { getRecommendedAction } from "./getRecommendedAction";
import { MessageBuyerModal } from "../../MessageBuyerModal/MessageBuyerModal";
import {
  HandoffCallButton,
  HandoffSMSButton,
  MailtoEmailButton,
} from "../../../../components/Comms";

const buttonStyles = css`
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 5px;
  cursor: pointer;
  height: 32px;
  padding: 7px 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.hague150};
  margin-left: 10px;
  * {
    font-weight: 500;
  }
`;

const actionButtons = css`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
`;

type Props = {
  buyer: BuyerSummary_buyer,
  selectedBpi: BuyerSummary_buyer_buyerPropertyInterests,
  summaryBarState: SummaryBarState,
  workflow: ?string,
};

export const WorkflowButtons = ({
  buyer,
  selectedBpi,
  summaryBarState,
  workflow,
}: Props) => {
  const {
    selectedUser: { email: selectedUserEmail, firstName: selectedUserName },
  } = useUser();
  const [modalOpen, setModalOpen] = useState(false);

  const recommendedAction = getRecommendedAction(selectedBpi, summaryBarState);
  const contacts = buyer?.contacts || [];
  return (
    <>
      <div css={actionButtons} $workflow={workflow}>
        {selectedBpi?.snooze ? (
          <WakeButton bpiId={selectedBpi.id} />
        ) : (
          <>
            <HandoffCallButton
              contacts={contacts}
              recommended={recommendedAction === "call"}
              relationId={buyer.id}
              relationType="buyer"
              withLabel
            />
            {workflow ? (
              <EmailSmsButton
                recommended={recommendedAction === "email_sms"}
                disabled={modalOpen}
                openMessageModal={() => setModalOpen(true)}
              />
            ) : (
              <>
                <HandoffSMSButton
                  css={buttonStyles}
                  contacts={contacts}
                  relationId={buyer.id}
                  relationType="buyer"
                  withLabel
                />
                <MailtoEmailButton
                  heading="Email buyer"
                  contacts={contacts}
                  relationId={buyer.id}
                >
                  <div css={buttonStyles}>Email</div>
                </MailtoEmailButton>
              </>
            )}
          </>
        )}
        {workflow && (
          <>
            <NextButton
              selectedUserEmail={selectedUserEmail}
              selectedBpi={selectedBpi}
              workflow={workflow}
              recommended={recommendedAction === "next"}
            />
            {selectedBpi && !selectedBpi.snooze && (
              <SnoozeButton
                bpiId={selectedBpi.id}
                openMessageModal={() => setModalOpen(true)}
              />
            )}
          </>
        )}
      </div>
      <MessageBuyerModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        buyer={buyer}
        selectedUserEmail={selectedUserEmail}
        selectedUserName={selectedUserName}
        workflow={workflow}
      />
    </>
  );
};
