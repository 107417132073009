// @flow
import {
  Mutation,
  type MutationFunction,
  Subscription,
} from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { Loader } from "components/Loader";

import { Grid, LeafCell } from "components/Grid";
import { AllowNegativeCurrencyField } from "components/AllowNegativeCurrencyField";
import { Checkbox } from "components/Checkbox";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { TextField } from "components/TextField";

import { reconciledMutation } from "./reconciledMutation";

const PROPERTY_SALE_REDEMPTION_FRAGMENT = gql`
  fragment propertySaleRedemptionAdvanceMay2018Fields on PropertySaleRedemption {
    dealId
    actualLossRealised
    adjustmentNotes
    amountOutstandingOwedToNested
    paymentAdjustment
    paymentDateReceived
    projectedLoss
    projectedSalePrice
    reconciledAndSignedOff
    totalAmountReceived

    ... on AdvanceMay2018 {
      advanceAmountReconciliation
      advanceFeeOnCompletion
      advancePaymentReceived
      agencyFeeOnCompletion
      projectedAdvanceRedemptionAmount
    }
  }
`;

const PROPERTY_SALE_REDEMPTION_QUERY = gql`
  query PropertySaleRedemptionAdvanceMay2018($dealId: ID!) {
    propertySaleRedemption(dealId: $dealId) {
      ...propertySaleRedemptionAdvanceMay2018Fields
    }
  }
  ${PROPERTY_SALE_REDEMPTION_FRAGMENT}
`;

const UPDATE_PROPERTY_SALE_REDEMPTION_MUTATION = gql`
  mutation UpdatePropertySaleRedemption(
    $input: PropertySaleRedemptionInput!
    $dealId: ID!
  ) {
    updatePropertySaleRedemption(input: $input, dealId: $dealId) {
      ...propertySaleRedemptionAdvanceMay2018Fields
    }
  }
  ${PROPERTY_SALE_REDEMPTION_FRAGMENT}
`;

const PROPERTY_SALE_REDEMPTION_SUBSCRIPTION = gql`
  subscription PropertySaleRedemptionUpdated($dealId: ID!) {
    propertySaleRedemptionUpdated(dealId: $dealId) {
      ...propertySaleRedemptionAdvanceMay2018Fields
    }
  }
  ${PROPERTY_SALE_REDEMPTION_FRAGMENT}
`;

const ReadOnlyFields = ({ propertySaleRedemption }) => (
  <>
    <LeafCell area="projectedSalePrice">
      <CurrencyField
        label="Projected sale price"
        readOnly
        value={propertySaleRedemption.projectedSalePrice}
      />
    </LeafCell>
    <LeafCell area="projectedAdvanceRedemptionAmount">
      <CurrencyField
        label="Projected advance repayment amount"
        readOnly
        value={propertySaleRedemption.projectedAdvanceRedemptionAmount}
      />
    </LeafCell>
    <LeafCell area="projectedLoss">
      <CurrencyField
        label="Projected loss"
        readOnly
        value={propertySaleRedemption.projectedLoss}
      />
    </LeafCell>
    <LeafCell area="amountOutstandingOwedToNested">
      <CurrencyField
        label="Amount outstanding owed to Nested"
        readOnly
        value={propertySaleRedemption.amountOutstandingOwedToNested}
      />
    </LeafCell>
    <LeafCell area="advanceAmountReconciliation">
      <CurrencyField
        label="Advance amount reconciliation"
        readOnly
        value={propertySaleRedemption.advanceAmountReconciliation}
      />
    </LeafCell>
  </>
);

const EditableFields = ({ propertySaleRedemption }) => (
  <Mutation mutation={UPDATE_PROPERTY_SALE_REDEMPTION_MUTATION}>
    {(mutate: MutationFunction<*, *>) => {
      const updatePropertySaleRedemption = async (input) => {
        await mutate({
          variables: {
            dealId: propertySaleRedemption.dealId,
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updatePropertySaleRedemption: {
              __typename: "AdvanceMay2018",
              ...propertySaleRedemption,
              ...input,
            },
          },
        });
      };

      return (
        <>
          <LeafCell area="paymentDateReceived">
            <DatePicker
              label="Date received"
              mutation={updatePropertySaleRedemption}
              property="paymentDateReceived"
              value={propertySaleRedemption.paymentDateReceived}
            />
          </LeafCell>
          <LeafCell area="totalAmountReceived">
            <AllowNegativeCurrencyField
              label="Total amount received (+ / -)"
              mutation={updatePropertySaleRedemption}
              property="totalAmountReceived"
              value={propertySaleRedemption.totalAmountReceived}
            />
          </LeafCell>
          <LeafCell area="advanceFeeOnCompletion">
            <CurrencyField
              label="Advance fee on completion"
              mutation={updatePropertySaleRedemption}
              property="advanceFeeOnCompletion"
              value={propertySaleRedemption.advanceFeeOnCompletion}
            />
          </LeafCell>
          <LeafCell area="agencyFeeOnCompletion">
            <CurrencyField
              label="Agency fee on completion"
              mutation={updatePropertySaleRedemption}
              property="agencyFeeOnCompletion"
              value={propertySaleRedemption.agencyFeeOnCompletion}
            />
          </LeafCell>
          <LeafCell area="advancePaymentReceived">
            <CurrencyField
              label="Advance payment received"
              mutation={updatePropertySaleRedemption}
              property="advancePaymentReceived"
              value={propertySaleRedemption.advancePaymentReceived}
            />
          </LeafCell>
          <LeafCell area="paymentAdjustment">
            <AllowNegativeCurrencyField
              label="Payment adjustment (-/+ £)"
              mutation={updatePropertySaleRedemption}
              property="paymentAdjustment"
              value={propertySaleRedemption.paymentAdjustment}
            />
          </LeafCell>
          <LeafCell area="adjustmentNotes">
            <TextField
              label="Payment adjustment notes"
              mutation={updatePropertySaleRedemption}
              property="adjustmentNotes"
              value={propertySaleRedemption.adjustmentNotes}
            />
          </LeafCell>
          <LeafCell area="actualLossRealised">
            <CurrencyField
              label="Actual loss realised"
              mutation={updatePropertySaleRedemption}
              property="actualLossRealised"
              value={propertySaleRedemption.actualLossRealised}
            />
          </LeafCell>
        </>
      );
    }}
  </Mutation>
);

const ReconciledAndSignedOff = ({ propertySaleRedemption }) => (
  <Mutation mutation={reconciledMutation}>
    {(mutate: MutationFunction<*, *>) => {
      const reconcile = async () => {
        await mutate({
          variables: {
            input: {
              propertySaleReconciledAndSignedOff:
                !propertySaleRedemption.reconciledAndSignedOff,
            },
            id: propertySaleRedemption.dealId,
          },
          // Due to Legacy bullshit, the reconciledAndSignedOff mutation
          // returns a nestDeal type. It's really no concern of the nestDeal
          // whether the propertySaleRedemption is signed off, so we're
          // gradually moving it off. We can't reconcile that nestDeal with the
          // propertySaleRedemption which is queried in this component; so we
          // just refetch the main query to reflect any changes to data.
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: PROPERTY_SALE_REDEMPTION_QUERY,
              variables: { dealId: propertySaleRedemption.dealId },
            },
          ],
        });
      };

      return (
        <LeafCell area="reconciledAndSignedOff">
          <Checkbox
            label="Reconciled and signed off?"
            property="reconciledAndSignedOff"
            value={propertySaleRedemption.reconciledAndSignedOff}
            mutation={reconcile}
          />
        </LeafCell>
      );
    }}
  </Mutation>
);

const layout = [
  "projectedSalePrice            projectedAdvanceRedemptionAmount projectedLoss          ..................",
  "paymentDateReceived           totalAmountReceived              advancePaymentReceived ..................",
  "advanceFeeOnCompletion        agencyFeeOnCompletion            ...................... ..................",
  "paymentAdjustment             adjustmentNotes                  adjustmentNotes        actualLossRealised",
  "amountOutstandingOwedToNested advanceAmountReconciliation      ...................... ..................",
  "reconciledAndSignedOff        ................................ ...................... ..................",
];

// The AdvanceMay2018 PSR object has no id of its own, so we provide this
// function (which is used in the `apollo-client.js` configuration) to get it
// to use the `dealId` as its cache key.
//
// We export this here so that the coupling between that configuration and this
// component is clear.
export const advanceMay2018Id = (object: { dealId: string }) =>
  `AdvanceMay2018:${object.dealId}`;

export const AdvanceMay2018 = ({ dealId }: { dealId: string }) => (
  <Grid columns={4} rows="auto auto" areas={layout}>
    <Subscription
      subscription={PROPERTY_SALE_REDEMPTION_SUBSCRIPTION}
      variables={{ dealId }}
    >
      {() => null}
    </Subscription>

    <ExtendedQuery
      query={PROPERTY_SALE_REDEMPTION_QUERY}
      variables={{ dealId }}
    >
      {({
        propertySaleRedemption,
      }: ExtendedQueryRenderProps<PropertySaleRedemptionAdvanceMay2018>) => {
        if (!propertySaleRedemption) return <Loader />;

        if (propertySaleRedemption.__typename === "AdvanceMay2018") {
          return (
            <>
              <ReadOnlyFields propertySaleRedemption={propertySaleRedemption} />
              <EditableFields propertySaleRedemption={propertySaleRedemption} />
              <ReconciledAndSignedOff
                propertySaleRedemption={propertySaleRedemption}
              />
            </>
          );
        }

        return "Deal has unexpected type! Please contact #tech-support on slack as this message should not be here.";
      }}
    </ExtendedQuery>
  </Grid>
);
