// @flow
import styled from "styled-components";
import { type Match } from "react-router";
import { Route, withRouter } from "react-router-dom";

import { Warning } from "components/Warning";
import { Modal } from "components/Modal";
import { Switch } from "components/Switch";
import { Card } from "components/Card";
import { type MutationFunction } from "@nested/utils/graphql/ExtendedMutation";

export const ContactName = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin: 0;
`;

export const ContactTitle = styled.p`
  margin: 4px 0 0;
`;

const ContactDetails = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;

  li {
    margin-top: 4px;
  }
`;

const ContactCard = styled(Card)`
  display: grid;
  grid-template-columns: auto 96px;
  align-items: center;

  label {
    justify-self: end;
  }
`;

type Props = {
  closeModal: () => void,
  contacts: $ReadOnlyArray<ContactsQuery_nestDeal_contacts>,
  updateContact: MutationFunction<UpdateContact, UpdateContactVariables>,
  match: Match,
};

const toList = (list) => list.map((item) => <li key={item}>{item}</li>);

const ContactPreferences = ({ closeModal, contacts, updateContact }: Props) => (
  <Modal
    data-test="contact-preferences-modal"
    title="Viewing Contact Preferences"
    closeModal={closeModal}
    isOpen
  >
    <Warning>
      This will change the contact preferences for all future automated viewing
      related emails/SMSs for this seller.
    </Warning>

    {contacts.map((contact) => {
      const { id, name, title, telephoneNumbers, emailAddresses } = contact;

      return (
        <ContactCard key={id} data-test={`modal-contact:${id}`}>
          <div>
            <ContactName className="fs-exclude">{name}</ContactName>
            {title && <ContactTitle>{title}</ContactTitle>}
            <ContactDetails className="fs-exclude">
              {toList(emailAddresses)}
              {toList(telephoneNumbers)}
            </ContactDetails>
          </div>
          <Switch
            data-test={`modal-contact-switch:${id}`}
            value={contact.viewingCommsEnabled}
            onSubmit={(viewingCommsEnabled: boolean) =>
              updateContact({
                variables: { id, input: { viewingCommsEnabled } },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateContact: {
                    __typename: "Contact",
                    ...contact,
                    viewingCommsEnabled,
                  },
                },
              })
            }
          />
        </ContactCard>
      );
    })}
  </Modal>
);

export const ViewingContactPreferencesModal = withRouter((props: Props) => (
  <Route
    path={`${props.match.path}/edit-contact-preferences`}
    render={() => <ContactPreferences {...props} />}
  />
));
