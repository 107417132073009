// @flow
import { gql } from "@apollo/client";
import React, { type ChildrenArray, type Element } from "react";
import { SecondaryButton } from "components/Buttons";
import { Grid, LeafCell } from "components/Grid";
import { AlignRight } from "components/AlignRight";
import { H2 } from "components/Heading";
import { Add } from "@nest-ui/icons";
import {
  LocalStorageEngine,
  LocalCache,
} from "components/LocalCache/LocalCache";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { filterValuationsByType } from "@nested/utils/graphql/valuations/valuationHelpers";
import { NoAdvances } from "./NoAdvances";
import { CreateAdvanceInPrinciple } from "./CreateAdvanceInPrinciple";
import { AdvancesSectionList } from "./AdvancesSectionList";
import { valuationToSelectOption } from "./utils/utils";

const ADVANCES_SECTION_QUERY = gql`
  query AdvancesSectionQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      dealTypeDetails {
        ... on DealTypeAdvancesForAllMarch2019 {
          id
          agencyFeePercent
          facilityFeePercent
          agencyFeeAboveAdvancePercent
          referralDiscount
        }
      }
      id
      advanceInPrinciples {
        advanceOffer {
          id
          advanceFeeCapPercent
          advanceFeeDailyPercent
          amount
          contractVersion {
            label
            type: id
          }
          contractVersionId
          customerContractSignedDate
          dateSentToCustomer
          expiryDate
          nestedContractSignedDate
          advanceDrawdownRequests {
            advanceFeeCapAmount
            agencyFeeAmount
            requestedDrawdownDate
            facilityFeeAmount
            feeAdjustmentAmount
            feeAdjustmentNotes
            id
            maximumNetAmount
            netAmount
            requestMadeDate
            totalFees
            datePaymentMade
            drawdownProjectedDate
            drawdownProjectedDateConfirmed
          }
        }
        id
        doesCustomerIntendToConvert
        dateCommunicatedToSeller
        advanceOfferValuationOct18Id
        expiryDate
        valuation {
          id
          expiryDate
          reasonForAdvanceOfferValuation {
            id
            label
          }
        }
      }
      valuationList {
        id
        ... on AdvanceOfferValuationOct18 {
          advanceAmount
          dateCompleted
          expectedSalePrice
          expiryDate
          reasonForAdvanceOfferValuation {
            id
            label
          }
        }
      }
    }

    contractVersions(type: ADVANCES_FOR_ALL_MARCH2019_ADVANCE_OFFER) {
      value: id
      label
    }
  }
`;

const CREATE_ADVANCE_IN_PRINCIPLE = gql`
  mutation CreateAdvanceInPrinciple($input: CreateAdvanceInPrincipleInput!) {
    createAdvanceInPrinciple(input: $input) {
      id
    }
  }
`;

type State = { showCreateEntry: boolean };
type Props = {
  dealId: string,
  children: ChildrenArray<Element<typeof AdvancesSectionList>>,
};

export class Advances extends React.Component<Props, State> {
  state = {
    showCreateEntry: false,
  };

  enableCreating = () => {
    this.setState({
      showCreateEntry: true,
    });
  };

  disableCreating = () => {
    this.setState({
      showCreateEntry: false,
    });
  };

  render() {
    const { dealId, children } = this.props;
    const { showCreateEntry } = this.state;

    return (
      <ExtendedQuery query={ADVANCES_SECTION_QUERY} variables={{ dealId }}>
        {({
          contractVersions,
          nestDeal,
        }: ExtendedQueryRenderProps<AdvancesSectionQuery>) => {
          const relevantValuations = filterValuationsByType(
            nestDeal.valuationList,
            ["AdvanceOfferValuationOct18"],
          );
          const { advanceInPrinciples, dealTypeDetails } = nestDeal;
          const advanceValuationDropdownOptions = relevantValuations.map(
            valuationToSelectOption,
          );

          const showList = advanceInPrinciples.length > 0;
          const noAdvances = !showList;

          return (
            <LocalCache
              storageEngine={LocalStorageEngine}
              cacheKey={`createAdvanceInPrinciple:${dealId}`}
              render={({ cachedState, hasState, updateCache, clearCache }) => {
                const isEditing = this.state.showCreateEntry || hasState;

                return (
                  <ExtendedMutation
                    awaitRefetchQueries
                    mutation={CREATE_ADVANCE_IN_PRINCIPLE}
                    refetchQueries={[
                      {
                        query: ADVANCES_SECTION_QUERY,
                        variables: {
                          dealId: nestDeal.id,
                        },
                      },
                    ]}
                  >
                    {(mutate) => {
                      const createAdvanceInPrinciple = async () => {
                        const result = await mutate({
                          variables: { input: cachedState },
                        });

                        if (result) {
                          this.disableCreating();
                          clearCache();
                        }
                      };

                      const deleteAdvance = () => {
                        this.disableCreating();
                        clearCache();
                      };

                      return (
                        <Grid columns={4}>
                          <LeafCell width={2}>
                            <H2>Advance(s)</H2>
                          </LeafCell>
                          {showList && (
                            <LeafCell width={2}>
                              <AlignRight>
                                <SecondaryButton
                                  data-test="advances:create-advance-proposal"
                                  onClick={this.enableCreating}
                                  disabled={showCreateEntry}
                                >
                                  <Add />
                                  Add new advance in principle
                                </SecondaryButton>
                              </AlignRight>
                            </LeafCell>
                          )}

                          {isEditing && (
                            <LeafCell width={4}>
                              <CreateAdvanceInPrinciple
                                advanceValuationDropdownOptions={
                                  advanceValuationDropdownOptions
                                }
                                updateState={updateCache}
                                advanceInPrinciple={cachedState}
                                dealId={dealId}
                                valuations={relevantValuations}
                                onSubmit={createAdvanceInPrinciple}
                                onCancel={deleteAdvance}
                              />
                            </LeafCell>
                          )}

                          {showList && (
                            <LeafCell width={4}>
                              {React.Children.map(children, (child) => {
                                switch (child.type.displayName) {
                                  case "AdvancesSectionList":
                                    return React.cloneElement(child, {
                                      advancesSectionQuery:
                                        ADVANCES_SECTION_QUERY,
                                      advanceInPrinciples,
                                      advanceValuationDropdownOptions,
                                      contractVersions,
                                      dealId,
                                      dealTypeDetails,
                                    });
                                  default:
                                    return null;
                                }
                              })}
                            </LeafCell>
                          )}

                          {noAdvances && !isEditing && (
                            <LeafCell width={4}>
                              <NoAdvances
                                enableCreating={this.enableCreating}
                              />
                            </LeafCell>
                          )}
                        </Grid>
                      );
                    }}
                  </ExtendedMutation>
                );
              }}
            />
          );
        }}
      </ExtendedQuery>
    );
  }
}
