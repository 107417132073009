// @flow
import type { Element } from "react";
import styled from "styled-components";

const StyledA = styled.a`
  outline: none;
  cursor: pointer;
`;

type Props = {
  children?: string | Element<string>,
  text?: string,
  href: ?string,
  openInNewTab?: boolean,
  style?: { [key: string]: string },
  dataTest?: string,
  css?: string,
};

export const A = ({
  children,
  text,
  href,
  openInNewTab = true,
  style,
  dataTest,
}: Props) => (
  <StyledA
    style={style}
    href={href}
    target={openInNewTab ? "_blank" : "_self"}
    rel={openInNewTab ? "noopener" : null}
    data-test={dataTest}
  >
    {children || text || href}
  </StyledA>
);
