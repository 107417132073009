// @flow
import type { MutationOperation } from "@apollo/client";
import React from "react";
import { compose } from "recompose";

import { NoSubmitDatePicker } from "components/DatePicker";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { Grid, LeafCell } from "components/Grid";
import { NewEntry } from "components/NewEntry";
import { NoSubmitSelectField } from "components/SelectField";
import { graphql } from "deal/utils/graphql";

import { ExpectedDrawdownTimingReadOnly } from "./ExpectedDrawdownTimingReadOnly";
import { ExpectedDrawdownTimingsList } from "./ExpectedDrawdownTimingsList";
import query from "./queries/expectedDrawdownTimings.graphql";
import { expectedDrawdownTimingsQueryConfig } from "./queries/config";
import createExpectedDrawdownTimingMutation from "./mutations/createExpectedDrawdownTiming.graphql";
import { createExpectedDrawdownTimingConfig } from "./mutations/createExpectedDrawdownTimingConfig";

type Props = {
  data: ExpectedDrawdownTimingsQuery,
  createExpectedDrawdownTiming: ({
    agreementDate: string,
    drawdownDay: number,
  }) => MutationOperation<CreateExpectedDrawdownTimingMutation_createExpectedDrawdownTiming>,
  deleteExpectedDrawdownTiming: (
    id: string,
  ) => () => MutationOperation<DeleteExpectedDrawdownTimingMutation_deleteExpectedDrawdownTiming>,
  parentId: string,
  updateExpectedDrawdownTiming: (
    id: string,
  ) => (
    input: UpdateExpectedDrawdownTimingInput,
  ) => MutationOperation<UpdateExpectedDrawdownTimingMutation_updateExpectedDrawdownTiming>,
};
type State = {
  editing: boolean,
};

export class ExpectedDrawdownTimingComponent extends React.Component<
  Props,
  State,
> {
  constructor(props: Props) {
    super(props);

    const { data } = props;

    const editing =
      data.nestDeal &&
      data.nestDeal.dealTypeDetails &&
      data.nestDeal.dealTypeDetails.__typename === "DealTypeAgencyPlusSep2018"
        ? !data.nestDeal.dealTypeDetails.latestExpectedDrawdownTiming
        : false;

    this.state = {
      editing,
    };
  }

  toggleEditing = () => {
    const { editing } = this.state;

    this.setState({ editing: !editing });
  };

  render() {
    const { createExpectedDrawdownTiming, data, parentId } = this.props;
    const { editing } = this.state;

    if (
      !data.nestDeal ||
      !data.nestDeal.dealTypeDetails ||
      data.nestDeal.dealTypeDetails.__typename !== "DealTypeAgencyPlusSep2018"
    ) {
      return "Sorry, expected drawdown timing does not apply on this deal type.";
    }

    const { drawdownTimingFees } = data.nestDeal.dealTypeDetails;

    const { latestExpectedDrawdownTiming } = data.nestDeal.dealTypeDetails;
    const expectedDrawdownTimings =
      data.nestDeal.dealTypeDetails.expectedDrawdownTimings || [];

    const possibleExpectedDrawdownDays = drawdownTimingFees
      ? drawdownTimingFees.map(({ days }) => ({
          label: String(days),
          value: days,
        }))
      : [];

    return (
      <div>
        {!editing && (
          <>
            <EditButton
              data-test="expected-drawdown-timing-edit-button"
              onClick={this.toggleEditing}
            >
              Edit
            </EditButton>

            <ExpectedDrawdownTimingReadOnly {...latestExpectedDrawdownTiming} />
          </>
        )}

        {editing && (
          <>
            {expectedDrawdownTimings.length > 0 && (
              <EditButton
                data-test="expected-drawdown-timing-finish-button"
                onClick={this.toggleEditing}
              >
                Finished
              </EditButton>
            )}

            <ExpectedDrawdownTimingsList
              expectedDrawdownTimings={expectedDrawdownTimings}
              parentId={parentId}
              possibleExpectedDrawdownDays={possibleExpectedDrawdownDays}
            />

            <NewEntry
              createMutation={createExpectedDrawdownTiming}
              itemName="expected-drawdown-timing"
              requiredFields={["agreementDate", "drawdownDay"]}
            >
              {({ update, values }) => (
                <Grid columns={4}>
                  <LeafCell>
                    <NoSubmitSelectField
                      data-test="expected-drawdown-timing-drawdown-day-new-entry"
                      label="Expected drawdown on or after day..."
                      onSubmit={update("drawdownDay")}
                      options={possibleExpectedDrawdownDays}
                      searchable
                      value={values.drawdownDay}
                    />
                  </LeafCell>
                  <LeafCell>
                    <NoSubmitDatePicker
                      data-test="expected-drawdown-timing-date-agreed-new-entry"
                      label="Date agreed"
                      onSubmit={update("agreementDate")}
                      value={values.agreementDate}
                    />
                  </LeafCell>
                </Grid>
              )}
            </NewEntry>
          </>
        )}
      </div>
    );
  }
}

export const ExpectedDrawdownTiming = compose(
  graphql(query, expectedDrawdownTimingsQueryConfig),
  graphql(
    createExpectedDrawdownTimingMutation,
    createExpectedDrawdownTimingConfig,
  ),
)(ExpectedDrawdownTimingComponent);
