import T from "prop-types";
import { path } from "ramda";

import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import guaranteeQuery from "./queries/guaranteeQuery.graphql";
import { guaranteeQueryConfig } from "./queries/guaranteeQueryConfig";

import { updateDealTypeGuaranteeConfig } from "./mutations/updateDealTypeGuaranteeConfig";
import UpdateDealTypeGuaranteeMutation from "./mutations/UpdateDealTypeGuaranteeMutation.graphql";

const GuaranteeComponent = ({ data: queryResult, updateDealTypeGuarantee }) => {
  const dealTypeData =
    path(["nestDeal", "dealTypeGuarantee"], queryResult) || null;
  return (
    dealTypeData && (
      <Grid columns={4}>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeGuarantee}
            property="baseFee"
            data-test="deal-type-guarantee-base-fee"
            label="Base fee %"
            value={dealTypeData.baseFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeGuarantee}
            property="incentiveFee"
            data-test="deal-type-guarantee-incentive-fee"
            label="Incentive fee %"
            value={dealTypeData.incentiveFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeGuarantee}
            property="noDrawDiscount"
            data-test="deal-type-guarantee-no-draw-discount"
            label="No draw discount %"
            value={dealTypeData.noDrawDiscount}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeGuarantee}
            property="earlyPaymentFeePerDay"
            data-test="deal-type-guarantee-early-payment-fee-per-day"
            label="Early payment fee % (per day)"
            value={dealTypeData.earlyPaymentFeePerDay}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeGuarantee}
            property="earlyPaymentFeeFreePercent"
            data-test="deal-type-guarantee-early-payment-fee-free-percent"
            label="Early payment fee free %"
            value={dealTypeData.earlyPaymentFeeFreePercent}
          />
        </LeafCell>
        <LeafCell>
          <CurrencyField
            mutation={updateDealTypeGuarantee}
            property="referralDiscount"
            data-test="deal-type-guarantee-referral-discount"
            label="Referral discount"
            value={dealTypeData.referralDiscount}
          />
        </LeafCell>
      </Grid>
    )
  );
};

GuaranteeComponent.propTypes = {
  data: T.object.isRequired,
  updateDealTypeGuarantee: T.func.isRequired,
};

export const Guarantee = compose(
  graphql(guaranteeQuery, guaranteeQueryConfig),
  graphql(UpdateDealTypeGuaranteeMutation, updateDealTypeGuaranteeConfig),
)(GuaranteeComponent);
