// @flow
import { Redirect, Route, Switch } from "react-router-dom";
import { Tabs, Tab } from "../../components/Navigation/Tabs";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../components/Navigation/Breadcrumbs";
import { ToListListView } from "./ToListListView";
import { ListedListView } from "./ListedListView";
import { UnderOfferListView } from "./UnderOfferListView";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { ListViewPageWrapper } from "../../components/ListViewSharedStyles";

export const Portfolio = () => {
  const { toList, listed, underOffer } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Sellers</Breadcrumb>
        <Breadcrumb to="#">Portfolio</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/portfolio/to-list" count={toList}>
          To list
        </Tab>
        <Tab to="/portfolio/listed" count={listed}>
          Listed
        </Tab>
        <Tab to="/portfolio/under-offer" count={underOffer}>
          Under offer
        </Tab>
      </Tabs>
      <Switch>
        <Redirect from="/portfolio" to="/portfolio/to-list" exact />
        <Route path="/portfolio/to-list" component={ToListListView} />
        <Route path="/portfolio/listed" component={ListedListView} />
        <Route path="/portfolio/under-offer" component={UnderOfferListView} />
      </Switch>
    </ListViewPageWrapper>
  );
};
