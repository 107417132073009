import DueDiligenceDatesQuery from "../queries/dueDiligenceDates.graphql";

export const createConfig = {
  props: ({ mutate, ownProps }) => ({
    createDueDiligenceDate: () =>
      mutate({
        variables: {
          id: ownProps.dealId,
        },
        refetchQueries: [
          {
            query: DueDiligenceDatesQuery,
            variables: {
              id: ownProps.dealId,
            },
          },
        ],
      }),
  }),
};
