// @flow
import { gql } from "@apollo/client";

import { Grid, LeafCell } from "components/Grid";
import { TextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

const PROCEEDING_AFTER_COMPLETED_INDIC_VALUATION_QUERY = gql`
  query ProceedingAfterCompletedIndicValuationQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      vaPropertyNotes
    }
  }
`;

const PROCEEDING_AFTER_COMPLETED_INDIC_VALUATION_MUTATION = gql`
  mutation ProceedingAfterCompletedIndicValuationMutation(
    $input: NestDealInput!
    $dealId: ID!
  ) {
    updateNestDeal(nestDeal: $input, id: $dealId) {
      id
      vaPropertyNotes
    }
  }
`;

type Props = {
  dealId: string,
};

export const ProceedingAfterCompletedIndicValuation = ({ dealId }: Props) => (
  <ExtendedQuery
    query={PROCEEDING_AFTER_COMPLETED_INDIC_VALUATION_QUERY}
    variables={{ dealId }}
  >
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<ProceedingAfterCompletedIndicValuationQuery>) => (
      <ExtendedMutation
        mutation={PROCEEDING_AFTER_COMPLETED_INDIC_VALUATION_MUTATION}
        variables={{ dealId }}
      >
        {(updateNestDeal) => {
          const mutate = (input) =>
            updateNestDeal({
              variables: {
                input,
              },
              optimisticResponse: {
                __typename: "Mutation",
                updateNestDeal: {
                  __typename: "NestDeal",
                  ...nestDeal,
                  ...input,
                },
              },
            });

          return (
            <Grid columns={2}>
              <LeafCell width={1}>
                <TextareaWithLinks
                  data-test="proceeding-after-completed-indic-valuation:va-property-notes"
                  label="VA property & marketability notes"
                  property="vaPropertyNotes"
                  value={nestDeal.vaPropertyNotes}
                  mutation={mutate}
                />
              </LeafCell>
            </Grid>
          );
        }}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
