// @flow

import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";

const BuyerAvatar = styled.img`
  border-radius: 50%;
  height: 36px;
  margin: 0 7px 0 0;
  width: 36px;
`;

const BuyerName = styled.p`
  color: ${({ theme }) => theme.palette.hague100};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
`;

const buyerLabelStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const buyerDetailsStyle = css`
  display: flex;
  width: 150px;
`;

export const BUYER_WITH_AGREED_OR_ACCEPTED_OFFER_QUERY = gql`
  query ProgressionBuyerWithAgreedOrAcceptedOffer($buyerOfferId: ID!) {
    buyerOffer(id: $buyerOfferId) {
      id
      buyer {
        id
        avatarUrl
        name
      }
    }
  }
`;

type Props = {
  buyerOfferId: string,
  className?: string,
};

export const BuyerDetails = ({ buyerOfferId, className }: Props) => {
  const { data, loading } = useQuery(
    BUYER_WITH_AGREED_OR_ACCEPTED_OFFER_QUERY,
    {
      variables: { buyerOfferId },
    },
  );

  if (loading) return null;

  const { buyer } = data?.buyerOffer || {};

  if (!buyer) return null;

  return (
    <div className={className}>
      <Link to={`/buyers/${buyer.id}`}>
        <div css={buyerDetailsStyle} data-test="buyer-details">
          <BuyerAvatar src={buyer.avatarUrl} />
          <div>
            <BuyerName className="fs-exclude">{buyer.name}</BuyerName>
            <div css={buyerLabelStyle}>Buyer</div>
          </div>
        </div>
      </Link>
    </div>
  );
};
