// @flow
import { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { gql, useMutation } from "@apollo/client";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Button } from "@nested/nest/src/components/Button/Button";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { TextInput } from "@nested/nest/src/components/TextInput/TextInput";
import { TextArea } from "@nested/nest/src/components/TextArea/TextArea";
import { EmailInputWithValidation } from "@nested/nest/src/components/TextInput/EmailInputWithValidation";
import { AreYouSureOverlay } from "@nested/nest/src/components/AreYouSureOverlay";
import GetActiveUsersQuery from "./queries/GetActiveUsersQuery.graphql";

export const ADD_NEW_USER = gql`
  mutation AddNewUser($input: CreateNestedUserInput!) {
    createNestedUser(input: $input) {
      id
    }
  }
`;

const formWrapper = css`
  padding: 10px 20px;
  ${media.tablet`
    padding: 0 30px;
    display: flex;
  `}
`;

const columnStyle = css`
  ${media.tablet`
    width: 50%;
    padding: ${({ $right }) => ($right ? "0 0 0 25px" : "0 25px 0 0")}
  `}
`;

const fieldsStyle = css`
  padding: 10px 0;
  & input {
    ::placeholder {
      color: ${({ theme }) => theme.palette.hague40};
    }
  }
  & ul {
    top: unset;
  }
`;

const submitButtonWrapper = css`
  padding: 20px;
  ${media.tablet`
    margin-top: 20px;
    position: static;
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    button {
      width: 310px;
      margin: 0 auto;
    }
`}
`;

type Props = {
  open: boolean,
  onClose: () => void,
};

const validate = (value) => (value ? undefined : "Required");

const AddUserForm = ({ isOpen, onClose, setEditing }) => {
  const firstNameInput = useRef();
  const [addNewUser] = useMutation(ADD_NEW_USER, {
    refetchQueries: [{ query: GetActiveUsersQuery }],
  });

  useEffect(() => {
    setTimeout(() => {
      if (firstNameInput.current) {
        firstNameInput.current?.focus();
      }
    }, 500);
  }, [isOpen]);

  const onSubmit = async (input) => {
    try {
      const { data } = await addNewUser({
        variables: { input },
      });
      if (data?.createNestedUser) {
        onClose();
      }
      return undefined;
    } catch (e) {
      errorHandler(e);
      return {
        [FORM_ERROR]: "Failed to create a new user",
      };
    }
  };

  return (
    <Form onSubmit={onSubmit} keepDirtyOnReinitialize>
      {({
        handleSubmit,
        submitting,
        validating,
        hasValidationErrors,
        pristine,
        values,
      }) => (
        <FormFields
          setEditing={setEditing}
          pristine={pristine}
          handleSubmit={handleSubmit}
          submitting={submitting}
          validating={validating}
          hasValidationErrors={hasValidationErrors}
          values={values}
          isOpen={isOpen}
          firstNameInput={firstNameInput}
        />
      )}
    </Form>
  );
};

const FormFields = ({
  setEditing,
  pristine,
  handleSubmit,
  submitting,
  validating,
  hasValidationErrors,
  isOpen,
  firstNameInput,
}) => {
  useEffect(() => {
    setEditing(!pristine);
  }, [pristine]);

  return (
    <form onSubmit={handleSubmit}>
      {
        // firstName and lastName fields are wrapped in a separate div
        // to make tabbing go from firstName to lastName when pressing TAB.
      }
      <div css={formWrapper}>
        <div css={columnStyle}>
          <Field name="firstName" validate={validate}>
            {({ input, meta }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="First name"
                  valid={!meta.error}
                  forwardedRef={firstNameInput}
                />
              );
            }}
          </Field>
        </div>
        <div css={columnStyle} $right>
          <Field name="lastName" validate={validate}>
            {({ input, meta }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Last name"
                  valid={!meta.error}
                />
              );
            }}
          </Field>
        </div>
      </div>
      <div css={formWrapper}>
        <div css={columnStyle}>
          <div css="position: relative;">
            <EmailInputWithValidation
              css={fieldsStyle}
              fieldName="email"
              isOpen={isOpen}
              label="Email"
            />
          </div>

          <Field name="introCallBookingUrl" validate={validate}>
            {({ input, meta }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Intro call Calendly URL"
                  valid={!meta.error}
                />
              );
            }}
          </Field>

          <Field name="jobTitle" validate={validate}>
            {({ input, meta }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Job title"
                  valid={!meta.error}
                />
              );
            }}
          </Field>
          <Field name="partnerArea" validate={validate}>
            {({ input, meta }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Partner area (customer facing)"
                  valid={!meta.error}
                />
              );
            }}
          </Field>
          <Field name="mobileNumber">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Mobile number (required for APs)"
                  showOptionalLabel
                />
              );
            }}
          </Field>
        </div>

        <div css={columnStyle} $right>
          <Field name="virtualHomeVisitBookingUrl">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Virtual home visit Calendly URL"
                  showOptionalLabel
                />
              );
            }}
          </Field>
          <Field name="viewingBookingUrl">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Viewing Calendly URL"
                  showOptionalLabel
                />
              );
            }}
          </Field>
          <Field name="actionPlanReviewBookingUrl">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Action plan review Calendly URL"
                  showOptionalLabel
                />
              );
            }}
          </Field>
          <Field name="videoCallUrl">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Zoom URL"
                  showOptionalLabel
                />
              );
            }}
          </Field>
          <Field name="homeVisitBookingUrl">
            {({ input }) => {
              return (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Home visit Calendly URL"
                  showOptionalLabel
                />
              );
            }}
          </Field>
        </div>
      </div>
      <div css={formWrapper}>
        <Field name="closeUserId">
          {({ input }) => {
            return (
              <TextInput
                {...input}
                className="fs-exclude"
                css={fieldsStyle}
                label="Close user ID (if applicable)"
                showOptionalLabel
              />
            );
          }}
        </Field>
      </div>
      <div css={formWrapper}>
        <Field name="bio" validate={validate}>
          {({ input, meta }) => {
            return (
              <TextArea
                {...input}
                className="fs-exclude"
                css={fieldsStyle}
                label="Bio"
                valid={!meta.error}
              />
            );
          }}
        </Field>
      </div>
      <div css={submitButtonWrapper}>
        <Button
          buttonStyle="pink"
          css="&:disabled { opacity: 0.4 }"
          data-test="add-new-user-button"
          disabled={submitting || hasValidationErrors || validating || pristine}
          large
          type="submit"
        >
          {submitting ? "Saving..." : "Add new user"}
        </Button>
      </div>
    </form>
  );
};

export const AddUserModal = ({ open, onClose }: Props) => {
  const [areYouSure, setAreYouSure] = useState(false);
  const [editing, setEditing] = useState(false);

  const onCloseResetState = () => {
    setAreYouSure(false);
    setEditing(false);
    onClose();
  };

  const onCloseUnlessEditing = () => {
    if (editing) {
      setAreYouSure(true);
    } else {
      onCloseResetState();
    }
  };

  return (
    <MobileFullScreenModal
      onClose={onCloseUnlessEditing}
      open={open}
      css="width: 780px;"
      headerText="Add new user"
      data-test="add-new-user-modal"
    >
      {open && (
        <>
          <AddUserForm
            isOpen={open}
            onClose={onCloseResetState}
            setEditing={setEditing}
          />
          {areYouSure && (
            <AreYouSureOverlay
              message="You have unsaved changes. Are you sure you want to stop creating this user?"
              leftText="Continue editing"
              leftOnClick={() => setAreYouSure(false)}
              rightText="Discard changes"
              rightOnClick={onCloseResetState}
            />
          )}
        </>
      )}
    </MobileFullScreenModal>
  );
};
