// @flow
export const Search = ({ fill = "#353F52" }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M60.6361075,119.72903 L64,123.092923 L63.0929229,124 L59.7290305,120.636108 C58.919682,121.255034 57.9085899,121.622118 56.811059,121.622118 C54.1536866,121.622118 52,119.468431 52,116.811059 C52,114.153687 54.1536866,112 56.811059,112 C59.4684314,112 61.622118,114.153687 61.622118,116.811059 C61.622118,117.90859 61.2550342,118.91965 60.6361075,119.72903 Z M56.8109949,120.339169 C58.7592172,120.339169 60.3391048,118.759281 60.3391048,116.811059 C60.3391048,114.862837 58.7592172,113.282949 56.8109949,113.282949 C54.8627726,113.282949 53.2828849,114.862837 53.2828849,116.811059 C53.2828849,118.759281 54.8627726,120.339169 56.8109949,120.339169 Z"
      transform="translate(-52 -112)"
    />
  </svg>
);
