import T from "prop-types";
import styled from "styled-components";
import { media } from "@nested/brand";

import { ActionButton } from "components/ActionButton";
import { graphql } from "deal/utils/graphql";

import completeDealMutation from "./mutations/completeDealMutation.graphql";
import { completeDealConfig } from "./mutations/completeDealConfig";

const Button = styled(ActionButton)`
  border: none;
  width: 100%;
`;

const CompletionDiv = styled.div`
  margin-left: 10px;
  ${media.desktop`
    display: block;
  `}
`;

const DealCompletionComponent = ({ completeDeal, dealActive }) =>
  dealActive ? (
    <CompletionDiv data-test="deal-completion">
      <Button label="Complete Deal" onSubmit={completeDeal} />
    </CompletionDiv>
  ) : (
    <CompletionDiv data-test="deal-completion">
      Deal Completed And Inactive
      <span role="img" aria-label="unicorn sploosh">
        🦄💦
      </span>
    </CompletionDiv>
  );

DealCompletionComponent.propTypes = {
  completeDeal: T.func.isRequired,
  dealActive: T.bool.isRequired,
};

export const DealCompletion = graphql(
  completeDealMutation,
  completeDealConfig,
)(DealCompletionComponent);
