// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";

const contactInfo = css`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.hague70};
  margin: 0;
`;

const contactName = css`
  ${contactInfo}
  color: ${({ theme }) => theme.palette.hague150};
  font-weight: 500;
  margin-bottom: 5px;
`;

const contactEmail = css`
  ${contactName}
  ${media.tablet`
    display: none;
  `}
`;

const getPhone = (phones) => {
  if (phones.length > 0) {
    return phones[0].telephoneNumber;
  }
  return "-";
};

type ContactDetailsProps = {
  className?: string,
  contact:
    | contactsTabDealQuery_dealOrBuyer_contacts
    | contactsTabBuyerQuery_dealOrBuyer_contacts
    | FindContactByEmail_findContactByEmail
    | FindContactByEmailUpdateContactModal_findContactByEmail,
  showEmail?: boolean,
};

export const ContactDetails = ({
  className,
  contact,
  showEmail,
}: ContactDetailsProps) => {
  const { firstName, lastName, phones, title } = contact;

  return (
    <div className={className}>
      <p
        css={contactName}
        className="fs-exclude"
        data-test={`name-${firstName || ""} ${lastName || ""}`}
      >
        {firstName}&nbsp;{lastName}
      </p>
      {showEmail && contact.emails && (
        <p css={contactEmail} className="fs-exclude">
          {contact.emails[0].emailAddress}
        </p>
      )}
      <p css={contactInfo} className="fs-exclude">
        {getPhone(phones)}
      </p>
      <p css={contactInfo} className="fs-exclude">
        {title}
      </p>
    </div>
  );
};
