// @flow
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { device } from "@nest-ui/styles";
import { LogoutLink } from "./LogoutLink";

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
`;

const Transition = styled.div`
  transition: all 100ms;
  width: ${({ navBarWidth, showNavBar }) => (showNavBar ? navBarWidth : "0")};
  overflow: hidden;

  &.nav-bar-enter,
  &.nav-bar-appear {
    width: 0;
  }

  &.nav-bar-enter-active,
  &.nav-bar-appear-active,
  &.nav-bar-exit {
    width: ${({ navBarWidth }) => navBarWidth};
  }

  &.nav-bar-exit-active {
    width: 0;
  }

  @media ${device.desktop} {
    width: ${({ navBarWidth }) => navBarWidth};
  }
`;

const NavBarDiv = styled.nav`
  width: ${({ navBarWidth }) => navBarWidth};
  height: calc(100vh - ${({ theme }) => theme.height.header});
  flex: 0 0 auto;
  background-color: ${({ theme }) => theme.color.text.hague150};
  color: ${({ theme }) => theme.color.text.light};
  overflow: hidden;
`;

const TranslucentBox = styled.div`
  background-color: ${({ theme }) => theme.color.primary.dark};
  position: fixed;
  opacity: 0.1;
  transition: opacity 100ms;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  &.translucent-box-enter,
  &.translucent-box-appear {
    opacity: 0;
  }

  &.translucent-box-enter-active,
  &.translucent-box-appear-active,
  &.translucent-box-exit {
    opacity: 0.1;
  }

  &.translucent-box-exit-active {
    opacity: 0;
  }
`;

const Hamburger = styled.button`
  padding: 0;
  position: absolute;
  top: 14px;
  right: -52px;
  border: none;
  outline: none;
  background-color: transparent;
  z-index: -2;
  color: ${({ theme }) => theme.palette.hague};
  font-size: 1.6em;

  @media ${device.desktop} {
    display: none;
  }
`;

const BottomButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
`;

const ScrollableSection = styled.div`
  height: calc(100vh - 32px - ${({ theme }) => theme.height.header});
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${({ theme }) => theme.color.primary.dark};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.secondary.dark};
  }
`;

export const SummaryDiv = styled.div`
  color: ${({ theme }) => theme.color.text.dark};
  min-height: ${({ minHeight }) => minHeight};
  padding: 16px;
  padding-bottom: 13px;

  p {
    font-size: ${({ theme }) => theme.font.size.default};
    line-height: 16px;
    padding: 0px;
    margin: 0px;
    color: ${({ theme }) => theme.color.text.light};
  }

  a {
    font-size: ${({ theme }) => theme.font.size.default};
    font-weight: ${({ theme }) => theme.font.weight.semibold};
    line-height: 16px;
    color: ${({ theme }) => theme.color.text.dark};
  }

  .Select-multi-value-wrapper {
    display: block;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.color.text.dark};

    div.Select-input {
      display: none !important;
    }
  }

  .Select-menu {
    ::-webkit-scrollbar {
      width: 5px;
      background-color: ${({ theme }) => theme.color.secondary.dark};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.color.primary.dark};
    }
  }
`;

export const Owner = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.light};
  margin-bottom: 4px;
`;

export const Separator = styled.hr`
  border: 0px;
  height: 1px;
  margin: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.1);
`;

export const NavBar = withRouter((props) => {
  const [showNavBar, setShowNavBar] = useState(false);
  const { variant, children, bottomButton, location } = props;
  const navBarWidth = variant === "Buyer" ? "369px" : "256px";

  useEffect(() => setShowNavBar(false), [location]);

  return (
    <Wrapper>
      <CSSTransition
        in={showNavBar}
        timeout={200}
        classNames="translucent-box"
        appear
        unmountOnExit
      >
        <TranslucentBox onClick={() => setShowNavBar(false)} />
      </CSSTransition>

      <Hamburger onClick={() => setShowNavBar(true)}>
        <FontAwesomeIcon icon={faBars} />
      </Hamburger>

      <CSSTransition in={showNavBar} timeout={200} classNames="nav-bar">
        <Transition showNavBar={showNavBar} navBarWidth={navBarWidth}>
          <NavBarDiv id="nav-bar" navBarWidth={navBarWidth}>
            <ScrollableSection>{children}</ScrollableSection>
            <BottomButtonBar>
              <LogoutLink />
              {bottomButton && bottomButton()}
            </BottomButtonBar>
          </NavBarDiv>
        </Transition>
      </CSSTransition>
    </Wrapper>
  );
});
