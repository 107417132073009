// @flow
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react/hooks";

import { Grid, Cell, LeafCell } from "components/Grid";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

import { NoSubmitRadioButtons } from "components/RadioButtons";
import styled from "styled-components";
import { ListEntryCard } from "components/Card";
import { ChainLinks } from "components/PropertyChainLinks/ChainLinks";
import { Heading } from "components/Heading";

const OurSeller = styled(ListEntryCard)`
  color: ${({ theme }) => theme.color.text.default};
  border: 2px solid ${({ theme }) => theme.color.secondary.light};
  height: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 48px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const SELLER_PROPERTY_CHAIN_QUERY = gql`
  query SellerChainInfo($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      vendorHasOnwardChain
    }
  }
`;

const UPDATE_SELLER_PROPERTY_CHAIN_MUTATION = gql`
  mutation UpdateSellerPropertyChain($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      vendorHasOnwardChain
    }
  }
`;

const BUYER_PROPERTY_CHAIN_QUERY = gql`
  query BuyerPropertyChainQuery($id: ID!) {
    buyerOffer(id: $id) {
      id
      buyerHasDownwardChain
    }
  }
`;

const UPDATE_BUYER_PROPERTY_CHAIN_MUTATION = gql`
  mutation UpdateBuyerPropertyChain($id: ID!, $input: BuyerOfferInput!) {
    updateBuyerOffer(id: $id, input: $input) {
      id
      buyerHasDownwardChain
    }
  }
`;

const RADIO_OPTIONS = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

type Props = {
  buyerOfferId: string,
  dealId: string,
  showHeading?: boolean,
};

export const BuyerOfferSellerBuyerChain = ({
  buyerOfferId,
  dealId,
  showHeading = true,
}: Props) => {
  const { data: nestDealData, error: nestDealDataError } = useQuery(
    SELLER_PROPERTY_CHAIN_QUERY,
    {
      variables: { dealId },
    },
  );

  const vendorHasOnwardChain = nestDealData?.nestDeal?.vendorHasOnwardChain;

  const { data: buyerOfferData, error: buyerOfferDataError } = useQuery(
    BUYER_PROPERTY_CHAIN_QUERY,
    {
      variables: { id: buyerOfferId },
    },
  );

  const buyerHasDownwardChain =
    buyerOfferData?.buyerOffer?.buyerHasDownwardChain;

  if (nestDealDataError || buyerOfferDataError) {
    errorHandler(nestDealDataError || buyerOfferDataError);
    return null;
  }

  return (
    <Cell width={4}>
      <Grid columns={4}>
        {showHeading && (
          <LeafCell width={1}>
            <Heading level={2}> Chain </Heading>
          </LeafCell>
        )}
        <LeafCell width={1} left={1}>
          <BuyerOfferSellerChain nestDeal={nestDealData?.nestDeal} />
        </LeafCell>
        <Cell width={4} id="vendor-chain">
          {vendorHasOnwardChain && <ChainLinks dealId={dealId} />}
          {(vendorHasOnwardChain || buyerHasDownwardChain) && (
            <OurSeller>Our seller</OurSeller>
          )}
          {buyerHasDownwardChain && <ChainLinks buyerOfferId={buyerOfferId} />}
        </Cell>
        <BuyerOfferBuyerChain buyerOffer={buyerOfferData?.buyerOffer} />
      </Grid>
    </Cell>
  );
};

const BuyerOfferSellerChain = ({ nestDeal }) => {
  const [updateVendorPropertyChain] = useMutation(
    UPDATE_SELLER_PROPERTY_CHAIN_MUTATION,
  );

  const updateVendorHasOnwardChain = async (value) => {
    try {
      await updateVendorPropertyChain({
        variables: {
          id: nestDeal?.id,

          input: {
            vendorHasOnwardChain: value,
          },
        },
        optimisticResponse: {
          updateNestDeal: {
            __typename: "NestDeal",
            id: nestDeal?.id,
            vendorHasOnwardChain: value,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <NoSubmitRadioButtons
      data-test="vendor-has-property-chain"
      label="Is our seller in an onward chain?"
      onSubmit={(value) => {
        updateVendorHasOnwardChain(value);
      }}
      value={nestDeal?.vendorHasOnwardChain}
      id="is-our-seller-in-an-onward-chain"
      options={RADIO_OPTIONS}
    />
  );
};

const BuyerOfferBuyerChain = ({ buyerOffer }) => {
  const [updateBuyerPropertyChain] = useMutation(
    UPDATE_BUYER_PROPERTY_CHAIN_MUTATION,
  );
  const updateBuyerHasDownwardChain = async (value) => {
    try {
      await updateBuyerPropertyChain({
        variables: {
          id: buyerOffer?.id,

          input: {
            buyerHasDownwardChain: value,
          },
        },
        optimisticResponse: {
          updateBuyerOffer: {
            __typename: "BuyerOffer",
            id: buyerOffer?.id,
            buyerHasDownwardChain: value,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <NoSubmitRadioButtons
      data-test="vendor-has-property-chain"
      label="Is the buyer in a downward chain?"
      onSubmit={(value) => {
        updateBuyerHasDownwardChain(value);
      }}
      value={buyerOffer?.buyerHasDownwardChain}
      id="is-the-buyer-in-a-downward-chain"
      options={RADIO_OPTIONS}
    />
  );
};
