// @flow
import { css } from "styled-components";

import { authClient } from "@nest-ui/sellers-nest/authClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const wrapperStyle = css`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`;

const logoutButtonStyle = css`
  padding: 20px 20px;
  margin-top: 20px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.hague150};
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-weight: 500;
  border: none;
  background: none;
`;

const logoutIconStyle = css`
  margin-left: 7px;
  color: ${({ theme }) => theme.palette.hague70};
`;

export const LogoutButton = () => {
  return (
    <div css={wrapperStyle}>
      <button
        css={logoutButtonStyle}
        data-test="logout"
        onClick={() => {
          authClient.logout();
        }}
      >
        Log out
        <span css={logoutIconStyle}>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </span>
      </button>
    </div>
  );
};
