import { resolveInputOptions } from "@nest-ui/sellers-nest/deal/utils/graphql";

export const inputOptionFields = {
  dealType: "dealTypes",
  debtFacilityCommittedTo: "debtFacility",
};

export const updateConfig = {
  props({
    mutate,
    ownProps: {
      data: { inputOptions, ...data },
      ownProps,
    },
  }) {
    return {
      updateNestDeal: (input) => {
        const formattedInput = resolveInputOptions(
          input,
          inputOptionFields,
          inputOptions,
        );

        return mutate({
          variables: {
            input,
            id: data.nestDeal.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateNestDeal: {
              __typename: "NestDeal",
              id: data.nestDeal.id,
              ...data.nestDeal,
              ...formattedInput,
            },
          },
        });
      },
      data: {
        inputOptions,
        ...data,
      },
      ...ownProps,
    };
  },
};
