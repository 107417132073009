// @flow
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { css } from "styled-components";
import { formatPrice } from "@nested/utils";
import { Button } from "@nested/nest/src/components/Button/Button";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { RenegotiatedOffers } from "@nest-ui/sellers-nest/tabs/Progression/RenegotiatedOffers/RenegotiatedOffers";
import latestOfferAmount from "./latestOfferAmount.graphql";

const wrapperStyle = css`
  display: flex;
  color: ${({ theme }) => theme.palette.hague100};
  justify-content: space-between;
  align-items: end;
`;

const labelStyle = css`
  display: block;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  height: 18px;
  margin-right: 20px;
  white-space: nowrap;
`;

const priceStyle = css`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
`;

export const CurrentOfferPrice = ({
  buyerOfferId,
  className,
}: {
  buyerOfferId: string,
  className?: string,
}) => {
  const [open, setOpen] = useState(false);
  const { data, loading } = useQuery(latestOfferAmount, {
    variables: { buyerOfferId },
  });

  const { latestRenegotiatedOfferAmount, nestDeal } = data?.buyerOffer || {};
  const hasExchanged = ["s10_exchanged", "s11_completed"].includes(
    nestDeal?.opportunityStatus?.valueText,
  );

  return (
    <div css={wrapperStyle} className={className}>
      <div css="display: block;">
        <span css={labelStyle}>Current Offer</span>
        <div css={priceStyle}>
          <span>
            {loading
              ? "-"
              : formatPrice(parseFloat(latestRenegotiatedOfferAmount))}
          </span>
        </div>
      </div>
      <Button
        buttonStyle="white"
        css="width: auto; margin: 0 0 0 auto;"
        onClick={() => setOpen(true)}
        disabled={hasExchanged}
      >
        Renegotiate
      </Button>
      <MobileFullScreenModal
        open={open}
        onClose={() => setOpen(false)}
        headerText="Renegotiated Offers"
      >
        {open && (
          <div css="padding: 0 20px 20px; min-height: 450px; position: relative;">
            <RenegotiatedOffers buyerOfferId={buyerOfferId} />
          </div>
        )}
      </MobileFullScreenModal>
    </div>
  );
};
