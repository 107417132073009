// @flow
import styled from "styled-components";
import { kebabCase } from "lodash";

import { ButtonWithIcon } from "components/ButtonWithIcon";
import { Delete, Tick } from "@nest-ui/icons";
import { ListEntry } from "components/ListEntry";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitPercentageField } from "components/PercentageField";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";
import { PrimaryButton } from "components/Buttons";
import { NoSubmitRadioButtons as RadioButtons } from "components/RadioButtons/RadioButtons";

const layout = [
  "date-completed  date-completed  date-completed  date-completed expiry-date expiry-date expiry-date expiry-date",
  "reason-for-advance-offer-valuation reason-for-advance-offer-valuation reason-for-advance-offer-valuation reason-for-advance-offer-valuation advance-valuation-trigger advance-valuation-trigger advance-valuation-trigger advance-valuation-trigger",
  "completed-by    completed-by    completed-by   completed-by   signed-off-by       signed-off-by       signed-off-by       signed-off-by      ",
  "listing-price   listing-price   listing-price  listing-price  expected-sale-price expected-sale-price expected-sale-price expected-sale-price",
  "issuing-advance issuing-advance advance-amount advance-amount advance-amount      advance-percent     advance-percent     advance-percent    ",
  "notes           notes           notes          notes          notes               notes               notes               notes              ",
  "save            save            save           save           .................... ..............     delete              delete             ",
];

const Card = styled(ListEntry)`
  margin-bottom: 0px;
`;

export const advancePercentage = ({
  advanceAmount,
  expectedSalePrice,
  issuingAdvance,
}: Object) => {
  if (advanceAmount && expectedSalePrice && issuingAdvance) {
    return ((advanceAmount / expectedSalePrice) * 100).toFixed(2);
  }
  return null;
};

type Props = {
  dealId: string,
  reasonsForAdvanceOfferValuation?: $ReadOnlyArray<{
    value: string,
    label: string,
  }>,
  advanceValuationTriggers: $ReadOnlyArray<{
    +value: string,
    +label: string,
  }>,
  users: $ReadOnlyArray<{ value: string, label: ?string }>,
  updateState: (newValuation: $Shape<CreateValuationInput>) => void,
  valuation: $Shape<CreateValuationInput>,
  onSubmit: () => any,
  onCancel: () => any,
};

export const CreateAdvanceOfferValuation = (props: Props) => {
  const fieldProps = (key: string) => ({
    "data-test": `create-advance-visit-valuation-${kebabCase(key)}`,
    value: props.valuation[key],
    onSubmit: (val) => props.updateState({ [key]: val }),
  });

  const disableSubmit = !(
    props.valuation.completedBy &&
    props.valuation.dateCompleted &&
    props.valuation.signedOffBy &&
    props.valuation.recommendedListingPrice &&
    props.valuation.expectedSalePrice &&
    props.valuation.issuingAdvance !== null &&
    props.valuation.advanceValuationTriggerId
  );

  return (
    <Card>
      <Grid columns={8} areas={layout}>
        <LeafCell area="date-completed">
          <NoSubmitDatePicker
            {...fieldProps("dateCompleted")}
            label="Date Completed"
          />
        </LeafCell>

        <LeafCell area="expiry-date">
          <NoSubmitDatePicker
            {...fieldProps("expiryDate")}
            label="Expiry Date"
          />
        </LeafCell>

        <LeafCell area="reason-for-advance-offer-valuation">
          <NoSubmitSelectField
            {...fieldProps("reasonForAdvanceOfferValuationId")}
            options={props.reasonsForAdvanceOfferValuation || []}
            searchable
            label="Reason for advance offer valuation"
          />
        </LeafCell>

        <LeafCell area="advance-valuation-trigger">
          <NoSubmitSelectField
            {...fieldProps("advanceValuationTriggerId")}
            options={props.advanceValuationTriggers || []}
            searchable
            label="Advance valuation trigger"
          />
        </LeafCell>

        <LeafCell area="completed-by">
          <NoSubmitSelectField
            {...fieldProps("completedBy")}
            options={props.users}
            searchable
            label="Completed By"
          />
        </LeafCell>

        <LeafCell area="signed-off-by">
          <NoSubmitSelectField
            {...fieldProps("signedOffBy")}
            options={props.users}
            searchable
            label="Signed Off By"
          />
        </LeafCell>

        <LeafCell area="listing-price">
          <NoSubmitCurrencyField
            {...fieldProps("recommendedListingPrice")}
            label="Recommended Listing Price"
          />
        </LeafCell>

        <LeafCell area="expected-sale-price">
          <NoSubmitCurrencyField
            {...fieldProps("expectedSalePrice")}
            label="Expected Sale Price"
          />
        </LeafCell>

        <LeafCell area="issuing-advance">
          <RadioButtons
            data-test={`create-advance-visit-valuation-issuing-advance`}
            id="create-advance-visit-valuation-issuing-advance"
            label="Issuing Advance"
            onSubmit={(val) => props.updateState({ issuingAdvance: val })}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={props.valuation.issuingAdvance}
          />
        </LeafCell>

        <LeafCell area="advance-amount">
          <NoSubmitCurrencyField
            {...fieldProps("advanceAmount")}
            disabled={!props.valuation.issuingAdvance}
            label="Advance Amount"
          />
        </LeafCell>

        <LeafCell area="advance-percent">
          <NoSubmitPercentageField
            data-test="create-advance-visit-valuation-advance-percent"
            value={advancePercentage({
              advanceAmount: props.valuation.advanceAmount,
              expectedSalePrice: props.valuation.expectedSalePrice,
              issuingAdvance: props.valuation.issuingAdvance,
            })}
            disabled
            label="Advance %"
          />
        </LeafCell>

        <LeafCell area="notes">
          <NoSubmitTextareaWithLinks {...fieldProps("notes")} label="Notes" />
        </LeafCell>

        <LeafCell area="save">
          <div>
            <PrimaryButton
              data-test="create-advance-visit-valuation-save-button"
              onClick={props.onSubmit}
              disabled={disableSubmit}
              icon={Tick}
              label="Save Valuation"
            />
          </div>
        </LeafCell>

        <LeafCell area="delete">
          <ButtonWithIcon
            data-test="create-advance-visit-valuation-cancel-button"
            icon={Delete}
            onSubmit={props.onCancel}
          />
        </LeafCell>
      </Grid>
    </Card>
  );
};
