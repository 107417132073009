// @flow

import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { withRouter, Redirect, type Match } from "react-router";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ScrollableSection } from "components/TabContainer";

import { Sorter } from "../ComparableList/Sorter";
import { Counter } from "../Counter/Counter";
import { ComparableWithNotes } from "../ComparableCard/ComparableWithNotes";
import { Header } from "../Header/Header";

const Wrapper = styled(ScrollableSection)`
  background-color: ${({ theme }) => theme.background.mainSection};
  color: ${({ theme }) => theme.text.grey};
  overflow-y: auto;
  padding: 16px;
`;

export const SELECTED_COMPARABLES_QUERY = gql`
  query SelectedComparablesQuery(
    $reference: ComparableReferenceInput!
    $sortBy: SortBy!
    $sortOrder: SortOrder!
  ) {
    selectedComparables(
      reference: $reference
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      addedAt
      address
      agentName
      comparabilityRating
      comparableImages {
        url
      }
      comparableLinks {
        type
        url
      }
      comparisonPoints {
        description
        rating
      }
      floorArea
      id
      internalNotes
      listingPrice
      numberOfBeds
      propertyType
      relevanceRating
      selected
      soldDate
      soldPrice
      status
      underOfferDate
      underOfferPrice
    }
  }
`;

type Props = {
  closeUrl: string,
  match: Match<*>,
  reference: {
    sourceId: string,
    sourceType: string,
  },
};

export const SelectedComparables = withRouter(
  ({ closeUrl, reference, match }: Props) => {
    const [sortBy, setSortBy] = useState({ sort: "PRICE", order: "ASC" });

    return (
      <>
        <Header closeUrl={closeUrl} nextDisabled />
        <Wrapper>
          <ExtendedQuery
            query={SELECTED_COMPARABLES_QUERY}
            variables={{
              reference,
              sortBy: sortBy.sort,
              sortOrder: sortBy.order,
            }}
            fetchPolicy="network-only"
            nextFetchPolicy="cache-first"
          >
            {({
              selectedComparables,
            }: ExtendedQueryRenderProps<SelectedComparablesQuery>) => {
              if (selectedComparables.length === 0) {
                const selectCompsUrl = match.url.substring(
                  0,
                  match.url.indexOf("/selected"),
                );

                return <Redirect to={selectCompsUrl} />;
              }

              return (
                <>
                  <Sorter sortBy={sortBy} setSortBy={setSortBy} />
                  <Counter
                    labels={[
                      `Finalise your ${selectedComparables.length} ${
                        selectedComparables.length > 1
                          ? "comparables"
                          : "comparable"
                      }`,
                    ]}
                  />
                  {selectedComparables.map((comparable) => (
                    <ComparableWithNotes
                      key={comparable.id}
                      comparable={comparable}
                      disableSelect={() => {}}
                      selectAction={() => {}}
                      refetchOnDelete={() => [
                        {
                          query: SELECTED_COMPARABLES_QUERY,
                          variables: {
                            reference,
                            sortBy: sortBy.sort,
                            sortOrder: sortBy.order,
                          },
                        },
                      ]}
                    />
                  ))}
                </>
              );
            }}
          </ExtendedQuery>
        </Wrapper>
      </>
    );
  },
);
