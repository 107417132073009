import { gql } from "@apollo/client";
import { UpcomingBuyersListView } from "../UpcomingBuyersListView";

export const UPCOMING_OFFERS_QUERY = gql`
  query UpcomingOffersListViewQuery($email: String!) {
    upcomingNegotiatingOffers(email: $email) {
      results {
        id
        deal {
          id
          externalId
          address
          currentListPrice
        }
        buyer {
          id
          name
        }
        snooze {
          id
          snoozedUntil
        }
      }
    }
  }
`;

export const UpcomingNegotiationsListView = () => (
  <UpcomingBuyersListView
    queryField="upcomingNegotiatingOffers"
    query={UPCOMING_OFFERS_QUERY}
    emptyMessage="No upcoming negotiations to deal with."
    getDueTimestamp={(bpi) => bpi.snooze.snoozedUntil}
  />
);
