// @flow
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: #222;
  margin-top: 64px;
  margin-bottom: 24px;
  line-height: 1;
  padding: 0px;
  padding-top: 10px;
`;
