// @flow
import { ContextRouter } from "react-router";

import { ViewingAppPropertyInfo } from "tabs/Listing/ViewingAppPropertyInfo/ViewingAppPropertyInfo";

type Params = {
  buyerId: string,
  buyerPropertyInterestId: string,
  dealId: string,
};

type Props = ContextRouter<Params>;

export const PropertyInfoPage = ({
  match: {
    params: { dealId, buyerPropertyInterestId, buyerId },
  },
}: Props) => (
  <ViewingAppPropertyInfo
    editMode={false}
    dealId={dealId}
    buyerPropertyInterestId={buyerPropertyInterestId}
    buyerId={buyerId}
  />
);
