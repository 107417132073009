// @flow
import { gql, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const pinButtonStyle = css`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: ${({ $pinned, theme }) =>
    $pinned ? theme.palette.blue170 : theme.palette.hague40};
`;

const PIN_MUTATION = gql`
  mutation PinNote($input: PinNoteInput!) {
    pinNote(input: $input) {
      id
      pinned
    }
  }
`;

const UNPIN_MUTATION = gql`
  mutation UnpinNote($input: UnpinNoteInput!) {
    unpinNote(input: $input) {
      id
      pinned
    }
  }
`;

type Props = {
  noteId: number,
  pinned: boolean,
};

export const PinButton = ({ noteId, pinned }: Props) => {
  const [unpinMutation] = useMutation(UNPIN_MUTATION);
  const [pinMutation] = useMutation(PIN_MUTATION);

  const pinNote = async () => {
    try {
      await pinMutation({
        variables: {
          input: {
            id: noteId,
          },
        },
        optimisticResponse: {
          pinNote: {
            __typename: "Note",
            id: noteId,
            pinned: true,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const unpinNote = async () => {
    try {
      unpinMutation({
        variables: {
          input: {
            id: noteId,
          },
        },
        optimisticResponse: {
          unpinNote: {
            __typename: "Note",
            id: noteId,
            pinned: false,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <button
      css={pinButtonStyle}
      $pinned={pinned}
      onClick={pinned ? unpinNote : pinNote}
    >
      <FontAwesomeIcon icon={faThumbtack} />
    </button>
  );
};
