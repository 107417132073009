// @flow
import { gql } from "@apollo/client";
import { max, maxBy } from "lodash";
import { UpcomingBuyersListView } from "../UpcomingBuyersListView";

export const UPCOMING_FEEDBACK_QUERY = gql`
  query UpcomingFeedbackListViewQuery($email: String!) {
    upcomingAwaitingFeedback(email: $email) {
      results {
        id
        deal {
          id
          externalId
          address
          currentListPrice
        }
        buyer {
          id
          name
        }
        viewingsNotCancelled {
          id
          datetimeViewingStarts
        }
        snooze {
          id
          snoozedUntil
        }
      }
    }
  }
`;

export const UpcomingFeedbackListView = () => (
  <UpcomingBuyersListView
    queryField="upcomingAwaitingFeedback"
    query={UPCOMING_FEEDBACK_QUERY}
    emptyMessage="No upcoming feedback to chase."
    getDueTimestamp={(bpi) =>
      max([
        bpi.snooze?.snoozedUntil,
        maxBy(bpi.viewingsNotCancelled, "datetimeViewingStarts")
          .datetimeViewingStarts,
      ])
    }
  />
);
