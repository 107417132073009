// @flow
import React from "react";
import { omit } from "ramda";
import { kebabCase } from "lodash";
import { Grid, LeafCell } from "components/Grid";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import { NoSubmitRadioButtons } from "components/RadioButtons/RadioButtons";
import { H2 } from "components/Heading";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextField } from "components/TextField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { itemDeletionConfirmed } from "components/DeleteButton/DeleteButton";
import { AlignRight } from "components/AlignRight";
import { FullWidthCancelButton } from "./Buttons/FullWidthCancelButton";
import { SaveButton } from "./Buttons/SaveButton";
import { DeleteListEntry } from "./Buttons/DeleteListEntry";

const UPDATE_ADVANCE_IN_PRINCIPLE = gql`
  mutation UpdateAdvanceInPrinciple($input: UpdateAdvanceInPrincipleInput!) {
    updateAdvanceInPrinciple(input: $input) {
      id
      doesCustomerIntendToConvert
      dateCommunicatedToSeller
      advanceOfferValuationOct18Id
      expiryDate
    }
  }
`;

const DELETE = gql`
  mutation DeleteAdvanceInPrinciple($id: ID!) {
    deleteAdvanceInPrinciple(id: $id) {
      id
    }
  }
`;

type State = { isEditing: boolean };

export class AdvanceInPrinciple extends React.Component<*, State> {
  state = {
    isEditing: false,
  };

  setIsEditing = (value: boolean) => {
    this.setState({ isEditing: value });
  };

  toggleIsEditing = () => {
    const value = !this.state.isEditing;
    this.setIsEditing(value);
  };

  render() {
    const {
      advanceInPrinciple,
      advancesSectionQuery,
      updateState,
      onCancel,
      onSubmit,
      hasState,
      advanceValuationDropdownOptions,
      dealId,
    } = this.props;
    const { isEditing } = this.state;
    const isReallyEditing = isEditing || hasState;

    const haveAllRequiredFields = Boolean(
      advanceInPrinciple.advanceOfferValuationOct18Id &&
        advanceInPrinciple.doesCustomerIntendToConvert &&
        advanceInPrinciple.dateCommunicatedToSeller &&
        advanceInPrinciple.expiryDate,
    );

    const showEditButton =
      !isReallyEditing && advanceInPrinciple.advanceOffer === null;

    const fieldProps = (key: string) => ({
      "data-test": `advance-in-principle-${kebabCase(key)}`,
      value: advanceInPrinciple[key],
      readOnly: !isReallyEditing,
      onSubmit: (val) =>
        updateState({
          advanceInPrinciple: {
            ...advanceInPrinciple,
            [key]: val,
          },
        }),
    });

    return (
      // We position the grid because the EditButton below is absolutely positioned
      // and we want that to be absolutely positioned relative to the grid.
      <Grid columns={4} style={{ position: "relative" }}>
        <LeafCell width={4}>
          <H2>Advance in principle communicated to seller</H2>
        </LeafCell>

        {showEditButton && (
          <EditButton
            style={{ top: "-8px", right: "-8px" }}
            data-test="aip-section-aip-edit-button"
            onClick={() => this.setIsEditing(true)}
          >
            Edit
          </EditButton>
        )}

        <LeafCell width={2}>
          <NoSubmitSelectField
            {...fieldProps("advanceOfferValuationOct18Id")}
            label="Advance valuation"
            searchable
            options={advanceValuationDropdownOptions}
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitDatePicker
            label="Expiry date"
            {...fieldProps("expiryDate")}
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitTextField
            label="Reason"
            readOnly
            value={
              advanceInPrinciple.valuation.reasonForAdvanceOfferValuation?.label
            }
          />
        </LeafCell>

        <LeafCell width={1}>
          <NoSubmitDatePicker
            {...fieldProps("dateCommunicatedToSeller")}
            label="Date AIP communicated to seller"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitRadioButtons
            data-test="advance-in-principle-does-customer-intend-to-convert"
            id="advance-in-principle-does-customer-intend-to-convert"
            label="Does customer intend to convert to advance offer?"
            onSubmit={() => undefined}
            options={[
              { label: "No", value: "NO" },
              { label: "Maybe", value: "MAYBE" },
              { label: "Yes", value: "YES" },
            ]}
            readOnly
            value={advanceInPrinciple.doesCustomerIntendToConvert}
          />
        </LeafCell>
        <LeafCell width={1} />

        {isReallyEditing && (
          <>
            <LeafCell width={1}>
              <ExtendedMutation
                mutation={UPDATE_ADVANCE_IN_PRINCIPLE}
                awaitRefetchQueries
                refetchQueries={[
                  { query: advancesSectionQuery, variables: { dealId } },
                ]}
              >
                {(
                  updateAdvanceInPrinciple: MutationFunction<UpdateAdvanceInPrinciple>,
                ) => {
                  const update = async () => {
                    const result = await updateAdvanceInPrinciple({
                      variables: {
                        input: omit(
                          ["__typename", "valuation", "advanceOffer"],
                          advanceInPrinciple,
                        ),
                      },
                    });

                    if (result) {
                      this.toggleIsEditing();
                      onSubmit();
                    }
                  };

                  return (
                    <SaveButton
                      data-test="advance-section:new-entry:save-button"
                      disabled={!haveAllRequiredFields}
                      onClick={update}
                    >
                      Save
                    </SaveButton>
                  );
                }}
              </ExtendedMutation>
            </LeafCell>
            <LeafCell width={1}>
              <FullWidthCancelButton
                data-test="advance-section:new-entry:cancel-button"
                onClick={() => {
                  onCancel();
                  this.toggleIsEditing();
                }}
              >
                Cancel
              </FullWidthCancelButton>
            </LeafCell>
            <LeafCell width={1} />
          </>
        )}

        {/* This just positions the delete button nicely to the right */}
        {!isReallyEditing && <LeafCell width={3} />}

        <ExtendedMutation
          awaitRefetchQueries
          mutation={DELETE}
          refetchQueries={[
            { query: advancesSectionQuery, variables: { dealId } },
          ]}
          variables={{ id: advanceInPrinciple.id }}
        >
          {(
            deleteAdvanceInPrinciple: MutationFunction<DeleteAdvanceInPrinciple>,
          ) => (
            <AlignRight>
              <DeleteListEntry
                data-test={`advance-section:delete-existing-entry:${advanceInPrinciple.id}`}
                onSubmit={() => {
                  const message =
                    "WARNING: This will delete this Advance in principle and any attached ADVANCE OFFERS and ADVANCE DRAWDOWN REQUESTS. Please ONLY delete if you added this proposal mistakenly, any rejected/retracted advances should NOT be deleted.";
                  if (itemDeletionConfirmed(message)) {
                    deleteAdvanceInPrinciple(advanceInPrinciple.id);
                  }
                }}
              />
            </AlignRight>
          )}
        </ExtendedMutation>
      </Grid>
    );
  }
}

// We set the display name so that we can still switch on the component name in prod builds
AdvanceInPrinciple.displayName = "AdvanceInPrinciple";
