// @flow
import { useEffect, forwardRef } from "react";
import { Field, useForm } from "react-final-form";
import { css } from "styled-components";
import { gql, useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { isValidEmail } from "@nested/utils";
import { TextInput } from "./TextInput";

export const EMAIL_VALIDATION_MUTATION = gql`
  mutation OnboardingValidateEmailAddress($email: String!) {
    validateEmailAddress(email: $email) {
      valid
    }
  }
`;

export const errorMessageStyle = css`
  position: absolute;
  bottom: -18px;
  color: ${({ theme }) => theme.palette.terracotta150};
`;

const emailValidatingStyle = css`
  position: absolute;
  right: 0;
  top: 4px;
`;

type Props = {
  className?: string,
  fieldName: string,
  isOpen: boolean,
  label?: string,
  placeholder?: string,
  "data-test"?: ?string,
};

const ERROR_MESSAGE = "Please enter a valid email address";

export const EmailInputWithValidation = forwardRef<
  Props,
  React$ElementRef<"input"> | null,
>(
  (
    {
      className = "",
      fieldName,
      isOpen,
      label,
      placeholder,
      "data-test": dataTest,
    },
    forwardedRef,
  ) => {
    const form = useForm();
    const [validateEmail, { loading }] = useMutation(EMAIL_VALIDATION_MUTATION);

    useEffect(() => {
      form.restart();
    }, [isOpen]);

    const validateEmailAddress = async (email, allValues, meta) => {
      // Prevent repeat validation if input was already validated once, unless currently being edited
      if (
        meta?.valid &&
        !meta.active &&
        // `meta.initial` is equal to undefined when adding a new seller in the Nest.
        // `meta.initial` is equal to the buyer email when converting a buyer to a seller in the Nest.
        (meta.dirty || (meta.initial !== undefined && meta.pristine))
      )
        return undefined;

      if (!isValidEmail(email || "")) {
        return ERROR_MESSAGE;
      }

      try {
        const { data } = await validateEmail({
          variables: { email },
        });
        if (data?.validateEmailAddress?.valid) {
          return undefined;
        }
        if (data?.validateEmailAddress?.valid === false) {
          return ERROR_MESSAGE;
        }
        return ERROR_MESSAGE;
      } catch (e) {
        errorHandler(e);
        return undefined;
      }
    };

    return (
      <div css="position: relative;" className={className}>
        <Field name={fieldName} validate={validateEmailAddress}>
          {({ input, meta }) => {
            return (
              <div css="position: relative">
                <TextInput
                  {...input}
                  className="fs-exclude"
                  valid={meta.valid && !loading}
                  label={label}
                  placeholder={placeholder}
                  data-test={dataTest}
                  forwardedRef={forwardedRef}
                />
                {(meta.validating || loading) && (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    css={emailValidatingStyle}
                  />
                )}
                {meta.invalid && meta.touched && (
                  <span css={errorMessageStyle}>{meta.error}</span>
                )}
              </div>
            );
          }}
        </Field>
      </div>
    );
  },
);
