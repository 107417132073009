// @flow
/**
 * This component adds error handling to mutations by default.
 */
import type { Node } from "react";
import { Mutation } from "@apollo/client/react/components";
import type {
  ApolloError,
  MutationFunction,
  MutationResult,
  MutationRenderPropFunction,
  MutationUpdaterFn,
  RefetchQueriesProviderFn,
  RefetchQueryDescription,
} from "@apollo/client";
import { errorHandler } from "./errorHandler";

type DocumentNode = any;

export type {
  MutationFunction,
  MutationResult,
} from "@apollo/client/react/components";

export function ExtendedMutation<TData, TVariables>({
  children,
  ...mutationProps
}: {|
  children: MutationRenderPropFunction<TData, TVariables>,
  mutation: DocumentNode,
  variables?: TVariables,
  update?: MutationUpdaterFn<TData>,
  ignoreResults?: boolean,
  optimisticResponse?: Object,
  awaitRefetchQueries?: boolean,
  refetchQueries?: RefetchQueryDescription | RefetchQueriesProviderFn,
  onCompleted?: (data: TData) => mixed,
  onError?: (error: ApolloError) => mixed,
  context?: { [string]: any },
|}): Node {
  return (
    <Mutation {...mutationProps}>
      {(
        mutate: MutationFunction<TData, TVariables>,
        result: MutationResult<TData>,
      ) => {
        function mutateWithErrorHandling(...args) {
          return mutate(...args).catch(errorHandler);
        }

        return children(mutateWithErrorHandling, result);
      }}
    </Mutation>
  );
}
