// @flow
import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { useMutation } from "@apollo/client/react/hooks";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Grid, LeafCell } from "components/Grid/Grid";
import { PrimaryButton } from "components/Buttons";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { H2 } from "components/Heading/index";
import { NoSubmitSelectField } from "components/SelectField";
import { Delete, Tick } from "@nest-ui/icons";
import { AddressPicker } from "./AddressPicker";
import { LIST_BUYERS_REPORT } from "../ReportList";

export const required = (value: ?boolean) => (value ? undefined : "Required");

export const FormError = styled.p`
  color: red;
  font-weight: 500;
`;

const Wrapper = styled.div`
  padding: 16px;
`;

type Props = {
  onClose: () => void,
  customerAccountId: string,
};

const CREATE_BUYERS_REPORT = gql`
  mutation CreateBuyersAgentReport($input: CreateBuyersAgentReportInput!) {
    createBuyersAgentReport(input: $input) {
      success
    }
  }
`;

export const agentDetailsArray = [
  { label: "Ashley Weekes", value: "ashley@nested.com" },
  { label: "Emily Askham", value: "emily.askham@nested.com" },
];

const createSubmitHandler =
  (mutation, customerAccountId, onClose, agentEmail) => async (state) => {
    const { address } = state;

    if (!agentEmail)
      return {
        [FORM_ERROR]: "Buyers report requires an agent name",
      };

    const input = {
      customerAccountId: parseInt(customerAccountId, 10),
      agentName: agentDetailsArray.filter(
        (agent) => agent.value === agentEmail,
      )[0].label,
      agentEmail,
      ...address,
      ...state.address,
    };
    try {
      const result = await mutation({
        variables: {
          input,
        },
        optimisticResponse: {
          createBuyersAgentReport: {
            __typename: "BuyersAgentReportResult",
            success: true,
          },
        },
        refetchQueries: [
          {
            query: LIST_BUYERS_REPORT,
            variables: {
              customerAccountId,
            },
          },
        ],
      });
      if (result?.data?.createBuyersAgentReport?.success) {
        onClose();
      }
      return undefined;
    } catch (e) {
      return {
        [FORM_ERROR]: "Failed to create buyers report, please try again later",
      };
    }
  };

export const CreateBuyersReport = ({ customerAccountId, onClose }: Props) => {
  const [createBuyersReport] = useMutation(CREATE_BUYERS_REPORT, {
    awaitRefetchQueries: true,
  });

  const [agentEmail, setAgentEmail] = useState("");
  return (
    <Form
      onSubmit={createSubmitHandler(
        createBuyersReport,
        customerAccountId,
        onClose,
        agentEmail,
      )}
    >
      {({
        handleSubmit,
        submitError,
        hasSubmitErrors,
        hasValidationErrors,
        submitting,
        pristine,
      }) => (
        <Wrapper>
          <H2 data-test="new-report-heading">New Report</H2>
          <Grid columns={4}>
            {!pristine && hasValidationErrors && (
              <LeafCell width={4}>Validation error</LeafCell>
            )}
            {hasSubmitErrors && (
              <LeafCell width={4}>
                <FormError data-test="create-report-error">
                  {submitError}
                </FormError>
              </LeafCell>
            )}
            <LeafCell width={2}>
              <Field validate={required} name="address">
                {({ input, meta }) => (
                  <AddressPicker
                    label="Property address"
                    {...input}
                    {...meta}
                  />
                )}
              </Field>
            </LeafCell>
            <LeafCell width={2}>
              <NoSubmitSelectField
                data-test="agent-name"
                label="Agent name"
                options={agentDetailsArray}
                onSubmit={(value) => setAgentEmail(value)}
                value={agentEmail}
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <PrimaryButton
                data-test="create-report-save-button"
                onClick={handleSubmit}
                icon={Tick}
                disabled={pristine || hasValidationErrors || submitting}
                label="Save"
              />
            </LeafCell>
            <LeafCell width={1} left={4}>
              <ButtonWithIcon
                data-test="create-report-cancel"
                icon={Delete}
                onSubmit={onClose}
              />
            </LeafCell>
          </Grid>
        </Wrapper>
      )}
    </Form>
  );
};
