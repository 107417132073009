// @flow
import { useRef } from "react";
import { css } from "styled-components";
import { media } from "@nested/brand";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { TextInput } from "../TextInput/TextInput";
import { DatePickerStyles } from "./datePickerStyles";
import { prettyFormat } from "../../pages/Portfolio/utils";

const backgroundTint = css`
  background: ${({ theme }) => theme.palette.hague150};
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  ${media.tablet`
    display: none;
  `}
`;

const wrapper = css`
  z-index: 3;
  width: calc(100vw - 20px);
  position: fixed;
  left: 10px;
  bottom: 10px;
  right: -10px;
  border-radius: 10px;
  padding: 10px;
  ${media.tablet`
    padding: 0;
    width: 200px;
    top: 1px;
    position: relative;
    border-radius: 5px;
  `};
`;

const tabletOnly = css`
  display: none;
  ${media.tablet`
    display: block;
  `}
`;

const labelStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
  font-weight: 500;
  margin: 0 0 5px 0;
`;

export const formatDate = (dateInput: ?Date) => {
  if (dateInput) {
    const { years } = moment(dateInput).toObject();
    if (years > 9999) {
      const truncYears = years.toString().slice(0, 4);
      return moment(dateInput)
        .year(parseInt(truncYears, 10))
        .format("YYYY-MM-DD");
    }
    return moment(dateInput).format("YYYY-MM-DD");
  }
  return dateInput;
};

const formatForDisplay = (date: ?Date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return null;
};

type Props = {
  className?: string,
  clearable?: boolean,
  customInput?: React.Element,
  dataTest?: string,
  disabled?: boolean,
  filterDate?: ?(date: Date) => boolean,
  label?: string,
  onSubmit: (date: Date) => void | Promise<void>,
  placeholder?: string,
  readOnly?: boolean,
  value: string,
};

export const DatePicker = ({
  className,
  clearable = false,
  customInput: CustomInput,
  dataTest = "date-picker",
  disabled = false,
  filterDate = () => true,
  label,
  onSubmit,
  placeholder = "dd/mm/yyyy",
  readOnly = false,
  value,
}: Props) => {
  const picker = useRef(null);
  const dateValue =
    value === undefined || !moment(value).isValid()
      ? undefined
      : moment.utc(value).toDate();

  const onClose = () => {
    picker.current?.cancelFocusInput();
    picker.current?.setOpen(false);
  };

  const DatePickerContainer = ({
    className: cn,
    children,
    arrowProps = {},
  }) => {
    return (
      <>
        <div css={backgroundTint} role="presentation" onClick={onClose}></div>
        <div className={cn} css={wrapper}>
          <div
            className="react-datepicker__triangle"
            {...arrowProps}
            css={tabletOnly}
          />
          <div css="position: relative;">{children}</div>
        </div>
      </>
    );
  };

  return (
    <div css="display: block;">
      {label && (
        <p css={labelStyle} data-test={`${dataTest}:label`}>
          {label}
        </p>
      )}
      {readOnly || disabled ? (
        <TextInput
          dataTest={dataTest}
          css="& input { cursor: not-allowed; }"
          className={className}
          disabled
          value={formatForDisplay(dateValue)}
          onChange={() => {}}
          title={prettyFormat(value)}
        />
      ) : (
        <div data-test={dataTest} title={prettyFormat(value)}>
          <DatePickerStyles />
          <ReactDatePicker
            ref={picker}
            calendarContainer={DatePickerContainer}
            calendarClassName="nest-date-picker"
            customInput={
              // $FlowFixMe - TextInput gets the required props added internally
              CustomInput ? <CustomInput /> : <TextInput />
            }
            className={className}
            dateFormat="dd/MM/yyyy"
            filterDate={filterDate}
            isClearable={clearable}
            selected={dateValue}
            onChange={(dateInput) => {
              const formattedDate = dateInput && formatDate(dateInput);
              return onSubmit(formattedDate);
            }}
            onClickOutside={onClose}
            placeholderText={placeholder}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            calendarStartDay={1}
          />
        </div>
      )}
    </div>
  );
};
