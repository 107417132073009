// @flow
import { gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { LegacyLegalServices } from "./LegacyLegalServices/LegacyLegalServices";
import { AdvancesForAllMarch2019LegalServices } from "./AdvancesForAllMarch2019LegalServices/AdvancesForAllMarch2019LegalServices";

export const LEGAL_SERVICES_TAB_QUERY = gql`
  query LegalServicesTabQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      dealType {
        value
        label
      }
    }
  }
`;

export const LegalServices = () => {
  const id = String(useParams()?.id);
  return (
    <ExtendedQuery query={LEGAL_SERVICES_TAB_QUERY} variables={{ id }}>
      {({
        nestDeal: { dealType },
      }: ExtendedQueryRenderProps<LegalServicesTabQuery>) => {
        switch (dealType?.value) {
          case "advances_for_all_march2019":
            return <AdvancesForAllMarch2019LegalServices dealId={id} />;
          default:
            return <LegacyLegalServices dealId={id} />;
        }
      }}
    </ExtendedQuery>
  );
};
