// @flow
import { css } from "styled-components";
import { getConfig } from "@nested/config";
import { getImage } from "@nested/utils/src/imgix/imgix";

const { IMGIX_URI } = getConfig();

const emailPropertyCardStyle = css`
  width: 100%;
`;

const iconsStyle = css`
  width: 24px;
`;

const iconNumberStyle = css`
  font-size: 12px;
  color: ${({ theme }) => theme.color.text.hague};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-right: 15px;
  margin-left: 8px;
`;

const priceStyle = css`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  line-height: 14px;
  color: ${({ theme }) => theme.color.text.hague70};
  margin-top: 0;
`;
const addressStyle = css`
  font-size: 16px;
  color: #1871cf;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 24px;
  margin-bottom: 0;
`;

const listItemBulletStyle = css`
  color: #ff8770;
  line-height: 24px;
  font-size: 16px;
`;

const listItemStyle = css`
  color: ${({ theme }) => theme.color.text.hague70};
  line-height: 24px;
  font-size: 16px;
`;

const viewingButtonStyle = css`
  color: white;
  border: none;
  background-color: ${({ theme }) => theme.color.text.hague};
  border-radius: 4px;
  padding: 10px;
  display: block;
  width: 100%;
  margin-top: 15px;
  font-weight: 500;
  letter-spacing: 1px;
`;

type Props = {
  propertyDetails: {
    externalId: string | number,
    address: { street: string, borough: string, outcode: string },
    priceQualifier: ?string,
    price: number,
    bedrooms: number,
    bathrooms?: number,
    keyFeatures: string[],
  },
  withKeyFeatures?: boolean,
};

export const EmailPreviewPropertyCard = ({
  propertyDetails: {
    externalId,
    address: { street, borough, outcode },
    priceQualifier,
    price,
    bedrooms,
    bathrooms,
    keyFeatures,
  },
  withKeyFeatures = true,
}: Props) => {
  return (
    <div css="margin-bottom: 16px">
      <img
        src={`${IMGIX_URI}/property-listings/${externalId}/1`}
        css={emailPropertyCardStyle}
        alt="property card"
      />
      <p
        data-test={`test-email-preview-address-${externalId}`}
        css={addressStyle}
      >
        {street}, {borough}, {outcode}
      </p>
      <p
        css={priceStyle}
        data-test={`test-email-preview-property-price-${externalId}`}
      >
        {priceQualifier ? `${priceQualifier} ${price}` : `${price}`}
      </p>
      <div css="display: flex">
        <div css="display: flex; align-items: center;">
          <img
            css={iconsStyle}
            src={getImage("email/cross_selling/bed.png")}
            alt="bed"
          />
          <span css={iconNumberStyle}>{bedrooms}</span>
        </div>
        {bathrooms && (
          <div css="display: flex; align-items: center">
            <img
              css={iconsStyle}
              src={getImage("email/cross_selling/bath.png")}
              alt="bath"
            />
            <span css={iconNumberStyle}>{bathrooms}</span>
          </div>
        )}
      </div>
      {withKeyFeatures && (
        <ul css="padding-left: 30px">
          {keyFeatures.map((feature, index) => (
            <li css={listItemBulletStyle} key={`key-feature-${index}`}>
              <span css={listItemStyle}>{feature}</span>
            </li>
          ))}
        </ul>
      )}
      <button css={viewingButtonStyle}>Request a viewing</button>
    </div>
  );
};
