// @flow
export type NurtureListNurtureHotness =
  | AllNurtureListViewQuery_allNurtureDeals_paginatedResults_results_nurtureHotness
  | NoTaskSetNurtureListViewQuery_noTaskSetNurtureDeals_paginatedResults_results_nurtureHotness;

export const NURTURE_VIEW_LIMIT = 25;

export const MOBILE_SORT_OPTIONS = [
  { label: "Enquired", value: "CREATED_AT" },
  { label: "Seller name", value: "SELLER_NAME" },
  { label: "Last outbound call", value: "LAST_OUTBOUND_CALL" },
  { label: "Hotness", value: "NURTURE_HOTNESS" },
];
