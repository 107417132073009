// @flow
import { Route, Switch } from "react-router-dom";
import { Tabs, Tab } from "../../../components/Navigation/Tabs";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../../components/Navigation/Breadcrumbs";
import { TodaysFeedbackListView } from "./TodaysFeedbackListView";
import { UpcomingFeedbackListView } from "./UpcomingFeedbackListView";
import { useListViewCounts } from "../../../hooks/useListViewCounts";
import { ListViewPageWrapper } from "../../../components/ListViewSharedStyles";

export const Feedback = () => {
  const { awaitingFeedback, upcomingAwaitingFeedback } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Buyers</Breadcrumb>
        <Breadcrumb to="#">Feedback</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/buyers/feedback" exact count={awaitingFeedback}>
          Today
        </Tab>
        <Tab to="/buyers/feedback/upcoming" count={upcomingAwaitingFeedback}>
          Upcoming
        </Tab>
      </Tabs>
      <Switch>
        <Route exact path="/buyers/feedback">
          <TodaysFeedbackListView />
        </Route>
        <Route path="/buyers/feedback/upcoming">
          <UpcomingFeedbackListView />
        </Route>
      </Switch>
    </ListViewPageWrapper>
  );
};
