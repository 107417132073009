import { Sentry } from "@nested/isomorphic-sentry";

export const logException = (ex, context) => {
  if (ex instanceof Error) {
    Sentry.captureException(ex, {
      extra: context,
    });
  } else {
    Sentry.captureMessage(JSON.stringify(ex), {
      extra: context,
    });
  }

  if (process.env.NODE_ENV === "production") {
    // context might have some data we don't want leaking out in production so we just log the exception
    // eslint-disable-next-line no-console
    console.error(ex);
  } else {
    // eslint-disable-next-line no-console
    console.log(ex, { context });
  }
};
