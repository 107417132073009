// @flow
import { useEffect, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faPhone,
  faEnvelope,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { formatPrice, getImage } from "@nested/utils";
import { useListViewCounts } from "../../../hooks/useListViewCounts";
import { FloatingAgent } from "../../../components/FloatingAgent";
import { ListViewError } from "../../../components/ListViewError";
import { NoResults } from "../../../components/NoResults";
import { PlaceholderTable } from "../../../components/Placeholder";
import { Table, TH, TD } from "../../../components/Table";
import { MobileLoader } from "../../../components/MobileListViewDeal";
import {
  mobileWrapperStyle,
  tabletWrapperStyle,
  dealsLoadingStyle,
} from "../../../components/ListViewSharedStyles";
import { groupEnquiriesByDeal, noBorderBpi } from "../listViewHelpers";

export const ENQUIRIES_QUERY = gql`
  query EnquiriesListViewQuery($email: String!) {
    enquiries(email: $email) {
      results {
        id
        deal {
          id
          externalId
          address
          currentListPrice
        }
        buyer {
          id
          name
          avatarUrl
        }
        postEnquiryContactSummary {
          dayNumber
          call
          email
          sms
        }
      }
    }
  }
`;

// mobile styles

const dealWrapper = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  padding-bottom: 20px;
`;

const dealHeading = css`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 18px;
  margin-bottom: 20px;
`;

const addressStyle = css`
  font-weight: 500;
`;

const bpiWrapper = css`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const buyerDetailsWrapper = css`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 18px;
`;

const buyerNameStyle = css`
  font-weight: 500;
  flex-grow: 1;
`;

const dayNumberStyle = css`
  font-weight: 400;
  flex-grow: 1;
  color: ${({ theme }) => theme.palette.hague70};
`;

const contactSummaryAndChevronWrapper = css`
  display: flex;
`;

const contactSummaryStyle = css`
  display: flex;
  width: 50px;
  justify-content: space-between;
  margin-right: 15px;
  align-items: center;
  padding-bottom: 2px;
`;

const chevronStyle = css`
  flex-grow: 0;
  color: ${({ theme }) => theme.palette.hague40};
`;

// tablet/desktop styles

const buyerNameCellStyle = css`
  padding-left: 20px;
  border-left: 1px solid ${({ theme }) => theme.palette.hague20};
  vertical-align: top;
`;

const buyerLinkStyle = css`
  font-weight: 500;
  padding: 15px 20px 15px 0;
  display: block;
  padding-top: ${({ first }) => (first ? "15px" : "7.5px")};
  padding-bottom: ${({ last }) => (last ? "15px" : "7.5px")};
`;

const propertyLinkStyle = css`
  ${buyerLinkStyle}
  padding-top: 0;
  font-weight: 400;
`;

const tableCellStyle = css`
  padding: 15px 15px 15px 0;
  vertical-align: top;
`;

const contactDayStyle = css`
  display: block;
  padding-top: ${({ first }) => (first ? "15px" : "7.5px")};
  padding-bottom: ${({ last }) => (last ? "15px" : "7.5px")};
  padding-left: 35px;
`;

const iconHeadingStyle = css`
  padding-right: 11px;
  padding-left: 11px;
  color: ${({ theme }) => theme.palette.hague70};
  width: 3.3%;
`;

const contactDotCellStyle = css`
  display: block;
  padding-top: ${({ first }) => (first ? "18px" : "10.5px")};
  padding-bottom: ${({ last }) => (last ? "15px" : "7.5px")};
  padding-left: 11px;
  width: 100%;
`;

// shared styles

const imageStyle = css`
  width: 60px;
  margin-right: 15px;
  border-radius: 2px;
`;

export const contactDotStyle = css`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme, completed }) =>
    completed ? theme.palette.green100 : theme.palette.terracotta100};
  border: solid 2px
    ${({ theme, completed }) =>
      completed ? theme.palette.green150 : theme.palette.terracotta150};
`;

export const overdueDotStyle = css`
  ${contactDotStyle}
  background-color: ${({ theme }) => theme.palette.sand70};
  border: solid 2px ${({ theme }) => theme.palette.sand150};
`;

const workflowLink = (bpi) =>
  `/buyers/${bpi.buyer.id}/interests/${bpi.id}/workflows/enquiries`;

const ContactDot = ({ dayNumber, completed }) => {
  if (dayNumber === 4) {
    return <div css={overdueDotStyle} />;
  }

  return <div css={contactDotStyle} completed={completed} />;
};

const DealList = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <MobileLoader />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {results.map(({ deal, bpis }) => (
        <div
          css={dealWrapper}
          data-test={`deal-row-${deal.externalId}`}
          key={deal.externalId}
        >
          <div css={dealHeading}>
            <img
              alt=""
              css={imageStyle}
              src={getImage(`property-listings/${deal.externalId}/1`)}
            />
            <div>
              <div css={addressStyle}>{deal.address}</div>
              <div>{formatPrice(Number(deal.currentListPrice))}</div>
            </div>
          </div>
          {bpis.map((bpi) => (
            <Link
              data-test={`bpi-row-${bpi.id}`}
              key={bpi.id}
              to={workflowLink(bpi)}
              css={bpiWrapper}
            >
              <div css={buyerDetailsWrapper}>
                <div>
                  <div css={buyerNameStyle}>{bpi.buyer.name}</div>
                  <div css={dayNumberStyle} data-test={`day-number-${bpi.id}`}>
                    Day{" "}
                    {bpi.postEnquiryContactSummary.dayNumber === 4
                      ? "4+"
                      : bpi.postEnquiryContactSummary.dayNumber}
                  </div>
                </div>
                <div css={contactSummaryAndChevronWrapper}>
                  <div css={contactSummaryStyle}>
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.call}
                    />
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.email}
                    />
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.sms}
                    />
                  </div>
                  <div css={chevronStyle}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

const DealTable = ({ loading, results }) => {
  if (loading && results.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      <Table>
        <thead>
          <tr>
            <TH css="width:40%; min-width: 270px;">Property</TH>
            <TH css="width: 15%;">List price</TH>
            <TH css="width: 20%; padding-left: 20px;">Buyer</TH>
            <TH css="width: 10%; padding-left: 20px;">Day</TH>
            {/* call */}
            <TH css={iconHeadingStyle}>
              <FontAwesomeIcon icon={faPhone} />
            </TH>
            {/* email */}
            <TH css={iconHeadingStyle}>
              <FontAwesomeIcon icon={faEnvelope} />
            </TH>
            {/* sms */}
            <TH css={iconHeadingStyle}>
              <FontAwesomeIcon icon={faCommentDots} />
            </TH>
          </tr>
        </thead>
        <tbody>
          {results.map(({ deal, bpis }, resultIndex) =>
            bpis.map((bpi, index) => (
              <tr key={bpi.id}>
                {index === 0 && (
                  <>
                    <TD
                      css={tableCellStyle}
                      rowSpan={bpis.length}
                      noBorder={resultIndex === results.length - 1}
                    >
                      <Link to={workflowLink(bpi)} css={propertyLinkStyle}>
                        <div css="display: flex; flex-direction: row; align-items: flex-start;">
                          <img
                            alt=""
                            css={imageStyle}
                            src={getImage(
                              `property-listings/${deal.externalId}/1`,
                            )}
                          />
                          {deal.address}
                        </div>
                      </Link>
                    </TD>
                    <TD
                      css={tableCellStyle}
                      rowSpan={bpis.length}
                      noBorder={resultIndex === results.length - 1}
                    >
                      <Link to={workflowLink(bpi)} css={propertyLinkStyle}>
                        {formatPrice(Number(deal.currentListPrice))}
                      </Link>
                    </TD>
                  </>
                )}
                <TD
                  css={buyerNameCellStyle}
                  className="fs-exclude"
                  noBorder={noBorderBpi({ index, bpis, resultIndex, results })}
                >
                  <Link
                    css={buyerLinkStyle}
                    to={workflowLink(bpi)}
                    first={index === 0}
                    last={index === bpis.length - 1}
                  >
                    {bpi.buyer.name}
                  </Link>
                </TD>
                <TD
                  noBorder={noBorderBpi({ index, bpis, resultIndex, results })}
                  css="vertical-align: top;"
                >
                  <Link
                    css={contactDayStyle}
                    to={workflowLink(bpi)}
                    first={index === 0}
                    last={index === bpis.length - 1}
                  >
                    {bpi.postEnquiryContactSummary.dayNumber === 4
                      ? "4+"
                      : bpi.postEnquiryContactSummary.dayNumber}
                  </Link>
                </TD>
                <TD
                  noBorder={noBorderBpi({ index, bpis, resultIndex, results })}
                  css="vertical-align: top;"
                >
                  <Link
                    css={contactDotCellStyle}
                    to={workflowLink(bpi)}
                    first={index === 0}
                    last={index === bpis.length - 1}
                  >
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.call}
                    />
                  </Link>
                </TD>
                <TD
                  noBorder={noBorderBpi({ index, bpis, resultIndex, results })}
                  css="vertical-align: top;"
                >
                  <Link
                    css={contactDotCellStyle}
                    to={workflowLink(bpi)}
                    first={index === 0}
                    last={index === bpis.length - 1}
                  >
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.email}
                    />
                  </Link>
                </TD>
                <TD
                  noBorder={noBorderBpi({ index, bpis, resultIndex, results })}
                  css="vertical-align: top;"
                >
                  <Link
                    css={contactDotCellStyle}
                    to={workflowLink(bpi)}
                    first={index === 0}
                    last={index === bpis.length - 1}
                  >
                    <ContactDot
                      dayNumber={bpi.postEnquiryContactSummary.dayNumber}
                      completed={bpi.postEnquiryContactSummary.sms}
                    />
                  </Link>
                </TD>
              </tr>
            )),
          )}
        </tbody>
      </Table>
    </div>
  );
};

export const TodaysEnquiriesListView = () => {
  const { selectedUser } = useUser();
  const { enquiries } = useListViewCounts();

  const {
    previousData,
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery(ENQUIRIES_QUERY, {
    variables: {
      email: selectedUser.email,
    },
  });

  useEffect(() => {
    refetch();
  }, [enquiries]);

  const results = data?.enquiries?.results || [];

  const enquiriesByDeal = useMemo(
    () =>
      groupEnquiriesByDeal(results, (b) => [
        b.postEnquiryContactSummary.dayNumber,
        b.buyer.name,
      ]),

    [results],
  );

  if (!loading && results.length === 0) {
    return (
      <NoResults
        image={null}
        message="Enquiries sorted"
        subtext={
          <p>
            Have you chased for{" "}
            <Link
              css="text-decoration: underline !important;"
              to="/buyers/feedback"
            >
              viewing feedback
            </Link>
            ?
          </p>
        }
      >
        <FloatingAgent />
      </NoResults>
    );
  }

  if (error) {
    return (
      <>
        <ListViewError />
      </>
    );
  }

  return (
    <>
      <div css={mobileWrapperStyle}>
        <DealList loading={loading} results={enquiriesByDeal} />
      </div>
      <div css={tabletWrapperStyle}>
        <DealTable loading={loading} results={enquiriesByDeal} />
      </div>
    </>
  );
};
