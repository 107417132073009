import debtFundingCommitmentQuery from "../queries/debtFundingCommitments.graphql";

export const createConfig = {
  props: ({ mutate, ownProps: { dealId } }) => ({
    createDebtFundingCommitment: () =>
      mutate({
        variables: {
          id: dealId,
        },
        refetchQueries: [
          {
            query: debtFundingCommitmentQuery,
            variables: {
              id: dealId,
            },
          },
        ],
      }),
  }),
};
