// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";

import { Card } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { Heading } from "components/Heading";

import { Button } from "components/Buttons";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { NestDealFields } from "./NestDealFields";
import { PropertyFields } from "./PropertyFields";
import { KeyFeatures } from "./KeyFeatures";
import { PropertySummary } from "./PropertySummary";

const ButtonLink = styled(Button)`
  text-decoration: none;
  display: inline-block;
  margin: 0;
`;

const query = gql`
  query PropertyInformationSectionQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      property {
        id
      }
    }
  }
`;

type Props = {
  parentId: string,
};

export const PropertyInformationSectionFields = ({
  parentId: dealId,
}: Props) => (
  <ExtendedQuery query={query} variables={{ dealId }}>
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<PropertyInformationSectionQuery>) => (
      <Grid columns={4} style={{ marginTop: "16px" }}>
        <NestDealFields dealId={dealId} />
        <PropertyFields propertyId={nestDeal.property.id} />
      </Grid>
    )}
  </ExtendedQuery>
);

const LISTING_QUERY = gql`
  query ListingQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      currentListingUrl
      calculatedFloorplanUrl
    }
  }
`;

export const PropertyInformationSection = ({ parentId: dealId }: Props) => (
  <section id="property_information">
    <Heading id="Property-Information">Property Information</Heading>
    <Card>
      <PropertyInformationSectionFields parentId={dealId} />

      <ExtendedQuery query={LISTING_QUERY} variables={{ dealId }}>
        {({ nestDeal }: ExtendedQueryRenderProps<ListingQuery>) => (
          <Grid columns={4} style={{ marginTop: "16px" }}>
            <LeafCell width={1}>
              <ButtonLink
                as="a"
                data-test="property_information:current_listing_url"
                disabled={!nestDeal.currentListingUrl}
                href={nestDeal.currentListingUrl}
                rel="noopener"
                target="_blank"
              >
                Current listing
              </ButtonLink>
            </LeafCell>
            <LeafCell width={1}>
              <ButtonLink
                as="a"
                data-test="property_information:calculated_floorplan_url"
                disabled={!nestDeal.calculatedFloorplanUrl}
                href={nestDeal.calculatedFloorplanUrl}
                rel="noopener"
                target="_blank"
              >
                Floorplan
              </ButtonLink>
            </LeafCell>
          </Grid>
        )}
      </ExtendedQuery>
      <KeyFeatures dealId={dealId} />
      <PropertySummary dealId={dealId} />
    </Card>
  </section>
);
