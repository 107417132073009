// @flow

import { isEmpty } from "lodash";
import { getConfig } from "@nested/config";
import { isValidEmail } from "@nested/utils";
import { type ValidationErrors } from "../components/MultiStageForm";

const { GRAPHQL_URI } = getConfig();

export type Validator = (any) => Promise<ValidationErrors>;

/*
 * Using a tagged template called 'gql' means apollo will add it to our CI checks and validate
 * it against the schema, because the parser looks for all tagged template literals with the name
 * gql.
 * We're returning a string here instead of using graphql-tag because graphql-tag returns a Document
 * object which apollo-client then handles, whereas we just want a string to pass into fetch.
 */
const gql = (strings) => strings.join("");

const EMAIL_VALIDATION_MUTATION = gql`
  mutation OnboardingValidateEmailAddress($email: String!) {
    validateEmailAddress(email: $email) {
      valid
    }
  }
`;

export const validatePresence =
  (field: string, message?: string = "Required"): Validator =>
  async (value) => {
    if (typeof value === "number") {
      return {};
    }
    if (isEmpty(value)) {
      return {
        [field]: message,
      };
    }
    return {};
  };

export const validateEmail =
  (
    field: string,
    message?: string = "Please enter a valid email address",
  ): Validator =>
  async (value) => {
    if (typeof value !== "string") {
      return {};
    }

    if (!isValidEmail(value)) {
      return {
        [field]: message,
      };
    }

    try {
      const response = await fetch(GRAPHQL_URI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: EMAIL_VALIDATION_MUTATION,
          variables: { email: value },
        }),
      });
      const json = await response.json();
      if (json?.data?.validateEmailAddress?.valid !== true) {
        return {
          [field]: message,
        };
      }
    } catch (error) {
      // If validation fails because of an error, let them continue
      return {};
    }

    return {};
  };

export const validateLength =
  (
    field: string,
    min: number,
    max: number,
    message?: string = "Is invalid",
  ): Validator =>
  async (value) => {
    if (typeof value !== "string") {
      return {};
    }

    if (value.length < min || value.length > max) {
      return {
        [field]: message,
      };
    }

    return {};
  };

export const validateWithinRange =
  (
    field: string,
    min: number,
    max: number,
    message?: string = "Is invalid",
  ): Validator =>
  async (value) => {
    if (typeof value !== "number") {
      return {};
    }

    if (value < min || value > max) {
      return {
        [field]: message,
      };
    }

    return {};
  };

export const composeValidators =
  (...validators: Array<Validator>): Validator =>
  async (value) => {
    let errors = {};
    for (let i = 0; i < validators.length; i += 1) {
      const result = await validators[i](value); // eslint-disable-line no-await-in-loop
      errors = {
        ...result,
        ...errors,
      };
    }
    return errors;
  };
