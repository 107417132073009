import { Route } from "react-router-dom";
import { Comparables } from "components/Comparables/Comparables";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { Reports } from "./Reports/Reports";

// Copy of the BuyersAgentPage but without the nav and different routes, designed to be shown under a deal
export const DealBuyersAgentPage = ({
  match: {
    params: { customerAccountId },
  },
}) => (
  <TabWrapper css="display: block;">
    <TabContainer>
      <Route
        path={"/deals/:id/buyers-agent/:customerAccountId"}
        render={() => <Reports customerAccountId={customerAccountId} />}
      />
      <Route
        path={
          "/deals/:id/buyers-agent/:customerAccountId/report/:reportId/comparables"
        }
        render={({
          match: {
            params: { id, reportId },
          },
        }) => (
          <Comparables
            closeUrl={`/deals/${id}/buyers-agent`}
            sourceType="BUYERS_AGENT_REPORT"
            sourceId={reportId}
          />
        )}
      />
    </TabContainer>
  </TabWrapper>
);
