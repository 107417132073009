import thirdPartyAppraisalsQuery from "../queries/thirdPartyAppraisals.graphql";

export const createConfig = {
  props: ({ mutate, ownProps }) => ({
    createThirdPartyAppraisal: () =>
      mutate({
        variables: {
          id: ownProps.parentId,
        },
        refetchQueries: [
          {
            query: thirdPartyAppraisalsQuery,
            variables: {
              dealId: ownProps.parentId,
            },
          },
        ],
      }),
  }),
};
