import { gql } from "@apollo/client";

export const CREATE_PRE_VISIT_VALUATION_MUTATION = gql`
  mutation CreatePreVisitValuation(
    $dealId: ID!
    $input: PreVisitValuationInput!
  ) {
    createPreVisitValuation(dealId: $dealId, input: $input) {
      id
      completedBy
      dateCompleted
      estimatedListPriceLowerBound
      estimatedListPriceUpperBound
      nonNullableEstimatedSalePriceLowerBound: estimatedSalePriceLowerBound
      nonNullableEstimatedSalePriceUpperBound: estimatedSalePriceUpperBound
      estimatedSalePriceBestEstimate
      estimatingAdvance
      estimatedAdvanceAmountLowerBound
      estimatedAdvanceOnTrack
      estimatedAdvanceAmountUpperBound
      listPriceBestEstimate
      notes
      vaMaximumListPrice
    }
  }
`;

export const UPDATE_PRE_VISIT_VALUATION_MUTATION = gql`
  mutation UpdatePreVisitValuation(
    $preVisitValuationId: ID!
    $input: PreVisitValuationUpdateInput!
  ) {
    updatePreVisitValuation(
      preVisitValuationId: $preVisitValuationId
      input: $input
    ) {
      id
      notes
    }
  }
`;
