// @flow
import styled from "styled-components";
import { NoSubmitTextField } from "components/TextField";

export const EditableField = styled(NoSubmitTextField)`
  align-self: stretch;
  display: inline-grid;
  label {
    display: none;
  }
  input {
    background-color: ${({ theme }) => theme.textField.background};
    border: 2px solid ${({ theme }) => theme.textField.border};
    font-size: ${({ largeText }) => {
      if (largeText) return "16px";
      return "14px";
    }};
    width: ${({ narrow }) => {
      if (narrow) return "40px";
      return "100%";
    }};
    &::placeholder {
      color: white;
    }
  }
`;

export const DottedList = styled.ul`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

export const DottedListItem = styled.li`
  display: inline-block;
  margin: 0;

  &::after {
    background-color: ${({ theme, lightTheme }) =>
      lightTheme ? theme.color.secondary.highlight : theme.background.listItem};
    border-radius: 100%;
    content: "";
    display: inline-block;
    margin-bottom: 2px;
    margin-left: 10px;
    margin-right: 10px;
    width: 5px;
    height: 5px;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
`;

export const DottedListItemSpaced = styled(DottedListItem)`
  margin-bottom: 5px;
`;
