import { compose, withProps } from "recompose";
import { withFormattedCurrency } from "@nest-ui/hocs";
import { TextField } from "components/TextField";

export const AllowNegativeCurrencyField = compose(
  withProps({
    allowNegative: true,
  }),
  withFormattedCurrency,
)(TextField);
