// @flow
import moment from "moment";
import { gql } from "@apollo/client";
import { UpcomingBuyersListView } from "../UpcomingBuyersListView";

export const UPCOMING_ENQUIRIES_QUERY = gql`
  query UpcomingEnquiriesListViewQuery($email: String!) {
    upcomingEnquiries(email: $email) {
      results {
        id
        deal {
          id
          externalId
          address
          currentListPrice
        }
        buyer {
          id
          name
        }
        snooze {
          id
          snoozedUntil
        }
      }
    }
  }
`;

export const UpcomingEnquiriesListView = () => (
  <UpcomingBuyersListView
    queryField="upcomingEnquiries"
    query={UPCOMING_ENQUIRIES_QUERY}
    emptyMessage="No upcoming enquiries."
    getDueTimestamp={(bpi) =>
      bpi.snooze?.snoozedUntil || moment().add(1, "day")
    }
  />
);
