// @flow
import React from "react";
import { omit } from "ramda";
import { kebabCase } from "lodash";
import { Grid, LeafCell } from "components/Grid";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import { H2 } from "components/Heading";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitPercentageField } from "components/PercentageField/PercentageField";
import { NoSubmitCheckbox } from "components/Checkbox/Checkbox";
import { FullWidthCancelButton } from "./Buttons/FullWidthCancelButton";
import { SaveButton } from "./Buttons/SaveButton";

const UPDATE_ADVANCE_OFFER = gql`
  mutation UpdateAdvanceOffer($input: UpdateAdvanceOfferInput!) {
    updateAdvanceOffer(input: $input) {
      id
    }
  }
`;

const CREATE = gql`
  mutation CreateAdvanceOffer($input: CreateAdvanceOfferInput!) {
    createAdvanceOffer(input: $input) {
      id
    }
  }
`;

const DELETE = gql`
  mutation DeleteAdvanceOffer($id: ID!) {
    deleteAdvanceOffer(id: $id) {
      id
    }
  }
`;

type State = { isEditing: boolean, convertToAdvanceOffer: boolean };

export class AdvanceOffer extends React.Component<*, State> {
  state = {
    isEditing: false,
    convertToAdvanceOffer:
      this.props.hasState || Boolean(this.props.advanceOffer.id) || false,
  };

  setIsEditing = (value: boolean) => {
    this.setState({ isEditing: value });
  };

  toggleIsEditing = () => {
    const value = !this.state.isEditing;
    this.setIsEditing(value);
  };

  setConvertToAdvanceOffer = (value: boolean) => {
    this.setState({ convertToAdvanceOffer: value });
  };

  render() {
    const {
      advanceOffer,
      advancesSectionQuery,
      advanceInPrincipleId,
      updateState,
      onCancel,
      onSubmit,
      contractVersions,
      hasState,
      dealId,
    } = this.props;
    const { isEditing } = this.state;
    const isReallyEditing = isEditing || hasState;
    const showEditButton =
      !isReallyEditing && advanceOffer?.advanceDrawdownRequests?.length <= 0;
    const haveAllRequiredFields = Boolean(
      advanceOffer.advanceFeeCapPercent &&
        advanceOffer.advanceFeeDailyPercent &&
        advanceOffer.amount &&
        advanceOffer.contractVersionId &&
        advanceOffer.dateSentToCustomer &&
        advanceOffer.expiryDate,
    );
    const advanceOfferExists = Boolean(advanceOffer?.id);
    const { convertToAdvanceOffer } = this.state;
    const fieldProps = (key: string) => ({
      "data-test": `advance-offer-${kebabCase(key)}`,
      value: advanceOffer[key],
      readOnly: !isReallyEditing,
      onSubmit: (val) =>
        updateState({
          advanceOffer: {
            ...advanceOffer,
            [key]: val,
          },
        }),
    });

    return (
      // We position the grid because the EditButton below is absolutely positioned
      // and we want that to be absolutely positioned relative to the grid.
      <Grid columns={4} style={{ position: "relative" }}>
        <LeafCell width={4}>
          <H2>Advance offer</H2>
        </LeafCell>

        {showEditButton && (
          <EditButton
            style={{ top: "-8px", right: "-8px" }}
            data-test="aip-section-advance-offer-edit-button"
            onClick={() => this.setIsEditing(true)}
          >
            Edit
          </EditButton>
        )}

        {(!advanceOfferExists || isReallyEditing) && (
          <>
            <LeafCell width={2}>
              <NoSubmitCheckbox
                optionName="Convert to advance offer?"
                parentId={advanceInPrincipleId}
                property="convert-to-advance-offer"
                value={this.state.convertToAdvanceOffer}
                onSubmit={(value) => {
                  if (isReallyEditing) {
                    this.setConvertToAdvanceOffer(value);
                  } else {
                    this.setIsEditing(true);
                    this.setConvertToAdvanceOffer(value);
                  }
                }}
              />
            </LeafCell>
            <LeafCell width={2} />
          </>
        )}

        {(advanceOfferExists || this.state.convertToAdvanceOffer) && (
          <>
            <LeafCell width={2}>
              <NoSubmitSelectField
                {...fieldProps("contractVersionId")}
                label="Contract version (this should match the contract signed in Juro)"
                searchable
                nullable
                options={contractVersions}
              />
            </LeafCell>
            <LeafCell width={1}>
              <NoSubmitDatePicker
                label="Date sent to customer"
                {...fieldProps("dateSentToCustomer")}
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <NoSubmitCurrencyField {...fieldProps("amount")} label="Amount" />
            </LeafCell>
            <LeafCell width={1}>
              <NoSubmitPercentageField
                {...fieldProps("advanceFeeDailyPercent")}
                label="Advance fee (daily %)"
              />
            </LeafCell>
            <LeafCell width={1}>
              <NoSubmitPercentageField
                {...fieldProps("advanceFeeCapPercent")}
                label="Advance fee (cap %)"
              />
            </LeafCell>
            <LeafCell width={1} left={1}>
              <NoSubmitDatePicker
                {...fieldProps("nestedContractSignedDate")}
                label="Advance Nested Contract Signed Date"
              />
            </LeafCell>
            <LeafCell width={1}>
              <NoSubmitDatePicker
                label="Advance Customer Contract Signed Date"
                {...fieldProps("customerContractSignedDate")}
              />
            </LeafCell>
            <LeafCell width={1}>
              <NoSubmitDatePicker
                label="Expiry date"
                {...fieldProps("expiryDate")}
              />
            </LeafCell>
          </>
        )}

        {isReallyEditing && (
          <>
            <LeafCell width={1} left={3}>
              <ExtendedMutation
                awaitRefetchQueries
                mutation={CREATE}
                refetchQueries={[
                  { query: advancesSectionQuery, variables: { dealId } },
                ]}
                variables={{ id: advanceOffer.id }}
              >
                {(createAdvanceOffer: MutationFunction<CreateAdvanceOffer>) => (
                  <ExtendedMutation
                    awaitRefetchQueries
                    mutation={DELETE}
                    refetchQueries={[
                      { query: advancesSectionQuery, variables: { dealId } },
                    ]}
                    variables={{ id: advanceOffer.id }}
                  >
                    {(
                      deleteAdvanceOffer: MutationFunction<DeleteAdvanceOffer>,
                    ) => (
                      <ExtendedMutation
                        mutation={UPDATE_ADVANCE_OFFER}
                        awaitRefetchQueries
                        refetchQueries={[
                          {
                            query: advancesSectionQuery,
                            variables: { dealId },
                          },
                        ]}
                      >
                        {(
                          updateAdvanceOffer: MutationFunction<UpdateAdvanceOffer>,
                        ) => {
                          const deleteTheThing = async () => {
                            const result = await deleteAdvanceOffer({
                              variables: { id: advanceOffer?.id },
                            });
                            if (result) {
                              this.setIsEditing(false);
                              onSubmit();
                            }
                          };

                          const update = async () => {
                            const result = await updateAdvanceOffer({
                              variables: {
                                input: omit(
                                  [
                                    "__typename",
                                    "contractVersion",
                                    "advanceInPrincipleId",
                                    "advanceDrawdownRequests",
                                  ],
                                  advanceOffer,
                                ),
                              },
                            });
                            if (result) {
                              this.setIsEditing(false);
                              onSubmit();
                            }
                          };

                          const create = async () => {
                            const result = await createAdvanceOffer({
                              variables: { input: { ...advanceOffer } },
                            });
                            if (result) {
                              this.setIsEditing(false);
                              onSubmit();
                            }
                          };

                          const saveEdits = async () => {
                            switch (true) {
                              case advanceOfferExists && convertToAdvanceOffer:
                                update();
                                break;
                              case !advanceOfferExists && convertToAdvanceOffer:
                                create();
                                break;
                              case advanceOfferExists && !convertToAdvanceOffer:
                                deleteTheThing();
                                break;
                              case !advanceOfferExists &&
                                !convertToAdvanceOffer:
                                onCancel();
                                break;
                              default:
                                throw new Error("w a t");
                            }
                          };

                          return (
                            <SaveButton
                              data-test="advance-section:new-entry:save-button"
                              disabled={!haveAllRequiredFields}
                              onClick={saveEdits}
                            >
                              Save
                            </SaveButton>
                          );
                        }}
                      </ExtendedMutation>
                    )}
                  </ExtendedMutation>
                )}
              </ExtendedMutation>
            </LeafCell>
            <LeafCell width={1}>
              <FullWidthCancelButton
                data-test="advance-section:new-entry:cancel-button"
                onClick={() => {
                  if (advanceOfferExists) {
                    this.setConvertToAdvanceOffer(true);
                  } else {
                    this.setConvertToAdvanceOffer(false);
                  }
                  this.setIsEditing(false);
                  onCancel();
                }}
              >
                Cancel
              </FullWidthCancelButton>
            </LeafCell>
            <LeafCell width={1} />
          </>
        )}
      </Grid>
    );
  }
}

// We set the display name so that we can still switch on the component name in prod builds
AdvanceOffer.displayName = "AdvanceOffer";
