import T from "prop-types";
import { compose } from "recompose";

import { AlignRight } from "components/AlignRight";
import { ListEntryCard } from "components/Card";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { DatePicker } from "components/DatePicker";
import { Grid, LeafCell } from "components/Grid";
import { CurrencyField } from "components/CurrencyField";
import { graphql } from "deal/utils/graphql";

import deletePropertyListingPriceMutation from "./deleteMutation/deletePropertyListingPriceMutation.graphql";
import updatePropertyListingPriceMutation from "./updateMutation/updatePropertyListingPriceMutation.graphql";
import { deleteConfig } from "./deleteMutation/config";
import { updateConfig } from "./updateMutation/config";

const enhance = compose(
  graphql(deletePropertyListingPriceMutation, deleteConfig),
  graphql(updatePropertyListingPriceMutation, updateConfig),
);

function PropertyListingPriceCardComponent({
  updatePropertyListingPrice,
  deletePropertyListingPrice,
  propertyListingPrice: { amount, startDate },
}) {
  return (
    <ListEntryCard data-test="property-listing-price-list-card">
      <Grid columns={4}>
        <LeafCell>
          <CurrencyField
            data-test="property-listing-price-card-amount"
            label="Price"
            mutation={updatePropertyListingPrice}
            property="amount"
            value={amount}
          />
        </LeafCell>
        <LeafCell>
          <DatePicker
            data-test="property-listing-price-card-start-date"
            label="Date"
            mutation={updatePropertyListingPrice}
            property="startDate"
            value={startDate}
          />
        </LeafCell>
        <LeafCell width={4}>
          <AlignRight>
            <DeleteButton
              data-test="property-listing-price-card-delete"
              onSubmit={() => {
                if (itemDeletionConfirmed()) {
                  deletePropertyListingPrice();
                }
              }}
            />
          </AlignRight>
        </LeafCell>
      </Grid>
    </ListEntryCard>
  );
}

PropertyListingPriceCardComponent.propTypes = {
  updatePropertyListingPrice: T.func,
  deletePropertyListingPrice: T.func,
  propertyListingPrice: T.shape({
    amount: T.string,
    startDate: T.string,
  }),
};

export const PropertyListingPriceCard = enhance(
  PropertyListingPriceCardComponent,
);
