import styled from "styled-components";

const H3 = styled.h3`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  color: #555;
  line-height: 1.33;
  margin-bottom: 12px;
  margin-top: 12px;
  text-transform: uppercase;
`;

export { H3 };
