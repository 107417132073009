// @flow
type Props = { fill?: string };

export const EditIcon = ({ fill = "#8C8C8C" }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g fill={fill} fillRule="nonzero">
      <path d="M10.146 3.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708L6.146 12.56a1.5 1.5 0 0 1-1.06.439H3.5a.5.5 0 0 1-.5-.5v-1.586c0-.398.158-.78.44-1.06l6.706-6.708zm-6 7.415a.5.5 0 0 0-.146.353V12h1.086a.5.5 0 0 0 .353-.146L11.793 5.5 10.5 4.207l-6.354 6.354z" />
      <path d="M12.5 4.793l.94-.94a.5.5 0 0 0 0-.707l-.586-.585a.5.5 0 0 0-.708 0l-.939.939L12.5 4.793zm-2.354-1.647l1.293-1.292a1.5 1.5 0 0 1 2.122 0l.585.585a1.5 1.5 0 0 1 0 2.122l-1.292 1.293a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z" />
    </g>
  </svg>
);

export const Pencil = EditIcon;
