// @flow
import { InfoBlob } from "components/InfoBlob/InfoBlob";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks";

type Props = {
  valuation: *,
  isReadOnly: boolean,
};

export const PostVisitValuationPreDec18 = ({
  valuation,
  isReadOnly,
}: Props) => (
  <Grid columns={6}>
    <LeafCell width={2}>
      <NoSubmitDatePicker
        data-test="post-visit-valuations-pre-dec-18:date-completed"
        label="Valuation Date"
        value={valuation.nullableDateCompleted}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={4}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations-pre-dec-18:valuation-gsheet-url"
        label="Valuation GSheet URL"
        value={valuation.nullableValuationGsheetUrl}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:list-price"
        label="Indicative listing price"
        value={valuation.nullableListPrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:estimated-sale-price"
        label="Estimated Sale Price Best Estimate"
        value={valuation.nullableEstimatedSalePrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:estimated-sale-price-lower-bound"
        label="Estimated Sale Price (Lower Bound)"
        value={valuation.nullableEstimatedSalePriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:estimated-sale-price-upper-bound"
        label="Estimated Sale Price (Upper Bound)"
        value={valuation.nullableEstimatedSalePriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:estimated-advance-lower-bound"
        label="Estimated Advance (lower bound)"
        value={valuation.estimatedAdvanceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="post-visit-valuations-pre-dec-18:estimated-advance-upper-bound"
        label="Estimated Advance Amount (Upper Bound)"
        value={valuation.estimatedAdvanceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations-pre-dec-18:liquidity-assessment-exact"
        label="Liquidity Assessment (estimated days to offer) (exact)"
        value={valuation.nullableLiquidityAssessmentExact}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={6}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations-pre-dec-18:valuation-confidence-comments"
        label="Valuation Confidence Notes"
        value={valuation.nullableValuationConfidenceComments}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={6}>
      <NoSubmitTextareaWithLinks
        data-test="post-visit-valuations-pre-dec-18:notes"
        label="Notes"
        value={valuation.notes}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={4}>
      <InfoBlob>PRE-DEC 2018</InfoBlob>
    </LeafCell>
  </Grid>
);
