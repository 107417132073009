// @flow
import type { MutationOperation } from "@apollo/client";
import expectedDrawdownTimingsQuery from "../queries/expectedDrawdownTimings.graphql";

type OwnProps = {
  parentId: string,
  data: ExpectedDrawdownTimingsQuery,
};

type Props = {
  mutate: MutationOperation<UpdateExpectedDrawdownTimingMutation_updateExpectedDrawdownTiming>,
  ownProps: OwnProps,
};

export const updateExpectedDrawdownTimingConfig = {
  props: ({ mutate, ownProps }: Props) => ({
    updateExpectedDrawdownTiming:
      (
        currentExpectedDrawdownTiming: UpdateExpectedDrawdownTimingMutation_updateExpectedDrawdownTiming,
      ) =>
      (input: UpdateExpectedDrawdownTimingInput) =>
        mutate({
          variables: {
            id: currentExpectedDrawdownTiming.id,
            input,
          },
          refetchQueries: [
            {
              query: expectedDrawdownTimingsQuery,
              variables: {
                id: ownProps.parentId,
              },
            },
          ],
          optimisticResponse: {
            __typename: "Mutation",
            updateExpectedDrawdownTiming: {
              __typename: "ExpectedDrawdownTiming",
              ...currentExpectedDrawdownTiming,
              ...input,
            },
          },
        }),
  }),
};
