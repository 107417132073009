// @flow
import { gql } from "@apollo/client";

import { BUYER_PROPERTY_INTEREST_FRAGMENT } from "./BuyerPropertyInterestFragment";

export const BUYER_FRAGMENT = gql`
  fragment buyerFields on Buyer {
    address
    buyerPropertyInterests {
      id
      ...buyerPropertyInterestFields
    }
    closeIoLeadId
    id
    leadStatus
    name
    notes
    leadSaId
    leadSaUser {
      id: closeUserId
      nestedUserId: id
      fullName
    }
  }

  ${BUYER_PROPERTY_INTEREST_FRAGMENT}
`;
