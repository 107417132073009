import T from "prop-types";
import { path } from "ramda";

import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { CurrencyField } from "components/CurrencyField";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import advanceMay2018Query from "./queries/advanceMay2018.graphql";
import { advanceMay2018QueryConfig } from "./queries/advanceMay2018QueryConfig";

import { updateDealTypeAdvanceMay2018Config } from "./mutations/updateDealTypeAdvanceMay2018Config";
import UpdateDealTypeAdvanceMay2018Mutation from "./mutations/UpdateDealTypeAdvanceMay2018Mutation.graphql";

const AdvanceMay2018Component = ({
  data: queryResult,
  updateDealTypeAdvanceMay2018,
}) => {
  const dealTypeData =
    path(["nestDeal", "dealTypeAdvanceMay2018"], queryResult) || null;
  return (
    dealTypeData && (
      <Grid columns={4}>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceMay2018}
            property="advanceFee"
            data-test="deal-type-advance-may-2018-advance-fee"
            label="Advance fee %"
            value={dealTypeData.advanceFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceMay2018}
            property="agencyFee"
            data-test="deal-type-advance-may-2018-agency-fee"
            label="Agency fee %"
            value={dealTypeData.agencyFee}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceMay2018}
            property="noAdvanceDiscountPercentage"
            data-test="deal-type-advance-may-2018-no-advance-discount-percentage"
            label="Discount for no advance %"
            value={dealTypeData.noAdvanceDiscountPercentage}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceMay2018}
            property="earlyPaymentFeePerDay"
            data-test="deal-type-advance-may-2018-early-payment-fee-per-day"
            label="Early payment fee % (per day)"
            value={dealTypeData.earlyPaymentFeePerDay}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeAdvanceMay2018}
            property="earlyPaymentFeeFreePercent"
            data-test="deal-type-advance-may-2018-early-payment-fee-free-percent"
            label="Early payment fee free %"
            value={dealTypeData.earlyPaymentFeeFreePercent}
          />
        </LeafCell>
        <LeafCell>
          <CurrencyField
            mutation={updateDealTypeAdvanceMay2018}
            property="referralDiscount"
            data-test="deal-type-advance-may-2018-referral-discount"
            label="Referral discount"
            value={dealTypeData.referralDiscount}
          />
        </LeafCell>
      </Grid>
    )
  );
};

AdvanceMay2018Component.propTypes = {
  data: T.object.isRequired,
  updateDealTypeAdvanceMay2018: T.func.isRequired,
};

export const AdvanceMay2018 = compose(
  graphql(advanceMay2018Query, advanceMay2018QueryConfig),
  graphql(
    UpdateDealTypeAdvanceMay2018Mutation,
    updateDealTypeAdvanceMay2018Config,
  ),
)(AdvanceMay2018Component);
