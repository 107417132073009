import moment from "moment-timezone";
import { DatePicker } from "components/DatePicker";

// This is just a DatePicker which also sets the time to midnight before calling the mutation
export const DateTimePicker = ({ property, mutation, onChange, ...rest }) => (
  <DatePicker
    property={property}
    mutation={(data) => {
      const value = data[property];
      if (!value) {
        return mutation(data);
      }

      return mutation({
        [property]: moment(value).toISOString(),
      });
    }}
    {...rest}
  />
);
