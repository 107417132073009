// @flow
import { gql } from "@apollo/client";
import { useState } from "react";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import {
  type MutationFunction,
  type MutationResult,
} from "@apollo/client/react/components";
import styled from "styled-components";

import { EphemeralStateModal } from "@nest-ui/sellers-nest/components/Modal/EphemeralStateModal";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@nested/nest/src/components/Button/Button";
import { NoSubmitSelectField } from "@nest-ui/sellers-nest/components/SelectField";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { BUYER_FRAGMENT } from "../BuyerFragment";

const AVAILABLE_PROPERTIES_QUERY = gql`
  query AvailableProperties($buyerId: ID!) {
    availableProperties(buyerId: $buyerId) {
      value
      label
    }
  }
`;

export const CREATE_BUYER_PROPERTY_INTEREST = gql`
  mutation CreateBuyerPropertyInterest(
    $input: CreateBuyerPropertyInterestInput!
  ) {
    createBuyerPropertyInterest(input: $input) {
      id
      ...buyerFields
    }
  }
  ${BUYER_FRAGMENT}
`;

type Props = {
  createWithDealID: (dealId: string) => Promise<*>,
  isSubmitting: boolean,
  buyerId: string,
};

const LeafCellWithSmallerDropdown = styled(LeafCell)`
  .Select-menu-outer {
    .Select-menu {
      max-height: 120px;
    }
  }
`;

function BaseAddInterestContainer(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDealID, setSelectedDealID] = useState<?string>(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDealID(null);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const submit = async () => {
    if (!selectedDealID)
      throw new Error("Cannot create BuyerPropertyInterest without a deal ID");

    await props.createWithDealID(selectedDealID);

    closeModal();
  };

  return (
    <ErrorBoundary>
      <AddInterestModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        submit={submit}
        selectedDealID={selectedDealID}
        setDealID={setSelectedDealID}
        buyerId={props.buyerId}
        isSubmitting={props.isSubmitting}
      />
      <Button
        buttonStyle="pink"
        onClick={openModal}
        data-test="add-property-button"
        iconPosition="left"
        icon={faPlus}
      >
        Add interest
      </Button>
    </ErrorBoundary>
  );
}

const AddInterestModal = ({
  isModalOpen,
  closeModal,
  submit,
  selectedDealID,
  setDealID,
  buyerId,
  isSubmitting = false,
}) => (
  <EphemeralStateModal
    isOpen={isModalOpen}
    title="Add interest in a property"
    closeModal={closeModal}
    onButtonClick={submit}
    buttonLabel="Add"
    buttonEnabled={selectedDealID !== null}
    data-test="add-property-modal"
    isSubmitting={isSubmitting}
  >
    <Grid columns={1} data-test="add-property-modal">
      <LeafCellWithSmallerDropdown width={1}>
        <ExtendedQuery
          query={AVAILABLE_PROPERTIES_QUERY}
          fetchPolicy="network-only"
          nextFetchPolicy="cache-first"
          variables={{ buyerId }}
        >
          {(data: ExtendedQueryRenderProps<AvailableProperties>) => (
            <NoSubmitSelectField
              label="Address"
              value={selectedDealID}
              data-test="add-property-modal-select-address"
              options={data.availableProperties}
              onSubmit={setDealID}
              searchable
            />
          )}
        </ExtendedQuery>
      </LeafCellWithSmallerDropdown>
    </Grid>
  </EphemeralStateModal>
);

type AddInterestProps = {
  buyerId: string,
};

export const AddInterest = ({ buyerId }: AddInterestProps) => (
  <ExtendedMutation mutation={CREATE_BUYER_PROPERTY_INTEREST}>
    {(
      createBuyerPropertyInterest: MutationFunction<*, *>,
      { loading }: MutationResult<*>,
    ) => {
      const createWithDealID = (dealId) =>
        createBuyerPropertyInterest({
          variables: {
            input: {
              buyerId,
              dealId,
            },
          },
        });
      return (
        <BaseAddInterestContainer
          createWithDealID={createWithDealID}
          isSubmitting={loading}
          buyerId={buyerId}
        />
      );
    }}
  </ExtendedMutation>
);
