import T from "prop-types";
import styled from "styled-components";
import { Tick as IconTick } from "@nest-ui/icons";

const Tick = ({ className, greenMode }) => (
  <div className={className}>
    <IconTick fill={greenMode ? "#10b186" : null} />
  </div>
);

Tick.propTypes = {
  className: T.string,
};

const borderColor = (checked, focused, theme, greenMode) => {
  if (greenMode && checked) {
    return theme.color.secondary.light;
  }
  if (greenMode) {
    return "white";
  }
  if (focused) {
    return theme.color.primary.dark;
  }
  if (checked) {
    return theme.color.primary.highlight;
  }
  return theme.color.secondary.highlight;
};

export const TickBox = styled(Tick)`
  background-color: ${({ checked, theme }) =>
    checked ? theme.color.secondary.accent : "white"};
  border: ${({ checked, focused, theme, greenMode }) =>
    `${theme.input.border.width} solid ${borderColor(
      checked,
      focused,
      theme,
      greenMode,
    )}`};
  border-radius: ${({ theme }) => theme.input.border.radius};
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  svg {
    width: 16px;
    height: 16px;
    display: ${({ checked }) => (checked ? "block" : "none")};
    position: absolute;
    top: -2px;
    left: -2px;
  }
`;

TickBox.propTypes = {
  checked: T.bool,
  focused: T.bool,
};
