// @flow
import { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";

const Dropdown = styled.ul`
  position: absolute;
  right: 0;
  top: 17px;
  width: 158px;
  padding: 0;
  max-height: calc(70vh - 105px);
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
  list-style-type: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: white;
  z-index: 2;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.tablet`
    max-height: 300px;
  `}
}
`;

const Option = styled.li`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  &:first-child {
    margin-top: 0;
  }
`;

const Tick = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.green500};
`;

const Selection = styled.span`
  color: ${({ theme }) => theme.palette.blue170};
  border-bottom: 2px solid ${({ theme }) => theme.palette.blue170};
  font-weight: 500;
`;

const Toggle = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 2px;
`;

const Arrow = styled(FontAwesomeIcon)`
  margin-left: 7px;
`;

const getSelectedText = (selectedOptions, allOptions) => {
  if (selectedOptions.length === 0) return "All";
  const first = allOptions.find(
    (option) => option.value === selectedOptions[0],
  );

  if (selectedOptions.length === 1) return first?.label;

  return `${first?.label || ""} +${selectedOptions.length - 1}`;
};

type MultiSelectDropdownOption = { value: string, label: string };

type Props = {
  defaultOpen?: boolean,
  initialSelections?: Array<string>,
  onSelectionChange: (Array<string>) => void,
  options: Array<MultiSelectDropdownOption>,
};

export const MultiSelectDropdown = ({
  defaultOpen = false,
  initialSelections = [],
  onSelectionChange,
  options,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelections);
  const [showDropdown, setShowDropdown] = useState(defaultOpen);

  const onClick = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(
        selectedOptions.filter((currentValue) => currentValue !== value),
      );
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  useEffect(() => {
    onSelectionChange(selectedOptions);
  }, [selectedOptions]);

  return (
    <>
      <Toggle
        onClick={() => setShowDropdown(!showDropdown)}
        data-test="multi-select-dropdown:toggle"
      >
        Show{" "}
        <Selection>
          {getSelectedText(selectedOptions, options)}
          <Arrow icon={showDropdown ? faCaretUp : faCaretDown} />
        </Selection>
      </Toggle>
      {showDropdown && (
        <Dropdown>
          <Option onClick={() => setSelectedOptions([])}>
            <div>All</div>
            {selectedOptions.length === 0 && <Tick icon={faCheck} />}
          </Option>
          {options.map((option, i) => (
            <Option key={i} onClick={() => onClick(option.value)}>
              <div>{option.label}</div>
              {selectedOptions.includes(option.value) && (
                <Tick icon={faCheck} />
              )}
            </Option>
          ))}
        </Dropdown>
      )}
    </>
  );
};
