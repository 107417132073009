// @flow

import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { Card } from "components/Card/Card";
import { Grid } from "components/Grid";

import { NestedProgressor as NestedProgressorCP } from "tabs/Progression/NestedProgressor";
import { NestedProgressorGuarantee } from "./NestedProgressorGuarantee";

type Props = { parentId: string };

export const NestedProgressor = ({ parentId }: Props) => (
  <ErrorBoundary>
    <Card>
      <Grid columns={2}>
        <NestedProgressorCP parentId={parentId} />
        <NestedProgressorGuarantee parentId={parentId} />
      </Grid>
    </Card>
  </ErrorBoundary>
);
