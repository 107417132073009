// @flow
import { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import Gravatar from "react-gravatar";
import { EditIcon, Add } from "@nest-ui/icons";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { Heading } from "components/Heading";
import { Card } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { SecondaryButton } from "components/SecondaryButton";
import { Button } from "components/SecondaryButton/SecondaryButton";
import { EditUserModal } from "./EditUserModal";
import { AddUserModal } from "./AddUserModal";
import { ReactivateUserModal } from "./ReactivateUserModal";
import GetActiveUsersQuery from "./queries/GetActiveUsersQuery.graphql";

export const UserProfileImage = styled(Gravatar)`
  border-radius: 50%;
  height: 30px;
  margin-right: 20px;
  width: 30px;
`;

const CustomGrid = styled(Grid)`
  grid-template-columns: 30px repeat(3, 1fr) !important;
  margin-top: 10px;
`;

const UserRow = ({
  user,
  setEditUserId,
  showModal,
}: {
  user: GetActiveUsersQuery_activeNestedUsers,
  setEditUserId: (string) => void,
  showModal?: boolean,
}) => (
  <Fragment key={user.id}>
    <LeafCell left={1} width={1}>
      <UserProfileImage email={user.email} />
    </LeafCell>
    <LeafCell
      width={2}
      css={css`
        align-items: center;
      `}
    >
      {user.fullName}
    </LeafCell>
    {showModal && (
      <LeafCell>
        <SecondaryButton
          onSubmit={() => setEditUserId(user.id)}
          icon={EditIcon}
        >
          Manage
        </SecondaryButton>
      </LeafCell>
    )}
  </Fragment>
);

export const IndividualUserSettings = () => {
  const [editUserId, setEditUserId] = useState();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showReactivateUserModal, setShowReactivateUserModal] = useState(false);

  return (
    <>
      {editUserId && (
        <EditUserModal onClose={() => setEditUserId()} userId={editUserId} />
      )}
      {showAddUserModal && (
        <AddUserModal
          onClose={() => setShowAddUserModal(false)}
          open={showAddUserModal}
        />
      )}
      {showReactivateUserModal && (
        <ReactivateUserModal
          onClose={() => setShowReactivateUserModal(false)}
        />
      )}
      <Heading>Nested Users</Heading>
      <Card>
        <Grid columns={4}>
          <LeafCell left={1} width={2}>
            <Button onClick={() => setShowAddUserModal(true)}>
              <Add />
              Add New User
            </Button>
          </LeafCell>
          <LeafCell width={2}>
            <Button onClick={() => setShowReactivateUserModal(true)}>
              <Add />
              Reactivate User
            </Button>
          </LeafCell>
        </Grid>
        <ExtendedQuery query={GetActiveUsersQuery}>
          {(data) => (
            <CustomGrid columns={4}>
              {data.activeNestedUsers.map((user) => (
                <UserRow user={user} setEditUserId={setEditUserId} showModal />
              ))}
              <LeafCell
                left={1}
                width={4}
                css={css`
                  padding-top: 16px;
                `}
              >
                <Heading level={2}>Viewing Assistants</Heading>
              </LeafCell>
              {data.viewingAssistants.map((user) => (
                <UserRow user={user} setEditUserId={setEditUserId} />
              ))}
            </CustomGrid>
          )}
        </ExtendedQuery>
      </Card>
    </>
  );
};
