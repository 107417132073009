import T from "prop-types";
import { path } from "ramda";

import { yesNoOptions } from "@nest-ui/shared/options";
import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";
import { RadioButtons } from "components/RadioButtons";
import { CurrencyField } from "components/CurrencyField";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import legacyGuaranteeQuery from "./queries/legacyGuaranteeQuery.graphql";
import { legacyGuaranteeQueryConfig } from "./queries/legacyGuaranteeQueryConfig";

import { updateDealTypeLegacyGuaranteeConfig } from "./mutations/updateDealTypeLegacyGuaranteeConfig";
import UpdateDealTypeLegacyGuaranteeMutation from "./mutations/UpdateDealTypeLegacyGuaranteeMutation.graphql";

const LegacyGuaranteeComponent = ({
  data: queryResult,
  updateDealTypeLegacyGuarantee,
}) => {
  const dealTypeData =
    path(["nestDeal", "dealTypeLegacyGuarantee"], queryResult) || null;
  return (
    dealTypeData && (
      <Grid columns={4}>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeLegacyGuarantee}
            property="agencyFeeIncVat"
            data-test="deal-type-legacy-guarantee-agency-fee-inc-vat"
            label="Agency fee % (inc VAT)"
            value={dealTypeData.agencyFeeIncVat}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeLegacyGuarantee}
            property="upsideShareIncVat"
            data-test="deal-type-legacy-guarantee-upside-share"
            label="Upside share % (inc VAT)"
            value={dealTypeData.upsideShareIncVat}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeLegacyGuarantee}
            property="feeCapIncVat"
            data-test="deal-type-legacy-guarantee-fee-cap"
            label="Fee cap % (inc VAT)"
            value={dealTypeData.feeCapIncVat}
          />
        </LeafCell>
        <LeafCell>
          <PercentageField
            mutation={updateDealTypeLegacyGuarantee}
            property="oldFlatEarlyPaymentFee"
            data-test="deal-type-legacy-guarantee-old-flat-early-payment-fee"
            label="Early payment fee %"
            value={dealTypeData.oldFlatEarlyPaymentFee}
          />
        </LeafCell>
        <LeafCell>
          <CurrencyField
            mutation={updateDealTypeLegacyGuarantee}
            property="referralDiscount"
            data-test="deal-type-legacy-guarantee-referral-discount"
            label="Referral discount"
            value={dealTypeData.referralDiscount}
          />
        </LeafCell>
        <LeafCell>
          <RadioButtons
            id="deal-type-legacy-guarantee-rebate"
            mutation={updateDealTypeLegacyGuarantee}
            property="rebate"
            data-test="deal-type-legacy-guarantee-rebate"
            options={yesNoOptions}
            label="Rebate"
            value={dealTypeData.rebate}
          />
        </LeafCell>
      </Grid>
    )
  );
};

LegacyGuaranteeComponent.propTypes = {
  data: T.object.isRequired,
  updateDealTypeLegacyGuarantee: T.func.isRequired,
};

export const LegacyGuarantee = compose(
  graphql(legacyGuaranteeQuery, legacyGuaranteeQueryConfig),
  graphql(
    UpdateDealTypeLegacyGuaranteeMutation,
    updateDealTypeLegacyGuaranteeConfig,
  ),
)(LegacyGuaranteeComponent);
