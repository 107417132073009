// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { TextField } from "components/TextField";
import { formatNumberAsCurrency } from "@nest-ui/hocs";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { InfoTile, InfoSection } from "../InfoTile";

const IPAD_APP_PROPERTY_BILLS_UPDATE = gql`
  mutation UpdateSaPropertyInfoBills($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      approxYearlyBills
    }
  }
`;

const IPAD_APP_PROPERTY_INFO_UPDATE = gql`
  mutation UpdateCouncilTax($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      councilTaxPerAnnum
    }
  }
`;

type Props = {
  editMode: boolean,
  nestDeal: ViewingAppNestDealQuery_nestDeal,
};

export const CostsSection = ({ editMode, nestDeal }: Props) => {
  const { id, property, councilTaxPerAnnum } = nestDeal;

  if (editMode) {
    return (
      <>
        <H2>Costs</H2>
        <Grid columns={4}>
          <LeafCell width={2}>
            <ExtendedMutation mutation={IPAD_APP_PROPERTY_BILLS_UPDATE}>
              {(
                updateProperty: MutationFunction<
                  UpdateSaPropertyInfoBills,
                  UpdateSaPropertyInfoBillsVariables,
                >,
              ) => (
                <TextField
                  label="Approx. bills for the year"
                  data-test="viewing-app:edit:approx-yearly-bills"
                  property="approxYearlyBills"
                  value={property.approxYearlyBills}
                  mutation={(input) =>
                    updateProperty({
                      variables: {
                        id: property.id,
                        input,
                      },
                      optimisticResponse: {
                        updateProperty: {
                          ...property,
                          ...input,
                        },
                      },
                    })
                  }
                />
              )}
            </ExtendedMutation>
          </LeafCell>
          <LeafCell width={2}>
            <ExtendedMutation mutation={IPAD_APP_PROPERTY_INFO_UPDATE}>
              {(
                updateSaPropertyInfo: MutationFunction<
                  UpdateSaPropertyInfo,
                  UpdateSaPropertyInfoVariables,
                >,
              ) => (
                <TextField
                  label="Council tax"
                  data-test="viewing-app:edit:council-tax"
                  property="councilTaxPerAnnum"
                  value={councilTaxPerAnnum}
                  mutation={(input) =>
                    updateSaPropertyInfo({
                      variables: {
                        id,
                        input,
                      },
                      optimisticResponse: {
                        updateNestDeal: {
                          ...nestDeal,
                          ...input,
                        },
                      },
                    })
                  }
                />
              )}
            </ExtendedMutation>
          </LeafCell>
        </Grid>
      </>
    );
  }

  return (
    <InfoSection title="Costs">
      <InfoTile
        data-test="viewing-app:approx-yearly-bills"
        title="Approx. bills for the year"
        text={property.approxYearlyBills}
      />
      <InfoTile
        data-test="viewing-app:council-tax"
        title="Council tax"
        text={formatNumberAsCurrency(councilTaxPerAnnum)}
      />
    </InfoSection>
  );
};
