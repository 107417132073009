// @flow
import { gql } from "@apollo/client";
import { useState } from "react";
import styled, { css } from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";

import { Button } from "@nest-ui/sellers-nest/components/SecondaryButton";
import { H1, H2 } from "@nest-ui/sellers-nest/components/Heading";
import { Header as OriginalHeader } from "@nest-ui/sellers-nest/components/Header";
import { device } from "@nest-ui/styles";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { PropertyMatchModal } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/PotentialBuyers";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { AddInterest } from "./AddInterest/AddInterest";
import { BuyerPropertyInterestCard } from "./BuyerPropertyInterestCard/BuyerPropertyInterestCard";
import { BlankCurrentPropertyInterests } from "./BlankCurrentPropertyInterests/BlankCurrentPropertyInterests";
import { BUYER_FRAGMENT } from "./BuyerFragment";
import { SimilarPropertiesList } from "./SimilarProperties/SimilarPropertiesList";

export const PROPERTY_INTERESTS_QUERY = gql`
  query PropertyInterestsByBuyer($buyerId: ID!) {
    buyer(id: $buyerId) {
      id
      buyerPosition {
        id
      }
      firstName
      maxPrice
      minBedrooms
      potentialProperties {
        id
        externalId
        address
        saExtendedSummary
        nestedListing
      }
      searchAreas {
        id
        name
      }
      ...buyerFields
    }

    buyerPositions {
      value: id
      label
    }

    buyerPropertyInterestStatuses {
      label
      value
    }

    activeNestedUsers {
      id
      email
      fullName
      closeUserId
    }

    viewingCancellationReasons {
      id
      reason
      showForConfirmedViewings
      showWhenRearranging
    }

    viewingAssistants {
      id
      fullName
      email
    }
  }
  ${BUYER_FRAGMENT}
`;

const Wrapper = styled.div`
  width: 100%;

  @media ${device.desktop} {
    max-width: 960px;
  }
`;

const PotentialInterests = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px 0 rgba(53, 63, 82, 0.1);
  margin: 16px;
  padding: 16px;
`;

const PotentialPropertiesListPreview = styled.div`
  &:last-child {
    margin-right: 0px;
  }
`;

const crossSellingPreviewImageStyles = css`
  height: 94px;
  margin-bottom: 16px;
  margin-right: 8px;
  width: 138px;
`;

const SubHeading = styled.div`
  h2 {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const Header = styled(OriginalHeader)`
  position: relative;

  & > button {
    position: absolute;
    height: 34px;
    width: auto;
    right: 16px;
    top: 8px;
  }
`;

const InterestsContainer = styled.div`
  /* 50px is top bar, 64px is interests header */
  ${({ noScroll }) =>
    noScroll
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100vh - 50px - 64px);
          overflow-y: scroll;
        `};

  ${SubHeading}:not(:first-child) {
    margin-top: 40px;
  }
`;

const TransparentOverlay = styled.div`
  position: absolute;
  max-width: 960px;
  width: calc(100% - 32px);
  height: 100%;
  top: 0;
  left: 16px;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
`;

type Props = {| buyerId: string, noScroll?: boolean |};

export const COMPLETED_BPI_STATUS_VALUE = "8";

export function splitInterests(
  buyerPropertyInterests: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_buyerPropertyInterests>,
) {
  return buyerPropertyInterests.reduce(
    (acc, bpi) => {
      if (bpi.status.value === COMPLETED_BPI_STATUS_VALUE) {
        acc.completedBuyerPropertyInterests.push(bpi);
      } else if (bpi.currentlyInterested) {
        acc.activeBuyerPropertyInterests.push(bpi);
      } else {
        acc.inactiveBuyerPropertyInterests.push(bpi);
      }
      return acc;
    },
    {
      completedBuyerPropertyInterests: [],
      activeBuyerPropertyInterests: [],
      inactiveBuyerPropertyInterests: [],
    },
  );
}

const PotentialPropertiesPreview = ({
  potentialProperties,
  setShowSimilarProperties,
}) => {
  return (
    <>
      {potentialProperties.length > 0 ? (
        <>
          <PotentialPropertiesListPreview data-test="recommended-properties-list-preview">
            {potentialProperties.slice(0, 6).map((property) => (
              <img
                key={property.externalId}
                src={`https://nested.imgix.net/property-listings/${property.externalId}/1`}
                alt="Property for cross-selling"
                css={crossSellingPreviewImageStyles}
              />
            ))}
          </PotentialPropertiesListPreview>
          <Button
            onClick={() => setShowSimilarProperties(true)}
            data-test="see-all-recommended-properties-button"
          >
            <FontAwesomeIcon icon={faSearch} />
            See all {potentialProperties.length} cross-selling opportunities
          </Button>
        </>
      ) : (
        <div data-test="no-recommended-properties-message">
          No cross selling opportunities for this buyer just yet...
        </div>
      )}
    </>
  );
};

type PotentialPropertiesProps = {
  potentialProperties: $ReadOnlyArray<PropertyInterestsByBuyer_buyer_potentialProperties>,
  buyer: PropertyInterestsByBuyer_buyer,
};

export const PotentialProperties = ({
  potentialProperties,
  buyer,
}: PotentialPropertiesProps) => {
  const [showSimilarProperties, setShowSimilarProperties] = useState(false);

  return (
    <>
      <PropertyMatchModal
        isOpen={showSimilarProperties}
        closeModal={() => setShowSimilarProperties(false)}
        data-test="recommended-properties-modal"
      >
        {showSimilarProperties && (
          <SimilarPropertiesList
            closeModal={() => setShowSimilarProperties(false)}
            buyer={buyer}
          />
        )}
      </PropertyMatchModal>
      <PotentialPropertiesPreview
        potentialProperties={potentialProperties}
        setShowSimilarProperties={setShowSimilarProperties}
      />
    </>
  );
};

export function PropertyInterests({ buyerId, noScroll }: Props) {
  const options = { variables: { buyerId } };
  const { data, loading, error } = useQuery(PROPERTY_INTERESTS_QUERY, options);

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  const {
    activeNestedUsers,
    buyer: {
      buyerPosition,
      buyerPropertyInterests,
      id,
      leadSaUser: leadSa,
      maxPrice,
      minBedrooms,
      potentialProperties,
      searchAreas,
    },
    buyerPositions,
    buyerPropertyInterestStatuses,
    viewingCancellationReasons,
    viewingAssistants,
  } = data;

  const onTheMarketBuyerPositions = buyerPositions
    .filter((position) => position.label.startsWith("Property to sell - "))
    .map((position) => position.value);

  const buyerIsOnTheMarket = onTheMarketBuyerPositions.includes(
    buyerPosition?.id,
  );

  const {
    completedBuyerPropertyInterests,
    activeBuyerPropertyInterests,
    inactiveBuyerPropertyInterests,
  } = splitInterests(buyerPropertyInterests);

  return (
    <Wrapper>
      <Header>
        <H1 css="text-align: left !important;">Interests</H1>
        <AddInterest buyerId={id} />
      </Header>
      <InterestsContainer noScroll={noScroll}>
        {completedBuyerPropertyInterests.length > 0 ? (
          <>
            <SubHeading>
              <H2>Completed</H2>
            </SubHeading>
            {completedBuyerPropertyInterests.map((buyerPropertyInterest) => (
              <BuyerPropertyInterestCard
                activeNestedUsers={activeNestedUsers}
                buyerId={buyerId}
                buyerPropertyInterest={buyerPropertyInterest}
                buyerPropertyInterestStatuses={buyerPropertyInterestStatuses}
                data-test="completed-buyer-property-interests"
                isSchedulingDisabled
                key={buyerPropertyInterest.id}
                leadSa={leadSa}
                viewingCancellationReasons={viewingCancellationReasons}
                viewingAssistants={viewingAssistants}
              />
            ))}
          </>
        ) : (
          <>
            <SubHeading>
              <H2>Potential interests</H2>
            </SubHeading>
            <PotentialInterests data-test="potential-interests-section">
              {maxPrice && minBedrooms >= 0 && searchAreas.length > 0 ? (
                <PotentialProperties
                  potentialProperties={potentialProperties}
                  buyer={data.buyer}
                />
              ) : (
                "Please fill in the buyer's max budget, minimum number of bedrooms and search areas in order to cross-sell properties to them"
              )}
            </PotentialInterests>
          </>
        )}
        <SubHeading>
          <H2>Current interests</H2>
        </SubHeading>
        <div data-test="active-buyer-property-interests">
          {activeBuyerPropertyInterests.length > 0 ? (
            activeBuyerPropertyInterests.map((buyerPropertyInterest) => (
              <BuyerPropertyInterestCard
                activeNestedUsers={activeNestedUsers}
                buyerId={buyerId}
                buyerPropertyInterest={buyerPropertyInterest}
                buyerPropertyInterestStatuses={buyerPropertyInterestStatuses}
                buyerIsOnTheMarket={buyerIsOnTheMarket}
                key={buyerPropertyInterest.id}
                leadSa={leadSa}
                viewingCancellationReasons={viewingCancellationReasons}
                viewingAssistants={viewingAssistants}
              />
            ))
          ) : (
            <BlankCurrentPropertyInterests data-test="blank-current-property-interests" />
          )}
        </div>
        {inactiveBuyerPropertyInterests.length > 0 && (
          <>
            <SubHeading>
              <H2>Not interested</H2>
            </SubHeading>
            <div
              style={{ position: "relative" }}
              data-test="inactive-buyer-property-interests"
            >
              {inactiveBuyerPropertyInterests.map((buyerPropertyInterest) => (
                <BuyerPropertyInterestCard
                  activeNestedUsers={activeNestedUsers}
                  buyerId={buyerId}
                  buyerPropertyInterest={buyerPropertyInterest}
                  buyerPropertyInterestStatuses={buyerPropertyInterestStatuses}
                  isSchedulingDisabled
                  key={buyerPropertyInterest.id}
                  leadSa={leadSa}
                  viewingCancellationReasons={viewingCancellationReasons}
                  viewingAssistants={viewingAssistants}
                />
              ))}
              <TransparentOverlay />
            </div>
          </>
        )}
      </InterestsContainer>
    </Wrapper>
  );
}
