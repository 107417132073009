// @flow
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useCombobox } from "downshift";
import { useQuery, gql } from "@apollo/client";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { ADDRESS_NOT_FOUND_ID } from "@nested/brand";
import { listStyle, listItemStyle } from "../Select/Select";
import { TextInput } from "../TextInput/TextInput";

export const GET_ADDRESS_BY_ID = gql`
  query AddressPickerGetAddress($id: ID!) {
    address(id: $id) {
      id
      label
      postcode
      isInServicedArea
      isBlacklisted
    }
  }
`;

export const POSTCODE_SEARCH = gql`
  query AddressPickerPostcodeSearch($postcode: String!) {
    postcodeDetails(postcode: $postcode) {
      addresses {
        id
        label
        postcode
        isInServicedArea
        isBlacklisted
      }
      valid
      normalisedPostcode
      inServicedArea
      blacklisted
    }
  }
`;

const wrapperStyle = css`
  position: relative;
`;

const inputStyle = css`
  input:focus {
    border-color: ${({ theme }) => theme.palette.hague40};
  }

  input {
    ${({ open }) =>
      open
        ? css`
            cursor: initial;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          `
        : css`
            cursor: pointer;
          `}
  }
`;

const loadingStyle = css`
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.hague50};
`;

const stateReducer = (state, { type, changes }) => {
  switch (type) {
    case useCombobox.stateChangeTypes.ToggleButtonClick:
    case useCombobox.stateChangeTypes.InputChange:
      return {
        ...changes,
        isOpen: true,
      };
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
      return {
        ...changes,
        inputValue: changes.selectedItem?.postcode || "",
      };
    default:
      return changes;
  }
};

type Props = {
  className?: string,
  allowAddressNotFound?: boolean,
  value?: string,
  onChange(address: AddressPickerPostcodeSearch_postcodeDetails_addresses): any,
  label?: string,
  placeholder?: string,
  "data-test"?: string,
  debounceTimeoutMs?: number,
  valid?: boolean,
  withMapIcon?: boolean,
  updating?: boolean,
};

export const AddressPicker = ({
  allowAddressNotFound = false,
  className,
  value,
  onChange,
  label,
  placeholder = "Enter a postcode",
  "data-test": dataTest = "address-picker",
  debounceTimeoutMs = 500,
  valid = true,
  withMapIcon = false,
  updating = false,
}: Props) => {
  const inputRef = useRef();
  const [postcodeSearch, setPostcodeSearch] = useState("");

  const debouncedSetPostcodeSearch = useCallback(
    debounce(setPostcodeSearch, debounceTimeoutMs),
    [],
  );

  const { loading: searchLoading, data: searchData } = useQuery(
    POSTCODE_SEARCH,
    {
      variables: {
        postcode: postcodeSearch.toLowerCase().replace(" ", ""),
      },
      skip: postcodeSearch.length < 5 || postcodeSearch.length > 8,
    },
  );

  const items = useMemo(() => {
    let availableItems = searchData?.postcodeDetails?.addresses || [];
    if (searchData?.postcodeDetails?.valid && allowAddressNotFound) {
      availableItems = [
        ...availableItems,
        {
          id: ADDRESS_NOT_FOUND_ID,
          postcode: searchData.postcodeDetails.normalisedPostcode,
          isInServicedArea: searchData.postcodeDetails.inServicedArea,
          isBlacklisted: searchData.postcodeDetails.blacklisted,
        },
      ];
    }
    return availableItems;
  }, [searchData, allowAddressNotFound]);

  const selectedItem = items.find((item) => item.id === value) || null;

  const { loading, data } = useQuery(GET_ADDRESS_BY_ID, {
    variables: {
      id: value,
    },
    skip: !value || value === ADDRESS_NOT_FOUND_ID,
  });

  useEffect(() => {
    if (data?.address && !selectedItem) {
      setPostcodeSearch(data.address.postcode);
    }
  }, [data]);

  const itemToString = (item) => {
    if (item?.id === ADDRESS_NOT_FOUND_ID) {
      return "Address not found";
    }
    return item ? `${item.label}, ${item.postcode}` : "";
  };

  const {
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getToggleButtonProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    isOpen,
  } = useCombobox({
    items,
    itemToString,
    onSelectedItemChange: (changes) => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      onChange(changes.selectedItem);
    },
    selectedItem,
    stateReducer,
  });

  useEffect(() => {
    debouncedSetPostcodeSearch(inputValue);
  }, [inputValue]);

  return (
    <div css={wrapperStyle} className={className} {...getComboboxProps()}>
      <TextInput
        data-test={dataTest}
        disabled={loading || updating}
        icon={withMapIcon && faMapMarkerAlt}
        labelProps={getLabelProps()}
        css={inputStyle}
        open={isOpen}
        {...getToggleButtonProps()}
        {...getInputProps({
          ref: inputRef,
          refKey: "forwardedRef",
        })}
        placeholder={placeholder}
        label={label}
        value={
          isOpen || !selectedItem ? inputValue : itemToString(selectedItem)
        }
        valid={valid}
        tabIndex={0}
      />
      {(loading || searchLoading || updating) && (
        <div css={loadingStyle}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
      <ul
        data-test={`${dataTest}:menu`}
        css={listStyle}
        open={isOpen}
        {...getMenuProps()}
      >
        {items.length === 0 && !searchLoading && (
          <li css={listItemStyle}>Please enter a valid postcode</li>
        )}
        {items.map((item, index) => (
          <li
            data-test={`${dataTest}:item:${item.id}`}
            selected={item.id === value}
            key={item.id}
            css={listItemStyle}
            {...getItemProps({ item, index })}
            highlight={highlightedIndex === index}
          >
            {itemToString(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};
