// @flow
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { TimelineItem } from "@nested/brand/src/components/Timeline";

type Props = {
  event: {
    content: string,
    title: string,
    timestamp: DateTime,
  },
};

const capitalizeAddress = (address) => {
  const words = address.split();
  return words
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(" ");
};

export const TimelineBuyerOppStatus = ({
  event: { title, timestamp, content: address },
}: Props) => {
  // Removes the "B01: " from the title
  const status = title.slice(5);

  return (
    <TimelineItem
      color="green"
      headingColor="sand"
      icon={faFlag}
      primaryHeading={status}
      secondaryHeading={address ? ` on ${capitalizeAddress(address)}` : ""}
      timestamp={timestamp}
    />
  );
};
