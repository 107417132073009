// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faCommentDots,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { SnoozeIcon } from "@nested/brand/src/icons/SnoozeIcon";
import { contactDotStyle as listViewDotStyle } from "../../Buyers/Enquiries/TodaysEnquiriesListView";
import { tabletWrapperStyle } from "../../../components/ListViewSharedStyles";
import {
  ENQUIRY_COMPLETE,
  ENQUIRIES_WORKFLOW,
  FEEDBACK_COMPLETE,
  FEEDBACK_WORKFLOW,
  INACTIVE_BPI_WITHOUT_WORKFLOW,
  NEGOTIATIONS_COMPLETE,
  SNOOZED_ENQUIRY,
  SNOOZED_FEEDBACK,
  SNOOZED_NEGOTIATION,
  type SummaryBarState,
} from "./getSummaryBarState";

const contactDotStyle = css`
  ${listViewDotStyle}
  margin-left: 16px;
`;

const contactDotWrapper = css`
  margin-left: auto;
  ${media.tablet`
    margin: 0 auto;
  `}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Icons next to contact dot, only appear on tablet and bigger viewports
const commsIconStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  margin-left: 10px;
  ${tabletWrapperStyle}
`;

const noInterestIconStyle = css`
  color: ${({ theme }) => theme.palette.terracotta150}};
  margin: 0;
  margin-left: 10px;
`;

const snoozeIconStyle = css`
  width: 17px;
  height: 18px;
  margin-left: 10px;
`;

// Wraps content (unless it is 2x3 or 3x3) which appears in the middle of the bar. Content is never rendered on mobile.
const statusWrapper = css`
  display: none;
  ${media.tablet`
    display: flex; 
    margin: 0 auto;
    align-items: center;
  `}
  color: ${({ theme }) => theme.palette.hague70};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 500;
  font-size: 12px;
`;

const threeByThreeCompleteIconStyle = css`
  color: ${({ theme }) => theme.palette.green100}};
  margin: 0;
  margin-left: 10px;
`;

const getCommsIcon = (icon: "call" | "email" | "sms") => {
  switch (icon) {
    case "call":
      return faPhone;
    case "email":
      return faEnvelope;
    case "sms":
      return faCommentDots;
    default:
      return null;
  }
};

const ContactDot = ({
  completed,
  icon,
}: {
  completed: boolean,
  icon: "call" | "email" | "sms",
}) => {
  return (
    <div css="display: flex">
      <div css={contactDotStyle} completed={completed} />
      <FontAwesomeIcon css={commsIconStyle} icon={getCommsIcon(icon)} />
    </div>
  );
};

const EnquiryStatus = ({ selectedBpi }) => {
  if (!selectedBpi.postEnquiryContactSummary) return null;

  return (
    selectedBpi.postEnquiryContactSummary.dayNumber < 4 && (
      <div
        data-test={`enquiry-contact-dots-${selectedBpi.id}`}
        css={contactDotWrapper}
      >
        <ContactDot
          completed={selectedBpi.postEnquiryContactSummary.call}
          icon={"call"}
        />
        <ContactDot
          completed={selectedBpi.postEnquiryContactSummary.email}
          icon={"email"}
        />
        <ContactDot
          completed={selectedBpi.postEnquiryContactSummary.sms}
          icon={"sms"}
        />
      </div>
    )
  );
};

const FeedbackStatus = ({ selectedBpi }) => {
  if (!selectedBpi.postViewingContactSummary) return null;

  return (
    selectedBpi.postViewingContactSummary.dayNumber < 3 && (
      <div
        data-test={`feedback-contact-dots-${selectedBpi.id}`}
        css={contactDotWrapper}
      >
        <ContactDot
          completed={selectedBpi.postViewingContactSummary.call}
          icon={"call"}
        />
        <ContactDot
          completed={selectedBpi.postViewingContactSummary.email}
          icon={"email"}
        />
        <ContactDot
          completed={selectedBpi.postViewingContactSummary.sms}
          icon={"sms"}
        />
      </div>
    )
  );
};

const NotInterestedStatus = () => (
  <div css={statusWrapper}>
    no interest
    <FontAwesomeIcon css={noInterestIconStyle} icon={faTimes} />
  </div>
);

const SnoozedStatus = ({ date }: { date: ?string }) => (
  <div css={statusWrapper}>
    snoozed until {moment(date).format("DD/MM/YY")}
    <SnoozeIcon css={snoozeIconStyle} />
  </div>
);

const EnquiryCompletedStatus = ({ selectedBpi }) => {
  if (!selectedBpi.currentlyInterested) {
    return <NotInterestedStatus />;
  }

  const viewingsCount = selectedBpi.viewings.filter(
    (v) => !v.viewingCancellationDatetime,
  ).length;

  return (
    <div css={statusWrapper}>
      <span>
        {viewingsCount}{" "}
        {viewingsCount === 1 ? "viewing booked" : "viewings booked"}
      </span>
      <FontAwesomeIcon css={threeByThreeCompleteIconStyle} icon={faCheck} />
    </div>
  );
};

const FeedbackCompletedStatus = ({ selectedBpi }) => {
  if (!selectedBpi.currentlyInterested) {
    return <NotInterestedStatus />;
  }

  return (
    <div css={statusWrapper}>
      feedback shared
      <FontAwesomeIcon css={threeByThreeCompleteIconStyle} icon={faCheck} />
    </div>
  );
};

const NegotiationsCompletedStatus = ({ selectedBpi }) => {
  if (!selectedBpi.currentlyInterested) {
    return <NotInterestedStatus />;
  }

  return (
    <div css={statusWrapper}>
      negotiations complete
      <FontAwesomeIcon css={threeByThreeCompleteIconStyle} icon={faCheck} />
    </div>
  );
};

export const BuyerStatus = ({
  summaryBarState,
  selectedBpi,
}: {
  summaryBarState: SummaryBarState,
  selectedBpi: BuyerSummary_buyer_buyerPropertyInterests,
}): React$Node => {
  // returns null if BUYER_INACTIVE or ACTIVE_BPI_OUTSIDE_WORKFLOW or NEGOTIATIONS_WORKFLOW
  switch (summaryBarState) {
    case INACTIVE_BPI_WITHOUT_WORKFLOW:
      return <NotInterestedStatus />;
    case SNOOZED_ENQUIRY:
      return <SnoozedStatus date={selectedBpi.snooze?.snoozedUntil} />;
    case SNOOZED_FEEDBACK:
      return <SnoozedStatus date={selectedBpi.snooze?.snoozedUntil} />;
    case SNOOZED_NEGOTIATION:
      return <SnoozedStatus date={selectedBpi.snooze?.snoozedUntil} />;
    case ENQUIRY_COMPLETE:
      return <EnquiryCompletedStatus selectedBpi={selectedBpi} />;
    case ENQUIRIES_WORKFLOW:
      return <EnquiryStatus selectedBpi={selectedBpi} />;
    case FEEDBACK_COMPLETE:
      return <FeedbackCompletedStatus selectedBpi={selectedBpi} />;
    case FEEDBACK_WORKFLOW:
      return <FeedbackStatus selectedBpi={selectedBpi} />;
    case NEGOTIATIONS_COMPLETE:
      return <NegotiationsCompletedStatus selectedBpi={selectedBpi} />;
    default:
      return null;
  }
};
