// @flow

type UsersList = $ReadOnlyArray<
  | PostVisitValuationsListNestDealQuery_activeNestedUsers
  | PreVisitValuationsListQuery_activeNestedUsers
  | AdvanceOfferValuationsListNestDealQuery_activeNestedUsers,
>;

export const userNameFromCloseId = (targetId: String, users: UsersList) =>
  users.find(({ closeUserId }) => closeUserId === targetId)?.fullName;
