// @flow
import type { ElementRef } from "react";
import Textarea from "react-textarea-autosize";
import { css } from "styled-components";

import { Input } from "components/Input";
import { Label } from "components/Label";
import { Div, characterCount, InputWrapper } from "./styles";

export type NakedTextFieldProps = {
  "data-test"?: string, // eslint-disable-line react/no-unused-prop-types
  className?: string,
  disabled?: boolean,
  highlightRed?: boolean,
  id?: string,
  inputRef?: ElementRef<any>,
  isSecondary?: boolean,
  label?: string,
  maxLength?: number,
  maxRows?: number,
  minRows?: number,
  multiline?: boolean,
  mutedText?: boolean,
  name?: string,
  onBlur: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void,
  onFocus: (event: SyntheticEvent<HTMLInputElement>) => void,
  onWheel?: (event: SyntheticMouseEvent<HTMLInputElement>) => void,
  placeholder?: string,
  property?: string,
  sectionTitle?: string,
  type?: string,
  value?: string | number | boolean | null,
};

export const NakedTextField = ({
  "data-test": dataTest,
  className,
  disabled,
  highlightRed,
  id = "",
  inputRef,
  isSecondary,
  label,
  maxLength,
  maxRows,
  minRows,
  multiline,
  mutedText,
  name,
  onBlur,
  onChange,
  onFocus,
  onWheel,
  placeholder = "",
  property = "",
  sectionTitle = "",
  type,
  value,
}: NakedTextFieldProps) => {
  const fieldId = `${sectionTitle}-${id || property}`;
  const InputComponent = multiline ? Textarea : Input;
  const showCharacterCounter = maxLength && typeof value === "string";
  return (
    <Div
      className={className}
      css={css`
        ${({ multiline: ml }) =>
          ml &&
          css`
            min-height: 64px;
          `}
      `}
      highlightRed={highlightRed}
      isSecondary={isSecondary}
      multiline={multiline}
      mutedText={mutedText}
      showCharacterCounter={showCharacterCounter}
    >
      {label && <Label htmlFor={fieldId}>{label}</Label>}
      <InputWrapper>
        <InputComponent
          data-test={dataTest}
          disabled={disabled}
          id={fieldId}
          inputRef={inputRef}
          maxRows={maxRows}
          maxLength={maxLength}
          minRows={minRows}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onWheel={onWheel}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {showCharacterCounter && typeof value === "string" && (
          <span css={characterCount}>
            {value?.length} / {maxLength}
          </span>
        )}
      </InputWrapper>
    </Div>
  );
};

NakedTextField.displayName = "NakedTextField";
