// @flow
import styled, { ThemeProvider } from "styled-components";
import { Route, Switch } from "react-router-dom";

import { Main } from "@nested/nest/src/components/Main";

import { NotificationsBanner } from "components/NotificationsBanner";
import { Notifications } from "components/Notifications/Notifications";
import { ScrollToTop } from "components/ScrollToTop";

import { SAiPadApp } from "pages/SAiPadApp/SAiPadApp";
import { BuyersAgentPage } from "pages/BuyersAgent/BuyersAgent";
import { UserManagement } from "pages/UserManagement/UserManagement";
import { BuyerGdpr } from "components/Gdpr/Gdpr";
import { GlobalStyle } from "@nest-ui/styles";
import { PropertyInfoPage } from "pages/SAiPadApp/PropertyInfoPage";
import { MyViewings } from "pages/MyViewings/MyViewings";
import { MobileActionSheetProvider } from "@nested/nest/src/components/MobileActionSheet/MobileActionSheetContext";
import { MobileActionSheet } from "@nested/nest/src/components/MobileActionSheet/MobileActionSheet";
import { HandoffModal } from "@nested/nest/src/components/Comms/HandoffModal";
import { theme } from "@nested/brand";

const PageWrapper = styled.div`
  background-color: #fafafa;
  display: flex;
  height: 100vh;
  overflow-x: hidden;
`;

export const breakpoints = {
  tablet: 800,
  desktop: 1120,
};

export const App = () => {
  return (
    <ThemeProvider theme={{ ...theme, breakpoints }}>
      <ScrollToTop>
        <PageWrapper>
          <MobileActionSheetProvider>
            <NotificationsBanner />
            <Notifications />
            <GlobalStyle />
            <Switch>
              <Route exact path="/users" component={UserManagement} />
              <Route
                path="/deals/:dealId/property-info"
                component={PropertyInfoPage}
              />
              <Route path="/deals/:id" component={Main} />
              <Route
                path="/buyers/:buyerId/bpi/:buyerPropertyInterestId"
                component={SAiPadApp}
              />
              <Route path="/buyers/:id/gdpr" component={BuyerGdpr} />
              <Route path="/buyers/:id" component={Main} />
              <Route
                path="/buyers-agent/:customerAccountId"
                component={BuyersAgentPage}
              />
              <Route path="/my-viewings" component={MyViewings} />
              <Route component={Main} />
            </Switch>
            <MobileActionSheet />
            <HandoffModal />
          </MobileActionSheetProvider>
        </PageWrapper>
      </ScrollToTop>
    </ThemeProvider>
  );
};
