// @flow
import { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import qs from "query-string";
import { useLocation } from "react-router";
import moment from "moment";
import styled, { css } from "styled-components";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { DealSummary } from "./DealSummary";
import { ViewingCard } from "./ViewingCard/ViewingCard";
import { ContactSellerCard } from "./ContactSellerCard";
import { MetaTags } from "./MetaTags";
import { useUser } from "../../hooks/useUser";

const ALL_NESTED_USERS_QUERY = gql`
  query AllNestedUsers {
    activeNestedUsers {
      id
      email
      firstName
      fullName
      aircallPhoneNumber
    }
    viewingAssistants {
      id
      email
      firstName
      fullName
    }
  }
`;

const DEALS_WITH_VIEWINGS_TODAY_QUERY = gql`
  query DealsWithViewingsToday($email: String!) {
    dealsWithViewingsToday(email: $email) {
      id
      address
      buyerOffers {
        id
      }
      contacts {
        id
        avatarUrl
        name
        phones {
          id
          telephoneNumber
        }
        title
      }
      currentListPrice
      externalId
      nestedListing
      property {
        id
        lat
        lng
      }
      propertySummary
      tenureSummary
      timeOnMarket
      todaysViewings {
        id
        buyerPropertyInterest {
          id
          buyer {
            id
            name
            firstName
            contacts {
              id
              name
              title
              phones {
                id
                telephoneNumber
              }
              avatarUrl
            }
            buyerPosition {
              id
            }
          }
        }
        datetimeViewingStarts
        datetimeFirstImpressionsSharedWithSeller
        viewingFeedbackFirstImpressions
        virtual
      }
      viewings {
        id
      }
    }
    buyerPositions {
      value: id
      label
    }
  }
`;

const Wrapper = styled.div`
  margin: auto;
  margin-top: 0;
  max-width: 640px;
  width: 100%;
`;

const headingStyles = css`
  font-weight: 700;
`;

const Heading = styled.div`
  background-color: ${({ theme }) => theme.color.primary.dark};
  color: white;
  font-size: 18px;
  padding: 30px 15px 15px 15px;
`;

const DealWrapper = styled.div`
  padding: 10px 0 20px 0;
`;

const messageStyles = css`
  font-size: 14px;
  padding: 15px;
`;

const cardsWrapperStyle = css`
  padding: 0 15px;
  // 480px and above
  @media (min-width: 30em) {
    padding: 10px 15px 20px;
  }
`;

export const MyViewings = () => {
  const [passedInEmail, setPassedInEmail] = useState(null);
  const { email } = useUser();
  const { search } = useLocation();

  useEffect(() => {
    // This somehow fixes the tests and I'm not sure how.
    // Feel free to find another solution.
    const { email: qsEmail } = qs.parse(search);
    setPassedInEmail(qsEmail);
  }, []);

  const dateToday = moment().format("ddd D MMM YYYY");

  return (
    <>
      <MetaTags />
      {/* Enable spying */}
      <div data-clarity-unmask="True" css="width: 100%;">
        <Wrapper>
          <Heading>
            <span css={headingStyles}>My viewings</span> - {dateToday}
          </Heading>
          <ExtendedQuery
            fetchPolicy="network-only"
            nextFetchPolicy="cache-first"
            query={ALL_NESTED_USERS_QUERY}
          >
            {({ activeNestedUsers, viewingAssistants }) => {
              const allUsers = [...activeNestedUsers, ...viewingAssistants];

              const currentUser = allUsers.find(
                (user) =>
                  user.email === (passedInEmail || email).replace(/\s/g, "+"),
              );

              if (!currentUser) {
                return (
                  <div
                    css={messageStyles}
                    data-test="my-viewings:not-an-active-user-message"
                  >
                    I know you want to conduct viewings, but for this you need
                    to be promoted to SA!
                  </div>
                );
              }

              return (
                <ExtendedQuery
                  fetchPolicy="network-only"
                  nextFetchPolicy="cache-first"
                  query={DEALS_WITH_VIEWINGS_TODAY_QUERY}
                  variables={{ email: currentUser.email }}
                >
                  {({ dealsWithViewingsToday, buyerPositions }) => {
                    if (dealsWithViewingsToday.length === 0) {
                      return (
                        <div
                          css={messageStyles}
                          data-test="my-viewings:no-viewings-today-message"
                        >
                          You don't have any viewings today!
                        </div>
                      );
                    }

                    const onTheMarketBuyerPositionsIds = buyerPositions
                      .filter((position) =>
                        position.label.startsWith("Property to sell - "),
                      )
                      .map((position) => position.value);

                    return dealsWithViewingsToday.map((deal) => (
                      <DealWrapper key={deal.id}>
                        <DealSummary deal={deal} />
                        <div css={cardsWrapperStyle}>
                          {deal.todaysViewings.map((v) => (
                            <ViewingCard
                              deal={deal}
                              key={v.id}
                              viewing={v}
                              onTheMarketBuyerPositionsIds={
                                onTheMarketBuyerPositionsIds
                              }
                              currentUser={currentUser}
                            />
                          ))}
                          <ContactSellerCard
                            externalId={deal.externalId}
                            contacts={deal.contacts}
                            agentFirstName={currentUser.firstName}
                          />
                        </div>
                      </DealWrapper>
                    ));
                  }}
                </ExtendedQuery>
              );
            }}
          </ExtendedQuery>
        </Wrapper>
      </div>
    </>
  );
};
