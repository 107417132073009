// @flow
import { gql } from "@apollo/client";

import { Grid, LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";
import { Label } from "components/Label";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { useMutation } from "@apollo/client/react/hooks";
import { UploadContract } from "tabs/Close/ContractChecklist/UploadContract";
import { css } from "styled-components";

const alignContractUploadStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
`;

const CONTRACT_CHECKLIST_QUERY = gql`
  query ContractChecklistQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      dealTypeDetails {
        ... on DealTypeAgency {
          id
          customerContractSignedDate
          nestedContractSignedDate
        }
      }
    }
  }
`;

const CONTRACT_SIGNED_DATES_MUTATION = gql`
  mutation UpdateContractSignedDates($id: ID!, $input: DealTypeAgencyInput!) {
    updateDealTypeAgency(input: $input, id: $id) {
      id
      customerContractSignedDate
      nestedContractSignedDate
    }
  }
`;

export const ContractChecklist = ({ dealId }: {| dealId: string |}) => {
  const [mutateDealType] = useMutation(CONTRACT_SIGNED_DATES_MUTATION);

  return (
    <ExtendedQuery query={CONTRACT_CHECKLIST_QUERY} variables={{ id: dealId }}>
      {({ nestDeal }: ExtendedQueryRenderProps<ContractChecklistQuery>) => {
        const { dealTypeDetails } = nestDeal;
        if (dealTypeDetails?.__typename !== "DealTypeAgency") {
          return "Select a deal type first";
        }

        return (
          <Grid columns={3}>
            <LeafCell
              width={1}
              style={{
                alignItems: "stretch",
              }}
            >
              <div css={alignContractUploadStyle}>
                <Label>{"Signed Contract"}</Label>

                <UploadContract dealId={dealId} />
              </div>
            </LeafCell>
            <LeafCell width={1}>
              <DatePicker
                label="Nested Contract Signed Date"
                value={dealTypeDetails.nestedContractSignedDate}
                property="nestedContractSignedDate"
                mutation={(input) => {
                  mutateDealType({
                    variables: {
                      input,
                      id: dealTypeDetails.id,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateDealTypeAgency: {
                        __typename: "DealTypeAgency",
                        ...dealTypeDetails,
                        ...input,
                      },
                    },
                  });
                }}
                data-test="contract-checklist-nested-contract-signed-date"
              />
            </LeafCell>
            <LeafCell width={1}>
              <DatePicker
                label="Customer Contract Signed Date"
                value={dealTypeDetails.customerContractSignedDate}
                property="customerContractSignedDate"
                mutation={(input) => {
                  mutateDealType({
                    variables: {
                      input,
                      id: dealTypeDetails.id,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateDealTypeAgency: {
                        __typename: "DealTypeAgency",
                        ...dealTypeDetails,
                        ...input,
                      },
                    },
                  });
                }}
                data-test="contract-checklist-customer-contract-signed-date"
              />
            </LeafCell>
          </Grid>
        );
      }}
    </ExtendedQuery>
  );
};
