// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";
import { type Match, type History } from "react-router";
import { withRouter } from "react-router-dom";

import { EditButton } from "components/DealDetailsBar/EditButton";
import { Content } from "components/Modal";
import { H2 } from "components/Heading";
import { Card } from "components/Card";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import {
  ExtendedMutation,
  type MutationFunction,
} from "@nested/utils/graphql/ExtendedMutation";

import {
  ViewingContactPreferencesModal,
  ContactName,
  ContactTitle,
} from "./ViewingContactPreferencesModal";

const CONTACTS_QUERY = gql`
  query ContactsQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      contacts {
        id
        name
        title
        viewingCommsEnabled
        telephoneNumbers
        emailAddresses
      }
    }
  }
`;

const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContact($id: ID!, $input: UpdateContactInput!) {
    updateContact(input: $input, id: $id) {
      id
      name
      title
      viewingCommsEnabled
    }
  }
`;

const ContactContainer = styled.div`
  display: grid;
  grid-template-columns: auto 16px;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.background.muted};

  &:last-child {
    border: none;
  }
`;

const ContactDetails = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;

  li {
    margin-top: 4px;
  }
`;

const CommsEnabledIndicator = styled.span`
  background-color: ${({ viewingCommsEnabled, theme }) =>
    viewingCommsEnabled
      ? theme.color.secondary.light
      : theme.color.background.danger}};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: inline-block;
  justify-self: end;
`;

const CardWithGreyModal = styled(Card)`
  ${Content} {
    background-color: ${({ theme }) => theme.color.background.default};
  }
`;

const Contact = (props: { contact: ContactsQuery_nestDeal_contacts }) => {
  const { name, title, viewingCommsEnabled } = props.contact;
  return (
    <ContactContainer>
      <ContactDetails>
        <ContactName className="fs-exclude">{name}</ContactName>
        {title && <ContactTitle>{title}</ContactTitle>}
      </ContactDetails>

      <CommsEnabledIndicator viewingCommsEnabled={viewingCommsEnabled} />
    </ContactContainer>
  );
};

type Props = {
  dealId: string,
  history: History,
  match: Match,
};

export const ViewingContactPreferences = withRouter((props: Props) => {
  const { dealId, history, match } = props;
  const openModal = () => history.push(`${match.url}/edit-contact-preferences`);
  const closeModal = () => history.push(match.url);

  return (
    <CardWithGreyModal data-test="contact-preferences">
      <H2>Viewing Contact Preferences</H2>

      <EditButton
        onClick={openModal}
        data-test="contact-preferences-edit-button"
      >
        Edit
      </EditButton>

      <ExtendedQuery query={CONTACTS_QUERY} variables={{ dealId }}>
        {({
          nestDeal: { contacts },
        }: ExtendedQueryRenderProps<ContactsQuery>) => (
          <>
            {contacts.map((contact) => (
              <Contact
                key={contact.id}
                contact={contact}
                data-test={`contact-read-only:${contact.id}`}
              />
            ))}

            <ExtendedMutation mutation={UPDATE_CONTACT_MUTATION}>
              {(
                updateContact: MutationFunction<
                  UpdateContact,
                  UpdateContactVariables,
                >,
              ) => (
                <ViewingContactPreferencesModal
                  closeModal={closeModal}
                  contacts={contacts}
                  updateContact={updateContact}
                />
              )}
            </ExtendedMutation>
          </>
        )}
      </ExtendedQuery>
    </CardWithGreyModal>
  );
});
