import { gql } from "@apollo/client";

export const BUYER_SUMMARY_FRAGMENT = gql`
  fragment BuyerSummaryFields on Buyer {
    id
    avatarUrl
    name
    firstName
    closeIoLeadId
    leadStatus
    contacts {
      id
      name
      title
      avatarUrl
      phones {
        id
        telephoneNumber
      }
      emails {
        id
        emailAddress
      }
    }
    buyerPropertyInterests {
      id
      currentlyInterested
      deal {
        id
        externalId
      }
      status {
        value
      }
      postViewingContactSummary {
        dayNumber
        call
        email
        sms
      }
      postEnquiryContactSummary {
        dayNumber
        call
        email
        sms
      }
      buyerOffers {
        id
        placedOn
        latestRenegotiatedOfferAmount
        status {
          label
        }
      }
      snooze {
        id
        snoozedUntil
      }
      viewings {
        id
        datetimeViewingStarts
        datetimeViewingEnds
        viewingCancellationDatetime
        datetimeFeedbackSharedWithSeller
      }
    }
  }
`;

export const BUYER_SUMMARY_QUERY = gql`
  query BuyerSummary($id: ID!) {
    buyer(id: $id) {
      id
      ...BuyerSummaryFields
    }
  }

  ${BUYER_SUMMARY_FRAGMENT}
`;
