// @flow
import {
  FEEDBACK_WORKFLOW,
  ENQUIRIES_WORKFLOW,
  COMPLETED_STATES,
  type SummaryBarState,
} from "../getSummaryBarState";

const getActionFromContactStrategy = (summary, maxDays) => {
  if (!summary || summary.dayNumber > maxDays) {
    return null;
  }

  if (summary.call && summary.email && summary.sms) {
    return "next";
  }

  if (!summary.call) {
    return "call";
  }

  return "email_sms";
};

export const getRecommendedAction = (
  selectedBpi: BuyerSummary_buyer_buyerPropertyInterests,
  summaryBarState: SummaryBarState,
) => {
  if (COMPLETED_STATES.includes(summaryBarState)) {
    return "next";
  }

  if (summaryBarState === ENQUIRIES_WORKFLOW) {
    const { postEnquiryContactSummary: summary } = selectedBpi;
    return getActionFromContactStrategy(summary, 3);
  }

  if (summaryBarState === FEEDBACK_WORKFLOW) {
    const { postViewingContactSummary: summary } = selectedBpi;
    return getActionFromContactStrategy(summary, 2);
  }

  return null;
};
