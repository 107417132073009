// @flow

import { Grid, LeafCell } from "components/Grid";
import { RadioButtons } from "components/RadioButtons";
import { gql } from "@apollo/client";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import type { ExtendedQueryRenderProps } from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import type { MutationFunction } from "@apollo/client/react/components";

const EXCEPTION_TO_VALUATION_CRITERIA_QUERY = gql`
  query ExceptionToValuationCriteriaQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      isExceptionToValuationCriteria
    }
  }
`;

const EXCEPTION_TO_VALUATION_CRITERIA_MUTATION = gql`
  mutation ExceptionToValuationCriteriaMutation(
    $id: ID!
    $input: NestDealInput!
  ) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      isExceptionToValuationCriteria
    }
  }
`;

export const ExceptionToValuationCriteria = ({
  parentId,
}: {|
  parentId: string,
|}) => (
  <ExtendedQuery
    query={EXCEPTION_TO_VALUATION_CRITERIA_QUERY}
    variables={{ id: parentId }}
  >
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<ExceptionToValuationCriteriaQuery>) => (
      <ExtendedMutation mutation={EXCEPTION_TO_VALUATION_CRITERIA_MUTATION}>
        {(
          mutation: MutationFunction<
            ExceptionToValuationCriteriaMutation,
            ExceptionToValuationCriteriaMutationVariables,
          >,
        ) => {
          const updateNestDeal = (input) =>
            mutation({
              variables: {
                id: nestDeal.id,
                input,
              },
              optimisticResponse: {
                updateNestDeal: {
                  ...nestDeal,
                  ...input,
                },
              },
            });
          return (
            <Grid column={4}>
              <LeafCell width={12}>
                <RadioButtons
                  data-test="is-exception-to-valuation-criteria"
                  value={nestDeal.isExceptionToValuationCriteria}
                  mutation={updateNestDeal}
                  options={[
                    { label: "No", value: false },
                    { label: "Yes", value: true },
                  ]}
                  property="isExceptionToValuationCriteria"
                  label="Exception to underwriting criteria"
                />
              </LeafCell>
            </Grid>
          );
        }}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
