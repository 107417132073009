// @flow
import styled from "styled-components";

import AsyncSelect from "react-select/async";

import { Label } from "components/Label";
import { theme } from "@nest-ui/styles";
import { components } from "react-select";
import { Tick } from "@nest-ui/icons";

import { customStyles } from "./ReactSelect";

const SelectContainer = styled.div`
  .react-select__control {
    flex-direction: row;
    border-radius: 4px;
    border-top: 1px;
  }

  .react-select__value-container {
    line-height: 15px;
  }

  .react-select__menu-notice--no-options {
    background-color: ${({ dark, theme: t }) =>
      dark ? t.color.primary.dark : t.color.primary.muted};
  }

  .react-select__option--is-selected {
    display: flex;
    align-items: center;
    svg {
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
  }
`;

const TickSelectedOption = (props) => (
  <components.Option {...props}>
    {props.isSelected && <Tick fill="#10b186" />}
    {props.children}
  </components.Option>
);

type Props = {|
  onChange: ({ label: string, value: any }) => void,
  value: { label: string, value: any } | null,
  noOptionsMessage: (string) => string,
  label?: string,
  dark: boolean,
  highlightRed?: boolean,
  loadOptions: (string) => Promise<Array<{ label: string, value: any }>>,
  "data-test"?: string,
  isOptionSelected?: (option: Choice) => boolean,
  showSelectedTicks?: boolean,
  className?: string,
|};

export function AsyncSelectField(props: Props) {
  const {
    onChange,
    value,
    label,
    loadOptions,
    highlightRed = false,
    dark = false,
    noOptionsMessage,
    showSelectedTicks,
    isOptionSelected,
    className,
    ...rest
  } = props;

  return (
    <SelectContainer dark={dark} className={className}>
      {label && <Label>{label}</Label>}
      <AsyncSelect
        classNamePrefix="react-select"
        components={{
          DropdownIndicator: () => false,
          Option: showSelectedTicks ? TickSelectedOption : components.Option,
        }}
        isOptionSelected={isOptionSelected}
        styles={customStyles(dark, highlightRed, theme)}
        theme={
          dark
            ? {
                colors: {
                  neutral80: "white",
                  neutral20: "white",
                },
              }
            : {}
        }
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        value={value}
        loadOptions={loadOptions}
        {...rest}
      />
    </SelectContainer>
  );
}
