import agencyValuationQuery from "../queries/agencyValuations.graphql";

export const deleteConfig = {
  props: ({ mutate, ownProps: { dealId, id } }) => ({
    deleteAgencyValuation() {
      return mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: agencyValuationQuery,
            variables: {
              id: dealId,
            },
          },
        ],
      });
    },
  }),
};
