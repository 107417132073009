// @flow
import { gql } from "@apollo/client";
import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import { LeafCell } from "components/Grid";
import { SelectField } from "components/SelectField";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import SA_RESPONSIBLE_PEOPLE from "./queries/SAResponsiblePeople.graphql";

const UPDATE_NEST_DEAL = gql`
  mutation UpdateNestDealSA($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      assignedSm {
        id
      }
      leadSaUser {
        id
      }
    }
  }
`;

type Props = {
  dealId: string,
};

const dealMutationHandler = (mutate, deal) => (input: NestDealInput) =>
  mutate({
    variables: { id: deal.id, input },
    optimisticResponse: {
      __typename: "Mutation",
      updateNestDeal: {
        __typename: "NestDeal",
        ...deal,
        ...input,
        assignedSm: {
          __typename: "NestedUser",
          id: input.assignedSmId || deal.assignedSm?.id,
        },
        leadSaUser: {
          __typename: "NestedUser",
          id: input.assignedLeadSaId || deal.leadSaUser?.id,
        },
      },
    },
  });

export const ResponsiblePeopleSelects = ({ dealId }: Props) => (
  <ExtendedQuery query={SA_RESPONSIBLE_PEOPLE} variables={{ dealId }}>
    {({
      activeNestedUsers,
      nestDeal,
    }: ExtendedQueryRenderProps<SAResponsiblePeople>) => {
      return (
        <>
          <Mutation mutation={UPDATE_NEST_DEAL}>
            {(
              mutate: MutationFunction<
                UpdateNestDealSA,
                UpdateNestDealSAVariables,
              >,
            ) => (
              <LeafCell width={1}>
                <SelectField
                  data-test="sa_responsible_people:sales_associate"
                  options={activeNestedUsers}
                  label="SA"
                  property="assignedLeadSaId"
                  mutation={dealMutationHandler(mutate, nestDeal)}
                  value={nestDeal?.leadSaUser?.id}
                />
              </LeafCell>
            )}
          </Mutation>
        </>
      );
    }}
  </ExtendedQuery>
);
