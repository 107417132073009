// @flow
import { css } from "styled-components";
import { Chevron } from "@nested/brand/src/icons";
import { getImage } from "@nested/utils";
import { regularHeading, mediumParagraph, media } from "@nested/brand";
import { SmartLink } from "@nested/component-library";

const sharedButtonStyles = css`
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  padding: 10px 15px 10px 10px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  ${media.tablet`
    padding: 20px 30px 20px 20px;
  `}
  transition: 0.3s ease;
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.hague40};
    transform: translateY(-3px);
  }
`;

const container = css`
  display: flex;
  align-items: center;
`;

const smartLinkStyles = css`
  text-decoration: none;
  ${sharedButtonStyles}
`;

const imageStyles = css`
  margin-right: 10px;
  width: 70px;
  ${media.tablet`
    width: 120px;
    margin-right: 20px;
  `}
`;

const textContent = css`
  padding-right: 25px;
  position: relative;
  flex-grow: 1;
  ${media.tablet`
    padding-right: 30px;
  `}
`;

const mainText = css`
  ${regularHeading}
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  ${media.tablet`
    ${regularHeading}
    margin: 0;
    font-size: 28px;
    line-height: 36px;
  `}
`;

const subText = css`
  display: none;
  ${media.tablet`
    display: block;
    ${mediumParagraph}
    margin: 20px 0 0;
  `}
`;

const clearButtonStyles = css`
  border: none;
  background: none;
  text-align: left;
  ${sharedButtonStyles}
`;

const chevronStyles = css`
  position: absolute;
  height: 28px;
  width: 28px;
  top: 50%;
  right: -6px;
  transform: translateY(-50%) rotate(-90deg);
  fill: ${({ theme }) => theme.palette.hague40};
  ${media.tablet`
    right: -20px;
  `}
`;

type Props = {
  icon?: {
    url: string,
    alt?: string,
  },
  heading: string,
  subtext?: string,
  dataTest?: string,
  linkTo?: string,
  onClick?: () => void,
};

export const ImageCardOption = ({
  dataTest,
  heading,
  icon: { url, alt } = {},
  linkTo,
  onClick,
  subtext,
}: Props) =>
  linkTo ? (
    <SmartLink to={linkTo} data-test={dataTest} css={smartLinkStyles}>
      <div css={container}>
        {url && <img src={getImage(url)} alt={alt} css={imageStyles} />}
        <div css={textContent}>
          <h2 css={mainText}>{heading}</h2>
          {subtext && <p css={subText}>{subtext}</p>}
          <Chevron inverted={false} height={40} css={chevronStyles} />
        </div>
      </div>
    </SmartLink>
  ) : (
    <button onClick={onClick} data-test={dataTest} css={clearButtonStyles}>
      <div css={container}>
        {url && <img src={getImage(url)} alt={alt} css={imageStyles} />}
        <div css={textContent}>
          <h2 css={mainText}>{heading}</h2>
          {subtext && <p css={subText}>{subtext}</p>}
          <Chevron inverted={false} height={40} css={chevronStyles} />
        </div>
      </div>
    </button>
  );
