import styled from "styled-components";

export const ListEntryCard = styled.div`
  background-color: ${({ theme }) => theme.color.background.default};
  border: ${({ highlight = false, theme }) =>
    highlight
      ? `2px solid ${theme.color.secondary.light};`
      : `2px solid ${theme.color.secondary.highlight}`};
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
`;
