// @flow
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";

import { TimelineItem } from "@nested/brand/src/components/Timeline";

import { EditableNote } from "./Notes/EditableNote";
import { PinButton } from "./PinButton";

type Props = {
  event: Timeline_timeline,
  isShareable: boolean,
  hideIcon?: boolean,
};

export const TimelineNote = ({
  event,
  isShareable,
  hideIcon = false,
}: Props) => {
  if (event.__typename !== "TimelineEvent") {
    return null;
  }

  const markNoteAsSharedWithSeller = event.isNoteSharedWithCustomer;

  const { timestamp, userEmail } = event;
  return (
    <TimelineItem
      bodyColor={markNoteAsSharedWithSeller ? "yellow" : undefined}
      color="yellow"
      headingColor={markNoteAsSharedWithSeller ? "yellow" : "sand"}
      icon={faFileAlt}
      markNoteAsSharedWithSeller={markNoteAsSharedWithSeller}
      primaryHeading="Note"
      timestamp={timestamp}
      userEmail={userEmail}
      pinButton={
        event.noteId ? (
          <PinButton noteId={event.noteId} pinned={event.pinned} />
        ) : null
      }
      hideIcon={hideIcon}
    >
      <EditableNote event={event} isShareable={isShareable} />
    </TimelineItem>
  );
};

export const PinnedNote = ({ isShareable, event }: Props) => {
  return (
    // We need to set a key here to ensure all state is reset, rather than relying on
    // the deeper components handling prop changes cleanly (they don't)
    <div css="padding: 10px 15px 0 15px;" key={event.id}>
      <TimelineNote isShareable={isShareable} hideIcon event={event} />
    </div>
  );
};
