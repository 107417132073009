// @flow
import { H2 } from "components/Heading";

import { ListEntryCard } from "components/Card/ListEntryCard";
import { PropertySummary } from "../../PropertyInformationSection/PropertySummary";
import { KeyFeatures } from "../../PropertyInformationSection/KeyFeatures";

export const PropertyDetailsSection = ({ dealId }: { dealId: string }) => (
  <ListEntryCard>
    <H2>Your property details section</H2>
    <KeyFeatures dealId={dealId} />
    <PropertySummary dealId={dealId} />
  </ListEntryCard>
);
