// @flow
import { gql, useQuery, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExchangeAlt,
  faLink,
  faPoundSign,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { PropertyInterests } from "@nest-ui/buyers-nest/buyer/pages/BuyerPage/PropertyInterests/PropertyInterests";
import { media, ClickToEditNestTextInput } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { useState } from "react";
import { BuyerSearchAreaSelect } from "./BuyerSearchAreaSelect";
import { OpenContactsModalButton } from "./Contacts/OpenContactsModalButton";
import { ChangeOnBlurNumberInput } from "../../components/NumberInput/ChangeOnBlurNumberInput";
import { ButtonGroup } from "../../components/ButtonGroup/ButtonGroup";
import { AddressPicker } from "../../components/AddressPicker/AddressPicker";
import { Select } from "../../components/Select/Select";
import { ChangeOnBlurTextArea } from "../../components/TextArea/ChangeOnBlurTextArea";
import { Toggle } from "../../components/Toggle/Toggle";
import { navRefStyle } from "../../components/RightSidePanel/Tasks/TasksButton";
import { CreateDeal } from "../../components/Navigation/CreateLead/CreateDeal";

const BUYER_DETAILS_FRAGMENT = gql`
  fragment BuyerDetailsFragment on Buyer {
    id
    addressId
    leadStatus
    contacts {
      id
      name
      firstName
      lastName
      emails {
        id
        emailAddress
      }
    }
    buyerPosition {
      id
    }
    closeIoLeadId
    financialPosition {
      id
    }
    maxPrice
    minBedrooms
    name
    firstName
    notes
    propertyTypes {
      id
    }
  }
`;

export const BUYER_DETAILS_QUERY = gql`
  query BuyerDetails($id: ID!) {
    buyer(id: $id) {
      id
      ...BuyerDetailsFragment
    }
    buyerPositions {
      value: id
      label
    }
    buyerPropertyTypes {
      value: id
      label
    }
    financialPositions {
      value: id
      label
    }
  }

  ${BUYER_DETAILS_FRAGMENT}
`;

export const BUYER_MERGE = gql`
  mutation BuyerMergeListViews($sourceBuyerId: ID!, $destinationBuyerId: ID!) {
    buyerMerge(
      sourceBuyerId: $sourceBuyerId
      destinationBuyerId: $destinationBuyerId
    ) {
      id
    }
  }
`;

export const UPDATE_BUYER = gql`
  mutation BuyerDetailsUpdateBuyer($id: ID!, $input: BuyerInput!) {
    updateBuyer(id: $id, input: $input) {
      id
      ...BuyerDetailsFragment
    }
  }

  ${BUYER_DETAILS_FRAGMENT}
`;

const scrollContainer = css`
  flex-grow: 1;
  ${media.tablet`
    /* Accounts for fixed buyer bar */
    margin-top: 77px;
  `}
`;

const pageWrapper = css`
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px 20px;
  ${media.tablet`
    padding: 0 20px 30px;
  `}
`;

const headingStyle = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-top: 20px;
`;

const buyerInformationButtonsStyle = css`
  font-weight: 500;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.hague70};
  ${media.tablet`
    display: flex;
    flex-direction: column;
  `}
`;

const mergeButtonStyle = css`
  font-weight: 500;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.hague70};
  padding: 10px 10px 0 0;
`;

const mergeIconStyle = css`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.hague40};
`;

const fieldWrapper = css`
  margin-top: 20px;
  ${media.tablet`
    width: calc(100% * ${({ $ratio }) => $ratio});
    padding-left: 10px;
    min-width: ${({ $minWidth }) => $minWidth};
    flex-grow: ${({ $grow }) => $grow || "unset"};
  `}
`;

const interestsWrapperStyle = css`
  margin: 30px -15px 0;
  padding-bottom: 60px;

  ${media.tablet`
    margin: 20px -20px 0;
    padding-bottom: 0;
  `}
`;

const sectionWrapper = css`
  ${media.tablet`
    display: flex;
  `}
`;

const leftColumnStyle = css`
  ${media.tablet`
    flex-grow: 0;
    flex-shrink: 0;
    width: 150px;
  `}
`;

const rightColumnStyle = css`
  ${media.tablet`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`;

const separatorStyle = css`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  margin: 30px 0 0;
`;

const budgetAndPropertyTypeStyle = css`
  ${media.tablet`
    display: inline-flex; 
    flex-grow: 1;
    width: 66%;
  `}
`;

type Props = {
  buyerId: string,
  buyerPageTopRef: any,
  className?: string,
  pageTopInView: boolean,
};

export const BuyerInformation = ({
  buyerId: id,
  buyerPageTopRef,
  className,
  pageTopInView,
}: Props) => {
  const [showCreateDealModal, setShowCreateDealModal] = useState(false);
  const history = useHistory();
  const { data, loading } = useQuery(BUYER_DETAILS_QUERY, {
    variables: { id },
  });

  const [updateBuyer] = useMutation(UPDATE_BUYER);
  const [mergeBuyer, { error: mergeBuyerMutationError }] =
    useMutation(BUYER_MERGE);

  if ((loading && !data?.buyer) || !id) {
    return null;
  }

  const { buyer, buyerPositions, buyerPropertyTypes, financialPositions } =
    data;

  const propertyTypeOptions = [
    {
      label: "Flats and houses",
      value: buyerPropertyTypes.map(({ value }) => value),
    },
    ...buyerPropertyTypes.map(({ label, value }) => ({
      label,
      value: [value],
    })),
  ];

  const bedroomOptions = ["Stu", 1, 2, 3, 4, 5, 6].map((x) => {
    if (x === "Stu") return { label: "Stu", value: 0 };
    return { label: x, value: x };
  });

  const buyerCurrentlyActive = buyer?.leadStatus === "buyer_active";
  return (
    <div css={scrollContainer} className={className}>
      <div css={pageWrapper}>
        <CreateDeal
          open={showCreateDealModal}
          onClose={() => setShowCreateDealModal(false)}
          formInitialValues={{
            firstName: buyer?.contacts[0]?.firstName,
            lastName: buyer?.contacts[0]?.lastName,
            email: buyer?.contacts[0]?.emails[0]?.emailAddress,
            addressId: buyer?.addressId,
          }}
        />
        <div ref={buyerPageTopRef} inView={pageTopInView} css={navRefStyle} />
        <div css={sectionWrapper}>
          <div css={leftColumnStyle}>
            <h3 css={headingStyle}>Buyer active</h3>
          </div>
          <div css={rightColumnStyle}>
            <div css={fieldWrapper} $ratio={1}>
              <Toggle
                data-test="buyer-status"
                checked={buyerCurrentlyActive}
                onChange={async () => {
                  const newLeadStatus = buyerCurrentlyActive
                    ? "buyer_inactive"
                    : "buyer_active";
                  try {
                    await updateBuyer({
                      variables: {
                        id,
                        input: {
                          leadStatus: newLeadStatus,
                        },
                      },
                      optimisticResponse: {
                        updateBuyer: {
                          __typename: "Buyer",
                          ...buyer,
                          leadStatus: newLeadStatus,
                        },
                      },
                    });
                  } catch (e) {
                    errorHandler(e);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div css={sectionWrapper}>
          <div css={leftColumnStyle}>
            <h3 css={headingStyle}>Buyer information</h3>
            <div css={buyerInformationButtonsStyle}>
              <button
                data-test="convert-buyer-to-seller-button"
                css={mergeButtonStyle}
                onClick={() => setShowCreateDealModal(true)}
              >
                Convert to Seller
                <FontAwesomeIcon css={mergeIconStyle} icon={faExchangeAlt} />
              </button>
              <button
                css={mergeButtonStyle}
                onClick={async () => {
                  // eslint-disable-next-line no-alert
                  const targetId = window.prompt(
                    `Please enter the ID of the buyer you wish to merge ${id} into.\n\nNOTE: Buyer ${id} will be DELETED in the Nest AND in Close. Its information will be merged into the buyer whose ID you supply below`,
                  );
                  if (targetId) {
                    try {
                      const result = await mergeBuyer({
                        variables: {
                          sourceBuyerId: id,
                          destinationBuyerId: targetId,
                        },
                      });
                      if (result?.data?.buyerMerge) {
                        history.push(`${result.data.buyerMerge.id}`);
                      } else {
                        errorHandler(mergeBuyerMutationError);
                      }
                    } catch (e) {
                      errorHandler(e);
                    }
                  }
                }}
              >
                Merge buyers
                <FontAwesomeIcon css={mergeIconStyle} icon={faLink} />
              </button>
              <OpenContactsModalButton buyerId={id} />
            </div>
          </div>

          <div css={rightColumnStyle}>
            <div css={fieldWrapper} $ratio={0.4}>
              <ClickToEditNestTextInput
                className="fs-exclude"
                data-test="buyer-name"
                label="Full name"
                value={buyer.name}
                onChange={(e) => {
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        name: e.target.value,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        name: e.target.value,
                      },
                    },
                  });
                }}
              />
            </div>
            <div css={fieldWrapper} $ratio={0.6}>
              <AddressPicker
                className="fs-exclude"
                data-test="buyer-address"
                label="Current address"
                value={buyer.addressId}
                onChange={(newAddress) =>
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        addressId: newAddress?.id,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        addressId: newAddress?.id,
                      },
                    },
                  })
                }
                valid={Boolean(buyer.addressId)}
              />
            </div>
            <div css={fieldWrapper} $ratio={0.5}>
              <Select
                options={financialPositions}
                value={buyer.financialPosition?.id}
                onChange={(value) =>
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        financialPositionId: value,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        financialPosition: { id: value },
                      },
                    },
                  })
                }
                labelAbove
                label="Financial position"
                valid={Boolean(buyer.financialPosition?.id)}
                data-test="financial-position"
                placeholder="Please select"
              />
            </div>
            <div css={fieldWrapper} $ratio={0.5}>
              <Select
                placeholder="Please select"
                options={buyerPositions}
                value={buyer.buyerPosition?.id}
                onChange={(value) =>
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        buyerPositionId: value,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        buyerPosition: { id: value },
                      },
                    },
                  })
                }
                labelAbove
                label="Buying position"
                valid={Boolean(buyer.buyerPosition?.id)}
                data-test="buyer-position"
              />
            </div>
            <div css={fieldWrapper} $ratio={1}>
              <ChangeOnBlurTextArea
                data-test="buyer-notes"
                label="General notes"
                placeholder="General notes"
                rows={3}
                value={buyer.notes}
                onChange={(e) =>
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        notes: e.target.value,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        notes: e.target.value,
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div css={sectionWrapper}>
          <div css={leftColumnStyle}>
            <h3 css={headingStyle}>Saved search</h3>
          </div>
          <div css={rightColumnStyle}>
            <div css={fieldWrapper} $ratio={1 / 3} $grow={1} $minWidth="200px">
              <ButtonGroup
                label={"Minimum number of beds"}
                options={bedroomOptions}
                value={buyer.minBedrooms}
                valid={buyer.minBedrooms !== null}
                onChange={(value) => {
                  updateBuyer({
                    variables: {
                      id,
                      input: {
                        minBedrooms: value,
                      },
                    },
                    optimisticResponse: {
                      updateBuyer: {
                        ...buyer,
                        minBedrooms: value,
                      },
                    },
                  });
                }}
                data-test="button-group:min-bedrooms"
              />
            </div>
            <div css={budgetAndPropertyTypeStyle}>
              <div css={fieldWrapper} $ratio={0.5}>
                <ChangeOnBlurNumberInput
                  icon={faPoundSign}
                  label="Max budget"
                  data-test="buyer-max-price"
                  value={buyer.maxPrice}
                  placeholder="0"
                  onChange={(value) =>
                    updateBuyer({
                      variables: {
                        id,
                        input: {
                          maxPrice: value,
                        },
                      },
                      optimisticResponse: {
                        updateBuyer: {
                          ...buyer,
                          maxPrice: value,
                        },
                      },
                    })
                  }
                  valid={Boolean(buyer.maxPrice)}
                />
              </div>
              <div css={fieldWrapper} $ratio={0.5}>
                <Select
                  placeholder="Please select"
                  data-test="property-type"
                  labelAbove
                  value={buyer.propertyTypes.map((option) => option.id)}
                  label="Property type"
                  options={propertyTypeOptions}
                  valid={buyer.propertyTypes.length > 0}
                  onChange={(value) => {
                    const newValue = propertyTypeOptions
                      .find((option) => option.value === value)
                      .value.map((optionId) => ({
                        __typename: "BuyerPropertyType",
                        id: optionId,
                      }));
                    updateBuyer({
                      variables: {
                        id,
                        input: {
                          propertyTypeIds: value,
                        },
                      },
                      optimisticResponse: {
                        updateBuyer: {
                          ...buyer,
                          propertyTypes: newValue,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div css={fieldWrapper} $ratio={1}>
              <BuyerSearchAreaSelect buyerId={id} />
            </div>
          </div>
        </div>
        <hr css={separatorStyle} />

        <div css={interestsWrapperStyle}>
          <PropertyInterests buyerId={id} noScroll />
        </div>
      </div>
    </div>
  );
};
