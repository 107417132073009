// @flow
import type { MutationFunction } from "@apollo/client/react/components";
import styled from "styled-components";
import { Grid, LeafCell } from "components/Grid/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitCurrencyField } from "components/CurrencyField/CurrencyField";
import { H3 } from "components/Heading";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { userNameFromCloseId } from "@nested/utils/graphql/users/users";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { UPDATE_PRE_VISIT_VALUATION_MUTATION } from "./valuationsMutation";
import { DeleteValuation } from "./DeleteValuation";

const SubHeading = styled(H3)`
  margin-bottom: 0;
`;

type Props = {
  valuation: *,
  users: $ReadOnlyArray<PreVisitValuationsListQuery_activeNestedUsers>,
  isReadOnly: boolean,
};

const EditableNote = ({ id, notes }: { id: string, notes: string }) => (
  <ExtendedMutation mutation={UPDATE_PRE_VISIT_VALUATION_MUTATION}>
    {(
      mutation: MutationFunction<
        UpdatePreVisitValuation,
        UpdatePreVisitValuationVariables,
      >,
    ) => {
      const updateValuation = (input) =>
        mutation({
          variables: {
            preVisitValuationId: id,
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updatePreVisitValuation: {
              __typename: "PreVisitValuation",
              id,
              ...input,
            },
          },
        });
      return (
        <NoSubmitTextareaWithLinks
          data-test={`valuation-list-notes:${id}`}
          label="Notes"
          value={notes}
          property="notes"
          onSubmit={(val) => updateValuation({ notes: val })}
        />
      );
    }}
  </ExtendedMutation>
);

export const PreVisitValuation = ({ valuation, users, isReadOnly }: Props) => (
  <Grid columns={4}>
    <LeafCell width={1}>
      <NoSubmitDatePicker
        data-test="valuation-list-completed-date"
        label="Valuation Date"
        value={valuation.dateCompleted}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={3}>
      <NoSubmitTextareaWithLinks
        data-test="valuation-list-completed-by"
        label="Completed By"
        value={userNameFromCloseId(valuation.completedBy, users)}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-listing-price-lower"
        label="Estimated List Price Lower"
        value={valuation.estimatedListPriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-listing-price-upper"
        label="Estimated List Price Upper"
        value={valuation.estimatedListPriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-price-best-estimate"
        label="List Price - Best Estimate"
        value={valuation.listPriceBestEstimate}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-va-maximum-list-price"
        label="VA Maximum List Price"
        value={valuation.vaMaximumListPrice}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>

    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-sale-price-lower"
        label="Estimated Sale Price Lower"
        value={valuation.nonNullableEstimatedSalePriceLowerBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-sale-price-upper"
        label="Estimated Sale Price Upper"
        value={valuation.nonNullableEstimatedSalePriceUpperBound}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    <LeafCell width={2}>
      <NoSubmitCurrencyField
        data-test="valuation-list-sale-price-best-estimate"
        label="Estimated Sale Price Best Estimate"
        value={valuation.estimatedSalePriceBestEstimate}
        readOnly={isReadOnly}
        disabled={!isReadOnly}
      />
    </LeafCell>
    {valuation.estimatingAdvance ? (
      <>
        <LeafCell width={4}>
          <SubHeading>Advance Estimates</SubHeading>
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="valuation-list-advance-amount-lower"
            label="Off-track advance"
            value={valuation.estimatedAdvanceAmountLowerBound}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="valuation-list-advance-on-track"
            label="On-track advance"
            value={valuation.estimatedAdvanceOnTrack}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            data-test="valuation-list-advance-amount-upper"
            label="Under Offer advance"
            value={valuation.estimatedAdvanceAmountUpperBound}
            readOnly={isReadOnly}
            disabled={!isReadOnly}
          />
        </LeafCell>
      </>
    ) : (
      <LeafCell width={4}>
        <NoSubmitTextareaWithLinks
          label="Advance"
          value="No Advance Estimated!"
          readOnly={isReadOnly}
          disabled={!isReadOnly}
        />
      </LeafCell>
    )}
    <LeafCell width={4}>
      {isReadOnly ? (
        <NoSubmitTextareaWithLinks
          data-test={`valuation-list-notes:${valuation.id}`}
          label="Notes"
          value={valuation.notes}
          readOnly={isReadOnly}
          disabled={!isReadOnly}
        />
      ) : (
        <EditableNote id={valuation.id} notes={valuation.notes} />
      )}
    </LeafCell>

    {!isReadOnly && (
      <LeafCell width={4}>
        <DeleteValuation id={valuation.id} dealId={valuation.dealId} />
      </LeafCell>
    )}
  </Grid>
);
