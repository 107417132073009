// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { ListEntryCard } from "components/Card";
import { LeafCell } from "components/Grid";
import { resolveInputOptions } from "deal/utils/graphql";
import { ErrorBoundary } from "components/ErrorBoundary";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import moment from "moment-timezone";

import reloadAgreedOrAcceptedOffer from "@nested/nest/src/pages/Deal/queries/reloadAgreedOrAcceptedOffer.graphql";
import reloadDealStatus from "@nested/nest/src/pages/Deal/queries/reloadDealStatus.graphql";

import { BuyerOfferSellerBuyerChain } from "tabs/Interest/BuyerOfferList/BuyerOfferDetails/components/BuyerOfferSellerBuyerChain";
import { BuyerOfferSellerSolicitorInfo } from "tabs/Interest/BuyerOfferList/BuyerOfferDetails/components/BuyerOfferSellerSolicitorInfo";
import { BuyerOfferGeneralInfo } from "./components/BuyerOfferGeneralInfo";
import { BuyerOfferOtherInfo } from "./components/BuyerOfferOtherInfo";
import { BuyerOfferFinanceInfo } from "./components/BuyerOfferFinanceInfo";
import { BuyerOfferBuyerSolicitorInfo } from "./components/BuyerOfferBuyerSolicitorInfo";
import { BuyerOfferAMLInfo } from "./components/BuyerOfferAMLInfo";
import { BuyerOfferSalesMemorandum } from "./components/BuyerOfferSalesMemorandum";

import buyerOfferQuery from "./queries/buyerOffer.graphql";
import updateBuyerOfferMutation from "./updateMutation/updateBuyerOffer.graphql";

const UPDATE_BUYER_OFFER_STATUS = gql`
  mutation UpdateBuyerOfferStatus($id: ID!, $value: String!) {
    updateBuyerOfferStatus(id: $id, value: $value) {
      id
      status {
        label
        value
      }
      acceptedButFallenThroughDate
    }
  }
`;

const buildRefetchQueries = (status, dealId) => {
  const refetchAgreedOrAcceptedOffer = {
    query: reloadAgreedOrAcceptedOffer,
    variables: { dealId },
  };

  const refetchDealStatus = {
    query: reloadDealStatus,
    variables: { dealId },
  };

  return status === "no_longer_accepted"
    ? [refetchAgreedOrAcceptedOffer, refetchDealStatus]
    : [refetchAgreedOrAcceptedOffer];
};

const inputOptionFields = {
  buyerFinanceMethod: "buyerFinanceMethod",
  acceptedOfferFalloutReason: "acceptedOfferFalloutReasons",
};

const inputOptionRefetchFields = {
  status: "statusOptions",
};

export const BuyerOfferDetails = ({
  buyerOfferId,
}: {
  buyerOfferId: ?string,
}) => {
  if (!buyerOfferId) {
    return null;
  }

  const [updateBuyerOffer] = useMutation(updateBuyerOfferMutation);
  const [updateBuyerOfferStatus] = useMutation(UPDATE_BUYER_OFFER_STATUS);
  return (
    <ExtendedQuery
      query={buyerOfferQuery}
      variables={{ id: buyerOfferId }}
      fetchPolicy="cache-and-network"
      nextFetchPolicy="cache-first"
    >
      {(data: ExtendedQueryRenderProps<BuyerOffer>) => {
        if (!data || !data.buyerOffer || !data.inputOptions) return null;
        const {
          buyerOffer,
          inputOptions,
          activeNestedUsers,
          propertyChainLinks,
        } = data;

        const mutation = (input) => {
          const formattedInput = resolveInputOptions(
            input,
            inputOptionFields,
            inputOptions,
          );

          const extra = input.saWhoGotTheOfferId
            ? {
                saWhoGotTheOffer: activeNestedUsers.find(
                  ({ id }) => id === input.saWhoGotTheOfferId,
                ),
              }
            : {};

          return updateBuyerOffer({
            variables: {
              input,
              id: buyerOffer.id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              updateBuyerOffer: {
                __typename: "BuyerOffer",
                id: buyerOffer.id,
                ...buyerOffer,
                ...formattedInput,
                ...extra,
              },
            },
          });
        };

        const updateStatusMutation = (input) => {
          const formattedInput = resolveInputOptions(
            input,
            inputOptionRefetchFields,
            {
              statusOptions: inputOptions.buyerOfferStatus,
            },
          );
          return updateBuyerOfferStatus({
            variables: {
              value: input?.status,
              id: buyerOffer.id,
            },
            refetchQueries: buildRefetchQueries(
              input?.status,
              buyerOffer.dealId,
            ),
            optimisticResponse: {
              __typename: "Mutation",
              updateBuyerOfferStatus: {
                __typename: "BuyerOffer",
                id: buyerOffer.id,
                ...formattedInput,
                acceptedButFallenThroughDate: moment(),
              },
            },
          });
        };

        return (
          <ErrorBoundary>
            <ListEntryCard>
              <BuyerOfferGeneralInfo
                buyerOffer={buyerOffer}
                id="BuyerOfferGeneralInfo"
                inputOptions={inputOptions}
                mutation={mutation}
                propertyChainLinks={propertyChainLinks}
                refetchMutation={updateStatusMutation}
              />
            </ListEntryCard>
            <ListEntryCard>
              <BuyerOfferOtherInfo
                buyerOffer={buyerOffer}
                id="BuyerOfferOtherInfo"
                inputOptions={inputOptions}
                activeNestedUsers={activeNestedUsers}
                mutation={mutation}
              />
            </ListEntryCard>
            <ListEntryCard>
              <BuyerOfferBuyerSolicitorInfo
                buyerOffer={buyerOffer}
                id="BuyerOfferSolicitorInfo"
                mutation={mutation}
              />
            </ListEntryCard>
            <ListEntryCard>
              <BuyerOfferSellerSolicitorInfo dealId={buyerOffer.dealId} />
            </ListEntryCard>

            <ListEntryCard>
              <BuyerOfferFinanceInfo
                buyerOffer={buyerOffer}
                id="BuyerOfferFinanceInfo"
                mutation={mutation}
              />
            </ListEntryCard>
            <ListEntryCard>
              <BuyerOfferSellerBuyerChain
                dealId={buyerOffer.dealId}
                buyerOfferId={buyerOffer.id}
              />
            </ListEntryCard>
            <ListEntryCard>
              <BuyerOfferAMLInfo
                buyerOffer={buyerOffer}
                id="BuyerOfferAMLInfo"
                mutation={mutation}
              />
            </ListEntryCard>
            <ListEntryCard>
              <LeafCell width={4}>
                <BuyerOfferSalesMemorandum
                  buyerOffer={buyerOffer}
                  id="BuyerOfferSalesMemorandum"
                  mutation={mutation}
                />
              </LeafCell>
            </ListEntryCard>
          </ErrorBoundary>
        );
      }}
    </ExtendedQuery>
  );
};
