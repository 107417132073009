// @flow
import { Grid, Cell, LeafCell } from "components/Grid";
import { Heading } from "components/Heading";
import { TextField } from "components";
import { gql, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { mergeDeepRight } from "ramda";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { Loader } from "components/Loader";

export const GET_SOLICITOR_INFO = gql`
  query KeyContactsSolicitorSectionQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      solicitorOnNestedPanel
      leadSolicitor {
        ... on NestedPanelLeadSolicitor {
          id
        }
        name
        telephone
        email
        solicitorFirm {
          ... on NestedPanelSolicitorFirm {
            id
          }
          name
          address
          documentExchange
        }
      }
    }
  }
`;

const UPDATE_SOLICITOR_INFO = gql`
  mutation UpdateKeyContactsSolicitor($input: NestDealInput!, $id: ID!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      solicitorOnNestedPanel
      leadSolicitor {
        ... on NestedPanelLeadSolicitor {
          id
        }
        name
        telephone
        email
        solicitorFirm {
          ... on NestedPanelSolicitorFirm {
            id
          }
          name
          address
          documentExchange
        }
      }
    }
  }
`;

type Props = {
  dealId: string,
  readOnly?: boolean,
};

export const BuyerOfferSellerSolicitorInfo = ({
  dealId,
  readOnly = false,
}: Props) => {
  const { loading, data } = useQuery(GET_SOLICITOR_INFO, {
    variables: {
      dealId,
    },
  });

  const [updateSolicitorInfoMutation] = useMutation(UPDATE_SOLICITOR_INFO);

  if (loading) {
    return <Loader />;
  }

  const { leadSolicitor, solicitorOnNestedPanel } = data?.nestDeal;

  const updateSolicitorInfo = async (input, optimisticResponsePart) => {
    await updateSolicitorInfoMutation({
      variables: {
        id: dealId,
        input,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: mergeDeepRight(data?.nestDeal, {
          ...optimisticResponsePart,
        }),
      },
    });
  };

  return (
    <Cell width={4}>
      <Grid columns={4}>
        <LeafCell width={4}>
          <Heading level={2}>Seller's solicitor</Heading>
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Seller's solicitor firm"
            property="solicitorFirm"
            value={leadSolicitor?.solicitorFirm?.name}
            disabled={solicitorOnNestedPanel}
            mutation={(input) =>
              updateSolicitorInfo(input, {
                leadSolicitor: {
                  solicitorFirm: {
                    name: input.solicitorFirm,
                  },
                },
              })
            }
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Seller's lead solicitor"
            value={leadSolicitor?.name}
            disabled={solicitorOnNestedPanel}
            property="leadSolicitor"
            mutation={(input) =>
              updateSolicitorInfo(input, {
                leadSolicitor: {
                  name: input.leadSolicitor,
                },
              })
            }
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            className="fs-exclude"
            label="Seller's solicitor phone number"
            value={leadSolicitor?.telephone}
            disabled={solicitorOnNestedPanel}
            property="solicitorPhoneNumber"
            mutation={(input) =>
              updateSolicitorInfo(input, {
                leadSolicitor: {
                  telephone: input.solicitorPhoneNumber,
                },
              })
            }
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextareaWithLinks
            data-test={"lol"}
            className="fs-exclude"
            label="Seller's solicitor email"
            value={leadSolicitor?.email}
            disabled={solicitorOnNestedPanel}
            property="solicitorEmail"
            mutation={(input) =>
              updateSolicitorInfo(input, {
                leadSolicitor: {
                  email: input.solicitorEmail,
                },
              })
            }
            readOnly={readOnly}
          />
        </LeafCell>
        <LeafCell width={4}>
          <TextField
            className="fs-exclude"
            label="Seller's solicitor address"
            value={leadSolicitor?.solicitorFirm?.address}
            disabled={solicitorOnNestedPanel}
            property="solicitorAddress"
            mutation={(input) =>
              updateSolicitorInfo(input, {
                leadSolicitor: {
                  solicitorFirm: {
                    address: input.solicitorAddress,
                  },
                },
              })
            }
            readOnly={readOnly}
          />
        </LeafCell>
      </Grid>
    </Cell>
  );
};
