// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";

import { TextareaWithLinks } from "components/TextareaWithLinks";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

const Wrapper = styled.div`
  margin-top: 16px;
`;

const GET_PROPERTY_SUMMARY_QUERY = gql`
  query GetPropertySummary($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      property {
        id
        summaryForPropertyAnalysis
      }
    }
  }
`;

const UPDATE_PROPERTY_SUMMARY_MUTATION = gql`
  mutation UpdatePropertySummary($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      summaryForPropertyAnalysis
    }
  }
`;

type Props = {
  dealId: string,
};

export const PropertySummary = ({ dealId }: Props) => (
  <ExtendedQuery query={GET_PROPERTY_SUMMARY_QUERY} variables={{ dealId }}>
    {({
      nestDeal: {
        property: { id: propertyId, summaryForPropertyAnalysis },
      },
    }: ExtendedQueryRenderProps<GetPropertySummary>) => (
      <Wrapper>
        <ExtendedMutation mutation={UPDATE_PROPERTY_SUMMARY_MUTATION}>
          {(mutation) => (
            <TextareaWithLinks
              label="Summary (customer facing!)"
              mulitline
              value={summaryForPropertyAnalysis}
              property="summaryForPropertyAnalysis"
              data-test="summary-for-property-analysis"
              mutation={(input) =>
                mutation({
                  variables: { id: propertyId, input },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateProperty: {
                      __typename: "Property",
                      id: propertyId,
                      ...input,
                    },
                  },
                })
              }
            />
          )}
        </ExtendedMutation>
      </Wrapper>
    )}
  </ExtendedQuery>
);
