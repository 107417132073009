// @flow
import { useEffect, useState } from "react";
import moment from "moment";
import { SavedNote } from "./SavedNote";
import { NewNoteInput } from "./NewNoteInput";
import { useUser } from "../../../../../hooks/useUser";
import { inferNoteRelationType } from "../../utils";

export const isEditableNote = (timestamp: string, isSameUser: boolean) =>
  isSameUser && moment().diff(timestamp, "days") < 1;

type Props = {
  event: Timeline_timeline,
  isShareable: boolean,
};

export const EditableNote = ({ event, isShareable }: Props) => {
  if (event.__typename !== "TimelineEvent") {
    return null;
  }

  const { userEmail, content, timestamp, sourceId, sourceTable } = event;
  const {
    currentUser: { email },
  } = useUser();
  const isSameUser = email === userEmail;

  const [editable, setEditable] = useState(
    isEditableNote(timestamp, isSameUser),
  );
  // Interval below checks the diff between time now and timestamp every 10 seconds.
  // This is to make sure that we disable editing/creation of a note as soon as 24 hours have elapsed.
  useEffect(() => {
    const interval = setInterval(() => {
      setEditable(isEditableNote(timestamp, isSameUser));
    }, 10000);

    return () => clearInterval(interval);
  }, []);
  if (content)
    return (
      <SavedNote
        event={event}
        allowEditing={editable}
        isShareable={isShareable}
      />
    );

  if (!content && editable)
    return (
      <NewNoteInput
        noteRelationType={inferNoteRelationType(sourceTable)}
        relationId={sourceId}
        withPlaceHolder
        alwaysShowEditBar={false}
        isShareable={isShareable}
      />
    );
  return null;
};
