import T from "prop-types";
import { compose } from "recompose";

import { withFormattedDate } from "@nest-ui/hocs";
import { TextField, NoSubmitTextField } from "components/TextField";

const MonthPickerComponent = (props) => <TextField {...props} type="month" />;

MonthPickerComponent.propTypes = {
  value: T.string,
};

const enhance = compose(withFormattedDate("YYYY-MM-DD", "YYYY-MM"));

export const MonthPicker = enhance(MonthPickerComponent);

const NakedMonthPicker = (props) => (
  <NoSubmitTextField {...props} type="month" />
);

export const NoSubmitMonthPicker = enhance(NakedMonthPicker);
