// Bespoke theme for the Comparables section of the nest. This may be used more
// widely in the Nest in the future, but for now it's isolated so merely
// applying a custom theme for this section

import { theme as mainTheme } from "@nest-ui/styles";

export const theme = {
  background: {
    card: "#353F52",
    cardHover: "#444851",
    csvInputSection: "#2E3645",
    header: "#495263",
    listItem: "#5c687f",
    mainSection: "#171D28",
    radioButton: "#586174",
    ratingChip: "#45526B",
  },
  border: {
    cardUnselected: "solid 2px transparent",
    cardSelected: "solid 2px #10B186",
    radius: "4px",
  },
  button: {
    grey: "#8590A6",
    greyHover: "#687386",
    green: "#10B186",
  },
  // These primary theme colors have been overriden *purely* to make
  // radiobuttons appropriate for our 50 shades of grey theme. They may make
  // other components look weird if you pull them in, so please be aware of
  // this initial intention
  color: {
    ...mainTheme.color,
    background: {
      ...mainTheme.color.background,
      button: "#586174",
    },
    primary: {
      ...mainTheme.color.primary,
      accent: "#717d95",
      dark: "#a7afc0",
      highlight: "#848FA6",
    },
    secondary: {
      ...mainTheme.color.secondary,
      highlight: "#697180",
    },
  },
  icon: {
    positive: "#7ED321",
    neutral: "#F5A623",
    negative: "#D0021B",
  },
  textField: {
    background: "#495263",
    border: "#697180",
    borderFocus: "#848FA6",
    text: "#FFFFFF",
  },
  text: {
    grey: "#8D9AB1",
    white: "#FFFFFF",
  },
};
