// @flow
import { createContext } from "react";
import { gql } from "@apollo/client";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { useQuery, useSubscription } from "@apollo/client/react/hooks";

export const TASK_COUNT = gql`
  query ToDoTaskCount($email: String!) {
    agentTaskCounts {
      today(email: $email) {
        due
      }
      upcoming(email: $email) {
        due
      }
    }
  }
`;

export const TODAY_TASK_COUNT_SUBSCRIPTION = gql`
  subscription AgentTaskTodayCountChanged($email: String!) {
    agentTaskTodayCountChanged(email: $email) {
      due
    }
  }
`;

export const UPCOMING_TASK_COUNT_SUBSCRIPTION = gql`
  subscription AgentTaskUpcomingCountChanged($email: String!) {
    agentTaskUpcomingCountChanged(email: $email) {
      due
    }
  }
`;

export const CHANGED_TASK_SUBSCRIPTION = gql`
  subscription AgentTaskForUserChanged($email: String!) {
    agentTaskForUserChanged(email: $email) {
      changedAt
    }
  }
`;

const calculateDueToday = (initialData, updatedData) => {
  if (Number.isInteger(updatedData?.agentTaskTodayCountChanged?.due)) {
    return updatedData?.agentTaskTodayCountChanged?.due;
  }

  return initialData?.agentTaskCounts?.today?.due;
};

const calculateUpcoming = (initialData, updatedData) => {
  if (Number.isInteger(updatedData?.agentTaskUpcomingCountChanged?.due)) {
    return updatedData?.agentTaskUpcomingCountChanged?.due;
  }

  return initialData?.agentTaskCounts?.upcoming?.due;
};

type TaskCounters = {
  dueToday: ?number,
  upcoming: ?number,
  taskChanged: ?DateTime,
};

export const TaskCounterContext = createContext<TaskCounters>({});

export const TaskCounterProvider = ({ children }: { children: React$Node }) => {
  const { selectedUser } = useUser();

  const { data: initialData } = useQuery(TASK_COUNT, {
    variables: { email: selectedUser.email },
  });

  const { data: updatedToday } = useSubscription(
    TODAY_TASK_COUNT_SUBSCRIPTION,
    {
      variables: { email: selectedUser.email },
    },
  );

  const { data: updatedUpcoming } = useSubscription(
    UPCOMING_TASK_COUNT_SUBSCRIPTION,
    {
      variables: { email: selectedUser.email },
    },
  );

  const { data: taskChanged } = useSubscription(CHANGED_TASK_SUBSCRIPTION, {
    variables: { email: selectedUser.email },
  });

  return (
    <TaskCounterContext.Provider
      value={{
        dueToday: calculateDueToday(initialData, updatedToday),
        upcoming: calculateUpcoming(initialData, updatedUpcoming),
        taskChanged: taskChanged?.agentTaskForUserChanged?.changedAt,
      }}
    >
      {children}
    </TaskCounterContext.Provider>
  );
};
