// @flow
import type { ContextRouter } from "react-router";
import { Fragment, useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { H1 } from "components/Heading/H1";
import { H2 } from "components/Heading";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { DangerButton } from "components/Buttons";

const SELLER_DETAILS = gql`
  query SellerGdprDetails($id: ID!) {
    nestDeal(id: $id) {
      id
      contacts {
        id
        firstName
        lastName
        emailAddresses
        telephoneNumbers
      }
    }
  }
`;

const BUYER_DETAILS = gql`
  query BuyerGdprDetails($id: ID!) {
    buyer(id: $id) {
      id
      contacts {
        id
        firstName
        lastName
        emailAddresses
        telephoneNumbers
      }
    }
  }
`;

const GDPR_REQUEST = gql`
  mutation GdprRequest($input: GdprInput!) {
    gdprRequest(input: $input)
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const CenteredButton = styled(DangerButton)`
  margin: 0 auto;
`;

const GdprCommon = ({ contacts, relationId, relationType }) => {
  return (
    <div css="width: 100%;" className="fs-exclude">
      <Centered>
        <H1 data-test="gdpr-heading">
          The button below will GDPR the following customers, emails and phone
          numbers:
        </H1>
      </Centered>
      <Centered>
        {contacts.map((contact) => {
          const { emailAddresses, telephoneNumbers, firstName, lastName, id } =
            contact;
          return (
            <Fragment key={id}>
              <H2>
                {firstName} {lastName}
              </H2>
              {emailAddresses.map((email) => (
                <p key={email} data-test={email}>
                  {email}
                </p>
              ))}
              {telephoneNumbers.map((number) => (
                <p key={number} data-test={number}>
                  {number}
                </p>
              ))}
            </Fragment>
          );
        })}
      </Centered>
      <GdprButton
        contacts={contacts}
        relationId={relationId}
        relationType={relationType}
      />
    </div>
  );
};

export const GdprButton = ({
  contacts,
  relationType,
  relationId,
}: {
  contacts: Array<{ id: string, name: string }>,
  relationType: string,
  relationId: string | number,
}) => {
  const [isCompleted, setCompleted] = useState(false);

  const contactIds = contacts.map(({ id }) => id);

  return (
    <ExtendedMutation
      mutation={GDPR_REQUEST}
      onCompleted={({ gdprRequest }) => {
        if (gdprRequest === "Success") {
          setCompleted(true);
        }
      }}
    >
      {(gdprMutation) => (
        <CenteredButton
          disabled={isCompleted}
          data-test="gdpr-button"
          onClick={async () => {
            // eslint-disable-next-line no-alert
            const confirmation = window.confirm(
              "This will permanently anonymise ALL of the contact details shown here. Are you sure?",
            );

            if (confirmation) {
              await gdprMutation({
                variables: {
                  input: {
                    contactIds,
                    relationType,
                    relationId,
                  },
                },
              });
            }
          }}
        >
          {isCompleted
            ? "Successfully GDPR'd!"
            : `GDPR This ${relationType === "DEAL" ? "Seller" : "Buyer"}`}
        </CenteredButton>
      )}
    </ExtendedMutation>
  );
};

export const SellerGdpr = (props: ContextRouter) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return (
    <ExtendedQuery query={SELLER_DETAILS} variables={{ id }}>
      {(response) => {
        const {
          nestDeal: { contacts },
        } = response;

        return (
          <GdprCommon
            data-test="SellerGdpr"
            contacts={contacts}
            relationType="DEAL"
            relationId={id}
          />
        );
      }}
    </ExtendedQuery>
  );
};

export const BuyerGdpr = (props: ContextRouter) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  return (
    <ExtendedQuery query={BUYER_DETAILS} variables={{ id }}>
      {(response) => {
        const {
          buyer: { contacts },
        } = response;
        return (
          <GdprCommon
            data-test="BuyerGdpr"
            contacts={contacts}
            relationType="BUYER"
            relationId={id}
          />
        );
      }}
    </ExtendedQuery>
  );
};
