import guaranteePaymentQuery from "../queries/guaranteePayments.graphql";

export const createConfig = {
  props: ({
    mutate,
    ownProps: {
      data: { nestDeal },
    },
  }) => ({
    createGuaranteePayment: () =>
      mutate({
        variables: {
          id: Number(nestDeal.id),
        },
        refetchQueries: [
          {
            query: guaranteePaymentQuery,
            variables: {
              id: nestDeal.id,
            },
          },
        ],
      }),
  }),
};
