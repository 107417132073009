import { useState, useEffect } from "react";
import styled from "styled-components";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { Modal } from "components/Modal";
import { AddButton } from "components/Buttons";
import { media } from "@nested/brand";
import { logException } from "@nested/utils/sentry";
import { Loader } from "components/Loader/Loader";
import { CreateCustomerAccountForm } from "./CreateCustomerAccountForm";

export const CREATE_ACCOUNT_DEAL_DETAILS = gql`
  query CreateAccountDealDetails($id: ID!) {
    nestDeal(id: $id) {
      id
      ownerName
      customerAccountId
    }
  }
`;

const CREATE_CUSTOMER_ACCOUNT_FOR_DEAL = gql`
  mutation CreateCustomerAccountForDeal(
    $input: CreateCustomerAccountForDealInput!
  ) {
    createCustomerAccountForDeal(input: $input) {
      successful
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -8px;
  ${media.tablet`
    margin: 0;
  `}
`;

const Box = styled.div`
  label,
  p {
    color: ${({ theme }) => theme.color.primary.dark};
  }
`;

const StyledButton = styled(AddButton)`
  margin: 0px;
  margin-left: 10px;
`;

const P = styled.p`
  color: ${({ theme }) => theme.color.primary.dark} !important;
  margin-bottom: 30px !important;
`;

export const CreateCustomerAccount = ({ dealId, pollInterval = 1000 }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [mutation] = useMutation(CREATE_CUSTOMER_ACCOUNT_FOR_DEAL);
  const { data, loading, startPolling, stopPolling, error } = useQuery(
    CREATE_ACCOUNT_DEAL_DETAILS,
    {
      variables: {
        id: dealId,
      },
    },
  );

  useEffect(() => {
    if (data?.nestDeal?.customerAccountId) {
      stopPolling();
    }
  });

  const onSubmit = async ({ firstName, lastName, email }) => {
    try {
      const {
        data: { createCustomerAccountForDeal },
      } = await mutation({
        variables: {
          input: {
            dealId,
            firstName,
            lastName,
            email,
          },
        },
      });

      if (!createCustomerAccountForDeal?.successful) {
        // Currently the API doesn't support this scenario, so if we hit this then I have no idea what went wrong...
        throw new Error(
          "Failed to create customer account due to an unexpected error. Please try again later or contact helpdesk if the problem continues",
        );
      }

      /*
       * Because account provisioning runs in an async job, we need to poll for the deal change in order to remove the button from the sidebar.
       * The useEffect hook above handles cancelling this poll when the account has been created
       */
      startPolling(pollInterval);
      setSuccessful(true);
    } catch (e) {
      logException(e);
      window.alert("Failed to create customer account: \n\n" + e.message); // eslint-disable-line
    }
  };

  if (error) {
    throw error;
  }

  if (loading || !data?.nestDeal) {
    return null;
  }

  const { nestDeal } = data;

  return (
    <>
      <Box>
        <Modal
          title="Create customer account"
          closeModal={() => setModalOpen(false)}
          isOpen={modalOpen}
          buttonLabel="Close"
        >
          {successful && !nestDeal.customerAccountId && (
            <>
              <P data-test="create-account-waiting">
                Processing account creation. This may take a few seconds while
                we provision the account and send a welcome email. If it hasn't
                finished within a few minutes, please contact #tech-support on
                slack
              </P>
              <Loader />
            </>
          )}
          {successful && nestDeal.customerAccountId && (
            <P data-test="create-account-success">
              Account successfully provisioned and welcome email sent!
            </P>
          )}
          {!successful && (
            <>
              <CreateCustomerAccountForm
                onSubmit={onSubmit}
                nestDeal={nestDeal}
              />
            </>
          )}
        </Modal>
      </Box>
      {!(successful || nestDeal.customerAccountId) && (
        <Wrapper>
          This deal doesn't have a customer account yet
          <StyledButton
            data-test="create-account-open-modal"
            onClick={() => setModalOpen(true)}
          >
            Create
          </StyledButton>
        </Wrapper>
      )}
    </>
  );
};
