// @flow
import { SelectField } from "components/SelectField";
type StatusResult = {
  label: string,
  value: string,
  disabled?: boolean,
};

// If the opportunity status is not in the list of active statuses (because it is no
// longer used, e.g. S02), we append it to the end of the list as a disabled option
// so that the text of the old status still shows.
export const addCurrentStatusIfNotInList = (
  currentStatus: ?OpportunityStatusSelect_nestDeal_opportunityStatus,
  allStatuses: $ReadOnlyArray<OpportunityStatusSelect_opportunityStatuses>,
): StatusResult[] => {
  const statusesCopy = allStatuses.map(({ label, valueText }) => ({
    label,
    value: valueText,
  }));
  const valueInList = statusesCopy.some(
    ({ value }) => value === currentStatus?.valueText,
  );
  if (!currentStatus || valueInList) {
    return statusesCopy;
  }
  return statusesCopy.concat([
    {
      label: currentStatus.label,
      value: currentStatus.valueText,
      disabled: true,
    },
  ]);
};

type Props = {
  dealId: string,
  opportunityStatus: ?OpportunityStatusSelect_nestDeal_opportunityStatus,
  opportunityStatuses: $ReadOnlyArray<OpportunityStatusSelect_opportunityStatuses>,
  updateOpportunityStatus: (input: any) => void,
};

export const OpportunityStatusSelect = ({
  dealId,
  opportunityStatus,
  opportunityStatuses,
  updateOpportunityStatus,
}: Props) => {
  const statuses = addCurrentStatusIfNotInList(
    opportunityStatus,
    opportunityStatuses,
  );

  return (
    <SelectField
      data-test="opportunity-status-dropdown"
      dark
      options={statuses}
      value={opportunityStatus?.valueText}
      property="opportunityStatus"
      mutation={async (input) => {
        const newStatus = opportunityStatuses.find(
          ({ valueText }) => valueText === input.opportunityStatus,
        );

        return updateOpportunityStatus({
          variables: {
            id: dealId,
            value: input.opportunityStatus,
          },
          optimisticResponse: {
            updateDealOpportunityStatus: {
              __typename: "NestDeal",
              id: dealId,
              opportunityStatus: {
                ...newStatus,
              },
            },
          },
        });
      }}
      nullable={false}
      showSpinner={false}
    />
  );
};
