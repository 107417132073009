// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { ProgressorSelect } from "./ProgressorSelect/ProgressorSelect";
import { ExchangeAndCompleteDates } from "./ExchangeAndCompleteDates/ExchangeAndCompleteDates";
import { BuyerDetails } from "./BuyerDetails/BuyerDetails";
import { SalePrice } from "./SalePrice/SalePrice";
import { CurrentOfferPrice } from "./CurrentOfferPrice/CurrentOfferPrice";
import { BuyerChain } from "./BuyerChain/BuyerChain";

const wrapperStyle = css`
  ${media.tablet`
    align-items: end;
    border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  `}
`;

const buyerAndChainStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 57px;
  justify-content: space-between;
  ${media.tablet`
    justify-content: start;
    
  `}
`;

const progressorAndDatesStyle = css`
  & > * {
    margin-bottom: 14px;
  }

  ${media.tablet`
    display: grid;
    gap: 20px;
    grid-template-columns: 40% calc(60% - 20px);
  `}
`;

const buyerAndPricesStyle = css`
  & > * {
    margin-bottom: 14px;
  }

  align-items: center;
  ${media.tablet`
    display: grid;
    gap: 20px;
    grid-template-columns: 40% calc(30% - 20px) calc(30% - 20px);
  `}
`;

type Props = { dealId: string, buyerOfferId: ?string };

export const ProgressionSummary = ({ dealId, buyerOfferId }: Props) => {
  return (
    <div css={wrapperStyle} id="progression-summary">
      <div css={progressorAndDatesStyle}>
        <ProgressorSelect id={dealId} />
        <ExchangeAndCompleteDates buyerOfferId={buyerOfferId} />
      </div>
      {buyerOfferId && (
        <div css={buyerAndPricesStyle}>
          <div css={buyerAndChainStyle}>
            <BuyerDetails buyerOfferId={buyerOfferId} />
            <BuyerChain dealId={dealId} buyerOfferId={buyerOfferId} />
          </div>
          <CurrentOfferPrice buyerOfferId={buyerOfferId} />
          <SalePrice id={dealId} />
        </div>
      )}
    </div>
  );
};
