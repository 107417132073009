// @flow

import styled from "styled-components";

import { ColoredDot } from "./RatingButtons";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

const Chip = styled.div`
  margin-left: 8px;
  display: flex;
  background-color: ${({ theme, lightTheme }) =>
    lightTheme ? theme.color.secondary.highlight : theme.background.ratingChip};
  padding: 0 8px;
  height: 24px;
  border-radius: 12px;
  & > div:nth-of-type(2) {
    border-left: 1px solid
      ${({ theme, lightTheme }) =>
        lightTheme ? theme.color.secondary.highlight : theme.background.card};
    margin-left: 8px;
    padding-left: 8px;
  }
`;

const RatingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VerticalOffset = styled.div`
  margin-bottom: 2px;
`;

export const comparabilityRatings = {
  PLUS_PLUS: { label: "++", color: "#7ED321" },
  PLUS: { label: "+", color: "#A6BA67" },
  EQUALS: { label: "=", color: "#F5A623" },
  MINUS: { label: "-", color: "#F57223" },
  MINUS_MINUS: { label: "--", color: "#D0021B" },
};

export const relevanceRatings = {
  GOOD: { label: "Good", color: "#7ED321" },
  AVERAGE: { label: "Average", color: "#F5A623" },
  POOR: { label: "Poor", color: "#D0021B" },
};

const Rating = ({ selectedRating, ratingOptions }) => {
  if (!selectedRating || !ratingOptions[selectedRating]) return "";

  const { color, label } = ratingOptions[selectedRating];

  return (
    <RatingButton>
      <ColoredDot color={color} />
      <VerticalOffset>{label}</VerticalOffset>
    </RatingButton>
  );
};

export const RatingsChip = ({
  comparabilityRating,
  relevanceRating,
  lightTheme,
}: {
  comparabilityRating: ?string,
  relevanceRating: ?string,
  lightTheme?: boolean,
}) => (
  <Container>
    Comp Rating:
    {comparabilityRating || relevanceRating ? (
      <Chip lightTheme={lightTheme}>
        {comparabilityRating && (
          <Rating
            ratingOptions={comparabilityRatings}
            selectedRating={comparabilityRating}
            data-test={`comparability-rating-chip:${comparabilityRating}`}
          />
        )}
        {relevanceRating && (
          <Rating
            ratingOptions={relevanceRatings}
            selectedRating={relevanceRating}
            data-test={`relevance-rating-chip:${relevanceRating}`}
          />
        )}
      </Chip>
    ) : (
      " None yet"
    )}
  </Container>
);
