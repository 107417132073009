import styled from "styled-components";

export const InfoBlob = styled.div`
  position: absolute;
  line-height: 1;
  font-weight: 600;
  bottom: 0px;
  left: 0px;
  background-color: #ffd28b;
  padding: 10px;
  border-radius: 0 20px 20px 3px;
  width: auto;
`;
