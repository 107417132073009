// @flow
import { useState, useRef, useEffect } from "react";
import {
  faPlus,
  faSign,
  faShoppingCart,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { ContactTypeLink as ActionLink } from "../ContactDrawer/ContactDrawerShared";
import { Drawer } from "../../Drawer/Drawer";
import { PopupBox } from "../../PopupBox";
import { CreateBuyer } from "./CreateBuyer";
import { CreateDeal } from "./CreateDeal";

const actionLinkStyle = css`
  font-size: 14px;
  padding: 0;
  line-height: 30px;
  padding: 4px 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.palette.hague40};
  }

  :hover {
    background: ${({ theme }) => theme.palette.blue20};
  }
`;

export const createLeadButtonStyle = css`
  background-color: ${({ theme }) => theme.palette.hague150};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.hague70};
  color: ${({ theme }) => theme.palette.hague20};
  cursor: pointer;
  height: 30px;
  min-width: 30px;
  padding: 0;
  width: 30px;
  margin-right: 15px;
  ${media.tablet`
    display: inline-block;
      margin-right: 15px;

  `};
`;

const desktopOnly = css`
  display: none;
  ${media.tablet`
    display: block;  
  `}
`;

const drawerStyle = css`
  & > div:first-of-type {
    padding-bottom: 0;
  }
`;

const iconStyle = css`
  align-items: center;
  background-color: ${({ $isSellerIcon, theme }) =>
    $isSellerIcon ? theme.palette.pink70 : theme.palette.yellow20};
  border-radius: 50%;
  display: flex;
  flex: 0 0 auto;
  height: 40px;
  justify-content: center;
  margin-right: 15px;
  width: 40px;

  & svg {
    color: ${({ $isSellerIcon, theme }) =>
      $isSellerIcon ? theme.palette.pink300 : theme.palette.yellow150};
  }
`;

const mobileIconWrapper = css`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.blue150};
  display: flex;
  margin-bottom: 20px;
  padding-left: 20px;
  width: 100%;
`;

const mobileOnly = css`
  ${media.tablet`
    display: none;
  `}
`;

const popupStyle = css`
  top: 36px;
  right: -68px;
  width: 200px;
`;

type CreateLeadsProps = {
  isOpen: boolean,
  onClose: () => void,
};

type LinksProps = {
  openSellerModal: () => void,
  openBuyerModal: () => void,
};

const MobileLinks = ({
  isOpen,
  onClose,
  openSellerModal,
  openBuyerModal,
}: LinksProps & CreateLeadsProps) => {
  return (
    <Drawer
      drawerOpen={isOpen}
      heading="Add new"
      closeDrawer={() => onClose()}
      css={drawerStyle}
    >
      <button
        onClick={() => {
          onClose();
          openSellerModal();
        }}
        css={mobileIconWrapper}
      >
        <span $isSellerIcon css={iconStyle}>
          <FontAwesomeIcon icon={faSign} />
        </span>
        <span css={actionLinkStyle}>Add new seller</span>
      </button>
      <button
        onClick={() => {
          onClose();
          openBuyerModal();
        }}
        css={mobileIconWrapper}
      >
        <span css={iconStyle}>
          <FontAwesomeIcon icon={faShoppingCart} />
        </span>
        <span css={actionLinkStyle}>Add new buyer</span>
      </button>
    </Drawer>
  );
};

const DesktopLinks = ({
  isOpen,
  onClose,
  openSellerModal,
  openBuyerModal,
}: LinksProps & CreateLeadsProps) => {
  return (
    <PopupBox triangleRightOffset="90px" isOpen={isOpen} css={popupStyle}>
      <ActionLink
        css={actionLinkStyle}
        onClick={() => {
          onClose();
          openSellerModal();
        }}
      >
        Add new seller
        <FontAwesomeIcon icon={faCaretRight} />
      </ActionLink>
      <ActionLink
        css={actionLinkStyle}
        onClick={() => {
          onClose();
          openBuyerModal();
        }}
      >
        Add new buyer
        <FontAwesomeIcon icon={faCaretRight} />
      </ActionLink>
    </PopupBox>
  );
};

const CreateBuyerOrSeller = ({ isOpen, onClose }: CreateLeadsProps) => {
  const [sellerModalOpen, setSellerModalOpen] = useState(false);
  const [buyerModalOpen, setBuyerModalOpen] = useState(false);

  return (
    <>
      <div css={mobileOnly}>
        <MobileLinks
          openSellerModal={() => setSellerModalOpen(true)}
          openBuyerModal={() => setBuyerModalOpen(true)}
          isOpen={isOpen}
          onClose={onClose}
        />
      </div>
      <div css={desktopOnly}>
        <DesktopLinks
          isOpen={isOpen}
          onClose={onClose}
          openSellerModal={() => setSellerModalOpen(true)}
          openBuyerModal={() => setBuyerModalOpen(true)}
        />
      </div>

      <CreateDeal
        open={sellerModalOpen}
        onClose={() => setSellerModalOpen(false)}
      />

      <CreateBuyer
        open={buyerModalOpen}
        onClose={() => setBuyerModalOpen(false)}
      />
    </>
  );
};

type Props = {
  className?: string,
};

export const CreateLeadButton = ({ className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref: { current: any } = useRef(null);

  const handleWindowClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleWindowClick);

    return () => document.removeEventListener("click", handleWindowClick);
  }, [isOpen]);

  return (
    <>
      <button
        ref={ref}
        data-test="button:create-lead"
        onClick={() => setIsOpen(!isOpen)}
        css={createLeadButtonStyle}
        className={className}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <CreateBuyerOrSeller onClose={() => setIsOpen(false)} isOpen={isOpen} />
    </>
  );
};
