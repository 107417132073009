import { gql } from "@apollo/client";
import { Loader } from "components/Loader";
import { Grid, LeafCell } from "components/Grid";
import { useMutation } from "@apollo/client/react/hooks";
import { TextField } from "components/TextField";
import { H2 } from "components/Heading";
import { InfoTile, InfoSection } from "../InfoTile";

const NEST_DEAL_UPDATE = gql`
  mutation UpdateNestDeal($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      latestSellerSituation
      rmWhyNested
    }
  }
`;

export const SellerCircumstance = (props) => {
  const { editMode, nestDeal } = props;
  if (!nestDeal) {
    return <Loader />;
  }

  if (editMode) {
    return <InternalVersion nestDeal={nestDeal} />;
  }
  return <ExternalVersion nestDeal={nestDeal} />;
};

const ExternalVersion = ({ nestDeal }) => {
  const { latestSellerSituation, rmWhyNested } = nestDeal;

  return (
    <InfoSection title="Seller Circumstance">
      <InfoTile
        data-test="why-are-they-selling"
        title="Why are they selling?"
        text={rmWhyNested}
      />
      <InfoTile
        data-test="latest-situation"
        title="Seller's latest situation"
        text={latestSellerSituation}
      />
    </InfoSection>
  );
};

const InternalVersion = ({ nestDeal }) => {
  const { id, latestSellerSituation, rmWhyNested } = nestDeal;
  const [updateNestDeal] = useMutation(NEST_DEAL_UPDATE);

  const update = (input) => {
    updateNestDeal({
      variables: {
        id,
        input,
      },
      optimisticResponse: {
        updateNestDeal: {
          ...nestDeal,
          ...input,
        },
      },
    });
  };

  return (
    <>
      <H2>Seller Circumstance</H2>
      <Grid columns={4}>
        <LeafCell width={2}>
          <TextField
            label="Why are they selling?"
            data-test="why-are-they-selling-edit"
            property="rmWhyNested"
            value={rmWhyNested}
            mutation={update}
            multiline
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Seller's latest situation"
            data-test="latest-situation-edit"
            property="latestSellerSituation"
            value={latestSellerSituation}
            mutation={update}
            multiline
          />
        </LeafCell>
        <LeafCell width={2} />
      </Grid>
    </>
  );
};
