// @flow
import { useState } from "react";
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { useSelector } from "react-redux";
import moment from "moment";
import { getAnalyticsMetadata, sendAnalyticsEvent } from "@nested/analytics";
import { Redirect } from "react-router";
import { ONBOARDING_FORM_FINISH_UP_SUBMITTED } from "@nested/analytics/events";
import { Sentry } from "@nested/isomorphic-sentry";
import { getConfig } from "@nested/config";
import { sleep } from "@nested/utils";

import { MultiStageForm, Question } from "../components/MultiStageForm";
import { type State } from "../components/MultiStageForm/ducks";
import {
  SignPost,
  Source,
  CalendlyOnboarding,
  CalendlyCallback,
} from "../questions";
import { persistor } from "../store";
import { calculateLeadScore } from "./usePersonalInformationOnSubmit";

const { ACCOUNT_URI } = getConfig();

const UPDATE_ONBOARDING_INPUT = gql`
  mutation updatePropertyDetailsForm(
    $input: UpdateCustomerOnboardingInput!
    $meta: VendorOnboardingMetaInput!
  ) {
    updateOnboardingInformation(input: $input, meta: $meta) {
      successful
      failureReason
    }
  }
`;

const LEAD_SCORES_FOR_MARKET_TAB = ["ZERO", "ONE"];

const getContactType = (reasonForContact) => {
  switch (reasonForContact) {
    case "SPEAK_WITH_AN_AGENT":
    case "INTRO_CALL":
      return "call";
    case "SCHEDULE_A_VALUATION":
    case "HOME_VISIT":
      return "visit";
    default:
      return "none";
  }
};

export const cleanupAndRedirectToAccount = async (
  state: State,
  leadScore?: (State) => string = calculateLeadScore,
) => {
  await persistor.purge();
  // Wait a few seconds for all GTM events to fire and sync to the server
  await sleep(2000);

  const score = leadScore(state);

  const {
    agentFullName,
    bookingDate,
    dealId,
    notInServicedAreaNextStep,
    reasonForContact,
  } = state;

  // It's impossible to get this far without a deal ID, but flow doesn't know that so this shuts it up
  const deal = dealId || "";

  if (notInServicedAreaNextStep === "ACCESS_DATA") {
    window.location.assign(`${ACCOUNT_URI}/listing/viewings`);
    return;
  }

  // contact booked through CalendlyOnboarding
  if (reasonForContact && agentFullName && bookingDate) {
    const contactBooked = getContactType(reasonForContact);
    const agentFirstName = agentFullName.split(" ")[0];
    const bookingDateUtc = moment.utc(bookingDate).format();

    window.location.assign(
      `${ACCOUNT_URI}/listing/strategy?contactBooked=${contactBooked}&agent=${agentFirstName}&bookingDate=${bookingDateUtc}&deal=${deal}`,
    );
    return;
  }

  if (LEAD_SCORES_FOR_MARKET_TAB.includes(score)) {
    window.location.assign(
      `${ACCOUNT_URI}/listing/viewings?contactBooked=none&deal=${deal}`,
    );
    return;
  }

  window.location.assign(
    `${ACCOUNT_URI}/listing/strategy?contactBooked=none&deal=${deal}`,
  );
};

export const FinishingUpForm = () => {
  const [updateVendor] = useMutation(UPDATE_ONBOARDING_INPUT);
  const [errorMessage, setErrorMessage] = useState();
  const { previouslyCreatedDealId, hasLeadScoreOne } = useSelector((state) => ({
    previouslyCreatedDealId: state.onboardingForm.dealId,
    hasLeadScoreOne: calculateLeadScore(state.onboardingForm) === "ONE",
  }));

  if (!previouslyCreatedDealId) {
    return <Redirect to="/get-started" replace />;
  }

  const onSubmit = async (state) => {
    try {
      sendAnalyticsEvent({
        event: ONBOARDING_FORM_FINISH_UP_SUBMITTED,
      });
      const response = await updateVendor({
        variables: {
          input: {
            dealId: state.dealId,
            customer: {
              marketingSources: state.sources?.sources,
              referrer: state.sources?.referrer,
            },
            property: {
              listings: state.listings,
              propertyType: state.propertyType,
              tenure: state.tenure,
              expectedSalePrice: state.expectedSalePrice,
              bedrooms: state.bedrooms,
            },
          },
          meta: getAnalyticsMetadata(),
        },
      });
      const data = response?.data?.updateOnboardingInformation;
      if (!data) {
        throw new Error("No data returned by graphql");
      }

      const { successful, failureReason } = data;

      if (successful && state.dealId) {
        await cleanupAndRedirectToAccount(state);
        return;
      }

      throw new Error(failureReason);
    } catch (e) {
      Sentry.captureException(e);
      if (e.message === "This form has already been submitted.") {
        await cleanupAndRedirectToAccount(state);
      } else {
        setErrorMessage(
          "Failed to submit your details. Please try again later.",
        );
      }
    }
  };
  return (
    <>
      <MultiStageForm
        onSubmit={onSubmit}
        lastButtonLabel="Finish"
        submitError={errorMessage}
        setSubmitError={setErrorMessage}
        formType="finishing-up"
        previousFormPath={"/get-started/book-a-call"}
      >
        <Question
          key="sources"
          route="/get-started/finishing-up"
          component={Source}
          name="sources"
        />
        <Question
          route="/get-started/finishing-up/reason-for-contact"
          component={SignPost}
          name="reasonForContact"
          skip={() => hasLeadScoreOne}
        />
        <Question
          route="/get-started/finishing-up/calendar"
          component={CalendlyOnboarding}
          name="calendlyOnboarding"
          skip={({ reasonForContact }) => !reasonForContact}
        />
        <Question
          route="/get-started/callback"
          component={CalendlyCallback}
          name="calendly"
          skip={({ calendlyOnboarding, reasonForContact }) =>
            !reasonForContact || !calendlyOnboarding
          }
        />
      </MultiStageForm>
    </>
  );
};
