// @flow
import moment from "moment";
import ReactCalendar from "react-calendar";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

const styles = css`
  .react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    margin-bottom: 5px;
    display: flex;
  }
  .react-calendar__navigation button {
    color: ${({ theme }) => theme.palette.hague100};
    font-size: 12px;
    line-height: 16px;
    background: none;
    font-weight: 500;
  }
  .react-calendar__navigation button:disabled {
    opacity: 0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    cursor: pointer;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.hague150};
    font-size: 12px;
    line-height: 16px;
    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.palette.hague40} !important;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    background: none;
    color: ${({ theme }) => theme.palette.hague};
    padding: 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    abbr {
      border: 1px solid transparent;
      display: inline-block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
    }
    /* We don't want highlights on blank squares from prev/next month */
    &.highlight.react-calendar__month-view__days__day--neighboringMonth abbr {
      background-color: unset;
      border: none;
    }
    &.highlight:enabled:hover abbr,
    &.highlight:enabled:focus abbr {
      cursor: pointer;
    }
  }
  .react-calendar__tile:disabled {
    color: ${({ theme }) => theme.palette.hague40} !important;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    /* Hover styles */
    cursor: pointer;
    abbr {
      background-color: ${({ theme }) => theme.palette.hague10};
      border-radius: 100%;
    }
  }
  .react-calendar__tile--now {
    /* Today's date styles */
    abbr {
      font-weight: 500;
    }
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    /* Today's date hover/focus styles */
    cursor: pointer;
  }
  .react-calendar__tile--hasActive {
    abbr {
      background: #76baff;
      border-radius: 100%;
      border: 1px solid #76baff;
    }
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    cursor: pointer;
  }
  .react-calendar__tile--active {
    /* Currently selected day styles */
    abbr {
      border: 1px solid #2d89d9 !important;
      background-color: #2d89d9 !important;
      border-radius: 100%;
      color: white;
    }
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    cursor: pointer;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    cursor: pointer;
  }
`;

const chevronStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
`;

const NextLabel = () => (
  <FontAwesomeIcon css={chevronStyle} icon={faChevronRight} />
);

const PrevLabel = () => (
  <FontAwesomeIcon css={chevronStyle} icon={faChevronLeft} />
);

export const Calendar = (props: Object) => (
  <div css={styles}>
    <ReactCalendar
      prev2Label={null}
      next2Label={null}
      nextLabel={<NextLabel />}
      prevLabel={<PrevLabel />}
      formatShortWeekday={(_, date) => moment(date).format("dd")}
      view="month"
      navigationLabel={({ date }) => moment(date).format("MMM YYYY")}
      formatMonth={(_, date) => moment(date).format("MM")}
      showFixedNumberOfWeeks
      {...props}
    />
  </div>
);
