// @flow
import { Sentry } from "@nested/isomorphic-sentry";

export type PropertyTypeEnum =
  | "BUNGALOW"
  | "COMMERCIAL"
  | "DETACHED"
  | "END_OF_TERRACE"
  | "EQUESTRIAN_FACILITY"
  | "FLAT"
  | "GARAGE"
  | "HOUSE"
  | "LAND"
  | "MAISONETTE"
  | "PLOT"
  | "SEMI_DETACHED"
  | "TERRACED";

// Maps property types to capitalised text
export const PropertyType = ({
  propertyType,
}: {
  propertyType: PropertyTypeEnum,
}) => {
  const propertyTypeMapping: { [PropertyTypeEnum]: string } = {
    BUNGALOW: "BUNGALOW",
    COMMERCIAL: "COMMERCIAL",
    DETACHED: "DETACHED",
    END_OF_TERRACE: "END OF TERRACE",
    EQUESTRIAN_FACILITY: "EQUESTRIAN_FACILITY",
    FLAT: "FLAT",
    GARAGE: "GARAGE",
    HOUSE: "HOUSE",
    LAND: "LAND",
    MAISONETTE: "MAISONETTE",
    PLOT: "PLOT",
    SEMI_DETACHED: "SEMI DETACHED",
    TERRACED: "TERRACED",
  };

  if (typeof propertyType !== "string") {
    Sentry.captureException(
      new Error(
        "Property type is missing on a property (or is formated incorrectly and is not a string)",
      ),
    );
    return null;
  }

  if (!propertyTypeMapping[propertyType]) {
    Sentry.captureException(
      new Error(`Property type "${propertyType}" is missing in the mapping.`),
    );
    return null;
  }

  return propertyTypeMapping[propertyType];
};

// Maps property type variables to lowercased, human readable description
export function PropertyTypeDescription(propertyType: PropertyTypeEnum) {
  const propertyTypeMapping = {
    BUNGALOW: "bungalow",
    COMMERCIAL: "commercial",
    DETACHED: "detached house",
    END_OF_TERRACE: "end of terrace house",
    EQUESTRIAN_FACILITY: "equestrian facility",
    FLAT: "flat",
    HOUSE: "house",
    GARAGE: "garage",
    LAND: "land",
    MAISONETTE: "maisonette",
    PLOT: "plot",
    SEMI_DETACHED: "semi-detached house",
    TERRACED: "terraced house",
  };
  if (typeof propertyType === "string" && propertyTypeMapping[propertyType]) {
    return propertyTypeMapping[propertyType];
  }
  return "house";
}
