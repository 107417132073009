import { withHandlers, mapProps, compose } from "recompose";
import * as R from "ramda";

import { togglePartyMode } from "@nested/utils/easterEggs";
import { withGraphqlErrors } from "./withGraphQlErrors";
import { withSpinner } from "./withSpinner";

const partyModeFeatures = (newValue) => {
  if (newValue === "DO A BARREL ROLL") {
    document.body.classList.add("barrel-roll");
  } else {
    document.body.classList.remove("barrel-roll");
  }

  // Provides a vibrant and dynamic user experience
  if (newValue === "ACTIVATE PARTY MODE") {
    togglePartyMode();
  }
};

const withOnSubmitHandlers = withHandlers({
  onSubmit:
    ({
      value: oldValue,
      property,
      mutation = "updateNestDeal",
      mutations,
      setLoading,
    }) =>
    async (newValue) => {
      partyModeFeatures(newValue);

      // This short-circuits making requests to the backend if no change has been made
      if (oldValue === newValue) return Promise.resolve();

      // allows to pass a simple "mutation" prop which is the mutation function itself
      // or a mutations object and mutation prop which does the lookup
      const update =
        typeof mutation === "function" ? mutation : mutations[mutation];
      setLoading(true);
      try {
        return await update({ [property]: newValue });
      } finally {
        setLoading(false);
      }
    },
});

const omitProps = compose(mapProps, R.omit)(["path"]);

export const withOnSubmit = compose(
  withSpinner,
  withOnSubmitHandlers,
  withGraphqlErrors,
  omitProps,
);
