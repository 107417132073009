// @flow
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

import { Select } from "@nested/nest/src/components/Select/Select";

export const PROGRESSOR_SELECT_QUERY = gql`
  query ProgressorSelectQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      assignedProgressor {
        id
      }
      acceptedBuyerOffer {
        id
      }
      agreedBuyerOffer {
        id
      }
    }
    activeNestedUsers {
      label: fullName
      value: id
    }
  }
`;

export const UPDATE_PROGRESSOR = gql`
  mutation UpdateProgressor($dealId: ID!, $nestDeal: NestDealInput!) {
    updateNestDeal(id: $dealId, nestDeal: $nestDeal) {
      id
      assignedProgressor {
        id
      }
    }
  }
`;

type Props = { id: string };

export const ProgressorSelect = ({ id }: Props) => {
  const { data, loading } = useQuery(PROGRESSOR_SELECT_QUERY, {
    variables: { id },
  });
  const [assignProgressor] = useMutation(UPDATE_PROGRESSOR);

  if (loading) return null;

  const { activeNestedUsers, nestDeal } = data || {};

  const onChange = async (newProgressorId) => {
    try {
      await assignProgressor({
        variables: {
          dealId: id,
          nestDeal: {
            assignedProgressorId: Number(newProgressorId),
          },
        },

        optimisticResponse: {
          __typename: "Mutation",
          updateNestDeal: {
            __typename: "NestDeal",
            id,
            assignedProgressor: {
              __typename: "NestedUser",
              id: newProgressorId,
            },
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <Select
      data-test="progressor-select"
      label="Progressor"
      labelAbove
      onChange={onChange}
      options={activeNestedUsers}
      disableSelect={!nestDeal.agreedBuyerOffer && !nestDeal.acceptedBuyerOffer}
      value={nestDeal.assignedProgressor?.id}
    />
  );
};
