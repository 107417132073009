// @flow
import moment from "moment";

// States in order in which they take precedence
// Inactive states
export const BUYER_INACTIVE: "buyer_inactive" = "buyer_inactive";
export const INACTIVE_BPI_WITHOUT_WORKFLOW: "inactive_bpi" = "inactive_bpi";

// Snoozed states
export const SNOOZED_ENQUIRY: "snoozed_enquiry" = "snoozed_enquiry";
export const SNOOZED_FEEDBACK: "snoozed_feedback" = "snoozed_feedback";
export const SNOOZED_NEGOTIATION: "snoozed_negotiation" = "snoozed_negotiation";

//  Workflow states
export const ENQUIRY_COMPLETE: "enquiry_complete" = "enquiry_complete";
export const FEEDBACK_COMPLETE: "feedback_complete" = "feedback_complete";
export const ENQUIRIES_WORKFLOW: "enquiry" = "enquiry";
export const FEEDBACK_WORKFLOW: "feedback" = "feedback";
export const NEGOTIATIONS_WORKFLOW: "negotiations" = "negotiations";
export const NEGOTIATIONS_COMPLETE: "negotiations_complete" =
  "negotiations_complete";

// Default state. Nothing is rendered in either status bar or status description.
export const ACTIVE_BPI_WITHOUT_WORKFLOW: "active_bpi" = "active_bpi";

export type SummaryBarState =
  | typeof BUYER_INACTIVE
  | typeof INACTIVE_BPI_WITHOUT_WORKFLOW
  | typeof SNOOZED_ENQUIRY
  | typeof SNOOZED_FEEDBACK
  | typeof SNOOZED_NEGOTIATION
  | typeof ENQUIRY_COMPLETE
  | typeof FEEDBACK_COMPLETE
  | typeof ENQUIRIES_WORKFLOW
  | typeof FEEDBACK_WORKFLOW
  | typeof NEGOTIATIONS_WORKFLOW
  | typeof NEGOTIATIONS_COMPLETE
  | typeof ACTIVE_BPI_WITHOUT_WORKFLOW
  | null;

export const COMPLETED_STATES = [
  ENQUIRY_COMPLETE,
  FEEDBACK_COMPLETE,
  NEGOTIATIONS_COMPLETE,
  SNOOZED_ENQUIRY,
  SNOOZED_FEEDBACK,
  SNOOZED_NEGOTIATION,
];

const getInactiveState = (bpi, workflow, leadStatus) => {
  if (!bpi) return null; // when url is buyers/:buyerId

  if (leadStatus !== "buyer_active") return BUYER_INACTIVE;

  if (!bpi.currentlyInterested && workflow === "enquiries")
    return ENQUIRY_COMPLETE;

  if (!bpi.currentlyInterested && workflow === "feedback")
    return FEEDBACK_COMPLETE;

  if (!bpi.currentlyInterested && workflow === "negotiations")
    return NEGOTIATIONS_COMPLETE;

  if (!bpi.currentlyInterested && !workflow)
    return INACTIVE_BPI_WITHOUT_WORKFLOW;

  return null;
};

const getSnoozedState = (bpi, workflow) => {
  if (!bpi) return null; // when url is buyers/:buyerId

  if (bpi.snooze && workflow === "enquiries") return SNOOZED_ENQUIRY;

  if (bpi.snooze && workflow === "feedback") return SNOOZED_FEEDBACK;

  if (bpi.snooze && workflow === "negotiations") return SNOOZED_NEGOTIATION;

  return null;
};

const getWorkflowState = (bpi, workflow) => {
  if (!bpi) return null; // when url is buyers/:buyerId

  const enquiryComplete = bpi.viewings.some(
    (v) => !v.viewingCancellationDatetime,
  );

  const feedbackIncomplete = bpi.viewings.some(
    (v) =>
      moment(v.datetimeViewingEnds).isBefore() &&
      !v.viewingCancellationDatetime &&
      !v.datetimeFeedbackSharedWithSeller,
  );

  const negotiationsComplete = bpi.status.value > "b05_offered";

  if (feedbackIncomplete && workflow === "feedback") return FEEDBACK_WORKFLOW;

  if (workflow === "feedback") return FEEDBACK_COMPLETE;

  if (enquiryComplete && workflow === "enquiries") return ENQUIRY_COMPLETE;

  if (workflow === "enquiries") return ENQUIRIES_WORKFLOW;

  if (negotiationsComplete && workflow === "negotiations")
    return NEGOTIATIONS_COMPLETE;

  if (workflow === "negotiations") return NEGOTIATIONS_WORKFLOW;

  return ACTIVE_BPI_WITHOUT_WORKFLOW;
};

export const getSummaryBarState = (
  selectedBpi: ?BuyerSummary_buyer_buyerPropertyInterests,
  workflow: ?string,
  leadStatus: string,
): SummaryBarState => {
  return (
    getInactiveState(selectedBpi, workflow, leadStatus) ||
    getSnoozedState(selectedBpi, workflow) ||
    getWorkflowState(selectedBpi, workflow)
  );
};
