// @flow
import { useEffect, useState } from "react";
import { NumberInput, type Props } from "./NumberInput";

export const ChangeOnBlurNumberInput = ({
  value,
  onBlur,
  onChange,
  ...rest
}: Props) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <NumberInput
      {...rest}
      value={state}
      onChange={(newValue) => {
        setState(newValue);
      }}
      onBlur={(e) => {
        onChange(state);
        onBlur && onBlur(e);
      }}
    />
  );
};
