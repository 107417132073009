// @flow
import { css } from "styled-components";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media } from "@nested/brand";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { type ContactWithPhones } from "../Navigation/ContactDrawer/ContactDrawerShared";
import { Button } from "../Button/Button";
import { useHandoff } from "../MobileActionSheet/useHandoff";
import { useUser } from "../../hooks/useUser";
import { isMobile } from "../MobileActionSheet/MobileActionSheetContext";

export const iconButtonStyle = css`
  border: none;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.hague70};
  display: flex;
  justify-content: center;
  height: 30px;
  min-width: 30px;
  width: 100%;
  padding: 0;

  ${media.tablet`
    border: 2px solid ${({ theme }) => theme.palette.hague20};
    &:hover {
      border: 2px solid ${({ theme }) => theme.palette.hague40};
    }
  `}
`;

export const labelButtonStyle = css`
  display: flex;
  height: 32px;
  width: auto;
  flex-grow: 1;
`;

type Props = {
  className?: string,
  contacts: $ReadOnlyArray<ContactWithPhones>,
  "data-test"?: string,
  recommended?: boolean,
  relationId: string,
  relationType: "buyer" | "deal",
  withLabel?: boolean,
};

export const HandoffCallButton = ({
  contacts,
  className,
  "data-test": dataTest,
  recommended,
  relationId,
  relationType,
  withLabel,
}: Props) => {
  const { openMobileActionSheet } = useHandoff();
  const { selectedUser } = useUser();

  const availablePhones = isEmpty(contacts)
    ? []
    : contacts.flatMap(({ phones }) => phones);

  const initiatedByHandoff = !isMobile(navigator.userAgent);

  const onClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    openMobileActionSheet({
      id: relationId,
      relationType,
      commsType: "call",
      initiatedByHandoff,
      selectedUser: selectedUser.email,
      contacts,
    });
  };

  const disabled = availablePhones.length === 0;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onClick={(e) => disabled && e.preventDefault() && e.stopPropagation()}
    >
      <Button
        data-test={
          dataTest ||
          `phone-${relationId}${initiatedByHandoff ? "" : "-mobile"}`
        }
        css={withLabel ? labelButtonStyle : iconButtonStyle}
        buttonStyle={recommended ? "pink" : "white"}
        onClick={onClick}
        disabled={disabled}
      >
        {withLabel ? "Call" : <FontAwesomeIcon icon={faPhone} />}
      </Button>
    </div>
  );
};
