// @flow
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { gql, useMutation } from "@apollo/client";
import { detailStyle } from "@nested/brand/src/components/Timeline/TimelineItem";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@nested/nest/src/components/Button/Button";
import { IconButton } from "../../../../IconButton/IconButton";
import { TextArea } from "../../../../TextArea/TextArea";
import { Checkbox } from "../../../../Checkbox/Checkbox";

export const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      isSharedWithCustomer
    }
  }
`;

export const BinIconButton = styled(IconButton)`
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  justify-content: center;
`;

export const newNoteStyle = css`
  textarea {
    background-color: transparent;
    border: none;
    font-weight: 400;
    min-height: 20px;
    padding: 0;
    resize: none;

    &:focus {
      border: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.palette.hague40};
    }
  }
`;

export const NoteEditBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
  align-items: center;
`;

export const ShareOrSaveButton = styled(Button)`
  width: 82px;
`;

type Props = {
  autoFocus?: boolean,
  withPlaceHolder?: boolean,
  alwaysShowEditBar?: boolean,
  onCancel?: () => void,
  onSuccess?: () => void,
  relationId: string,
  noteRelationType: string,
  isShareable: boolean,
};

export const NewNoteInput = ({
  autoFocus,
  onCancel,
  onSuccess,
  relationId,
  noteRelationType,
  isShareable,
  alwaysShowEditBar = true,
  withPlaceHolder,
}: Props) => {
  const noteInput = useRef(null);
  const [createNote, { loading }] = useMutation(CREATE_NOTE);

  useEffect(() => {
    return autoFocus ? noteInput.current?.focus() : undefined;
  });

  const [checked, setChecked] = useState(false);
  const createNewNote = async (text: string) => {
    try {
      const result = await createNote({
        variables: {
          input: {
            text,
            relationId,
            relationType: noteRelationType.toUpperCase(),
            isSharedWithCustomer: checked,
          },
        },
      });

      if (result?.data?.createNote?.id) {
        onSuccess && onSuccess();
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  const [noteContent, setNoteContent] = useState("");

  const [showNoteEditBar, setShowNoteEditBar] = useState(alwaysShowEditBar);

  const disableSaveButton = loading || noteContent === "";

  return (
    <div css={detailStyle}>
      <TextArea
        css={newNoteStyle}
        data-test="new-note-text"
        disabled={loading}
        forwardedRef={noteInput}
        lineLimit={6}
        placeholder={withPlaceHolder && "Start typing your note..."}
        value={noteContent}
        onChange={(e) => {
          setNoteContent(e.target.value);
        }}
        onFocus={() => {
          setShowNoteEditBar(true);
        }}
      />

      {showNoteEditBar && (
        <NoteEditBar>
          <BinIconButton
            faIcon={faTrashAlt}
            onClick={
              onCancel ||
              (() => {
                setNoteContent("");
                setShowNoteEditBar(false);
              })
            }
          />
          {isShareable ? (
            <Checkbox
              checked={checked}
              data-test="share-new-note-checkbox"
              disabled={noteContent === ""}
              labelText={"Share with seller?"}
              onChange={() => setChecked(!checked)}
              id={"share-note-checkbox"}
              labelTextPosition={"left"}
            />
          ) : null}
          <ShareOrSaveButton
            buttonStyle={disableSaveButton ? "grey" : "pink"}
            onClick={() => {
              setShowNoteEditBar(false);
              createNewNote(noteContent);
            }}
            disabled={disableSaveButton}
          >
            {checked ? "Share" : "Save"}
          </ShareOrSaveButton>
        </NoteEditBar>
      )}
    </div>
  );
};
