export const updateConfig = {
  props({ mutate, ownProps }) {
    return {
      updateNestedListings(input) {
        return mutate({
          optimisticResponse: {
            __typename: "Mutation",
            updateNestDeal: {
              __typename: "NestDeal",
              ...ownProps.data.nestDeal,
              ...input,
            },
          },
          variables: {
            id: ownProps.data.nestDeal.id,
            input,
          },
        });
      },
    };
  },
};
