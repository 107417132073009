// @flow
export const Add = ({ fill = "#353F52" }: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g fill={fill} fillRule="nonzero">
      <path d="M2.5 8.5a.5.5 0 0 1 0-1h11a.5.5 0 1 1 0 1h-11z" />
      <path d="M7.5 2.5a.5.5 0 0 1 1 0v11a.5.5 0 1 1-1 0v-11z" />
    </g>
  </svg>
);

export const AddIcon = Add;
