// @flow

import { useState } from "react";
import {
  Button,
  ContactsWrapper,
  noContactStyle,
  ContactTypeLink,
  type ContactWithEmails,
} from "../Navigation/ContactDrawer/ContactDrawerShared";
import { Drawer } from "../Drawer/Drawer";

type Props = {
  children: React$Node,
  contacts: $ReadOnlyArray<ContactWithEmails>,
  relationId: string,
};

export const MailtoEmailButton = ({
  children,
  contacts,
  relationId,
}: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const availableEmails = contacts.flatMap(({ emails }) => emails);
  const availableEmailsCount = availableEmails.length;

  const openEmailClient = (contactsEmail) => {
    window.open(`mailto:${contactsEmail}`);
  };

  return (
    <>
      <Button
        data-test={`email-${relationId}`}
        disabled={availableEmailsCount === 0}
        onClick={() => {
          if (availableEmailsCount === 1)
            openEmailClient(availableEmails[0]?.emailAddress);
          if (availableEmailsCount > 1) {
            setOpenDrawer(true);
          }
        }}
      >
        {children}
      </Button>
      <Drawer
        heading="Email"
        closeDrawer={() => setOpenDrawer(false)}
        drawerOpen={openDrawer}
        drawerType="email"
      >
        {contacts.map((contact) => {
          const { id, emails } = contact;
          const contactHasEmails = emails.length > 0;

          return (
            <ContactsWrapper key={`contact-${id}`} contact={contact}>
              {contactHasEmails ? (
                emails.map((email, index) => (
                  <ContactTypeLink
                    className="fs-exclude"
                    key={`contact-${id}-email-${index}`}
                    onClick={() => {
                      setOpenDrawer(false);
                      openEmailClient(email.emailAddress);
                    }}
                  >
                    {email.emailAddress}
                  </ContactTypeLink>
                ))
              ) : (
                <p css={noContactStyle}>No email address</p>
              )}
            </ContactsWrapper>
          );
        })}
      </Drawer>
    </>
  );
};
