// @flow
import { useState } from "react";
import styled, { css } from "styled-components";
import { NestedLogo } from "@nested/your-account/app/icons/NestedLogo";
import { media } from "@nested/brand";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { Sidebar } from "./Sidebar";
import { UserSelect } from "./UserSelect";
import { Search } from "./Search/Search";
import {
  CreateLeadButton,
  createLeadButtonStyle,
} from "./CreateLead/CreateLeadButton";

const sidebarStyle = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${({ open }) => (open ? "0" : "-200px")};
  transition: left 200ms ease-out;
`;

export const Nav = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.hague100};
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  ${media.desktop`
    padding: 10px 20px;
  `}
`;

export const navStyle = styled.nav`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.hague100};
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  ${media.desktop`
    padding: 10px 20px;
  `}
`;

const toggleWrapperStyle = css`
  ${media.desktop`
    display: none;
  `}
`;

const logoStyle = css`
  height: 25px;
`;

const logoWrapperStyle = css`
  margin-left: 15px;
  flex-grow: 0.5;
  ${media.tablet`
    flex-grow: 0;
  `}

  ${media.desktop`
    margin-left: 0px;
  `}
`;

const headingStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    color: white;
    white-space: nowrap;
    font-weight: 500;
    margin-left: 15px;
    display: inline-block;
    margin-right: 20px;
  `}
`;

const sidebarBackgroundStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ open }) =>
    open ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"};
  z-index: 99;
  pointer-events: ${({ open }) => (open ? "unset" : "none")};
  transition: background-color 200ms ease-out;
  ${media.desktop`
    display: none;
  `}
`;

const menuToggleButtonStyle = css`
  background-color: transparent;
  border: none;
  opacity: 0.7;
  font-size: 1.6em;
  cursor: pointer;
  padding: 0px;
  color: white;
`;

export const buttonWrapperStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .createLeadButton {
    order: 1;
  }

  .HelpButton {
    order: 2;
  }

  ${media.tablet`
  .createLeadButton {
    order: 2;
  }
  
  .HelpButton {
    order: 1;
  }
  `};
`;

type Props = {
  href: string,
  className?: string,
};

export const HelpButton = ({ href, className }: Props) => (
  <button css={createLeadButtonStyle} className={className}>
    <a href={href} target="_blank" style={{ display: "inherit" }}>
      <FontAwesomeIcon icon={faQuestion} />
    </a>
  </button>
);

export const Navigation = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Nav>
        <div css={toggleWrapperStyle}>
          <button
            css={menuToggleButtonStyle}
            onClick={() => setSidebarOpen(true)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div css={logoWrapperStyle}>
          <NestedLogo css={logoStyle} />
        </div>
        <div css={headingStyle}>The Nest</div>
        <Search />
        <div css={buttonWrapperStyle}>
          <HelpButton
            href={
              "https://www.notion.so/Partner-Agent-Setup-and-Training-bf62ed0af4d243b98b6f580bb33b3c8c"
            }
            className="HelpButton"
          />

          <CreateLeadButton className="createLeadButton" />
        </div>
        <ErrorBoundary>
          <UserSelect />
        </ErrorBoundary>
      </Nav>
      <div
        css={sidebarBackgroundStyle}
        open={sidebarOpen}
        role="presentation"
        onClick={() => setSidebarOpen(false)}
      >
        <Sidebar
          css={sidebarStyle}
          open={sidebarOpen}
          onClose={() => setSidebarOpen(false)}
        />
      </div>
    </>
  );
};
