// @flow
import { useState } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ContactModal } from "./ContactModal";

const buttonStyle = css`
  padding: 0px;
  font-weight: 500;
  display: inline-flex;
  cursor: pointer;
  margin-top: 10px;
  align-items: center;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.hague70};
`;
const iconStyle = css`
  margin-left: 10px;
  color: ${({ theme }) => theme.palette.hague40};
`;

type Props = {
  buyerId: string,
};

export const OpenContactsModalButton = ({ buyerId }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen && (
        <ContactModal
          buyerId={buyerId}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
      <button css={buttonStyle} onClick={() => setModalOpen(true)}>
        Contacts
        <FontAwesomeIcon css={iconStyle} icon={faPencilAlt} />
      </button>
    </>
  );
};
