// @flow
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Field } from "react-final-form";
import moment from "moment-timezone";
import { css } from "styled-components";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { Checkbox } from "@nested/nest/src/components/Checkbox/Checkbox";
import { formatShortDate } from "@nested/utils/src/formatDate/formatDate";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { DatePicker } from "@nested/nest/src/components/DatePicker/DatePicker";
import { NumberInput } from "@nested/nest/src/components/NumberInput/NumberInput";
import { Button } from "@nested/nest/src/components/Button/Button";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { Confetti } from "@nested/your-account/app/components/Confetti";
import reloadAgreedOrAcceptedOffer from "../../queries/reloadAgreedOrAcceptedOffer.graphql";
import reloadDealStatus from "../../queries/reloadDealStatus.graphql";
import { SALE_PRICE_QUERY } from "../ProgressionSummary/SalePrice/SalePrice";
import { GET_DATES } from "../ProgressionSummary/ExchangeAndCompleteDates/ExchangeAndCompleteDates";
import unreachMilestoneMutation from "../unreachMilestone.graphql";
import {
  MILESTONE_LABELS,
  milestoneStyle,
  checkboxStyle,
  dateStyle,
  type MilestoneProps,
} from "./MilestoneShared";

export const REACH_EXCHANGED_MILESTONE = gql`
  mutation ReachExchangedMilestone(
    $buyerOfferId: ID!
    $input: ReachExchangedMilestoneInput!
  ) {
    reachExchangedMilestone(buyerOfferId: $buyerOfferId, input: $input) {
      id
      reachedAt
    }
  }
`;

export const EXPECTED_COMPLETION_DATE_QUERY = gql`
  query ExchangedModalExpectedCompletionDate($buyerOfferId: ID!) {
    buyerOffer(id: $buyerOfferId) {
      id
      expectedCompletionDate
    }
  }
`;

export const modalStyle = css`
  ${media.tablet`
    height: 500px;
    width: 700px;
  `}
`;

export const textStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
`;

export const sectionStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const fieldStyle = css`
  margin-bottom: 20px;
  width: 100%;
  ${media.tablet`
    width: calc(50% - 10px);
  `}
`;

export const buttonStyle = css`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  ${media.tablet`
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    padding: 20px;
    button {
      max-width: 96px;
      margin: 0 0 0 auto;
    }
  `}
`;

export const required = (value: any) => (value ? undefined : "Required");

export const ExchangedMilestone = ({
  buyerOfferId,
  dealId,
  disabled,
  milestone,
}: MilestoneProps) => {
  const [open, setOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { data } = useQuery(EXPECTED_COMPLETION_DATE_QUERY, {
    variables: { buyerOfferId },
    skip: !buyerOfferId,
  });
  const refetchQueries = [
    { query: reloadDealStatus, variables: { dealId } },
    { query: reloadAgreedOrAcceptedOffer, variables: { dealId } },
    { query: SALE_PRICE_QUERY, variables: { id: dealId } },
    { query: GET_DATES, variables: { buyerOfferId } },
  ];
  const [reach, { loading: reachLoading }] = useMutation(
    REACH_EXCHANGED_MILESTONE,
    { refetchQueries },
  );
  const [unreach, { loading: unreachLoading }] = useMutation(
    unreachMilestoneMutation,
    { refetchQueries },
  );
  const { createNotification } = useNotifications();
  const { currentUser } = useUser();

  const { id, reachedAt } = milestone || {};
  const checkboxDisabled =
    disabled || !buyerOfferId || reachLoading || unreachLoading;

  const onCheckboxToggle = async () => {
    if (!reachedAt) {
      setOpen(true);
      return;
    }

    if (Boolean(reachedAt) && currentUser.isNestAdmin && buyerOfferId) {
      // eslint-disable-next-line no-alert
      const unreachConfirmed = window.confirm(
        "WARNING: You are about to revert this to the Under Offer state, are you sure you want to do this?",
      );
      if (!unreachConfirmed) return;

      try {
        await unreach({
          variables: {
            buyerOfferId,
            milestoneId: id,
          },
          optimisticResponse: {
            unreachMilestone: {
              __typename: "MilestoneStatus",
              id,
              reachedAt: null,
            },
          },
        });
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const onSubmit = async (input) => {
    if (!buyerOfferId || reachedAt) {
      return;
    }

    try {
      const result = await reach({
        variables: {
          buyerOfferId,
          input,
        },
        optimisticResponse: {
          reachExchangedMilestone: {
            __typename: "MilestoneStatus",
            id,
            reachedAt: moment(),
          },
        },
      });
      if (result?.data?.reachExchangedMilestone?.reachedAt) {
        setOpen(false);
        setShowConfetti(true);
        createNotification("New milestone reached!", {
          subText: MILESTONE_LABELS[id],
        });
      }
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <MobileFullScreenModal
        css={modalStyle}
        open={open}
        onClose={() => setOpen(false)}
        headerText="Confirm dates and price"
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            actualExchangeDate: moment().format("YYYY-MM-DD"),
            expectedCompletionDate: data?.buyerOffer?.expectedCompletionDate,
          }}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} css="padding: 0 20px;">
              <p css={textStyle}>
                Please confirm the exchange date and final sale price. Check the
                expected completion date is correct
              </p>
              <div css={sectionStyle}>
                <Field name="actualExchangeDate" validate={required}>
                  {({ input, meta }) => {
                    return (
                      <div css={fieldStyle}>
                        <DatePicker
                          dataTest="exchanged-date"
                          label="Exchanged Date"
                          onSubmit={(date) => input.onChange(date)}
                          valid={meta.valid}
                          value={input.value}
                        />
                      </div>
                    );
                  }}
                </Field>
                <Field name="actualSalePrice" validate={required}>
                  {({ input, meta }) => (
                    <NumberInput
                      {...input}
                      css={fieldStyle}
                      decimalScale={2}
                      icon={faPoundSign}
                      label="Final Sale Price"
                      valid={meta.valid}
                    />
                  )}
                </Field>
              </div>
              <Field name="expectedCompletionDate" validate={required}>
                {({ input, meta }) => (
                  <DatePicker
                    dataTest="expected-completion-date"
                    label="Expected Completion Date"
                    onSubmit={(date) => input.onChange(date)}
                    valid={meta.valid}
                    value={input.value}
                  />
                )}
              </Field>
              <div css={buttonStyle}>
                <Button
                  data-test={"submit-button"}
                  disabled={hasValidationErrors || submitting}
                  buttonStyle="pink"
                  type="submit"
                  css="width: 100%;"
                >
                  {submitting ? "Saving..." : "Save"}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </MobileFullScreenModal>
      <div css={milestoneStyle}>
        <Checkbox
          checked={Boolean(milestone?.reachedAt)}
          css={checkboxStyle}
          disabled={checkboxDisabled}
          id={id}
          labelText={MILESTONE_LABELS[id]}
          onChange={onCheckboxToggle}
        />
        {reachedAt && <div css={dateStyle}>{formatShortDate(reachedAt)}</div>}
      </div>
      <Confetti showConfetti={showConfetti} />
    </>
  );
};
