// @flow

import styled from "styled-components";
import { NoSubmitRadioButtons as RadioButtons } from "components/RadioButtons";

export const RatingIcon = styled.span`
  background-color: ${({ rating, theme }) => {
    if (rating === "POSITIVE") {
      return theme.icon.positive;
    }
    if (rating === "NEUTRAL") {
      return theme.icon.neutral;
    }

    return theme.icon.negative;
  }};
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin: 0px 5px;
`;

const StyledRadioButtons = styled(RadioButtons)`
  width: 100%;
  margin-bottom: 12px;

  label {
    color: white;
  }
`;

type Props = {
  comparableId: string,
  onSelect: (ComparisonRatingType) => void,
  ratingValue: ComparisonRatingType | null,
};

const options = [
  {
    icon: <RatingIcon rating="POSITIVE" />,
    label: "Positive",
    value: "POSITIVE",
  },
  {
    icon: <RatingIcon rating="NEUTRAL" />,
    label: "Neutral",
    value: "NEUTRAL",
  },
  {
    icon: <RatingIcon rating="NEGATIVE" />,
    label: "Negative",
    value: "NEGATIVE",
  },
];

export const Rating = ({ comparableId, onSelect, ratingValue }: Props) => (
  <StyledRadioButtons
    data-test="rating-btn"
    id={comparableId}
    onSubmit={onSelect}
    options={options}
    value={ratingValue}
  />
);
