// @flow
import styled from "styled-components";
import { RichTextField } from "@nest-ui/sellers-nest/components/TextField";
import { Label as OriginalLabel } from "@nest-ui/sellers-nest/components/Label/Label";
import { Checkbox } from "@nest-ui/sellers-nest/components/Checkbox/Checkbox";
import { Grid, LeafCell } from "@nest-ui/sellers-nest/components/Grid/Grid";

const Label = styled(OriginalLabel)`
  margin-top: 8px;
`;

type Props = {|
  externalDealId: ID,
  feedbackSharedWithSeller: boolean,
  firstImpressionsSharedWithSeller: boolean,
  updateViewing: (
    input:
      | {| viewingFeedbackFull: ?string |}
      | {| viewingFeedbackFirstImpressions: ?string |},
  ) => ?Promise<any>,
  viewing: PropertyInterestsByBuyer_buyer_buyerPropertyInterests_viewings,
  viewingId: string,
|};

const positiveFirstImpressions =
  "<p>The viewing went well and the buyer seemed interested.</p>";
const neutralFirstImpressions =
  "<p>The viewing went well but the buyer didn’t give a firm indication of whether they are interested.</p>";
const negativeFirstImpressions =
  "<p>The buyer didn’t seem interested. We’ll follow up with them as it’s important we understand what they did and didn't like about your home. I’m not anticipating a second viewing or offer from them.</p>";

const prefillLinksFirstImpressions = [
  { label: "Positive", content: positiveFirstImpressions },
  { label: "Neutral", content: neutralFirstImpressions },
  { label: "Negative", content: negativeFirstImpressions },
];

const offered = "<p>Your buyer made an offer!</p>";
const planningOffer =
  "<p>The buyer is planning to make an offer, we’ll let you know as soon as we know more.</p>";
const noContact = `<p>We did our best to contact the buyer for feedback but didn’t hear back. We’ll let you know if they get back to us but we’re assuming they’re not interested.</p>`;
const considering = `<h3>What they liked:</h3><p>&#8205;</p><h3>What they didn’t like:</h3><p>&#8205;</p><h3>Their thoughts on price:</h3><p>&#8205;</p><h3>Next steps:</h3><p>&#8205;</p>`;
const standard = `<h3>What they liked:</h3><p>&#8205;</p><h3>What they didn’t like:</h3><p>&#8205</p><h3>Their thoughts on price:</h3><p>&#8205;</p>`;

const prefillLinksFullFeedback = [
  { label: "No contact", content: noContact },
  { label: "Not interested", content: standard },
  { label: "Considering", content: considering },
  { label: "2nd View", content: standard },
  { label: "Planning offer", content: planningOffer },
  { label: "Offered", content: offered },
];

export const ViewingFeedback = ({
  feedbackSharedWithSeller,
  firstImpressionsSharedWithSeller,
  updateViewing,
  viewing,
  viewingId,
}: Props) => {
  const { viewingFeedbackFirstImpressions, viewingFeedbackFull } = viewing;

  return (
    <>
      <Label>First impressions</Label>
      <Grid columns={6}>
        <LeafCell width={5}>
          <RichTextField
            data-test={`viewings:feedback:first-impressions-${viewingId}`}
            multiline
            onSubmit={(feedback) => {
              updateViewing({ viewingFeedbackFirstImpressions: feedback });
            }}
            value={viewingFeedbackFirstImpressions}
            prefillLinks={prefillLinksFirstImpressions}
            disableLinks={firstImpressionsSharedWithSeller}
          />
        </LeafCell>
        <LeafCell width={1} left={6}>
          <Checkbox
            greenMode
            parentId={viewingId}
            data-test={`viewings:firstImpressionsSharedWithSeller-${viewingId}`}
            mutation={updateViewing}
            optionName={firstImpressionsSharedWithSeller ? "Shared!" : "Share"}
            property="firstImpressionsSharedWithSeller"
            value={firstImpressionsSharedWithSeller}
          />
        </LeafCell>
      </Grid>
      <Label>Full feedback</Label>
      <Grid columns={6}>
        <LeafCell width={5}>
          <RichTextField
            data-test={`viewings:feedback:full-feedback-${viewingId}`}
            multiline
            onSubmit={(feedback) => {
              updateViewing({ viewingFeedbackFull: feedback });
            }}
            value={viewingFeedbackFull}
            prefillLinks={prefillLinksFullFeedback}
            disableLinks={feedbackSharedWithSeller}
          />
        </LeafCell>
        <LeafCell width={1} left={6}>
          <Checkbox
            greenMode
            parentId={viewingId}
            data-test={`viewings:feedbackSharedWithSeller-${viewingId}`}
            mutation={updateViewing}
            optionName={feedbackSharedWithSeller ? "Shared!" : "Share"}
            property="feedbackSharedWithSeller"
            value={feedbackSharedWithSeller}
          />
        </LeafCell>
      </Grid>
    </>
  );
};
