// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { gql, useMutation } from "@apollo/client";
import { getImage } from "@nested/utils";
import { buttonStyles } from "../IconButton/IconButton";
import { AreYouSureRowOverlay } from "../AreYouSureOverlay";
import { getContactsQuery } from "./ContactsTab";

export const UNLINK_CONTACT_MUTATION = gql`
  mutation UnlinkContact($contactId: ID!, $dealId: ID, $buyerId: ID) {
    unlinkContact(contactId: $contactId, dealId: $dealId, buyerId: $buyerId) {
      success
    }
  }
`;

const disabledButtonStyle = css`
  cursor: pointer;
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

const doNotEnterButtonStyle = css`
  ${buttonStyles}
  ${disabledButtonStyle}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const doNotEnterIconStyle = css`
  display: inline-block;
  mask: url(${getImage("nest/icons/Remove.svg")}) no-repeat center;
  background: ${({ theme }) => theme.palette.terracotta150};
  height: 14px;
  width: 14px;
`;

const areYouSureStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: white;
  &.fade-enter {
    & div {
      opacity: 0;
    }
  }
  &.fade-enter-active {
    & div {
      opacity: 1;
      transition: opacity 0.4s ease;
    }
  }
`;

const removeContactButtonTabletWrapper = css`
  ${disabledButtonStyle}
  color: ${({ theme }) => theme.palette.terracotta150};
  font-weight: 500;
  background: none;
  border: none;
  padding: 0;
  display: none;
  width: 80px;
  ${media.tablet`
    margin-top: 10px;
    display: flex;
    align-items:center;
  `}
`;

const removeContactButtonMobileWrapper = css`
  ${disabledButtonStyle}
  margin-left: 9px;
  ${media.tablet`
    display: none;
  `}
`;

export const RemoveContactButton = ({
  contactId,
  disabled,
  onClick,
}: {
  contactId: string,
  disabled: boolean,
  onClick: () => void,
}) => (
  <div>
    <button
      data-test={`remove-contact-${contactId}`}
      title={
        disabled
          ? "This contact is an account holder or is the only contact"
          : null
      }
      onClick={() => onClick()}
      disabled={disabled}
      css={removeContactButtonTabletWrapper}
    >
      <span css={doNotEnterIconStyle}></span>
      <span css="margin-left: 5px">Remove</span>
    </button>
    <div css={removeContactButtonMobileWrapper}>
      <button
        disabled={disabled}
        onClick={() => onClick()}
        css={doNotEnterButtonStyle}
      >
        <span css={doNotEnterIconStyle}></span>
      </button>
    </div>
  </div>
);

export const ConfirmContactRemovalPrompt = ({
  contactId,
  dealId,
  buyerId,
  contactToDeleteId,
  hidePrompt,
  name,
}: {
  contactId: string,
  dealId: ?string,
  buyerId: ?string,
  name: string,
  contactToDeleteId: ?string,
  hidePrompt: () => void,
}) => {
  if (contactToDeleteId !== contactId) return null;

  const [mutation] = useMutation(UNLINK_CONTACT_MUTATION, {
    refetchQueries: [
      { query: getContactsQuery(dealId), variables: { id: dealId || buyerId } },
    ],
    awaitRefetchQueries: true,
  });

  const unlinkContact = async () => {
    try {
      const result = await mutation({
        variables: {
          contactId,
          dealId,
          buyerId,
        },
      });

      if (result?.data?.unlinkContact?.success) {
        return;
      }

      if (result?.errors) {
        errorHandler(result?.errors);
        return;
      }
    } catch (e) {
      errorHandler(e);
    } finally {
      hidePrompt();
    }
  };

  return (
    <AreYouSureRowOverlay
      css={areYouSureStyle}
      message={`Remove ${name} from this ${dealId ? "deal" : "buyer"}?`}
      messageSubText="This will not delete them from the system"
      onCancel={() => hidePrompt()}
      onConfirm={async () => unlinkContact()}
    />
  );
};
