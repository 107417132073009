// @flow
import { gql, useQuery, useMutation } from "@apollo/client";
import { ChangeOnBlurNumberInput } from "@nested/nest/src/components/NumberInput/ChangeOnBlurNumberInput";
import { Placeholder } from "@nested/nest/src/components/Placeholder";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons";

export const SALE_PRICE_QUERY = gql`
  query SalePrice($id: ID!) {
    nestDeal(id: $id) {
      id
      opportunityStatus {
        label
      }
      salePrice
    }
  }
`;

export const UPDATE_SALE_PRICE_MUTATION = gql`
  mutation UpdateSalePricePrice($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      salePrice
    }
  }
`;

export const SalePrice = ({ id }: { id: string }) => {
  const { data, loading } = useQuery(SALE_PRICE_QUERY, {
    variables: { id },
  });
  const [updatePrice] = useMutation(UPDATE_SALE_PRICE_MUTATION);

  if (loading) return <Placeholder width={300} />;

  const { salePrice, opportunityStatus } = data.nestDeal;

  const hasExchanged = ["Exchanged", "Completed"].includes(
    opportunityStatus.label,
  );

  return (
    <ChangeOnBlurNumberInput
      icon={faPoundSign}
      label="Final Sale Price"
      data-test="sale-price"
      disabled={!hasExchanged}
      decimalScale={2}
      fixedDecimalScale={!Number.isInteger(salePrice)}
      // we need to parse the sale price to force the trailing zeroes
      // to appear and disappear accordingly because of this behaviour
      // https://github.com/s-yadav/react-number-format/issues/455#issuecomment-808735474
      value={parseFloat(salePrice)}
      placeholder="-"
      onChange={(value) =>
        updatePrice({
          variables: {
            id,
            input: {
              salePrice: value,
            },
          },
          optimisticResponse: {
            updateNestDeal: {
              id,
              salePrice: value,
            },
          },
        })
      }
    />
  );
};
