// @flow
import type { BrowserHistory } from "history/createBrowserHistory";

import { compose, withHandlers } from "recompose";
import { withRouter } from "react-router";

import { graphql } from "deal/utils/graphql";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";

import { CreateButton } from "components/CreateButton";
import createDueDiligenceDatesMutation from "./createMutation/createDueDiligenceDate.graphql";
import { createConfig } from "./createMutation/config";

import dueDiligenceDatesQuery from "./queries/dueDiligenceDates.graphql";
import { dueDiligenceDatesQueryConfig } from "./queries/config";

import { DueDiligenceDates } from "./DueDiligenceDates";
import { DueDiligenceDatesModal } from "./DueDiligenceDatesModal";

type Props = {
  data: {
    loading: boolean,
    nestDeal: DueDiligenceDatesList_nestDeal,
    inputOptions: DueDiligenceDatesList_inputOptions,
  },
  createNewDueDiligenceDates: (CreateFunctionArgs) => $Call<
    typeof createNewDueDiligenceDatesFun,
  >,
};

const DueDiligenceDatesListComponent = ({
  data,
  createNewDueDiligenceDates,
}: Props) => {
  if (data?.loading) {
    return null;
  }

  const { dealTypeDetails } = data?.nestDeal;

  let dueDiligenceDatesList = [];
  if (
    // this checks to see if the deal type of the deal is one which has due diligence dates
    // it's required to satisfy flow, but sadly flow doesn't let me pull this out into a named variable
    dealTypeDetails &&
    (dealTypeDetails.__typename === "DealTypeAdvanceJul2018" ||
      dealTypeDetails.__typename === "DealTypeAgencyPlusSep2018" ||
      dealTypeDetails.__typename === "DealTypeAdvancesForAllMarch2019")
  ) {
    dueDiligenceDatesList = dealTypeDetails.dueDiligenceDates ?? [];
  }

  return (
    <ErrorBoundary>
      <div data-test="due-diligence-dates-list">
        {dueDiligenceDatesList.map(
          ({ id, endDate, reasonForChangeOfDate, insertedAt, explanation }) => (
            <DueDiligenceDates
              dealId={data?.nestDeal?.id}
              id={id}
              key={id}
              endDate={endDate}
              reasonForChangeOfDate={reasonForChangeOfDate}
              explanation={explanation}
              insertedAt={insertedAt}
              reasonsForChangeOfDueDiligenceEndDate={
                data?.inputOptions?.reasonsForChangeOfDueDiligenceEndDate
              }
            />
          ),
        )}
        <CreateButton
          label="Add a due diligence end date"
          data-test="create-due-diligence-date-button"
          onSubmit={createNewDueDiligenceDates}
        />
        <DueDiligenceDatesModal
          reasonsForChangeOfDueDiligenceEndDate={
            data?.inputOptions?.reasonsForChangeOfDueDiligenceEndDate
          }
        />
      </div>
    </ErrorBoundary>
  );
};

type CreateMutationResult = {
  data: CreateDueDiligenceDate,
};

type CreateFunctionArgs = {
  createDueDiligenceDate: () => Promise<CreateMutationResult>,
  dealId: string,
  history: BrowserHistory,
};

export function createNewDueDiligenceDatesFun({
  createDueDiligenceDate,
  history,
  dealId,
}: CreateFunctionArgs) {
  return async function createNewDueDiligenceDatesThenNavigate() {
    const mutationResult = await createDueDiligenceDate();
    const dueDiligenceDateId = mutationResult?.data?.createDueDiligenceDate?.id;
    if (dueDiligenceDateId === undefined) {
      throw Error(
        "Due diligence date ID was undefined after create mutation returned.",
      );
    }
    return history.push(
      `/deals/${dealId}/legal-ops-info/new-due-diligence-date/${dueDiligenceDateId}`,
    );
  };
}

const enhance = compose(
  withRouter,
  graphql(dueDiligenceDatesQuery, dueDiligenceDatesQueryConfig),
  graphql(createDueDiligenceDatesMutation, createConfig),
  // $FlowFixMe - issue is in recompose types
  withHandlers({ createNewDueDiligenceDates: createNewDueDiligenceDatesFun }),
);

export const DueDiligenceDatesList = enhance(DueDiligenceDatesListComponent);
