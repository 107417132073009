import T from "prop-types";
import { Route } from "react-router-dom";
import { compose } from "recompose";

import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { DatePicker } from "components/DatePicker";
import { SelectField } from "components/SelectField";

import { graphql } from "deal/utils/graphql";
import { Modal } from "components/Modal";
import updateDueDiligenceDatesMutation from "./updateMutation/updateDueDiligenceDate.graphql";
import { updateConfig } from "./updateMutation/config";
import findDueDiligenceDatesQuery from "./queries/findDueDiligenceDate.graphql";
import { findQueryConfig } from "./queries/findQueryConfig";

const enhance = compose(
  graphql(findDueDiligenceDatesQuery, findQueryConfig),
  graphql(updateDueDiligenceDatesMutation, updateConfig),
);

function DueDiligenceDatesModalComponent({
  data,
  updateDueDiligenceDate,
  history,
  match,
  reasonsForChangeOfDueDiligenceEndDate,
}) {
  const {
    params: { dealId },
  } = match;
  return (
    <Modal
      title="Due diligence end date"
      closeModal={() => history.push(`/deals/${dealId}/legal-ops-info`)}
      isOpen
      data-test="due-diligence-dates-modal"
    >
      <Grid columns={4}>
        <LeafCell width={1}>
          <DatePicker
            label="Due diligence end date"
            mutation={updateDueDiligenceDate}
            property="endDate"
            data-test="due-diligence-dates-modal-end-date"
            value={data.dueDiligenceDate.endDate}
          />
        </LeafCell>
        <LeafCell width={3} />
        <LeafCell width={2}>
          <SelectField
            label="Reason for change of date"
            property="reasonForChangeOfDate"
            mutation={updateDueDiligenceDate}
            data-test="due-diligence-dates-modal-reason-for-change-of-date"
            value={data.dueDiligenceDate.reasonForChangeOfDate}
            options={reasonsForChangeOfDueDiligenceEndDate}
          />
        </LeafCell>
        <LeafCell width={2}>
          <TextField
            label="Explanation"
            property="explanation"
            mutation={updateDueDiligenceDate}
            data-test="due-diligence-dates-modal-explanation"
            value={data.dueDiligenceDate.explanation}
            multiline
          />
        </LeafCell>
      </Grid>
    </Modal>
  );
}

const DueDiligenceDatesModalFields = enhance(DueDiligenceDatesModalComponent);

export function DueDiligenceDatesModal({
  reasonsForChangeOfDueDiligenceEndDate,
}) {
  return (
    <Route
      path="/deals/:dealId/legal-ops-info/new-due-diligence-date/:dueDiligenceDateId"
      render={(props) => (
        <DueDiligenceDatesModalFields
          reasonsForChangeOfDueDiligenceEndDate={
            reasonsForChangeOfDueDiligenceEndDate
          }
          {...props}
        />
      )}
    />
  );
}

DueDiligenceDatesModal.propTypes = {
  reasonsForChangeOfDueDiligenceEndDate: T.array.isRequired,
};

DueDiligenceDatesModalComponent.propTypes = {
  updateDueDiligenceDate: T.func,
  match: T.object.isRequired,
  history: T.object.isRequired,
  data: T.object,
  reasonsForChangeOfDueDiligenceEndDate: T.array.isRequired,
};
