import { Fragment } from "react";
import T from "prop-types";
import { CSSTransition } from "react-transition-group";

const FormToggleAnimation = ({ children, ...rest }) => (
  <CSSTransition
    classNames="formToggle"
    timeout={{ enter: 2450, exit: 450 }}
    component={Fragment}
    {...rest}
  >
    {children}
  </CSSTransition>
);

FormToggleAnimation.propTypes = {
  children: T.node,
};

export { FormToggleAnimation };
