import { Component } from "react";
import { logException } from "@nested/utils/sentry";
import T from "prop-types";

// See: https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html

// A JavaScript error in a part of the UI shouldn’t break the whole app.
// To solve this problem for React users, React 16 introduces a new concept of
// an “error boundary”.

// Error boundaries are React components that catch JavaScript errors anywhere
// in their child component tree, log those errors, and display a fallback UI
// instead of the component tree that crashed. Error boundaries catch errors
// during rendering, in lifecycle methods, and in constructors of the whole tree
// below them.

// A class component becomes an error boundary if it defines a new lifecycle
// method called componentDidCatch(error, info).

// You can use it like this:

// <ErrorBoundary fallbackComponent={MyComponent}>
//   <MyWidget />
// </ErrorBoundary>

// Also see withErrorBoundary for a HOC
const defaultFallback = (
  <div data-test="error-boundary">Something went wrong.</div>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    logException(error, info);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent !== undefined
        ? this.props.fallbackComponent
        : defaultFallback;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  fallbackComponent: T.node,
  children: T.node.isRequired,
};

export { ErrorBoundary };
