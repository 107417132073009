import T from "prop-types";

import { H1 } from "./H1";
import { H2 } from "./H2";
import { H3 } from "./H3";

const headings = {
  1: H1,
  2: H2,
  3: H3,
};

const Heading = ({ level = 1, ...rest }) => {
  const Component = headings[level];
  return <Component {...rest} />;
};

Heading.propTypes = {
  level: T.number,
};

export { H1, H2, H3 };
export { Heading };
