// @flow
import moment from "moment";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";
type Props = {
  firstName: ?string,
  lastName: ?string,
  ownerName: ?string,
};

export const getFullName = ({
  firstName,
  lastName,
  ownerName,
}: $ReadOnly<Props>) => {
  if (!firstName && !lastName && !ownerName) {
    return "";
  }

  return firstName && lastName
    ? `${titleCaseWord(firstName)} ${titleCaseWord(lastName)}`
    : titleCaseOwnerName(ownerName);
};

const titleCaseWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const titleCaseOwnerName = (ownerName) => {
  if (!ownerName) {
    return "";
  }
  return ownerName.toLowerCase().split(" ").map(titleCaseWord).join(" ");
};

type GetSummaryStatusOptions = {
  opportunityStatus: ?$ReadOnly<{
    valueText: string,
  }>,
  acceptedBuyerOffer: ?$ReadOnly<{
    actualDateOfCompletion: ?string,
    actualDateOfExchange: ?string,
    placedOn: ?string,
  }>,
  createdAt: string,
  currentListingStartDate: ?string,
  propertyAnalysisSharedAt: ?string,
  nestedContractSignedDate: ?string,
  rmMostRecentContactDatetime: ?string,
};

export const getStatusSummary = (
  {
    opportunityStatus,
    acceptedBuyerOffer,
    createdAt,
    currentListingStartDate,
    nestedContractSignedDate,
    propertyAnalysisSharedAt,
    rmMostRecentContactDatetime,
  }: GetSummaryStatusOptions,
  now: * = moment(),
) => {
  if (!opportunityStatus) {
    return "-";
  }

  switch (opportunityStatus.valueText) {
    case "s01_received_offer_request":
      // Leads in book visit view
      if (!rmMostRecentContactDatetime) {
        return `Enquired ${formatDateDifference(createdAt, now)}`;
      }
      // Leads in valuation view
      return `Valuation ${formatDateDifference(
        rmMostRecentContactDatetime,
        now,
      )}`;
    case "s06_provided_final_offer":
      return propertyAnalysisSharedAt
        ? `Valuation shared ${formatDateDifference(
            propertyAnalysisSharedAt,
            now,
          )}`
        : "Valuation shared";
    case "s07_closed":
      return nestedContractSignedDate
        ? `Closed ${formatDateDifference(nestedContractSignedDate, now)}`
        : "Closed";
    case "s08_listed":
      if (!currentListingStartDate) {
        return "-";
      }

      return `Listed/reduced ${formatDateDifference(
        currentListingStartDate,
        now,
      )}`;
    case "s09_under_offer":
      if (!acceptedBuyerOffer?.placedOn) {
        return "Under offer";
      }

      return `Under offer ${formatDateDifference(
        acceptedBuyerOffer.placedOn,
        now,
      )}`;
    case "s10_exchanged":
      if (!acceptedBuyerOffer?.actualDateOfExchange) {
        return "-";
      }
      return `Exchanged ${formatDateDifference(
        acceptedBuyerOffer.actualDateOfExchange,
        now,
      )}`;
    case "s11_completed":
      if (!acceptedBuyerOffer?.actualDateOfCompletion) {
        return "-";
      }

      return `Completed ${formatDateDifference(
        acceptedBuyerOffer.actualDateOfCompletion,
        now,
      )}`;
    default:
      return "-";
  }
};
