// @flow
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import { css } from "styled-components";
import { media, TeamMember } from "@nested/brand";
import { Placeholder } from "../../components/Placeholder";

const jobTitle = css`
  margin: 0 6px 0 10px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.hague70};
  &:first-child {
    margin-left: 0;
  }
`;

const container = css`
  display: none;

  ${media.tablet`
    display: flex;
    flex-direction: row;
    align-items: center;
  `}
`;

const gravatarStyle = css`
  ${container}
  opacity: ${({ $faded }) => ($faded ? "0.5" : "1")};
`;

export const TEAM_QUERY = gql`
  query DealSummaryQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      assignedProgressor {
        id
        email
        fullName
      }
      assignedRm {
        id
        email
        fullName
      }
    }
  }
`;

const placeholderGravatar = css`
  border-radius: 50%;
  margin-left: 5px;
`;

const PlaceholderTeam = () => (
  <div data-test="team:placeholder" css={container}>
    <Placeholder width={20} height={20} />
    <div css={gravatarStyle}>
      <Placeholder css={placeholderGravatar} height={30} width={30} />
    </div>
    <Placeholder css="margin-left: 10px;" width={20} height={20} />
    <div css={gravatarStyle}>
      <Placeholder css={placeholderGravatar} height={30} width={30} />
    </div>
  </div>
);

type Props = {
  idOrExternalId: string,
};

export const Team = ({ idOrExternalId }: Props) => {
  const { data, loading } = useQuery(TEAM_QUERY, {
    variables: {
      id: idOrExternalId,
    },
  });

  if (loading) {
    return <PlaceholderTeam />;
  }

  const { assignedRm, assignedProgressor } = data?.nestDeal;

  return (
    <div css={container}>
      {assignedRm && (
        <>
          <p data-test="team:rm" css={jobTitle}>
            AP
          </p>
          <TeamMember
            css={gravatarStyle}
            email={assignedRm.email}
            fullName={assignedRm.fullName}
          />
        </>
      )}
      {assignedProgressor && (
        <>
          <p data-test="team:progressor" css={jobTitle}>
            CP
          </p>
          <TeamMember
            css={gravatarStyle}
            email={assignedProgressor.email}
            fullName={assignedProgressor.fullName}
          />
        </>
      )}
    </div>
  );
};
