// @flow
import { NumericFormat } from "react-number-format";
import {
  TextInput,
  type Props as TextInputProps,
} from "../TextInput/TextInput";

export type Props = {
  ...TextInputProps,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  onChange: (value: number) => void,
  value: number,
};

export const NumberInput = ({
  decimalScale,
  fixedDecimalScale,
  onChange,
  value,
  ...rest
}: Props) => (
  <NumericFormat
    {...rest}
    value={value || ""}
    allowNegative={false}
    customInput={TextInput}
    decimalScale={decimalScale || 0}
    fixedDecimalScale={fixedDecimalScale}
    onValueChange={({ floatValue }) => {
      onChange(floatValue);
    }}
    thousandSeparator
  />
);
