import { useState } from "react";
import { BuyerOfferSellerBuyerChain } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferDetails/components/BuyerOfferSellerBuyerChain";
import { getImage } from "@nested/utils";
import styled, { css } from "styled-components";
import { ListEntryCard } from "@nest-ui/sellers-nest/components/Card";
import { BuyerOfferBuyerSolicitorInfo } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferDetails/components/BuyerOfferBuyerSolicitorInfo";
import { useQuery } from "@apollo/client/react/hooks";
import buyerOfferQuery from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferDetails/queries/buyerOffer.graphql";
import { BuyerOfferSellerSolicitorInfo } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferDetails/components/BuyerOfferSellerSolicitorInfo";
import { H2, H3 } from "@nest-ui/sellers-nest/components/Heading";
import { Loader } from "@nest-ui/sellers-nest/components/Loader";
import { MobileFullScreenModal } from "../../../../../components/Modal";

const chainButtonStyle = css`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const chainButtonChildrenStyle = css`
  text-align: center;
  color: ${({ theme }) => theme.palette.hague100};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
`;

const Content = styled.div`
  ${H2}:first-child, ${H3}:first-child {
    margin-top: 0;
  }
`;

export const BuyerChain = ({ dealId, buyerOfferId }) => {
  const [openModal, setOpenModal] = useState(false);

  const { data: buyerOfferData, loading } = useQuery(buyerOfferQuery, {
    variables: { id: buyerOfferId },
  });

  const { buyerOffer } = buyerOfferData || {};

  return (
    <>
      <div>
        <ChainButton onClick={() => setOpenModal(true)}>Chain</ChainButton>
        <MobileFullScreenModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          headerText="Chain"
          css="min-height: 300px;"
        >
          {openModal && loading && <Loader />}
          {openModal && !loading && buyerOffer && (
            <Content>
              <div css={"padding: 0 10px 0 10px"}>
                <ListEntryCard>
                  <BuyerOfferSellerBuyerChain
                    buyerOfferId={buyerOfferId}
                    dealId={dealId}
                    showHeading={false}
                  />
                </ListEntryCard>
                <ListEntryCard>
                  <BuyerOfferBuyerSolicitorInfo
                    buyerOffer={buyerOffer}
                    id="BuyerOfferSolicitorInfo"
                    mutation={() => {}}
                    readOnly
                  />
                </ListEntryCard>
                <ListEntryCard>
                  <BuyerOfferSellerSolicitorInfo dealId={dealId} readOnly />
                </ListEntryCard>
              </div>
            </Content>
          )}
        </MobileFullScreenModal>
      </div>
    </>
  );
};

const ChainButton = ({ children, onClick }) => {
  return (
    /* eslint-disable-next-line */
    <div css={chainButtonStyle} onClick={onClick}>
      <img
        width={57}
        height={42}
        src={getImage("nest/icons/chain_management_icon_100_73.png")}
        alt={""}
      />
      <div css={chainButtonChildrenStyle}>{children}</div>
    </div>
  );
};
