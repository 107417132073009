// @flow
import styled, { css } from "styled-components";
import { NakedTextField } from "components/TextField";
import { Field, useField } from "react-final-form";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { NoSubmitSelectField } from "components/SelectField";
import { Button } from "@nested/nest/src/components/Button/Button";
import { formatPrice } from "@nested/utils/src/formatPrice/formatPrice";
import { media } from "@nested/brand";
import { forwardRef, useRef } from "react";
import {
  PROPERTY_JUST_LISTED,
  PRICE_REDUCTION,
  FALL_THROUGH,
  SLOW_MOVING_PROPERTY,
  COMING_SOON,
  suggestEmailSubject,
  suggestEmailBody,
  createAnonymisedAddress,
} from "./utils";
import { MailoutPreview } from "./MailoutPreview";
// The SMS limit is 1000 characters, this number is once you subtract
// the default text and links, plus approximations for first names.
const MAILOUT_BODY_MAX_LENGTH = 775;

export const chooseBuyersButtonStyle = css`
  display: none;
  margin-bottom: 20px;
  width: 100%;
  ${media.tablet`
    display: block;
  `}
`;

export const customiseMessageStyle = css`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.color.background.hague};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  color: white;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 14px;
  ${media.tablet`
   height: 100%;
    width: 50%;
  `}
`;

export const FormFieldsWrapper = styled.div`
  padding: 0 20px;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.tablet`
    height: unset;
    flex-grow: 1;
  `}
`;

export const ColumnHeader = styled.div`
  padding: 5px 20px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.background.hague};
  color: white;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 14px;
  border-bottom: solid 1px rgba(7, 51, 67, 1);
  height: 53px;
`;

const CustomiseMessageLink = styled.button`
  color: ${({ theme }) => theme.color.text.dark};
  text-decoration: none;
  background: transparent;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: 14px;
  cursor: pointer;
  padding: 0;
`;

export const previewButtonStyles = css`
  text-align: left;
  margin: 20px 0;
  ${media.tablet`
    display: none;
  `}
`;

export const modalTitle = css`
  margin-left: calc(50% - 106px);
`;

type FormWrapperProps = {
  children: any,
  onCancel: () => void,
  title: string,
};

export const ModalHeader = forwardRef<
  {
    title: string,
    onCancel: () => void,
  },
  React$ElementRef<"p"> | null,
>(({ title, onCancel }, formRef) => (
  <ColumnHeader>
    <CustomiseMessageLink onClick={onCancel}>Cancel</CustomiseMessageLink>
    <p ref={formRef} css={modalTitle}>
      {title}
    </p>
  </ColumnHeader>
));

export const EmailFormWrapper = forwardRef<
  FormWrapperProps,
  React$ElementRef<"p"> | null,
>(({ children, title, onCancel }, formRef) => (
  <div css={customiseMessageStyle}>
    <ModalHeader ref={formRef} title={title} onCancel={onCancel} />
    <FormFieldsWrapper>{children}</FormFieldsWrapper>
  </div>
));

const textFieldErrorStyles = css`
  input {
    border: 2px solid ${({ theme }) => theme.color.background.tomato} !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const fieldLabelStyles = css`
  color: white;

  &:first-child {
    margin-top: 20px;
    font-size: 14px;
  }
`;

const CircumstanceField = styled(NoSubmitSelectField)`
  ${fieldLabelStyles}
  & button {
    margin-top: 10px;
    font-size: 14px;
    padding: 20px 10px;

    span {
      line-height: 16px;
    }
  }
`;

export const subjectFieldStyles = css`
  ${fieldLabelStyles}
  & input {
    margin-top: 5px;
    font-size: 14px;
    padding: 20px 10px;
  }

  ${({ hasErrors }) => hasErrors && textFieldErrorStyles}
`;

const errorsDropdown = css`
  background-color: ${({ theme }) => theme.color.background.tomato};
  color: white;
  padding: 8px;
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  line-height: 16px;

  p {
    margin: 0;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    font-weight: 400;
    list-style-type: none;
  }

  li {
    position: relative;
    margin-left: 12px;
  }

  li::before {
    content: "";
    height: 4px;
    width: 4px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: -11px;
    top: 6px;
  }
`;

const REQUIRED_ERROR = "Required";

export const validateSubjectField = (text: ?string) => {
  if (!text) return [REQUIRED_ERROR];
  const errors = [];
  if (/[A-Z]{3,}/.test(text)) {
    errors.push("Titles with too many capitals get flagged as junk mail.");
  }
  if (/!/.test(text)) {
    errors.push("Titles with exclamation marks get flagged as junk mail.");
  }
  return errors.length === 0 ? undefined : errors;
};

const ErrorsDropdown = ({ errors }: { errors: string[] }) => {
  const required = errors.length === 1 && errors[0] === REQUIRED_ERROR;
  return (
    <div css={errorsDropdown}>
      {required ? (
        <p>This field is required</p>
      ) : (
        <>
          <p>Did you mean to shout?</p>
          <ul>
            {errors.map((e) => (
              <li>{e}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export const SubjectField = ({
  errors,
  pristine,
  value,
  ...rest
}: {
  errors: string[],
  pristine: boolean,
  value: string,
}) => {
  const hasErrors =
    value === "" ? errors?.length > 0 && !pristine : errors?.length > 0;

  return (
    <>
      <NakedTextField
        {...rest}
        value={value}
        css={subjectFieldStyles}
        hasErrors={hasErrors}
        maxLength={80}
      />
      {hasErrors && <ErrorsDropdown errors={errors} />}
    </>
  );
};

export const CustomMessageField = styled(NakedTextField)`
  ${fieldLabelStyles}
  & textarea {
    margin-top: 5px;
    font-size: 14px;
  }
`;

const circumstanceOptions = [
  { label: "Property just listed", value: PROPERTY_JUST_LISTED },
  { label: "Price reduction", value: PRICE_REDUCTION },
  { label: "Fall through", value: FALL_THROUGH },
  { label: "Slow moving property", value: SLOW_MOVING_PROPERTY },
];

const preListOptions = [{ label: "Coming soon", value: COMING_SOON }];

const required = (value: ?boolean) => (value ? undefined : "Required");

const SelectCircumstance = ({
  property,
  houseOrFlat,
  anonymisedAddress,
  preList,
  price,
}) => {
  const subjectFieldProps = useField("subject");
  const bodyFieldProps = useField("customMessage");

  return (
    <div>
      <Field name="circumstance">
        {({ input: { onChange, ...input }, meta }) => {
          const updateCircumstance = (newCircumstance) => {
            const subject = suggestEmailSubject(
              newCircumstance,
              houseOrFlat,
              property?.bedrooms,
              anonymisedAddress,
            );
            const body = suggestEmailBody(
              newCircumstance,
              houseOrFlat,
              property?.bedrooms,
              property?.bathrooms,
              anonymisedAddress,
              price,
            );
            subjectFieldProps.input.onChange(subject);
            bodyFieldProps.input.onChange(body);
            onChange(newCircumstance);
          };
          return (
            <CircumstanceField
              onSubmit={updateCircumstance}
              {...input}
              {...meta}
              label="Update circumstance"
              data-test="update-circumstance-field"
              options={preList ? preListOptions : circumstanceOptions}
            />
          );
        }}
      </Field>
    </div>
  );
};

type Props = {
  closeModal: () => void,
  deal: MailoutModalQuery_nestDeal,
  onNext: () => void,
  hasValidationErrors: ?boolean,
};

export const ComposeMessage = ({
  closeModal,
  onNext,
  deal,
  hasValidationErrors,
}: Props) => {
  const formRef = useRef(null);
  const previewRef = useRef(null);
  const {
    property,
    opportunityStatus,
    houseOrFlat,
    currentListPrice,
    externalId,
    rmRecommendedListPrice,
  } = deal;
  const anonymisedAddress = createAnonymisedAddress(property);
  const refs = { formRef, previewRef };
  // this shouldn't happen but it's needed to shut flow up
  // about comparing the status to the listed string
  if (!opportunityStatus) {
    return null;
  }
  const preList = opportunityStatus?.valueText < "s08_listed";
  const formattedPrice = formatPrice(
    parseFloat(preList ? rmRecommendedListPrice : currentListPrice),
  );
  return (
    <>
      <EmailFormWrapper
        onCancel={closeModal}
        onBack={closeModal}
        title="Compose mailout"
        ref={formRef}
      >
        <div>
          <SelectCircumstance
            property={property}
            houseOrFlat={houseOrFlat}
            anonymisedAddress={anonymisedAddress}
            price={formattedPrice}
            preList={preList}
          />
          <div>
            <Field name="subject" validate={validateSubjectField}>
              {({ input, meta }) => (
                <SubjectField
                  {...input}
                  {...meta}
                  label="Email subject"
                  data-test="email-subject-field"
                  placeholder="Brilliant email title"
                  errors={meta.error}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="customMessage" validate={required}>
              {({ input, meta }) => (
                <CustomMessageField
                  {...input}
                  {...meta}
                  multiline
                  label="Additional custom message"
                  data-test="email-body-field"
                  minRows={5}
                  maxLength={MAILOUT_BODY_MAX_LENGTH}
                />
              )}
            </Field>
          </div>
        </div>
        <div>
          <Button
            icon={faEye}
            buttonStyle="outline"
            large
            css={previewButtonStyles}
            onClick={() =>
              previewRef.current?.scrollIntoView({
                behaviour: "smooth",
                block: "end",
              })
            }
          >
            Preview message
          </Button>
          <Button
            buttonStyle="pink"
            data-test="choose-buyers-button"
            disabled={hasValidationErrors}
            onClick={onNext}
            css={chooseBuyersButtonStyle}
            large
          >
            Next: Choose buyers
          </Button>
        </div>
      </EmailFormWrapper>
      <MailoutPreview
        hasValidationErrors={hasValidationErrors}
        onNext={onNext}
        externalId={externalId}
        formattedPrice={formattedPrice}
        refs={refs}
        preListDeal={preList ? deal : null}
      />
    </>
  );
};
