// @flow
import styled from "styled-components";
import { ListEntryCard } from "components/Card/ListEntryCard";

export const ViewingCardContainer = styled(ListEntryCard)`
  border: ${({ hasAlert, theme }) =>
    hasAlert
      ? `2px solid ${theme.color.background.danger};`
      : `2px solid ${theme.color.secondary.highlight}`};
`;
