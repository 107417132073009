// @flow

import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  type MutationFunction,
  type RefetchQueriesProviderFn,
} from "@apollo/client/react/components";

import { ComparableWithNotes } from "../ComparableCard/ComparableWithNotes";

const HoverHighlight = styled.div`
  & > div > div:hover {
    background-color: ${({ theme }) => theme.background.cardHover};
  }
`;

const SELECT_COMPARABLE_MUTATION = gql`
  mutation SelectComparable($id: ID!) {
    selectComparable(id: $id) {
      id
      selected
    }
  }
`;

const UNSELECT_COMPARABLE_MUTATION = gql`
  mutation UnselectComparable($id: ID!) {
    unselectComparable(id: $id) {
      id
      selected
    }
  }
`;

type Props = {
  comparable: ListComparablesQuery_comparables,
  refetchOnDelete: RefetchQueriesProviderFn,
};

export const SelectableComparable = ({
  comparable,
  refetchOnDelete,
}: Props) => {
  const [selectDisabled, disableSelect] = useState<boolean>(false);

  return (
    <ExtendedMutation
      mutation={
        comparable.selected
          ? UNSELECT_COMPARABLE_MUTATION
          : SELECT_COMPARABLE_MUTATION
      }
    >
      {(
        mutation: MutationFunction<
          UnselectComparable | SelectComparable,
          UnselectComparableVariables | SelectComparableVariables,
        >,
      ) => (
        <HoverHighlight>
          <ComparableWithNotes
            comparable={comparable}
            refetchOnDelete={refetchOnDelete}
            selected={comparable.selected}
            disableSelect={disableSelect}
            selectAction={
              selectDisabled
                ? () => {}
                : () => {
                    mutation({
                      variables: { id: comparable.id },
                      optimisticResponse: {
                        unselectComparable: {
                          __typename: "Comparable",
                          ...comparable,
                          selected: false,
                        },
                        selectComparable: {
                          __typename: "Comparable",
                          ...comparable,
                          selected: true,
                        },
                      },
                    });
                  }
            }
          />
        </HoverHighlight>
      )}
    </ExtendedMutation>
  );
};
