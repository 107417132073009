// @flow
import { gql } from "@apollo/client";

import { Cell } from "components/Grid";
import { SelectField } from "components/SelectField";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { type MutationFunction } from "@apollo/client/react/components";

const PROPERTY_INFORMATION_NEST_DEAL_QUERY = gql`
  query PropertyInformationNestDealQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      propertySubType
    }
  }
`;

const UPDATE_NEST_DEAL_PROPERTY_INFORMATION_MUTATION = gql`
  mutation UpdateNestDealPropertyInformation(
    $dealId: ID!
    $input: NestDealInput!
  ) {
    updateNestDeal(id: $dealId, nestDeal: $input) {
      id
      propertySubType
    }
  }
`;

type Props = {
  dealId: string,
};

const mutationHandler = (mutate, nestDeal) => (input: NestDealInput) =>
  mutate({
    variables: { dealId: nestDeal.id, input },
    optimisticResponse: {
      __typename: "Mutation",
      updateNestDeal: {
        __typename: "NestDeal",
        ...nestDeal,
        ...input,
      },
    },
  });

export const NestDealFields = ({ dealId }: Props) => (
  <ExtendedQuery
    query={PROPERTY_INFORMATION_NEST_DEAL_QUERY}
    variables={{ dealId }}
  >
    {({
      nestDeal,
    }: ExtendedQueryRenderProps<PropertyInformationNestDealQuery>) => (
      <ExtendedMutation
        mutation={UPDATE_NEST_DEAL_PROPERTY_INFORMATION_MUTATION}
      >
        {(
          mutate: MutationFunction<
            UpdateNestDealPropertyInformation,
            UpdateNestDealPropertyInformationVariables,
          >,
        ) => (
          <>
            <Cell width={1}>
              <SelectField
                label="Property type (our assessment)"
                data-test="property_information:property_type"
                options={[
                  "Studio flat",
                  "Flat",
                  "LG floor flat",
                  "Gr floor flat",
                  "Mid floor flat",
                  "Top floor flat",
                  "Gr floor maisonette",
                  "Mid floor maisonette",
                  "Top floor maisonette",
                  "Terraced house",
                  "End-of-terrace house",
                  "Mid-terrace house",
                  "Semi-detached house",
                  "Detached house",
                  "Bungalow",
                ]}
                property="propertySubType"
                mutation={mutationHandler(mutate, nestDeal)}
                value={nestDeal.propertySubType}
              />
            </Cell>
          </>
        )}
      </ExtendedMutation>
    )}
  </ExtendedQuery>
);
