import { theme } from "@nest-ui/styles";

const ImportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={theme.color.text.default}
      strokeLinecap="round"
    >
      <path strokeLinejoin="round" d="M10.64 8.725L8 11.485l-2.64-2.76" />
      <path d="M8 1.5v9.62M11.467 6.5h2.166c.479 0 .867.398.867.889v6.222c0 .491-.388.889-.867.889H2.367a.878.878 0 0 1-.867-.889V7.39c0-.491.388-.889.867-.889h2.166" />
    </g>
  </svg>
);

export { ImportIcon };
