// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";

import { CurrencyField } from "components/CurrencyField";
import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { NumberField } from "components/NumberField";
import { TextArea } from "components/TextArea";
import { SelectField } from "components/SelectField";

import { InfoTile, InfoSection } from "../InfoTile";

const NEST_DEAL_MUTATION = gql`
  mutation UpdateNestDealForViewingApp($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      groundRent
      leaseRemaining
      serviceCharge
      tenure
      occupancy
    }
  }
`;

const PROPERTY_MUTATION = gql`
  mutation UpdatePropertyForViewingApp($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      freeholderDetails
      serviceChargeParticulars
    }
  }
`;

type Props = {
  editMode: boolean,
  nestDeal: ViewingAppNestDealQuery_nestDeal,
};

export const LeaseDetailsSection = (props: Props) => {
  const { editMode, nestDeal } = props;
  const {
    id: dealId,
    groundRent,
    leaseRemaining,
    serviceCharge,
    tenure,
    property,
    occupancy,
  } = nestDeal;
  const {
    serviceChargeParticulars,
    freeholderDetails,
    id: propertyId,
  } = property;

  const [UpdateNestDealForViewingApp] = useMutation(NEST_DEAL_MUTATION);
  const [UpdatePropertyForViewingApp] = useMutation(PROPERTY_MUTATION);

  const dealMutation = (input) =>
    UpdateNestDealForViewingApp({
      variables: { id: dealId, input },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          __typename: "NestDeal",
          ...nestDeal,
          ...input,
        },
      },
    });

  const propertyMutation = (input) =>
    UpdatePropertyForViewingApp({
      variables: { id: propertyId, input },
      optimisticResponse: {
        __typename: "Mutation",
        updateProperty: {
          __typename: "Property",
          ...property,
          ...input,
        },
      },
    });

  if (editMode) {
    return (
      <>
        <H2>Lease Details</H2>
        <Grid columns={2} rows="auto auto">
          <LeafCell width={1}>
            <CurrencyField
              label="Ground Rent (pa)"
              value={groundRent}
              data-test="ground-rent:edit"
              mutation={dealMutation}
              property="groundRent"
            />
          </LeafCell>
          <LeafCell width={1}>
            <CurrencyField
              label="Service Charge (pa)"
              value={serviceCharge}
              data-test="service-charge:edit"
              mutation={dealMutation}
              property="serviceCharge"
            />
          </LeafCell>
          <LeafCell width={1}>
            <NumberField
              label="Years remaining on lease"
              value={leaseRemaining}
              data-test="lease-remaining:edit"
              mutation={dealMutation}
              property="leaseRemaining"
            />
          </LeafCell>
          <LeafCell width={1}>
            <SelectField
              data-test="tenure:edit"
              property="tenure"
              label="Tenure"
              value={tenure}
              mutation={dealMutation}
              options={[
                "Freehold",
                "Share of freehold",
                "Leasehold",
                "Commonhold",
                "Shared ownership",
                "I'm not sure",
              ]}
            />
          </LeafCell>
          <LeafCell width={1}>
            <TextArea
              data-test="service-charge-particulars:edit"
              label="What's included in the service charge?"
              mutation={propertyMutation}
              value={serviceChargeParticulars}
              property="serviceChargeParticulars"
            />
          </LeafCell>
          <LeafCell width={1}>
            <TextArea
              data-test="freeholder-details:edit"
              label="Freeholder Details"
              mutation={propertyMutation}
              value={freeholderDetails}
              property="freeholderDetails"
            />
          </LeafCell>
          <LeafCell width={2}>
            <SelectField
              data-test="property-occupancy-select-field"
              label="Property occupancy"
              value={occupancy}
              property="occupancy"
              mutation={dealMutation}
              options={["Owner", "Tenant", "Vacant"]}
            />
          </LeafCell>
        </Grid>
      </>
    );
  }

  return (
    <InfoSection title="Lease Details (if applicable)">
      <InfoTile
        data-test="tenure"
        title="Freehold / Leasehold?"
        text={tenure}
      />

      <InfoTile
        data-test="freeholder-details"
        title="Who is the freeholder?"
        text={freeholderDetails}
      />

      <InfoTile
        data-test="service-charge"
        title="Service charge"
        text={`£${serviceCharge || "--"} (pa)`}
      />

      <InfoTile
        data-test="ground-rent"
        title="Ground rent"
        text={`£${groundRent || "--"} (pa)`}
      />

      <InfoTile
        data-test="lease-length"
        title="Lease length"
        text={`${leaseRemaining || "--"} years remaining`}
      />

      <InfoTile
        data-test="service-charge-particulars"
        title="What is included in the service charges? (e.g. building insurance)"
        text={serviceChargeParticulars}
      />
    </InfoSection>
  );
};
