// @flow
import { css } from "styled-components";
import { useQuery, useMutation } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { Form, Field } from "react-final-form";
import { useNotifications } from "@nest-ui/sellers-nest/hooks/useNotifications";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Select } from "../../../components/Select/Select";
import { Button } from "../../../components/Button/Button";
import { TextInput } from "../../../components/TextInput/TextInput";

import {
  formStyle,
  submitButtonStyle,
  required,
  fieldStyle,
} from "./StatusFormShared";

const nurtureHotnessStyle = css`
  ${fieldStyle}
`;

const deactivationFormStyle = css`
  ${formStyle};
  position: relative;
`;

export const DEACTIVATION_OPTIONS_QUERY = gql`
  query DeactivationOptions {
    notProceededNextSteps {
      id
      label
      value: id
    }
    preCloseFalloutReasons {
      label
      value
    }
  }
`;

export const DEACTIVATION_MUTATION = gql`
  mutation DeactivateDeal($input: DeactivateDealInput!) {
    deactivateDeal(input: $input) {
      id
      active
      nurtureHotness {
        label
      }
    }
  }
`;

const OTHER_AGENT = ["2", "3", "4"];

const instructedAnotherAgent = (nextStepId) => OTHER_AGENT.includes(nextStepId);

export const NURTURE_TEMPERATURE_OPTIONS = [
  { label: "🥵 Hot", value: "HOT" },
  { label: "😊 Warm", value: "WARM" },
  { label: "🥶 Cold", value: "COLD" },
  { label: "⛔️ Don't nurture", value: "DO_NOT_NURTURE" },
];

type Props = {
  closeForm: () => void,
  closeOverlay?: () => void,
  "data-test"?: string,
  keepToggleInactive?: () => void,
  id: string,
};

export const DeactivationForm = ({
  id,
  closeForm,
  closeOverlay,
  "data-test": dataTest = "deactivation-form",
  keepToggleInactive,
}: Props) => {
  const { createNotification } = useNotifications();
  const { data, loading, error } = useQuery(DEACTIVATION_OPTIONS_QUERY);
  const [deactivateDeal, { error: mutationError }] = useMutation(
    DEACTIVATION_MUTATION,
  );

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return null;
  }

  const { notProceededNextSteps, preCloseFalloutReasons } = data;

  const onSubmit = async (values) => {
    const input = Object.assign(values, { dealId: id });
    try {
      const result = await deactivateDeal({
        variables: { input },
        optimisticResponse: {
          deactivateDeal: {
            __typename: "NestDeal",
            id,
            active: false,
            nurtureHotness: {
              __typename: "NurtureHotness",
              label: values.nurtureHotness,
            },
          },
        },
      });
      if (!result?.data?.deactivateDeal.active) {
        createNotification("Deactivated");
        closeOverlay ? closeOverlay() : closeForm();
      } else {
        errorHandler(mutationError);
        return null;
      }
    } catch (e) {
      if (keepToggleInactive) keepToggleInactive();
      errorHandler(e);
    }
    return null;
  };
  return (
    <div css={deactivationFormStyle}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, values, hasValidationErrors, submitting }) => {
          // stops eslint complaining about param reassignment of the values variable
          /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["values"] }] */

          /* The notProceededWinningAgent field only appears if the next step is about
          choosing another agent. If the winning agent has been filled in, then the next step
          is changed so that the winning agent field is no longer relevant, we need to remove
          the winning agent value so that it is not incorrectly submitted with the form. */
          if (!instructedAnotherAgent(values?.notProceededNextStepId))
            delete values?.notProceededWinningAgent;

          return (
            <form onSubmit={handleSubmit} data-test={dataTest}>
              <Field name="notProceededNextStepId" validate={required}>
                {({ input }) => {
                  return (
                    <Select
                      data-test={`${dataTest}:next-step-dropdown`}
                      options={notProceededNextSteps}
                      css={fieldStyle}
                      label="Customer's next step"
                      labelAbove
                      valid={Boolean(values?.notProceededNextStepId)}
                      {...input}
                    />
                  );
                }}
              </Field>
              {instructedAnotherAgent(values?.notProceededNextStepId) && (
                <Field name="notProceededWinningAgent">
                  {({ input }) => (
                    <TextInput
                      data-test={`${dataTest}:winning-agent`}
                      label="Winning agent"
                      showOptionalLabel
                      css={fieldStyle}
                      {...input}
                    />
                  )}
                </Field>
              )}
              <Field name="preCloseFalloutReason" validate={required}>
                {({ input }) => {
                  return (
                    <Select
                      data-test={`${dataTest}:fallout-reason-dropdown`}
                      options={preCloseFalloutReasons}
                      css={fieldStyle}
                      label="Fallout reason"
                      labelAbove
                      valid={Boolean(values?.preCloseFalloutReason)}
                      {...input}
                    />
                  );
                }}
              </Field>
              <Field name="falloutComments">
                {({ input }) => (
                  <TextInput
                    data-test={`${dataTest}:deactivation-explanation`}
                    label="Explanation"
                    showOptionalLabel
                    css={fieldStyle}
                    {...input}
                  />
                )}
              </Field>

              <Field name="nurtureHotness" validate={required}>
                {({ input }) => {
                  return (
                    <Select
                      data-test={`${dataTest}:nurture-hotness-dropdown`}
                      options={NURTURE_TEMPERATURE_OPTIONS}
                      css={nurtureHotnessStyle}
                      label="Lead hotness"
                      labelAbove
                      valid={Boolean(values?.nurtureHotness)}
                      {...input}
                    />
                  );
                }}
              </Field>

              <div css={submitButtonStyle}>
                <Button
                  data-test={`${dataTest}:submit-button`}
                  disabled={hasValidationErrors || submitting}
                  buttonStyle="pink"
                  type="submit"
                  css="width: 100%;"
                >
                  {submitting ? "Saving..." : "Save"}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
