// @flow
import T from "prop-types";

import { LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import { updateDealTypeGuaranteeConfig } from "./mutations/updateDealTypeGuaranteeConfig";
import UpdateDealTypeGuaranteeMutation from "./mutations/UpdateDealTypeGuaranteeMutation.graphql";

type Props = {|
  nestDeal: {
    dealTypeDetails: DealTypeGuaranteeGlobalFragment,
  },
  updateDealTypeGuarantee: () => Promise<UpdateDealTypeGuarantee>,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
|};

export const GuaranteeComponent = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeGuarantee,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            mutation={updateDealTypeGuarantee}
            readOnly={readOnly}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            mutation={updateDealTypeGuarantee}
            readOnly={readOnly}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}
  </>
);

GuaranteeComponent.propTypes = {
  nestDeal: T.object.isRequired,
  updateDealTypeGuarantee: T.func.isRequired,
  readOnly: T.bool,
};

export const Guarantee = compose(
  graphql(UpdateDealTypeGuaranteeMutation, updateDealTypeGuaranteeConfig),
)(GuaranteeComponent);
