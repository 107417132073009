// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { ProgressorSelect } from "@nested/nest/src/pages/Deal/Progression/ProgressionSummary/ProgressorSelect/ProgressorSelect";
import { ExchangeAndCompleteDates } from "@nested/nest/src/pages/Deal/Progression/ProgressionSummary//ExchangeAndCompleteDates/ExchangeAndCompleteDates";
import { Button } from "@nested/nest/src/components/Button/Button";

const modalStyle = css`
  ${media.tablet`
    height: 500px;
    width: 700px;
  `}
`;

const progressorAndDatesStyle = css`
  & > * {
    margin-bottom: 14px;
  }
  ${media.tablet`
    display: grid;
    gap: 20px;
    grid-template-columns: 40% calc(60% - 20px);
  `}
`;

const buttonStyle = css`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  ${media.tablet`
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    padding: 20px;
    button {
      max-width: 96px;
      margin: 0 0 0 auto;
    }
  `}
`;

const wrapperStyle = css`
  padding: 10px;
`;

type Props = {
  dealId: string,
  buyerOfferId: string,
  open: boolean,
  onClose(): void,
};

export const MemoValidationModal = ({
  dealId,
  buyerOfferId,
  onClose,
  open,
}: Props) => (
  <MobileFullScreenModal
    open={open}
    css={modalStyle}
    onClose={onClose}
    headerText="Confirm expected dates and progressor"
  >
    <div css={wrapperStyle}>
      <div css={progressorAndDatesStyle}>
        <ProgressorSelect id={dealId} />
        <ExchangeAndCompleteDates buyerOfferId={buyerOfferId} />
      </div>
      <div css={buttonStyle}>
        <Button buttonStyle="pink" onClick={onClose} css="width: 100%;">
          Done
        </Button>
      </div>
    </div>
  </MobileFullScreenModal>
);
