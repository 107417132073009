// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import { Grid, LeafCell } from "components/Grid";
import { RadioButtons } from "components/RadioButtons";
import { TextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { yesNoNAOptions } from "@nest-ui/shared/options";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

const PROPERTY_QUESTIONNAIRE_QUERY = gql`
  query PostSurveyChecklistQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      issuesIdentifiedBySurveyor
      surveyIdentifiedMajorPropertyDefects
    }
  }
`;
const PROPERTY_QUESTIONNAIRE_MUTATION = gql`
  mutation UpdatePostSurveyChecklist($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      issuesIdentifiedBySurveyor
      surveyIdentifiedMajorPropertyDefects
    }
  }
`;

export function PostSurveyChecklist({
  parentId: externalDealId,
}: {|
  parentId: string,
|}) {
  return (
    <ExtendedQuery
      query={PROPERTY_QUESTIONNAIRE_QUERY}
      variables={{ id: externalDealId }}
    >
      {({ nestDeal }: ExtendedQueryRenderProps<PostSurveyChecklistQuery>) => (
        <ExtendedMutation mutation={PROPERTY_QUESTIONNAIRE_MUTATION}>
          {(
            mutation: MutationFunction<
              UpdatePostSurveyChecklist,
              UpdatePostSurveyChecklistVariables,
            >,
          ) => {
            const updatePostSurveyChecklist = (input) =>
              mutation({
                variables: {
                  id: nestDeal.id,
                  input,
                },
                optimisticResponse: {
                  updateNestDeal: {
                    ...nestDeal,
                    ...input,
                  },
                },
              });

            return (
              <Grid columns={2}>
                <LeafCell width={1}>
                  <RadioButtons
                    data-test="post-survey-checklist:survey-identified-major-property-defects"
                    label="Major property defects identified in survey"
                    mutation={updatePostSurveyChecklist}
                    options={yesNoNAOptions}
                    property="surveyIdentifiedMajorPropertyDefects"
                    value={nestDeal.surveyIdentifiedMajorPropertyDefects}
                  />
                </LeafCell>

                <LeafCell width={1} left={1}>
                  <TextareaWithLinks
                    data-test="post-survey-checklist:issues-identified-by-surveyor"
                    label="Major property defect details (surveyor)"
                    mutation={updatePostSurveyChecklist}
                    property="issuesIdentifiedBySurveyor"
                    value={nestDeal.issuesIdentifiedBySurveyor}
                  />
                </LeafCell>
              </Grid>
            );
          }}
        </ExtendedMutation>
      )}
    </ExtendedQuery>
  );
}
