// @flow

import type { Node } from "react";

import { useState } from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import {
  type MutationFunction,
  type RefetchQueriesProviderFn,
} from "@apollo/client/react/components";
import { Grid, Cell } from "components/Grid";
import { device } from "@nest-ui/styles";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { Button } from "components/Buttons";
import { NoSubmitNumberField } from "components/NumberField";
import { Add, Pencil, ExternalLink } from "@nest-ui/icons";

import { theme as comparablesTheme } from "../theme";

import { RatingsChip } from "./ComparabilityRatings";
import { ComparableTooltip, DELETE_COMPARABLE } from "./ComparableTooltip";
import {
  EditableField,
  DottedListItem,
  DottedList,
  DottedListItemSpaced,
} from "./ComparableCardComponents";
import { SalesInformation } from "./SalesInformation";

export const EDIT_COMPARABLE = gql`
  mutation editComparable($id: ID!, $input: EditComparableInput!) {
    editComparable(id: $id, input: $input) {
      id
      address
      numberOfBeds
      propertyType
      status
      underOfferDate
      underOfferPrice
      soldDate
      soldPrice
    }
  }
`;

const Wrapper = styled(Grid)`
  background-color: ${({ theme }) => theme.background.card};
  border-radius: 4px;
  color: ${({ theme }) => theme.text.white};
  min-height: 132px;
  width: 100%;
  padding: 16px;
  border: ${({ theme, selected }) =>
    selected ? theme.border.cardSelected : theme.border.cardUnselected};

  @media ${device.desktop} {
    grid-template-columns: 121px 1fr 80px 40px;
  }
`;

export const PropertyPhotoContainer = styled(Cell)`
  background: ${({ imageUrl }) =>
    imageUrl
      ? `center / cover no-repeat url(${imageUrl})`
      : "url(https://nested.imgix.net/missingProperty.svg) center center no-repeat, #e1f4ef"};
  border-radius: 4px;
  height: 150px;

  @media ${device.desktop} {
    height: unset;
  }
`;

const AddressCell = styled(Cell)`
  font-size: 16px;
  font-weight: 500;
  min-height: 32px;
  display: grid;
  align-items: center;
`;

const AttributesCell = styled(Cell)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
`;

const SalesInfoCell = styled(Cell)`
  align-items: center;
  display: flex;
  font-size: 14px;
  min-height: 32px;
`;

const LinksCell = styled(Cell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const IconCell = styled(Cell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Icon = styled.span`
  svg {
    height: 18px;
    width: 18px;
  }
`;

const Cross = styled(Icon)`
  svg {
    transform: rotate(45deg);
  }
  margin-left: 4px;
`;

const IconLink = styled.a`
  text-decoration: none;
  border-radius: 50%;
  cursor: pointer;
`;

const DoneButton = styled(Button)`
  margin: 0 0 0 auto;
  background-color: ${({ theme }) => theme.button.green};
`;

const Link = styled.a`
  align-items: center;
  border-radius: 12px;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  justify-content: space-between;
  letter-spacing: 1px;
  margin-right: 10px;
  padding: 5px 10px;
  text-decoration: none;

  background-color: ${({ type, theme }) => {
    switch (type) {
      case "RIGHTMOVE":
        return theme.button.green;
      case "RIGHTMOVE_PLUS":
        return theme.background.mainSection;
      default:
        return theme.button.grey;
    }
  }};

  svg {
    height: 13px;
  }

  div {
    margin-left: 4px;
  }
`;

const areas = [
  "photo      address      address      iconsTop",
  "photo      salesInfo    links        links",
  "photo      propertyInfo propertyInfo iconsBottom",
  "additional additional   additional   additional",
];

const linkText = (type) => {
  switch (type) {
    case "RIGHTMOVE":
      return <div>RM</div>;
    case "RIGHTMOVE_PLUS":
      return <div>RM+</div>;
    default:
      return "";
  }
};

type LinkProps = {
  comparable: ListComparablesQuery_comparables,
};

const Links = ({ comparable }: LinkProps) => (
  <LinksCell area="links">
    {comparable.comparableLinks.map((link) => (
      <Link
        data-test={`comparable-card:link/${link.type}`}
        href={link.url}
        key={`${link.type}:${link.url}`}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        type={link.type}
      >
        <ExternalLink />
        {linkText(link.type)}
      </Link>
    ))}
  </LinksCell>
);

type Props = {
  children?: Node,
  comparable: ListComparablesQuery_comparables,
  selectAction?: () => void,
  selected?: boolean,
  refetchOnDelete?: RefetchQueriesProviderFn,
  disableSelect: (boolean) => void,
};

export const ComparableCard = ({
  comparable,
  refetchOnDelete,
  children,
  selectAction,
  selected = false,
  disableSelect,
}: Props) => {
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <Wrapper
      key={comparable.id}
      areas={children ? areas : areas.slice(0, areas.length - 1)}
      columnGap="16px"
      data-test="comparable-wrapper"
      selected={selected}
      onClick={selectAction}
    >
      <ExtendedMutation mutation={EDIT_COMPARABLE}>
        {(
          editComparable: MutationFunction<
            editComparable,
            editComparableVariables,
          >,
        ) => {
          const mutation = (input) =>
            editComparable({
              variables: {
                id: comparable.id,
                input,
              },
              optimisticResponse: {
                editComparable: {
                  __typename: "Comparable",
                  id: comparable.id,
                  address: comparable.address,
                  numberOfBeds: comparable.numberOfBeds,
                  propertyType: comparable.propertyType,
                  status: comparable.status,
                  underOfferDate: comparable.underOfferDate,
                  underOfferPrice: comparable.underOfferPrice,
                  soldPrice: comparable.soldPrice,
                  soldDate: comparable.soldDate,
                  ...input,
                },
              },
            });

          return (
            <>
              <PropertyPhotoContainer
                area="photo"
                imageUrl={comparable.comparableImages[0]?.url}
              />

              <AddressCell area="address" className="fs-exclude">
                {editMode ? (
                  <EditableField
                    largeText
                    data-test="address-editable-field"
                    value={comparable.address}
                    isSecondary
                    highlight
                    onSubmit={(value) => mutation({ address: value })}
                  />
                ) : (
                  <span data-test="address-non-editable">
                    {comparable.address}
                  </span>
                )}
              </AddressCell>

              {!editMode && (
                <IconCell area="iconsTop">
                  <IconLink
                    data-test="edit-comp-tooltip-menu"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditMode(true);
                      disableSelect(true);
                    }}
                  >
                    <Icon>
                      <Pencil fill={comparablesTheme.text.white} />
                    </Icon>
                  </IconLink>

                  <ExtendedMutation mutation={DELETE_COMPARABLE}>
                    {(
                      deleteComparable: MutationFunction<
                        DeleteComparable,
                        DeleteComparableVariables,
                      >,
                    ) => (
                      <IconLink
                        onClick={(e) => {
                          e.stopPropagation();
                          const { id, address } = comparable;
                          // eslint-disable-next-line no-alert
                          const confirmation = window.confirm(
                            `Are you sure you want to remove ${address}?`,
                          );
                          if (confirmation) {
                            deleteComparable({
                              mutation: DELETE_COMPARABLE,
                              variables: { id },
                              refetchQueries: refetchOnDelete,
                            });
                          }
                        }}
                      >
                        <Cross>
                          <Add fill={comparablesTheme.text.white} />
                        </Cross>
                      </IconLink>
                    )}
                  </ExtendedMutation>
                </IconCell>
              )}

              <SalesInfoCell area="salesInfo">
                <SalesInformation
                  comparable={comparable}
                  editMode={editMode}
                  mutation={mutation}
                />
              </SalesInfoCell>

              {!editMode && <Links comparable={comparable} />}

              <AttributesCell area="propertyInfo">
                <DottedList>
                  {editMode ? (
                    <DottedListItemSpaced>
                      <EditableField
                        as={NoSubmitNumberField}
                        narrow
                        data-test="bedrooms-editable-field"
                        value={comparable.numberOfBeds}
                        isSecondary
                        onSubmit={(value) => mutation({ numberOfBeds: value })}
                      />{" "}
                      bed
                    </DottedListItemSpaced>
                  ) : (
                    comparable.numberOfBeds && (
                      <DottedListItemSpaced>
                        {comparable.numberOfBeds} bed
                      </DottedListItemSpaced>
                    )
                  )}

                  {editMode ? (
                    <DottedListItemSpaced>
                      <EditableField
                        data-test="property-type-editable-field"
                        value={comparable.propertyType}
                        isSecondary
                        onSubmit={(value) => mutation({ propertyType: value })}
                      />
                    </DottedListItemSpaced>
                  ) : (
                    comparable.propertyType && (
                      <DottedListItemSpaced>
                        {comparable.propertyType}
                      </DottedListItemSpaced>
                    )
                  )}

                  <DottedListItem>
                    <RatingsChip
                      comparabilityRating={comparable.comparabilityRating}
                      relevanceRating={comparable.relevanceRating}
                    />
                  </DottedListItem>
                </DottedList>
              </AttributesCell>
              <IconCell area="iconsBottom">
                <ComparableTooltip
                  data-test={`comparable-card-tooltip:${comparable.id}`}
                  id={comparable.id}
                  address={comparable.address}
                  comparabilityRating={comparable.comparabilityRating}
                  comparableLinks={comparable.comparableLinks}
                  relevanceRating={comparable.relevanceRating}
                  refetchOnDelete={refetchOnDelete}
                  setEditMode={setEditMode}
                  disableSelect={disableSelect}
                />
              </IconCell>
            </>
          );
        }}
      </ExtendedMutation>

      {editMode && (
        <Cell width={3}>
          <DoneButton
            onClick={() => {
              setEditMode(false);
              disableSelect(false);
            }}
            data-test="done-editing-button"
          >
            Done
          </DoneButton>
        </Cell>
      )}

      {children && <Cell area="additional">{children}</Cell>}
    </Wrapper>
  );
};
