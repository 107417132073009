// @flow

import { ErrorBoundary } from "components/ErrorBoundary";
import { Grid } from "components/Grid";
import { Card } from "components/Card";

import { ResponsiblePeopleNotes } from "./ResponsiblePeopleNotes";
import { ResponsiblePeopleSelects } from "./ResponsiblePeopleSelects";

type Props = {
  parentId: string,
};

export const ResponsiblePeople = ({ parentId: dealId }: Props) => (
  <ErrorBoundary>
    <Card>
      <Grid columns={1}>
        <ResponsiblePeopleNotes dealId={dealId} />
        <ResponsiblePeopleSelects dealId={dealId} />
      </Grid>
    </Card>
  </ErrorBoundary>
);
