// @flow
import { Grid, LeafCell } from "components/Grid";
import { DateTimePicker } from "components/DateTimePicker";
import { TextareaWithLinks as TextAreaWithLinks } from "components/TextareaWithLinks";
import { Checkbox } from "components/Checkbox";

import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import surveyQuery from "./queries/SurveyQuery.graphql";
import updateDealWithSurvey from "./mutations/UpdateDealWithSurvey.graphql";

type SurveySectionQuery = {
  ...SurveyQuery,
  nestDeal: SurveyQuery_nestDeal,
};

type Props = {
  parentId: string,
};

const Survey = ({ parentId }: Props) => (
  <ExtendedQuery query={surveyQuery} variables={{ dealId: parentId }}>
    {({ nestDeal }: ExtendedQueryRenderProps<SurveySectionQuery>) => (
      <Mutation mutation={updateDealWithSurvey}>
        {(
          updateNestDeal: MutationFunction<
            UpdateDealWithSurvey,
            UpdateDealWithSurveyVariables,
          >,
        ) => (
          <Grid columns={4}>
            <LeafCell left={1}>
              <DateTimePicker
                label="Scheduled date"
                value={nestDeal.surveyStart}
                property="surveyStart"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="survey-start"
              />
            </LeafCell>
            <LeafCell left={1} width={2}>
              <TextAreaWithLinks
                label="Surveyor company"
                value={nestDeal.surveyorCompany}
                property="surveyorCompany"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="surveyor-company"
              />
            </LeafCell>
            <LeafCell width={2}>
              <TextAreaWithLinks
                label="Surveyor name"
                value={nestDeal.surveyorName}
                property="surveyorName"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
                data-test="surveyor-name"
              />
            </LeafCell>
            <LeafCell left={1}>
              <Checkbox
                label="Shared with VA"
                optionName="Yes"
                value={nestDeal.surveySharedWithVa}
                data-test="survey-shared-with-va"
                property="surveySharedWithVa"
                mutation={mutationConfig(updateNestDeal, nestDeal, parentId)}
              />
            </LeafCell>
          </Grid>
        )}
      </Mutation>
    )}
  </ExtendedQuery>
);

const mutationConfig =
  (
    mutate: MutationFunction<
      UpdateDealWithSurvey,
      UpdateDealWithSurveyVariables,
    >,
    nestDeal: SurveyQuery_nestDeal,
    id: string,
  ) =>
  (input) =>
    mutate({
      variables: {
        nestDeal: input,
        dealId: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateNestDeal: {
          __typename: "NestDeal",
          id,
          ...nestDeal,
          ...input,
        },
      },
    });

export { Survey };
