// @flow
import { gql } from "@apollo/client";
import { type ContextRouter } from "react-router-dom";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { Card } from "components/Card";
import { H2 } from "components/Heading";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { CloseMiscChecklist } from "./CloseMiscChecklist/CloseMiscChecklist";
import { ContractChecklist } from "./ContractChecklist/ContractChecklist";
import { DealTypes } from "./DealTypes/DealTypes";
import { AMLCheckList } from "./AMLCheckList/AMLCheckList";

const CLOSE_TAB_QUERY = gql`
  query CloseTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
    }
  }
`;

export const Close = (props: ContextRouter) => {
  const {
    match: {
      params: { id: idOrExternalDealId },
    },
  } = props;

  return (
    <ExtendedQuery query={CLOSE_TAB_QUERY} variables={{ idOrExternalDealId }}>
      {({ nestDeal }: ExtendedQueryRenderProps<CloseTabQuery>) => {
        const { id } = nestDeal;

        return (
          <TabWrapper css="display: block;">
            <TabContainer id="Close" data-test="close-tab" fullWidth>
              <Card>
                <CloseMiscChecklist dealId={id} />
              </Card>
              <Card>
                <H2>Fees</H2>
                <DealTypes parentId={id} />
              </Card>
              <Card>
                <H2>AML checklist</H2>
                <AMLCheckList parentId={id} />
              </Card>
              <Card>
                <H2>Contract</H2>
                <ContractChecklist dealId={id} />
              </Card>
            </TabContainer>
          </TabWrapper>
        );
      }}
    </ExtendedQuery>
  );
};
