// @flow
import styled, { css } from "styled-components";
import { media } from "@nested/brand";
import { BackButton } from "../BackButton";
import { XButton } from "../XButton";

const navStyle = css`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.hague100};
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  ${media.desktop`
    padding: 10px 20px;
  `}
`;

export const OverlayBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 98;
  background: white;
  ${({ $open }) =>
    $open
      ? css`
          display: block;
          pointer-events: auto;
        `
      : css`
          display: none;
        `}
`;

const headingStyle = css`
  color: white;
  font-weight: 500;
  margin: auto;
`;

const closeIconStyle = css`
  padding: 15px;
  color: white;
  font-size: 14px;
`;

type NavProps = {
  className?: string,
  heading: string,
  onBack?: () => void,
  onClose: () => void,
  withBackButton?: boolean,
};

export const OverlayNav = ({
  className,
  heading,
  onBack,
  onClose,
  withBackButton,
}: NavProps) => (
  <nav css={navStyle} className={className}>
    <div css={headingStyle}>{heading}</div>
    {withBackButton && onBack ? (
      <BackButton onClick={onBack} css="color: white;" />
    ) : (
      <XButton
        css={closeIconStyle}
        data-test="close-overlay"
        onClick={onClose}
      />
    )}
  </nav>
);

type Props = {
  children: React$Node,
  className?: string,
  heading: string,
  open: boolean,
  onBack?: () => void,
  onClose: () => void,
  withBackButton?: boolean,
};

export const Overlay = ({
  children,
  className,
  heading,
  onBack,
  onClose,
  open,
  withBackButton,
}: Props) => (
  <OverlayBackground $open={open} className={className}>
    <OverlayNav
      heading={heading}
      onClose={onClose}
      onBack={onBack}
      withBackButton={withBackButton}
    />
    {children}
  </OverlayBackground>
);
