// @flow
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { FORM_ERROR } from "final-form";
import { useHistory } from "react-router-dom";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { gql, useMutation } from "@apollo/client";
import { ADDRESS_NOT_FOUND_ID, media } from "@nested/brand";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { calculateLeadScore } from "@nested/onboarding-form/src/forms/usePersonalInformationOnSubmit";
import { MARKETING_OPTIONS } from "@nest-ui/sellers-nest/tabs/Valuation/EditSources/EditSources";
import { Button } from "../../Button/Button";
import { MobileFullScreenModal } from "../../Modal";
import { TextInput } from "../../TextInput/TextInput";
import { AddressPicker } from "../../AddressPicker/AddressPicker";
import {
  EmailInputWithValidation,
  errorMessageStyle,
} from "../../TextInput/EmailInputWithValidation";
import { RadioList } from "../../RadioList";
import { CheckboxList } from "../../CheckboxList";
import { Checkbox } from "../../Checkbox/Checkbox";
import { AreYouSureOverlay } from "../../AreYouSureOverlay";

const INTENT_OPTIONS = [
  {
    value: "ALREADY_ON_THE_MARKET",
    label: "Already on the market",
  },
  {
    value: "READY_TO_SELL",
    label: "Ready to sell",
  },
  {
    value: "THINKING_ABOUT_IT",
    label: "Thinking about selling",
  },
  {
    value: "JUST_CURIOUS",
    label: "Just curious",
  },
];

const URGENCY_OPTIONS = [
  {
    value: "LESS_THAN_THREE_MONTHS",
    label: "In the next 3 months",
  },
  {
    value: "THREE_TO_SIX_MONTHS",
    label: "In 3-6 months",
  },
  {
    value: "SIX_MONTHS_PLUS",
    label: "In 6+ months",
  },
];

export const ONBOARD_DEAL = gql`
  mutation OnboardDeal($input: OnboardDealInput!) {
    onboardDeal(input: $input) {
      externalId
    }
  }
`;

const formWrapper = css`
  padding: 10px 20px;
  ${media.tablet`
    padding: 0 30px;
    display: flex;
  `}
`;

const columnStyle = css`
  ${media.tablet`
    width: 50%;
    padding: ${({ $right }) => ($right ? "0 0 0 25px" : "0 25px 0 0")};
  `}
`;

export const SubHeading = styled.div`
  color: ${({ theme }) => theme.palette.hague40};
  letter-spacing: 1.5px;
  padding: 10px 0;
  text-transform: uppercase;
`;

const fieldsStyle = css`
  padding: 10px 0;

  & input {
    ::placeholder {
      color: ${({ theme }) => theme.palette.hague40};
    }
  }

  & ul {
    top: unset;
  }
`;

const emailFieldStyle = css`
  ${fieldsStyle};
`;

const addressErrorMessageStyle = css`
  ${errorMessageStyle}
  bottom: -10px;
`;

export const submitButtonWrapper = css`
  padding: 20px;
  ${media.tablet`
    margin-top: 20px;
    position: static;
    border-top: 1px solid ${({ theme }) => theme.palette.hague20};
    button {
      width: 310px;
      margin: 0 auto;
    }
`}
`;

type Props = {
  open: boolean,
  onClose: () => void,
  formInitialValues?: {
    firstName?: string,
    lastName?: string,
    email?: string,
    addressId?: string,
  },
};

export const Section = ({
  title,
  children,
}: {
  title: string,
  children: React$Node,
}) => (
  <section css="padding: 0; margin: 0;">
    <SubHeading>{title}</SubHeading>
    {children}
  </section>
);

const validate = (value) => (value ? undefined : "Required");

const CreateDealForm = ({
  isOpen,
  onClose,
  setEditing,
  formInitialValues = {},
}) => {
  const firstNameInput = useRef();
  const [onboardDeal] = useMutation(ONBOARD_DEAL);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (firstNameInput.current) {
        firstNameInput.current?.focus();
      }
    }, 500);
  }, [isOpen]);

  const onSubmit = async (args) => {
    const {
      customer,
      property: {
        address: { id: addressId, postcode },
        manualAddress: manualAddressInput,
      },
    } = args;
    const leadScore = calculateLeadScore(customer);

    let manualAddress;
    if (addressId === ADDRESS_NOT_FOUND_ID) {
      manualAddress = {
        ...manualAddressInput,
        postcode,
      };
    }

    const input = {
      customer: { ...customer, leadScore },
      property: { addressId, manualAddress },
    };

    try {
      const { data } = await onboardDeal({
        variables: { input },
      });
      if (data?.onboardDeal) {
        onClose();
        history.push(`/deals/${data.onboardDeal.externalId}`);
      }
      return undefined;
    } catch (e) {
      errorHandler(e);
      return {
        [FORM_ERROR]: "Failed to create seller",
      };
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={{
        customer: {
          sources: [],
          sendWelcomeEmail: true,
          sendAccountCreatedEmail: true,
          firstName: formInitialValues?.firstName,
          lastName: formInitialValues?.lastName,
          email: formInitialValues?.email,
        },
        property: formInitialValues?.addressId
          ? {
              address: {
                id: formInitialValues?.addressId,
              },
            }
          : undefined,
      }}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        submitting,
        validating,
        hasValidationErrors,
        pristine,
        values,
      }) => (
        <FormFields
          setEditing={setEditing}
          pristine={pristine}
          handleSubmit={handleSubmit}
          submitting={submitting}
          validating={validating}
          hasValidationErrors={hasValidationErrors}
          formInitialValues={formInitialValues}
          values={values}
          isOpen={isOpen}
          firstNameInput={firstNameInput}
        />
      )}
    </Form>
  );
};

const FormFields = ({
  setEditing,
  pristine,
  handleSubmit,
  submitting,
  validating,
  hasValidationErrors,
  values,
  isOpen,
  firstNameInput,
}) => {
  useEffect(() => {
    setEditing(!pristine);
  }, [pristine]);

  return (
    <form onSubmit={handleSubmit}>
      <div css={formWrapper}>
        <div css={columnStyle}>
          <Section title="Seller details">
            <Field name="customer.firstName" validate={validate}>
              {({ input, meta }) => {
                return (
                  <TextInput
                    {...input}
                    className="fs-exclude"
                    css={fieldsStyle}
                    label="First name"
                    valid={!meta.error}
                    forwardedRef={firstNameInput}
                  />
                );
              }}
            </Field>
            <Field name="customer.lastName" validate={validate}>
              {({ input, meta }) => {
                return (
                  <TextInput
                    {...input}
                    className="fs-exclude"
                    css={fieldsStyle}
                    label="Last name"
                    valid={!meta.error}
                  />
                );
              }}
            </Field>
            <div css="position: relative;">
              <EmailInputWithValidation
                css={emailFieldStyle}
                fieldName="customer.email"
                isOpen={isOpen}
                label="Email"
              />
            </div>
            <Field name="customer.telephone">
              {({ input }) => (
                <TextInput
                  {...input}
                  className="fs-exclude"
                  css={fieldsStyle}
                  label="Telephone"
                  showOptionalLabel
                />
              )}
            </Field>
          </Section>
          <Section title="Property details">
            <Field name="property.address" validate={validate}>
              {({ input, meta }) => {
                return (
                  <div css="position: relative;">
                    <AddressPicker
                      {...input}
                      allowAddressNotFound
                      className="fs-exclude"
                      css={fieldsStyle}
                      label="Property address"
                      value={input.value.id}
                      valid={meta.valid}
                    />
                    {input.value && meta.error && (
                      <span css={addressErrorMessageStyle}>{meta.error}</span>
                    )}
                  </div>
                );
              }}
            </Field>
            {values?.property?.address?.id === ADDRESS_NOT_FOUND_ID && (
              <>
                <Field
                  name="property.manualAddress.lineOne"
                  validate={validate}
                >
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      className="fs-exclude"
                      css={fieldsStyle}
                      label="Address line one"
                      valid={!meta.error}
                    />
                  )}
                </Field>
                <Field name="property.manualAddress.lineTwo">
                  {({ input }) => (
                    <TextInput
                      {...input}
                      className="fs-exclude"
                      css={fieldsStyle}
                      label="Address line two"
                      showOptionalLabel
                    />
                  )}
                </Field>
                <Field name="property.manualAddress.city" validate={validate}>
                  {({ input, meta }) => (
                    <TextInput
                      {...input}
                      className="fs-exclude"
                      css={fieldsStyle}
                      label="City"
                      valid={!meta.error}
                    />
                  )}
                </Field>
                <TextInput
                  onChange={() => undefined}
                  className="fs-exclude"
                  disabled
                  css={fieldsStyle}
                  label="Postcode"
                  value={values?.property?.address?.postcode}
                />
              </>
            )}
            <Field name="customer.customerIntent" validate={validate}>
              {({ input, meta }) => (
                <div css="margin-top: 10px;">
                  <RadioList
                    {...input}
                    data-test="seller-circumstance-radio"
                    label="Selling circumstances"
                    selectedValue={input.value}
                    hasErrors={!meta.valid}
                    options={INTENT_OPTIONS}
                    dividers
                  />
                </div>
              )}
            </Field>
            {values?.customer?.customerIntent === "THINKING_ABOUT_IT" && (
              <Field name="customer.urgency" validate={validate}>
                {({ input, meta }) => (
                  <div css="margin-top: 10px;">
                    <RadioList
                      {...input}
                      data-test="urgency-radio"
                      label="When are you thinking of selling?"
                      selectedValue={input.value}
                      hasErrors={!meta.valid}
                      options={URGENCY_OPTIONS}
                      dividers
                    />
                  </div>
                )}
              </Field>
            )}
          </Section>
        </div>
        <div css={columnStyle} $right>
          <Section title="Marketing">
            {/* $FlowFixMe - known issue with typing in library */}
            <FieldArray name="customer.sources" validate={validate}>
              {({ fields, meta }) => {
                return (
                  <div>
                    <CheckboxList
                      css={fieldsStyle}
                      hasErrors={!meta.valid}
                      label="Marketing attribution"
                      options={MARKETING_OPTIONS}
                      selectedValues={fields}
                    />
                  </div>
                );
              }}
            </FieldArray>
            {values?.customer?.sources.some((source) =>
              ["A_FRIEND", "NESTED_EMPLOYEE", "PARTNER"].includes(source),
            ) && (
              <Field name="customer.referrer" validate={validate}>
                {({ input, meta }) => (
                  <TextInput
                    {...input}
                    label="Who referred you to Nested?"
                    valid={!meta.error}
                  />
                )}
              </Field>
            )}
            <Section title="communications">
              <Field type="checkbox" name="customer.sendWelcomeEmail">
                {({ input, meta }) => (
                  <Checkbox
                    labelText='Send "Welcome to Nested" email'
                    {...input}
                    {...meta}
                    css="margin-bottom: 10px;"
                    data-test="create-deal:send-welcome-email-checkbox"
                  />
                )}
              </Field>
              <Field type="checkbox" name="customer.sendAccountCreatedEmail">
                {({ input, meta }) => {
                  return (
                    <Checkbox
                      labelText='Send "Account created" email'
                      {...input}
                      {...meta}
                      data-test="create-deal:account-created-checkbox"
                    />
                  );
                }}
              </Field>
            </Section>
          </Section>
        </div>
      </div>
      <div css={submitButtonWrapper}>
        <Button
          buttonStyle="pink"
          css="&:disabled { opacity: 0.4 }"
          data-test="add-seller-button"
          disabled={submitting || hasValidationErrors || validating || pristine}
          large
          type="submit"
        >
          {submitting ? "Saving..." : "Add new seller"}
        </Button>
      </div>
    </form>
  );
};

export const CreateDeal = ({
  open,
  onClose,
  formInitialValues = {},
}: Props) => {
  const [areYouSure, setAreYouSure] = useState(false);
  const [editing, setEditing] = useState(false);

  const onCloseResetState = () => {
    setAreYouSure(false);
    setEditing(false);
    onClose();
  };

  const onCloseUnlessEditing = () => {
    if (editing) {
      setAreYouSure(true);
    } else {
      onCloseResetState();
    }
  };

  return (
    <MobileFullScreenModal
      onClose={onCloseUnlessEditing}
      open={open}
      css="width: 780px;"
      headerText="Add new seller"
    >
      {open && (
        <>
          <CreateDealForm
            isOpen={open}
            onClose={onCloseResetState}
            formInitialValues={formInitialValues}
            setEditing={setEditing}
          />
          {areYouSure && (
            <AreYouSureOverlay
              message="You have unsaved changes. Are you sure you want to stop creating this seller?"
              leftText="Continue editing"
              leftOnClick={() => setAreYouSure(false)}
              rightText="Discard changes"
              rightOnClick={onCloseResetState}
            />
          )}
        </>
      )}
    </MobileFullScreenModal>
  );
};
