// @flow

import styled from "styled-components";
import { Grid, Cell } from "components/Grid";

import { Info } from "@nest-ui/icons";
import { theme as comparablesTheme } from "../theme";
import { ComparisonPoint } from "./ComparisonPoint";

const Wrapper = styled.div`
  flex-grow: 1;
`;

const InfoWrapper = styled.div`
  margin: 0 auto;
  height: 24px;
  width: 24px;
`;

const BlankText = styled.span`
  color: ${({ theme }) => theme.text.grey};
  font-size: 12px;
  line-height: 1.5em;
`;

const StyledGrid = styled(Grid)`
  margin: 10px auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

type Props = {
  comparable: SelectedComparablesQuery_selectedComparables,
  comparisonPoints: $ReadOnlyArray<SelectedComparablesQuery_selectedComparables_comparisonPoints>,
};

export const ComparisonPointList = ({
  comparable,
  comparisonPoints,
}: Props) => (
  <Wrapper>
    {comparisonPoints.length > 0 ? (
      comparisonPoints.map(({ description, rating }, index) => (
        <ComparisonPoint
          data-test="comparison-point"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          description={description}
          rating={rating}
          comparable={comparable}
        />
      ))
    ) : (
      <StyledGrid columns={1}>
        <Cell left={1} width={1}>
          <InfoWrapper data-test="info-wrapper">
            <Info fill={comparablesTheme.text.grey} />
          </InfoWrapper>
        </Cell>
        <Cell center left={1} width={1}>
          <BlankText data-test="blank-text">
            Click 'Select' and start typing the comparision point, eg. 'garden'
            or 'size'
          </BlankText>
        </Cell>
      </StyledGrid>
    )}
  </Wrapper>
);
