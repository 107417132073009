// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Placeholder } from "../../../components/Placeholder";
import { buyerSummaryWrapper } from "./BuyerSummaryBar";

const buyerAvatarStyle = css`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: 0 10px 0 0;
  ${media.tablet`
    width: 42px;
    height: 42px;
    margin: 0 10px;
  `}
`;

const buyerSummaryContent = css`
  display: flex;
  flex-direction: column;
  padding: 30px 15px 20px;
  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px 15px;
  `}

  ${media.desktop`
    // max-width: 1220px; to be brought back before launch
  `}
`;

const contactSummaryStyle = css`
  && {
    display: none;
  }
  margin-left: auto;
  ${media.tablet`
    && {
      display: flex;
    }
    margin: 0 auto;
  `}
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const taskButtonStyle = css`
  margin-left: 10px;
  border-radius: 10px;
  align-items: center;
  ${media.tablet`
margin-left: 32px;
  `}
`;

const workflowButtons = css`
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    padding: 0;
  `}
`;

// We need bpiId and workflow, because this placeholder looks different depending whether we are in a workflow or not.
export const BuyerSummaryBarPlaceholder = ({
  bpiId,
  workflow,
}: {
  bpiId: ?string,
  workflow: ?string,
}) => (
  <div css={buyerSummaryWrapper}>
    <div css={buyerSummaryContent}>
      <div css="display: flex; align-items: center; width: 100%;">
        <Placeholder width={42} height={42} css={buyerAvatarStyle} />
        <div css="display: flex; flex-direction: column; justify-content: center;">
          <Placeholder css="margin-bottom: 4px;" height={10} width={89} />
          {bpiId && workflow && <Placeholder height={10} width={104} />}
        </div>
        {bpiId && workflow && <Placeholder css={contactSummaryStyle} />}
      </div>
      <div css={workflowButtons}>
        {bpiId && workflow && (
          <Placeholder
            width={57}
            height={26}
            css="padding: 7px 15px; border-radius: 5px;"
          />
        )}
        <Placeholder height={30} width={30} css={taskButtonStyle} />
      </div>
    </div>
  </div>
);
