// @flow
import { groupBy, sortBy, uniq } from "lodash";

export const groupEnquiriesByDeal = <T: { deal: { id: string } }>(
  enquiries: T[],
  bpiSorter?: (item: T) => any,
) => {
  const grouped = groupBy(enquiries, (bpi) => bpi.deal.id);

  return uniq(enquiries.map((bpi) => bpi.deal.id)).map((dealId) => ({
    deal: grouped[dealId][0].deal,
    bpis: sortBy(grouped[dealId], bpiSorter),
  }));
};

type Props = {
  index: number,
  bpis: any[],
  resultIndex: number,
  results: any[],
};

// We remove borders for all BPIs except the last one per deal, unless it's also the last deal in the table
export const noBorderBpi = ({ index, bpis, resultIndex, results }: Props) =>
  index !== bpis.length - 1 ||
  (index === bpis.length - 1 && resultIndex === results.length - 1);
