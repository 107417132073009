// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { media } from "@nested/brand";

/* New CRM branded buttons. 4 types:
 *
 * "default" : Hague with white text, no border
 * "pink" : Pink with hague text, no border
 * "white" : White with hague text and grey border
 * "outline" : Designed for use on dark backgrounds, hague with white border and text.
 *
 * All buttons optionally take a fontawesome icon which can be positioned on the left or right
 * of the text.
 */

const pinkButtonStyles = css`
  background-color: #ffc1cb;
  color: ${({ theme }) => theme.color.text.hague150};
`;

const whiteButtonStyles = css`
  background-color: white;
  color: ${({ theme }) => theme.color.text.hague150};
  border: 1px solid ${({ theme }) => theme.color.text.hague40};
`;

const greyButtonStyles = css`
  background-color: ${({ theme }) => theme.palette.hague20};
  color: ${({ theme }) => theme.color.text.hague150};
`;

const outlineButtonStyles = css`
  background-color: ${({ theme }) => theme.color.text.hague};
  color: white;
  border: 1px solid white;
`;

const buttonStyles = css`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
  padding: 7px 15px;
  background-color: ${({ theme }) => theme.color.text.hague};
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 42px;
  border-radius: 5px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  ${({ $buttonStyle }) => {
    switch ($buttonStyle) {
      case "pink":
        return pinkButtonStyles;

      case "white":
        return whiteButtonStyles;

      case "grey":
        return greyButtonStyles;

      case "outline":
        return outlineButtonStyles;

      default:
        return "";
    }
  }}
  text-align: ${({ $iconPosition }) => {
    switch ($iconPosition) {
      case "left":
        return "right";

      case "right":
        return "left";

      default:
        return "center";
    }
  }};

  ${media.tablet`
    width: auto;
    height: ${({ large }) => (large ? "42px" : "32px")};
  `}
`;

const defaultIconStyles = css`
  color: ${({ $buttonStyle }) => {
    switch ($buttonStyle) {
      case "pink":
        return css`
          ${({ theme }) => theme.color.text.hague};
        `;

      case "white":
        return css`
          ${({ theme }) => theme.color.text.hague70}
        `;

      case "outline":
        return css`rgba(255, 255, 255, 0.8)`;

      default:
        return css`#9ab3bb`;
    }
  }};
`;
type Props = {
  buttonStyle?: "default" | "pink" | "white" | "grey" | "outline",
  children: React$Node,
  className?: string,
  iconCSS?: string,
  disabled?: ?boolean,
  icon?: React$Node,
  iconPosition?: "left" | "right",
  large?: boolean,
  onClick?: (t: any) => any,
};

export const Button = ({
  buttonStyle = "default",
  children,
  className,
  iconCSS,
  disabled,
  icon,
  iconPosition = "right",
  large,
  onClick,
  ...props
}: Props) => {
  const iconStyle = css`
    ${defaultIconStyles}
    ${() =>
      iconPosition === "right"
        ? css`
            margin-left: 7px;
          `
        : css`
            margin-right: 7px;
          `}
      ${iconCSS}
  `;

  return (
    <button
      {...props}
      $buttonStyle={buttonStyle}
      css={buttonStyles}
      className={className}
      disabled={disabled}
      large={large}
      onClick={onClick}
      $iconPosition={icon ? iconPosition : undefined}
    >
      {icon && iconPosition === "left" && (
        <FontAwesomeIcon
          icon={icon}
          css={iconStyle}
          $buttonStyle={buttonStyle}
        />
      )}
      <span css="flex-grow: 1;">{children}</span>
      {icon && iconPosition === "right" && (
        <FontAwesomeIcon
          icon={icon}
          css={iconStyle}
          $buttonStyle={buttonStyle}
        />
      )}
    </button>
  );
};
