import { withHandlers } from "recompose";

const priceFormat = new Intl.NumberFormat("en-GB", {
  style: "currency",
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  currency: "GBP",
});

const pound = String.fromCharCode("0x00A3");

export function formatNumberAsCurrency(number) {
  // always keep the pound sign
  if (number === null) return pound;
  if (number === undefined) return pound;
  if (number === "-") return `-${pound}`;
  if (typeof number === "number") return priceFormat.format(number);
  // match if the number has a decimal point
  if (number.match(/\./)) {
    const moneyAmount = /^(-{0,1})([0-9]+)\.{1}([0-9]{0,2}).*$/;
    // eslint-disable-next-line
    const [_, minus, pounds, pence] = number.match(moneyAmount);
    // if ends in .00, return the formatted integer pounds
    if (pence === "00") return `${minus}${priceFormat.format(pounds)}`;
    // otherwise, format the xx in xx.yy as currency
    return `${minus}${priceFormat.format(pounds)}.${pence}`;
  }
  // if nothing matches, must be an integer
  return priceFormat.format(number);
}

function castCurrencyToNumber(allowNegative) {
  return (x) => {
    // When user presses backspace on the input "-£" and deletes
    // £ sign they actually want to delete the minus sign at the start
    // thereby clearing the field
    if (x === "-") return null;
    // if the field allows negatives then user can type a minus,
    // otherwise the field will be empty when they type a minus.
    if (x === "-£" || x === "£-") return allowNegative ? "-" : null;

    const firstMinus = /^([-]{0,1})(.*)/;
    // eslint-disable-next-line
    const [_, minus, text] = x.replace(/£/g, "").match(firstMinus);

    const disallowedCharacters = /[^.0-9]+/g;
    const periodsAtStart = /^\.*/;
    const number = text
      .replace(disallowedCharacters, "")
      .replace(periodsAtStart, "");

    // user has deleted £ sign and therefore cleared field
    // or replacing above has resulted in empty string so
    // clear field
    if (number === "") return null;

    const numberToTwoDecimalPlaces = /([0-9]+\.{0,1}[0-9]{0,2}).*$/;
    // eslint-disable-next-line
    const [__, truncated] = number.match(numberToTwoDecimalPlaces);
    return allowNegative ? minus + truncated : truncated;
  };
}

export const withFormattedCurrency = withHandlers({
  format:
    ({ property, label }) =>
    (number) => {
      if (typeof number === "number") {
        throw new Error(
          `Please use the :money scalar for currency field "${label}" (EGGL field: "${property}").`,
        );
      }
      return formatNumberAsCurrency(number);
    },
  cast: ({ allowNegative }) => castCurrencyToNumber(allowNegative),
});
