// @flow

import React from "react";
import { gql } from "@apollo/client";
import styled from "styled-components";
import { Grid, Cell } from "components/Grid";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { Button } from "components/Buttons";
import { NoSubmitTextField } from "components/TextField";
import { NoSubmitNumberField } from "components/NumberField";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitCurrencyField } from "components/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";

import { LIST_COMPARABLES_QUERY } from "./ComparableList";

export const ADD_MANUAL_COMPARABLE = gql`
  mutation AddManualComparable($input: AddManualComparableInput!) {
    addManualComparable(input: $input) {
      id
    }
  }
`;

const StyledGrid = styled(Grid)`
  background-color: ${({ theme }) => theme.background.card};
  border-radius: 4px;
  border: ${({ theme }) => theme.border.cardUnselected};
  color: ${({ theme }) => theme.text.white};
  padding: 16px;
  width: 100%;

  &.show {
    min-height: 132px;
  }

  &.hide {
    height: 0px;
    padding: 0;
    margin: 0;
  }
`;

const EditableField = styled(NoSubmitTextField)`
  align-self: stretch;
  display: inline-grid;

  label {
    font-size: 12px;
  }

  input {
    background-color: ${({ theme }) => theme.textField.background};
    border: 2px solid ${({ theme }) => theme.textField.border};
    font-size: 12px;
    width: ${({ narrow }) => {
      if (narrow) return "40px";
      return "100%";
    }};

    &:focus {
      border: 2px;
    }
  }
`;

const EditableCurrency = styled(EditableField)`
  input {
    width: 100px;
  }
`;

const SoldDate = styled(EditableField)`
  input {
    width: 140px;
  }
`;

const PropertyPhotoContainer = styled(Cell)`
  background: url(https://nested.imgix.net/missingProperty.svg) center center
      no-repeat,
    #e1f4ef;
  border-radius: 4px;
`;

const FullWidthCell = styled(Cell)`
  font-size: 16px;
  font-weight: 500;
  display: grid;
  align-items: center;
`;

const AttributesCell = styled(Cell)`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const SalesInfoCell = styled(Cell)`
  align-items: center;
  display: flex;
  font-size: 12px;

  button {
    min-width: 120px;
  }
`;

const DottedList = styled.ul`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

const DottedListItem = styled.li`
  display: inline-flex;
  margin: 0;

  &::after {
    align-self: flex-end;
    background-color: ${({ theme }) => theme.background.listItem};
    border-radius: 100%;
    content: "";
    display: inline-flex;
    margin-bottom: 14px;
    margin-left: 10px;
    margin-right: 10px;
    width: 5px;
    height: 5px;
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.grey};
  margin: 0 0 0 16px;

  &:hover {
    background-color: ${({ theme }) => theme.button.greyHover};
  }
`;

const AddButton = styled(Button)`
  background-color: ${({ theme }) => theme.button.green};
  margin: 0 0 0 16px;
`;

const areas = [
  "photo address",
  "photo link",
  "..... salesInfo",
  "..... propertyInfo",
];

const SalesInformation = ({ area, comparable, update }) => (
  <SalesInfoCell area={area}>
    <DottedList>
      <DottedListItem>
        <NoSubmitSelectField
          dark
          data-test="comparables/manual-input:status"
          label="Status"
          onSubmit={(value) => update({ status: value })}
          options={["OTM", "UO", "Sold", "Withdrawn"]}
          value={comparable.status}
        />
      </DottedListItem>

      <DottedListItem>
        <EditableCurrency
          as={NoSubmitCurrencyField}
          dark
          data-test="comparables/manual-input:listing-price"
          highlight
          isSecondary
          label="Listing price"
          onSubmit={(value) => update({ listingPrice: value })}
          value={comparable.listingPrice}
        />
      </DottedListItem>

      {comparable.status && comparable.status === "UO" && (
        <>
          <DottedListItem>
            <EditableCurrency
              as={NoSubmitCurrencyField}
              dark
              data-test="comparables/manual-input:under-offer-price"
              highlight
              isSecondary
              label="Under offer price"
              onSubmit={(value) => update({ underOfferPrice: value })}
              value={comparable.underOfferPrice}
            />
          </DottedListItem>

          <DottedListItem>
            <EditableField
              dark
              data-test="comparables/manual-input:under-offer-date"
              highlight
              isSecondary
              label="Under offer date"
              onSubmit={(value) => update({ underOfferDate: value })}
              value={comparable.underOfferDate}
              type="date"
            />
          </DottedListItem>
        </>
      )}

      {comparable.status && comparable.status.toLowerCase() === "sold" && (
        <>
          <DottedListItem>
            <EditableCurrency
              as={NoSubmitCurrencyField}
              dark
              highlight
              isSecondary
              label="Sold price"
              onSubmit={(value) => update({ soldPrice: value })}
              value={comparable.soldPrice}
            />
          </DottedListItem>

          <DottedListItem>
            <SoldDate
              as={NoSubmitDatePicker}
              dark
              highlight
              isSecondary
              label="Sold date"
              onSubmit={(value) => update({ soldDate: value })}
              value={comparable.soldDate}
            />
          </DottedListItem>
        </>
      )}
    </DottedList>
  </SalesInfoCell>
);

type Props = {
  className: string,
  reference: ComparableReferenceInput,
  switchToCSV: () => void,
  sortBy: SortBy,
  sortOrder: SortOrder,
};

type State = {|
  address?: ?string,
  link?: ?string,
  listingPrice?: ?Decimal,
  numberOfBeds?: ?number,
  propertyType?: ?string,
  reference: ComparableReferenceInput,
  soldDate?: ?Date,
  soldPrice?: ?Decimal,
  status?: ?string,
  underOfferDate?: ?Date,
  underOfferPrice?: ?Decimal,
|};

export class ManualInput extends React.Component<Props, State> {
  update = (change: { [string]: any }) => {
    this.setState(change);
  };

  emptyState = {
    address: null,
    link: null,
    listingPrice: null,
    numberOfBeds: null,
    soldDate: null,
    soldPrice: null,
    status: null,
    underOfferDate: null,
    underOfferPrice: null,
  };

  constructor(props: Props) {
    super(props);

    const { reference } = this.props;

    this.state = { reference, ...this.emptyState };
  }

  clearFields() {
    const { reference } = this.props;

    this.setState({ reference, ...this.emptyState });
  }

  render() {
    const comparable = this.state;
    const { className, reference, switchToCSV, sortBy, sortOrder } = this.props;

    return (
      <StyledGrid
        areas={areas}
        className={className}
        columnGap="16px"
        columns="121px 1fr"
        data-test="comparable-wrapper"
      >
        <PropertyPhotoContainer area="photo" />

        <FullWidthCell area="address">
          <EditableField
            data-test="comparables/manual-input:address"
            highlight
            isSecondary
            label="Address"
            onSubmit={(value) => this.update({ address: value })}
            value={comparable.address}
          />
        </FullWidthCell>

        <FullWidthCell area="link">
          <EditableField
            data-test="comparables/manual-input:link"
            highlight
            isSecondary
            label="Link"
            onSubmit={(value) => this.update({ link: value })}
            value={comparable.link}
          />
        </FullWidthCell>

        <SalesInformation
          area="salesInfo"
          comparable={comparable}
          update={this.update}
        />

        <AttributesCell area="propertyInfo">
          <DottedList>
            <DottedListItem>
              <EditableField
                as={NoSubmitNumberField}
                data-test="comparables/manual-input:number-of-beds"
                isSecondary
                label="Beds"
                narrow
                onSubmit={(value) => this.update({ numberOfBeds: value })}
                value={comparable.numberOfBeds}
              />
            </DottedListItem>
            <DottedListItem>
              <EditableField
                data-test="comparables/manual-input:property-type"
                isSecondary
                label="Property Type (e.g. House/Flat)"
                onSubmit={(value) => this.update({ propertyType: value })}
                value={comparable.propertyType}
              />
            </DottedListItem>
          </DottedList>
        </AttributesCell>

        <Cell width={2}>
          <ButtonWrapper>
            <CancelButton onClick={switchToCSV} data-test="cancel-button">
              Cancel
            </CancelButton>
            <ExtendedMutation
              mutation={ADD_MANUAL_COMPARABLE}
              onCompleted={() => {
                this.clearFields();
                switchToCSV();
              }}
              refetchQueries={[
                {
                  query: LIST_COMPARABLES_QUERY,
                  variables: {
                    reference,
                    sortBy,
                    sortOrder,
                  },
                },
              ]}
            >
              {(addManualComparable) => (
                <AddButton
                  onClick={() => {
                    addManualComparable({
                      variables: {
                        input: { ...comparable, reference },
                      },
                    });
                  }}
                  data-test="add-button"
                >
                  Add
                </AddButton>
              )}
            </ExtendedMutation>
          </ButtonWrapper>
        </Cell>
      </StyledGrid>
    );
  }
}
