// @flow
import React from "react";
import { type MutationFunction } from "@apollo/client/react/components";

import { CreateButton } from "components/CreateButton";
import { EditButton } from "components/DealDetailsBar/EditButton";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { SubAgentCard } from "./SubAgentCard";
import {
  SA_SUB_AGENTS_QUERY,
  SA_SUB_AGENT_CREATE_MUTATION,
} from "./subAgentsQueries";

type Props = { parentId: string };
type State = { editing: boolean };

export class SubAgentsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  toggleEditing = () => {
    this.setEditing(!this.state.editing);
  };

  setEditing = (editing: boolean) => {
    this.setState({ editing });
  };

  isCreatButtonVisible = (subAgentCount: number) => {
    const { editing } = this.state;
    return editing || subAgentCount === 0;
  };

  render() {
    const { editing } = this.state;
    const { parentId: dealId } = this.props;

    return (
      <ExtendedQuery query={SA_SUB_AGENTS_QUERY} variables={{ dealId }}>
        {({ subAgents }: ExtendedQueryRenderProps<SASubAgents>) => (
          <div data-test="sub-agents">
            {subAgents.length > 0 && (
              <EditButton
                data-test="sub-agents:edit-button"
                onClick={this.toggleEditing}
              >
                {editing ? "Finish" : "Edit"}
              </EditButton>
            )}

            <div data-test="sub-agents:list">
              {subAgents.map((subAgent) => (
                <SubAgentCard
                  key={subAgent.id}
                  subAgent={subAgent}
                  editing={editing}
                />
              ))}
            </div>

            <ExtendedMutation mutation={SA_SUB_AGENT_CREATE_MUTATION}>
              {(
                createSASubAgent: MutationFunction<
                  CreateSASubAgent,
                  CreateSASubAgentVariables,
                >,
              ) =>
                this.isCreatButtonVisible(subAgents.length) && (
                  <CreateButton
                    data-test="sub-agents:create-button"
                    className="create-button"
                    label="Add sub agent"
                    onSubmit={() => {
                      this.setEditing(true);
                      return createSASubAgent({
                        variables: {
                          input: { dealId },
                        },
                        refetchQueries: [
                          {
                            query: SA_SUB_AGENTS_QUERY,
                            variables: {
                              dealId,
                            },
                          },
                        ],
                      });
                    }}
                  />
                )
              }
            </ExtendedMutation>
          </div>
        )}
      </ExtendedQuery>
    );
  }
}
