// @flow
import { useParams, useLocation, Redirect } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client/react/hooks";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { errorHandler } from "@nested/utils/graphql/errorHandler";

export const OPPORTUNITY_STATUS_QUERY = gql`
  query GetDealOpportunityStatusForRedirect($id: ID!) {
    nestDeal(id: $id) {
      id
      opportunityStatus {
        valueText
      }
    }
  }
`;

const getTabName = (opportunityStatus) => {
  switch (opportunityStatus) {
    case "s01_received_offer_request":
      return "valuation";
    case "s06_provided_final_offer":
      return "close";
    case "s07_closed":
      return "listing";
    case "s08_listed":
      return "interest";
    case "s09_under_offer":
    case "s10_exchanged":
    case "s11_completed":
      return "progression-info";
    default:
      return "valuation";
  }
};

export const RedirectToAppropriateTab = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, loading, error } = useQuery(OPPORTUNITY_STATUS_QUERY, {
    variables: { id },
  });

  if (!id) {
    // This can't happen, but flow doesn't know that
    return null;
  }

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  const opportunityStatus = data.nestDeal.opportunityStatus.valueText;
  const tabName = getTabName(opportunityStatus);

  return <Redirect to={`/deals/${id}/${tabName}${search}`} />;
};
