// @flow
import { useRef, useState } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ChangeOnBlurNestTextInput } from "./ChangeOnBlurNestTextInput";
import { type Props } from "./NestTextInput";

const editButtonStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  margin: 0px;
  background-color: transparent;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.blue150};
`;

const inputStyles = css`
  input:disabled {
    background-color: ${({ dark, theme }) =>
      dark ? theme.palette.hague150 : "white"};
    opacity: 1;
    color: ${({ theme }) => theme.palette.hague70};
  }
`;

export const ClickToEditNestTextInput = ({
  forwardedRef,
  onBlur,
  "data-test": dataTest = "click-to-edit-input",
  ...props
}: Props) => {
  const localRef = useRef(null);
  const inputRef = forwardedRef || localRef;

  const [editable, setEditable] = useState(false);

  return (
    <div css="position: relative;">
      <ChangeOnBlurNestTextInput
        {...props}
        css={inputStyles}
        data-test={dataTest}
        forwardedRef={inputRef}
        disabled={!editable}
        onBlur={(e) => {
          setEditable(false);

          onBlur && onBlur(e);
        }}
      />
      {!editable && (
        <button
          css={editButtonStyle}
          data-test={`${dataTest}:click-to-edit-button`}
          onClick={() => {
            setEditable(true);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 0);
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </button>
      )}
    </div>
  );
};
