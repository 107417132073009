import { withProps, compose } from "recompose";

import { ImportIcon } from "@nest-ui/icons";
import { withOnSubmit } from "@nest-ui/hocs";
import { SecondaryButton } from "components/SecondaryButton";

export const ImportButton = compose(
  withProps({
    icon: ImportIcon,
  }),
  withOnSubmit,
)(SecondaryButton);
