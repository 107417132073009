// @flow
import styled from "styled-components";
import { kebabCase, startCase } from "lodash";

import { Info } from "@nest-ui/icons";
import { NoSubmitCreateButton } from "components/CreateButton";

const Div = styled.div`
  width: 261px;
  height: 345px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  color: #242b38;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  font-family: Euclid;

  .info-icon svg {
    display: block;
    width: 44px;
    height: 44px;
    margin: 0 auto;
    padding-bottom: 12px;
  }
`;

export const NoValuations = ({ enableCreating, valuationType }: *) => (
  <Div data-test={`${kebabCase(valuationType)}-valuation-empty-list`}>
    <div>
      <div className="info-icon">
        <Info fill="#B5B5B5" />
      </div>
      <p>We don&apos;t have any {startCase(valuationType)} Valuations yet.</p>
      <NoSubmitCreateButton
        data-test={`${kebabCase(valuationType)}-valuation-create-valuation`}
        onSubmit={enableCreating}
        label={`Add New ${startCase(valuationType)} Valuation`}
      />
    </div>
  </Div>
);
