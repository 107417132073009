// @flow
type Props = {
  fill: string,
};

export const CardMenu = ({ fill }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill={fill}>
      <path d="M7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 C10.8659932,0 14,3.13400675 14,7 C14,10.8659932 10.8659932,14 7,14 Z M7,12.9230769 C10.2712251,12.9230769 12.9230769,10.2712251 12.9230769,7 C12.9230769,3.72877494 10.2712251,1.07692308 7,1.07692308 C3.72877494,1.07692308 1.07692308,3.72877494 1.07692308,7 C1.07692308,10.2712251 3.72877494,12.9230769 7,12.9230769 Z" />
      <circle cx="7" cy="7" r="1" />
      <circle cx="4" cy="7" r="1" />
      <circle cx="10" cy="7" r="1" />
    </g>
  </svg>
);

CardMenu.defaultProps = {
  fill: "#B6BFCC",
};
