// @flow

/* This is a temporary component which represents the old way the Property Sale
 * Redemption section was laid out and what fields it used before we started
 * differentiating payments based on deal types. We are migrating away from
 * this to having specific fields for different deal types, but in order to
 * avoid a big fuck off big bang fuckopalypse, we're using this.
 *
 * It is called "DeleteMe" instead of "Legacy" because there are deal types
 * which include the word "Legacy". Naming things can fuck itself.
 */

import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";
import { gql } from "@apollo/client";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import { Grid, LeafCell } from "components/Grid";
import { AllowNegativeCurrencyField } from "components/AllowNegativeCurrencyField/AllowNegativeCurrencyField";
import { Checkbox } from "components/Checkbox";
import { CurrencyField } from "components/CurrencyField";
import { DatePicker } from "components/DatePicker";
import { Loader } from "components/Loader";
import { TextField } from "components/TextField";

import { reconciledMutation } from "./reconciledMutation";

const PROPERTY_SALE_REDEMPTION_FRAGMENT = gql`
  fragment propertySaleRedemptionFields on NestDeal {
    id
    propertySalePaymentDateReceived
    propertySaleActualLossRealised
    propertySaleAdjustmentNotes
    propertySaleBaseFeeOnCompletion
    propertySaleGuaranteePaymentReceived
    propertySaleIncentiveFeeReceived
    propertySalePaymentAdjustment
    propertySalePaymentDateReceived
    propertySaleReconciledAndSignedOff
    propertySaleRedemption {
      amountOutstandingOwedToNested
      guaranteeAmountReconciliation
      projectedGuaranteeRedemptionAmount
      projectedLoss
      projectedSalePrice
    }
    propertySaleTotalAmountReceived
  }
`;

const PROPERTY_SALE_REDEMPTION_QUERY = gql`
  query PropertySaleRedemptionQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      ...propertySaleRedemptionFields
    }
  }
  ${PROPERTY_SALE_REDEMPTION_FRAGMENT}
`;

const UPDATE_PROPERTY_SALE_REDEMPTION_MUTATION = gql`
  mutation UpdateNestDealPropertySaleRedemption(
    $input: NestDealInput!
    $id: ID!
  ) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      ...propertySaleRedemptionFields
    }
  }
  ${PROPERTY_SALE_REDEMPTION_FRAGMENT}
`;

const ReadOnlyFields = ({ nestDeal }) => (
  <>
    <LeafCell area="projectedSalePrice">
      <CurrencyField
        label="Projected sale price"
        readOnly
        value={nestDeal?.propertySaleRedemption?.projectedSalePrice}
      />
    </LeafCell>
    <LeafCell area="projectedGuaranteeRedemptionAmount">
      <CurrencyField
        label="Projected guarantee repayment amount"
        readOnly
        value={
          nestDeal?.propertySaleRedemption?.projectedGuaranteeRedemptionAmount
        }
      />
    </LeafCell>
    <LeafCell area="projectedLoss">
      <CurrencyField
        label="Projected loss"
        readOnly
        value={nestDeal?.propertySaleRedemption?.projectedLoss}
      />
    </LeafCell>
    <LeafCell area="amountOutstandingOwedToNested">
      <CurrencyField
        label="Amount outstanding owed to Nested"
        readOnly
        value={nestDeal?.propertySaleRedemption?.amountOutstandingOwedToNested}
      />
    </LeafCell>
    <LeafCell area="guaranteeAmountReconciliation">
      <CurrencyField
        label="Guarantee amount reconciliation"
        readOnly
        value={nestDeal?.propertySaleRedemption?.guaranteeAmountReconciliation}
      />
    </LeafCell>
  </>
);

const EditableFields = ({ nestDeal }) => (
  <Mutation mutation={UPDATE_PROPERTY_SALE_REDEMPTION_MUTATION}>
    {(mutate: MutationFunction<*, *>) => {
      const updateNestDeal = async (input) => {
        await mutate({
          variables: {
            id: nestDeal.id,
            input,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateNestDeal: {
              __typename: "NestDeal",
              ...nestDeal,
              ...input,
            },
          },
        });
      };

      return (
        <>
          <LeafCell area="propertySalePaymentDateReceived">
            <DatePicker
              label="Date received"
              property="propertySalePaymentDateReceived"
              value={nestDeal.propertySalePaymentDateReceived}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleTotalAmountReceived">
            <AllowNegativeCurrencyField
              label="Total amount received (+ / -)"
              property="propertySaleTotalAmountReceived"
              value={nestDeal.propertySaleTotalAmountReceived}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleGuaranteePaymentReceived">
            <CurrencyField
              label="Guarantee payment received"
              property="propertySaleGuaranteePaymentReceived"
              value={nestDeal.propertySaleGuaranteePaymentReceived}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleBaseFeeOnCompletion">
            <CurrencyField
              label="Base fee on completion"
              property="propertySaleBaseFeeOnCompletion"
              value={nestDeal.propertySaleBaseFeeOnCompletion}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleIncentiveFeeReceived">
            <CurrencyField
              label="Incentive fee received"
              property="propertySaleIncentiveFeeReceived"
              value={nestDeal.propertySaleIncentiveFeeReceived}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySalePaymentAdjustment">
            <AllowNegativeCurrencyField
              label="Payment adjustment (-/+ £)"
              property="propertySalePaymentAdjustment"
              value={nestDeal.propertySalePaymentAdjustment}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleAdjustmentNotes">
            <TextField
              label="Payment adjustment notes"
              property="propertySaleAdjustmentNotes"
              value={nestDeal.propertySaleAdjustmentNotes}
              mutation={updateNestDeal}
            />
          </LeafCell>
          <LeafCell area="propertySaleActualLossRealised">
            <CurrencyField
              label="Actual loss realised"
              property="propertySaleActualLossRealised"
              value={nestDeal.propertySaleActualLossRealised}
              mutation={updateNestDeal}
            />
          </LeafCell>
        </>
      );
    }}
  </Mutation>
);

const ReconciledAndSignedOff = ({ nestDeal }) => (
  <Mutation mutation={reconciledMutation}>
    {(mutate: MutationFunction<*, *>) => {
      const reconcile = async () => {
        await mutate({
          variables: {
            input: {
              propertySaleReconciledAndSignedOff:
                !nestDeal.propertySaleReconciledAndSignedOff,
            },
            id: nestDeal.id,
          },
        });
      };

      return (
        <LeafCell area="propertySaleReconciledAndSignedOff">
          <Checkbox
            label="Reconciled and signed off?"
            property="propertySaleReconciledAndSignedOff"
            value={nestDeal.propertySaleReconciledAndSignedOff}
            mutation={reconcile}
          />
        </LeafCell>
      );
    }}
  </Mutation>
);

const layout = [
  "projectedSalePrice                 projectedGuaranteeRedemptionAmount  projectedLoss                        ..............................",
  "propertySalePaymentDateReceived    propertySaleTotalAmountReceived     propertySaleGuaranteePaymentReceived ..............................",
  "propertySaleBaseFeeOnCompletion    propertySaleIncentiveFeeReceived    .................................... ..............................",
  "propertySalePaymentAdjustment      propertySaleAdjustmentNotes         propertySaleAdjustmentNotes          propertySaleActualLossRealised",
  "amountOutstandingOwedToNested      guaranteeAmountReconciliation       .................................... ..............................",
  "propertySaleReconciledAndSignedOff ................................... .................................... ..............................",
];

export const DeleteMe = ({ dealId }: { dealId: string }) => (
  <Grid columns={4} rows="auto auto" areas={layout}>
    <ExtendedQuery
      query={PROPERTY_SALE_REDEMPTION_QUERY}
      variables={{ id: dealId }}
    >
      {({
        nestDeal,
      }: ExtendedQueryRenderProps<PropertySaleRedemptionQuery>) => {
        if (!nestDeal) return <Loader />;

        return (
          <>
            <ReadOnlyFields nestDeal={nestDeal} />
            <EditableFields nestDeal={nestDeal} />
            <ReconciledAndSignedOff nestDeal={nestDeal} />
          </>
        );
      }}
    </ExtendedQuery>
  </Grid>
);
