// @flow
import { gql } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { type ContextRouter, Route } from "react-router";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { useUser } from "@nest-ui/sellers-nest/hooks/useUser";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { Card } from "components/Card";
import { H2 } from "components/Heading";

import { Comparables } from "components/Comparables/Comparables";
import { ComparablePropertiesSection } from "components/Comparables/ComparablePropertiesSection";

import { PostCallChecklist } from "tabs/Valuation/PostCallChecklist";
import { ProposedAgencyFeeSection } from "tabs/Valuation/PropertyAnalysis/ProposedAgencyFee/ProposedAgencyFeeSection";
import { RecentMarketing } from "./RecentMarketing/RecentMarketing";
import { PropertyInformationSectionFields } from "./PropertyInformationSection/PropertyInformationSection";
import { LeaseholdProperties } from "./LeaseholdProperties/LeaseholdProperties";
import { ValuationCompleteChecklist } from "./ValuationCompleteChecklist/ValuationCompleteChecklist";
import { ListingPriceRecommendationSection } from "./PropertyAnalysis/ListingPriceSuggestion/ListingPriceRecommendationSection";
import { PropertyDetailsSection } from "./PropertyAnalysis/PropertyDetailsSection/PropertyDetailsSection";
import { ShowPropertyAnalysisToCustomerToggle } from "./PropertyAnalysis/ShowPropertyAnalysisToCustomerToggle/ShowPropertyAnalysisToCustomerToggle";
import { LinkToPropertyAnalysisAdmin } from "./PropertyAnalysis/LinkToPropertyAnalysisAdmin/LinkToPropertyAnalysisAdmin";
import { EditSources } from "./EditSources/EditSources";

const VALUATION_TAB_QUERY = gql`
  query ValuationTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
      externalId
    }
  }
`;

export const Valuation = ({
  match: {
    params: { id: idOrExternalDealId },
  },
}: ContextRouter<{ id: string }>) => {
  const comparablesMatch = useRouteMatch("/deals/:id/valuation/comparables");
  const { currentUser } = useUser();

  return (
    <ExtendedQuery
      query={VALUATION_TAB_QUERY}
      variables={{ idOrExternalDealId }}
    >
      {({
        nestDeal: { id: dealId, externalId },
      }: ExtendedQueryRenderProps<ValuationTabQuery>) => {
        if (comparablesMatch) {
          return (
            <Route path={comparablesMatch.url}>
              <Comparables
                sourceType="DEAL"
                sourceId={externalId}
                closeUrl={`/deals/${idOrExternalDealId}/valuation`}
              />
            </Route>
          );
        }
        return (
          <TabWrapper>
            <TabContainer id="Valuation" data-test="valuation-tab" fullWidth>
              <Card>
                <H2>Book a call/valuation</H2>
                <ErrorBoundary>
                  <PostCallChecklist dealId={dealId} />
                </ErrorBoundary>
              </Card>

              <Card>
                <H2>Property information</H2>
                <RecentMarketing parentId={dealId} />
                <PropertyInformationSectionFields parentId={dealId} />
              </Card>

              <Card>
                <H2>Leasehold properties (if applicable)</H2>
                <LeaseholdProperties parentId={dealId} />
              </Card>

              <Card>
                <ComparablePropertiesSection
                  comparablesUrl={`/deals/${externalId}/valuation/comparables`}
                  showAccountLink
                  sourceId={externalId}
                  sourceType="DEAL"
                />
                <ListingPriceRecommendationSection parentId={dealId} />
                <ProposedAgencyFeeSection dealId={dealId} />
                <PropertyDetailsSection dealId={dealId} />
                <ShowPropertyAnalysisToCustomerToggle
                  parentId={dealId}
                  externalId={externalId}
                />
                <LinkToPropertyAnalysisAdmin externalId={externalId} />
              </Card>

              <Card>
                <ValuationCompleteChecklist dealId={dealId} />
              </Card>
              {currentUser.isNestAdmin && (
                <Card>
                  <EditSources dealId={dealId} />
                </Card>
              )}
            </TabContainer>
          </TabWrapper>
        );
      }}
    </ExtendedQuery>
  );
};
