export const updateNestDealSDLTSurchargeSectionConfig = {
  props({ mutate, ownProps: { parentId: dealId, data } }) {
    const nestDeal = data?.nestDeal ?? {};
    return {
      updateNestDeal: (input) =>
        mutate({
          variables: {
            input,
            id: dealId,
          },
          optimisticResponse: {
            __typename: "Mutation",
            updateNestDeal: {
              __typename: "NestDeal",
              id: dealId,
              ...nestDeal,
              ...input,
            },
          },
        }),
    };
  },
};
