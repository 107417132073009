const Exit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
  >
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
      <path strokeLinejoin="round" d="M3.26 10.64L.5 8l2.76-2.64" />
      <path d="M1.194 8h10.96" />
      <path
        strokeLinejoin="round"
        d="M7.93 2.673c0-1.177.9-2.13 2.008-2.13h5.163c1.109 0 2.008.953 2.008 2.13v10.654c0 1.177-.899 2.13-2.008 2.13H9.938c-1.109 0-2.008-.953-2.008-2.13"
      />
    </g>
  </svg>
);

export { Exit };
