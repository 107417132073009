// @flow
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useMutation } from "@apollo/client";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { detailStyle as details } from "@nested/brand/src/components/Timeline/TimelineItem";
import { TextArea } from "../../../../TextArea/TextArea";
import {
  BinIconButton,
  newNoteStyle as noteStyle,
  NoteEditBar,
  ShareOrSaveButton,
} from "./NewNoteInput";
import { Checkbox } from "../../../../Checkbox/Checkbox";

export const SharedWithSellerTag = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.hague70};
  padding: 10px 0 0 0;
`;

const newNoteStyle = css`
  ${noteStyle}
  textarea {
    padding-right: 30px;
  }
`;

const detailStyle = css`
  ${details}
  position: relative;
`;

const pencilStyle = css`
  color: ${({ theme, $editing }) =>
    $editing ? theme.palette.blue40 : theme.palette.blue150};
`;

const buttonStyle = css`
  position: absolute;
  top: 11px;
  right: 11px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const deleteConfirmationLabelStyle = css`
  text-align: center;
  margin-top: 0px;
  margin-bottom: 5px;
`;

const deleteConfirmationButtonStyle = css`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.palette.hague20};
  color: ${({ theme }) => theme.palette.hague100};
  padding: 5px 10px;
  background-color: white;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    border: 2px solid ${({ theme }) => theme.palette.hague40};
  }

  :not(:last-child) {
    margin-right: 5px;
  }
`;

export const EDIT_NOTE = gql`
  mutation editNote($input: EditNoteInput!) {
    editNote(input: $input) {
      id
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
    }
  }
`;

type Props = {
  event: Timeline_timeline,
  allowEditing: boolean,
  isShareable: boolean,
};

export const SavedNote = ({ event, allowEditing, isShareable }: Props) => {
  if (event.__typename !== "TimelineEvent") {
    return null;
  }

  const { content, id, noteId, isNoteSharedWithCustomer } = event;
  const [disableTextArea, setDisableTextArea] = useState(true);
  const [noteContent, setNoteContent] = useState(content || "");

  useEffect(() => {
    if (disableTextArea && content) {
      // If the content changes remotely and we aren't actively editing the note (eg by editing a pinned note),
      // we can safely replace the content
      setNoteContent(content);
    }
  }, [content]);

  const noteInput = useRef(null);
  const [updateNote, { loading: updateLoading }] = useMutation(EDIT_NOTE);
  const [deleteNote, { loading: deletionLoading }] = useMutation(DELETE_NOTE);

  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [shareChecked, setShareChecked] = useState(
    Boolean(isNoteSharedWithCustomer),
  );

  const fsExclude = content?.startsWith("**VALUATION QUESTIONNAIRE RESPONSE**")
    ? "fs-exclude"
    : undefined;

  const executeDeleteNote = async () => {
    try {
      await deleteNote({
        variables: {
          input: { id: noteId },
        },
      });
      setConfirmingDelete(false);
      setDisableTextArea(true);
    } catch (e) {
      errorHandler(e);
    }
  };

  const executeUpdateNote = async (text: string) => {
    try {
      await updateNote({
        variables: {
          input: { id: noteId, text, isSharedWithCustomer: shareChecked },
        },
      });
      setDisableTextArea(true);
    } catch (e) {
      errorHandler(e);
    }
  };

  const contentHasChanged = content !== noteContent;

  const disableSaveButton =
    (!contentHasChanged && isNoteSharedWithCustomer === shareChecked) ||
    noteContent === "" ||
    updateLoading;

  const noteWillBeSharedOrUnshared = isNoteSharedWithCustomer !== shareChecked;

  let shareUnshareOrSaveButtonLabel;

  if (!isNoteSharedWithCustomer && shareChecked) {
    shareUnshareOrSaveButtonLabel = "Share";
  } else if (isNoteSharedWithCustomer && !shareChecked) {
    shareUnshareOrSaveButtonLabel = "Unshare";
  } else {
    shareUnshareOrSaveButtonLabel = "Save";
  }

  if (confirmingDelete) {
    return (
      <div css={detailStyle}>
        <p css={deleteConfirmationLabelStyle}>
          Are you sure you want to delete this note?
        </p>
        <div css="text-align: center;">
          <button
            data-test="confirm-delete"
            css={deleteConfirmationButtonStyle}
            onClick={executeDeleteNote}
          >
            Yes
          </button>
          <button
            data-test="cancel-delete"
            css={deleteConfirmationButtonStyle}
            onClick={() => setConfirmingDelete(false)}
          >
            No
          </button>
        </div>
      </div>
    );
  }

  return (
    <div css={detailStyle}>
      <TextArea
        className={fsExclude}
        css={newNoteStyle}
        data-test={`textarea-${id}`}
        disabled={deletionLoading || updateLoading}
        forwardedRef={noteInput}
        lineLimit={6}
        onChange={(e) => {
          setNoteContent(e.target.value);
        }}
        readOnly={disableTextArea}
        value={noteContent}
      />

      {allowEditing && (
        <button
          data-test={`edit-${id}`}
          onClick={() => {
            if (
              !disableTextArea &&
              !contentHasChanged &&
              !noteWillBeSharedOrUnshared
            ) {
              setDisableTextArea(true);
            } else {
              setDisableTextArea(false);
              const end = noteInput.current?.value.length || 0;
              // set cursor at the end of the text when note is focused
              noteInput.current?.setSelectionRange(end, end);
              noteInput.current?.focus();
            }
          }}
          css={buttonStyle}
        >
          <FontAwesomeIcon
            css={pencilStyle}
            icon={faPencilAlt}
            $editing={
              !disableTextArea &&
              (contentHasChanged || noteWillBeSharedOrUnshared)
            }
          />
        </button>
      )}

      {!disableTextArea && (
        <NoteEditBar>
          <BinIconButton
            data-test={`delete-button-${id}`}
            faIcon={faTrashAlt}
            onClick={() => setConfirmingDelete(true)}
          />
          {isShareable ? (
            <Checkbox
              checked={shareChecked}
              disabled={noteContent === ""}
              labelText={"Share with seller?"}
              onChange={() => setShareChecked(!shareChecked)}
              id={"share-note-checkbox"}
              labelTextPosition={"left"}
            />
          ) : null}
          <ShareOrSaveButton
            buttonStyle={disableSaveButton ? "grey" : "pink"}
            onClick={() => {
              executeUpdateNote(noteContent);
            }}
            disabled={disableSaveButton}
          >
            {shareUnshareOrSaveButtonLabel}
          </ShareOrSaveButton>
        </NoteEditBar>
      )}
      {isNoteSharedWithCustomer && (
        <SharedWithSellerTag>Shared with seller</SharedWithSellerTag>
      )}
    </div>
  );
};
