// @flow
import { NavBar, Owner, SummaryDiv } from "components/NavBar/NavBar";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { gql } from "@apollo/client";

const CUSTOMER_ACCOUNT_DETAILS = gql`
  query getAccount($id: ID!) {
    customerAccount(id: $id) {
      id
      firstName
      lastName
    }
  }
`;

type Props = {
  customerAccountId: string,
};

export const BuyersAgentNav = ({ customerAccountId }: Props) => (
  <NavBar>
    <SummaryDiv>
      <Owner>Customer</Owner>
      <ExtendedQuery
        query={CUSTOMER_ACCOUNT_DETAILS}
        variables={{ id: customerAccountId }}
      >
        {({ customerAccount }) => {
          if (!customerAccount) {
            return <div>No customer found</div>;
          }
          return (
            <div
              data-test="sidebar-customer-name"
              className="fs-exclude"
            >{`${customerAccount.firstName} ${customerAccount.lastName}`}</div>
          );
        }}
      </ExtendedQuery>
    </SummaryDiv>
  </NavBar>
);
