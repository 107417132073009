export const findQueryConfig = {
  options({
    match: {
      params: { dueDiligenceDateId },
    },
  }) {
    return {
      variables: {
        id: dueDiligenceDateId,
      },
    };
  },
};
