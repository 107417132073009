// @flow
import { useQuery } from "@apollo/client/react/hooks";
import { gql } from "@apollo/client";
import { css } from "styled-components";
import { DealCompletion } from "@nest-ui/sellers-nest/deal/components/LeftNav/DealSummary/DealCompletion/DealCompletion";
import { media } from "@nested/brand";
import { TasksButton } from "../../components/RightSidePanel/Tasks/TasksButton";
import { Team } from "./Team";
import { PropertySummary } from "./PropertySummary";
import { OpportunityStatusSelect } from "./OpportunityStatusSelect";
import { EditDealButton } from "./EditDeal/EditDealButton";
import { FalloutReasonBanner } from "./FalloutReasonBanner";
import { SummaryButtons } from "./SummaryButtons";
import { DealStatus } from "./DealStatus/DealStatus";
import { EmailSellerButton } from "./EmailSellerButton/EmailSellerButton";
import { HandoffCallButton, HandoffSMSButton } from "../../components/Comms";
import { NurtureHotnessSelect } from "./NurtureHotnessSelect";

const flexColumn = css`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  ${media.tablet`
    padding: 30px 20px 0;
  `}
`;

const flexRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const dealStatus = css`
  margin: 0 20px 0 0;
`;

const dealSummary = css`
  ${flexRow}
  justify-content: space-between;
  margin-top: 15px;

  ${media.tablet`
    padding-top: 0;
    margin-top: 0;
  `}
`;

const dealOpportunityStatus = css`
  display: none;

  ${media.tablet`
    ${flexRow}
    justify-content: space-between;
    margin: 15px 0;
  `}
`;

const selectContainer = css`
  width: 230px;
  margin-right: 5px;
`;

const dealActionsTablet = css`
  display: flex;
  flex-direction: row;
  ${media.tablet`
    gap: 10px;
  `}
`;

export const DEAL_SUMMARY_QUERY = gql`
  query DealSummaryBar($id: ID!) {
    nestDeal(id: $id) {
      id
      active
      address
      externalId
      firstName
      lastName
      nurtureHotness {
        label
      }
      ownerName
      contacts {
        id
        name
        title
        avatarUrl
        phones {
          id
          telephoneNumber
        }
        emails {
          id
          emailAddress
        }
      }
      opportunityStatus {
        label
        value
        valueText
      }
      preCloseFalloutReason {
        label
      }
      reasonForDisinstruction {
        label
        value
      }
    }
  }
`;

type Props = {
  idOrExternalId: string,
  taskButtonProps: {
    onClick: (viewport: string) => void,
    showTaskPanel: (viewport: string) => boolean,
    taskCount: ?number,
  },
};

const hideOnMobile = css`
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

export const DealSummary = ({ idOrExternalId, taskButtonProps }: Props) => {
  const { data, loading } = useQuery(DEAL_SUMMARY_QUERY, {
    variables: { id: idOrExternalId },
  });

  const {
    id,
    active,
    address,
    closeIoLeadId,
    contacts = [],
    externalId,
    opportunityStatus,
    preCloseFalloutReason,
    reasonForDisinstruction,
  } = data?.nestDeal || {};

  // this avoids temporary displaying fallout reason on reactivated deals whilst page is loading
  const displayFalloutBanner = loading ? false : !active;

  const isOpportunityStatusCompleted =
    opportunityStatus?.valueText === "s11_completed";

  const showNurtureHotnessSelect =
    !loading && !active && !isOpportunityStatusCompleted;

  return (
    <div css={flexColumn}>
      {displayFalloutBanner && (
        <FalloutReasonBanner
          reasonForDisinstruction={reasonForDisinstruction?.label}
          preCloseFalloutReason={preCloseFalloutReason?.label}
        />
      )}
      <div css={dealSummary}>
        <PropertySummary idOrExternalId={idOrExternalId} />
        <div css={dealActionsTablet}>
          <EditDealButton id={id} />
          <HandoffCallButton
            contacts={contacts}
            css={hideOnMobile}
            relationId={externalId}
            relationType="deal"
          />
          <EmailSellerButton dealId={id} contacts={contacts} />
          <HandoffSMSButton
            css={hideOnMobile}
            contacts={contacts}
            relationId={externalId}
            relationType="deal"
          />
          <TasksButton
            css={hideOnMobile}
            data-test="tablet-tasks-button"
            onClick={() => taskButtonProps.onClick("tablet")}
            selected={taskButtonProps.showTaskPanel("tablet")}
            taskCount={taskButtonProps.taskCount || 0}
          />
        </div>
      </div>
      <SummaryButtons
        id={id}
        address={address}
        contacts={contacts}
        externalId={externalId}
        closeIoLeadId={closeIoLeadId}
        taskButtonProps={taskButtonProps}
      />
      <div css={dealOpportunityStatus}>
        <div css={flexRow}>
          {data?.nestDeal && (
            <div css={dealStatus}>
              <DealStatus id={id} active={active} />
            </div>
          )}
          {active && (
            <div css={selectContainer}>
              <OpportunityStatusSelect idOrExternalId={idOrExternalId} />
            </div>
          )}

          {showNurtureHotnessSelect && (
            <div css={selectContainer}>
              <NurtureHotnessSelect id={id} />
            </div>
          )}

          {isOpportunityStatusCompleted && (
            <DealCompletion dealId={id} dealActive={active} />
          )}
        </div>
        <Team idOrExternalId={idOrExternalId} />
      </div>
    </div>
  );
};
