// @flow
import { gql, useQuery, useMutation } from "@apollo/client";
import { css } from "styled-components";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { media } from "@nested/brand";
import { DatePicker } from "@nested/nest/src/components/DatePicker/DatePicker";

export const GET_DATES = gql`
  query GetExpectedAndActualDates($buyerOfferId: ID!) {
    buyerOffer(id: $buyerOfferId) {
      id
      actualDateOfCompletion
      actualDateOfExchange
      expectedCompletionDate
      expectedExchangeDate
      nestDeal {
        id
        opportunityStatus {
          valueText
        }
      }
    }
  }
`;

export const SET_EXPECTED_EXCHANGE_DATE = gql`
  mutation SetExpectedExchangeDate($buyerOfferId: ID!, $date: Date!) {
    setExpectedExchangeDateOnBuyerOffer(
      buyerOfferId: $buyerOfferId
      date: $date
    ) {
      id
      expectedExchangeDate
    }
  }
`;

export const SET_EXPECTED_COMPLETION_DATE = gql`
  mutation SetExpectedCompletionDate($buyerOfferId: ID!, $date: Date!) {
    setExpectedCompletionDateOnBuyerOffer(
      buyerOfferId: $buyerOfferId
      date: $date
    ) {
      id
      expectedCompletionDate
    }
  }
`;

export const SET_ACTUAL_DATE = gql`
  mutation UpdateActualDatesOnBuyerOffer($input: BuyerOfferInput!, $id: ID!) {
    updateBuyerOffer(input: $input, id: $id) {
      id
      actualDateOfCompletion
      actualDateOfExchange
    }
  }
`;

const wrapperStyle = css`
  align-items: end;
  > :first-child {
    padding-bottom: 14px;
  }
  ${media.tablet`
    display: flex; 
    gap: 20px;
    > :first-child {
      padding-bottom: 0;
    }
    > * {
      width: calc(50% - 10px);
    }
  `}
`;

export const ExchangeAndCompleteDates = ({
  buyerOfferId,
}: {
  buyerOfferId: ?string,
}) => {
  const { data } = useQuery(GET_DATES, {
    variables: { buyerOfferId },
    skip: !buyerOfferId,
  });
  const [setExpectedExchangeDate] = useMutation(SET_EXPECTED_EXCHANGE_DATE);
  const [setExpectedCompletionDate] = useMutation(SET_EXPECTED_COMPLETION_DATE);
  const [setActualDate] = useMutation(SET_ACTUAL_DATE);

  const {
    actualDateOfExchange,
    actualDateOfCompletion,
    expectedExchangeDate,
    expectedCompletionDate,
  } = data?.buyerOffer || {};

  const dealStatus = data?.buyerOffer?.nestDeal?.opportunityStatus?.valueText;

  const hasCompleted = dealStatus === "s11_completed";
  const hasExchanged = dealStatus === "s10_exchanged" || hasCompleted;

  const expectedExchangeOnSubmit = async (date) => {
    try {
      await setExpectedExchangeDate({
        variables: { buyerOfferId, date },
        optimisticResponse: {
          __typename: "Mutation",
          setExpectedExchangeDateOnBuyerOffer: {
            __typename: "BuyerOffer",
            id: buyerOfferId,
            expectedExchangeDate: date,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const expectedCompletionOnSubmit = async (date) => {
    try {
      await setExpectedCompletionDate({
        variables: { buyerOfferId, date },
        optimisticResponse: {
          __typename: "Mutation",
          setExpectedCompletionDateOnBuyerOffer: {
            __typename: "BuyerOffer",
            id: buyerOfferId,
            expectedCompletionDate: date,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  const updateBuyerOffer = async (input) => {
    try {
      await setActualDate({
        variables: { id: buyerOfferId, input },
        optimisticResponse: {
          __typename: "Mutation",
          updateBuyerOffer: {
            __typename: "BuyerOffer",
            id: buyerOfferId,
            actualDateOfCompletion,
            actualDateOfExchange,
            ...input,
          },
        },
      });
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <div css={wrapperStyle}>
      {hasExchanged ? (
        <DatePicker
          dataTest="actual-date-of-exchange"
          disabled={!buyerOfferId}
          label={"Exchanged"}
          onSubmit={(date) => updateBuyerOffer({ actualDateOfExchange: date })}
          value={actualDateOfExchange}
        />
      ) : (
        <DatePicker
          dataTest="expected-exchange-date"
          disabled={!buyerOfferId}
          label="Expected Exchange Date"
          onSubmit={expectedExchangeOnSubmit}
          value={expectedExchangeDate}
        />
      )}
      {hasCompleted ? (
        <DatePicker
          dataTest="actual-date-of-completion"
          disabled={!buyerOfferId}
          label={"Completed"}
          onSubmit={(date) =>
            updateBuyerOffer({ actualDateOfCompletion: date })
          }
          value={actualDateOfCompletion}
        />
      ) : (
        <DatePicker
          dataTest="expected-completion-date"
          disabled={!buyerOfferId}
          label="Expected Completion Date"
          onSubmit={expectedCompletionOnSubmit}
          value={expectedCompletionDate}
        />
      )}
    </div>
  );
};
