// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";

const errorWrapper = css`
  border: 2px solid ${({ theme }) => theme.palette.pink100};
  background: ${({ theme }) => theme.palette.pink20};
  margin: 20px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  ${media.tablet`
      flex-direction: ${({ sidebar }) => (sidebar ? "column" : "row")};
  `}
`;

const imageStyle = css`
  width: 120px;
`;

const textStyle = css`
  margin: 0 0 0 20px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  ${media.tablet`
    margin: ${({ sidebar }) => (sidebar ? "20px 0 0 0" : "0 0 0 20px")};
  `}
`;

export const ListViewError = ({
  className,
  message = "Something went wrong",
  sidebar = false,
}: {
  className?: string,
  message?: string,
  sidebar?: boolean,
}) => {
  return (
    <div css={errorWrapper} sidebar={sidebar} className={className}>
      <img
        css={imageStyle}
        src={getImage("illustrations/error-bot.png")}
        alt="error bot"
      />
      <div>
        <p css={textStyle} sidebar={sidebar}>
          {message}
        </p>
      </div>
    </div>
  );
};
