import { Fragment } from "react";
import T from "prop-types";
import { TransitionGroup } from "react-transition-group";

import { LeafCell } from "components/Grid";

import { DatePicker } from "components/DatePicker";
import { SelectField } from "components/SelectField";
import { FormToggleAnimation } from "../FormToggleAnimation";

export function ConditionalBuyerOfferFields({
  buyerOffer,
  updateBuyerOffer,
  inputOptions,
}) {
  return (
    <TransitionGroup component={Fragment}>
      {hasBeenAccepted(buyerOffer?.status?.value) && (
        <FormToggleAnimation>
          <LeafCell width={1}>
            <DatePicker
              label="Accepted offer date"
              property="acceptedDate"
              value={buyerOffer.acceptedDate}
              mutation={updateBuyerOffer}
              data-test="buyer-offer-modal-accepted-offer-date"
            />
          </LeafCell>
        </FormToggleAnimation>
      )}
      {hasFallenThrough(buyerOffer?.status?.value) && [
        <FormToggleAnimation key="1">
          <LeafCell width={1}>
            <DatePicker
              label="Date no longer accepted"
              property="acceptedButFallenThroughDate"
              value={buyerOffer.acceptedButFallenThroughDate}
              mutation={updateBuyerOffer}
              data-test="buyer-offer-modal-fallen-through-date"
            />
          </LeafCell>
        </FormToggleAnimation>,
        <FormToggleAnimation key="3">
          <LeafCell width={2}>
            <SelectField
              options={inputOptions.acceptedOfferFalloutReasons}
              label="Reason offer no longer accepted"
              property="acceptedOfferFalloutReason"
              mutation={updateBuyerOffer}
              value={buyerOffer.acceptedOfferFalloutReason}
              data-test="buyer-offer-modal-accepted-offer-fallout-reasons"
            />
          </LeafCell>
        </FormToggleAnimation>,
      ]}
    </TransitionGroup>
  );
}

function hasFallenThrough(value) {
  return value === "no_longer_accepted";
}

function hasBeenAccepted(value) {
  return value === "accepted" || value === "no_longer_accepted";
}

ConditionalBuyerOfferFields.propTypes = {
  buyerOffer: T.object.isRequired,
  updateBuyerOffer: T.func.isRequired,
  inputOptions: T.object.isRequired,
};
