// @flow
import { Route, Switch } from "react-router-dom";
import { Tabs, Tab } from "../../../components/Navigation/Tabs";
import {
  Breadcrumb,
  Breadcrumbs,
} from "../../../components/Navigation/Breadcrumbs";
import { TodaysNegotiationsListView } from "./TodaysNegotiationsListView";
import { UpcomingNegotiationsListView } from "./UpcomingNegotiationsListView";
import { useListViewCounts } from "../../../hooks/useListViewCounts";
import { ListViewPageWrapper } from "../../../components/ListViewSharedStyles";

export const Negotiations = () => {
  const { negotiatingOffers, upcomingNegotiatingOffers } = useListViewCounts();

  return (
    <ListViewPageWrapper>
      <Breadcrumbs>
        <Breadcrumb to="#">Buyers</Breadcrumb>
        <Breadcrumb to="#">Enquiries</Breadcrumb>
      </Breadcrumbs>
      <Tabs>
        <Tab to="/buyers/negotiations" exact count={negotiatingOffers}>
          Today
        </Tab>
        <Tab
          to="/buyers/negotiations/upcoming"
          count={upcomingNegotiatingOffers}
        >
          Upcoming
        </Tab>
      </Tabs>
      <Switch>
        <Route exact path="/buyers/negotiations">
          <TodaysNegotiationsListView />
        </Route>
        <Route path="/buyers/negotiations/upcoming">
          <UpcomingNegotiationsListView />
        </Route>
      </Switch>
    </ListViewPageWrapper>
  );
};
