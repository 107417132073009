// @flow
import { gql } from "@apollo/client";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Card as OriginalCard } from "components/Card";
import { H3 } from "components/Heading";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import {
  Background,
  HeaderWrapper,
  Header,
  HeaderLinks,
  Content,
  ContentWrapper,
} from "pages/SAiPadApp/StyledComponents";
import { Swoosh } from "pages/SAiPadApp/Swoosh";

import { CostsSection } from "./CostsSection/CostsSection";
import { LeaseDetailsSection } from "./LeaseDetailsSection/LeaseDetailsSection";
import { ViewingAccess } from "./ViewingAccess/ViewingAccess";
import { PropertyDetails, SaleDetails } from "./components";
import { SellerCircumstance } from "./SellerCircumstance/SellerCircumstance";

const NEST_DEAL_QUERY = gql`
  query ViewingAppNestDealQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      address
      councilTaxPerAnnum
      groundRent
      leaseRemaining
      serviceCharge
      tenure
      rmNotesExterior
      rmTopThreeFeaturesForBuyers
      currentListPrice
      daysSinceOriginalListing
      occupancy
      propertyListingPrices {
        id
        amount
        startDate
      }
      buyerOffers {
        id
        amount
        placedOn
        notes
        status {
          value
          label
        }
      }
      latestSellerSituation
      rmWhyNested
      property {
        id
        bedrooms
        bathrooms
        propertyType
        approxYearlyBills
        approxYearBuilt
        freeholderDetails
        serviceChargeParticulars
        parkingDetails
        neighbourDetails
        viewingAccessParticulars
        outsideSpaceAccess
      }
    }
  }
`;

const H1 = styled.h1`
  font-size: 18px;
  margin: 20px 0 0;
  color: ${({ theme }) => theme.color.primary.dark};
`;

const Card = styled(OriginalCard)`
  & > h2:not(:first-child) {
    margin-top: 20px;
  }
`;

const LayoutComponent = (props) => (
  <>
    <ViewingAccess {...props} />
    <SellerCircumstance {...props} />
    <SaleDetails {...props} />
    <PropertyDetails {...props} />
    <LeaseDetailsSection {...props} />
    <CostsSection {...props} />
  </>
);

type Props =
  | {| editMode: true, dealId: string |}
  | {|
      editMode: false,
      dealId: string,
      buyerId?: string,
      buyerPropertyInterestId?: string,
    |};

export const ViewingAppPropertyInfo = (props: Props) => {
  const { dealId, editMode } = props;
  const homeUrl =
    props.buyerId && props.buyerPropertyInterestId
      ? `/buyers/${props.buyerId}/bpi/${props.buyerPropertyInterestId}`
      : `/deals/${props.dealId}`;

  return (
    <ExtendedQuery query={NEST_DEAL_QUERY} variables={{ id: dealId }}>
      {({ nestDeal }: ExtendedQueryRenderProps<ViewingAppNestDealQuery>) => {
        const { address, property } = nestDeal;

        if (props.editMode) {
          return (
            <Card>
              <LayoutComponent {...{ editMode, nestDeal }} />
            </Card>
          );
        }

        return (
          <Background>
            <HeaderWrapper>
              <Swoosh />
              <Header>
                <div>
                  <img
                    src="https://nested.imgix.net/nestedLogoWhite.svg?auto=format,compress"
                    alt=""
                  />
                </div>
                <HeaderLinks>
                  <Link to={homeUrl}>Home</Link>
                  <a
                    href={`/deals/${dealId}/listing#property---info--app-`}
                    target="_blank"
                  >
                    Edit
                  </a>
                </HeaderLinks>
              </Header>
            </HeaderWrapper>
            <ContentWrapper>
              <Content>
                <H1
                  data-test="viewing-app-property-info-address"
                  className="fs-exclude"
                >
                  {address}
                </H1>
                <H3>
                  {property.bedrooms} Beds {property.bathrooms} Baths{" "}
                  {property.propertyType}
                </H3>
                <LayoutComponent {...{ editMode, nestDeal }} />
              </Content>
            </ContentWrapper>
          </Background>
        );
      }}
    </ExtendedQuery>
  );
};
