import { useState } from "react";
import { NoSubmitTextField } from "components/TextField";
import { Grid, LeafCell } from "components/Grid";
import { AddButton } from "components/Buttons";
import { Warning } from "components/Warning";

export const CreateCustomerAccountForm = ({
  onSubmit,
  nestDeal: { ownerName },
}) => {
  const [firstName, lastName] = ownerName ? ownerName.split(" ") : ["", ""];

  const [formState, updateFormState] = useState({
    firstName,
    lastName,
  });

  const createChangeHandler = (field) => (value) => {
    updateFormState({
      ...formState,
      [field]: value,
    });
  };
  return (
    <>
      <Grid columns={2}>
        <LeafCell width={2}>
          <Warning>
            This will trigger an email to the customer telling them to log in to
            their account. Please double check the name and email address before
            proceeding
          </Warning>
        </LeafCell>
        <LeafCell>
          <NoSubmitTextField
            className="fs-exclude"
            label="Email address"
            name="email"
            property="email"
            data-test="create-account-email"
            onSubmit={createChangeHandler("email")}
            value={formState.email}
          />
        </LeafCell>
        <LeafCell left={1}>
          <NoSubmitTextField
            className="fs-exclude"
            label="First name"
            name="firstName"
            property="firstName"
            data-test="create-account-first-name"
            onSubmit={createChangeHandler("firstName")}
            value={formState.firstName}
          />
        </LeafCell>
        <LeafCell>
          <NoSubmitTextField
            className="fs-exclude"
            label="Last name"
            name="lastName"
            property="lastName"
            data-test="create-account-last-name"
            onSubmit={createChangeHandler("lastName")}
            value={formState.lastName}
          />
        </LeafCell>
        <LeafCell>
          <AddButton
            data-test="create-account-submit"
            onClick={() => onSubmit(formState)}
          >
            Create account
          </AddButton>
        </LeafCell>
      </Grid>
    </>
  );
};
