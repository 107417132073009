// @flow

import type { Element } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import { CancelButton, PrimaryButton } from "components/Buttons";
import { Add } from "@nest-ui/icons";

import { TranslucentBox, WhiteBox, Title, Content, Footer } from "./Modal";

// The difference between this modal and the regular modal, is that this modal
// does not save anything until the primary button is pressed. It contains
// ephemeral state that may disappear if the user clicks away.

const Box = styled(WhiteBox)`
  height: auto;
  width: 336px;
`;

// Bottom padding was increased to accomodate select dropdowns
const ContentWithBottomSpace = styled(Content)`
  padding-bottom: calc(16px + 200px);
`;

type EphemeralStateModalProps = {|
  // eslint-disable-next-line react/no-unused-prop-types
  "data-test": string,
  buttonEnabled: boolean,
  buttonLabel: string,
  children: Element<any>,
  closeModal: () => void,
  isOpen: boolean,
  icon?: (SVGProps) => React$Element<"svg">,
  isSubmitting: boolean,
  onButtonClick: () => any,
  title: string,
|};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const EphemeralStateModal = ({
  "data-test": dataTest,
  buttonEnabled = true,
  buttonLabel = "Add",
  children,
  closeModal,
  icon = Add,
  isOpen,
  isSubmitting = false,
  onButtonClick,
  title,
}: EphemeralStateModalProps) => (
  <CSSTransition
    in={isOpen}
    timeout={200}
    classNames="modal"
    appear
    unmountOnExit
  >
    <TranslucentBox onClick={closeModal}>
      <Box
        data-test={dataTest}
        className="white-box"
        id="modal"
        onClick={(e) => e.stopPropagation()}
      >
        <Title title={title} />
        <ContentWithBottomSpace>{children}</ContentWithBottomSpace>
        <Footer>
          <Buttons>
            <CancelButton
              onClick={closeModal}
              data-test="ephemeral-state-modal-cancel-button"
            >
              Cancel
            </CancelButton>
            <PrimaryButton
              disabled={isSubmitting || !buttonEnabled}
              onClick={onButtonClick}
              data-test="ephemeral-state-modal-submit-button"
              label={buttonLabel}
              icon={icon}
            />
          </Buttons>
        </Footer>
      </Box>
    </TranslucentBox>
  </CSSTransition>
);
