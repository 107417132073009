// @flow
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import styled, { css } from "styled-components";
import { ChangeOnBlurNestTextInput } from "@nested/brand";
import { ChangeOnBlurTextArea } from "@nested/nest/src/components/TextArea/ChangeOnBlurTextArea";
import { MobileFullScreenModal } from "@nested/nest/src/components/Modal";
import { Button } from "@nested/nest/src/components/Button/Button";
import { Grid, LeafCell } from "components/Grid";
import { H2 as OriginalH2 } from "components/Heading";
import { ListEntryCard } from "components/ListEntry";
import { UserProfileImage } from "./IndividualUserSettings";
import { DeactivateUser } from "./DeactivateUser";

const H2 = styled(OriginalH2)`
  display: inline-block;
  margin: 7px 0;
`;

const Cell = styled(LeafCell)`
  align-items: flex-start;
`;

const USER_FRAGMENT = gql`
  fragment EditUserFields on NestedUser {
    id
    actionPlanReviewBookingUrl
    active
    bio
    closeUserId
    email
    firstName
    fullName
    homeVisitBookingUrl
    introCallBookingUrl
    jobTitle
    lastName
    mobileNumber
    partnerArea
    videoCallUrl
    viewingBookingUrl
    virtualHomeVisitBookingUrl
    servicedAreaPostcodeSectors {
      id
      area
      district
      sector
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GetNestedUserForEdit($id: ID!) {
    nestedUser(id: $id) {
      id
      ...EditUserFields
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateNestedUser($id: ID!, $input: UpdateNestedUserInput!) {
    updateNestedUser(id: $id, input: $input) {
      successful
      nestedUser {
        id
        ...EditUserFields
      }
    }
  }
  ${USER_FRAGMENT}
`;

const validateRequiredForAPs = (jobTitle, field) =>
  jobTitle !== "Area Partner" || Boolean(field);

const EditUser = ({ user, deactivateUser }) => {
  const [update] = useMutation(UPDATE_USER_MUTATION);
  const updateUser = async (input) => {
    try {
      await update({
        variables: {
          id: user.id,
          input,
        },
        optimisticResponse: {
          updateNestedUser: {
            __typename: "UpdateNestedUserResult",
            successful: true,
            nestedUser: {
              ...user,
              ...input,
            },
          },
        },
      });
    } catch (e) {
      window.alert("Failed to update the user: \n\n" + e.message); // eslint-disable-line
    }
  };

  if (!user) return null;

  const {
    actionPlanReviewBookingUrl,
    active,
    bio,
    closeUserId,
    email,
    firstName,
    homeVisitBookingUrl,
    introCallBookingUrl,
    jobTitle,
    lastName,
    mobileNumber,
    partnerArea,
    servicedAreaPostcodeSectors,
    videoCallUrl,
    viewingBookingUrl,
    virtualHomeVisitBookingUrl,
  } = user;

  return (
    <>
      <Grid columns={4} css="padding: 0 20px 20px;">
        <Cell>
          <ChangeOnBlurNestTextInput
            label="First Name"
            onChange={(e) => {
              updateUser({ firstName: e.target.value });
            }}
            valid={Boolean(firstName)}
            value={firstName}
          />
        </Cell>
        <Cell>
          <ChangeOnBlurNestTextInput
            label="Last Name"
            onChange={(e) => {
              updateUser({ lastName: e.target.value });
            }}
            valid={Boolean(lastName)}
            value={lastName}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurNestTextInput
            label="Email"
            onChange={(e) => {
              updateUser({ email: e.target.value });
            }}
            valid={Boolean(email)}
            value={email}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurNestTextInput
            label="Job Title"
            onChange={(e) => {
              updateUser({ jobTitle: e.target.value });
            }}
            valid={Boolean(jobTitle)}
            value={jobTitle}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurNestTextInput
            label="Partner Area - customer facing (required for APs)"
            onChange={(e) => {
              updateUser({ partnerArea: e.target.value });
            }}
            valid={validateRequiredForAPs(jobTitle, partnerArea)}
            value={partnerArea}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurNestTextInput
            label="Mobile Number (required for APs)"
            onChange={(e) => {
              updateUser({ mobileNumber: e.target.value });
            }}
            valid={validateRequiredForAPs(jobTitle, mobileNumber)}
            value={mobileNumber}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurNestTextInput
            label="Close User ID (if applicable)"
            onChange={(e) => {
              updateUser({ closeUserId: e.target.value });
            }}
            value={closeUserId}
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ introCallBookingUrl: e.target.value });
            }}
            value={introCallBookingUrl}
            valid={introCallBookingUrl}
            label="Intro Call Calendly URL"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ homeVisitBookingUrl: e.target.value });
            }}
            value={homeVisitBookingUrl}
            valid={validateRequiredForAPs(jobTitle, homeVisitBookingUrl)}
            label="Home Visit Calendly URL (required for APs)"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ viewingBookingUrl: e.target.value });
            }}
            value={viewingBookingUrl}
            label="Viewing Calendly URL"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ actionPlanReviewBookingUrl: e.target.value });
            }}
            value={actionPlanReviewBookingUrl}
            label="Action Plan Review Calendly URL"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ virtualHomeVisitBookingUrl: e.target.value });
            }}
            value={virtualHomeVisitBookingUrl}
            label="Virtual Home Visit Calendly URL"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={2}>
          <ChangeOnBlurTextArea
            onChange={(e) => {
              updateUser({ videoCallUrl: e.target.value });
            }}
            value={videoCallUrl}
            label="Zoom URL"
            lineLimit={2}
            disableShowMore
          />
        </Cell>
        <Cell width={4}>
          <ChangeOnBlurTextArea
            label="Bio"
            onChange={(e) => {
              updateUser({ bio: e.target.value });
            }}
            valid={validateRequiredForAPs(jobTitle, bio)}
            value={bio}
          />
        </Cell>
        <Cell width={4}>
          <ListEntryCard css="display: flex; justify-content: space-between;">
            <UserProfileImage email={email} />
            <H2 css="min-width: fit-content;">Update profile Image</H2>
            <p
              css={css`
                margin: 0 0 0 20px;
              `}
            >
              To add a profile image, the user must go to{" "}
              <a href="https://en.gravatar.com/" target="_blank">
                gravatar.com
              </a>{" "}
              and set up an account with their nested email address. Once this
              is done, their image should appear here.
            </p>
          </ListEntryCard>
        </Cell>
        {servicedAreaPostcodeSectors.length > 0 && (
          <Cell width={4} left={1}>
            <ListEntryCard css="margin-top: 0;">
              <H2>RM serviced area</H2>
              <p>
                {servicedAreaPostcodeSectors
                  .map((s) => [s.area, s.district, " ", s.sector].join(""))
                  .join(", ")}
              </p>
            </ListEntryCard>
          </Cell>
        )}
        <Cell width={2} left={2}>
          <Button onClick={() => deactivateUser(true)} buttonStyle="pink">
            {active ? "Deactivate and Reassign" : "Deactivated"}
          </Button>
        </Cell>
      </Grid>
    </>
  );
};

export const EditUserModal = ({
  onClose,
  userId,
}: {
  onClose: () => void,
  userId: string,
}) => {
  const [deactivate, setDeactivate] = useState(false);
  const { data } = useQuery(GET_USER_QUERY, {
    variables: { id: userId },
  });

  if (!data?.nestedUser) return null;

  const headerText = deactivate
    ? `Deactivate ${data.nestedUser.fullName}`
    : "Manage User";

  return (
    <MobileFullScreenModal
      onClose={onClose}
      onBack={deactivate ? () => setDeactivate(false) : null}
      css="width: 780px; min-height: 380px;"
      data-test="manage-user-modal"
      headerText={headerText}
      open
    >
      {deactivate ? (
        <DeactivateUser userId={userId} onClose={onClose} />
      ) : (
        <EditUser user={data.nestedUser} deactivateUser={setDeactivate} />
      )}
    </MobileFullScreenModal>
  );
};
