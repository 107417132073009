// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { CurrencyField } from "components/CurrencyField";
import { Grid, LeafCell } from "components/Grid";
import { PercentageField } from "components/PercentageField";

const AGENCY_PLUS_SEP_2018_FIELDS = gql`
  fragment DealTypeAgencyPlusSep2018Fragment on DealTypeAgencyPlusSep2018 {
    id
    agencyFee
    agencyOnlyFee
    depositDrawdownAllowancePercentage
    depositDrawdownFee
    drawdownTimingFees {
      id
      fee
      days
      totalFee
    }
    referralDiscount
    reservationFee
  }
`;

const AGENCY_PLUS_SEP_2018_QUERY = gql`
  query AgencyPlusSep2018Query($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      dealTypeAgencyPlusSep2018 {
        id
        ...DealTypeAgencyPlusSep2018Fragment
      }
    }
  }

  ${AGENCY_PLUS_SEP_2018_FIELDS}
`;

const UPDATE_AGENCY_PLUS_SEP_2018 = gql`
  mutation UpdateDealTypeAgencyPlusSep2018(
    $input: DealTypeAgencyPlusSep2018Input
    $id: ID!
  ) {
    updateDealTypeAgencyPlusSep2018(input: $input, id: $id) {
      id
      ...DealTypeAgencyPlusSep2018Fragment
    }
  }
  ${AGENCY_PLUS_SEP_2018_FIELDS}
`;

const Content = ({ data, mutation }) => (
  <Grid columns={4}>
    <LeafCell>
      <PercentageField
        mutation={mutation}
        property="agencyOnlyFee"
        label="List-first fee %"
        value={data.agencyOnlyFee}
        data-test="deal-type-agency-plus-sep-2018-agency-only-fee"
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        mutation={mutation}
        property="agencyFee"
        label="Agency+ fee %"
        value={data.agencyFee}
        data-test="deal-type-agency-plus-sep-2018-agency-fee"
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        mutation={mutation}
        property="reservationFee"
        label="Advance reservation fee %"
        value={data.reservationFee}
        data-test="deal-type-agency-plus-sep-2018-advance-reservation-fee"
      />
    </LeafCell>
    <LeafCell />
    <LeafCell>
      <PercentageField
        mutation={mutation}
        property="depositDrawdownAllowancePercentage"
        label="Deposit drawdown allowance %"
        value={data.depositDrawdownAllowancePercentage}
        data-test="deal-type-agency-plus-sep-2018-deposit-drawdown-allowance"
      />
    </LeafCell>
    <LeafCell>
      <PercentageField
        mutation={mutation}
        property="depositDrawdownFee"
        label="Deposit drawdown fee %"
        value={data.depositDrawdownFee}
        data-test="deal-type-agency-plus-sep-2018-deposit-drawdown-fee"
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        mutation={mutation}
        property="referralDiscount"
        label="Referral discount"
        value={data.referralDiscount}
        data-test="deal-type-agency-plus-sep-2018-referral-discount"
      />
    </LeafCell>
  </Grid>
);

export const AgencyPlusSep2018 = ({ dealId }: { dealId: string }) => (
  <ExtendedQuery
    query={AGENCY_PLUS_SEP_2018_QUERY}
    variables={{ dealId }}
    fetchPolicy="network-only"
    nextFetchPolicy="cache-first"
  >
    {({
      nestDeal: { dealTypeAgencyPlusSep2018: dealTypeData },
    }: ExtendedQueryRenderProps<AgencyPlusSep2018Query>) => {
      if (!dealTypeData) return null;

      return (
        <ExtendedMutation mutation={UPDATE_AGENCY_PLUS_SEP_2018}>
          {(
            mutate: MutationFunction<
              UpdateDealTypeAgencyPlusSep2018,
              UpdateDealTypeAgencyPlusSep2018Variables,
            >,
          ) => {
            const updateAgencyPlusSep2018 = async (input) => {
              await mutate({
                variables: { input, id: dealTypeData.id },
                optimisticResponse: {
                  __typename: "Mutation",
                  updateDealTypeAgencyPlusSep2018: {
                    __typename: "DealTypeAgencyPlusSep2018",
                    ...dealTypeData,
                    ...input,
                  },
                },
              });
            };

            return (
              <Content data={dealTypeData} mutation={updateAgencyPlusSep2018} />
            );
          }}
        </ExtendedMutation>
      );
    }}
  </ExtendedQuery>
);
