export const CollapseArrows = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 11.25L0.15625 14.3438C0.0520833 14.4479 0 14.5625 0 14.6875C0 14.8333 0.0520833 14.9583 0.15625 15.0625L0.9375 15.8438C1.16667 16.0938 1.40625 16.0938 1.65625 15.8438L4.75 12.75L5.71875 13.7812C5.94792 14.0104 6.21875 14.0625 6.53125 13.9375C6.84375 13.8125 7 13.5833 7 13.25V9.75C7 9.54167 6.92708 9.36458 6.78125 9.21875C6.63542 9.07292 6.45833 9 6.25 9H2.75C2.41667 9 2.1875 9.15625 2.0625 9.46875C1.9375 9.78125 1.98958 10.0521 2.21875 10.2812L3.25 11.25ZM10.2812 2.21875C10.0521 1.98958 9.78125 1.9375 9.46875 2.0625C9.15625 2.1875 9 2.41667 9 2.75V6.25C9 6.45833 9.07292 6.63542 9.21875 6.78125C9.36458 6.92708 9.54167 7 9.75 7H13.25C13.5833 7 13.8125 6.84375 13.9375 6.53125C14.0625 6.21875 14.0104 5.94792 13.7812 5.71875L12.75 4.75L15.8438 1.65625C15.9479 1.55208 16 1.4375 16 1.3125C16 1.16667 15.9479 1.04167 15.8438 0.9375L15.0625 0.15625C14.9583 0.0520833 14.8438 0 14.7188 0C14.5729 0 14.4479 0.0520833 14.3438 0.15625L11.25 3.25L10.2812 2.21875Z"
      fill="#4E7A88"
    />
  </svg>
);
