// @flow
import styled, { css } from "styled-components";
import { media, SmallAgentGravatar } from "@nested/brand";
import { getImage } from "@nested/utils";
import { HandoffCallButton } from "./Comms";
import { type ContactWithPhones } from "./Navigation/ContactDrawer/ContactDrawerShared";

export const ListViewPageWrapper = styled.div`
  padding: 20px 15px;
  ${media.tablet`
    padding: 30px 20px;
  `}
`;

export const mobileWrapperStyle = css`
  margin-bottom: 60px;
  padding-bottom: 200px;
  ${media.tablet`
    display: none;
  `}
`;

export const tabletWrapperStyle = css`
  display: none;
  ${media.tablet`
    display: block;
    width: 100%;
    overflow-y: auto;
  `}
`;

export const dealsLoadingStyle = css`
  opacity: ${({ $loading }) => ($loading ? " 0.3" : "1")};
  transition: opacity 100ms linear;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
`;

const imageStyle = css`
  width: 80px;
  margin-right: 15px;
  border-radius: 2px;
`;

export const rowWithImage = css`
  height: 94px;
`;

const callButtonStyle = css`
  margin-left: auto;
  width: 30px;
`;

type Props = {
  progressor: {
    email: string,
    firstName: string,
  },
};

export const Progressor = ({ progressor }: Props) => {
  if (progressor) {
    return (
      <div css="display: flex; align-items: center;">
        <SmallAgentGravatar email={progressor.email} />
        {progressor.firstName}
      </div>
    );
  }
  return "-";
};

export const ImageAndAddress = ({
  address,
  externalId,
  className = "",
}: {
  address: string,
  externalId: string,
  className?: string,
}) => {
  return (
    <div
      css="display: flex; flex-direction: row; align-items: center;"
      className={`fs-exclude ${className}`}
    >
      <img
        alt=""
        css={imageStyle}
        src={getImage(`property-listings/${externalId}/1`)}
      />
      {address}
    </div>
  );
};

type CallButtonProps = {
  contacts: $ReadOnlyArray<ContactWithPhones>,
  externalId: string,
  relationType: string,
};

export const ListViewCallButton = ({
  contacts,
  externalId,
  relationType,
}: CallButtonProps) => {
  return (
    <HandoffCallButton
      css={callButtonStyle}
      contacts={contacts}
      data-test={`desktop-phone-${externalId}`}
      relationType={relationType}
      relationId={externalId}
    />
  );
};
