// @flow
import { withHandlers, withProps, compose, type HOC } from "recompose";
import { type, hasIn } from "ramda";
import { isEqual } from "lodash";

import { withReadOnly, withOnSubmit } from "@nest-ui/hocs";
import { Label } from "components/Label";
import { Select } from "./ReactSelect";

const isEmptyArray = (array: mixed) =>
  Array.isArray(array) && array.length === 0;

/* The `value` property of this component can be four possible things in four
 * possible cases:
 *   1. Single select with a simple string: e.g. "Studio flat"
 *   2. Single select with an object as the `options`: e.g. {value: "just_interested"}
 *   3. Multiple selects with a simple array of strings: e.g. ["Balcony", "Patio"]
 *   4. Multiple selects with an object as the `options` - POSSIBLY NOT SUPPORTED!
 *
 * Option 4 may work, but it would need manual testing to confirm
 */
export const parseValue = (value: ?Value | ?Choice | ?Array<Value>) => {
  if (value instanceof Object && !Array.isArray(value)) {
    // Case 3 (and possibly 4)
    return value.value;
  }
  // Cases 1 and 2
  return value;
};

/* Input Options from graphql come sorted alphabetically, which is fine in most cases.
 * Sometimes however we need the order to match the workflow. In this case, you can
 * pass the `preferredOrder` config to a SelectField in order to specify
 * an order you want, where `preferredOrder` is a list of values in the order you want them.
 *
 * The caveat with this is that we don't know if all options will be present in the preference,
 * so this function makes a best attempt at sorting by the preference. Any additional fields
 * will be left in their original order and appended to the end of the list.
 *
 * If no preference is specified the options are left unchanged.
 */
const sortWithPreference = (options: Array<Choice>, preferred) => {
  if (!preferred) {
    return options;
  }

  const isPreferred = (option: Choice) => preferred.includes(option.value);
  const preferredOrder = (a: Choice, b: Choice) =>
    preferred.indexOf(a.value) - preferred.indexOf(b.value);
  const unmatchedOptions = (option: Choice) =>
    !preferred.includes(option.value);

  return options
    .filter(isPreferred)
    .sort(preferredOrder)
    .concat(options.filter(unmatchedOptions));
};

function isComplexOption(option: any) {
  return (
    type(option) === "Object" &&
    hasIn("label", option) &&
    hasIn("value", option)
  );
}

export const parseOption = (option: Value | Choice): Choice => {
  if (
    typeof option === "number" ||
    typeof option === "string" ||
    typeof option === "boolean" ||
    option === null
  ) {
    return {
      label: String(option),
      value: option,
    };
  }

  if (isComplexOption(option)) {
    return option;
  }

  throw new Error(
    `Got unexpected option in SelectField: ${JSON.stringify(
      option,
    )} with type ${type(
      option,
    )}. Please ensure your options array consists of either plain values or objects in the shape {label: label, value: value}`,
  );
};

const parseDivider = (divider) => ({
  ...divider,
  isDisabled: true,
});

const getLabelsFromOptions = (values: Array<Value>, options: Array<Choice>) =>
  values
    .map((value: Value) => {
      const selected = options.find((option) => isEqual(option.value, value));
      return selected ? selected.label || value : value;
    })
    .join(", ");

type Props = {|
  "data-test"?: string,
  dark?: boolean,
  disabled?: boolean,
  dividers?: $ReadOnlyArray<Choice>,
  highlightRed?: boolean,
  label?: string,
  multiple?: boolean,
  nullable?: boolean,
  onSubmit: (option: Value) => void,
  options: $ReadOnlyArray<Choice>,
  preferredOrder?: $ReadOnlyArray<string>,
  searchable?: boolean,
  value: ?Value | ?Choice,
  property?: string,
  placeholder?: string,
|};

type Selection = {|
  isDisabled?: boolean,
  label: string,
  value: Value,
|};

type Selections = Array<Selection>;

// Exported for testing only
export const onChangeHandler =
  (props: any) => (selection: Selection | Selections) => {
    const { onSubmit, multiple } = props;
    if (selection === null) {
      // This first case allows us to nullify a dropdown
      // with backspace if the nullable prop is there.
      onSubmit(null);
    } else if (multiple === true && Array.isArray(selection)) {
      onSubmit(isEmptyArray(selection) ? null : selection.map((s) => s.value));
    } else if (!Array.isArray(selection)) {
      onSubmit(selection.value);
    }
  };

export const enhance: HOC<*, Props> = compose(
  withProps((props) => {
    const { value, options, preferredOrder, dividers = [] } = props;
    return {
      value: parseValue(value),
      options: sortWithPreference(
        options.map(parseOption).concat(dividers.map(parseDivider)),
        preferredOrder,
      ),
    };
  }),
  withHandlers({ onChange: onChangeHandler }),
  withReadOnly(({ value, options, multiple }) =>
    multiple
      ? getLabelsFromOptions(value || [], options)
      : getLabelsFromOptions([value], options),
  ),
);

const getSelectedOptionFromValue = (options, value) => {
  if (Array.isArray(value)) {
    return value.map((item) => options.find((o) => o.value === item));
  }

  return options.find((o) => o.value === value) || null;
};

const SelectComponent = (props) => {
  const {
    "data-test": dataTest,
    dark,
    highlightRed,
    label,
    multiple,
    nullable = true,
    onChange,
    options,
    searchable = true,
    value,
    className,
    placeholder,
  } = props;

  return (
    <div className={className} data-test={dataTest || "select-field"}>
      {label ? <Label>{label}</Label> : null}
      <Select
        dark={dark}
        highlightRed={highlightRed}
        clearable={nullable}
        searchable={searchable}
        options={options}
        multi={multiple}
        value={getSelectedOptionFromValue(options, value)}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export const NoSubmitSelectField = enhance(SelectComponent);
export const SelectField = withOnSubmit(NoSubmitSelectField);
