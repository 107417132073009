// @flow
import { gql } from "@apollo/client";
import { type ContextRouter } from "react-router-dom";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { AdvancesForAllMarch2019Payments } from "tabs/Payments/AdvancesForAllMarch2019Payments/AdvancesForAllMarch2019Payments";
import { LegacyPayments } from "tabs/Payments/LegacyPayments/LegacyPayments";

const PAYMENTS_TAB_QUERY = gql`
  query PaymentsTabQuery($idOrExternalDealId: ID!) {
    nestDeal(id: $idOrExternalDealId) {
      id
      dealTypeDetails {
        ... on DealTypeAdvancesForAllMarch2019 {
          id
        }
      }
      dealType {
        value
        label
      }
    }
  }
`;

export const Payments = ({
  match: {
    params: { id: idOrExternalDealId },
  },
}: ContextRouter) => (
  <ExtendedQuery query={PAYMENTS_TAB_QUERY} variables={{ idOrExternalDealId }}>
    {({
      nestDeal: { id, dealType, dealTypeDetails },
    }: ExtendedQueryRenderProps<PaymentsTabQuery>) => {
      const dealTypeValue = dealType?.value;
      // This dealTypeId is only ever used when the deal type is advances_for_all_march2019
      // and if the deal type is that there will be an id for it, meaning we'll only ever use it
      // if it is non null. But I don't know how to tell flow that.
      // $FlowFixMe
      const dealTypeid = dealTypeDetails?.id;
      switch (dealTypeValue) {
        case undefined:
          return null;
        case null:
          return null;
        case "advances_for_all_march2019":
          return (
            <AdvancesForAllMarch2019Payments
              dealId={id}
              dealTypeValue="advances_for_all_march2019"
              // $FlowFixMe this will never actually be null
              dealTypeId={dealTypeid}
            />
          );
        default:
          return <LegacyPayments dealId={id} dealTypeValue={dealTypeValue} />;
      }
    }}
  </ExtendedQuery>
);
