import styled from "styled-components";

import { media } from "@nested/brand";

const FormCell = styled.section`
  padding: 16px 0;
  padding-bottom: 200px;
  width: 100%;

  ${media.tablet`
    width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
    max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "640px")};
  `}

  & > section:first-child {
    margin-top: 0px;
  }
`;

export const ParentCell = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  width: 100%;
  max-height: 100%;
`;

export const ScrollableSection = styled.div`
  max-height: 100vh;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
`;

export const TabContainer = ({ children, fullWidth = false, ...rest }) => (
  <FormCell fullWidth={fullWidth} {...rest}>
    {children}
  </FormCell>
);

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.palette.sand20};
  padding: 0px 15px;
  ${media.desktop`
    padding: 0px 20px;
  `};
`;
