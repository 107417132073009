import thirdPartyAppraisalsQuery from "../queries/thirdPartyAppraisals.graphql";

export const deleteConfig = {
  props({ mutate, ownProps }) {
    return {
      deleteThirdPartyAppraisal: () =>
        mutate({
          variables: {
            id: ownProps.id,
          },
          refetchQueries: [
            {
              query: thirdPartyAppraisalsQuery,
              variables: {
                dealId: ownProps.dealId,
              },
            },
          ],
        }),
    };
  },
};
