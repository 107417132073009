// @flow
import type { MutationOperation } from "@apollo/client";
import ExpectedDrawdownTimingsQuery from "../queries/expectedDrawdownTimings.graphql";

type OwnProps = {
  parentId: string,
};

type Props = {
  mutate: MutationOperation<CreateExpectedDrawdownTimingMutation_createExpectedDrawdownTiming>,
  ownProps: OwnProps,
};

export const createExpectedDrawdownTimingConfig = {
  props: ({ mutate, ownProps }: Props) => ({
    createExpectedDrawdownTiming: ({
      agreementDate,
      drawdownDay,
    }: {
      agreementDate: string,
      drawdownDay: number,
    }) =>
      mutate({
        variables: {
          input: {
            dealId: ownProps.parentId,
            agreementDate,
            drawdownDay,
          },
        },
        refetchQueries: [
          {
            query: ExpectedDrawdownTimingsQuery,
            variables: {
              id: ownProps.parentId,
            },
          },
        ],
      }),
  }),
};
