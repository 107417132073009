import { resolveInputOptions } from "deal/utils/graphql";
import dueDiligenceDatesQuery from "../queries/dueDiligenceDates.graphql";
const inputOptionFields = {
  reasonForChangeOfDate: "reasonsForChangeOfDueDiligenceEndDate",
};

export const updateConfig = {
  props({ mutate, ownProps }) {
    return {
      updateDueDiligenceDate(input) {
        const inputOptions = {
          reasonsForChangeOfDueDiligenceEndDate:
            ownProps.reasonsForChangeOfDueDiligenceEndDate,
        };
        const optimisticInput = resolveInputOptions(
          input,
          inputOptionFields,
          inputOptions,
        );
        const {
          params: { dealId },
        } = ownProps.match;
        return mutate({
          optimisticResponse: {
            __typename: "Mutation",
            updateDueDiligenceDate: {
              __typename: "DueDiligenceDate",
              ...ownProps.data.dueDiligenceDate,
              ...optimisticInput,
            },
          },
          refetchQueries: [
            {
              query: dueDiligenceDatesQuery,
              variables: { id: dealId },
            },
          ],
          variables: {
            id: ownProps.match.params.dueDiligenceDateId,
            input,
          },
        }).then((result) => result);
      },
    };
  },
};
