// @flow
import type { Element } from "react";

import { useEffect, useState } from "react";
import { Redirect, useLocation, useHistory, matchPath } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { sendAnalyticsEvent } from "@nested/analytics";
import { ONBOARDING_FORM_QUESTION_ANSWERED } from "@nested/analytics/events";
import { Question } from "./Question";
import { QuestionNotFound } from "./QuestionNotFound";
import { type State, formStateUpdateAction } from "./ducks";
import { createActionButtons } from "./Buttons";
import {
  BackButton as OriginalBackButton,
  type BackButtonProps,
} from "../BackButton";
import { persistor } from "../../store";

/*
 * Each question fires an event.
 * formType - Which part of onboarding was the question from (e.g. personal information)
 * name - The name of the question
 * value - The answer to the question. It must be flattened if its an object
 */
const fireAnalyticsEvent = (formType, name, value) => {
  let eventData = {
    event: ONBOARDING_FORM_QUESTION_ANSWERED,
    type: formType,
    question: name,
    answer: undefined,
  };
  if (typeof value === "object") {
    eventData = { ...eventData, ...value, answer: JSON.stringify(value) };
  } else {
    eventData[name] = value;
    eventData.answer = value;
  }
  sendAnalyticsEvent(eventData);
};

export const MultiStageForm = ({
  children,
  onSubmit,
  lastButtonLabel = "Next",
  submitError,
  setSubmitError,
  formType,
  previousFormPath,
  restartPath = "/get-started",
}: {|
  children: $ReadOnlyArray<Element<typeof Question>>,
  onSubmit: (formState: State) => Promise<void>,
  lastButtonLabel?: string,
  submitError?: React$Node,
  setSubmitError?: (message?: React$Node) => void,
  formType?: string,
  previousFormPath?: string,
  restartPath?: string,
|}) => {
  const [nextState, setNext] = useState({
    redirectToNext: false,
    replaceBrowserHistory: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const formState = useSelector((state) => state.onboardingForm);
  const dispatch = useDispatch();
  const updateFormState = (payload: $Shape<State>) =>
    dispatch(formStateUpdateAction(payload));
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const { push, replace } = history;

  /*
   * We need to include the currently selected question in the list regardless
   * of whether or not it was skipped, because if it's the first question we
   * land on in the form and has also been skipped, we want to redirect to the
   * next question instead of show a not found page
   */
  const questions = children.filter(({ props: { route, skip } }) => {
    const match = matchPath(pathname, { path: route, exact: true });
    return match || !skip || !skip(formState);
  });

  const currentIndex = questions.findIndex((child) =>
    matchPath(pathname, { path: child.props.route, exact: true }),
  );

  const skipCurrentFn = questions[currentIndex]?.props?.skip;
  const skipCurrent = skipCurrentFn && skipCurrentFn(formState);
  const nextRoute = questions[currentIndex + 1]?.props?.route;
  const prevRoute = questions[currentIndex - 1]?.props?.route;

  useEffect(() => {
    const run = async () => {
      if (!nextState.redirectToNext) {
        return;
      }
      setNext({ ...nextState, redirectToNext: false });
      if (nextRoute) {
        nextState.replaceBrowserHistory ? replace(nextRoute) : push(nextRoute);
      } else {
        try {
          setSubmitting(true);
          await onSubmit(formState);
        } finally {
          setSubmitting(false);
        }
      }
    };
    run();
  });

  if (currentIndex === -1) {
    return <QuestionNotFound restartPath={restartPath} />;
  }

  /*
   * If we land on a question which was skipped by the answer to a previous question,
   * redirect to the next one
   */
  if (skipCurrent) {
    return <Redirect to={nextRoute} replace />;
  }

  /**
   * Checks if there are previously unanswered questions, which could
   * legitimately happen, e.g. we re-order questions and user comes back to an
   * unfinished form later, user manually enters a different URL, etc.
   */
  const previousRoutes = questions.slice(0, currentIndex);

  const unansweredQuestion = previousRoutes.find(
    (child) => formState[child.props.name] === undefined,
  );

  if (unansweredQuestion) {
    return <Redirect to={unansweredQuestion.props.route} replace />;
  }

  const { component: Component, name } = questions[currentIndex].props;

  const next = async (value, validator, options = {}) => {
    setSubmitting(true);
    const errors = validator ? await validator(value) : {};
    setSubmitting(false);
    setValidationErrors(errors);
    if (isEmpty(errors)) {
      fireAnalyticsEvent(formType, name, value);
      updateFormState({ [name]: value });
      // ensures form state updates are completed before we trigger onSubmit
      await persistor.flush();
      if (options.prefilled) {
        setNext({ redirectToNext: true, replaceBrowserHistory: true });
      } else {
        setNext({ redirectToNext: true, replaceBrowserHistory: false });
      }
    }
  };

  const BackButton = ({ onClick }: BackButtonProps) => {
    if (!prevRoute && !previousFormPath) {
      return null;
    }
    return (
      <OriginalBackButton
        prev={() => {
          setSubmitError && setSubmitError(undefined);
          if (onClick) {
            onClick();
            return;
          }
          history.push(prevRoute || previousFormPath);
        }}
      />
    );
  };

  const submitLabel = nextRoute ? "Next" : lastButtonLabel;

  return (
    <Component
      key={pathname}
      ActionButtons={createActionButtons({
        next,
        label: submitLabel,
        submitError,
        disabled: submitting,
      })}
      BackButton={BackButton}
      initialValue={formState[name]}
      next={next}
      submitError={submitError}
      updateFormState={updateFormState}
      valid={isEmpty(validationErrors)}
      validationErrors={validationErrors}
    />
  );
};
