// @flow
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { css } from "styled-components";
import { Button } from "@nested/nest/src/components/Button/Button";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const fileInputStyle = css`
  display: none;
`;

export const IconButtonStyle = css`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

type Props = {
  allowedFileFormats: Array<string>,
  maxAllowedSizeInBytes: number,
  additionalAlertMessage?: string,
  onChange: Function,
  icon?: Object,
  "data-test"?: string,
};

export const SingleFileUploadButton = ({
  allowedFileFormats,
  maxAllowedSizeInBytes,
  additionalAlertMessage = null,
  onChange,
  icon = null,
  "data-test": dataTest = "single-file-upload-button",
}: Props) => {
  const fileInputRef = useRef(null);

  const handleFileChange = ({ target: { files } }) => {
    const file = files[0];
    if (files.length !== 1) {
      return;
    }
    if (!allowedFileFormats.includes(file.type)) {
      // eslint-disable-next-line no-alert
      window.alert(
        additionalAlertMessage !== null
          ? `File format not supported. ${additionalAlertMessage}`
          : "File format not supported.",
      );
      return;
    }
    if (file.size > maxAllowedSizeInBytes) {
      // eslint-disable-next-line no-alert
      window.alert(
        additionalAlertMessage !== null
          ? `The file is too large. ${additionalAlertMessage}`
          : "The file is too large.",
      );
      return;
    }

    onChange(files[0]);
  };

  return (
    <>
      <input
        data-test={dataTest}
        type="file"
        ref={fileInputRef}
        css={fileInputStyle}
        onChange={handleFileChange}
      />
      {icon ? (
        <IconButton
          faIcon={icon}
          onClick={() => {
            fileInputRef.current?.click();
          }}
        />
      ) : (
        <Button
          buttonStyle="white"
          icon={faFileUpload}
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          Upload
        </Button>
      )}
    </>
  );
};

const IconButton = ({ faIcon, onClick }) => {
  return (
    <button css={IconButtonStyle} onClick={onClick}>
      <FontAwesomeIcon icon={faIcon} />
    </button>
  );
};
