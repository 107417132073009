// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import {
  HandoffCallButton,
  HandoffSMSButton,
  MailtoEmailButton,
} from "../../components/Comms";
import { TasksButton } from "../../components/RightSidePanel/Tasks/TasksButton";

const dealCommsButtons = css`
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;

  & > * {
    text-decoration: none;
    margin-right: 7px;
    width: 100%;
  }

  & > *:focus {
    text-decoration: none;
  }

  & > *:disabled {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.hague10};
    color: ${({ theme }) => theme.palette.hague50};
    cursor: default;
  }

  & > *:last-child {
    margin-right: 0;
  }

  ${media.tablet`
    display: none;
  `}
`;

const communicationButtonsStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 5px;
  background: transparent;
  height: 32px;
  padding: 0;
  color: ${({ theme }) => theme.palette.hague70};

  &:focus {
    background-color: transparent;
  }
`;

type SummaryButtonsProps = {
  address: string,
  contacts: DealSummaryBar_nestDeal_contacts[],
  externalId: string,
  id: string,
  taskButtonProps: {
    onClick: (viewport: string) => void,
    showTaskPanel: (viewport: string) => boolean,
    taskCount: ?number,
  },
};

export const SummaryButtons = ({
  contacts,
  externalId,
  taskButtonProps,
}: SummaryButtonsProps) => {
  return (
    <div css={dealCommsButtons}>
      <HandoffSMSButton
        css={communicationButtonsStyle}
        contacts={contacts}
        relationId={externalId}
        relationType="deal"
      />
      <MailtoEmailButton contacts={contacts} relationId={externalId}>
        <div css={communicationButtonsStyle}>
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
      </MailtoEmailButton>
      <HandoffCallButton
        contacts={contacts}
        css={communicationButtonsStyle}
        relationId={externalId}
        relationType="deal"
      />
      <TasksButton
        onClick={() => taskButtonProps.onClick("mobile")}
        selected={taskButtonProps.showTaskPanel("mobile")}
        taskCount={taskButtonProps.taskCount || 0}
      />
    </div>
  );
};
