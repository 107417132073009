import T from "prop-types";
import { compose, withProps } from "recompose";
import { path } from "ramda";

import { graphql } from "deal/utils/graphql";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";

import { CreateButton } from "components/CreateButton";
import createDebtFundingCommitmentMutation from "./createMutation/createDebtFundingCommitment.graphql";
import { createConfig } from "./createMutation/config";
import deleteDebtFundingCommitmentMutation from "./deleteMutation/deleteDebtFundingCommitment.graphql";
import { deleteConfig } from "./deleteMutation/config";
import updateDebtFundingCommitmentMutation from "./updateMutation/updateDebtFundingCommitment.graphql";
import { updateConfig } from "./updateMutation/config";

import debtFundingCommitmentsQuery from "./queries/debtFundingCommitments.graphql";
import { debtFundingCommitmentsQueryConfig } from "./queries/config";

import { DebtFundingCommitment } from "./DebtFundingCommitment";

const DebtFundingCommitmentListComponent = ({
  data: queryResult,
  deleteDebtFundingCommitment,
  createDebtFundingCommitment,
  updateDebtFundingCommitment,
  facilityOnly,
}) => {
  const debtFundingCommitments =
    path(["nestDeal", "debtFundingCommitments"], queryResult) || [];
  const debtFacilityOptions =
    path(["inputOptions", "debtFacility"], queryResult) || [];
  return (
    <ErrorBoundary>
      <div data-test="debt-funding-commitment-list">
        {debtFundingCommitments.map(
          ({
            id,
            repaymentAmount,
            repaymentDate,
            drawdownAmount,
            drawdownDate,
            debtFacilityCommittedTo,
          }) => (
            <DebtFundingCommitment
              id={id}
              key={id}
              debtFacilityOptions={debtFacilityOptions}
              repaymentAmount={repaymentAmount}
              repaymentDate={repaymentDate}
              drawdownAmount={drawdownAmount}
              drawdownDate={drawdownDate}
              debtFacilityCommittedTo={debtFacilityCommittedTo}
              deleteDebtFundingCommitment={deleteDebtFundingCommitment(id)}
              updateDebtFundingCommitment={updateDebtFundingCommitment(id)}
              facilityOnly={facilityOnly}
            />
          ),
        )}
        <CreateButton
          data-test="create-debt-funding-commitment-button"
          label="Add debt funding commitment"
          onSubmit={createDebtFundingCommitment}
        />
      </div>
    </ErrorBoundary>
  );
};

const enhance = compose(
  withProps(({ parentId }) => ({
    dealId: parentId,
  })),
  graphql(debtFundingCommitmentsQuery, debtFundingCommitmentsQueryConfig),
  graphql(createDebtFundingCommitmentMutation, createConfig),
  graphql(deleteDebtFundingCommitmentMutation, deleteConfig),
  graphql(updateDebtFundingCommitmentMutation, updateConfig),
);

DebtFundingCommitmentListComponent.propTypes = {
  facilityOnly: T.bool,
  data: T.object.isRequired,
  deleteDebtFundingCommitment: T.func.isRequired,
  createDebtFundingCommitment: T.func.isRequired,
  updateDebtFundingCommitment: T.func.isRequired,
};

export const DebtFundingCommitmentList = enhance(
  DebtFundingCommitmentListComponent,
);
