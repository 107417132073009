// @flow
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { css } from "styled-components";
import { isEmpty } from "lodash";
import { media } from "@nested/brand";
import { formatDatetimeDifference } from "@nested/utils/src/formatDate/formatDate";
import { Table, TH, BuyerTD } from "@nested/nest/src/components/Table";
import { ListViewError } from "@nested/nest/src/components/ListViewError";
import {
  tabletWrapperStyle,
  dealsLoadingStyle,
  ListViewCallButton,
} from "@nested/nest/src/components/ListViewSharedStyles";
import { MobileListViewBuyerCard } from "@nested/nest/src/components/MobileListViewBuyer";
import {
  PlaceholderTable,
  PlaceholderList,
} from "@nested/nest/src/components/Placeholder";

export const NOT_INTERESTED_QUERY = gql`
  query NotInterestedBuyers($id: ID!) {
    nestDeal(id: $id) {
      id
      notInterestedBpis {
        id
        buyer {
          id
          name
          contacts {
            id
            name
            title
            phones {
              id
              telephoneNumber
              subscribedToPropertyMatch
            }
            emails {
              id
              subscribedToPropertyMatch
            }
            avatarUrl
          }
        }
        latestViewing {
          id
          datetimeViewingStarts
        }
        falloutReason {
          id
          label
        }
      }
    }
  }
`;

const mobileWrapperStyle = css`
  ${media.tablet`
    display: none;
  `}
`;

const callButtonTDStyle = css`
  a {
    padding: 10px 0px;
  }
`;

const buyerTableStyle = css`
  ${dealsLoadingStyle};
  max-height: 450px;
  margin-top: 20px;
  padding-right: 10px;
`;

const notSubscribedStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
`;

const stickyHeaderStyle = css`
  background: white;
  box-shadow: 0 1px ${({ theme }) => theme.palette.hague20};
  top: 0;
  position: sticky;
  z-index: 1;
`;

const isSubscribed = ({ contacts }) => {
  const subscribed = contacts.find(
    ({ phones, emails }) =>
      phones.find((phone) => phone.subscribedToPropertyMatch) ||
      emails.find((email) => email.subscribedToPropertyMatch),
  );

  return subscribed;
};

export const formatFalloutReason = (falloutReason: string) =>
  falloutReason.substring(falloutReason.indexOf(" ") + 1);

const BuyerTable = ({ buyerPropertyInterests, loading }) => {
  if (loading && buyerPropertyInterests.length === 0) {
    return <PlaceholderTable />;
  }

  return (
    <div css={buyerTableStyle}>
      <Table css="margin-top: 0;">
        <thead css={stickyHeaderStyle}>
          <tr>
            <TH>Name</TH>
            <TH>Last viewing</TH>
            <TH>Fallout reason</TH>
            <TH css="padding: 0;"></TH>
          </tr>
        </thead>
        <tbody css="max-height: 450px;">
          {buyerPropertyInterests.map(
            ({ buyer, latestViewing, falloutReason }) => {
              return (
                <tr key={buyer.id} data-test={`buyer-row-${buyer.id}`}>
                  <BuyerTD buyerId={buyer.id} className="fs-exclude" narrow>
                    <div>{buyer.name}</div>
                    {!isSubscribed(buyer) && (
                      <div css={notSubscribedStyle}>
                        Not subscribed to mailouts
                      </div>
                    )}
                  </BuyerTD>
                  <BuyerTD
                    buyerId={buyer.id}
                    title={
                      latestViewing &&
                      moment(latestViewing.datetimeViewingStarts).format("LLLL")
                    }
                    narrow
                  >
                    {latestViewing
                      ? formatDatetimeDifference(
                          latestViewing.datetimeViewingStarts,
                        )
                      : "-"}
                  </BuyerTD>
                  <BuyerTD buyerId={buyer.id} narrow>
                    {formatFalloutReason(falloutReason?.label) || "-"}
                  </BuyerTD>
                  <BuyerTD buyerId={buyer.id} css={callButtonTDStyle} narrow>
                    <ListViewCallButton
                      contacts={buyer.contacts}
                      externalId={buyer.id}
                      relationType={"buyer"}
                    />
                  </BuyerTD>
                </tr>
              );
            },
          )}
        </tbody>
      </Table>
    </div>
  );
};

const buildDetail = (latestViewing, falloutReason) => {
  return (
    <>
      <div>{`Last viewed: ${
        latestViewing
          ? formatDatetimeDifference(latestViewing.datetimeViewingStarts)
          : "-"
      }`}</div>
      <div>{`Fallout reason: ${
        falloutReason ? formatFalloutReason(falloutReason?.label) : "-"
      }`}</div>
    </>
  );
};

const BuyerList = ({ buyerPropertyInterests, loading }) => {
  if (loading && buyerPropertyInterests.length === 0) {
    return <PlaceholderList />;
  }

  return (
    <div $loading={loading} css={dealsLoadingStyle}>
      {buyerPropertyInterests.map(
        ({ id, buyer, latestViewing, falloutReason }) => (
          <MobileListViewBuyerCard
            key={id}
            buyer={buyer}
            detail={buildDetail(latestViewing, falloutReason)}
            detailTitle={moment(latestViewing?.datetimeViewingStarts).format(
              "LLLL",
            )}
          />
        ),
      )}
    </div>
  );
};

export const NotInterestedList = ({
  id,
  "data-test": dataTest,
}: {
  id: string,
  "data-test"?: string,
}) => {
  const { data, error, loading } = useQuery(NOT_INTERESTED_QUERY, {
    variables: { id },
  });

  const notInterestedBpis = data?.nestDeal?.notInterestedBpis || [];

  if (error) {
    return <ListViewError />;
  }

  if (!loading && isEmpty(notInterestedBpis)) {
    return <div css="margin-top: 20px;">No not interested buyers</div>;
  }

  return (
    <>
      <div data-test={dataTest}>
        <div css={mobileWrapperStyle}>
          <BuyerList
            buyerPropertyInterests={notInterestedBpis}
            loading={loading}
          />
        </div>
        <div css={tabletWrapperStyle}>
          <BuyerTable
            buyerPropertyInterests={notInterestedBpis}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};
