// @flow
type Props = { fill?: string, width?: string };

export const CancelIcon = ({ fill = "#F14747", width = "12" }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="10"
    viewBox="0 0 12 10"
  >
    <g
      fill={fill}
      fillRule="evenodd"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1)"
    >
      <path d="M0,0 L10,10" />
      <path d="M0,0 L10,10" transform="rotate(-90 5 5)" />
    </g>
  </svg>
);
