// @flow
import styled, { css } from "styled-components";

type SharedContactFields = {
  +__typename: "Contact",
  +id: string,
  +name: ?string,
  +title: ?string,
  +avatarUrl: string,
};

export type ContactWithPhones = SharedContactFields & {
  +phones: $ReadOnlyArray<{
    +__typename: "TelephoneNumber",
    +id: string,
    +telephoneNumber: string,
  }>,
};

export type ContactWithEmails = SharedContactFields & {
  +emails: $ReadOnlyArray<{
    +__typename: "EmailAddress",
    +id: string,
    +emailAddress: string,
  }>,
};

export type PhoneButtonProps = {
  ButtonComponent?: React$ComponentType<any>,
  onCopy?: () => void,
  heading: string,
  children: React$Node,
  contacts: $ReadOnlyArray<ContactWithPhones>,
  externalId: string,
  dealId?: string,
  relationType?: string,
};

export const Button = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: transparent;
`;

export const ContactTypeLink = styled.button`
  background: none;
  border: none;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 22px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.blue150};
  padding: 0 0 10px 50px;
  width: 100%;
  cursor: pointer;
`;

export const noContactStyle = css`
  line-height: 22px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague70};
  background: none;
  border: none;
  text-align: left;
  padding: 0 0 10px 50px;
  margin: 0;
`;

const ContactCardWrapper = styled.div`
  padding: 10px 20px;
`;

const ContactInfo = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ title }) => (title ? "10px" : "0")};
`;

const Name = styled.p`
  line-height: 20px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.hague150};
  font-weight: 500;
  margin: 0;
  padding-left: 10px;
`;

const Title = styled.p`
  line-height: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague70};
  margin: 0;
  padding-left: 10px;
`;

const avatarStyle = css`
  border-radius: 50%;
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
`;

const ContactAvatar = ({ url, "data-test": dataTest }) => (
  <img alt="contact avatar" css={avatarStyle} src={url} data-test={dataTest} />
);

type ContactsWrapperProps = {
  children: React$Node,
  contact: ContactWithEmails | ContactWithPhones,
};

export const ContactsWrapper = ({
  contact,
  children,
}: ContactsWrapperProps) => {
  const { id, name, title, avatarUrl } = contact;

  return (
    <ContactCardWrapper>
      <ContactInfo title={title}>
        <ContactAvatar url={avatarUrl} data-test={`contact-avatar-${id}`} />
        <div>
          <Name className="fs-exclude">{name}</Name>
          <Title className="fs-exclude">{title}</Title>
        </div>
      </ContactInfo>
      {children}
    </ContactCardWrapper>
  );
};
