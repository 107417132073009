// @flow
import type { MutationOperation } from "@apollo/client";
import ExpectedDrawdownTimingsQuery from "../queries/expectedDrawdownTimings.graphql";

type OwnProps = {
  parentId: string,
};

type Props = {
  mutate: MutationOperation<DeleteExpectedDrawdownTimingMutation_deleteExpectedDrawdownTiming>,
  ownProps: OwnProps,
};

export const deleteExpectedDrawdownTimingConfig = {
  props: ({ mutate, ownProps }: Props) => ({
    deleteExpectedDrawdownTiming: (id: string) => () =>
      mutate({
        variables: {
          id,
        },
        refetchQueries: [
          {
            query: ExpectedDrawdownTimingsQuery,
            variables: {
              id: ownProps.parentId,
            },
          },
        ],
      }),
  }),
};
