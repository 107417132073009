// @flow
import type { ApolloError } from "@apollo/client/react/components";
import { withHandlers, type HOC } from "recompose";
import { alertErrors } from "@nested/utils/alertErrors";
import { logException } from "@nested/utils/sentry";

export function handleErrors({ networkError, graphQLErrors }: ApolloError) {
  /**
   * Contrary to what the ApolloError type says, networkError can be
   * void, which means that we need to do a check for `undefined` as well
   */
  if (networkError === null || networkError === undefined) {
    return alertErrors({
      errors: graphQLErrors,
      detail: `
      If this is an error, please refresh the page and try again.

      If the issue persists, please contact #tech-support on slack. Please include a link to this page and a brief description of what you were doing when this alert triggered.`,
    });
  }

  const { message } = networkError;

  return alertErrors({ detail: message });
}

type Props = {
  onSubmit: () => Promise<any>,
};

export function createOnSubmitWithErrors(props: Props) {
  const { onSubmit } = props;
  return function onSubmitWithErrors(...args: any) {
    const promise = onSubmit(...args);
    // if this returns undefined, we want to log an exception because it means a mutation result
    // is not being returned correctly
    if (promise === undefined) {
      const message =
        "expected a promise, but onSubmit(...args) returned undefined";
      logException(message, {
        onSubmit: onSubmit.toString(),
        props,
      });
      throw new Error(message);
    }

    return promise.catch(handleErrors);
  };
}

const withGraphQLErrorsObject = {
  onSubmit: createOnSubmitWithErrors,
};

export const withGraphqlErrors = (withHandlers(withGraphQLErrorsObject): HOC<
  *,
  *,
>);
