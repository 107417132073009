// @flow
import { NavLink } from "react-router-dom";
import { css } from "styled-components";
import { media } from "@nested/brand";
import { Placeholder } from "../Placeholder";

const tabsStyle = css`
  border-bottom: 2px solid
    ${({ theme, $noBorder }) =>
      $noBorder ? "transparent" : theme.palette.hague20};
  width: 100%;
  height: 40px;
  margin-top: 5px;
  ${media.tablet`
    border-bottom: 2px solid
    ${({ theme, $noBorderWhenNotSelected, $noBorder }) =>
      $noBorderWhenNotSelected || $noBorder
        ? "transparent"
        : theme.palette.hague20};
    width: auto;
    display: inline-block;
    margin-top: 0px;
  `}
`;

const tabsUlStyle = css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
`;

const tabStyle = css`
  &:not(:last-child) {
    margin-right: ${({ $narrow }) => ($narrow ? "20px" : "30px")};
  }

  ${media.tablet`
    &:not(:last-child) {
      margin-right: 20px;
    }
  `}

  ${media.desktop`
    &:not(:last-child) {
      margin-right: 30px;
    }
  `}
`;

const activeStyles = css`
  color: ${({ theme }) => theme.palette.blue150} !important;
  border-bottom: 4px solid ${({ theme }) => theme.palette.blue150};
  span {
    color: ${({ theme }) => theme.palette.blue100};
    opacity: 1;
  }
`;

const tabLinkStyle = css`
  display: block;
  text-decoration: none;
  padding: 0;
  border: none;
  border-bottom: 4px solid transparent;
  font-weight: 500;
  position: relative;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.hague100};
  cursor: pointer;

  &.active {
    ${activeStyles}
  }

  ${({ $active }) => $active && activeStyles}
`;

const counterStyle = css`
  opacity: 0.4;
  margin-left: 10px;
`;

type TabsProps = {
  className?: string,
  children: React$Node,
  id?: string,
  noBorderWhenNotSelected?: boolean,
  noBorder?: boolean,
};

export const Tabs = ({
  className,
  children,
  id,
  noBorderWhenNotSelected = false,
  noBorder,
}: TabsProps) => (
  <nav
    id={id}
    className={className}
    css={tabsStyle}
    $noBorderWhenNotSelected={noBorderWhenNotSelected}
    $noBorder={noBorder}
  >
    <ul css={tabsUlStyle}>{children}</ul>
  </nav>
);

type TabProps = {
  className?: string,
  count?: ?number,
  children: React$Node,
  to: string,
  exact?: boolean,
};

export const Tab = ({ className, count, children, to, exact }: TabProps) => (
  <li className={className} css={tabStyle}>
    <NavLink exact={exact} css={tabLinkStyle} to={to}>
      {children}
      {Number.isInteger(count) && <span css={counterStyle}>{count}</span>}
    </NavLink>
  </li>
);

type NestedTabProps = {
  active: boolean,
  className?: string,
  count?: ?number,
  children: React$Node,
  onClick: () => void,
  "data-test"?: string,
};

export const NestedTab = ({
  active,
  className,
  children,
  count,
  onClick,
  "data-test": dataTest,
}: NestedTabProps) => (
  <li className={className} css={tabStyle} $narrow data-test={dataTest}>
    <button $active={active} css={tabLinkStyle} onClick={onClick}>
      {children}
      <span css={counterStyle}>
        {Number.isInteger(count) ? count : <Placeholder width={15} />}
      </span>
    </button>
  </li>
);

type ButtonTabProps = {
  className?: string,
  count?: ?number,
  children: React$Node,
  onClick: () => void,
  selected: ?boolean,
};

export const ButtonTab = ({
  className,
  count,
  children,
  onClick,
  selected,
}: ButtonTabProps) => (
  <li className={className} css={tabStyle}>
    <button css={tabLinkStyle} onClick={onClick} $active={selected}>
      {children}
      {Number.isInteger(count) && <span css={counterStyle}>{count}</span>}
    </button>
  </li>
);
