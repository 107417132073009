// @flow
import { kebabCase } from "lodash";
import { type MutationFunction } from "@apollo/client/react/components";

import { AlignRight } from "components/AlignRight";
import { ListEntryCard } from "components/Card";
import { Checkbox } from "components/Checkbox/Checkbox";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { DatePicker } from "components/DatePicker";
import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { CurrencyField } from "components/CurrencyField";
import { PercentageField } from "components/PercentageField/PercentageField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import {
  SA_SUB_AGENTS_QUERY,
  SA_SUB_AGENT_UPDATE_MUTATION,
  SA_SUB_AGENT_DELETE_MUTATION,
} from "./subAgentsQueries";

export function SubAgentCard({
  subAgent,
  editing,
}: {|
  subAgent: SASubAgents_subAgents,
  editing: boolean,
|}) {
  return (
    <ListEntryCard data-test="sub-agent:list-entry-card">
      <Grid columns={2}>
        <ExtendedMutation mutation={SA_SUB_AGENT_UPDATE_MUTATION}>
          {(
            updateSASubAgent: MutationFunction<
              UpdateSASubAgent,
              UpdateSASubAgentVariables,
            >,
          ) => {
            const fieldProps = (key) => ({
              "data-test": `sub-agent:${kebabCase(key)}`,
              mutation: (input) =>
                updateSASubAgent({
                  variables: {
                    id: subAgent.id,
                    input,
                  },
                  optimisticResponse: {
                    updateSubAgent: {
                      ...subAgent,
                      ...input,
                    },
                  },
                }),
              property: key,
              value: subAgent[key],
              readOnly: !editing,
            });

            return (
              <>
                <LeafCell>
                  <TextField label="Branch" {...fieldProps("branchName")} />
                </LeafCell>
                <LeafCell>
                  <TextField
                    label="Contact name"
                    {...fieldProps("contactName")}
                    className="fs-exclude"
                  />
                </LeafCell>
                <LeafCell>
                  <TextField
                    label="Contact phone number"
                    {...fieldProps("contactNumber")}
                    className="fs-exclude"
                  />
                </LeafCell>
                <LeafCell>
                  <TextField
                    label="Contact email"
                    {...fieldProps("contactEmail")}
                    className="fs-exclude"
                  />
                </LeafCell>
                <LeafCell>
                  <PercentageField
                    label="Fee to pay (inc VAT) %"
                    {...fieldProps("feePercentIncVat")}
                  />
                </LeafCell>
                <LeafCell>
                  <CurrencyField
                    label="Negotiator bonus £"
                    {...fieldProps("negotiatorBonus")}
                  />
                </LeafCell>
                <LeafCell>
                  <TextField label="Notes" {...fieldProps("notes")} />
                </LeafCell>
                <LeafCell>
                  <Checkbox
                    label="Has agreement been signed?"
                    {...fieldProps("isAgreementSigned")}
                    parentId={subAgent.id}
                  />
                </LeafCell>
                <LeafCell>
                  <DatePicker
                    label="Date instructed"
                    {...fieldProps("dateInstructed")}
                  />
                </LeafCell>
                <LeafCell>
                  <DatePicker
                    label="Date disinstructed"
                    {...fieldProps("disinstructedDate")}
                  />
                </LeafCell>
                <LeafCell>
                  <DatePicker
                    label="Date listed online (if applicable)"
                    {...fieldProps("onlineListingDate")}
                  />
                </LeafCell>
                <LeafCell>
                  <TextField
                    label="Listing URL (if applicable)"
                    {...fieldProps("onlineListingUrl")}
                  />
                </LeafCell>
              </>
            );
          }}
        </ExtendedMutation>

        {editing && (
          <ExtendedMutation mutation={SA_SUB_AGENT_DELETE_MUTATION}>
            {(
              deleteSASubAgent: MutationFunction<
                DeleteSASubAgent,
                DeleteSASubAgentVariables,
              >,
            ) => (
              <LeafCell width={2}>
                <AlignRight>
                  <DeleteButton
                    data-test="sub-agent:delete-button"
                    onSubmit={() => {
                      if (itemDeletionConfirmed()) {
                        deleteSASubAgent({
                          variables: {
                            id: subAgent.id,
                          },
                          refetchQueries: [
                            {
                              query: SA_SUB_AGENTS_QUERY,
                              variables: {
                                dealId: subAgent.dealId,
                              },
                            },
                          ],
                        });
                      }
                    }}
                  />
                </AlignRight>
              </LeafCell>
            )}
          </ExtendedMutation>
        )}
      </Grid>
    </ListEntryCard>
  );
}
