// @flow

import { useState } from "react";
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";
import styled from "styled-components";
import { Grid, Cell } from "components/Grid";

import { Button } from "components/Buttons";
import { NoSubmitTextField } from "components/TextField";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { Add, Tick } from "@nest-ui/icons";

import { theme as comparablesTheme } from "../theme";

import { LIST_COMPARABLES_QUERY } from "./ComparableList";

export const ADD_COMPARABLES_MUTATION = gql`
  mutation AddScrapedComparables($input: AddScrapedComparablesInput!) {
    addScrapedComparables(input: $input) {
      comparables {
        addedAt
        address
        agentName
        comparabilityRating
        comparableImages {
          url
        }
        comparableLinks {
          type
          url
        }
        comparisonPoints {
          description
          rating
        }
        floorArea
        id
        internalNotes
        listingPrice
        numberOfBeds
        propertyType
        relevanceRating
        selected
        soldDate
        soldPrice
        status
        underOfferPrice
        underOfferDate
      }
      success
      validationErrors
    }
  }
`;

const StyledGrid = styled(Grid)`
  align-content: center;
  grid-column-gap: 16px;

  &.show {
    min-height: 68px;
  }
`;

const Input = styled(NoSubmitTextField)`
  textarea {
    background-color: ${({ theme }) => theme.textField.background};
    border: 2px solid ${({ theme }) => theme.textField.border};
    border-radius: 4px;
    color: ${({ theme }) => theme.textField.text};

    ::placeholder {
      opacity: 0.4;
      color: ${({ theme }) => theme.text.white};
    }

    &:focus {
      border-color: ${({ theme }) => theme.textField.borderFocus};
    }
  }
  label {
    color: ${({ theme }) => theme.text.white};
  }
`;

const StyledButton = styled(Button)`
  height: auto;
  min-height: 32px;
  width: 100%;
`;

const ButtonLabel = styled.span`
  padding-top: 1px;
  padding-left: 4px;
  padding-right: 4px;
`;

const AddWrapper = styled.div`
  display: flex;
  svg {
    height: 12px;
    width: 12px;
  }
`;

const ErrorsPanel = styled.div`
  background-color: #e24e4a;
  border: 2px solid #a42a2a;
  border-radius: 4px;
  color: white;
  padding: 16px;

  p {
    margin: 0;
  }

  ul {
    margin-top: 0;
    padding-left: 8px;
  }
`;

type UpdateCacheFn = (
  ComparableReferenceInput | null,
  string,
  string,
) => ({ [data: string]: any }, { [data: string]: any }) => any;

// This allows us to update the LIST_COMPARABLES_QUERY without having to
// refetch it, which would do a shitty refresh of the whole page panel.
//
// WARNING: This doesn't preserve ordering; we may need to change
// `addScrapedComparables` to take sort options and returns all comparables.
// Then just overwrite the cache completely instead of spreading over it.
// Alternatively; a subscription!
//
export const defaultUpdateCache: UpdateCacheFn =
  (reference, sortBy, sortOrder) =>
  (
    store,
    {
      data: {
        addScrapedComparables: { comparables },
      },
    },
  ) => {
    const data = store.readQuery({
      query: LIST_COMPARABLES_QUERY,
      variables: { reference, sortBy, sortOrder },
    });

    store.writeQuery({
      query: LIST_COMPARABLES_QUERY,
      variables: { reference, sortBy, sortOrder },
      data: { ...data, comparables: data.comparables.concat(comparables) },
    });
  };

const areas = ["input input input addButton", "errors errors errors errors"];

type Props = {
  className: string,
  reference: ComparableReferenceInput,
  sortBy: SortBy,
  sortOrder: SortOrder,
  switchToManual: () => void,
  updateCache: UpdateCacheFn,
};

export const CSVInput = ({
  className,
  reference,
  sortBy,
  sortOrder,
  updateCache,
  switchToManual,
}: Props) => {
  const [csv, setCsv] = useState("");
  const [errors, setErrors] = useState([]);
  const [focused, setFocused] = useState(false);

  return (
    <StyledGrid
      className={className}
      columns={4}
      areas={errors.length === 0 ? areas.slice(0, 1) : areas}
    >
      <Cell area="input">
        <Input
          data-test="comparables/csv-input:input"
          label="Comp Plugin Output"
          maxRows={2}
          minRows={2}
          multiline
          placeholder="Paste output from Comp Browser Plugin here!"
          onSubmit={(val) => setCsv(val)}
          value={csv}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </Cell>

      <Cell area="addButton">
        <ExtendedMutation
          mutation={ADD_COMPARABLES_MUTATION}
          update={updateCache(reference, sortBy, sortOrder)}
          onCompleted={({ addScrapedComparables }) => {
            if (!addScrapedComparables) return;

            const { success, validationErrors } = addScrapedComparables;
            if (success) {
              setCsv("");
            } else if (validationErrors) {
              setErrors(validationErrors);
            }
          }}
        >
          {(
            mutation: MutationFunction<
              AddScrapedComparables,
              AddScrapedComparablesVariables,
            >,
          ) => (
            <>
              {csv || focused ? (
                <StyledButton
                  data-test="comparables/csv-input:submit"
                  onClick={() => {
                    setErrors([]);
                    mutation({
                      variables: {
                        input: {
                          reference,
                          rawComparables: csv,
                        },
                      },
                    });
                  }}
                >
                  <Tick fill={comparablesTheme.text.white} />
                  <ButtonLabel>Add Comps</ButtonLabel>
                </StyledButton>
              ) : (
                <StyledButton
                  data-test="comparables/csv-input:submit"
                  onClick={() => {
                    setErrors([]);
                    switchToManual();
                  }}
                >
                  <AddWrapper>
                    <Add fill={comparablesTheme.text.white} />
                  </AddWrapper>
                  <ButtonLabel>Manually add comp</ButtonLabel>
                </StyledButton>
              )}
            </>
          )}
        </ExtendedMutation>
      </Cell>
      {errors.length > 0 && (
        <Cell area="errors">
          <ErrorsPanel>
            <ul>
              {errors.map((error, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{error}</li>
              ))}
            </ul>
            <p>
              Please either enter{" "}
              {errors.length === 1 ? "this comp" : "these comps"} manually or
              raise the {errors.length === 1 ? "error" : "errors"} with the tech
              team.
            </p>
          </ErrorsPanel>
        </Cell>
      )}
    </StyledGrid>
  );
};

CSVInput.defaultProps = {
  updateCache: defaultUpdateCache,
};
