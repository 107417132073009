// @flow

const relationTypes = {
  attempted_seller_contact_events: "seller_call",
  attempted_buyer_contact_events: "buyer_call",
  snoozes: "snooze",
  reached_milestones: "reached_milestone",
};
// Notes can belong to either deal, buyer, attempted_buyer/seller_contact_event, milestone or a snooze.
// For notes which do belong to either deal or a buyer, we use noteRelationType and relationId props.
// For notes which do not belong to either deal or a buyer directly, we use this function to infer correct noteRelationType based on sourceTable
export const inferNoteRelationType = (sourceTable: string) => {
  return relationTypes[sourceTable];
};
