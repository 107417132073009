// @flow

import { useState } from "react";
import { gql } from "@apollo/client";
import { css } from "styled-components";

import { useQuery } from "@apollo/client/react/hooks";
import { Loader } from "@nest-ui/sellers-nest/components/Loader/Loader";
import { errorHandler } from "@nested/utils/graphql/errorHandler";
import { Button } from "@nested/nest/src/components/Button/Button";
import { ModalHeader } from "@nest-ui/sellers-nest/tabs/Interest/PotentialBuyers/MailoutModal/ComposeMessage";

import { SimilarPropertyCard } from "./SimilarPropertyCard";
import { EmailBuilder } from "./EmailBuilder";

export const SHORT_PROPERTY_SUMMARY = gql`
  query PropertyMatchSummary($id: ID!) {
    buyer(id: $id) {
      id
      potentialProperties {
        id
        externalId
        address
        saExtendedSummary
        nestedListing
        currentListPrice
        property {
          id
          street
          outcode
        }
      }
      contacts {
        id
        phones {
          id
          telephoneNumber
          subscribedToPropertyMatch
        }
        emails {
          id
          emailAddress
          subscribedToPropertyMatch
        }
      }
    }
  }
`;

const wrapperStyle = css`
  overflow: auto;
  height: calc(100% - 128px);
  padding: 10px;
`;

const footerStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 62px;
  background-color: white;
  padding: 0 20px 20px;
  text-align: center;
`;

const selectedCountStyles = css`
  font-size: 14px;
`;

const emailSubjectPrefill = (selectedProperties) => {
  if (selectedProperties.length === 1) {
    const {
      property: { street, outcode },
    } = selectedProperties[0];

    if (street && outcode)
      return `🏡💡 A property you may be interested in - ${street}, ${outcode}`;
  }

  return "🏡💡 Properties you may be interested in";
};

const emailBodyPrefill = (selectedProperties) => {
  const propertyOrProperties =
    selectedProperties.length > 1 ? "properties" : "property";

  return `I thought the ${propertyOrProperties} below might be of interest.`;
};

type Props = {
  buyer: PropertyInterestsByBuyer_buyer,
  closeModal: () => void,
};

export const SimilarPropertiesList = ({ buyer, closeModal }: Props) => {
  const [checkedState, setCheckedState] = useState({});
  const [showEmailPreview, setShowEmailPreview] = useState(false);

  const selectedProperties = Object.keys(checkedState)
    .filter((key) => checkedState[key])
    .map((key) => checkedState[key]);
  const selectedPropertiesCount = selectedProperties.length;

  const { loading, error, data } = useQuery(SHORT_PROPERTY_SUMMARY, {
    variables: {
      id: buyer.id,
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  if (error) {
    errorHandler(error);
    return null;
  }

  if (loading) return <Loader />;

  const {
    buyer: { potentialProperties: properties, contacts },
  } = data;

  const subscribedPhoneNumers = contacts.flatMap((contact) =>
    contact.phones.filter((phone) => phone.subscribedToPropertyMatch),
  );

  const subscribedEmailAddresses = contacts.flatMap((contact) =>
    contact.emails.filter((email) => email.subscribedToPropertyMatch),
  );

  const subscribedPhonesAndEmails = subscribedPhoneNumers.concat(
    subscribedEmailAddresses,
  );

  const handleClick = () => {
    if (subscribedPhonesAndEmails.length === 0) {
      // eslint-disable-next-line no-alert
      window.alert(
        "This buyer doesn't have a phone number or email address, or their phone numbers and email addresses are unsubscribed from Property Match. Please check the buyer contact preferences before trying again.",
      );
      closeModal();
    } else {
      setShowEmailPreview(true);
    }
  };

  const onCheckboxChange = (property) => {
    // Unchecking
    if (checkedState[property.id]) {
      setCheckedState({ ...checkedState, [property.id]: false });
      return;
    }
    // Checking
    if (selectedPropertiesCount >= 5) {
      // eslint-disable-next-line no-alert
      window.alert(
        "Sorry, there is a maximum of 5 properties per cross sell communication!",
      );
      return;
    }
    setCheckedState({ ...checkedState, [property.id]: property });
  };

  const isLastProperty = properties.length === 1;
  const emailSubject = emailSubjectPrefill(selectedProperties);
  const emailBody = emailBodyPrefill(selectedProperties);

  if (showEmailPreview)
    return (
      <EmailBuilder
        onBack={() => setShowEmailPreview(false)}
        onCancel={() => {
          setShowEmailPreview(false);
          closeModal();
        }}
        emailSubject={emailSubject}
        emailBody={emailBody}
        buyer={buyer}
        selectedProperties={selectedProperties}
      />
    );

  return (
    <>
      <ModalHeader title="Similar properties" onCancel={closeModal} />
      <div css={wrapperStyle}>
        <p
          css={selectedCountStyles}
          data-test="selected-for-cross-sell-counter"
        >
          <span>Selected for cross sell email: </span>
          <span css="font-weight: 500;">
            {selectedPropertiesCount ? `${selectedPropertiesCount} / 5` : "0"}
          </span>
        </p>
        {properties.map((property) => (
          <SimilarPropertyCard
            key={property.id}
            checkboxValue={Boolean(checkedState[property.id])}
            checkboxChangeCallback={() => onCheckboxChange(property)}
            potentialProperty={property}
            buyerId={buyer.id}
            closeModal={closeModal}
            isLastProperty={isLastProperty}
          />
        ))}
      </div>
      <div css={footerStyle}>
        <Button
          buttonStyle="pink"
          onClick={() => handleClick()}
          disabled={selectedProperties.length === 0}
          data-test="create-email-button"
          large
          css="margin-left: auto;"
        >
          Create Email & SMS
        </Button>
      </div>
    </>
  );
};
