// @flow
import { DatePicker } from "components/DatePicker";
import { graphql } from "deal/utils/graphql";

import { LeafCell } from "components/Grid";

import { updateDealTypeAdvanceJul2018Config } from "./mutations/updateDealTypeAdvanceJul2018Config";
import UpdateDealTypeAdvanceJul2018Mutation from "./mutations/UpdateDealTypeAdvanceJul2018Mutation.graphql";

type Props = {|
  nestDeal: {
    dealTypeDetails: DealTypeAdvanceJul2018GlobalFragment,
  },
  updateDealTypeAdvanceJul2018: () => Promise<UpdateDealTypeAdvanceJul2018>,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
|};

export const AdvanceJul2018Component = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeAdvanceJul2018,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            readOnly={readOnly}
            mutation={updateDealTypeAdvanceJul2018}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            readOnly={readOnly}
            mutation={updateDealTypeAdvanceJul2018}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}

    <LeafCell width={1}>
      <DatePicker
        label="Projected DD period end date"
        value={dealTypeDetails?.coolingOffPeriodEndDate}
        property="coolingOffPeriodEndDate"
        readOnly
        data-test="cooling-off-period-end-date"
      />
    </LeafCell>
    <LeafCell width={1}>
      <DatePicker
        mutation={updateDealTypeAdvanceJul2018}
        label="Projected first drawdown date"
        value={dealTypeDetails?.projectedFirstDrawdownDate}
        property="projectedFirstDrawdownDate"
        data-test="projected-first-drawdown-date"
        readOnly={readOnly}
      />
    </LeafCell>
  </>
);

export const AdvanceJul2018 = graphql(
  UpdateDealTypeAdvanceJul2018Mutation,
  updateDealTypeAdvanceJul2018Config,
)(AdvanceJul2018Component);
