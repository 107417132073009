// @flow
import { LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import { updateDealTypeAgencyConfig } from "./mutations/updateDealTypeAgencyConfig";
import UpdateDealTypeAgencyMutation from "./mutations/UpdateDealTypeAgencyMutation.graphql";

type Props = {
  nestDeal: {
    dealTypeDetails: DealTypeAgencyGlobalFragment,
  },
  hideContractSignedDates?: boolean,
  readOnly?: boolean,
  updateDealTypeAgency: () => Promise<UpdateDealTypeAgencyDrawDown>,
};

export const AgencyComponent = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeAgency,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            mutation={updateDealTypeAgency}
            readOnly={readOnly}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            mutation={updateDealTypeAgency}
            readOnly={readOnly}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}
  </>
);

export const Agency = compose(
  graphql(UpdateDealTypeAgencyMutation, updateDealTypeAgencyConfig),
)(AgencyComponent);
