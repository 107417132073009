// @flow
import { gql } from "@apollo/client";
import {
  Mutation,
  type MutationFunction,
} from "@apollo/client/react/components";
import { useMutation } from "@apollo/client/react/hooks";
import styled from "styled-components";

import { DeleteButtonWithIcon } from "@nest-ui/sellers-nest/components/DeleteButton/DeleteButton";
import { device } from "@nest-ui/styles";

import { PropertySummary } from "./PropertySummary";
import { Viewings } from "./Viewings/Viewings";
import { BUYER_FRAGMENT } from "../BuyerFragment";
import { EnquiryComments } from "./EnquiryComments";
import { Offers } from "./Offers";

import { PROPERTY_INTERESTS_QUERY } from "../PropertyInterests";
import { BUYER_PROPERTY_INTEREST_FRAGMENT } from "../BuyerPropertyInterestFragment";

const DELETE_BUYER_PROPERTY_INTEREST = gql`
  mutation DeleteBuyerPropertyInterest($id: ID!) {
    deleteBuyerPropertyInterest(id: $id) {
      id
      ...buyerFields
    }
  }

  ${BUYER_FRAGMENT}
`;

export const UPDATE_BUYER_PROPERTY_INTEREST = gql`
  mutation UpdateBuyerPropertyInterest(
    $id: ID!
    $input: UpdateBuyerPropertyInterestInput!
  ) {
    updateBuyerPropertyInterest(id: $id, input: $input) {
      id
      ...buyerPropertyInterestFields
    }
  }

  ${BUYER_PROPERTY_INTEREST_FRAGMENT}
`;

const API_USER_ID = "3";

const Contents = styled.div`
  align-items: flex-end;
  align-self: stretch;
  background-color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 0;
  box-shadow: 0 2px 8px 0 rgba(53, 63, 82, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const PropertyInfoContainer = styled.div`
  background-color: white;
  border-right: solid 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 8px 0 rgba(53, 63, 82, 0.1);
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  min-width: 240px;
  width: 240px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  @media ${device.desktop} {
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
`;

const PropertyInterest = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 16px 16px;
  max-width: 960px;
  padding: 0;
  width: auto;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const ViewingsAndOffers = styled.div`
  width: 100%;
`;

const DeleteButtonWithPadding = styled(DeleteButtonWithIcon)`
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 0;
  width: 16px;
`;

type BuyerPropertyInterestCardProps = {|
  activeNestedUsers: $ReadOnlyArray<PropertyInterestsByBuyer_activeNestedUsers>,
  buyerId: string,
  "data-test"?: string,
  buyerPropertyInterest: PropertyInterestsByBuyer_buyer_buyerPropertyInterests,
  buyerPropertyInterestStatuses: $ReadOnlyArray<PropertyInterestsByBuyer_buyerPropertyInterestStatuses>,
  buyerIsOnTheMarket?: boolean,
  isSchedulingDisabled?: boolean,
  leadSa: ?PropertyInterestsByBuyer_buyer_leadSaUser,
  viewingCancellationReasons: $ReadOnlyArray<PropertyInterestsByBuyer_viewingCancellationReasons>,
  viewingAssistants: $ReadOnlyArray<PropertyInterestsByBuyer_viewingAssistants>,
|};

export const BuyerPropertyInterestCard = ({
  activeNestedUsers,
  buyerId,
  buyerPropertyInterest,
  buyerPropertyInterestStatuses,
  buyerIsOnTheMarket,
  isSchedulingDisabled,
  leadSa,
  viewingCancellationReasons,
  viewingAssistants,
}: BuyerPropertyInterestCardProps) => {
  const associatedSas = {
    buyerLeadSa: leadSa,
    buyerPropertyInterestSa: activeNestedUsers.find(
      (user) => user.id === buyerPropertyInterest.assignedSa?.id,
    ),
    currentlyResponsibleSaUser:
      buyerPropertyInterest.deal.currentlyResponsibleSaUser,
    vendorLeadSa: buyerPropertyInterest.deal.vendorLeadSa,
  };

  const [updateBuyerPropertyInterest] = useMutation(
    UPDATE_BUYER_PROPERTY_INTEREST,
    {
      refetchQueries: [
        { query: PROPERTY_INTERESTS_QUERY, variables: { buyerId } },
      ],
    },
  );

  const changeBuyerPropertyInterestSa = (newSaId) => {
    const { fullName } =
      activeNestedUsers.find((user) => user.id === newSaId) || {};
    updateBuyerPropertyInterest({
      variables: {
        id: buyerPropertyInterest.id,
        input: {
          assignedSaId: newSaId,
        },
      },
      optimisticResponse: {
        __typename: "Mutation",
        updateBuyerPropertyInterest: {
          __typename: "BuyerPropertyInterest",
          ...buyerPropertyInterest,
          assignedSa: {
            __typename: "NestedUser",
            id: newSaId,
            fullName,
          },
        },
      },
    });
  };

  const hasAssignedSa =
    (buyerPropertyInterest.assignedSa?.id &&
      buyerPropertyInterest.assignedSa.id !== API_USER_ID) ||
    false;

  return (
    <PropertyInterest>
      <PropertyInfoContainer>
        <PropertySummary
          activeNestedUsers={activeNestedUsers}
          buyerPropertyInterest={buyerPropertyInterest}
          buyerPropertyInterestStatuses={buyerPropertyInterestStatuses}
          changeBuyerPropertyInterestSa={changeBuyerPropertyInterestSa}
          hasAssignedSa={hasAssignedSa}
        />
      </PropertyInfoContainer>
      <Contents>
        <ViewingsAndOffers>
          <EnquiryComments enquiries={buyerPropertyInterest.buyerEnquiries} />

          <Viewings
            activeNestedUsers={activeNestedUsers}
            associatedSas={associatedSas}
            buyerId={buyerId}
            buyerContacts={buyerPropertyInterest.buyer.contacts}
            buyerPropertyInterestId={buyerPropertyInterest.id}
            changeBuyerPropertyInterestSa={changeBuyerPropertyInterestSa}
            dealId={buyerPropertyInterest.deal.id}
            hasAssignedSa={hasAssignedSa}
            buyerIsOnTheMarket={buyerIsOnTheMarket || false}
            isSchedulingDisabled={isSchedulingDisabled}
            propertyAccessArrangements={
              buyerPropertyInterest.deal.propertyAccessArrangements
            }
            subAgents={buyerPropertyInterest.deal.subAgents}
            vendorContacts={buyerPropertyInterest.deal.contacts}
            viewings={buyerPropertyInterest.viewings}
            viewingCancellationReasons={viewingCancellationReasons}
            viewingAssistants={viewingAssistants}
          />
          <Offers
            buyerId={buyerId}
            buyerPropertyInterestId={buyerPropertyInterest.id}
            buyerOffers={buyerPropertyInterest.buyerOffers}
          />
        </ViewingsAndOffers>

        {buyerPropertyInterest.viewings.length === 0 && (
          <Mutation mutation={DELETE_BUYER_PROPERTY_INTEREST}>
            {(
              mutation: MutationFunction<
                DeleteBuyerPropertyInterest,
                DeleteBuyerPropertyInterestVariables,
              >,
            ) => (
              <DeleteButtonWithPadding
                data-test="delete-buyer-property-interest"
                onSubmit={() => {
                  const confirmationMessage =
                    "You should ONLY delete a PropertyInterest if it was added by mistake. If the buyer is no longer interested or just not responding, you should instead set 'Currently interested?' to NO on the Property Interest." +
                    "\n\nAre you sure you want to delete this Property Interest?";
                  // eslint-disable-next-line no-alert
                  const confirmation = window.confirm(confirmationMessage);
                  if (!confirmation) {
                    return;
                  }
                  mutation({
                    variables: { id: buyerPropertyInterest.id },
                  });
                }}
              />
            )}
          </Mutation>
        )}
      </Contents>
    </PropertyInterest>
  );
};
