// @flow
import { compose, withProps } from "recompose";
import { type MutationFunc } from "@apollo/client/react/components";

import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { withGraphQLQuery } from "deal/hocs/withGraphQLQuery";
import { graphql } from "deal/utils/graphql";

import { DealDetailsBar } from "components/DealDetailsBar";

import { TeamNotes } from "./TeamNotes/TeamNotes";
import { CustomerInformation } from "./CustomerInformation";
import { PropertyInformation } from "./PropertyInformation";
import { Marketing } from "./Marketing";

import DealDetailsQuery from "./DealDetailsQuery.graphql";
import UpdateDealDetailsMutation from "./UpdateDealDetailsMutation.graphql";
import { updateDealDetailsConfig } from "./updateDealDetailsConfig";

type Props = {
  data: {
    nestDeal: VATabDealDetails_nestDeal,
  },
  className?: string,
  mutations: {
    updateNestDeal: MutationFunc<
      UpdateNestDealVADealDetails,
      UpdateNestDealVADealDetailsVariables,
    >,
  },
};

const enhance = compose(
  withGraphQLQuery(DealDetailsQuery),
  graphql(UpdateDealDetailsMutation, updateDealDetailsConfig),
  withProps(({ updateNestDeal }) => ({
    mutations: { updateNestDeal },
  })),
);

const DealDetails = enhance(
  ({ mutations, data: { nestDeal }, className }: Props) => (
    <ErrorBoundary>
      <DealDetailsBar className={className}>
        <CustomerInformation nestDeal={nestDeal} />
        <PropertyInformation nestDeal={nestDeal} mutations={mutations} />
        <Marketing nestDeal={nestDeal} mutations={mutations} />
        <TeamNotes dealId={nestDeal?.id} />
      </DealDetailsBar>
    </ErrorBoundary>
  ),
);

export { DealDetails };
