// @flow
import { css } from "styled-components";
import { Button } from "../../../../components/Button/Button";

const buttonStyle = css`
  height: 32px;
  width: auto;
  margin-left: 10px;
`;

type Props = {
  recommended?: boolean,
  disabled?: boolean,
  openMessageModal: () => void,
};

export const EmailSmsButton = ({
  recommended,
  disabled,
  openMessageModal,
}: Props) => {
  return (
    <>
      <Button
        data-test="email-sms-button"
        css={buttonStyle}
        disabled={disabled}
        buttonStyle={recommended ? "pink" : "white"}
        onClick={() => openMessageModal()}
      >
        Email/SMS
      </Button>
    </>
  );
};
