import { path } from "ramda";

export const updateDealTypeLegacyGuaranteeConfig = {
  props: ({ ownProps: { data }, mutate }) => {
    const dealTypeData = path(["nestDeal", "dealTypeLegacyGuarantee"], data);
    return {
      updateDealTypeLegacyGuarantee: (input) =>
        mutate({
          variables: { input, id: dealTypeData.id },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeLegacyGuarantee: {
              __typename: "DealTypeLegacyGuarantee",
              ...dealTypeData,
              ...input,
            },
          },
        }),
    };
  },
};
