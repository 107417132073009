// @flow
import styled, { css } from "styled-components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { PlaceholderList } from "../../../components/Placeholder";
import { Toggle } from "../../../components/Toggle/Toggle";

const PROPERTY_MATCH = "PROPERTY_MATCH";

const contactWrapper = css`
  padding: 20px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.hague70};
  ${media.tablet`
    padding: 0;
  `}
`;

const tableHeading = css`
  color: ${({ theme }) => theme.palette.hague};
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  p {
    margin: 0;
  }
`;

const contactCard = css`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const ContactDetailText = styled.p`
  flex-grow: 1;
  margin: 0;
  font-weight: 400;
  max-width: calc(100% - 24px - 67px);
  word-break: break-all;
`;

const toggleStyles = css`
  min-width: unset;
  margin-left: 5px;
`;

const ContactName = styled.p`
  margin-top: 10px 0 0;
  color: ${({ theme }) => theme.palette.hague};
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const iconStyles = css`
  margin-right: 10px;
`;

export const CONTACTS_QUERY = gql`
  query BuyerContactPreferences($id: ID!) {
    buyer(id: $id) {
      id
      contacts {
        id
        firstName
        lastName
        emails {
          id
          emailAddress
          subscribedToPropertyMatch
        }
        phones {
          id
          telephoneNumber
          subscribedToPropertyMatch
        }
      }
    }
  }
`;

export const SUBSCRIBED_TO_PROPERTY_MATCH_EMAIL_MUTATION = gql`
  mutation ChangeSubscriptionStatus(
    $emailAddressId: ID!
    $subscribed: Boolean!
    $emailSubscribedTo: EmailSubscriptions!
  ) {
    changeSubscriptionStatus(
      emailAddressId: $emailAddressId
      subscribed: $subscribed
      emailSubscribedTo: $emailSubscribedTo
    ) {
      id
      subscribedToPropertyMatch
    }
  }
`;

export const SUBSCRIBED_TO_PROPERTY_MATCH_SMS_MUTATION = gql`
  mutation ChangeSmsSubscriptionStatus(
    $telephoneNumberId: ID!
    $subscribed: Boolean!
    $alertSubscribedTo: EmailSubscriptions!
  ) {
    changeSmsSubscriptionStatus(
      telephoneNumberId: $telephoneNumberId
      subscribed: $subscribed
      alertSubscribedTo: $alertSubscribedTo
    ) {
      id
      subscribedToPropertyMatch
    }
  }
`;

type ContactCardProps = {
  emails: $ReadOnlyArray<BuyerContactPreferences_buyer_contacts_emails>,
  phones: $ReadOnlyArray<BuyerContactPreferences_buyer_contacts_phones>,
  name: ?string,
  buyerId: string | number,
};

const EmailSubscriptionToggle = ({
  buyerId,
  email,
  subscribedToPropertyMatch,
}) => {
  const [changeSubscription] = useMutation(
    SUBSCRIBED_TO_PROPERTY_MATCH_EMAIL_MUTATION,
    {
      refetchQueries: [
        {
          query: CONTACTS_QUERY,
          variables: { id: buyerId },
        },
      ],
    },
  );

  return (
    <Toggle
      data-test={`property-match-email-subscription-switch-${email.id}`}
      checked={Boolean(subscribedToPropertyMatch)}
      css={toggleStyles}
      onChange={() => {
        changeSubscription({
          variables: {
            emailAddressId: email.id,
            subscribed: !subscribedToPropertyMatch,
            emailSubscribedTo: PROPERTY_MATCH,
          },
          optimisticResponse: {
            changeSubscriptionStatus: {
              __typename: "EmailAddress",
              ...email,
              subscribedToPropertyMatch: !subscribedToPropertyMatch,
            },
          },
        });
      }}
    />
  );
};

const PhoneSubscriptionToggle = ({
  buyerId,
  phone,
  subscribedToPropertyMatch,
}) => {
  const [changeSubscription] = useMutation(
    SUBSCRIBED_TO_PROPERTY_MATCH_SMS_MUTATION,
    {
      refetchQueries: [
        {
          query: CONTACTS_QUERY,
          variables: { id: buyerId },
        },
      ],
    },
  );

  return (
    <Toggle
      css={toggleStyles}
      data-test={`property-match-subscription-switch-${phone.id}`}
      checked={Boolean(subscribedToPropertyMatch)}
      onChange={() => {
        changeSubscription({
          variables: {
            telephoneNumberId: phone.id,
            subscribed: !subscribedToPropertyMatch,
            alertSubscribedTo: PROPERTY_MATCH,
          },
          optimisticResponse: {
            changeSmsSubscriptionStatus: {
              __typename: "TelephoneNumber",
              ...phone,
              subscribedToPropertyMatch: !subscribedToPropertyMatch,
            },
          },
        });
      }}
    />
  );
};

const ContactCard = ({ name, emails, phones, buyerId }: ContactCardProps) => {
  return (
    <div css={contactCard}>
      <ContactName className="fs-exclude">{name}</ContactName>
      {emails.map((email, emailIndex) => {
        const { emailAddress, subscribedToPropertyMatch, id } = email;
        return (
          <ContactRow key={`email-${emailIndex}`}>
            <FontAwesomeIcon icon={faEnvelope} css={iconStyles} />
            <ContactDetailText
              data-test={`contact-email-${id}`}
              className="fs-exclude"
            >
              {emailAddress}
            </ContactDetailText>
            <EmailSubscriptionToggle
              buyerId={buyerId}
              email={email}
              subscribedToPropertyMatch={subscribedToPropertyMatch}
            />
          </ContactRow>
        );
      })}
      {phones.map((phone, phoneIndex) => {
        const { telephoneNumber, subscribedToPropertyMatch, id } = phone;
        return (
          <ContactRow key={`phone-${phoneIndex}`}>
            <FontAwesomeIcon icon={faPhone} css={iconStyles} />
            <ContactDetailText
              data-test={`contact-phone-${id}`}
              className="fs-exclude"
            >
              {telephoneNumber}
            </ContactDetailText>
            <PhoneSubscriptionToggle
              buyerId={buyerId}
              phone={phone}
              subscribedToPropertyMatch={subscribedToPropertyMatch}
            />
          </ContactRow>
        );
      })}
    </div>
  );
};

type Props = {
  buyerId: string | number,
};

export const ContactPreferencesTab = ({ buyerId }: Props) => {
  const { data, loading } = useQuery(CONTACTS_QUERY, {
    variables: { id: buyerId },
  });

  const contacts = data?.buyer?.contacts || [];

  return (
    <div css={contactWrapper}>
      <div css={tableHeading}>
        <p>Contact</p>
        <p>Subscribed to property match?</p>
      </div>
      {loading && <PlaceholderList />}
      {contacts.map(({ firstName, lastName, emails, phones }, index) => (
        <ContactCard
          key={index}
          emails={emails}
          phones={phones}
          name={`${firstName || ""} ${lastName || ""}`}
          buyerId={buyerId}
        />
      ))}
    </div>
  );
};
