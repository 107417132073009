// @flow
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { media } from "@nested/brand";
import { formatDateDifference } from "@nested/utils/src/formatDate/formatDate";

import { type toggleCompleteTaskType } from "../../hooks/useTaskCompletion";
import { CircularCheckbox } from "../../components/Checkbox/CircularCheckbox";
import { NurtureHotnessImage } from "../../components/NurtureHotnessImage";
import { HandoffCallButton } from "../../components/Comms";
import { prettyFormat } from "../Portfolio/utils";

import { type AgentTaskWithReference } from "./Tasks";

const getName = ({ reference }: AgentTaskWithReference): string => {
  if (reference.__typename === "Buyer") {
    return reference.name;
  }
  if (reference.firstName && reference.lastName) {
    return `${reference.firstName} ${reference.lastName}`;
  }
  return reference.ownerName || "";
};

const getDealOrBuyerLink = ({ reference }: AgentTaskWithReference): string => {
  if (reference.__typename === "Buyer") {
    return `/buyers/${reference.id}?showTasks=true`;
  }
  return `/deals/${reference.externalId}?showTasks=true`;
};

const Row = styled.div`
  display: flex;
  padding-top: 15px;

  ${media.tablet`
    padding-top: 0;
  `}
`;

const rowLinkStyles = css`
  width: 100%;
  position: relative;

  ${media.tablet`
    display: flex;
  `}
`;

export const RowLink = ({
  task,
  children,
  className,
}: {
  task: AgentTaskWithReference,
  children: any,
  className?: string,
}) => (
  <Link
    aria-label={`Link to ${
      task.reference.__typename === "Buyer" ? "buyer" : "deal"
    }`}
    to={getDealOrBuyerLink(task)}
    css={rowLinkStyles}
    className={className}
  >
    {children}
  </Link>
);

const CheckboxWrapper = styled.div`
  padding-right: 15px;

  ${media.tablet`
    align-self: center;
    padding: 0;
    width: 30px;
  `}
`;

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.hague20};
  display: flex;
  padding-bottom: 10px;
  width: 100%;

  color: ${({ taskCompleted, theme }) =>
    taskCompleted ? theme.palette.hague40 : theme.palette.hague150};

  ${media.tablet`
    line-height: 50px;
    padding-bottom: 0;
    position: relative;
  `}
`;

const Name = styled.div`
  font-weight: 500;
  line-height: 18px;

  ${media.tablet`
    align-self: center;
    font-weight: 400;
    padding-right: 10px;
  `}
`;

const Description = styled.div`
  line-height: 18px;
  padding-right: 40px;

  ${media.tablet`
    align-self: center;
    padding: 16px 20px 16px 0;
    width: 50%;
  `}
`;

const DueDate = styled.div`
  line-height: 18px;
  color: ${({ taskCompleted, theme }) => {
    if (taskCompleted) return theme.palette.hague40;
    return theme.palette.hague70;
  }};

  ${({ mobile }) => (mobile ? "display: block;" : "display: none;")}

  ${media.tablet`
    ${({ mobile }) => (mobile ? "display: none;" : "display: block;")}
    align-self: center;
    width: 15%;
  `}
`;

const nurtureHotnessStyle = css`
  display: ${({ $mobile }) => ($mobile ? "block" : "none")};
  font-size: large;

  ${media.tablet`
    display: ${({ $mobile }) => ($mobile ? "none" : "block")};
    width: 5%;
    text-align: center;
  `}
`;

const nameAndNurtureHotnessMobile = css`
  display: flex;
  gap: 10px;

  ${media.tablet`
    width: 25%;
  `}
`;

const callIconStyle = css`
  position: absolute;
  right: 10px;
  bottom: 0;
  button {
    border: 2px solid ${({ theme }) => theme.palette.hague20};
    border-radius: 10px;
    margin: auto;
    width: 30px;
  }

  ${media.tablet`
    align-self: center;
    width: 5%;
    position: unset;
  `}
`;

type TaskListRowProps = {
  className: string,
  completedSection: boolean,
  isLastTask: boolean,
  item: AgentTaskWithReference,
  toggleCompleteTask: toggleCompleteTaskType,
};

export const TaskListRow = ({
  className,
  completedSection,
  isLastTask,
  item: task,
  toggleCompleteTask,
}: TaskListRowProps) => {
  const taskIsComplete = Boolean(task.completedAt);

  // Mobile list has no "Due" header, so add the word in for context, and
  // show/hide based on whether we're mobile or not
  const notMobileDate = formatDateDifference(task.dueAt);
  const mobileDate = `Due ${notMobileDate.toLowerCase()}`;
  const dateForHover = prettyFormat(task.dueAt);
  const nurtureHotness =
    task.reference.__typename === "NestDeal" && !task.reference.active
      ? task.reference.nurtureHotness
      : null;

  return (
    <Row className={className} data-test={`task-row-${task.id}`} key={task.id}>
      <CheckboxWrapper onKeyPress={() => toggleCompleteTask(task, isLastTask)}>
        <CircularCheckbox
          checked={taskIsComplete}
          data-test={`task-list-checkbox`}
          id={`${completedSection ? "completed" : "uncompleted"}-${task.id}`}
          onChange={() => toggleCompleteTask(task, isLastTask)}
        />
      </CheckboxWrapper>

      <Content taskCompleted={completedSection}>
        <RowLink task={task}>
          <div css={nameAndNurtureHotnessMobile}>
            <Name className="fs-exclude">{getName(task)}</Name>
            {nurtureHotness && (
              <div
                css={nurtureHotnessStyle}
                $mobile
                title={nurtureHotness.label}
              >
                <NurtureHotnessImage nurtureHotness={nurtureHotness} />
              </div>
            )}
          </div>
          <Description className="fs-exclude">{task.description}</Description>
          <DueDate title={dateForHover} taskCompleted={completedSection} mobile>
            {mobileDate}
          </DueDate>
          <DueDate title={dateForHover} taskCompleted={completedSection}>
            {notMobileDate}
          </DueDate>
          <div css={nurtureHotnessStyle} title={nurtureHotness?.label}>
            {nurtureHotness ? (
              <NurtureHotnessImage nurtureHotness={nurtureHotness} />
            ) : (
              "-"
            )}
          </div>
          <div css={callIconStyle}>
            <HandoffCallButton
              contacts={task.reference.contacts}
              data-test={`desktop-phone-${task.reference.id}`}
              relationType={
                task.reference.__typename === "Buyer" ? "buyer" : "deal"
              }
              relationId={
                task.reference.__typename === "Buyer"
                  ? task.reference.id
                  : task.reference.externalId
              }
            />
          </div>
        </RowLink>
      </Content>
    </Row>
  );
};
