import { gql } from "@apollo/client";

export const VALUATIONS_QUERY = gql`
  query PreVisitValuationsListQuery($dealId: ID!) {
    nestDeal(id: $dealId) {
      id
      valuationList {
        id
        dealId
        notes

        ... on PreVisitValuation {
          completedBy
          dateCompleted
          estimatedListPriceLowerBound
          estimatedListPriceUpperBound
          nonNullableEstimatedSalePriceLowerBound: estimatedSalePriceLowerBound
          nonNullableEstimatedSalePriceUpperBound: estimatedSalePriceUpperBound
          estimatedSalePriceBestEstimate
          estimatingAdvance
          estimatedAdvanceAmountLowerBound
          estimatedAdvanceOnTrack
          estimatedAdvanceAmountUpperBound
          listPriceBestEstimate
          vaMaximumListPrice
        }

        ... on MiniValuation {
          listPriceLowerBound
          listPriceUpperBound
          # These have to be aliased because other valuations have non_nullable
          # versions of the same field, which confuses flow
          nullableEstimatedSalePriceLowerBound: estimatedSalePriceLowerBound
          nullableEstimatedSalePriceUpperBound: estimatedSalePriceUpperBound
          estimatedSalePriceBestEstimate
          advanceLowerBound
          advanceUpperBound
        }
      }
    }

    activeNestedUsers {
      id
      closeUserId
      fullName
    }
  }
`;
