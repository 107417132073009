// @flow
import styled from "styled-components";
import { kebabCase } from "lodash";

import { ListEntry } from "components/ListEntry/ListEntry";
import { Tick, Delete } from "@nest-ui/icons";
import { Grid, LeafCell } from "components/Grid/Grid";
import { NoSubmitCurrencyField } from "components/CurrencyField/CurrencyField";
import { NoSubmitDatePicker } from "components/DatePicker";
import { NoSubmitSelectField } from "components/SelectField";
import { NoSubmitTextareaWithLinks } from "components/TextareaWithLinks/TextareaWithLinks";
import { PrimaryButton } from "components/Buttons";
import { NoSubmitRadioButtons } from "components/RadioButtons";
import { ButtonWithIcon } from "components/ButtonWithIcon";
import { H3 } from "components/Heading";

const Card = styled(ListEntry)`
  margin-bottom: 0px;
`;

const SubHeading = styled(H3)`
  margin-bottom: 0;
`;

type Props = {
  dealId: string,
  users: $ReadOnlyArray<PreVisitValuationsListQuery_activeNestedUsers>,
  updateState: (newValuation: $Shape<PreVisitValuationInput>) => void,
  valuation: $Shape<PreVisitValuationInput>,
  onSubmit: () => any,
  onCancel: () => any,
};

export const CreatePreVisitValuation = (props: Props) => {
  const fieldProps = (key: string) => ({
    "data-test": `create-pre-visit-valuation-${kebabCase(key)}`,
    value: props.valuation[key],
    onSubmit: (val) => props.updateState({ [key]: val }),
  });

  const disableSubmit = !(
    props.valuation.dateCompleted &&
    props.valuation.completedBy &&
    props.valuation.estimatedListPriceLowerBound &&
    props.valuation.estimatedListPriceUpperBound &&
    props.valuation.estimatedSalePriceLowerBound &&
    props.valuation.estimatedSalePriceUpperBound
  );

  return (
    <Card>
      <Grid columns={4}>
        <LeafCell width={2}>
          <NoSubmitDatePicker
            {...fieldProps("dateCompleted")}
            label="Valuation Date"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitSelectField
            {...fieldProps("completedBy")}
            options={props.users.map(({ fullName, closeUserId }) => ({
              label: fullName,
              value: closeUserId,
            }))}
            searchable
            label="Completed By"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedListPriceLowerBound")}
            label="Estimated List Price Lower"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedListPriceUpperBound")}
            label="Estimated List Price Upper"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("listPriceBestEstimate")}
            label="List Price - Best Estimate"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("vaMaximumListPrice")}
            label="VA Maximum List Price"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePriceLowerBound")}
            label="Estimated Sale Price Lower"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePriceUpperBound")}
            label="Estimated Sale Price Upper"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedSalePriceBestEstimate")}
            label="Estimated Sale Price Best Estimate"
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <SubHeading>Advance Estimates</SubHeading>
        </LeafCell>
        <LeafCell width={2} left={1}>
          <NoSubmitRadioButtons
            data-test="create-pre-visit-valuation-estimating-advance"
            id="create-pre-visit-valuation-estimating-advance"
            label="Estimating Advance"
            onSubmit={(val) => {
              /*
               * `estimatingAdvance` is a required field, so once it is
               * selected we don't allow to de-select it.
               */
              if (val !== undefined && val !== null) {
                props.updateState({ estimatingAdvance: val });
              }
            }}
            options={[
              { label: "No", value: false },
              { label: "Yes", value: true },
            ]}
            value={props.valuation.estimatingAdvance}
          />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceAmountLowerBound")}
            disabled={!props.valuation.estimatingAdvance}
            label="Off-track advance"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceOnTrack")}
            disabled={!props.valuation.estimatingAdvance}
            label="On-track advance"
          />
        </LeafCell>

        <LeafCell width={2}>
          <NoSubmitCurrencyField
            {...fieldProps("estimatedAdvanceAmountUpperBound")}
            disabled={!props.valuation.estimatingAdvance}
            label="Under Offer advance"
          />
        </LeafCell>

        <LeafCell width={4}>
          <NoSubmitTextareaWithLinks {...fieldProps("notes")} label="Notes" />
        </LeafCell>

        <LeafCell width={2} left={1}>
          <PrimaryButton
            data-test="create-pre-visit-valuation-save-button"
            onClick={props.onSubmit}
            disabled={disableSubmit}
            icon={Tick}
            label="Save Valuation"
          />
        </LeafCell>
        <LeafCell width={1} left={4}>
          <ButtonWithIcon
            data-test="create-pre-visit-valuation-cancel"
            icon={Delete}
            onSubmit={props.onCancel}
          />
        </LeafCell>
      </Grid>
    </Card>
  );
};
