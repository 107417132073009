import { path } from "ramda";

export const updateDealTypeGuaranteeConfig = {
  props: ({ ownProps: { data }, mutate }) => {
    const dealTypeData = path(["nestDeal", "dealTypeGuarantee"], data);
    return {
      updateDealTypeGuarantee: (input) =>
        mutate({
          variables: { input, id: dealTypeData.id },
          optimisticResponse: {
            __typename: "Mutation",
            updateDealTypeGuarantee: {
              __typename: "DealTypeGuarantee",
              ...dealTypeData,
              ...input,
            },
          },
        }),
    };
  },
};
