import { createStore, combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { reducer } from "./components/MultiStageForm/ducks";

const rootReducer = combineReducers({ onboardingForm: reducer });

const persistedReducer = persistReducer({ key: "root", storage }, rootReducer);

export const store = createStore(persistedReducer, {});

// WARNING, there's a potential gotcha with purging local storage:
//
// When you call `persistor.purge()`, it should delete all localstorage, but
// are you currently hitting a bug where this doesn't appear to be happening?
//
// The likely cause is that you're updating some state asynchronously (e.g.
// with a hook like `updateFormState`) just before you call `persistor.purge`
// synchronously. The bizarre state of affairs here is that the call to `purge`
// will successfully clear state, but then your update to the state may then
// put the state **back in** local storage. The solution is to either change
// the order in which you're doing things or make your state updates
// synchronous
export const persistor = persistStore(store);
