// @flow

import { useEffect, useRef, useState } from "react";
import { gql } from "@apollo/client";
import {
  type MutationFunction,
  type RefetchQueriesProviderFn,
} from "@apollo/client/react/components";
import styled from "styled-components";

import { Add, Pencil, CardMenu, ExternalLink } from "@nest-ui/icons";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";

import { theme as comparablesTheme } from "../theme";
import { RatingButtons } from "./RatingButtons";
import { comparabilityRatings, relevanceRatings } from "./ComparabilityRatings";

type Props = {
  address: string,
  comparabilityRating: ?string,
  comparableLinks: $ReadOnlyArray<ListComparablesQuery_comparables_comparableLinks>,
  id: string,
  relevanceRating: ?string,
  refetchOnDelete?: RefetchQueriesProviderFn,
  setEditMode: (boolean) => void,
  disableSelect: (boolean) => void,
};

export const DELETE_COMPARABLE = gql`
  mutation DeleteComparable($id: ID!) {
    deleteComparable(id: $id) {
      id
    }
  }
`;

export const SET_COMPARABILITY_RATING = gql`
  mutation SetComparabilityRating($id: ID!, $input: ComparabilityRatingInput!) {
    setComparabilityRating(id: $id, input: $input) {
      id
      comparabilityRating
    }
  }
`;
export const SET_RELEVANCE_RATING = gql`
  mutation SetRelevanceRating($id: ID!, $input: RelevanceRatingInput!) {
    setRelevanceRating(id: $id, input: $input) {
      id
      relevanceRating
    }
  }
`;

const MenuIcon = styled.a`
  cursor: pointer;
  padding: 1px;
`;

const Tooltip = styled.ul`
  background-color: ${({ theme }) => theme.textField.background};
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.11);
  list-style-type: none;
  margin: 0;
  min-width: 270px;
  padding: 16px;
  position: absolute;
  right: -10px;
  top: calc(100% + 12px);
  z-index: 1;

  &::after {
    border: 8px solid;
    border-color: transparent transparent
      ${({ theme }) => theme.textField.background} transparent;
    bottom: 100%;
    content: "";
    margin-left: -8px;
    pointer-events: none;
    position: absolute;
    right: 10px;
  }
`;

const TooltipItem = styled.li`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TooltipLink = styled.a`
  color: ${({ theme }) => theme.textField.text};
  display: flex;
  flex-direction: row;
  font-weight: 600;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
`;

const TooltipNotLink = styled.div`
  color: ${({ theme }) => theme.textField.text};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
`;

const RadioButtonTooltip = styled(TooltipNotLink)`
  flex-direction: column;
  margin-bottom: 16px;

  span {
    align-self: flex-start;
    margin-bottom: 8px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.span`
  height: 18px;
  width: 18px;
`;

const Cross = styled(Icon)`
  svg {
    transform: rotate(45deg);
  }
`;

const isEventTarget = (event: MouseEvent, element) =>
  element && element.current && element.current.contains(event.target);

export const ComparableTooltip = ({
  address,
  comparabilityRating,
  comparableLinks,
  disableSelect,
  id,
  refetchOnDelete,
  relevanceRating,
  setEditMode,
}: Props) => {
  const icon = useRef(null);
  const tooltip = useRef(null);
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (!isEventTarget(e, icon) && !isEventTarget(e, tooltip)) {
        setTooltipShown(false);
      }
    };

    document.addEventListener("mousedown", clickHandler, false);
    return () => document.removeEventListener("mousedown", clickHandler);
  });

  const rightmoveLink = comparableLinks.find(
    (link) => link.type === "RIGHTMOVE",
  );
  const rightmovePlusLink = comparableLinks.find(
    (link) => link.type === "RIGHTMOVE_PLUS",
  );
  const otherLink = comparableLinks.find((link) => link.type === "OTHER");

  return (
    <Wrapper>
      <MenuIcon
        ref={icon}
        data-test="comparable:menu-icon"
        onClick={(e) => {
          e.stopPropagation();
          setTooltipShown(!tooltipShown);
        }}
      >
        <CardMenu />
      </MenuIcon>
      {tooltipShown && (
        <Tooltip
          data-test="comparable:tooltip"
          ref={tooltip}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <TooltipItem>
            <RadioButtonTooltip>
              <span>Comparability</span>
              <ExtendedMutation mutation={SET_COMPARABILITY_RATING}>
                {(
                  setComparabilityRating: MutationFunction<
                    SetComparabilityRating,
                    SetComparabilityRatingVariables,
                  >,
                ) => (
                  <RatingButtons
                    data-test="comparability-radio-buttons"
                    current={comparabilityRating}
                    ratings={comparabilityRatings}
                    onClick={(value) => {
                      const newValue =
                        value === comparabilityRating ? null : value;

                      setComparabilityRating({
                        variables: {
                          id,
                          input: { comparabilityRating: newValue },
                        },
                        optimisticResponse: {
                          setComparabilityRating: {
                            __typename: "Comparable",
                            id,
                            comparabilityRating: newValue,
                          },
                        },
                      });
                    }}
                  />
                )}
              </ExtendedMutation>
            </RadioButtonTooltip>
          </TooltipItem>
          <TooltipItem>
            <RadioButtonTooltip>
              <span>Relevance</span>
              <ExtendedMutation mutation={SET_RELEVANCE_RATING}>
                {(
                  setRelevanceRating: MutationFunction<
                    SetRelevanceRating,
                    SetRelevanceRatingVariables,
                  >,
                ) => (
                  <RatingButtons
                    data-test="relevance-radio-buttons"
                    current={relevanceRating}
                    ratings={relevanceRatings}
                    onClick={(value) => {
                      const newValue = value === relevanceRating ? null : value;
                      setRelevanceRating({
                        variables: {
                          id,
                          input: { relevanceRating: newValue },
                        },
                        optimisticResponse: {
                          setRelevanceRating: {
                            __typename: "Comparable",
                            id,
                            relevanceRating: newValue,
                          },
                        },
                      });
                    }}
                  />
                )}
              </ExtendedMutation>
            </RadioButtonTooltip>
          </TooltipItem>

          <TooltipItem>
            <TooltipNotLink
              data-test="edit-comp-tooltip-menu"
              onClick={() => {
                setTooltipShown(false);
                setEditMode(true);
                disableSelect(true);
              }}
            >
              <span>Edit Comp</span>
              <Icon>
                <Pencil fill={comparablesTheme.text.white} />
              </Icon>
            </TooltipNotLink>
          </TooltipItem>

          {rightmoveLink && (
            <TooltipItem>
              <TooltipLink href={rightmoveLink.url} target="_blank">
                <span>View on Rightmove</span>
                <ExternalLink />
              </TooltipLink>
            </TooltipItem>
          )}

          {rightmovePlusLink && (
            <TooltipItem>
              <TooltipLink href={rightmovePlusLink.url} target="_blank">
                <span>View on Rightmove+</span>
                <ExternalLink />
              </TooltipLink>
            </TooltipItem>
          )}

          {otherLink && (
            <TooltipItem>
              <TooltipLink href={otherLink.url} target="_blank">
                <span>View</span>
                <ExternalLink />
              </TooltipLink>
            </TooltipItem>
          )}

          <ExtendedMutation mutation={DELETE_COMPARABLE}>
            {(
              deleteComparable: MutationFunction<
                DeleteComparable,
                DeleteComparableVariables,
              >,
            ) => (
              <TooltipItem
                data-test="comparable:tooltip-remove-comp"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirmation = window.confirm(
                    `Are you sure you want to remove ${address}?`,
                  );

                  if (confirmation) {
                    deleteComparable({
                      mutation: DELETE_COMPARABLE,
                      variables: { id },
                      refetchQueries: refetchOnDelete,
                    });
                  }
                }}
              >
                <TooltipNotLink>
                  <span>Remove Comp</span>
                  <Cross>
                    <Add fill={comparablesTheme.text.white} />
                  </Cross>
                </TooltipNotLink>
              </TooltipItem>
            )}
          </ExtendedMutation>
        </Tooltip>
      )}
    </Wrapper>
  );
};
