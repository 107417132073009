export const updateDealTypeGuaranteeConfig = {
  props: ({ ownProps: { nestDeal }, mutate }) => ({
    updateDealTypeGuarantee: (input) =>
      mutate({
        variables: { input, id: nestDeal.dealTypeDetails.id },
        optimisticResponse: {
          __typename: "Mutation",
          updateDealTypeGuarantee: {
            __typename: "DealTypeGuarantee",
            ...nestDeal.dealTypeDetails,
            ...input,
          },
        },
      }),
  }),
};
