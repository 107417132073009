// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";

import { Grid, LeafCell } from "components/Grid";
import { H2 } from "components/Heading";
import { TextArea } from "components/TextArea";
import { TextField } from "components/TextField";
import { InfoTile, InfoSection } from "../InfoTile";

const UPDATE_NEIGHBOUR_AND_PARKING_DETAILS = gql`
  mutation UpdateNeighbourAndParkingDetails($id: ID!, $input: PropertyInput!) {
    updateProperty(id: $id, input: $input) {
      id
      neighbourDetails
      parkingDetails
    }
  }
`;

const NEST_DEAL_UPDATE = gql`
  mutation UpdateRMNotes($id: ID!, $input: NestDealInput!) {
    updateNestDeal(id: $id, nestDeal: $input) {
      id
      rmNotesExterior
      rmTopThreeFeaturesForBuyers
    }
  }
`;

type Props = {
  editMode: boolean,
  nestDeal: ViewingAppNestDealQuery_nestDeal,
};

export const PropertyDetails = ({ editMode, nestDeal }: Props) => {
  const { id, property, rmNotesExterior, rmTopThreeFeaturesForBuyers } =
    nestDeal;
  const { neighbourDetails, parkingDetails } = property;

  const [updateProperty] = useMutation(UPDATE_NEIGHBOUR_AND_PARKING_DETAILS);
  const [updateNestDeal] = useMutation(NEST_DEAL_UPDATE);

  if (editMode) {
    return (
      <>
        <H2>Property Details</H2>
        <Grid columns={4}>
          <LeafCell width={2}>
            <TextArea
              label="Key property selling points"
              data-test="rm-top-three-features-for-buyers:edit"
              property="rmTopThreeFeaturesForBuyers"
              value={rmTopThreeFeaturesForBuyers}
              mutation={(input) =>
                updateNestDeal({
                  variables: {
                    id,
                    input,
                  },
                  optimisticResponse: {
                    updateNestDeal: {
                      ...nestDeal,
                      ...input,
                    },
                  },
                })
              }
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextArea
              label="Outside space"
              data-test="rm-notes-exterior:edit"
              property="rmNotesExterior"
              value={rmNotesExterior}
              mutation={(input) =>
                updateNestDeal({
                  variables: {
                    id,
                    input,
                  },
                  optimisticResponse: {
                    updateNestDeal: {
                      ...nestDeal,
                      ...input,
                    },
                  },
                })
              }
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextArea
              label="Neighbours"
              data-test="neighbour-details:edit"
              property="neighbourDetails"
              value={neighbourDetails}
              mutation={(input) =>
                updateProperty({
                  variables: {
                    id: property.id,
                    input,
                  },
                  optimisticResponse: {
                    updateProperty: {
                      ...property,
                      ...input,
                    },
                  },
                })
              }
            />
          </LeafCell>
          <LeafCell width={2}>
            <TextField
              label="Parking"
              data-test="parking-details:edit"
              property="parkingDetails"
              value={parkingDetails}
              mutation={(input) =>
                updateProperty({
                  variables: {
                    id: property.id,
                    input,
                  },
                  optimisticResponse: {
                    updateProperty: {
                      ...property,
                      ...input,
                    },
                  },
                })
              }
            />
          </LeafCell>
        </Grid>
      </>
    );
  }

  return (
    <InfoSection title="Property Details">
      <InfoTile
        data-test="rm-top-three-features-for-buyers"
        title="Key property selling points"
        text={rmTopThreeFeaturesForBuyers}
      />
      <InfoTile
        data-test="rm-notes-exterior"
        title="Outside space"
        text={rmNotesExterior}
      />
      <InfoTile
        data-test="neighbour-details"
        title="Neighbours"
        text={neighbourDetails}
      />
      <InfoTile
        data-test="parking-details"
        title="Parking"
        text={parkingDetails}
      />
    </InfoSection>
  );
};
