import T from "prop-types";
import { theme } from "@nest-ui/styles";

const transform = (isOpen) => (isOpen ? "scale(1, -1) translate(0, -16)" : "");

// This is the raw SVG version of the design from Zeplin
const Arrow = ({
  isOpen,
  fill = theme.color.primary.dark,
  height = 20,
  width = 20,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <path
      transform={transform(isOpen)}
      fill={fill}
      fillRule="nonzero"
      d="M7.989 9.164L4.297 5.229a.75.75 0 1 0-1.094 1.026l4.237 4.516a.75.75 0 0 0 1.092.002l4.263-4.516a.75.75 0 1 0-1.09-1.03L7.989 9.164z"
    />
  </svg>
);

Arrow.propTypes = {
  isOpen: T.bool,
  fill: T.string,
};

// This is potentially confusing; why is the "Dark" arrow white?
//
// Well, because in the Dark theme we have light highlights. Conversely, the
// normal state is to be the primary dark colour because our standard Arrow
// sits on a light background
export const DarkArrow = (props) => <Arrow {...props} fill="white" />;

export { Arrow };
