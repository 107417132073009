// @flow

import { LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";

import { graphql } from "deal/utils/graphql";
import { compose } from "recompose";

import { updateDealTypeLegacyGuaranteeConfig } from "./mutations/updateDealTypeLegacyGuaranteeConfig";
import UpdateDealTypeLegacyGuaranteeMutation from "./mutations/UpdateDealTypeLegacyGuaranteeMutation.graphql";

type Props = {|
  nestDeal: {
    dealTypeDetails: DealTypeLegacyGuaranteeGlobalFragment,
  },
  updateDealTypeLegacyGuarantee: () => Promise<UpdateDealTypeLegacyGuarantee>,
  readOnly?: boolean,
  hideContractSignedDates?: boolean,
|};

export const LegacyGuaranteeComponent = ({
  nestDeal: { dealTypeDetails },
  updateDealTypeLegacyGuarantee,
  readOnly,
  hideContractSignedDates,
}: Props) => (
  <>
    {!hideContractSignedDates && (
      <>
        <LeafCell width={1}>
          <DatePicker
            label="Nested Contract Signed Date"
            value={dealTypeDetails?.nestedContractSignedDate}
            property="nestedContractSignedDate"
            mutation={updateDealTypeLegacyGuarantee}
            readOnly={readOnly}
            data-test="nested-contract-signed-date"
          />
        </LeafCell>
        <LeafCell width={1}>
          <DatePicker
            label="Customer Contract Signed Date"
            value={dealTypeDetails?.customerContractSignedDate}
            property="customerContractSignedDate"
            mutation={updateDealTypeLegacyGuarantee}
            readOnly={readOnly}
            data-test="customer-contract-signed-date"
          />
        </LeafCell>
      </>
    )}
  </>
);

export const LegacyGuarantee = compose(
  graphql(
    UpdateDealTypeLegacyGuaranteeMutation,
    updateDealTypeLegacyGuaranteeConfig,
  ),
)(LegacyGuaranteeComponent);
