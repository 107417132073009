import { compose } from "recompose";
import { NoSubmitTextField } from "components/TextField";
import { isPresent } from "@nested/utils/isPresent";
import {
  withOnSubmit,
  withReadOnly,
  withFormattedCurrency,
} from "@nest-ui/hocs";

const enhance = compose(
  withFormattedCurrency,
  withReadOnly(({ format, value }) =>
    isPresent(value) ? format(value) : null,
  ),
);

export const NoSubmitCurrencyField = enhance(NoSubmitTextField);
export const CurrencyField = withOnSubmit(NoSubmitCurrencyField);
