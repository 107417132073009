// @flow
import styled from "styled-components";

import { ListEntryCard as OriginalListEntryCard } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { NoSubmitDatePicker } from "components/DatePicker";
import { TextField } from "components/TextField";

const ListEntryCard = styled(OriginalListEntryCard)`
  position: relative;
`;

type Props = {
  agreementDate?: Date,
  drawdownDay?: number,
};

export function ExpectedDrawdownTimingReadOnly({
  agreementDate,
  drawdownDay,
}: Props) {
  return (
    <ListEntryCard data-test="new-expected-drawdown-timing-card-existing-entry">
      <Grid columns={4}>
        <LeafCell>
          <TextField
            data-test="expected-drawdown-timing-drawdown-day-read-only"
            label="Expected drawdown on or after day..."
            readOnly
            value={drawdownDay}
          />
        </LeafCell>
        <LeafCell>
          <NoSubmitDatePicker
            data-test="expected-drawdown-timing-date-agreed-read-only"
            label="Date agreed"
            readOnly
            value={agreementDate}
          />
        </LeafCell>
      </Grid>
    </ListEntryCard>
  );
}
