// @flow
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import styled, { css } from "styled-components";
import { BuyerStatus } from "./BuyerStatus";
import { BuyerStatusDescription } from "./BuyerStatusDescription";
import { type SummaryBarState } from "./getSummaryBarState";

const BackToWorkflow = styled(Link)`
  display: none;
  ${media.tablet`
    align-self: center;
    color: ${({ theme }) => theme.palette.hague70};
    display: block;
  `}
`;

const BuyerAvatar = styled.img`
  border-radius: 50%;
  width: 36px;
  margin: 0 10px 0 0;
  ${media.tablet`
    width: 42px;
    margin: 0 10px;
  `}
`;

const buyerDetails = css`
  display: flex;
  align-items: center;
  width: 100%;
`;

const buyerDetailsNameAndStatusWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BuyerName = styled.p`
  color: ${({ theme }) => theme.palette.hague150};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  ${media.tablet`
    font-size: 16px;
    line-height: 24px;
  `}
`;

type Props = {
  buyer: BuyerSummary_buyer,
  selectedBpi: BuyerSummary_buyer_buyerPropertyInterests,
  summaryBarState: SummaryBarState,
};

export const BuyerDetails = ({
  buyer,
  selectedBpi,
  summaryBarState,
}: Props) => {
  const { workflow } = useParams();

  return (
    <div css={buyerDetails}>
      <BackToWorkflow
        data-test="back-arrow"
        to={`/buyers/${workflow || "enquiries"}`}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </BackToWorkflow>
      <BuyerAvatar
        alt=""
        src={buyer.avatarUrl}
        data-test={`buyer-avatar-${String(buyer.id)}`}
      />
      <div css={buyerDetailsNameAndStatusWrapper}>
        <BuyerName data-test="buyer-name" className="fs-exclude">
          {buyer.name}
        </BuyerName>

        <BuyerStatusDescription
          selectedBpi={selectedBpi}
          summaryBarState={summaryBarState}
        />
      </div>
      <BuyerStatus
        selectedBpi={selectedBpi}
        summaryBarState={summaryBarState}
      />
    </div>
  );
};
