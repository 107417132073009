export function resolveInputOptions(input, inputOptionFields, inputOptions) {
  return Object.keys(input).reduce((acc, fieldName) => {
    // eslint-disable-next-line no-prototype-builtins
    if (inputOptionFields.hasOwnProperty(fieldName)) {
      const value = input[fieldName];
      const option =
        value === null
          ? null
          : inputOptions[inputOptionFields[fieldName]].find(
              ({ value: optionValue }) => optionValue === input[fieldName],
            );

      return {
        [fieldName]: {
          value,
          label: option ? option.label : value,
          __typename: "StringInputOption",
        },
        ...acc,
      };
    }
    return { [fieldName]: input[fieldName], ...acc };
  }, {});
}
