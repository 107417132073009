// @flow
import { gql } from "@apollo/client";
import { type MutationFunction } from "@apollo/client/react/components";

import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import {
  DeleteButtonWithIcon as DeleteButton,
  itemDeletionConfirmed,
} from "components/DeleteButton";

import { VALUATIONS_LIST_QUERY } from "./valuationsQuery";

const DELETE_MUTATION = gql`
  mutation DeleteAdvanceOfferValuationsListEntry(
    $id: ID!
    $valuationType: DeletableValuationType!
  ) {
    deleteValuation(id: $id, valuationType: $valuationType) {
      __typename
      id
    }
  }
`;

type Props = {
  id: string,
  dealId: string,
  valuationType: DeletableValuationType,
};

export const DeleteValuation = ({ id, dealId, valuationType }: Props) => (
  <ExtendedMutation
    variables={{ id, valuationType }}
    mutation={DELETE_MUTATION}
    refetchQueries={[
      {
        query: VALUATIONS_LIST_QUERY,
        variables: { dealId },
      },
    ]}
  >
    {(
      mutate: MutationFunction<
        DeleteAdvanceOfferValuationsListEntry,
        DeleteAdvanceOfferValuationsListEntryVariables,
      >,
    ) => (
      <DeleteButton
        data-test={`valuation-list-delete-valuation-button-${id}`}
        onSubmit={() => {
          const message =
            "Are you sure that you want to delete this valuation? This operation cannot be undone.";
          if (itemDeletionConfirmed(message)) {
            mutate({
              variables: { id, valuationType },
            });
          }
        }}
      />
    )}
  </ExtendedMutation>
);
