// @flow
import { gql } from "@apollo/client";

import { Grid, LeafCell } from "components/Grid";
import { SelectField } from "components/SelectField";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";
import { useMutation } from "@apollo/client/react/hooks";

const VALUATION_COMPLETE_CHECKLIST_FRAGMENT = gql`
  fragment ValuationCompleteChecklistFields on NestDeal {
    id
    onwardPurchaseStatus {
      label
      value
    }
  }
`;

const VALUATION_COMPLETE_CHECKLIST_QUERY = gql`
  query ValuationCompleteChecklistQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      ...ValuationCompleteChecklistFields
    }
    onwardPurchaseStatuses {
      label
      value
    }
  }
  ${VALUATION_COMPLETE_CHECKLIST_FRAGMENT}
`;

const VALUATION_COMPLETE_CHECKLIST_MUTATION = gql`
  mutation UpdateValuationCompleteChecklist($id: ID!, $input: NestDealInput!) {
    updateNestDeal(nestDeal: $input, id: $id) {
      id
      ...ValuationCompleteChecklistFields
    }
  }
  ${VALUATION_COMPLETE_CHECKLIST_FRAGMENT}
`;

export const ValuationCompleteChecklist = ({
  dealId,
}: {|
  dealId: string,
|}) => {
  const [mutate] = useMutation(VALUATION_COMPLETE_CHECKLIST_MUTATION);

  return (
    <ExtendedQuery
      query={VALUATION_COMPLETE_CHECKLIST_QUERY}
      variables={{ id: dealId }}
    >
      {({
        nestDeal,
        onwardPurchaseStatuses,
      }: ExtendedQueryRenderProps<ValuationCompleteChecklistQuery>) => {
        return (
          <Grid columns={2}>
            <LeafCell width={2}>
              <SelectField
                data-test="valuation-complete-checklist:onward-purchase-status"
                options={onwardPurchaseStatuses}
                property="onwardPurchaseStatus"
                value={nestDeal.onwardPurchaseStatus}
                label="Onward purchase status"
                mutation={(input) => {
                  const onwardPurchaseStatus = onwardPurchaseStatuses.find(
                    ({ value }) => value === input.onwardPurchaseStatus,
                  );
                  mutate({
                    variables: {
                      input,
                      id: nestDeal.id,
                    },
                    optimisticResponse: {
                      __typename: "Mutation",
                      updateNestDeal: {
                        __typename: "NestDeal",
                        ...nestDeal,
                        onwardPurchaseStatus,
                      },
                    },
                  });
                }}
              />
            </LeafCell>
          </Grid>
        );
      }}
    </ExtendedQuery>
  );
};
