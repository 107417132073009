// @flow
import { css } from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { media } from "@nested/brand";
import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { useListViewCounts } from "../../hooks/useListViewCounts";
import { useTaskCounters } from "../../hooks/useTaskCounters";
import { LogoutButton } from "./LogoutButton";

const sidebarStyle = css`
  background-color: ${({ theme }) => theme.palette.sand20};
  width: 200px;
  flex-shrink: 0;
  flex-grow: 0;
  border-right: 1px solid ${({ theme }) => theme.palette.hague20};
  ${media.desktop`
    width: 170px;
  `}
`;

const menuHeadingStyle = css`
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.6;
  letter-spacing: 1px;
  margin-top: 30px;
  font-weight: 500;
  line-height: 18px;
  padding: 0px 20px;
`;

const mobileSidebarHeaderStyle = css`
  padding: 10px 15px 0px 15px;
  margin-bottom: -15px;
  text-align: right;
  font-size: 1.8em;
  box-sizing: border-box;
  width: 100%;
  ${media.desktop`
    display: none;
  `}
`;

const closeButtonStyle = css`
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.hague80};
`;

const sidebarMenuStyle = css`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  margin-top: 20px;
  margin-left: 10px;
`;

const sidebarMenuItemStyle = css`
  font-weight: 500;
`;

const menuLinkStyle = css`
  padding: 8px 20px;
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
  &.active {
    color: ${({ theme }) => theme.palette.blue150} !important;
    span {
      color: ${({ theme }) => theme.palette.blue100};
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 4px;
      height: 17px;
      background-color: ${({ theme }) => theme.palette.blue150};
    }
  }
  ${media.desktop`
    padding: 6px 20px;
  `}
`;

const countStyle = css`
  float: right;
  color: ${({ theme }) => theme.palette.hague70};
  &.active {
    color: ${({ theme }) => theme.palette.blue500};
  }
`;

const sidebarMainLinksStyle = css`
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${media.tablet`
    max-height: unset;
  `}

  ::-webkit-scrollbar {
    width: 8px;
    background-color: ${({ theme }) => theme.palette.sand50};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.sand100};
  }
`;

const TaskSection = ({ onClose }) => {
  const location = useLocation();
  const { dueToday } = useTaskCounters();

  return (
    <>
      <div css={menuHeadingStyle}>
        <Link to="/tasks">Tasks</Link>
      </div>
      <ul css={sidebarMenuStyle}>
        <li css={sidebarMenuItemStyle}>
          <NavLink
            css={menuLinkStyle}
            to="/tasks/to-do/today"
            onClick={onClose}
            isActive={() => location.pathname.includes("/tasks/to-do")}
          >
            To-do
            <span css={countStyle}>{dueToday}</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

type Props = {
  className?: string,
  onClose: () => void,
};

export const Sidebar = ({ className, onClose }: Props) => {
  const {
    bookVisit,
    valuation,
    close,
    noTaskSetNurture,
    toList,
    listed,
    underOffer,
    exchanged,
    completed,
    enquiries,
    awaitingFeedback,
    negotiatingOffers,
    buyersWithOffers,
  } = useListViewCounts();

  return (
    <ErrorBoundary>
      <div
        css={sidebarStyle}
        className={className}
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div css={sidebarMainLinksStyle}>
          <div css={mobileSidebarHeaderStyle}>
            <button css={closeButtonStyle} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <TaskSection onClose={onClose} />

          <div css={menuHeadingStyle}>
            <Link to="/leads">Leads</Link>
          </div>
          <ul css={sidebarMenuStyle}>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/leads/book-visit"
                onClick={onClose}
              >
                Book visit
                <span css={countStyle}>{bookVisit}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/leads/valuation"
                onClick={onClose}
              >
                Valuation
                <span css={countStyle}>{valuation}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink css={menuLinkStyle} to="/leads/close" onClick={onClose}>
                Close
                <span css={countStyle}>{close}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink css={menuLinkStyle} to="/nurture" onClick={onClose}>
                Nurture
                <span css={countStyle}>{noTaskSetNurture}</span>
              </NavLink>
            </li>
          </ul>
          <div css={menuHeadingStyle}>
            <Link to="/buyers">Buyers</Link>
          </div>
          <ul css={sidebarMenuStyle}>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/buyers/enquiries"
                onClick={onClose}
              >
                Enquiries
                <span css={countStyle}>{enquiries}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/buyers/feedback"
                onClick={onClose}
              >
                Feedback
                <span css={countStyle}>{awaitingFeedback}</span>
              </NavLink>
              <NavLink
                css={menuLinkStyle}
                to="/buyers/negotiations"
                onClick={onClose}
              >
                Negotiations
                <span css={countStyle}>{negotiatingOffers}</span>
              </NavLink>
              <NavLink
                css={menuLinkStyle}
                to="/buyers/under-offer"
                onClick={onClose}
              >
                Under Offer
                <span css={countStyle}>{buyersWithOffers}</span>
              </NavLink>
            </li>
          </ul>
          <div css={menuHeadingStyle}>
            <Link to="/portfolio">Portfolio</Link>
          </div>
          <ul css={sidebarMenuStyle}>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/portfolio/to-list"
                onClick={onClose}
              >
                To list
                <span css={countStyle}>{toList}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/portfolio/listed"
                onClick={onClose}
              >
                Listed
                <span css={countStyle}>{listed}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/portfolio/under-offer"
                onClick={onClose}
              >
                Under offer
                <span css={countStyle}>{underOffer}</span>
              </NavLink>
            </li>
          </ul>
          <div css={menuHeadingStyle}>
            <Link to="/sold">Sold</Link>
          </div>
          <ul css={sidebarMenuStyle}>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/sold/exchanged"
                onClick={onClose}
              >
                Exchanged
                <span css={countStyle}>{exchanged}</span>
              </NavLink>
            </li>
            <li css={sidebarMenuItemStyle}>
              <NavLink
                css={menuLinkStyle}
                to="/sold/completed"
                onClick={onClose}
              >
                Completed
                <span css={countStyle}>{completed}</span>
              </NavLink>
            </li>
          </ul>
          <LogoutButton />
        </div>
      </div>
    </ErrorBoundary>
  );
};
