// @flow
import { gql } from "@apollo/client";
import { compose } from "recompose";

import { graphql } from "deal/utils/graphql";
import { ListContainer } from "components/ListContainer";
import {
  ExtendedQuery,
  type ExtendedQueryRenderProps,
} from "@nested/utils/graphql/ExtendedQuery";

import createHouseholdCostMutation from "./createMutation/createHouseholdCost.graphql";
import { createConfig } from "./createMutation/config";
import deleteHouseholdCostMutation from "./deleteMutation/deleteHouseholdCost.graphql";
import { deleteConfig } from "./deleteMutation/config";

import { HouseholdCost } from "./HouseholdCost";

const HOUSEHOLD_COSTS_QUERY = gql`
  query HouseholdCostsQuery($id: ID!) {
    nestDeal(id: $id) {
      id
      householdCosts {
        id
        amount
        dateIncurred
        dateReimbursed
        description
      }
    }
  }
`;

const HouseholdCostsListComponent = ({
  deleteHouseholdCost,
  createHouseholdCost,
  parentId: externalDealId,
}) => (
  <ExtendedQuery
    query={HOUSEHOLD_COSTS_QUERY}
    variables={{ id: externalDealId }}
  >
    {(data: ExtendedQueryRenderProps<HouseholdCostsQuery>) => (
      <ListContainer
        component={HouseholdCost}
        createButtonText="Add another household cost"
        createListEntry={createHouseholdCost}
        deleteListEntry={deleteHouseholdCost}
        data={data}
        value={data.nestDeal.householdCosts}
      />
    )}
  </ExtendedQuery>
);

export const HouseholdCostsList = compose(
  graphql(createHouseholdCostMutation, createConfig),
  graphql(deleteHouseholdCostMutation, deleteConfig),
)(HouseholdCostsListComponent);
