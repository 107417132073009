import { gql } from "@apollo/client";

export const CREATE_ADVANCE_OFFER_VALUATION = gql`
  mutation CreateAdvanceOfferValuation(
    $dealId: ID!
    $input: CreateValuationInput!
  ) {
    createValuation(
      dealId: $dealId
      input: $input
      valuationType: ADVANCE_OFFER_VALUATION_OCT_18
    ) {
      id
      notes

      ... on AdvanceOfferValuationOct18 {
        issuingAdvance
        dateCompleted
        recommendedListingPrice
        expectedSalePrice
        advanceAmount
        completedBy
      }
    }
  }
`;

export const UPDATE_ADVANCE_VALUATION_MUTATION = gql`
  mutation UpdateAdvanceValuation(
    $advanceValuationId: ID!
    $input: AdvanceOfferValuationOct18UpdateInput!
  ) {
    updateAdvanceOfferValuationOct18(
      advanceValuationId: $advanceValuationId
      input: $input
    ) {
      id
      notes
    }
  }
`;
