// @flow
import { useState, useRef } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDateDifference,
  prettyFormat,
} from "@nested/utils/src/formatDate/formatDate";
import { media } from "@nested/brand";
import { Calendar } from "../../Calendar/Calendar";
import { CalendarPopupBox } from "../../Calendar/CalendarPopupBox";
import { SnoozeButtons } from "../../Calendar/SnoozeButtons";

const editableDateStyle = css`
  border: none;
  background-color: unset;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  font-size: 12px;
  padding-left: 2px;
  color: ${({ theme, $isCompletedTask, $isDueTask }) => {
    if ($isCompletedTask) return theme.palette.hague40;
    if ($isDueTask) return theme.palette.terracotta150;
    return theme.palette.hague100;
  }};
`;

const calendarStyle = css`
  margin-right: 5px;
  color: ${({ theme, $isCompletedTask, $isDueTask }) => {
    if ($isCompletedTask) return theme.palette.hague40;
    if ($isDueTask) return theme.palette.terracotta150;
    return theme.palette.hague70;
  }};
`;

const popupStyle = css`
  ${media.tablet`
    left: -20px;
    right: auto;
    width: 257px;
    ${({ yCoordinate }) =>
      css`
        top: ${yCoordinate};
      `}
  `}
`;

type Props = {
  value: string | Date,
  onSubmit: (dueAt: Date) => Promise<void>,
  isCompletedTask: boolean,
  isDueTask: boolean,
  "data-test"?: string,
};

// Below calculates position of date picker relative to the button. Offset is different for buyer and deal
const calculateYCoordinate = (buttonRef, pathname: string) => {
  if (buttonRef.current) {
    const boundingClient = buttonRef.current.getBoundingClientRect();
    const offset = pathname.startsWith("/buyer") ? 100 : 25;
    return `${boundingClient.top - offset}px`;
  }

  return 0;
};

export const TaskDatePicker = ({
  value,
  onSubmit,
  isCompletedTask,
  isDueTask,
  "data-test": dataTest,
}: Props) => {
  const { pathname } = useLocation();
  const parsedDate = moment(value).startOf("day");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const ref = useRef(null);

  return (
    <div data-test={dataTest}>
      <button
        css={editableDateStyle}
        onClick={() => setCalendarOpen(true)}
        $isCompletedTask={isCompletedTask}
        $isDueTask={isDueTask}
        ref={ref}
        disabled={isCompletedTask}
        data-test="reschedule-task-button"
        title={prettyFormat(parsedDate)}
      >
        <FontAwesomeIcon
          css={calendarStyle}
          icon={isCompletedTask ? faCalendarCheck : faCalendarAlt}
          $isCompletedTask={isCompletedTask}
          $isDueTask={isDueTask}
        />
        {formatDateDifference(parsedDate)}
      </button>
      {calendarOpen && (
        <CalendarPopupBox
          css={popupStyle}
          parentRef={ref}
          onClose={() => setCalendarOpen(false)}
          yCoordinate={calculateYCoordinate(ref, pathname)}
        >
          <SnoozeButtons
            onClick={(date) => {
              onSubmit(date);
              setCalendarOpen(false);
            }}
          />
          <Calendar
            value={parsedDate.toDate()}
            onChange={(date) => {
              onSubmit(date);
              setCalendarOpen(false);
            }}
            minDate={moment().toDate()}
          />
        </CalendarPopupBox>
      )}
    </div>
  );
};
