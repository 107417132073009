import { Fragment, useState } from "react";
import { gql } from "@apollo/client";
import { EditIcon } from "@nest-ui/icons";
import { ExtendedMutation } from "@nested/utils/graphql/ExtendedMutation";
import { ExtendedQuery } from "@nested/utils/graphql/ExtendedQuery";
import { Heading } from "components/Heading";
import { Card } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { SecondaryButton } from "components/SecondaryButton";
import { NoSubmitSelectField } from "components/SelectField";
import { TextField } from "components/TextField";

const GET_DISTRICTS = gql`
  query GetDistrictsForRMAllocation {
    servicedAreaPostcodeDistricts

    activeNestedUsers {
      id
      fullName
    }
  }
`;

const SERVICED_AREA_POSTCODE_SECTOR_FIELDS = gql`
  fragment ServicedAreaPostcodeSectorFields on ServicedAreaPostcodeSector {
    id
    area
    district
    sector
    relationshipManagers {
      id
      fullName
    }
    salesAssociates {
      id
      fullName
    }
  }
`;

const GET_SECTORS = gql`
  query GetSectorsForRMAllocation($districts: [String!]!) {
    servicedAreaPostcodeSectors(districts: $districts) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

const ASSIGN_ALL_AP = gql`
  mutation AssignAllSectorsToAP(
    $districts: [String!]!
    $input: SetAreaPartnerOnServicedAreaSectorInput!
  ) {
    setAreaPartnerOnServicedAreaDistricts(
      districts: $districts
      input: $input
    ) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

const ASSIGN_ALL_RMS = gql`
  mutation AssignAllSectorsToRMs(
    $districts: [String!]!
    $input: SetRelationshipManagersOnServicedAreaSectorInput!
  ) {
    setRelationshipManagersOnServicedAreaDistricts(
      districts: $districts
      input: $input
    ) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

const ASSIGN_ALL_SAS = gql`
  mutation AssignAllSectorsToSAs(
    $districts: [String!]!
    $input: SetSalesAssociatesOnServicedAreaSectorInput!
  ) {
    setSalesAssociatesOnServicedAreaDistricts(
      districts: $districts
      input: $input
    ) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

const ASSIGN_SECTOR_RM = gql`
  mutation AssignSectorToRM(
    $id: ID!
    $input: SetRelationshipManagersOnServicedAreaSectorInput!
  ) {
    setRelationshipManagersOnServicedAreaSector(id: $id, input: $input) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

const ASSIGN_SECTOR_SA = gql`
  mutation AssignSectorToSA(
    $id: ID!
    $input: SetSalesAssociatesOnServicedAreaSectorInput!
  ) {
    setSalesAssociatesOnServicedAreaSector(id: $id, input: $input) {
      id
      ...ServicedAreaPostcodeSectorFields
    }
  }
  ${SERVICED_AREA_POSTCODE_SECTOR_FIELDS}
`;

export const RMAreaAssignment = () => {
  const [districts, setDistricts] = useState([]);
  const [assignAll, setAssignAll] = useState([]);
  return (
    <>
      <Heading>Area Assignment</Heading>
      <Card>
        <ExtendedQuery query={GET_DISTRICTS}>
          {({ servicedAreaPostcodeDistricts, activeNestedUsers }) => (
            <Grid columns={5}>
              <LeafCell width={2}>
                <NoSubmitSelectField
                  data-test="district-select"
                  options={servicedAreaPostcodeDistricts}
                  label="Districts"
                  multiple
                  onSubmit={setDistricts}
                  value={districts}
                  searchable
                />
              </LeafCell>
              {districts?.length > 0 && (
                <LeafCell width={5}>
                  <Grid columns={5}>
                    <ExtendedQuery
                      query={GET_SECTORS}
                      variables={{ districts }}
                    >
                      {({ servicedAreaPostcodeSectors }) => (
                        <>
                          <LeafCell width={2}>
                            <NoSubmitSelectField
                              data-test="assign-all-select"
                              label="Assign all"
                              options={activeNestedUsers.map((user) => ({
                                label: user.fullName,
                                value: user.id,
                              }))}
                              multiple
                              value={assignAll}
                              onSubmit={setAssignAll}
                            />
                          </LeafCell>
                          {assignAll?.length > 0 && (
                            <>
                              <ExtendedMutation mutation={ASSIGN_ALL_RMS}>
                                {(mutation) => (
                                  <LeafCell width={1}>
                                    <SecondaryButton
                                      data-test="assign-all-rm-button"
                                      icon={EditIcon}
                                      onSubmit={async () => {
                                        const rmNames = assignAll
                                          .map(
                                            (id) =>
                                              activeNestedUsers.find(
                                                (u) => u.id === id,
                                              ).fullName,
                                          )
                                          .join(", ");

                                        // eslint-disable-next-line
                                        const confirmed = window.confirm(
                                          `Warning: This will assign ${servicedAreaPostcodeSectors.length} sectors to ${rmNames} as RM`,
                                        );

                                        if (!confirmed) {
                                          return;
                                        }

                                        await mutation({
                                          variables: {
                                            districts,
                                            input: {
                                              relationshipManagerIds: assignAll,
                                            },
                                          },
                                        });

                                        setAssignAll();
                                      }}
                                    >
                                      Assign RM
                                    </SecondaryButton>
                                  </LeafCell>
                                )}
                              </ExtendedMutation>
                              <ExtendedMutation mutation={ASSIGN_ALL_SAS}>
                                {(mutation) => (
                                  <LeafCell width={1}>
                                    <SecondaryButton
                                      data-test="assign-all-sa-button"
                                      icon={EditIcon}
                                      onSubmit={async () => {
                                        const saNames = assignAll
                                          .map(
                                            (id) =>
                                              activeNestedUsers.find(
                                                (u) => u.id === id,
                                              ).fullName,
                                          )
                                          .join(", ");

                                        // eslint-disable-next-line
                                        const confirmed = window.confirm(
                                          `Warning: This will assign ${servicedAreaPostcodeSectors.length} sectors to ${saNames} as SA`,
                                        );

                                        if (!confirmed) {
                                          return;
                                        }

                                        await mutation({
                                          variables: {
                                            districts,
                                            input: {
                                              salesAssociateIds: assignAll,
                                            },
                                          },
                                        });

                                        setAssignAll();
                                      }}
                                    >
                                      Assign SA
                                    </SecondaryButton>
                                  </LeafCell>
                                )}
                              </ExtendedMutation>
                              {assignAll.length === 1 && (
                                <ExtendedMutation mutation={ASSIGN_ALL_AP}>
                                  {(mutation) => (
                                    <LeafCell width={1}>
                                      <SecondaryButton
                                        data-test="assign-all-ap-button"
                                        icon={EditIcon}
                                        onSubmit={async () => {
                                          const apId = assignAll[0];
                                          const apName = activeNestedUsers.find(
                                            (u) => u.id === apId,
                                          ).fullName;

                                          // eslint-disable-next-line
                                          const confirmed = window.confirm(
                                            `Warning: This will assign ${servicedAreaPostcodeSectors.length} sectors to ${apName} as AP (both RM and SA)`,
                                          );

                                          if (!confirmed) {
                                            return;
                                          }

                                          await mutation({
                                            variables: {
                                              districts,
                                              input: {
                                                areaPartnerId: apId,
                                              },
                                            },
                                          });

                                          setAssignAll();
                                        }}
                                      >
                                        Assign AP
                                      </SecondaryButton>
                                    </LeafCell>
                                  )}
                                </ExtendedMutation>
                              )}
                            </>
                          )}

                          <LeafCell width={5}>
                            <Grid columns={5}>
                              <ExtendedMutation mutation={ASSIGN_SECTOR_RM}>
                                {(rmMutation) => (
                                  <ExtendedMutation mutation={ASSIGN_SECTOR_SA}>
                                    {(saMutation) =>
                                      servicedAreaPostcodeSectors.map(
                                        (sector) => (
                                          <Fragment key={sector.id}>
                                            <LeafCell left={1} width={1}>
                                              <TextField
                                                readOnly
                                                value={`${sector.area}${sector.district} ${sector.sector}`}
                                              />
                                            </LeafCell>
                                            <LeafCell width={2}>
                                              <NoSubmitSelectField
                                                label="RMs"
                                                data-test={`edit-sector-${sector.id}-rm`}
                                                multiple
                                                value={sector.relationshipManagers.map(
                                                  ({ id }) => id,
                                                )}
                                                options={activeNestedUsers.map(
                                                  (user) => ({
                                                    label: user.fullName,
                                                    value: user.id,
                                                  }),
                                                )}
                                                onSubmit={(value) => {
                                                  rmMutation({
                                                    variables: {
                                                      id: sector.id,
                                                      input: {
                                                        relationshipManagerIds:
                                                          value || [],
                                                      },
                                                    },
                                                  });
                                                }}
                                              />
                                            </LeafCell>
                                            <LeafCell width={2}>
                                              <NoSubmitSelectField
                                                label="SAs"
                                                data-test={`edit-sector-${sector.id}-sa`}
                                                multiple
                                                value={sector.salesAssociates.map(
                                                  ({ id }) => id,
                                                )}
                                                options={activeNestedUsers.map(
                                                  (user) => ({
                                                    label: user.fullName,
                                                    value: user.id,
                                                  }),
                                                )}
                                                onSubmit={(value) => {
                                                  saMutation({
                                                    variables: {
                                                      id: sector.id,
                                                      input: {
                                                        salesAssociateIds:
                                                          value || [],
                                                      },
                                                    },
                                                  });
                                                }}
                                              />
                                            </LeafCell>
                                          </Fragment>
                                        ),
                                      )
                                    }
                                  </ExtendedMutation>
                                )}
                              </ExtendedMutation>
                            </Grid>
                          </LeafCell>
                        </>
                      )}
                    </ExtendedQuery>
                  </Grid>
                </LeafCell>
              )}
            </Grid>
          )}
        </ExtendedQuery>
      </Card>
    </>
  );
};
