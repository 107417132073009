// @flow
import React from "react";

import { DrawdownTimingFeesComponentReadOnly } from "./DrawdownTimingFeesComponentReadOnly";
import { DrawdownTimingFeesComponentEditable } from "./DrawdownTimingFeesComponentEditable";

type Props = {
  dealId: ID,
  dayChoices: Choice[],
  drawdownTimingFees: $ReadOnlyArray<DrawdownTimingFee>,
};

type State = {
  editing: boolean,
};

export class DrawdownTimingFeesComponent extends React.Component<Props, State> {
  state = { editing: this.props.drawdownTimingFees.length === 0 };

  toggleEditing = () => {
    this.setState((state) => ({ editing: !state.editing }));
  };

  render() {
    const { dealId, dayChoices, drawdownTimingFees } = this.props;
    const { editing } = this.state;

    return editing ? (
      <DrawdownTimingFeesComponentEditable
        dealId={dealId}
        dayChoices={dayChoices}
        drawdownTimingFees={drawdownTimingFees}
        toggleEditing={this.toggleEditing}
      />
    ) : (
      <DrawdownTimingFeesComponentReadOnly
        dayChoices={dayChoices}
        drawdownTimingFees={drawdownTimingFees}
        toggleEditing={this.toggleEditing}
      />
    );
  }
}
