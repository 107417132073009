// @flow
import type { Element } from "react";
import styled from "styled-components";
import { withProps, type HOC } from "recompose";

import { Add } from "@nest-ui/icons";

export { Button as SecondaryButton } from "./SecondaryButton/SecondaryButton";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.input.height};
  border-radius: ${({ theme }) => theme.input.border.radius};
  background-color: ${({ theme }) => theme.color.secondary.light};
  color: ${({ theme }) => theme.color.text.light};
  padding: 8px;
  min-width: 84px;
  margin-top: 16px;
  margin-right: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-size: ${({ theme }) => theme.font.size.default};
  line-height: 1.33;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.color.secondary.light : theme.color.secondary.hover};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const DangerButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.input.height};
  border-radius: ${({ theme }) => theme.input.border.radius};
  background-color: ${({ theme }) => theme.color.background.danger};
  color: ${({ theme }) => theme.color.text.light};
  padding: 8px;
  min-width: 84px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-size: ${({ theme }) => theme.font.size.default};
  line-height: 1.33;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${({ theme, disabled }) =>
    !disabled &&
    `&:hover {
        background-color: ${theme.color.background.dangerHover};
        }`}

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CancelButton = styled.button`
  height: 32px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.background.muted};
  color: ${({ theme }) => theme.color.primary.dark};
  padding: 8px;
  min-width: 84px;
  margin-top: 16px;
  margin-right: 16px;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  &:hover {
    background-color: ${({ theme }) => theme.color.background.hoverMuted};
  }
`;

const Label = styled.div`
  padding-left: 4px;
`;

type PrimaryButtonProps = {
  children?: string,
  icon?: ({ fill?: string }) => Element<"svg">,
  label?: string,
};

export const PrimaryButton = ({
  children,
  icon: Icon,
  label,
  ...props
}: PrimaryButtonProps) => (
  <Button {...props}>
    {Icon && <Icon fill="#fff" />}
    {label && <Label>{label}</Label>}
    {children && <Label>{children}</Label>}
  </Button>
);

const enhance: HOC<*, { children?: string, label?: string }> = withProps({
  icon: Add,
});

export const AddButton = enhance(PrimaryButton);
