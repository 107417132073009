// @flow
import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client/react/hooks";
import { DeleteButtonWithIcon } from "components/DeleteButton";
import { LIST_BUYERS_REPORT } from "../ReportList";

const DELETE_BUYERS_REPORT = gql`
  mutation DeleteBuyersAgentReport($id: ID!) {
    deleteBuyersAgentReport(id: $id) {
      success
    }
  }
`;

const deleteSubmitHandler =
  (mutation, report, customerAccountId) => async () => {
    try {
      // eslint-disable-next-line no-alert
      const confirmation = window.confirm(
        "Are you sure you want to delete this report?",
      );

      if (!confirmation) {
        return;
      }

      await mutation({
        variables: {
          id: report.id,
        },
        refetchQueries: [
          {
            query: LIST_BUYERS_REPORT,
            variables: {
              customerAccountId,
            },
          },
        ],
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      window.alert(
        `Failed to delete buyers report, please try again later or contact helpdesk if the problem persists:\n\n${e}`,
      );
    }
  };

type Props = {
  customerAccountId: string,
  report: getReports_buyersAgentReports,
};

export const DeleteBuyersReport = ({ report, customerAccountId }: Props) => {
  const [deleteBuyersReport] = useMutation(DELETE_BUYERS_REPORT);

  return (
    <DeleteButtonWithIcon
      data-test="delete-button"
      onSubmit={deleteSubmitHandler(
        deleteBuyersReport,
        report,
        customerAccountId,
      )}
    />
  );
};
