// @flow
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { css } from "styled-components";

// This component enables smooth transition animation when adding or removing
// items from a list. It expects each item to be an object containing a string id.
// Import it into your module as follows:
// import { AnimatedList } from "path/to/AnimatedList"
// Define what each row should look like in your module:
// const row = (item, animationsStyle) => (
//   <div css={animationStyle}>Row content here</div>
// );
// **Note: Make sure you pass the animationStyle into the outer div**

type Item = $ReadOnly<{
  __typename: string,
  id: string,
  ...
}>;

type RowType = {
  item: Item,
  additionalComponentProps: Object,
};

type AnimatedListProps = {
  additionalComponentProps?: Object,
  list: $ReadOnlyArray<Item>,
  rowComponent: React.ComponentType<RowType>,
  customAnimation?: string,
};

export const AnimatedList = ({
  additionalComponentProps,
  list,
  rowComponent: Row,
  customAnimation,
}: AnimatedListProps) => {
  const animationStyle = css`
    &.fade-enter {
      max-height: 0px;
      opacity: 0;
      overflow: hidden;
    }
    &.fade-enter-active {
      ${customAnimation}
      max-height: 500px;
      opacity: 1;
      transition: opacity 300ms ease-out, max-height 300ms ease-out;
    }
    &.fade-exit {
      max-height: 500px;
      opacity: 1;
      overflow: hidden;
    }
    &.fade-exit-active {
      ${customAnimation}
      max-height: 0px;
      opacity: 0;
      transition: opacity 300ms ease-out, max-height 300ms ease-out;
    }
  `;

  return (
    <TransitionGroup component={null}>
      {list.map((item) => (
        <CSSTransition
          key={`${item.__typename}-${item.id}`}
          timeout={300}
          classNames="fade"
        >
          <div css={animationStyle}>
            <Row item={item} {...additionalComponentProps} />
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
