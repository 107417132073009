import { getConfig } from "@nested/config";
import { Sentry } from "@nested/isomorphic-sentry";
import { logException } from "@nested/utils/sentry";

const { RELEASE_VERSION, SENTRY_REPORTING_ENABLED } = getConfig();

// Initialize error tracking
Sentry.init({
  environment: process.env.NODE_ENV || "development",
  release: RELEASE_VERSION,
  beforeSend: (event) => {
    if (
      SENTRY_REPORTING_ENABLED !== "true" ||
      event?.user?.email === "developers+smoke_test@nested.com"
    ) {
      return null;
    }
    return event;
  },
});

window.onunhandledrejection = ({ reason: error }) => {
  logException(error);
};

// eslint-disable-next-line global-require
const { init } = require("./init");
init();
