import { gql } from "@apollo/client";

export const TIMELINE_EVENT_FRAGMENT = gql`
  fragment TimelineEventFragment on TimelineEvent {
    __typename
    id
    eventType
    sourceId
    sourceTable
    title
    timestamp
    dateSnoozedUntil
    content
    isNoteSharedWithCustomer
    userEmail
    recipient
    noteId
    pinned
  }
`;

export const TIMELINE_AGENT_TASK_FRAGMENT = gql`
  fragment TimelineAgentTaskFragment on AgentTaskEvent {
    __typename
    id
    agent
    description
    completedAt
    reinstatedAt
  }
`;

export const TIMELINE_EMAIL_FRAGMENT = gql`
  fragment TimelineEmailFragment on EmailThread {
    __typename
    id
    emailCount(relationType: $relationType, relationId: $relationId)
    emails(relationType: $relationType, relationId: $relationId) {
      id
      senderEmail
      senderName
      snippet
      sentAt
    }
    subject
  }
`;
