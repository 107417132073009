const SadHouse = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth="4"
    >
      <path
        strokeLinecap="round"
        d="M10.36 31.245V16.393 12.76h10.641v9.132L33.86 10.58l4.084-3.591a3.144 3.144 0 0 1 4.117 0l4.084 3.59m23.5 20.665l8.36 7.351m-8.36 0v32.28c0 1.605-1.362 2.906-3.04 2.906H13.4c-1.68 0-3.04-1.3-3.04-2.906v-32.28m-8.361 0l8.36-7.351m0 0v7.351m59.284 0v-7.351L46.145 10.58m-12.285 0l-23.5 20.664"
      />
      <path
        strokeLinecap="square"
        d="M29.024 61.584c3.688-3.39 7.31-5.085 10.864-5.085 3.555 0 7.065 1.695 10.53 5.085"
      />
      <path d="M44.037 39.064c1.974 3.39 3.912 5.085 5.814 5.085 1.902 0 3.78-1.695 5.634-5.085M23.394 39.064c1.974 3.39 3.911 5.085 5.813 5.085 1.902 0 3.78-1.695 5.635-5.085" />
    </g>
  </svg>
);

export { SadHouse };
