// @flow

import styled from "styled-components";
import { RadioButton } from "components/RadioButtons/RadioButtons";

const RadioButtonContainer = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  font-weight: normal;
  input {
    appearance: none;
    margin: 0px;
    padding: 0px;
  }
  label:first-of-type {
    border-top-left-radius: ${({ theme }) => theme.border.radius};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius};
  }
  label:last-of-type {
    border-top-right-radius: ${({ theme }) => theme.border.radius};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius};
  }
`;

const StyledRadioButton = styled(RadioButton)`
  background-color: ${({ theme, checked }) =>
    checked ? theme.textField.borderFocus : theme.background.radioButton};
  border: 2px solid ${({ theme }) => theme.textField.border};
  flex-basis: 0;
  flex-grow: 1;

  &:hover {
    border: 2px solid ${({ theme }) => theme.textField.borderFocus};
    z-index: 2;
  }
`;

const VerticalOffset = styled.div`
  margin-bottom: 2px;
`;

export const ColoredDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: ${({ color }) => color};
`;

const Button = ({ color, label, checked, onClick }) => (
  <StyledRadioButton checked={checked} onClick={onClick}>
    <ColoredDot color={color} />
    <VerticalOffset>{label}</VerticalOffset>
  </StyledRadioButton>
);

type Props = {
  ratings: { [string]: { label: string, color: string } },
  current: ?string,
  onClick: Function,
  lightTheme?: boolean,
};

export const RatingButtons = ({
  ratings,
  current,
  onClick,
  lightTheme,
}: Props) => (
  <RadioButtonContainer>
    {Object.entries(ratings).map(([value, rating]) => {
      if (typeof rating !== "object") return "";

      return (
        <Button
          lightTheme={lightTheme}
          data-test="rating-button"
          key={value}
          label={rating?.label}
          color={rating?.color}
          checked={value === current}
          onClick={() => onClick(value)}
        />
      );
    })}
  </RadioButtonContainer>
);
