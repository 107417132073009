// @flow
import type { MutationOperation } from "@apollo/client";
import { compose } from "recompose";
import styled from "styled-components";

import { ListEntryCard as OriginalListEntryCard } from "components/Card";
import { Grid, LeafCell } from "components/Grid";
import { DatePicker } from "components/DatePicker";
import {
  DeleteButtonWithIcon,
  itemDeletionConfirmed,
} from "components/DeleteButton";
import { SelectField } from "components/SelectField";
import { graphql } from "deal/utils/graphql";

import deleteExpectedDrawdownTimingMutation from "./mutations/deleteExpectedDrawdownTiming.graphql";
import { deleteExpectedDrawdownTimingConfig } from "./mutations/deleteExpectedDrawdownTimingConfig";
import updateExpectedDrawdownTimingMutation from "./mutations/updateExpectedDrawdownTiming.graphql";
import { updateExpectedDrawdownTimingConfig } from "./mutations/updateExpectedDrawdownTimingConfig";

const ListEntryCard = styled(OriginalListEntryCard)`
  position: relative;
`;

const DeleteButtonWithConfirmation = styled(DeleteButtonWithIcon)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

type Props = {
  deleteExpectedDrawdownTiming: (
    id: string,
  ) => () => MutationOperation<DeleteExpectedDrawdownTimingMutation_deleteExpectedDrawdownTiming>,
  expectedDrawdownTimings: Array<DealTypeAgencyPlusSep2018GlobalFragment_expectedDrawdownTimings>,
  parentId: string,
  possibleExpectedDrawdownDays: Array<{ label: string, value: number }>,
  updateExpectedDrawdownTiming: (
    currentExpectedDrawdownTiming: UpdateExpectedDrawdownTimingMutation_updateExpectedDrawdownTiming,
  ) => (
    input: UpdateExpectedDrawdownTimingInput,
  ) => MutationOperation<UpdateExpectedDrawdownTimingMutation_updateExpectedDrawdownTiming>,
};

export function ExpectedDrawdownTimingsListComponent({
  deleteExpectedDrawdownTiming,
  expectedDrawdownTimings,
  possibleExpectedDrawdownDays,
  updateExpectedDrawdownTiming,
}: Props): Array<React$Element<any>> {
  return expectedDrawdownTimings.map((expectedDrawdownTiming) => (
    <ListEntryCard
      data-test="expected-drawdown-timing-card-existing-entry"
      key={expectedDrawdownTiming.id}
    >
      <Grid columns={4}>
        <LeafCell>
          <SelectField
            data-test="expected-drawdown-timing-drawdown-day-existing-entry"
            label="Expected drawdown on or after day..."
            mutation={updateExpectedDrawdownTiming(expectedDrawdownTiming)}
            property="drawdownDay"
            options={possibleExpectedDrawdownDays}
            searchable
            value={expectedDrawdownTiming.drawdownDay}
          />
        </LeafCell>
        <LeafCell>
          <DatePicker
            data-test="expected-drawdown-timing-date-agreed-existing-entry"
            label="Date agreed"
            mutation={updateExpectedDrawdownTiming(expectedDrawdownTiming)}
            property="agreementDate"
            value={expectedDrawdownTiming.agreementDate}
          />
        </LeafCell>
      </Grid>
      <DeleteButtonWithConfirmation
        data-test="delete-expected-drawdown-timing-card-existing-entry"
        onSubmit={() => {
          if (itemDeletionConfirmed()) {
            deleteExpectedDrawdownTiming(expectedDrawdownTiming.id);
          }
        }}
      />
    </ListEntryCard>
  ));
}

export const ExpectedDrawdownTimingsList = compose(
  graphql(
    deleteExpectedDrawdownTimingMutation,
    deleteExpectedDrawdownTimingConfig,
  ),
  graphql(
    updateExpectedDrawdownTimingMutation,
    updateExpectedDrawdownTimingConfig,
  ),
)(ExpectedDrawdownTimingsListComponent);
