// @flow
import { Heading, H2 } from "components/Heading";
import { Card } from "components/Card";
import { TabContainer, TabWrapper } from "components/TabContainer";
import { Advances } from "tabs/AdvanceValuationsAndLegal/Advances/Advances";
import { AdvancesSectionList } from "tabs/AdvanceValuationsAndLegal/Advances/AdvancesSectionList";
import { AdvanceInPrinciple } from "tabs/AdvanceValuationsAndLegal/Advances/AdvanceInPrinciple";
import { AdvanceDrawdownRequest } from "tabs/AdvanceValuationsAndLegal/Advances/AdvanceDrawdownRequest";
import { AdvanceOffer } from "tabs/AdvanceValuationsAndLegal/Advances/AdvanceOffer";
import { LegalServicesSection } from "tabs/AdvanceValuationsAndLegal/Advances/LegalServicesSection/LegalServicesSection";
import { AdvancePayment } from "tabs/AdvanceValuationsAndLegal/Advances/AdvancePayment";
import { NestedProgressor } from "../NestedProgressor/NestedProgressor";
import { HouseholdCostsList } from "../HouseholdCostsList/HouseholdCostsList";
import { DealDetails } from "../DealDetails";
import { DebtFundingSection } from "../DebtFundingSection/DebtFundingSection";
import { PropertySaleRedemption } from "../PropertySaleRedemption";
import { SDLTSurchargeSection } from "../SDLTSurcharge";

type Props = { dealId: string, dealTypeValue: string, dealTypeId: string };

export const AdvancesForAllMarch2019Payments = ({
  dealId,
  dealTypeValue,
  dealTypeId,
}: Props) => (
  <TabWrapper>
    <TabContainer id="Payments" data-test="payments-tab" className="fs-exclude">
      <NestedProgressor parentId={dealId} />

      <Heading id="advance-payments">Advance payments</Heading>
      <Card>
        <Advances dealId={dealId}>
          <AdvancesSectionList>
            <AdvanceInPrinciple />
            <AdvanceOffer />
            <AdvanceDrawdownRequest />
            <LegalServicesSection readOnly />
            <AdvancePayment />
          </AdvancesSectionList>
        </Advances>
      </Card>

      <Heading id="SDLT-Surcharge">SDLT Surcharge</Heading>
      <Card>
        <SDLTSurchargeSection parentId={dealId} />
      </Card>

      <Heading id="Property-sale-redemption">Property sale redemption</Heading>
      <Card>
        <PropertySaleRedemption
          dealId={dealId}
          dealTypeValue={dealTypeValue}
          dealTypeId={dealTypeId}
        />
      </Card>

      <DebtFundingSection parentId={dealId} />

      <Heading id="Re-imburse-household-costs---fee-adjustments--s10-">
        Re-imburse household costs & fee adjustments (S10)
      </Heading>

      <Card>
        <H2>Household costs & fee adjustmentd</H2>
        <HouseholdCostsList parentId={dealId} />
      </Card>
    </TabContainer>
    <DealDetails dealId={dealId} className="fs-exclude" />
  </TabWrapper>
);
