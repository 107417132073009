// @flow
// temp disable whilst we deprecate an id field in https://app.clubhouse.io/nested/story/35598/filter-suggested-buyers-and-properties-based-on-buyer-requirements
/* eslint-disable graphql/required-fields */
import { gql } from "@apollo/client";
import { BUYER_OFFER_LIST_FRAGMENT } from "@nest-ui/sellers-nest/tabs/Interest/BuyerOfferList/BuyerOfferListFragment";

export const BUYER_PROPERTY_INTEREST_FRAGMENT = gql`
  fragment buyerPropertyInterestFields on BuyerPropertyInterest {
    assignedSa {
      fullName
      id
    }
    buyer {
      contacts {
        id
        name
        emailAddresses
      }
      id
    }
    buyerEnquiries {
      id
      additionalComments
    }
    buyerOffers {
      id
      deal {
        id
        assignedProgressor {
          id
        }
      }
      ...BuyerOfferListFragment
    }
    currentlyInterested
    deal {
      id
      address
      contacts {
        id
        name
        emailAddresses
        viewingCommsEnabled
      }
      externalId
      nestedListing
      currentListPrice
      opportunityStatus {
        label
        valueText
      }
      propertyAccessArrangements
      saExtendedSummary
      subAgents {
        branchName
        id
      }
      currentlyResponsibleSaUser {
        fullName
        id: closeUserId
      }
      vendorLeadSa: leadSaUser {
        fullName
        id: closeUserId
      }
      viewings {
        id
      }
      buyerOffers {
        id
      }
      listingStatus {
        date
        status
      }
    }
    falloutReason {
      id
      label
    }
    falloutDetails
    id
    status {
      value
    }
    viewings {
      buyerPropertyInterest {
        id
        buyer {
          id
        }
      }
      conductor
      datetimeConfirmedWithVendor
      datetimeFeedbackSharedWithSeller
      datetimeFirstImpressionsSharedWithSeller
      datetimeFeedbackAdded
      datetimeViewingEnds
      datetimeViewingStarts
      followUpFeedback {
        id
        datetimeSharedWithSeller
        feedback
      }
      id
      notes
      nestedUserId
      rearrangedViewingId
      viewingCancellationReasonId
      viewingFeedbackFirstImpressions
      viewingFeedbackFull
      viewingSaId
      viewingSubAgentId
      virtual
    }
  }
  ${BUYER_OFFER_LIST_FRAGMENT}
`;
