// @flow

import { path } from "ramda";

import { DealDetailsBar, Section } from "components/DealDetailsBar";
import { Grid, LeafCell } from "components/Grid";
import { TextField } from "components/TextField";
import { CurrencyField } from "components/CurrencyField";
import { UnorderedList } from "components/UnorderedList";
import { TextareaWithLinks } from "components/TextareaWithLinks";
import { DatePicker } from "components/DatePicker";

import { ErrorBoundary } from "@nest-ui/sellers-nest/components/ErrorBoundary";
import { withGraphQLQuery } from "deal/hocs/withGraphQLQuery";

import DealDetailsQuery from "./DealDetailsQuery.graphql";
import { FeesAdvanceJul2018 } from "./FeesAdvanceJul2018";
import { FeesAdvanceMay2018 } from "./FeesAdvanceMay2018";
import { FeesAgencyPlusSep2018 } from "./FeesAgencyPlusSep2018";
import { FeesAgency } from "./FeesAgency";
import { FeesGuarantee } from "./FeesGuarantee";
import { FeesLegacyGuarantee } from "./FeesLegacyGuarantee";
import { FeesUnderwriting } from "./FeesUnderwriting";
import { DrawdownPeriodDatesSection } from "../DrawdownPeriodDates/DrawdownPeriodDates";

const dealTypeMapping = {
  advance_jul2018: FeesAdvanceJul2018,
  advance_may2018: FeesAdvanceMay2018,
  agency_plus_sep2018: FeesAgencyPlusSep2018,
  free_no_guarantee: FeesAgency,
  regular_new: FeesGuarantee,
  regular_original: FeesLegacyGuarantee,
  underwrite: FeesUnderwriting,
};

const FeesComponent = ({ nestDeal }: PaymentsTabDealDetails) => {
  const dealType = nestDeal?.dealType;

  if (!dealType) {
    return null;
  }

  const Component = dealTypeMapping[dealType.value];

  if (Component === undefined) {
    return null;
  }

  return <Component nestDeal={nestDeal} />;
};

export const ReadOnlyContent = ({ nestDeal }: PaymentsTabDealDetails) => (
  <Grid columns={2}>
    <LeafCell width={2}>
      <TextField
        label="Address"
        value={nestDeal?.address}
        readOnly
        className="fs-exclude"
      />
    </LeafCell>
    <LeafCell width={2}>
      <CurrencyField
        value={path(["latestGuaranteeAgreement", "amount"], nestDeal)}
        label="Guarantee amount"
        readOnly
      />
    </LeafCell>
    <DrawdownPeriodDatesSection dealId={nestDeal?.id} readOnly />
    <LeafCell width={2}>
      <UnorderedList
        value={nestDeal?.guaranteeCurrentStatus}
        label="Guarantee current status"
        readOnly
      />
    </LeafCell>
    <LeafCell width={2}>
      <TextareaWithLinks
        value={nestDeal?.guaranteeNotes}
        label="Guarantee notes"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <DatePicker
        value={path(
          ["acceptedBuyerOffer", "projectedDateOfExchange"],
          nestDeal,
        )}
        label="Projected date of exchange"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <DatePicker
        value={path(["acceptedBuyerOffer", "actualDateOfExchange"], nestDeal)}
        label="Actual date of exchange"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <DatePicker
        value={path(
          ["acceptedBuyerOffer", "projectedDateOfCompletion"],
          nestDeal,
        )}
        label="Projected date of completion"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <DatePicker
        value={path(["acceptedBuyerOffer", "actualDateOfCompletion"], nestDeal)}
        label="Actual date of completion"
        readOnly
      />
    </LeafCell>
    <LeafCell>
      <CurrencyField
        value={nestDeal?.salePrice}
        label="Actual sale price"
        readOnly
      />
    </LeafCell>
    <LeafCell width={2}>
      <TextField
        value={path(["dealType", "label"], nestDeal)}
        label="Deal type"
        readOnly
      />
    </LeafCell>
    <FeesComponent nestDeal={nestDeal} />
  </Grid>
);

const DealDetails = withGraphQLQuery(DealDetailsQuery)(
  ({ data: { nestDeal }, className }) => (
    <ErrorBoundary>
      <DealDetailsBar className={className}>
        <Section nestDeal={nestDeal} readOnlyContent={ReadOnlyContent} />
      </DealDetailsBar>
    </ErrorBoundary>
  ),
);

export { DealDetails };
